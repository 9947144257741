import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { useMeCore } from "../../../cores/me";
import { useSocialPostCore } from "../../../cores/socialPost";
import { SocialProvider } from "../../../cores/socialPost/interfaces/socialProvider";
import { MONTH } from "../../utils/dateUtils";
import { InputWembii } from "../InputWembii";
import { PanelWembii } from "../PanelWembii";
import { ableToPost } from "../socialPost/selector/SocialPostSelector";
import { useIntl } from "react-intl";
import { ableToStory } from "../socialPost/selector/SocialStorySelector";
import { SocialTargetOptions } from "../../../cores/socialPost/config";

export const SocialCalendarBar = () => {
  const intl = useIntl();
  const { company, socialSettings } = useMeCore();
  const { date, setDate, getSocialPosts } = useSocialPostCore();
  const [provider, setProvider] = useState("all");
  const [target, setTarget] = useState("post");

  const socialProviders: SocialProvider[] = useMemo(
    () =>
      (target === "post" ? ableToPost : ableToStory)
        .filter((social) => socialSettings![social])
        .map(
          (social): SocialProvider => ({
            id: social,
            name: social,
          })
        ),
    [socialSettings, target]
  );

  const today = useCallback(() => {
    setDate(moment());
  }, [setDate]);

  const nextMonth = useCallback(() => {
    setDate(moment(date).add(1, 'M'));
  }, [date, setDate]);

  const previousMonth = useCallback(() => {
    setDate(moment(date).subtract(1, 'M'));
  }, [date, setDate]);

  useEffect(() => {
      getSocialPosts(
        company?.id!, 
        moment(date).startOf('M').format('YYYY-MM-DD'),
        moment(date).endOf('M').format('YYYY-MM-DD'), 
        provider,
        "",
        target
      );
  }, [company, date, provider, target, getSocialPosts]);

  const onChangeTarget = useCallback((target) => {
    setTarget(target);
    setProvider("all");
  }, []);

  return (
    <PanelWembii className="w-100 px-4 py-3 mb-3 pointer" shadow>
      <Row>
        <Col xs="12" lg="2" className="d-flex justify-content-center justify-content-lg-start">
          <InputWembii  
            label={intl.formatMessage({ id: "app.socialManager.socialTxt" })}
            type="select"
            mb={false}
            value={provider}
            onChange={(v) => setProvider(v)}
          >
            <option key="all" value="all">Todas</option>
            {socialProviders.map(provider => (
              <option key={provider.id} value={provider.id} className="text-capitalize">
                {provider.name}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="2" className="d-flex justify-content-center justify-content-lg-start">
          <InputWembii
            label={intl.formatMessage({ id: "app.socialManager.typeTxt" })}
            type="select"
            mb={false}
            value={target}
            onChange={(v) => onChangeTarget(v)}
          >
            {SocialTargetOptions.map(({ value, label }) => (
              <option key={value} value={value} className="text-capitalize">
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="4" className="d-flex justify-content-center align-items-center p-3 font-size-2">
          <div className="pointer" onClick={previousMonth}>
            <FontAwesomeIcon
              icon={["fas", "chevron-left"]}
              size="1x"
              color="gray"
              className="mx-4"
            />
          </div>
          {MONTH[moment(date).format("M")] + ' ' + moment(date).format("YYYY")}
          <div className="pointer" onClick={nextMonth}>
            <FontAwesomeIcon
              icon={["fas", "chevron-right"]}
              size="1x"
              color="gray"
              className="mx-4"
            />
          </div>
        </Col>
        <Col xs="12" lg="4" className="d-flex justify-content-center justify-content-lg-end align-items-center">
          <div className="pointer pr-2" onClick={today}>
           {intl.formatMessage({ id: "app.social.goToday" })} 
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
