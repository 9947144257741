import moment from "moment";
import React from "react";
import { Button } from "reactstrap";
import { ANALYTICS_POST_EXPAND } from "../../../../constants/modal.constant";
import { useAnalyticsCore } from "../../../../cores/analytics";
import { InstagramPost } from "../../../../cores/analytics/interface/api/get-instagram-feed-posts";
import { useGlobalCore } from "../../../../cores/globals";
import { CommentLabel } from "./CommentLabel";
import { InstagramPostModal } from "./InstagramPostModal";
import { LikeLabel } from "./LikeLabel";
import { ViewLabel } from "./ViewLabel";
export default function InstagramPosts() {
  const { instagramFeedSelectedPostGroup } = useAnalyticsCore();

  return (
    <div className="p-4 overflow-auto">
      <h2 className="h2 text-center mb-4">Elige un posteo</h2>
      {instagramFeedSelectedPostGroup.map((post) =>
        post ? <InstagramPostItem key={post.id} post={post} /> : null
      )}
    </div>
  );
}

const InstagramPostItem = ({ post }: { post: InstagramPost }) => {
  const {
    timestamp,
    imageUrls,
    videoUrl,
    likeCount,
    commentCount,
    viewCount,
  } = post;
  const { turnOnModal } = useGlobalCore();
  return (
    <div className="align-items-center d-flex p-2 neu-in pointer mb-1">
      {(imageUrls.length > 0 || videoUrl) && (
        <img
          style={{ width: "30px", height: "30px", objectFit: "fill" }}
          className="rounded-circle"
          src={imageUrls[0] || videoUrl}
          alt="Avatar"
        />
      )}
      <span className="small ml-3">
        {moment(timestamp * 1000)
          .format("DD/MM/YYYY - hh:mm:ss")
          .toString()}
      </span>
      <div className="mx-auto">
        <LikeLabel className="mx-2" amount={likeCount} />
        <CommentLabel className="mx-2" amount={commentCount} />
        <ViewLabel className="mx-2" amount={viewCount} />
      </div>
      <Button
        size="sm"
        className="ml-auto"
        color="primary"
        onClick={() => {
          turnOnModal(
            ANALYTICS_POST_EXPAND,
            <InstagramPostModal post={post} />
          );
        }}
      >
        Seleccionar
      </Button>
    </div>
  );
};
