import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMeCore } from "../../../cores/me";
import { InputWembii } from "../InputWembii";
import { CONTACT_COMPANY_MODAL } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { useContactCore } from "../../../cores/contacts";
import { Company } from "../../../cores/contacts/interfaces/api/get-company";
import { Contact } from "../../../cores/contacts/interfaces/api/get-contacts";

interface Props {
  contact: Contact;
  company: Company;
  onClose: () => void;
  onSuccess: () => void;
}

export default function AssignmentForm({ company: roleCompany, contact, onClose, onSuccess }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const {
    postContactCompany,
    putContactCompany,
    deleteContactCompany,
  } = useContactCore();
  const { turnOffModal } = useGlobalCore();

  const [role, setRole] = useState("");

  useEffect(() => {
    if (roleCompany.role) {
      setRole(roleCompany.role);
    }
  }, [roleCompany]);

  const cleanForm = useCallback(() => {
    setRole("");
    onSuccess();
    onClose();
    turnOffModal(CONTACT_COMPANY_MODAL);
  }, [onClose, onSuccess, turnOffModal]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!roleCompany.role) {
      postContactCompany(
        company?.id!,
        roleCompany?.id!,
        contact.id,
        role,
        cleanForm
      );
    } else {
      putContactCompany(
        company?.id!,
        roleCompany?.id!,
        contact.id,
        role,
        cleanForm
      );
    }
  };

  const disabled = useCallback(
    () => !role || roleCompany.role === role,
    [role, roleCompany]
  );

  const onDelete = useCallback(() => {
    deleteContactCompany(
      company?.id!,
      roleCompany?.id!,
      contact.id,
      cleanForm
    );
  }, [deleteContactCompany, cleanForm, roleCompany, company, contact.id]);

  return (
    <div
      className="h-100 w-100 position-fixed top-0 z-index-5 d-flex align-items-center justify-content-center bg-black-transparent"
      style={{ borderRadius: "30px" }}
    >
      <Form className="text-center bg-white rounded p-2" onSubmit={onSubmit}>
        <div className="text-right w-100">
          <FontAwesomeIcon
            icon={["fas", "times"]}
            size="1x"
            className="pointer"
            onClick={onClose}
          />
        </div>
        <Row>
          <Col className="d-flex align-items-center justify-content-center">
            {roleCompany.name}
          </Col>
          <Col>
            <InputWembii
              label={intl.formatMessage({ id: "app.contacts.companies.role" })}
              type="text"
              name="role"
              id="role"
              required
              placeholder={intl.formatMessage({ id: "app.contacts.companies.role" })}
              value={role}
              onChange={setRole}
            />
          </Col>
        </Row>

        {roleCompany.role  && (
          <Button color="danger" className="mr-1" onClick={onDelete}>
            {intl.formatMessage({ id: "app.activity.tags.delete" })}  
          </Button>
        )}
        <Button color="primary" disabled={disabled()} className="ml-1">
          {roleCompany.role 
            ? intl.formatMessage({ id: "app.contacts.companies.role.update" })
            : intl.formatMessage({ id: "app.contacts.companies.role.assign" })
          }  
        </Button>
      </Form>
    </div>
  );
}