import React, { useCallback, useMemo } from "react";
import { Button } from "reactstrap";
import { GOOGLE_PROJECTS_SIDE_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useGoogleCore } from "../../../../cores/google";
import { GoogleProject } from "../../../../cores/google/interface/api/get-google-projects";
import { useMeCore } from "../../../../cores/me";
import { GooglenAnalyticsIcon } from "./GoogleAnalitycsIcon";

export default function GoogleProjects() {
  const { projects = [] } = useGoogleCore();
  return (
    <div className="p-4 overflow-auto">
      <h2 className="h2 text-center mb-4">Elige un Proyecto</h2>
      {projects?.length === 0 && (
        <div className="h6 py-3 text-center wembii-border mt-3">
          No hay proyectos disponibles para esta cuenta
        </div>
      )}
      {projects?.map((project) => (
        <GoogleProjectComponent key={project.id} project={project} />
      ))}
    </div>
  );
}

const GoogleProjectComponent = ({ project }: { project: GoogleProject }) => {
  const { name, webProperties } = project;
  const { tokens, bind } = useGoogleCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { turnOffModal } = useGlobalCore();
  const disabled = useMemo(
    () =>
      !tokens?.token || !tokens?.refreshToken || !company || !webProperties[0],
    [company, tokens, webProperties]
  );

  const handleClick = useCallback(() => {
    if (tokens && tokens.refreshToken && tokens.token && project.id)
      bind(
        company!.id,
        tokens?.token!,
        tokens?.refreshToken!,
        project.id,
        () => {
          getMeSocialSettings(company?.id!);
        }
      );
    turnOffModal(GOOGLE_PROJECTS_SIDE_MODAL);
  }, [bind, company, project.id, tokens, turnOffModal, getMeSocialSettings]);

  return (
    <div className="align-items-center d-flex p-2 px-3 neu-in pointer mb-2">
      <GooglenAnalyticsIcon active />
      <span className="h5 mb-0 ml-3"> {name} </span>
      {webProperties[0] && (
        <span className="small mb-0 ml-3">
          <a href={webProperties[0].websiteUrl} target="_black">
            {webProperties[0].websiteUrl}
          </a>
        </span>
      )}
      <Button
        className="ml-auto"
        color="primary"
        onClick={handleClick}
        disabled={disabled}
      >
        Vincular
      </Button>
    </div>
  );
};
