import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Badge } from "reactstrap";
import {
  FACEBOOK_COLOR,
  JUMPSELLER_COLOR,
  WHATSAPP_COLOR,
  WOO_COMMERCE_COLOR,
} from "../../../constants/socialColors.constant";
import { useContactCore } from "../../../cores/contacts";
import { ContactSource } from "../../../cores/contacts/enum/ContactSource.enum";
import { FacebookIcon } from "../social/Facebook/FacebookIcon";
import { JumpsellerIcon } from "../social/Jumpseller/JumpsellerIcon";
import { WooCommerceIcon } from "../social/WooCommerce/WooCommerceIcon";

interface Props {
  source: ContactSource;
  onClick?: () => void;
}

export const ContactSourceBadge = (props: Props) => {
  const intl = useIntl();
  const { source, onClick } = props;
  const { tags } = useContactCore();

  const colorIconMap = useMemo(() => ({
    Manual: {
      icon: (
        <FontAwesomeIcon
          icon={["fas", "feather-alt"]}
          color={"white"}
          className="mr-2"
        />
      ),
      textColor: "#fff",
      backgroundColor: "black",
      label: "Manual"
    },
    WooCommerce: {
      icon: <WooCommerceIcon className="mr-2" color={"white"} />,
      textColor: "#fff",
      backgroundColor: WOO_COMMERCE_COLOR,
      label: "WooCommerce"
    },
    Jumpseller: {
      icon: <JumpsellerIcon className="mr-2" color={"white"} />,
      textColor: "#fff",
      backgroundColor: JUMPSELLER_COLOR,
      label: "Jumpseller"
    },
    "Web-Chat": {
      icon: (
        <FontAwesomeIcon
          icon={["fas", "comments"]}
          color={"white"}
          className="mr-2"
        />
      ),
      textColor: "#fff",
      backgroundColor: "#DAF",
      label: intl.formatMessage({ id: "app.source.webChat" })
    },
    "Whatsapp-Chat": {
      icon: (
        <FontAwesomeIcon
          icon={["fab", "whatsapp"]}
          color={"white"}
          className="mr-2"
        />
      ),
      textColor: "#fff",
      backgroundColor: WHATSAPP_COLOR,
      label: intl.formatMessage({ id: "app.source.whatsap" })
    },
    "Facebook-Form": {
      icon: <FacebookIcon className="mr-2" color={"white"} />,
      textColor: "#fff",
      backgroundColor: FACEBOOK_COLOR,
      label: intl.formatMessage({ id: "app.source.facebookForm" })
    },
    "Web-Form": {
      icon: (
        <FontAwesomeIcon
          icon={["fab", "wpforms"]}
          color={"white"}
          className="mr-2"
        />
      ),
      textColor: "#fff",
      backgroundColor: "#673ab7",
      label: intl.formatMessage({ id: "app.source.form" })
    },
    CRM: {
      icon: (
        <FontAwesomeIcon
          icon={["fab", "wpforms"]}
          color={"white"}
          className="mr-2"
        />
      ),
      textColor: "#fff",
      backgroundColor: "#945C20",
      label: "CRM"
    },
    Meeting: {
      icon: (
        <FontAwesomeIcon
          icon={["fas", "calendar"]}
          color={"white"}
          className="mr-2"
        />
      ),
      textColor: "#fff",
      backgroundColor: "#9E0000",
      label: intl.formatMessage({ id: "app.source.meeting" })
    },
  }), [intl]);

  const data = useMemo(() => {
    if (colorIconMap[source]) {
      return colorIconMap[source];
    } else {
      const tag = tags.find((tag) => tag.nameId === source)
      return (tag) ? ({
        icon: (
          <FontAwesomeIcon
            icon={["fas", "info-circle"]}
            color={tag.textColor}
            className="mr-2"
          />
        ),
        textColor: tag.textColor,
        backgroundColor: tag.backgroundColor,
        label: tag.name
      }) : ({
        icon: (
          <FontAwesomeIcon
            icon={["fas", "exclamation-triangle"]}
            color={"white"}
            className="mr-2"
          />
        ),
        textColor: "#fff",
        backgroundColor: "#000",
        label: intl.formatMessage({ id: "app.contacts.sourceUndefined" })
      })
    }
  }, [colorIconMap, source, tags, intl]);

  return (
    <Badge pill style={{ backgroundColor: data.backgroundColor }} onClick={onClick}>
      <div className="px-2 py-1">
        {data.icon}
        <span className="h6 mb-0 font-weight-bold text-wrap" style={{ color: data.textColor }}>
          {data.label}
        </span>
      </div>
    </Badge>
  );
};
