import _ from "lodash";
import cn from "classnames";
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { Button, Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import {
  CARD_CHECK_LOADING,
  PLAN_COUPON_LOADING,
  PLAN_LOADING,
  UPGRADE_PLAN_LOADING,
} from "../../constants/loading.constant";
import { usePaymentCore } from "../../cores/payment";
import { useMeCore } from "../../cores/me";
import { usePlanCore } from "../../cores/plan";
import { PlanType } from "../../cores/plan/contants/planType.constant";
import { PlanBadge } from "../component/companies/PlanBadge";
import { InputWembii } from "../component/InputWembii";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import { PlanRule } from "../component/plan/PlanRule";
import { CouponInfo } from "../component/plan/CouponInfo";
import { Plan } from "../../cores/plan/interfaces/api/get-plans";
import { useGlobalCore } from "../../cores/globals";
import { useRouterCore } from "../../cores/router";
import AreYouSure from "../component/AreYouSure";
import { CHANGE_SUBSCRIPTION_MODAL } from "../../constants/modal.constant";
import { CL$Format } from "../../cores/utils";
import { useIntl } from "react-intl";
import { PanelWembii } from "../component/PanelWembii";
import Content from "../layout/Content";
import Layout from "../layout/Layout";

const coinOptions = {
  CLP: { id: "CLP", label: "CLS" },
  USD: { id: "USD", label: "USD" },
  BRL: { id: "BRL", label: "BRL" },
  PEN: { id: "PEN", label: "PEN" },
};

export const Plans = () => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(0);
  const [couponTxt, setCouponTxt] = useState("");
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [checkedCoupon, setCheckedCoupon] = useState(false);
  const [payWithBank, setPayWithBank] = useState(false);
  // const [cardTxt, setCardTxt] = useState("");
  // const [checkedCard, setCheckedCard] = useState(false);
  const [cardValid] = useState(false);
  const [coin, setCoin] = useState("CLP");
  const [type, setType] = useState("annual");
  const { getPlans, plans } = usePlanCore();
  const { goToMeCompany } = useRouterCore();
  const { reset, turnOffModal, turnOnModal, isLoading } = useGlobalCore();
  const {
    postFlowSubscription,
    deleteSubscription,
    getFlowCoupon,
    getStripeCoupon,
    postStripeSubscription,
    resetCoupon,
    coupon,
  } = usePaymentCore();
  const { userPlan, companyRole, deselectCompany } = useMeCore();

  useEffect(() => {
    getPlans();
    resetCoupon();
  }, [getPlans, resetCoupon, userPlan]);

  const toggle = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setSelectedPlan(plans.find(({ id }) => id === tab) || null);
    }
  };

  const handleClickUpgrade = useCallback(async () => {
    const couponId = payWithBank ? null : coupon?.id || null;
    let urlData;
    switch (coin) {
      case coinOptions.CLP.id:
        urlData = await postFlowSubscription(
          payWithBank && cardValid
            ? type === "annual" ? selectedPlan?.annualFlowChileBankId! : selectedPlan?.flowChileBankId!
            : type === "annual" ? selectedPlan?.annualFlowId! : selectedPlan?.flowId!,
          couponId
        );
        break;
      case coinOptions.USD.id:
        urlData = await postStripeSubscription(
          type === "annual" ? selectedPlan?.annualStripeUsdId! : selectedPlan?.stripeUsdId!,
          couponId
        );
        break;
      case coinOptions.BRL.id:
        urlData = await postStripeSubscription(
          type === "annual" ? selectedPlan?.annualStripeBrlId! : selectedPlan?.stripeBrlId!,
          couponId
        );
        break;
      case coinOptions.PEN.id:
        urlData = await postStripeSubscription(
          type === "annual" ? selectedPlan?.annualStripePenId! : selectedPlan?.stripePenId!,
          couponId
        );
        break;
    }
    
    if (urlData) window.open(urlData.url, "_self");
  }, [
    postFlowSubscription,
    postStripeSubscription,
    coupon,
    type,
    selectedPlan,
    coin,
    cardValid,
    payWithBank
  ]);

  const handleClickCancel = useCallback(() => {
    turnOnModal(
      CHANGE_SUBSCRIPTION_MODAL,
      <AreYouSure
        message={`Está seguro de querer cancelar la subscripción activa? Se pasará a tener el Plan FREE`}
        onSubmit={() => {
          deleteSubscription(() => {
            reset();
            deselectCompany();
            goToMeCompany();
          });
          turnOffModal(CHANGE_SUBSCRIPTION_MODAL);
        }}
        onClose={() => turnOffModal(CHANGE_SUBSCRIPTION_MODAL)}
      />
    );
  }, [
    turnOnModal,
    turnOffModal,
    deleteSubscription,
    reset,
    goToMeCompany,
    deselectCompany,
  ]);

  const debouncedCheckCoupon = useRef(
    _.debounce(
      (coupon, coin) =>
        coupon && (
          coin === coinOptions.CLP.id
            ? getFlowCoupon(coupon, () => {
                setCheckedCoupon(true);
              })
            : getStripeCoupon(coupon, () => {
                setCheckedCoupon(true);
              })
        ),
      1000
    )
  ).current;

  // const debouncedCheckCard = useRef(
  //   _.debounce(
  //     async (card) => {
  //       if (card) {
  //         const valid = await getCardVerification(card, () => {
  //           setCheckedCard(true);
  //         });
  //         setCardValid(valid || false);
  //       }
  //     },
  //     1000
  //   )
  // ).current;

  useEffect(() => {
    setCheckedCoupon(false);
    debouncedCheckCoupon(couponTxt, coin);
  }, [debouncedCheckCoupon, couponTxt, coin]);

  // useEffect(() => {
  //   setCheckedCard(false);
  //   debouncedCheckCard(cardTxt);
  // }, [debouncedCheckCard, cardTxt]);

  useEffect(() => {
    if (userPlan) {
      setActiveTab(userPlan.id);
      setSelectedPlan(plans.find(({ id }) => id === userPlan.id) || null);
    }
  }, [userPlan, plans, setSelectedPlan]);

  const showUpgradeOptions = useMemo(
    () =>
      (!companyRole || companyRole.owner) &&
      selectedPlan?.name !== PlanType.FREE &&
      userPlan?.id !== selectedPlan?.id,
    [selectedPlan, userPlan, companyRole]
  );

  const showCancelOptions = useMemo(
    () =>
      (!companyRole || companyRole.owner) &&
      selectedPlan?.name !== PlanType.FREE &&
      userPlan?.id === selectedPlan?.id,
    [selectedPlan, userPlan, companyRole]
  );

  const price = useMemo(() => {
    let price;
    switch(coin) {
      case coinOptions.CLP.id:
        if (payWithBank && cardValid) {
          price = type === "annual" ? selectedPlan?.annualFlowChileBankPrice! : selectedPlan?.flowChileBankPrice!;
          return CL$Format(price, "", "CLS ");
        } else {
          price = type === "annual" ? selectedPlan?.annualFlowPrice! : selectedPlan?.flowPrice!;
          return CL$Format(price, "", "CLS ");
        }
      case coinOptions.USD.id:
        price = type === "annual" ? selectedPlan?.annualStripeUsdPrice! : selectedPlan?.stripeUsdPrice!;
        return `USD ${price}`;
      case coinOptions.BRL.id:
        price = type === "annual" ? selectedPlan?.annualStripeBrlPrice! : selectedPlan?.stripeBrlPrice!;
        return `BRL ${price}`;
      case coinOptions.PEN.id:
        price = type === "annual" ? selectedPlan?.annualStripePenPrice! : selectedPlan?.stripePenPrice!;
        return `PEN ${price}`;
    }
  }, [coin, type, selectedPlan, payWithBank, cardValid]);

  const handleChangeType = useCallback((type) => {
    resetCoupon();
    setPayWithBank(false);
    setType(type);
  }, [resetCoupon]);

  const handleChangeCoin = useCallback((coin) => {
    resetCoupon();
    setPayWithBank(false);
    setCoin(coin);
  }, [resetCoupon]);

  return (
    <Layout>
      <Content>
        <Container className="mt-4">
          <Row>
            <Col xs="12">
              <Container>
                <PanelWembii shadow className="px-5 py-4 mb-2 position-relative" style={{ minHeight: '700px' }}>
                  <div className="d-flex justify-content-between p-4 pb-2">
                    <h2 className="h2 text-center mb-2">
                      {intl.formatMessage({ id: "app.planModal.title" })}
                    </h2>
                    <div className="d-flex">
                      <InputWembii
                        mb={false}
                        type="select"
                        className="mr-2"
                        value={type}
                        onChange={(value) => handleChangeType(value)}
                      >
                        <option value="annual">
                          {intl.formatMessage({ id: "app.plan.annual" })}
                        </option>
                        <option value="monthly">
                          {intl.formatMessage({ id: "app.plan.monthly" })}
                        </option>
                      </InputWembii>
                      <InputWembii
                        mb={false}
                        type="select"
                        value={coin}
                        onChange={(value) => handleChangeCoin(value)}
                      >
                        {Object.values(coinOptions).map(({ id, label }) => (
                          <option key={id} value={"" + id}>
                            {label}
                          </option>
                        ))}
                      </InputWembii>
                    </div>
                  </div>
                  <UpdateLoadingCard
                    className="position-absolute-center"
                    loadingId={PLAN_LOADING}
                    text="Actualizando Planes"
                  />
                  {!isLoading(PLAN_LOADING) && (
                    <>
                      <Nav tabs className="justify-content-center flex-nowrap">
                        {plans.map((plan) => (
                          <NavItem key={plan.id}>
                            <NavLink
                              className={cn("pointer", { active: activeTab === plan.id })}
                              onClick={() => {
                                toggle(plan.id);
                              }}
                            >
                              <PlanBadge
                                plan={plan}
                                show={true}
                              />
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                      <div className="my-3">
                        {selectedPlan &&
                          Object.keys(selectedPlan.rules).map((key) => (
                            <PlanRule
                              key={key}
                              name={key}
                              value={`${
                                (selectedPlan.rules as Record<string, any>)[key]
                              }`}
                            />
                          ))
                        }
                        <Row className="my-3 px-2">
                          <Col xs="12" md="8" className="m-0 h5 text-black-50 font-weight-bold">
                            {intl.formatMessage({ id: "app.plan.price" })}
                          </Col>
                          <Col xs="12" md="4" className="m-0 h5 text-center">
                            <span className="font-weight-bold">{price}</span>
                            {coin === coinOptions.CLP.id && (
                              <span className="ml-2 text-muted">{intl.formatMessage({ id: "app.plan.iva" })}</span>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <UpdateLoadingCard
                        loadingId={UPGRADE_PLAN_LOADING}
                        text="Cambiando Subscripción"
                      />
                      <UpdateLoadingCard
                        loadingId={PLAN_COUPON_LOADING}
                        text="Buscando coupon"
                      />
                      <UpdateLoadingCard
                        loadingId={CARD_CHECK_LOADING}
                        text="Verificando tarjeta"
                      />
                      {showUpgradeOptions && (
                        <div className="d-flex flex-column">
                          {/* {coin === coinOptions.CLP.id && (
                            <div>
                              <CheckboxWembii
                                label={intl.formatMessage({ id: "app.planModal.chileBank" })}
                                checked={payWithBank}
                                onChange={setPayWithBank}
                              />

                              {payWithBank && (
                                <Row className="mb-3">
                                  <Col>
                                    <InputWembii
                                      label={intl.formatMessage({ id: "app.planModal.insertCard" })}
                                      mb={false}
                                      value={cardTxt}
                                      onChange={setCardTxt}
                                    />
                                  </Col>
                                  <Col className="d-flex align-items-end">
                                    {checkedCard && cardValid && <FontAwesomeIcon
                                      color="#23f0c7"
                                      size="2x"
                                      className="mx-3 mb-2"
                                      icon={["fas", "check"]}
                                    />}
                                    {checkedCard && !cardValid && <FontAwesomeIcon
                                      color="#ef767a"
                                      size="2x"
                                      className="mx-3 mb-2"
                                      icon={["fas", "times"]}
                                    />}
                                  </Col>
                                </Row>
                              )}
                            </div>
                          )} */}
                          {!payWithBank && (
                            <Row className="mb-3">
                              <Col>
                                <InputWembii
                                  label="Coupon de descuento"
                                  value={couponTxt}
                                  mb={false}
                                  onChange={setCouponTxt}
                                />
                              </Col>
                              <Col className="d-flex align-items-end">
                                {checkedCoupon && <CouponInfo coupon={coupon} />}
                              </Col>
                            </Row>
                          )}

                          <div className="d-flex justify-content-end">
                            <Button onClick={handleClickUpgrade}>
                              {intl.formatMessage({ id: "app.plan.subscribe" })}
                            </Button>
                          </div>
                        </div>
                      )}
                      {showCancelOptions && (
                        <div className="d-flex justify-content-end">
                          <Button onClick={handleClickCancel}>
                            {intl.formatMessage({ id: "app.plan.cancel" })}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </PanelWembii>
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
    </Layout>
  );
};
