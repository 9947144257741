import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LAYOUT_LOADING, MAIN_LAYOUT_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { sessionApiService } from "./services/api";
import { sessionClearAction } from "./store/actions";
import {
  sessionFailureAction,
  sessionInitAction,
  sessionSuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import {
  refreshTokenSelector,
  tokenSelector,
  userSelector,
} from "./store/selector";

export function useSessionCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const refreshToken = useSelector(refreshTokenSelector);
  const user = useSelector(userSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const postSession = useCallback(
    async (email: string, password: string) => {
      try {
        dispatch(sessionInitAction());
        turnOnLoading(MAIN_LAYOUT_LOADING);
        const session = await sessionApiService.postSession(email, password);
        dispatch(sessionSuccessAction({ session }));
        turnOnLoading(LAYOUT_LOADING);
        turnOffLoading(MAIN_LAYOUT_LOADING, 2500);
      } catch (error: any) {
        turnOffLoading(MAIN_LAYOUT_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error54" }));
        dispatch(sessionFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    postSession,
    logout: () => dispatch(sessionClearAction()),
    token,
    refreshToken,
    user,
  };
}
