import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import {
  CONTACT_COMPANY_BOARD_MODAL,
  CONTACT_COMPANY_EXPAND_EDIT_FORM,
  DELETE_BOARD_ITEM_MODAL,
} from "../../../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useBoardCore } from "../../../../cores/boards";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import AreYouSure from "../../AreYouSure";
import Avatar from "../../Avatar";
import { useContactCore } from "../../../../cores/contacts";
import CompanyList from "../../contacts/modal/CompanyList";
import { CompanyBoard } from "../../../../cores/boards/interfaces/api/get-card";
import EditCompanyForm from "../../contactCompanies/modal/EditCompanyForm";
import { truncateText } from "../../../utils/stringUtils";
import { useIntl } from "react-intl";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";

interface Props {
  canModify: boolean;
  board: string;
}

export default function CompanyContainer({ canModify, board }: Props) {
  const intl = useIntl();
  const { company } = useMeCore();
  const { card, postCompany, deleteCompany, getCard } = useBoardCore();
  const { getCompanies, getCompany, companyFilters } = useContactCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const openCompanies = useCallback(() => {
    getCompanies(company!.id, companyFilters);

    turnOnModal(
      CONTACT_COMPANY_BOARD_MODAL,
      card && company && (
        <CompanyList
          assignments={card?.companies!}
          onAssign={(boardCompany) => {
            postCompany(
              company?.id!,
              board,
              card?.list!,
              card?.id!,
              boardCompany.id,
              () => {
                getCard(company?.id!, board, card?.list!, card?.id!);
              }
            );
            turnOffModal(CONTACT_COMPANY_BOARD_MODAL);
          }}
        />
      ),
      Position.LEFT
    );
  }, [
    company,
    board,
    card,
    companyFilters,
    turnOnModal,
    turnOffModal,
    postCompany,
    getCompanies,
    getCard,
  ]);

  const onShowing = useCallback(
    (boardCompany: CompanyBoard) => {
      if (canModify) {
        getCompany(company?.id!, boardCompany.id);
        turnOnModal(
          CONTACT_COMPANY_EXPAND_EDIT_FORM,
          <EditCompanyForm
            isUnassignedButton={true}
            onDelete={() => {
              turnOnModal(
                DELETE_BOARD_ITEM_MODAL,
                <AreYouSure
                  message={`Está seguro de querer desasignar la empresa ${boardCompany.name}`}
                  onSubmit={() => {
                    deleteCompany(
                      company?.id!,
                      board,
                      card?.list!,
                      card?.id!,
                      boardCompany.id,
                      () => {
                        getCard(company?.id!, board, card?.list!, card?.id!);
                      }
                    );
                    turnOffModal(DELETE_BOARD_ITEM_MODAL);
                    turnOffModal(CONTACT_COMPANY_EXPAND_EDIT_FORM);
                  }}
                  onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
                />,
                Position.LEFT
              );
            }}
            onUpdate={() => {
              turnOffModal(CONTACT_COMPANY_EXPAND_EDIT_FORM);
              getCard(company?.id!, board, card?.list!, card?.id!);
            }}
          />,
          Position.LEFT
        );
      }
    },
    [
      company,
      canModify,
      board,
      card,
      turnOnModal,
      turnOffModal,
      deleteCompany,
      getCompany,
      getCard,
    ]
  );

  return (
    <div>
      <div className="d-flex flex-wrap align-items-center">
        {card?.companies.map((company) => (
          <div
            key={company.id}
            className="text-dark-50 font-weight-bold pointer m-1 px-2 border border-primary rounded-pill"
            onClick={() => onShowing(company)}
          >
            {truncateText(company.name)}
          </div>
        ))}
        {!canModify && card?.companies.length === 0 && (
          <span className="text-muted">
            {intl.formatMessage({ id: "app.crmBusiness.noCompanies" })}
          </span>
        )}
        {canModify && (
          <Avatar
            className="text-dark-50 pointer m-1"
            onClick={openCompanies}
            icon={
              <FontAwesomeIcon
                icon={["fas", "plus"]}
                size="1x"
                color={COLOR_PRIMARY}
              />
            }
          />
        )}
      </div>
    </div>
  );
}
