import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { TwitterProfile } from "../interface/api/get-twitter-profiles";
import { TwitterSetting } from "../interface/api/post-twitter-bind";

export const postUnbindTwitterInitAction = createAction(
  "[Twitter] Unbind Twitter Init"
);
export const postUnbindTwitterSuccessAction = createAction(
  "[Twitter] Unbind Twitter Success"
);
export const postUnbindTwitterFailureAction = createAction(
  "[Twitter] Unbind Twitter Failure",
  props<{ error: string }>()
);

export const postBindTwitterInitAction = createAction(
  "[Twitter] Bind Twitter Init"
);
export const postBindTwitterSuccessAction = createAction(
  "[Twitter] Bind Twitter Success",
  props<{ settings: TwitterSetting }>()
);
export const postBindTwitterFailureAction = createAction(
  "[Twitter] Bind Twitter Failure",
  props<{ error: string }>()
);

export const getTwitterInitAction = createAction(
  "[Twitter] Get Twitter Settings Init"
);
export const getTwitterSuccessAction = createAction(
  "[Twitter] Get Twitter Settings Success",
  props<{ settings: TwitterSetting }>()
);
export const getTwitterFailureAction = createAction(
  "[Twitter] Get Twitter Settings Failure",
  props<{ error: string }>()
);

export const getTwitterLoginInitAction = createAction(
  "[Twitter] Get Twitter Login Init"
);
export const getTwitterLoginSuccessAction = createAction(
  "[Twitter] Get Twitter Login Success",
  props<{ url: string }>()
);
export const getTwitterLoginFailureAction = createAction(
  "[Twitter] Get Twitter Login Failure",
  props<{ error: string }>()
);

export const postTwitterSocialPostInitAction = createAction(
  "[Twitter] Social Post Init"
);
export const postTwitterSocialPostSuccessAction = createAction(
  "[Twitter] Social Post Success"
);
export const postTwitterSocialPostFailureAction = createAction(
  "[Twitter] Social Post Failure",
  props<{ error: string }>()
);

export const putTwitterSocialPostInitAction = createAction(
  "[Twitter] Social Put Init"
);
export const putTwitterSocialPostSuccessAction = createAction(
  "[Twitter] Social Put Success"
);
export const putTwitterSocialPostFailureAction = createAction(
  "[Twitter] Social Put Failure",
  props<{ error: string }>()
);

export const getTwitterProfilesInitAction = createAction(
  "[Twitter] Get Twitter Profiles Init"
);
export const getTwitterProfilesSuccessAction = createAction(
  "[Twitter] Get Twitter Profiles Success",
  props<{ profiles: TwitterProfile[] }>()
);
export const getTwitterProfilesFailureAction = createAction(
  "[Twitter] Get Twitter Profiles Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindTwitterInitAction,
  postUnbindTwitterSuccessAction,
  postUnbindTwitterFailureAction,
  postBindTwitterInitAction,
  postBindTwitterSuccessAction,
  postBindTwitterFailureAction,
  getTwitterInitAction,
  getTwitterSuccessAction,
  getTwitterFailureAction,
  getTwitterLoginInitAction,
  getTwitterLoginSuccessAction,
  getTwitterLoginFailureAction,
  postTwitterSocialPostInitAction,
  postTwitterSocialPostSuccessAction,
  postTwitterSocialPostFailureAction,
  putTwitterSocialPostInitAction,
  putTwitterSocialPostSuccessAction,
  putTwitterSocialPostFailureAction,
  getTwitterProfilesInitAction,
  getTwitterProfilesSuccessAction,
  getTwitterProfilesFailureAction,
});

export type TwitterApiAction = typeof actions;
