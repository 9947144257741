import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNotificationCore } from "../../cores/notifications";
import NotificationContainer from "../component/notifications/NotificationContainer";
import { useIntl } from "react-intl";
import { useMeCore } from "../../cores/me";
import { Container } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";

const Notifications = () => {
  const [hasMore, setHasMore] = useState(true);
  const { company } = useMeCore();
  const intl = useIntl();
  const {
    notifications,
    putReadNotifications,
    clearNotifications,
    getNotifications,
  } = useNotificationCore();
  const onReadNotifications = useCallback(() => {
    putReadNotifications(company?.id!);
  }, [putReadNotifications, company]);

  useEffect(() => {
    if (notifications.length > 0) {
      clearNotifications();
    }
    getNotifications(company?.id!, 1);
  }, []);
  return (
    <Container className="h-100 overflow-auto pb-4 bg-light rounded-5 shadow-sm ">
      {notifications.length ? (
        <>
          <h3 className="py-4 my-auto mx-auto text-center">
            {intl.formatMessage({ id: "app.editForm.NotificationTitle" })}
          </h3>
          <span
            className="d-flex align-items-center  mr-3 mb-2 hover-color-danger justify-content-end"
            onClick={onReadNotifications}
          >
            <FontAwesomeIcon
              size="1x"
              className="m-1"
              icon={["fas", "check"]}
            />
            {intl.formatMessage({
              id: "app.navbar.readAllNotifications",
            })}
          </span>
          <InfiniteScroll
            pageStart={0}
            loadMore={async (page) => {
              const totalDocs = await getNotifications(company?.id!, page);
              setHasMore(totalDocs! !== 0);
            }}
            hasMore={hasMore}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
            useWindow={false}
          >
            {notifications.map((notification, index) => (
              <NotificationContainer
                unread={notification.status === 0}
                key={index}
                notification={notification}
              />
            ))}{" "}
          </InfiniteScroll>
        </>
      ) : (
        <div className="w-100 d-flex  h-100 text-muted text-center">
          <h3 className="py-4 my-auto mx-auto text-center">
            {intl.formatMessage({ id: "app.navbar.notNotification" })}
          </h3>
        </div>
      )}
    </Container>
  );
};

export default Notifications;
