import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { ContactSourceOptions } from "../../../../cores/contacts/config";
import { useContactCore } from "../../../../cores/contacts";
import { AutomationCondition } from "../../../../cores/automations/interfaces/api/get-automation";
import { ConditionOperatorOptions } from "../../../../cores/automations/config";
import { LabelWembii } from "../../LabelWembii";
import { ContactSourceBadge } from "../../contacts/ContactSource";
import { ContactSource } from "../../../../cores/contacts/enum/ContactSource.enum";

interface Props {
  isFirst: Boolean;
  condition: AutomationCondition;
  onClick: () => void;
}

export default function Condition(props: Props) { 
  const intl = useIntl();
  const { condition, isFirst, onClick } = props;
  const { tags } = useContactCore();

  const tag = useMemo(() => 
    [
      ...ContactSourceOptions,
      ...tags.map((tag) => ({ value: tag.nameId, label: tag.name }))
    ].find((tag) => tag.value === condition.value)
  , [tags, condition]);

  const operator = useMemo(() => 
    ConditionOperatorOptions.find((operator) => operator.value === condition.operator)
  , [condition]);

  return (
    <div>
      {!isFirst && (
        <div className="font-weight-bold text-center">
          {intl.formatMessage({ id: operator?.label })}
        </div>
      )}

      <div
        className="d-flex flex-column justify-content-center align-items-center border rounded pointer p-2 my-1"
        onClick={onClick}
      >
        <LabelWembii>
          {intl.formatMessage({ "id": "app.automations.condition.contact.source" })}
        </LabelWembii>
        <ContactSourceBadge source={tag?.value as ContactSource} />
      </div>
    </div>
  );
}