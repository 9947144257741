import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useContactCore } from "../../../../cores/contacts";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { useIntl } from "react-intl";
import { Company } from "../../../../cores/contacts/interfaces/api/get-company";
import { CONTACT_COMPANY_EXPAND_CREATION_FORM } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { PaginationWemmbii } from "../../pagination/Pagination";
import { CompanyBoard } from "../../../../cores/boards/interfaces/api/get-card";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";

interface Props {
  assignments: CompanyBoard[];
  onAssign: (arg0: Company) => void;
}

const SearchOptions = [
  { value: "name__p", label: "app.contacts.companies.name" },
  { value: "rut__p", label: "app.contacts.companies.rut" },
  { value: "country__p", label: "app.contacts.companies.country" },
  { value: "city__p", label: "app.contacts.companies.city" },
  { value: "phone__p", label: "app.contacts.companies.phone" },
  { value: "website__p", label: "app.contacts.companies.website" }
];

export default function CompanyList(props: Props) {
  const intl = useIntl();
  const { assignments, onAssign } = props;
  const { getCompanies, setCompanyFilters, companies, companyFilters, customFields } = useContactCore();
  const { company } = useMeCore();
  const { turnOnModal } = useGlobalCore();
  const [debounceSearchText, setDebounceSearchText] = useState("");
  const [searchOption, setSearchOption] = useState("name__p");
  const [search, setSearch] = useState("");

  const debouncedSearch = useRef(
    _.debounce((search) => setSearch(search), 1000)
  ).current;

  useEffect(() => {
    setCompanyFilters({
      page: 1,
      perPage: 10,
      owner: "",
      [searchOption]: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchOption, setCompanyFilters]);

  useEffect(() => {
    debouncedSearch(debounceSearchText);
  }, [debounceSearchText, debouncedSearch]);

  useEffect(() => {
    getCompanies(company!.id, companyFilters);
  }, [company, companyFilters, getCompanies]);

  const onClickCreateCompany = useCallback(() => {
    turnOnModal(CONTACT_COMPANY_EXPAND_CREATION_FORM, true, Position.LEFT);
  }, [turnOnModal]);

  const isCompanyAssigned = useCallback((company) => 
    assignments.some((assignment: CompanyBoard) =>
      assignment.id === company.id
    )
  , [assignments]);

  const changeFilters = useCallback((field) => {
    setCompanyFilters({ ...companyFilters, ...field });
  }, [companyFilters, setCompanyFilters]);

  const searchOptions = useMemo(() => [
    ...SearchOptions.map((option) => ({
      value: option.value,
      label: intl.formatMessage({id: option.label})
    })),
    ...customFields.map((field) => ({ value: field.id, label: field.name }))
  ], [customFields, intl]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 py-5">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.contacts.companies.title" })}
            </h2>

            <div className="d-flex justify-content-between align-items-center">
              <InputWembii
                label={intl.formatMessage({ id: "app.contacts.search" })}
                placeholder={intl.formatMessage({ id: "app.contacts.search1" })}
                type="text"
                onChange={(v) => setDebounceSearchText(v)}
                value={debounceSearchText}
                style={{ borderRadius: "0px 10px 10px 0px" }}
                prepend={
                  <InputWembii
                    type="select"
                    onChange={(v) => setSearchOption(v)}
                    value={searchOption}
                    mb={false}
                    style={{
                      borderRadius: "10px 0px 0px 10px",
                      maxWidth: "125px"
                    }}
                  >
                    {searchOptions.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </InputWembii>
                }
              />
              <Button
                className="whiteButton shadow m-0"
                onClick={onClickCreateCompany}
              >
                {intl.formatMessage({ id: "app.contacts.companies.createCompany" })}
              </Button>
            </div>
            <div className="d-flex justify-content-start justify-content-lg-end">
              <PaginationWemmbii
                page={companyFilters.page}
                setPage={(page) => changeFilters({ page })}
                perPage={companyFilters.perPage}
                setPerPage={(perPage) => changeFilters({ perPage })}
                disabledPages={companies.length < companyFilters.perPage}
              />
            </div>

            {companies.length === 0 && (
              <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center text-muted">
                <FontAwesomeIcon
                  icon={["fas", "building"]}
                  size="5x"
                  color="gray"
                />
                {intl.formatMessage({ id: "app.contacts.companies.notCompanies" })}
              </div>
            )}

            {companies.map((company) => (
              <div
                className="d-flex flex-row my-2 neu-in py-2 px-3"
                key={company.id}
              >
                <div className="text-center flex-grow-1 align-items-center justify-content-center d-flex flex-column">
                  <small>{company.name}</small>
                  {company.sector && <small>{company.sector}</small>}
                </div>
                {!isCompanyAssigned(company) && (
                  <Button
                    className="ml-auto"
                    color="primary"
                    onClick={() => onAssign(company)}
                  > 
                    {intl.formatMessage({ id: "app.contacts.companies.role.assign" })}
                  </Button>
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
