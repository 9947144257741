import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FACEBOOK_COLOR } from "../../../../constants/socialColors.constant";

export interface IconProps {
  active?: boolean;
  color?: string;
  className?: string;
  size?: SizeProp;
}

export const FacebookIcon = (props: IconProps) => {
  const { active, className, size, color } = props;
  return (
    <FontAwesomeIcon
      style={{
        color: color || (active ? FACEBOOK_COLOR : "gray"),
      }}
      icon={["fab", "facebook"]}
      className={className}
      size={size}
    />
  );
};
