import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { useFacebookFormCore } from "../../cores/facebookForm";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import FormQuestions from "../component/forms/facebook/modal/FormQuestions";
import LeadCard from "../component/forms/facebook/LeadCard";
import { ViewFormOptions } from "../component/forms/facebook/ViewFormOptions";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import { FACEBOOK_FORM_LEADS_LOADING } from "../../constants/loading.constant";
import { useIntl } from "react-intl";
import { FACEBOOK_VIEW_FORM } from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { View } from "../../cores/facebookForm/constants/view";
import { NoData } from "../component/NoData";

export default function ViewFacebookForm() {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const { turnOffModal, turnOnModal, getModalVisibility, isModal } = useGlobalCore();
  const { company, socialSettings, companyRole, isAdmin, isReseller } = useMeCore();
  const { facebookForm, view, getFacebookForm } = useFacebookFormCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    if (
      !socialSettings?.facebook || (
        !companyRole?.forms &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [goToSocial, socialSettings, companyRole, isAdmin, isReseller]);

  const toggleSideModal = useCallback(() => {
    turnOnModal(FACEBOOK_VIEW_FORM, true);
  }, [turnOnModal]);

  useEffect(() => {
    getFacebookForm(company?.id!, id);
  }, [getFacebookForm, company, id]);

  return (
    <Layout>
      <Header2
        title={facebookForm && facebookForm.form?.name!}
        rightChildren={
          <ViewFormOptions form={facebookForm.form!} onBack={toggleSideModal} />
        }
      />
      <Content>
        <UpdateLoadingCard
          loadingId={FACEBOOK_FORM_LEADS_LOADING}
          text={intl.formatMessage({ id: "app.facebookForm.updatingAnswer" })} 
        />
        <Container>
          {facebookForm ? (
            <Row>
              {facebookForm.leads.map((lead) => (
                <Col
                  md={view === View.GRID ? '4' : '12'}
                  sm={view === View.GRID ? '6' : '12'}
                  xs="12"
                  key={lead.id}
                  className="mb-4"
                >
                  <LeadCard facebookFormLead={lead} questions={facebookForm.form?.questions!} />
                </Col>
              ))}
            </Row>
          ) : (
            <NoData
              image="generic3"
              text={intl.formatMessage({ id: "app.facebookForm.notForm" })} 
              horizontal
            />
          )}
        </Container>
      </Content>
      <SideModal
        active={isModal(FACEBOOK_VIEW_FORM)}
        visible={getModalVisibility(FACEBOOK_VIEW_FORM)}
        onClose={() => {
          turnOffModal(FACEBOOK_VIEW_FORM);
        }}
      >
        <FormQuestions questions={facebookForm.form?.questions!} />
      </SideModal>
    </Layout>
  );
}
