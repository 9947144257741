import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Badge } from "reactstrap";
import { AutomationAction } from "../../../../cores/automations/interfaces/api/get-automation";
import { NewOpportunityActionBody } from "../../../../cores/automations/interfaces/OpportunityAction";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import { getFirstLetters } from "../../../utils/stringUtils";
import Avatar from "../../Avatar";
import { LabelWembii } from "../../LabelWembii";
import { TooltipWembii } from "../../TooltipWembii";

interface Props {
  action: AutomationAction;
}

export default function NewOpportunityAction(props: Props) { 
  const intl = useIntl();
  const { action } = props;

  const board = useMemo(() => 
    (action.body as NewOpportunityActionBody).board
  , [action]);

  const list = useMemo(() => 
    (action.body as NewOpportunityActionBody).list
  , [action]);

  const tags = useMemo(() => 
    (action.body as NewOpportunityActionBody).tags
  , [action]);

  const assignments = useMemo(() => 
    (action.body as NewOpportunityActionBody).assignments
  , [action]);

  return (
    <>
      <LabelWembii>
        {intl.formatMessage({ "id": "app.sidebar.crm.business" })}
      </LabelWembii>
      <div className="text-muted">
        {board.name}
        {board.deleted && (
          <Badge color="warning" className="text-white ml-2">
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              size="1x"
              className="mr-1"
            />
            {intl.formatMessage({ id: "app.automations.automation.warning.deleted" })}
          </Badge>
        )}
      </div>

      <LabelWembii>
        {intl.formatMessage({ "id": "app.crmBusiness.list" })}
      </LabelWembii>
      <div className="text-muted">
        {list.name}
        {list.deleted && (
          <Badge color="warning" className="text-white ml-2">
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              size="1x"
              className="mr-1"
            />
            {intl.formatMessage({ id: "app.automations.automation.warning.deleted" })}
          </Badge>
        )}
      </div>

      {tags.length > 0 && (
        <>
          <LabelWembii>
            {intl.formatMessage({ "id": "app.activity.tags.title" })}
          </LabelWembii>
          <div className="d-flex flex-wrap align-items-center">
            {tags.map((tag) => (
              <span key={tag.id}>
                <Badge
                  pill
                  style={{ backgroundColor: tag.backgroundColor }}
                  className="text-left px-3 m-1"
                >
                  <span className="h6 mb-0 font-weight-bold" style={{ color: tag.textColor }}>
                    {tag.name}
                  </span>
                </Badge>
                {tag.deleted && (
                  <Badge color="warning" className="text-white ml-2">
                    <FontAwesomeIcon
                      icon={["fas", "exclamation-triangle"]}
                      size="1x"
                      className="mr-1"
                    />
                    {intl.formatMessage({ id: "app.automations.automation.warning.deleted" })}
                  </Badge>
                )}
              </span>
            ))}
          </div>
        </>
      )}

      {assignments.length > 0 && (
        <>
          <LabelWembii>
            {intl.formatMessage({ "id": "app.activity.toAssign" })}
          </LabelWembii>
          <div className="d-flex flex-wrap">
            {assignments.map((assignment) => (
              <div key={assignment.id}>
                <Avatar
                  id={`user-modal-${assignment.id}`}
                  active={assignment.status === UserStatus.ACTIVE.id}
                  className="text-dark-50 font-weight-bold pointer m-1"
                  label={getFirstLetters(`${assignment.lastName} ${assignment.firstName}`)}
                  image={assignment.avatarUrl}
                />
                <TooltipWembii id={`user-modal-${assignment.id}`} text={`${assignment.firstName} ${assignment.lastName}`} />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}