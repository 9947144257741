export function props<T>(): T {
    return {} as T;
}

export function createAction<U, T extends string>(type: T, x?: U) {
    const functionWithTypeProperty = (payload: U = {} as U) => {
        return {
            type,
            ...payload,
        };
    };
    return Object.defineProperty(functionWithTypeProperty, "type", {
        value: type,
        writable: false,
    }) as typeof functionWithTypeProperty & { type: T };
}

export function joinActions<C extends { [key: string]: (...args: any) => any }>(c: C): ReturnType<C[keyof C]> {
    return undefined!;
}
