import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { SocialTemplate } from "../interface/api/get-social-templates";
import { OpenAiSetting } from "../interface/api/post-openai-bind";
import {
  getOpenAiFailureAction,
  getOpenAiInitAction,
  getOpenAiSuccessAction,
  postBindOpenAiFailureAction,
  postBindOpenAiInitAction,
  postBindOpenAiSuccessAction,
  postUnbindOpenAiFailureAction,
  postUnbindOpenAiInitAction,
  postUnbindOpenAiSuccessAction,
  OpenAiApiAction,
  getSocialTemplatesFailureAction,
  getSocialTemplatesInitAction,
  getSocialTemplatesSuccessAction,
} from "./api-actions";
import { OpenAiAction, setShowTemplatesAction } from "./actions";

export interface OpenAiState {
  settings: OpenAiSetting | null;
  socialTemplates: {
    ids: string[];
    entities: Record<string, SocialTemplate>;
  };
  showTemplates: boolean;
  error: string;
}

const initialState: OpenAiState = {
  settings: null,
  socialTemplates: {
    ids: [],
    entities: {},
  },
  showTemplates: false,
  error: "",
};

const socialTemplatesAdapter = createEntityAdapter<SocialTemplate>(
  (payload) => payload.title
);

export default produce(
  (
    draft: OpenAiState = initialState,
    action: OpenAiApiAction | OpenAiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindOpenAiSuccessAction.type:
      case getOpenAiSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindOpenAiFailureAction.type:
      case postBindOpenAiFailureAction.type:
      case getOpenAiFailureAction.type:
      case postUnbindOpenAiSuccessAction.type:
        draft.settings = null;
        return draft;
      case getSocialTemplatesSuccessAction.type:
        socialTemplatesAdapter.addMany(action.templates, draft.socialTemplates);
        draft.showTemplates = true;
        return draft;
      case setShowTemplatesAction.type:
        draft.showTemplates = action.showTemplates;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindOpenAiInitAction.type:
      case postUnbindOpenAiInitAction.type:
      case getOpenAiInitAction.type:
      case getSocialTemplatesFailureAction.type:
      case getSocialTemplatesInitAction.type:
      default:
        return draft;
    }
  }
);
