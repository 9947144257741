export const CampaignGoals = {
  LINK_CLICKS: { id: "LINK_CLICKS", name: "app.advertisements.type.traffic" },
  APP_INSTALLS: { id: "APP_INSTALLS", name: "Instalación de la app" },
  BRAND_AWARENESS: { id: "BRAND_AWARENESS", name: "Reconocimiento de marca" },
  CONVERSIONS: { id: "CONVERSIONS", name: "Conversiones" },
  EVENT_RESPONSES: { id: "EVENT_RESPONSES", name: "EVENT_RESPONSES" },
  LEAD_GENERATION: { id: "LEAD_GENERATION", name: "app.advertisements.type.leadGeneration" },
  LOCAL_AWARENESS: { id: "LOCAL_AWARENESS", name: "Reconocimiento de local" },
  MESSAGES: { id: "MESSAGES", name: "Mensajes" },
  OFFER_CLAIMS: { id: "OFFER_CLAIMS", name: "OFFER_CLAIMS" },
  PAGE_LIKES: { id: "PAGE_LIKES", name: "Me gusta a la página" },
  POST_ENGAGEMENT: { id: "POST_ENGAGEMENT", name: "Interacciones" },
  PRODUCT_CATALOG_SALES: { id: "PRODUCT_CATALOG_SALES", name: "Ventas del catálogo" },
  REACH: { id: "REACH", name: "Alcance" },
  STORE_VISITS: { id: "STORE_VISITS", name: "Tráfico en el negocio" },
  VIDEO_VIEWS: { id: "VIDEO_VIEWS", name: "Reproducción de video" }
};

export const getCampaignGoalById = (id: string) => Object.values(CampaignGoals)
  .find((goal) => goal.id === id);

export const getCampaignGoals = () => Object.values(CampaignGoals);