import { produce } from "immer";
import { User } from "../interfaces/models/user";
import { SessionAction, sessionClearAction } from "./actions";
import {
  SessionApiAction,
  sessionFailureAction,
  sessionInitAction,
  sessionSuccessAction,
} from "./api-actions";

export interface SessionState {
  token: string | null;
  refreshToken: string | null;
  user: User | null;
}

const initialState: SessionState = {
  token: null,
  refreshToken: null,
  user: null,
};

export default produce(
  (
    draft: SessionState = initialState,
    action: SessionApiAction | SessionAction
  ) => {
    switch (action.type) {
      case sessionSuccessAction.type:
        draft.token = action.session.token;
        draft.refreshToken = action.session.refreshToken;
        draft.user = action.session.user;
        return draft;
      case sessionClearAction.type:
        return initialState;
      case sessionInitAction.type:
      case sessionFailureAction.type:
      default:
        return draft;
    }
  }
);
