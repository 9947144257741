import { ContactSource } from "./enum/ContactSource.enum";

export const ContactSourceOptions = [
  {
    label: "app.source.manual",
    value: ContactSource.MANUAL,
  },
  {
    label: "app.source.wooCommerce",
    value: ContactSource.WOO_COMMERCE,
  },
  {
    label: "app.source.jumpSeller",
    value: ContactSource.JUMPSELLER,
  },
  {
    label: "app.source.webChat",
    value: ContactSource.CHAT,
  },
  {
    label: "app.source.whatsap",
    value: ContactSource.WHATSAPPCHAT,
  },
  {
    label: "app.source.form",
    value: ContactSource.FORM,
  },
  {
    label: "app.source.facebookForm",
    value: ContactSource.FACEBOOK_FORM,
  },
  {
    label:  "app.source.CRM",
    value: ContactSource.CRM,
  },
  {
    label:  "app.source.meeting",
    value: ContactSource.MEETING,
  },
];
