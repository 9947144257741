import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { RESELLER_LOADING } from "../../constants/loading.constant";
import { CREATE_EDIT_RESELLER_MODAL } from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useResellerCore } from "../../cores/reseller";
import { useRouterCore } from "../../cores/router";
import CreateEditResellerForm from "../component/resellers/CreateEditResellerForm";
import ResellerCard from "../component/resellers/ResellerCard";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";

export default function Resellers() {
  const intl = useIntl();
  const { getResellers, resellers } = useResellerCore();
  const {
    isModal,
    turnOffModal,
    turnOnModal,
    getModalContent,
    getModalVisibility,
  } = useGlobalCore();
  const { user } = useMeCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    if (!user?.userRole?.admin)
      goToSocial();
  }, [user, goToSocial]);

  useEffect(() => {
    getResellers();
  }, [getResellers]);

  return (
    <Layout> 
      <Header2
        title={intl.formatMessage({ id: "app.adminResellers.title" })}
        rightChildren={
          <>
            <Button
              className="whiteButton"
              onClick={() =>
                turnOnModal(
                  CREATE_EDIT_RESELLER_MODAL,
                  <CreateEditResellerForm
                    onSubmit={() => turnOffModal(CREATE_EDIT_RESELLER_MODAL)}
                  />
                )
              }
            >
              {intl.formatMessage({ id: "app.adminResellers.createResell" })}
            </Button>
          </>
        }
      />
      <Content>
        <UpdateLoadingCard
          loadingId={RESELLER_LOADING}
          text="Actualizando Resellers"
        />

        <Container fluid>
          <Row>
            {resellers.map((reseller) => (
              <Col
                xs="12"
                sm="6"
                md="4"
                lg="3"
                className="mb-4 d-flex justify-content-center"
                key={reseller.id}
              >
                <ResellerCard reseller={reseller} />
              </Col>
            ))}
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(CREATE_EDIT_RESELLER_MODAL)}
        visible={getModalVisibility(CREATE_EDIT_RESELLER_MODAL)}
        onClose={() => turnOffModal(CREATE_EDIT_RESELLER_MODAL)}
      >
        {getModalContent(CREATE_EDIT_RESELLER_MODAL)}
      </SideModal>
    </Layout>
  );
}
