import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import {
  SMTP_ME_GET_LOADING,
  SMTP_ME_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { SMTP_ME_BIND_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useMeCore } from "../../../../cores/me";
import { useSmtpCore } from "../../../../cores/smtp";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { SmtpIcon } from "../../social/Smtp/SmtpIcon";
import SmtpLoginForm from "../../social/Smtp/SmtpLoginForm";
import SocialRow from "./SocialRow";

export const SmtpBindRow = () => {
  const intl = useIntl();
  const { get, userSettings, unbind } = useSmtpCore();
  const { company, getMeUserSettings } = useMeCore();
  const { isLoading, turnOnModal, turnOffModal } = useGlobalCore();

  useEffect(() => {
    get(company!.id, true);
  }, [company, get]);

  const isActive = useMemo(
    (): boolean => !!userSettings?.email,
    [userSettings]
  );

  const handleBind = useCallback(async () => {
    turnOnModal(
      SMTP_ME_BIND_MODAL,
      <SmtpLoginForm
        asUser={true}
        onSuccess={() => {
          turnOffModal(SMTP_ME_BIND_MODAL);
          getMeUserSettings();
        }}
      />,
      Position.LEFT
    );
  }, [turnOnModal, getMeUserSettings, turnOffModal]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, true, () => {
      getMeUserSettings();
    });
  }, [company, unbind, getMeUserSettings]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Smtp,
      name: _.upperCase(SocialProviderName.Smtp),
      icon: <SmtpIcon size="2x" active={isActive} />,
      displayName: userSettings?.email,
    }),
    [userSettings, isActive]
  );

  return (
    <SocialRow
      social={socialProvider}
      bindLoading={isLoading(SMTP_ME_SYNC_LOADING)}
      getLoading={isLoading(SMTP_ME_GET_LOADING)}
      isSync={isActive}
    >
      <div className="w-100 px-1">
        <Button
          className="w-100 mx-auto"
          color="primary"
          size="sm"
          outline={isActive}
          onClick={!isActive ? handleBind : handleUnbind}
        >
          {!isActive
            ? intl.formatMessage({ id: "app.home.syncUp" })
            : intl.formatMessage({ id: "app.home.disconnect" })}
        </Button>
      </div>
    </SocialRow>
  );
};
