import React from "react";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { getFirstLetters } from "../../../utils/stringUtils";
import Avatar from "../../Avatar";
import { LabelWembii } from "../../LabelWembii";

export default function QuoteMeInfo() {
  const intl = useIntl();
  const { user, company } = useMeCore();

  return (
    <div className="d-flex flex-column">
      <LabelWembii>
        {intl.formatMessage({ id: "app.crmBusiness.quote.me" })}
      </LabelWembii>
      <div className="border d-flex align-items-center p-2">
        <Avatar
          className="text-dark-50 pointer mx-2"
          label={getFirstLetters(`${user?.firstName} ${user?.lastName}`)}
          image={user?.avatarUrl}
        />
        <div>
          <div>{user?.firstName} {user?.lastName}</div>
          {user?.email && <div>{user?.email}</div>}
          {user?.phoneNumber && <div>{user?.phoneNumber}</div>}
        </div>
      </div>

      <LabelWembii className="mt-2">
        {intl.formatMessage({ id: "app.crmBusiness.quote.myCompany" })}
      </LabelWembii>
      <div className="border d-flex align-items-center p-2">
        <Avatar
          className="text-dark-50 pointer mx-2"
          label={getFirstLetters(company?.name!)}
          image={company?.avatarUrl}
        />
        <div>
          <div>{company?.name}</div>
          {company?.phoneNumber && <div>{company?.phoneNumber}</div>}
          {company?.city && <div>{company?.city}</div>}
          {company?.country && <div>{company?.country}</div>}
        </div>
      </div>
    </div>
  );
}
