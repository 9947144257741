import React, { useCallback, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { CheckboxWembii } from "../../CheckboxWembii";
import { RadiobuttonWembii } from "../../RadiobuttonWembii";
import { Sign } from "../../../../cores/me/interfaces/api/get-meSign";
import ReactQuill from "react-quill";
import { UploadFileType } from "../../../../cores/utils";
import { useEmailCore } from "../../../../cores/email";

export default function SignEmailForm() {
  const intl = useIntl();
  const [name, setName] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [addSign, setAddSign] = useState(true);
  const [selectedSign, setSelectedSign] = useState("");
  const [signature, setSignature] = useState("");
  const {
    signs,
    sign,
    postMeSign,
    putMeSign,
    deleteMeSign,
    postMeSignImage,
    getMeSigns,
  } = useMeCore();
  const { message, setMessage } = useEmailCore();

  const ref = useRef<any>(null);

  const filesAllowed = useMemo(
    () => [UploadFileType.JPEG, UploadFileType.JPG, UploadFileType.PNG],
    []
  );
  
  const toggleAddSign = useCallback(() => {
    setAddSign(!addSign);
    
    if (!addSign) {
      setSelectedSign("");
      setName("");
      setIsDefault(false);
      setSignature("");
    }
  },[addSign]);

  const toggleDefault = useCallback(() => {
    setIsDefault(!isDefault);
  },[isDefault]);

  const onSuccessSign = useCallback(() => {
    setSelectedSign("");
    setName("");
    setIsDefault(false);
    setSignature("");
    setAddSign(true);
    getMeSigns();
  }, [getMeSigns]);

  const onDelete = useCallback(() => {
    deleteMeSign(selectedSign, onSuccessSign);  
  }, [deleteMeSign, onSuccessSign, selectedSign]);

  const onFormSubmit = useCallback((e: any) => {
    e.preventDefault();

    if (addSign) {
      postMeSign(
        {
          name,
          default: isDefault,
          sign: signature
        },
        onSuccessSign
      );
    } else {
      putMeSign(
        selectedSign, {
          name,
          default: isDefault,
          sign: signature
        },
        () => {
          onSuccessSign();
          if (sign && +sign.id === +selectedSign) {
            setMessage(message.replace(sign.sign, signature));
          }
        }
      );
    }
  }, [
    postMeSign,
    setMessage,
    putMeSign,
    onSuccessSign,
    selectedSign,
    name,
    isDefault,
    signature,
    addSign,
    message,
    sign
  ]);

  const disabled = useCallback(
    () => addSign && (!name || !signature),
    [addSign, name, signature]
  );

  const disabled2 = useCallback(
    () => {
      const sign = signs.find((sg) => +sg.id === +selectedSign);

      return !addSign && ((
        sign &&
        name === sign.name &&
        isDefault === sign.default &&
        signature === sign.sign
      ) || (
        !sign
      ));
    },
    [selectedSign, name, addSign, signature, isDefault, signs]
  );

  const disabled3 = useCallback(
    () => !addSign && !selectedSign,
    [addSign, selectedSign]
  );

  const onSelectingSign = useCallback((value: string) => {
    setSelectedSign(value);

    const sign = signs.find((sg) => +sg.id === +value);
    if (sign) {
      setName(sign.name);
      setIsDefault(sign.default);
      setSignature(sign.sign);
    } else {
      setName("");
      setIsDefault(false);
      setSignature("");
    }
  }, [setSelectedSign, signs]);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", filesAllowed.join(", "));
    input.click();
    input.onchange = async () => {
      if (input && input.files) {
        const editor = ref.current.getEditor();
        const data = await postMeSignImage(input.files[0])
        const unprivilegedEditor = ref.current.makeUnprivilegedEditor(editor);
        const selection = unprivilegedEditor.getSelection()
          ? unprivilegedEditor.getSelection().index
          : 0;
        editor.insertEmbed(selection, "image", data?.data.link);
      }
    };
  }, [postMeSignImage, filesAllowed]);

  const imageDropHandler = useCallback(async (imageDataUrl, type, imageData) => {
    if (filesAllowed.find((file) => file === type)) {
      const file = imageData.toFile();
      const editor = ref.current.getEditor();
      const data = await postMeSignImage(file)
      const unprivilegedEditor = ref.current.makeUnprivilegedEditor(editor);
      const selection = unprivilegedEditor.getSelection()
        ? unprivilegedEditor.getSelection().index
        : 0;
      editor.insertEmbed(selection, "image", data?.data.link);
    }
  }, [postMeSignImage, filesAllowed]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-3">
            <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
              {intl.formatMessage({ id: "app.sendEmail.sign" })}
            </h2>
            <Form className="px-2 h-50" onSubmit={onFormSubmit}>
              <div className="d-flex align-items-center justify-content-around">
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.sendEmail.sign.add" })}
                  checked={addSign}
                  onChange={toggleAddSign}
                />
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.sendEmail.sign.modify" })}
                  checked={!addSign}
                  onChange={toggleAddSign}
                />
              </div>
              <InputWembii
                type="select"
                onChange={onSelectingSign}
                value={selectedSign}
                disabled={addSign}
              >
                <option value="">
                  {intl.formatMessage({ id: "app.select.placeholder" })}
                </option>
                {signs.map((sign: Sign) => (
                  <option key={sign.id} value={sign.id}>
                    {sign.name}
                  </option>
                ))}
              </InputWembii>

              <hr />

              <div className="d-flex align-items-center justify-content-between">
                <InputWembii
                  label={intl.formatMessage({ id: "app.sendEmail.sign.name" })}
                  type="text"
                  name="name"
                  className="w-75"
                  required
                  placeholder={intl.formatMessage({ id: "app.sendEmail.sign.name" })}
                  value={name}
                  onChange={setName}
                />
                <CheckboxWembii
                  mb={false}
                  label={intl.formatMessage({ id: "app.sendEmail.sign.default" })}
                  checked={isDefault}
                  onChange={toggleDefault}
                />
              </div>

              <div className="pt-2 px-2 wembii-border-small">
                <ReactQuill
                  value={signature}
                  ref={ref}
                  className="textEditor"
                  onChange={setSignature}
                  modules={{
                    toolbar: {
                      container: [
                        ['bold', 'italic', 'underline','strike', 'blockquote'],
                        [{ 'align': [] }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'header': [] }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        ['link', 'image'],
                        ['clean']
                      ],
                      handlers: {
                        image: imageHandler
                      }
                    },
                    imageDropAndPaste: {
                      handler: imageDropHandler
                    },
                    imageActions: {},
                    imageFormats: {},
                  }}
                  formats={[
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'align',
                    'color', 'background',
                    'size',
                    'list', 'bullet',
                    'indent',
                    'script',
                    'link', 'image',
                    'clean',
                    'width', 'height'
                  ]}
                />
              </div>

              <div className="mt-3">
                <Button
                  color="primary"
                  block
                  disabled={disabled() || disabled2()}
                >
                  {intl.formatMessage({ id: "app.sendEmail.sign.save" })}
                </Button>
                {!addSign && (
                  <Button
                    className="my-1"
                    color="danger"
                    block
                    onClick={onDelete}
                    disabled={disabled3()}
                  >
                    {intl.formatMessage({ id: "app.sendEmail.sign.delete" })}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
