import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { LinkedInProfile } from "../interface/api/get-linkedin-profiles";
import { LinkedInSetting } from "../interface/api/post-linkedin-bind";
import {
  getLinkedInFailureAction,
  getLinkedInInitAction,
  getLinkedInProfilesFailureAction,
  getLinkedInProfilesInitAction,
  getLinkedInProfilesSuccessAction,
  getLinkedInSuccessAction,
  LinkedInApiAction,
  postBindLinkedInFailureAction,
  postBindLinkedInInitAction,
  postBindLinkedInSuccessAction,
  postUnbindLinkedInFailureAction,
  postUnbindLinkedInInitAction,
  postUnbindLinkedInSuccessAction,
  putLinkedInSocialPostFailureAction,
  putLinkedInSocialPostInitAction,
  putLinkedInSocialPostSuccessAction,
} from "./api-actions";

export interface LinkedInState {
  settings: LinkedInSetting | null;
  profiles: LinkedInProfile[];
  error: string;
}

const initialState: LinkedInState = {
  settings: null,
  profiles: [],
  error: "",
};

export default produce(
  (
    draft: LinkedInState = initialState,
    action: LinkedInApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindLinkedInSuccessAction.type:
      case getLinkedInSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindLinkedInFailureAction.type:
      case postBindLinkedInFailureAction.type:
      case getLinkedInFailureAction.type:
        draft.error = action.error;
        return draft;
      case postUnbindLinkedInSuccessAction.type:
        draft.settings = null;
        return draft;
      case getLinkedInProfilesSuccessAction.type:
        draft.profiles = action.profiles;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindLinkedInInitAction.type:
      case postUnbindLinkedInInitAction.type:
      case getLinkedInInitAction.type:
      case putLinkedInSocialPostInitAction.type:
      case putLinkedInSocialPostFailureAction.type:
      case putLinkedInSocialPostSuccessAction.type:
      case getLinkedInProfilesInitAction.type:
      case getLinkedInProfilesFailureAction.type:
      default:
        return draft;
    }
  }
);
