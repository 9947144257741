import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { LinkedInProfile } from "../interface/api/get-linkedin-profiles";
import { LinkedInSetting } from "../interface/api/post-linkedin-bind";

export const postUnbindLinkedInInitAction = createAction(
  "[Linked In] Unbind Linked In Init"
);
export const postUnbindLinkedInSuccessAction = createAction(
  "[Linked In] Unbind Linked In Success"
);
export const postUnbindLinkedInFailureAction = createAction(
  "[Linked In] Unbind Linked In Failure",
  props<{ error: string }>()
);

export const postBindLinkedInInitAction = createAction(
  "[Linked In] Bind Linked In Init"
);
export const postBindLinkedInSuccessAction = createAction(
  "[Linked In] Bind Linked In Success",
  props<{ settings: LinkedInSetting }>()
);
export const postBindLinkedInFailureAction = createAction(
  "[Linked In] Bind Linked In Failure",
  props<{ error: string }>()
);

export const getLinkedInInitAction = createAction(
  "[Linked In] Get Linked Settings In Init"
);
export const getLinkedInSuccessAction = createAction(
  "[Linked In] Get Linked Settings In Success",
  props<{ settings: LinkedInSetting }>()
);
export const getLinkedInFailureAction = createAction(
  "[Linked In] Get Linked Settings In Failure",
  props<{ error: string }>()
);

export const postLinkedInSocialPostInitAction = createAction(
  "[LinkedIn] Social Post Init"
);
export const postLinkedInSocialPostSuccessAction = createAction(
  "[LinkedIn] Social Post Success"
);
export const postLinkedInSocialPostFailureAction = createAction(
  "[LinkedIn] Social Post Failure",
  props<{ error: string }>()
);

export const putLinkedInSocialPostInitAction = createAction(
  "[LinkedIn] Social Put Init"
);
export const putLinkedInSocialPostSuccessAction = createAction(
  "[LinkedIn] Social Put Success"
);
export const putLinkedInSocialPostFailureAction = createAction(
  "[LinkedIn] Social Put Failure",
  props<{ error: string }>()
);

export const getLinkedInProfilesInitAction = createAction(
  "[Linked In] Get Linked In Profiles Init"
);
export const getLinkedInProfilesSuccessAction = createAction(
  "[Linked In] Get Linked In Profiles Success",
  props<{ profiles: LinkedInProfile[] }>()
);
export const getLinkedInProfilesFailureAction = createAction(
  "[Linked In] Get Linked In Profiles Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindLinkedInInitAction,
  postUnbindLinkedInSuccessAction,
  postUnbindLinkedInFailureAction,
  postBindLinkedInInitAction,
  postBindLinkedInSuccessAction,
  postBindLinkedInFailureAction,
  getLinkedInInitAction,
  getLinkedInSuccessAction,
  getLinkedInFailureAction,
  postLinkedInSocialPostInitAction,
  postLinkedInSocialPostSuccessAction,
  postLinkedInSocialPostFailureAction,
  putLinkedInSocialPostInitAction,
  putLinkedInSocialPostSuccessAction,
  putLinkedInSocialPostFailureAction,
  getLinkedInProfilesInitAction,
  getLinkedInProfilesSuccessAction,
  getLinkedInProfilesFailureAction,
});

export type LinkedInApiAction = typeof actions;
