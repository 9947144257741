import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import React from "react";
import { COLOR_PRIMARY } from "../../constants/theme";

interface Props {
  onAdding: (arg0: any) => void;
}

export const EmojiForm = ({ onAdding }: Props) => {
  return (
    <div className="p-2 h-100">
      <Picker
        style={{
          border: 0,
          boxShadow: "none",
          width: "auto",
          height: "100%",
          padding: "5px",
        }}
        color={COLOR_PRIMARY}
        showPreview={true}
        sheetSize={64}
        showSkinTones={true}
        onSelect={onAdding}
      />
    </div>
  );
};
