import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React, { ReactNode, useCallback, useMemo } from "react";
import { Label } from "reactstrap";
import { INSTAGRAM_COLOR } from "../../../constants/socialColors.constant";
import { ConversationContact } from "../../../cores/socialChat/interfaces/conversations.interface";
import { getFirstLetters } from "../../utils/stringUtils";
import Avatar from "../Avatar";
import { PanelWembii } from "../PanelWembii";
import {
  BOARD_CONTACTS_MODAL,
  CONTACT_EXPAND_CREATION_FORM,
} from "../../../constants/modal.constant";
import { ContactSource } from "../../../cores/contacts/enum/ContactSource.enum";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import CreateContactForm from "../contacts/modal/CreateContactForm";
import { useMeCore } from "../../../cores/me";
import { useGlobalCore } from "../../../cores/globals";
import { useContactCore } from "../../../cores/contacts";
import { useSocialChatCore } from "../../../cores/socialChat";

interface Props {
  color: string;
  type: string;
  contacts: ConversationContact[];
  avatarAction?: (arg0: ConversationContact) => void;
  actions?: ReactNode;
  companyId: string;
}

export default function PanelChatTitle(props: Props) {
  const { company } = useMeCore();
  const { updateConversationContact, conversationStatus } = useSocialChatCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { getContactTags } = useContactCore();
  const { color, type, contacts, actions, avatarAction } = props;
  const contactSocial =
    contacts[0].socials?.find((social) => social.socialName === type) ?? null;
  const getParticipantNames = useMemo(
    () => contacts.map((participant) => participant.name).join(", "),
    [contacts]
  );

  const onClickCreateContact = useCallback(() => {
    getContactTags(company?.id!);
    turnOnModal(
      CONTACT_EXPAND_CREATION_FORM,
      <CreateContactForm
        source={ContactSource.CHAT}
        preContactData={{
          externalReferenceId: contacts[0].id,
          name: getParticipantNames,
          reference: `Contacto proveniente de ${type}`,
          social: {
            socialName: type,
            socialUniqueId: (contacts[0].id ?? "").trim(),
            username: contacts[0].name,
          },
        }}
        onCreation={(contact) => {
          if (
            contact.socials![0].socialName === "facebook" ||
            contact.socials![0].socialName === "instagram"
          ) {
            updateConversationContact(
              {
                id: contact.id,
                name: contact.name,
                owners: contact.owners,
                avatarUrl: contacts[0].avatarUrl ?? "",
                email: contact.email,
                externalReferenceId: contact.externalReferenceId,
                from: contacts[0].from,
                state: `${contact.state}`,
                socials: contact.socials,
              },
              contact.socials![0].socialName
            );

            turnOffModal(BOARD_CONTACTS_MODAL);
          }
        }}
      />,
      Position.LEFT
    );
  }, [
    updateConversationContact,
    contacts,
    getParticipantNames,
    type,
    turnOffModal,
    turnOnModal,
    getContactTags,
    company?.id,
  ]);

  return (
    <PanelWembii
      shadow
      className="p-2 rounded-pill mb-3 w-100 bg-primary text-light"
    >
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex align-items-center w-100">
          {type === "facebook" && (
            <FontAwesomeIcon
              className=""
              icon={["fab", "facebook"]}
              size="lg"
              color="#ffffff"
            />
          )}
          {type === "instagram" && (
            <FontAwesomeIcon
              className=""
              icon={["fab", "instagram"]}
              size="lg"
              color={INSTAGRAM_COLOR}
            />
          )}
          {type === "whatsapp" && (
            <FontAwesomeIcon
              color="#ffffff"
              icon={["fab", "whatsapp"]}
              size="lg"
            />
          )}
          <Avatar
            className={cn("mx-2", {
              pointer: !!avatarAction && contactSocial?.socialUniqueId,
            })}
            label={getFirstLetters(contacts[0].name)}
            image={contacts[0].avatarUrl}
            backgroundColor={color}
            color={type === "instagram" ? "white" : "black"}
            width={"45px"}
            height={"45px"}
            style={{
              cursor:
                (type === "instagram" || type === "facebook") &&
                !contactSocial?.socialUniqueId
                  ? ""
                  : "pointer",
            }}
            onClick={
              (type === "instagram" || type === "facebook") &&
              !contactSocial?.socialUniqueId
                ? () => null
                : () => avatarAction?.(contacts[0])
            }
          />
          <Label
            onClick={
              (type === "instagram" || type === "facebook") &&
              !contactSocial?.socialUniqueId
                ? () => null
                : () => avatarAction?.(contacts[0])
            }
            className="font-weight-bold m-0 d-none d-md-block"
            size="lg"
            style={{
              cursor:
                (type === "instagram" || type === "facebook") &&
                !contactSocial?.socialUniqueId
                  ? ""
                  : "pointer",
            }}
          >
            {getParticipantNames}
          </Label>
        </div>
        {actions && actions}

        {(type === "facebook" || type === "instagram") &&
          contactSocial === null && (
            <button
              onClick={onClickCreateContact}
              className="btn rounded-pill p-2 mx-2 shadow-lg border-none bg-light text-primary "
            >
              <FontAwesomeIcon
                className="mx-2 text-primary"
                icon={["fas", "contact-book"]}
                size="lg"
                color={conversationStatus !== "active" ? "grey" : ""}
              />
            </button>
          )}
      </div>
    </PanelWembii>
  );
}
