import { AdAccountsPayload, GetAdAccountsResponse } from "../interfaces/api/get-ad-accounts";
import { AdsPayload, GetAdsResponse } from "../interfaces/api/get-ads";
import { AdsetsPayload, GetAdsetsResponse } from "../interfaces/api/get-adsets";
import { CampaignsPayload, GetCampaignsResponse } from "../interfaces/api/get-campaigns";
import { DeliveryEstimatePayload, GetDeliveryEstimateResponse } from "../interfaces/api/get-delivery-estimate";
import { GetInterestsResponse, InterestsPayload } from "../interfaces/api/get-interests";
import { GetLanguagesResponse, LanguagesPayload } from "../interfaces/api/get-languages";
import { GetLocationsResponse, LocationsPayload } from "../interfaces/api/get-locations";
import { GetMinBudgetResponse, MinBudgetPayload } from "../interfaces/api/get-min-budgets";
import { PutAdRequest, PutAdResponse } from "../interfaces/api/put-ad";
import { PutAdsetRequest, PutAdsetResponse } from "../interfaces/api/put-adset";
import { PutCampaignRequest, PutCampaignResponse } from "../interfaces/api/put-campaign";

export const advertisementsMapper = {
  mapResponseToAdAccounts(
    response: GetAdAccountsResponse
  ): AdAccountsPayload {
    return { adAccounts: response.payload.adAccounts };
  },
  mapResponseToLocations(
    response: GetLocationsResponse
  ): LocationsPayload {
    return { locations: response.payload.locations };
  },
  mapResponseToInterests(
    response: GetInterestsResponse
  ): InterestsPayload {
    return { interests: response.payload.interests };
  },
  mapResponseToLanguages(
    response: GetLanguagesResponse
  ): LanguagesPayload {
    return { languages: response.payload.languages };
  },
  mapResponseToCampaigns(
    response: GetCampaignsResponse
  ): CampaignsPayload {
    return { campaigns: response.payload.campaigns };
  },
  mapResponseToAdsets(
    response: GetAdsetsResponse
  ): AdsetsPayload {
    return { adsets: response.payload.adsets };
  },
  mapResponseToAds(
    response: GetAdsResponse
  ): AdsPayload {
    return { ads: response.payload.ads };
  },
  mapResponseToCampaign(
    response: PutCampaignResponse
  ): PutCampaignRequest {
    return { campaign: response.payload.campaign, status: response.payload.status }
  },
  mapResponseToDeliveryEstimate(
    response: GetDeliveryEstimateResponse
  ): DeliveryEstimatePayload {
    return { estimate: response.payload.estimate }
  },
  mapResponseToAd(
    response: PutAdResponse
  ): PutAdRequest {
    return { ad: response.payload.ad, status: response.payload.status }
  },
  mapResponseToAdset(
    response: PutAdsetResponse
  ): PutAdsetRequest {
    return { 
      adset: response.payload.adset, 
      status: response.payload.status, 
      dailyBudget: response.payload.dailyBudget 
    }
  },
  mapResponseToMinBudget(
    response: GetMinBudgetResponse
  ): MinBudgetPayload {
    return { minBudget: response.payload.minBudget };
  },
};
