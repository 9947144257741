import cn from "classnames";
import Select from "react-select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button, Label } from "reactstrap";
import { useIntl } from "react-intl";
import { Board } from "../../../../cores/boards/interfaces/api/get-board";
import moment from "moment";
import AssignmentContainer from "./AssignmentContainer";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant";
import { getCurrencySymbol } from "../../../../cores/boards/constants/currencies.constant";
import CurrencySelector from "../CurrencySelector";
import NumberInput from "./NumberInput";
import ContactContainer from "./ContactContainer";
import GmailButton from "../../gmail/GmailButton";
import TextInput from "./TextInput";
import DateInput from "./DateInput";
import { useBoardCore } from "../../../../cores/boards";
import { useMeCore } from "../../../../cores/me";
import { CL$Format } from "../../../../cores/utils";
import {
  CARD_TAGS_MODAL,
  EVENT_CARD_MODAL,
  QUOTE_CARD_MODAL,
  TASK_CARD_MODAL,
} from "../../../../constants/modal.constant";
import TaskDetails from "../../tasks/modal/TaskDetails";
import { Errors } from "../../../../constants/errors";
import { useTaskCore } from "../../../../cores/tasks";
import { useGlobalCore } from "../../../../cores/globals";
import { isAlphanumeric } from "../../../utils/stringUtils";
import Avatar from "../../Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import CreateEventForm from "../../event/modals/CreateEventForm";
import { GoogleCalendarIcon } from "../../social/GoogleCalendar/GoogleCalendarIcon";
import { PutCardRequest } from "../../../../cores/boards/interfaces/api/put-card";
import { InputWembii } from "../../InputWembii";
import {
  CardStatusLabels,
  CardStatusOptions,
} from "../../../../cores/boards/constants/card-type";
import CompanyContainer from "./CompanyContainer";
import { CompanyBoard } from "../../../../cores/boards/interfaces/api/get-card";
import { Contact } from "../../../../cores/contacts/interfaces/api/get-contacts";
import ProductContainer from "./ProductContainer";
import QuoteForm from "../modal/QuoteForm";
import CustomFieldContainer from "./CustomFieldContainer";
import { useEventCore } from "../../../../cores/event";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { LoadingElement } from "../../LoadingElement";
import {
  BOARD_CARD_MOVE_LOADING,
  BOARD_CARD_UPDATE_LOADING,
} from "../../../../constants/loading.constant";

interface Props {
  canModify: boolean;
  board: Board | null;
  onUpdateSubmit: (
    arg0: PutCardRequest,
    arg1?: boolean,
    arg2?: string[]
  ) => void;
}

export default function CardOptions(props: Props) {
  const intl = useIntl();
  const { canModify, board, onUpdateSubmit } = props;
  const { company, socialSettings, userSettings, user } = useMeCore();
  const { turnOnModal } = useGlobalCore();
  const { getCalendar } = useEventCore();
  const {
    card,
    listNames,
    tags,
    customFields,
    errorCode,
    changeCardOrder,
    putCardOrder,
    getCard,
    getCardTags,
    getFilters,
    getListBudgets,
    setQuote,
  } = useBoardCore();
  const { clearTask } = useTaskCore();
  const [editBudget, setEditBudget] = useState(false);
  const [status, setStatus] = useState(card?.status);
  const [editEndDate, setEditEndDate] = useState(false);
  const [editInternalId, setEditInternalId] = useState(false);
  const [budget, setBudget] = useState(card?.budget!);
  const [currency, setCurrency] = useState(card?.currency!);
  const [endDate, setEndDate] = useState(card?.endDate!);
  const [internalId, setInternalId] = useState(card?.internalId!);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  }>();

  useEffect(() => {
    if (card) {
      setBudget(card.budget);
      setEndDate(card.endDate);
      setInternalId(card.internalId);
      setStatus(card.status);
      setCurrency(card.currency);
      setEditBudget(false);
      setEditEndDate(false);
      setEditInternalId(false);
    }
  }, [card]);

  useEffect(() => {
    setOptions(listNames.map(({ id, name }) => ({ value: id, label: name })));
  }, [listNames]);

  useEffect(() => {
    setSelectedOption(options.find(({ value }) => value === card?.list));
  }, [card, options]);

  const toggleEditBudget = useCallback(() => {
    if (canModify) {
      setCurrency(card?.currency!);
      setEditBudget(!editBudget);
    }
  }, [editBudget, canModify, card]);

  const toggleEditEndDate = useCallback(() => {
    if (canModify) setEditEndDate(!editEndDate);
  }, [editEndDate, canModify]);

  const toggleEditInternalId = useCallback(() => {
    if (canModify) setEditInternalId(!editInternalId);
  }, [editInternalId, canModify]);

  const handleCardOrder = useCallback(
    async (option) => {
      changeCardOrder(card?.id!, card?.list!, option.value, 0);
      putCardOrder(
        company?.id!,
        board?.id!,
        card?.list!,
        card?.id!,
        option.value,
        0,
        () => {
          const filter = getFilters(board?.id!);
          getListBudgets(company?.id!, board?.id!, card?.list!, filter);
          getListBudgets(company?.id!, board?.id!, option.value, filter);
        }
      );
    },
    [
      changeCardOrder,
      putCardOrder,
      getListBudgets,
      getFilters,
      company,
      board,
      card,
    ]
  );

  const onCreateTask = useCallback(() => {
    clearTask();
    turnOnModal(
      TASK_CARD_MODAL,
      <TaskDetails
        contacts={card?.contacts}
        assignTo={card?.assignments}
        cardId={card?.id}
        onSuccess={() => {
          getCard(company?.id!, board?.id!, card?.list!, card?.id!);
        }}
      />
    );
  }, [turnOnModal, clearTask, getCard, card, company, board]);

  const contacts = useMemo(() => {
    const companyContacts = card?.companies
      ? card?.companies.reduce((prev: Contact[], curr: CompanyBoard) => {
          const mergedContacts = curr.contacts
            ? curr.contacts
                ?.map((contactCompany) => {
                  return prev.filter(
                    (contact) => contact.id !== contactCompany.id
                  );
                })
                .flat()
            : prev;
          return mergedContacts;
        }, [])
      : [];
    const contacts = card?.contacts ? card.contacts : [];

    return [...contacts, ...companyContacts];
  }, [card]);

  const onCreateEvent = useCallback(() => {
    getCalendar(company?.id!);
    turnOnModal(
      EVENT_CARD_MODAL,
      <CreateEventForm
        contacts={contacts as Contact[]}
        cardId={card?.id}
        onSuccess={() => {
          getCard(company?.id!, board?.id!, card?.list!, card?.id!);
        }}
      />
    );
  }, [turnOnModal, getCard, getCalendar, card, company, board, contacts]);

  const onCreateQuote = useCallback(() => {
    const contacts = card?.contacts || [];
    const contactCompany = card?.companies ? card?.companies[0] : null;
    const products = card?.products || [];

    setQuote({
      id: `${moment().valueOf()}-${user?.id}${company?.id}-${card?.id}`,
      name: "",
      creationDate: moment().toDate(),
      expirationDate: moment().add(1, "day").toDate(),
      comments: "",
      terms: "",
      company: contactCompany,
      owner: user,
      ownerCompany: company,
      contacts,
      signature: false,
      currency: card?.currency!,
      products,
      discounts: [],
      fees: [],
      taxes: [],
    });

    turnOnModal(QUOTE_CARD_MODAL, <QuoteForm />);
  }, [turnOnModal, setQuote, card, company, user]);

  const onSubmitEmail = useCallback(() => {
    getCard(company?.id!, board?.id!, card?.list!, card?.id!);
  }, [company, board, card, getCard]);

  const showGmailButton = useMemo(
    () =>
      ((card?.contacts && card?.contacts.length > 0) ||
        (card?.companies &&
          card?.companies.reduce(
            (prev, curr) => prev + curr.contacts?.length!,
            0
          ) > 0)) &&
      (socialSettings?.gmail ||
        userSettings?.gmail ||
        socialSettings?.smtp ||
        userSettings?.smtp) &&
      canModify,
    [card, socialSettings, userSettings, canModify]
  );

  const showEventButton = useMemo(
    () =>
      ((card?.contacts && card?.contacts.length > 0) ||
        (card?.companies &&
          card?.companies.reduce(
            (prev, curr) => prev + curr.contacts?.length!,
            0
          ) > 0)) &&
      canModify,
    [card, canModify]
  );

  const showEstimationButton = useMemo(
    () =>
      ((card?.contacts && card?.contacts.length > 0) ||
        (card?.companies &&
          card?.companies.reduce(
            (prev, curr) => prev + curr.contacts?.length!,
            0
          ) > 0)) &&
      card?.products &&
      card?.products.length > 0 &&
      canModify,
    [card, canModify]
  );

  const error = useMemo(() => {
    switch (errorCode) {
      case Errors.DuplicatedData:
        return (
          <Label className="text-center my-2 w-100 text-danger">
            {intl.formatMessage({ id: "app.crmBusiness.internalIdDuplicated" })}
          </Label>
        );
    }
  }, [errorCode, intl]);

  const openCardTags = useCallback(() => {
    getCardTags(company?.id!, board?.id!);
    turnOnModal(CARD_TAGS_MODAL, true, Position.LEFT);
  }, [turnOnModal, getCardTags, company, board]);

  return (
    <>
      <div className="w-100">
        {board?.type === BoardType.BUSINESS && (
          <div className="d-flex justify-content-center mb-3">
            {canModify ? (
              <LoadingElement id={BOARD_CARD_UPDATE_LOADING}>
                <InputWembii
                  type="select"
                  onChange={(v) => onUpdateSubmit({ status: parseInt(v) })}
                  value={status}
                  mb={false}
                >
                  {CardStatusOptions.map(({ label, value }) => (
                    <option key={label} value={value}>
                      {intl.formatMessage({ id: label })}
                    </option>
                  ))}
                </InputWembii>
              </LoadingElement>
            ) : (
              <span>
                {intl.formatMessage({ id: CardStatusLabels[status!] })}
              </span>
            )}
          </div>
        )}
        <Label className="font-weight-bold">
          {intl.formatMessage({ id: "app.activity.tags" })}
        </Label>
        <div className="d-flex flex-wrap align-items-center">
          {tags.map((tag) => (
            <Badge
              pill
              style={{ backgroundColor: tag.backgroundColor }}
              className="text-left px-3 m-1"
              key={tag.id}
            >
              <span
                className="h6 mb-0 font-weight-bold"
                style={{ color: tag.textColor }}
              >
                {tag.name}
              </span>
            </Badge>
          ))}
          {!canModify && tags.length === 0 && (
            <span className="text-muted">
              {intl.formatMessage({ id: "app.crmBusiness.noTags" })}
            </span>
          )}
          {canModify && (
            <Avatar
              className="text-dark-50 pointer"
              onClick={openCardTags}
              icon={
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  size="1x"
                  color={COLOR_PRIMARY}
                />
              }
            />
          )}
        </div>
      </div>
      <div className="d-flex w-100 mt-4">
        <div className="w-50 px-1">
          <Label className="font-weight-bold">
            {intl.formatMessage({ id: "app.activity.toAssign" })}
          </Label>
          <AssignmentContainer canModify={canModify} board={board?.id!} />
        </div>
        <div className="w-50 px-1">
          <Label className="font-weight-bold">
            {board?.type === BoardType.BUSINESS
              ? intl.formatMessage({ id: "app.crmBusiness.list" })
              : intl.formatMessage({ id: "app.activity.inList" })}
          </Label>
          {canModify ? (
            <LoadingElement id={BOARD_CARD_MOVE_LOADING}>
              <Select
                type="select"
                value={selectedOption}
                onChange={handleCardOrder}
                isClearable={false}
                options={options}
                isSearchable
                classNamePrefix="select"
                placeholder={intl.formatMessage({ id: "app.crmBusiness.list" })}
              />
            </LoadingElement>
          ) : (
            <div className="text-muted pointer">{selectedOption?.label}</div>
          )}
        </div>
      </div>
      {board?.type === BoardType.BUSINESS && (
        <>
          <div className="d-flex w-100 mt-4 pb-4">
            <div className="w-50 px-1">
              <Label className="font-weight-bold">
                {intl.formatMessage({ id: "app.crmBusiness.oportunity" })}
              </Label>

              {!editBudget ? (
                <div className="pointer" onClick={toggleEditBudget}>
                  {card && card.currency && getCurrencySymbol(card.currency)}{" "}
                  {CL$Format(budget)}
                </div>
              ) : (
                <>
                  <CurrencySelector
                    currency={currency}
                    onSubmit={setCurrency}
                  />
                  <NumberInput
                    value={budget}
                    className="pr-4 pt-2"
                    onSubmit={(budget) => onUpdateSubmit({ budget, currency })}
                    toggleEdition={toggleEditBudget}
                    showChek={true}
                  />
                </>
              )}
            </div>
            <div className="w-50 px-1">
              <Label className="font-weight-bold">
                {intl.formatMessage({ id: "app.crmBusiness.closeDate" })}
              </Label>

              {!editEndDate ? (
                <div className="pointer" onClick={toggleEditEndDate}>
                  {!endDate ? (
                    <span className="text-muted">
                      {intl.formatMessage({ id: "app.crmBusiness.closeDate?" })}
                    </span>
                  ) : (
                    <span
                      className={cn({
                        "text-danger": moment(endDate).isBefore(moment()),
                      })}
                    >
                      {moment(endDate).format("DD/MM/YYYY")}
                    </span>
                  )}
                </div>
              ) : (
                <DateInput
                  date={endDate}
                  className="pr-4"
                  onSubmit={(endDate) => onUpdateSubmit({ endDate })}
                  toggleEdition={toggleEditEndDate}
                />
              )}
            </div>
          </div>
          <div className="d-flex w-100">
            <div className="w-50 px-1">
              <Label className="font-weight-bold">
                {intl.formatMessage({ id: "app.crmBusiness.contacts" })}
              </Label>
              <ContactContainer canModify={canModify} board={board.id!} />
            </div>

            <div className="w-50 px-1">
              <Label className="font-weight-bold">
                {intl.formatMessage({ id: "app.crmBusiness.companies" })}
              </Label>
              <CompanyContainer canModify={canModify} board={board.id!} />
            </div>
          </div>
          <div className="d-flex w-100 mt-4">
            <div className="w-50 px-1">
              <Label className="font-weight-bold">
                {intl.formatMessage({ id: "app.activity.internalId" })}
              </Label>

              {!editInternalId ? (
                <div
                  className="pointer truncate"
                  onClick={toggleEditInternalId}
                >
                  {!internalId ? (
                    <span className="text-muted">
                      {intl.formatMessage({
                        id: "app.crmBusiness.noInternalId",
                      })}
                    </span>
                  ) : (
                    <span>{internalId}</span>
                  )}
                </div>
              ) : (
                <TextInput
                  value={internalId!}
                  validateTxt={isAlphanumeric}
                  onSubmit={(internalId) => onUpdateSubmit({ internalId })}
                  allowEmpty={true}
                  showChek={true}
                  toggleEdition={toggleEditInternalId}
                />
              )}
            </div>

            <div className="w-50 px-1">
              <Label className="font-weight-bold">
                {intl.formatMessage({ id: "app.crmBusiness.products" })}
              </Label>
              <ProductContainer canModify={canModify} board={board.id!} />
            </div>
          </div>
        </>
      )}
      {customFields.length > 0 && (
        <div className="w-100">
          <CustomFieldContainer
            canModify={canModify}
            board={board?.id!}
            onSubmit={(customField) => onUpdateSubmit({ customField })}
          />
        </div>
      )}
      {board?.type === BoardType.BUSINESS && (
        <>
          <div className="w-100 mt-4 d-flex justify-content-center">
            {showEstimationButton && (
              <Button
                className="my-2 mr-2"
                size="sm"
                onClick={onCreateQuote}
                style={{ width: 160 }}
              >
                {intl.formatMessage({ id: "app.crmBusiness.createQuote" })}
              </Button>
            )}
            {canModify && (
              <Button
                className="my-2 mr-2 upgradePlan"
                size="sm"
                onClick={onCreateTask}
                style={{ width: 160 }}
              >
                {intl.formatMessage({ id: "app.crmBusiness.addTasks" })}
              </Button>
            )}
            {showGmailButton && (
              <GmailButton
                contacts={contacts as Contact[]}
                onSubmit={onSubmitEmail}
                cardId={card?.id!}
              />
            )}
            {showEventButton && (
              <Button
                className="my-2 ml-2 d-flex align-items-center text-dark"
                size="sm"
                style={{ backgroundColor: "#fff" }}
                onClick={onCreateEvent}
              >
                <GoogleCalendarIcon active className="mx-1" />
                {intl.formatMessage({ id: "app.crmBusiness.addEvent" })}
              </Button>
            )}
          </div>
        </>
      )}
      {errorCode && error}
    </>
  );
}
