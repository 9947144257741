import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TASKS_LOADING, TASK_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import {
  GetTaskByContactParams,
  GetTasksParams,
} from "./interfaces/api/get-tasks";
import { PostTaskRequest } from "./interfaces/api/post-task";
import { PutTaskRequest } from "./interfaces/api/put-task";
import { taskApiService } from "./services/api";
import { clearTaskAction, setTaskFiltersAction } from "./store/actions";
import {
  deleteContactTaskSuccessAction,
  deleteTaskFailureAction,
  deleteTaskInitAction,
  deleteTaskSuccessAction,
  getContactTasksSuccessAction,
  getTaskFailureAction,
  getTaskInitAction,
  getTasksExportSuccessAction,
  getTasksFailureAction,
  getTasksInitAction,
  getTasksSuccessAction,
  getTaskSuccessAction,
  postContactTaskSuccessAction,
  postTaskFailureAction,
  postTaskInitAction,
  postTaskSuccessAction,
  putTaskFailureAction,
  putTaskInitAction,
  putTaskSuccessAction,
} from "./store/api-actions";
import {
  contactTasksSelector,
  taskFiltersSelector,
  taskSelector,
  tasksSelector,
  totalTasksSelector,
} from "./store/selector";
import { Task } from "./interfaces/api/get-task";
import { tasksExportSelector } from "./store/selector";

export function useTaskCore() {
  const dispatch = useDispatch();
  const tasks = useSelector(tasksSelector);
  const tasksExport = useSelector(tasksExportSelector);
  const contactTasks = useSelector(contactTasksSelector);
  const task = useSelector(taskSelector);
  const total = useSelector(totalTasksSelector);
  const taskFilters = useSelector(taskFiltersSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const getTasks = useCallback(
    async (companyId: string, params: GetTasksParams) => {
      try {
        dispatch(getTasksInitAction());
        turnOnLoading(TASKS_LOADING);
        const { tasks, total } = await taskApiService.getTasks(
          companyId,
          params
        );
        dispatch(getTasksSuccessAction({ tasks, total }));
        turnOffLoading(TASKS_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(TASKS_LOADING);
        dispatch(getTasksFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getTasksExport = useCallback(
    async (companyId: string, params: GetTasksParams) => {
      try {
        dispatch(getTasksInitAction());
        turnOnLoading(TASKS_LOADING);
        const { tasks } = await taskApiService.getTasksExport(
          companyId,
          params
        );
        dispatch(getTasksExportSuccessAction({ tasks }));
        turnOffLoading(TASKS_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(TASKS_LOADING);
        dispatch(getTasksFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getTasksByContact = useCallback(
    async (companyId: string, params: GetTaskByContactParams) => {
      try {
        dispatch(getTasksInitAction());
        turnOnLoading(TASKS_LOADING);
        const { tasks, total } = await taskApiService.getContactTasks(
          companyId,
          params
        );
        dispatch(getContactTasksSuccessAction({ tasks, total }));
        turnOffLoading(TASKS_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(TASKS_LOADING);
        dispatch(getTasksFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getTask = useCallback(
    async (companyId: string, taskId: string) => {
      try {
        dispatch(getTaskInitAction());
        turnOnLoading(TASK_LOADING);
        const response = await taskApiService.getTask(companyId, taskId);
        dispatch(getTaskSuccessAction(response));
        turnOffLoading(TASK_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(TASK_LOADING);
        dispatch(getTaskFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const postTask = useCallback(
    async (
      companyId: string,
      body: PostTaskRequest,
      taskType: "contactTask" | "task",
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postTaskInitAction());
        const task = await taskApiService.postTask(companyId, body);
        dispatch(postTaskSuccessAction({ ...task, taskType }));
        toast.success("Tarea Creado Correctamente");
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        toast.error("Error creando Tarea");
        dispatch(postTaskFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postContactTask = useCallback(
    async (
      companyId: string,
      body: PostTaskRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postTaskInitAction());
        const task = await taskApiService.postTask(companyId, body);
        dispatch(postContactTaskSuccessAction(task));
        toast.success("Tarea Creado Correctamente");
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        toast.error("Error creando Tarea");
        dispatch(postTaskFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putTask = useCallback(
    async (
      companyId: string,
      taskId: string,
      body: PutTaskRequest,
      onSuccess?: (task?: Task) => void
    ) => {
      try {
        dispatch(putTaskInitAction());
        const response = await taskApiService.putTask(companyId, taskId, body);
        dispatch(putTaskSuccessAction(response));
        onSuccess?.(response.task);
        toast.success("Tarea Modificada Correctamente");
      } catch (error: any) {
        console.error(error);
        toast.error("Error modificando Tarea");
        dispatch(putTaskFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteTask = useCallback(
    async (companyId: string, taskId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteTaskInitAction());
        const response = await taskApiService.deleteTask(companyId, taskId);
        dispatch(deleteTaskSuccessAction(response));
        toast.success("Tarea Eliminada Correctamente");
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        toast.error("Error eliminando Tarea");
        dispatch(deleteTaskFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteContactTask = useCallback(
    async (companyId: string, taskId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteTaskInitAction());
        const response = await taskApiService.deleteTask(companyId, taskId);
        dispatch(deleteContactTaskSuccessAction(response));
        toast.success("Tarea de contacto Eliminada Correctamente");
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        toast.error("Error eliminando Tarea de contacto");
        dispatch(deleteTaskFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const setTaskFilters = useCallback(
    (filters: GetTasksParams) => {
      dispatch(setTaskFiltersAction({ filters }));
    },
    [dispatch]
  );

  return {
    tasks,
    tasksExport,
    contactTasks,
    task,
    total,
    taskFilters,
    getTasks,
    getTask,
    postTask,
    putTask,
    deleteTask,
    setTaskFilters,
    getTasksByContact,
    deleteContactTask,
    postContactTask,
    getTasksExport,
    clearTask: () => dispatch(clearTaskAction()),
  };
}
