import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Col, Row } from "reactstrap";
import { CONTACT_EXPAND_EDIT_FORM } from "../../../constants/modal.constant";
import { useConfigCore } from "../../../cores/config";
import { useContactCore } from "../../../cores/contacts";
import { Contact } from "../../../cores/contacts/interfaces/api/get-contacts";
import { useGlobalCore } from "../../../cores/globals";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import { useMeCore } from "../../../cores/me";
import EditContactForm from "../contacts/modal/EditContactForm";
import { PanelWembii } from "../PanelWembii";

export const ContactRow = ({ contact, onEdit, canModify }: {
  contact: Contact;
  onEdit: (arg0: Contact) => void;
  canModify: boolean;
}) => {
  const { company } = useMeCore();
  const { getCommentTypes } = useConfigCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const {
    getContactTags,
    getCustomFields,
    getContact,
    getCompany,
    deleteContactCompany,
    company: contactCompany
  } = useContactCore();

  const onEditing = useCallback((e, contact) => {
    e.stopPropagation();
    onEdit(contact);
  }, [onEdit]);

  const onClicking = useCallback(() => {
    getContactTags(company?.id!);
    getCommentTypes(company?.id!);
    getCustomFields(company?.id!);
    getContact(company?.id!, contact.id);
    turnOnModal(
      CONTACT_EXPAND_EDIT_FORM,
      <EditContactForm
        onDelete={() => {
          deleteContactCompany(
            company?.id!,
            contactCompany?.id!,
            contact.id,
            () => {
              getCompany(company?.id!, contactCompany?.id!);
              turnOffModal(CONTACT_EXPAND_EDIT_FORM);
            }
          );
        }}
        isUnassignedButton={true}
        onUpdate={() => {
          getCompany(company?.id!, contactCompany?.id!);
        }}
      />,
      Position.LEFT
    );
  }, [
    contact,
    company,
    contactCompany,
    turnOffModal,
    getCompany,
    turnOnModal,
    getContact,
    getContactTags,
    getCommentTypes,
    deleteContactCompany,
    getCustomFields
  ]);

  return (
    <PanelWembii
      className="w-100 px-4 py-3 my-2 pointer"
      shadow
    >
      <Row className="d-flex align-items-center justify-content-center" onClick={onClicking}>
        <Col md="7">
          <div>
            {contact.name}
          </div>
          <div className="text-muted">
            {contact.role}
          </div>
        </Col>
        <Col md="5" className="text-right">
          {canModify && (
            <FontAwesomeIcon
              icon={["fas", "edit"]}
              size="1x"
              className="ml-3 pointer"
              color="gray"
              onClick={(e) => onEditing(e, contact)}
            />
          )}
        </Col>
      </Row>
    </PanelWembii>
  );
};