import cn from "classnames";
import React, { useEffect, useState } from "react";
import { FormGroup, InputProps, Label } from "reactstrap";
import { COLOR_PRIMARY, COLOR_LIGHT } from "../../constants/theme";

interface Props extends InputProps {
  className?: string;
  label?: string;
  name?: string;
  id?: string;
  mb?: boolean;
  checked?: boolean;
  onChange?: (e: any) => void;
}

export const RadiobuttonWembii = (props: Props) => {
  const {
    id,
    label,
    checked,
    className,
    mb = true,
    onChange,
  } = props;
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked)
  }, [checked]);

  return (
    <FormGroup check className={cn("p-0", className, { "m-0": !mb, "mb-3": mb })}>
      <Label check className="d-flex align-items-center flex-wrap pointer"  onClick={() => onChange?.(!isChecked)}>
        <Label 
          className="rounded-circle d-inline-block mb-0 mr-2 pointer"
          for={id}
          style={{
            width: 20,
            height: 20,
            backgroundColor: isChecked ? COLOR_PRIMARY : COLOR_LIGHT,
          }}
        >
          {isChecked && (
            <svg viewBox="0 0 18 18" style={{
              fill: "none",
              stroke: "white"
            }}>
              <circle cx="9" cy="7" r="5" fill="white" />
            </svg>
          )}
        </Label>
        {' '}
        {label}
      </Label>
    </FormGroup>
  );
};
