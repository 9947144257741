import analyticsApiClient from "../../helpers/services/analytics-api-client";
import { GetJumpsellerOrdersParams } from "../../market/interface/api/get-jumpseller-orders";
import { GetInsightResponse } from "../interface/api/get-insights";
import { GetInstagramFeedPosts } from "../interface/api/get-instagram-feed-posts";
import { GetReportResponse } from "../interface/api/get-report";
import { analyticsMapper } from "./mapper";

export const analyticsApiService = {
  facebookInsights(
    companyId: string,
    since: string,
    until: string,
    type: string,
    limit?: number
  ) {
    return analyticsApiClient()
      .get<GetInsightResponse>(`facebook/insights`, {
        params: { companyId, since, until, type, limit },
      })
      .then(({ data }) => analyticsMapper.mapResponseToMetrics(data));
  },
  instagramInsights(
    companyId: string,
    since: string,
    until: string,
    type: string,
    limit?: number
  ) {
    return analyticsApiClient()
      .get<GetInsightResponse>(`instagram/insights`, {
        params: { companyId, since, until, type, limit },
      })
      .then(({ data }) => analyticsMapper.mapResponseToMetrics(data));
  },
  googleInsights(
    companyId: string,
    since: string,
    until: string,
    type: string,
    limit?: number
  ) {
    return analyticsApiClient()
      .get<GetInsightResponse>(`google/insights`, {
        params: { companyId, since, until, type, limit },
      })
      .then(({ data }) => analyticsMapper.mapResponseToMetrics(data));
  },
  wooCommerceInsights(companyId: string, since: string, until: string) {
    return analyticsApiClient()
      .get<GetInsightResponse>(`woocommerce/insights`, {
        params: { companyId, since, until },
      })
      .then(({ data }) => analyticsMapper.mapResponseToMetrics(data));
  },
  mercadolibreInsights(companyId: string, since: string, until: string) {
    return analyticsApiClient()
      .get<GetInsightResponse>(`mercadolibre/insights`, {
        params: { companyId, since, until },
      })
      .then(({ data }) => analyticsMapper.mapResponseToMetrics(data));
  },
  jumpsellerInsights(companyId: string, params: GetJumpsellerOrdersParams) {
    return analyticsApiClient()
      .get<GetInsightResponse>(`jumpseller/insights`, {
        params: { companyId, ...params },
      })
      .then(({ data }) => analyticsMapper.mapResponseToMetrics(data));
  },
  instagramFeedPosts(companyId: string) {
    return analyticsApiClient()
      .get<GetInstagramFeedPosts>(`instagram/feed/post`, {
        params: { companyId },
      })
      .then(({ data }) => analyticsMapper.mapResponseToInstagramPost(data));
  },
  insightReport(companyId: string,) {
    return analyticsApiClient()
      .get<GetReportResponse>(`report/insights`, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
};
