import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { WooCurrency } from "../interface/api/get-current-currency";
import { WooCommerceSetting } from "../interface/api/post-woocommerce-bind";
import {
  setWooCommerceName,
  setWooCommerceUrl,
  WooCommerceAction,
} from "./actions";
import {
  getWooCommerceCurrencyFailureAction,
  getWooCommerceCurrencyInitAction,
  getWooCommerceCurrencySuccessAction,
  getWooCommerceFailureAction,
  getWooCommerceInitAction,
  getWooCommerceLoginFailureAction,
  getWooCommerceSuccessAction,
  postBindWooCommerceFailureAction,
  postBindWooCommerceInitAction,
  postBindWooCommerceSuccessAction,
  postUnbindWooCommerceFailureAction,
  postUnbindWooCommerceInitAction,
  postUnbindWooCommerceSuccessAction,
  WooCommerceApiAction,
} from "./api-actions";

export interface WooCommerceState {
  settings: WooCommerceSetting | null;
  url: string;
  name: string;
  error: string;
  currency: WooCurrency | null;
}

const initialState: WooCommerceState = {
  settings: null,
  url: "",
  name: "",
  error: "",
  currency: null,
};

export default produce(
  (
    draft: WooCommerceState = initialState,
    action: WooCommerceApiAction | WooCommerceAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindWooCommerceSuccessAction.type:
      case getWooCommerceSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case getWooCommerceCurrencySuccessAction.type:
        draft.currency = action.currency;
        return draft;
      case postUnbindWooCommerceFailureAction.type:
      case getWooCommerceFailureAction.type:
      case getWooCommerceLoginFailureAction.type:
      case getWooCommerceCurrencyFailureAction.type:
        draft.error = action.error;
        return draft;
      case postBindWooCommerceFailureAction.type:
      case postUnbindWooCommerceSuccessAction.type:
        draft.settings = null;
        return draft;
      case setWooCommerceUrl.type:
        draft.url = action.url;
        return draft;
      case setWooCommerceName.type:
        draft.name = action.name;
        return draft;
      case resetAction.type:
        return initialState;
      case getWooCommerceCurrencyInitAction.type:
      case postBindWooCommerceInitAction.type:
      case postUnbindWooCommerceInitAction.type:
      case getWooCommerceInitAction.type:
      default:
        return draft;
    }
  }
);
