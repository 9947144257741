import cn from "classnames";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Badge } from "reactstrap";
import { CompanyRole } from "../../../cores/company/interfaces/api/get-company";
import { Permissions } from "../../../cores/company/interfaces/roles";

interface Props {
  roles: CompanyRole;
  id: string;
}

export const RoleBadges = (props: Props) => {
  const intl = useIntl();
  const { roles, id } = props;
  const [isShown, setIsShown] = useState(-1);

  const getPermissionName = (permission: string) =>
    intl.formatMessage({ id: Permissions[permission].label });

  return (
    <div className="d-flex h-100 justify-content-center align-items-center flex-wrap">
      {Object.keys(roles).map((role, index) => (
        <Badge
          onMouseEnter={() => setIsShown(index)}
          onMouseLeave={() => setIsShown(-1)}
          id={`tooltip-${id}-${role}-${index}`}
          key={`tooltip-${id}-${role}-${index}`}
          pill
          className="m-1 pointer"
          style={{
            minWidth: "30px",
            height: "30px",
            backgroundColor: "#c5e384",
          }}
        >
          <h6
            className={cn("m-0", { h5: !isShown, h6: isShown === index })}
            style={{ lineHeight: "25px" }}
          >
            {isShown === index
              ? getPermissionName(role).toUpperCase()
              : getPermissionName(role)[0].toUpperCase()
            }
          </h6>
        </Badge>
      ))}
    </div>
  );
};
