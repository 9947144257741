import { SocialPostRules } from "../socialPost/interfaces/postError";

export const SOCIAL_POST_RULES: SocialPostRules = {
  maxVideoSize: parseInt(
    process.env.REACT_APP_TWITTER_MAX_VIDEO_SIZE ||
    process.env.REACT_APP_DEFAULT_MAX_VIDEO_SIZE ||
    "20"
  ),
  maxImageSize: parseInt(
    process.env.REACT_APP_TWITTER_MAX_IMAGE_SIZE ||
    process.env.REACT_APP_DEFAULT_MAX_IMAGE_SIZE ||
    "100"
  ),
  maxCharactersAllowed: 280,
  maxPostPhotoAllowed: 4,
  postMediaRequired: false,
  videoAllowed: true,
};
