import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Notification } from "../interfaces/api/get-notifications";

export const getNotificationsInitAction = createAction(
  "[Notification] Get Notifications Init"
);
export const getUnreadNotificationsSuccessAction = createAction(
  "[Notification] Get Unread Notifications Success",
  props<{ notifications: Notification[]; count: number }>()
);
export const clearNotifications = createAction(
  "[Notification] Clear Notifications Success"
);
export const getNotificationsSuccessAction = createAction(
  "[Notification] Get Notifications Success",
  props<{ notifications: Notification[]; count: number;  }>()
);
export const getNotificationsFailureAction = createAction(
  "[Notification] Get Notifications Failure",
  props<{ error: string }>()
);

export const putReadNotificationsInitAction = createAction(
  "[Notification] Put Read Notifications Init"
);
export const putReadNotificationsSuccessAction = createAction(
  "[Notification] Put Read Notifications Success"
);
export const putReadNotificationsFailureAction = createAction(
  "[Notification] Put Read Notifications Failure",
  props<{ error: string }>()
);

export const putReadNotificationInitAction = createAction(
  "[Notification] Put Read Notification Init"
);
export const putReadNotificationSuccessAction = createAction(
  "[Notification] Put Read Notification Success",
  props<{ id: string }>()
);
export const putReadNotificationFailureAction = createAction(
  "[Notification] Put Read Notification Failure",
  props<{ error: string }>()
);

export const getSystemNotificationsInitAction = createAction(
  "[Notification] Get System Notifications Init"
);
export const getSystemNotificationsSuccessAction = createAction(
  "[Notification] Get System Notifications Success",
  props<{
    infoMessage: string;
    infoMessageEs: string;
    infoMessageEn: string;
    infoMessagePt: string;
  }>()
);
export const getSystemNotificationsFailureAction = createAction(
  "[Notification] Get System Notifications Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getNotificationsInitAction,
  clearNotifications,
  getNotificationsSuccessAction,
  getNotificationsFailureAction,
  putReadNotificationsInitAction,
  putReadNotificationsSuccessAction,
  putReadNotificationsFailureAction,
  putReadNotificationInitAction,
  putReadNotificationSuccessAction,
  putReadNotificationFailureAction,
  getSystemNotificationsInitAction,
  getSystemNotificationsSuccessAction,
  getSystemNotificationsFailureAction,
  getUnreadNotificationsSuccessAction,
});

export type NotificationApiAction = typeof actions;
