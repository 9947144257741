import React from "react";
import cn from "classnames";
import { useIntl } from "react-intl";
import { GooglenIcon } from "./GoogleIcon";

interface Props {
  isActive: boolean;
  onClick: () => void;
  onlySyncText?: boolean;
}

export const GoogleButton = (props: Props) => {
  const { isActive, onClick } = props;
  const intl = useIntl();

  return (
    <div
      className={cn(
        "d-flex align-items-center pointer border border-primary rounded-pill mx-auto",
        {
          "text-primary": isActive,
          "text-white": !isActive,
          "bg-primary": !isActive,
        }
      )}
      onClick={onClick}
    >
      <GooglenIcon className="ml-2 mr-1 rounded-circle" />
      <div className="text-center w-100">
        {!isActive
          ? intl.formatMessage({
              id: props.onlySyncText
                ? "app.home.syncUp"
                : "app.home.syncUpGoogle",
            })
          : intl.formatMessage({ id: "app.home.disconnect" })}
      </div>
    </div>
  );
};
