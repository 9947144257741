import React, { useCallback, useState } from "react";
import { Button, Container, Form, FormGroup, Label } from "reactstrap";
import { useWebChatCore } from "../../../../../cores/conversations";
import { LabelWembii } from "../../../LabelWembii";
import { PanelWembii } from "../../../PanelWembii";
import { useIntl } from "react-intl";
import { TitleWembii } from "../../../TitleWembii";
import { useGlobalCore } from "../../../../../cores/globals";
import { CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL } from "../../../../../constants/modal.constant";
import CommercialTimeForm from "../modal/CommercialTimeForm";
import Icofont from "react-icofont";

interface Props {
  onSubmit: (e: any) => void;
}

export default function CommertialTimeForm({ onSubmit }: Props) {
  const intl = useIntl();
  const { chat, setChatConfig } = useWebChatCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const [hasChange, setHasChange] = useState(false);

  const onFormSubmit = useCallback(
    (e: any) => {
      onSubmit(e);
      setHasChange(false);
    },
    [onSubmit]
  );

  const toggleCommercialTimeSideModal = useCallback(() => {
    turnOnModal(
      CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL,
      <CommercialTimeForm
        commercialTime={chat?.config?.commercialTime!}
        onSubmit={(commecialTime) => {
          setChatConfig({ ...chat.config, ...commecialTime });
          setHasChange(true);
          turnOffModal(CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL);
        }}
      />
    );
  }, [turnOffModal, turnOnModal, chat, setChatConfig]);

  return (
    <Container className="p-0">
      <PanelWembii shadow className="p-4 mb-4">
        <Form className="w-100" onSubmit={onFormSubmit}>
          <TitleWembii Tag="h5" color="primary">
            {intl.formatMessage({ id: "app.chatConfigCommertial.Schedule" })}
          </TitleWembii>
          <FormGroup>
            <LabelWembii forid="addTime" required>
              {intl.formatMessage({ id: "app.chatConfigCommertial.labelTxt" })}
            </LabelWembii>
            <Label className="text-muted w-100">
              {intl.formatMessage({
                id: "app.chatConfigCommertial.attentionCommerce",
              })}
            </Label>
            <div
              className="border border-white-50 text-dark shadow-sm btn btn-sm"
              color="white"
              id="addTime"
              onClick={toggleCommercialTimeSideModal}
            >
              <Icofont className="mr-1" icon="wall-clock" />
              {intl.formatMessage({
                id: "app.chatConfigCommertial.addSchedule",
              })}
            </div>
          </FormGroup>

          <div className="d-flex justify-content-center mt-2">
            <Button color="primary" disabled={!hasChange}>
              {intl.formatMessage({ id: "app.chatConfig.saveConfig" })}
            </Button>
          </div>
        </Form>
      </PanelWembii>
    </Container>
  );
}
