import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { CHAT_MESSAGES_LOADING } from "../../../../constants/loading.constant";
import { useMeCore } from "../../../../cores/me";
import {
  Conversation,
  Message,
} from "../../../../cores/socialChat/interfaces/conversations.interface";
import { PanelWembii } from "../../PanelWembii";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";
import MessageRow from "../MessageRow";
import SubmitInput from "../SubmitInput";
import { useIntl } from "react-intl";
import { Attachment } from "../../../../cores/conversations/interfaces/attachment/attachment.interface";
import { useWebChatCore } from "../../../../cores/conversations";
import { ContentMessageType } from "../../../../cores/socialChat/types/whatsapp-message.types";
import { ConversationStatus } from "../../../../cores/socialChat/types/conversation-status.types";
import { useSocialChatCore } from "../../../../cores/socialChat";
import ScrollButton from "../scrollButton/ScrollButton";

interface PostMessageProps {
  companyId: string;
  conversationId: string;
  recipientId: string;
  content: string;
  type: ContentMessageType;
  status: ConversationStatus;
  attachments: Attachment[];
}
interface Props {
  selected: Conversation | null;
  messages: Message[];
  getMessages: (arg0: string, arg1: string) => void;
  postMessage: (props: PostMessageProps) => Promise<void>;
  postSeenMessage?: (arg0: string, arg1: string, arg2: string) => void;
}

export default function SocialChatForm(props: Props) {
  const intl = useIntl();
  const { attachments } = useWebChatCore();
  const { conversationStatus } = useSocialChatCore();
  const { selected, messages, getMessages, postMessage, postSeenMessage } =
    props;
  const { company, socialSettings } = useMeCore();

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref !== null) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [messages]);

  const meId = useMemo(() => {
    if (selected?.type === "facebook") return socialSettings?.facebook?.userId;
    if (selected?.type === "instagram")
      return socialSettings?.instagramMensseger?.organizationId;
  }, [selected, socialSettings]);

  const itsMe = useCallback(
    (message: Message) => {
      if (selected?.type === "whatsapp") return !!message.from.email;
      return meId === message.from.id;
    },
    [selected, meId]
  );

  const onSubmit = async (message = "") => {
    if (attachments.length > 0) {
      await postMessage({
        companyId: company?.id!,
        conversationId: selected?.id!, //conversationId
        recipientId: selected?.participants[0]?.id!, //recipientId
        content: message,
        type: attachments[0].type,
        attachments,
        status: conversationStatus,
      });
    }

    if (message) {
      await postMessage({
        companyId: company?.id!,
        conversationId: selected?.id!, //conversationId
        recipientId: selected?.participants[0]?.id!, //recipientId
        content: message,
        type: "text",
        attachments: [],
        status: conversationStatus,
      });
    }
  };

  const disabled = useCallback(() => {
    let isDisabled = true;

    const messagesFromUser = messages.filter(
      (message) => message.from.id !== meId
    );
    if (messagesFromUser.length > 0) {
      const lastMessage =
        messagesFromUser[messagesFromUser.length - 1].timestamp;
      const lastMessageTime = moment(`${lastMessage.date} ${lastMessage.time}`);
      const minutesDiff = moment().utc().diff(lastMessageTime, "minutes");

      isDisabled = minutesDiff > 24 * 60;
    }

    return false;
  }, [messages, meId]);

  useEffect(() => {
    getMessages(company?.id!, selected?.id!);
  }, [getMessages, selected, company]);

  useEffect(() => {
    if (!disabled()) {
      postSeenMessage?.(company?.id!, selected?.id!, selected?.fromId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, company, postSeenMessage, disabled]);
  return (
    <PanelWembii className="mb-3 position-relative">
      <UpdateLoadingCard
        className="position-absolute-center"
        loadingId={CHAT_MESSAGES_LOADING}
        text={intl.formatMessage({ id: "app.chatFacebook.updatingMsg" })}
      />
      <div style={{ height: 512 }} className="position-relative mb-4">
        <div
          className="backdrop-chat p-2"
          style={{ maxHeight: "512px", overflowY: "auto" }}
        >
          {messages.map((message) => (
            <MessageRow
              key={message.id}
              message={message}
              itsMe={itsMe(message)}
            />
          ))}
          <div ref={ref} />
        </div>
        {disabled() && (
          <span className="text-center text-muted">
            {intl.formatMessage({ id: "app.chatFacebook.notHaveMsg" })}
          </span>
        )}
      </div>
      <ScrollButton reference={ref} positionY={80} />
      <SubmitInput
        attachments={attachments}
        conversation={selected}
        disableAttachments={selected?.type !== "whatsapp"}
        onSubmit={onSubmit}
        disabled={disabled()}
      />
    </PanelWembii>
  );
}
