import mainApiClient from "../../helpers/services/main-api-client";
import socialApiClient from "../../helpers/services/social-api-client";
import { DeleteMeSignResponse } from "../interfaces/api/delete-meSign";
import { GetMeAreasResponse } from "../interfaces/api/get-meArea";
import {
  GetMeCompaniesResponse,
  GetMeCompanyResponse,
} from "../interfaces/api/get-meCompany";
import { GetMeSignResponse } from "../interfaces/api/get-meSign";
import { GetMeSocialSettingsResponse } from "../interfaces/api/get-meSocialSettings";
import { GetMeUserResponse } from "../interfaces/api/get-meUser";
import { GetMeUserSettingsResponse } from "../interfaces/api/get-meUserSettings";
import { PostMeLanguageResponse } from "../interfaces/api/post-meLanguage";
import { PostMeSignRequest, PostMeSignResponse } from "../interfaces/api/post-meSign";
import { PutMePasswordRequest, PutMePasswordResponse } from "../interfaces/api/put-mePassword";
import { PutMeSignRequest, PutMeSignResponse } from "../interfaces/api/put-meSign";
import { PutMeUserRequest, PutMeUserResponse } from "../interfaces/api/put-meUser";
import { userMapper } from "./mapper";

export const meApiService = {
  getMeUser() {
    return mainApiClient()
      .get<GetMeUserResponse>(`me/user`)
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  getMeCompanies() {
    return mainApiClient()
      .get<GetMeCompaniesResponse>(`me/companies`)
      .then(({ data }) => userMapper.mapResponseToCompanies(data));
  },
  getMeCompany(companyId: string) {
    return mainApiClient()
      .get<GetMeCompanyResponse>(`me/company/${companyId}`)
      .then(({ data }) => userMapper.mapResponseToCompany(data));
  },
  getMeSocialSetting(companyId: string) {
    return socialApiClient()
      .get<GetMeSocialSettingsResponse>(`me/socialSettings`, {
        params: { companyId },
      })
      .then(({ data }) => userMapper.mapResponseToSocial(data));
  },
  getMeUserSetting() {
    return socialApiClient()
      .get<GetMeUserSettingsResponse>(`me/userSettings`, { })
      .then(({ data }) => userMapper.mapResponseToUserSocial(data));
  },
  getMeAreas(companyId: string) {
    return mainApiClient()
      .get<GetMeAreasResponse>(`me/areas`, {
        params: { companyId },
      })
      .then(({ data }) => userMapper.mapResponseToAreas(data));
  },
  putMeUser(body: PutMeUserRequest) {
    return mainApiClient()
      .put<PutMeUserResponse>(`me/user`, body, {})
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  putMePassword(body: PutMePasswordRequest) {
    return mainApiClient()
      .put<PutMePasswordResponse>(`me/password`, body, {})
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  postMeAvatar(avatar: File) {
    let bodyFormData = new FormData();
    bodyFormData.append("images", avatar);

    return mainApiClient()
      .post<PutMeUserResponse>(`me/avatar`, bodyFormData, {})
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  postMeLanguage(locale: string) {
    return mainApiClient()
      .post<PostMeLanguageResponse>(`me/language`, { locale }, {})
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  getMeSigns() {
    return mainApiClient()
      .get<GetMeSignResponse>(`me/sign`)
      .then(({ data }) => userMapper.mapResponseToSigns(data));
  },
  postMeSign(body: PostMeSignRequest) {
    return mainApiClient()
      .post<PostMeSignResponse>(`me/sign`, body, {})
      .then(({ data }) => userMapper.mapResponseToSign(data));
  },
  putMeSign(id: string, body: PutMeSignRequest) {
    return mainApiClient()
      .put<PutMeSignResponse>(`me/sign/${id}`, body, {})
      .then(({ data }) => userMapper.mapResponseToSign(data));
  },
  deleteMeSign(id: string) {
    return mainApiClient()
      .delete<DeleteMeSignResponse>(`me/sign/${id}`)
      .then(({ data }) => userMapper.mapResponseToId(data));
  },
  postSignImage(image: File) {
    let bodyFormData = new FormData();
    bodyFormData.append("images", image);

    return mainApiClient()
      .post(`me/sign/image`, bodyFormData, {})
      .then(({ data }) => data.payload);
  },
};
