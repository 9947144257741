import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import { useFacebookCore } from "../../../../cores/facebook";
import { useInstagramCore } from "../../../../cores/instagram";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { SOCIAL_ACTION_TYPES } from "../../../../cores/socialPost/consts/socialActionType.constant";
import { SOCIAL_POST_TYPES } from "../../../../cores/socialPost/consts/socialPostType.constant";

interface Props {
  from: string;
  onReset: () => void;
}

export const StoryActionButtons = ({ from, onReset }: Props) => {
  const intl = useIntl();
  const {
    modifySocialStory: modifySocialStoryFacebook,
    modifySocialStoryVideo: modifySocialStoryVideoFacebook,
  } = useFacebookCore();
  const {
    modifySocialStory: modifySocialStoryInstagram,
    modifySocialStoryVideo: modifySocialStoryVideoInstagram,
  } = useInstagramCore();
  const { company } = useMeCore();
  const { goToSocialHistory, goToSocialCalendar } = useRouterCore();
  const {
    socialProviders,
    images,
    selectedPost,
    video,
    text = "",
    setSocialAction,
  } = useSocialPostCore();

  const modify = useCallback(() => {
    setSocialAction(SOCIAL_ACTION_TYPES.SCHEDULE);

    selectedPost &&
      socialProviders.forEach((sp) => {
        switch (sp.id) {
          case SocialProviderId.Facebook:
            selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO &&
            selectedPost.media.length > 0
              ? modifySocialStoryVideoFacebook(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  video,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                )
              : modifySocialStoryFacebook(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  images,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                );
            break;
          case SocialProviderId.Instagram:
            selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO &&
            selectedPost.media.length > 0
              ? modifySocialStoryVideoInstagram(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  video,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                )
              : modifySocialStoryInstagram(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  images,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                );
            break;
          default:
            break;
        }
      });
  }, [
    selectedPost,
    socialProviders,
    company,
    text,
    video,
    images,
    onReset,
    setSocialAction,
    modifySocialStoryFacebook,
    modifySocialStoryInstagram,
    modifySocialStoryVideoFacebook,
    modifySocialStoryVideoInstagram
  ]);

  const cancelEdition = useCallback(() => {
    onReset();
    if (from)
      if (from === "history") {
        goToSocialHistory();
      } else {
        goToSocialCalendar();
      }
  }, [goToSocialHistory, goToSocialCalendar, onReset, from]);

  const saveEdition = useCallback(async () => {
    await modify();
    if (from)
      if (from === "history") {
        goToSocialHistory();
      } else {
        goToSocialCalendar();
      }
  }, [goToSocialHistory, goToSocialCalendar, modify, from]);

  return (
    <div className="d-flex">
      <Button className="whiteButton mx-2" onClick={saveEdition}>
        {intl.formatMessage({ id: "app.socialMediaPost.save" })}
      </Button>
      <Button color="danger" onClick={cancelEdition}>
        {intl.formatMessage({ id: "app.socialMediaPost.cancel" })}
      </Button>
    </div>
  );
};
