import advertisementsApiClient from "../../helpers/services/advertisements-api-client";
import { GetAdAccountsResponse } from "../interfaces/api/get-ad-accounts";
import { GetLocationsResponse } from "../interfaces/api/get-locations";
import { GetInterestsResponse } from "../interfaces/api/get-interests";
import { GetLanguagesResponse } from "../interfaces/api/get-languages";
import { CampaignCreation, PostCampaignResponse, Targeting } from "../interfaces/api/post-campaign";
import { advertisementsMapper } from "./mapper";
import { GetCampaignsResponse } from "../interfaces/api/get-campaigns";
import { GetAdsetsResponse } from "../interfaces/api/get-adsets";
import { GetAdsResponse } from "../interfaces/api/get-ads";
import { GetMinBudgetResponse } from "../interfaces/api/get-min-budgets";
import { PutCampaignResponse } from "../interfaces/api/put-campaign";
import { PutAdsetResponse } from "../interfaces/api/put-adset";
import { PutAdResponse } from "../interfaces/api/put-ad";
import { GetDeliveryEstimateResponse } from "../interfaces/api/get-delivery-estimate";

export const advertisementsApiService = {
  getAdAccounts(companyId: string) {
    return advertisementsApiClient()
      .get<GetAdAccountsResponse>(`advertisements/facebook/adaccounts`, { params: 
        { companyId } 
      })
      .then(({ data }) => advertisementsMapper.mapResponseToAdAccounts(data));
  },
  getLocations(companyId: string, pattern: string) {
    return advertisementsApiClient()
      .get<GetLocationsResponse>(`advertisements/facebook/locations`, { params: 
        { companyId, pattern } 
      })
      .then(({ data }) => advertisementsMapper.mapResponseToLocations(data));
  },
  getInterests(companyId: string, pattern: string) {
    return advertisementsApiClient()
      .get<GetInterestsResponse>(`advertisements/facebook/interests`, { params: 
        { companyId, pattern } 
      })
      .then(({ data }) => advertisementsMapper.mapResponseToInterests(data));
  },
  getLanguages(companyId: string, pattern?: string) {
    return advertisementsApiClient()
      .get<GetLanguagesResponse>(`advertisements/facebook/languages`, { params: 
        { companyId, pattern }
     })
      .then(({ data }) => advertisementsMapper.mapResponseToLanguages(data));
  },
  getCampaigns(companyId: string, adAccount: string) {
    return advertisementsApiClient()
      .get<GetCampaignsResponse>(`advertisements/facebook`, { params: 
        { companyId, adAccount }
      })
      .then(({ data }) => advertisementsMapper.mapResponseToCampaigns(data));
  },
  getAdsets(companyId: string, campaign: string) {
    return advertisementsApiClient()
      .get<GetAdsetsResponse>(`advertisements/facebook/adsets`, { params: 
        { companyId, campaign }
      })
      .then(({ data }) => advertisementsMapper.mapResponseToAdsets(data));
  },
  getAds(companyId: string, adset: string) {
    return advertisementsApiClient()
      .get<GetAdsResponse>(`advertisements/facebook/ads`, { params: 
        { companyId, adset }
      })
      .then(({ data }) => advertisementsMapper.mapResponseToAds(data));
  },
  getMinBudget(companyId: string, adAccount: string, currency: string) {
    return advertisementsApiClient()
      .get<GetMinBudgetResponse>(`advertisements/facebook/minbudget`, { params: 
        { companyId, adAccount, currency }
      })
      .then(({ data }) => advertisementsMapper.mapResponseToMinBudget(data));
  },
  getDeliveryEstimate(companyId: string, adAccount: string, objective: string, targeting: Targeting) {
    return advertisementsApiClient()
      .get<GetDeliveryEstimateResponse>(`advertisements/facebook/delivery_estimate`, { params: 
        { companyId, adAccount, objective, targeting }
      })
      .then(({ data }) => data.payload);
  },
  postCampaign(companyId: string, adAccount: string, campaign: CampaignCreation, image: File) {
    const bodyFormData = new FormData();
    bodyFormData.append("adAccount", adAccount);
    bodyFormData.append("campaign", JSON.stringify(campaign));
    bodyFormData.append("images", image)
    return advertisementsApiClient()
      .post<PostCampaignResponse>(`advertisements/facebook`, bodyFormData, { 
        params: { companyId } 
      })
      .then(({ data }) => data.payload);
  },
  putCampaign(companyId: string, campaign: string, status: string) {
    return advertisementsApiClient()
      .put<PutCampaignResponse>(`advertisements/facebook`, { campaign, status }, { 
        params: { companyId } 
      })
      .then(({ data }) => advertisementsMapper.mapResponseToCampaign(data));
  },
  putAdset(companyId: string, adset: string, status: string, dailyBudget: string) {
    return advertisementsApiClient()
      .put<PutAdsetResponse>(`advertisements/facebook/adset`, { adset, status, dailyBudget }, { 
        params: { companyId } 
      })
      .then(({ data }) => advertisementsMapper.mapResponseToAdset(data));
  },
  putAd(companyId: string, ad: string, status: string) {
    return advertisementsApiClient()
      .put<PutAdResponse>(`advertisements/facebook/ad`, { ad, status }, { 
        params: { companyId } 
      })
      .then(({ data }) => advertisementsMapper.mapResponseToAd(data));
  },
};
