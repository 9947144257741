import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";

export const setDateAction = createAction(
  "[BoardAnalytics] Set date",
  props<{ date: string }>()
);

export const setHasMoreAction = createAction(
  "[BoardAnalytics] Set has more",
  props<{ hasMore: boolean }>()
);

const actions = joinActions({
  setDateAction,
  setHasMoreAction,
});

export type BoardAnalyticsAction = typeof actions;
