import React, { useEffect, useState } from "react";
import { File } from "../../../../cores/boards/interfaces/api/get-card";
import { PanelFile } from "../../../../cores/boards/interfaces/PanelFile";

interface Props {
  file: PanelFile | File;
}

export default function ImagePreview({ file }: Props) {
  const [src, setSrc] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (file.data) {
      setName((file as PanelFile).name);
      setSrc(URL.createObjectURL((file as PanelFile).data));
    } else {
      setName((file as File).key);
      setSrc((file as File).url);
    }
  }, [file]);

  return (
    <img
      className="filePreview"
      key={file.id}
      alt={name}
      src={src}
    />
  );
}