import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Col, Container, Row } from "reactstrap";
import { useCompanyCore } from "../../../../cores/company";
import { CompanyUser } from "../../../../cores/company/interfaces/api/get-company";
import { InputWembii } from "../../InputWembii";
import { useContactCore } from "../../../../cores/contacts";
import { CheckboxWembii } from "../../CheckboxWembii";

export default function UserList() {
  const intl = useIntl();
  const { users } = useCompanyCore();
  const { addMassAssignment, removeMassAssignment, toggleReplaceAssigments, massAssignment, replaceAssignment } = useContactCore();
  const [filter, setFilter] = useState("");
  const [searchResults, setSearchResults] = useState<CompanyUser[]>([]);

  useEffect(() => {
    const results = users
      .filter(
        (user) =>
          user.firstName.toLowerCase().includes(filter.toLowerCase()) ||
          user.lastName.toLowerCase().includes(filter.toLowerCase()) ||
          user.email.toLowerCase().includes(filter.toLowerCase())
      );
    setSearchResults(results);
  }, [filter, users]);

  const isUserAssigned = useCallback((user) => 
    massAssignment.some((id) =>id === user.id)
  , [massAssignment]);

  const onClickingUser = useCallback((user) => {
    if (isUserAssigned(user)) {
      removeMassAssignment(user.id);
    } else {
      addMassAssignment(user.id);
    }
  }, [isUserAssigned, removeMassAssignment, addMassAssignment]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 py-5">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.activity.users" })}
            </h2>

            <CheckboxWembii
              label={intl.formatMessage({ id: "app.contacts.assignment.replace" })}
              checked={replaceAssignment}
              onChange={toggleReplaceAssigments}
            />

            <InputWembii
              type="text"
              placeholder={intl.formatMessage({ id: "app.activity.usersSearch" })}
              rounded
              onChange={(v) => setFilter(v)}
              icon={
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="1x"
                  color="grey"
                  className="pointer"
                />
              }
            />

            {searchResults.length === 0 && (
              <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center text-muted">
                <FontAwesomeIcon
                  icon={["fas", "users"]}
                  size="5x"
                  color="gray"
                />
                {intl.formatMessage({ id: "app.activity.thereNotUsers" })}
              </div>
            )}

            {searchResults.map((user) => (
              <div
                className="d-flex flex-row my-2 neu-in py-2 px-3"
                key={user.id}
              >
                <div className="text-center flex-grow-1 align-items-center d-flex flex-column">
                  <small className="mr-3">{user.email}</small>
                  <small>{`${user.firstName} ${user.lastName}`}</small>
                </div>
                <Button
                  className="ml-auto"
                  color={isUserAssigned(user) ? "secondary" : "primary"}
                  onClick={() => onClickingUser(user)}
                >
                  {!isUserAssigned(user)
                    ? intl.formatMessage({ id: "app.activity.usersAssign" })
                    : intl.formatMessage({ id: "app.activity.usersUnassign" })
                  }
                </Button>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
