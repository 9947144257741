import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { GetCompaniesParams } from "../interfaces/api/get-companies";
import { Contact, GetContactsParams } from "../interfaces/api/get-contacts";
import { ContactStateRequest } from "../interfaces/contact-state-request";

export const setHeadersAction = createAction(
  "[Contact] Set Headers",
  props<{ headers: Record<string, number> }>()
);
export const setContactStateRequestAction = createAction(
  "[Contact] Set Contact State Request Action",
  props<{ contactStateRequest:ContactStateRequest }>()
);

export const resetHeadersAction = createAction("[Contact] Reset Headers");

export const selectContactAction = createAction(
  "[Contact] Select contact",
  props<{ contact: Contact }>()
);

export const deselectContactAction = createAction(
  "[Contact] Deselect contact",
  props<{ id: string }>()
);

export const setSourceAction = createAction(
  "[Contact] Set source",
  props<{ source: string }>()
);

export const setContactFiltersAction = createAction(
  "[Contact] Set contact filters",
  props<{ filters: GetContactsParams }>()
);

export const setCompanyFiltersAction = createAction(
  "[Contact] Set company filters",
  props<{ filters: GetCompaniesParams }>()
);

export const deselectAllContactsAction = createAction(
  "[Contact] Deselect all contacts"
);

export const addMassAssignmentAction = createAction(
  "[Contact] Add assignment",
  props<{ id: string }>()
);

export const removeMassAssignmentAction = createAction(
  "[Contact] Remove assignment",
  props<{ id: string }>()
);

export const cleanMassAssignmentAction = createAction(
  "[Contact] Clean assignments"
);

export const setReplaceAssignmentAction = createAction(
  "[Contact] Set replace assignment",
  props<{ replace: boolean }>()
);

const actions = joinActions({
  setHeadersAction,
  resetHeadersAction,
  selectContactAction,
  deselectContactAction,
  deselectAllContactsAction,
  setContactFiltersAction,
  setCompanyFiltersAction,
  setSourceAction,
  setContactStateRequestAction,
  addMassAssignmentAction,
  removeMassAssignmentAction,
  cleanMassAssignmentAction,
  setReplaceAssignmentAction,
});

export type ContactAction = typeof actions;
