import React, { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { Container, FormGroup } from "reactstrap"
import { useMeCore } from "../../../../../cores/me"
import { LabelWembii } from "../../../LabelWembii"
import { PanelWembii } from "../../../PanelWembii"
import { TitleWembii } from "../../../TitleWembii"
import { useIntl } from "react-intl";

export default function InstalationForm() {
  const intl = useIntl();
  const { company } = useMeCore()

  const domain = process.env.REACT_APP_EMBEDDED_WHATSAPP_DOMAIN

  const [code] = useState(
    `<script src="${domain}"></script>\n` +
    `<script>window.loadWembiiWhatsapp("${btoa(company?.id!)}")</script>`,
  )

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(code);
    toast.success(`${intl.formatMessage({ id: "app.toast.sucess45" })}`);
  }, [code, intl]);

  return (
    <Container className="p-0"> 
      <PanelWembii shadow className="p-4 mb-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.editForm.instalation" })}
        </TitleWembii>
        <LabelWembii forid="copyCode">
          {intl.formatMessage({ id: "app.configWsppUsers.copyCode" })}
        </LabelWembii>
        <FormGroup className="d-flex align-items-start justify-content-between">
          <pre className="d-flex flex-column p-2 bg-light">
            {code}
          </pre>
          <div
            className="border border-white-50 text-dark shadow-sm btn btn-sm"
            color="white"
            id="copyCode"
            onClick={handleCopy}
          >
            {intl.formatMessage({ id: "app.configWsppUsers.copyCode" })}
          </div>
        </FormGroup>
      </PanelWembii>
    </Container>
  )
}

