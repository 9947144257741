import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Conversation, Message } from "../interfaces/conversations.interface";
import { ConversationStatus } from "../types/conversation-status.types";

export const getInstagramConversationsInitAction = createAction(
  "[SocialChat] Get Instagram Conversations Init"
);
export const selectConversationStatusToShow = createAction(
  "[SocialChat] Select Conversation Status To Show",
  props<{ conversationStatus: ConversationStatus }>()
);

export const getInstagramConversationsSuccessAction = createAction(
  "[SocialChat] Get Instagram Conversations Success",
  props<{
    active: Conversation[];
    archived: Conversation[];
    inTrash: Conversation[];
  }>()
);
export const getInstagramConversationsFailureAction = createAction(
  "[SocialChat] Get Instagram Conversations Failure",
  props<{ error: string }>()
);

export const getInstagramMessagesInitAction = createAction(
  "[SocialChat] Get Instagram Messages Init"
);
export const getInstagramMessagesSuccessAction = createAction(
  "[SocialChat] Get Instagram Messages Success",
  props<{ messages: Message[] }>()
);
export const getInstagramMessagesFailureAction = createAction(
  "[SocialChat] Get Instagram Messages Failure",
  props<{ error: string }>()
);

export const postInstagramMessageInitAction = createAction(
  "[SocialChat] Post Instagram Message Init"
);
export const postInstagramMessageSuccessAction = createAction(
  "[SocialChat] Post Instagram Message Success",
  props<{ message: Message; id: string }>()
);
export const postInstagramMessageFailureAction = createAction(
  "[SocialChat] Post Instagram Message Failure",
  props<{ error: string }>()
);

export const getFacebookConversationsInitAction = createAction(
  "[SocialChat] Get Facebook Conversations Init"
);
export const getFacebookConversationsSuccessAction = createAction(
  "[SocialChat] Get Facebook Conversations Success",
  props<{
    active: Conversation[];
    archived: Conversation[];
    inTrash: Conversation[];
  }>()
);
export const getFacebookConversationsFailureAction = createAction(
  "[SocialChat] Get Facebook Conversations Failure",
  props<{ error: string }>()
);

export const getFacebookMessagesInitAction = createAction(
  "[SocialChat] Get Facebook Messages Init"
);
export const getFacebookMessagesSuccessAction = createAction(
  "[SocialChat] Get Facebook Messages Success",
  props<{ messages: Message[] }>()
);
export const getFacebookMessagesFailureAction = createAction(
  "[SocialChat] Get Facebook Messages Failure",
  props<{ error: string }>()
);

export const postFacebookMessageInitAction = createAction(
  "[SocialChat] Post Facebook Message Init"
);
export const postFacebookMessageSuccessAction = createAction(
  "[SocialChat] Post Facebook Message Success",
  props<{
    message: Message;
    id: string;
  }>()
);
export const postFacebookMessageFailureAction = createAction(
  "[SocialChat] Post Facebook Message Failure",
  props<{ error: string }>()
);

export const postFacebookSeenMessageInitAction = createAction(
  "[SocialChat] Post Facebook Seen Message Init"
);
export const postFacebookSeenMessageSuccessAction = createAction(
  "[SocialChat] Post Facebook Seen Message Success",
  props<{ id: string }>()
);
export const postFacebookSeenMessageFailureAction = createAction(
  "[SocialChat] Post Facebook Seen Message Failure",
  props<{ error: string }>()
);

export const getWhatsappConversationsInitAction = createAction(
  "[SocialChat] Get Whatsapp Conversations Init"
);
export const getWhatsappConversationsSuccessAction = createAction(
  "[SocialChat] Get Whatsapp Conversations Success",
  props<{
    active: Conversation[];
    archived: Conversation[];
    inTrash: Conversation[];
  }>()
);

export const getWhatsappConversationsFailureAction = createAction(
  "[SocialChat] Get Whatsapp Conversations Failure",
  props<{ error: string }>()
);

export const getWhatsappMessagesInitAction = createAction(
  "[SocialChat] Get Whatsapp Messages Init"
);
export const getWhatsappMessagesSuccessAction = createAction(
  "[SocialChat] Get Whatsapp Messages Success",
  props<{ messages: Message[] }>()
);
export const getWhatsappMessagesFailureAction = createAction(
  "[SocialChat] Get Whatsapp Messages Failure",
  props<{ error: string }>()
);

export const postWhatsappMessageInitAction = createAction(
  "[SocialChat] Post Whatsapp Message Init"
);
export const postWhatsappMessageSuccessAction = createAction(
  "[SocialChat] Post Whatsapp Message Success",
  props<{
    message: Message;
    id: string;
  }>()
);
export const postWhatsappMessageFailureAction = createAction(
  "[SocialChat] Post Whatsapp Message Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getInstagramConversationsInitAction,
  getInstagramConversationsSuccessAction,
  getInstagramConversationsFailureAction,
  getInstagramMessagesInitAction,
  getInstagramMessagesSuccessAction,
  getInstagramMessagesFailureAction,
  postInstagramMessageInitAction,
  postInstagramMessageSuccessAction,
  postInstagramMessageFailureAction,
  getFacebookConversationsInitAction,
  getFacebookConversationsSuccessAction,
  getFacebookConversationsFailureAction,
  getFacebookMessagesInitAction,
  getFacebookMessagesSuccessAction,
  getFacebookMessagesFailureAction,
  postFacebookMessageInitAction,
  postFacebookMessageSuccessAction,
  postFacebookMessageFailureAction,
  postFacebookSeenMessageInitAction,
  selectConversationStatusToShow,
  postFacebookSeenMessageSuccessAction,
  postFacebookSeenMessageFailureAction,
  getWhatsappConversationsInitAction,
  getWhatsappConversationsSuccessAction,
  getWhatsappConversationsFailureAction,
  getWhatsappMessagesInitAction,
  getWhatsappMessagesSuccessAction,
  getWhatsappMessagesFailureAction,
  postWhatsappMessageInitAction,
  postWhatsappMessageSuccessAction,
  postWhatsappMessageFailureAction,
});

export type SocialChatApiAction = typeof actions;
