import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const advertisementsRoot = (state: State) => state.advertisements;

export const adAccountsSelector = createSelector(advertisementsRoot, (me) => 
    me.adAccounts.ids.map((id) => me.adAccounts.entities[id])
);
export const locationsSelector = createSelector(advertisementsRoot, (me) => 
    me.locations.ids.map((id) => me.locations.entities[id])
);
export const interestsSelector = createSelector(advertisementsRoot, (me) => 
    me.interests.ids.map((id) => me.interests.entities[id])
);
export const languagesSelector = createSelector(advertisementsRoot, (me) => 
    me.languages.ids.map((id) => me.languages.entities[id])
);
export const campaignsSelector = createSelector(advertisementsRoot, (me) => 
    me.campaigns.ids.map((id) => me.campaigns.entities[id])
);
export const adsetsSelector = createSelector(advertisementsRoot, (me) => 
    me.adsets.ids.map((id) => me.adsets.entities[id])
);
export const adsSelector = createSelector(advertisementsRoot, (me) => 
    me.ads.ids.map((id) => me.ads.entities[id])
);
export const languagesEntitiesSelector = createSelector(advertisementsRoot, (me) => me.languages.entities);
export const campaignSelector = createSelector(advertisementsRoot, (me) => me.campaign);
export const selectedAdAccountSelector = createSelector(advertisementsRoot, (me) => me.selectedAdAccount);
export const selectedImageSelector = createSelector(advertisementsRoot, (me) => me.image);
export const minBudgetSelector = createSelector(advertisementsRoot, (me) => me.minBudget);
export const deliveryEstimateSelector = createSelector(advertisementsRoot, (me) => me.deliveryEstimate);
