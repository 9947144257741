import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import {
  JUMPSELLER_GET_LOADING,
  JUMPSELLER_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useJumpsellerCore } from "../../../../cores/jumpseller";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import SocialCard from "../SocialCard";
import { JumpsellerIcon } from "./JumpsellerIcon";

export default function JumpSellerBindCard() {
  const intl = useIntl();
  const { get, settings, unbind, login } = useJumpsellerCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get]);

  const isActive = useMemo((): boolean => !_.isEmpty(settings), [settings]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Jumpseller,
      name: SocialProviderName.Jumpseller,
      icon: <JumpsellerIcon size="6x" active={isActive} />,
      displayName: settings?.name,
      url: settings?.url,
      avatar: settings?.avatarUrl,
    }),
    [isActive, settings]
  );

  const handleBind = useCallback(async () => {
    const loginUrl = await login(company!.id);
    if (loginUrl) window.open(loginUrl, "_self");
  }, [company, login]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  return (
    <SocialCard
      social={socialProvider}
      bindLoading={isLoading(JUMPSELLER_SYNC_LOADING)}
      getLoading={isLoading(JUMPSELLER_GET_LOADING)}
      showSynchronizer={false}
      remainingBindDays={null}
    >
      <Button
        className="w-100 mx-auto"
        color="primary"
        outline={isActive}
        onClick={!isActive ? handleBind : handleUnbind}
      >
        {!isActive
          ? intl.formatMessage({ id: "app.home.syncUp" })
          : intl.formatMessage({ id: "app.home.disconnect" })}
      </Button>
    </SocialCard>
  );
}
