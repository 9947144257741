import React, { useCallback } from "react";
import DatePicker from "react-date-picker";
import { useIntl } from "react-intl";
import { useBoardCore } from "../../../../cores/boards";
import { InputWembii } from "../../InputWembii";
import { LabelWembii } from "../../LabelWembii";

export default function QuoteDetails() {
  const intl = useIntl();
  const { quote, setQuote } = useBoardCore();

  const setQuoteConfig = useCallback((field) => {
    setQuote({ ...quote, ...field });
  }, [quote, setQuote]);

  return (
    <div>
      <InputWembii
        label={intl.formatMessage({ id: "app.crmBusiness.quote.name" })}
        type="text"
        name="name"
        id="name"
        required
        placeholder={intl.formatMessage({ id: "app.crmBusiness.quote.name" })}
        value={quote.name}
        onChange={(name) => setQuoteConfig({ name })}
      />

      <div className="mb-2">
        <LabelWembii required>
          {intl.formatMessage({ id: "app.crmBusiness.quote.expiration" })}
        </LabelWembii>
        <DatePicker
          className="w-100"
          format="dd/MM/y"
          value={quote.expirationDate}
          clearIcon={null}
          onChange={(expirationDate: Date) => setQuoteConfig({ expirationDate })}
        />
      </div>

      <InputWembii
        label={intl.formatMessage({ id: "app.crmBusiness.quote.comments" })}
        type="textarea"
        name="comments"
        id="comments"
        placeholder={intl.formatMessage({ id: "app.crmBusiness.quote.comments" })}
        style={{ minHeight: 150 }}
        value={quote.comments}
        onChange={(comments) => setQuoteConfig({ comments })}
      />
      
      <InputWembii
        label={intl.formatMessage({ id: "app.crmBusiness.quote.terms" })}
        type="textarea"
        name="terms"
        id="terms"
        placeholder={intl.formatMessage({ id: "app.crmBusiness.quote.terms" })}
        style={{ minHeight: 150 }}
        value={quote.terms}
        onChange={(terms) => setQuoteConfig({ terms })}
      />
    </div>
  );
}
