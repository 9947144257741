import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useBoardCore } from "../../../../cores/boards";
import { CardRow } from "../creator/CardRow";
import moment from "moment";
import { useMeCore } from "../../../../cores/me";
import { useBoardAnalyticsCore } from "../../../../cores/boardAnalytics";

export default function Opportunities() {
  const intl = useIntl();
  const { boardBusiness: board, getFilters } = useBoardCore();
  const { getBoardCards, cards, date, offset, hasMore } = useBoardAnalyticsCore();
  const { company } = useMeCore();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const loader = useRef(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore) {
      setPage((prev) => prev + 1);
    }
  }, [hasMore]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "10px",
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader && loader.current && observer)
      observer.observe(loader.current as unknown as Element);
  }, [handleObserver]);

  useEffect(() => {
    const getCards = async () => {
      setLoading(true);
      let filter = getFilters(board?.id!);

      filter = {
        ...filter,
        createdSince: moment(date).toISOString(),
        createdUntil: moment(date).toISOString(),
      };
      await getBoardCards(company?.id!, board?.id!, filter, offset);
      setLoading(false);
    };

    if (hasMore) {
      getCards();
    }
  }, [page, date, hasMore, offset, board, company, getFilters, getBoardCards]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.metricsCrm.cards" })}
            </h2>

            {cards.length === 0 ? (
              <div className="d-flex align-items-center justify-content-center h-100 text-muted">
                {intl.formatMessage({ id: "app.metricsCrm.noCards" })}
              </div>
            ) : (
              <Row>
                <Col md={{ offset: 6, size: 4 }} className="font-weight-bold">
                  {intl.formatMessage({ id: "app.opportunity.currentStage" })}
                </Col>
              </Row>
            )}
            {cards.map((card) => (
              <Suspense key={card.id} fallback={<div />}>
                <CardRow card={card} />
              </Suspense>
            ))}
          </div>
          {hasMore && <div ref={loader} />}
          {loading && (
            <div className="d-flex justify-content-center">
              <div className="lds-ellipsis">
                <div></div><div></div><div></div><div></div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
