import { produce } from "immer";
import { 
  AdvertisementsApiAction, 
  getAdAccountsFailureAction, 
  getAdAccountsInitAction, 
  getAdAccountsSuccessAction, 
  getLocationsFailureAction, 
  getLocationsInitAction, 
  getLocationsSuccessAction, 
  getInterestsFailureAction, 
  getInterestsInitAction,
  getInterestsSuccessAction,
  getLanguagesFailureAction,
  getLanguagesInitAction,
  getLanguagesSuccessAction,
  postCampaignFailureAction,
  postCampaignInitAction,
  postCampaignSuccessAction,
  getCampaignsInitAction,
  getCampaignsSuccessAction,
  getCampaignsFailureAction,
  getAdsetsInitAction,
  getAdsetsFailureAction,
  getAdsInitAction,
  getAdsFailureAction,
  getAdsetsSuccessAction,
  getAdsSuccessAction,
  getMinBudgetInitAction,
  getMinBudgetFailureAction,
  getMinBudgetSuccessAction,
  putCampaignFailureAction,
  putCampaignInitAction,
  putCampaignSuccessAction,
  putAdInitAction,
  putAdFailureAction,
  putAdsetInitAction,
  putAdsetFailureAction,
  putAdSuccessAction,
  putAdsetSuccessAction,
  getDeliveryEstimateInitAction,
  getDeliveryEstimateFailureAction,
  getDeliveryEstimateSuccessAction
} from "./api-actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Location } from "../interfaces/api/get-locations";
import { Interest } from "../interfaces/api/get-interests";
import { Language } from "../interfaces/api/get-languages";
import { AdvertisementsAction, resetCampaignAction, selectAdAccountAction, selectImageAction, setCampaignAction } from "./actions";
import { CampaignCreation } from "../interfaces/api/post-campaign";
import { AdAccount } from "../interfaces/api/get-ad-accounts";
import { Campaign } from "../interfaces/api/get-campaigns";
import { Adset } from "../interfaces/api/get-adsets";
import { Ad } from "../interfaces/api/get-ads";
import { MinBudget } from "../interfaces/api/get-min-budgets";
import { CampaignGoals } from "../constants/campaign-goals";
import { CampaignStatus } from "../constants/campaign-status";
import { BillingEvents } from "../constants/billing-events";
import { DeliveryEstimate } from "../interfaces/api/get-delivery-estimate";
import { GlobalActions, resetAction } from "../../globals/store/actions";

export interface AdvertisementsState {
  adAccounts: {
    ids: string[];
    entities: Record<string, AdAccount>;
  };
  selectedAdAccount: AdAccount | null;
  locations: {
    ids: string[];
    entities: Record<string, Location>;
  };
  interests: {
    ids: string[];
    entities: Record<string, Interest>;
  };
  languages: {
    ids: number[];
    entities: Record<number, Language>;
  };
  campaigns: {
    ids: string[];
    entities: Record<string, Campaign>;
  };
  adsets: {
    ids: string[];
    entities: Record<string, Adset>;
  };
  ads: {
    ids: string[];
    entities: Record<string, Ad>;
  };
  minBudget: MinBudget | null;
  deliveryEstimate: DeliveryEstimate | null;
  campaign: CampaignCreation;
  image: {
    file: File | null,
    url: string
  };
}

const initialState: AdvertisementsState = {
  adAccounts: {
    ids: [],
    entities: {},
  },
  selectedAdAccount: null,
  locations: {
    ids: [],
    entities: {},
  },
  interests: {
    ids: [],
    entities: {},
  },
  languages: {
    ids: [],
    entities: {},
  },
  campaigns: {
    ids: [],
    entities: {},
  },
  adsets: {
    ids: [],
    entities: {},
  },
  ads: {
    ids: [],
    entities: {},
  },
  minBudget: null,
  deliveryEstimate: null,
  campaign: {
    name: '',
    url: '',
    objective: CampaignGoals.LINK_CLICKS.id,
    optimizationGoal: BillingEvents.IMPRESSIONS.id,
    status: CampaignStatus.PAUSED.id,
    dailyBudget: 0,
    bidAmount: undefined,
    startTime: '',
    endTime: undefined,
    targeting: {
      locales: 23,
      countries: [],
      cities: [],
      interests: [],
      genders: [1, 2],
      ageMin: 18,
      ageMax: 65,
      publisherPlatforms: [],
      facebookPositions: [],
      instagramPositions: [],
    },
    titleBusiness: '',
    urlBusiness: '',
    description: '',
    form: {
      name: '',
      isOptimizedForQuality: 0,
      contextCard: {
        title: '',
        style: 'PARAGRAPH_STYLE',
        content: '',
      },
      privacyPolicy: {
        linkText: '',
        url: ''
      },
      thankYouPage: {
        title: '',
        body: '',
        buttonType: 'VIEW_WEBSITE',
        buttonText: '',
        websiteUrl: '',
      },
      questionPageCustomHeadline: '',
      customQuestions: [],
      autoQuestions: [],
    }
  },
  image: {
    file: null,
    url: "",
  },
};

const adAccountsAdapter = createEntityAdapter<AdAccount>(
  (payload) => payload.id
);
const locationsAdapter = createEntityAdapter<Location>(
  (payload) => payload.key
);
const interetsAdapter = createEntityAdapter<Interest>(
  (payload) => payload.id
);
const languagesAdapter = createEntityAdapter<Language>(
  (payload) => payload.key
);
const campaignsAdapter = createEntityAdapter<Campaign>(
  (payload) => payload.id
);
const adsetsAdapter = createEntityAdapter<Adset>(
  (payload) => payload.id
);
const adsAdapter = createEntityAdapter<Ad>(
  (payload) => payload.id
);

export default produce(
  (draft: AdvertisementsState = initialState, action: AdvertisementsApiAction | AdvertisementsAction | GlobalActions) => {
    switch (action.type) {
      case setCampaignAction.type: 
        draft.campaign = action.campaign;
        return draft;
      case selectAdAccountAction.type:
        draft.selectedAdAccount = draft.adAccounts.entities[action.id];
        return draft;
      case selectImageAction.type:
        draft.image = { file: action.file, url: action.url };
        return draft;
      case getAdAccountsSuccessAction.type:
        adAccountsAdapter.addMany(action.adAccounts, draft.adAccounts);
        if (action.adAccounts.length) {
          draft.selectedAdAccount = action.adAccounts[0]
        }
        return draft;
      case getLocationsSuccessAction.type:
        locationsAdapter.addMany(action.locations, draft.locations);
        return draft;
      case getInterestsSuccessAction.type:
        interetsAdapter.addMany(action.interests, draft.interests);
        return draft;
      case getLanguagesSuccessAction.type:
        languagesAdapter.addMany(action.languages, draft.languages);
        return draft;
      case getCampaignsSuccessAction.type:
        campaignsAdapter.addMany(action.campaigns, draft.campaigns);
        return draft;
      case getAdsetsSuccessAction.type:
        adsetsAdapter.addMany(action.adsets, draft.adsets);
        return draft;
      case getAdsSuccessAction.type:
        adsAdapter.addMany(action.ads, draft.ads);
        return draft;
      case getMinBudgetSuccessAction.type:
        draft.minBudget = action.minBudget;
        return draft;
      case getDeliveryEstimateSuccessAction.type:
        draft.deliveryEstimate = action.estimate;
        return draft;
      case putCampaignSuccessAction.type:
        const campaign = draft.campaigns.entities[action.campaign];
        campaignsAdapter.updateOne({ ...campaign, status: action.status }, draft.campaigns); 
        return draft;
      case putAdsetSuccessAction.type:
        const adset = draft.adsets.entities[action.adset];
        adsAdapter.updateOne({ ...adset, status: action.status, dailyBudget: action.dailyBudget }, draft.adsets); 
        return draft;
      case putAdSuccessAction.type:
        const ad = draft.ads.entities[action.ad];
        adsAdapter.updateOne({ ...ad, status: action.status }, draft.ads); 
        return draft;
      case resetCampaignAction.type:
        return initialState;
      case resetAction.type:
        return initialState;
      case getAdAccountsInitAction.type:
      case getAdAccountsFailureAction.type:
      case getLocationsInitAction.type:
      case getLocationsFailureAction.type:
      case getInterestsInitAction.type:
      case getInterestsFailureAction.type:
      case getLanguagesInitAction.type:
      case getLanguagesFailureAction.type:
      case getCampaignsInitAction.type:
      case getCampaignsFailureAction.type:
      case getAdsetsInitAction.type:
      case getAdsetsFailureAction.type:
      case getAdsInitAction.type:
      case getAdsFailureAction.type:
      case getMinBudgetInitAction.type:
      case getMinBudgetFailureAction.type:
      case getDeliveryEstimateInitAction.type:
      case getDeliveryEstimateFailureAction.type:
      case postCampaignInitAction.type:
      case postCampaignFailureAction.type:
      case postCampaignSuccessAction.type:
      case putCampaignInitAction.type:
      case putCampaignFailureAction.type:
      case putAdsetInitAction.type:
      case putAdsetFailureAction.type:
      case putAdInitAction.type:
      case putAdFailureAction.type:
      default:
        return draft;
    }
  }
);