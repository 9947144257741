import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { WhatsappSetting } from "../interface/api/post-whatsapp-bind";
import {
  getWhatsappFailureAction,
  getWhatsappInitAction,
  getWhatsappSuccessAction,
  postBindWhatsappFailureAction,
  postBindWhatsappInitAction,
  postBindWhatsappSuccessAction,
  completeAuth0Verification,
  postUnbindWhatsappFailureAction,
  postUnbindWhatsappInitAction,
  postUnbindWhatsappSuccessAction,
  WhatsappApiAction,
} from "./api-actions";

export interface WhatsappState {
  settings: WhatsappSetting | null;
  error: string;
  code: string;
}

const initialState: WhatsappState = {
  settings: null,
  error: "",
  code: "",
};

export default produce(
  (
    draft: WhatsappState = initialState,
    action: WhatsappApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case completeAuth0Verification.type:
        draft.code = action.code;
        return draft;
      case postBindWhatsappSuccessAction.type:
      case getWhatsappSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindWhatsappFailureAction.type:
      case postBindWhatsappFailureAction.type:
      case getWhatsappFailureAction.type:
        draft.error = action.error;
        return draft;
      case postUnbindWhatsappSuccessAction.type:
        draft.settings = null;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindWhatsappInitAction.type:
      case postUnbindWhatsappInitAction.type:
      case getWhatsappInitAction.type:
      default:
        return draft;
    }
  }
);
