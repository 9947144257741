import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Audience } from "../interface/api/get-mailchimp-audiences";
import { MailchimpSetting } from "../interface/api/post-mailchimp-bind";

export const postUnbindMailchimpInitAction = createAction(
  "[Mailchimp] Unbind Mailchimp Init"
);
export const postUnbindMailchimpSuccessAction = createAction(
  "[Mailchimp] Unbind Mailchimp Success"
);
export const postUnbindMailchimpFailureAction = createAction(
  "[Mailchimp] Unbind Mailchimp Failure",
  props<{ error: string }>()
);

export const postBindMailchimpInitAction = createAction(
  "[Mailchimp] Bind Mailchimp Init"
);
export const postBindMailchimpSuccessAction = createAction(
  "[Mailchimp] Bind Mailchimp Success",
  props<{ settings: MailchimpSetting }>()
);
export const postBindMailchimpFailureAction = createAction(
  "[Mailchimp] Bind Mailchimp Failure",
  props<{ error: string }>()
);

export const getMailchimpInitAction = createAction(
  "[Mailchimp] Get Mailchimp Settings Init"
);
export const getMailchimpSuccessAction = createAction(
  "[Mailchimp] Get Mailchimp Settings Success",
  props<{ settings: MailchimpSetting }>()
);
export const getMailchimpFailureAction = createAction(
  "[Mailchimp] Get Mailchimp Settings Failure",
  props<{ error: string }>()
);

export const getMailchimpAudiencesInitAction = createAction(
  "[Mailchimp] Get Mailchimp Audiences Init"
);
export const getMailchimpAudiencesSuccessAction = createAction(
  "[Mailchimp] Get Mailchimp Audiences Success",
  props<{ audiences: Audience[] }>()
);
export const getMailchimpAudiencesFailureAction = createAction(
  "[Mailchimp] Get Mailchimp Audiences Failure",
  props<{ error: string }>()
);

export const postMailchimpAudienceInitAction = createAction(
  "[Mailchimp] Post Mailchimp Audience Init"
);
export const postMailchimpAudienceSuccessAction = createAction(
  "[Mailchimp] Post Mailchimp Audience Success",
  props<{ audience: Audience }>()
);
export const postMailchimpAudienceFailureAction = createAction(
  "[Mailchimp] Post Mailchimp Audience Failure",
  props<{ errorCode: number | null, errorMessage: string | null }>()
);

export const postMailchimpSyncContactsInitAction = createAction(
  "[Mailchimp] Post Mailchimp Sync Contacts Init"
);
export const postMailchimpSyncContactsSuccessAction = createAction(
  "[Mailchimp] Post Mailchimp Sync Contacts Success"
);
export const postMailchimpSyncContactsFailureAction = createAction(
  "[Mailchimp] Post Mailchimp Sync Contacts Failure",
  props<{ errorCode: number | null, errorMessage: string | null }>()
);


const actions = joinActions({
  postUnbindMailchimpInitAction,
  postUnbindMailchimpSuccessAction,
  postUnbindMailchimpFailureAction,
  postBindMailchimpInitAction,
  postBindMailchimpSuccessAction,
  postBindMailchimpFailureAction,
  getMailchimpInitAction,
  getMailchimpSuccessAction,
  getMailchimpFailureAction,
  getMailchimpAudiencesInitAction,
  getMailchimpAudiencesSuccessAction,
  getMailchimpAudiencesFailureAction,
  postMailchimpAudienceInitAction,
  postMailchimpAudienceSuccessAction,
  postMailchimpAudienceFailureAction,
  postMailchimpSyncContactsInitAction,
  postMailchimpSyncContactsSuccessAction,
  postMailchimpSyncContactsFailureAction,
});

export type MailchimpApiAction = typeof actions;
