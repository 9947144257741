import { GetGoogleCalendarLoginResponse } from "../interface/api/get-google-calendar-login";
import {
  GoogleCalendarSetting,
  PostGoogleCalendarBindResponse,
} from "../interface/api/post-google-calendar-bind";

export const googleCalendarSettingMapper = {
  mapResponseToGoogleCalendarSetting(response: PostGoogleCalendarBindResponse): GoogleCalendarSetting {
    const { googleCalendarSetting } = response?.payload;
    return googleCalendarSetting;
  },

  mapResponseToGoogleCalendarLoginUrl(
    response: GetGoogleCalendarLoginResponse
  ): { url: string } {
    const { url } = response?.payload;
    return { url };
  },
};
