import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  filesSelector,
  messageSelector,
  subjectSelector,
} from "./store/selector";
import {
  clearAction,
  deleteFileAction,
  setFilesAction,
  setMessageAction,
  setSubjectAction,
  updateFileAction
} from "./store/actions";
import { EmailFile } from "./interface/emailFile";
import { emailApiService } from "./services/api";

export function useEmailCore() {
  const dispatch = useDispatch();
  const files = useSelector(filesSelector);
  const message = useSelector(messageSelector);
  const subject = useSelector(subjectSelector);

  const setMessage = useCallback((message: string) => dispatch(setMessageAction({ message })), [
    dispatch,
  ]);

  const addFiles = useCallback((files: EmailFile[]) => dispatch(setFilesAction({ files })), [
    dispatch,
  ]);

  const updateFile = useCallback((file: EmailFile) => dispatch(updateFileAction({ file })), [
    dispatch,
  ]);

  const deleteFile = useCallback((id: string) => dispatch(deleteFileAction({ id })), [
    dispatch,
  ]);

  const setSubject = useCallback(
    (subject: string) => dispatch(setSubjectAction({ subject })), 
    [dispatch]
  );

  const reset = useCallback(() => dispatch(clearAction()), [
    dispatch,
  ]);

  const postEmailTextEditorImage = useCallback(
    async (image: File) => {
      try {
        const { link } = await emailApiService.postEmailTextEditorImage(image);
        return { data: { link } };
      } catch (error: any) {
        console.error(error);
      }
    },
    []
  );

  return {
    files,
    subject,
    message,
    addFiles,
    deleteFile,
    updateFile,
    reset,
    setSubject,
    setMessage,
    postEmailTextEditorImage
  };
}
