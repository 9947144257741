import React from "react";
import {  Button, ButtonGroup } from "reactstrap";
import { View } from "../../cores/facebookForm/constants/view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  view: string,
  setView: (arg0: string) => void,
}

export const ViewButtons = (props: Props) => {
  const { view, setView } = props;

  return (
    <ButtonGroup className="view-style-buttons">
      <Button
        className="lbutton"
        onClick={() => setView(View.GRID)}
        outline={view === View.GRID}
      >
        <FontAwesomeIcon icon={["fas", "table-cells-large"]} />
      </Button>
      <Button
        className="rbutton"
        onClick={() => setView(View.LIST)}
        outline={view === View.LIST}
      >
        <FontAwesomeIcon icon={["fas", "table-list"]} />
      </Button>
    </ButtonGroup>
  );
};
