import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Col, Container, Row } from "reactstrap";
import { QUOTE_CARD_MODAL } from "../../../../constants/modal.constant";
import { useBoardCore } from "../../../../cores/boards";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { createQuotePDF } from "../../../utils/quotePdfUtils";
import GmailButton from "../../gmail/GmailButton";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";
import QuoteBuyerInfo from "../creator/QuoteBuyerInfo";
import QuoteDetails from "../creator/QuoteDetails";
import QuoteMeInfo from "../creator/QuoteMeInfo";
import QuoteProducts from "../creator/QuoteProducts";
import QuoteSignature from "../creator/QuoteSignature";
import { Contact } from "../../../../cores/contacts/interfaces/api/get-contact";

const steps = [
  { id: 1, name: "app.crmBusiness.quote.details" },
  { id: 2, name: "app.crmBusiness.quote.buyerInfo" },
  { id: 3, name: "app.crmBusiness.quote.sellerInfo" },
  { id: 4, name: "app.crmBusiness.quote.lineItems" },
  { id: 5, name: "app.crmBusiness.quote.signature" },
];

export default function QuoteForm() {
  const intl = useIntl();
  const { quote, boardBusiness: board, card, postQuote } = useBoardCore();
  const { company } = useMeCore();
  const { turnOffModal } = useGlobalCore();
  const [step, setStep] = useState(1);
  const [showEmailButton, setShowEmailButton] = useState(false);
  const [quoteFile, setQuoteFile] = useState<File>();
  const [loading, setLoading] = useState(false);

  const nextDisabled = useMemo(() => {
    switch (step) {
      case 1:
        return !quote.name || !quote.expirationDate;
      case 2:
        return false;
      case 3:
        return false;
      case 4:
        return false;
      case 5:
        return false;
    }
  }, [step, quote]);

  const form = useMemo(() => {
    switch (step) {
      case 1:
        return <QuoteDetails />;
      case 2:
        return <QuoteBuyerInfo />;
      case 3:
        return <QuoteMeInfo />;
      case 4:
        return <QuoteProducts />;
      case 5:
        return <QuoteSignature />;
    }
  }, [step]);

  const createPdf = useCallback(() => {
    setLoading(true);
    createQuotePDF(quote).getBlob((blob) => {
      postQuote(
        company?.id!,
        board?.id!,
        card?.list!,
        card?.id!,
        {
          id: quote.id,
          name: quote.name,
          creationDate: quote.creationDate!,
          expirationDate: quote.expirationDate!,
          pdf: blob,
        },
        () => {
          setShowEmailButton(true);
          setLoading(false);
          setQuoteFile(
            new File([blob], `quote_${quote.name.replaceAll(" ", "_")}.pdf`)
          );
        }
      );
    });
  }, [quote, company, board, card, postQuote]);

  const previewPdf = useCallback(() => {
    createQuotePDF(quote).open();
  }, [quote]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column justify-content-between h-100 py-5">
            <div>
              <h2 className="h2 text-center mb-4">
                {intl.formatMessage({ id: "app.crmBusiness.quote" })}
              </h2>
              <h5 className="text-muted text-center mb-1">
                {intl.formatMessage({ id: steps[step - 1].name })}
              </h5>
              <div className="text-muted text-center mb-3">
                {step} / {steps.length}
              </div>

              {form}
            </div>

            <Row className="mt-3">
              <Col>
                {step > 1 && (
                  <Button color="white" onClick={() => setStep(step - 1)}>
                    {intl.formatMessage({ id: "app.crmBusiness.quote.goBack" })}
                  </Button>
                )}
              </Col>
              <Col className="d-flex justify-content-end">
                {step < steps.length && (
                  <Button
                    color="white"
                    onClick={() => setStep(step + 1)}
                    disabled={nextDisabled}
                  >
                    {intl.formatMessage({ id: "app.crmBusiness.quote.next" })}
                  </Button>
                )}
                {step === steps.length &&
                  (loading ? (
                    <LoadingSpinnerWembii active />
                  ) : (
                    <>
                      <Button
                        color="white"
                        onClick={previewPdf}
                        className="mr-2"
                      >
                        {intl.formatMessage({
                          id: "app.crmBusiness.quote.preview",
                        })}
                      </Button>
                      {!showEmailButton ? (
                        <Button color="white" onClick={createPdf}>
                          {intl.formatMessage({
                            id: "app.crmBusiness.quote.create",
                          })}
                        </Button>
                      ) : (
                        <GmailButton
                          contacts={quote.contacts as Contact[]}
                          onSubmit={() => turnOffModal(QUOTE_CARD_MODAL)}
                          cardId={card?.id!}
                          quote={quoteFile}
                        />
                      )}
                    </>
                  ))}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
