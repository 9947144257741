import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { v4 } from "uuid";
import { useMeCore } from "../../../../../cores/me";
import { useWebFormCore } from "../../../../../cores/webForm";
import { useIntl } from "react-intl";
import {
  CompanyInputType,
  ContactInputType,
  InputType,
} from "../../../../../cores/webForm/constants/InputType.constant";
import { CheckboxWembii } from "../../../CheckboxWembii";
import { InputWembii } from "../../../InputWembii";
import { PanelWembii } from "../../../PanelWembii";
import { TitleWembii } from "../../../TitleWembii";
import Icofont from "react-icofont";
import { useContactCore } from "../../../../../cores/contacts";

export default function InputsForm() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { webForm, putFormInputs, addInput, removeInput, updateInput } =
    useWebFormCore();
  const { customFields } = useContactCore();
  const [hasChange, setHasChange] = useState(false);

  const handlerAddInput = useCallback(async () => {
    addInput({
      id: v4(),
      type: InputType.TEXT.id,
      placeholder: `${intl.formatMessage({ id: "app.editForm.helpTxt" })}`,
      required: true,
    });
    setHasChange(true);
  }, [addInput, setHasChange, intl]);

  const handleConfigChange = useCallback(
    (input) => {
      updateInput(input);
      setHasChange(true);
    },
    [updateInput]
  );

  const handlerRemoveInput = useCallback(
    async (id) => {
      removeInput(id);
      setHasChange(true);
    },
    [removeInput, setHasChange]
  );

  const handleChangeInput = useCallback(async () => {
    await putFormInputs(company?.id!, webForm.form?.id!, webForm.inputs);
    setHasChange(false);
  }, [putFormInputs, company, webForm, setHasChange]);

  const options = useMemo(
    () => [
      ...Object.values(ContactInputType).map((type) => ({
        value: `${type.id}`,
        label: intl.formatMessage({ id: type.name }),
      })),
      ...customFields.map((field) => ({
        value: `${field.id}`,
        label: `${intl.formatMessage({
          id: "app.chatConfigCommertial.customTxt",
        })} - ${field.name}`,
      })),
      ...Object.values(CompanyInputType).map((type) => ({
        value: `${type.id}`,
        label: intl.formatMessage({ id: type.name }),
      })),
      ...Object.values(InputType).map((type) => ({
        value: `${type.id}`,
        label: intl.formatMessage({ id: type.name }),
      })),
    ],
    [customFields, intl]
  );

  return (
    <Container className="p-0">
      <PanelWembii shadow className="mb-4 p-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.editForm.tittle3" })}
        </TitleWembii>
        <div className="w-100">
          {webForm.inputs.map((input) => (
            <Row className="h-100">
              <Col sm="3" xs="6">
                <InputWembii
                  type="select"
                  name="inputType"
                  id="inputType"
                  value={input.type}
                  flat
                  onChange={(v) => handleConfigChange({ ...input, type: v })}
                >
                  {options.map((type) => (
                    <option
                      key={`${type.value}${input.type}`}
                      value={type.value}
                    >
                      {type.label}
                    </option>
                  ))}
                </InputWembii>
              </Col>
              <Col sm="4" xs="6">
                <InputWembii
                  type="text"
                  name="placeholder"
                  id="placeholder"
                  placeholder="Texto de ayuda"
                  value={input.placeholder}
                  flat
                  onChange={(v) =>
                    handleConfigChange({ ...input, placeholder: v })
                  }
                />
              </Col>
              <Col sm="3" xs="6" className="d-flex align-items-center">
                <CheckboxWembii
                  label={intl.formatMessage({
                    id: "app.editForm.requiredCheck",
                  })}
                  name="required"
                  checked={input.required}
                  mb={false}
                  onChange={(v) =>
                    handleConfigChange({ ...input, required: v })
                  }
                />
              </Col>
              <Col sm="2" xs="6" className="d-flex justify-content-end">
                <div
                  onClick={() => handlerRemoveInput(input.id)}
                  className="m-2 text-danger pointer"
                >
                  <Icofont icon="ui-delete" />
                </div>
              </Col>
            </Row>
          ))}
          <div className="text-center">
            <Button color="primary" onClick={handlerAddInput}>
              <Icofont icon="plus" />
            </Button>
            <Button
              color="primary"
              className="ml-2"
              onClick={handleChangeInput}
              disabled={!hasChange}
            >
              {intl.formatMessage({ id: "app.editForm.saveConfigBtn" })}
            </Button>
          </div>
        </div>
      </PanelWembii>
    </Container>
  );
}
