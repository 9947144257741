import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { INSTAGRAM_INSIGHT_LOADING } from "../../constants/loading.constant";
import {
  ANALYTICS_EXPAND,
  ANALYTICS_POST_EXPAND,
  ANALYTICS_POST_GROUP_EXPAND,
} from "../../constants/modal.constant";
import { useAnalyticsCore } from "../../cores/analytics";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { AnalyticsDatePicker } from "../component/analytics/AnalyticsDatePicker";
import InstagramPosts from "../component/analytics/feed/InstagramPosts";
import { InstagramCharts } from "../component/analytics/InstagramCharts";
import { InstagramIcon } from "../component/social/Instagram/InstagramIcon";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";
import { Col, Label, Row } from "reactstrap";
import { PanelWembii } from "../component/PanelWembii";
import { ErrorIcon } from "../component/socialPost/ErrorIcon";
import { Errors } from "../../constants/errors";

export const InstagramAnalytics = () => {
  const intl = useIntl();
  const { getInstagramInsights, date, instagramErrorCode } = useAnalyticsCore();
  const {
    isLoading,
    isModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
  } = useGlobalCore();
  const {
    company,
    socialSettings,
    companyPlan,
    companyRole,
    isAdmin,
    isReseller,
  } = useMeCore();
  const { goToSocial } = useRouterCore();

  const isSync = useMemo(
    () => socialSettings && socialSettings.instagram,
    [socialSettings]
  );

  useEffect(() => {
    turnOffModal(ANALYTICS_POST_EXPAND);
    turnOffModal(ANALYTICS_POST_GROUP_EXPAND);
  }, [turnOffModal]);

  const error = useMemo(() => {
    switch (instagramErrorCode) {
      case Errors.PermissionsMissing:
        return (
          <Label className="text-muted text-center my-4 w-100">
            {intl.formatMessage({ id: "app.social.permissionsError" })}.
          </Label>
        );
    }
  }, [instagramErrorCode, intl]);

  useEffect(() => {
    if (
      !isSync ||
      !companyPlan?.rules.allowAnalytics ||
      (!companyRole?.analitycs &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [goToSocial, isSync, companyPlan, companyRole, isAdmin, isReseller]);

  const isFutureDate = (date: string) => moment(date).isAfter(moment());

  const areDatesInverted = (since: string, until: string) =>
    moment(since).isAfter(moment(until));

  useEffect(() => {
    if (
      isSync &&
      !isFutureDate(date.since) &&
      !isFutureDate(date.until) &&
      !areDatesInverted(date.since, date.until)
    ) {
      getInstagramInsights(company!.id, date.since, date.until, "Daily");
      getInstagramInsights(company!.id, date.since, date.until, "Lifetime", 10);
    }
  }, [company, date, getInstagramInsights, isSync]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.analiyticsInst.title" })}
        icon={<InstagramIcon active size="3x" />}
        rightChildren={<AnalyticsDatePicker />}
      />
      {isLoading(INSTAGRAM_INSIGHT_LOADING) ? (
        <UpdateLoadingCard
          loadingId={INSTAGRAM_INSIGHT_LOADING}
          text={intl.formatMessage({ id: "app.analiyticsInst.updatingMetric" })}
        />
      ) : (
        <Content>
          {instagramErrorCode && (
            <Row className="d-flex justify-content-center">
              <Col xs="12" md="8">
                <PanelWembii className="mb-2">
                  <div className="d-flex justify-content-center align-items-center">
                    <ErrorIcon size="2x" className="m-2 ml-4" />
                    {error}
                  </div>
                </PanelWembii>
              </Col>
            </Row>
          )}

          <InstagramCharts />
        </Content>
      )}
      <SideModal
        active={isModal(ANALYTICS_POST_EXPAND)}
        visible={getModalVisibility(ANALYTICS_POST_EXPAND)}
        onClose={() => turnOffModal(ANALYTICS_POST_EXPAND)}
      >
        {getModalContent(ANALYTICS_POST_EXPAND)}
      </SideModal>
      <SideModal
        active={isModal(ANALYTICS_EXPAND)}
        visible={getModalVisibility(ANALYTICS_EXPAND)}
        onClose={() => turnOffModal(ANALYTICS_EXPAND)}
        left
      >
        {getModalContent(ANALYTICS_EXPAND)}
      </SideModal>
      <SideModal
        active={isModal(ANALYTICS_POST_GROUP_EXPAND)}
        visible={getModalVisibility(ANALYTICS_POST_GROUP_EXPAND)}
        onClose={() => turnOffModal(ANALYTICS_POST_GROUP_EXPAND)}
        left
      >
        <InstagramPosts />
      </SideModal>
    </Layout>
  );
};
