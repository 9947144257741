import React from "react";
import { Badge } from "reactstrap";
import {
  WooCommerceOrderStatus,
  WooCommerceOrderStatusColor,
  WooCommerceOrderStatusDic,
} from "../../../../cores/market/woocommerce.config";

interface Props {
  status: WooCommerceOrderStatus;
}

export const WooCommerceOrderStatusBadge = ({ status }: Props) => {
  return (
    <Badge
      pill
      className="p-2 px-3"
      style={{ backgroundColor: WooCommerceOrderStatusColor[status] }}
    >
      {WooCommerceOrderStatusDic[status]}
    </Badge>
  );
};
