import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const eventRoot = (state: State) => state.event;

export const descriptionSelector = createSelector(eventRoot, ({ description }) => description);

export const titleSelector = createSelector(eventRoot, ({ title }) => title);

export const timezoneSelector = createSelector(eventRoot, ({ timezone }) => timezone);

export const eventsSelector = createSelector(eventRoot, ({ events }) =>
  events.ids.map((id) => events.entities[id])
);

export const hoursSelector = createSelector(eventRoot, ({ hours }) =>
  hours.ids.map((id) => hours.entities[id])
);

export const inputsSelector = createSelector(eventRoot, ({ inputs }) =>
  inputs.ids.map((id) => inputs.entities[id])
);

export const eventSelector = createSelector(eventRoot, ({ event }) => event);

export const publicCalendarSelector = createSelector(eventRoot, ({ publicCalendar }) => publicCalendar);

export const timeSelector = createSelector(eventRoot, ({ availability }) => availability);

export const calendarSelector = createSelector(eventRoot, ({ calendar }) => calendar);

export const errorCodeSelector = createSelector(eventRoot, (me) => me.errorCode);

export const errorMessageSelector = createSelector(eventRoot, (me) => me.errorMessage);

export const commentsSelector = createSelector(eventRoot, (me) => {
  const comments = me.comments || { ids: [] };
  return comments.ids.map((id) => me.comments.entities[id])
});
