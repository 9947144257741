import cn from "classnames";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import generic1 from "../../assets/noData/generic-1.svg";
import generic2 from "../../assets/noData/generic-2.svg";
import generic3 from "../../assets/noData/generic-3.svg";
import generic4 from "../../assets/noData/generic-4.svg";
import generic5 from "../../assets/noData/generic-5.svg";
import generic6 from "../../assets/noData/generic-6.svg";

interface Props {
  image?: "generic1" | "generic2" | "generic3" | "generic4" | "generic5" | "generic6";
  text?: string;
  actionLabel?: string;
  action?: () => void;
  flip?: boolean;
  horizontal?: boolean;
}

const images: Record<string, string> = {
  generic1,
  generic2,
  generic3,
  generic4,
  generic5,
  generic6,
};

export const NoData = (props: Props) => {
  const {
    image = "generic1",
    text,
    action,
    actionLabel,
    flip,
    horizontal,
  } = props;
  const src = images[image] || images["generic1"];
  return (
    <Row className="mt-4 mx-2 px-5">
      <Col
        xs="12"
        lg={horizontal ? "12" : "6"}
        className={cn({ "order-2": flip })}
      >
        <div className="d-flex justify-content-center h-100 align-items-center">
          <img
            src={src}
            alt={image}
            style={{ maxWidth: "750px", maxHeight: "440px", objectFit: "contain" }}
          />
        </div>
      </Col>
      {text && (
        <Col
          xs="12"
          lg={horizontal ? "12" : "6"}
          className={cn({
            "mt-5": horizontal,
            "mt-4 mt-lg-0": !horizontal,
            "order-1": flip,
          })}
        >
          <div className="d-flex flex-column justify-content-center h-100 align-items-center w-100">
            <h4 className="h4 text-center">{text}</h4>
            {actionLabel && action && (
              <Button color="primary" outline className="my-3" onClick={action}>
                <h5 className="h5 m-0"> {actionLabel}</h5>
              </Button>
            )}
          </div>
        </Col>
      )}
    </Row>
  );
};
