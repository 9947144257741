import { Dictionary } from "lodash";
import { COLOR_BLACK, COLOR_DANGER, COLOR_SUCCESS, COLOR_WARNING } from "../../constants/theme";

export enum TaskStatus {
  Incompleted = 0,
  Completed = 1,
  Overdue = 2,
}

export const TaskStatusOptions: Dictionary<{value: number, label: string}> = {
  [TaskStatus.Completed]: { value: TaskStatus.Completed, label: "Completa" },
  [TaskStatus.Incompleted]: { value: TaskStatus.Incompleted, label: "Incompleta" },
  [TaskStatus.Overdue]: { value: TaskStatus.Overdue, label: "Vencida" },
};

export enum TaskType {
  Other = 1,
  Call = 2,
  Email = 3,
}

export const TypeOptions: Dictionary<{value: number, label: string}> = {
  [TaskType.Other]: { value: TaskType.Other, label: "Otro" },
  [TaskType.Call]: { value: TaskType.Call, label: "Llamada" },
  [TaskType.Email]: { value: TaskType.Email, label: "Correo" },
};

export enum TaskPriority {
  None = 1,
  High = 2,
  Medium = 3,
  Low = 4
}

export const PriorityOptions: Dictionary<{value: number, label: string, color: string}> = {
  [TaskPriority.None]: { value: TaskPriority.None, label: "Ninguna", color: COLOR_BLACK },
  [TaskPriority.High]: { value: TaskPriority.High, label: "Alta", color: COLOR_DANGER },
  [TaskPriority.Medium]: { value: TaskPriority.Medium, label: "Media", color: COLOR_WARNING },
  [TaskPriority.Low]: { value: TaskPriority.Low, label: "Baja", color: COLOR_SUCCESS },
};

export enum TaskReminder {
  None = 1,
  DueTime = 2,
  OneHour = 3,
  OneDay = 4,
  OneWeek = 5,
  Custom = 6,
}

export const ReminderOptions: Dictionary<{value: number, label: string}> = {
  [TaskReminder.None]: { value: TaskReminder.None, label: "No hay recordatorio" },
  [TaskReminder.DueTime]: { value: TaskReminder.DueTime, label: "A la hora del vencimiento de la tarea" },
  [TaskReminder.OneHour]: { value: TaskReminder.OneHour, label: "1 hora antes" },
  [TaskReminder.OneDay]: { value: TaskReminder.OneDay, label: "1 día antes" },
  [TaskReminder.OneWeek]: { value: TaskReminder.OneWeek, label: "1 semana antes" },
  [TaskReminder.Custom]: { value: TaskReminder.Custom, label: "Fecha personalizada" },
};