import React, { useEffect } from "react";
import {
  FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT,
  GOOGLE_PROJECTS_SIDE_MODAL,
  INSTAGRAM_BIND_MODAL,
  INSTAGRAM_MENSSEGER_BIND_MODAL,
  LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT,
  MAILCHIMP_AUDIENCES_SIDE_MODAL,
  MERCADO_COUNTRIES,
  MORE_INFO_SOCIAL,
  OPENAI_SIDE_MODAL_RIGHT,
  SMTP_BIND_MODAL,
  WOO_COMMERCE_BIND_FORM,
} from "../../constants/modal.constant";
import {
  INSTAGRAM_SYNC_LOADING,
  FACEBOOK_SYNC_LOADING,
  LINKED_IN_BUSINESS_SYNC_LOADING,
  GOOGLE_SYNC_LOADING,
  WOO_COMMERCE_SYNC_LOADING,
  FACEBOOK_ADS_SYNC_LOADING,
  INSTAGRAM_MENSSEGER_SYNC_LOADING,
  SMTP_SYNC_LOADING,
  OPENAI_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import FacebookBindCard from "../component/social/Facebook/FacebookBindCard";
import FacebookAdsBindCard from "../component/social/FacebookAds/FacebookAdsBindCard";
import GoogleBindCard from "../component/social/GoogleAnalitycs/GoogleBindCard";
import GoogleProjects from "../component/social/GoogleAnalitycs/GoogleProjects";
import InstagramBindCard from "../component/social/Instagram/InstagramBindCard";
import LinkedInBindCard from "../component/social/LinkedIn/LinkedInBindCard";
import LinkedInBusinessBindCard from "../component/social/LinkedIn/LinkedInBusinessBindCard";
import MercadolibreBindCard from "../component/social/Mercadolibre/MercadolibreBindCard";
import WooCommerceBindCard from "../component/social/WooCommerce/WooCommerceBindCard";
import { WooCommerceBindForm } from "../component/social/WooCommerce/WooCommerceBindForm";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import JumpsellerBindCard from "../component/social/Jumpseller/JumpsellerBindCard";
import InstagramMenssegerBindCard from "../component/social/InstagramMensseger/InstagramMenssegerBindCard";
import MailchimpBindCard from "../component/social/Mailchimp/MailchimpBindCard";
import GmailBindCard from "../component/social/Gmail/GmailBindCard";
import Countries from "../component/social/Mercadolibre/Countries";
import SmtpBindCard from "../component/social/Smtp/SmtpBindCard";
import WhatsappBindCard from "../component/social/Whatsapp/WhatsappBindCard";
import OpenAiBindCard from "../component/social/OpenAi/OpenAiBindCard";
import Office365BindCard from "../component/social/Office365/Office365BindCard";

export default function Social() {
  const { company, getMeSocialSettings, getMeUserSettings } = useMeCore();
  const {
    turnOffModal,
    isModal,
    getModalContent,
    turnOffLoading,
    getModalVisibility,
  } = useGlobalCore();
  const cards = [
    <InstagramBindCard />,
    <InstagramMenssegerBindCard />,
    <FacebookBindCard />,
    <FacebookAdsBindCard />,
    <LinkedInBusinessBindCard />,
    <LinkedInBindCard />,
    // <TwitterBindCard />,
    <GmailBindCard />,
    <Office365BindCard />,
    <SmtpBindCard />,
    <GoogleBindCard />,
    <WooCommerceBindCard />,
    <MercadolibreBindCard />,
    <JumpsellerBindCard />,
    <MailchimpBindCard />,
    <WhatsappBindCard />,
    <OpenAiBindCard />,
  ];

  useEffect(() => {
    getMeUserSettings();
    if (company) getMeSocialSettings(company.id);
  }, [getMeSocialSettings, getMeUserSettings, company]);

  return (
    <Layout className="p-0">
      <Content className="my-3 mx-auto">
        <div className="container-fluid">
          <div className="social-grid">
            {cards.map((card, index) => (
              <div
                className="social-item"
                key={index}
              >
                {card}
              </div>
            ))}
          </div>
        </div>
      </Content>
      <SideModal
        active={isModal(INSTAGRAM_BIND_MODAL)}
        visible={getModalVisibility(INSTAGRAM_BIND_MODAL)}
        onClose={() => {
          turnOffModal(INSTAGRAM_BIND_MODAL);
          turnOffLoading(INSTAGRAM_SYNC_LOADING);
        }}
      >
        {getModalContent(INSTAGRAM_BIND_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(INSTAGRAM_MENSSEGER_BIND_MODAL)}
        visible={getModalVisibility(INSTAGRAM_MENSSEGER_BIND_MODAL)}
        onClose={() => {
          turnOffModal(INSTAGRAM_MENSSEGER_BIND_MODAL);
          turnOffLoading(INSTAGRAM_MENSSEGER_SYNC_LOADING);
        }}
      >
        {getModalContent(INSTAGRAM_MENSSEGER_BIND_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(MAILCHIMP_AUDIENCES_SIDE_MODAL)}
        visible={getModalVisibility(MAILCHIMP_AUDIENCES_SIDE_MODAL)}
        onClose={() => {
          turnOffModal(MAILCHIMP_AUDIENCES_SIDE_MODAL);
          turnOffLoading(MAILCHIMP_AUDIENCES_SIDE_MODAL);
        }}
      >
        {getModalContent(MAILCHIMP_AUDIENCES_SIDE_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(OPENAI_SIDE_MODAL_RIGHT)}
        visible={getModalVisibility(OPENAI_SIDE_MODAL_RIGHT)}
        onClose={() => {
          turnOffModal(OPENAI_SIDE_MODAL_RIGHT);
          turnOffLoading(OPENAI_SYNC_LOADING);
        }}
      >
        {getModalContent(OPENAI_SIDE_MODAL_RIGHT)}
      </SideModal>
      <SideModal
        active={isModal(FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT)}
        visible={getModalVisibility(FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT)}
        onClose={() => {
          turnOffModal(FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT);
          turnOffLoading(FACEBOOK_SYNC_LOADING);
          turnOffLoading(FACEBOOK_ADS_SYNC_LOADING);
        }}
      >
        {getModalContent(FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT)}
      </SideModal>
      <SideModal
        active={isModal(LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT)}
        visible={getModalVisibility(LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT)}
        onClose={() => {
          turnOffModal(LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT);
          turnOffLoading(LINKED_IN_BUSINESS_SYNC_LOADING);
        }}
      >
        {getModalContent(LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT)}
      </SideModal>
      <SideModal
        active={isModal(GOOGLE_PROJECTS_SIDE_MODAL)}
        visible={getModalVisibility(GOOGLE_PROJECTS_SIDE_MODAL)}
        onClose={() => {
          turnOffModal(GOOGLE_PROJECTS_SIDE_MODAL);
          turnOffModal(GOOGLE_SYNC_LOADING);
        }}
      >
        <GoogleProjects />
      </SideModal>
      <SideModal
        active={isModal(WOO_COMMERCE_BIND_FORM)}
        visible={getModalVisibility(WOO_COMMERCE_BIND_FORM)}
        onClose={() => {
          turnOffModal(WOO_COMMERCE_BIND_FORM);
          turnOffLoading(WOO_COMMERCE_SYNC_LOADING);
        }}
      >
        <WooCommerceBindForm />
      </SideModal>
      <SideModal
        active={isModal(MERCADO_COUNTRIES)}
        visible={getModalVisibility(MERCADO_COUNTRIES)}
        onClose={() => {
          turnOffModal(MERCADO_COUNTRIES);
        }}
      >
        <Countries />
      </SideModal>
      <SideModal
        active={isModal(SMTP_BIND_MODAL)}
        visible={getModalVisibility(SMTP_BIND_MODAL)}
        onClose={() => {
          turnOffModal(SMTP_BIND_MODAL);
          turnOffLoading(SMTP_SYNC_LOADING);
        }}
      >
        {getModalContent(SMTP_BIND_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(MORE_INFO_SOCIAL)}
        visible={getModalVisibility(MORE_INFO_SOCIAL)}
        onClose={() => turnOffModal(MORE_INFO_SOCIAL)}
        left
      >
        {getModalContent(MORE_INFO_SOCIAL)}
      </SideModal>
    </Layout>
  );
}
