import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Col, Container, Row } from "reactstrap";
import { AssignmentBoard } from "../../../../cores/boards/interfaces/api/get-board";
import { useCompanyCore } from "../../../../cores/company";
import { CompanyUser } from "../../../../cores/company/interfaces/api/get-company";
import { ContactOwner } from "../../../../cores/contacts/interfaces/api/get-contact";
import { User } from "../../../../cores/session/interfaces/models/user";
import { InputWembii } from "../../InputWembii";
import { AutomationUser } from "../../../../cores/automations/interfaces/api/get-automation";

interface Props {
  assignments: AssignmentBoard[] | User[] | ContactOwner[] | AutomationUser[];
  onAssign: (user: CompanyUser) => void;
  onDelete?: (user: CompanyUser) => void; 
}

export default function UserList(props: Props) {
  const intl = useIntl();
  const { users } = useCompanyCore();
  const { assignments, onAssign, onDelete } = props;
  const [filter, setFilter] = useState("");
  const [searchResults, setSearchResults] = useState<CompanyUser[]>([]);

  useEffect(() => {
    const results = users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(filter.toLowerCase()) ||
        user.lastName.toLowerCase().includes(filter.toLowerCase()) ||
        user.email.toLowerCase().includes(filter.toLowerCase())
    );
    setSearchResults(results);
  }, [filter, users, assignments]);

  const isUserAssigned = useCallback(
    (user) =>
      assignments.some(
        (assignment: AssignmentBoard | User | AutomationUser) =>
          assignment.id === user.id
      ),
    [assignments]
  );

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 py-5">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.activity.users" })}
            </h2>

            <InputWembii
              type="text"
              placeholder={intl.formatMessage({
                id: "app.activity.usersSearch",
              })}
              rounded
              onChange={(v) => setFilter(v)}
              icon={
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="1x"
                  color="grey"
                  className="pointer"
                />
              }
            />

            {searchResults.length === 0 && (
              <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center text-muted">
                <FontAwesomeIcon
                  icon={["fas", "users"]}
                  size="5x"
                  color="gray"
                />
                {intl.formatMessage({ id: "app.activity.thereNotUsers" })}
              </div>
            )}

            {searchResults.map((user) => (
              <div
                className="d-flex flex-row my-2 neu-in py-2 px-3"
                key={user.id}
              >
                <div className="text-center flex-grow-1 align-items-center d-flex flex-column">
                  <small className="mr-3">{user.email}</small>
                  <small>{`${user.firstName} ${user.lastName}`}</small>
                </div>
                {!isUserAssigned(user) ? (
                  <Button
                    className="ml-auto"
                    color="primary"
                    onClick={() => onAssign(user)}
                  >
                    {intl.formatMessage({ id: "app.activity.usersAssign" })}
                  </Button>
                ) : (
                  onDelete && (
                    <Button
                      className="ml-auto"
                      color="danger"
                      onClick={() => onDelete!(user)}
                    >
                      {intl.formatMessage({ id: "app.activity.delete" })}
                    </Button>
                  )
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
