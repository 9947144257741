import {
  IconName,
  IconPrefix,
  SizeProp,
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { COLOR_PRIMARY } from "../../../../constants/theme";

export interface IconProps {
  color?: string;
  className?: string;
  size?: SizeProp;
  onClick?: () => void;
}

interface Props extends IconProps {
  prefix?: IconPrefix;
  name: IconName;
}

export const Icon = (props: Props) => {
  const {
    color = COLOR_PRIMARY,
    className,
    size,
    onClick,
    name,
    prefix = "fas",
  } = props;
  return (
    <FontAwesomeIcon
      className={className}
      onClick={onClick}
      style={{ color }}
      icon={[prefix, name]}
      size={size}
    />
  );
};
