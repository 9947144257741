import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useSocialPostCore } from "../../../../cores/socialPost";

interface Props {
  onClose: () => void;
}

export const VideoEditorForm = (props: Props) => {
  const { onClose } = props;
  const { removeVideo, removePostAllMedia, video, selectedPost } = useSocialPostCore();
  const [name, setName] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [size, setSize] = useState(0);
  const [type, setType] = useState("");

  useEffect(() => {
    if (video) {
      setName(video.name);
      setVideoSrc(video.data);
      setSize(video.size);
      setType(video.type);
    } else if (selectedPost) {
      setName(selectedPost.media[0].key);
      setVideoSrc(selectedPost.media[0].url);
    }
  }, [video, selectedPost]);

  const handleDelete = () => {
    removeVideo();
    if (selectedPost) {
      removePostAllMedia();
    }
    onClose();
  };

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 align-items-center p-4">
            <h2 className="h2 text-center mb-4">Editar Video</h2>
            <video className="videoEditor" controls>
              <source src={videoSrc} type={type} />
              Your browser does not support HTML5 video.
            </video>
            <div className="px-2 my-3">
              <div className="my-3 text-center">
                <span className="font-weight-bolder">Nombre: </span>
                {name}
              </div>
              {video && (
                <>
                  <div className="my-3 text-center">
                    <span className="font-weight-bolder">Tamaño: </span>
                    {size} bytes
                  </div>
                  <div className="my-3 text-center">
                    <span className="font-weight-bolder">Tipo: </span>
                    {type}
                  </div>
                </>
              )}
            </div>
            <Button className="w-50" color="danger" onClick={handleDelete}>
              Eliminar
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
