import cn from "classnames";
import React, { useMemo } from "react";
import { capitalize } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFile,
  faDownload,
  faFileWord,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

import { Badge, Label } from "reactstrap";
import { WebMessage } from "../../../cores/conversations/interfaces/api/get-messages";
import moment from "moment";
import { Message } from "../../../cores/socialChat/interfaces/conversations.interface";
import Avatar from "../Avatar";
import { getFirstLetters } from "../../utils/stringUtils";
import { daysRemainingFromToday } from "../../utils/dateUtils";
interface Props {
  message: WebMessage | Message;
  itsMe: boolean;
}

export default function MessageRow(props: Props) {
  const { message, itsMe } = props;
  function getFileExtension(url: string): string {
    const parts = url.split(".");
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase();
    }
    return "";
  }

  const dateLabel = useMemo(() => {
    const days = daysRemainingFromToday(message.timestamp.full);

    if (days > -10) {
      return capitalize(moment(message.timestamp.full).calendar());
    } else {
      return capitalize(moment(message.timestamp.full).format("llll"));
    }
  }, [message.timestamp.full]);

  return (
    <div
      key={message.id}
      className={cn("w-100 d-flex", { "justify-content-end": itsMe })}
    >
      <Label
        className={cn("w-75 m-1 d-flex flex-column ", {
          "align-items-end": itsMe,
          "align-items-start": !itsMe,
        })}
      >
        <div className="d-flex">
          <div className="position-relative">
            <Badge
              color={itsMe ? "primary" : "light"}
              className={cn("p-3 text-wrap text-left fs-15 shadow", {
                "text-light": itsMe,
              })}
            >
              {message.type ? (
                <>
                  {message.type.toLowerCase() === "text" && (
                    <div
                      className={cn("m-0 p-0", {
                        "text-light": itsMe,
                      })}
                      dangerouslySetInnerHTML={{
                        __html: message.message.replaceAll("\n", "<br />"),
                      }}
                    ></div>
                  )}
                  {message.type === "audio" && (
                    <div className="audio-player">
                      <audio controls>
                        <source src={message.message} type="audio/mpeg" />
                        Tu navegador no soporta la reproducción de audio.
                      </audio>
                    </div>
                  )}
                  {message.type === "video" && (
                    <div
                      style={{
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "200px",
                        height: "300px",
                        borderRadius: "5%",
                      }}
                    >
                      <video controls width="200" height="300">
                        <source src={message.message} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                  {message.type === "document" && (
                    <div className="audio-player">
                      {message.message && message.message.length > 1 && (
                        <a
                          href={message.message}
                          style={
                            itsMe ? { color: "#ffffff" } : { color: "blue" }
                          }
                        >
                          {getFileExtension(message.message) === "docx" ? (
                            <>
                              <FontAwesomeIcon
                                icon={faFileWord}
                                className="mr-1"
                              />
                              Descargar Documento de texto
                            </>
                          ) : getFileExtension(message.message) === "pdf" ? (
                            <>
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                className="mr-1"
                              />
                              Descargar Archivo PDF
                            </>
                          ) : getFileExtension(message.message) === "xlsx" ? (
                            <>
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                className="mr-1"
                              />
                              Descargar Hoja de Calculo
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faFile} className="mr-1" />
                              Descargar Archivo
                            </>
                          )}
                        </a>
                      )}
                    </div>
                  )}
                  {message.type === "image" && (
                    <div
                      style={{
                        backgroundImage: `url(${message.message})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "200px",
                        height: "300px",
                        borderRadius: "5%",
                      }}
                    >
                      <div className="w-100 justify-end my-auto mx-auto">
                        <a
                          href={message.message}
                          className="my-auto mx-auto border shadow rounded-full bg-light p-2 text-center"
                          style={{ borderRadius: "50%" }}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>{message.message}</>
              )}
            </Badge>
            <div
              className={cn({
                "ballon-right": itsMe,
                "ballon-left": !itsMe,
              })}
            ></div>
          </div>
          {itsMe && (
            <Avatar
              className="ml-2 shadow"
              label={getFirstLetters(message.from.name)}
              image={message.from.avatarUrl}
            />
          )}
        </div>
        <Label className="text-muted px-2 m-0" size="sm">
          {dateLabel}
        </Label>
      </Label>
    </div>
  );
}
