import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ADMIN_COMPANY_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { GetCompaniesQuery, GetCompanyParams } from "./interfaces/api/get-companies";
import { CompanyRole } from "./interfaces/api/get-company";
import { companyApiService } from "./services/api";
import {
  deleteCompanyFailureAction,
  deleteCompanyInitAction,
  deleteCompanySuccessAction,
  getCompaniesFailureAction,
  getCompaniesInitAction,
  getCompaniesSuccessAction,
  getCompanyFailureAction,
  getCompanyInitAction,
  getCompanySuccessAction,
  getCompaniesFiltersSuccessAction,
  putCompanyApiKeyFailureAction,
  putCompanyApiKeyInitAction,
  putCompanyApiKeySuccessAction,
  putCompanyAvatarFailureAction,
  putCompanyAvatarInitAction,
  putCompanyAvatarSuccessAction,
  putCompanyFailureAction,
  putCompanyInitAction,
  putCompanySuccessAction,
  getCompanyIntegrationFailureAction,
  getCompanyIntegrationInitAction,
  getCompanyIntegrationSuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import {
  companiesSelector,
  companyPlanSelector,
  companySelector,
  userIdsSelector,
  usersSelector,
  companiesFilter,
  integrationSelector
} from "./store/selector";
import { Errors } from "../../constants/errors";

export function useCompanyCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const users = useSelector(usersSelector);
  const userIds = useSelector(userIdsSelector);
  const company = useSelector(companySelector);
  const plan = useSelector(companyPlanSelector);
  const companies = useSelector(companiesSelector);
  const integration = useSelector(integrationSelector);
  const companiesFilters = useSelector(companiesFilter);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const getCompanies = useCallback(
    async (params?: GetCompaniesQuery) => {
      try {
        dispatch(getCompaniesInitAction());
        turnOnLoading(ADMIN_COMPANY_LOADING);
        const companies = await companyApiService.getCompanies(params);
        dispatch(getCompaniesSuccessAction({ companies }));
        turnOffLoading(ADMIN_COMPANY_LOADING, 500);
      } catch (error: any) {
        turnOffLoading(ADMIN_COMPANY_LOADING);
        console.error(error);
        dispatch(getCompaniesFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getSearchCompanies = useCallback(
    async (params?: GetCompanyParams) => {
      try {
        dispatch(getCompaniesInitAction());
        turnOnLoading(ADMIN_COMPANY_LOADING);
        const companiesFilters = await companyApiService.getSearchCompanies(params);
        dispatch(getCompaniesFiltersSuccessAction({companiesFilters}));
        turnOffLoading(ADMIN_COMPANY_LOADING, 500);
      } catch (error: any) {
        turnOffLoading(ADMIN_COMPANY_LOADING);
        console.error(error);
        dispatch(getCompaniesFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getCompany = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getCompanyInitAction());
        const response = await companyApiService.getCompany(companyId);
        dispatch(getCompanySuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getUserCompanies = useCallback(async (userId: string) => {
    try {
      const response = await companyApiService.getUserCompanies(userId);
      return response;
    } catch (error: any) {
      console.error(error);
      toast.error(intl.formatMessage({ id: "app.toast.error10" }));
    }
  }, [intl]);
  const postCompany = useCallback(
    async (
      name: string,
      description: string,
      website: string,
      phoneNumber: string,
      country: string,
      city: string,
      sendWeeklyReport: boolean,
      asReseller: boolean,
      onSuccess?: () => void
    ) => {
      try {
        const company = await companyApiService.postCompany(
          name,
          description,
          website,
          phoneNumber,
          country,
          city,
          sendWeeklyReport,
          asReseller
        );
        onSuccess?.();
        return company;
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          if (code === Errors.NotVerified) {
            toast.error(intl.formatMessage({ id: "app.user.notVerified" }));
          }
        }
        console.error(error);
      }
    },
    [intl]
  );
  const putCompany = useCallback(
    async (
      companyId: string,
      name: string,
      description: string,
      website: string,
      phoneNumber: string,
      country: string,
      city: string,
      sendWeeklyReport: boolean,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCompanyInitAction());
        const company = await companyApiService.putCompany(
          companyId,
          name,
          description,
          website,
          phoneNumber,
          country,
          city,
          sendWeeklyReport
        );
        dispatch(putCompanySuccessAction({ company }));    
        toast.success(intl.formatMessage({ id: "app.toast.sucess2" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error11" }));
        dispatch(putCompanyFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const postAssignment = useCallback(
    async (
      userId: string,
      companyId: string,
      userRole: CompanyRole,
      onSuccess?: () => void
    ) => {
      try {
        await companyApiService.postAssign(userId, companyId, userRole);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
      }
    },
    []
  );
  const putAssignment = useCallback(
    async (userId: string, companyId: string, userRole: CompanyRole) => {
      try {
        await companyApiService.putAssign(userId, companyId, userRole);
      } catch (error: any) {
        console.error(error);
      }
    },
    []
  );
  const putApikey = useCallback(
    async (companyId: string) => {
      try {
        dispatch(putCompanyApiKeyInitAction());
        const response = await companyApiService.putApiKey(companyId);
        dispatch(putCompanyApiKeySuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(putCompanyApiKeyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteAssignment = useCallback(
    async (userId: string, companyId: string) => {
      try {
        await companyApiService.deleteAssign(userId, companyId);
      } catch (error: any) {
        console.error(error);
      }
    },
    []
  );
  const deleteCompany = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteCompanyInitAction());
        await companyApiService.deleteCompany(companyId);
        dispatch(deleteCompanySuccessAction({ id: companyId }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCompanyAvatar = useCallback(
    async (companyId: string, avatar: File, onSuccess?: () => void) => {
      try {
        dispatch(putCompanyAvatarInitAction());
        const company = await companyApiService.postCompanyAvatar(
          companyId,
          avatar
        ); 
        dispatch(putCompanyAvatarSuccessAction({ company }));
        toast.success(intl.formatMessage({ id: "app.toast.sucess1" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error12" }));
        dispatch(putCompanyAvatarFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const getCompanyIntegration = useCallback(
    async (id: string) => {
      try {
        dispatch(getCompanyIntegrationInitAction());
        const response = await companyApiService.getCompanyIntegration(id);
        dispatch(getCompanyIntegrationSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getCompanyIntegrationFailureAction({ error }));
      }
    },
    [dispatch]
  );

  return {
    users,
    userIds,
    company,
    companies,
    plan,
    integration,
    companiesFilters,
    getCompanies,
    getSearchCompanies,
    getUserCompanies,
    postCompany,
    getCompany,
    putCompany,
    postAssignment,
    putAssignment,
    deleteAssignment,
    deleteCompany,
    postCompanyAvatar,
    putApikey,
    getCompanyIntegration,
  };
}
