import { Dictionary } from "lodash";

export interface ContactHeader {
  name: number;
  surname: number;
  phone: number;
  email: number;
  enterprise: number;
}

export const ContactHeaders: Dictionary<{key: string, label: string}> = {
  name: { key: "name", label: "app.contacts.import.name" },
  surname: { key: "surname", label: "app.contacts.import.surname" },
  phone: { key: "phone", label: "app.contacts.import.phone" },
  email: { key: "email", label: "app.contacts.import.email" },
  enterprise: { key: "enterprise", label: "app.contacts.import.enterprise" },
};
