import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { COLOR_PRIMARY } from "../../../../constants/theme";

export default function DocxPreview({ size }: { size?: SizeProp }) {
  return (
    <FontAwesomeIcon
      icon={["far", "file-word"]}
      size={!size ? "5x" : size}
      className="filePreview"
      color={COLOR_PRIMARY}
    />
  );
}