import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contactApiService } from "./services/api";
import { chatTemplatesSelector, emailTemplatesSelector, socialTemplatesSelector } from "./store/selector";
import {
  deleteChatTemplateFailureAction,
  deleteChatTemplateInitAction,
  deleteChatTemplateSuccessAction,
  deleteEmailTemplateFailureAction,
  deleteEmailTemplateInitAction,
  deleteEmailTemplateSuccessAction,
  deleteSocialTemplateFailureAction,
  deleteSocialTemplateInitAction,
  deleteSocialTemplateSuccessAction,
  getChatTemplatesFailureAction,
  getChatTemplatesInitAction,
  getChatTemplatesSuccessAction,
  getEmailTemplatesFailureAction,
  getEmailTemplatesInitAction,
  getEmailTemplatesSuccessAction,
  getSocialTemplatesFailureAction,
  getSocialTemplatesInitAction,
  getSocialTemplatesSuccessAction,
  postChatTemplateFailureAction,
  postChatTemplateInitAction,
  postChatTemplateSuccessAction,
  postEmailTemplateFailureAction,
  postEmailTemplateInitAction,
  postEmailTemplateSuccessAction,
  postSocialTemplateFailureAction,
  postSocialTemplateInitAction,
  postSocialTemplateSuccessAction,
  putChatTemplateFailureAction,
  putChatTemplateInitAction,
  putChatTemplateSuccessAction,
  putEmailTemplateFailureAction,
  putEmailTemplateInitAction,
  putEmailTemplateSuccessAction,
  putSocialTemplateFailureAction,
  putSocialTemplateInitAction,
  putSocialTemplateSuccessAction
} from "./store/api-actions";
import { PostEmailTemplateRequest } from "./interfaces/api/post-email-template";
import { PutEmailTemplateRequest } from "./interfaces/api/put-email-template";
import { PutSocialTemplateRequest } from "./interfaces/api/put-social-template";
import { PostSocialTemplateRequest } from "./interfaces/api/post-social-template";
import { PostChatTemplateRequest } from "./interfaces/api/post-chat-template";
import { PutChatTemplateRequest } from "./interfaces/api/put-chat-template";

export function useTemplateCore() {
  const dispatch = useDispatch();
  const emailTemplates = useSelector(emailTemplatesSelector);
  const socialTemplates = useSelector(socialTemplatesSelector);
  const chatTemplates = useSelector(chatTemplatesSelector);

  const getEmailTemplates = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getEmailTemplatesInitAction());
        const { templates } = await contactApiService.getEmailTemplates(companyId);
        dispatch(getEmailTemplatesSuccessAction({ templates }));
      } catch (error: any) {
        console.error(error);
        dispatch(getEmailTemplatesFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getSocialTemplates = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getSocialTemplatesInitAction());
        const { templates } = await contactApiService.getSocialTemplates(companyId);
        dispatch(getSocialTemplatesSuccessAction({ templates }));
      } catch (error: any) {
        console.error(error);
        dispatch(getSocialTemplatesFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getChatTemplates = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getChatTemplatesInitAction());
        const { templates } = await contactApiService.getChatTemplates(companyId);
        dispatch(getChatTemplatesSuccessAction({ templates }));
      } catch (error: any) {
        console.error(error);
        dispatch(getChatTemplatesFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postEmailTemplate = useCallback(
    async (
      companyId: string,
      body: PostEmailTemplateRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postEmailTemplateInitAction());
        const { template } = await contactApiService.postEmailTemplate(
          companyId,
          body
        );
        dispatch(postEmailTemplateSuccessAction({ template }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postEmailTemplateFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postSocialTemplate = useCallback(
    async (
      companyId: string,
      body: PostSocialTemplateRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postSocialTemplateInitAction());
        const { template } = await contactApiService.postSocialTemplate(
          companyId,
          body
        );
        dispatch(postSocialTemplateSuccessAction({ template }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postSocialTemplateFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postChatTemplate = useCallback(
    async (
      companyId: string,
      body: PostChatTemplateRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postChatTemplateInitAction());
        const { template } = await contactApiService.postChatTemplate(
          companyId,
          body
        );
        dispatch(postChatTemplateSuccessAction({ template }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postChatTemplateFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putEmailTemplate = useCallback(
    async (
      companyId: string,
      templateId: string,
      body: PutEmailTemplateRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putEmailTemplateInitAction());
        const { template } = await contactApiService.putEmailTemplate(
          companyId,
          templateId,
          body
        );
        dispatch(putEmailTemplateSuccessAction({ template }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putEmailTemplateFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putSocialTemplate = useCallback(
    async (
      companyId: string,
      templateId: string,
      body: PutSocialTemplateRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putSocialTemplateInitAction());
        const { template } = await contactApiService.putSocialTemplate(
          companyId,
          templateId,
          body
        );
        dispatch(putSocialTemplateSuccessAction({ template }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putSocialTemplateFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putChatTemplate = useCallback(
    async (
      companyId: string,
      templateId: string,
      body: PutChatTemplateRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putChatTemplateInitAction());
        const { template } = await contactApiService.putChatTemplate(
          companyId,
          templateId,
          body
        );
        dispatch(putChatTemplateSuccessAction({ template }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putChatTemplateFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteEmailTemplate = useCallback(
    async (companyId: string, templateId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteEmailTemplateInitAction());
        const id = await contactApiService.deleteEmailTemplate(companyId, templateId);
        dispatch(deleteEmailTemplateSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteEmailTemplateFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteSocialTemplate = useCallback(
    async (companyId: string, templateId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteSocialTemplateInitAction());
        const id = await contactApiService.deleteSocialTemplate(companyId, templateId);
        dispatch(deleteSocialTemplateSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteSocialTemplateFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteChatTemplate = useCallback(
    async (companyId: string, templateId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteChatTemplateInitAction());
        const id = await contactApiService.deleteChatTemplate(companyId, templateId);
        dispatch(deleteChatTemplateSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteChatTemplateFailureAction({ error }));
      }
    },
    [dispatch]
  );

  return {
    emailTemplates,
    socialTemplates,
    chatTemplates,
    getEmailTemplates,
    getSocialTemplates,
    getChatTemplates,
    postEmailTemplate,
    postSocialTemplate,
    postChatTemplate,
    putEmailTemplate,
    putSocialTemplate,
    putChatTemplate,
    deleteEmailTemplate,
    deleteSocialTemplate,
    deleteChatTemplate,
  };
}
