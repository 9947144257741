import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { InstagramAccount } from "../interface/api/get-instagram-accounts";
import { InstagramMenssegerSetting } from "../interface/api/post-instagram-bind";
import {
  getInstagramMenssegerAccountsInitAction,
  getInstagramMenssegerAccountsSuccessAction,
  getInstagramMenssegerFailureAction,
  getInstagramMenssegerInitAction,
  getInstagramMenssegerSuccessAction,
  InstagramMenssegerApiAction,
  postBindInstagramMenssegerFailureAction,
  postBindInstagramMenssegerInitAction,
  postBindInstagramMenssegerSuccessAction,
  postUnbindInstagramMenssegerFailureAction,
  postUnbindInstagramMenssegerInitAction,
  postUnbindInstagramMenssegerSuccessAction,
} from "./api-actions";

export interface InstagramMenssegerState {
  settings: InstagramMenssegerSetting | null;
  errorCode: number | null;
  errorMessage: string | null;
  accounts: InstagramAccount[];
}

const initialState: InstagramMenssegerState = {
  settings: null,
  errorCode: null,
  errorMessage: null,
  accounts: [],
};

export default produce(
  (
    draft: InstagramMenssegerState = initialState,
    action: InstagramMenssegerApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindInstagramMenssegerSuccessAction.type:
      case getInstagramMenssegerSuccessAction.type:
        draft.settings = action.settings;
        draft.errorCode = null;
        draft.errorMessage = null;
        return draft;
      case postBindInstagramMenssegerFailureAction.type:
        draft.errorCode = action.errorCode;
        draft.errorMessage = action.errorMessage;
        return draft;
      case getInstagramMenssegerAccountsSuccessAction.type:
        draft.accounts = action.accounts;
        return draft;
      case postUnbindInstagramMenssegerFailureAction.type:
      case getInstagramMenssegerFailureAction.type:
      case postUnbindInstagramMenssegerSuccessAction.type:
        draft.settings = null;
        return draft;
      case getInstagramMenssegerAccountsInitAction.type:
      case postUnbindInstagramMenssegerInitAction.type:
      case getInstagramMenssegerInitAction.type:
      case resetAction.type:
        return initialState;
      case postBindInstagramMenssegerInitAction.type:
      default:
        return draft;
    }
  }
);
