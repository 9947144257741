import { io } from 'socket.io-client';

const create = () => {
  const socket = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:15000', {
    "transports": ['websocket'],
  });
  return socket;
};

export default create;
