import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { COLOR_SUCCESS } from "../../../constants/theme";
import { AssignmentType, TaskAssignmentNotification } from "../../../cores/notifications/interfaces/api/get-notifications";

interface Props {
  notification: TaskAssignmentNotification; 
}

export default function TaskAssignmentNotificationItem({
  notification
}: Props) {
  const taskType = useMemo(() => {
    switch (notification.type) {
      case AssignmentType.ACTIVITY:
        return "Actividad";
      case AssignmentType.CRM:
        return "Oportunidad";
      case AssignmentType.TASK:
        return "Tarea";
    } 
  }, [notification.type]);

  return (
    <div className="d-flex align-items-center border-bottom mb-1">
      <FontAwesomeIcon
        icon={["fas", "tasks"]}
        className="mr-2"
        size="2x"
        color={COLOR_SUCCESS}
      />
      <div className="d-flex flex-column overflow-hidden">
        <span className="capitalize">Te asignaron una nueva {taskType}</span>

        <span className="text-truncate text-muted text-wrap">
          {notification.title}
        </span>
      </div>
    </div>
  );
}
