import React from "react";
import { useSocialChatCore } from "../../../../cores/socialChat";
import { InputWembii } from "../../InputWembii";

export const PeriodDropdownOptions = () => {
  const { setDateFilter, dateFilter } = useSocialChatCore();
  return (
    <div className="p-4 col">
      <div>
        <InputWembii
          label="desde"
          type="date"
          onChange={(value) => setDateFilter(value, "from")}
          min={new Date("12-12-2000").toISOString().split("T")[0]}
          max={
            dateFilter.to
              ? new Date(dateFilter.to).toISOString().split("T")[0]
              : ""
          }
        />
        <InputWembii
          label="hasta"
          type="date"
          onChange={(value) => setDateFilter(value, "to")}
          min={
            dateFilter.from
              ? new Date(dateFilter.from).toISOString().split("T")[0]
              : ""
          }
        />
      </div>
    </div>
  );
};
