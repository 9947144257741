import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import CommentInput from "./CommentInput";
import { Comment } from "../../../cores/contacts/interfaces/api/get-contact";
import moment from "moment";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../cores/me";
import { useGlobalCore } from "../../../cores/globals";
import { useContactCore } from "../../../cores/contacts";
import AreYouSure from "../AreYouSure";
import { CommentType, CommentTypeLabels } from "../../../cores/boards/constants/comment-type";
import { UserStatus } from "../../../cores/user/constants/user-status";
import Avatar from "../Avatar";
import { DELETE_CONTACT_COMMENT_MODAL } from "../../../constants/modal.constant";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import { getFirstLetters } from "../../utils/stringUtils";
import { useConfigCore } from "../../../cores/config";

interface Props {
  comment: Comment;
}

export default function CommentCard(props: Props) {
  const intl = useIntl();
  const { comment } = props;
  const { user, company } = useMeCore();
  const { deleteComment, putComment, contact } = useContactCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { commentTypes } = useConfigCore();
  const [editComment, setEditComment] = useState(false);

  const toggleEditComment = useCallback(() => {
    setEditComment(!editComment);
  }, [editComment]);

  const onDelete = useCallback(() => {
    turnOnModal(
      DELETE_CONTACT_COMMENT_MODAL,
      <AreYouSure
        message={intl.formatMessage({ id: "app.activity.deleteComment" })}
        onSubmit={() => {
          deleteComment(
            company?.id!,
            contact?.id!,
            comment.id
          );
          turnOffModal(DELETE_CONTACT_COMMENT_MODAL);
        }}
        onClose={() => turnOffModal(DELETE_CONTACT_COMMENT_MODAL)}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, company, comment, contact, deleteComment, intl]);

  const onUpdateCommentSubmit = (text: string, type: number) => {
    putComment(
      company?.id!,
      contact?.id!,
      comment.id, {
        text,
        type,
      }
    );
    setEditComment(false);
  };

  const formatedComment = useMemo(
    () => ({
      __html: comment.text
        .replaceAll(/(?=@@@B\[)(.*?)(?=\)E@@@)/g, (value) => {
          const data = value.split("]@M@(");
          return `<span class="font-weight-bold pointer tagged ${
            +user?.id! === +data[1] ? "text-primary" : ""
          }">@${data[0]}</span>`;
        })
        .replace(/@@@B\[/g, "")
        .replace(/\)E@@@/g, ""),
    }),
    [comment.text, user]
  );

  const commentType = useMemo(() => 
    comment.type
      ? (comment.type < 100)
        ? intl.formatMessage({ id: CommentTypeLabels[comment.type] })
        : commentTypes.find(type => comment.type === +type.id)?.name
      : intl.formatMessage({ id: CommentTypeLabels[CommentType.Comment] })
  , [comment.type, commentTypes, intl]);

  const createdAt = useMemo(() =>
    moment(comment.createdAt).format("DD/MM/YYYY HH:mm a")
  , [comment.createdAt]);

  const updatedAt = useMemo(() =>
    moment(comment.updatedAt).format("DD/MM/YYYY HH:mm a")
  , [comment.updatedAt]);

  return (
    <div className="d-flex mt-3 mb-4 w-100">
      <Avatar
        className="text-dark-50"
        active={comment.user.status === UserStatus.ACTIVE.id}
        label={getFirstLetters(`${comment.user.lastName} ${comment.user.firstName}`)}
        image={comment.user.avatarUrl}
      />
      <div className="d-flex flex-column align-items-start ml-2 w-100">
        <div>
          <span className={cn("font-weight-bold mr-2", {
            "text-line-through": comment.user.status !== UserStatus.ACTIVE.id
          })}>
            {`${comment.user.firstName} ${comment.user.lastName}`}
          </span>
          <span className="text-muted">
            {createdAt}
            {createdAt !== updatedAt && " (Editado)"}
          </span>
        </div>
        {!editComment ? (
          <div className="p-2 border rounded">
            <div className="text-muted fs-12">
              {commentType}
            </div>
            <div
              className="pre-wrap"
              dangerouslySetInnerHTML={formatedComment}
            />
          </div>
        ) : (
          <div className="p-2 w-100">
            <CommentInput
              onSubmit={onUpdateCommentSubmit}
              comment={comment.text}
              type={comment.type}
              onClose={toggleEditComment}
            />
          </div>
        )}
        {comment.user.id === user?.id! && comment.type !== CommentType.MeetingComment && (
          <div className="mt-2">
            <span
              className="mr-2 text-underline pointer"
              onClick={toggleEditComment}
            >
              {intl.formatMessage({ id: "app.activity.modify" })}
            </span>
            <span className="text-underline pointer" onClick={onDelete}>
              {intl.formatMessage({ id: "app.activity.delete" })}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
