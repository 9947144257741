export enum JumpsellerOrderStatus {
  any = "",
  abandoned = "Abandoned",
  cancelled = "Canceled",
  pending_payment = "Pending Payment",
  paid = "Paid",
}

export const JumpsellerOrderStatusDic: Record<
JumpsellerOrderStatus,
  string
> = {
  [JumpsellerOrderStatus.any]: "Todas",
  [JumpsellerOrderStatus.abandoned]: "Abandonado",
  [JumpsellerOrderStatus.cancelled]: "Cancelado",
  [JumpsellerOrderStatus.pending_payment]: "Pago pendiente",
  [JumpsellerOrderStatus.paid]: "Pagado",
};

export const JumpsellerOrderStatusColor: Record<
JumpsellerOrderStatus,
  string
> = {
  [JumpsellerOrderStatus.any]: "#FFF",
  [JumpsellerOrderStatus.pending_payment]: "#FFCE56",
  [JumpsellerOrderStatus.paid]: "#4BC0C0",
  [JumpsellerOrderStatus.cancelled]: "#FF6384",
  [JumpsellerOrderStatus.abandoned]: "#9966FF",
};

export const JumpsellerStatusOptions = [
  {
    label: JumpsellerOrderStatusDic[JumpsellerOrderStatus.any],
    value: JumpsellerOrderStatus.any,
  },
  {
    label: JumpsellerOrderStatusDic[JumpsellerOrderStatus.paid],
    value: JumpsellerOrderStatus.paid,
  },
  {
    label: JumpsellerOrderStatusDic[JumpsellerOrderStatus.pending_payment],
    value: JumpsellerOrderStatus.pending_payment,
  },
  {
    label: JumpsellerOrderStatusDic[JumpsellerOrderStatus.cancelled],
    value: JumpsellerOrderStatus.cancelled,
  },
  {
    label: JumpsellerOrderStatusDic[JumpsellerOrderStatus.abandoned],
    value: JumpsellerOrderStatus.abandoned,
  },
];

export const JumpsellerQuantityOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];
