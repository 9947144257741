import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAdvertisementCore } from "../../../cores/advertisements";
import { useMeCore } from "../../../cores/me";
import { PanelWembii } from "../PanelWembii";
import { SelectWembii } from "../SelectWembii";
import { useIntl } from "react-intl";
import { TitleWembii } from "../TitleWembii";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipWembii } from "../TooltipWembii";

interface selectItem {
  value: string;
  label: string;
}

export default function AudienceForm() {
  const intl = useIntl()
  const { company } = useMeCore();
  const {
    interests,
    campaign,
    setCampaign,
    getInterests,
  } = useAdvertisementCore();
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState<selectItem[]>([]);

  useEffect(() => {
    setOptions(
      interests.map((interest) => {
        return { value: interest.id, label: interest.name };
      })
    );
    setIsSearching(false);
  }, [interests]);

  const debouncedSearch = useRef(
    _.debounce((search) => {
      getInterests(company!.id, search);
    }, 1000)
  ).current;

  useEffect(() => {
    if (search) {
      setOptions([]);
      setIsSearching(true);
      debouncedSearch(search);
    }
  }, [debouncedSearch, search]);

  const handleInterestConfigChange = useCallback(
    (values) => {
      if (values) {
        const interests = values.map((item: selectItem) => ({
          id: item.value,
          name: item.label,
        }));

        setCampaign({
          ...campaign,
          ...{
            targeting: { ...campaign.targeting, interests: interests },
          },
        });
      } else {
        setCampaign({
          ...campaign,
          ...{
            targeting: { ...campaign.targeting, interests: [] },
          },
        });
      }
    },
    [campaign, setCampaign]
  );

  return (
    <PanelWembii shadow className="mb-4 p-4">
      <TitleWembii Tag="h5" color="primary">
        {intl.formatMessage({ id: "app.advertisements.audienceTitle" })}
      </TitleWembii>

      <SelectWembii
        isMulti
        name="interests"
        label={intl.formatMessage({ id: "app.advertisements.audienceInterestTxt" })}
        options={options}
        onInputChange={(v) => setSearch(v)}
        onChange={(v) => handleInterestConfigChange(v)}
        classNamePrefix="select"
        isClearable={false}
        isLoading={isSearching}
        icon={     
          <div 
            className="mb-4"
            style={{marginLeft:290}}
          >         
            <FontAwesomeIcon 
              icon={["fas", "info-circle"]}
              size="1x"
              className="position-absolute border-none"
              id="campaign-interest"
              color="grey"
            />
            <TooltipWembii 
              id="campaign-interest"
              text={intl.formatMessage({ id: "app.tooltopAds10" })} 
            />
          </div>  
        }
      />
    </PanelWembii>
  );
}
