export const INFO_TAGS = {
  FULL_CONTACT_NAME: {
    name: "app.sendEmail.template.fullContactName" ,
    label: "{{nombre_contacto_completo}}"
  },
  FULL_USER_NAME: {
    name: "app.sendEmail.template.fullUserName",
    label: "{{nombre_usuario_completo}}"
  },
  USER_FIRSTNAME: {
    name: "app.sendEmail.template.userFirstame",
    label: "{{nombre_usuario}}"
  },
  USER_LASTNAME: {
    name: "app.sendEmail.template.userLastname",
    label: "{{apellido_usuario}}"
  },
}