import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { FacebookForm, FormLead } from "../interfaces/api/get-facebook-form";
import { Paging } from "../../globals/interfaces/paging";

export const getFacebookFormsInitAction = createAction(
  "[FacebookForms] Get FacebookForms Init"
);
export const getFacebookFormsSuccessAction = createAction(
  "[FacebookForms] Get FacebookForms Success",
  props<{ forms: FacebookForm[], paging: Paging }>()
);
export const getFacebookFormsFailureAction = createAction(
  "[FacebookForms] Get FacebookForms Failure",
  props<{ error: string }>()
);

export const getFacebookFormInitAction = createAction(
  "[FacebookForms] Get FacebookForms Init"
);
export const getFacebookFormSuccessAction = createAction(
  "[FacebookForms] Get FacebookForm Success",
  props<{ form: FacebookForm, leads: FormLead[], paging: Paging }>()
);
export const getFacebookFormFailureAction = createAction(
  "[FacebookForms] Get FacebookForm Failure",
  props<{ error: string }>()
);

export const setViewAction = createAction(
  "[FacebookForms] Set view action",
  props<{ view: string }>()
);

const actions = joinActions({
  getFacebookFormsInitAction,
  getFacebookFormsSuccessAction,
  getFacebookFormsFailureAction,
  getFacebookFormInitAction,
  getFacebookFormSuccessAction,
  getFacebookFormFailureAction,
  setViewAction,
});

export type FacebookFormApiAction = typeof actions;
