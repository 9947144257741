import moment from "moment";

export const DAY_OF_WEEK: Record<string, string> = {
  "0": "Domingo",
  "1": "Lunes",
  "2": "Martes",
  "3": "Miercoles",
  "4": "Jueves",
  "5": "Viernes",
  "6": "Sabado",
};

export const MONTH: Record<string, string> = {
  "1": "Enero",
  "2": "Febrero",
  "3": "Marzo",
  "4": "Abril",
  "5": "Mayo",
  "6": "Junio",
  "7": "Julio",
  "8": "Agosto",
  "9": "Septiembre",
  "10": "Octubre",
  "11": "Noviembre",
  "12": "Diciembre",
};

export const daysRemainingFromToday = (date: string) => {
  var eventDate = moment(date);
  var todayDate = moment();
  return eventDate.diff(todayDate, "days");
};
