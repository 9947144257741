import {
  ChatUser,
  ChatPayload,
  GetChatResponse,
} from "../interfaces/api/get-whatsapp-chat";
import { PostChatUserResponse } from "../interfaces/api/post-chat-user";
import { PutChatUserResponse } from "../interfaces/api/put-chat-user";
import {
  PutChatResponse,
  ResponsePayload,
} from "../interfaces/api/put-whatsapp-chat";

export const chatMapper = {
  mapResponseToChat(response: GetChatResponse): ChatPayload {
    return {
      chat: response.payload.chat,
      whatsappUsers: response.payload.whatsappUsers,
    };
  },
  mapResponseToChatConfig(response: PutChatResponse): ResponsePayload {
    return { chat: response.payload.chat };
  },
  mapResponseToChatUser(response: PostChatUserResponse | PutChatUserResponse): {
    whatsappUser: ChatUser;
  } {
    return { whatsappUser: response.payload.whatsappUser };
  },
};
