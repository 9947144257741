import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Button, Col, Container, Row } from "reactstrap";
import { useMeCore } from "../../../../../cores/me";
import { useIntl } from "react-intl";
import { useWebFormCore } from "../../../../../cores/webForm";
import { PanelWembii } from "../../../PanelWembii";
import { TitleWembii } from "../../../TitleWembii";
import { useContactCore } from "../../../../../cores/contacts";
import { ContactSourceOptions } from "../../../../../cores/contacts/config";
import { InputWembii } from "../../../InputWembii";
import { ContactSourceBadge } from "../../../contacts/ContactSource";
import { ContactSource } from "../../../../../cores/contacts/enum/ContactSource.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_PRIMARY } from "../../../../../constants/theme";
import { CONTACT_TAGS_FORM } from "../../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../../cores/globals";
import { useCompanyCore } from "../../../../../cores/company";

interface Props {
  onSubmit: () => void;
}

export default function ContactForm({ onSubmit }: Props) {
  const intl = useIntl();
  const { company } = useMeCore();
  const { turnOnModal } = useGlobalCore();
  const { tags, getContactTags } = useContactCore();
  const [hasChange, setHasChange] = useState(false);
  const { users } = useCompanyCore();
  const {
    webForm,
    setForm,
    postFormUser,
    deleteFormUser,
  } = useWebFormCore();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [selected, setSelected] = useState<{ value: string; label: string }[]>(
    []
  );

  const handleChange = useCallback(
    async (_value, { action, removedValue, option }) => {
      switch (action) {
        case "remove-value":
          const user = webForm.users.find(
            (item) => item.userId === removedValue.value
          );

          await deleteFormUser(
            company?.id!,
            webForm?.form?.id!,
            user?.id!
          );
          break;
        case "select-option":
          await postFormUser(
            company?.id!,
            webForm?.form?.id!,
            option.value
          );
          break;
      }
    },
    [company, webForm, deleteFormUser, postFormUser]
  );

  useEffect(() => {
    setOptions(
      users.map((user) => {
        return { value: user.id, label: `${user.firstName} ${user.lastName}` };
      })
    );
  }, [users]);

  useEffect(() => {
    setSelected(
      options.filter((option) =>
        webForm.users.some((item) => item.userId === option.value)
      )
    );
  }, [options, webForm.users]);

  const tagsValues = useMemo(() => 
    [
      ...ContactSourceOptions.map((source) =>({
        value: source.value,
        label: intl.formatMessage({id: source.label})
      })),
      ...tags.map((tag) => ({ value: tag.nameId, label: tag.name }))
    ]
  , [tags, intl]);

  const onOpenContactTags = useCallback(() => {
    getContactTags(company?.id!);
    turnOnModal(CONTACT_TAGS_FORM, true);
  }, [turnOnModal, getContactTags, company]);

  const handleConfigChange = useCallback((field) => {
    setForm({ ...webForm.form, ...field });
    setHasChange(true);
  }, [webForm, setForm]);

  const saveConfig = useCallback(() => {
    onSubmit();
    setHasChange(false);
  }, [onSubmit]);

  return (
    <Container className="p-0">
      <PanelWembii shadow className="mb-4 p-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.editForm.ContactTitle" })}
        </TitleWembii>
        <Row className="h-100">
          <Col xs="12">
            {intl.formatMessage({ id: "app.editForm.ContactSubTitle" })}
            <div className="d-flex align-items-center my-2">
              <InputWembii
                type="select"
                name="tag"
                value={webForm.form?.tag}
                className="mb-0"
                onChange={(v) => handleConfigChange({ tag: v })}
              >
                {tagsValues.map(tag => 
                  <option key={tag.value} value={tag.value}>
                    {tag.label}
                  </option>
                )}
              </InputWembii>
              <FontAwesomeIcon
                icon={["fas", "plus"]}
                size="2x"
                className="mx-3 pointer"
                color={COLOR_PRIMARY}
                onClick={onOpenContactTags}
              />
              <ContactSourceBadge source={webForm.form?.tag as ContactSource} />
            </div>

            {intl.formatMessage({ id: "app.editForm.UserSubTitle" })}
            <Select
              isMulti
              name="users"
              options={options}
              value={selected}
              className="mt-2"
              placeholder={intl.formatMessage({ id: "app.select.placeholder" })}
              noOptionsMessage={() => intl.formatMessage({ id: "app.select.noData" })}
              onChange={handleChange}
              classNamePrefix="select"
              isClearable={false}
            />

            <div className="d-flex justify-content-center mt-2">
              <Button color="primary" disabled={!hasChange} onClick={saveConfig}>
                {intl.formatMessage({ id: "app.editForm.saveConfig" })}
              </Button>
            </div>
          </Col>
        </Row>
      </PanelWembii>
    </Container>
  );
}
