import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import cn from "classnames";

export const LineDropdown: React.FC<{
  label: string;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  children: ReactNode;
  icon: IconProp;
  className?: string;
}> = ({ open, setOpen, children, icon, label, className }) => {
  return (
    <div
      onClick={() => setOpen(!open)}
      className={cn(`my-auto pointer position-relative ${className}`, {
        "text-primary": open,
      })}
    >
      <div className="d-flex">
        <FontAwesomeIcon
          className={cn("my-auto mx-2 ", {
            "text-primary": open,
          })}
          icon={icon!}
          size="lg"
          color="gray"
        />
        <p className="w-100 text-center my-auto ">{label}</p>

        <div
          hidden={!open}
          className="bg-light shadow position-absolute"
          style={{ zIndex: 999, top: 30, left: -50 }}
        >
          {children}
        </div>
        <FontAwesomeIcon
          className={cn("mx-2 my-auto ", {
            "text-primary": open,
          })}
          icon={open ? ["fas", "chevron-up"] : ["fas", "chevron-down"]}
          size="lg"
          color="gray"
        />
      </div>
    </div>
  );
};
