import React, { useCallback, useState } from "react";
import Switch from "react-switch";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import { COLOR_PRIMARY } from "../../../../../constants/theme";
import { useIntl } from "react-intl";
import { useWhatsappChatCore } from "../../../../../cores/whatsappChat";
import { useMeCore } from "../../../../../cores/me";
import { InputWembii } from "../../../InputWembii";
import { PanelWembii } from "../../../PanelWembii";
import { TitleWembii } from "../../../TitleWembii";
import { POSITION } from "../../../../../constants/position.constant";

export default function GeneralForm() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { chat, setChatConfig, putChat } = useWhatsappChatCore();

  const [hasChange, setHasChange] = useState(false);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    setHasChange(false);

    putChat(
      company?.id!,
      chat?.config?.id!,
      chat?.config?.title!,
      chat?.config?.subtitle!,
      chat?.config?.titleColor!,
      chat?.config?.backgroundColor!,
      chat?.config?.startChat!,
      chat?.config?.openButtonColor!,
      chat?.config?.openButtonIconColor!,
      chat?.config?.position!,
      chat?.config?.isActive!,
    );
  };

  const disabled = useCallback(
    () => !chat?.config?.title || !chat?.config?.subtitle || !chat?.config?.titleColor
      || !chat?.config?.backgroundColor || !chat?.config?.openButtonColor
      || !chat?.config?.openButtonIconColor,
    [chat]
  );

  const handleMainConfigChange = useCallback((field) => {
    setChatConfig({ ...chat.config, ...field });
    setHasChange(true);
  }, [chat, setChatConfig]);

  return (
    <Container className="p-0">
      <PanelWembii shadow className="p-4 mb-4">
        <Form className="w-100" onSubmit={onFormSubmit}>
          <TitleWembii
            Tag="h5"
            color="primary"
            toolbar={
              <>
                <FormGroup className="d-flex align-items-center">
                  <span className="text-secondary font-weight-bold">
                    {chat?.config?.position === POSITION.LEFT
                      ? intl.formatMessage({ id: "app.configWspp.left?" })
                      : intl.formatMessage({ id: "app.configWspp.rigth?" })
                    }
                  </span>
                  <Switch
                    id="position"
                    name="position"
                    className="ml-1"
                    checked={chat?.config?.position === POSITION.RIGHT}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor={COLOR_PRIMARY}
                    height={20}
                    width={30}
                    onChange={(v) => handleMainConfigChange({position: v ? POSITION.RIGHT : POSITION.LEFT})}
                  />
                </FormGroup>

                <FormGroup className="d-flex align-items-center">
                  <span className="text-secondary font-weight-bold">
                    {chat?.config?.isActive === true
                      ? intl.formatMessage({ id: "app.configWspp.active" })
                      : intl.formatMessage({ id: "app.configWspp.disabled" })
                    }
                  </span>
                  <Switch
                    id="isActive"
                    name="isActive"
                    className="ml-1"
                    checked={chat?.config?.isActive!}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor={COLOR_PRIMARY}
                    height={20}
                    width={30}
                    onChange={(v) => handleMainConfigChange({isActive: v})}
                  />
                </FormGroup>
              </>
            }
          >
            {intl.formatMessage({ id: "app.configWspp.chatAppearance" })}
          </TitleWembii>
          <Row>
            <Col>
              <InputWembii
                type="text"
                name="title"
                id="title"
                label={intl.formatMessage({ id: "app.configWspp.titleTxt" })}
                value={chat?.config?.title}
                flat
                required
                onChange={(v) => handleMainConfigChange({ title: v })}
              />
            </Col>
            <Col>
              <InputWembii
                type="text"
                name="substitle"
                id="substitle"
                label={intl.formatMessage({ id: "app.configWspp.subtitlesTxt" })}
                value={chat?.config?.subtitle}
                flat
                required
                onChange={(v) => handleMainConfigChange({ subtitle: v })}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <InputWembii
                type="color"
                name="titleColor"
                id="titleColor"
                label={intl.formatMessage({ id: "app.configWspp.titleColorTxt" })}
                value={chat?.config?.titleColor}
                flat
                required
                onChange={(c) => handleMainConfigChange({ titleColor: c })}
              />
            </Col>
            <Col>
              <InputWembii
                type="color"
                name="backgroundColor"
                id="backgroundColor"
                label={intl.formatMessage({ id: "app.configWspp.backColor" })}
                value={chat?.config?.backgroundColor}
                flat
                required
                onChange={(c) => handleMainConfigChange({ backgroundColor: c })}
              />
            </Col>
          </Row>

          <TitleWembii
            Tag="h5"
            color="primary"
            toolbar={
              <FormGroup className="d-flex align-items-center">
                <span className="text-secondary font-weight-bold">
                 {intl.formatMessage({ id: "app.configWspp.startChat" })}
                </span>
                <Switch
                  id="startChat"
                  name="startChat"
                  className="ml-1"
                  checked={chat?.config?.startChat!}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor={COLOR_PRIMARY}
                  height={20}
                  width={30}
                  onChange={(v) => handleMainConfigChange({startChat: v})}
                />
              </FormGroup>
            }>
            {intl.formatMessage({ id: "app.configWspp.btnTxt" })}
          </TitleWembii>

          <Row>
            <Col>
              <InputWembii
                type="color"
                name="openButtonColor"
                id="openButtonColor"
                label={intl.formatMessage({ id: "app.configWspp.btnStartColor" })}
                value={chat?.config?.openButtonColor}
                flat
                required
                onChange={(c) => handleMainConfigChange({ openButtonColor: c })}
              />
            </Col>
            <Col>
              <InputWembii
                type="color"
                name="openButtonIconColor"
                id="openButtonIconColor"
                label={intl.formatMessage({ id: "app.configWspp.iconStartColor" })}
                value={chat?.config?.openButtonIconColor}
                flat
                required
                onChange={(c) =>
                  handleMainConfigChange({ openButtonIconColor: c })
                }
              />
            </Col>
          </Row>

          <div className="d-flex justify-content-center mt-2">
            <Button color="primary" disabled={disabled() || !hasChange}>
             {intl.formatMessage({ id: "app.configWspp.saveConfig" })}
            </Button>
          </div>
        </Form>
      </PanelWembii>
    </Container>
  );
}
