export const SocketAction = {
  MESSAGE: "action://message",
  WEB_MESSAGE: "action://web-message",
  NOTIFICATION: "action://notification",
  CONVERSATION: "action://conversation",
  CONVERSATION_STATUS: "action://conversation-status-change",
  SOCIAL_POST: "action://socialPost",
  SUBSCRIBE: "action://subscribe",
  LEAVE: "action://leave",
  CLIENT_SUBSCRIBE: "action://client-subscribe",
  ONLINE: "action://online",
  OFFLINE: "action://offline",
  DISCONECTING: "disconnecting",
}
