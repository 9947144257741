import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Col, Input, Label, Media, Row } from "reactstrap";
import {
  FACEBOOK_COLOR,
  INSTAGRAM_COLOR,
} from "../../../constants/socialColors.constant";
import { useAdvertisementCore } from "../../../cores/advertisements";
import { ADS_CONFIG_RULES } from "../../../cores/advertisements/config";
import { beforeUploadFile, UploadFileType } from "../../../cores/utils";
import { InputWembii } from "../InputWembii";
import { useIntl } from "react-intl";
import { LabelWembii } from "../LabelWembii";
import { PanelWembii } from "../PanelWembii";
import { TitleWembii } from "../TitleWembii";
import { FacebookFeedPreview } from "./preview/FacebookFeedPreview";
import { FacebookStoryPreview } from "./preview/FacebookStoryPreview";
import { InstagramStoryPreview } from "./preview/InstagramStoryPreview";
import { InstagramStreamPreview } from "./preview/InstagramStreamPreview";
import { isUrl } from "../../utils/stringUtils";
import { TooltipWembii } from "../TooltipWembii";

export default function DesignForm() {
  const intl = useIntl();
  const { campaign, image, setCampaign, selectImage } = useAdvertisementCore();
  const [preview, setPreview] = useState("facebookFeed");

  const ref = useRef<any>(null);

  const filesAllowed = useMemo(() => [
    UploadFileType.JPEG,
    UploadFileType.JPG,
    UploadFileType.PNG
  ], []);

  const handleSelectPhoto = (files: FileList | null) => {
    if (files) {
      const error = !beforeUploadFile(files[0], ADS_CONFIG_RULES.maxImageSize, filesAllowed)

      if (!error) {
        selectImage(files[0], URL.createObjectURL(files[0]));
      }
    }
  };

  const handleConfigChange = useCallback(
    (field) => {
      setCampaign({ ...campaign, ...field });
    },
    [campaign, setCampaign]
  );

  const isFacebookConfigSet = (value: string) => {
    return campaign.targeting.facebookPositions.includes(value);
  };

  const isInstagramConfigSet = (value: string) => {
    return campaign.targeting.instagramPositions.includes(value);
  };

  const isUrlInvalid = useMemo(() =>
    campaign.urlBusiness !== '' && !isUrl(campaign.urlBusiness)
    , [campaign]
  );

  return (
    <PanelWembii shadow className="mb-4 p-4">
      <div className="d-flex w-100">
        <div className="flex-grow-1 mr-5">
          <TitleWembii Tag="h5" color="primary">
            {intl.formatMessage({ id: "app.advertisements.design" })}
          </TitleWembii>

          <Label
            className="position-relative border rounded mb-4"
            style={{
              height: 150,
              width: 150,
            }}
          >
            {image.url ? (
              <>
                <Media
                  src={image.url}
                  className="w-100 h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "50% top",
                  }}
                />
                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  className="position-absolute float-top-right pointer"
                  size="1x"
                  color="red"
                  onClick={() => selectImage(null, "")}
                />
              </>
            ) : (
              <Label
                for="imageUploader"
                className="h-100 pointer d-flex flex-column justify-content-center align-items-center"
              >
                <FontAwesomeIcon
                  icon={["far", "image"]}
                  size="2x"
                  color="gray"
                />
                <span className="text-muted" style={{ fontSize: 12 }}>
                  {intl.formatMessage({ id: "app.advertisements.designChooseImage" })}
                </span>
              </Label>
            )}
          </Label>
          <Input
            ref={ref}
            type="file"
            id="imageUploader"
            className="d-none"
            accept={filesAllowed.join(',')}
            onChange={(e) => handleSelectPhoto(e.target.files)}
          />
          <Row>
            <Col>
              <InputWembii
                type="text"
                name="businessName"
                id="businessName"
                label={intl.formatMessage({ id: "app.advertisements.designbusinessName" })}
                flat
                required
                value={campaign.titleBusiness}
                onChange={(v) => handleConfigChange({ titleBusiness: v })}
                icon={     
                  <div className="mb-4 mr-3">         
                    <FontAwesomeIcon 
                      icon={["fas", "info-circle"]}
                      size="1x"
                      className="position-absolute border-none"
                      id="campaign-businessName"
                      color="grey"
                    />
                    <TooltipWembii 
                      id="campaign-businessName"
                      text={intl.formatMessage({ id: "app.tooltopAds11" })} 
                    />
                  </div>  
                }
              />
            </Col>
            <Col>
              <InputWembii
                type="text"
                name="siteUrl"
                id="siteUrl"
                label={intl.formatMessage({ id: "app.advertisements.designbusinessUrlSite" })}
                flat
                required
                invalid={isUrlInvalid}
                value={campaign.urlBusiness}
                onChange={(v) => handleConfigChange({ urlBusiness: v })}
                icon={     
                  <div className="mb-4 mr-3">         
                    <FontAwesomeIcon 
                      icon={["fas", "info-circle"]}
                      size="1x"
                      className="position-absolute border-none"
                      id="campaign-businnessUrl"
                      color="grey"
                    />
                    <TooltipWembii 
                      id="campaign-businnessUrl"
                      text={intl.formatMessage({ id: "app.tooltopAds12" })} 
                    />
                  </div>  
                }
              />
            </Col>
          </Row>
          <InputWembii
            type="textarea"
            name="description"
            id="description"
            label={intl.formatMessage({ id: "app.advertisements.designDesciption" })}
            required
            value={campaign.description}
            onChange={(v) => handleConfigChange({ description: v })}
            icon={     
              <div className="mb-4 mr-2">         
                <FontAwesomeIcon 
                  icon={["fas", "info-circle"]}
                  size="1x"
                  className="position-absolute border-none"
                  id="campaign-designDesciption"
                  color="grey"
                />
                <TooltipWembii 
                  id="campaign-designDesciption"
                  text={intl.formatMessage({ id: "app.tooltopAds13" })} 
                />
              </div>  
            }
          />
        </div>
        <div>
          <LabelWembii>
            {intl.formatMessage({ id: "app.advertisements.designPreview" })}
          </LabelWembii>

          {preview === "facebookFeed" && <FacebookFeedPreview />}
          {preview === "facebookStory" && <FacebookStoryPreview />}
          {preview === "instagramStream" && <InstagramStreamPreview />}
          {preview === "instagramStory" && <InstagramStoryPreview />}

          <div className="d-flex justify-content-center w-100 mt-2">
            {isFacebookConfigSet("feed") && (
              <FontAwesomeIcon
                icon={["fab", "facebook"]}
                size="2x"
                className="m-1 pointer"
                color={FACEBOOK_COLOR}
                onClick={() => setPreview("facebookFeed")}
              />
            )}
            {isFacebookConfigSet("story") && (
              <FontAwesomeIcon
                icon={["fab", "facebook"]}
                size="2x"
                className="m-1 pointer"
                color={FACEBOOK_COLOR}
                onClick={() => setPreview("facebookStory")}
              />
            )}
            {isInstagramConfigSet("stream") && (
              <FontAwesomeIcon
                icon={["fab", "instagram"]}
                size="2x"
                className="m-1 pointer"
                color={INSTAGRAM_COLOR}
                onClick={() => setPreview("instagramStream")}
              />
            )}
            {isInstagramConfigSet("story") && (
              <FontAwesomeIcon
                icon={["fab", "instagram"]}
                size="2x"
                className="m-1 pointer"
                color={INSTAGRAM_COLOR}
                onClick={() => setPreview("instagramStory")}
              />
            )}
          </div>
        </div>
      </div>
    </PanelWembii>
  );
}
