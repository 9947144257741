import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useIntl } from "react-intl";

interface Props {
  onSubmit: (arg0: string) => void;
  previousName?: string;
}

export default function FormNameModal(props: Props) {
  const intl = useIntl()
  const { onSubmit, previousName } = props;

  const [name, setName] = useState("");

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    onSubmit(name);
    setName("");
  };

  const disabled = useCallback(() => !name, [name]);

  useEffect(() => {
    if (previousName) {
      setName(previousName);
    }
  }, [previousName]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 justify-content-center py-5">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.advertisements.formWemName" })} 
            </h2>
            <Form className="px-2" onSubmit={onFormSubmit}>
              <FormGroup>
                <Label for="form-name">
                  {intl.formatMessage({ id: "app.advertisements.formWemNameTxt" })} 
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="form-name"
                  placeholder={intl.formatMessage({ id: "app.advertisements.formWemNameTxt" })} 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
              <Button color="primary" block disabled={disabled()}>
                {intl.formatMessage({ id: "app.advertisements.formWemAceptTxt" })} 
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
