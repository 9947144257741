import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { BOARD_CONTACTS_MODAL, CONTACT_EXPAND_EDIT_FORM, DELETE_BOARD_ITEM_MODAL } from "../../../../constants/modal.constant";
import { COLOR_DANGER, COLOR_PRIMARY } from "../../../../constants/theme";
import { useBoardCore } from "../../../../cores/boards";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import AreYouSure from "../../AreYouSure";
import Avatar from "../../Avatar";
import EditContactForm from "../../contacts/modal/EditContactForm";
import ContactList from "../modal/ContactList";
import { useContactCore } from "../../../../cores/contacts";
import { truncateText } from "../../../utils/stringUtils";
import { useIntl } from "react-intl";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useConfigCore } from "../../../../cores/config";

interface Props {
  canModify: boolean;
  board: string;
}

export default function ContactContainer({ canModify, board }: Props) {
  const intl = useIntl();
  const { company } = useMeCore();
  const { getCommentTypes } = useConfigCore();
  const {
    card,
    postContact,
    deleteContact,
    getCard,
  } = useBoardCore();
  const { getContactTags, getContact, getCustomFields } = useContactCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const openAssignmentContacts = useCallback(() => {
    turnOnModal(
      BOARD_CONTACTS_MODAL,
      <ContactList
        assignments={card?.contacts!}
        companies={card?.companies!}
        onAssign={(contact) => {
          postContact(
            company?.id!,
            board,
            card?.list!,
            card?.id!,
            contact.id,
            () => {
              getCard(company?.id!, board, card?.list!, card?.id!);
            }
          );
          turnOffModal(BOARD_CONTACTS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, company, board, card, postContact, getCard]);

  const onShowAssignment = useCallback((contact) => {
    if (canModify) {
      getContactTags(company?.id!);
      getCommentTypes(company?.id!);
      getCustomFields(company?.id!);
      getContact(company?.id!, contact.id);
      turnOnModal(
        CONTACT_EXPAND_EDIT_FORM,
        <EditContactForm
          isUnassignedButton={true}
          closeOnUpdating={false}
          onDelete={() => {
            turnOnModal(
              DELETE_BOARD_ITEM_MODAL,
              <AreYouSure
                message={`Está seguro de querer desasignar el contacto ${contact.name}`}
                onSubmit={() => {
                  deleteContact(
                    company?.id!,
                    board,
                    card?.list!,
                    card?.id!,
                    contact.id,
                    () => {
                      getCard(company?.id!, board, card?.list!, card?.id!);
                    }
                  );
                  turnOffModal(DELETE_BOARD_ITEM_MODAL);
                  turnOffModal(CONTACT_EXPAND_EDIT_FORM);
                }}
                onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
              />,
              Position.LEFT
            );
          }}
          onUpdate={() => {
            getCard(company?.id!, board, card?.list!, card?.id!);
          }}
        />,
        Position.LEFT
      );
    }
  }, [
    turnOnModal,
    turnOffModal,
    deleteContact,
    getContactTags,
    getCustomFields,
    getCommentTypes,
    getCard,
    getContact,
    company,
    board,
    card,
    canModify
  ]);

  return (
    <div>
      <div className="d-flex flex-wrap align-items-center">
        {card?.contacts.map((contact) => (
          <div
            key={contact.id}
            className="text-dark-50 font-weight-bold pointer m-1 px-2 border border-warning rounded-pill position-relative"
            onClick={() => onShowAssignment(contact)}
          >
            {contact.status !== UserStatus.ACTIVE.id && (
              <FontAwesomeIcon
                icon={["fas", "ban"]}
                size="1x"
                style={{
                  top: "-5px",
                  right: "-5px"
                }}
                className="position-absolute"
                color={COLOR_DANGER}
              />
            )}
            {truncateText(contact.name) || contact.email || contact.phoneNumber}
          </div>
        ))}
        {!canModify && card?.contacts.length === 0 && (
          <span className="text-muted">
            {intl.formatMessage({ id: "app.crmBusiness.noContacts" })}
          </span>
        )}
        {canModify && (
          <Avatar
            className="text-dark-50 pointer m-1"
            onClick={openAssignmentContacts}
            icon={
              <FontAwesomeIcon
                icon={["fas", "plus"]}
                size="1x"
                color={COLOR_PRIMARY}
              />
            }
          />
        )}
      </div>
    </div>
  );
}
