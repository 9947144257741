import { Dictionary } from "lodash";

export const ContactInformation = {
  NAME: "name",
  EMAIL: "email",
  CREATED_AT: "createdAt",
  UPDATED_AT: "updatedAt",
  PHONE: "phone",
  WHATSAPP: "whatsapp",
  STATE: "state",
  OWNERS: "owners",
  SOURCE: "source",
}

export const DefaultInformationOptions = [
  { value: ContactInformation.CREATED_AT, label: "app.contacts.createdDate" },
  { value: ContactInformation.UPDATED_AT, label: "app.contacts.updatedDate" },
  { value: ContactInformation.WHATSAPP, label: "app.contacts.whatsapp" },
  { value: ContactInformation.STATE, label: "app.contacts.state" },
  { value: ContactInformation.OWNERS, label: "app.contacts.owners" },
  { value: ContactInformation.SOURCE, label: "app.contacts.origin" },
];

export const  ContactInformationLabels: Dictionary<string> = {
  [`${ContactInformation.NAME}`]: "app.contacts.name",
  [`${ContactInformation.PHONE}`]: "app.contacts.phone",
  [`${ContactInformation.WHATSAPP}`]: "app.contacts.whatsapp",
};

export const ContactInformationOptions = [
  { value: ContactInformation.PHONE, label: "app.contacts.phone" },
  { value: ContactInformation.WHATSAPP, label: "app.contacts.whatsapp" },
];