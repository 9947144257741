import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { SocialTemplate } from "../interface/api/get-social-templates";
import { OpenAiSetting } from "../interface/api/post-openai-bind";

export const postUnbindOpenAiInitAction = createAction(
  "[OpenAi] Unbind OpenAi Init"
);
export const postUnbindOpenAiSuccessAction = createAction(
  "[OpenAi] Unbind OpenAi Success"
);
export const postUnbindOpenAiFailureAction = createAction(
  "[OpenAi] Unbind OpenAi Failure",
  props<{ error: string }>()
);

export const postBindOpenAiInitAction = createAction(
  "[OpenAi] Bind OpenAi Init"
);
export const postBindOpenAiSuccessAction = createAction(
  "[OpenAi] Bind OpenAi Success",
  props<{ settings: OpenAiSetting }>()
);
export const postBindOpenAiFailureAction = createAction(
  "[OpenAi] Bind OpenAi Failure",
  props<{ error: string }>()
);

export const getOpenAiInitAction = createAction(
  "[OpenAi] Get OpenAi Settings Init"
);
export const getOpenAiSuccessAction = createAction(
  "[OpenAi] Get OpenAi Settings Success",
  props<{ settings: OpenAiSetting }>()
);
export const getOpenAiFailureAction = createAction(
  "[OpenAi] Get OpenAi Settings Failure",
  props<{ error: string }>()
);

export const getSocialTemplatesInitAction = createAction(
  "[OpenAi] Get Social Templates Init"
);
export const getSocialTemplatesSuccessAction = createAction(
  "[OpenAi] Get Social Templates Success",
  props<{ templates: SocialTemplate[] }>()
);
export const getSocialTemplatesFailureAction = createAction(
  "[OpenAi] Get Social Templates Failure",
  props<{ errorCode: number | null, errorMessage: string | null }>()
);

const actions = joinActions({
  postUnbindOpenAiInitAction,
  postUnbindOpenAiSuccessAction,
  postUnbindOpenAiFailureAction,
  postBindOpenAiInitAction,
  postBindOpenAiSuccessAction,
  postBindOpenAiFailureAction,
  getOpenAiInitAction,
  getOpenAiSuccessAction,
  getOpenAiFailureAction,
  getSocialTemplatesInitAction,
  getSocialTemplatesSuccessAction,
  getSocialTemplatesFailureAction,
});

export type OpenAiApiAction = typeof actions;
