import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { COLOR_PRIMARY } from "../../../constants/theme";
import { useAdvertisementCore } from "../../../cores/advertisements";
import { CampaignStatus } from "../../../cores/advertisements/constants/campaign-status";
import { useMeCore } from "../../../cores/me";
import { InputWembii } from "../InputWembii";
import ActivateButton from "./ActivateButton";
import { useIntl } from "react-intl";

export default function CampaingsTable() {
  const intl = useIntl();
  const [campaignAdset, setCampaignAdset] = useState("");
  const [campaignAd, setCampaignAd] = useState("");
  const { company } = useMeCore();
  const {
    campaigns,
    adsets,
    ads,
    minBudget,
    getAdsets,
    getAds,
    putCampaign,
    putAdset,
    putAd,
  } = useAdvertisementCore();

  const columnsCampaigns = useMemo(
    () => [
      { name: "Campaña", width: 200 },
      { name: "Fecha de inicio", width: 200 },
      { name: "Fecha de fin", width: 200 },
      { name: "Estado", width: 150 },
      { name: "Gasto", width: 150 },
      { name: "Impresiones", width: 150 },
      { name: "Clicks", width: 150 },
      { name: "CPM", width: 150 },
      { name: "CPP", width: 150 },
      { name: "CPC", width: 150 },
      { name: "CTR", width: 150 },
      { name: "Alcance", width: 150 },
    ],
    []
  );

  const columnsAdset = useMemo(
    () => [
      { name: "Grupo de anuncios", width: 200 },
      { name: "Estado", width: 200 },
      { name: "Presupuesto Diario", width: 150 },
      { name: "Gasto", width: 150 },
      { name: "Impresiones", width: 150 },
      { name: "Clicks", width: 150 },
      { name: "CPM", width: 150 },
      { name: "CPP", width: 150 },
      { name: "CPC", width: 150 },
      { name: "CTR", width: 150 },
      { name: "Alcance", width: 150 },
    ],
    []
  );

  const columnsAds = useMemo(
    () => [
      { name: "Anuncio", width: 200 },
      { name: "Estado", width: 200 },
      { name: "Gasto", width: 150 },
      { name: "Impresiones", width: 150 },
      { name: "Clicks", width: 150 },
      { name: "CPM", width: 150 },
      { name: "CPP", width: 150 },
      { name: "CPC", width: 150 },
      { name: "CTR", width: 150 },
      { name: "Alcance", width: 150 },
    ],
    []
  );

  const handleSelectCampaign = useCallback(
    async (id) => {
      if (id === campaignAdset) {
        setCampaignAdset("");
        setCampaignAd("");
      } else {
        await getAdsets(company?.id!, id);
        setCampaignAdset(id);
      }
    },
    [company, campaignAdset, getAdsets]
  );

  const handleSelectAdset = useCallback(
    async (id) => {
      if (id === campaignAd) {
        setCampaignAd("");
      } else {
        await getAds(company?.id!, id);
        setCampaignAd(id);
      }
    },
    [company, campaignAd, getAds]
  );

  const handleUpdateCampaign = useCallback(
    async (id, status, endDate) => {
      if (!endDate || moment().isBefore(endDate)) {
        if (status === CampaignStatus.ACTIVE.id) {
          putCampaign(company?.id!, id, CampaignStatus.PAUSED.id);
        } else if (status === CampaignStatus.PAUSED.id) {
          putCampaign(company?.id!, id, CampaignStatus.ACTIVE.id);
        }
      }
    },
    [company, putCampaign]
  );

  const handleUpdateAdset = useCallback(
    async (id, status, dailyBudget, changeStatus, endDate) => {
      if (!endDate || moment().isBefore(endDate)) {
        if (changeStatus) {
          if (status === CampaignStatus.ACTIVE.id) {
            putAdset(company?.id!, id, CampaignStatus.PAUSED.id, dailyBudget);
          } else if (status === CampaignStatus.PAUSED.id) {
            putAdset(company?.id!, id, CampaignStatus.ACTIVE.id, dailyBudget);
          }
        } else {
          putAdset(company?.id!, id, status, dailyBudget);
        }
      }
    },
    [company, putAdset]
  );

  const debouncedUpdate = useRef(
    _.debounce((id, status, dailyBudget, endDate) => {
      if (dailyBudget < minBudget?.minDailyBudgetImp!) {
        toast.error(
          `${intl.formatMessage({ id: "app.toast.error64" })} ${minBudget?.minDailyBudgetImp}`
        );
      } else {
        handleUpdateAdset(id, status, dailyBudget, false, endDate);
      }
    }, 1000)
  ).current;

  const handleUpdateAd = useCallback(
    async (id, status, endDate) => {
      if (!endDate || moment().isBefore(endDate)) {
        if (status === CampaignStatus.ACTIVE.id) {
          putAd(company?.id!, id, CampaignStatus.PAUSED.id);
        } else if (status === CampaignStatus.PAUSED.id) {
          putAd(company?.id!, id, CampaignStatus.ACTIVE.id);
        }
      }
    },
    [company, putAd]
  );

  const expandIcon = useCallback(
    (condition) =>
      condition ? (
        <FontAwesomeIcon
          icon={["fas", "angle-down"]}
          size="1x"
          className="mr-1"
          color={COLOR_PRIMARY}
        />
      ) : (
        <FontAwesomeIcon
          icon={["fas", "angle-right"]}
          size="1x"
          className="mr-1"
          color={COLOR_PRIMARY}
        />
      ),
    []
  );

  return (
    <div className="overflow-auto h-100">
      <div className="d-inline-flex">
        {columnsCampaigns.map((column) => (
          <div
            className="header"
            key={column.name}
            style={{
              width: column.width,
            }}
          >
            {column.name}
          </div>
        ))}
      </div>
      {campaigns.map((campaign) => (
        <div className="table" key={campaign.id}>
          <div className="d-inline-flex table-row">
            <div
              className="table-cell pointer justify-content-start table-cell-big"
              onClick={() => handleSelectCampaign(campaign.id)}
            >
              {expandIcon(campaignAdset === campaign.id)}
              {campaign.name}
              {}
            </div>
            <div className="table-cell table-cell-big">
              {campaign.startTime}
            </div>
            <div className="table-cell table-cell-big">{campaign.stopTime}</div>
            <div className="table-cell">
              <ActivateButton
                status={campaign.status}
                endDate={campaign.stopTime}
                onClick={() =>
                  handleUpdateCampaign(campaign.id, campaign.status, campaign.stopTime)
                }
              />
            </div>
            <div className="table-cell">{campaign.spend}</div>
            <div className="table-cell">{campaign.impressions}</div>
            <div className="table-cell">{campaign.clicks}</div>
            <div className="table-cell">{campaign.cpm}</div>
            <div className="table-cell">{campaign.cpp}</div>
            <div className="table-cell">{campaign.cpc}</div>
            <div className="table-cell">{campaign.ctr}</div>
            <div className="table-cell">{campaign.reach}</div>
          </div>

          {campaignAdset === campaign.id && (
            <div className="ml-2 pl-3 border-left border-primary table">
              <div className="d-inline-flex">
                {columnsAdset.map((column) => (
                  <div
                    className="header"
                    key={column.name}
                    style={{
                      width: column.width,
                    }}
                  >
                    {column.name}
                  </div>
                ))}
              </div>
              {adsets.map((adset) => (
                <div key={adset.id}>
                  <div className="d-inline-flex table-row">
                    <div
                      className="table-cell pointer justify-content-start table-cell-big"
                      onClick={() => handleSelectAdset(adset.id)}
                    >
                      {expandIcon(campaignAd === adset.id)}
                      {adset.name}
                    </div>
                    <div className="table-cell table-cell-big">
                      <ActivateButton
                        status={adset.status}
                        endDate={campaign.stopTime}
                        onClick={() =>
                          handleUpdateAdset(
                            adset.id,
                            adset.status,
                            adset.dailyBudget,
                            true,
                            campaign.stopTime
                          )
                        }
                      />
                    </div>
                    <div className="table-cell">
                      <InputWembii
                        type="number"
                        className="w-75 align-items-center"
                        rounded
                        defaultValue={adset.dailyBudget}
                        mb={false}
                        onChange={(v) =>
                          debouncedUpdate(adset.id, adset.status, v, campaign.stopTime)
                        }
                      />
                    </div>
                    <div className="table-cell">{adset.spend}</div>
                    <div className="table-cell">{adset.impressions}</div>
                    <div className="table-cell">{adset.clicks}</div>
                    <div className="table-cell">{adset.cpm}</div>
                    <div className="table-cell">{adset.cpp}</div>
                    <div className="table-cell">{adset.cpc}</div>
                    <div className="table-cell">{adset.ctr}</div>
                    <div className="table-cell">{adset.reach}</div>
                  </div>

                  {campaignAd === adset.id && (
                    <div className="ml-2 pl-3 border-left border-info table">
                      <div className="d-inline-flex">
                        {columnsAds.map((column) => (
                          <div
                            className="header"
                            key={column.name}
                            style={{
                              width: column.width,
                            }}
                          >
                            {column.name}
                          </div>
                        ))}
                      </div>
                      {ads.map((ad) => (
                        <div key={ad.id} className="d-inline-flex table-row">
                          <div className="table-cell justify-content-start table-cell-big">
                            {ad.name}
                          </div>
                          <div className="table-cell table-cell-big">
                            <ActivateButton
                              status={ad.status}
                              endDate={campaign.stopTime}
                              onClick={() => handleUpdateAd(ad.id, ad.status, campaign.stopTime)}
                            />
                          </div>
                          <div className="table-cell">{ad.spend}</div>
                          <div className="table-cell">{ad.impressions}</div>
                          <div className="table-cell">{ad.clicks}</div>
                          <div className="table-cell">{ad.cpm}</div>
                          <div className="table-cell">{ad.cpp}</div>
                          <div className="table-cell">{ad.cpc}</div>
                          <div className="table-cell">{ad.ctr}</div>
                          <div className="table-cell">{ad.reach}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
