import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useContactCore } from "../../../../cores/contacts";
import { ContactStateOptions } from "../../../../cores/contacts/interfaces/ContactState";

export const ContactStateDropdownOptions: React.FC<{
  onSelect: (stateLabel: string) => void;
}> = ({ onSelect }) => {
  const { states, setConversationContactState } = useContactCore();
  const intl = useIntl();
  const statesValues = useMemo(
    () => [
      ...ContactStateOptions.map((state) => ({
        value: state.value,
        label: intl.formatMessage({ id: state.label }),
      })),
      ...states.map((state) => ({ value: state.id, label: state.name })),
    ],
    [states, intl]
  );
  return (
    <div className="position-relative col-sm-12">
      <ul className="w-100">
        <li
          onClick={() => {
            onSelect(intl.formatMessage({ id: "app.chat.filterLabel" }));
            setConversationContactState("all");
          }}
          className="text-start p-2 btn w-100"
        >
          {intl.formatMessage({ id: "app.chat.filterLabel" })}
        </li>
        {statesValues.map((state) => (
          <li
            onClick={() => {
              onSelect(state.label);
              setConversationContactState(
                state.value === "all" ? state.value : Number(state.value)
              );
            }}
            className="text-start p-2 btn w-100"
            key={state.value}
          >
            {state.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
