import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const openAiRoot = (state: State) => state.openAi;

export const settingSelector = createSelector(
  openAiRoot,
  (openai) => openai.settings
);

export const showTemplatesSelector = createSelector(
  openAiRoot,
  (openai) => openai.showTemplates
);

export const socialTemplatesSelector = createSelector(
  openAiRoot,
  ({ socialTemplates }) => socialTemplates.ids.map((id) => socialTemplates.entities[id])
);