import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload/interface";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { v4 } from "uuid";
import { VIDEO_UPLOAD_LOADING } from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { useIntl } from "react-intl";
import { SocialProviderRules } from "../../../../cores/socialPost/config";
import { SOCIAL_POST_TYPES } from "../../../../cores/socialPost/consts/socialPostType.constant";
import { PostVideo } from "../../../../cores/socialPost/interfaces/postVideo";
import {
  beforeUploadFile,
  getBase64,
  UploadFileType,
} from "../../../../cores/utils";
import AreYouSure from "../../AreYouSure";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";
import { VideoPreview } from "../creator/VideoPreview";
import { ADD_SOCIAL_VIDEO_MODAL } from "../../../../constants/modal.constant";

export default function VideoForm() {
  const intl = useIntl();
  const {
    addVideo,
    addVideoDuration,
    addVideoSize,
    video,
    images,
    removeAllImages,
    removePostAllMedia,
    selectedPost,
    socialProviders,
  } = useSocialPostCore();
  const { turnOffLoading, turnOnLoading, turnOffModal, isLoading } = useGlobalCore();
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [videoType, setVideoType] = useState<string>("");

  useEffect(() => {
    if (video) {
      setVideoSrc(video.data);
      setVideoType(video.type);
    } else if (selectedPost && selectedPost.media.length) {
      setVideoSrc(selectedPost.media[0].url);
    } else {
      setVideoSrc(null);
    }
  }, [video, selectedPost]);

  const maxSizeVideo = useMemo(() => 
    Math.abs(_.max(socialProviders.map(
      ({ id }) => SocialProviderRules[id].maxVideoSize
    ))!),
  [socialProviders]);

  const handleBeforeUpload = (file: File | Blob) =>
    beforeUploadFile(file, maxSizeVideo, [UploadFileType.MP4, UploadFileType.MOV]);

  const handleOnChange = async ({ file }: UploadChangeParam) => {
    turnOnLoading(VIDEO_UPLOAD_LOADING);
    if (file.status !== "uploading" && file.originFileObj) {
      setTimeout(() => turnOffLoading(VIDEO_UPLOAD_LOADING), 1000);
      const data = await getBase64(file.originFileObj);
      const { name, type = "", lastModified = 0, size = 0 } = file;
      const _video: PostVideo = {
        id: v4(),
        lastModified,
        name,
        size,
        type,
        duration: 0,
        data: data as string,
      };

      if (selectedPost) {
        removePostAllMedia();
      }
      addVideo(_video);
    }
    setTimeout(() => turnOffLoading(VIDEO_UPLOAD_LOADING), 500);
  };

  const removeImages = useCallback(() => {
    removeAllImages();
    if (selectedPost) {
      removePostAllMedia();
    }
  }, [removeAllImages, selectedPost, removePostAllMedia]);

  const addMetadata = useCallback((video) => {
    addVideoDuration(video.duration);
    addVideoSize(video.videoWidth, video.videoHeight);
  }, [addVideoDuration, addVideoSize]);

  return (
    <div className="d-flex h-100">
      <div className="p-2 w-100 h-100">
        {(images.length > 0 ||
          (selectedPost?.post.type === SOCIAL_POST_TYPES.IMAGE &&
            selectedPost.media.length > 0)) && (
          <AreYouSure
            message="Ya tienes imágenes subidas, si subes un video perderás dichas imágenes"
            onClose={() => turnOffModal(ADD_SOCIAL_VIDEO_MODAL)}
            onSubmit={removeImages}
          />
        )}
        {images.length === 0 &&
          (!selectedPost ||
            selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO ||
            (selectedPost?.post.type === SOCIAL_POST_TYPES.IMAGE &&
              !selectedPost.media.length)) && (
            <>
              <div className="d-flex justify-content-center py-2">
                <Upload
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      // @ts-ignore
                      onSuccess("ok");
                    }, 0);
                  }}
                  onChange={handleOnChange}
                  multiple={false}
                  showUploadList={false}
                  beforeUpload={handleBeforeUpload}
                  accept={`${UploadFileType.MP4}, ${UploadFileType.MOV}`}
                >
                  <Button color="primary">
                    {intl.formatMessage({ id: "app.socialMediaPost.selectVideo" })}
                  </Button>
                </Upload>
              </div>
              <hr />
              <div className="d-flex justify-content-center">
                <LoadingSpinnerWembii
                  className="py-5"
                  active={isLoading(VIDEO_UPLOAD_LOADING)}
                >
                  {videoSrc != null && (
                    <VideoPreview
                      video={videoSrc}
                      type={videoType}
                      onLoadedMetadata={(video) => addMetadata(video)}
                    />
                  )}
                </LoadingSpinnerWembii>
              </div>
              {!video && (
                <div className="w-100 h-75 d-flex flex-column justify-content-center align-items-center text-muted">
                  <FontAwesomeIcon
                    icon={["fas", "film"]}
                    size="5x"
                    className="mb-1"
                    color="gray"
                  />
                  {intl.formatMessage({ id: "app.socialMediaPost.notSelectVideo" })}
                </div>
              )}
            </>
          )}
      </div>
    </div>
  );
};
