import React, { useCallback } from "react";
import {
  Comment,
  Email,
} from "../../../cores/contacts/interfaces/api/get-contact";
import CommentCard from "./CommentCard";
import CommentInput from "./CommentInput";
import _ from "lodash";
import EmailCard from "./EmailCard";
import { ModalTaskRow } from "../tasks/ModalTaskRow";
import { Task } from "../../../cores/tasks/interfaces/api/get-task";
import { FormResponsePayload } from "../../../cores/webForm/interfaces/api/get-webform-response.response";
import FormResponseCard from "./FormResponseCard";

interface Props {
  formResponses: FormResponsePayload[];
  comments: Comment[];
  emails: Email[];
  tasks: Task[];
  onSubmit: (arg0: string, arg1: number) => void;
}

export default function ActivityContainer(props: Props) {
  const { comments, emails, onSubmit, tasks, formResponses } = props;
  const activitiesFiltered = _.orderBy(
    [
      ...comments.map((comment) => ({ ...comment, object: "comment" })),
      ...emails.map((email) => ({ ...email, object: "email" })),
      ...tasks.map((task) => ({ ...task, object: "task" })),
      ...formResponses.map((form) => ({ ...form, object: "form" })),
    ],
    "createdAt",
    "desc"
  );

  const showActivity = useCallback((activity) => {
    switch (activity.object) {
      case "comment":
        return (
          <CommentCard
            key={`cmt_${activity.id}`}
            comment={activity as Comment}
          />
        );
      case "email":
        return (
          <EmailCard key={`eml_${activity.id}`} email={activity as Email} />
        );
      case "form":
        return (
          <FormResponseCard
            key={`form_${activity.id}`}
            formResponsePayload={activity}
          />
        );
      case "task":
        return (
          <ModalTaskRow key={`tsk_${activity.id}`} task={activity as Task} />
        );
    }
  }, []);

  return (
    <div className="py-2">
      <div className="w-100">
        <CommentInput onSubmit={onSubmit} />

        {activitiesFiltered.map((activity) => showActivity(activity))}
      </div>
    </div>
  );
}
