import React, { useCallback, useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { useGlobalCore } from "../../../../cores/globals";
import { useBoardCore } from "../../../../cores/boards";
import { CUSTOM_FIELD } from "../../../../constants/internalModal.constant";
import { InputWembii } from "../../InputWembii";
import { CardCustomField, CustomFieldOptions } from "../../../../cores/boards/constants/custom-field-type";
import { CustomField } from "../../../../cores/boards/interfaces/api/get-custom-fields";

interface Props {
  field?: CustomField;
  board: string;
  onCreation?: (arg0: string) => void;
}

export default function FieldForm({ field, board, onCreation }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const { turnOffInternalModal } = useGlobalCore();
  const { postCustomField, putCustomField, deleteCustomField } = useBoardCore();

  const [name, setName] = useState("");
  const [type, setType] = useState<string>(CardCustomField.Text);

  useEffect(() => {
    if (field) {
      setName(field.name);
      setType(field.type);
    }
  }, [field]);

  const cleanForm = useCallback(() => {
    setName("");
    setType(CardCustomField.Text);
    turnOffInternalModal(CUSTOM_FIELD);
  }, [turnOffInternalModal]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!field) {
      const id = await postCustomField(
        company?.id!,
        board,
        {
          name,
          type
        },
        cleanForm
      );

      if (id) onCreation?.(id);
    } else {
      putCustomField(
        company?.id!,
        board,
        field.id,
        { 
          name,
          type
        },
        cleanForm
      );
    }
  };

  const onDelete = useCallback(() => {
    deleteCustomField(
      company?.id!,
      board,
      field?.id!,
      cleanForm
    );
  }, [deleteCustomField, cleanForm, company, board, field]);

  const disabled = useCallback(
    () => !name,
    [name]
  );

  return (
    <Form onSubmit={onSubmit}>
      <InputWembii
        type="select"
        label={intl.formatMessage({ id: "app.customField.type" })}
        required
        value={type}
        onChange={setType}
      >
        {CustomFieldOptions.map(({ value, label }) => (
          <option key={value} value={value}>
            {intl.formatMessage({ id: label })}
          </option>
        ))}
        :
      </InputWembii>
      <InputWembii
        label={intl.formatMessage({ id: "app.customField.name" })}
        type="text"
        name="name"
        id="tag-name"
        className="pr-1"
        required
        placeholder={intl.formatMessage({ id: "app.customField.name" })}
        value={name}
        onChange={setName}
      />
      {field && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.customField.delete" })}  
        </Button>
      )}
      <Button color="primary" disabled={disabled()} className="ml-1">
        {field 
          ? intl.formatMessage({ id: "app.customField.update" })
          : intl.formatMessage({ id: "app.customField.create" })
        }  
      </Button>
    </Form>
  );
}