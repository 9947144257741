import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { AdAccount } from "../interfaces/api/get-ad-accounts";
import { Location } from "../interfaces/api/get-locations";
import { Interest } from "../interfaces/api/get-interests";
import { Language } from "../interfaces/api/get-languages";
import { Campaign } from "../interfaces/api/get-campaigns";
import { Adset } from "../interfaces/api/get-adsets";
import { Ad } from "../interfaces/api/get-ads";
import { MinBudget } from "../interfaces/api/get-min-budgets";
import { DeliveryEstimate } from "../interfaces/api/get-delivery-estimate";

export const getAdAccountsInitAction = createAction(
  "[Advertisements] Get AdAccount Init"
);
export const getAdAccountsSuccessAction = createAction(
  "[Advertisements] Get AdAccount Success",
  props<{ adAccounts: AdAccount[] }>()
);
export const getAdAccountsFailureAction = createAction(
  "[Advertisements] Get AdAccount Failure",
  props<{ error: string }>()
);

export const getLocationsInitAction = createAction(
  "[Advertisements] Get Locations Init"
);
export const getLocationsSuccessAction = createAction(
  "[Advertisements] Get Locations Success",
  props<{ locations: Location[] }>()
);
export const getLocationsFailureAction = createAction(
  "[Advertisements] Get Locations Failure",
  props<{ error: string }>()
);

export const getInterestsInitAction = createAction(
  "[Advertisements] Get Interests Init"
);
export const getInterestsSuccessAction = createAction(
  "[Advertisements] Get Interests Success",
  props<{ interests: Interest[] }>()
);
export const getInterestsFailureAction = createAction(
  "[Advertisements] Get Interests Failure",
  props<{ error: string }>()
);

export const getLanguagesInitAction = createAction(
  "[Advertisements] Get Languages Init"
);
export const getLanguagesSuccessAction = createAction(
  "[Advertisements] Get Languages Success",
  props<{ languages: Language[] }>()
);
export const getLanguagesFailureAction = createAction(
  "[Advertisements] Get Languages Failure",
  props<{ error: string }>()
);

export const getCampaignsInitAction = createAction(
  "[Advertisements] Get Campaigns Init"
);
export const getCampaignsSuccessAction = createAction(
  "[Advertisements] Get Campaigns Success",
  props<{ campaigns: Campaign[] }>()
);
export const getCampaignsFailureAction = createAction(
  "[Advertisements] Get Campaigns Failure",
  props<{ error: string }>()
);

export const getAdsetsInitAction = createAction(
  "[Advertisements] Get Adsets Init"
);
export const getAdsetsSuccessAction = createAction(
  "[Advertisements] Get Adsets Success",
  props<{ adsets: Adset[] }>()
);
export const getAdsetsFailureAction = createAction(
  "[Advertisements] Get Adsets Failure",
  props<{ error: string }>()
);

export const getAdsInitAction = createAction(
  "[Advertisements] Get Ads Init"
);
export const getAdsSuccessAction = createAction(
  "[Advertisements] Get Ads Success",
  props<{ ads: Ad[] }>()
);
export const getAdsFailureAction = createAction(
  "[Advertisements] Get Ads Failure",
  props<{ error: string }>()
);

export const getMinBudgetInitAction = createAction(
  "[Advertisements] Get Min Budget Init"
);
export const getMinBudgetSuccessAction = createAction(
  "[Advertisements] Get Min Budget Success",
  props<{ minBudget: MinBudget }>()
);
export const getMinBudgetFailureAction = createAction(
  "[Advertisements] Get Min Budget Failure",
  props<{ error: string }>()
);

export const getDeliveryEstimateInitAction = createAction(
  "[Advertisements] Get Delivery Estimate Init"
);
export const getDeliveryEstimateSuccessAction = createAction(
  "[Advertisements] Get Delivery Estimate Success",
  props<{ estimate: DeliveryEstimate }>()
);
export const getDeliveryEstimateFailureAction = createAction(
  "[Advertisements] Get Delivery Estimate Failure",
  props<{ error: string }>()
);

export const postCampaignInitAction = createAction(
  "[Advertisements] Post Campaign Init"
);
export const postCampaignSuccessAction = createAction(
  "[Advertisements] Post Campaign Success",
  props<{ id: string }>()
);
export const postCampaignFailureAction = createAction(
  "[Advertisements] Post Campaign Failure",
  props<{ error: string }>()
);

export const putCampaignInitAction = createAction(
  "[Advertisements] Put Campaign Init"
);
export const putCampaignSuccessAction = createAction(
  "[Advertisements] Put Campaign Success",
  props<{ campaign: string, status: string }>()
);
export const putCampaignFailureAction = createAction(
  "[Advertisements] Put Campaign Failure",
  props<{ error: string }>()
);

export const putAdsetInitAction = createAction(
  "[Advertisements] Put Adset Init"
);
export const putAdsetSuccessAction = createAction(
  "[Advertisements] Put Adset Success",
  props<{ adset: string, status: string, dailyBudget: number }>()
);
export const putAdsetFailureAction = createAction(
  "[Advertisements] Put Adset Failure",
  props<{ error: string }>()
);

export const putAdInitAction = createAction(
  "[Advertisements] Put Ad Init"
);
export const putAdSuccessAction = createAction(
  "[Advertisements] Put Ad Success",
  props<{ ad: string, status: string }>()
);
export const putAdFailureAction = createAction(
  "[Advertisements] Put Ad Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getAdAccountsInitAction,
  getAdAccountsSuccessAction,
  getAdAccountsFailureAction,
  getLocationsInitAction,
  getLocationsSuccessAction,
  getLocationsFailureAction,
  getInterestsInitAction,
  getInterestsSuccessAction,
  getInterestsFailureAction,
  getLanguagesInitAction,
  getLanguagesSuccessAction,
  getLanguagesFailureAction,
  getCampaignsInitAction,
  getCampaignsSuccessAction,
  getCampaignsFailureAction,
  getAdsetsInitAction,
  getAdsetsSuccessAction,
  getAdsetsFailureAction,
  getAdsInitAction,
  getAdsSuccessAction,
  getAdsFailureAction,
  getMinBudgetInitAction,
  getMinBudgetSuccessAction,
  getMinBudgetFailureAction,
  getDeliveryEstimateInitAction,
  getDeliveryEstimateFailureAction,
  getDeliveryEstimateSuccessAction,
  postCampaignInitAction,
  postCampaignSuccessAction,
  postCampaignFailureAction,
  putCampaignInitAction,
  putCampaignSuccessAction,
  putCampaignFailureAction,
  putAdsetInitAction,
  putAdsetSuccessAction,
  putAdsetFailureAction,
  putAdInitAction,
  putAdSuccessAction,
  putAdFailureAction,
});

export type AdvertisementsApiAction = typeof actions;
