import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { Coupon } from "../interfaces/api/get-coupon";
import { PaymentAction, resetCouponAction } from "./actions";
import {
  deleteSubscriptionFailureAction,
  deleteSubscriptionInitAction,
  deleteSubscriptionSuccessAction,
  PaymentApiAction,
  getFlowCouponFailureAction,
  getFlowCouponInitAction,
  getFlowCouponSuccessAction,
  postFlowSubscriptionFailureAction,
  postFlowSubscriptionInitAction,
  postFlowSubscriptionSuccessAction,
  getStripeCouponFailureAction,
  getStripeCouponInitAction,
  getStripeCouponSuccessAction,
  postStripeSubscriptionFailureAction,
  postStripeSubscriptionSuccessAction,
  postStripeSubscriptionInitAction,
} from "./api-actions";

export interface PaymentState {
  coupon: Coupon | null;
}

const initialState: PaymentState = {
  coupon: null,
};

export default produce(
  (
    draft: PaymentState = initialState,
    action: PaymentApiAction | PaymentAction | GlobalActions
  ) => {
    switch (action.type) {
      case getFlowCouponSuccessAction.type:
      case getStripeCouponSuccessAction.type:
        draft.coupon = action.coupon;
        return draft;
      case resetCouponAction.type:
        draft.coupon = null;
        return draft;
      case resetAction.type:
        return initialState;
      case getFlowCouponInitAction.type:
      case getFlowCouponFailureAction.type:
      case getStripeCouponInitAction.type:
      case getStripeCouponFailureAction.type:
      case postFlowSubscriptionInitAction.type:
      case postFlowSubscriptionSuccessAction.type:
      case postFlowSubscriptionFailureAction.type:
      case postStripeSubscriptionInitAction.type:
      case postStripeSubscriptionSuccessAction.type:
      case postStripeSubscriptionFailureAction.type:
      case deleteSubscriptionInitAction.type:
      case deleteSubscriptionSuccessAction.type:
      case deleteSubscriptionFailureAction.type:
      default:
        return draft;
    }
  }
);
