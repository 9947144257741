import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Task } from "../interfaces/api/get-task";

export const getTasksInitAction = createAction("[Task] Get Tasks Init");
export const getTasksSuccessAction = createAction(
  "[Task] Get Tasks Success",
  props<{ tasks: Task[]; total: number }>()
);
export const getTasksExportSuccessAction = createAction(
  "[Task] Get Tasks Export Success",
  props<{ tasks: Task[] }>()
);
export const getContactTasksSuccessAction = createAction(
  "[Task] Get Contact Tasks Success",
  props<{ tasks: Task[]; total: number }>()
);
export const getTasksFailureAction = createAction(
  "[Task] Get Tasks Failure",
  props<{ error: string }>()
);

export const getTaskInitAction = createAction("[Task] Get Task Init");
export const getTaskSuccessAction = createAction(
  "[Task] Get Task Success",
  props<{ task: Task }>()
);
export const getTaskFailureAction = createAction(
  "[Task] Get Task Failure",
  props<{ error: string }>()
);

export const postTaskInitAction = createAction("[Task] Post Task Init");
export const postTaskSuccessAction = createAction(
  "[Task] Post Task Success",
  props<{ task: Task; taskType: "contactTask" | "task" }>()
);
export const postContactTaskSuccessAction = createAction(
  "[Task] Post Contact Task Success",
  props<{ task: Task }>()
);
export const postTaskFailureAction = createAction(
  "[Task] Post Task Failure",
  props<{ error: string }>()
);

export const putTaskInitAction = createAction("[Task] Put Task Init");
export const putTaskSuccessAction = createAction(
  "[Task] Put Task Success",
  props<{ task: Task }>()
);
export const putTaskFailureAction = createAction(
  "[Task] Put Task Failure",
  props<{ error: string }>()
);

export const deleteTaskInitAction = createAction("[Task] Delete Task Init");
export const deleteTaskSuccessAction = createAction(
  "[Task] Delete Task Success",
  props<{ id: string }>()
);
export const deleteContactTaskSuccessAction = createAction(
  "[Task] Delete Contact Task Success",
  props<{ id: string }>()
);
export const deleteTaskFailureAction = createAction(
  "[Task] Delete Task Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getTasksExportSuccessAction,
  getTasksInitAction,
  getTasksSuccessAction,
  getTasksFailureAction,
  getTaskInitAction,
  getTaskSuccessAction,
  getTaskFailureAction,
  postTaskInitAction,
  postTaskSuccessAction,
  postTaskFailureAction,
  deleteContactTaskSuccessAction,
  putTaskInitAction,
  putTaskSuccessAction,
  putTaskFailureAction,
  deleteTaskInitAction,
  deleteTaskSuccessAction,
  deleteTaskFailureAction,
  getContactTasksSuccessAction,
  postContactTaskSuccessAction,
});

export type TaskApiAction = typeof actions;
