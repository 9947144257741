import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import Switch from "react-switch";
import { useWebFormCore } from "../../../../../cores/webForm";
import { useIntl } from "react-intl";
import { PositionType } from "../../../../../cores/webForm/constants/PositionType.constant";
import { CheckboxWembii } from "../../../CheckboxWembii";
import { InputWembii } from "../../../InputWembii";
import { LabelWembii } from "../../../LabelWembii";
import { PanelWembii } from "../../../PanelWembii";
import { TitleWembii } from "../../../TitleWembii";
import { isUrl } from "../../../../utils/stringUtils";
import { RadiobuttonWembii } from "../../../RadiobuttonWembii";
import { Action } from "../../../../../cores/webForm/constants/Action.constant";
import { COLOR_PRIMARY } from "../../../../../constants/theme";

interface Props {
  onSubmit: () => void;
}

export default function GeneralForm({ onSubmit }: Props) {
  const intl = useIntl();
  const { webForm, setForm } = useWebFormCore();
  const [hasChange, setHasChange] = useState(false);
  const [hideTitle, setHideTitle] = useState(false);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    onSubmit();
    setHasChange(false);
  };

  const isUrlInvalid = useMemo(() =>
    webForm.form?.redirectUrl !== '' &&
    webForm.form?.redirectUrl !== null &&
    !isUrl(webForm.form?.redirectUrl!)
    , [webForm]
  );

  const handleHideTitle = (hideTitle: boolean) => {
    setHideTitle(hideTitle);
    if (hideTitle) {
      handleConfigChange({ title: "" });
    }
  };

  const disabled = useCallback(
    () =>
      !webForm.form?.buttonText ||
      !webForm.form?.buttonTextColor ||
      !webForm.form?.buttonTextSize ||
      !webForm.form?.buttonColor ||
      !webForm.form?.buttonSize ||
      !webForm.form?.buttonPosition ||
      (webForm.form?.action === Action.MESSAGE && !webForm.form?.successfulMessage) ||
      (webForm.form?.action === Action.REDIRECT && (!webForm.form?.redirectUrl || isUrlInvalid)),
    [webForm.form, isUrlInvalid]
  );

  const handleConfigChange = useCallback((field) => {
    setForm({ ...webForm.form, ...field });
    setHasChange(true);
  }, [webForm, setForm]);

  return (
    <Container className="p-0">
      <PanelWembii shadow className="mb-4 p-4">
        <TitleWembii 
          Tag="h5" 
          color="primary"
          toolbar={
            <FormGroup className="d-flex align-items-center">
              <span className="text-secondary font-weight-bold">
                {webForm.form?.isActive === true
                  ? intl.formatMessage({ id: "app.configWspp.active" })
                  : intl.formatMessage({ id: "app.configWspp.disabled" })
                }
              </span>
              <Switch
                id="isActive"
                name="isActive"
                className="ml-1"
                checked={webForm.form?.isActive!}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={COLOR_PRIMARY}
                height={20}
                width={30}
                onChange={(v) => handleConfigChange({isActive: v})}
              />
            </FormGroup>
          }>   
          {intl.formatMessage({ id: "app.editForm.tittle" })}
        </TitleWembii>
        <Row className="h-100">
          <Col xs="12">
            <Form className="px-2" onSubmit={onFormSubmit}>
              <Row>
                <Col xs="4">
                  <InputWembii
                    type="text"
                    name="title"
                    id="formTitle"
                    label={intl.formatMessage({ id: "app.editForm.tittle2" })}
                    placeholder={intl.formatMessage({ id: "app.editForm.tittle2" })}
                    value={webForm.form?.title}
                    disabled={hideTitle}
                    flat
                    onChange={(v) => handleConfigChange({ title: v })}
                  />
                </Col>
                <Col xs="3">
                  <InputWembii
                    type="number"
                    name="titleSize"
                    id="titleSize"
                    label={intl.formatMessage({ id: "app.editForm.size" })}
                    placeholder={intl.formatMessage({ id: "app.editForm.size" })}
                    value={webForm.form?.titleSize}
                    disabled={hideTitle}
                    flat
                    onChange={(v) => handleConfigChange({ titleSize: parseInt(v) })}
                  />
                </Col>
                <Col xs="3">
                  <InputWembii
                    type="color"
                    name="titleColor"
                    id="formTitleColor"
                    label={intl.formatMessage({ id: "app.editForm.colorTitle" })} 
                    placeholder={intl.formatMessage({ id: "app.editForm.colorTitle" })} 
                    value={webForm.form?.titleColor}
                    flat
                    required
                    onChange={(v) => handleConfigChange({ titleColor: v })}
                  />
                </Col>
                <Col xs="2" className="d-flex align-items-center">
                  <CheckboxWembii 
                    label={intl.formatMessage({ id: "app.editForm.hidden" })}
                    checked={hideTitle}
                    mb={false}
                    onChange={handleHideTitle}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputWembii
                    type="text"
                    name="buttonText"
                    id="formButtonText"
                    label={intl.formatMessage({ id: "app.editForm.send" })}
                    placeholder={intl.formatMessage({ id: "app.editForm.send" })} 
                    value={webForm.form?.buttonText}
                    flat
                    required
                    onChange={(v) => handleConfigChange({ buttonText: v })}
                  />
                </Col>
                <Col xs="4">
                  <InputWembii
                    type="color"
                    name="buttonTextColor"
                    id="formButtonTextColor"
                    label={intl.formatMessage({ id: "app.editForm.colorTxt1" })} 
                    placeholder={intl.formatMessage({ id: "app.editForm.colorTxt2" })} 
                    value={webForm.form?.buttonTextColor}
                    flat
                    required
                    onChange={(v) => handleConfigChange({ buttonTextColor: v })}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  <InputWembii
                    type="number"
                    name="buttonTextSize"
                    id="buttonTextSize"
                    label={intl.formatMessage({ id: "app.editForm.sizeTxt1" })}
                    placeholder={intl.formatMessage({ id: "app.editForm.sizeTxt2" })}
                    value={webForm.form?.buttonTextSize}
                    flat
                    required
                    onChange={(v) => handleConfigChange({ buttonTextSize: parseInt(v) })}
                  />
                </Col>
                <Col  xs="4">
                  <InputWembii
                    type="color"
                    name="buttonColor"
                    id="buttonColor"
                    label={intl.formatMessage({ id: "app.editForm.colorButton1" })}
                    placeholder={intl.formatMessage({ id: "app.editForm.colorButton2" })}
                    value={webForm.form?.buttonColor}
                    flat
                    required
                    onChange={(v) => handleConfigChange({ buttonColor: v })}
                  />
                </Col>
                <Col xs="4">
                  <InputWembii
                    type="number"
                    name="buttonSize"
                    id="buttonSize"
                    label={intl.formatMessage({ id: "app.editForm.colorButtonSend1" })}
                    placeholder={intl.formatMessage({ id: "app.editForm.colorButtonSend2" })}
                    value={webForm.form?.buttonSize}
                    flat
                    required
                    onChange={(v) => handleConfigChange({ buttonSize: parseInt(v) })}
                  />
                </Col>
              </Row>
              <div className="d-flex flex-column align-items-center mb-2">
                <LabelWembii>
                  {intl.formatMessage({ id: "app.editForm.buttonPosition" })}
                </LabelWembii>
                <ButtonGroup>
                  {Object.values(PositionType).map((position) => (
                    <Button
                      key={position.id}
                      className="m-1"
                      color={
                        webForm?.form?.buttonPosition === position.id ? "primary" : "secondary"
                      }
                      onClick={() => handleConfigChange({ buttonPosition: position.id })}
                    >
                      {position.name}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <LabelWembii>
                {intl.formatMessage({ id: "app.editForm.successAction" })}
              </LabelWembii>
              <div className="d-flex align-items-center justify-content-between">
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.editForm.succesButton1" })}
                  checked={webForm.form?.action === Action.MESSAGE}
                  onChange={() => handleConfigChange({ action: Action.MESSAGE })}
                />
                <InputWembii
                  type="text"
                  name="successfulMessage"
                  className="w-75"
                  placeholder={intl.formatMessage({ id: "app.editForm.succesButton1" })}
                  value={webForm.form?.successfulMessage}
                  flat
                  required
                  disabled={webForm.form?.action === Action.REDIRECT}
                  onChange={(v) => handleConfigChange({ successfulMessage: v })}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.editForm.redirectUrl" })}
                  checked={webForm.form?.action === Action.REDIRECT}
                  onChange={() => handleConfigChange({ action: Action.REDIRECT })}
                />
                <InputWembii
                  type="text"
                  name="redirectUrl"
                  className="w-75"
                  placeholder={intl.formatMessage({ id: "app.editForm.redirectUrl" })}
                  value={webForm.form?.redirectUrl}
                  flat
                  invalid={isUrlInvalid}
                  disabled={webForm.form?.action === Action.MESSAGE}
                  onChange={(v) => handleConfigChange({ redirectUrl: v })}
                />
              </div>

              <div className="d-flex justify-content-center mt-2">
                <Button color="primary" disabled={disabled() || !hasChange}>
                  {intl.formatMessage({ id: "app.editForm.saveConfig" })}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </PanelWembii>
    </Container>
  );
}
