import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { Col, Row } from "reactstrap";
import { CONTACT_EXPAND_EDIT_FORM, SEND_EMAIL_FORM } from "../../../constants/modal.constant";
import { useContactCore } from "../../../cores/contacts";
import { ContactSource } from "../../../cores/contacts/enum/ContactSource.enum";
import { Contact } from "../../../cores/contacts/interfaces/api/get-contacts";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import Avatar from "../Avatar";
import { CheckboxWembii } from "../CheckboxWembii";
import { PanelWembii } from "../PanelWembii";
import { ContactSourceBadge } from "./ContactSource";
import EditContactForm from "./modal/EditContactForm";
import { TooltipWembii } from "../TooltipWembii";
import { ContactInformation } from "../../../cores/contacts/interfaces/DefaultInformation";
import { useIntl } from "react-intl";
import { ContactStateLabels } from "../../../cores/contacts/interfaces/ContactState";
import { useBoardCore } from "../../../cores/boards";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import SendEmailForm from "../gmail/modals/SendEmailForm";
import { useTemplateCore } from "../../../cores/templates";
import { useEmailCore } from "../../../cores/email";
import { getFirstLetters } from "../../utils/stringUtils";
import { useConfigCore } from "../../../cores/config";
import { addSign } from "../../utils/signUtils";

interface Props {
  contact: Contact;
}

export const ContactRow = ({ contact }: Props) => {
  const intl = useIntl();
  const {
    id, name, state, phoneNumber, whatsapp, email, source, owners, fields, createdAt, updatedAt,
  } = contact;
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { getBusinessBoards } = useBoardCore();
  const { setMessage } = useEmailCore();
  const { getCommentTypes } = useConfigCore();
  const { getEmailTemplates } = useTemplateCore();
  const {
    getContacts,
    deleteContacts,
    getContactTags,
    selectContact,
    deselectContact,
    getContact,
    getCustomFields,
    selectedContacts,
    states,
    customFields,
    contactFilters,
    config,
  } = useContactCore();
  const {
    company,
    isAdmin,
    isReseller,
    companyRole,
    user,
    userSettings,
    socialSettings,
    setSign,
    getMeSigns
  } = useMeCore();

  const onClick = useCallback(() => {
    getContactTags(company?.id!);
    getCommentTypes(company?.id!);
    getCustomFields(company?.id!);
    getContact(company?.id!, id);
    getBusinessBoards(company?.id!);
    turnOnModal(
      CONTACT_EXPAND_EDIT_FORM,
      <EditContactForm
        onDelete={async () => {
          deleteContacts(company?.id!, [id], () => {
            getContacts(company?.id!, contactFilters);
            turnOffModal(CONTACT_EXPAND_EDIT_FORM);
          });
        }}
        onUpdate={() => {
          getContacts(company?.id!, contactFilters);
        }}
      />,
      Position.LEFT
    );
  }, [
    id,
    company,
    contactFilters,
    turnOnModal,
    turnOffModal,
    getContacts,
    deleteContacts,
    getContact,
    getContactTags,
    getCommentTypes,
    getCustomFields,
    getBusinessBoards
  ]);

  const isSelected = useMemo(() => 
    selectedContacts.find((selected) => selected.id === id) !== undefined
  , [selectedContacts, id]);

  const toggleSelection = useCallback(() => {
    if (isSelected)
      deselectContact(id);
    else
      selectContact(contact);
  }, [deselectContact, selectContact, id, isSelected, contact]);

  const moreOwners = useMemo(() => {
    const owns = [...owners];
    return owns.splice(1);
  }, [owners]);

  const canModify = useMemo(() => 
    isAdmin ||
    isReseller ||
    companyRole?.owner ||
    companyRole?.admin ||
    owners.findIndex((owner) => owner.id === user?.id) !== -1 ||
    owners.length === 0
  , [isAdmin, isReseller, companyRole, owners, user]);

  const defaultFieldConfig = useMemo(() =>
    config.filter((config) => !config.customField)
      .reduce((prev: Record<string, boolean>, curr) => {
        prev[curr.field] = true;
        return prev;
      }, {})
  , [config]);

  const customFieldConfig = useMemo(() =>
    config.filter((config) => config.customField)
      .reduce((prev: Record<string, boolean>, curr) => {
        prev[curr.field] = true;
        return prev;
      }, {})
  , [config]);

  const getCustomFieldName = useCallback((id: string) => {
    const field = customFields.find((field) => field.id === id);
    return field?.name!;
  }, [customFields]);

  const col3 = useMemo(() => {
    if (!defaultFieldConfig[ContactInformation.SOURCE] && !defaultFieldConfig[ContactInformation.OWNERS]) {
      return 5;
    } else {
      return 4;
    }
  }, [defaultFieldConfig]);

  const col4 = useMemo(() => {
    if (!defaultFieldConfig[ContactInformation.SOURCE]) {
      return 6;
    } else {
      return 4;
    }
  }, [defaultFieldConfig]);

  const col5 = useMemo(() => {
    if (!defaultFieldConfig[ContactInformation.OWNERS]) {
      return 3;
    } else {
      return 2;
    }
  }, [defaultFieldConfig]);

  const openWhatsapp = useCallback((e: any) => {
    e.stopPropagation();
    if (whatsapp)
      window.open(`https://wa.me/${whatsapp}`, "_blank");
  }, [whatsapp]);

  const openPhone = useCallback((e: any) => {
    e.stopPropagation();
    if (phoneNumber)
      window.open(`tel://${phoneNumber}`);
  }, [phoneNumber]);

  const gmailEnable = useMemo(() => 
    socialSettings?.gmail ||
    userSettings?.gmail ||
    socialSettings?.smtp ||
    userSettings?.smtp
  , [userSettings, socialSettings]);

  const stateValue = useMemo(() => {
    if (state < 100) {
      return intl.formatMessage({ id: ContactStateLabels[state] });
    } else {
      return states.find((st) => +st.id === state)?.name;
    }
  }, [state, intl, states]);

  const openEmail = useCallback(async (e: any) => {
    e.stopPropagation();
    if (email && gmailEnable) {
      const meSigns = await getMeSigns();
      const defaultSign = meSigns ? meSigns.find((sign) => sign.default) : null;
      if (defaultSign) {
        setSign(defaultSign);
        setMessage(addSign('<p><br></p>', defaultSign));
      } else {
        setSign(null);
        setMessage("");
      }
      getEmailTemplates(company?.id!);
      turnOnModal(
        SEND_EMAIL_FORM,
        <SendEmailForm contacts={[contact]} cardId={null} />
      );
    }
  }, [
    email,
    gmailEnable,
    company,
    contact,
    turnOnModal,
    getMeSigns,
    setSign,
    getEmailTemplates,
    setMessage
  ]);

  return (
    <PanelWembii className="w-100 px-4 py-3 my-2 pointer" onClick={onClick}>
      <Row>
        <Col xs="12" lg="1">
          <div className="h-100 d-flex align-items-center justify-content-center justify-content-lg-start">
            <CheckboxWembii
              mb={false}
              disabled={!canModify}
              checked={isSelected}
              onChange={toggleSelection}
            />
          </div>
        </Col>
        {defaultFieldConfig[ContactInformation.OWNERS] && (
          <Col xs="12" lg="1">
            <div className="user-cascade-container d-flex align-items-center justify-content-center justify-content-lg-start flex-wrap position-relative">
              {owners.map((owner, index) => (
                <div
                  className={cn("user-avatar", {
                    "avatar-lg-hidden": index > 0
                  })}
                  style={{
                    "zIndex": index,
                    "left": index * 25,
                    "top": "50%"
                  }}
                >
                  <Avatar
                    key={index}
                    id={`owner-${id}-${owner.id}`}
                    className="text-dark-50 pointer"
                    image={owner.avatarUrl}
                    label={getFirstLetters(`${owner.lastName} ${owner.firstName}`)}
                    icon={
                      <FontAwesomeIcon
                        icon={["fas", "user"]}
                        size="1x"
                      />
                    }
                  />
                  <TooltipWembii 
                    id={`owner-${id}-${owner.id}`}
                    text={`${owner.lastName} ${owner.firstName}`} 
                  />
                </div>
              ))}
              {moreOwners.length > 0 && (
                <div
                  className={cn("user-avatar avatar-md-hidden")}
                  style={{
                    "zIndex": 1,
                    "left": 1 * 25,
                    "top": "50%"
                  }}
                >
                  <Avatar
                    id={`owner-${id}-rest`}
                    className="text-dark-50 pointer"
                    icon={
                      moreOwners.length > 1 ? (
                        <FontAwesomeIcon
                          icon={["fas", "users"]}
                          size="1x"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={["fas", "user"]}
                          size="1x"
                        />
                      )
                    }
                  />
                  <TooltipWembii 
                    id={`owner-${id}-rest`}
                    text={moreOwners
                      .map((owner) => `${owner.lastName} ${owner.firstName}`)
                      .join(", ")
                    } 
                  />
                </div>
              )}
            </div>
          </Col>
        )}
        <Col xs="12" lg={col3} className="d-flex flex-column justify-content-center align-items-start">
          <div className="d-flex align-items-center justify-content-center justify-content-lg-start w-100">
            <span className="h6 mb-0 font-weight-bold mr-2" id={`name${id}`}>{name}</span>
            {defaultFieldConfig[ContactInformation.STATE] && (
              <>
                <span className="text-lowercase text-muted" id={`state${id}`}>
                  ({ stateValue })
                </span>
                <TooltipWembii 
                  id={`state${id}`}
                  text={intl.formatMessage({ id: "app.contacts.state" })}
                />
              </>
            )}
          </div>
          <TooltipWembii 
            id={`name${id}`}
            text={intl.formatMessage({ id: "app.contacts.name" })}
          />
          <div
            className="d-flex align-items-center justify-content-center justify-content-lg-start w-100"
            id={`email${id}`}
            onClick={(e) => openEmail(e)}
          >
            <FontAwesomeIcon icon={["far", "envelope"]} className="mr-2" />
            <span className="mb-0 text-black-50 small">
              {email ? email : "-----"}
            </span>
          </div>
          <TooltipWembii 
            id={`email${id}`}
            text={intl.formatMessage({ id: "app.contacts.email" })}
          />
        </Col>
        <Col xs="12" lg={col4}>
          <Row>
            {defaultFieldConfig[ContactInformation.CREATED_AT] && (
              <Col xs="12" lg="6">
                <div
                  className="d-flex align-items-center justify-content-center justify-content-lg-start text-muted"
                  id={`createdDate${id}`}
                >
                  <FontAwesomeIcon icon={["fas", "plus-square"]} size="xs" className="mr-2" />
                  {createdAt}
                </div>
                <TooltipWembii 
                  id={`createdDate${id}`}
                  text={intl.formatMessage({ id: "app.contacts.createdDate" })}
                />
              </Col>
            )}
            {defaultFieldConfig[ContactInformation.UPDATED_AT] && (
              <Col xs="12" lg="6">
                <div
                  className="d-flex align-items-center justify-content-center justify-content-lg-start text-muted"
                  id={`updatedDate${id}`}
                >
                  <FontAwesomeIcon icon={["far", "edit"]} size="xs" className="mr-2" />
                  {updatedAt}
                </div>
                <TooltipWembii 
                  id={`updatedDate${id}`}
                  text={intl.formatMessage({ id: "app.contacts.updatedDate" })}
                />
              </Col>
            )}
            <Col xs="12" lg="6">
              <div
                className="d-flex align-items-center justify-content-center justify-content-lg-start"
                id={`phone${id}`}
                onClick={(e) => openPhone(e)}
              >
                <FontAwesomeIcon icon={["fas", "phone"]} className="mr-2" />
                <span className="mb-0 text-black-50 small">
                  {phoneNumber ? phoneNumber : "-----"}
                </span>
              </div>
              <TooltipWembii 
                id={`phone${id}`}
                text={intl.formatMessage({ id: "app.contacts.phone" })}
              />
            </Col>
            {defaultFieldConfig[ContactInformation.WHATSAPP] && (
              <Col xs="12" lg="6">
                <div
                  className="d-flex align-items-center justify-content-center justify-content-lg-start"
                  id={`whatsapp${id}`}
                  onClick={(e) => openWhatsapp(e)}
                >
                  <FontAwesomeIcon icon={["fab", "whatsapp"]} className="mr-2" />
                  <span className="mb-0 text-black-50 small">
                    {whatsapp ? whatsapp : "-----"}
                  </span>
                </div>
                <TooltipWembii 
                  id={`whatsapp${id}`}
                  text={intl.formatMessage({ id: "app.contacts.whatsapp" })}
                />
              </Col>
            )}
            {fields?.map((field) => (
              customFieldConfig[field.customFieldId] && (
                <Col xs="12" lg="6">
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start" id={`field${id}${field.customFieldId}`}>
                    {field.value}
                  </div>
                  <TooltipWembii 
                    id={`field${id}${field.customFieldId}`}
                    text={getCustomFieldName(field.customFieldId)}
                  />
                </Col>
              )
            ))}
          </Row>
        </Col>
        {defaultFieldConfig[ContactInformation.SOURCE] && (
          <Col xs="12" lg={col5}>
            <div className="h-100 d-flex align-items-center justify-content-center justify-content-lg-end">
              <ContactSourceBadge source={source as ContactSource} />
            </div>
          </Col>
        )}
      </Row>
    </PanelWembii>
  );
};
