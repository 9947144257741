import { useCallback } from "react";
import { delay } from "../utils";
import { IOnDesignPublish } from "./interfaces/canva";

let Canva: any = null;

export function useCanvaCore() {
  const initCanva = useCallback(() => {
    setTimeout(async () => {
      // @ts-ignore
      if (window.Canva && window.Canva.DesignButton) {
        // @ts-ignore
        Canva = await window.Canva.DesignButton.initialize({
          apiKey: process.env.REACT_APP_CANVA_API_KEY,
        });
      }
    }, 0);
  }, []);

  const getCanva = useCallback(async (tries = 0): Promise<any> => {
    if (!Canva && tries <= 10) {
      await delay(100);
      return await getCanva(tries + 1);
    }

    return Canva;
  }, []);

  const openCanva = useCallback(
    async (onDesignPublish: ({ exportUrl }: IOnDesignPublish) => void) => {
      (await getCanva()).createDesign({
        design: {
          type: "InstagramPost",
        },
        onDesignPublish,
      });
    },
    [getCanva]
  );

  return {
    initCanva,
    getCanva,
    openCanva,
  };
}
