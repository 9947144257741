import { produce } from "immer";
import moment from "moment";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { User } from "../../session/interfaces/models/user";
import { SessionAction, sessionClearAction } from "../../session/store/actions";
import { MeCompanyPayload } from "../interfaces/api/get-meCompany";
import { Sign } from "../interfaces/api/get-meSign";
import { SocialSettings } from "../interfaces/api/get-meSocialSettings";
import { UserSettings } from "../interfaces/api/get-meUserSettings";
import {
  deselectCompanyAction,
  MeAction,
  removeCompanyAction,
  selectCompanyAction,
  setSignAction,
  updateReportsCompanyAction,
} from "./actions";
import {
  deleteMeSignFailureAction,
  deleteMeSignInitAction,
  deleteMeSignSuccessAction,
  getMeAreasFailureAction,
  getMeAreasInitAction,
  getMeAreasSuccessAction,
  getMeCompaniesFailureAction,
  getMeCompaniesInitAction,
  getMeCompaniesSuccessAction,
  getMeCompanyFailureAction,
  getMeCompanyInitAction,
  getMeCompanySuccessAction,
  getMeSignsFailureAction,
  getMeSignsInitAction,
  getMeSignsSuccessAction,
  getMeSocialFailureAction,
  getMeSocialInitAction,
  getMeSocialSuccessAction,
  getMeUserFailureAction,
  getMeUserInitAction,
  getMeUserSocialFailureAction,
  getMeUserSocialInitAction,
  getMeUserSocialSuccessAction,
  getMeUserSuccessAction,
  MeApiAction,
  postMeLanguageFailureAction,
  postMeLanguageInitAction,
  postMeLanguageSuccessAction,
  postMeSignFailureAction,
  postMeSignInitAction,
  postMeSignSuccessAction,
  putMeAvatarFailureAction,
  putMeAvatarInitAction,
  putMeAvatarSuccessAction,
  putMePasswordFailureAction,
  putMePasswordInitAction,
  putMePasswordSuccessAction,
  putMeSignFailureAction,
  putMeSignInitAction,
  putMeSignSuccessAction,
} from "./api-actions";

export interface MeState {
  user: User | null;
  selectedCompanyId: string | null;
  selectedCompany: MeCompanyPayload | null;
  socialSettings: SocialSettings | null;
  userSettings: UserSettings | null;
  companies: {
    ids: number[];
    entities: Record<string, MeCompanyPayload>;
  };
  signs: {
    ids: string[];
    entities: Record<string, Sign>;
  };
  sign: Sign | null;
  areas: any[];
}

const initialState: MeState = {
  user: null,
  selectedCompanyId: null,
  selectedCompany: null,
  companies: {
    ids: [],
    entities: {},
  },
  signs: {
    ids: [],
    entities: {},
  },
  sign: null,
  areas: [],
  //TODO change to entity / ids structure
  socialSettings: null,
  userSettings: null,
};

const companiesAdapter = createEntityAdapter<MeCompanyPayload>(
  (payload) => payload.company.id
);
const signsAdapter = createEntityAdapter<Sign>((payload) => payload.id);

export default produce(
  (
    draft: MeState = initialState,
    action: MeApiAction | MeAction | SessionAction
  ) => {
    switch (action.type) {
      case getMeUserSuccessAction.type:
        draft.user = action.user;
        return draft;
      case getMeCompaniesSuccessAction.type:
        companiesAdapter.addMany(action.companies, draft.companies);
        return draft;
      case getMeCompanySuccessAction.type:
        draft.selectedCompany = action.company;
        return draft;
      case selectCompanyAction.type:
        draft.selectedCompanyId = action.companyId;
        return draft;
      case deselectCompanyAction.type:
        draft.selectedCompany = null;
        draft.selectedCompanyId = null;
        return draft;
      case getMeSocialSuccessAction.type:
        draft.socialSettings = action.social;
        return draft;
      case getMeUserSocialSuccessAction.type:
        draft.userSettings = action.social;
        return draft;
      case getMeAreasSuccessAction.type:
        draft.areas = action.areas;
        return draft;
      case updateReportsCompanyAction.type:
        draft.selectedCompany!.company.reports = action.amount;
        draft.selectedCompany!.company.lastReportDate = moment().toString();
        return draft;
      case removeCompanyAction.type:
        companiesAdapter.removeOne(action.companyId, draft.companies);
        return draft;
      case putMeAvatarSuccessAction.type:
        draft.user = action.user;
        return draft;
      case postMeLanguageSuccessAction.type:
        draft.user = action.user;
        return draft;
      case getMeSignsSuccessAction.type:
        signsAdapter.addMany(action.signs, draft.signs);
        return draft;
      case postMeSignSuccessAction.type:
        signsAdapter.setOne(action.sign, draft.signs);
        return draft;
      case putMeSignSuccessAction.type:
        if (action.sign.id === draft.sign?.id) {
          draft.sign = action.sign;
        }
        signsAdapter.updateOne(action.sign, draft.signs);
        return draft;
      case deleteMeSignSuccessAction.type:
        signsAdapter.removeOne(+action.id, draft.signs);
        return draft;
      case setSignAction.type:
        draft.sign = action.sign;
        return draft;
      case sessionClearAction.type:
        return initialState;
      case getMeSignsInitAction.type:
      case getMeSignsFailureAction.type:
      case postMeSignInitAction.type:
      case postMeSignFailureAction.type:
      case putMeSignInitAction.type:
      case putMeSignFailureAction.type:
      case deleteMeSignInitAction.type:
      case deleteMeSignFailureAction.type:
      case getMeCompaniesInitAction.type:
      case getMeCompaniesFailureAction.type:
      case getMeSocialInitAction.type:
      case getMeCompanyInitAction.type:
      case getMeCompanyFailureAction.type:
      case getMeUserInitAction.type:
      case getMeUserFailureAction.type:
      case getMeSocialFailureAction.type:
      case getMeAreasInitAction.type:
      case getMeAreasFailureAction.type:
      case putMeAvatarInitAction.type:
      case putMeAvatarFailureAction.type:
      case postMeLanguageInitAction.type:
      case postMeLanguageFailureAction.type:
      case putMePasswordInitAction.type:
      case putMePasswordSuccessAction.type:
      case putMePasswordFailureAction.type:
      case getMeUserSocialInitAction.type:
      case getMeUserSocialFailureAction.type:
      default:
        return draft;
    }
  }
);
