import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const twitterRoot = (state: State) => state.twitter;

export const settingSelector = createSelector(
  twitterRoot,
  (twitter) => twitter.settings
);

export const loginUrlSelector = createSelector(
  twitterRoot,
  (twitter) => twitter.url
);

export const profilesSelector = createSelector(
  twitterRoot,
  (twitter) => twitter.profiles
);
