import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { WhatsappChat, ChatUser } from "../interfaces/api/get-whatsapp-chat";

export const getChatInitAction = createAction("[WhatsappWeb] Get Chat Init");
export const getChatSuccessAction = createAction(
  "[WhatsappWeb] Get Chat Success",
  props<{ chat: WhatsappChat; whatsappUsers: ChatUser[] }>()
);
export const getChatFailureAction = createAction(
  "[WhatsappWeb] Get Chat Failure",
  props<{ error: string }>()
);

export const putChatInitAction = createAction("[WhatsappWeb] Put Chat Init");
export const putChatSuccessAction = createAction(
  "[WhatsappWeb] Put Chat Success",
  props<{ chat: WhatsappChat }>()
);
export const putChatFailureAction = createAction(
  "[WhatsappWeb] Put Chat Failure",
  props<{ error: string }>()
);

export const postWhatsappUserInitAction = createAction(
  "[WhatsappWeb] Post Whatsapp User Init"
);
export const postWhatsappUserSuccessAction = createAction(
  "[WhatsappWeb] Post Whatsapp User Success",
  props<{ whatsappUser: ChatUser }>()
);
export const postWhatsappUserFailureAction = createAction(
  "[WhatsappWeb] Post Whatsapp User Failure",
  props<{ error: string }>()
);

export const putWhatsappUserInitAction = createAction(
  "[WhatsappWeb] Put Whatsapp User Init"
);
export const putWhatsappUserSuccessAction = createAction(
  "[WhatsappWeb] Put Whatsapp User Success",
  props<{ whatsappUser: ChatUser }>()
);
export const putWhatsappUserFailureAction = createAction(
  "[WhatsappWeb] Put Whatsapp User Failure",
  props<{ error: string }>()
);

export const deleteWhatsappUserInitAction = createAction(
  "[WhatsappWeb] Delete Whatsapp User Init"
);
export const deleteWhatsappUserSuccessAction = createAction(
  "[WhatsappWeb] Delete Whatsapp User Success",
  props<{ id: string }>()
);
export const deleteWhatsappUserFailureAction = createAction(
  "[WhatsappWeb] Delete Whatsapp User Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getChatInitAction,
  getChatSuccessAction,
  getChatFailureAction,
  putChatInitAction,
  putChatSuccessAction,
  putChatFailureAction,
  postWhatsappUserInitAction,
  postWhatsappUserSuccessAction,
  postWhatsappUserFailureAction,
  putWhatsappUserInitAction,
  putWhatsappUserSuccessAction,
  putWhatsappUserFailureAction,
  deleteWhatsappUserInitAction,
  deleteWhatsappUserSuccessAction,
  deleteWhatsappUserFailureAction,
});

export type WhatsappChatApiAction = typeof actions;
