import React from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { useSocialChatCore } from "../../../../cores/socialChat";
import {
  WHATSAPP_COLOR,
  FACEBOOK_COLOR,
  INSTAGRAM_COLOR,
} from "../../../../constants/socialColors.constant";
import { Conversations } from "../../../../cores/socialChat/interfaces/conversations-group.interface";
import { InputWembii } from "../../InputWembii";
import { FilterOption } from "../filterOption/FilterOption";
import SelectContactStateDropdown from "../SelectContactState/ContactStateDropdownOptions";
import { Container } from "reactstrap";
import { Conversation } from "../../../../cores/socialChat/interfaces/conversations.interface";

const ChatFilterModal: React.FC<{ conversations: Conversations }> = ({
  conversations,
}) => {
  const {
    conversationStatus,
    selectedSocial,
    setSelectedSocial,
    setConversationStatus,
    setDateFilter,
    dateFilter,
  } = useSocialChatCore();
  const intl = useIntl();

  const isValidDate = (date: Date) => {
    return date instanceof Date && !isNaN(date.getMilliseconds());
  };

  const filterDateConversation = (conversation: Conversation) => {
    const fromDate = new Date(dateFilter.from);
    const toDate = new Date(dateFilter.to);
    const conversationDate = new Date(conversation.timestamp.full);

    if (isValidDate(fromDate) || isValidDate(toDate)) {
      if (!isValidDate(fromDate) && isValidDate(toDate)) {
        return conversationDate <= toDate;
      }
      if (isValidDate(fromDate) && !isValidDate(toDate)) {
        return conversationDate >= fromDate;
      }
      if (isValidDate(fromDate) && isValidDate(toDate)) {
        return conversationDate >= fromDate && conversationDate <= toDate;
      }
    }
    return true;
  };

  const getSocialCounters = () => {
    const { all, facebook, instagram, web, whatsapp } = conversations;

    return {
      instagram:
        instagram.active.filter(filterDateConversation).length +
        instagram.archived.filter(filterDateConversation).length +
        instagram.inTrash.filter(filterDateConversation).length,

      facebook:
        facebook.active.filter(filterDateConversation).length +
        facebook.archived.filter(filterDateConversation).length +
        facebook.inTrash.filter(filterDateConversation).length,
      whatsapp:
        whatsapp.active.filter(filterDateConversation).length +
        whatsapp.archived.filter(filterDateConversation).length +
        whatsapp.inTrash.filter(filterDateConversation).length,
      web:
        web.active.filter(filterDateConversation).length +
        web.archived.filter(filterDateConversation).length +
        web.inTrash.filter(filterDateConversation).length,
      all:
        all.active.filter(filterDateConversation).length +
        all.archived.filter(filterDateConversation).length +
        all.inTrash.filter(filterDateConversation).length,
    };
  };

  const getStatusesCounter = () => {
    return {
      active: conversations[selectedSocial].active.filter(
        filterDateConversation
      ).length,
      archived: conversations[selectedSocial].archived.filter(
        filterDateConversation
      ).length,
      inTrash: conversations[selectedSocial].inTrash.filter(
        filterDateConversation
      ).length,
    };
  };

  return (
    <Container className="h-100" fluid>
      <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
        {intl.formatMessage({ id: "app.crmBusiness.filters" })}
      </h2>
      <div className="row p-2">
        <div className="my-4 border-bottom">
          <div className="mb-4">
            <SelectContactStateDropdown />
          </div>

          <FilterOption
            label={` ${intl.formatMessage({ id: "app.chat.active" })} (
              ${getStatusesCounter().active})`}
            onClick={() => setConversationStatus("active")}
            className={cn("pointer col-md-auto col-sm-12", {
              "text-primary": conversationStatus === "active",
            })}
            icon={
              <FontAwesomeIcon
                className="mx-2"
                icon={["fas", "comments"]}
                size="lg"
                color={conversationStatus !== "active" ? "grey" : ""}
              />
            }
          />
          <FilterOption
            label={` ${intl.formatMessage({ id: "app.chat.archived" })} (
              ${getStatusesCounter().archived})`}
            onClick={() => setConversationStatus("archived")}
            className={cn("pointer col-md-auto col-sm-12", {
              "text-primary": conversationStatus === "archived",
            })}
            icon={
              <FontAwesomeIcon
                className="mx-2"
                icon={["fas", "comments"]}
                size="lg"
                color={conversationStatus !== "archived" ? "grey" : ""}
              />
            }
          />
          <FilterOption
            label={` ${intl.formatMessage({ id: "app.chat.inTrash" })} (
              ${getStatusesCounter().inTrash})`}
            onClick={() => setConversationStatus("inTrash")}
            className={cn("pointer col-md-auto col-sm-12", {
              "text-primary": conversationStatus === "inTrash",
            })}
            icon={
              <FontAwesomeIcon
                className="mx-2"
                size="lg"
                icon={["fas", "trash"]}
                color={conversationStatus !== "inTrash" ? "grey" : ""}
              />
            }
          />
        </div>
        <div className="my-4">
          <FilterOption
            label={`Todos (${getSocialCounters().all})`}
            onClick={() => setSelectedSocial("all")}
            className={cn("pointer col-md-auto col-sm-12 ", {
              "text-primary": selectedSocial === "all",
            })}
            icon={
              <FontAwesomeIcon
                className="mx-2"
                icon={["fas", "message"]}
                size="lg"
                color={selectedSocial !== "all" ? "grey" : ""}
              />
            }
          />
          <FilterOption
            label={`Web (${getSocialCounters().web})`}
            onClick={() => setSelectedSocial("web")}
            className={cn("pointer col-md-auto col-sm-12", {
              "text-primary": selectedSocial === "web",
            })}
            icon={
              <FontAwesomeIcon
                className="mx-2"
                icon={["fas", "globe"]}
                size="lg"
                color={selectedSocial !== "web" ? "grey" : ""}
              />
            }
          />
          <FilterOption
            label={`Whatsapp (${getSocialCounters().whatsapp})`}
            onClick={() => setSelectedSocial("whatsapp")}
            className={cn("pointer col-md-auto col-sm-12", {
              "text-primary": selectedSocial === "whatsapp",
            })}
            icon={
              <FontAwesomeIcon
                className="mx-2"
                icon={["fab", "whatsapp"]}
                size="lg"
                color={WHATSAPP_COLOR}
              />
            }
          />
          <FilterOption
            label={`Facebook (${getSocialCounters().facebook})`}
            onClick={() => setSelectedSocial("facebook")}
            className={cn("pointer col-md-auto col-sm-12", {
              "text-primary": selectedSocial === "facebook",
            })}
            icon={
              <FontAwesomeIcon
                className="mx-2"
                icon={["fab", "facebook"]}
                size="lg"
                color={FACEBOOK_COLOR}
              />
            }
          />
          <FilterOption
            label={`Instagram (${getSocialCounters().instagram})`}
            onClick={() => setSelectedSocial("instagram")}
            className={cn("pointer col-md-auto col-sm-12", {
              "text-primary": selectedSocial === "instagram",
            })}
            icon={
              <FontAwesomeIcon
                className="mx-2"
                icon={["fab", "instagram"]}
                size="lg"
                color={INSTAGRAM_COLOR}
              />
            }
          />
          <InputWembii
            onChange={(value) => setDateFilter(value, "from")}
            type="date"
            max={
              isValidDate(new Date(dateFilter.to))
                ? new Date(dateFilter.to).toISOString().split("T")[0]
                : undefined
            }
            className="mx-2 col"
            label="Desde"
          />
          <InputWembii
            type="date"
            min={
              isValidDate(new Date(dateFilter.from))
                ? new Date(dateFilter.from).toISOString().split("T")[0]
                : undefined
            }
            className="mx-2 col"
            label="Hasta"
            onChange={(value) => setDateFilter(value, "to")}
          />
        </div>
      </div>
    </Container>
  );
};

export default ChatFilterModal;
