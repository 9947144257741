import _ from "lodash";
import Select from "react-select";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import {
  USER_COMPANIES,
  USER_EXPAND_CREATE_FORM,
  USER_EXPAND_EDIT_FORM,
} from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { usePlanCore } from "../../../../cores/plan";
import { useResellerCore } from "../../../../cores/reseller";
import { useUserCore } from "../../../../cores/user";
import { useIntl } from "react-intl";
import SideModal from "../../../layout/SideModal";
import { InputWembii } from "../../InputWembii";
import { PaginationWemmbii } from "../../pagination/Pagination";
import { PanelWembii } from "../../PanelWembii";
import { CreateUserForm } from "../CreateUserForm";
import { LabelWembii } from "../../LabelWembii";

const userRolesOptions = [
  { value: "", label: "Todos"   },
  { value: "User", label: "User" },
  { value: "Owner", label: "Owner" },
  { value: "Admin", label: "Admin" },
  { value: "Reseller", label: "Reseller" },
];

const searchOptions = [
  { value: "email__p", label: "Email" },
  { value: "firstName__p", label: "Nombre" },
  { value: "lastName__p", label: "Apellido" },
];

export const UsersBar = () => {
  const intl = useIntl();
  const { getUsers, users } = useUserCore();
  const { company, user } = useMeCore();
  const { plans, getAllPlans } = usePlanCore();
  const { turnOffModal, isModal, getModalContent, getModalVisibility } = useGlobalCore();
  const { resellers, getResellers } = useResellerCore();
  const [search, setSearch] = useState("");
  const [debounceSearchText, setDebounceSearchText] = useState("");
  const [searchOption, setSearchOption] = useState("email__p");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [role, setRole] = useState("");
  const [reseller, setReseller] = useState(user?.reseller?.id);
  const [selectedPlans, setSelectedPlans] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    setOptions(plans.map((plan) => ({ value: `${plan.id}`, label: intl.formatMessage({ id: plan.name }) })));
  }, [plans, intl]);

  const debouncedSearch = useRef(
    _.debounce((search) => setSearch(search), 1000)
  ).current;

  useEffect(() => {
    getResellers();
  }, [getResellers]);

  useEffect(() => {
    debouncedSearch(debounceSearchText);
  }, [debounceSearchText, debouncedSearch]);

  useEffect(() => {
    getUsers({
      page,
      perPage,
      [searchOption]: search,
      role,
      plans: selectedPlans.map((plan) => plan.value),
      belongToResellerId: reseller,
    });
  }, [
    company,
    getUsers,
    page,
    perPage,
    selectedPlans,
    reseller,
    role,
    search,
    searchOption,
  ]);

  useEffect(() => {
    setPage(1);
    setPerPage(10);
  }, [company, getUsers, role, selectedPlans, reseller, search, searchOption]);

  useEffect(() => {}, [
    company,
    getUsers,
    role,
    selectedPlans,
    reseller,
    search,
    searchOption,
  ]);

  useEffect(() => {
    getAllPlans();
  }, [getAllPlans]);

  useEffect(() => {
    if (user && user.reseller) setReseller(`${user.reseller.id}`);
  }, [resellers, user]);

  const onSuccess = useCallback(() => {
    turnOffModal(USER_EXPAND_EDIT_FORM);
    turnOffModal(USER_EXPAND_CREATE_FORM);
    getUsers({
      page,
      perPage,
      [searchOption]: search,
      role,
      plans: selectedPlans.map((plan) => plan.value),
      belongToResellerId: reseller,
    });
  }, [
    turnOffModal,
    getUsers,
    page,
    perPage,
    searchOption,
    search,
    role,
    selectedPlans,
    reseller,
  ]);

  const setPlans = useCallback((value) => {
    if (value) {
      setSelectedPlans(value);
    } else {
      setSelectedPlans([]);
    }
  }, []);

  return (
    <>
      <PanelWembii className="w-100 px-4 py-3 my-3 pointer" shadow>
        <Row>
          <Col xs="7" sm="6" lg="4"  md="6">
            <InputWembii
              mb={false}
              label={intl.formatMessage({ id: "app.adminUsers.search" })}
              placeholder={intl.formatMessage({ id: "app.adminUsers.search" })}
              type="text"
              onChange={(value) => setDebounceSearchText(value)}
              value={debounceSearchText}
              style={{ borderRadius: "0px 10px 10px 0px" }}
              prepend={
                <InputWembii
                  type="select"
                  onChange={(value: string) => setSearchOption(value)}
                  value={searchOption}
                  mb={false}
                  style={{ borderRadius: "10px 0px 0px 10px" }}
                >
                  {searchOptions.map(({ label, value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </InputWembii>
              }
            />
          </Col>
          <Col xs="5" lg="4" sm="6" md="6">
            <div className=" mx-auto">
              <InputWembii
                label="Rol"
                type="select"
                onChange={(value: string) => setRole(value)}
                value={role}
              >
                {userRolesOptions.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </InputWembii>
            </div>
          </Col>
          <Col xs="6" lg="4" sm="6" md="6"> 
            <div className="mx-auto">
              <FormGroup className="d-flex flex-column position-relative w-100 m-0">
                <LabelWembii>
                  Plan
                </LabelWembii>
                <Select
                  isMulti
                  id="plan"
                  name="plans"
                  options={options}
                  value={selectedPlans}
                  className=""
                  onChange={setPlans}
                  placeholder={intl.formatMessage({ id: "app.source.all" })}
                  classNamePrefix="select"
                />
              </FormGroup>
            </div>
          </Col>
          <Col xs="6" lg="3" sm="6" md="6">
            <div className="mx-auto">
              <InputWembii
                label="Reseller"
                type="select"
                onChange={(value: string) => setReseller(value)}
                value={reseller}
                disabled={user?.userRole?.reseller}
              >
                {[{ id: "", name: intl.formatMessage({ id: "app.adminUsers.all" }) },
                  ...resellers].map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  )
                )}
              </InputWembii>
            </div>
          </Col>
          <Col />
          <Col xs="12" lg="auto">
            <PaginationWemmbii
              className="w-fit mx-auto mr-lg-0 ml-lg-auto"
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              disabledPages={users.length < perPage}
            />
          </Col>
        </Row>
      </PanelWembii>
      <SideModal
        active={isModal(USER_EXPAND_EDIT_FORM)}
        visible={getModalVisibility(USER_EXPAND_EDIT_FORM)}
        onClose={onSuccess}
      >
        {getModalContent(USER_EXPAND_EDIT_FORM)}
      </SideModal>
      <SideModal
        active={isModal(USER_EXPAND_CREATE_FORM)}
        visible={getModalVisibility(USER_EXPAND_CREATE_FORM)}
        onClose={() => turnOffModal(USER_EXPAND_CREATE_FORM)}
      >
        <CreateUserForm onCreate={onSuccess} />
      </SideModal>
      <SideModal
        active={isModal(USER_COMPANIES)}
        visible={getModalVisibility(USER_COMPANIES)}
        onClose={() => turnOffModal(USER_COMPANIES)}
        left
      >
        {getModalContent(USER_COMPANIES)}
      </SideModal>
    </>
  );
};
