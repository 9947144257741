import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { Col, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useBoardCore } from "../../../../cores/boards";
import { InputWembii } from "../../InputWembii";
import { Tax } from "../../../../cores/boards/interfaces/Quote";
import { getCurrencySymbol } from "../../../../cores/boards/constants/currencies.constant";
import { COLOR_DANGER } from "../../../../constants/theme";
import { CL$Format } from "../../../../cores/utils";

interface Props {
  tax: Tax;
  index: number;
  subtotal: number;
}

export const TaxRow = ({ tax, index, subtotal }: Props) => {
  const intl = useIntl();
  const { quote, card, setQuote } = useBoardCore();

  const changeTaxName = useCallback((value) => {
    let taxes = [...quote.taxes];
    let tax = { ...taxes[index] };
    tax.name = value;
    taxes[index] = tax;

    setQuote({ ...quote, taxes });
  }, [quote, index, setQuote]);

  const changeTax = useCallback((value) => {
    let taxes = [...quote.taxes];
    let tax = { ...taxes[index] };
    tax.value = parseFloat(value);
    taxes[index] = tax;

    setQuote({ ...quote, taxes });
  }, [quote, index, setQuote]);

  const changeTaxType = useCallback((value) => {
    let taxes = [...quote.taxes];
    let tax = { ...taxes[index] };
    tax.type = parseInt(value);
    taxes[index] = tax;

    setQuote({ ...quote, taxes });
  }, [quote, index, setQuote]);

  const removeTax = useCallback(() => {
    let taxes = [...quote.taxes];
    taxes.splice(index, 1);

    setQuote({ ...quote, taxes });
  }, [quote, index, setQuote]);

  const taxOptions = useMemo(() => [
    { value: 0, label: "%" },
    { value: 1, label: getCurrencySymbol(card?.currency!) },
  ], [card]);

  const calculateTax = useMemo(() => {
    const value = (!tax.type || tax.type === 0)
      ? subtotal * tax.value / 100
      : tax.value;
    return value.toFixed(2);
  }, [subtotal, tax]);

  return (
    <div>
      <div>{intl.formatMessage({ id: "app.products.uniqueTax" })}</div>
      <Row key={index} className="py-1">
        <Col>
          <InputWembii
            type="text"
            name="name"
            id="name"
            mb={false}
            value={tax.name}
            onChange={(v) => changeTaxName(v)}
          />
        </Col>
        <Col>
          <InputWembii
            type="number"
            name="tax"
            id="tax"
            mb={false}
            min="0"
            step=".01"
            value={tax.value}
            style={{ borderRadius: "0px 10px 10px 0px" }}
            onChange={(v) => changeTax(v)}
            prepend={
              <InputWembii
                type="select"
                value={tax.type}
                mb={false}
                style={{
                  borderRadius: "10px 0px 0px 10px",
                  maxWidth: "125px"
                }}
                onChange={(v) => changeTaxType(v)}
              >
                {taxOptions.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </InputWembii>
            }
          />
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          {getCurrencySymbol(card?.currency!)}{` ${CL$Format(calculateTax)}`}

          <FontAwesomeIcon
            icon={["fas", "trash"]}
            className="ml-2 pointer"
            color={COLOR_DANGER}
            onClick={removeTax}
          />
        </Col>
      </Row>
    </div>
  );
};
