import cn from "classnames";
import React from "react";
import { COLOR_SECONDARY } from "../../../../constants/theme";
import { Icon } from "../../companies/icons/Icon";

interface Props {
  amount: number;
  showLabel?: boolean;
  color?: string;
  className?: string;
}

export const CommentLabel = (props: Props) => {
  const { amount, showLabel, color, className } = props;
  return (
    <span className={cn(className, "font-weight-bolder")}>
      <Icon
        name="comment-alt"
        color={color || COLOR_SECONDARY}
        className="mr-2"
      />
      {showLabel && "Comentarios: "} {amount}
    </span>
  );
};
