import chatApiClient from "../../helpers/services/chat-api-client";
import { GetChatResponse } from "../interfaces/api/get-whatsapp-chat";
import {
  PostChatUserRequest,
  PostChatUserResponse,
} from "../interfaces/api/post-chat-user";
import {
  PutChatUserRequest,
  PutChatUserResponse,
} from "../interfaces/api/put-chat-user";
import {
  PutChatRequest,
  PutChatResponse,
} from "../interfaces/api/put-whatsapp-chat";
import { chatMapper } from "./mapper";

export const whatsappChatApiService = {
  getChat(companyId: string) {
    return chatApiClient()
      .get<GetChatResponse>(`chat/whatsapp/config`, { params: { companyId } })
      .then(({ data }) => chatMapper.mapResponseToChat(data));
  },
  putChat(
    companyId: string,
    chatId: string,
    title: string,
    subtitle: string,
    titleColor: string,
    backgroundColor: string,
    startChat: boolean,
    openButtonColor: string,
    openButtonIconColor: string,
    position: number,
    isActive: boolean
  ) {
    const body: PutChatRequest = {
      title,
      subtitle,
      titleColor,
      backgroundColor,
      startChat,
      openButtonColor,
      openButtonIconColor,
      position,
      isActive,
    };
    return chatApiClient()
      .put<PutChatResponse>(`chat/whatsapp/config/${chatId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => chatMapper.mapResponseToChatConfig(data));
  },
  postChatUser(
    companyId: string,
    chatId: string,
    phoneNumber: string,
    userId: string,
    alias: string,
    tag: string,
    skipLogin: boolean
  ) {
    const body: PostChatUserRequest = {
      phoneNumber,
      userId,
      chatId,
      alias,
      tag,
      skipLogin,
    };
    return chatApiClient()
      .post<PostChatUserResponse>(`chat/whatsapp/config/${chatId}/user`, body, {
        params: { companyId },
      })
      .then(({ data }) => chatMapper.mapResponseToChatUser(data));
  },

  putChatUser(
    companyId: string,
    chatId: string,
    chatUserId: string,
    phoneNumber: string,
    userId: string,
    alias: string,
    tag: string,
    skipLogin: boolean
  ) {
    const body: PutChatUserRequest = {
      phoneNumber,
      userId,
      alias,
      tag,
      skipLogin,
    };
    return chatApiClient()
      .put<PutChatUserResponse>(
        `chat/whatsapp/config/${chatId}/user/${chatUserId}`,
        body,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => chatMapper.mapResponseToChatUser(data));
  },
  deleteChatUser(companyId: string, chatId: string, chatUserId: string) {
    return chatApiClient()
      .delete(`chat/whatsapp/config/${chatId}/user/${chatUserId}`, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
};
