import React from "react";
import { Nav } from "reactstrap";
import { TitleWembii } from "../TitleWembii";
import { NavItem2 } from "./NavItem2";
import { useIntl } from "react-intl";
import { useRouterCore } from "../../../cores/router";

const SidebarUserRoutes: React.FC<{ collapseSideBar: boolean }> = ({
  collapseSideBar,
}) => {
  const intl = useIntl();
  const { routes, goToMeCompany } = useRouterCore();

  return (
    <>
      <hr className="horizontal-separator my-3" />
      {!collapseSideBar && (
        <TitleWembii Tag="h6">
          {intl.formatMessage({ id: "app.sidebar.myPlan" })}
        </TitleWembii>
      )}
      <Nav vertical>
        <NavItem2
          onClick={goToMeCompany}
          label={intl.formatMessage({ id: "app.sidebar.company" })}
          iconName="building"
          activeUrl={routes.meCompany}
        />
      </Nav>
    </>
  );
};

export default SidebarUserRoutes;
