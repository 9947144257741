import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { MAILCHIMP_COLOR } from "../../../../constants/socialColors.constant";
import { IconProps } from "../Facebook/FacebookIcon";

export const MailchimpIcon = (props: IconProps) => {
  const { active, className, size = "1x", color } = props;

  return (
    <FontAwesomeIcon
      style={{
        color: color || (active ? MAILCHIMP_COLOR : "gray"),
      }}
      icon={["fab", "mailchimp"]}
      className={className}
      size={size}
    />
  );
};
