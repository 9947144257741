import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { COLOR_SUCCESS } from "../../../../constants/theme";

export default function ExcelPreview({ size }: { size?: SizeProp }) {
  return (
    <FontAwesomeIcon
      icon={["far", "file-excel"]}
      size={!size ? "5x" : size}
      className="filePreview"
      color={COLOR_SUCCESS}
    />
  );
}