import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Session } from "../interfaces/models/session";

export const sessionInitAction = createAction("[Session] Init");
export const sessionSuccessAction = createAction(
  "[Session] Success",
  props<{ session: Session }>()
);
export const sessionFailureAction = createAction(
  "[Session] Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  sessionInitAction,
  sessionSuccessAction,
  sessionFailureAction,
});

export type SessionApiAction = typeof actions;
