import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Container, FormGroup } from "reactstrap";
import { useMeCore } from "../../../../../cores/me";
import { useIntl } from "react-intl";
import { useWebFormCore } from "../../../../../cores/webForm";
import { LabelWembii } from "../../../LabelWembii";
import { PanelWembii } from "../../../PanelWembii";
import { TitleWembii } from "../../../TitleWembii";

export default function InstalationForm() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { webForm } = useWebFormCore();
  const domain = process.env.REACT_APP_EMBEDDED_FORM_DOMAIN;
  const [code, setCode] = useState("");

  useEffect(() => {
    if (webForm.form) {
      setCode(
        `<div id="wembii-lith-form"></div>\n`+
        `<script src="${domain}"></script>\n`+
        `<script>window.loadWembiiForm("${btoa(company?.id!)}", "${btoa(webForm?.form!.id)}")</script>`
      );
    }
  }, [webForm, company, domain]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(code);
    toast.success(intl.formatMessage({ id: "app.editForm.instalationMsg" }));
  }, [code, intl]);

  return (
    <Container className="p-0">
      <PanelWembii shadow className="mb-4 p-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.editForm.instalation" })}
        </TitleWembii>
        <LabelWembii forid="copyCode">
          {intl.formatMessage({ id: "app.editForm.instalationSubtitle" })}
        </LabelWembii>
        <FormGroup className="d-flex align-items-start justify-content-between mb-0">
          <pre className="d-flex flex-column p-2 bg-light">
            {code}
          </pre>
          <div
            className="border border-white-50 text-dark shadow-sm btn btn-sm"
            color="white"
            id="copyCode"
            onClick={handleCopy}
          >
             {intl.formatMessage({ id: "app.editForm.instalationCopy" })}
          </div>
        </FormGroup>
      </PanelWembii>
    </Container>
  );
}
