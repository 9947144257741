import React from "react";
import { EDIT_VIDEO_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { VideoEditorForm } from "./VideoEditorForm";

interface Props {
  video: string;
  type: string;
  onLoadedMetadata?: (arg0: HTMLVideoElement) => void;
}

export const VideoPreview = (props: Props) => {
  const { video, type, onLoadedMetadata } = props;
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const handleOnClick = () => {
    turnOnModal(
      EDIT_VIDEO_MODAL,
      <VideoEditorForm onClose={() => turnOffModal(EDIT_VIDEO_MODAL)} />,
      Position.LEFT
    );
  };

  return (
    <video
      className="w-100 pointer"
      onClick={handleOnClick}
      onLoadedMetadata={(event) => onLoadedMetadata?.(event.currentTarget)}
    >
      <source src={video} type={type} />
      Your browser does not support HTML5 video.
    </video>
  );
};
