import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const sessionRoot = (state: State) => state.session;

export const tokenSelector = createSelector(
  sessionRoot,
  (session) => session.token
);
export const refreshTokenSelector = createSelector(
  sessionRoot,
  (session) => session.refreshToken
);
export const userSelector = createSelector(
  sessionRoot,
  (session) => session.user
);
