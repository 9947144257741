import React, { useCallback } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { InputWembii } from "../../InputWembii";
import { useIntl } from "react-intl";
import { Board, UserRoles } from "../../../../cores/boards/interfaces/api/get-board";
import CurrencySelector from "../CurrencySelector";
import { LabelWembii } from "../../LabelWembii";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant";
import { BOARD_EDIT_CONFIG, BOARD_USERS_MODAL, DELETE_BOARD_ITEM_MODAL } from "../../../../constants/modal.constant";
import AreYouSure from "../../AreYouSure";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { useBoardCore } from "../../../../cores/boards";
import UserList from "./UserList";
import Avatar from "../../Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserBoardRole, UserBoardRoleOptions } from "../../../../cores/boards/constants/user-board-role";
import { COLOR_DANGER } from "../../../../constants/theme";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { getFirstLetters } from "../../../utils/stringUtils";

interface Props {
  onDelete?: (arg0: Board) => void; 
  board: Board;
}

export default function EditBoard(props: Props) {
  const intl = useIntl();
  const { onDelete, board } = props;
  const { company } = useMeCore();
  const { deleteBoard, board: boardUpdate, setBoardUpdate } = useBoardCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const onDeleteBoard = useCallback(() => {
    turnOnModal(
      DELETE_BOARD_ITEM_MODAL,
      <AreYouSure 
        message={intl.formatMessage({ id: "app.activity.deleteBoard" })}
        onSubmit={() => {
          deleteBoard(company?.id!, board.id, () => {
            onDelete?.(board);
          });
          turnOffModal(DELETE_BOARD_ITEM_MODAL);
          turnOffModal(BOARD_EDIT_CONFIG);
        }}
        onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
      />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    deleteBoard,
    onDelete,
    company,
    board,
    intl
  ]);

  const setValue = useCallback((field) => {
    const newValue = { ...boardUpdate, ...field }
    const isChanged = board.name !== newValue.name
      || board.color !== newValue.color
      || board.currency !== newValue.currency
      || board.userRoles !== newValue.roles
    setBoardUpdate({ ...newValue, isChanged });
  }, [
    board.name,
    board.color,
    board.currency,
    board.userRoles,
    boardUpdate,
    setBoardUpdate
  ]);

  const openAssignmentUsers = useCallback(() => {
    const users = boardUpdate.roles.map((role) => role.user);
    turnOnModal(
      BOARD_USERS_MODAL,
      <UserList assignments={users} onAssign={(user) => {
        setValue({
          roles: [
            ...boardUpdate.roles,
            {
              id: "-1",
              owner: false,
              admin: false,
              member: true,
              user
            }
          ]
        });
        turnOffModal(BOARD_USERS_MODAL);
      }} />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    setValue,
    boardUpdate.roles
  ]);

  const getRole = useCallback((userRole: UserRoles) => 
    userRole.owner
      ? UserBoardRole.Owner
      : userRole.admin
        ? UserBoardRole.Admin
        : UserBoardRole.Member
  , []);

  const changeUserRole = useCallback((role: string, index: number) => {
    const newRoles = [...boardUpdate.roles];
    newRoles[index] = {
      ...newRoles[index],
      admin: false,
      owner: false,
      member: false,
      [`${role}`]: true
    };
    setValue({ roles: newRoles });
  }, [boardUpdate.roles, setValue]);

  const removeUserRole = useCallback((userRole: UserRoles) => {
    setValue({
      roles: boardUpdate.roles.filter((role) => role !== userRole)
    });
  }, [boardUpdate.roles, setValue]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 py-5">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.activity.modifyBoard" })}
            </h2>
            <InputWembii
              type="text"
              name="boardName"
              id="boardName"
              label={intl.formatMessage({ id: "app.activity.boardName" })}
              value={boardUpdate.name}
              required
              onChange={(name) => setValue({ name })}
            />

            {board.type === BoardType.BUSINESS && (
              <>
                <LabelWembii required={true}>
                  {intl.formatMessage({ id: "app.crmBusiness.defaultCurrency" })}
                </LabelWembii>
                <CurrencySelector
                  currency={boardUpdate.currency}
                  onSubmit={(currency) => setValue({ currency })}
                  width="100%"
                />
              </>
            )}

            {board.type === BoardType.ACTIVITY && (
              <div className="d-flex align-items-end mb-4">
                <div 
                  style={{ backgroundColor: boardUpdate.color, height: 40, width: 70 }}
                  className="rounded-pill shadow mr-2"
                />
                <InputWembii
                  type="color"
                  name="color"
                  id="color"
                  label={intl.formatMessage({ id: "app.activity.boardColor" })}
                  mb={false}
                  value={boardUpdate.color}
                  autoComplete="off"
                  required
                  onChange={(color) => setValue({ color })}
                />
              </div>
            )}

            <div className="d-flex justify-content-between align-items-center mt-3">
              <LabelWembii className="">
                {intl.formatMessage({ id: "app.crmBusiness.users" })}
              </LabelWembii>
              <Button
                className="whiteButton border m-0"
                onClick={openAssignmentUsers}
              >
                Add user
              </Button>
            </div>
            {boardUpdate.roles.map((userRole, index) => (
              <Row className="mb-2">
                <Col className="d-flex align-items-center">
                  <Avatar
                    className="text-dark-50 pointer mr-2"
                    image={userRole.user.avatarUrl}
                    label={getFirstLetters(`${userRole.user.lastName} ${userRole.user.firstName}`)}
                    icon={
                      <FontAwesomeIcon
                        icon={["fas", "user"]}
                        size="1x"
                      />
                    }
                  />
                  {userRole.user.firstName} {userRole.user.lastName}
                </Col>
                <Col className="d-flex align-items-center">
                  <InputWembii
                    type="select"
                    className="w-100"
                    mb={false}
                    value={getRole(userRole)}
                    onChange={(v) => changeUserRole(v, index)}
                  >
                    {UserBoardRoleOptions.map(({ label, value }, index) => (
                      <option key={index} value={value}>
                        {intl.formatMessage({ id: label })}
                      </option>
                    ))}
                  </InputWembii>
                  <FontAwesomeIcon
                    icon={["fas", "trash"]}
                    className="m-3 pointer"
                    color={COLOR_DANGER}
                    onClick={() => removeUserRole(userRole)}
                  />
                </Col>
              </Row>
            ))}
            
            <div className="d-flex justify-content-center mt-3">
              <Button
                color="danger"
                className="mr-2"
                onClick={onDeleteBoard}
              >
                {intl.formatMessage({ id: "app.activity.delete" })}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
