import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import {
  Button,
  CardImg,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import yourLogoHere from "../../../assets/yourLogoHere.png";
import { useCompanyCore } from "../../../cores/company";
import { useIntl } from "react-intl";
import { COMPANY_CONFIG_RULES } from "../../../cores/company/config";
import { beforeUploadFile, UploadFileType } from "../../../cores/utils";
import { FromFeedback2 } from "../FromFeedback2";
import { TitleWembii } from "../TitleWembii";

interface Props {
  onSubmit?: () => void;
}

export default function EditCompanyForm(props: Props) {
  const intl = useIntl();
  const { onSubmit } = props;
  const { company, putCompany, postCompanyAvatar } = useCompanyCore();
  const [name, setName] = useState(company?.name);
  const [description, setDescription] = useState(company?.description);
  const [website, setWebsite] = useState(company?.website);
  const [phone, setPhone] = useState(company?.phoneNumber);
  const [country, setCountry] = useState(company?.country);
  const [city, setCity] = useState(company?.city);

  const ref = useRef<any>(null);
  
  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    await putCompany(
      company?.id!,
      name!,
      description!,
      website!,
      phone!,
      country!,
      city!,
      false
    );
    onSubmit?.();
  };

  const isFieldEmpty = (field: string | undefined) => !field;

  const isFieldToLong = (field: string | undefined, length: number) =>
    (field ? field.length : 0) > length;

  const requiredFieldFeedback = (field: string | undefined) => (
    <FromFeedback2
      show={isFieldEmpty(field)}
      message={intl.formatMessage({ id: "app.editCompany.error1" })}
    />
  );
  const fieldLengthFeedback = (field: string | undefined, length: number) => (
    <FromFeedback2
      show={isFieldToLong(field, length)}
      message={`${intl.formatMessage({ id: "app.editCompany.error2" })} ${length} 
      ${intl.formatMessage({ id: "app.editCompany.error3" })}`}
    />
  );

  const isNameInvalid = useMemo(
    () => isFieldEmpty(name) || isFieldToLong(name, 35),
    [name]
  );
  const isDescriptionInvalid = useMemo(() => isFieldToLong(description, 100), [
    description,
  ]);
  const isWebsiteInvalid = useMemo(() => isFieldToLong(website, 35), [website]);
  const isCountryInvalid = useMemo(
    () => isFieldEmpty(country) || isFieldToLong(country, 35),
    [country]
  );
  const isCityInvalid = useMemo(
    () => isFieldEmpty(city) || isFieldToLong(city, 35),
    [city]
  );

  const disabled = useCallback(
    () =>
      !name ||
      !country ||
      !city ||
      isNameInvalid ||
      isDescriptionInvalid ||
      isCityInvalid ||
      isWebsiteInvalid ||
      isCountryInvalid,
    [
      name,
      country,
      city,
      isNameInvalid,
      isDescriptionInvalid,
      isCityInvalid,
      isWebsiteInvalid,
      isCountryInvalid
    ]
  );

  const disabled2 = useCallback(
    () =>
      name === company?.name &&
      description === company?.description &&
      phone === company?.phoneNumber &&
      website === company?.website &&
      country === company?.country &&
      city === company?.city,
    [name, description, phone, website, country, city, company]
  );

  const filesAllowed = useMemo(
    () => [UploadFileType.JPEG, UploadFileType.JPG, UploadFileType.PNG],
    []
  );

  const companyLogo = useMemo(() =>
    (!company?.avatarUrl) ? yourLogoHere : company.avatarUrl
  , [company]);

  const handleSelectPhoto = (files: FileList | null) => {
    if (files) {
      const error = !beforeUploadFile(files[0], COMPANY_CONFIG_RULES.maxImageSize, filesAllowed)

      if (!error) {
        postCompanyAvatar(company?.id!, files[0], onSubmit);
      }
    }
  };

  useEffect(() => {
    setName(company?.name);
    setDescription(company?.description);
    setPhone(company?.phoneNumber);
    setWebsite(company?.website);
    setCountry(company?.country);
    setCity(company?.city);
  }, [company]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 justify-content-center p-4">
            <TitleWembii Tag="h5" color="primary">
              {intl.formatMessage({ id: "app.editCompany.editMeCompany" })}
            </TitleWembii>

            <div className="d-flex justify-content-center">
              <Label className="pointer m-2" for="imageUploader">
                <CardImg
                  top
                  width="90%"
                  className="rounded-circle bg-white"
                  src={companyLogo}
                  alt="Card image cap"
                  style={{ height: "200px", width: "200px", objectFit: "contain" }}
                />
              </Label>
              <Input
                ref={ref}
                type="file"
                id="imageUploader"
                className="d-none"
                accept={filesAllowed.join(',')}
                onChange={(e) => handleSelectPhoto(e.target.files)}
              />
            </div>

            <Form className="px-2" onSubmit={onFormSubmit}>
              <FormGroup>
                <Label for="company-name" required>
                  {intl.formatMessage({ id: "app.nameTxt" })}
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="company-name"
                  placeholder={intl.formatMessage({ id: "app.nameTxt" })}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  invalid={isNameInvalid}
                />
                {requiredFieldFeedback(name)}
                {fieldLengthFeedback(name, 35)}
              </FormGroup>
              <FormGroup> 
                <Label for="company-description">
                  {intl.formatMessage({ id: "app.editCompany.descriptionTxt" })}
                </Label>
                <Input
                  type="text"
                  name="description"
                  id="company-description"
                  placeholder={intl.formatMessage({ id: "app.editCompany.descriptionTxt" })}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  invalid={isDescriptionInvalid}
                />
                {fieldLengthFeedback(description, 100)}
              </FormGroup>
              <FormGroup>
                <Label for="company-phone">
                  {intl.formatMessage({ id: "app.phoneTxt" })}
                </Label>
                <PhoneInput
                  name="phone"
                  id="company-phone"
                  placeholder={intl.formatMessage({ id: "app.phoneTxt" })}
                  value={phone!}
                  onChange={setPhone}
                />
              </FormGroup>
              <FormGroup>
                <Label for="company-webSite">
                  {intl.formatMessage({ id: "app.editCompany.websiteTxt" })}
                </Label>
                <Input
                  type="text"
                  name="webSite"
                  id="company-webSite"
                  placeholder={intl.formatMessage({ id: "app.editCompany.websiteTxt" })}
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  invalid={isWebsiteInvalid}
                />
                {fieldLengthFeedback(website, 35)}
              </FormGroup>
              <FormGroup>
                <Label for="company-country" required>
                  {intl.formatMessage({ id: "app.editCompany.countryTxt" })}
                </Label>
                <Input
                  type="text"
                  name="country"
                  id="company-country"
                  placeholder={intl.formatMessage({ id: "app.editCompany.countryTxt" })}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  invalid={isCountryInvalid}
                />
                {requiredFieldFeedback(country)}
                {fieldLengthFeedback(country, 35)}
              </FormGroup>
              <FormGroup>
                <Label for="company-city" required>
                  {intl.formatMessage({ id: "app.editCompany.cityTxt" })}
                </Label>
                <Input
                  type="text"
                  name="city"
                  id="company-city"
                  placeholder={intl.formatMessage({ id: "app.editCompany.cityTxt" })}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  invalid={isCityInvalid}
                />
                {requiredFieldFeedback(city)}
                {fieldLengthFeedback(city, 35)}
              </FormGroup>
              <Button
                color="primary"
                block
                disabled={disabled() || disabled2()}
              >
                {intl.formatMessage({ id: "app.editCompany.save" })}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
