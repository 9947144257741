import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export interface IconProps {
  className?: string;
  size?: SizeProp;
}

export const ErrorIcon = (props: IconProps) => {
  const { className, size } = props;
  return (
    <FontAwesomeIcon
      style={{
        color: "red",
      }}
      icon={["fas", "exclamation-circle"]}
      className={className}
      size={size}
    />
  );
};
