import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import { LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useLinkedInBusinessCore } from "../../../../cores/linkedInBusiness";
import { Organization } from "../../../../cores/linkedInBusiness/interface/api/post-linkedinBussiness-organizations";
import { useMeCore } from "../../../../cores/me";

interface BindData {
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
}

interface Props {
  bindData: BindData;
}

export default function LinkedInAccounts(props: Props) {
  const { organizations } = useLinkedInBusinessCore();
  const { bindData } = props;
  const intl = useIntl();
  return (
    <div className="p-4">
      <h2 className="h2 text-center mb-4">
        {intl.formatMessage({id: "app.linkedin.chooseAcount"})}
      </h2>
      {organizations.map((organization) => (
        <LinkedInAccount key={organization.id} organization={organization} bindData={bindData} />
      ))}
    </div>
  );
}

const LinkedInAccount = ({
  organization,
  bindData,
}: {
  organization: Organization;
  bindData: BindData;
}) => {
  const intl = useIntl();
  const { avatarUrl, name, id: organizationId } = organization;
  const { accessToken, refreshToken, expiresIn } = bindData;
  const { bind } = useLinkedInBusinessCore();
  const { company } = useMeCore();
  const { turnOffModal } = useGlobalCore();
  const handleBind = useCallback(async () => {
    await bind(
      company!.id,
      accessToken,
      refreshToken,
      expiresIn,
      organizationId
    );
    turnOffModal(LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT);
  }, [
    bind,
    company,
    accessToken,
    refreshToken,
    expiresIn,
    organizationId,
    turnOffModal,
  ]);

  return (
    <div className="align-items-center d-flex p-2 neu-in pointer">
      <img
        style={{ width: "50px", height: "50px" }}
        className="rounded-circle"
        src={avatarUrl}
        alt="Avatar"
      />
      <span className="h5 ml-3"> {name} </span>
      <Button className="ml-auto" color="primary" onClick={handleBind}>
        {intl.formatMessage({id : "app.sincronyze"})}
      </Button>
    </div>
  );
};
