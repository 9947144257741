import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { InstagramAccount } from "../interface/api/get-instagram-accounts";
import { InstagramSetting } from "../interface/api/post-instagram-bind";
import { cleanErrorsAction, InstagramAction } from "./actions";
import {
  getInstagramAccountsInitAction,
  getInstagramAccountsSuccessAction,
  getInstagramFailureAction,
  getInstagramInitAction,
  getInstagramSuccessAction,
  InstagramApiAction,
  postBindInstagramFailureAction,
  postBindInstagramInitAction,
  postBindInstagramSuccessAction,
  postInstagramCodeSuccessAction,
  postInstagramSocialPostFailureAction,
  postUnbindInstagramFailureAction,
  postUnbindInstagramInitAction,
  postUnbindInstagramSuccessAction,
  putInstagramSocialPostFailureAction,
  putInstagramSocialPostInitAction,
  putInstagramSocialPostSuccessAction,
} from "./api-actions";

export interface InstagramState {
  settings: InstagramSetting | null;
  errorCode: number | null;
  errorMessage: string | null;
  accounts: InstagramAccount[];
}

const initialState: InstagramState = {
  settings: null,
  errorCode: null,
  errorMessage: null,
  accounts: [],
};

export default produce(
  (
    draft: InstagramState = initialState,
    action: InstagramApiAction | InstagramAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindInstagramSuccessAction.type:
      case getInstagramSuccessAction.type:
        draft.settings = action.settings;
        draft.errorCode = null;
        draft.errorMessage = null;
        return draft;
      case postBindInstagramFailureAction.type:
        draft.errorCode = action.errorCode;
        draft.errorMessage = action.errorMessage;
        return draft;
      case postInstagramSocialPostFailureAction.type:
        draft.errorCode = action.errorCode;
        draft.errorMessage = action.errorMessage;
        return draft;
      case getInstagramAccountsSuccessAction.type:
        draft.accounts = action.accounts;
        return draft;
      case cleanErrorsAction.type:
        draft.errorCode = null;
        draft.errorMessage = null;
        return draft
      case postUnbindInstagramFailureAction.type:
      case getInstagramFailureAction.type:
      case postUnbindInstagramSuccessAction.type:
        draft.settings = null;
        return draft;
      case postBindInstagramInitAction.type:
      case getInstagramAccountsInitAction.type:
      case postUnbindInstagramInitAction.type:
      case getInstagramInitAction.type:
      case postInstagramCodeSuccessAction.type:
        draft.errorCode = null;
        draft.errorMessage = null;
        return draft;
      case resetAction.type:
        return initialState;
      case putInstagramSocialPostInitAction.type:
      case putInstagramSocialPostSuccessAction.type:
      case putInstagramSocialPostFailureAction.type:
      default:
        return draft;
    }
  }
);
