import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { useCompanyCore } from "../../../cores/company";
import { useMeCore } from "../../../cores/me";
import { useTaskCore } from "../../../cores/tasks";
import { TaskStatusOptions } from "../../../cores/tasks/config";
import { InputWembii } from "../InputWembii";
import { PaginationWemmbii } from "../pagination/Pagination";
import { PanelWembii } from "../PanelWembii";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";
import { LabelWembii } from "../LabelWembii";
import { useIntl } from "react-intl";
import { TaskStatus } from "../../../cores/tasks/config";
import { TooltipWembii } from "../TooltipWembii";

export const TasksBar = () => {
  const intl = useIntl();
  const { users } = useCompanyCore();
  const { getTasks, setTaskFilters, taskFilters, tasks } = useTaskCore();
  const { company, user: meUser } = useMeCore();
  const [search, setSearch] = useState("");
  const [debounceSearchText, setDebounceSearchText] = useState("");
  const [completedDates, setCompletedDates] = useState(["", ""]);

  const debouncedSearch = useRef(
    _.debounce((search) => setSearch(search), 1000)
  ).current;

  useEffect(() => {
    setTaskFilters({
      page: 1,
      perPage: 10,
      userId: meUser?.id!,
      status: taskFilters.status,
      title__p: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, search, meUser, setTaskFilters]);

  useEffect(() => {
    debouncedSearch(debounceSearchText);
  }, [debounceSearchText, debouncedSearch]);

  useEffect(() => {
    getTasks(company!.id, taskFilters);
  }, [company, getTasks, taskFilters]);

  const changeFilters = useCallback((field) => {
    setTaskFilters({ ...taskFilters, ...field });
  }, [taskFilters, setTaskFilters]);

  const onChangeCompletedDate = useCallback((dates) => {
    setCompletedDates(dates);
    setTaskFilters({ ...taskFilters, completedDates: dates });
  }, [taskFilters, setTaskFilters]);

  return (
    <PanelWembii className="w-100 px-4 py-3 my-3 mb-5 pointer" shadow>
      <Row>
        <Col xs="12" lg="3">
          <InputWembii
            label="Búsqueda"
            placeholder="Buscar"
            type="text"
            mb={false}
            onChange={(v) => setDebounceSearchText(v)}
            value={debounceSearchText}
          />
        </Col>
        <Col xs="6" lg="2">
          <InputWembii
            label="Estado"
            type="select"
            mb={false}
            onChange={(status) => changeFilters({ status })}
            value={taskFilters.status}
          >
            <option key="all" value="">
              Todos
            </option>
            {Object.values(TaskStatusOptions).map(({ label, value }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="6" lg="3">
          <div className="h-100 d-flex flex-column align-items-start" id="completed-date">
            <LabelWembii>
              {intl.formatMessage({ id: "app.tasks.completedDate" })}
            </LabelWembii>
            <DateTimeRangePicker onChange={onChangeCompletedDate} value={completedDates} disabled={!taskFilters.status || +taskFilters.status !== TaskStatus.Completed} />
          </div>
          {(!taskFilters.status || +taskFilters.status !== TaskStatus.Completed) && (
            <TooltipWembii
              id="completed-date"
              text={intl.formatMessage({ id: "app.tasks.completedDate.tooltip" })} 
            />
          )}
        </Col>
        <Col xs="12" lg="4">
          <div className="d-flex justify-content-start justify-content-lg-end">
            <PaginationWemmbii
              page={taskFilters.page}
              setPage={(page) => changeFilters({ page })}
              perPage={taskFilters.perPage}
              setPerPage={(perPage) => changeFilters({ perPage })}
              disabledPages={tasks.length < taskFilters.perPage}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="6" lg="3">
          <InputWembii
            label="Asignado a"
            type="select"
            mb={false}
            onChange={(userId) => changeFilters({ userId })}
            value={taskFilters.userId}
          >
            <option key="all" value="">
              Todos
            </option>
            {users.map(({ id, lastName, firstName }) => (
              <option key={id} value={id}>
                {firstName}{' '}{lastName}
              </option>
            ))}
          </InputWembii>
        </Col>
      </Row>
    </PanelWembii>
  );
};
