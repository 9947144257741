import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTOMATIONS_LOADING,
  AUTOMATION_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { PostActionRequest } from "./interfaces/api/post-action";
import { PostAutomationRequest } from "./interfaces/api/post-automation";
import { PostConditionRequest } from "./interfaces/api/post-condition";
import { PostContactRequest } from "./interfaces/api/post-contact";
import { PutActionRequest } from "./interfaces/api/put-action";
import { PutConditionRequest } from "./interfaces/api/put-condition";
import { automationApiService } from "./services/api";
import {
  postAutomationInitAction,
  postAutomationSuccessAction,
  postAutomationFailureAction,
  getAutomationsInitAction,
  getAutomationsSuccessAction,
  getAutomationsFailureAction,
  deleteAutomationInitAction,
  deleteAutomationSuccessAction,
  deleteAutomationFailureAction,
  putAutomationInitAction,
  putAutomationSuccessAction,
  putAutomationFailureAction,
  getAutomationSuccessAction,
  getAutomationFailureAction,
  getAutomationInitAction,
  postConditionInitAction,
  postConditionSuccessAction,
  postConditionFailureAction,
  deleteConditionInitAction,
  deleteConditionSuccessAction,
  deleteConditionFailureAction,
  putConditionFailureAction,
  putConditionInitAction,
  putConditionSuccessAction,
  deleteActionInitAction,
  deleteActionFailureAction,
  deleteActionSuccessAction,
  postActionFailureAction,
  postActionInitAction,
  postActionSuccessAction,
  putActionFailureAction,
  putActionInitAction,
  putActionSuccessAction,
  postContactInitAction,
  postContactSuccessAction,
  postContactFailureAction,
  deleteContactInitAction,
  deleteContactSuccessAction,
  deleteContactFailureAction,
  postUserSuccessAction,
  deleteUserSuccessAction,
} from "./store/api-actions";
import {
  actionsSelector,
  automationSelector,
  automationsSelector,
  conditionsSelector,
  contactsSelector,
  usersSelector,
} from "./store/selector";
import { PostAutomationUserRequest } from "./interfaces/api/post-user";

export function useAutomationCore() {
  const dispatch = useDispatch();
  const automation = useSelector(automationSelector);
  const actions = useSelector(actionsSelector);
  const conditions = useSelector(conditionsSelector);
  const automations = useSelector(automationsSelector);
  const contacts = useSelector(contactsSelector);
  const users = useSelector(usersSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const getAutomations = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getAutomationsInitAction());
        turnOnLoading(AUTOMATIONS_LOADING);
        const response = await automationApiService.getAutomations(companyId);
        dispatch(getAutomationsSuccessAction(response));
        turnOffLoading(AUTOMATIONS_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(AUTOMATIONS_LOADING);
        console.error(error);
        dispatch(getAutomationsFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const getAutomation = useCallback(
    async (companyId: string, automationId: string, onSuccess?: () => void) => {
      try {
        dispatch(getAutomationInitAction());
        turnOnLoading(AUTOMATION_LOADING);
        const response = await automationApiService.getAutomation(
          companyId,
          automationId
        );
        dispatch(getAutomationSuccessAction(response));
        onSuccess?.();
        turnOffLoading(AUTOMATION_LOADING, 1500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(AUTOMATION_LOADING);
        dispatch(getAutomationFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const postAutomation = useCallback(
    async (
      companyId: string,
      body: PostAutomationRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postAutomationInitAction());
        const response = await automationApiService.postAutomation(
          companyId,
          body
        );
        dispatch(postAutomationSuccessAction(response));
        onSuccess?.();
        return response.automation.id;
      } catch (error: any) {
        console.error(error);
        dispatch(postAutomationFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putAutomation = useCallback(
    async (companyId: string, automationId: string, active: boolean) => {
      try {
        dispatch(putAutomationInitAction());
        const response = await automationApiService.putAutomation(
          companyId,
          automationId,
          active
        );
        dispatch(putAutomationSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(putAutomationFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteAutomation = useCallback(
    async (companyId: string, automationId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteAutomationInitAction());
        const response = await automationApiService.deleteAutomation(
          automationId,
          companyId
        );
        dispatch(deleteAutomationSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteAutomationFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCondition = useCallback(
    async (
      companyId: string,
      automationId: string,
      body: PostConditionRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postConditionInitAction());
        const response = await automationApiService.postCondition(
          companyId,
          automationId,
          body
        );
        dispatch(postConditionSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postConditionFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putCondition = useCallback(
    async (
      companyId: string,
      automationId: string,
      conditionId: string,
      body: PutConditionRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putConditionInitAction());
        const response = await automationApiService.putCondition(
          companyId,
          automationId,
          conditionId,
          body
        );
        dispatch(putConditionSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putConditionFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteCondition = useCallback(
    async (
      companyId: string,
      automationId: string,
      conditionId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteConditionInitAction());
        const response = await automationApiService.deleteCondition(
          conditionId,
          automationId,
          companyId
        );
        dispatch(deleteConditionSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteConditionFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postAction = useCallback(
    async (
      companyId: string,
      automationId: string,
      body: PostActionRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postActionInitAction());
        const response = await automationApiService.postAction(
          companyId,
          automationId,
          body
        );
        dispatch(postActionSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postActionFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putAction = useCallback(
    async (
      companyId: string,
      automationId: string,
      actionId: string,
      body: PutActionRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putActionInitAction());
        const response = await automationApiService.putAction(
          companyId,
          automationId,
          actionId,
          body
        );
        dispatch(putActionSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putActionFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteAction = useCallback(
    async (
      companyId: string,
      automationId: string,
      actionId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteActionInitAction());
        const response = await automationApiService.deleteAction(
          actionId,
          automationId,
          companyId
        );
        dispatch(deleteActionSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteActionFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postContact = useCallback(
    async (
      companyId: string,
      automationId: string,
      body: PostContactRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postContactInitAction());
        const response = await automationApiService.postContact(
          companyId,
          automationId,
          body
        );
        dispatch(postContactSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postContactFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postUser = useCallback(
    async (
      companyId: string,
      automationId: string,
      body: PostAutomationUserRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postContactInitAction());
        await automationApiService.postUser(companyId, automationId, body);
        dispatch(postUserSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postContactFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const deleteContact = useCallback(
    async (
      companyId: string,
      automationId: string,
      contactId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteContactInitAction());
        const response = await automationApiService.deleteContact(
          contactId,
          automationId,
          companyId
        );
        dispatch(deleteContactSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteContactFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const deleteUser = useCallback(
    async (
      companyId: string,
      automationId: string,
      userId: string,
      onSuccess?: (id: string) => void
    ) => {
      try {
        dispatch(deleteContactInitAction());
        const response = await automationApiService.deleteUser(
          companyId,
          userId,
          automationId
        );
        dispatch(deleteUserSuccessAction(response));
        onSuccess?.(response.id);
      } catch (error: any) {
        console.error(error);
        dispatch(deleteContactFailureAction({ error }));
      }
    },
    [dispatch]
  );

  return {
    automation,
    actions,
    users,
    conditions,
    automations,
    contacts,
    getAutomations,
    getAutomation,
    postAutomation,
    putAutomation,
    deleteAutomation,
    postCondition,
    putCondition,
    deleteCondition,
    postAction,
    putAction,
    deleteAction,
    postContact,
    deleteContact,
    postUser,
    deleteUser,
  };
}
