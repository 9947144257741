import React, { useMemo } from "react";
import Protector from "../Protector";
import { RenderProtectorRoutes } from "./RenderProtectorRoutes";
import { uniqueId } from "lodash";

const SidebarProtectorRoutes = () => {
  const { protectorRoutes } = RenderProtectorRoutes();
  const activeRoutes = useMemo(() => {
    return protectorRoutes.filter(({ moduleIsActive }) => moduleIsActive);
  }, [protectorRoutes]);
  return (
    <>
      {activeRoutes.map(({ condition, render }) => (
        <Protector key={uniqueId()} active={condition}>
          {render}
        </Protector>
      ))}
    </>
  );
};

export default SidebarProtectorRoutes;
