import React, { useCallback, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { BOARDS_LOADING } from "../../constants/loading.constant";
import { useBoardCore } from "../../cores/boards";
import { NoData } from "../component/NoData";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { useIntl } from "react-intl";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useMeCore } from "../../cores/me";
import BoardCard from "../component/boards/BoardCard";
import { BoardType } from "../../cores/boards/constants/board-type.constant";
import { useRouterCore } from "../../cores/router";
import CreateBoard from "../component/boards/modal/CreateBoard";
import {
  BOARD_CREATE_CONFIG,
  MORE_INFO_HEADER_ACTIVITY,
} from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { YoutubeModal } from "../component/YoutubeModal";

export const ActivityBoardsScreen = () => {
  const intl = useIntl();
  const { turnOffModal, turnOnModal, getModalVisibility, isModal } =
    useGlobalCore();
  const { company, companyPlan, companyRole, isAdmin, isReseller } =
    useMeCore();
  const { boards, getActivityBoards } = useBoardCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    if (
      !companyPlan?.rules.allowActivityBoard ||
      (!companyRole?.activities &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  const toggleSideModal = useCallback(() => {
    turnOnModal(BOARD_CREATE_CONFIG, true);
  }, [turnOnModal]);

  useEffect(() => {
    getActivityBoards(company?.id!);
  }, [company, getActivityBoards]);

  useEffect(() => {}, [boards]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.activity.title" })}
        showHelp
        modalHelpAction={()=>turnOnModal(MORE_INFO_HEADER_ACTIVITY, true)}
        rightChildren={
          <>
            {boards.length !== 0 && (
              <Button className="whiteButton" onClick={toggleSideModal}>
                {intl.formatMessage({ id: "app.activity.createBoard" })}
              </Button>
            )}
          </>
        }
      />
      <Content>
        <Container fluid>
          <UpdateLoadingCard
            loadingId={BOARDS_LOADING}
            text={intl.formatMessage({ id: "app.activity.updatingBoard" })}
          />
          {!boards.length && (
            <NoData
              image="generic4"
              text={intl.formatMessage({ id: "app.activity.notHaveBoard" })}
              actionLabel={intl.formatMessage({
                id: "app.activity.createBoard",
              })}
              action={toggleSideModal}
              horizontal
            />
          )}
          <Row>
            {boards.map((board) => (
              <Col lg="3" md="4" sm="6" xs="12" key={board.id} className="mb-4">
                <BoardCard board={board} />
              </Col>
            ))}
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(BOARD_CREATE_CONFIG)}
        visible={getModalVisibility(BOARD_CREATE_CONFIG)}
        onClose={() => {
          turnOffModal(BOARD_CREATE_CONFIG);
        }}
      >
        <CreateBoard
          type={BoardType.ACTIVITY}
          onSuccess={() => {
            turnOffModal(BOARD_CREATE_CONFIG);
          }}
        />
      </SideModal>

      <SideModal
        active={isModal(MORE_INFO_HEADER_ACTIVITY)}
        visible={getModalVisibility(MORE_INFO_HEADER_ACTIVITY)}
        onClose={() => {
          turnOffModal(MORE_INFO_HEADER_ACTIVITY);
        }}
      >
        <YoutubeModal
          title={intl.formatMessage({id:"app.activity.helpVideoTitle"})}
          
          videoId="VGgMWaTh2eo"
        />
      </SideModal>
    </Layout>
  );
};
