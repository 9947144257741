import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { LinkedInBusinessSetting } from "../interface/api/post-linkedinBussiness-bind";
import { Organization } from "../interface/api/post-linkedinBussiness-organizations";

export const postUnbindLinkedInBusinessInitAction = createAction(
  "[Linked In Business] Unbind Linked In Business Init"
);
export const postUnbindLinkedInBusinessSuccessAction = createAction(
  "[Linked In Business] Unbind Linked In Business Success"
);
export const postUnbindLinkedInBusinessFailureAction = createAction(
  "[Linked In Business] Unbind Linked In Business Failure",
  props<{ error: string }>()
);

export const postBindLinkedInBusinessInitAction = createAction(
  "[Linked In Business] Bind Linked In Business Init"
);
export const postBindLinkedInBusinessSuccessAction = createAction(
  "[Linked In Business] Bind Linked In Business Success",
  props<{ settings: LinkedInBusinessSetting }>()
);
export const postBindLinkedInBusinessFailureAction = createAction(
  "[Linked In Business] Bind Linked In Business Failure",
  props<{ error: string }>()
);

export const getLinkedInBusinessInitAction = createAction(
  "[Linked In Business] Get Linked In Business Settings Init"
);
export const getLinkedInBusinessSuccessAction = createAction(
  "[Linked In Business] Get Linked In Business Settings Success",
  props<{ settings: LinkedInBusinessSetting }>()
);
export const getLinkedInBusinessFailureAction = createAction(
  "[Linked In Business] Get Linked In Business Settings Failure",
  props<{ error: string }>()
);

export const postOrganizationsLinkedInBusinessInitAction = createAction(
  "[Linked In Business] Fetch Linked In Business Organizations Init"
);
export const postOrganizationsLinkedInBusinessSuccessAction = createAction(
  "[Linked In Business] Fetch Linked In Business Organizations Success",
  props<{ organizations: Organization[] }>()
);
export const postOrganizationsLinkedInBusinessFailureAction = createAction(
  "[Linked In Business] Fetch Linked In Business Organizations Failure",
  props<{ error: string }>()
);

export const postLinkedInBusinessSocialPostInitAction = createAction(
  "[LinkedInBusiness] Social Post Init"
);
export const postLinkedInBusinessSocialPostSuccessAction = createAction(
  "[LinkedInBusiness] Social Post Success"
);
export const postLinkedInBusinessSocialPostFailureAction = createAction(
  "[LinkedInBusiness] Social Post Failure",
  props<{ error: string }>()
);

export const putLinkedInBusinessSocialPostInitAction = createAction(
  "[LinkedInBusiness] Social Put Init"
);
export const putLinkedInBusinessSocialPostSuccessAction = createAction(
  "[LinkedInBusiness] Social Put Success"
);
export const putLinkedInBusinessSocialPostFailureAction = createAction(
  "[LinkedInBusiness] Social Put Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindLinkedInBusinessInitAction,
  postUnbindLinkedInBusinessSuccessAction,
  postUnbindLinkedInBusinessFailureAction,
  postBindLinkedInBusinessInitAction,
  postBindLinkedInBusinessSuccessAction,
  postBindLinkedInBusinessFailureAction,
  getLinkedInBusinessInitAction,
  getLinkedInBusinessSuccessAction,
  getLinkedInBusinessFailureAction,
  postOrganizationsLinkedInBusinessInitAction,
  postOrganizationsLinkedInBusinessSuccessAction,
  postOrganizationsLinkedInBusinessFailureAction,
  postLinkedInBusinessSocialPostInitAction,
  postLinkedInBusinessSocialPostSuccessAction,
  postLinkedInBusinessSocialPostFailureAction,
  putLinkedInBusinessSocialPostInitAction,
  putLinkedInBusinessSocialPostSuccessAction,
  putLinkedInBusinessSocialPostFailureAction,
});

export type LinkedInBusinessApiAction = typeof actions;
