import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const contactRoot = (state: State) => state.contact;

export const contactsSelector = createSelector(contactRoot, ({ contacts }) =>
  contacts.ids.map((id) => contacts.entities[id])
);

export const selectedContactsSelector = createSelector(
  contactRoot,
  ({ selectedContacts }) =>
    selectedContacts.ids.map((id) => selectedContacts.entities[id])
);

export const contactTagsSelector = createSelector(contactRoot, ({ tags }) =>
  tags.ids.map((id) => tags.entities[id])
);

export const contactStatesSelector = createSelector(contactRoot, ({ states }) =>
  states.ids.map((id) => states.entities[id])
);

export const companiesSelector = createSelector(contactRoot, ({ companies }) =>
  companies.ids.map((id) => companies.entities[id])
);

export const contactCompaniesSelector = createSelector(
  contactRoot,
  ({ contactCompanies }) =>
    contactCompanies.ids.map((id) => contactCompanies.entities[id])
);

export const contactEmailsSelector = createSelector(
  contactRoot,
  ({ contactEmails }) =>
    contactEmails.ids.map((id) => contactEmails.entities[id])
);

export const contactCardsSelector = createSelector(
  contactRoot,
  ({ contactCards }) => contactCards.ids.map((id) => contactCards.entities[id])
);

export const contactCommentsSelector = createSelector(
  contactRoot,
  ({ contactComments }) =>
    contactComments.ids.map((id) => contactComments.entities[id])
);

export const customFieldValuesSelector = createSelector(
  contactRoot,
  ({ customFieldValues }) =>
    customFieldValues.ids.map((id) => customFieldValues.entities[id])
);

export const customFieldsSelector = createSelector(
  contactRoot,
  ({ customFields }) => customFields.ids.map((id) => customFields.entities[id])
);

export const configSelector = createSelector(contactRoot, ({ config }) =>
  config.ids.map((id) => config.entities[id])
);

export const totalContactsSelector = createSelector(
  contactRoot,
  ({ total }) => total
);

export const headersSelector = createSelector(
  contactRoot,
  ({ headers }) => headers
);

export const sourceSelector = createSelector(
  contactRoot,
  ({ source }) => source
);

export const contactFiltersSelector = createSelector(
  contactRoot,
  ({ contactFilters }) => contactFilters
);

export const companyFiltersSelector = createSelector(
  contactRoot,
  ({ companyFilters }) => companyFilters
);

export const companySelector = createSelector(
  contactRoot,
  ({ company }) => company
);

export const contactSelector = createSelector(
  contactRoot,
  ({ contact }) => contact
);

export const massAssignmentSelector = createSelector(
  contactRoot,
  ({ massAssignment }) => massAssignment
);

export const replaceAssignmentSelector = createSelector(
  contactRoot,
  ({ replaceAssigment }) => replaceAssigment
);

export const contactStateRequestSelector = createSelector(
  contactRoot,
  ({ contactStateRequest }) => contactStateRequest
);
