import socialApiClient from "../../helpers/services/social-api-client";
import { GetInstagramAccounts } from "../interface/api/get-instagram-accounts";
import { GetInstagramBindResponse } from "../interface/api/get-instagram-bind";
import { PostInstagramBindRequest, PostInstagramBindResponse } from "../interface/api/post-instagram-bind"
import { instagramSettingMapper } from "./mapper";

export const instagramMenssegerApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`instagram/mensseger/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },

  bind(companyId: string, body: PostInstagramBindRequest) {
    return socialApiClient()
      .post<PostInstagramBindResponse>(`instagram/mensseger/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        instagramSettingMapper.mapResponseToInstagramSetting(data)
      );
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetInstagramBindResponse>(`instagram/mensseger`, {
        params: { companyId },
      })
      .then(({ data }) =>
        instagramSettingMapper.mapResponseToInstagramSetting(data)
      );
  },
  accounts(companyId: string, token: string) {
    return socialApiClient()
      .get<GetInstagramAccounts>(`instagram/mensseger/accounts`, {
        params: { companyId, token },
      })
      .then(({ data }) =>
        instagramSettingMapper.mapResponseToInstagramAccounts(data)
      );
  },
};
