import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Props {
  currency: string;
}

export const Currency = (props: Props) => {
  const { currency } = props;
  return (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon={["fas", "coins"]} size="lg" className="mr-2" />
      <div className="h6 mb-0">{currency}</div>
    </div>
  );
};
