import cn from "classnames";
import React, { ReactNode, useCallback, useState } from "react";
import { TwitterPicker } from "react-color";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputProps,
} from "reactstrap";
import { LabelWembii } from "./LabelWembii";

interface Props extends InputProps {
  className?: string;
  label?: string;
  name?: string;
  id?: string;
  flat?: boolean;
  disabled?: boolean;
  showShadow?: boolean;
  required?: boolean;
  mb?: boolean;
  rounded?: boolean;
  icon?: ReactNode;
  prepend?: ReactNode;
  invalid?: boolean;
  iconPosition?: "left" | "right";
  style?: object;
  validateTxt?: (arg0: string) => boolean;
  onChange?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onClick?: (e: any) => void;
}

export const InputWembii = (props: Props) => {
  const {
    className,
    id,
    label,
    min,
    max,
    type,
    flat,
    showShadow,
    rounded,
    required,
    value,
    icon,
    iconPosition = "right",
    disabled = false,
    onChange,
    onKeyUp,
    onClick,
    validateTxt,
    mb = true,
    prepend,
    style = {},
    invalid = false,
    ...attributes
  } = props;
  const [showPicker, setShowPicker] = useState(false);

  const handleChangeColor = useCallback(
    (color) => {
      setShowPicker(false);
      onChange?.(color.hex);
    },
    [onChange]
  );

  const handleOnChange = useCallback(
    (value: string) => {
      if (validateTxt) {
        if (validateTxt(value)) onChange?.(value);
      } else {
        onChange?.(value);
      }
    },
    [validateTxt, onChange]
  );

  return (
    <FormGroup
      className={cn("d-flex flex-column position-relative", className, {
        "m-0": !mb,
      })}
    >
      {label && (
        <LabelWembii forid={id} required={required}>
          {label}
        </LabelWembii>
      )}
      <InputGroup>
        {prepend && (
          <InputGroupAddon addonType="prepend">{prepend}</InputGroupAddon>
        )}
        <Input
          id={id}
          min={min}
          max={max}
          type={type === "color" ? "text" : type}
          {...attributes}
          style={{
            borderRadius: rounded ? 15 : 10,
            ...style,
          }}
          onKeyUp={onKeyUp}
          disabled={disabled}
          invalid={invalid}
          className={cn(className, {
            "border-top-0": flat,
            "border-right-0": flat,
            "border-left-0": flat,
            "rounded-0": flat,
            "shadow-sm": showShadow,
            "pr-5": icon && iconPosition === "right",
            "pl-5": icon && iconPosition === "left",
          })}
          autoComplete="off"
          value={value}
          onChange={(e) => handleOnChange?.(e.target.value)}
          onClick={(e: any) => {
            type === "color" ? setShowPicker(true) : onClick?.(e);
          }}
        />
        {icon && (
          <div
            className={cn(
              "position-absolute p-1 h-100 d-flex align-items-center z-index-5",
              {
                "right-9": iconPosition === "right",
                "left-9": iconPosition === "left",
              }
            )}
          >
            {icon}
          </div>
        )}

        {type === "color" && showPicker ? (
          <div
            style={{
              position: "absolute",
              zIndex: 50,
              marginTop: 50,
            }}
          >
            <div
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
              }}
              onClick={() => setShowPicker(false)}
            />
            <TwitterPicker onChange={(c) => handleChangeColor(c)} />
          </div>
        ) : null}
      </InputGroup>
    </FormGroup>
  );
};
