import React, { useCallback, useState } from "react";
import { useContactCore } from "../../../cores/contacts";
import { useMeCore } from "../../../cores/me";
import { CheckboxWembii } from "../CheckboxWembii";
import { LoadingSpinnerWembii } from "../LoadingSpinnerWembii";

interface Props {
  label: string;
  checked: boolean;
  field: string;
  customField: boolean;
}

export const ConfigRow = (props: Props) => {
  const { label, checked, field, customField } = props;
  const { config, postContactConfig, deleteContactConfig } = useContactCore();
  const { company } = useMeCore();
  const [loading, setLoading] = useState(false);

  const toggleFieldConfig = useCallback(async (field: string, customField: boolean, value: boolean) => {
    setLoading(true);
    if (value) {
      await postContactConfig(company?.id!, { field, customField });
    } else {
      const conf = config.find((config) => config.field === `${field}`);
      await deleteContactConfig(company?.id!, conf?.id!);
    }

    setLoading(false);
  }, [company, config, postContactConfig, deleteContactConfig]);

  return (
    <div className="d-flex justify-content-between align-items-center my-1">
      {label}

      {loading ? (
        <div className="mr-2">
          <LoadingSpinnerWembii active size="xs" />
        </div>
      ) : (
        <CheckboxWembii
          mb={false}
          checked={checked}
          onChange={(v) => toggleFieldConfig(field, customField, v)}
        />
      )}
    </div>
  );
};
