import React from "react";
import { Nav, NavbarBrand, Media } from "reactstrap";
import logo from "../../../assets/wembii-logo.png";

import SidebarProtectorRoutes from "./SidebarProtectorRoutes";

const SidebarNavigation = () => {
  return (
    <Nav vertical>
      <NavbarBrand href="/social" className="m-0">
        <Media
          className="w-100"
          object
          src={logo}
          alt="Generic placeholder image"
        />
      </NavbarBrand>
      <hr className="horizontal-separator my-2" />
      <SidebarProtectorRoutes />
    </Nav>
  );
};

export default SidebarNavigation;
