import React, { useCallback, useMemo } from "react";
import { Container } from "reactstrap";
import { COLOR_SECONDARY } from "../../../constants/theme";
import { useAnalyticsCore } from "../../../cores/analytics";
import { ChartMetric } from "../../../cores/analytics/interface/ChartMetric";
import { Metric2 } from "../../../cores/analytics/interface/Metric2";
import { ChartContainer } from "./ChartContainer";
import { BarChart } from "./charts/BarChart";
import { PieChart } from "./charts/PieChart";
import { Legend } from "./Legend";
import { Monitors } from "./Monitors";
import { useIntl } from "react-intl";

export const FacebookCharts = () => {
  const intl = useIntl();
  const { facebookInsights, toChartMetric } = useAnalyticsCore();

  const impressionData: Metric2[] = useMemo(
    () => [{ ...facebookInsights.page_impressions, type: "line" }],
    [facebookInsights.page_impressions]
  );

  const impressionDataMonitors: Metric2[] = useMemo(
    () => [
      { ...facebookInsights.page_impressions, type: "line" },
      { ...facebookInsights.page_fans, type: "bar", color: COLOR_SECONDARY },
    ],
    [facebookInsights.page_impressions, facebookInsights.page_fans]
  );

  const activityData: Metric2[] = useMemo(
    () => [
      { ...facebookInsights.page_post_engagements, type: "line" },
      { ...facebookInsights.page_impressions_unique, type: "line" },
      { ...facebookInsights.page_positive_feedback_by_type, type: "line" },
      {
        ...facebookInsights.page_total_actions,
        type: "line",
      },
      {
        ...facebookInsights.page_views_total,
        type: "line",
      },
    ],
    [
      facebookInsights.page_post_engagements,
      facebookInsights.page_impressions_unique,
      facebookInsights.page_positive_feedback_by_type,
      facebookInsights.page_total_actions,
      facebookInsights.page_views_total,
    ]
  );

  const activityDataMonitors: Metric2[] = useMemo(
    () => [
      { ...facebookInsights.page_post_engagements, type: "line" },
      { ...facebookInsights.page_impressions_unique, type: "line" },
      { ...facebookInsights.page_positive_feedback_by_type, type: "line" },
      {
        ...facebookInsights.page_total_actions,
        type: "line",
      },
      {
        ...facebookInsights.page_views_total,
        type: "line",
      },
      { ...facebookInsights.page_fans, type: "bar", color: COLOR_SECONDARY },
    ],
    [
      facebookInsights.page_post_engagements,
      facebookInsights.page_impressions_unique,
      facebookInsights.page_positive_feedback_by_type,
      facebookInsights.page_total_actions,
      facebookInsights.page_views_total,
      facebookInsights.page_fans,
    ]
  );

  const reactionData: Metric2[] = useMemo(
    () => [
      {
        ...facebookInsights.page_actions_post_reactions_anger_total,
        type: "line",
      },
      {
        ...facebookInsights.page_actions_post_reactions_haha_total,
        type: "line",
      },
      {
        ...facebookInsights.page_actions_post_reactions_like_total,
        type: "line",
      },
      {
        ...facebookInsights.page_actions_post_reactions_love_total,
        type: "line",
      },
      {
        ...facebookInsights.page_actions_post_reactions_sorry_total,
        type: "line",
      },
    ],
    [
      facebookInsights.page_actions_post_reactions_anger_total,
      facebookInsights.page_actions_post_reactions_haha_total,
      facebookInsights.page_actions_post_reactions_like_total,
      facebookInsights.page_actions_post_reactions_love_total,
      facebookInsights.page_actions_post_reactions_sorry_total,
    ]
  );

  const fansCitiesData: Metric2 = useMemo(
    () => ({
      ...facebookInsights.page_fans_city,
      type: "pie",
    }),
    [facebookInsights.page_fans_city]
  );

  const fansCountryData: Metric2 = useMemo(
    () => ({
      ...facebookInsights.page_fans_country,
      type: "pie",
    }),
    [facebookInsights.page_fans_country]
  );

  // const fansGenderAgeData: Metric2 = useMemo(
  //   () => ({
  //     ...facebookInsights.page_fans_gender_age,
  //     type: "horizontalBar",
  //   }),
  //   [facebookInsights.page_fans_gender_age]
  // );

  const impressionChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(impressionData),
    [impressionData, toChartMetric]
  );

  const activityChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(activityData),
    [activityData, toChartMetric]
  );

  const reactionChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(reactionData),
    [reactionData, toChartMetric]
  );

  const fansCitiesChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([fansCitiesData]),
    [fansCitiesData, toChartMetric]
  );

  const fansCountryChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([fansCountryData]),
    [fansCountryData, toChartMetric]
  );

  // const fansGenderAgeChartMetrics: ChartMetric = useMemo(
  //   () => toChartMetric([fansGenderAgeData]),
  //   [fansGenderAgeData, toChartMetric]
  // );

  const impressionMonitor = useCallback(
    () => <Monitors metrics={impressionDataMonitors} />,
    [impressionDataMonitors]
  );

  const activityMonitor = useCallback(
    () => <Monitors metrics={activityDataMonitors} />,
    [activityDataMonitors]
  );

  const reactionMonitor = useCallback(
    () => <Monitors metrics={reactionData} />,
    [reactionData]
  );

  return (
    <Container>
      <ChartContainer
        id="facebookChart"
        height={250}
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsFace.title1" })}
        monitors={impressionMonitor()}
      >
        <BarChart
          labels={impressionChartMetrics.labels}
          datasets={impressionChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="facebookChart"
        height={250}
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsFace.title2" })}
        monitors={activityMonitor()}
      >
        <BarChart
          labels={activityChartMetrics.labels}
          datasets={activityChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="facebookChart"
        height={250}
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsFace.title3" })}
        monitors={reactionMonitor()}
      >
        <BarChart
          labels={reactionChartMetrics.labels}
          datasets={reactionChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="facebookChart"
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsFace.title4" })}
        height={300}
        legend={<Legend metric={fansCitiesData} />}
        isEmptyData={
          !fansCitiesData.values || fansCitiesData.values?.length === 0
        }
      >
        <PieChart
          labels={fansCitiesChartMetrics.labels}
          datasets={fansCitiesChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="facebookChart"
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsFace.title5" })}
        height={300}
        legend={<Legend metric={fansCountryData} />}
        isEmptyData={
          !fansCountryData.values || fansCountryData.values?.length === 0
        }
      >
        <PieChart
          labels={fansCountryChartMetrics.labels}
          datasets={fansCountryChartMetrics.datasets}
        />
      </ChartContainer>
      {/* <ChartContainer
        deprecated
        id="facebookChart"
        height={300}
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsFace.title6" })}
        legend={<Legend metric={fansGenderAgeData} />}
        isEmptyData={
          !fansGenderAgeData.values || fansGenderAgeData.values?.length === 0
        }
      >
        <BarChart
          colorByBar
          horizontal
          labels={fansGenderAgeChartMetrics.labels}
          datasets={fansGenderAgeChartMetrics.datasets}
        />
      </ChartContainer> */}
    </Container>
  );
};
