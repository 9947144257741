import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const automationRoot = (state: State) => state.automation;

export const automationsSelector = createSelector(
  automationRoot,
  ({ automations }) => automations.ids.map((id) => automations.entities[id])
);

export const automationSelector = createSelector(
  automationRoot,
  ({ automation }) => automation
);

export const actionsSelector = createSelector(automationRoot, ({ actions }) =>
  actions.ids.map((id) => actions.entities[id])
);

export const conditionsSelector = createSelector(
  automationRoot,
  ({ conditions }) => conditions.ids.map((id) => conditions.entities[id])
);

export const contactsSelector = createSelector(automationRoot, ({ contacts }) =>
  contacts.ids.map((id) => contacts.entities[id])
);
export const usersSelector = createSelector(
  automationRoot,
  ({ automationUsers }) =>
    automationUsers.ids.map((id) => automationUsers.entities[id])
);
