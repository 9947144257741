import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Label } from "reactstrap";
import {
  BOARD_FILES_MODAL,
  BOARD_FILE_PREVIEW_MODAL,
  DELETE_BOARD_ITEM_MODAL
} from "../../../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useBoardCore } from "../../../../cores/boards";
import { Board } from "../../../../cores/boards/interfaces/api/get-board";
import { File } from "../../../../cores/boards/interfaces/api/get-card";
import { useGlobalCore } from "../../../../cores/globals";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import AreYouSure from "../../AreYouSure";
import { FileInfoPreview } from "../modal/FileInfoPreview";
import FileSelector from "../modal/FileSelector";
import FilePreview from "../preview/FilePreview";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";

interface Props {
  canModify: boolean;
  board: Board | null;
}

export default function AttachmentContainer({ canModify, board }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const { card, files, postFiles, deleteFile, getCard } = useBoardCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const openFileSelector = useCallback(() => {
    if (canModify)
      turnOnModal(
        BOARD_FILES_MODAL,
        <FileSelector board={board} onSubmit={(files) => {
          postFiles(
            company?.id!,
            board?.id!,
            card?.list!,
            card?.id!,
            files,
            () => {
              getCard(company?.id!, board?.id!, card?.list!, card?.id!);
              turnOffModal(BOARD_FILES_MODAL)
            },
          )
        }} />,
        Position.LEFT
      );
  }, [
    turnOnModal,
    turnOffModal,
    postFiles,
    getCard,
    company,
    board,
    card,
    canModify
  ]);

  const openFilePreview = useCallback((file) => {
    turnOnModal(
      BOARD_FILE_PREVIEW_MODAL,
      <FileInfoPreview file={file} />,
      Position.LEFT
    );
  }, [turnOnModal]);

  const onDelete = useCallback((file: File) => {
    turnOnModal(
      DELETE_BOARD_ITEM_MODAL,
      <AreYouSure
        message={
         `${intl.formatMessage({ id: "app.activity.deleteFile" })} 
          ${file.key}?`
        }
        onSubmit={() => {
          deleteFile(
            company?.id!,
            board?.id!,
            card?.list!,
            card?.id!,
            file.id,
            () => {
              getCard(company?.id!, board?.id!, card?.list!, card?.id!);
            }
          );
          turnOffModal(DELETE_BOARD_ITEM_MODAL);
        }}
        onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, deleteFile, getCard, company, board, card, intl]);

  return (
    <div className="py-2">
      <div className="w-100">
        <FontAwesomeIcon
          icon={["fas", "paperclip"]}
          size="1x"
          className="mr-2"
          color="gray"
        />
        <Label forid="comment" className="font-weight-bold">
          {intl.formatMessage({ id: "app.activity.attachments" })}
        </Label>

        {files.length === 0 ? (
          <Label
            className="w-100 p-3 text-muted d-flex justify-content-center align-items-center bg-light pointer"
            onClick={openFileSelector}
          >
            <FontAwesomeIcon
              icon={["far", "file"]}
              size="2x"
              className="mr-2"
              color="gray"
            />
            {intl.formatMessage({ id: "app.activity.notSelectFile" })}
          </Label>
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            {files.map((file) => (
              <FilePreview
                key={file.key}
                file={file}
                canModify={canModify}
                onPreview={openFilePreview}
                onDelete={onDelete}
              />
            ))}
            {canModify && (
              <div className="panelFilePreview neu-out m-2 pointer" onClick={openFileSelector}>
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  size="2x"
                  color={COLOR_PRIMARY}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}