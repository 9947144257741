import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const userRoot = (state: State) => state.user;

export const userSelector = createSelector(
  userRoot,
  (user) => user.lastUserCreated
);

export const userSearchedSelector = createSelector(
  userRoot,
  (user) => user.user
);

export const usersSelector = createSelector(userRoot, (user) =>
  user.ids.map((id) => user.entities[id])
);

export const totalUserSelector = createSelector(userRoot, (user) =>
  user.total
);
