import { ConversationsPayload } from "../interfaces/api/conversation-payload";
import { GetFacebookMessagesResponse } from "../interfaces/api/get-facebook-messages";
import { GetInstagramConversationsResponse } from "../interfaces/api/get-instagram-conversations";
import {
  GetInstagramMessagesResponse,
  MessagesPayload,
} from "../interfaces/api/get-instagram-messages";
import { GetWhatsappMessagesResponse } from "../interfaces/api/get-whatsapp-messages";
import {
  MessagePayload,
  PostInstagramMessageResponse,
} from "../interfaces/api/post-instagram-message";

export const socialChatMapper = {
  mapResponseToConversations({
    payload,
  }: GetInstagramConversationsResponse): ConversationsPayload {
    return {
      active: payload.active,
      archived: payload.archived,
      inTrash: payload.inTrash,
    };
  },

  mapResponseToMessages(
    response:
      | GetInstagramMessagesResponse
      | GetFacebookMessagesResponse
      | GetWhatsappMessagesResponse
  ): MessagesPayload {
    return { messages: response.payload.messages };
  },
  mapResponseToMessage(response: PostInstagramMessageResponse): MessagePayload {
    return { message: response.payload.message, id: response.payload.id };
  },
};
