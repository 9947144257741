import {
  createAction,
  joinActions,
} from "../../helpers/store/actions-helpers";

export const resetCouponAction = createAction(
  "[Payment] Reset coupon"
);

const actions = joinActions({
  resetCouponAction
});

export type PaymentAction = typeof actions;
