import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Nav, NavLink,  NavItem, Row, TabContent, TabPane } from "reactstrap";
import { CONTACT_COMPANY_CONTACTS_MODAL, CONTACT_COMPANY_EXPAND_EDIT_FORM } from "../../../../constants/modal.constant";
import { useContactCore } from "../../../../cores/contacts";
import { useGlobalCore } from "../../../../cores/globals";
import { InputWembii } from "../../InputWembii";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { LabelWembii } from "../../LabelWembii";
import { ContactRow } from "../ContactRow";
import ContactList from "../../boards/modal/ContactList";
import AssignmentForm from "../AssignmentForm";
import { Contact } from "../../../../cores/contacts/interfaces/api/get-contacts";
import PhoneInput from "react-phone-number-input";
import { isUrl } from "../../../utils/stringUtils";
import InternalModal from "../../../layout/InternalModal";
import { ENTERPRISE_CONTACT_ASSIGNMENT } from "../../../../constants/internalModal.constant";
import { CompanyOwner } from "../../../../cores/contacts/interfaces/api/get-company";
import OwnerContainer from "../../contacts/OwnerContainer";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";

interface Props {
  onDelete: () => void;
  onUpdate?: () => void;
  isUnassignedButton?: boolean;
}

export default function EditCompanyForm(props: Props) {
  const intl = useIntl();
  const { onDelete, onUpdate, isUnassignedButton = false } = props;
  const { turnOnModal, turnOffModal, isInternalModal, turnOffInternalModal, turnOnInternalModal } = useGlobalCore();
  const { company, isAdmin, isReseller, companyRole, user } = useMeCore();
  const { putCompany, getCompany, company: contactCompany, customFields, customFieldValues } = useContactCore();

  const [contact, setContact] = useState<Contact | null>(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [rut, setRut] = useState("");
  const [website, setWebsite] = useState("");
  const [sector, setSector] = useState("");
  const [employees, setEmployees] = useState("0");
  const [owners, setOwners] = useState<CompanyOwner[]>([]);
  const [activeTab, setActiveTab] = useState("1");
  const [fields, setFields] = useState<any>({});

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    const ownerIds = owners.map((owner) => owner.id);

    putCompany(
      company?.id!,
      contactCompany?.id!, {
        name,
        address,
        city,
        country,
        phone,
        rut,
        website,
        sector,
        employees: parseInt(employees),
        customFields: fields,
        owners: ownerIds
      },
      () => {
        turnOffModal(CONTACT_COMPANY_EXPAND_EDIT_FORM);
        onUpdate?.();
      }
    );
  };

  const isUrlInvalid = useMemo(() =>
    !!website && !isUrl(website)
    , [website]
  );

  const disabled = useCallback(
    () => !name || isUrlInvalid,
    [name, isUrlInvalid]
  );

  const disabled2 = useCallback(
    () =>
      name === contactCompany?.name &&
      address === contactCompany?.address &&
      city === contactCompany?.city &&
      country === contactCompany?.country &&
      website === contactCompany?.website &&
      sector === contactCompany?.sector &&
      phone === contactCompany?.phone &&
      rut === contactCompany?.rut &&
      parseInt(employees) === contactCompany?.employees &&
      owners === contactCompany?.owners,
    [
      contactCompany,
      name,
      address,
      city,
      country,
      website,
      sector,
      phone,
      owners,
      employees,
      rut
    ]
  );

  const selectContact = useCallback((contact: Contact) => {
    turnOnInternalModal(ENTERPRISE_CONTACT_ASSIGNMENT, true);
    setContact(contact);
  }, [turnOnInternalModal]);

  useEffect(() => {
    if (contactCompany) {
      setName(contactCompany.name);
      setAddress(contactCompany.address || "");
      setCity(contactCompany.city || "");
      setCountry(contactCompany.country || "");
      setSector(contactCompany.sector || "");
      setWebsite(contactCompany.website || "");
      setPhone(contactCompany.phone || "");
      setRut(contactCompany.rut || "");
      setEmployees(`${contactCompany.employees}`);
      setOwners(contactCompany.owners);
    }
  }, [contactCompany]);

  const onAddingContact = useCallback(() => {
    turnOnModal(
      CONTACT_COMPANY_CONTACTS_MODAL,
      <ContactList
        assignments={contactCompany?.contacts || []}
        onAssign={(contact) => {
          selectContact(contact);
          turnOffModal(CONTACT_COMPANY_CONTACTS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    selectContact,
    contactCompany?.contacts,
  ]);

  const onSuccess = useCallback(() => {
    getCompany(company?.id!, contactCompany?.id!);
    onUpdate?.();
  }, [getCompany, onUpdate, company, contactCompany]);

  const onAddingOwner = useCallback((user) => {
    setOwners([
      ...owners,
      user
    ]);
  }, [owners]);

  const onRemovingOwner = useCallback((id) => {
    const newOwners = owners.filter((owner) => owner.id !== id);
    setOwners(newOwners);
  }, [owners]);

  const onSettingField = useCallback((value: string, field: string) => {
    const newFields = { ...fields };
    newFields[`${field}`] = value;
    setFields(newFields);
  }, [fields]);

  useEffect(() => {
    customFields.forEach((field) => {
      fields[`${field.id}`] = "";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);

  useEffect(() => {
    customFieldValues.forEach((field) => {
      fields[`${field.customFieldId}`] = field.value;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFieldValues]);

  const canModify = useMemo(() => 
    isAdmin ||
    isReseller ||
    companyRole?.owner ||
    companyRole?.admin ||
    contactCompany?.owners.findIndex((owner) => owner.id === user?.id) !== -1 ||
    contactCompany?.owners.length === 0
  , [isAdmin, isReseller, companyRole, contactCompany, user]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(ENTERPRISE_CONTACT_ASSIGNMENT)}
        onClose={() => turnOffInternalModal(ENTERPRISE_CONTACT_ASSIGNMENT)}
      >
        <AssignmentForm
          contact={contact!}
          onSuccess={onSuccess}
        />
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            {!canModify && (
              <span className="text-muted w-100 d-flex justify-content-end">
                {intl.formatMessage({ id: "app.crmBusiness.readOnly" })}
              </span>
            )}
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 text-center mb-4">
                <FontAwesomeIcon icon={["far", "building"]} className="mr-2" />
                {intl.formatMessage({ id: "app.contacts.companies.company" })}
              </h2>

              <Nav tabs className="justify-content-center mt-2">
                <NavItem>
                  <NavLink
                    className={cn("pointer", { active: activeTab === "1" })}
                    onClick={() => { setActiveTab("1") }}
                  >
                    {intl.formatMessage({ id: "app.contacts.generalInformation" })}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={cn("pointer", { active: activeTab === "2" })}
                    onClick={() => { setActiveTab("2") }}
                  >
                    {intl.formatMessage({ id: "app.contacts.title" })}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={cn("pointer", { active: activeTab === "3" })}
                    onClick={() => { setActiveTab("3") }}
                  >
                    {intl.formatMessage({ id: "app.contacts.customFields" })}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Form className="px-2 mt-2" onSubmit={onFormSubmit}>
                    <div className="mb-2">
                      <LabelWembii>
                        {intl.formatMessage({ id: "app.contacts.owners" })}
                      </LabelWembii>
                      <OwnerContainer
                        contacts={owners}
                        canModify={canModify}
                        onAdding={onAddingOwner}
                        onRemoving={onRemovingOwner}
                        />
                    </div>
                    <InputWembii
                      label={intl.formatMessage({ id: "app.contacts.companies.name" })}
                      type="text"
                      name="name"
                      id="company-name"
                      disabled={!canModify}
                      required
                      placeholder={intl.formatMessage({ id: "app.contacts.companies.name" })}
                      value={name}
                      onChange={setName}
                    />
                    <InputWembii
                      label="RUT"
                      type="text"
                      name="sector"
                      id="company-rut" 
                      placeholder="RUT"
                      value={rut}
                      onChange={setRut}
                    />
                    <InputWembii
                      label={intl.formatMessage({ id: "app.contacts.companies.sector" })}
                      type="text"
                      name="sector"
                      disabled={!canModify}
                      id="company-sector" 
                      placeholder={intl.formatMessage({ id: "app.contacts.companies.sector" })}
                      value={sector}
                      onChange={setSector}
                    />
                    <InputWembii
                      label={intl.formatMessage({ id: "app.contacts.companies.country" })}
                      type="text"
                      name="country"
                      disabled={!canModify}
                      id="company-country" 
                      placeholder={intl.formatMessage({ id: "app.contacts.companies.country" })}
                      value={country}
                      onChange={setCountry}
                    />
                    <InputWembii
                      label={intl.formatMessage({ id: "app.contacts.companies.city" })}
                      type="text"
                      name="city"
                      disabled={!canModify}
                      id="company-city" 
                      placeholder={intl.formatMessage({ id: "app.contacts.companies.city" })}
                      value={city}
                      onChange={setCity}
                    />
                    <InputWembii
                      label={intl.formatMessage({ id: "app.contacts.companies.address" })}
                      type="text"
                      name="address"
                      disabled={!canModify}
                      id="company-address" 
                      placeholder={intl.formatMessage({ id: "app.contacts.companies.address" })}
                      value={address}
                      onChange={setAddress}
                    />
                    <FormGroup>
                      <LabelWembii forid="company-phone">
                        {intl.formatMessage({ id: "app.contacts.companies.phone" })}
                      </LabelWembii>
                      <PhoneInput
                        name="phone"
                        disabled={!canModify}
                        id="company-ohone"
                        placeholder={intl.formatMessage({ id: "app.contacts.companies.phone" })}
                        value={phone}
                        onChange={(v) => setPhone(v || "")}
                      />
                    </FormGroup>
                    <InputWembii
                      label={intl.formatMessage({ id: "app.contacts.companies.website" })}
                      type="text"
                      name="website"
                      disabled={!canModify}
                      id="company-website"
                      invalid={isUrlInvalid}
                      placeholder={intl.formatMessage({ id: "app.contacts.companies.website" })}
                      value={website}
                      onChange={setWebsite}
                    />
                    <InputWembii
                      label={intl.formatMessage({ id: "app.contacts.companies.employees" })}
                      type="number"
                      name="employees"
                      disabled={!canModify}
                      id="company-employees"
                      className="w-50"
                      placeholder={intl.formatMessage({ id: "app.contacts.companies.employees" })}
                      value={employees}
                      onChange={setEmployees}
                    />
                    <div className="d-flex align-items-center mb-3">
                      {canModify && (
                        <Button
                          color="primary"
                          block
                          disabled={disabled() || disabled2()}
                          className="mr-1 my-0"
                        >
                          {intl.formatMessage({ id: "app.contacts.save" })}
                        </Button>
                      )}
                      {(canModify || isUnassignedButton) && (
                        <Button
                          className="ml-1 my-0"
                          color="danger"
                          block
                          onClick={onDelete}
                        >
                          {!isUnassignedButton
                            ? intl.formatMessage({ id: "app.contacts.delete" })
                            : intl.formatMessage({ id: "app.contacts.unassigned" })
                          }
                        </Button>
                      )}
                    </div>
                  </Form>
                </TabPane>
                <TabPane tabId="2">
                  <div className="d-flex justify-content-between align-items-center">
                    <LabelWembii>
                      {intl.formatMessage({ id: "app.contacts.title" })}
                    </LabelWembii>
                    {canModify && (
                      <Button
                        className="whiteButton"
                        type="button"
                        onClick={onAddingContact}
                      >
                        
                        {intl.formatMessage({ id: "app.contacts.companies.addContact" })}
                      </Button>
                    )}
                  </div>
                  {contactCompany?.contacts && contactCompany?.contacts.map((contact) => (
                    <ContactRow
                      contact={contact}
                      key={contact.id}
                      onEdit={selectContact}
                      canModify={canModify}
                    />
                  ))}
                  {contactCompany?.contacts && contactCompany?.contacts.length === 0 && (
                    <div className="m-4 text-center text-muted">
                      {intl.formatMessage({ id: "app.contacts.companies.notContacts" })}
                    </div>
                  )}
                </TabPane>
                <TabPane tabId="3">
                  <Form className="px-2 pt-2" onSubmit={onFormSubmit}>
                    {customFields.length === 0 && (
                      <div className="d-flex align-items-center justify-content-center text-muted my-2">
                        {intl.formatMessage({ id: "app.contacts.noCustomFields" })}
                      </div>
                    )}

                    {customFields.map((field) => (
                      <InputWembii
                        label={field.name}
                        type="text"
                        key={field.id}
                        disabled={!canModify}
                        placeholder={field.name}
                        onChange={(v) => onSettingField(v, field.id)}
                        value={fields[`${field.id}`]}
                      />
                    ))}

                    {customFields.length > 0 && canModify && (
                      <div className="d-flex align-items-center mb-3">
                        <Button
                          color="primary"
                          block
                          className="mr-1 my-0"
                        >
                          {intl.formatMessage({ id: "app.contacts.save" })}
                        </Button>
                      </div>
                    )}
                  </Form>
                </TabPane>
              </TabContent>        
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
