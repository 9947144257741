import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { InputWembii } from "../../InputWembii";
import { ActionOptions } from "../../../../cores/automations/config";
import { AutomationAction } from "../../../../cores/automations/interfaces/api/get-automation";
import { Action } from "../../../../cores/automations/enum/Action.enum";
import NewOpportunityActionForm from "./NewOpportunityActionForm";
import AutomaticEmailActionForm from "./AutomaticEmailActionForm";
import { AUTOMATION_ACTION } from "../../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { AutomationTrigger } from "../../../../cores/automations/enum/AutomationTrigger.enum";
import CascadeAssignmentActionForm from "./CascadeAssignmentActionForm";
import { PreloadActionData } from "../../../../cores/automations/interfaces/PreloadActionData";

interface Props {
  action: AutomationAction | null;
  trigger: string;
  preloadData?: PreloadActionData;
}

export default function ActionForm(props: Props) {
  const intl = useIntl();
  const { action, trigger } = props;
  const { turnOffInternalModal } = useGlobalCore();
  const [type, setType] = useState("");

  useEffect(() => {
    if (action) {
      setType(action.type as Action);
    } else {
      setType(Action.AUTOMATIC_EMAIL);
    }
  }, [action]);

  const onSuccess = useCallback(() => {
    turnOffInternalModal(AUTOMATION_ACTION);
    setType(Action.AUTOMATIC_EMAIL);
  }, [turnOffInternalModal]);

  const actionForm = useMemo(() => {
    switch (type) {
      case Action.NEW_OPPORTUNITY:
        return (
          <NewOpportunityActionForm
            preloadData={props.preloadData}
            action={action}
            type={type}
            onSuccess={onSuccess}
          />
        );
      case Action.AUTOMATIC_EMAIL:
        return (
          <AutomaticEmailActionForm
            action={action}
            type={type}
            onSuccess={onSuccess}
          />
        );
      case Action.CASCADE_ASSIGNMENT:
        return (
          <CascadeAssignmentActionForm
            action={action}
            type={type}
            onSuccess={onSuccess}
          />
        );
    }
  }, [type, action, onSuccess,]);

  const actionOptions = useMemo(() => {
    return ActionOptions.filter((option) =>
      option.triggers.includes(trigger as AutomationTrigger)
    );
  }, [trigger]);

  return (
    <div className="p-2">
      <InputWembii
        type="select"
        label={intl.formatMessage({
          id: "app.automations.automation.action.type",
        })}
        value={type}
        onChange={setType}
      >
        {actionOptions.map(({ value, label }) => (
          <option key={value} value={value}>
            {intl.formatMessage({ id: label })}
          </option>
        ))}
      </InputWembii>

      {actionForm}
    </div>
  );
}
