import React, { useCallback, useEffect, useState } from "react";
import { useBoardCore } from "../../../../cores/boards";
import { useIntl } from "react-intl";
import { Label } from "reactstrap";
import TextInput from "./TextInput";
import { Field } from "../../../../cores/boards/interfaces/api/put-card";
import { CardCustomField } from "../../../../cores/boards/constants/custom-field-type";
import NumberInput from "./NumberInput";
import DateInput from "./DateInput";
import moment from "moment";
import MoneyInput from "./MoneyInput";
import { getCurrencySymbol } from "../../../../cores/boards/constants/currencies.constant";
import { CL$Format } from "../../../../cores/utils";

interface Props {
  canModify: boolean;
  board: string;
  onSubmit: (arg0: Field) => void;
}

interface fieldValues {
  value: string;
  unit: string; 
  edit: boolean;
}

export default function CustomFieldContainer({ canModify, board, onSubmit }: Props) {
  const intl = useIntl();
  const { customFields, card } = useBoardCore();
  const [fields, setFields] = useState<{ [key: string]: fieldValues }>({});

  useEffect(() => {
    const newFields = customFields.reduce((prev: { [key: string]: fieldValues }, curr) => {
      const field = card?.customFields.find((field) => field.id === curr.id);
      const value = field ? field.value : "";
      const unit = field ? field.unit : "";
      prev[`${curr.id}`] = { value, unit, edit: false };
      return prev
    }, {});
    setFields(newFields);
  }, [customFields, card]);

  const toggleEdition = useCallback((field: string) => {
    if (canModify) {
      const newFields = { ...fields };
      newFields[`${field}`].edit = !newFields[`${field}`].edit;
      setFields(newFields);
    }
  }, [fields, canModify]);

  const onSettingField = useCallback((field: string, value: string, unit = "") => {
    const newFields = { ...fields };
    newFields[`${field}`].value = value;
    setFields(newFields);

    onSubmit({ id: field, value, unit });
    toggleEdition(field);
  }, [fields, onSubmit, toggleEdition]);

  const showInput = useCallback((field) => {
    if (fields[`${field.id}`])
      switch (field.type) {
        case CardCustomField.Text:
          return (
            <TextInput
              value={fields[`${field.id}`].value}
              onSubmit={(v) => onSettingField(field.id, v)}
              allowEmpty={true}
              showChek={true}
              toggleEdition={() => toggleEdition(field.id)}
            />
          );
        case CardCustomField.Number:
          return (
            <NumberInput
              value={parseFloat(fields[`${field.id}`].value)}
              onSubmit={(v) => onSettingField(field.id, `${v}`)}
              allowEmpty={true}
              showChek={true}
              toggleEdition={() => toggleEdition(field.id)}
            />
          );
        case CardCustomField.Date:
          return (
            <DateInput
              date={fields[`${field.id}`].value}
              onSubmit={(v) => onSettingField(field.id, moment(v).toISOString())}
              toggleEdition={() => toggleEdition(field.id)} 
            />
          );
        case CardCustomField.Money:
          return (
            <MoneyInput
              value={fields[`${field.id}`].value}
              unit={fields[`${field.id}`].unit}
              allowEmpty={true}
              showChek={true}
              onSubmit={(v, u) => onSettingField(field.id, `${v}`, u)}
              toggleEdition={() => toggleEdition(field.id)} 
            />
          );
      }
  }, [fields, toggleEdition, onSettingField]);

  const showLabel = useCallback((field) => {
    if (fields[`${field.id}`]) {
      return field.type === CardCustomField.Date
        ? moment(fields[`${field.id}`].value).format("DD/MM/YYYY")
        : field.type === CardCustomField.Money
          ? `${getCurrencySymbol(fields[`${field.id}`].unit)} ${CL$Format(fields[`${field.id}`].value)}`
          : `${fields[`${field.id}`].unit} ${fields[`${field.id}`].value}`;
    } else {
      return "";
    }
  }, [fields]);

  return (
    <div className="d-flex flex-wrap w-100">
      {customFields.map((field) => (
        fields[`${field.id}`] && (
          <div className="w-50 px-1 mt-4" key={field.id}>
            <Label className="font-weight-bold">
              {field.name}
            </Label>

            {!fields[`${field.id}`].edit ? (
              <div className="pointer truncate" onClick={() => toggleEdition(field.id)}>
                {!fields[`${field.id}`].value ? (
                  <span className="text-muted">
                    {intl.formatMessage({ id: "app.noData" })}
                  </span>
                ) : (
                  <span>{showLabel(field)}</span>
                )}
              </div>
            ) : (
              showInput(field)
            )}
          </div>
        )
      ))}
    </div>
  );
}
