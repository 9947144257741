import socialApiClient from "../../helpers/services/social-api-client";
import { GetGoogleCalendarBindResponse } from "../interface/api/get-google-calendar-bind";
import { GetGoogleCalendarLoginResponse } from "../interface/api/get-google-calendar-login";
import { PostGoogleCalendarBindResponse } from "../interface/api/post-google-calendar-bind";
import { googleCalendarSettingMapper } from "./mapper";

export const googleCalendarApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`google/calendar/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, code: string) {
    return socialApiClient()
      .post<PostGoogleCalendarBindResponse>(`google/calendar/bind`, { code }, {
        params: { companyId },
      })
      .then(({ data }) => googleCalendarSettingMapper.mapResponseToGoogleCalendarSetting(data));
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetGoogleCalendarBindResponse>(`google/calendar`, {
        params: { companyId },
      })
      .then(({ data }) => googleCalendarSettingMapper.mapResponseToGoogleCalendarSetting(data));
  },
  login(companyId: string) {
    return socialApiClient()
      .get<GetGoogleCalendarLoginResponse>(`google/calendar/login`, {
        params: { companyId },
      })
      .then(({ data }) =>
        googleCalendarSettingMapper.mapResponseToGoogleCalendarLoginUrl(data)
      );
  },
};
