import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { WEB_CHAT_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { WebChat } from "./interfaces/api/get-web-chat";
import { Avatar } from "./interfaces/avatar";
import { webChatApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  removeAttachmentFromStageAreaAction,
  removeConversationAndUpdateById,
  setAttachmentAction,
  setAvatarAction,
  setChatAction,
  setSelectedConversationAction,
} from "./store/actions";
import {
  deleteAreaFailureAction,
  deleteAreaInitAction,
  deleteAreaSuccessAction,
  deleteConversationFailureAction,
  deleteConversationInitAction,
  deleteConversationSuccessAction,
  getChatFailureAction,
  getChatInitAction,
  getChatSuccessAction,
  getConversationsFailureAction,
  getConversationsInitAction,
  getConversationsSuccessAction,
  getMessagesFailureAction,
  getMessagesInitAction,
  getMessagesSuccessAction,
  postAreaFailureAction,
  postAreaInitAction,
  postAreaSuccessAction,
  putAreaFailureAction,
  putAreaInitAction,
  putAreaSuccessAction,
  putChatFailureAction,
  putChatInitAction,
  putChatSuccessAction,
  updateWebConversationOwners,
} from "./store/api-actions";
import {
  avatarSelector,
  chatSelector,
  conversationsSelector,
  messagesSelector,
  selectedSelector,
  selectedAttachmentsSelector,
} from "./store/selector";
import { Attachment } from "./interfaces/attachment/attachment.interface";
import {
  removeConversationFromStackByConversationId,
  updateConversationOwners,
} from "../socialChat/store/actions";
import { ConversationStatus } from "../socialChat/types/conversation-status.types";
import { ContactStateRequest } from "../contacts/interfaces/contact-state-request";
import { ConversationStatusRequest } from "./interfaces/api/update-conversations";
import { ContactOwner } from "../contacts/interfaces/api/get-contact";
import { SocialType } from "../socialChat/types/social.types";

export function useWebChatCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const attachments = useSelector(selectedAttachmentsSelector);
  const chat = useSelector(chatSelector);
  const conversations = useSelector(conversationsSelector);
  const selected = useSelector(selectedSelector);
  const messages = useSelector(messagesSelector);
  const avatar = useSelector(avatarSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const getChat = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(getChatInitAction());
        const response = await webChatApiService.getChat(companyId);
        dispatch(getChatSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(getChatFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putChat = useCallback(
    async (
      companyId: string,
      chatId: string,
      title: string,
      username: string,
      titleColor: string,
      backgroundColor: string,
      startButtonColor: string,
      startChat: boolean,
      openButtonColor: string,
      openButtonIconColor: string,
      welcomeMessage: string,
      commercialTime: string,
      offline: boolean,
      avatar: string | null,
      isActive: boolean,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putChatInitAction());
        const response = await webChatApiService.putChat(
          companyId,
          chatId,
          title,
          username,
          titleColor,
          backgroundColor,
          startButtonColor,
          startChat,
          openButtonColor,
          openButtonIconColor,
          welcomeMessage,
          commercialTime,
          offline,
          avatar,
          isActive
        );
        dispatch(putChatSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess31" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putChatFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const postChatAvatar = useCallback(
    async (
      companyId: string,
      chatId: string,
      avatar: Avatar,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putChatInitAction());
        const response = await webChatApiService.postChatAvatar(
          companyId,
          chatId,
          avatar
        );
        dispatch(putChatSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putChatFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postChatArea = useCallback(
    async (
      companyId: string,
      chatId: string,
      name: string,
      users: number[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postAreaInitAction());
        const response = await webChatApiService.postArea(
          companyId,
          chatId,
          name,
          users
        );
        dispatch(postAreaSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess32" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postAreaFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const putChatArea = useCallback(
    async (
      companyId: string,
      chatId: string,
      areaId: string,
      name: string,
      users: number[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putAreaInitAction());
        const response = await webChatApiService.putArea(
          companyId,
          chatId,
          areaId,
          name,
          users
        );
        dispatch(putAreaSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess33" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putAreaFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const deleteChatArea = useCallback(
    async (companyId: string, chatId: string, areaId: string) => {
      try {
        dispatch(deleteAreaInitAction());
        const response = await webChatApiService.deleteArea(
          companyId,
          chatId,
          areaId
        );
        dispatch(deleteAreaSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess34" }));
      } catch (error: any) {
        console.error(error);
        dispatch(deleteAreaFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );

  const getConversations = useCallback(
    async (companyId: string, contactState: ContactStateRequest) => {
      try {
        dispatch(getConversationsInitAction());
        turnOnLoading(WEB_CHAT_LOADING);
        const response = await webChatApiService.getConversations(
          companyId,
          contactState
        );

        dispatch(getConversationsSuccessAction(response));
        turnOffLoading(WEB_CHAT_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(WEB_CHAT_LOADING);
        dispatch(getConversationsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const updateConversationStatus = useCallback(
    async (
      {
        companyId,
        conversationId,
        socialName,
        status,
      }: ConversationStatusRequest,
      statusToUpdate: ConversationStatus
    ) => {
      try {
        turnOnLoading(WEB_CHAT_LOADING);
        await webChatApiService.updateConversationStatus({
          companyId,
          conversationId,
          socialName,
          status,
        });
        //WebChat
        if (socialName === "web") {
          dispatch(
            removeConversationAndUpdateById({
              conversationId,
              newStatus: status,
            })
          );
        } else {
          dispatch(
            removeConversationFromStackByConversationId({
              conversationId,
              newStatus: status,
              statusToUpdate,
              socialType: socialName,
            })
          );
        }

        turnOffLoading(WEB_CHAT_LOADING);
      } catch (error: any) {
        turnOffLoading(WEB_CHAT_LOADING);
        dispatch(getConversationsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const deleteWebConversation = useCallback(
    async (companyId: string, conversationId: string) => {
      try {
        dispatch(deleteConversationInitAction());
        const response = await webChatApiService.deleteConversation(
          companyId,
          conversationId
        );
        dispatch(deleteConversationSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess35" }));
      } catch (error: any) {
        console.error(error);
        dispatch(deleteConversationFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const getMessages = useCallback(
    async (companyId: string, conversationId: string) => {
      try {
        dispatch(getMessagesInitAction());
        const response = await webChatApiService.getMessages(
          companyId,
          conversationId
        );
        dispatch(getMessagesSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getMessagesFailureAction({ error }));
      }
    },
    [dispatch]
  );

  return {
    chat,
    conversations,
    selected,
    messages,
    avatar,
    getChat,
    putChat,
    postChatAvatar,
    postChatArea,
    putChatArea,
    deleteChatArea,
    setChatConfig: (chat: WebChat) => dispatch(setChatAction({ chat })),
    setAvatar: (avatar: Avatar | null) => dispatch(setAvatarAction({ avatar })),
    getConversations,
    getMessages,
    deleteWebConversation,
    updateConversationStatus,
    selectWebConversation: (id: string | null) =>
      dispatch(setSelectedConversationAction({ id })),
    attachments,
    setAttachments: (attachments: Attachment[]) =>
      dispatch(setAttachmentAction({ attachments })),
    handleDeleteAttachment: (attachmentId: string) =>
      dispatch(removeAttachmentFromStageAreaAction({ attachmentId })),
    updateConversationOwners: (
      conversationId: string,
      statusToUpdate: ConversationStatus,
      socialType: SocialType,
      newOwners: ContactOwner[],
      participantId: string
    ) => {
      if (socialType === "web") {
        updateWebConversationOwners({
          conversationId,
          statusToUpdate,
          newOwners,
          participantId,
        });
      } else {
        dispatch(
          updateConversationOwners({
            conversationId,
            statusToUpdate,
            newOwners,
            participantId,
            socialType,
          })
        );
      }
    },
  };
}
