import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { Area } from "../../../../../cores/conversations/interfaces/api/get-web-chat";
import { useCompanyCore } from "../../../../../cores/company";
import { InputWembii } from "../../../InputWembii";
import { LabelWembii } from "../../../LabelWembii";

interface Props {
  onSubmit: (arg0: string, arg1: number[]) => void;
  onDelete: () => void;
  area?: Area;
}

export default function AreaForm(props: Props) {
  const intl = useIntl();
  const { area, onSubmit, onDelete } = props;
  const { users } = useCompanyCore();
  const [name, setName] = useState("");
  const [userIds, setUserIds] = useState<number[]>([]);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [selected, setSelected] = useState<{ value: string; label: string }[]>(
    []
  );

  const disabled = useCallback(() => !name, [name]);

  useEffect(() => {
    if (!!area) {
      setName(area.name);
      setUserIds(area.users);
    }
  }, [area]);

  useEffect(() => {
    setOptions(
      users.map((user) => {
        return { value: user.id, label: `${user.firstName} ${user.lastName}` };
      })
    );
  }, [users]);

  useEffect(() => {
    setSelected(
      options.filter((option) =>
        userIds.some((item: any) => item === option.value)
      )
    );
  }, [userIds, options]);

  const handleChange = useCallback(
    async (_value, { action, removedValue, option }) => {
      switch (action) {
        case "remove-value":
          let uss = userIds.filter((user) => user !== removedValue.value);
          setUserIds(uss);
          break;
        case "select-option":
          setUserIds([...userIds, option.value]);
          break;
      }
    },
    [userIds]
  );

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    onSubmit(name, userIds);
  };

  return (
    <Container className="h-100 p-4" fluid>
      <Row>
        <Col xs="12">
          <div className="d-flex flex-column h-100 justify-content-center">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.chatConfigArea.area" })}
            </h2>
            <Form onSubmit={onFormSubmit}>
              <InputWembii
                type="text"
                name="areaName"
                id="areaName"
                placeholder={intl.formatMessage({
                  id: "app.chatConfigArea.nameTxt",
                })}
                label={intl.formatMessage({ id: "app.chatConfigArea.nameTxt" })}
                value={name}
                flat
                required
                onChange={(v) => setName(v)}
              />
              <LabelWembii forid="user" required>
                {intl.formatMessage({ id: "app.chatConfigArea.nameTxt" })}
              </LabelWembii>
              <Select
                isMulti
                id="user"
                name="users"
                options={options}
                value={selected}
                className="mb-4 w-100"
                placeholder={intl.formatMessage({
                  id: "app.select.placeholder",
                })}
                noOptionsMessage={() =>
                  intl.formatMessage({ id: "app.select.noData" })
                }
                onChange={handleChange}
                classNamePrefix="select"
                isClearable={false}
              />

              <div className="d-flex justify-content-center">
                {area && (
                  <div className="btn btn-danger mr-1" onClick={onDelete}>
                    {intl.formatMessage({ id: "app.chatConfigArea.delete" })}
                  </div>
                )}
                <Button color="primary" disabled={disabled()}>
                  {intl.formatMessage({ id: "app.chatConfigArea.save" })}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
