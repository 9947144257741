import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import { FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT } from "../../../../constants/modal.constant";
import { useFacebookCore } from "../../../../cores/facebook";
import { FacebookAccount as Account } from "../../../../cores/facebook/interface/api/get-facebook-account";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";

export default function FacebookAccounts({ userToken }: { userToken: string }) {
  const intl = useIntl();
  const { accounts } = useFacebookCore();
  return (
    <div className="p-4 overflow-auto">
      <h2 className="h2 text-center mb-4">
        {intl.formatMessage({ id: "app.social.selectAccount" })}
      </h2>
      {accounts.map((account) => (
        <FacebookAccount key={account.id} account={account} userToken={userToken} />
      ))}
    </div>
  );
}

const FacebookAccount = ({
  account,
  userToken,
}: {
  account: Account;
  userToken: string;
}) => {
  const intl = useIntl();
  const { avatarUrl, name, token } = account;
  const { bind } = useFacebookCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { turnOffModal } = useGlobalCore();
  const handleBind = useCallback(async () => {
    await bind(company!.id, token, userToken, () => {
      getMeSocialSettings(company?.id!);
    });
    turnOffModal(FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT);
  }, [bind, company, token, turnOffModal, userToken, getMeSocialSettings]);

  return (
    <div className="align-items-center d-flex p-2 neu-in pointer">
      <img
        style={{ width: "50px", height: "50px" }}
        className="rounded-circle"
        src={avatarUrl}
        alt="Avatar"
      />
      <span className="h5 ml-3"> {name} </span>
      <Button className="ml-auto" color="primary" onClick={handleBind}>
        {intl.formatMessage({id : "app.sincronyze"})}
      </Button>
    </div>
  );
};
