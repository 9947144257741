import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { JumpsellerSetting } from "../interface/api/post-jumpseller-bind";

export const postUnbindJumpsellerInitAction = createAction(
  "[Jumpseller] Unbind Jumpseller Init"
);
export const postUnbindJumpsellerSuccessAction = createAction(
  "[Jumpseller] Unbind Jumpseller Success"
);
export const postUnbindJumpsellerFailureAction = createAction(
  "[Jumpseller] Unbind Jumpseller Failure",
  props<{ error: string }>()
);

export const postBindJumpsellerInitAction = createAction(
  "[Jumpseller] Bind Jumpseller Init"
);
export const postBindJumpsellerSuccessAction = createAction(
  "[Jumpseller] Bind Jumpseller Success",
  props<{ settings: JumpsellerSetting }>()
);
export const postBindJumpsellerFailureAction = createAction(
  "[Jumpseller] Bind Jumpseller Failure",
  props<{ error: string }>()
);

export const getJumpsellerInitAction = createAction(
  "[Jumpseller] Get Jumpseller Settings Init"
);
export const getJumpsellerSuccessAction = createAction(
  "[Jumpseller] Get Jumpseller Settings Success",
  props<{ settings: JumpsellerSetting }>()
);
export const getJumpsellerFailureAction = createAction(
  "[Jumpseller] Get Jumpseller Settings Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindJumpsellerInitAction,
  postUnbindJumpsellerSuccessAction,
  postUnbindJumpsellerFailureAction,
  postBindJumpsellerInitAction,
  postBindJumpsellerSuccessAction,
  postBindJumpsellerFailureAction,
  getJumpsellerInitAction,
  getJumpsellerSuccessAction,
  getJumpsellerFailureAction,
});

export type JumpsellerApiAction = typeof actions;
