import mainApiClient from "../../helpers/services/main-api-client";
import { DeleteChatTemplateResponse } from "../interfaces/api/delete-chat-template";
import { DeleteEmailTemplateResponse } from "../interfaces/api/delete-email-template";
import { DeleteSocialTemplateResponse } from "../interfaces/api/delete-social-template";
import { GetChatTemplatesResponse } from "../interfaces/api/get-chat-templates";
import { GetEmailTemplatesResponse } from "../interfaces/api/get-email-templates";
import { GetSocialTemplatesResponse } from "../interfaces/api/get-social-templates";
import { PostChatTemplateRequest, PostChatTemplateResponse } from "../interfaces/api/post-chat-template";
import { PostEmailTemplateRequest, PostEmailTemplateResponse } from "../interfaces/api/post-email-template";
import { PostSocialTemplateRequest, PostSocialTemplateResponse } from "../interfaces/api/post-social-template";
import { PutChatTemplateResponse, PutChatTemplateRequest } from "../interfaces/api/put-chat-template";
import { PutEmailTemplateRequest, PutEmailTemplateResponse } from "../interfaces/api/put-email-template";
import { PutSocialTemplateRequest, PutSocialTemplateResponse } from "../interfaces/api/put-social-template";
import { templatesMapper } from "./mapper";

export const contactApiService = {
  postEmailTemplate(
    companyId: string,
    body: PostEmailTemplateRequest
  ) {
    return mainApiClient()
      .post<PostEmailTemplateResponse>(`templates/email`, body, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToEmailTemplate(data));
  },
  postSocialTemplate(
    companyId: string,
    body: PostSocialTemplateRequest
  ) {
    return mainApiClient()
      .post<PostSocialTemplateResponse>(`templates/social`, body, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToSocialTemplate(data));
  },
  postChatTemplate(
    companyId: string,
    body: PostChatTemplateRequest
  ) {
    return mainApiClient()
      .post<PostChatTemplateResponse>(`templates/chat`, body, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToChatTemplate(data));
  },
  putEmailTemplate(
    companyId: string,
    templateId: string,
    body: PutEmailTemplateRequest
  ) {
    return mainApiClient()
      .put<PutEmailTemplateResponse>(`templates/email/${templateId}`, body, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToEmailTemplate(data));
  },
  putSocialTemplate(
    companyId: string,
    templateId: string,
    body: PutSocialTemplateRequest
  ) {
    return mainApiClient()
      .put<PutSocialTemplateResponse>(`templates/social/${templateId}`, body, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToSocialTemplate(data));
  },
  putChatTemplate(
    companyId: string,
    templateId: string,
    body: PutChatTemplateRequest
  ) {
    return mainApiClient()
      .put<PutChatTemplateResponse>(`templates/chat/${templateId}`, body, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToChatTemplate(data));
  },
  getEmailTemplates(companyId: string) {
    return mainApiClient()
      .get<GetEmailTemplatesResponse>(`templates/email`, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToEmailTemplates(data));
  },
  getSocialTemplates(companyId: string) {
    return mainApiClient()
      .get<GetSocialTemplatesResponse>(`templates/social`, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToSocialTemplates(data));
  },
  getChatTemplates(companyId: string) {
    return mainApiClient()
      .get<GetChatTemplatesResponse>(`templates/chat`, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToChatTemplates(data));
  },
  deleteEmailTemplate(companyId: string, templateId: string) {
    return mainApiClient()
      .delete<DeleteEmailTemplateResponse>(`templates/email/${templateId}`, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToId(data));
  },
  deleteSocialTemplate(companyId: string, templateId: string) {
    return mainApiClient()
      .delete<DeleteSocialTemplateResponse>(`templates/social/${templateId}`, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToId(data));
  },
  deleteChatTemplate(companyId: string, templateId: string) {
    return mainApiClient()
      .delete<DeleteChatTemplateResponse>(`templates/chat/${templateId}`, { params: { companyId } })
      .then(({ data }) => templatesMapper.mapResponseToId(data));
  },
};
