import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { CARD_PRODUCTS_MODAL } from "../../../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useBoardCore } from "../../../../cores/boards";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import Avatar from "../../Avatar";
import { truncateText } from "../../../utils/stringUtils";
import { useProductCore } from "../../../../cores/products";
import ProductList from "../modal/ProductList";
import { ProductBoard } from "../../../../cores/boards/interfaces/api/get-card";
import { CARD_PRODUCT } from "../../../../constants/internalModal.constant";
import ProductForm from "./ProductForm";
import { useIntl } from "react-intl";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";

interface Props {
  canModify: boolean;
  board: string;
}

export default function CompanyContainer({ canModify, board }: Props) {
  const intl = useIntl();
  const { company } = useMeCore();
  const { card, getCard, boardBusiness } = useBoardCore();
  const { getProduct } = useProductCore();
  const { turnOnModal, turnOnInternalModal } = useGlobalCore();

  const openProducts = useCallback(() => {
    turnOnModal(
      CARD_PRODUCTS_MODAL,
      <ProductList
        products={card?.products!}
        onAssign={(product) => {
          const price = product.prices.find((price) => price.currency === boardBusiness?.currency!);

          const prod = {
            id: "",
            name: product.name,
            code: product.code,
            description: product.description,
            quantity: 1,
            price: price ? price.price : 0,
            discount: 0,
            discountType: 0,
            status: 0,
            product: product.id,
          };
          turnOnInternalModal(CARD_PRODUCT, <ProductForm
            cardProduct={prod}
            onSuccess={() => {
              getCard(company?.id!, board, card?.list!, card?.id!);
            }}
          />);
        }}
      />,
      Position.LEFT
    );
  }, [
    company,
    board,
    boardBusiness,
    card,
    turnOnModal,
    getCard,
    turnOnInternalModal
  ]);

  const onShowing = useCallback((product: ProductBoard) => {
    if (canModify) {
      getProduct(company?.id!, product.product);
      turnOnInternalModal(
        CARD_PRODUCT,
        <ProductForm
          cardProduct={product}
          onSuccess={() => {
            getCard(company?.id!, board, card?.list!, card?.id!);
          }}
        />
      );
    }
  }, [
    company,
    canModify,
    board,
    card,
    turnOnInternalModal,
    getProduct,
    getCard
  ]);

  return (
    <div className="d-flex flex-wrap align-items-center">
      {card?.products.map((product) => (
        <div
          key={product.id}
          className="text-dark-50 font-weight-bold pointer m-1 px-2 border rounded-pill"
          onClick={() => onShowing(product)}
        >
          {truncateText(product.name)}
        </div>
      ))}
      {!canModify && card?.products.length === 0 && (
        <span className="text-muted">
          {intl.formatMessage({ id: "app.crmBusiness.noProducts" })}
        </span>
      )}
      {canModify && (
        <Avatar
          className="text-dark-50 pointer m-1"
          onClick={openProducts}
          icon={
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              size="1x"
              color={COLOR_PRIMARY}
            />
          }
        />
      )}
    </div>
  );
}
