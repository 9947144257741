import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dictionary } from "lodash";
import React, { ReactNode } from "react";
import { Col, Row } from "reactstrap";
import { useIntl } from "react-intl";

interface Props {
  name: string;
  value: string;
  bold?: boolean;
}

const rulesNameMap: Dictionary<string> = {
  teamSize: "app.plan.rule.teamSize",
  companyAmount: "app.plan.rule.companyAmount",
  webFormsAmount: "app.plan.rule.webFormsAmount",
  reportsAmount: "app.plan.rule.reportsAmount",
  allowScheduledPost: "app.plan.rule.allowScheduledPost",
  allowExportContact: "app.plan.rule.allowExportContact",
  allowCanva: "app.plan.rule.allowCanva",
  allowWebChat: "app.plan.rule.allowWebChat",
  allowWhatsappChat: "app.plan.rule.allowWhatsappChat",
  allowAnalytics: "app.plan.rule.allowAnalytics",
  allowFacebookAdvertisements: "app.plan.rule.allowFacebookAdvertisements",
  allowMarket: "app.plan.rule.allowMarket",
  allowActivityBoard: "app.plan.rule.allowActivityBoard",
  allowBusinessBoard: "app.plan.rule.allowBusinessBoard",
  allowAutomation: "app.plan.rule.allowAutomation",
  allowDatabase: "app.plan.rule.allowDatabase",
};

const rulesValueMap: Dictionary<ReactNode> = {
  true: <FontAwesomeIcon color="#23f0c7" icon={["fas", "check"]} />,
  false: <FontAwesomeIcon color="#ef767a" icon={["fas", "times"]} />,
};

export const PlanRule = (props: Props) => {
  const { name, value, bold = false } = props;
  const intl = useIntl();
  return (
    <Row className="my-3 px-2">
      <Col xs="12" md="8" className={cn("m-0 h5 text-black-50", {
        'font-weight-bold': bold
      })}>
        {intl.formatMessage({ id: rulesNameMap[name] })}
      </Col>
      <Col xs="12" md="4" className={cn("m-0 h5 text-right", {
        'font-weight-bold': bold
      })}>
        {rulesValueMap[value] || value}
      </Col>
    </Row>
  );
};
