import React from "react";
import { Col, Row } from "reactstrap";
import { GmailBindRow } from "./social/GmailBindRow";
import { GoogleCalendarBindRow } from "./social/GoogleCalendarBindRow";
import { SmtpBindRow } from "./social/SmtpBindRow";
import { Office365BindRow } from "./social/Office365BindRow";

export const IntegrationForm = () => {
  return (
    <Row className="my-4">
      <Col xs="12">
        <GmailBindRow />
        <Office365BindRow />
        <SmtpBindRow />
        <GoogleCalendarBindRow />
      </Col>
    </Row>
  );
};
