import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useContactCore } from "../../../../cores/contacts";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { PaginationWemmbii } from "../../pagination/Pagination";
import {
  CompanyBoard,
  ContactBoard,
} from "../../../../cores/boards/interfaces/api/get-board";
import { Contact } from "../../../../cores/contacts/interfaces/api/get-contacts";
import { CONTACT_EXPAND_CREATION_FORM } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { ContactTask } from "../../../../cores/tasks/interfaces/api/get-task";
import { ContactSourceOptions } from "../../../../cores/contacts/config";
import { ContactSource } from "../../../../cores/contacts/enum/ContactSource.enum";
import { useIntl } from "react-intl";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { AutomationContact } from "../../../../cores/automations/interfaces/api/get-automation";
import CreateContactForm from "../../contacts/modal/CreateContactForm";

interface Props {
  assignments: ContactBoard[] | AutomationContact[] | ContactTask[] | Contact[];
  companies?: CompanyBoard[];
  onAssign: (arg0: Contact) => void;
}

const SearchOptions = [
  { value: "email__p", label: "app.contacts.email" },
  { value: "name__p", label: "app.contacts.name" },
  { value: "reference__p", label: "app.contacts.reference" },
  { value: "tel__p", label: "app.contacts.phone" },
];

export default function ContactList(props: Props) {
  const { assignments, companies = [], onAssign } = props;
  const { contacts, customFields, getContacts, getContactTags } =
    useContactCore();
  const { company } = useMeCore();
  const { turnOnModal } = useGlobalCore();
  const [debounceSearchText, setDebounceSearchText] = useState("");
  const [source, setSource] = useState<ContactSource | null>(null);
  const [searchOption, setSearchOption] = useState("email__p");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [companyIds, setCompanyIds] = useState<string[]>([]);
  const intl = useIntl();

  const debouncedSearch = useRef(
    _.debounce((search) => setSearch(search), 1000)
  ).current;
  const defineCompaniesIds = useCallback(() => {
    const mappedCompanies = companies.map((company) => company.id);
    setCompanyIds(mappedCompanies);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    defineCompaniesIds();
  }, [defineCompaniesIds]);

  useEffect(() => {
    debouncedSearch(debounceSearchText);
  }, [debounceSearchText, debouncedSearch]);

  const sourcesContactsList = useMemo(
    () => [
      { label: intl.formatMessage({ id: "app.source.all" }), value: "" },
      ...ContactSourceOptions.map((source) => ({
        value: source.value,
        label: intl.formatMessage({ id: source.label }),
      })),
    ],
    [intl]
  );

  useEffect(() => {
    setPage(1);
    setPerPage(10);
  }, []);

  useEffect(() => {
    const sources = source ? [source] : [];
    getContacts(company!.id, {
      page,
      perPage,
      [searchOption]: search,
      owner: "",
      sources,
      companies: companyIds,
    });
  }, [
    company,
    search,
    companyIds,
    getContacts,
    source,
    page,
    perPage,
    searchOption,
  ]);

  const onClickCreateContact = useCallback(() => {
    getContactTags(company?.id!);
    turnOnModal(
      CONTACT_EXPAND_CREATION_FORM,
      <CreateContactForm source={ContactSource.CRM} onCreation={onAssign} />,
      Position.LEFT
    );
  }, [turnOnModal, getContactTags, onAssign, company]);

  const isContactAssigned = useCallback(
    (contact) =>
      assignments.some(
        (
          assignment: ContactBoard | ContactTask | AutomationContact | Contact
        ) => assignment.id === contact.id
      ),
    [assignments]
  );

  const searchOptions = useMemo(
    () => [
      ...SearchOptions.map((option) => ({
        value: option.value,
        label: intl.formatMessage({ id: option.label }),
      })),
      ...customFields.map((field) => ({ value: field.id, label: field.name })),
    ],
    [customFields, intl]
  );

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 py-5">
            <h2 className="h2 text-center mb-4">Contactos</h2>

            <div className="d-flex justify-content-between align-items-center">
              <InputWembii
                type="text"
                placeholder="Búsqueda"
                rounded
                mb={false}
                onChange={(v) => setDebounceSearchText(v)}
                style={{ borderRadius: "0px 10px 10px 0px" }}
                icon={
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="1x"
                    color="grey"
                    className="pointer"
                  />
                }
                prepend={
                  <InputWembii
                    type="select"
                    onChange={(v) => setSearchOption(v)}
                    value={searchOption}
                    mb={false}
                    style={{ borderRadius: "10px 0px 0px 10px" }}
                  >
                    {searchOptions.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </InputWembii>
                }
              />
              <Button
                className="whiteButton shadow m-0"
                onClick={onClickCreateContact}
              >
                Crear Contacto
              </Button>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <InputWembii
                label="Origen"
                type="select"
                mb={false}
                onChange={(value: ContactSource) => setSource(value)}
                value={source || ""}
              >
                {sourcesContactsList.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </InputWembii>
              <PaginationWemmbii
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                disabledPages={contacts.length < perPage}
              />
            </div>

            {contacts.length === 0 && (
              <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center text-muted">
                <FontAwesomeIcon
                  icon={["fas", "address-card"]}
                  size="5x"
                  color="gray"
                />
                No hay contactos
              </div>
            )}

            {contacts.map((contact) => (
              <div
                className="d-flex flex-row my-2 neu-in py-2 px-3 align-items-center"
                key={contact.id}
              >
                <div className="text-center flex-grow-1 align-items-center d-flex flex-column">
                  {!contact.email ? (
                    <small>{contact.phoneNumber}</small>
                  ) : (
                    <small>{contact.email}</small>
                  )}
                  <small>{contact.name}</small>
                  {contact.companies.length > 0 && (
                    <div>
                      <FontAwesomeIcon
                        icon={["fas", "building"]}
                        size="1x"
                        className="mr-2"
                        color="gray"
                      />
                      <small>
                        {contact.companies
                          .map((company) => company.name)
                          .join(", ")}
                      </small>
                    </div>
                  )}
                </div>
                <Button
                  className="ml-auto"
                  color={isContactAssigned(contact) ? "secondary" : "primary"}
                  onClick={() => onAssign(contact)}
                  disabled={isContactAssigned(contact)}
                >
                  {isContactAssigned(contact) ? "Asignado" : "Asignar"}
                </Button>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
