import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getFirstLetters, getColor } from "../../../utils/stringUtils";
import Avatar from "../../Avatar";
import { TooltipWembii } from "../../TooltipWembii";
import { ContactOwner } from "../../../../cores/contacts/interfaces/api/get-contact";

const AssigneeRow: React.FC<{
  avatars: ContactOwner[];
}> = ({ avatars }) => {
  return (
    <>
      {avatars.length > 0 ? (
        <div>
          {avatars.length > 2 && (
            <>
              <Avatar
                id="index-0"
                className="position-absolute border border-primary"
                style={{ bottom: -6, right: 0 }}
                width={"25px"}
                height={"25px"}
                icon={
                  avatars.length > 1 ? (
                    <FontAwesomeIcon icon={["fas", "users"]} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={["fas", "user"]} size="1x" />
                  )
                }
              />
              <TooltipWembii id="index-0" text="Otros asignados" />
            </>
          )}
          {avatars.slice(0, 2).map((avatar, index) => (
            <div key={`index-${index + 1}`}>
              <Avatar
                labelFontSize={12}
                id={`index-${index + 1}`}
                className="position-absolute shadow shadow-sm border border-primary"
                style={{ bottom: -6, right: 10 * (index + 1) }}
                label={getFirstLetters(
                  `${avatar.firstName} ${avatar.lastName}`
                )}
                image={avatar.avatarUrl}
                backgroundColor={getColor(parseInt(avatar.id))}
                color="#fff"
                width={"25px"}
                height={"25px"}
              />

              <TooltipWembii
                id={`index-${index + 1}`}
                text={`${avatar.firstName} ${avatar.lastName}`}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AssigneeRow;
