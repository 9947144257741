import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Button, Container } from "reactstrap";
import { RESEND_CONFIRMATION_LOADING } from "../../constants/loading.constant";
import { COLOR_PRIMARY } from "../../constants/theme";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useSessionCore } from "../../cores/session";
import { useUserCore } from "../../cores/user";
import { PanelWembii } from "./PanelWembii";
import { useIntl } from "react-intl";
import { UpdateLoadingCard } from "./UpdateLoadingCard";

export default function NoConfirmedModal() {
  const intl = useIntl();
  const { logout } = useSessionCore();
  const { reset, isLoading } = useGlobalCore();
  const { user } = useMeCore();
  const { postResendConfirmation } = useUserCore();

  const onLogout = useCallback(() => {
    reset();
    logout();
  }, [logout, reset]);

  const onResend = useCallback(() => {
    postResendConfirmation(user!.id);
  }, [postResendConfirmation, user]);

  return (
    <Container className="h-100 w-100 position-absolute p-0" style={{ zIndex: 10000 }} fluid>
      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <div className="h-100 w-100 bg-secondary opacity-4"></div>
        <PanelWembii className="p-4 position-absolute text-center">
          <div className="d-flex justify-content-center">
            <FontAwesomeIcon
              icon={["far", "envelope"]}
              size="6x"
              color={COLOR_PRIMARY}
              className="m-5"
            />
          </div>
          <h2>{intl.formatMessage({id: "app.noConfirmedAccount.title" })}</h2>
          <label className="py-2 text-wrap">
            {intl.formatMessage(
                { id: "app.noConfirmedAccount.subtitle" },
                { user: user!.email}
            )}
          </label> 
          <label className="m-0">
            {intl.formatMessage({id: "app.noConfirmedAccount.description1" })}
          </label>
          <label>
            {intl.formatMessage({id: "app.noConfirmedAccount.description2" })}
          </label>

          <UpdateLoadingCard
            loadingId={RESEND_CONFIRMATION_LOADING}
          />
          {!isLoading(RESEND_CONFIRMATION_LOADING) && (
            <>
              <div className="d-flex justify-content-center m-2 mt-5">
                <Button
                  color="primary"
                  className="rounded bg-white text-primary"
                  onClick={onResend}
                >
                  {intl.formatMessage({id: "app.noConfirmedAccount.btn1" })}
                </Button>
              </div>
              <div className="d-flex justify-content-center m-2">
                <Button className="text-danger bg-white border-0 rounded" onClick={onLogout}>
                  {intl.formatMessage({id: "app.noConfirmedAccount.btn2" })}
                </Button>
              </div>
            </>
          )}
        </PanelWembii>
      </div>
    </Container>
  );
}
