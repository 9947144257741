import moment from "moment";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  FACEBOOK_INSIGHT_LOADING,
  GENERATING_REPORT,
  GOOGLE_INSIGHT_LOADING,
  INSTAGRAM_INSIGHT_LOADING,
  JUMPSELLER_INSIGHT_LOADING,
  MERCADOLIBRE_INSIGHT_LOADING,
  WOO_COMMERCE_INSIGHT_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { GetJumpsellerOrdersParams } from "../market/interface/api/get-jumpseller-orders";
import { SocialProvider } from "../socialPost/interfaces/socialProvider";
import { Metric, Value } from "./interface/api/get-insights";
import { ChartMetric } from "./interface/ChartMetric";
import { Metric2, MetricDate } from "./interface/Metric2";
import { analyticsApiService } from "./services/api";
import { analyticsMapper } from "./services/mapper";
import {
  addSocialProviderAction,
  removeSocialProviderAction,
  selectPostGroupAction,
  updateInsightDateAction,
} from "./store/actions";
import {
  getFacebookInsightFailureAction,
  getFacebookInsightInitAction,
  getFacebookInsightSuccessAction,
  getGoogleInsightInitAction,
  getGoogleInsightSuccessAction,
  getInsightReportFailureAction,
  getInsightReportInitAction,
  getInsightReportSuccessAction,
  getInstagramFeedPostsFailureAction,
  getInstagramFeedPostsInitAction,
  getInstagramFeedPostsSuccessAction,
  getInstagramInsightFailureAction,
  getInstagramInsightInitAction,
  getInstagramInsightSuccessAction,
  getJumpsellerInsightInitAction,
  getJumpsellerInsightSuccessAction,
  getMercadolibreInsightInitAction,
  getMercadolibreInsightSuccessAction,
  getWooCommerceInsightInitAction,
  getWooCommerceInsightSuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import {
  dateSelector,
  facebookInsightEntitySelector,
  googleInsightEntitySelector,
  instagramFeedPostsSelector,
  instagramFeedSelectedPostGroupSelector,
  instagramInsightEntitySelector,
  jumpsellerInsightEntitySelector,
  mercadolibreInsightEntitySelector,
  socialProvidersEntitiesSelector,
  socialProvidersSelector,
  wooCommerceInsightEntitySelector,
  instagramErrorCodeSelector,
  facebookErrorMessageSelector,
  googleErrorCodeSelector,
  jumpsellerErrorCodeSelector,
  mercadolibreErrorMessageSelector,
  wooCommerceErrorMessageSelector,
} from "./store/selector";
import { Errors } from "../../constants/errors";

export function useAnalyticsCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const facebookInsights = useSelector(facebookInsightEntitySelector);
  const googleInsights = useSelector(googleInsightEntitySelector);
  const instagramInsights = useSelector(instagramInsightEntitySelector);
  const wooCommerceInsights = useSelector(wooCommerceInsightEntitySelector);
  const mercadolibreInsights = useSelector(mercadolibreInsightEntitySelector);
  const jumpsellerInsights = useSelector(jumpsellerInsightEntitySelector);
  const instagramFeedPost = useSelector(instagramFeedPostsSelector);
  const instagramFeedSelectedPostGroup = useSelector(
    instagramFeedSelectedPostGroupSelector
  );
  const date = useSelector(dateSelector);
  const socialProviders = useSelector(socialProvidersSelector);
  const socialProvidersEntities = useSelector(socialProvidersEntitiesSelector);
  const instagramErrorCode = useSelector(instagramErrorCodeSelector);
  const facebookErrorCode = useSelector(facebookErrorMessageSelector);
  const googleErrorCode = useSelector(googleErrorCodeSelector);
  const jumpsellerErrorCode = useSelector(jumpsellerErrorCodeSelector);
  const mercadolibreErrorCode = useSelector(mercadolibreErrorMessageSelector);
  const wooCommerceErrorCode = useSelector(wooCommerceErrorMessageSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const updateDate = useCallback(
    (date: Partial<MetricDate>) => {
      dispatch(updateInsightDateAction({ date }));
    },
    [dispatch]
  );

  const setSelectedPostGroup = useCallback(
    (postIds: string[]) => {
      dispatch(selectPostGroupAction({ postIds }));
    },
    [dispatch]
  );

  const getFacebookInsights = useCallback(
    async (
      companyId: string,
      since: string,
      until: string,
      type: string,
      limit?: number
    ) => {
      try {
        dispatch(getFacebookInsightInitAction());
        turnOnLoading(FACEBOOK_INSIGHT_LOADING);
        const insights = await analyticsApiService.facebookInsights(
          companyId,
          since,
          until,
          type,
          limit
        );
        dispatch(getFacebookInsightSuccessAction({ insights }));
        turnOffLoading(FACEBOOK_INSIGHT_LOADING, 500);
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            // CHANGEME
            case Errors.PermissionsMissing:
              dispatch(
                getFacebookInsightFailureAction({
                  errorCode: Errors.PermissionsMissing,
                })
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error2" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error2" }));
        }

        turnOffLoading(FACEBOOK_INSIGHT_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getWooCommerceInsights = useCallback(
    async (companyId: string, since: string, until: string) => {
      try {
        dispatch(getWooCommerceInsightInitAction());
        turnOnLoading(WOO_COMMERCE_INSIGHT_LOADING);
        const insights = await analyticsApiService.wooCommerceInsights(
          companyId,
          since,
          until
        );
        dispatch(getWooCommerceInsightSuccessAction({ insights }));
        turnOffLoading(WOO_COMMERCE_INSIGHT_LOADING, 500);
      } catch (error: any) {        
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error3" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error3" }));
          console.error(error);
        }

        turnOffLoading(WOO_COMMERCE_INSIGHT_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getMercadolibreInsights = useCallback(
    async (companyId: string, since: string, until: string) => {
      try {
        dispatch(getMercadolibreInsightInitAction());
        turnOnLoading(MERCADOLIBRE_INSIGHT_LOADING);
        const insights = await analyticsApiService.mercadolibreInsights(
          companyId,
          since,
          until
        );
        dispatch(getMercadolibreInsightSuccessAction({ insights }));
        turnOffLoading(MERCADOLIBRE_INSIGHT_LOADING, 500);
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            default:
              console.error(error, code);
          }
        } else {
          console.error(error);
        }

        turnOffLoading(MERCADOLIBRE_INSIGHT_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getJumpsellerInsights = useCallback(
    async (companyId: string, params: GetJumpsellerOrdersParams) => {
      try {
        dispatch(getJumpsellerInsightInitAction());
        turnOnLoading(JUMPSELLER_INSIGHT_LOADING);
        const insights = await analyticsApiService.jumpsellerInsights(
          companyId,
          params
        );
        dispatch(getJumpsellerInsightSuccessAction({ insights }));
        turnOffLoading(JUMPSELLER_INSIGHT_LOADING, 500);
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            default:
              console.error(error, code);
          }
        } else {
          console.error(error);
        }

        turnOffLoading(JUMPSELLER_INSIGHT_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getInstagramInsights = useCallback(
    async (
      companyId: string,
      since: string,
      until: string,
      type: string,
      limit?: number
    ) => {
      try {
        dispatch(getInstagramInsightInitAction());
        turnOnLoading(INSTAGRAM_INSIGHT_LOADING);
        const insights = await analyticsApiService.instagramInsights(
          companyId,
          since,
          until,
          type,
          limit
        );
        dispatch(getInstagramInsightSuccessAction({ insights }));
        turnOffLoading(INSTAGRAM_INSIGHT_LOADING, 500);
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            case Errors.PermissionsMissing:
              dispatch(
                getInstagramInsightFailureAction({
                  errorCode: Errors.PermissionsMissing,
                })
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error4" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error4" }));
          console.error(error);
        }

        turnOffLoading(INSTAGRAM_INSIGHT_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getGoogleInsights = useCallback(
    async (
      companyId: string,
      since: string,
      until: string,
      type: string,
      limit?: number
    ) => {
      try {
        dispatch(getGoogleInsightInitAction());
        turnOnLoading(GOOGLE_INSIGHT_LOADING);
        const insights = await analyticsApiService.googleInsights(
          companyId,
          since,
          until,
          type,
          limit
        );
        dispatch(getGoogleInsightSuccessAction({ insights }));
        turnOffLoading(GOOGLE_INSIGHT_LOADING, 500);
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error5" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error5" }));
          console.error(error);
        }

        turnOffLoading(GOOGLE_INSIGHT_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getInstagramFeedPosts = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getInstagramFeedPostsInitAction());
        turnOnLoading(INSTAGRAM_INSIGHT_LOADING);
        const posts = await analyticsApiService.instagramFeedPosts(companyId);
        dispatch(getInstagramFeedPostsSuccessAction({ posts }));
        turnOffLoading(INSTAGRAM_INSIGHT_LOADING, 500);
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            case Errors.PermissionsMissing:
              dispatch(
                getInstagramFeedPostsFailureAction({
                  errorCode: Errors.PermissionsMissing,
                })
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error6" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error6" }));
          console.error(error);
        }

        turnOffLoading(INSTAGRAM_INSIGHT_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getInsightReport = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(getInsightReportInitAction());
        await analyticsApiService.insightReport(companyId);
        dispatch(getInsightReportSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        toast.error(intl.formatMessage({ id: "app.toast.error7" }));
        turnOffLoading(GENERATING_REPORT);
        console.error(error);
        dispatch(getInsightReportFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, intl]
  );

  const buildInstagramPostInsights = useCallback(
    (since: string, until: string): Metric => {
      try {
        const diff = moment(until).diff(moment(since), "days");
        const values: Value[] = [];
        for (let index = 0; index < diff; index++) {
          values.push({
            label: moment(since)
              .add(index, "days")
              .format("MMMM Do YYYY")
              .toString(),
            value: 0,
          });
        }
        const postInsights = analyticsMapper.mapInstagramPostToMetric(
          instagramFeedPost
        );
        const combinedValues = values.map(
          (value) =>
            postInsights.values.find(({ label }) => value.label === label) ||
            value
        );
        return { ...postInsights, values: combinedValues };
      } catch (error: any) {
        toast.error(intl.formatMessage({ id: "app.toast.error4" }));
        console.error(error);
        throw error;
      }
    },
    [instagramFeedPost, intl]
  );

  const toChartMetric = useCallback((metrics: Metric2[]): ChartMetric => {
    return analyticsMapper.mapToChartMetric(metrics);
  }, []);

  const toggleSocialProvider = useCallback(
    (socialProvider: SocialProvider) =>
      socialProvidersEntities[socialProvider.id]
        ? dispatch(removeSocialProviderAction({ socialProvider }))
        : dispatch(addSocialProviderAction({ socialProvider })),
    [dispatch, socialProvidersEntities]
  );

  return {
    getFacebookInsights,
    getInstagramInsights,
    getGoogleInsights,
    getWooCommerceInsights,
    getMercadolibreInsights,
    getJumpsellerInsights,
    getInstagramFeedPosts,
    facebookInsights,
    instagramInsights,
    wooCommerceInsights,
    mercadolibreInsights,
    jumpsellerInsights,
    googleInsights,
    buildInstagramPostInsights,
    toChartMetric,
    date,
    updateDate,
    instagramFeedSelectedPostGroup,
    setSelectedPostGroup,
    instagramFeedPost,
    socialProviders,
    toggleSocialProvider,
    getInsightReport,
    instagramErrorCode,
    facebookErrorCode,
    wooCommerceErrorCode,
    googleErrorCode,
    jumpsellerErrorCode,
    mercadolibreErrorCode,
  };
}
