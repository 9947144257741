import React, { useEffect, useState } from "react";
import { UncontrolledCarousel } from "reactstrap";
import { Media } from "../../../../cores/socialPost/interfaces/api/get-social-posts";

interface Props {
  images: Media[]; 
}

export const ImagesCarousel = (props: Props) => {
  const { images } = props;
  const [items, setItems] = useState<{key: string, src: string}[]>([]);

  useEffect(() => {
    setItems(images.map(d => ({
      key: d.key,
      src: d.url,
      caption: ''
    })))
  }, [images]);

  return (
    <UncontrolledCarousel indicators={false} items={items} />
  );
};
