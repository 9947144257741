import socialApiClient from "../../helpers/services/social-api-client";
import { GetJumpsellerBindResponse } from "../interface/api/get-jumpseller-bind";
import { GetJumpsellerLoginResponse } from "../interface/api/get-jumpseller-login";
import { PostJumpsellerBindRequest, PostJumpsellerBindResponse } from "../interface/api/post-jumpseller-bind";
import { jumpsellerSettingMapper } from "./mapper";

export const jumpsellerApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`jumpseller/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, code: string) {
    const body: PostJumpsellerBindRequest = {
      code
    }
    return socialApiClient()
      .post<PostJumpsellerBindResponse>(`jumpseller/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) => jumpsellerSettingMapper.mapResponseToJumpsellerSetting(data));
  },
  generateContacts(companyId: string) {
    return socialApiClient()
      .post<PostJumpsellerBindResponse>(
        `jumpseller/importContacts`,
        {},
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetJumpsellerBindResponse>(`jumpseller`, {
        params: { companyId },
      })
      .then(({ data }) => jumpsellerSettingMapper.mapResponseToJumpsellerSetting(data));
  },
  login(companyId: string) {
    return socialApiClient()
      .get<GetJumpsellerLoginResponse>(`jumpseller/login`, {
        params: { companyId },
      })
      .then(({ data }) => jumpsellerSettingMapper.mapResponseToJumpsellerLoginUrl(data));
  },
};
