import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useWhatsappChatCore } from "../../cores/whatsappChat";
import { useMeCore } from "../../cores/me";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useGlobalCore } from "../../cores/globals";
import { useIntl } from "react-intl";
import { CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL, CONTACT_TAGS_FORM } from "../../constants/modal.constant";
import GeneralForm from "../component/chat/whatsapp/creator/GeneralForm";
import ChatPreview from "../component/chat/whatsapp/ChatPreview";
import { Header2 } from "../layout/Header2";
import InstalationForm from "../component/chat/whatsapp/creator/InstalationForm";
import UsersForm from "../component/chat/whatsapp/creator/UsersForm";
import { useRouterCore } from "../../cores/router";
import ContactTags from "../component/contacts/modal/ContactTags";
import { CONTACT_TAGS } from "../../constants/internalModal.constant";

export default function EditWhatsappChat() {
  const intl = useIntl();
  const { company, companyPlan, companyRole, isAdmin, isReseller } = useMeCore();
  const { chat, getChat } = useWhatsappChatCore();
  const { isModal, turnOffModal, getModalContent, getModalVisibility, turnOffInternalModal } = useGlobalCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    if (
      !companyPlan?.rules.allowWhatsappChat || (
        !companyRole?.chat &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  useEffect(() => {
    getChat(company?.id!);
  }, [getChat, company]);

  useEffect(() => {}, [chat]);

  return (
    <Layout>
      <Header2 title=
        {intl.formatMessage({ id: "app.configWspp.tittle" })}  
      />
      <Content>
        <Container fluid>
          <Row>
            <Col md="8" sm="12">
              <GeneralForm />
              <UsersForm />
              <InstalationForm />
            </Col>
            <Col md="4" sm="12">
              <ChatPreview />
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL)}
        visible={getModalVisibility(CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL)}
        onClose={() => turnOffModal(CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL)}
      >
        {getModalContent(CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_TAGS_FORM)}
        visible={getModalVisibility(CONTACT_TAGS_FORM)}
        onClose={() => {
          turnOffModal(CONTACT_TAGS_FORM);
          turnOffInternalModal(CONTACT_TAGS);
        }}
        left
      >
        <ContactTags />
      </SideModal>
    </Layout>
  );
}
