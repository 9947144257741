import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { Draggable } from "react-smooth-dnd";
import { BOARD_CARD_MODAL } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { Board, BoardListCard } from "../../../cores/boards/interfaces/api/get-board";
import { useMeCore } from "../../../cores/me";
import { useBoardCore } from "../../../cores/boards";
import { useCompanyCore } from "../../../cores/company";
import Avatar from "../Avatar";
import moment from "moment";
import { BoardType } from "../../../cores/boards/constants/board-type.constant";
import { getCurrencySymbol } from "../../../cores/boards/constants/currencies.constant";
import { TooltipWembii } from "../TooltipWembii";
import { UserStatus } from "../../../cores/user/constants/user-status";
import { useIntl } from "react-intl";
import { CL$Format } from "../../../cores/utils";
import { Badge } from "reactstrap";
import { CardStatus } from "../../../cores/boards/constants/card-type";
import { COLOR_DANGER } from "../../../constants/theme";
import { getFirstLetters } from "../../utils/stringUtils";

interface Props {
  board: Board | null;
  card: BoardListCard;
  list: string;
  index: number;
}

export default function CardList(props: Props) {
  const intl = useIntl();
  const { board, card, list } = props;
  const { getCompany } = useCompanyCore();
  const { turnOnModal } = useGlobalCore();
  const { company } = useMeCore();
  const { card: cardSelected, showTagText, getCard, getLists, setShowTagText, getCustomFields } = useBoardCore();

  const onClick = useCallback(() => {
    getCard(company?.id!, board?.id!, list, card.id, true);
    getCustomFields(company?.id!, board?.id!);
    getLists(company?.id!, board?.id!);
    getCompany(company?.id!);
    turnOnModal(BOARD_CARD_MODAL, true);
  }, [card, board, list, company, getCard, getCompany, getLists, turnOnModal, getCustomFields]);

  const contactsAssigned = useMemo(() => {
    if (card.companies.length > 0) {
      return card.contacts.slice(0, 1);
    } else {
      return card.contacts.slice(0, 2);
    }
  }, [card.companies, card.contacts]);

  const contactsRemainingQuantity = useMemo(() => {
    if (card.companies.length > 0) {
      return card.contacts.length - 1;
    } else {
      return card.contacts.length - 2;
    }
  }, [card.companies, card.contacts]);

  const companiesAssigned = useMemo(() => {
    if (card.contacts.length > 0) {
      return card.companies.slice(0, 1);
    } else {
      return card.companies.slice(0, 2);
    }
  }, [card.companies, card.contacts]);

  const companiesRemainingQuantity = useMemo(() => {
    if (card.contacts.length > 0) {
      return card.companies.length - 1;
    } else {
      return card.companies.length - 2;
    }
  }, [card.companies, card.contacts]);

  const totalRemainingQuantity = useMemo(() => {
    let quantity = companiesRemainingQuantity > 0 ? companiesRemainingQuantity : 0;
    quantity = contactsRemainingQuantity > 0 ? contactsRemainingQuantity + quantity : quantity;
    return quantity;
  }, [contactsRemainingQuantity, companiesRemainingQuantity]);

  const toggleShowTagText = useCallback((e) => {
    e.stopPropagation();
    setShowTagText(!showTagText);
  }, [showTagText, setShowTagText]);

  const lastUpdate = useMemo(() => {
    return moment().diff(moment(card.updatedAt), "d");
  }, [card.updatedAt]);

  const assigned = useMemo(() => {
    return card.assignments.length === 1
      ? card.assignments[0]
      : null;
  }, [card.assignments]);

  const assigmentNames = useMemo(() => {
    return card.assignments
      .map((assignment) => `${assignment.firstName} ${assignment.lastName}`)
      .join(" - ");
  }, [card.assignments]);

  return (
    <Draggable>
      <div
        className={cn("card card-panel px-3 py-2 pointer mb-2 border rounded", {
          "card-selected": card.id === cardSelected?.id
        })}
        onClick={onClick}
      >
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex flex-row flex-wrap align-items-center">
              {card.tags.map((tag) => (
                <Badge
                  pill
                  style={{ backgroundColor: tag.backgroundColor }}
                  className={cn("text-left px-2 my-1 mr-1 pointer", {
                    labelsExpanded: showTagText,
                    labelsCollaped: !showTagText,
                  })}
                  key={tag.id}
                  onClick={toggleShowTagText}
                >
                  <span className="h6 mb-0 font-weight-bold" style={{ color: tag.textColor, fontSize: 11.2 }}>
                    {showTagText ? tag.name : ""}
                  </span>
                </Badge>
              ))}
            </div>
            <div className="overflow-hidden font-weight-bold" style={{ fontSize: '1.1rem' }}>
              {card.title}
            </div>
            {board?.type === BoardType.BUSINESS && (
              <div>
                {card.budget > 0 && (
                  <div>
                    <span className="font-weight-bold" style={{fontSize: 11.2}}>
                      {intl.formatMessage({ id: "app.crmBusiness.value" })}
                    </span>
                    <span style={{fontSize: 11.2}}>
                      {" "}{getCurrencySymbol(card.currency)}{" "}
                      {CL$Format(card.budget, "$")}
                    </span>
                  </div>
                )}
                {card.endDate && (
                  <div>
                    <span className="font-weight-bold" style={{fontSize: 11.2}}>
                      {intl.formatMessage({ id: "app.crmBusiness.closeDate" })}
                    </span>
                    <span
                      className={cn({
                        "text-danger": moment(card.endDate).isBefore(moment()),
                      })}
                      style={{fontSize: 11.2}}
                    >
                      {" "}{moment(card.endDate).format("DD/MM/YYYY")}
                    </span>
                  </div>
                )}
              </div>
            )}
            {(card.contacts.length > 0 || card.companies.length > 0) && (
              <div>
                <div className="d-flex flex-wrap">
                  {contactsAssigned.map((contact) => (
                    <div
                      key={contact.id}
                      style={{maxHeight: 24, maxWidth: 190}}
                      className="truncate-label my-1 text-dark-50 font-weight-bold pointer px-2 border border-warning rounded-pill position-relative"
                    >
                      {contact.status !== UserStatus.ACTIVE.id && (
                        <FontAwesomeIcon
                          icon={["fas", "ban"]}
                          size="1x"
                          style={{
                            top: "-5px",
                            right: "-5px"
                          }}
                          className="position-absolute"
                          color={COLOR_DANGER}
                        />
                      )}
                      {contact.name || contact.email || contact.phoneNumber}
                    </div>
                  ))}
                  {companiesAssigned.map((company) => (
                    <div
                      key={company.id}
                      style={{maxHeight: 24, maxWidth: 190}}
                      className="truncate-label my-1 text-dark-50 font-weight-bold pointer px-2 border border-primary rounded-pill"
                    >
                      {company.name}
                    </div>
                  ))}
                  {(totalRemainingQuantity > 0) &&
                    <div className="text-dark-50 font-weight-bold pointer m-1 px-2">
                      {`+${totalRemainingQuantity}`}
                    </div>
                  }
                </div>
              </div>
            )}
          </div>

          <div className="d-flex flex-column justify-content-between">
            {assigned && (
              <>
                <Avatar
                  id={`user-${assigned.id}-${card.id}`}
                  active={assigned.status === UserStatus.ACTIVE.id}
                  className="text-primary font-weight-bold m-1"
                  label={getFirstLetters(`${assigned.lastName} ${assigned.firstName}`)}
                  image={assigned.avatarUrl}
                />
                <TooltipWembii
                  id={`user-${assigned.id}-${card.id}`}
                  text={assigmentNames}
                />
              </>
            )}

            {card.assignments.length > 1 && (
              <>
                <Avatar
                  id="multiple-assignment"
                  className="text-primary font-weight-bold m-1"
                  label={`${card.assignments.length}`}
                />
                <TooltipWembii
                  id="multiple-assignment"
                  text={assigmentNames}
                />
              </>
            )}

            <div className="flex-grow-1 d-flex justify-content-center align-items-center">
              {(card.status === CardStatus.Oppened || board?.type === BoardType.ACTIVITY) && (
                <>
                  <div id={`indicator-${card.id}`} className={cn("indicator", {
                    indicatorGreen: lastUpdate < 7,
                    indicatorYellow: lastUpdate > 6 && lastUpdate < 14,
                    indicatorRed: lastUpdate > 13,
                  })} />
                  <TooltipWembii
                    id={`indicator-${card.id}`}
                    text={intl.formatMessage({ id: "app.crmBusiness.inactivity" }, {days: lastUpdate})}
                  />
                </>
              )}
            </div>

          </div>
        </div>
      </div>
    </Draggable>
  );
}
