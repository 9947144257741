import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { useParams } from "react-router-dom";
import {
  Alert,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import { useIntl } from "react-intl";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import { useGlobalCore } from "../../cores/globals";
import { EVENT_CREATION_LOADING } from "../../constants/loading.constant";
import { useWebFormCore } from "../../cores/webForm";
import { CompanyInputType, ContactInputType, InputType } from "../../cores/webForm/constants/InputType.constant";
import { PositionType } from "../../cores/webForm/constants/PositionType.constant";
import { InputWembii } from "../component/InputWembii";
import { FormInput } from "../../cores/webForm/interfaces/api/post-public-form";
import { isEmail } from "../utils/stringUtils";

export const WebForm = () => {
  const intl = useIntl();
  const { company, form } = useParams<{ company: string, form: string }>();
  const { isLoading } = useGlobalCore();
  const { getPublicWebForm, postPublicWebForm, webForm } = useWebFormCore();
  const [inputs, setInputs] = useState<FormInput[]>([]);
  const [inputErrors, setInputErrors] = useState<string[]>([]);
  const [companyId, setCompanyId] = useState("");
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false);

  useEffect(() => {
    if (company && form) {
      const companyId = atob(company);
      const formId = atob(form);
      setCompanyId(companyId);
      getPublicWebForm(companyId, formId);
    }
  }, [company, form, getPublicWebForm]);

  useEffect(() => {
    if (webForm) {
      setInputs(webForm.inputs.map((input) => ({
        ...input,
        value: ""
      })));
    }
  }, [webForm]);

  const resetForm = useCallback(() => {
    setInputErrors([]);
    setInputs(webForm.inputs.map((input) => ({
      ...input,
      value: ""
    })));
  }, [webForm.inputs]);

  const onFormSubmit = useCallback((e: any) => {
    e.preventDefault();
    const errors = [...inputErrors];

    inputs.forEach((input) => {
      if (input.required && !input.value) {
        errors.push(input.id);
      }
    })
    setInputErrors(errors);

    if (errors.length === 0) {
      postPublicWebForm(
        companyId, {
          companyId,
          formId: webForm?.form!.id,
          form: inputs,
          reference: window.location.href
        },
        () => {
          if (webForm.form?.action === "message") {
            setShowSuccessfulMessage(true);
            resetForm();
            setTimeout(() => {
              setShowSuccessfulMessage(false);
            }, 2000);
          } else {
            window.open(webForm.form?.redirectUrl, "_self");
          }
        }
      );
    }
  }, [
    companyId,
    inputs,
    inputErrors,
    webForm,
    postPublicWebForm,
    resetForm
  ]);

  const onChangingInput = useCallback((input: FormInput, value: string) => {
    const copyInputs = [...inputs];
    const inputIndex = inputs.findIndex((inpt) => inpt.id === input.id);
    if (inputIndex >= 0) {
      copyInputs[inputIndex].value = value;

      if (
        (copyInputs[inputIndex].required && !value) ||
        (copyInputs[inputIndex].type === ContactInputType.CONTACT_EMAIL.id && !isEmail(value))
      ) {
        setInputErrors([...inputErrors, input.id]);
      } else {
        setInputErrors(inputErrors.filter((error) => error !== input.id));
      }
    }

    setInputs(copyInputs);
  }, [inputs, inputErrors]);

  const getTypeInput = (type: string) => {
    switch (type) {
      case ContactInputType.CONTACT_NAME.id:
        return "text";
      case InputType.TEXT.id:
        return "text";
      case CompanyInputType.COMPANY_NAME.id:
        return "text";
      case ContactInputType.CONTACT_EMAIL.id:
        return "email";
      case InputType.NUMBER.id:
        return "number";
      case ContactInputType.CONTACT_PHONE.id:
        return "number";
      case InputType.LONG_TEXT.id:
        return "textarea";
      default:
        return "text";
    }
  };

  const getPosition = (position: string) => {
    switch (position) {
      case PositionType.LEFT.id:
        return "flex-start";
      case PositionType.MIDDLE.id:
        return "center";
      case PositionType.RIGHT.id:
        return "flex-end";
    }
  };

  return (
    <Container className="h-100 w-100 overflow-auto" fluid>
      <Row className="h-100">
        <Col
          md={{
            offset: 4,
            size: 4
          }}
          sm="12"
        >
          <Form
            onSubmit={onFormSubmit}
            className="h-100 d-flex flex-column align-items-center justify-content-center overflow-auto p-3"
          >
            <div className="w-100 p-4 my-3 shadow bg-white rounded">
              {webForm.form?.title && (
                <h2
                  className="text-center text-wrap"
                  style={{
                    fontSize: webForm.form?.titleSize,
                    color: webForm.form?.titleColor,
                  }}
                >
                  {webForm.form?.title}
                </h2>
              )}

              {showSuccessfulMessage && (
                <Alert
                  color="primary"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {webForm.form?.successfulMessage}
                </Alert>
              )}

              {inputs.map((input) => (
                <InputWembii
                  key={input.id}
                  type={getTypeInput(input.type)}
                  className={cn({ borderRed: inputErrors.includes(input.id) })}
                  label={input.placeholder}
                  required={input.required}
                  name={`field-${input.id}`}
                  id={`field-${input.id}`}
                  placeholder={input.placeholder}
                  onChange={(v) => onChangingInput(input, v)}
                  value={input.value}
                />
              ))}

              <UpdateLoadingCard
                loadingId={EVENT_CREATION_LOADING}
                text={intl.formatMessage({ id: "app.meetings.creation" })}
              />
              {!isLoading(EVENT_CREATION_LOADING) && (
                <div className="d-flex z-index-50" style={{ justifyContent: getPosition(webForm.form?.buttonPosition!) }}>
                  <button
                    className="border-0 mx-2 rounded px-3 py-2"
                    style={{
                      fontSize: webForm.form?.buttonTextSize,
                      width: webForm.form?.buttonSize,
                      backgroundColor: webForm.form?.buttonColor,
                    }}
                  >
                    <span
                      style={{
                        color: webForm.form?.buttonTextColor,
                      }}
                    >
                      {webForm.form?.buttonText}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
