import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import {
  GMAIL_POST_LOADING,
  OFFICE365_POST_LOADING,
  SMTP_POST_LOADING,
} from "../../../../constants/loading.constant";
import {
  CONTACT_EXPAND_EDIT_FORM,
  EMAIL_WARNING_MODAL,
  EMOJI_FORM,
  GMAIL_CONTACTS_MODAL,
  SEND_EMAIL_FORM,
} from "../../../../constants/modal.constant";
import { Contact } from "../../../../cores/contacts/interfaces/api/get-contacts";
import { EmailContact } from "../../../../cores/contacts/interfaces/EmailContact";
import { RecipientType } from "../../../../cores/contacts/interfaces/RecipientType";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import ContactList from "../../boards/modal/ContactList";
import { InputWembii } from "../../InputWembii";
import { LabelWembii } from "../../LabelWembii";
import { GmailIcon } from "../../social/Gmail/GmailIcon";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";
import { Attachment } from "../creator/Attachment";
import { EmailTextEditor } from "../creator/EmailTextEditor";
import { ContactsInput } from "../creator/ContactsInput";
import { useEmailCore } from "../../../../cores/email";
import { useGmailCore } from "../../../../cores/gmail";
import { useSmtpCore } from "../../../../cores/smtp";
import { useContactCore } from "../../../../cores/contacts";
import EditContactForm from "../../contacts/modal/EditContactForm";
import AreYouSure from "../../AreYouSure";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { INFO_TAGS } from "../../../../cores/templates/constants/infoTags";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useConfigCore } from "../../../../cores/config";
import { useOffice365Core } from "../../../../cores/office365";

interface Props {
  contacts: Contact[];
  onSuccess?: () => void;
  cardId: string | null;
  quote?: File | null;
}

export default function SendEmailForm(props: Props) {
  const intl = useIntl();
  const { files, subject, message, reset, setSubject } = useEmailCore();
  const { socialSettings, userSettings, company } = useMeCore();
  const { getCommentTypes } = useConfigCore();
  const { isLoading, turnOnModal, turnOffModal } = useGlobalCore();
  const { postEmail: postGmailEmail } = useGmailCore();
  const { postEmail: postSmtpEmail } = useSmtpCore();
  const { postEmail: postOffice365Email } = useOffice365Core();
  const {
    getContactTags,
    getContact,
    getContacts,
    getCustomFields,
    deleteContacts,
    contactFilters,
  } = useContactCore();
  const [from, setFrom] = useState(0);
  const [contacts, setContacts] = useState<EmailContact[]>([]);
  const [showCCO, setShowCCO] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [cursorIndex, setCursorIndex] = useState(0);

  const toggleCCOInput = useCallback(() => {
    if (showCCO) {
      setContacts(
        contacts.map((contact) => {
          if (contact.cco) {
            contact.cco = false;
          }

          return contact;
        })
      );
    }
    setShowCCO(!showCCO);
  }, [showCCO, contacts]);

  const toggleCCInput = useCallback(() => {
    if (showCC) {
      setContacts(
        contacts.map((contact) => {
          if (contact.cc) {
            contact.cc = false;
          }

          return contact;
        })
      );
    }
    setShowCC(!showCC);
  }, [showCC, contacts]);

  const onEditContact = useCallback(
    (id) => {
      getContactTags(company?.id!);
      getCommentTypes(company?.id!);
      getCustomFields(company?.id!);
      getContact(company?.id!, id);
      turnOnModal(
        CONTACT_EXPAND_EDIT_FORM,
        <EditContactForm
          onDelete={() => {
            deleteContacts(company?.id!, [id], () => {
              getContacts(company?.id!, contactFilters);
              turnOffModal(CONTACT_EXPAND_EDIT_FORM);
              setContacts(contacts.filter((contact) => contact.id !== id));
            });
          }}
          onUpdate={(contact) => {
            getContacts(company?.id!, contactFilters);
            const index = contacts.findIndex((ctc) => ctc.id === contact.id);
            if (index > -1) {
              const newContacts = [...contacts];
              const oldContact = newContacts[index];
              newContacts[index] = { ...oldContact, ...contact };
              setContacts(newContacts);
            }
          }}
        />,
        Position.LEFT
      );
    },
    [
      company,
      contactFilters,
      contacts,
      turnOnModal,
      turnOffModal,
      getContacts,
      deleteContacts,
      getCommentTypes,
      getContact,
      getContactTags,
      getCustomFields,
    ]
  );

  const fromEmails = useMemo(() => {
    const personalEmails = _.compact([
      userSettings?.gmail,
      userSettings?.office365,
      userSettings?.smtp,
    ]);
    const companyEmails = _.compact([
      socialSettings?.gmail,
      socialSettings?.office365,
      socialSettings?.smtp,
    ]);

    return [
      ...personalEmails.map((setting) => ({
        asUser: true,
        ...setting,
      })),
      ...companyEmails.map((setting) => ({
        asUser: false,
        ...setting,
      })),
    ];
  }, [userSettings, socialSettings]);

  const sendEmail = useCallback(() => {
    const source = fromEmails[from];
    const to = contacts
      .filter((contact) => contact.email)
      .map((contact) => ({
        id: contact.id,
        cco: contact.cco,
        cc: contact.cc,
      }));
    const filesWithoutError = files.filter((file) => !file.error);

    switch (source.type) {
      case "gmail":
        postGmailEmail(
          company?.id!,
          {
            to,
            subject,
            message,
          },
          filesWithoutError,
          source.asUser,
          props.cardId,
          () => {
            props.onSuccess?.();
            turnOffModal(SEND_EMAIL_FORM);
            turnOffModal(EMOJI_FORM);
            reset();
          }
        );
        break;
      case "smtp":
        postSmtpEmail(
          company?.id!,
          {
            to,
            subject,
            message,
          },
          filesWithoutError,
          source.asUser,
          props.cardId,
          () => {
            props.onSuccess?.();
            turnOffModal(SEND_EMAIL_FORM);
            turnOffModal(EMOJI_FORM);
            reset();
          }
        );
        break;
      case "office365":
        postOffice365Email(
          company?.id!,
          {
            to,
            subject,
            message,
          },
          filesWithoutError,
          source.asUser,
          props.cardId,
          () => {
            props.onSuccess?.();
            turnOffModal(SEND_EMAIL_FORM);
            turnOffModal(EMOJI_FORM);
            reset();
          }
        );
        break;
    }
  }, [
    message,
    contacts,
    subject,
    from,
    files,
    props,
    fromEmails,
    company,
    reset,
    turnOffModal,
    postSmtpEmail,
    postGmailEmail,
    postOffice365Email,
  ]);

  const onFormSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      const toQuantity = contacts
        .filter((contact) => contact.email)
        .filter((contact) => !contact.cco && !contact.cc).length;

      if (toQuantity > 1) {
        turnOnModal(
          EMAIL_WARNING_MODAL,
          <AreYouSure
            message={`${intl.formatMessage({
              id: "app.sendEmail.to.warning",
            })}`}
            onSubmit={() => {
              sendEmail();
              turnOffModal(EMAIL_WARNING_MODAL);
            }}
            onClose={() => turnOffModal(EMAIL_WARNING_MODAL)}
          />
        );
      } else {
        sendEmail();
      }
    },
    [sendEmail, turnOnModal, turnOffModal, contacts, intl]
  );

  const disabled = useCallback(
    () =>
      !subject ||
      !message ||
      contacts.filter((contact) => contact.email).length === 0 ||
      contacts.filter((contact) => contact.email).length > 2000,
    [subject, message, contacts]
  );

  const handleRemove = useCallback(
    (e, id: string) => {
      e.stopPropagation();
      setContacts(contacts.filter((contact) => contact.id !== id));
    },
    [contacts]
  );

  const moveContactsTo = useCallback(() => {
    setContacts(
      contacts.map((contact) => ({
        ...contact,
        cco: false,
        cc: false,
      }))
    );
  }, [contacts]);

  const moveContactsToCCO = useCallback(() => {
    setContacts(
      contacts.map((contact) => ({
        ...contact,
        cco: true,
        cc: false,
      }))
    );
  }, [contacts]);

  const moveContactsToCC = useCallback(() => {
    setContacts(
      contacts.map((contact) => ({
        ...contact,
        cco: false,
        cc: true,
      }))
    );
  }, [contacts]);

  const openContactsModal = useCallback(
    (type: RecipientType) => {
      turnOnModal(
        GMAIL_CONTACTS_MODAL,
        <ContactList
          assignments={contacts}
          onAssign={(contact) => {
            const cnt = { ...contact, cco: false, cc: false };
            if (type === RecipientType.CCO) {
              cnt.cco = true;
            } else if (type === RecipientType.CC) {
              cnt.cc = true;
            }

            setContacts([...contacts, cnt]);
            turnOffModal(GMAIL_CONTACTS_MODAL);
          }}
        />,
        Position.LEFT
      );
    },
    [turnOnModal, turnOffModal, contacts]
  );

  const normalContacts = useMemo(
    () => contacts.filter((contact) => !contact.cco && !contact.cc),
    [contacts]
  );

  const ccoContacts = useMemo(
    () => contacts.filter((contact) => contact.cco),
    [contacts]
  );

  const ccContacts = useMemo(
    () => contacts.filter((contact) => contact.cc),
    [contacts]
  );

  const addSubjectInfoTag = useCallback(
    (tag) => {
      setSubject(
        subject.slice(0, cursorIndex) +
          `${tag.label} ` +
          subject.slice(cursorIndex)
      );
    },
    [subject, setSubject, cursorIndex]
  );

  useEffect(() => {
    setContacts(
      props.contacts.map((contact) => ({
        ...contact,
        cco: props.contacts.length > 1,
        cc: false,
      }))
    );

    if (props.contacts.length > 1) setShowCCO(true);
  }, [props.contacts]);

  const showMaxContactsAllowedError = useMemo(
    () => contacts.filter((contact) => contact.email).length > 2000,
    [contacts]
  );

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-4">
            <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
              <GmailIcon active size="3x" className="mx-1 mb-1" />
              {intl.formatMessage({ id: "app.sendEmail.title" })}
            </h2>
            <Form className="px-2 h-50" onSubmit={onFormSubmit}>
              <LabelWembii required>
                {intl.formatMessage({ id: "app.sendEmail.from" })}
              </LabelWembii>
              <InputWembii
                type="select"
                name="from"
                id="from"
                value={from}
                onChange={(value) => setFrom(value)}
              >
                {fromEmails.map((setting, index) => (
                  <option key={index} value={index}>
                    {setting.email}({" "}
                    {setting.asUser ? "Cuenta personal" : "Cuenta de Empresa"})
                  </option>
                ))}
              </InputWembii>
              <ContactsInput
                label="app.sendEmail.to"
                contacts={normalContacts}
                onRemoving={handleRemove}
                onEditing={onEditContact}
                onOpening={() => openContactsModal(RecipientType.NORMAL)}
              />
              <div className="mb-2 d-flex align-items-start">
                <LabelWembii
                  className="mr-1 text-underline text-primary pointer"
                  onClick={toggleCCOInput}
                >
                  {intl.formatMessage({ id: "app.sendEmail.cco" })}
                </LabelWembii>
                <LabelWembii
                  className="mx-1 text-underline text-primary pointer"
                  onClick={toggleCCInput}
                >
                  {intl.formatMessage({ id: "app.sendEmail.cc" })}
                </LabelWembii>
                <LabelWembii
                  className="ml-1 text-underline text-primary mb-2 pointer"
                  onClick={moveContactsTo}
                >
                  {intl.formatMessage({ id: "app.sendEmail.moveAll" })}
                </LabelWembii>
              </div>
              {showCCO && (
                <>
                  <ContactsInput
                    label="app.sendEmail.cco"
                    contacts={ccoContacts}
                    accContacts={normalContacts.length}
                    onRemoving={handleRemove}
                    onEditing={onEditContact}
                    onOpening={() => openContactsModal(RecipientType.CCO)}
                  />
                  <LabelWembii
                    className="text-underline text-primary mb-2 pointer"
                    onClick={moveContactsToCCO}
                  >
                    {intl.formatMessage({ id: "app.sendEmail.moveAll" })}
                  </LabelWembii>
                </>
              )}
              {showCC && (
                <>
                  <ContactsInput
                    label="app.sendEmail.cc"
                    contacts={ccContacts}
                    accContacts={normalContacts.length + ccoContacts.length}
                    onRemoving={handleRemove}
                    onEditing={onEditContact}
                    onOpening={() => openContactsModal(RecipientType.CC)}
                  />
                  <LabelWembii
                    className="text-underline text-primary mb-2 pointer"
                    onClick={moveContactsToCC}
                  >
                    {intl.formatMessage({ id: "app.sendEmail.moveAll" })}
                  </LabelWembii>
                </>
              )}
              <InputWembii
                label={intl.formatMessage({ id: "app.sendEmail.subject" })}
                type="text"
                name="subject"
                id="subject"
                required
                placeholder={intl.formatMessage({
                  id: "app.sendEmail.subject",
                })}
                value={subject}
                onChange={(value) => setSubject(value)}
                onKeyUp={(ev: any) => setCursorIndex(ev.target.selectionStart)}
                onClick={(ev: any) => setCursorIndex(ev.target.selectionStart)}
                icon={
                  <div className="border-left pl-2">
                    <UncontrolledDropdown>
                      <DropdownToggle nav className="mb-0 mx-1 p-0">
                        <FontAwesomeIcon
                          icon={["fas", "asterisk"]}
                          color="grey"
                          className="mx-1 pointer"
                        />
                      </DropdownToggle>
                      <DropdownMenu right className="border-0 py-3 px-2 shadow">
                        {Object.values(INFO_TAGS).map((tag, i) => (
                          <DropdownItem
                            key={i}
                            onClick={() => addSubjectInfoTag(tag)}
                          >
                            <div className="d-flex align-items-center">
                              {intl.formatMessage({ id: tag.name })}
                            </div>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                }
              />

              <LabelWembii required>
                {intl.formatMessage({ id: "app.sendEmail.message" })}
              </LabelWembii>
              <div className="pt-2 px-2 wembii-border-small">
                <EmailTextEditor />
              </div>
              <div className="d-flex flex-wrap">
                {files.map((file) => (
                  <Attachment key={file.id} file={file} />
                ))}
              </div>

              {showMaxContactsAllowedError && (
                <div className="mt-2 error">
                  {intl.formatMessage({ id: "app.sendEmail.tooMuch.contacts" })}
                </div>
              )}
              {contacts.length > 100 && (
                <div className="mt-2 text-muted">
                  {intl.formatMessage({ id: "app.sendEmail.sendWay" })}
                </div>
              )}
              {isLoading(GMAIL_POST_LOADING) ||
              isLoading(SMTP_POST_LOADING) ||
              isLoading(OFFICE365_POST_LOADING) ? (
                <UpdateLoadingCard
                  loadingId={[GMAIL_POST_LOADING, SMTP_POST_LOADING]}
                  text={intl.formatMessage({ id: "app.sendEmail.sending" })}
                />
              ) : (
                <Button
                  className="my-2"
                  color="primary"
                  block
                  disabled={disabled()}
                >
                  {intl.formatMessage({ id: "app.sendEmail.send" })}
                </Button>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
