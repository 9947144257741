import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const meRoot = (state: State) => state.me;

export const userSelector = createSelector(meRoot, (me) => me.user);

export const socialSettingsSelector = createSelector(
  meRoot,
  (me) => me.socialSettings
);

export const userSettingsSelector = createSelector(
  meRoot,
  (me) => me.userSettings
);

export const companiesSelector = createSelector(meRoot, ({ companies }) =>
  companies.ids.map((id) => companies.entities[id])
);

export const selectedCompanySelector = createSelector(
  meRoot,
  (me) => me.selectedCompany
);

export const selectedCompanyIdSelector = createSelector(
  meRoot,
  (me) => me.selectedCompanyId
);

export const areasSelector = createSelector(meRoot, (me) => me.areas);

export const signsSelector = createSelector(meRoot, ({ signs }) =>
  signs.ids.map((id) => signs.entities[id])
);

export const signSelector = createSelector(meRoot, (me) => me.sign);
