import {
  GetInstagramAccounts,
  InstagramAccount,
} from "../interface/api/get-instagram-accounts";
import {
  InstagramSetting,
  PostInstagramBindResponse,
} from "../interface/api/post-instagram-bind";

export const instagramSettingMapper = {
  mapResponseToInstagramSetting(
    response: PostInstagramBindResponse
  ): InstagramSetting {
    const { instagramSetting } = response?.payload;
    return instagramSetting;
  },

  mapResponseToInstagramAccounts(
    response: GetInstagramAccounts
  ): InstagramAccount[] {
    const { accounts } = response?.payload;
    return accounts;
  },
};
