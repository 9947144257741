import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button } from "reactstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { AutomationAction } from "../../../../cores/automations/interfaces/api/get-automation";
import { useAutomationCore } from "../../../../cores/automations";
import { useBoardCore } from "../../../../cores/boards";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant";
import { LabelWembii } from "../../LabelWembii";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import {
  BOARD_USERS_MODAL,
  CARD_TAGS_MODAL,
} from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { CardTag } from "../../../../cores/boards/interfaces/api/get-tags";
import Avatar from "../../Avatar";
import CardTags from "../../boards/modal/CardTags";
import { User } from "../../../../cores/session/interfaces/models/user";
import { TooltipWembii } from "../../TooltipWembii";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import UserList from "../../boards/modal/UserList";
import { NewOpportunityActionBody } from "../../../../cores/automations/interfaces/OpportunityAction";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { getFirstLetters } from "../../../utils/stringUtils";
import { PreloadActionData } from "../../../../cores/automations/interfaces/PreloadActionData";

interface Props {
  action: AutomationAction | null;
  type: string;
  onSuccess: () => void;
  preloadData?: PreloadActionData;
}

export default function NewOpportunityActionForm(props: Props) {
  const intl = useIntl();
  const { onSuccess, action, type } = props;
  const { company } = useMeCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { postAction, putAction, deleteAction, automation } =
    useAutomationCore();
  const { boards, listNames, getLists, getCardTags } = useBoardCore();

  const [board, setBoard] = useState("");
  const [list, setList] = useState("");
  const [tags, setTags] = useState<CardTag[]>([]);
  const [assignments, setAssignments] = useState<User[]>(
    props.preloadData?.assignments ?? []
  );

  useEffect(() => {
    if (action) {
      setTags((action.body as NewOpportunityActionBody).tags);
      setAssignments((action.body as NewOpportunityActionBody).assignments);
    }
  }, [action]);

  useEffect(() => {
    if (boards.length && !board && boards[0].type === BoardType.BUSINESS) {
      if (action) {
        setBoard((action.body as NewOpportunityActionBody).board.id);
        getLists(
          company?.id!,
          (action.body as NewOpportunityActionBody).board.id
        );
      } else {
        setBoard(boards[0].id);
        getLists(company?.id!, boards[0].id);
      }
    }
  }, [boards, board, company, action, getLists]);

  useEffect(() => {
    if (board) getLists(company?.id!, board);
  }, [board, company, getLists]);

  useEffect(() => {
    if (listNames.length) {
      if (action) {
        setList((action.body as NewOpportunityActionBody).list.id);
      } else {
        setList(listNames[0].id);
      }
    }
  }, [listNames, action]);

  const onSubmit = () => {
    const newTags = tags.map((tag) => tag.id);
    const newAssignments = assignments.map((user) => user.id);

    if (action) {
      putAction(
        company?.id!,
        automation?.id!,
        action.id,
        {
          type,
          body: { board, list, tags: newTags, assignments: newAssignments },
        },
        onSuccess
      );
    } else {
      postAction(
        company?.id!,
        automation?.id!,
        {
          type,
          body: { board, list, tags: newTags, assignments: newAssignments },
        },
        onSuccess
      );
    }
  };

  const disabled = useCallback(
    () => !type || !board || !list,
    [type, board, list]
  );

  const onDelete = useCallback(() => {
    deleteAction(company?.id!, automation?.id!, action?.id!, onSuccess);
  }, [company, action, automation, deleteAction, onSuccess]);

  const toggleTag = useCallback(
    (cardTag: CardTag, selected: boolean) => {
      if (selected) {
        setTags(tags.filter((tag) => tag.id !== cardTag.id));
      } else {
        setTags([...tags, cardTag]);
      }
      turnOffModal(CARD_TAGS_MODAL);
    },
    [tags, turnOffModal]
  );

  const openCardTags = useCallback(() => {
    getCardTags(company?.id!, board);
    turnOnModal(
      CARD_TAGS_MODAL,
      <CardTags board={board} onSelecting={toggleTag} tags={tags} />,
      Position.LEFT
    );
  }, [turnOnModal, getCardTags, toggleTag, company, board, tags]);

  const openAssignmentUsers = useCallback(() => {
    turnOnModal(
      BOARD_USERS_MODAL,
      <UserList
        assignments={assignments}
        onAssign={(user) => {
          setAssignments([...assignments, user]);
          turnOffModal(BOARD_USERS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, assignments]);

  const onDeleteAssignment = useCallback(
    (removeUser) => {
      setAssignments(assignments.filter((user) => user.id !== removeUser.id));
    },
    [assignments]
  );

  const getUserName = (user: User) => {
    let text = `${user.firstName} ${user.lastName}`;
    if (user.status !== UserStatus.ACTIVE.id) {
      text = `${text} - No activo`;
    }

    return text;
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <InputWembii
            type="select"
            label={intl.formatMessage({ id: "app.sidebar.crm.business" })}
            value={board}
            onChange={setBoard}
          >
            {boards.map((board) => (
              <option key={board.id} value={board.id}>
                {board.name}
              </option>
            ))}
          </InputWembii>
        </div>
        <div className="col">
          <InputWembii
            type="select"
            label={intl.formatMessage({ id: "app.crmBusiness.list" })}
            value={list}
            onChange={setList}
          >
            {listNames.map((list) => (
              <option key={list.id} value={list.id}>
                {list.name}
              </option>
            ))}
          </InputWembii>
        </div>
      </div>

      <LabelWembii>
        {intl.formatMessage({ id: "app.activity.tags" })}
      </LabelWembii>
      <div className="d-flex flex-wrap align-items-center">
        {tags.map((tag) => (
          <Badge
            pill
            style={{ backgroundColor: tag.backgroundColor }}
            className="text-left px-3 m-1 pointer"
            key={tag.id}
            onClick={() => toggleTag(tag, true)}
          >
            <span
              className="h6 mb-0 font-weight-bold"
              style={{ color: tag.textColor }}
            >
              {tag.name}
            </span>
          </Badge>
        ))}
        <Avatar
          className="text-dark-50 pointer"
          onClick={openCardTags}
          icon={
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              size="1x"
              color={COLOR_PRIMARY}
            />
          }
        />
      </div>

      <LabelWembii className="font-weight-bold">
        {intl.formatMessage({ id: "app.activity.toAssign" })}
      </LabelWembii>
      <div className="d-flex flex-wrap">
        {assignments.map((assignment) => (
          <div key={assignment.id}>
            <Avatar
              id={`user-modal-${assignment.id}`}
              active={assignment.status === UserStatus.ACTIVE.id}
              className="text-dark-50 font-weight-bold pointer m-1"
              label={getFirstLetters(
                `${assignment.lastName} ${assignment.firstName}`
              )}
              image={assignment.avatarUrl}
              onClick={() => onDeleteAssignment(assignment)}
            />
            <TooltipWembii
              id={`user-modal-${assignment.id}`}
              text={getUserName(assignment)}
            />
          </div>
        ))}
        <Avatar
          className="text-dark-50 pointer m-1"
          onClick={openAssignmentUsers}
          icon={
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              size="1x"
              color={COLOR_PRIMARY}
            />
          }
        />
      </div>

      {action && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.automations.automation.delete" })}
        </Button>
      )}
      <Button
        color="primary"
        disabled={disabled()}
        className="ml-1"
        onClick={onSubmit}
      >
        {action
          ? intl.formatMessage({ id: "app.automations.automation.update" })
          : intl.formatMessage({ id: "app.automations.automation.create" })}
      </Button>
    </>
  );
}
