import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Card } from "../interface/api/get-board-cards";
import { Metric } from "../interface/api/get-insights";

export const getCrmInsightInitAction = createAction(
  "[BoardAnalytics] Get CRM Insight Init"
);
export const getCrmInsightSuccessAction = createAction(
  "[BoardAnalytics] Get CRM Insight Success",
  props<{ insights: Metric[] }>()
);
export const getCrmInsightFailureAction = createAction(
  "[BoardAnalytics] Get CRM Insight Failure",
  props<{ error: string }>()
);

export const getBoardCardsInitAction = createAction(
  "[Board] Get Board Cards Init"
);
export const getBoardCardsSuccessAction = createAction(
  "[Board] Get Board Cards Success",
  props<{ cards: Card[] }>()
);
export const getBoardCardsFailureAction = createAction(
  "[Board] Get Board Cards Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getCrmInsightInitAction,
  getCrmInsightSuccessAction,
  getCrmInsightFailureAction,
  getBoardCardsInitAction,
  getBoardCardsSuccessAction,
  getBoardCardsFailureAction,
});

export type BoardAnalyticsApiAction = typeof actions;
