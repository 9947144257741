export enum ContactSource {
  MANUAL = "Manual",
  WOO_COMMERCE = "WooCommerce",
  JUMPSELLER = "Jumpseller",
  CHAT = "Web-Chat",
  WHATSAPPCHAT = "Whatsapp-Chat",
  FORM = "Web-Form",
  FACEBOOK_FORM = "Facebook-Form",
  CRM = "CRM",
  MEETING = "Meeting"
}
