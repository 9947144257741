import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dictionary } from "lodash";
import React, { ReactNode, useCallback } from "react";
import { COLOR_DANGER, COLOR_SUCCESS, COLOR_WARNING } from "../../../constants/theme";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { SUBSCRIPTION_STATUS } from "../../../cores/notifications/constants/subscriptionStatus.constant";
import { SubscriptionNotification } from "../../../cores/notifications/interfaces/api/get-notifications";
import { useRouterCore } from "../../../cores/router";
import { useIntl } from "react-intl";

interface Props {
  notification: SubscriptionNotification; 
}

const iconMap: Dictionary<ReactNode> = {
  0: <FontAwesomeIcon color={COLOR_WARNING} size="2x" icon={["fas", "clock"]} />,
  1: <FontAwesomeIcon color={COLOR_SUCCESS} size="2x" icon={["fas", "check"]} />,
  3: <FontAwesomeIcon color={COLOR_DANGER} size="2x" icon={["fas", "times"]} />,
};

export default function SubscriptionNotificationItem({
  notification
}: Props) {
  const { reset } = useGlobalCore();
  const { deselectCompany, companyRole } = useMeCore();
  const { goToMeCompany } = useRouterCore();
  const intl = useIntl();

  const handleClick = useCallback(async () => {
    reset();
    deselectCompany();
    goToMeCompany();
  }, [reset, goToMeCompany, deselectCompany]);

  return (
    <div className="d-flex align-items-center border-bottom mb-1">
      {iconMap[notification.type]}
      <div className="d-flex flex-column overflow-hidden ml-2">
        <span className="capitalize">
          {`${intl.formatMessage({id: "app.plan.Message"})}  ${intl.formatMessage({id: notification.text})}`}
        </span>

        {notification.type === SUBSCRIPTION_STATUS.ACTIVED && !companyRole?.owner && (
          <span className="text-muted" onClick={handleClick}>          
            Haga click para aplicar los cambios
          </span>
        )}
      </div>
    </div>
  );
}
