import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Button, CardBody, CardImg, CardSubtitle, CardTitle } from "reactstrap";
import yourLogoHere from "../../../assets/yourLogoHere.png";
import { CompanyRole } from "../../../cores/company/interfaces/api/get-company";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { useIntl } from "react-intl";
import { Company, Plan } from "../../../cores/me/interfaces/api/get-meCompany";
import { useRouterCore } from "../../../cores/router";
import { User } from "../../../cores/session/interfaces/models/user";
import { PanelWembii } from "../PanelWembii";
import { PlanBadge } from "./PlanBadge";
import { ResellerBadge } from "./ResellerBadge";
import { RoleBadges } from "./RoleBadge";

interface Props {
  company: Company;
  plan?: Plan;
  role?: CompanyRole;
  owner?: User;
  onEditCompany: (id: string) => void;
  onDeleteCompany: (id: string) => void;
}

export default function CompanyCard(props: Props) {
  const intl = useIntl();
  const { company, plan, role, owner, onEditCompany, onDeleteCompany } = props;
  const { reset } = useGlobalCore();
  const { selectCompany, company: meCompany, user, isAdmin, isReseller } = useMeCore();
  const { goToSocial } = useRouterCore();
  const isSelected = meCompany?.id === company.id;

  const handleSelectCompany = async () => {
    if (role || isAdmin || isReseller) {
      reset();
      selectCompany(company.id);
      goToSocial();
    }
  };

  const handleEditCompany = () => onEditCompany(company.id);

  const handleDeleteCompany = () => onDeleteCompany(company.id);

  const companyLogo = useMemo(() =>
    (!company?.avatarUrl) ? yourLogoHere : company.avatarUrl
  , [company]);

  return (
    <PanelWembii
      key={company.id}
      style={{
        width: "250px",
        minWidth: "250px",
        maxWidth:"250px",
        marginRight: "50px",
        marginTop: "20px",
        minHeight: !isSelected && user?.userRole?.admin ? "338px" : "300px"
      }}
      className="align-content-center border-0 d-flex justify-content-center p-3 text-center position-relative"
      shadow
    >
      <div className="d-flex justify-content-center">
        <CardImg
          top
          width="90%"
          className="rounded-circle bg-white"
          src={companyLogo}
          alt="Card image cap"
          style={{ height: "110px", width: "110px", objectFit: "contain" }}
        />
      </div>
      <CardBody className="p-0 d-flex flex-column mt-2">
        <div style={{ position: "absolute", top: "10px", left: "10px" }}>
          {plan && <PlanBadge plan={plan} />}
          {!plan && (
            <FontAwesomeIcon
              className="text-danger"
              size="2x"
              icon={["fas", "exclamation-triangle"]}
            />
          )}
        </div>

        {company.reseller && (
          <div style={{ position: "absolute", top: "10px", right: "10px" }}>
            <ResellerBadge reseller={company.reseller} />
          </div>
        )}
        {owner && (
          <CardTitle className="truncate">
            {owner.lastName} {owner.firstName}
          </CardTitle>
        )}
        <CardSubtitle>{company.name}</CardSubtitle>
        {!plan && (
          <div className="d-flex justify-content-center text-danger font-weight-bolder">
            {intl.formatMessage({ id: "app.adminCompanies.compaWithoutOwner" })}
          </div>
        )}
        {role && (
          <RoleBadges roles={role} id={company.id} />
        )}
        <div className="d-flex wrap-container mt-auto">
          {!isSelected && (
            <Button
              onClick={handleSelectCompany}
              className="my-1 w-50"
              disabled={isSelected}
              color={"primary"}
              size="sm"
            >  
              {intl.formatMessage({ id: "app.select.placeholder" })}
            </Button>
          )}
          {(role?.owner ||
            role?.admin ||
            user?.userRole?.admin ||
            user?.userRole?.reseller) && (
            <div className="my-1 w-50 px-1">
              <Button
                onClick={handleEditCompany}
                className="w-100"
                color="secondary"
                size="sm"
              >
                {intl.formatMessage({ id: "app.adminCompanies.edit" })}
              </Button>
            </div>
          )}
          {!isSelected && user?.userRole?.admin && (
            <Button
              onClick={handleDeleteCompany}
              className="my-1 w-100"
              color="danger"
              size="sm"
            >
              <FontAwesomeIcon
                icon={["fas", "trash"]}
                className="mx-2"
                size="1x"
              />
              {intl.formatMessage({ id: "app.adminCompanies.delete" })}
            </Button>
          )}
        </div>
      </CardBody>
    </PanelWembii>
  );
}
