import React, { useEffect, useState } from "react";
import { EDIT_IMAGE_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { Media } from "../../../../cores/socialPost/interfaces/api/get-social-posts";
import { PostImage } from "../../../../cores/socialPost/interfaces/postImage";
import { ImageEditorForm } from "./ImageEditorForm";

interface Props {
  image: PostImage | Media;
}

export const ImagePreview = ({ image }: Props) => {
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const [name, setName] = useState("");
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (image.type) {
      setName((image as PostImage).name);
      setImageSrc((image as PostImage).data);
    } else {
      setName((image as Media).key);
      setImageSrc((image as Media).url);
    }
  }, [image]);

  const onClick = () => {
    turnOnModal(
      EDIT_IMAGE_MODAL,
      <ImageEditorForm
        image={image}
        onClose={() => turnOffModal(EDIT_IMAGE_MODAL)}
      />,
      Position.LEFT
    );
  };

  return (
    <img
      className="imagePreview neu-out m-2 pointer"
      src={imageSrc}
      alt={name}
      onClick={onClick}
    />
  );
};
