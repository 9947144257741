import cn from "classnames";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import { SCHEDULE_POST_MODAL } from "../../../../constants/modal.constant";
import { useFacebookCore } from "../../../../cores/facebook";
import { useGlobalCore } from "../../../../cores/globals";
import { useInstagramCore } from "../../../../cores/instagram";
import { useLinkedInCore } from "../../../../cores/linkedIn";
import { useLinkedInBusinessCore } from "../../../../cores/linkedInBusiness";
import { useMeCore } from "../../../../cores/me";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { SOCIAL_ACTION_TYPES } from "../../../../cores/socialPost/consts/socialActionType.constant";
import { useTwitterCore } from "../../../../cores/twitter";
import { TooltipWembii } from "../../TooltipWembii";
import { PostActionButtons } from "./PostActionButtons";

interface Props {
  from: string;
  repost: boolean;
  onReset: () => void;
}

export const PostButtons = ({ from, repost, onReset }: Props) => {
  const intl = useIntl();
  const {
    socialPost: socialPostTwitter,
    socialPostVideo: socialPostVideoTwitter,
  } = useTwitterCore();
  const {
    socialPost: socialPostFacebook,
    socialPostVideo: socialPostVideoFacebook,
  } = useFacebookCore();
  const {
    socialPost: socialPostLinkedIn,
  } = useLinkedInCore();
  const {
    socialPost: socialPostLinkedInBusiness,
    socialPostVideo: socialPostVideoLinkedInBusiness,
  } = useLinkedInBusinessCore();
  const {
    socialPost: socialPostInstagram,
    socialPostVideo: socialPostVideoInstagram,
    cleanErrors,
  } = useInstagramCore();
  const { company, companyPlan } = useMeCore();
  const { turnOnModal } = useGlobalCore();
  const {
    socialProviders,
    postErrors,
    images,
    video,
    text = "",
    mentions,
    setSocialAction,
    selectedPost,
  } = useSocialPostCore();

  const canSchedule = useMemo(
    () => companyPlan?.rules.allowScheduledPost,
    [companyPlan]
  );

  const disabled = useMemo(() => {
    const socialProviderIds = socialProviders.map((sp) => sp.id);
    const hasError =
      postErrors.find((error) =>
        socialProviderIds.includes(error.socialProviderId)
      ) != null;
    return (
      socialProviders.length <= 0 ||
      hasError ||
      (!text && !video && !images.length)
    );
  }, [postErrors, images.length, socialProviders, text, video]);

  const onSuccess = useCallback(() => {
    onReset();
    cleanErrors();
  }, [onReset, cleanErrors]);

  const onOpeningSchedule = useCallback(() => {
    if (!disabled && canSchedule) {
      turnOnModal(SCHEDULE_POST_MODAL, true);
    }
  }, [turnOnModal, disabled, canSchedule]);

  const post = useCallback(() => {
    const parseDate = "";
    setSocialAction(SOCIAL_ACTION_TYPES.POST);
    const repostId = repost ? selectedPost?.post.id! : '';

    socialProviders.forEach((sp) => {
      switch (sp.id) {
        case SocialProviderId.Twitter:
          video
            ? socialPostVideoTwitter(
                company?.id!,
                text,
                mentions,
                video!,
                parseDate,
                repostId,
                onSuccess
              )
            : socialPostTwitter(company?.id!, text, mentions, images, parseDate, repostId, onSuccess);
          break;
        case SocialProviderId.LinkedInBusiness:
          video
            ? socialPostVideoLinkedInBusiness(
                company?.id!,
                text,
                mentions,
                video!,
                parseDate,
                repostId,
                onSuccess
              )
            : socialPostLinkedInBusiness(
                company?.id!,
                text,
                mentions,
                images,
                parseDate,
                repostId,
                onSuccess
              );
          break;
        case SocialProviderId.LinkedIn:
          socialPostLinkedIn(company?.id!, text, mentions, images, parseDate, repostId, onSuccess);
          break;
        case SocialProviderId.Facebook:
          video
            ? socialPostVideoFacebook(
                company?.id!,
                text,
                mentions,
                video!,
                parseDate,
                repostId,
                onSuccess
              )
            : socialPostFacebook(company?.id!, text, mentions, images, parseDate, repostId, onSuccess);
          break;
        case SocialProviderId.Instagram:
          video
            ? socialPostVideoInstagram(
                company?.id!,
                text,
                mentions,
                video!,
                parseDate,
                repostId,
                onSuccess
              )
            : socialPostInstagram(company?.id!, text, mentions, images, parseDate, repostId, onSuccess);
          break;
        default:
          break;
      }
    });
  }, [
    socialProviders,
    video,
    mentions,
    company,
    text,
    repost,
    images,
    selectedPost?.post.id,
    socialPostVideoTwitter,
    socialPostTwitter,
    socialPostVideoLinkedInBusiness,
    socialPostLinkedInBusiness,
    socialPostLinkedIn,
    socialPostVideoFacebook,
    socialPostFacebook,
    socialPostVideoInstagram,
    socialPostInstagram,
    onSuccess,
    setSocialAction,
  ]);

  return (
    <div className="wrap-container my-2">
      {selectedPost && !repost ? (
        <PostActionButtons from={from} save={false} disabled={disabled} onReset={onReset} />
      ) : (
        <Button
          className="mr-3"
          color="primary"
          disabled={disabled}
          onClick={post}
        >
          {intl.formatMessage({ id: "app.socialMediaPost.toPost" })}
        </Button>
      )}
      <Button
        outline
        id="schedule-button"
        color="primary"
        disabled={disabled}
        className={cn({
          'opacity-4': !canSchedule,
          'cursor-not-allowed': !canSchedule,
        })}
        onClick={onOpeningSchedule}
      >
        {intl.formatMessage({ id: "app.socialMediaPost.program" })}
      </Button>
      {!canSchedule && (
        <TooltipWembii 
          id="schedule-button"
          text={intl.formatMessage({ id: "app.plan.upgrade" })} 
        />
      )}    
    </div>
  );
};
