import React, { useCallback } from "react";
import { Container, FormGroup } from "reactstrap";
import { useWebChatCore } from "../../../../../cores/conversations";
import { useMeCore } from "../../../../../cores/me";
import { LabelWembii } from "../../../LabelWembii";
import { PanelWembii } from "../../../PanelWembii";
import { useIntl } from "react-intl";
import { TitleWembii } from "../../../TitleWembii";
import { useGlobalCore } from "../../../../../cores/globals";
import { CONFIGURE_CHAT_AREA_SIDE_MODAL } from "../../../../../constants/modal.constant";
import AreaForm from "../modal/AreaForm";
import { Area } from "../../../../../cores/conversations/interfaces/api/get-web-chat";
import { useCompanyCore } from "../../../../../cores/company";

export default function NotificationForm() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { getCompany } = useCompanyCore();
  const { chat, postChatArea, putChatArea, deleteChatArea } = useWebChatCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const toggleAreaSideModal = useCallback(
    (area?: Area) => {
      getCompany(company?.id!);
      turnOnModal(
        CONFIGURE_CHAT_AREA_SIDE_MODAL,
        <AreaForm
          area={area}
          onSubmit={(name, users) => {
            if (!area) {
              postChatArea(company?.id!, chat?.config!.id, name, users);
            } else {
              putChatArea(company?.id!, chat?.config!.id, area.id, name, users);
            }
            turnOffModal(CONFIGURE_CHAT_AREA_SIDE_MODAL);
          }}
          onDelete={() => {
            deleteChatArea(company?.id!, chat?.config!.id, area?.id!);
            turnOffModal(CONFIGURE_CHAT_AREA_SIDE_MODAL);
          }}
        />
      );
    },
    [
      deleteChatArea,
      postChatArea,
      putChatArea,
      turnOffModal,
      turnOnModal,
      getCompany,
      company,
      chat,
    ]
  );

  return (
    <Container className="p-0">
      <PanelWembii shadow className="mb-4 p-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.chatConfigNotifications.tittle" })}
        </TitleWembii>
        <FormGroup>
          <div
            className="border border-white-50 text-dark shadow-sm btn btn-sm"
            color="white"
            id="notifications"
            onClick={() => toggleAreaSideModal()}
          >
            {intl.formatMessage({
              id: "app.chatConfigNotifications.configArea",
            })}
          </div>
          <div>
            <LabelWembii forid="notifications">
              {intl.formatMessage({ id: "app.chatConfigNotifications.areas" })}
            </LabelWembii>
            <div className="d-flex flex-wrap">
              {chat?.areas.map((area) => (
                <div
                  key={area.id}
                  className="border text-dark shadow-sm rounded-0 btn btn-sm"
                  color="gray"
                  onClick={() => toggleAreaSideModal(area)}
                >
                  {area.name}
                </div>
              ))}
            </div>
          </div>
        </FormGroup>
      </PanelWembii>
    </Container>
  );
}
