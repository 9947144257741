import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Button, Form } from "reactstrap";
import { useMeCore } from "../../../../cores/me";
import { useIntl } from "react-intl";
import { useBoardCore } from "../../../../cores/boards";
import { InputWembii } from "../../InputWembii";
import { LabelWembii } from "../../LabelWembii";
import { PanelWembii } from "../../PanelWembii";

interface Props {
  board: string;
}

export default function AddListContainer({ board }: Props) {
  const intl = useIntl();
  const { company } = useMeCore();
  const { postList } = useBoardCore();
  const [addList, setAddList] = useState(false);
  const [listName, setListName] = useState("");

  const toggleAddList = useCallback(() => {
    setListName("");
    setAddList(!addList);
  }, [addList]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    
    postList(company?.id!, listName, board);
    toggleAddList();
  };

  return (
    <PanelWembii className="mx-2 p-4">
      {!addList ? (
        <div 
          style={{ width: 300 }}
          className="d-flex align-items-center pointer"
          onClick={toggleAddList}
        >
          <FontAwesomeIcon
            icon={["fas", "plus"]}
            className="pointer mr-2"
            size="1x"
            color="gray"
          />
          {intl.formatMessage({ id: "app.activity.addList" })}
        </div>
      ): (
        <Form style={{ width: 300 }} onSubmit={onFormSubmit}>
          <LabelWembii forid="title" required={true}>
            {intl.formatMessage({ id: "app.activity.titleList" })}
          </LabelWembii>
          <InputWembii
            type="text"
            id="title"
            icon={
              <FontAwesomeIcon
                icon={["fas", "times"]}
                size="1x"
                color="gray"
                className="pointer"
                onClick={toggleAddList}
              />
            }
            value={listName}
            onChange={(v) => setListName(v)}
          />
          <Button color="primary" disabled={!listName}>
          {intl.formatMessage({ id: "app.activity.add" })}
          </Button>
        </Form>
      )}
    </PanelWembii>
  );
}
