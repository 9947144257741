import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Plan } from "../interfaces/api/get-plans";
import {
  getPlansInitAction,
  getPlansSuccessAction,
  PlanApiAction,
  postUpgradePlansFailureAction,
  postUpgradePlansInitAction,
  postUpgradePlansSuccessAction,
} from "./api-actions";

export interface PlanState {
  plans: {
    ids: number[];
    entities: Record<string, Plan>;
  };
  selectedPlan: Plan | null;
}

const initialState: PlanState = {
  plans: { ids: [], entities: {} },
  selectedPlan: null,
};

const companiesAdapter = createEntityAdapter<Plan>((payload) => payload.id);

export default produce(
  (draft: PlanState = initialState, action: PlanApiAction | GlobalActions) => {
    switch (action.type) {
      case getPlansSuccessAction.type:
        companiesAdapter.addMany(action.plans, draft.plans);
        return draft;
      case resetAction.type:
        return initialState;
      case getPlansInitAction.type:
      case postUpgradePlansInitAction.type:
      case postUpgradePlansFailureAction.type:
      case postUpgradePlansSuccessAction.type:
      default:
        return draft;
    }
  }
);
