import React from "react";
import { IconProps } from "../Facebook/FacebookIcon";
import { sizeReference } from "../WooCommerce/WooCommerceIcon";

export const InstagramMenssegerIcon = (props: IconProps) => {
  const { active, className, size = "1x", color } = props;
  return (
    <svg
      viewBox="0 0 512 512"
      className={className}
      width={sizeReference[size]}
      height={sizeReference[size]}
    >
      <defs>
        <radialGradient id="instagramGradient" cx="101.925" cy="809.0079" r="1.0896" gradientTransform="matrix(800 0 0 -799.9985 -81386 648000.75)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#0099FF" />
          <stop offset="0.6098" stopColor="#A033FF" />
          <stop offset="0.9348" stopColor="#FF5280" />
          <stop offset="1" stopColor="#FF7061" />
        </radialGradient>
      </defs>

      <path fill={color || active ? "url('#instagramGradient')" : "gray"}
            d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z">
      </path>
    </svg>
  );
};
