import _, { Dictionary } from "lodash";
import React, { ReactNode, useCallback, useMemo } from "react";
import Switch from "react-switch";
import { FormGroup } from "reactstrap";
import { 
  FACEBOOK_COLOR,
  GOOGLE_COLOR,
  INSTAGRAM_COLOR,
  MERCADOLIBRE_COLOR,
  WOO_COMMERCE_COLOR
} from "../../../constants/socialColors.constant";
import { useAnalyticsCore } from "../../../cores/analytics";
import { useMeCore } from "../../../cores/me";
import { SocialProviderId, SocialProviderNameMapper } from "../../../cores/socialPost/config";
import { SocialProvider } from "../../../cores/socialPost/interfaces/socialProvider";
import { FacebookIcon, IconProps } from "../social/Facebook/FacebookIcon";
import { GooglenAnalyticsIcon } from "../social/GoogleAnalitycs/GoogleAnalitycsIcon";
import { InstagramIcon } from "../social/Instagram/InstagramIcon";
import { MercadolibreIcon } from "../social/Mercadolibre/MercadolibreIcon";
import { WooCommerceIcon } from "../social/WooCommerce/WooCommerceIcon";

export const IconMapper: Dictionary<(props: IconProps) => ReactNode> = {
  facebook: (props) => <FacebookIcon {...props} />,
  instagram: (props) => <InstagramIcon {...props} />,
  google: (props) => <GooglenAnalyticsIcon {...props} />,
  wooCommerce: (props) => <WooCommerceIcon {...props} />,
  mercadoLibre: (props) => <MercadolibreIcon {...props} />,
};

export const ableForReporting = [
  { id: SocialProviderId.Facebook, color: FACEBOOK_COLOR },
  { id: SocialProviderId.Instagram, color: INSTAGRAM_COLOR },
  { id: SocialProviderId.Google, color: GOOGLE_COLOR },
  { id: SocialProviderId.WooCommerce, color: WOO_COMMERCE_COLOR },
  { id: SocialProviderId.Mercadolibre, color: MERCADOLIBRE_COLOR },
];

export const SocialReportSelector = () => {
  const { socialSettings } = useMeCore();
  const { socialProviders, toggleSocialProvider } = useAnalyticsCore();

  const socialProvidersAbleForReporting: SocialProvider[] = useMemo(() => {
    let providers = ableForReporting
      .filter((social) => socialSettings![social.id])
      .map(
        (social): SocialProvider => ({
          id: social.id,
          color: social.color,
          name: SocialProviderNameMapper[social.id],
          icon: IconMapper[social.id],
        })
      );

    return providers;
  }, [socialSettings]);

  const isSelected = useCallback(
    (id: string): boolean =>
      Boolean(socialProviders.filter((sp) => sp.id === id).length),
    [socialProviders]
  );

  return (
    <div>
      {socialProvidersAbleForReporting.map((sp) => (
        <FormGroup className="d-flex align-items-center mb-4" key={sp.id}>
          <Switch
            checked={isSelected(sp.id)}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor={sp.color}
            height={20}
            width={30}
            className="mr-2"
            onChange={() => toggleSocialProvider(sp)}
          />
          { _.isFunction(sp.icon) && sp.icon({ active: true, size: "1x" }) }
          <span className="text-secondary font-weight-bold ml-2">
            { sp.name }
          </span>
        </FormGroup>
      ))}
    </div>
  );
};
