import React, { useState } from "react";
import {
  Alert,
  Button,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useWebFormCore } from "../../../../cores/webForm";
import { ContactInputType, InputType } from "../../../../cores/webForm/constants/InputType.constant";
import { PositionType } from "../../../../cores/webForm/constants/PositionType.constant";
import { PanelWembii } from "../../PanelWembii";
import { TitleWembii } from "../../TitleWembii";
import { useIntl } from "react-intl";

export default function FormPreview() {
  const intl = useIntl();
  const { webForm } = useWebFormCore();
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    setShowSuccessfulMessage(true);

    setTimeout(() => {
      setShowSuccessfulMessage(false);
    }, 2000);
  };

  const getTypeInput = (type: string) => {
    switch (type) {
      case ContactInputType.CONTACT_EMAIL.id:
        return "email";
      case InputType.NUMBER.id:
        return "number";
      case ContactInputType.CONTACT_PHONE.id:
        return "number";
      case InputType.LONG_TEXT.id:
        return "textarea";
      default:
        return "text";
    }
  };

  const getPosition = (position: string) => {
    switch (position) {
      case PositionType.LEFT.id:
        return "flex-start";
      case PositionType.MIDDLE.id:
        return "center";
      case PositionType.RIGHT.id:
        return "flex-end";
    }
  };

  return (
    <Container className="sticky-top p-0 z-index-50">
      <PanelWembii shadow className="p-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.socialMediaPost.preview" })}
        </TitleWembii>
        
        <Form
          onSubmit={onFormSubmit}
          className="d-flex flex-column align-items-center overflow-auto p-2"
          style={{
            maxHeight: 400
          }}
        > 
          <div className="w-100">
            {webForm.form?.title && (
              <h2
                className="text-center text-wrap"
                style={{
                  fontSize: webForm.form?.titleSize,
                  color: webForm.form?.titleColor,
                }}
              >
                {webForm.form?.title}
              </h2>
            )}

            {showSuccessfulMessage && (
              <Alert
                color="primary"
                style={{
                  textAlign: "center",
                }}
              >
                {webForm.form?.successfulMessage}
              </Alert>
            )}

            {webForm.inputs.map((input) => (
              <FormGroup key={input.id} className="w-100">
                <Label for={`field-${input.id}`}>{input.placeholder}</Label>
                <Input
                  type={getTypeInput(input.type)}
                  name={`field-${input.id}`}
                  id={`field-${input.id}`}
                  placeholder={input.placeholder}
                />
                <FormFeedback>El campo no puede ser vacío</FormFeedback>
              </FormGroup>
            ))}
          </div>

          <Button
            className="border-0 mx-2"
            style={{
              fontSize: webForm.form?.buttonTextSize,
              width: webForm.form?.buttonSize,
              backgroundColor: webForm.form?.buttonColor,
              alignSelf: getPosition(webForm.form?.buttonPosition!),
            }}
          >
            <span
              style={{
                color: webForm.form?.buttonTextColor,
              }}
            >
              {webForm.form?.buttonText}
            </span>
          </Button>
        </Form>
      </PanelWembii>
    </Container>
  );
}
