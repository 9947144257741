import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { EmailTemplate } from "../interfaces/api/get-email-templates";
import { SocialTemplate } from "../interfaces/api/get-social-templates";
import {
  deleteChatTemplateFailureAction,
  deleteChatTemplateInitAction,
  deleteChatTemplateSuccessAction,
  deleteEmailTemplateFailureAction,
  deleteEmailTemplateInitAction,
  deleteEmailTemplateSuccessAction,
  deleteSocialTemplateFailureAction,
  deleteSocialTemplateInitAction,
  deleteSocialTemplateSuccessAction,
  getChatTemplatesFailureAction,
  getChatTemplatesInitAction,
  getChatTemplatesSuccessAction,
  getEmailTemplatesFailureAction,
  getEmailTemplatesInitAction,
  getEmailTemplatesSuccessAction,
  getSocialTemplatesFailureAction,
  getSocialTemplatesInitAction,
  getSocialTemplatesSuccessAction,
  postChatTemplateFailureAction,
  postChatTemplateInitAction,
  postChatTemplateSuccessAction,
  postEmailTemplateFailureAction,
  postEmailTemplateInitAction,
  postEmailTemplateSuccessAction,
  postSocialTemplateFailureAction,
  postSocialTemplateInitAction,
  postSocialTemplateSuccessAction,
  putChatTemplateFailureAction,
  putChatTemplateInitAction,
  putChatTemplateSuccessAction,
  putEmailTemplateFailureAction,
  putEmailTemplateInitAction,
  putEmailTemplateSuccessAction,
  putSocialTemplateFailureAction,
  putSocialTemplateInitAction,
  putSocialTemplateSuccessAction,
  TemplateApiAction
} from "./api-actions";
import { ChatTemplate } from "../interfaces/api/get-chat-templates";

export interface TemplateState {
  emailTemplates: {
    ids: string[];
    entities: Record<string, EmailTemplate>;
  };
  socialTemplates: {
    ids: string[];
    entities: Record<string, SocialTemplate>;
  };
  chatTemplates: {
    ids: string[];
    entities: Record<string,ChatTemplate>;
  };
}

const initialState: TemplateState = {
  emailTemplates: {
    ids: [],
    entities: {},
  },
  socialTemplates: {
    ids: [],
    entities: {},
  },
  chatTemplates: {
    ids: [],
    entities: {},
  },
};

const emailTemplatesAdapter = createEntityAdapter<EmailTemplate>(
  (payload) => payload.id
);
const socialTemplatesAdapter = createEntityAdapter<SocialTemplate>(
  (payload) => payload.id
);
const chatTemplatesAdapter = createEntityAdapter<ChatTemplate>(
  (payload) => payload.id
);

export default produce(
  (
    draft: TemplateState = initialState,
    action: TemplateApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case getEmailTemplatesSuccessAction.type:
        emailTemplatesAdapter.addMany(action.templates, draft.emailTemplates);
        return draft;
      case postEmailTemplateSuccessAction.type:
        emailTemplatesAdapter.setOne(action.template, draft.emailTemplates);
        return draft;
      case putEmailTemplateSuccessAction.type:
        emailTemplatesAdapter.updateOne(action.template, draft.emailTemplates);
        return draft;
      case deleteEmailTemplateSuccessAction.type:
        emailTemplatesAdapter.removeOne(+action.id, draft.emailTemplates);
        return draft;
      case getSocialTemplatesSuccessAction.type:
        socialTemplatesAdapter.addMany(action.templates, draft.socialTemplates);
        return draft;
      case postSocialTemplateSuccessAction.type:
        socialTemplatesAdapter.setOne(action.template, draft.socialTemplates);
        return draft;
      case putSocialTemplateSuccessAction.type:
        socialTemplatesAdapter.updateOne(action.template, draft.socialTemplates);
        return draft;
      case deleteSocialTemplateSuccessAction.type:
        socialTemplatesAdapter.removeOne(+action.id, draft.socialTemplates);
        return draft;
      case getChatTemplatesSuccessAction.type:
        chatTemplatesAdapter.addMany(action.templates, draft.chatTemplates);
        return draft;
      case postChatTemplateSuccessAction.type:
        chatTemplatesAdapter.setOne(action.template, draft.chatTemplates);
        return draft;
      case putChatTemplateSuccessAction.type:
        chatTemplatesAdapter.updateOne(action.template, draft.chatTemplates);
        return draft;
      case deleteChatTemplateSuccessAction.type:
        chatTemplatesAdapter.removeOne(+action.id, draft.chatTemplates);
        return draft;
      case resetAction.type:
        return initialState;
      case getEmailTemplatesInitAction.type:
      case getEmailTemplatesFailureAction.type:
      case postEmailTemplateInitAction.type:
      case postEmailTemplateFailureAction.type:
      case putEmailTemplateInitAction.type:
      case putEmailTemplateFailureAction.type:
      case deleteEmailTemplateInitAction.type:
      case deleteEmailTemplateFailureAction.type:
      case getSocialTemplatesInitAction.type:
      case getSocialTemplatesFailureAction.type:
      case postSocialTemplateInitAction.type:
      case postSocialTemplateFailureAction.type:
      case putSocialTemplateInitAction.type:
      case putSocialTemplateFailureAction.type:
      case deleteSocialTemplateInitAction.type:
      case deleteSocialTemplateFailureAction.type:
      case getChatTemplatesInitAction.type:
      case getChatTemplatesFailureAction.type:
      case postChatTemplateInitAction.type:
      case postChatTemplateFailureAction.type:
      case putChatTemplateInitAction.type:
      case putChatTemplateFailureAction.type:
      case deleteChatTemplateInitAction.type:
      case deleteChatTemplateFailureAction.type:
      default:
        return draft;
    }
  }
);
