import React, { useMemo, useState } from "react";
import PhoneInput from "react-phone-number-input";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useIntl } from "react-intl";
import { useCompanyCore } from "../../../cores/company";
import { FromFeedback2 } from "../FromFeedback2";
import { useMeCore } from "../../../cores/me";
import { useGlobalCore } from "../../../cores/globals";
import { LabelWembii } from "../LabelWembii";
import { useRouterCore } from "../../../cores/router";

interface Props {
  onSubmit?: () => void;
  asReseller?: boolean;
  autoSelectOnCreation?: boolean;
}

export default function CreateCompanyForm(props: Props) {
  const intl = useIntl();
  const { onSubmit, asReseller = false, autoSelectOnCreation = false } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const { postCompany, getCompanies } = useCompanyCore();
  const { goToSocial } = useRouterCore();
  const { selectCompany } = useMeCore();
  const { reset } = useGlobalCore();

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    const company = await postCompany(
      name,
      description,
      website,
      phone,
      country,
      city,
      false,
      asReseller
    );

    setName("");
    setDescription("");
    setWebsite("");
    setCountry("");
    setCity("");
    setPhone("");
    onSubmit?.();
    
    if (company && autoSelectOnCreation) {
      reset();
      getCompanies({});
      goToSocial();
      selectCompany(company.company.id);
    }
  };

  const isFieldToLong = (field: string, length: number) =>
    field.length > length;
  const fieldLengthFeedback = (field: string, length: number) => (
    <FromFeedback2
      show={isFieldToLong(field, length)}
      message={intl.formatMessage(
        { id: "app.adminCompanies.error1" },
        { length }
      )}
    />
  );

  const isNameInvalid = useMemo(() => isFieldToLong(name, 35), [name]);
  const isDescriptionInvalid = useMemo(
    () => isFieldToLong(description, 100),
    [description]
  );
  const isWebsiteInvalid = useMemo(() => isFieldToLong(website, 35), [website]);
  const isCountryInvalid = useMemo(() => isFieldToLong(country, 35), [country]);
  const isCityInvalid = useMemo(() => isFieldToLong(city, 35), [city]);

  const disabled = useMemo(
    () =>
      !name ||
      !country ||
      !city ||
      isNameInvalid ||
      isDescriptionInvalid ||
      isCityInvalid ||
      isWebsiteInvalid ||
      isCountryInvalid,
    [
      city,
      country,
      isCityInvalid,
      isCountryInvalid,
      isDescriptionInvalid,
      isNameInvalid,
      isWebsiteInvalid,
      name
    ]
  );

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 justify-content-center py-5">
            <h2 className="h2 text-center mb-4">  
             {intl.formatMessage({ id: "app.adminCompanies.title" })}
            </h2>
            <Form className="px-2" onSubmit={onFormSubmit}>
              <FormGroup>
                <LabelWembii forid="company-name" required>
                  {intl.formatMessage({ id: "app.nameTxt" })} 
                </LabelWembii>
                <Input
                  type="text"
                  invalid={isNameInvalid}
                  name="name"
                  id="company-name"
                  placeholder={intl.formatMessage({ id: "app.nameTxt" })} 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                {fieldLengthFeedback(name, 35)}
              </FormGroup>
              <FormGroup>
                <LabelWembii forid="company-description">
                  {intl.formatMessage({ id: "app.adminCompanies.descriptionTxt" })}
                </LabelWembii>
                <Input
                  type="text"
                  name="description"
                  id="company-description"
                  placeholder={intl.formatMessage({ id: "app.adminCompanies.descriptionTxt" })}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  invalid={isDescriptionInvalid}
                />
                
                {fieldLengthFeedback(description, 100)}
              </FormGroup>
              <FormGroup>
                <LabelWembii forid="company-webSite">
                  {intl.formatMessage({ id: "app.adminCompanies.websiteTxt" })}
                </LabelWembii>
                <Input
                  type="text"
                  name="webSite"
                  id="company-webSite"
                  placeholder={intl.formatMessage({ id: "app.adminCompanies.websiteTxt" })}
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  invalid={isWebsiteInvalid}
                />

                {fieldLengthFeedback(website, 55)}
              </FormGroup>
              <FormGroup>
                <LabelWembii forid="company-phone">
                  {intl.formatMessage({ id: "app.phoneTxt" })}
                </LabelWembii>
                <PhoneInput
                  name="phone"
                  id="company-phone"
                  placeholder={intl.formatMessage({ id: "app.phoneTxt" })}
                  value={phone}
                  onChange={(v) => setPhone(v || "")}
                />
              </FormGroup>
              <FormGroup>
                <LabelWembii forid="company-country" required>
                  {intl.formatMessage({ id:"app.adminCompanies.countryTxt" })}
                </LabelWembii>
                <Input
                  type="text"
                  name="country"
                  id="company-country"
                  placeholder={intl.formatMessage({ id:"app.adminCompanies.countryTxt" })}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  invalid={isCountryInvalid}
                />
                {fieldLengthFeedback(country, 35)}
              </FormGroup>
              <FormGroup>
                <LabelWembii forid="company-city" required>
                  {intl.formatMessage({ id:"app.adminCompanies.cityTxt" })}
                </LabelWembii>
                <Input
                  type="text"
                  name="city"
                  id="company-city"
                  placeholder={intl.formatMessage({ id:"app.adminCompanies.cityTxt" })}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  invalid={isCityInvalid}
                />
                {fieldLengthFeedback(city, 35)}
              </FormGroup>
              <Button color="primary" block disabled={!!disabled}>
                {intl.formatMessage({ id: "app.adminCompanies.createCompany" })}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
