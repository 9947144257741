import QueryString from "query-string";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GOOGLE_PROJECTS_SIDE_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useGoogleCore } from "../../../../cores/google";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";

export default function GooglePopUp() {
  const { search } = useLocation();
  const { getProjects } = useGoogleCore();
  const { company } = useMeCore();
  const { goToSocial } = useRouterCore();
  const { turnOnModal } = useGlobalCore();

  useEffect(() => {
    const { code } = QueryString.parse(search);
    if (code) getProjects(company!.id, code as string);
    goToSocial();
    turnOnModal(GOOGLE_PROJECTS_SIDE_MODAL, true);
  }, [search, company, goToSocial, getProjects, turnOnModal]);

  return <div></div>;
}
