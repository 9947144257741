import formApiClient from "../../helpers/services/form-api-client";
import { GetFacebookFormResponse } from "../interfaces/api/get-facebook-form";
import { GetFacebookFormsResponse } from "../interfaces/api/get-facebook-forms";
import { facebookFormMapper } from "./mapper";

export const facebookFormApiService = {
  getFacebookForms(companyId: string) {
    return formApiClient()
      .get<GetFacebookFormsResponse>(`form/facebook`, { params: { companyId } })
      .then(({ data }) => facebookFormMapper.mapResponseToFacebookForms(data));
  },
  getFacebookForm(companyId: string, formId: string) {
    return formApiClient()
      .get<GetFacebookFormResponse>(`form/facebook/${formId}`, { params: { companyId } })
      .then(({ data }) => facebookFormMapper.mapResponseToFacebookForm(data));
  }
};
