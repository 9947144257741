import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Container, Label } from "reactstrap";
import { useWebChatCore } from "../../../cores/conversations";
import { Conversation } from "../../../cores/conversations/interfaces/api/get-conversations";
import { PanelWembii } from "../PanelWembii";
import { useSocketCore } from "../../../cores/socket";
import { getColor, getFirstLetters } from "../../utils/stringUtils";
import { useMeCore } from "../../../cores/me";
import { useSocialChatCore } from "../../../cores/socialChat";
import Avatar from "../Avatar";
import {
  FACEBOOK_COLOR,
  INSTAGRAM_COLOR,
  WHATSAPP_COLOR,
} from "../../../constants/socialColors.constant";
import { useIntl } from "react-intl";
import { ContactStateOptions } from "../../../cores/contacts/interfaces/ContactState";
import { useContactCore } from "../../../cores/contacts";
import {
  faArchive,
  faTrash,
  faUndo,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Conversations } from "../../../cores/socialChat/interfaces/conversations-group.interface";
import AssigneeRow from "./assigneesRow/AssigneesRow";
import {
  WEB_CHAT_LOADING,
  INSTAGRAM_CHAT_LOADING,
  FACEBOOK_CHAT_LOADING,
  WHATSAPP_CHAT_LOADING,
} from "../../../constants/loading.constant";
import { UpdateLoadingCard } from "../UpdateLoadingCard";
import { useGlobalCore } from "../../../cores/globals";
import { useFilterDate } from "../../../hooks/useFilterDate/useFilterDate.hook";
import OwnerContainer from "../contacts/OwnerContainer";
import { ContactOwner } from "../../../cores/contacts/interfaces/api/get-contact";

export const ConversationList: React.FC<{
  onSelect: (arg0: string) => void;
  conversations: Conversations;
  filter: string;
}> = ({ onSelect, conversations, filter }) => {
  const [fastAssignment, setFastAssignment] = useState({
    id: "",
    active: false,
  });
  const { user, company, isAdmin, isReseller, companyRole, socialSettings } =
    useMeCore();
  const { putContact } = useContactCore();
  const { isLoading } = useGlobalCore();
  const intl = useIntl();
  const {
    selected,
    updateConversationStatus,
    updateConversationOwners,
    getConversations,
  } = useWebChatCore();
  const {
    conversationStatus,
    selectedSocial,
    dateFilter,
    getFacebookConversations,
    getInstagramConversations,
    getWhatsappConversations,
  } = useSocialChatCore();

  const { onlineClients, subscribeToChannel, leaveFromChannel } =
    useSocketCore();
  const { states, contactStateRequest } = useContactCore();

  const [searchResults, setSearchResults] = useState<Conversation[]>([]);
  const { filterDateConversation } = useFilterDate(dateFilter);
  const [owners, setOwners] = useState<ContactOwner[]>([]);

  useEffect(() => {
    const results = conversations[selectedSocial][conversationStatus]
      .sort((a, b) => (b.timestamp.full < a.timestamp.full ? -1 : 1))
      .filter((conversation) =>
        conversation.participants.find((participant) =>
          participant.name.toLowerCase().includes(filter.toLowerCase())
        )
      )
      .filter(filterDateConversation);
    setSearchResults(results);
  }, [filter, conversations, conversationStatus, selectedSocial, dateFilter]);

  const itsOnline = useCallback(
    (id: string) => onlineClients.includes(id),
    [onlineClients]
  );
  const canModify = useMemo(
    () => isAdmin || isReseller || companyRole?.owner || companyRole?.admin,
    [isAdmin, isReseller, companyRole, user]
  );
  const onAddingOwner = useCallback(
    async (user, conversation: Conversation) => {
      const mergedOwners = [...owners, user];
      setOwners(mergedOwners);
      const participant = conversation.participants[0];

      await putContact(
        company?.id!,
        participant.id,
        {
          name: conversation.participants[0].name,
          owners: mergedOwners.map((owner) => owner.id),
        },
        () => {
          getConversations(company?.id!, contactStateRequest);
          if (socialSettings?.facebook) {
            getFacebookConversations(company?.id!);
          }
          if (socialSettings?.whatsapp) {
            getWhatsappConversations(company?.id!, contactStateRequest);
          }
          if (socialSettings?.instagramMensseger) {
            getInstagramConversations(company?.id!);
          }
          updateConversationOwners(
            conversation.id,
            conversationStatus,
            selectedSocial,
            mergedOwners,
            participant.id
          );
          setFastAssignment({
            active: !fastAssignment.active,
            id: conversation.id,
          });
        }
      );
    },
    [owners, putContact, setOwners, updateConversationOwners, company?.id]
  );

  const onRemovingOwner = useCallback(
    async (id: string, conversation: Conversation) => {
      const participant = conversation.participants[0];
      const newOwners = (participant.owners ?? []).filter(
        (owner) => owner.id !== id
      );
      setOwners(newOwners);
      await putContact(
        company?.id!,
        participant.id,
        {
          name: participant.name,
          owners: newOwners.map((owner) => owner.id) ?? [],
        },
        () => {
          getConversations(company?.id!, contactStateRequest);
          if (socialSettings?.facebook) {
            getFacebookConversations(company?.id!);
          }
          if (socialSettings?.whatsapp) {
            getWhatsappConversations(company?.id!, contactStateRequest);
          }
          if (socialSettings?.instagramMensseger) {
            getInstagramConversations(company?.id!);
          }
          updateConversationOwners(
            conversation.id,
            conversationStatus,
            selectedSocial,
            newOwners,
            participant.id
          );
          setFastAssignment({
            active: !fastAssignment.active,
            id: conversation.id,
          });
        }
      );
    },
    [owners, company?.id, setOwners, putContact]
  );

  const handleSelectConversation = useCallback(
    async (conversation) => {
      if (selected && selected.type === "web") leaveFromChannel(selected.room!);
      onSelect(conversation);
    },
    [onSelect, selected, leaveFromChannel]
  );
  useEffect(() => {
    if (user && user.id && selected && selected.room) {
      subscribeToChannel(user?.id!, selected.id, selected.room, company?.id!);
    }
  }, [selected, user, subscribeToChannel, company]);

  const getParticipantNames = useCallback(
    (conversation: Conversation) =>
      conversation.participants
        .map((participant) => participant.name)
        .join(", "),
    []
  );

  const handleUpdateConversationStatus = useCallback(
    async (conversation: Conversation) => {
      await updateConversationStatus(
        {
          companyId: Number(company?.id),
          conversationId: conversation.id,
          socialName: conversation.type,
          status: "inTrash",
        },
        conversationStatus
      );
    },

    [company?.id, updateConversationStatus, conversationStatus]
  );

  const handleConversationSelection = useCallback(
    async (conversation: Conversation) => {
      await updateConversationStatus(
        {
          companyId: Number(company?.id),
          conversationId: conversation.id,
          socialName: conversation.type,
          status: conversationStatus === "archived" ? "active" : "archived",
        },
        conversationStatus
      );
    },
    [company?.id, conversationStatus, updateConversationStatus]
  );

  const statesValues = useMemo(
    () => [
      ...ContactStateOptions.map((state) => ({
        value: state.value,
        label: intl.formatMessage({ id: state.label }),
      })),
      ...states.map((state) => ({ value: state.id, label: state.name })),
    ],
    [states, intl]
  );

  const getStateValue = useCallback(
    (state) => {
      return statesValues.find((stt) => stt.value === state)?.label;
    },
    [statesValues]
  );

  const getLabelSnippet = ({ snippet, messageType }: Conversation) => {
    if (messageType) {
      switch (messageType) {
        case "text":
          return snippet;
        case "audio":
          return intl.formatMessage({ id: "app.chat.sentAudio" });
        case "video":
          return intl.formatMessage({ id: "app.chat.sentVideo" });
        case "image":
          return intl.formatMessage({ id: "app.chat.sentImage" });
        case "document":
          return intl.formatMessage({ id: "app.chat.sentAttachment" });
        default:
          return snippet;
      }
    }
    return snippet;
  };
  return (
    <Container
      className="p-0 position-relative overflow-auto w-auto"
      style={{ height: "72vh" }}
    >
      {isLoading(WEB_CHAT_LOADING) ? (
        <UpdateLoadingCard
          loadingId={WEB_CHAT_LOADING}
          text={intl.formatMessage({ id: "app.chat.UpdatingWebChats" })}
        />
      ) : isLoading(INSTAGRAM_CHAT_LOADING) ? (
        <UpdateLoadingCard
          loadingId={INSTAGRAM_CHAT_LOADING}
          text={intl.formatMessage({ id: "app.chat.UpdatingInstagramChats" })}
        />
      ) : isLoading(FACEBOOK_CHAT_LOADING) ? (
        <UpdateLoadingCard
          loadingId={FACEBOOK_CHAT_LOADING}
          text={intl.formatMessage({ id: "app.chat.UpdatingFacebookChats" })}
        />
      ) : isLoading(WHATSAPP_CHAT_LOADING) ? (
        <UpdateLoadingCard
          loadingId={WHATSAPP_CHAT_LOADING}
          text={intl.formatMessage({ id: "app.chat.UpdatingWhatsappChats" })}
        />
      ) : (
        <>
          {searchResults.map((conversation) => (
            <PanelWembii
              key={conversation.id}
              className="position-relative pointer  p-2 my-2 border  "
            >
              <div className="d-flex align-items-center justify-content-between ">
                <div
                  onClick={() => handleSelectConversation(conversation)}
                  className="d-flex  align-items-center w-75"
                >
                  <div className="d-flex align-items-center">
                    <div className="align-self-start">
                      {conversation.type === "facebook" && (
                        <FontAwesomeIcon
                          className=""
                          icon={["fab", "facebook"]}
                          size="lg"
                          color={FACEBOOK_COLOR}
                        />
                      )}
                      {conversation.type === "instagram" && (
                        <FontAwesomeIcon
                          className=""
                          icon={["fab", "instagram"]}
                          size="lg"
                          color={INSTAGRAM_COLOR}
                        />
                      )}
                      {conversation.type === "whatsapp" && (
                        <FontAwesomeIcon
                          className=""
                          icon={["fab", "whatsapp"]}
                          size="lg"
                          color={WHATSAPP_COLOR}
                        />
                      )}
                      {conversation.type === "web" && (
                        <div
                          className={cn("rounded-circle bg-secondary", {
                            "bg-success": itsOnline(
                              conversation.participants[0].id
                            ),
                          })}
                          style={{
                            width: 16,
                            height: 16,
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                  <div className="position-relative">
                    <Avatar
                      className="mr-2 border"
                      label={getFirstLetters(conversation.participants[0].name)}
                      image={conversation.participants[0].avatarUrl}
                      backgroundColor={getColor(parseInt(conversation.id))}
                      color="white"
                      width={"56px"}
                      height={"56px"}
                    />
                    <AssigneeRow
                      avatars={conversation.participants[0].owners ?? []}
                    />
                  </div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="d-flex align-items-center flex-wrap">
                        <Label className="text-primary pointer font-weight-bold m-0 d-flex align-items-center">
                          <span className="mr-2">{`${getParticipantNames(
                            conversation
                          )}`}</span>
                        </Label>
                        {conversation.participants[0].state !== undefined && (
                          <Badge color="light" className="text-grey">
                            {getStateValue(conversation.participants[0].state)}
                          </Badge>
                        )}
                        {conversation.unreadCount > 0 && (
                          <Badge
                            color="danger"
                            className="text-white text-start mx-2 my-auto"
                            pill
                          >
                            {conversation.unreadCount}
                          </Badge>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-start flex-wrap">
                      <Label className="text-muted text-truncate m-0">
                        {conversation.type === "whatsapp"
                          ? getLabelSnippet(conversation)
                          : conversation.snippet}
                      </Label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="position-absolute right-0 my-3">
                <div className="d-flex my-auto relative ">
                  {fastAssignment.active &&
                    fastAssignment.id === conversation.id && (
                      <div
                        key={conversation.id}
                        style={{ zIndex: 100, right: 150, top: 30 }}
                        className="bg-white shadow-sm px-2 my-2 rounded-lg border position-absolute"
                      >
                        <small className="text-nowrap">Asignación rápida</small>
                        <div className="d-flex">
                          <OwnerContainer
                            contacts={owners}
                            canModify={canModify}
                            onAdding={(data) =>
                              onAddingOwner(data, conversation)
                            }
                            onRemoving={(data) =>
                              onRemovingOwner(data, conversation)
                            }
                          />
                        </div>
                      </div>
                    )}
                  {(conversation.participants[0].socials !== undefined ||
                    conversation.type === "web") && (
                    <button
                      onClick={() => {
                        setFastAssignment({
                          active: !fastAssignment.active,
                          id: conversation.id,
                        });
                        setOwners(conversation.participants[0]!.owners ?? []);
                      }}
                      className="position-relative btn text-center text-muted "
                    >
                      <FontAwesomeIcon
                        icon={faUserPlus}
                        size="sm"
                        color="gray"
                      />
                    </button>
                  )}
                  <button
                    onClick={() => handleConversationSelection(conversation)}
                    className="position-relative btn text-center text-muted "
                  >
                    {conversationStatus === "archived" ? (
                      <FontAwesomeIcon icon={faUndo} size="sm" color="gray" />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArchive}
                        size="sm"
                        color="gray"
                      />
                    )}
                  </button>
                  {(conversationStatus === "archived" ||
                    conversationStatus === "active") && (
                    <button
                      onClick={() =>
                        handleUpdateConversationStatus(conversation)
                      }
                      className="position-relative btn text-center text-danger "
                    >
                      <FontAwesomeIcon icon={faTrash} size="sm" color="red" />
                    </button>
                  )}
                </div>
              </div>

              <Label
                className="d-flex text-muted position-absolute m-0 pr-2 float-right float-top-right"
                size="sm"
              >
                {conversation.timestamp.date}
              </Label>
            </PanelWembii>
          ))}
        </>
      )}
    </Container>
  );
};
