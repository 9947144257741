import cn from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { COLOR_DANGER } from "../../../../constants/theme";
import { PanelFile } from "../../../../cores/boards/interfaces/PanelFile";
import { v4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";
import { UPLOADING_BOARD_FILES } from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import _ from "lodash";
import { beforeUploadFile, UploadFileType } from "../../../../cores/utils";
import VideoPreview from "../preview/VideoPreview";
import ImagePreview from "../preview/ImagePreview";
import PdfPreview from "../preview/PdfPreview";
import { useIntl } from "react-intl";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant";
import { BOARD_CONFIG_RULES } from "../../../../cores/boards/config";
import ExcelPreview from "../preview/ExcelPreview";
import DocxPreview from "../preview/DocxPreview";
import { Board } from "../../../../cores/boards/interfaces/api/get-board";

interface Props {
  onSubmit: (arg0: PanelFile[]) => void
  board: Board | null;
}

export default function FileSelector({ onSubmit, board }: Props) {
  const intl = useIntl();
  const [files, setFiles] = useState<PanelFile[]>([]);
  const { isLoading } = useGlobalCore();

  const ref = useRef<any>(null);

  const filesAllowed = useMemo(() => 
    (board?.type === BoardType.ACTIVITY) ?
      [
        UploadFileType.JPEG,
        UploadFileType.JPG,
        UploadFileType.PNG,
        UploadFileType.PDF,
        UploadFileType.MOV,
        UploadFileType.MP4,
        UploadFileType.EXCEL,
        UploadFileType.CSV,
        UploadFileType.DOCX
      ] :
      [
        UploadFileType.PDF,
        UploadFileType.JPEG,
        UploadFileType.JPG,
        UploadFileType.PNG,
        UploadFileType.EXCEL,
        UploadFileType.CSV,
        UploadFileType.DOCX
      ]
  , [board]);
  
  const onChange = (fls: FileList | null) => {
    if (fls) {
      const newFiles: PanelFile[] = []
      _.forEach(fls, (file) => {
        const { name, size, lastModified, type } = file;
        const exist = files.some(item => item.name === name);

        if (!exist) {
          const error = !beforeUploadFile(file, BOARD_CONFIG_RULES.maxFileSize, filesAllowed)

          const id = v4();
          newFiles.push({
            id,
            lastModified,
            name,
            size,
            type,
            data: file,
            error,
          });
        }
      });
        
      setFiles([...files, ...newFiles]);
    }
  };

  const onUploadFiles = useCallback(() => {
    const filesWithoutErrors = files.filter((file) => !file.error);
    onSubmit(filesWithoutErrors);
  }, [files, onSubmit]);

  const onRemoveFile = useCallback((id: string) => {
    const newFiles = files.filter((file) => file.id !== id);
    setFiles(newFiles);
  }, [files]);

  const fileSelector = (file: PanelFile) => {
    if (file.type === UploadFileType.PDF)
      return <PdfPreview />
    if (file.type === UploadFileType.EXCEL || file.type === UploadFileType.CSV)
      return <ExcelPreview />
    if (file.type === UploadFileType.DOCX)
      return <DocxPreview />
    if (file.type === UploadFileType.MOV || file.type === UploadFileType.MP4)
      return <VideoPreview file={file} />

    return <ImagePreview file={file} />
  };

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 py-5">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.activity.selectFile" })}
            </h2>

            <div className="d-flex justify-content-center">
              <Label for="crmFilesUploader" className="btn btn-primary">
                {intl.formatMessage({ id: "app.activity.selectFile" })}
              </Label>
              <Input
                ref={ref}
                type="file"
                id="crmFilesUploader"
                className="d-none"
                accept={`${filesAllowed.join(',')},.docx`}
                multiple
                onChange={(e) => onChange(e.target.files)}
              />
            </div>

            {files.length === 0 && (
              <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center text-muted">
                <FontAwesomeIcon
                  icon={["fas", "file"]}
                  size="5x"
                  className="mb-1"
                  color="gray"
                />
                {intl.formatMessage({ id: "app.activity.notSelectFile" })}
              </div>
            )}

            <div className="d-flex justify-content-center flex-wrap py-4 overflow-auto">
              {files.map((file) => (
                <div key={file.id} className={cn(
                  "panelFilePreview neu-out m-2 position-relative",
                  { "fileError": file.error }
                )}>
                  {fileSelector(file)}
                  {file.error && (
                    <FontAwesomeIcon
                      icon={["fas", "ban"]}
                      size="1x"
                      className="position-absolute m-1 top-0 right-0"
                      color={COLOR_DANGER}
                    />
                  )}
                  <div className="fileButtons w-100 h-100">
                    <FontAwesomeIcon
                      icon={["fas", "times"]}
                      size="2x"
                      className="pointer"
                      color={COLOR_DANGER}
                      onClick={() => onRemoveFile(file.id)}
                    />
                  </div>
                </div>
              ))}
            </div>

            <UpdateLoadingCard
              loadingId={UPLOADING_BOARD_FILES}
              text="Subiendo los archivos"
            />
            {files.length > 0 && (
              <div className="m-4 text-center">
                <Button
                  color="primary"
                  onClick={onUploadFiles}
                  disabled={isLoading(UPLOADING_BOARD_FILES)}
                >
                  {intl.formatMessage({ id: "app.activity.add" })}
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
