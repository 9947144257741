export enum AssignmentType {
  ACTIVITY = "activity",
  CRM = "crm",
  TASK = "task",
}

export interface From {
  id: string;
  name: string;
  email: string;
}

//TODO: PROBAR COMO REACCIONA LA NOTI
export interface MessageNotification {
  id: string;
  type: string;
  timestamp: string;
  from: From;
  message: string;
  messageType: string;
}

export interface ConversationNotification {
  id: string;
  type: string;
  timestamp: string;
  contact: From;
}

export interface SocialPostNotification {
  social: string;
  status: number;
  content: string;
}

export interface ContactsImportedNotification {
  origin: string;
  amount: number;
}

export interface ImportingProcessNotification {
  status: boolean;
}

export interface NewContactsNotification {
  origin: string;
}

export interface ContactExportedNotification {
  origin: string;
  amount: number;
}

export interface TaskAssignmentNotification {
  type: AssignmentType;
  title: string;
  id: string;
}

export interface SubscriptionNotification {
  text: string;
  type: number;
}

export interface Notification {
  id: string;
  status: number;
  type: string;
  data:
    | MessageNotification
    | ConversationNotification
    | SocialPostNotification
    | SubscriptionNotification
    | ContactExportedNotification
    | ContactsImportedNotification
    | TaskAssignmentNotification
    | ImportingProcessNotification;
}

export interface NotificationsPayload {
  notifications: Notification[];
  count: number;
}

export interface GetNotificationsResponse {
  message: string;
  payload: NotificationsPayload;
}
