import React, { useCallback, useMemo } from "react";
import { useMeCore } from "../../../../cores/me";
import { useIntl } from "react-intl";
import { useMercadolibreCore } from "../../../../cores/mercadolibre";
import { Col, Row } from "reactstrap";
import { Flag } from "../../Flag";

export default function Countries() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { login } = useMercadolibreCore();

  const handleBind = useCallback(async (country) => {
    const loginUrl = await login(company!.id, country);
    if (loginUrl) window.open(loginUrl, "_self");
  }, [company, login]);

  const countries = useMemo(() => [
    { id: 1, country: "ar", intlLabel: "app.social.argentina" },
    { id: 2, country: "bo", intlLabel: "app.social.bolivia" },
    { id: 3, country: "br", intlLabel: "app.social.brazil" },
    { id: 4, country: "cl", intlLabel: "app.social.chile" },
    { id: 5, country: "co", intlLabel: "app.social.colombia" },
    { id: 6, country: "cr", intlLabel: "app.social.costarica" },
    { id: 7, country: "do", intlLabel: "app.social.dominicana" },
    { id: 8, country: "ec", intlLabel: "app.social.ecuador" },
    { id: 9, country: "gt", intlLabel: "app.social.guatemala" },
    { id: 10, country: "hn", intlLabel: "app.social.honduras" },
    { id: 11, country: "mx", intlLabel: "app.social.mexico" },
    { id: 12, country: "ni", intlLabel: "app.social.nicaragua" },
    { id: 13, country: "pa", intlLabel: "app.social.parana" },
    { id: 14, country: "pe", intlLabel: "app.social.peru" },
    { id: 15, country: "py", intlLabel: "app.social.paraguay" },
    { id: 16, country: "sv", intlLabel: "app.social.salvador" },
    { id: 17, country: "uy", intlLabel: "app.social.uruguay" },
    { id: 18, country: "ve", intlLabel: "app.social.venezuela" },
  ], []);

  return (
    <div className="p-4 h-100 d-flex justify-content-center align-items-center">
      <Row>
        {countries.map((country) => (
          <Col md="6" key={country.id}>
            <div
              className="pointer d-flex align-items-center m-1 text-center text-primary-on-hover"
              onClick={() => handleBind(country.country)}
            >
              <Flag flag={country.country} width={50} />
              <h5 className="ml-2 w-100 text-bold-on-hover">
                {intl.formatMessage({ id: country.intlLabel })}
              </h5>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}
