import cn from "classnames";
import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { Plan } from "../../../cores/me/interfaces/api/get-meCompany";
import { useIntl } from "react-intl";

interface Props {
  plan: Plan;
  show?: boolean;
  className?: string;
}

export const PlanBadge = (props: Props) => {
  const {
    plan: { name, color },
    show,
    className,
  } = props;

  useEffect(() => {
    if (!show)
      setIsShown(false);
  }, [show]);

  const [isShown, setIsShown] = useState(false);
  const intl = useIntl();

  return (
    <Badge
      onMouseEnter={() => !show && setIsShown(true)}
      onMouseLeave={() => !show && setIsShown(false)}
      key={`tooltip-${intl.formatMessage({ id: name })}`}
      id={`tooltip-${intl.formatMessage({ id: name })}`}
      pill
      style={{
        minWidth: "30px",
        minHeight: "30px",
        backgroundColor: color || "#c5e384",
      }}
      className={cn(className, { closed: !isShown && !show }, "d-none d-sm-block")}
    >
      <h5
        className={cn("m-0", { h5: !isShown, "h6 px-2": isShown || show })}
        style={{ lineHeight: "25px" }}
      >
        {isShown || show 
          ? intl.formatMessage({id : name})
          : intl.formatMessage({id : name})[0]
        }
      </h5>
    </Badge>
  );
};
