import { DeleteContactInputResponse } from "../../contacts/interfaces/api/delete-config";
import mainApiClient from "../../helpers/services/main-api-client";
import socialApiClient from "../../helpers/services/social-api-client";
import { DeleteCommentResponse } from "../interface/api/delete-comment";
import { GetAvailableTimeResponse } from "../interface/api/get-available-time";
import { GetCalendarResponse } from "../interface/api/get-calendar";
import { GetEventResponse } from "../interface/api/get-event";
import { GetEventsResponse } from "../interface/api/get-events";
import { GetPublicCalendarResponse } from "../interface/api/get-public-calendar";
import {
  PostCalendarTimeRequest,
  PostCalendarTimeResponse,
} from "../interface/api/post-calendar-time";
import {
  PostCommentRequest,
  PostCommentResponse,
} from "../interface/api/post-comment";
import {
  PostContactInputRequest,
  PostContactInputResponse,
} from "../interface/api/post-contact-input";
import {
  PostEventResponse,
  PostEventRequest,
} from "../interface/api/post-event";
import { PostPublicEventRequest } from "../interface/api/post-public-event";
import {
  PutCalendarRequest,
  PutCalendarResponse,
} from "../interface/api/put-calendar";
import {
  PutCommentRequest,
  PutCommentResponse,
} from "../interface/api/put-comment";
import { eventMapper } from "./mapper";

export const eventApiService = {
  postEvent(companyId: string, body: PostEventRequest) {
    return mainApiClient()
      .post<PostEventResponse>(`events`, body, {
        params: { companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToEvent(data));
  },
  deleteEvent(companyId: string, event: string) {
    return mainApiClient()
      .delete(`events/${event}`, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  getEvents(companyId: string, startDate: string, endDate: string) {
    return mainApiClient()
      .get<GetEventsResponse>(`events`, {
        params: { startDate, endDate, companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToEvents(data));
  },
  getEvent(companyId: string, eventId: string) {
    return mainApiClient()
      .get<GetEventResponse>(`events/${eventId}`, {
        params: { companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToEvent(data));
  },
  getGoogleEvent(companyId: string, eventId: string) {
    return socialApiClient()
      .get<GetEventResponse>(`google/calendar/event/${eventId}`, {
        params: { companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToEvent(data));
  },
  deleteGoogleEvent(companyId: string, event: string) {
    return socialApiClient()
      .delete(`google/calendar/event/${event}`, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  getCalendar(companyId: string) {
    return mainApiClient()
      .get<GetCalendarResponse>(`calendar`, {
        params: { companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToCalendar(data));
  },
  putCalendar(companyId: string, calendarId: string, body: PutCalendarRequest) {
    return mainApiClient()
      .put<PutCalendarResponse>(`calendar/${calendarId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToCalendar(data));
  },
  refreshCalendarId(companyId: string, calendarId: string) {
    return mainApiClient()
      .put<PutCalendarResponse>(
        `calendar/${calendarId}/calendarId`,
        {},
        {
          params: { companyId },
        }
      )
      .then(({ data }) => eventMapper.mapResponseToCalendar(data));
  },
  getAvailableTime(
    calendarId: string,
    date: string,
    timezone: string,
    duration: number
  ) {
    return mainApiClient()
      .get<GetAvailableTimeResponse>(
        `public/calendar/available/time/${calendarId}`,
        {
          params: { date, timezone, duration },
        }
      )
      .then(({ data }) => eventMapper.mapResponseToAvailableTime(data));
  },
  getPublicCalendar(calendarId: string) {
    return mainApiClient()
      .get<GetPublicCalendarResponse>(`public/calendar/${calendarId}`)
      .then(({ data }) => eventMapper.mapResponseToPublicCalendar(data));
  },
  postPublicEvent(calendarId: string, body: PostPublicEventRequest) {
    return mainApiClient()
      .post<PostEventResponse>(`public/events/${calendarId}`, body)
      .then(({ data }) => data.payload);
  },
  postCalendarTime(
    companyId: string,
    calendarId: string,
    body: PostCalendarTimeRequest
  ) {
    return mainApiClient()
      .post<PostCalendarTimeResponse>(`calendar/${calendarId}/time`, body, {
        params: { companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToTime(data));
  },
  deleteCalendarTime(companyId: string, calendarId: string, id: string) {
    return mainApiClient()
      .delete(`calendar/${calendarId}/time/${id}`, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  postContactInput(
    companyId: string,
    calendarId: string,
    config: PostContactInputRequest
  ) {
    return mainApiClient()
      .post<PostContactInputResponse>(`calendar/${calendarId}/input`, config, {
        params: { companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToInput(data));
  },
  deleteContactInput(companyId: string, calendarId: string, inputId: string) {
    return mainApiClient()
      .delete<DeleteContactInputResponse>(
        `calendar/${calendarId}/input/${inputId}`,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data.payload);
  },
  postComment(
    companyId: string,
    eventId: string,
    text: string,
    from: string
  ) {
    const body: PostCommentRequest = {
      text,
    };
    return mainApiClient()
      .post<PostCommentResponse>(`events/${eventId}/comment`, body, {
        params: { companyId,from },
      })
      .then(({ data }) => eventMapper.mapResponseToComment(data));
  },
  putComment(
    companyId: string,
    eventId: string,
    commentId: string,
    text: string
  ) {
    const body: PutCommentRequest = {
      text,
    };
    return mainApiClient()
      .put<PutCommentResponse>(`events/${eventId}/comment/${commentId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToComment(data));
  },
  deleteComment(companyId: string, eventId: string, commentId: string) {
    return mainApiClient()
      .delete<DeleteCommentResponse>(`events/${eventId}/comment/${commentId}`, {
        params: { companyId },
      })
      .then(({ data }) => eventMapper.mapResponseToId(data));
  },
};
