import QueryString from "query-string";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import { useOffice365Core } from "../../../../cores/office365";

export default function Office365PopUp2() {
  const { search } = useLocation();
  const { bind } = useOffice365Core();
  const { company, getMeUserSettings } = useMeCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    const { code } = QueryString.parse(search);

    if (code)
      bind(company!.id, code as string, true, () => {
        getMeUserSettings();
      });
    goToSocial();
  }, [search, company, bind, goToSocial, getMeUserSettings]);

  return <div></div>;
}
