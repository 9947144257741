import React from "react";
import { Col, Container, Row } from "reactstrap";
import { HISTORY_POST_LOADING } from "../../constants/loading.constant";
import { useSocialPostCore } from "../../cores/socialPost";
import { NoData } from "../component/NoData";
import { SocialPostPreview } from "../component/socialHistory/preview/SocialPostPreview";
import { SocialManagerBar } from "../component/socialManager/SocialManagerBar";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import { useIntl } from "react-intl";

export default function SocialManager() {
  const { socialPosts } = useSocialPostCore();
  const intl = useIntl();

  return (
    <Layout>
      <Header2 title={intl.formatMessage({ id: "app.sidebar.adminWembii.socialManager" })} />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <SocialManagerBar />
              <UpdateLoadingCard
                loadingId={HISTORY_POST_LOADING}
                text={intl.formatMessage({ id: "app.socialManager.searchingFaildedPublish" })}
              />
              <Container
                fluid
                className="d-flex flex-wrap justify-content-center align-items-baseline"
              >
                {socialPosts.map((post) => (
                  <SocialPostPreview
                    key={post.post.id}
                    provider={post.post.socialProvider}
                    post={post}
                    showError={false}
                  />
                ))}
                {socialPosts.length === 0 && (
                  <NoData
                    image="generic1"
                    text={intl.formatMessage({ id: "app.socialManager.noPostFailed" })}
                    horizontal
                  />
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
    </Layout>
  );
}
