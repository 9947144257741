import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Badge, Col, Row } from "reactstrap";
import { CompanyUser } from "../../cores/company/interfaces/api/get-company";
import { User } from "../../cores/session/interfaces/models/user";
import { RoleBadges } from "./companies/RoleBadge";
import { StatusBadge } from "./companies/StatusBadge";
import { PanelWembii } from "./PanelWembii";

interface Props {
  user: User & CompanyUser;
  className?: string;
  actions?: ReactNode;
  showCompanyRoles?: boolean;
  onClick?: () => void;
}

export default function UserRow({
  user,
  actions,
  onClick,
  showCompanyRoles,
}: Props) {
  const { id, firstName, lastName, email, status, phoneNumber, userRole } =
    user;
  const roles = user.companyRole;
  return (
    <PanelWembii
      className="w-100 px-4 py-3 my-2 pointer"
      onClick={() => onClick?.()}
    >
      <Row>
        <Col
          xs="12"
          lg="1"
          className="text-black-50 text-center"
        >
          {`#${id}`}
        </Col>
        <Col xs="12" lg="2" className="d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-center my-1 w-100">
            <FontAwesomeIcon icon={["far", "id-card"]} className="mr-2" />
            <span className="h6 mb-0">{`${lastName} ${firstName}`}</span>
          </div>
        </Col>
        <Col
          xs="12"
          lg="3"
          className="d-flex align-items-center justify-content-center flex-column"
        >
          <div className="d-flex align-items-center justify-content-center w-100 my-1">
            <FontAwesomeIcon icon={["far", "envelope"]} className="mr-2" />
            <span className="mb-0 text-black-50 small">{email}</span>
          </div>
          <div className="d-flex align-items-center justify-content-center w-100 my-1">
            {phoneNumber && (
              <FontAwesomeIcon icon={["fas", "phone"]} className="mr-2" />
            )}
            <span className="mb-0 text-black-50 small">{phoneNumber}</span>
          </div>
        </Col>
        {userRole && (
          <Col xs="12" lg="2" className="d-flex align-items-center">
            <div className="w-fit mx-auto">
              {Object.keys(userRole || {}).map((role, index) => (
                <Badge
                  pill
                  className="p2 px-3 text-black-50 bg-info"
                  key={index}
                >
                  <h6 className="mb-0 text-uppercase h6">{role}</h6>
                </Badge>
              ))}
            </div>
          </Col>
        )}
        <Col xs="12" lg={userRole ? "2" : "4"} className="d-flex flex-column justify-content-center">
          <div className="w-fit mx-auto">
            <StatusBadge status={status} show />
          </div>
          {roles && showCompanyRoles && (
            <div className="w-fit mx-auto d-flex align-items-center">
              <RoleBadges roles={roles} id={user.id} />
            </div>
          )}
        </Col>
        {actions && (
          <Col xs="12" lg="2" className="d-flex align-items-center">
            <div className="w-fit mx-auto mr-lg-0 ml-lg-auto">{actions}</div>
          </Col>
        )}
      </Row>
    </PanelWembii>
  );
}
