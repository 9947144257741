import { GetProductsPayloadResponse, GetProductsResponse } from "../interfaces/api/get-products";
import { GetProductPayloadResponse, GetProductResponse } from "../interfaces/api/get-product";

export const productsMapper = {
  mapResponseToProducts(response: GetProductsResponse): GetProductsPayloadResponse {
    const { products } = response?.payload;
    return { products };
  },
  mapResponseToProduct(response: GetProductResponse): GetProductPayloadResponse {
    const { product } = response?.payload;
    return { product };
  },
};
