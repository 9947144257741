import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { MercadolibreSetting } from "../interface/api/post-mercadolibre-bind";

export const postUnbindMercadolibreInitAction = createAction(
  "[Mercadolibre] Unbind Mercadolibre Init"
);
export const postUnbindMercadolibreSuccessAction = createAction(
  "[Mercadolibre] Unbind Mercadolibre Success"
);
export const postUnbindMercadolibreFailureAction = createAction(
  "[Mercadolibre] Unbind Mercadolibre Failure",
  props<{ error: string }>()
);

export const postBindMercadolibreInitAction = createAction(
  "[Mercadolibre] Bind Mercadolibre Init"
);
export const postBindMercadolibreSuccessAction = createAction(
  "[Mercadolibre] Bind Mercadolibre Success",
  props<{ settings: MercadolibreSetting }>()
);
export const postBindMercadolibreFailureAction = createAction(
  "[Mercadolibre] Bind Mercadolibre Failure",
  props<{ error: string }>()
);

export const getMercadolibreInitAction = createAction(
  "[Mercadolibre] Get Mercadolibre Settings Init"
);
export const getMercadolibreSuccessAction = createAction(
  "[Mercadolibre] Get Mercadolibre Settings Success",
  props<{ settings: MercadolibreSetting }>()
);
export const getMercadolibreFailureAction = createAction(
  "[Mercadolibre] Get Mercadolibre Settings Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindMercadolibreInitAction,
  postUnbindMercadolibreSuccessAction,
  postUnbindMercadolibreFailureAction,
  postBindMercadolibreInitAction,
  postBindMercadolibreSuccessAction,
  postBindMercadolibreFailureAction,
  getMercadolibreInitAction,
  getMercadolibreSuccessAction,
  getMercadolibreFailureAction,
});

export type MercadolibreApiAction = typeof actions;
