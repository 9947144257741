import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { GOOGLE_COLOR } from "../../../../constants/socialColors.constant";
import { IconProps } from "../Facebook/FacebookIcon";

export const GooglenAnalyticsIcon = (props: IconProps) => {
  const { active, className, size, color } = props;
  return (
    <FontAwesomeIcon
      style={{
        color: color || (active ? GOOGLE_COLOR : "gray"),
      }}
      icon={["fab", "google"]}
      className={className}
      size={size}
    />
  );
};
