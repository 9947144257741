import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const googleRoot = (state: State) => state.google;

export const settingSelector = createSelector(
  googleRoot,
  (google) => google.settings
);

export const tokenSelector = createSelector(googleRoot, ({ tokens }) => tokens);

export const projectsSelector = createSelector(
  googleRoot,
  ({ projects }) => projects
);
