import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useMemo } from "react";
import { Col, Row } from "reactstrap";
import footerImage from "../../../../assets/preview/facebook-buttons-footer.png";
import { useFacebookCore } from "../../../../cores/facebook";
import { SocialPost } from "../../../../cores/socialPost/interfaces/api/get-social-posts";
import { DropdownOptions } from "./DropdownOptions";
import { MediaPreview } from "./MediaPreview";

interface Props {
  post: SocialPost;
  onDelete: () => void;
  onEdit: () => void;
}

export const FacebookPostPreview = (props: Props) => {
  const { post, onEdit, onDelete } = props;
  const { settings } = useFacebookCore();
  const { name: defaultName, avatarUrl: defaultAvatar } = settings || {};
  const { publisherName: name, publisherAvatarUrl: avatarUrl } =
    post.post || {};

  const formattedText = useMemo(() => {
    const orderMentions = (post.mention || [])
      .slice()
      .sort((a, b) => (a.offset > b.offset ? -1 : 1));

    let txt = post.post.text;
    orderMentions.forEach((mention) => {
      const name = `<span style="color:#0d4b7e;">${mention.mentions[0].username}</span>`;
      const offset = mention.offset + mention.mentions[0].username.length + 1;
      txt = txt.slice(0, mention.offset) + name + txt.slice(offset);
    });

    return txt;
  }, [post.mention, post.post.text]);

  return (
    <>
      <Row className="facebookPreview-header">
        <Col xs="10" className="d-flex align-items-center p-0">
          <div>
            <img
              className="facebookPreview-header-avatar"
              src={avatarUrl || defaultAvatar}
              alt="avatar"
            />
          </div>
          <div>
            <div className="font-weight-bold">{name || defaultName}</div>
            <div className="text-muted">
              {post.post.postAt ? (
                moment(post.post.postAt).format("DD MMM YYYY hh:mm a")
              ) : (
                <span className="text-warning">
                  {moment(post.post.scheduleDate).format("DD MMM YYYY hh:mm a")}{" "}
                  <FontAwesomeIcon icon={["far", "clock"]} />
                </span>
              )}
            </div>
          </div>
        </Col>
        <Col xs="2" className="align-content-center d-flex justify-content-end">
          {post.post.status === 0 && (
            <DropdownOptions
              children={
                <FontAwesomeIcon
                  className="pointer"
                  icon={["fas", "ellipsis-h"]}
                />
              }
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
        </Col>
      </Row>
      <Row className="facebookPreview-description">
        <Col xs="12" className=" p-0">
          <div className="px-2 line-break-anywhere pre-wrap">
            <div
              dangerouslySetInnerHTML={{
                __html: formattedText,
              }}
            ></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="p-0">
          {post.media.length > 0 && (
            <MediaPreview
              className="facebookPreview-image"
              type={post.post.type}
              media={post.media}
            />
          )}
        </Col>
      </Row>
      <Row>
        <img
          className="facebookPreview-footer"
          src={footerImage}
          alt="footer"
        />
      </Row>
    </>
  );
};
