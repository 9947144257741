import { PostSessionResponse } from "../interfaces/api/post-session";
import { Session } from "../interfaces/models/session";

export const sessionMapper = {
  mapResponseToSession(response: PostSessionResponse): Session {
    return {
      token: response.payload.session.token,
      refreshToken: response.payload.session.refreshToken,
      user: response.payload.session.user,
    };
  },
};
