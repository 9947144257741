import React, { useCallback } from "react";
import SideModal from "../../layout/SideModal";
import {
  BOARD_CONTACTS_MODAL,
  BOARD_FILES_MODAL,
  BOARD_FILE_PREVIEW_MODAL,
  BOARD_USERS_MODAL,
  CARD_PRODUCTS_MODAL,
  CARD_TAGS_MODAL,
  CONTACT_COMPANY_BOARD_MODAL,
  DELETE_BOARD_ITEM_MODAL,
  DELETE_PRODUCT_FORM,
  PRODUCT_EXPAND_CREATION_FORM,
  PRODUCT_EXPAND_EDIT_FORM,
  QUOTE_CARD_MODAL
} from "../../../constants/modal.constant";
import { CARD_TAGS } from "../../../constants/internalModal.constant";
import CardTags from "./modal/CardTags";
import { useGlobalCore } from "../../../cores/globals";
import { useBoardCore } from "../../../cores/boards";
import { CardTag } from "../../../cores/boards/interfaces/api/get-tags";
import { useMeCore } from "../../../cores/me";

export default function BoardSideModals() {
  const {
    isModal,
    turnOffModal,
    turnOffInternalModal,
    getModalContent,
    getModalVisibility,
  } = useGlobalCore();
  const { company } = useMeCore();
  const {
    deleteTag,
    postTag,
    getCard,
    setQuote,
    card,
    boardBusiness: board,
    tags
  } = useBoardCore();

  const toggleTag = useCallback((cardTag: CardTag, selected: boolean) => {
    if (selected) {
      deleteTag(
        company?.id!,
        board?.id!,
        card?.id!,
        cardTag.id,
        () => {
          getCard(company?.id!, board?.id!, card?.list!, card?.id!);
        }
      );
    } else {
      postTag(
        company?.id!,
        board?.id!,
        card?.id!,
        cardTag.id,
        () => {
          getCard(company?.id!, board?.id!, card?.list!, card?.id!);
        }
      );
    }
  }, [postTag, deleteTag, getCard, card, board, company]);

  return (
    <>
      <SideModal
        active={isModal(CARD_TAGS_MODAL)}
        visible={getModalVisibility(CARD_TAGS_MODAL)}
        onClose={() => {
          turnOffModal(CARD_TAGS_MODAL);
          turnOffInternalModal(CARD_TAGS);
        }}
        left
      >
        <CardTags onSelecting={toggleTag} tags={tags} board={board?.id!} />
      </SideModal>
      <SideModal
        active={isModal(BOARD_USERS_MODAL)}
        visible={getModalVisibility(BOARD_USERS_MODAL)}
        onClose={() => turnOffModal(BOARD_USERS_MODAL)}
        left
      >
        {getModalContent(BOARD_USERS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_CONTACTS_MODAL)}
        visible={getModalVisibility(BOARD_CONTACTS_MODAL)}
        onClose={() => turnOffModal(BOARD_CONTACTS_MODAL)}
        left
      >
        {getModalContent(BOARD_CONTACTS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_FILES_MODAL)}
        visible={getModalVisibility(BOARD_FILES_MODAL)}
        onClose={() => turnOffModal(BOARD_FILES_MODAL)}
        left
      >
        {getModalContent(BOARD_FILES_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_FILE_PREVIEW_MODAL)}
        visible={getModalVisibility(BOARD_FILE_PREVIEW_MODAL)}
        onClose={() => turnOffModal(BOARD_FILE_PREVIEW_MODAL)}
        left
      >
        {getModalContent(BOARD_FILE_PREVIEW_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(DELETE_BOARD_ITEM_MODAL)}
        visible={getModalVisibility(DELETE_BOARD_ITEM_MODAL)}
        onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
        left
      >
        {getModalContent(DELETE_BOARD_ITEM_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_COMPANY_BOARD_MODAL)}
        visible={getModalVisibility(CONTACT_COMPANY_BOARD_MODAL)}
        onClose={() => turnOffModal(CONTACT_COMPANY_BOARD_MODAL)}
        left
      >
        {getModalContent(CONTACT_COMPANY_BOARD_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CARD_PRODUCTS_MODAL)}
        visible={getModalVisibility(CARD_PRODUCTS_MODAL)}
        onClose={() => turnOffModal(CARD_PRODUCTS_MODAL)}
        left
      >
        {getModalContent(CARD_PRODUCTS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(PRODUCT_EXPAND_CREATION_FORM)}
        visible={getModalVisibility(PRODUCT_EXPAND_CREATION_FORM)}
        onClose={() => turnOffModal(PRODUCT_EXPAND_CREATION_FORM)}
        left
      >
        {getModalContent(PRODUCT_EXPAND_CREATION_FORM)}
      </SideModal>
      <SideModal
        active={isModal(DELETE_PRODUCT_FORM)}
        visible={getModalVisibility(DELETE_PRODUCT_FORM)}
        onClose={() => turnOffModal(DELETE_PRODUCT_FORM)}
        left
      >
        {getModalContent(DELETE_PRODUCT_FORM)}
      </SideModal>
      <SideModal
        active={isModal(PRODUCT_EXPAND_EDIT_FORM)}
        visible={getModalVisibility(PRODUCT_EXPAND_EDIT_FORM)}
        onClose={() => turnOffModal(PRODUCT_EXPAND_EDIT_FORM)}
        left
      >
        {getModalContent(PRODUCT_EXPAND_EDIT_FORM)}
      </SideModal>
      <SideModal
        active={isModal(QUOTE_CARD_MODAL)}
        visible={getModalVisibility(QUOTE_CARD_MODAL)}
        onClose={() => {
          turnOffModal(QUOTE_CARD_MODAL)
          setQuote({
            id: "",
            name: "",
            creationDate: null,
            expirationDate: null,
            owner: null,
            ownerCompany: null,
            comments: "",
            terms: "",
            company: null,
            contacts: [],
            signature: false,
            currency: "",
            products: [],
            discounts: [],
            fees: [],
            taxes: [],
          })
        }}
      >
        {getModalContent(QUOTE_CARD_MODAL)}
      </SideModal>
    </>
  );
}
