import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { WHATSAPP_COLOR } from "../../../constants/socialColors.constant";
import { COLOR_BLACK } from "../../../constants/theme";
import { ContactSource } from "../../../cores/contacts/enum/ContactSource.enum";
import { NewContactsNotification } from "../../../cores/notifications/interfaces/api/get-notifications";

interface Props {
  notification: NewContactsNotification; 
}

export default function NewContactNotificationItem({
  notification
}: Props) {
  const title = useMemo(() => {
    switch (notification.origin) {
      case ContactSource.CHAT:
        return "Conversaciones";
      case ContactSource.FORM:
        return "Formulario Web";
      case ContactSource.WHATSAPPCHAT:
        return "Whatsapp";
    }
  }, [notification]);

  const icon = useMemo(() => {
    switch (notification.origin) {
      case ContactSource.CHAT:
        return <FontAwesomeIcon
          icon={["fas", "comments"]}
          className="mr-2"
          size="2x"
          color={COLOR_BLACK}
        />;
      case ContactSource.FORM:
        return <FontAwesomeIcon
          icon={["fab", "wpforms"]}
          className="mr-2"
          size="2x"
          color={COLOR_BLACK}
        />;
      case ContactSource.WHATSAPPCHAT:
        return <FontAwesomeIcon
          icon={["fab", "whatsapp"]}
          className="mr-2"
          size="2x"
          color={WHATSAPP_COLOR}
        />;
    }
  }, [notification]);
  
  return (
    <div className="d-flex align-items-center border-bottom mb-1">
      {icon}
      <div className="d-flex flex-column overflow-hidden">
        <span className="capitalize">{title}</span>

        <span className="text-truncate text-success">
          <FontAwesomeIcon
            icon={["fas", "check"]}
            className="mr-2"
          />
          Nuevo contacto creado
        </span>
      </div>
    </div>
  );
}
