import { AxiosInstance } from "axios";
import { store } from "../../../../redux/store";
import { User } from "../../../session/interfaces/models/user";
import { sessionApiService } from "../../../session/services/api";
import { sessionClearAction } from "../../../session/store/actions";
import { sessionSuccessAction } from "../../../session/store/api-actions";

export const refreshTokenInterceptor = (
  instance: AxiosInstance,
  refreshToken: string | null,
  user: User | null
) =>
  async function (error: any) {
    try {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401)
        if (!originalRequest._retry) {
          originalRequest._retry = true;

          const session = await sessionApiService.postSessionRefresh(
            user?.email!,
            refreshToken!
          );

          store.dispatch(sessionSuccessAction({ session }));

          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: session.token,
          };
          return instance(originalRequest);
        } else {
          store.dispatch(sessionClearAction());
        }
    } catch (e) {
      console.error("Error Refreshing Token", e);
      store.dispatch(sessionClearAction());
    }
    return Promise.reject(error);
  };
