import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const linkedInRoot = (state: State) => state.linkedIn;

export const settingSelector = createSelector(
  linkedInRoot,
  (linkedIn) => linkedIn.settings
);

export const profilesSelector = createSelector(
  linkedInRoot,
  (linkedIn) => linkedIn.profiles
);
