import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationApiService } from "./services/api";
import {
  clearNotifications,
  getNotificationsFailureAction,
  getNotificationsInitAction,
  getNotificationsSuccessAction,
  getSystemNotificationsFailureAction,
  getSystemNotificationsInitAction,
  getSystemNotificationsSuccessAction,
  getUnreadNotificationsSuccessAction,
  putReadNotificationFailureAction,
  putReadNotificationInitAction,
  putReadNotificationsFailureAction,
  putReadNotificationsInitAction,
  putReadNotificationsSuccessAction,
  putReadNotificationSuccessAction,
} from "./store/api-actions";
import {
  countSelector,
  notificationsSelector,
  infoMessageEnSelector,
  infoMessageEsSelector,
  infoMessagePtSelector,
  infoMessageSelector,
  unreadCountSelector,
  unreadNotificationsSelector,
} from "./store/selector";

export function useNotificationCore() {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const unreadNotifications = useSelector(unreadNotificationsSelector);
  const unreadCount = useSelector(unreadCountSelector);
  const count = useSelector(countSelector);
  const infoMessage = useSelector(infoMessageSelector);
  const infoMessageEs = useSelector(infoMessageEsSelector);
  const infoMessageEn = useSelector(infoMessageEnSelector);
  const infoMessagePt = useSelector(infoMessagePtSelector);
  const getNotifications = useCallback(
    async (companyId: string, page: number) => {
      try {
        dispatch(getNotificationsInitAction());
        const { notifications, count } =
          await notificationApiService.getNotifications(companyId, {
            page,
          });
        dispatch(
          getNotificationsSuccessAction({ notifications, count })
        );
        return notifications.length;
      } catch (error: any) {
        console.error(error);
        dispatch(getNotificationsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getUnreadNotifications = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getNotificationsInitAction());
        const { notifications, count } =
          await notificationApiService.getNotifications(
            companyId,
            { page: 1, type: "short" },
            0
          );
        dispatch(getUnreadNotificationsSuccessAction({ notifications, count }));
      } catch (error: any) {
        console.error(error);
        dispatch(getNotificationsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putReadNotifications = useCallback(
    async (companyId: string) => {
      try {
        dispatch(putReadNotificationsInitAction());
        await notificationApiService.putReadNotifications(companyId);
        dispatch(putReadNotificationsSuccessAction());
      } catch (error: any) {
        console.error(error);
        dispatch(putReadNotificationsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putReadNotification = useCallback(
    async (companyId: string, notificationId: string) => {
      try {
        dispatch(putReadNotificationInitAction());
        const { id } = await notificationApiService.putReadNotification(
          companyId,
          notificationId
        );
        dispatch(putReadNotificationSuccessAction({ id }));
      } catch (error: any) {
        console.error(error);
        dispatch(putReadNotificationFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getSystemNotifications = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getSystemNotificationsInitAction());
        const response = await notificationApiService.getSystemNotifications(
          companyId
        );
        dispatch(getSystemNotificationsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getSystemNotificationsFailureAction({ error }));
      }
    },
    [dispatch]
  );

  return {
    notifications,

    count,
    unreadCount,
    infoMessage,
    infoMessageEn,
    infoMessageEs,
    infoMessagePt,
    getNotifications,
    clearNotifications: () => dispatch(clearNotifications()),
    putReadNotifications,
    putReadNotification,
    getSystemNotifications,
    unreadNotifications,
    getUnreadNotifications,
  };
}
