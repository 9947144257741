import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Reseller } from "../interfaces/api/get-resellers";

export const createResellerInitAction = createAction("[Reseller] Create Init");
export const createResellerSuccessAction = createAction(
  "[Reseller] Create Success",
  props<{ reseller: Reseller }>()
);
export const createResellerFailureAction = createAction(
  "[Reseller] Create Failure",
  props<{ error: string }>()
);

export const getResellersInitAction = createAction(
  "[Reseller] Fetch Resellers Init"
);
export const getResellersSuccessAction = createAction(
  "[Reseller] Fetch Resellers Success",
  props<{ resellers: Reseller[] }>()
);
export const getResellersFailureAction = createAction(
  "[Reseller] Fetch Resellers Failure",
  props<{ error: string }>()
);

export const putResellerInitAction = createAction(
  "[Reseller] Put Reseller Init"
);
export const putResellerSuccessAction = createAction(
  "[Reseller] Put Reseller Success",
  props<{ reseller: Reseller }>()
);
export const putResellerFailureAction = createAction(
  "[Reseller] Put Reseller Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  createResellerInitAction,
  createResellerSuccessAction,
  createResellerFailureAction,
  getResellersInitAction,
  getResellersSuccessAction,
  getResellersFailureAction,
  putResellerInitAction,
  putResellerSuccessAction,
  putResellerFailureAction,
});

export type ResellerApiAction = typeof actions;
