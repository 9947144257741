import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useMeCore } from "../../../../cores/me";
import { useWooCommerceCore } from "../../../../cores/wooCommerce";
import { CALLBACK_URL, SCOPE } from "../../../../cores/wooCommerce/config";
import { WooCommerceIcon } from "./WooCommerceIcon";

export const WooCommerceBindForm = () => {
  const intl = useIntl();
  const { name, setName, setUrl, url, login } = useWooCommerceCore();
  const { company } = useMeCore();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const loginUrl = await login(company!.id, SCOPE, CALLBACK_URL, url);
      if (loginUrl) window.open(loginUrl, "_self");
    },
    [company, login, url]
  );

  const disabled = useMemo(() => !name || !url, [name, url]);

  return (
    <div className="p-4">
      <h2 className="h2 text-center mb-4">
        Ingresa Nombre y URL de WooCommerce
      </h2>
      <div className="my-2 d-flex justify-content-center">
        <WooCommerceIcon
          size="9x"
          className="text-primary mb-2"
          active={!disabled}
        />
      </div>
      <Form onSubmit={handleSubmit} className="px-4">
        <FormGroup>
          <Label for="name">Nombre</Label>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="url">URL de WooCommerce</Label>
          <Input
            type="text"
            name="url"
            id="url"
            placeholder="WooCommerce URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </FormGroup>
        <div className="d-flex flex-colum justify-content-center">
          <Button disabled={disabled}> 
            {intl.formatMessage({id : "app.sincronyze"})} 
          </Button>
        </div>
      </Form>
    </div>
  );
};
