import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "reactstrap";
import { InputWembii } from "../../InputWembii";
import { COLOR_DANGER, COLOR_SUCCESS } from "../../../../constants/theme";

interface Props {
  onSubmit: (e: any) => void;
  validateTxt?: (arg0: string) => boolean;
  toggleEdition: () => void;
  className?: string;
  value: string;
  allowEmpty?: boolean;
  showChek?: boolean;
}

export default function TextInput(props: Props) {
  const {
    onSubmit,
    toggleEdition,
    validateTxt,
    value,
    className,
    allowEmpty = false,
    showChek = false
  } = props;
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    setNewValue(value || "");
  }, [value]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    
    if (newValue !== value) {
      if (allowEmpty || (!allowEmpty && newValue)) {
        onSubmit(newValue);
      }
    }
  }

  const handleOnChange = useCallback((value: string) => {
    if (validateTxt) {
      if (validateTxt(value)) 
        setNewValue(value);
    } else {
      setNewValue(value);
    }
  }, [validateTxt]);

  return (
    <Form 
      onSubmit={onFormSubmit}
      className={cn("w-100 m-0", className)} 
    >
      <div className="w-100 d-flex align-items-center">
        <InputWembii
          type="text"
          id="value"
          className="w-100"
          mb={false}
          value={newValue}
          onChange={(v) => handleOnChange(v)}
          icon={
            !showChek && <FontAwesomeIcon
              icon={["fas", "times"]}
              size="1x"
              color="gray"
              className="pointer"
              onClick={toggleEdition}
            />
          }
        />
        {showChek && 
          <>
            <FontAwesomeIcon
              icon={["fas", "check"]}
              size="2x"
              color={COLOR_SUCCESS}
              className="pointer mx-1"
              onClick={onFormSubmit}
            />
            <FontAwesomeIcon
              icon={["fas", "times"]}
              size="2x"
              color={COLOR_DANGER}
              className="pointer mx-1"
              onClick={toggleEdition}
            />
          </>
        }
      </div>
    </Form>
  );
}
