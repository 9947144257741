import React, { useCallback, useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { CARD_PRODUCT } from "../../../../constants/internalModal.constant";
import { InputWembii } from "../../InputWembii";
import { useBoardCore } from "../../../../cores/boards";
import { ProductBoard } from "../../../../cores/boards/interfaces/api/get-card";
import { CARD_PRODUCTS_MODAL } from "../../../../constants/modal.constant";

interface Props {
  cardProduct: ProductBoard;
  onSuccess?: () => void;
}

export default function ProductForm({ cardProduct, onSuccess }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const {
    card,
    boardBusiness: board,
    postProduct,
    putProduct,
    deleteProduct,
  } = useBoardCore();
  const { turnOffInternalModal, turnOffModal } = useGlobalCore();

  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountType, setDiscountType] = useState(0);

  useEffect(() => {
    setPrice(`${cardProduct.price}`);
    setQuantity(`${cardProduct.quantity}`);
    setDiscount(`${cardProduct.discount}`);
    setDiscountType(cardProduct.discountType);
  }, [cardProduct]);

  const cleanForm = useCallback(() => {
    setPrice("");
    setQuantity("");
    setDiscount("");
    setDiscountType(0);
    turnOffInternalModal(CARD_PRODUCT);
    turnOffModal(CARD_PRODUCTS_MODAL);
    onSuccess?.();
  }, [turnOffInternalModal, turnOffModal, onSuccess]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (cardProduct.id === "") {
      postProduct(
        company?.id!,
        board?.id!,
        card?.list!,
        card?.id!,
        {
          product: cardProduct.product,
          quantity: parseInt(quantity),
          price: parseFloat(price),
          discount: parseFloat(discount),
          discountType,
        },
        cleanForm
      );
    } else {
      putProduct(
        company?.id!,
        board?.id!,
        card?.list!,
        card?.id!,
        cardProduct.id,
        {
          quantity: parseInt(quantity),
          price: parseFloat(price),
          discount: parseFloat(discount),
          discountType,
        },
        cleanForm
      );
    }
  };

  const disabled = useCallback(
    () => !price || !quantity,
    [price, quantity]
  );

  const onDelete = useCallback(() => {
    deleteProduct(
      company?.id!,
      board?.id!,
      card?.list!,
      card?.id!,
      cardProduct.id,
      cleanForm
    );
  }, [deleteProduct, cleanForm, board, card, company, cardProduct]);

  return (
    <Form className="p-2" onSubmit={onSubmit}>
      <div className="font-weight-bold">{cardProduct.name}</div>
      <div className="text-muted mb-3">{cardProduct.code}</div>
      
      <InputWembii
        label={intl.formatMessage({ id: "app.products.unitPrice" })}
        type="number"
        name="price"
        step=".01"
        id="price"
        min="0"
        value={price}
        onChange={setPrice}
      />
      <InputWembii
        label={intl.formatMessage({ id: "app.products.quantity" })}
        type="number"
        name="quantity"
        id="quantity"
        min="0"
        value={quantity}
        onChange={setQuantity}
      />
      <InputWembii
        label={intl.formatMessage({ id: "app.products.unitDiscount" })}
        type="number"
        name="discount"
        step=".01"
        min="0"
        id="discount"
        value={discount}
        onChange={setDiscount}
      />

      {cardProduct.id !== "" && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.activity.tags.delete" })}  
        </Button>
      )}
      <Button color="primary" disabled={disabled()} className="ml-1">
        {cardProduct.id !== "" 
          ? intl.formatMessage({ id: "app.contacts.companies.role.update" })
          : intl.formatMessage({ id: "app.contacts.companies.role.assign" })
        }  
      </Button>
    </Form>
  );
}