import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-number-input";
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useContactCore } from "../../../../cores/contacts";
import { ContactSource } from "../../../../cores/contacts/enum/ContactSource.enum";
import { InputWembii } from "../../InputWembii";
import { LabelWembii } from "../../LabelWembii";
import { ContactSourceBadge } from "../ContactSource";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { ContactSourceOptions } from "../../../../cores/contacts/config";
import { ContactStateOptions } from "../../../../cores/contacts/interfaces/ContactState";
import OwnerContainer from "../OwnerContainer";
import {
  Contact,
  ContactOwner,
  OptionalContact,
} from "../../../../cores/contacts/interfaces/api/get-contact";
import { useGlobalCore } from "../../../../cores/globals";
import {
  CONTACT_POST_LOADING,
  SOCIAL_CHAT_LOADING,
} from "../../../../constants/loading.constant";

interface Props {
  source: ContactSource;
  preContactData?: OptionalContact;
  onCreation?: (arg0: Contact) => void;
}

export default function CreateContactForm({
  source,
  onCreation,
  preContactData,
}: Props) {
  const intl = useIntl();
  const { company, user } = useMeCore();
  const {
    postContact,
    getContacts,
    tags,
    states,
    customFields,
    contactFilters,
  } = useContactCore();
  const { turnOnLoading, isLoading } = useGlobalCore();
  const [name, setName] = useState(preContactData?.name ?? "");
  const [email, setEmail] = useState(preContactData?.email ?? "");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState(0);
  const [owners, setOwners] = useState<ContactOwner[]>([user!]);
  const [whatsapp, setWhatsapp] = useState("");
  const [roleValue, setRoleValue] = useState(source);
  const [isActive, setIsActive] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [reference, setReference] = useState(preContactData?.reference ?? "");
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    customFields.forEach((field) => {
      fields[`${field.id}`] = "";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);

  const toggleSelect = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const onSuccess = useCallback(() => {
    setEmail("");
    setName("");
    setPhone("");

    getContacts(company?.id!, contactFilters);
  }, [setEmail, setName, setPhone, company, contactFilters, getContacts]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    const ownerIds = owners.map((owner) => owner.id);
    if (preContactData) turnOnLoading(SOCIAL_CHAT_LOADING);

    const contact = await postContact(
      company?.id!,
      {
        name,
        email,
        source: roleValue,
        state,
        phoneNumber: phone,
        customFields: fields,
        whatsapp,
        reference,
        owners: ownerIds,
        social: preContactData?.social,
      },
      onSuccess
    );
    if (contact) onCreation?.(contact);
  };

  const disabled = useCallback(
    () => !name || !company || isLoading(CONTACT_POST_LOADING),
    [name, company, isLoading]
  );

  const onSelectRole = useCallback(
    (role) => {
      setRoleValue(role);
      toggleSelect();
    },
    [toggleSelect]
  );

  const tagsValues = useMemo(
    () => [
      ...ContactSourceOptions.map((source) => ({
        value: source.value,
        label: intl.formatMessage({ id: source.label }),
      })),
      ...tags.map((tag) => ({ value: tag.nameId, label: tag.name })),
    ],
    [tags, intl]
  );

  const statesValues = useMemo(
    () => [
      ...ContactStateOptions.map((state) => ({
        value: state.value,
        label: intl.formatMessage({ id: state.label }),
      })),
      ...states.map((state) => ({ value: state.id, label: state.name })),
    ],
    [states, intl]
  );

  const onSettingField = useCallback(
    (value: string, field: string) => {
      const newFields = { ...fields };
      newFields[`${field}`] = value;
      setFields(newFields);
    },
    [fields]
  );

  const onAddingOwner = useCallback(
    (user) => {
      setOwners([...owners, user]);
    },
    [owners]
  );

  const onRemovingOwner = useCallback(
    (id) => {
      const newOwners = owners.filter((owner) => owner.id !== id);
      setOwners(newOwners);
    },
    [owners]
  );

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 justify-content-center p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.contacts.createContact" })}
            </h2>
            <Row>
              <Col>
                <LabelWembii forid="contact-whatsapp" className="mb-2">
                  {intl.formatMessage({ id: "app.contacts.state" })}
                </LabelWembii>
                <InputWembii
                  type="select"
                  onChange={(v) => setState(parseInt(v))}
                  value={state}
                >
                  {statesValues.map(({ label, value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </InputWembii>
              </Col>
              <Col>
                <LabelWembii>
                  {intl.formatMessage({ id: "app.contacts.owners" })}
                </LabelWembii>
                <OwnerContainer
                  contacts={owners}
                  onAdding={onAddingOwner}
                  onRemoving={onRemovingOwner}
                />
              </Col>
            </Row>
            <div className="mb-2 d-flex justify-content-center flex-column align-items-center">
              <FontAwesomeIcon
                icon={["far", "id-card"]}
                className="mr-2 mb-2"
                size="6x"
                color={COLOR_PRIMARY}
              />
              {isActive ? (
                <InputWembii
                  type="select"
                  name="roleValue"
                  value={roleValue}
                  className="w-50"
                  onChange={(v) => onSelectRole(v)}
                >
                  {tagsValues.map((tag) => (
                    <option key={tag.value} value={tag.value}>
                      {tag.label}
                    </option>
                  ))}
                </InputWembii>
              ) : (
                <ContactSourceBadge source={roleValue} onClick={toggleSelect} />
              )}
            </div>
            <Form className="px-2" onSubmit={onFormSubmit}>
              <Nav tabs className="justify-content-center mt-2">
                <NavItem>
                  <NavLink
                    className={cn("pointer", { active: activeTab === "1" })}
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    {intl.formatMessage({
                      id: "app.contacts.generalInformation",
                    })}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={cn("pointer", { active: activeTab === "2" })}
                    onClick={() => {
                      setActiveTab("2");
                    }}
                  >
                    {intl.formatMessage({ id: "app.contacts.customFields" })}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="my-3">
                  <InputWembii
                    label={intl.formatMessage({ id: "app.nameTxt" })}
                    type="text"
                    name="name"
                    id="contact-name"
                    placeholder={intl.formatMessage({ id: "app.nameTxt" })}
                    value={name}
                    onChange={setName}
                  />
                  <InputWembii
                    label={intl.formatMessage({ id: "app.emailTxt" })}
                    type="email"
                    name="email"
                    id="contact-email"
                    placeholder={intl.formatMessage({ id: "app.emailTxt" })}
                    value={email}
                    onChange={setEmail}
                  />
                  <LabelWembii forid="contact-phone">
                    {intl.formatMessage({ id: "app.phoneTxt" })}
                  </LabelWembii>
                  <PhoneInput
                    name="phone"
                    id="contact-phone"
                    placeholder={intl.formatMessage({ id: "app.phoneTxt" })}
                    className="mb-4"
                    value={phone}
                    onChange={(v) => setPhone(v || "")}
                  />
                  <LabelWembii forid="contact-whatsapp">Whatsapp</LabelWembii>
                  <PhoneInput
                    name="whatsapp"
                    id="contact-whatsapp"
                    placeholder="Whatsapp"
                    className="mb-4"
                    value={whatsapp}
                    onChange={(v) => setWhatsapp(v || "")}
                  />
                  <InputWembii
                    label="Reference"
                    type="text"
                    name="reference"
                    id="contact-reference"
                    value={reference}
                    onChange={setReference}
                  />
                </TabPane>
                <TabPane tabId="2" className="my-3">
                  {customFields.length === 0 && (
                    <div className="d-flex align-items-center justify-content-center text-muted my-2">
                      {intl.formatMessage({
                        id: "app.contacts.noCustomFields",
                      })}
                    </div>
                  )}

                  {customFields.map((field) => (
                    <InputWembii
                      label={field.name}
                      type="text"
                      key={field.id}
                      placeholder={field.name}
                      onChange={(v) => onSettingField(v, field.id)}
                      value={fields[`${field.id}`]}
                    />
                  ))}
                </TabPane>
              </TabContent>

              <Button color="primary" block disabled={disabled()}>
                {intl.formatMessage({ id: "app.contacts.create" })}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
