import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  FACEBOOK_GET_LOADING,
  FACEBOOK_POST_LOADING,
  FACEBOOK_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useIntl } from "react-intl";
import { useGlobalCore } from "../globals";
import { Media } from "../socialPost/interfaces/api/get-social-posts";
import { PostImage } from "../socialPost/interfaces/postImage";
import { PostVideo } from "../socialPost/interfaces/postVideo";
import { facebookApiService } from "./services/api";
import {
  getFacebookFailureAction,
  getFacebookInitAction,
  getFacebookSuccessAction,
  postBindFacebookInitAction,
  postBindFacebookSuccessAction,
  getFacebookAccountsFailureAction,
  getFacebookAccountsInitAction,
  getFacebookAccountsSuccessAction,
  postFacebookSocialPostFailureAction,
  postFacebookSocialPostInitAction,
  postFacebookSocialPostSuccessAction,
  postUnbindFacebookFailureAction,
  postUnbindFacebookInitAction,
  postUnbindFacebookSuccessAction,
  putFacebookSocialPostFailureAction,
  putFacebookSocialPostInitAction,
  putFacebookSocialPostSuccessAction,
  getFacebookPagesInitAction,
  getFacebookPagesSuccessAction,
  getFacebookPagesFailureAction,
} from "./store/api-actions";
import { accountsSelector, pagesSelector, settingSelector } from "./store/selector";
import { Mentions } from "../socialPost/interfaces/Mention";

export function useFacebookCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const accounts = useSelector(accountsSelector);
  const pages = useSelector(pagesSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindFacebookInitAction());
        await facebookApiService.unbind(companyId);
        dispatch(postUnbindFacebookSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindFacebookFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      oauthToken: string,
      userToken: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindFacebookInitAction());
        turnOnLoading(FACEBOOK_SYNC_LOADING);
        const settings = await facebookApiService.bind(companyId, {
          oauthToken,
          userToken,
        });
        dispatch(postBindFacebookSuccessAction({ settings }));
        turnOffLoading(FACEBOOK_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess6" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error17" }));
        console.error(error);
        dispatch(postUnbindFacebookFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getFacebookInitAction());
        turnOnLoading(FACEBOOK_GET_LOADING);
        const settings = await facebookApiService.get(companyId);
        dispatch(getFacebookSuccessAction({ settings }));
        turnOffLoading(FACEBOOK_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(FACEBOOK_GET_LOADING);
        console.error(error);
        dispatch(getFacebookFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const postAccounts = useCallback(
    async (companyId: string, token: string) => {
      try {
        dispatch(getFacebookAccountsInitAction());
        turnOnLoading(FACEBOOK_SYNC_LOADING);
        const accounts = await facebookApiService.accounts(companyId, token);
        dispatch(getFacebookAccountsSuccessAction({ accounts }));
      } catch (error: any) {
        turnOffLoading(FACEBOOK_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error18" }));
        console.error(error);
        dispatch(getFacebookAccountsFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getPages = useCallback(
    async (companyId: string, name: string) => {
      try {
        dispatch(getFacebookPagesInitAction());
        const response = await facebookApiService.getPages(companyId, name);
        dispatch(getFacebookPagesSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getFacebookPagesFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const socialPost = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(postFacebookSocialPostInitAction());
        turnOnLoading(FACEBOOK_POST_LOADING);
        await facebookApiService.socialPost(
          text,
          mentions,
          images,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postFacebookSocialPostSuccessAction());
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.success(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.sucess7" })
            : intl.formatMessage({ id: "app.toast.sucess8" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.error(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.error19" })
            : intl.formatMessage({ id: "app.toast.error20" })
        );
        console.error(error);
        dispatch(postFacebookSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialPostVideo = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo,
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(postFacebookSocialPostInitAction());
        turnOnLoading(FACEBOOK_POST_LOADING);
        await facebookApiService.socialPostVideo(
          text,
          mentions,
          video,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postFacebookSocialPostSuccessAction());
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess7" })
          : intl.formatMessage({ id: "app.toast.sucess8" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error19" })
          : intl.formatMessage({ id: "app.toast.error20" })
        );
        console.error(error);
        dispatch(postFacebookSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialPost = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(putFacebookSocialPostInitAction());
        turnOnLoading(FACEBOOK_POST_LOADING);
        await facebookApiService.putSocialPost(
          postId,
          text,
          mentions,
          images,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putFacebookSocialPostSuccessAction());
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess7" })
          : intl.formatMessage({ id: "app.toast.sucess8" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error19" })
          : intl.formatMessage({ id: "app.toast.error20" })
        );
        console.error(error);
        dispatch(putFacebookSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialPostVideo = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo | null,
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(putFacebookSocialPostInitAction());
        turnOnLoading(FACEBOOK_POST_LOADING);
        await facebookApiService.putSocialPostVideo(
          postId,
          text,
          mentions,
          video,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putFacebookSocialPostSuccessAction());
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess7" })
          : intl.formatMessage({ id: "app.toast.sucess8" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error19" })
          : intl.formatMessage({ id: "app.toast.error20" })
        );
        console.error(error);
        dispatch(putFacebookSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialStory = useCallback(
    async (
      companyId: string,
      text: string,
      images: PostImage[],
      scheduleDate: string,
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(postFacebookSocialPostInitAction());
        turnOnLoading(FACEBOOK_POST_LOADING);
        await facebookApiService.socialStory(
          text,
          images,
          scheduleDate,
          companyId
        );
        dispatch(postFacebookSocialPostSuccessAction());
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess7" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error19" }));
        console.error(error);
        dispatch(postFacebookSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialStoryVideo = useCallback(
    async (
      companyId: string,
      text: string,
      video: PostVideo,
      scheduleDate: string,
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(postFacebookSocialPostInitAction());
        turnOnLoading(FACEBOOK_POST_LOADING);
        await facebookApiService.socialStoryVideo(
          text,
          video,
          scheduleDate,
          companyId
        );
        dispatch(postFacebookSocialPostSuccessAction());
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess7" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error19" }));
        console.error(error);
        dispatch(postFacebookSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialStory = useCallback(
    async (
      companyId: string,
      storyId: string,
      text: string,
      images: PostImage[],
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(putFacebookSocialPostInitAction());
        turnOnLoading(FACEBOOK_POST_LOADING);
        await facebookApiService.putSocialStory(
          storyId,
          text,
          images,
          scheduleDate,
          media,
          companyId
        );
        dispatch(putFacebookSocialPostSuccessAction());
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess7" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error19" }));
        console.error(error);
        dispatch(putFacebookSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialStoryVideo = useCallback(
    async (
      companyId: string,
      storyId: string,
      text: string,
      video: PostVideo | null,
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(putFacebookSocialPostInitAction());
        turnOnLoading(FACEBOOK_POST_LOADING);
        await facebookApiService.putSocialStoryVideo(
          storyId,
          text,
          video,
          scheduleDate,
          media,
          companyId
        );
        dispatch(putFacebookSocialPostSuccessAction());
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess7" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_POST_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error19" }));
        console.error(error);
        dispatch(putFacebookSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    accounts,
    pages,
    postAccounts,
    unbind,
    bind,
    get,
    socialPost,
    socialPostVideo,
    modifySocialPost,
    modifySocialPostVideo,
    socialStory,
    socialStoryVideo,
    modifySocialStory,
    modifySocialStoryVideo,
    getPages,
  };
}
