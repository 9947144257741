import React, { ReactNode } from "react";
interface Props {
  active: boolean;
  children: ReactNode;
}

export default function Protector(props: Props) {
  const { active, children } = props;
  return <> {active ? children : null} </>;
}
