import React, { useEffect, useMemo, useState } from "react";
import { CampaignStatus, getCampaignStatus } from "../../../cores/advertisements/constants/campaign-status";
import { COLOR_DANGER, COLOR_LIGHT, COLOR_PRIMARY, COLOR_SECONDARY } from "../../../constants/theme";
import moment from "moment";

interface Props {
  onClick: () => void;
  status: string;
  endDate: string;
}

export default function ActivateButton(props: Props) {
  const { status, endDate, onClick } = props;
  const [color, setColor] = useState(COLOR_LIGHT);

  const timeStatus = useMemo(() =>
    endDate && moment().isAfter(endDate) ?
      CampaignStatus.COMPLETED.id :
      status
  , [endDate, status]);

  useEffect(() => {
    switch (timeStatus) {
      case CampaignStatus.ACTIVE.id:
        setColor(COLOR_PRIMARY);
        break;
      case CampaignStatus.DELETED.id:
        setColor(COLOR_DANGER);
        break;
      default:
        setColor(COLOR_SECONDARY);
    }
  }, [timeStatus]);

  return (
    <div 
      className="rounded-pill px-4 py-1 bg-white pointer text-center"
      style={{
        color: color,
        border: "1px solid",
        borderColor: color
      }}
      onClick={onClick}
    >
      {getCampaignStatus(timeStatus)?.name}
    </div>
  );
}
