import { GetLinkedinProfilesPayload, GetLinkedinProfilesResponse } from "../interface/api/get-linkedin-profiles";
import {
  LinkedInSetting,
  PostLinkedInBindResponse,
} from "../interface/api/post-linkedin-bind";

export const linkedInSettingMapper = {
  mapResponseToLinkedInSetting(
    response: PostLinkedInBindResponse
  ): LinkedInSetting {
    const { linkedInSetting } = response?.payload;
    return linkedInSetting;
  },
  mapResponseToLinkedInProfiles(
    response: GetLinkedinProfilesResponse
  ): GetLinkedinProfilesPayload {
    const { profiles } = response?.payload;
    return { profiles };
  },
};
