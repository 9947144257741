import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const wooCommerceRoot = (state: State) => state.wooCommerce;

export const settingSelector = createSelector(
  wooCommerceRoot,
  (wooCommerce) => wooCommerce.settings
);

export const urlSelector = createSelector(
  wooCommerceRoot,
  (wooCommerce) => wooCommerce.url
);

export const nameSelector = createSelector(
  wooCommerceRoot,
  (wooCommerce) => wooCommerce.name
);

export const currencySelector = createSelector(
  wooCommerceRoot,
  (wooCommerce) => wooCommerce.currency
);
