import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { Button } from "reactstrap";
import { GENERATING_REPORT } from "../../../constants/loading.constant";
import { useAnalyticsCore } from "../../../cores/analytics";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { UpgradePlanButton } from "../plan/UpgradePlanButton";
import { useIntl } from "react-intl";

export const ActionButtons = () => {
  const intl = useIntl();
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const { company, companyPlan, updateReportsCompany } = useMeCore();
  const { socialProviders, getInsightReport } = useAnalyticsCore();

  const canDownload = useMemo(
    () => {
      if (moment().isSame(moment(company?.lastReportDate), 'M')) {
        return company?.reports! < companyPlan?.rules.reportsAmount!;
      } else {
        return true;
      }
    }, [companyPlan, company]
  );

  const setInlineStyles = (node: any) => {
    const transformProperties = [
      "fill",
      "color",
      "font-size",
      "stroke",
      "font",
    ];
    if (!node.style) return;
    let styles = getComputedStyle(node);
    for (let transformProperty of transformProperties) {
      node.style[transformProperty] = styles[transformProperty as any];
    }
  };

  const onclone = useCallback((element: any) => {
    const svgElements = element.body.getElementsByTagName("svg");
    Array.from(svgElements).forEach((svgElement: any) => {
      const bBox = svgElement.getBBox();
      svgElement.setAttribute("width", bBox.width);
      svgElement.setAttribute("height", bBox.height);
      setInlineStyles(svgElement);
    });
  },[]);

  const handleDownloadReport = useCallback(
    async () => {
      turnOnLoading(GENERATING_REPORT);

      await getInsightReport(
        company?.id!,
        async () => {
          const pdf = new jsPDF({
            orientation: "landscape"
          });
    
          const prom1 = socialProviders.map(async (social, index1) => {
            const graphs = document.querySelectorAll(`[id='${social.id}Chart']`);
            const header = document.querySelector(`[id='${social.id}Header']`);
            const headerCanvas = await html2canvas(header as HTMLElement, {
              onclone,
              useCORS: true,
              scale: 1,
            })
      
            const prom2: any[] = [];
            graphs.forEach((graph) => {
              prom2.push(html2canvas(graph as HTMLElement, {
                onclone,
                scale: 1,
              }))
            });
      
            const canvas = await Promise.all(prom2);
            canvas.forEach((canva, index2) => {
              pdf.addImage(headerCanvas.toDataURL("image/png"), "PNG", 10, 10, 280, 20);
              pdf.addImage(canva.toDataURL("image/png"), "PNG", 10, 45, 280, 130);
      
              if (index2 < canvas.length - 1) pdf.addPage();
            });
    
            if (index1 < socialProviders.length - 1) pdf.addPage();
          });
  
          await Promise.all(prom1);
    
          pdf.save(`wembii-report.pdf`);

          updateReportsCompany(company?.reports! + 1)
          turnOffLoading(GENERATING_REPORT, 500);
        }
      );
    },
    [
      socialProviders,
      company,
      updateReportsCompany,
      getInsightReport,
      turnOnLoading,
      turnOffLoading,
      onclone,
    ]
  );

  const disabled = useCallback(
    () => socialProviders.length === 0,
    [socialProviders]
  );

  return (
    <div className="d-flex justify-content-center mt-3">
      {!canDownload ? (
        <UpgradePlanButton />
      ) : (
        <Button 
          color="primary" 
          disabled={disabled()} 
          onClick={handleDownloadReport}
        >
          <FontAwesomeIcon
            icon={["far", "file-pdf"]}
            className="mx-2"
            size="1x"
            color="white"
          />
         {intl.formatMessage({ id: "app.reports.downloadReport" })}
        </Button>
      )}
    </div>
  );
};
