import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { ActionOptions } from "../../../../cores/automations/config";
import { Action } from "../../../../cores/automations/enum/Action.enum";
import { AutomationAction } from "../../../../cores/automations/interfaces/api/get-automation";
import AutomaticEmailAction from "./AutomaticEmailAction";
import NewOpportunityAction from "./NewOpportunityAction";
import CascadeAssignmentAction from "./CascadeAssignmentAction";

interface Props {
  action: AutomationAction;
  onClick: () => void;
}

export default function ActionContainer(props: Props) { 
  const intl = useIntl();
  const { action, onClick } = props;

  const actionOption = useMemo(() => 
    ActionOptions.find((option) => option.value === action.type)
  , [action]);

  const actionBody = useMemo(() => {
    switch(action.type) {
      case Action.NEW_OPPORTUNITY: 
        return <NewOpportunityAction action={action} />
      case Action.AUTOMATIC_EMAIL:
        return <AutomaticEmailAction action={action} />
      case Action.CASCADE_ASSIGNMENT:
        return <CascadeAssignmentAction action={action} />
    }
  }, [action]);

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center border my-1 rounded pointer p-2"
      onClick={onClick}  
    >
      <div className="font-weight-bold">
        {intl.formatMessage({ id: actionOption?.label })}
      </div>

      {actionBody}
    </div>
  );
}