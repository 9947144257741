import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import { useFacebookCore } from "../../../../cores/facebook";
import { useInstagramCore } from "../../../../cores/instagram";
import { useLinkedInCore } from "../../../../cores/linkedIn";
import { useLinkedInBusinessCore } from "../../../../cores/linkedInBusiness";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { SOCIAL_ACTION_TYPES } from "../../../../cores/socialPost/consts/socialActionType.constant";
import { SOCIAL_POST_TYPES } from "../../../../cores/socialPost/consts/socialPostType.constant";
import { useTwitterCore } from "../../../../cores/twitter";

interface Props {
  from: string;
  save?: boolean;
  disabled?: boolean;
  onReset: () => void;
}

export const PostActionButtons = ({
  from,
  save = true,
  disabled = false,
  onReset
}: Props) => {
  const intl = useIntl();
  const {
    modifySocialPost: modifySocialPostTwitter,
    modifySocialPostVideo: modifySocialPostVideoTwitter,
  } = useTwitterCore();
  const {
    modifySocialPost: modifySocialPostFacebook,
    modifySocialPostVideo: modifySocialPostVideoFacebook,
  } = useFacebookCore();
  const {
    modifySocialPost: modifySocialPostLinkedIn,
  } = useLinkedInCore();
  const {
    modifySocialPost: modifySocialPostLinkedInBusiness,
    modifySocialPostVideo: modifySocialPostVideoLinkedInBusiness,
  } = useLinkedInBusinessCore();
  const {
    modifySocialPost: modifySocialPostInstagram,
    modifySocialPostVideo: modifySocialPostVideoInstagram,
  } = useInstagramCore();
  const { company } = useMeCore();
  const { goToSocialHistory, goToSocialCalendar } = useRouterCore();
  const {
    socialProviders,
    images,
    selectedPost,
    video,
    text = "",
    mentions,
    setSocialAction,
  } = useSocialPostCore();

  const modify = useCallback(() => {
    setSocialAction(SOCIAL_ACTION_TYPES.SCHEDULE);

    selectedPost &&
      socialProviders.forEach((sp) => {
        switch (sp.id) {
          case SocialProviderId.Twitter:
            selectedPost.post.type === SOCIAL_POST_TYPES.VIDEO &&
            selectedPost.media.length > 0
              ? modifySocialPostVideoTwitter(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  mentions,
                  video,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                )
              : modifySocialPostTwitter(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  mentions,
                  images,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                );
            break;
          case SocialProviderId.LinkedInBusiness:
            selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO &&
            selectedPost.media.length > 0
              ? modifySocialPostVideoLinkedInBusiness(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  mentions,
                  video,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                )
              : modifySocialPostLinkedInBusiness(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  mentions,
                  images,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                );
            break;
          case SocialProviderId.LinkedIn:
            modifySocialPostLinkedIn(
              company?.id!,
              selectedPost.post.id,
              text,
              mentions,
              images,
              selectedPost.post.scheduleDate,
              selectedPost.media,
              onReset
            );
            break;
          case SocialProviderId.Facebook:
            selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO &&
            selectedPost.media.length > 0
              ? modifySocialPostVideoFacebook(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  mentions,
                  video,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                )
              : modifySocialPostFacebook(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  mentions,
                  images,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                );
            break;
          case SocialProviderId.Instagram:
            selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO &&
            selectedPost.media.length > 0
              ? modifySocialPostVideoInstagram(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  mentions,
                  video,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                )
              : modifySocialPostInstagram(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  mentions,
                  images,
                  selectedPost.post.scheduleDate,
                  selectedPost.media,
                  onReset
                );
            break;
          default:
            break;
        }
      });
  }, [
    selectedPost,
    socialProviders,
    company,
    text,
    video,
    images,
    mentions,
    modifySocialPostVideoTwitter,
    onReset,
    modifySocialPostTwitter,
    modifySocialPostVideoLinkedInBusiness,
    modifySocialPostLinkedInBusiness,
    modifySocialPostLinkedIn,
    modifySocialPostVideoFacebook,
    modifySocialPostFacebook,
    modifySocialPostVideoInstagram,
    modifySocialPostInstagram,
    setSocialAction,
  ]);

  const saveEdition = useCallback(async () => {
    await modify();
    if (from === "history") {
      goToSocialHistory();
    } else {
      goToSocialCalendar();
    }
  }, [goToSocialHistory, goToSocialCalendar, modify, from]);

  return (
    save ? (
      <Button className="whiteButton mx-2" disabled={disabled} onClick={saveEdition}>
        {intl.formatMessage({ id: "app.socialMediaPost.save" })}
      </Button>
    ) : (
      <Button
        className="mr-3"
        color="primary"
        disabled={disabled}
        onClick={saveEdition}
      >
        {intl.formatMessage({ id: "app.socialMediaPost.toPost" })}
      </Button>
    )
  );
};
