import React, { useCallback, useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { CHART_THEME } from "../../../../constants/theme";
import { ChartMetric } from "../../../../cores/analytics/interface/ChartMetric";
import {
  charBasicDataset,
  pieChartBasicOptions,
} from "../../../utils/chartsUtils";

interface Props extends ChartMetric {
  colors?: string[];
  onElementClick?: (element: any) => void;
}

export const PieChart = (props: Props) => {
  const { labels, datasets, onElementClick, colors = CHART_THEME } = props;

  const getColor = useCallback(
    (index: number) => colors[index % colors.length],
    [colors]
  );

  const _colors = useMemo(
    (): string[] => datasets[0].data.map((_, index) => getColor(index)),
    [datasets, getColor]
  );

  const data = useCallback(
    () => ({
      labels,
      datasets: datasets.map(({ label, data, fill, type }) => {
        return {
          ...charBasicDataset,
          label,
          borderColor: _colors,
          fill,
          type,
          backgroundColor: _colors,
          data: [...data],
        };
      }),
    }),
    [_colors, datasets, labels]
  );

  return <Pie data={data} options={pieChartBasicOptions} getElementAtEvent={onElementClick} height={300} />;
};
