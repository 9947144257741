export const ASSIGN_USER_SIDE_MODAL_LEFT = "ASSIGN_USER_SIDE_MODAL_LEFT";
export const FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT =
  "FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT";
export const OPENAI_SIDE_MODAL_RIGHT = "OPENAI_SIDE_MODAL_RIGHT";
export const MAILCHIMP_AUDIENCES_SIDE_MODAL = "MAILCHIMP_AUDIENCES_SIDE_MODAL";
export const LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT =
  "LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT";
export const ADD_MENTION_MODAL = "ADD_MENTION_MODAL";
export const EDIT_IMAGE_MODAL = "EDIT_IMAGE_MODAL";
export const EDIT_VIDEO_MODAL = "EDIT_VIDEO_MODAL";
export const ADD_SOCIAL_IMAGE_MODAL = "ADD_SOCIAL_IMAGE_MODAL";
export const ADD_SOCIAL_VIDEO_MODAL = "ADD_SOCIAL_VIDEO_MODAL";
export const INSTAGRAM_BIND_MODAL = "INSTAGRAM_BIND_MODAL";
export const SMTP_BIND_MODAL = "SMTP_BIND_MODAL";
export const SMTP_ME_BIND_MODAL = "SMTP_ME_BIND_MODAL";
export const INSTAGRAM_MENSSEGER_BIND_MODAL = "INSTAGRAM_MENSSEGER_BIND_MODAL";
export const ANALYTICS_EXPAND = "ANALYTICS_EXPAND";
export const ANALYTICS_POST_GROUP_EXPAND = "ANALYTICS_POST_GROUP_EXPAND";
export const ANALYTICS_POST_EXPAND = "ANALYTICS_POST_EXPAND";
export const GOOGLE_PROJECTS_SIDE_MODAL = "GOOGLE_PROJECTS_SIDE_MODAL";
export const CONFIGURE_CHAT_AREA_SIDE_MODAL = "CONFIGURE_CHAT_AREA_SIDE_MODAL";
export const CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL =
  "CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL";
export const CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL =
  "CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL";
export const WOO_COMMERCE_BIND_FORM = "WOO_COMMERCE_BIND_FORM";
export const WOO_COMMERCE_ORDER_EXPAND = "WOO_COMMERCE_ORDER_EXPAND";
export const MERCADO_LIBRE_ORDER_EXPAND = "MERCADO_LIBRE_ORDER_EXPAND";
export const MERCADO_COUNTRIES = "MERCADO_COUNTRIES";
export const JUMPSELLER_ORDER_EXPAND = "JUMPSELLER_ORDER_EXPAND";

export const CONTACT_EXPAND_EDIT_FORM = "CONTACT_EXPAND_EDIT_FORM";
export const CONTACT_EXPAND_CREATION_FORM = "CONTACT_EXPAND_CREATION_FORM";
export const CONTACT_IMPORT_EXPORT = "CONTACT_IMPORT_EXPORT";
export const CONTACT_FILTER_MODAL = "CONTACT_FILTER_MODAL";
export const CONTACT_TAGS_FORM = "CONTACT_TAGS_FORM";
export const CONTACT_STATES_FORM = "CONTACT_STATES_FORM";
export const CONTACT_CONFIG_FORM = "CONTACT_CONFIG_FORM";
export const CONTACT_COMPANY_EXPAND_EDIT_FORM =
  "CONTACT_COMPANY_EXPAND_EDIT_FORM";
export const CONTACT_COMPANY_EXPAND_CREATION_FORM =
  "CONTACT_COMPANY_EXPAND_CREATION_FORM";
export const CONTACT_COMPANY_CONTACTS_MODAL = "CONTACT_COMPANY_CONTACTS_MODAL";
export const CONTACT_COMPANY_MODAL = "CONTACT_COMPANY_MODAL";
export const CONTACT_DELETE_MODAL = "CONTACT_DELETE_MODAL";
export const CONTACT_COMPANY_BOARD_MODAL = "CONTACT_COMPANY_BOARD_MODAL";
export const CONTACT_USERS_MODAL = "CONTACT_USERS_MODAL";
export const CONTACT_MASS_ASSIGNMENT_MODAL = "CONTACT_MASS_ASSIGNMENT_MODAL";
export const DELETE_CONTACT_OWNER_MODAL = "DELETE_CONTACT_OWNER_MODAL";
export const DELETE_CONTACT_COMMENT_MODAL = "DELETE_CONTACT_COMMENT_MODAL";
export const COMPANY_CUSTOM_FIELDS_FORM = "COMPANY_CUSTOM_FIELDS_FORM";

export const AUTOMATION_CREATE_FORM = "AUTOMATION_CREATE_FORM";
export const AUTOMATION_EDIT_FORM = "AUTOMATION_EDIT_FORM";
export const AUTOMATION_CONTACTS_MODAL = "AUTOMATION_CONTACTS_MODAL";
export const DELETE_AUTOMATION_MODAL = "DELETE_AUTOMATION_MODAL";
export const DELETE_AUTOMATION_ITEM_MODAL = "DELETE_AUTOMATION_ITEM_MODAL";

export const USER_EXPAND_EDIT_FORM = "USER_EXPAND_EDIT_FORM";
export const USER_ASSIGN_MODAL = "USER_ASSIGN_MODAL";
export const USER_DELETE_FORM = "USER_DELETE_FORM";
export const USER_ASSING_FORM = "USER_ASSING_FORM";
export const ME_USER_EDIT_FORM = "ME_USER_EDIT_FORM";
export const USER_EXPAND_CREATE_FORM = "USER_EXPAND_CREATE_FORM";
export const USER_COMPANIES = "USER_COMPANIES";

export const CHANGE_SUBSCRIPTION_MODAL = "CHANGE_SUBSCRIPTION_MODAL";

export const POST_MODAL = "POST_MODAL";

export const CREATE_EDIT_RESELLER_MODAL = "CREATE_EDIT_RESELLER_MODAL";

export const BOARD_CREATE_CONFIG = "BOARD_CREATE_CONFIG";
export const BOARD_EDIT_CONFIG = "BOARD_EDIT_CONFIG";
export const BOARD_FILTERS_MODAL = "BOARD_FILTERS_MODAL";
export const BOARD_CARD_MODAL = "BOARD_CARD_MODAL";
export const BOARD_ROBOT_INACTIVITY = "BOARD_ROBOT_INACTIVITY";
export const CREATE_AUTOMATION_INACTIVITY = "CREATE_AUTOMATION_INACTIVITY";
export const OPEN_USER_ASIGNAMENT_MODAL = "OPEN_USER_ASIGNAMENT_MODAL";
export const BOARD_USERS_MODAL = "BOARD_USERS_MODAL";
export const BOARD_CONTACTS_MODAL = "BOARD_CONTACTS_MODAL";
export const BOARD_FILES_MODAL = "BOARD_FILES_MODAL";
export const BOARD_FILE_PREVIEW_MODAL = "BOARD_FILE_PREVIEW_MODAL";
export const CARD_TAGS_MODAL = "CARD_TAGS_MODAL";
export const CARD_PRODUCTS_MODAL = "CARD_PRODUCTS_MODAL";
export const DELETE_BOARD_ITEM_MODAL = "DELETE_BOARD_ITEM_MODAL";
export const BOARD_CUSTOM_FIELDS_FORM = "BOARD_CUSTOM_FIELDS_FORM";
export const BOARD_METRIC_MODAL = "BOARD_METRIC_MODAL";
export const MOVE_CARDS_MODAL = "MOVE_CARDS_MODAL";
export const BOARD_IMPORT = "BOARD_IMPORT";

export const PRODUCT_EXPAND_EDIT_FORM = "PRODUCT_EXPAND_EDIT_FORM";
export const PRODUCT_EXPAND_CREATION_FORM = "PRODUCT_EXPAND_CREATION_FORM";
export const DELETE_PRODUCT_FORM = "DELETE_PRODUCT_FORM";

export const TASK_CARD_MODAL = "TASK_CARD_MODAL";
export const DELETE_TASK_MODAL = "DELETE_TASK_MODAL";
export const TASK_CONTACTS_MODAL = "TASK_CONTACTS_MODAL";
export const EVENT_CARD_MODAL = "EVENT_CARD_MODAL";
export const QUOTE_CARD_MODAL = "QUOTE_CARD_MODAL";
export const EVENT_CONTACTS_MODAL = "EVENT_CONTACTS_MODAL";
export const EVENT_USERS_MODAL = "EVENT_USERS_MODAL";

export const SEND_EMAIL_FORM = "SEND_EMAIL_FORM";
export const EMOJI_FORM = "EMOJI_FORM";
export const SIGN_EMAIL_FORM = "SIGN_EMAIL_FORM";
export const TEMPLATE_EMAIL_FORM = "TEMPLATE_EMAIL_FORM";
export const CHAT_FILTERS_MODAL = "CHAT_FILTERS_MODAL";
export const TEMPLATE_CHAT_FORM = "TEMPLATE_CHAT_FORM";
export const TEMPLATE_SOCIAL_FORM = "TEMPLATE_SOCIAL_FORM";
export const SOCIAL_CHAT_FILTERS_MODAL = "SOCIAL_CHAT_FILTERS_MODAL";
export const TEMPLATES_SOCIAL_FORM = "TEMPLATES_SOCIAL_FORM";
export const HASHTAGS_SOCIAL_FORM = "HASHTAGS_SOCIAL_FORM";
export const GMAIL_CONTACTS_MODAL = "GMAIL_CONTACTS_MODAL";
export const OPENAI_SOCIAL_FORM = "OPENAI_SOCIAL_FORM";

export const SCHEDULE_POST_MODAL = "SCHEDULE_POST_MODAL";
export const EMAIL_WARNING_MODAL = "EMAIL_WARNING_MODAL";

export const MORE_INFO_CHAT_WEB = "MORE_INFO_CHAT_WEB";
export const MORE_INFO_EDIT_WEB_FORM = "MORE_INFO_EDIT_WEB_FORM";
export const MORE_INFO_FACEBOOK_CAMPAIGN = "MORE_INFO_FACEBOOK_CAMPAIGN";
export const MORE_INFO_SOCIAL_POST = "MORE_INFO_SOCIAL_POST";
export const MORE_INFO_CRM = "MORE_INFO_CRM";
export const MORE_INFO_SOCIAL = "MORE_INFO_SOCIAL";
export const MORE_INFO_HEADER_ACTIVITY = "MORE_INFO_HEADER_ACTIVITY";
export const MORE_INFO_CALENDAR = "MORE_INFO_CALENDAR";

export const MEETING_EDIT_FORM = "MEETING_EDIT_FORM";
export const MEETING_CONFIG_FORM = "MEETING_CONFIG_FORM";
export const DELETE_EVENT_MODAL = "DELETE_EVENT_MODAL";

export const COMPANY_CREATE_CONFIG = "COMPANY_CREATE_CONFIG";
export const COMPANY_DELETE_FORM = "COMPANY_DELETE_FORM";

export const FACEBOOK_VIEW_FORM = "FACEBOOK_VIEW_FORM";
export const WEBFORM_CONFIG = "WEBFORM_CONFIG";
export const CAMPAIGN_REVIEW = "CAMPAIGN_REVIEW";

export const COMMENT_TYPES_FORM = "COMMENT_TYPES_FORM";
