import React, { useCallback } from "react";
import { useContext } from "react";
import {  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { LOCALE } from "../../constants/locale.constant";
import { Context } from "../../LocaleWrapper";
import { Flag } from "./Flag";

interface Props {
  nav?: boolean,
  inNavbar?: boolean,
  right?: boolean,
  onChange?: (arg0: string) => void,
}

export const LocalSelector = (props: Props) => {
  const {
    nav = false,
    inNavbar = false,
    right = false,
    onChange
  } = props;

  const { locale, selectLanguage } = useContext(Context);
  
  const onClick = useCallback(async (language) => {
    await onChange?.(language);
    selectLanguage(language);
  }, [onChange, selectLanguage]);

  return (
    <div className="m-2">
      <UncontrolledDropdown nav={nav} inNavbar={inNavbar}>
        <DropdownToggle nav={nav} tag="span" data-toggle="dropdown">
          <Flag flag={locale} />
        </DropdownToggle>
        <DropdownMenu
          className="border-0 py-3 px-2 shadow"
          right={right}
          style={{ borderRadius: "20px" }}
        >
          <DropdownItem onClick={() => onClick(LOCALE.SPANISH)}>
            <Flag flag="es" />
            <span className="ml-2">Español</span>
          </DropdownItem>
          <DropdownItem onClick={() => onClick(LOCALE.ENGLISH)}>
            <Flag flag="en" />
            <span className="ml-2">English</span>
          </DropdownItem>
          <DropdownItem onClick={() => onClick(LOCALE.PORTUGUESE)}>
            <Flag flag="pt" />
            <span className="ml-2">Portugués</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
