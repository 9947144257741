import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Task } from "../../tasks/interfaces/api/get-task";
import { BoardType } from "../constants/board-type.constant";
import {
  Comment,
  Email,
  File,
  Event,
  ListCard,
  Activity,
  ProductBoard,
  Quote as QuoteBoard,
} from "../interfaces/api/get-card";
import { Board, BoardList, UserRoles } from "../interfaces/api/get-board";
import { CardTag } from "../interfaces/api/get-tags";
import { PanelFilters } from "../interfaces/PanelFilters";
import {
  BoardAction,
  changeCardOrderAction,
  changeListOrderAction,
  cleanCardAction,
  clearFilterAction,
  setBoardUpdateAction,
  setFilterAction,
  setQuoteAction,
  setShowTagTextAction,
} from "./actions";
import {
  getBoardsFailureAction,
  getBoardsInitAction,
  getBoardsSuccessAction,
  BoardApiAction,
  postCardSuccessAction,
  postListSuccessAction,
  putCardOrderSuccessAction,
  putListOrderSuccessAction,
  postBoardInitAction,
  postBoardFailureAction,
  postBoardSuccessAction,
  getBoardFailureAction,
  getBoardSuccessAction,
  postListInitAction,
  postListFailureAction,
  postCardInitAction,
  postCardFailureAction,
  getCardInitAction,
  getCardFailureAction,
  getCardSuccessAction,
  putCardInitAction,
  putCardFailureAction,
  putCardSuccessAction,
  postCommentInitAction,
  postCommentFailureAction,
  postCommentSuccessAction,
  putListInitAction,
  putListFailureAction,
  putListSuccessAction,
  deleteCommentSuccessAction,
  deleteCommentInitAction,
  deleteCommentFailureAction,
  putCommentInitAction,
  putCommentFailureAction,
  putCommentSuccessAction,
  putBoardFailureAction,
  putBoardInitAction,
  putBoardSuccessAction,
  deleteCardInitAction,
  deleteCardFailureAction,
  deleteCardSuccessAction,
  deleteBoardInitAction,
  deleteBoardFailureAction,
  deleteBoardSuccessAction,
  deleteListInitAction,
  deleteListFailureAction,
  deleteListSuccessAction,
  putListOrderInitAction,
  putListOrderFailureAction,
  putCardOrderInitAction,
  putCardOrderFailureAction,
  postAssignmentInitAction,
  postAssignmentSuccessAction,
  postAssignmentFailureAction,
  deleteAssignmentInitAction,
  deleteAssignmentSuccessAction,
  deleteAssignmentFailureAction,
  postContactSuccessAction,
  deleteContactSuccessAction,
  postContactInitAction,
  postContactFailureAction,
  deleteContactInitAction,
  deleteContactFailureAction,
  postTaggingInitAction,
  postTaggingSuccessAction,
  postTaggingFailureAction,
  postFilesInitAction,
  postFilesFailureAction,
  postFilesSuccessAction,
  deleteFileInitAction,
  deleteFileFailureAction,
  deleteFileSuccessAction,
  getListsInitAction,
  getListsFailureAction,
  getListsSuccessAction,
  getCardTagsInitAction,
  getCardTagsFailureAction,
  postCardTagInitAction,
  postCardTagFailureAction,
  putCardTagInitAction,
  putCardTagFailureAction,
  deleteCardTagInitAction,
  deleteCardTagFailureAction,
  getCardTagsSuccessAction,
  putCardTagSuccessAction,
  deleteCardTagSuccessAction,
  postCardTagSuccessAction,
  postTagInitAction,
  postTagFailureAction,
  deleteTagInitAction,
  deleteTagFailureAction,
  deleteTagSuccessAction,
  postTagSuccessAction,
  getListCardsInitAction,
  getListCardsSuccessAction,
  getListCardsFailureAction,
  getListBudgetInitAction,
  getListBudgetFailureAction,
  getListBudgetSuccessAction,
  deleteCompanySuccessAction,
  postCompanySuccessAction,
  deleteCompanyInitAction,
  deleteCompanyFailureAction,
  postProductSuccessAction,
  postProductInitAction,
  postProductFailureAction,
  putProductInitAction,
  putProductFailureAction,
  deleteProductFailureAction,
  deleteProductInitAction,
  putProductSuccessAction,
  deleteProductSuccessAction,
  postQuoteSuccessAction,
  getBoardExportFailureAction,
  getBoardExportInitAction,
  getBoardExportSuccessAction,
  getBoardActivityInitAction,
  getBoardBusinessInitAction,
  getCustomFieldsSuccessAction,
  postCustomFieldSuccessAction,
  putCustomFieldSuccessAction,
  deleteCustomFieldSuccessAction,
  deleteCustomFieldFailureAction,
  deleteCustomFieldInitAction,
  getCustomFieldsFailureAction,
  getCustomFieldsInitAction,
  postCustomFieldFailureAction,
  postCustomFieldInitAction,
  putCustomFieldFailureAction,
  putCustomFieldInitAction,
  getListContactsInitAction,
  getListContactsSuccessAction,
  getListContactsFailureAction,
  putMoveCardsInitAction,
  putMoveCardsSuccessAction,
  putMoveCardsFailureAction,
} from "./api-actions";
import { Quote } from "../interfaces/Quote";
import moment from "moment";
import { CustomField } from "../interfaces/api/get-custom-fields";

export interface BoardState {
  boards: {
    ids: string[];
    entities: Record<string, Board>;
  };
  boardActivity: Board | null;
  boardBusiness: Board | null;
  lists: {
    ids: string[];
    entities: Record<string, BoardList>;
  };
  card: ListCard | null;
  comments: {
    ids: string[];
    entities: Record<string, Comment>;
  };
  emails: {
    ids: string[];
    entities: Record<string, Email>;
  };
  files: {
    ids: string[];
    entities: Record<string, File>;
  };
  tasks: {
    ids: string[];
    entities: Record<string, Task>;
  };
  events: {
    ids: string[];
    entities: Record<string, Event>;
  };
  quotes: {
    ids: string[];
    entities: Record<string, QuoteBoard>;
  };
  activities: {
    ids: string[];
    entities: Record<string, Activity>;
  };
  products: {
    ids: string[];
    entities: Record<string, ProductBoard>;
  };
  tags: {
    ids: string[];
    entities: Record<string, CardTag>;
  };
  showTagText: boolean;
  allTags: {
    ids: string[];
    entities: Record<string, CardTag>;
  };
  listNames: BoardList[];
  filters: {
    ids: string[];
    entities: Record<string, PanelFilters>;
  };
  customFields: {
    ids: string[];
    entities: Record<string, CustomField>;
  };
  quote: Quote;
  errorCode: number | null;
  errorMessage: string | null;
  board: {
    name: string;
    color: string;
    currency: string;
    roles: UserRoles[];
    isChanged: boolean;
  };
}

const initialState: BoardState = {
  boards: {
    ids: [],
    entities: {},
  },
  boardActivity: null,
  boardBusiness: null,
  lists: {
    ids: [],
    entities: {},
  },
  card: null,
  comments: {
    ids: [],
    entities: {},
  },
  emails: {
    ids: [],
    entities: {},
  },
  files: {
    ids: [],
    entities: {},
  },
  tasks: {
    ids: [],
    entities: {},
  },
  events: {
    ids: [],
    entities: {},
  },
  quotes: {
    ids: [],
    entities: {},
  },
  activities: {
    ids: [],
    entities: {},
  },
  products: {
    ids: [],
    entities: {},
  },
  showTagText: true,
  tags: {
    ids: [],
    entities: {},
  },
  allTags: {
    ids: [],
    entities: {},
  },
  listNames: [],
  filters: {
    ids: [],
    entities: {},
  },
  quote: {
    id: "",
    name: "",
    creationDate: null,
    expirationDate: null,
    owner: null,
    ownerCompany: null,
    currency: "",
    comments: "",
    terms: "",
    company: null,
    contacts: [],
    signature: false,
    products: [],
    discounts: [],
    fees: [],
    taxes: [],
  },
  customFields: {
    ids: [],
    entities: {},
  },
  errorCode: null,
  errorMessage: null,
  board: {
    name: "",
    color: "",
    currency: "",
    roles: [],
    isChanged: false,
  },
};

const boardsAdapter = createEntityAdapter<Board>((payload) => payload.id);
const listsAdapter = createEntityAdapter<BoardList>((payload) => payload.id);
const commentsAdapter = createEntityAdapter<Comment>((payload) => payload.id);
const filesAdapter = createEntityAdapter<File>((payload) => payload.id);
const tasksAdapter = createEntityAdapter<Task>((payload) => payload.id);
const emailsAdapter = createEntityAdapter<Email>((payload) => payload.id);
const quotesAdapter = createEntityAdapter<QuoteBoard>((payload) => payload.id);
const tagsAdapter = createEntityAdapter<CardTag>((payload) => payload.id);
const eventsAdapter = createEntityAdapter<Event>((payload) => payload.id);
const activitiesAdapter = createEntityAdapter<Activity>(
  (payload) => payload.id
);
const filtersAdapter = createEntityAdapter<PanelFilters>(
  (payload) => payload.id
);
const productsAdapter = createEntityAdapter<ProductBoard>(
  (payload) => payload.id
);
const customFieldAdapter = createEntityAdapter<CustomField>(
  (payload) => payload.id
);

export default produce(
  (
    draft: BoardState = initialState,
    action: BoardApiAction | BoardAction | GlobalActions
  ) => {
    switch (action.type) {
      case getBoardSuccessAction.type:
        if (action.board.type === BoardType.ACTIVITY) {
          draft.boardActivity = {
            ...action.board,
            lists: [],
          };
        } else {
          draft.boardBusiness = {
            ...action.board,
            lists: [],
          };
        }
        listsAdapter.addMany(action.board.lists, draft.lists);
        return draft;
      case getBoardsSuccessAction.type:
        boardsAdapter.addMany(action.boards, draft.boards);
        return draft;
      case postBoardSuccessAction.type:
        boardsAdapter.setOne(action.board, draft.boards);
        return draft;
      case putBoardSuccessAction.type:
        if (action.board.type === BoardType.ACTIVITY) {
          draft.boardActivity = {
            ...action.board,
            lists: [],
          };
        } else {
          draft.boardBusiness = {
            ...action.board,
            lists: [],
          };
        }
        boardsAdapter.updateOne(action.board, draft.boards);
        return draft;
      case postListSuccessAction.type:
        listsAdapter.setOne({ ...action.list, total: 0 }, draft.lists);
        return draft;
      case putListSuccessAction.type:
        let list4 = draft.lists.entities[action.list.id];
        list4 = {
          ...action.list,
          total: list4.total,
          totalBudget: list4.totalBudget,
          hasMore: list4.hasMore,
          cards: list4.cards,
        };
        listsAdapter.updateOne(list4, draft.lists);
        return draft;
      case getListBudgetSuccessAction.type:
        let list5 = draft.lists.entities[action.id];
        list5 = {
          ...list5,
          totalBudget: action.totalBudget,
          totalProbability: action.totalProbability,
        };
        listsAdapter.updateOne(list5, draft.lists);
        return draft;
      case getCardSuccessAction.type:
        draft.card = action.card;
        commentsAdapter.addMany(action.card.comments, draft.comments);
        filesAdapter.addMany(action.card.files, draft.files);
        tasksAdapter.addMany(action.card.tasks, draft.tasks);
        emailsAdapter.addMany(action.card.emails, draft.emails);
        tagsAdapter.addMany(action.card.tags, draft.tags);
        eventsAdapter.addMany(action.card.events, draft.events);
        quotesAdapter.addMany(action.card.quotes, draft.quotes);
        activitiesAdapter.addMany(action.card.activities, draft.activities);

        const list2 = draft.lists.entities[draft.card.list];
        if (list2) {
          const cardIndex4 = list2.cards.findIndex(
            (card) => card.id === action.card.id
          );
          list2.cards[cardIndex4] = {
            ...action.card,
            files: action.card.files.map((file) => file.id),
            tasks: action.card.tasks.map((task) => task.id),
            comments: action.card.comments.map((comment) => comment.id),
            emails: action.card.emails.map((email) => email.id),
            events: action.card.events.map((event) => event.id),
            quotes: action.card.quotes.map((quote) => quote.id),
            activities: action.card.activities.map((activity) => activity.id),
          };
          listsAdapter.updateOne(list2, draft.lists);
        }
        return draft;
      case postCardSuccessAction.type:
        const list1 = draft.lists.entities[action.card.list];
        if (list1) {
          list1.total += 1;
          list1.cards = [
            {
              ...action.card,
              files: action.card.files.map((file) => file.id),
              tasks: action.card.tasks.map((task) => task.id),
              comments: action.card.comments.map((comment) => comment.id),
              emails: action.card.emails.map((email) => email.id),
              events: action.card.events.map((event) => event.id),
              quotes: action.card.quotes.map((quote) => quote.id),
              activities: action.card.activities.map((activity) => activity.id),
            },
            ...list1.cards,
          ];
          listsAdapter.updateOne(list1, draft.lists);
        }
        return draft;
      case getListCardsSuccessAction.type:
        let list3 = draft.lists.entities[action.list];
        const cards = action.cards.filter(
          (card) => !list3.cards.some((crd) => crd.id === card.id)
        );
        if (list3) {
          list3 = {
            ...list3,
            hasMore: list3.cards.length + action.cards.length < list3.total,
            cards: [...list3.cards, ...cards],
          };
          listsAdapter.updateOne(list3, draft.lists);
        }
        return draft;
      case putCardSuccessAction.type:
        draft.card = action.card;
        draft.errorCode = null;
        draft.errorMessage = null;
        return draft;
      case postAssignmentSuccessAction.type:
        draft.card = {
          ...draft.card!,
          assignments: action.assignments,
        };
        return draft;
      case deleteAssignmentSuccessAction.type:
        draft.card = {
          ...draft.card!,
          assignments: action.assignments,
        };
        return draft;
      case postContactSuccessAction.type:
        draft.card = {
          ...draft.card!,
          contacts: action.contacts,
        };
        return draft;
      case deleteContactSuccessAction.type:
        draft.card = {
          ...draft.card!,
          contacts: action.contacts,
        };
        return draft;
      case postCompanySuccessAction.type:
        draft.card = {
          ...draft.card!,
          companies: action.companies,
        };
        return draft;
      case deleteCompanySuccessAction.type:
        draft.card = {
          ...draft.card!,
          companies: action.companies,
        };
        return draft;
      case postCommentSuccessAction.type:
        commentsAdapter.setOne(action.comment, draft.comments);
        commentsAdapter.moveToFirstPosition(action.comment.id, draft.comments);
        return draft;
      case putCommentSuccessAction.type:
        commentsAdapter.updateOne(action.comment, draft.comments);
        return draft;
      case deleteCommentSuccessAction.type:
        commentsAdapter.removeOne(action.id, draft.comments);
        return draft;
      case deleteListSuccessAction.type:
        listsAdapter.removeOne(action.id, draft.lists);
        return draft;
      case postFilesSuccessAction.type:
        filesAdapter.addMany(action.files, draft.files);
        return draft;
      case deleteFileSuccessAction.type:
        filesAdapter.removeOne(action.id, draft.files);
        return draft;
      case changeListOrderAction.type:
        listsAdapter.moveToPosition(action.id, action.index, draft.lists);
        return draft;
      case setBoardUpdateAction.type:
        draft.board = action.board;
        return draft;
      case changeCardOrderAction.type:
        if (draft.lists.ids.length) {
          const listSource3 = draft.lists.entities[action.listSourceId];
          const listDestination3 = draft.lists.entities[action.listDestinationId];

          if (listSource3 && listDestination3) {
            const cardIndex3 = listSource3.cards.findIndex((card) => card.id === action.id);
            const card3 = listSource3.cards[cardIndex3];
            card3.list = action.listDestinationId;
            card3.updatedAt = moment().toISOString();
            listSource3.cards.splice(cardIndex3, 1);
            listSource3.total -= 1;
            listDestination3.cards.splice(action.index, 0, card3);
            listDestination3.total += 1;
            draft.lists.entities[action.listSourceId] = listSource3;
            draft.lists.entities[action.listDestinationId] = listDestination3;
          }
        }
        return draft;
      case putCardOrderSuccessAction.type:
        if (draft.card?.id === action.id) {
          draft.card.list = action.listDestinationId;
        }
        return draft;
      case setFilterAction.type:
        filtersAdapter.updateOne(action.filters, draft.filters);
        return draft;
      case clearFilterAction.type:
        filtersAdapter.removeOne(action.id, draft.filters);
        return draft;
      case getBoardActivityInitAction.type:
        draft = {
          ...initialState,
          boardBusiness: draft.boardBusiness,
          boards: draft.boards,
          card: draft.card,
          filters: draft.filters,
        };
        return draft;
      case getBoardBusinessInitAction.type:
        draft = {
          ...initialState,
          boardActivity: draft.boardActivity,
          boards: draft.boards,
          card: draft.card,
          filters: draft.filters,
        };
        return draft;
      case getListsSuccessAction.type:
        draft.listNames = action.lists;
        return draft;
      case putCardFailureAction.type:
        draft.errorCode = action.errorCode;
        draft.errorMessage = action.errorMessage;
        return draft;
      case getCardTagsSuccessAction.type:
        tagsAdapter.addMany(action.tags, draft.allTags);
        return draft;
      case postCardTagSuccessAction.type:
        tagsAdapter.setOne(action.tag, draft.allTags);
        return draft;
      case putCardTagSuccessAction.type:
        tagsAdapter.updateOne(action.tag, draft.allTags);
        return draft;
      case deleteCardTagSuccessAction.type:
        tagsAdapter.removeOne(action.id, draft.allTags);
        return draft;
      case postTagSuccessAction.type:
        tagsAdapter.setOne(action.tag, draft.tags);
        return draft;
      case deleteTagSuccessAction.type:
        tagsAdapter.removeOne(action.id, draft.tags);
        return draft;
      case deleteBoardSuccessAction.type:
        boardsAdapter.removeOne(action.id, draft.boards);
        if (
          action.id === draft.boardActivity?.id ||
          action.id === draft.boardBusiness?.id
        ) {
          draft.boardActivity = null;
          draft.boardBusiness = null;
          listsAdapter.removeAll(draft.lists);
        }
        return draft;
      case setShowTagTextAction.type:
        draft.showTagText = action.showTagText;
        return draft;
      case postProductSuccessAction.type:
        productsAdapter.setOne(action.product, draft.products);
        return draft;
      case putProductSuccessAction.type:
        productsAdapter.updateOne(action.product, draft.products);
        return draft;
      case deleteProductSuccessAction.type:
        productsAdapter.removeOne(action.id, draft.products);
        return draft;
      case postQuoteSuccessAction.type:
        quotesAdapter.setOne(action.quote, draft.quotes);
        return draft;
      case getCardInitAction.type:
        draft.errorCode = null;
        return draft;
      case getCardFailureAction.type:
        draft.errorCode = action.errorCode;
        return draft;
      case setQuoteAction.type:
        draft.quote = action.quote;
        return draft;
      case getCustomFieldsSuccessAction.type:
        customFieldAdapter.addMany(action.fields, draft.customFields);
        return draft;
      case postCustomFieldSuccessAction.type:
        customFieldAdapter.setOne(action.field, draft.customFields);
        return draft;
      case putCustomFieldSuccessAction.type:
        customFieldAdapter.updateOne(action.field, draft.customFields);
        return draft;
      case deleteCustomFieldSuccessAction.type:
        customFieldAdapter.removeOne(action.id, draft.customFields);
        return draft;
      case cleanCardAction.type:
        draft.card = null;
        commentsAdapter.removeAll(draft.comments);
        filesAdapter.removeAll(draft.files);
        tasksAdapter.removeAll(draft.tasks);
        emailsAdapter.removeAll(draft.emails);
        tagsAdapter.removeAll(draft.tags);
        eventsAdapter.removeAll(draft.events);
        quotesAdapter.removeAll(draft.quotes);
        activitiesAdapter.removeAll(draft.activities);
        return draft;
      case resetAction.type:
        return initialState;
      case getBoardFailureAction.type:
      case postBoardInitAction.type:
      case postBoardFailureAction.type:
      case postCardInitAction.type:
      case postCardFailureAction.type:
      case postCommentInitAction.type:
      case postCommentFailureAction.type:
      case getBoardsInitAction.type:
      case getBoardsFailureAction.type:
      case putCardInitAction.type:
      case putListInitAction.type:
      case putListFailureAction.type:
      case deleteCommentInitAction.type:
      case deleteCommentFailureAction.type:
      case putCommentInitAction.type:
      case putCommentFailureAction.type:
      case postListInitAction.type:
      case postListFailureAction.type:
      case putBoardInitAction.type:
      case putBoardFailureAction.type:
      case deleteCardInitAction.type:
      case deleteCardFailureAction.type:
      case deleteCardSuccessAction.type:
      case deleteBoardInitAction.type:
      case deleteBoardFailureAction.type:
      case deleteListInitAction.type:
      case deleteListFailureAction.type:
      case putListOrderInitAction.type:
      case putListOrderSuccessAction.type:
      case putListOrderFailureAction.type:
      case putCardOrderInitAction.type:
      case putCardOrderFailureAction.type:
      case postAssignmentInitAction.type:
      case postAssignmentFailureAction.type:
      case deleteAssignmentInitAction.type:
      case deleteAssignmentFailureAction.type:
      case getListBudgetInitAction.type:
      case getListBudgetFailureAction.type:
      case postContactInitAction.type:
      case postContactFailureAction.type:
      case deleteContactInitAction.type:
      case deleteContactFailureAction.type:
      case deleteCompanyInitAction.type:
      case deleteCompanyFailureAction.type:
      case postTaggingInitAction.type:
      case postTaggingSuccessAction.type:
      case postTaggingFailureAction.type:
      case postFilesInitAction.type:
      case postFilesFailureAction.type:
      case deleteFileInitAction.type:
      case deleteFileFailureAction.type:
      case getListsInitAction.type:
      case getListsFailureAction.type:
      case getCardTagsInitAction.type:
      case getCardTagsFailureAction.type:
      case postCardTagInitAction.type:
      case postCardTagFailureAction.type:
      case putCardTagInitAction.type:
      case putCardTagFailureAction.type:
      case deleteCardTagInitAction.type:
      case deleteCardTagFailureAction.type:
      case postTagInitAction.type:
      case postTagFailureAction.type:
      case deleteTagInitAction.type:
      case deleteTagFailureAction.type:
      case getListCardsInitAction.type:
      case getListCardsFailureAction.type:
      case postProductInitAction.type:
      case postProductFailureAction.type:
      case putProductInitAction.type:
      case putProductFailureAction.type:
      case deleteProductInitAction.type:
      case deleteProductFailureAction.type:
      case getBoardExportInitAction.type:
      case getBoardExportSuccessAction.type:
      case getBoardExportFailureAction.type:
      case getCustomFieldsInitAction.type:
      case getCustomFieldsFailureAction.type:
      case postCustomFieldInitAction.type:
      case postCustomFieldFailureAction.type:
      case putCustomFieldInitAction.type:
      case putCustomFieldFailureAction.type:
      case deleteCustomFieldInitAction.type:
      case deleteCustomFieldFailureAction.type:
      case getListContactsInitAction.type:
      case getListContactsSuccessAction.type:
      case getListContactsFailureAction.type:
      case putMoveCardsInitAction.type:
      case putMoveCardsSuccessAction.type:
      case putMoveCardsFailureAction.type:
      default:
        return draft;
    }
  }
);
