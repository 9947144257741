import React from "react";
import { Col, Container, Label, Row } from "reactstrap";

interface Props {
  questions: string[];
}

export default function FormQuestions(props: Props) {
  const { questions } = props;

  return (
    <Container className="h-100 p-4" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 justify-content-center">
            <h2 className="h2 text-center mb-4">Preguntas</h2>
            {questions && questions.map((question) => (
              <Label key={question} className="text-center">
                {question} 
              </Label>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
