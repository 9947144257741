import React from "react";
import SideModal from "../../layout/SideModal";
import {
  DELETE_EVENT_MODAL,
  EVENT_CARD_MODAL,
  EVENT_CONTACTS_MODAL,
  EVENT_USERS_MODAL,
} from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";

export default function EventSideModals() {
  const {
    isModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
  } = useGlobalCore();

  return (
    <>
      <SideModal
        active={isModal(EVENT_CARD_MODAL)}
        visible={getModalVisibility(EVENT_CARD_MODAL)}
        onClose={() => turnOffModal(EVENT_CARD_MODAL)}
      >
        {getModalContent(EVENT_CARD_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(EVENT_CONTACTS_MODAL)}
        visible={getModalVisibility(EVENT_CONTACTS_MODAL)}
        onClose={() => turnOffModal(EVENT_CONTACTS_MODAL)}
        left
      >
        {getModalContent(EVENT_CONTACTS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(EVENT_USERS_MODAL)}
        visible={getModalVisibility(EVENT_USERS_MODAL)}
        onClose={() => turnOffModal(EVENT_USERS_MODAL)}
        left
      >
        {getModalContent(EVENT_USERS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(DELETE_EVENT_MODAL)}
        visible={getModalVisibility(DELETE_EVENT_MODAL)}
        onClose={() => turnOffModal(DELETE_EVENT_MODAL)}
        left
      >
        {getModalContent(DELETE_EVENT_MODAL)}
      </SideModal>
    </>
  );
}
