import cn from "classnames";
import React from "react";
import { Icon } from "../../companies/icons/Icon";

interface Props {
  amount: number;
  showLabel?: boolean;
  color?: string;
  className?: string;
}

export const LikeLabel = (props: Props) => {
  const { amount, showLabel, color, className } = props;
  return (
    <span className={cn(className, "font-weight-bolder")}>
      <Icon name="heart" color={color || "#ef767a"} className="mr-2" />
      {showLabel && "Me Gusta: "} {amount}
    </span>
  );
};
