import React, { useCallback, useEffect, useMemo, useState } from "react";
import QueryString from "query-string";
import { Col, Container, Row } from "reactstrap";
import {
  FACEBOOK_POST_LOADING,
  INSTAGRAM_POST_LOADING,
} from "../../constants/loading.constant";
import {
  ADD_SOCIAL_IMAGE_MODAL,
  ADD_SOCIAL_VIDEO_MODAL,
  EDIT_IMAGE_MODAL,
  EDIT_VIDEO_MODAL,
  EMOJI_FORM,
  HASHTAGS_SOCIAL_FORM,
  SCHEDULE_POST_MODAL,
} from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useIntl } from "react-intl";
import { useRouterCore } from "../../cores/router";
import { useSocialPostCore } from "../../cores/socialPost";
import { PickerMode } from "../../cores/socialPost/config";
import { PanelWembii } from "../component/PanelWembii";
import { TextEditor } from "../component/socialPost/creator/TextEditor";
import { PostForm } from "../component/socialPost/PostForm";
import { TitleWembii } from "../component/TitleWembii";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { SocialStorySelector } from "../component/socialPost/selector/SocialStorySelector";
import { RemindButton } from "../component/socialPost/creator/RemindButton";
import ImageForm from "../component/socialPost/modals/ImageForm";
import VideoForm from "../component/socialPost/modals/VideoForm";
import { SocialStoryPreview } from "../component/socialPost/preview/SocialStoryPreview";
import { SocialType } from "../../cores/socialPost/interfaces/postError";
import { useLocation } from "react-router-dom";
import { StoryActionButtons } from "../component/socialPost/creator/StoryActionButtons";
import { EmojiForm } from "../component/EmojiForm";
import HashtagsForm from "../component/socialPost/modals/HashtagsForm";

export default function SocialStory() {
  const intl = useIntl();
  const { search } = useLocation();
  const { getMeSocialSettings, company, companyRole, isAdmin, isReseller } =
    useMeCore();
  const {
    socialProviders,
    selectedPost,
    reset,
    addEmoji,
    updateMentions,
    inputCursorIndex,
    getHashtags,
  } = useSocialPostCore();
  const { goToSocial } = useRouterCore();
  const {
    areLoading,
    isModal,
    getModalContent,
    getModalVisibility,
    turnOnModal,
    turnOffModal,
  } = useGlobalCore();
  const [picker, setPicker] = useState<PickerMode>(PickerMode.Off);
  const [from, setFrom] = useState("");

  useEffect(() => {
    if (
      !companyRole?.social &&
      !companyRole?.owner &&
      !companyRole?.admin &&
      !companyRole?.agent &&
      !isAdmin &&
      !isReseller
    )
      goToSocial();
  }, [goToSocial, companyRole, isAdmin, isReseller]);

  const loadingIds = useMemo(
    (): string[] => [INSTAGRAM_POST_LOADING || FACEBOOK_POST_LOADING],
    []
  );

  const shouldDisplay = useMemo(
    (): boolean => !areLoading(loadingIds),
    [areLoading, loadingIds]
  );

  useEffect(() => {
    const { from } = QueryString.parse(search);
    if (from) {
      setFrom(from as string);
    }
  }, [search]);

  useEffect(() => {
    getMeSocialSettings(company!.id);
  }, [company, getMeSocialSettings]);

  const resetPost = useCallback(() => {
    setPicker(PickerMode.Off);
    reset();
  }, [reset]);

  const handleAddEmoji = useCallback(
    (emoji: any) => {
      const unifieds = emoji.unified.split("-");
      const emojiCode = unifieds
        .map((unified: any) => String.fromCodePoint(Number(`0x${unified}`)))
        .join("");
      addEmoji(emojiCode);
      updateMentions(emojiCode.length, inputCursorIndex);
    },
    [addEmoji, updateMentions, inputCursorIndex]
  );

  const openEmojiModal = useCallback(() => {
    turnOnModal(EMOJI_FORM, <EmojiForm onAdding={handleAddEmoji} />);
  }, [turnOnModal, handleAddEmoji]);

  const openImagePickerModal = useCallback(() => {
    turnOnModal(ADD_SOCIAL_IMAGE_MODAL, true);
  }, [turnOnModal]);

  const openVideoPickerModal = useCallback(() => {
    turnOnModal(ADD_SOCIAL_VIDEO_MODAL, true);
  }, [turnOnModal]);

  const openHashtagModal = useCallback(() => {
    getHashtags(company?.id!);
    turnOnModal(HASHTAGS_SOCIAL_FORM, true);
  }, [turnOnModal, getHashtags, company]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.socialStory.title" })}
        subtitle={
          !selectedPost
            ? intl.formatMessage({
                id: "app.socialMediaPost.selectSocialMedia",
              })
            : intl.formatMessage({ id: "app.socialStory.modifyPost" })
        }
        mb={false}
        rightChildren={
          <>
            {selectedPost && (
              <StoryActionButtons from={from} onReset={resetPost} />
            )}
          </>
        }
      />
      <Content>
        <UpdateLoadingCard
          loadingId={loadingIds}
          text={intl.formatMessage({ id: "app.socialMediaPost.programming" })}
        />
        {shouldDisplay && (
          <Container fluid>
            <Row>
              <Col xs="12">
                <SocialStorySelector />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col xs="12" lg="6">
                <div className="pt-4">
                  <PanelWembii shadow className="p-3 px-4">
                    <TitleWembii Tag="h5" color="primary">
                      {intl.formatMessage({ id: "app.socialStory.yourStory" })}
                    </TitleWembii>
                    <div className="p-3 wembii-border">
                      <TextEditor
                        activePicker={picker}
                        onClickEmoji={openEmojiModal}
                        onClickHashtag={openHashtagModal}
                        onClickImages={openImagePickerModal}
                        onClickVideo={openVideoPickerModal}
                        socialType={SocialType.Story}
                      />
                    </div>

                    <RemindButton />
                  </PanelWembii>
                </div>
              </Col>
              {socialProviders.length > 0 && (
                <Col xs="12" lg="6">
                  <div className="pt-4">
                    <PanelWembii shadow className="p-3 px-4">
                      <TitleWembii Tag="h5" color="primary">
                        {intl.formatMessage({
                          id: "app.socialMediaPost.preview",
                        })}
                      </TitleWembii>
                      <SocialStoryPreview />
                    </PanelWembii>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        )}
      </Content>
      <SideModal
        active={isModal(SCHEDULE_POST_MODAL)}
        visible={getModalVisibility(SCHEDULE_POST_MODAL)}
        onClose={() => turnOffModal(SCHEDULE_POST_MODAL)}
      >
        <PostForm
          onClose={() => turnOffModal(SCHEDULE_POST_MODAL)}
          onReset={resetPost}
          repost={false}
          socialType={SocialType.Story}
        />
      </SideModal>
      <SideModal
        active={isModal(ADD_SOCIAL_IMAGE_MODAL)}
        visible={getModalVisibility(ADD_SOCIAL_IMAGE_MODAL)}
        onClose={() => turnOffModal(ADD_SOCIAL_IMAGE_MODAL)}
      >
        <ImageForm singleImage={true} />
      </SideModal>
      <SideModal
        active={isModal(ADD_SOCIAL_VIDEO_MODAL)}
        visible={getModalVisibility(ADD_SOCIAL_VIDEO_MODAL)}
        onClose={() => turnOffModal(ADD_SOCIAL_VIDEO_MODAL)}
      >
        <VideoForm />
      </SideModal>
      <SideModal
        active={isModal(EMOJI_FORM)}
        visible={getModalVisibility(EMOJI_FORM)}
        onClose={() => turnOffModal(EMOJI_FORM)}
      >
        {getModalContent(EMOJI_FORM)}
      </SideModal>
      <SideModal
        active={isModal(EDIT_IMAGE_MODAL)}
        visible={getModalVisibility(EDIT_IMAGE_MODAL)}
        onClose={() => turnOffModal(EDIT_IMAGE_MODAL)}
        left
      >
        {getModalContent(EDIT_IMAGE_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(EDIT_VIDEO_MODAL)}
        visible={getModalVisibility(EDIT_VIDEO_MODAL)}
        onClose={() => turnOffModal(EDIT_VIDEO_MODAL)}
        left
      >
        {getModalContent(EDIT_VIDEO_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(HASHTAGS_SOCIAL_FORM)}
        visible={getModalVisibility(HASHTAGS_SOCIAL_FORM)}
        onClose={() => turnOffModal(HASHTAGS_SOCIAL_FORM)}
      >
        <HashtagsForm />
      </SideModal>
    </Layout>
  );
}
