import {
  GetFacebookAdsAccountPayload,
  GetFacebookAdsAccountResponse,
} from "../interface/api/get-facebook-ads-account";
import {
  FacebookAdsSetting,
  PostFacebookAdsBindResponse,
} from "../interface/api/post-facebook-ads-bind";

export const facebookAdsMapper = {
  mapResponseToFacebookAdsSetting(
    response: PostFacebookAdsBindResponse
  ): FacebookAdsSetting {
    const { facebookAdsSetting } = response?.payload;
    return facebookAdsSetting;
  },

  mapResponseToFacebookAdsAccounts(
    response: GetFacebookAdsAccountResponse
  ): GetFacebookAdsAccountPayload {
    const { accounts, adsAccounts } = response?.payload;
    return { accounts, adsAccounts };
  },
};
