import panelApiClient from "../../helpers/services/panel-api-client";
import { DeleteTaskResponse } from "../interfaces/api/delete-task";
import { GetTaskResponse } from "../interfaces/api/get-task";
import {
  GetTaskByContactParams,
  GetTasksParams,
  GetTasksResponse,
} from "../interfaces/api/get-tasks";
import { PostTaskRequest, PostTaskResponse } from "../interfaces/api/post-task";
import { PutTaskRequest, PutTaskResponse } from "../interfaces/api/put-task";
import { taskMapper } from "./mapper";

export const taskApiService = {
  getTasks(companyId: string, params: GetTasksParams) {
    return panelApiClient()
      .get<GetTasksResponse>(`tasks`, { params: { companyId, ...params } })
      .then(({ data }) => taskMapper.mapResponseToTasks(data));
  },
  getTasksExport(
    companyId: string,
    { completedDates, contactId, status, title__p, userId }: GetTasksParams
  ) {
    return panelApiClient()
      .get<GetTasksResponse>(`tasks/export`, {
        params: {
          companyId,
          completedDates,
          contactId,
          status,
          title__p,
          userId,
        },
      })
      .then(({ data }) => taskMapper.mapResponseToTasks(data));
  },
  getContactTasks(companyId: string, params: GetTaskByContactParams) {
    return panelApiClient()
      .get<GetTasksResponse>(`tasks/contactTasks`, {
        params: { companyId, ...params },
      })
      .then(({ data }) => taskMapper.mapResponseToTasks(data));
  },
  getTask(companyId: string, taskId: string) {
    return panelApiClient()
      .get<GetTaskResponse>(`tasks/${taskId}`, { params: { companyId } })
      .then(({ data }) => taskMapper.mapResponseToTask(data));
  },
  postTask(companyId: string, body: PostTaskRequest) {
    return panelApiClient()
      .post<PostTaskResponse>(`tasks`, body, {
        params: { companyId },
      })
      .then(({ data }) => taskMapper.mapResponseToTask(data));
  },
  putTask(companyId: string, taskId: string, body: PutTaskRequest) {
    return panelApiClient()
      .put<PutTaskResponse>(`tasks/${taskId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => taskMapper.mapResponseToTask(data));
  },
  deleteTask(companyId: string, taskId: string) {
    return panelApiClient()
      .delete<DeleteTaskResponse>(`tasks/${taskId}`, { params: { companyId } })
      .then(({ data }) => taskMapper.mapResponseToId(data));
  },
};
