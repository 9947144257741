import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { InstagramAccount } from "../interface/api/get-instagram-accounts";
import { InstagramSetting } from "../interface/api/post-instagram-bind";

export const postUnbindInstagramInitAction = createAction(
  "[Instagram] Unbind Instagram Init"
);
export const postUnbindInstagramSuccessAction = createAction(
  "[Instagram] Unbind Instagram Success"
);
export const postUnbindInstagramFailureAction = createAction(
  "[Instagram] Unbind Instagram Failure",
  props<{ error: string }>()
);

export const postBindInstagramInitAction = createAction(
  "[Instagram] Bind Instagram Init"
);
export const postBindInstagramSuccessAction = createAction(
  "[Instagram] Bind Instagram Success",
  props<{ settings: InstagramSetting }>()
);
export const postBindInstagramFailureAction = createAction(
  "[Instagram] Bind Instagram Failure",
  props<{ errorCode: number | null, errorMessage: string | null }>()
);

export const getInstagramInitAction = createAction(
  "[Instagram] Get Instagram Settings Init"
);
export const getInstagramSuccessAction = createAction(
  "[Instagram] Get Instagram Settings Success",
  props<{ settings: InstagramSetting }>()
);
export const getInstagramFailureAction = createAction(
  "[Instagram] Get Instagram Settings Failure",
  props<{ error: string }>()
);

export const getInstagramAccountsInitAction = createAction(
  "[Instagram] Get Instagram Accounts Init"
);
export const getInstagramAccountsSuccessAction = createAction(
  "[Instagram] Get Instagram Accounts Success",
  props<{ accounts: InstagramAccount[] }>()
);
export const getInstagramAccountsFailureAction = createAction(
  "[Instagram] Get Instagram Accounts Failure",
  props<{ error: string }>()
);

export const postInstagramSocialPostInitAction = createAction(
  "[Instagram] Social Post Init"
);
export const postInstagramSocialPostSuccessAction = createAction(
  "[Instagram] Social Post Success"
);
export const postInstagramSocialPostFailureAction = createAction(
  "[Instagram] Social Post Failure",
  props<{ errorCode: number, errorMessage: string; }>()
);

export const postInstagramSocialStoryInitAction = createAction(
  "[Instagram] Social Story Init"
);
export const postInstagramSocialStorySuccessAction = createAction(
  "[Instagram] Social Story Success"
);
export const postInstagramSocialStoryFailureAction = createAction(
  "[Instagram] Social Story Failure",
  props<{ error: string; }>()
);

export const putInstagramSocialStoryInitAction = createAction(
  "[Instagram] Social Put Story Init"
);
export const putInstagramSocialStorySuccessAction = createAction(
  "[Instagram] Social Put Story Success"
);
export const putInstagramSocialStoryFailureAction = createAction(
  "[Instagram] Social Put Story Failure",
  props<{ error: string; }>()
);


export const postInstagramCodeInitAction = createAction(
  "[Instagram] Send Code Init"
);
export const postInstagramCodeSuccessAction = createAction(
  "[Instagram] Send Code Success"
);
export const postInstagramCodeFailureAction = createAction(
  "[Instagram] Send Code Failure",
  props<{ error: string }>()
);

export const getInstagramCodeInitAction = createAction(
  "[Instagram] Request Code Init"
);
export const getInstagramCodeSuccessAction = createAction(
  "[Instagram] Request Code Success"
);
export const getInstagramCodeFailureAction = createAction(
  "[Instagram] Request Code Failure",
  props<{ error: string }>()
);

export const putInstagramSocialPostInitAction = createAction(
  "[Instagram] Social Put Init"
);
export const putInstagramSocialPostSuccessAction = createAction(
  "[Instagram] Social Put Success"
);
export const putInstagramSocialPostFailureAction = createAction(
  "[Instagram] Social Put Failure",
  props<{ errorCode: number, errorMessage: string }>()
);

const actions = joinActions({
  postInstagramCodeInitAction,
  postInstagramCodeSuccessAction,
  postInstagramCodeFailureAction,
  getInstagramCodeInitAction,
  getInstagramCodeSuccessAction,
  getInstagramCodeFailureAction,
  postUnbindInstagramInitAction,
  postUnbindInstagramSuccessAction,
  postUnbindInstagramFailureAction,
  postBindInstagramInitAction,
  postBindInstagramSuccessAction,
  postBindInstagramFailureAction,
  getInstagramInitAction,
  getInstagramSuccessAction,
  getInstagramFailureAction,
  postInstagramSocialPostInitAction,
  postInstagramSocialPostSuccessAction,
  postInstagramSocialPostFailureAction,
  getInstagramAccountsInitAction,
  getInstagramAccountsSuccessAction,
  getInstagramAccountsFailureAction,
  putInstagramSocialPostInitAction,
  putInstagramSocialPostSuccessAction,
  putInstagramSocialPostFailureAction,
  putInstagramSocialStoryInitAction,
  putInstagramSocialStorySuccessAction,
  putInstagramSocialStoryFailureAction,
});

export type InstagramApiAction = typeof actions;
