import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { InputWembii } from "../../InputWembii";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { useProductCore } from "../../../../cores/products";
import { LabelWembii } from "../../LabelWembii";
import { getCurrencySymbol } from "../../../../cores/boards/constants/currencies.constant";
import { PRODUCT_PRICE } from "../../../../constants/internalModal.constant";
import { Price } from "../../../../cores/products/interfaces/api/get-product";
import { useGlobalCore } from "../../../../cores/globals";
import InternalModal from "../../../layout/InternalModal";
import PriceForm from "../PriceForm";

interface Props {
  onDelete: () => void;
  onUpdate?: () => void;
}

export default function EditProductForm(props: Props) {
  const intl = useIntl();
  const { onDelete, onUpdate } = props;
  const { company } = useMeCore();
  const { putProduct, product } = useProductCore();
  const { isInternalModal, turnOffInternalModal, turnOnInternalModal } = useGlobalCore();

  const [index, setIndex] = useState(-1);
  const [price, setPrice] = useState<Price | null>(null);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [prices, setPrices] = useState<Price[]>([]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    putProduct(
      company?.id!,
      product?.id!,
      {
        name,
        code,
        description,
        prices,
      },
      onUpdate
    )
  };

  const disabled = useCallback(
    () => !name,
    [name]
  );

  useEffect(() => {
    if (product) {
      setName(product.name);
      setCode(product.code);
      setDescription(product.description);
      setPrices(product.prices);
    }
  }, [product]);

  const onClickAddingPrice = useCallback(() => {
    setPrice(null);
    setIndex(-1);
    turnOnInternalModal(PRODUCT_PRICE, true);
  }, [turnOnInternalModal]);

  const onAddingPrice = useCallback((currency, price) => {
    setPrices([
      ...prices,
      { id: "", currency, price }
    ]);
  }, [prices]);

  const onEditingPrice = useCallback((price) => {
    const newPrices = [...prices];
    newPrices.splice(index, 1);
    newPrices.splice(index, 0, price);
    setPrices(newPrices);
  }, [prices, index]);

  const onDeletingPrice = useCallback((index) => {
    const newPrices = [...prices];
    newPrices.splice(index, 1);
    setPrices(newPrices);
  }, [prices]);

  const onClickEditingPrice = useCallback((index) => {
    const price = prices[index];
    setPrice(price);
    setIndex(index);
    turnOnInternalModal(PRODUCT_PRICE, true);
  }, [prices, turnOnInternalModal]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(PRODUCT_PRICE)}
        onClose={() => turnOffInternalModal(PRODUCT_PRICE)}
      >
        <PriceForm onAdding={onAddingPrice} onEdditing={onEditingPrice} productPrice={price} />
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 text-center mb-4">
                <FontAwesomeIcon icon={["fas", "shopping-cart"]} className="mr-2" />
                {intl.formatMessage({ id: "app.products.product" })}
              </h2>

              <Form className="px-2" onSubmit={onFormSubmit}>
                <InputWembii
                  label={intl.formatMessage({ id: "app.products.name" })}
                  type="text"
                  name="name"
                  id="product-name"
                  required
                  placeholder={intl.formatMessage({ id: "app.products.name" })}
                  value={name}
                  onChange={setName}
                />
                <InputWembii
                  label={intl.formatMessage({ id: "app.products.code" })}
                  type="text"
                  name="code"
                  id="product-code" 
                  placeholder={intl.formatMessage({ id: "app.products.code" })}
                  value={code}
                  onChange={setCode}
                />
                <InputWembii
                  label={intl.formatMessage({ id: "app.products.description" })}
                  type="textarea"
                  name="description"
                  id="product-description"
                  placeholder={intl.formatMessage({ id: "app.products.description" })}
                  style={{ minHeight: 150 }}
                  value={description}
                  onChange={setDescription}
                />

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <LabelWembii>
                    {intl.formatMessage({ id: "app.products.unitPrice" })}
                  </LabelWembii>

                  <Button
                    className="whiteButton shadow m-0"
                    onClick={onClickAddingPrice}
                  >
                    {intl.formatMessage({ id: "app.products.price.add" })}
                  </Button>
                </div>

                {prices.length > 0 && (
                  <Row>
                    <Col md="8">
                      <Row>
                        <Col className="font-weight-bold text-muted">
                          {intl.formatMessage({ id: "app.products.currency" })}
                        </Col>
                        <Col className="font-weight-bold text-muted">
                          {intl.formatMessage({ id: "app.products.unitPrice" })}
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4" />
                  </Row>
                )}
                {prices.map((price, index) => (
                  <Row key={index} className={cn("py-2", {
                    'bg-light': index % 2 === 0
                  })}>
                    <Col md="8">
                      <Row>
                        <Col>{getCurrencySymbol(price.currency)}</Col>
                        <Col>{price.price}</Col>
                      </Row>
                    </Col>
                    <Col md="4" className="d-flex justify-content-end">
                      <div className="text-primary mx-2 pointer" onClick={() => onClickEditingPrice(index)}>
                        <FontAwesomeIcon
                          icon={["fas", "edit"]}
                          size="1x"
                          className="mr-1"
                        />
                      </div>
                      <div className="text-danger mx-2 pointer" onClick={() => onDeletingPrice(index)}>
                        <FontAwesomeIcon
                          icon={["fas", "trash"]}
                          size="1x"
                          className="mr-1"
                        />
                      </div>
                    </Col>
                  </Row>
                ))}

                <div className="d-flex align-items-center my-3">
                  <Button
                    color="primary"
                    block
                    disabled={disabled()}
                    className="mr-1 my-0"
                  >
                    {intl.formatMessage({ id: "app.contacts.save" })}
                  </Button>
                  <Button
                    className="ml-1 my-0"
                    color="danger"
                    block
                    onClick={onDelete}
                  >
                    {intl.formatMessage({ id: "app.contacts.delete" })}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
