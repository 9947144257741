import mainApiClient from "../../helpers/services/main-api-client";
import { GetPlansResponse } from "../interfaces/api/get-plans";
import { PostFreeTrialAssignRequest } from "../interfaces/api/post-free-trial-assign";
import { PostPlanAssignRequest } from "../interfaces/api/post-plans-assign";
import { planMapper } from "./mapper";

export const planApiService = {
  getPlans() {
    return mainApiClient()
      .get<GetPlansResponse>(`plans`)
      .then(({ data }) => planMapper.mapResponseToPlans(data));
  },
  getAllPlans() {
    return mainApiClient()
      .get<GetPlansResponse>(`plans/all`)
      .then(({ data }) => planMapper.mapResponseToPlans(data));
  },
  assignPlan(data: PostPlanAssignRequest) {
    return mainApiClient()
      .post(`plans/assign`, data)
      .then(({ data }) => data);
  },
  assignFreetrial(data: PostFreeTrialAssignRequest) {
    return mainApiClient()
      .post(`plans/freetrial/assign`, data)
      .then(({ data }) => data);
  },
  unassignFreetrial(userId: string) {
    return mainApiClient()
      .delete(`plans/freetrial/unassign/${userId}`)
      .then(({ data }) => data);
  },
  requestUpgradePlan() {
    return mainApiClient()
      .post(`plans/upgrade`)
      .then(({ data }) => data);
  },
};
