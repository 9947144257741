import { EmailTypes } from "../cores/unsubscribedSuccess/interfaces/emailTypes.interface";


export const emailTypes: EmailTypes = {
  formEmail: "app.unsubscribeEmail.formEmail",

  confirmationEmail: "app.unsubscribeEmail.confirmationEmail",

  welcomeEmail: "app.unsubscribeEmail.welcomeEmail",

  newUserEmail: "app.unsubscribeEmail.newUserEmail",

  forgotPasswordEmail: "app.unsubscribeEmail.forgotPasswordEmail",

  reportUserEmail: "app.unsubscribeEmail.reportUserEmail",

  reportAdminEmail: "app.unsubscribeEmail.reportAdminEmail",

  failedPostEmail: "app.unsubscribeEmail.failedPostEmail",

  upgradeAdminEmail: "app.unsubscribeEmail.upgradeAdminEmail",

  offlineMessageEmail: "app.unsubscribeEmail.offlineMessageEmail",

  activityAssignmentEmail: "app.unsubscribeEmail.activityAssignmentEmail",

  taskAssignmentEmail: "app.unsubscribeEmail.taskAssignmentEmail",

  businessAssignmentEmail: "app.unsubscribeEmail.businessAssignmentEmail",

  taskTaggingAssignmentEmail: "app.unsubscribeEmail.taskTaggingAssignmentEmail",

  newSubscriptionEmail: "app.unsubscribeEmail.newSubscriptionEmail",

  tokenExpirationEmail: "app.unsubscribeEmail.tokenExpirationEmail",

  socialHistoryReminderEmail: "app.unsubscribeEmail.socialHistoryReminderEmail",

  boardInactivityEmail: "app.unsubscribeEmail.boardInactivityEmail",

  eventCreatedEmail: "app.unsubscribeEmail.eventCreatedEmail",
};
