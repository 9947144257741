import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ImportingProcessNotification } from "../../../cores/notifications/interfaces/api/get-notifications";
import { useIntl } from "react-intl";

interface Props {
  notification: ImportingProcessNotification; 
}

export default function ImportingProcessNotificationItem({
  notification
}: Props) {
  const intl = useIntl();

  return (
    <div className="d-flex align-items-center border-bottom mb-1">
      <div className="d-flex flex-column overflow-hidden">
        <span className="capitalize">{intl.formatMessage({ id: "app.contacts.importContact" })}</span>

        {notification.status ? (
          <span className="text-truncate text-success">
            <FontAwesomeIcon
              icon={["fas", "check"]}
              className="mr-2"
            />
            Se importaron los contactos correctamente
          </span>
        ) : (
          <span className="text-truncate text-danger">
            <FontAwesomeIcon
              icon={["fas", "check"]}
              className="mr-2"
            />
            Ocurrió un error importando los contactos
          </span>
        )}
      </div>
    </div>
  );
}
