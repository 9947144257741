import socialApiClient from "../../helpers/services/social-api-client";
import { Media } from "../../socialPost/interfaces/api/get-social-posts";
import { Mentions } from "../../socialPost/interfaces/Mention";
import { PostImage } from "../../socialPost/interfaces/postImage";
import { PostVideo } from "../../socialPost/interfaces/postVideo";
import { blobFromDataURI } from "../../utils";
import { GetTwitterBindResponse } from "../interface/api/get-twitter-bind";
import { GetTwitterLoginResponse } from "../interface/api/get-twitter-login";
import { GetTwitterProfilesResponse } from "../interface/api/get-twitter-profiles";
import {
  PostTwitterBindRequest,
  PostTwitterBindResponse,
} from "../interface/api/post-twitter-bind";
import { PostSocialPostResponse } from "../interface/api/post-twitter-socialPost";
import { PutSocialPostResponse } from "../interface/api/put-twitter-socialPost";
import { twitterSettingMapper } from "./mapper";

export const twitterApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`twitter/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostTwitterBindRequest) {
    return socialApiClient()
      .post<PostTwitterBindResponse>(`twitter/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        twitterSettingMapper.mapResponseToTwitterSetting(data)
      );
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetTwitterBindResponse>(`twitter`, {
        params: { companyId },
      })
      .then(({ data }) =>
        twitterSettingMapper.mapResponseToTwitterSetting(data)
      );
  },
  login(companyId: string) {
    return socialApiClient()
      .get<GetTwitterLoginResponse>(`twitter/login`, {
        params: { companyId },
      })
      .then(({ data }) =>
        twitterSettingMapper.mapResponseToTwitterLoginUrl(data)
      );
  },
  getProfiles(companyId: string, name: string) {
    return socialApiClient()
      .get<GetTwitterProfilesResponse>(`twitter/profiles/${name}`, {
        params: { companyId },
      })
      .then(({ data }) =>
        twitterSettingMapper.mapResponseToTwitterProfiles(data)
      );
  },
  socialPost(
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`twitter/socialpost`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  socialPostVideo(
    text: string,
    mentions: Mentions[],
    video: PostVideo,
    scheduleDate: string,
    repostId: string,
    companyId: string,
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`twitter/socialpost/video`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  putSocialPost(
    id: string,
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(`twitter/socialpost/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  putSocialPostVideo(
    id: string,
    text: string,
    mentions: Mentions[],
    video: PostVideo | null,
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    video && bodyFormData.append("video", blobFromDataURI(video.data))
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(`twitter/socialpost/video/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
};
