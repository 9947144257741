import React, { useEffect, useState } from "react";
import { VIDEO_UPLOAD_LOADING } from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SOCIAL_POST_TYPES } from "../../../../cores/socialPost/consts/socialPostType.constant";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";

interface Props {
  className?: string;
}

export const MediaPreview = ({ className = "" }: Props) => {
  const { images, video, selectedPost } = useSocialPostCore();
  const { isLoading } = useGlobalCore();
  const [imageSrc, setImageSrc] = useState("");
  const [videoType, setVideoType] = useState("");

  useEffect(() => {
    if (images.length > 0) {
      setImageSrc(images[0].data);
    } else if (selectedPost && selectedPost.media.length > 0) {
      setImageSrc(selectedPost.media[0].url);
    }
  }, [images, selectedPost]);

  useEffect(() => {
    if (video) {
      setVideoType(video.type);
    }
  }, [video]);

  return (
    <div>
      {(images.length > 0 || (selectedPost?.post.type === SOCIAL_POST_TYPES.IMAGE && selectedPost.media.length > 0)) && (
        <img className={className} src={imageSrc} alt="alt" />
      )}
      <LoadingSpinnerWembii active={isLoading(VIDEO_UPLOAD_LOADING)}>
        {(video || (selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO && selectedPost.media.length > 0)) && (
          <video className={className}>
            <source src={video ? video!.data : selectedPost?.media[0].url } type={videoType} />
            Your browser does not support HTML5 video.
          </video>
        )}
      </LoadingSpinnerWembii>
    </div>
  );
};
