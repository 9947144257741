import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  SMTP_GET_LOADING,
  SMTP_POST_LOADING,
  SMTP_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { smtpApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  getSmtpConnectionFailureAction,
  getSmtpConnectionInitAction,
  getSmtpConnectionSuccessAction,
  getSmtpFailureAction,
  getSmtpInitAction,
  getSmtpSuccessAction,
  postBindSmtpInitAction,
  postBindSmtpSuccessAction,
  postUnbindSmtpFailureAction,
  postUnbindSmtpInitAction,
  postUnbindSmtpSuccessAction,
} from "./store/api-actions";
import { errorCodeSelector, errorMessageSelector, settingSelector, userSettingSelector } from "./store/selector";
import { Email } from "./interface/api/post-smtp-email";
import { EmailFile } from "../email/interface/emailFile";
import { Errors } from "../../constants/errors";

export function useSmtpCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const userSettings = useSelector(userSettingSelector);
  const errorCode = useSelector(errorCodeSelector);
  const errorMessage = useSelector(errorMessageSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, asUser: boolean = false, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindSmtpInitAction());
        await smtpApiService.unbind(companyId, asUser);
        dispatch(postUnbindSmtpSuccessAction({ asUser }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindSmtpFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      host: string,
      port: string,
      email: string,
      password: string,
      secure: boolean,
      asUser: boolean = false,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindSmtpInitAction());
        turnOnLoading(SMTP_SYNC_LOADING);
        const response = await smtpApiService.bind(
          companyId,
          asUser,
          host,
          port,
          email,
          password,
          secure
        );
        dispatch(postBindSmtpSuccessAction({ settings: response, asUser }));
        turnOffLoading(SMTP_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess51" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(SMTP_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error67" }));
        console.error(error);
        dispatch(postUnbindSmtpFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string, asUser: boolean = false) => {
      try {
        dispatch(getSmtpInitAction());
        turnOnLoading(SMTP_GET_LOADING);
        const response = await smtpApiService.get(companyId, asUser);
        dispatch(getSmtpSuccessAction({ settings: response, asUser }));
        turnOffLoading(SMTP_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(SMTP_GET_LOADING);
        console.error(error);
        dispatch(getSmtpFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const verifyConnection = useCallback(
    async (
      companyId: string,
      host: string,
      port: string,
      email: string,
      password: string,
      secure: boolean,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getSmtpConnectionInitAction());
        turnOnLoading(SMTP_SYNC_LOADING);
        await smtpApiService.getConnection(companyId, host, port, email, password, secure);
        dispatch(getSmtpConnectionSuccessAction());
        turnOffLoading(SMTP_SYNC_LOADING, 1500);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);

        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message || "";
          switch (code) {
            case Errors.WrongSmtpHost:
              dispatch(
                getSmtpConnectionFailureAction({
                  errorCode: Errors.WrongSmtpHost,
                  errorMessage: message,
                })
              );
              break;
            case Errors.Timeout:
              dispatch(
                getSmtpConnectionFailureAction({
                  errorCode: Errors.Timeout,
                  errorMessage: message,
                })
              );
              break;
            case Errors.WrongAuth:
              dispatch(
                getSmtpConnectionFailureAction({
                  errorCode: Errors.WrongAuth,
                  errorMessage: message,
                })
              );
              break;
            default:
              console.error(error, code);
          }
        } else {
          console.error(error);
        }
        turnOffLoading(SMTP_SYNC_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const postEmail = useCallback(
    async (
      companyId: string,
      email: Email,
      files: EmailFile[],
      asUser: boolean = false,
      cardId: string | null,
      onSuccess?: () => void
    ) => {
      try {
        turnOnLoading(SMTP_POST_LOADING);
        const id = await smtpApiService.postEmail(companyId, email, files, asUser, cardId);
        onSuccess?.();
        toast.success(intl.formatMessage({ id: "app.toast.sucess47" }));
        turnOffLoading(SMTP_POST_LOADING, 500);
        return id;
      } catch (error: any) {
        turnOffLoading(SMTP_POST_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error69" }));
      }
    },
    [turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    userSettings,
    errorCode,
    errorMessage,
    unbind,
    verifyConnection,
    bind,
    get,
    postEmail,
  };
}
