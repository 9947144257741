import { GetWooCommerceOrders } from "../interface/api/get-woocommerce-orders";
import { PutWooCommerceOrderResponse } from "../interface/api/put-woocommerce-order";
import { Order } from "../interface/order";

export const marketMapper = {
  mapResponseToOrders(response: GetWooCommerceOrders): Order[] {
    return response.payload.orders;
  },
  mapResponseToOrder(response: PutWooCommerceOrderResponse): Order {
    return response.payload.order;
  },
};
