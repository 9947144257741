import cn from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { EDIT_ME_AVATAR_USER_LOADING } from "../../../constants/loading.constant";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { ME_CONFIG_RULES } from "../../../cores/me/config";
import { beforeUploadFile, UploadFileType } from "../../../cores/utils";
import Avatar from "../Avatar";
import { useIntl } from "react-intl";
import { UpdateLoadingCard } from "../UpdateLoadingCard";
import { PersonalInformationForm } from "./PersonalInformationForm";
import { SecurityForm } from "./SecurityForm";
import { IntegrationForm } from "./IntegrationForm";
import { getFirstLetters } from "../../utils/stringUtils";

interface Props {
  tab?: string;
}

export const EditMeUserForm = ({ tab = "1" }: Props) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(tab);
  const { user, company, postMeAvatar, getMeUserSettings } = useMeCore();
  const { isLoading } = useGlobalCore();

  useEffect(() => {
    getMeUserSettings();
  }, [getMeUserSettings]);

  const ref = useRef<any>(null);

  const filesAllowed = useMemo(
    () => [UploadFileType.JPEG, UploadFileType.JPG, UploadFileType.PNG],
    []
  );

  const handleSelectPhoto = (files: FileList | null) => {
    if (files) {
      const error = !beforeUploadFile(files[0], ME_CONFIG_RULES.maxImageSize, filesAllowed)

      if (!error) {
        postMeAvatar(files[0]);
      }
    }
  };

  return (
    <Container className="h-100 p-4" fluid>
      <Row className="h-100">
        <Col xs="12">
          <h2 className="h2 text-center mb-4">  
            {intl.formatMessage({ id: "app.navbar.userConfig" })}
          </h2>
          <div className="d-flex flex-column justify-content-center align-items-center position-relative">
            <Label className="pointer m-2" for="imageUploader">
              {!isLoading(EDIT_ME_AVATAR_USER_LOADING)  && (
                <Avatar
                  className="text-dark-50 pointer mx-2"
                  label={getFirstLetters(`${user?.lastName} ${user?.firstName}`)}
                  image={user?.avatarUrl}
                  width={"150px"}
                  height={"150px"}
                />
              )}
              <UpdateLoadingCard
                loadingId={EDIT_ME_AVATAR_USER_LOADING}
                text={intl.formatMessage({ id: "app.navbar.updatingAvatar" })}
              />
            </Label>
            <Input
              ref={ref}
              type="file"
              id="imageUploader"
              className="d-none"
              accept={filesAllowed.join(',')}
              onChange={(e) => handleSelectPhoto(e.target.files)}
            />
          </div>

          <Nav tabs className="justify-content-center mt-3">
            <NavItem>
              <NavLink
                className={cn("pointer", { active: activeTab === "1" })}
                onClick={() => { setActiveTab("1") }}
              >
                {intl.formatMessage({ id: "app.navbar.personalInformation" })}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={cn("pointer", { active: activeTab === "2" })}
                onClick={() => { setActiveTab("2") }}
              >
                {intl.formatMessage({ id: "app.navbar.security" })}
              </NavLink>
            </NavItem>
            {company && (
              <NavItem>
                <NavLink
                  className={cn("pointer", { active: activeTab === "3" })}
                  onClick={() => { setActiveTab("3") }}
                >
                  {intl.formatMessage({ id: "app.navbar.integrations" })}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <PersonalInformationForm />
            </TabPane>
            <TabPane tabId="2">
              <SecurityForm />
            </TabPane>
            {company && (
              <TabPane tabId="3">
                <IntegrationForm />
              </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
};
