import mainApiClient from "../../helpers/services/main-api-client";
import { GetAutomationResponse } from "../interfaces/api/get-automation";
import { GetAutomationsResponse } from "../interfaces/api/get-automations";
import {
  PostActionRequest,
  PostActionResponse,
} from "../interfaces/api/post-action";
import {
  PostAutomationRequest,
  PostAutomationResponse,
} from "../interfaces/api/post-automation";
import {
  PostConditionRequest,
  PostConditionResponse,
} from "../interfaces/api/post-condition";
import {
  PostContactRequest,
  PostContactResponse,
} from "../interfaces/api/post-contact";
import {
  PostAutomationUserRequest,
  PostAutomationUserResponse,
} from "../interfaces/api/post-user";
import {
  PutActionRequest,
  PutActionResponse,
} from "../interfaces/api/put-action";
import { PutAutomationRequest } from "../interfaces/api/put-automation";
import {
  PutConditionRequest,
  PutConditionResponse,
} from "../interfaces/api/put-condition";
import { automationsMapper } from "./mapper";

export const automationApiService = {
  postAutomation(companyId: string, body: PostAutomationRequest) {
    return mainApiClient()
      .post<PostAutomationResponse>(`automations`, body, {
        params: { companyId },
      })
      .then(({ data }) => automationsMapper.mapResponseToAutomation(data));
  },
  putAutomation(companyId: string, automationId: string, active: boolean) {
    const body: PutAutomationRequest = {
      active,
    };
    return mainApiClient()
      .put(`automations/${automationId}`, body, { params: { companyId } })
      .then(({ data }) => automationsMapper.mapResponseToAutomation(data));
  },
  getAutomations(companyId: string) {
    return mainApiClient()
      .get<GetAutomationsResponse>(`automations`, {
        params: { companyId },
      })
      .then(({ data }) => automationsMapper.mapResponseToAutomations(data));
  },
  getAutomation(companyId: string, automationId: string) {
    return mainApiClient()
      .get<GetAutomationResponse>(`automations/${automationId}`, {
        params: { companyId },
      })
      .then(({ data }) => automationsMapper.mapResponseToAutomation(data));
  },
  deleteAutomation(automationId: string, companyId: string) {
    return mainApiClient()
      .delete(`automations/${automationId}`, { params: { companyId } })
      .then(({ data }) => automationsMapper.mapResponseToId(data));
  },
  postCondition(
    companyId: string,
    automationId: string,
    body: PostConditionRequest
  ) {
    return mainApiClient()
      .post<PostConditionResponse>(
        `automations/${automationId}/condition`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => automationsMapper.mapResponseToCondition(data));
  },
  putCondition(
    companyId: string,
    automationId: string,
    conditionId: string,
    body: PutConditionRequest
  ) {
    return mainApiClient()
      .put<PutConditionResponse>(
        `automations/${automationId}/condition/${conditionId}`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => automationsMapper.mapResponseToCondition(data));
  },
  deleteCondition(
    conditionId: string,
    automationId: string,
    companyId: string
  ) {
    return mainApiClient()
      .delete(`automations/${automationId}/condition/${conditionId}`, {
        params: { companyId },
      })
      .then(({ data }) => automationsMapper.mapResponseToId(data));
  },
  postAction(companyId: string, automationId: string, body: PostActionRequest) {
    return mainApiClient()
      .post<PostActionResponse>(`automations/${automationId}/action`, body, {
        params: { companyId },
      })
      .then(({ data }) => automationsMapper.mapResponseToAction(data));
  },
  putAction(
    companyId: string,
    automationId: string,
    actionId: string,
    body: PutActionRequest
  ) {
    return mainApiClient()
      .put<PutActionResponse>(
        `automations/${automationId}/action/${actionId}`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => automationsMapper.mapResponseToAction(data));
  },
  deleteAction(actionId: string, automationId: string, companyId: string) {
    return mainApiClient()
      .delete(`automations/${automationId}/action/${actionId}`, {
        params: { companyId },
      })
      .then(({ data }) => automationsMapper.mapResponseToId(data));
  },
  postContact(
    companyId: string,
    automationId: string,
    body: PostContactRequest
  ) {
    return mainApiClient()
      .post<PostContactResponse>(`automations/${automationId}/assign`, body, {
        params: { companyId },
      })
      .then(({ data }) => automationsMapper.mapResponseToContact(data));
  },
  deleteContact(contactId: string, automationId: string, companyId: string) {
    return mainApiClient()
      .delete(`automations/${automationId}/assign/${contactId}`, {
        params: { companyId },
      })
      .then(({ data }) => automationsMapper.mapResponseToId(data));
  },
  postUser(
    companyId: string,
    automationId: string,
    body: PostAutomationUserRequest
  ) {
    return mainApiClient()
      .post<PostAutomationUserResponse>(
        `automations/${automationId}/owners/assign`,
        body,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => automationsMapper.mapResponseToUser(data));
  },
  deleteUser(companyId: string, userId: string, automationId: string) {
    return mainApiClient()
      .delete(`automations/${automationId}/owners/unassign`, {
        params: { userId, companyId },
      })
      .then(({ data }) => automationsMapper.mapResponseToId(data));
  },
};
