import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useMeCore } from "../../../../cores/me";
import { useSocialPostCore } from "../../../../cores/socialPost";
import {
  SocialProviderId,
  SocialProviderNameMapper,
  SocialProviderRules,
} from "../../../../cores/socialPost/config";
import { SOCIAL_POST_TYPES } from "../../../../cores/socialPost/consts/socialPostType.constant";
import { PostErrorType, SocialType } from "../../../../cores/socialPost/interfaces/postError";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { PanelWembii } from "../../PanelWembii";
import { useIntl } from "react-intl";
import { SocialProviderIcon } from "./SocialProviderIcon";
import { IconMapper } from "./SocialPostSelector";

export const ableToStory = [
  SocialProviderId.Facebook,
  SocialProviderId.Instagram,
];

export const SocialStorySelector = () => {
  const intl = useIntl();
  const { socialSettings } = useMeCore();
  const {
    socialProviders,
    toggleSocialProvider,
    clearSocialProviders,
    selectSocialProvider,
    storyErrors,
    images,
    video,
    selectedPost,
    addStoryError,
    removeStoryError,
  } = useSocialPostCore();

  const hasMaxImageError: (id: string) => boolean = useCallback(
    (id: string) =>
      storyErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.MaxImagesPerPost
      ).length > 0,
    [storyErrors]
  );

  const hasMediaRequireError: (id: string) => boolean = useCallback(
    (id: string) =>
      storyErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.MediaRequired
      ).length > 0,
    [storyErrors]
  );

  const hasMaxImageSizeError: (id: string) => boolean = useCallback(
    (id: string) =>
      storyErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.MaxImageSize
      ).length > 0,
    [storyErrors]
  );

  const hasMaxVideoSizeError: (id: string) => boolean = useCallback(
    (id: string) =>
      storyErrors.filter(
        ({ type, socialProviderId }) =>
          socialProviderId === id && type === PostErrorType.MaxVideoSize
      ).length > 0,
    [storyErrors]
  );

  const maxImagesProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].maxStoryPhotoAllowed
      ),
    [socialProviders]
  );

  const mediaRequiredProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].storyMediaRequired === true
      ),
    [socialProviders]
  );

  const maxVideoSizeProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].maxVideoSize !== -1
      ),
    [socialProviders]
  );

  const maxImageSizeProviders: SocialProvider[] = useMemo(
    () =>
      socialProviders.filter(
        ({ id }) => SocialProviderRules[id].maxImageSize !== -1
      ),
    [socialProviders]
  );

  useEffect(() => {
    return () => {
      clearSocialProviders();
    };
  }, [clearSocialProviders]);

  useEffect(() => {
    maxImagesProviders.forEach(({ id }) => {
      let cont = images.length;
      if (selectedPost && selectedPost.post.type === SOCIAL_POST_TYPES.IMAGE) {
        cont += selectedPost?.media?.length;
      }

      SocialProviderRules[id].maxStoryPhotoAllowed! < cont
        ? !hasMaxImageError(id) &&
          addStoryError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.MaxImagesPerPost,
            message: `Máximo de imágenes por publicación alcanzado. ${_.capitalize(
              id
            )} permite ${SocialProviderRules[id].maxStoryPhotoAllowed} imágenes `,
          })
        : hasMaxImageError(id) &&
          removeStoryError(
            id as SocialProviderId,
            PostErrorType.MaxImagesPerPost
          );
    });
  }, [
    addStoryError,
    hasMaxImageError,
    images.length,
    removeStoryError,
    socialProviders,
    selectedPost,
    maxImagesProviders,
  ]);

  useEffect(() => {
    mediaRequiredProviders.forEach(({ id }) =>
      images.length === 0 &&
      !video &&
      (!selectedPost || selectedPost.media.length === 0)
        ? !hasMediaRequireError(id) &&
          addStoryError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.MediaRequired,
            message: intl.formatMessage({ id: "app.socialMediaPost.errorMsg12" })
          })
        : hasMediaRequireError(id) &&
          removeStoryError(id as SocialProviderId, PostErrorType.MediaRequired)
    );
  }, [
    addStoryError,
    images,
    removeStoryError,
    video,
    selectedPost,
    mediaRequiredProviders,
    hasMediaRequireError,
    intl
  ]);

  useEffect(() => {
    maxVideoSizeProviders.forEach(({ id }) =>
      video && video.size > SocialProviderRules[id].maxVideoSize * 1024 * 1024
        ? !hasMaxVideoSizeError(id) &&
          addStoryError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.MaxVideoSize,
            message:`${intl.formatMessage({ id: "app.socialMediaPost.errorMsg2" })} ${_.capitalize(
              id
            )} ${intl.formatMessage({ id: "app.socialMediaPost.errorMsg3" })}  
            ${SocialProviderRules[id].maxVideoSize}
            ${intl.formatMessage({ id: "app.socialMediaPost.errorMsg4" })}`,
          })
        : hasMaxVideoSizeError(id) &&
          removeStoryError(id as SocialProviderId, PostErrorType.MaxVideoSize)
    );
  }, [
    addStoryError,
    hasMaxVideoSizeError,
    removeStoryError,
    video,
    maxVideoSizeProviders,
    intl
  ]);

  useEffect(() => {
    maxImageSizeProviders.forEach(({ id }) =>
      images.filter(image => 
        image.size > SocialProviderRules[id].maxImageSize * 1024 * 1024).length > 0
        ? !hasMaxImageSizeError(id) &&
          addStoryError({
            socialProviderId: id as SocialProviderId,
            type: PostErrorType.MaxImageSize,
            message: `${intl.formatMessage({ id: "app.socialMediaPost.errorMsg5" })} ${_.capitalize(
              id
            )} ${intl.formatMessage({ id: "app.socialMediaPost.errorMsg6" })}
            ${SocialProviderRules[id].maxImageSize} 
            ${intl.formatMessage({ id: "app.socialMediaPost.errorMsg7" })}`,
          })
        : hasMaxImageSizeError(id) &&
          removeStoryError(id as SocialProviderId, PostErrorType.MaxImageSize)
    );
  }, [
    addStoryError,
    hasMaxImageSizeError,
    removeStoryError,
    images,
    selectedPost,
    maxImageSizeProviders,
    intl
  ]);

  const isSelected = useCallback(
    (id: string): boolean =>
      Boolean(socialProviders.filter((sp) => sp.id === id).length),
    [socialProviders]
  );

  const socialProvidersAbleToPost: SocialProvider[] = useMemo(() => {
    let providers = ableToStory
      .filter((social) => socialSettings![social])
      .map(
        (social): SocialProvider => ({
          id: social,
          name: SocialProviderNameMapper[social],
          displayName: socialSettings![social]?.name,
          avatar: socialSettings![social]?.avatarUrl,
          icon: IconMapper[social],
        })
      );

    if (selectedPost) {
      providers = providers.filter(
        (social) => social.id === selectedPost.post.socialProvider
      );
      selectSocialProvider(providers[0]);
    }

    return providers;
  }, [socialSettings, selectedPost, selectSocialProvider]);

  return (
    <div className="d-flex justify-content-around flex-wrap">
      {socialProvidersAbleToPost.map((sp) => (
        <PanelWembii
          shadow
          className="px-md-5 px-xs-2 py-4 my-1 pointer"
          key={sp.id}
          style={{ maxWidth: 150, minWidth: 124 }}
          onClick={() => (!selectedPost ? toggleSocialProvider(sp) : null)}
        >
          <SocialProviderIcon
            socialProvider={sp}
            isSelected={isSelected(sp.id)}
            socialType={SocialType.Story}
          />
        </PanelWembii>
      ))}
    </div>
  );
};
