import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { InputWembii } from "../../InputWembii";
import { useCompanyCore } from "../../../../cores/company";
import { useMeCore } from "../../../../cores/me";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "../../Avatar";
import { LabelWembii } from "../../LabelWembii";
import { useTaskCore } from "../../../../cores/tasks";
import { useGlobalCore } from "../../../../cores/globals";
import {
  CONTACT_EXPAND_EDIT_FORM,
  DELETE_TASK_MODAL,
  TASK_CARD_MODAL,
  TASK_CONTACTS_MODAL,
} from "../../../../constants/modal.constant";
import moment from "moment";
import {
  PriorityOptions,
  ReminderOptions,
  TaskReminder,
  TaskStatus,
  TaskStatusOptions,
  TypeOptions,
} from "../../../../cores/tasks/config";
import AreYouSure from "../../AreYouSure";
import ContactList from "../../boards/modal/ContactList";
import {
  AssignmentTask,
  ContactTask,
} from "../../../../cores/tasks/interfaces/api/get-task";
import { TooltipWembii } from "../../TooltipWembii";
import { useIntl } from "react-intl";
import { getFirstLetters, isStrNotNull } from "../../../utils/stringUtils";
import EditContactForm from "../../contacts/modal/EditContactForm";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import DatePicker from "react-date-picker";
import GmailButton from "../../gmail/GmailButton";
import { useContactCore } from "../../../../cores/contacts";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useConfigCore } from "../../../../cores/config";
import { Contact } from "../../../../cores/contacts/interfaces/api/get-contact";
import { Task } from "../../../../cores/tasks/interfaces/api/get-task";

interface Props {
  task?: string;
  contacts?: ContactTask[];
  assignTo?: AssignmentTask[];
  cardId?: string;
  onSuccess?: (task?: Task) => void;
  contactTask?: boolean;
}

export default function TaskDetails(props: Props) {
  const intl = useIntl();
  const {
    task: taskId,
    contacts: cardContacts,
    assignTo: cardAssignents,
    cardId,
    onSuccess,
    contactTask,
  } = props;
  const {
    user: meUser,
    company,
    socialSettings,
    userSettings,
    isReseller,
    isAdmin,
    companyRole,
  } = useMeCore();
  const { getContactTags, getContact } = useContactCore();
  const { users } = useCompanyCore();
  const { getCommentTypes } = useConfigCore();
  const { turnOffModal, turnOnModal } = useGlobalCore();
  const {
    task,
    taskFilters,
    getTask,
    getTasks,
    deleteContactTask,
    postTask,
    putTask,
    deleteTask,
  } = useTaskCore();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState(1);
  const [type, setType] = useState(1);
  const [user, setUser] = useState("");
  const [endDate, setEndDate] = useState<Date>();
  const [endTime, setEndTime] = useState("");
  const [reminder, setReminder] = useState<number>(1);
  const [reminderDate, setReminderDate] = useState<Date>();
  const [reminderTime, setReminderTime] = useState("");
  const [status, setStatus] = useState(TaskStatus.Incompleted);
  const [contacts, setContacts] = useState<ContactTask[]>([]);

  useEffect(() => {
    if (cardContacts) {
      setContacts(cardContacts);
    }

    if (cardAssignents && cardAssignents.length > 0) {
      setUser(cardAssignents[0].id);
    } else {
      setUser(meUser?.id!);
    }
    if (taskId) {
      getTask(company?.id!, taskId);
    }
  }, [taskId, cardContacts, cardAssignents, company, meUser, getTask]);

  useEffect(() => {
    if (task) {
      setTitle(task.title);
      setDescription(task.description);
      setPriority(task.priority);
      setType(task.type);
      setEndDate(moment(task.endDate).toDate());
      setEndTime(moment(task.endDate).format("HH:mm"));
      setReminder(task.reminder);
      setStatus(task.status);
      setUser(task.user.id);
      setContacts(task.contacts);
    } else {
      setEndDate(moment().add(1, "day").toDate());
      setEndTime(moment().format("HH:mm"));
    }

    if (task?.reminderDate) {
      setReminderDate(moment(task.reminderDate).toDate());
      setReminderTime(moment(task.reminderDate).format("HH:mm"));
    } else {
      setReminderDate(moment().add(1, "day").toDate());
      setReminderTime(moment().format("HH:mm"));
    }
  }, [task]);

  const canModify = useMemo(
    () =>
      isAdmin ||
      isReseller ||
      companyRole?.owner ||
      companyRole?.admin ||
      companyRole?.agent ||
      task?.user.id === meUser?.id,
    [isAdmin, isReseller, meUser, task, companyRole]
  );

  const handleDelete = useCallback(() => {
    turnOnModal(
      DELETE_TASK_MODAL,
      <AreYouSure
        message={`¿${intl.formatMessage({ id: "app.tasks.deleteMesagge" })} ${
          task?.title
        }?`}
        onSubmit={() => {
          if (contactTask) {
            deleteContactTask(company?.id!, task?.id!, () => {
              getTasks(company?.id!, taskFilters);
              turnOffModal(TASK_CARD_MODAL);
              onSuccess?.();
            });
          } else {
            deleteTask(company?.id!, task?.id!, () => {
              getTasks(company?.id!, taskFilters);
              turnOffModal(TASK_CARD_MODAL);
              onSuccess?.();
            });
          }

          turnOffModal(DELETE_TASK_MODAL);
        }}
        onClose={() => turnOffModal(DELETE_TASK_MODAL)}
      />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    onSuccess,
    deleteTask,
    getTasks,
    taskFilters,
    company,
    task,
    intl,
    contactTask,
    deleteContactTask,
  ]);

  const getContactNameOrEmailOrPhone = (contact: ContactTask) => {
    let text = contact.name || contact.email || contact.phoneNumber;
    if (contact.status !== UserStatus.ACTIVE.id) {
      text = `${text} ${intl.formatMessage({ id: "app.crmBusiness.active?" })}`;
    }

    return text;
  };

  const isFieldToLong = (field: string | undefined, length: number) =>
    (field ? field.length : 0) > length;

  const isDescriptionInvalid = useMemo(
    () => isFieldToLong(description, 500),
    [description]
  );

  const calculateReminderDate = useCallback(() => {
    let date = moment(endDate).format("yyyy-MM-DD");
    switch (+reminder) {
      case TaskReminder.DueTime:
        return moment(`${date} ${endTime}`).toString();
      case TaskReminder.OneHour:
        return moment(`${date} ${endTime}`).subtract(1, "h").toString();
      case TaskReminder.OneDay:
        return moment(`${date} ${endTime}`).subtract(1, "d").toString();
      case TaskReminder.OneWeek:
        return moment(`${date} ${endTime}`).subtract(1, "w").toString();
      case TaskReminder.Custom:
        date = moment(reminderDate).format("yyyy-MM-DD");
        return moment(`${date} ${reminderTime}`).toString();
      default:
        return null;
    }
  }, [reminder, endDate, endTime, reminderDate, reminderTime]);

  const handleSave = useCallback(async () => {
    const date = moment(endDate).format("yyyy-MM-DD");
    const endTask = moment(`${date} ${endTime}`).toString();
    const reminderTask = calculateReminderDate();
    const contactsTask = contacts.map((contact) => contact.id);

    if (task) {
      putTask(
        company?.id!,
        task.id,
        {
          title,
          description,
          priority,
          type,
          userId: user,
          endDate: endTask,
          reminder,
          reminderDate: reminderTask,
          contacts: contactsTask,
          status,
        },
        (task) => {
          turnOffModal(TASK_CARD_MODAL);
          onSuccess?.(task);
        }
      );
    } else {
      await postTask(
        company?.id!,
        {
          title,
          description,
          priority,
          type,
          userId: user,
          endDate: endTask,
          reminder,
          reminderDate: reminderTask,
          contacts: contactsTask,
          cardId,
        },
        contactsTask.length ? "contactTask" : "task",
        () => {
          turnOffModal(TASK_CARD_MODAL);
          onSuccess?.();
        }
      );
    }

    getTasks(company?.id!, taskFilters);
  }, [
    task,
    company,
    title,
    description,
    priority,
    type,
    user,
    endDate,
    contacts,
    cardId,
    endTime,
    reminder,
    status,
    turnOffModal,
    postTask,
    putTask,
    onSuccess,
    calculateReminderDate,
    getTasks,
    taskFilters,
  ]);

  const openAssignmentContacts = useCallback(() => {
    turnOnModal(
      TASK_CONTACTS_MODAL,
      <ContactList
        assignments={contacts}
        onAssign={(contact) => {
          setContacts([
            ...contacts,
            { ...contact, status: UserStatus.ACTIVE.id },
          ]);
          turnOffModal(TASK_CONTACTS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, contacts]);

  const onShowAssignment = useCallback(
    (contact) => {
      getContactTags(company?.id!);
      getCommentTypes(company?.id!);
      getContact(company?.id!, contact.id);
      turnOnModal(
        CONTACT_EXPAND_EDIT_FORM,
        <EditContactForm
          isUnassignedButton={true}
          onDelete={() => {
            turnOnModal(
              DELETE_TASK_MODAL,
              <AreYouSure
                message={`Está seguro de querer desasignar el contacto ${contact.name}`}
                onSubmit={() => {
                  setContacts(contacts.filter((ctt) => ctt.id !== contact.id));
                  turnOffModal(DELETE_TASK_MODAL);
                  turnOffModal(CONTACT_EXPAND_EDIT_FORM);
                }}
                onClose={() => turnOffModal(DELETE_TASK_MODAL)}
              />,
              Position.LEFT
            );
          }}
        />,
        Position.LEFT
      );
    },
    [
      turnOnModal,
      turnOffModal,
      contacts,
      company,
      getContactTags,
      getCommentTypes,
      getContact,
    ]
  );

  const typeStatus = useMemo(
    () => [
      TaskStatusOptions[TaskStatus.Completed],
      TaskStatusOptions[TaskStatus.Incompleted],
    ],
    []
  );

  const disabled = useCallback(
    () =>
      !title ||
      !priority ||
      !type ||
      !endDate ||
      !endTime ||
      !user ||
      isDescriptionInvalid,
    [title, priority, type, endDate, endTime, user, isDescriptionInvalid]
  );

  const showGmailButton = useMemo(
    () =>
      (socialSettings?.gmail ||
        userSettings?.gmail ||
        socialSettings?.smtp ||
        userSettings?.smtp) &&
      contacts.length > 0,
    [userSettings, socialSettings, contacts.length]
  );

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <h2 className="h2 text-center">
            {intl.formatMessage({ id: "app.crmTasksModal.Task" })}
          </h2>
          {task && (
            <div className="d-flex justify-content-center">
              <InputWembii
                type="select"
                onChange={(v) => setStatus(v)}
                value={status}
              >
                {typeStatus.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </InputWembii>
            </div>
          )}
          <div className="d-flex flex-column py-4">
            <InputWembii
              type="text"
              name="title"
              id="title"
              label={intl.formatMessage({ id: "app.crmTasksModal.title" })}
              required
              value={title}
              onChange={(v) => setTitle(v)}
            />
            <div className="d-flex w-100">
              <div className="w-50 mr-1">
                <InputWembii
                  type="select"
                  label={intl.formatMessage({ id: "app.crmTasksModal.type" })}
                  required
                  onChange={(v) => setType(v)}
                  value={type}
                >
                  {Object.values(TypeOptions).map(({ label, value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </InputWembii>
              </div>
              <div className="w-50 ml-1">
                <InputWembii
                  type="select"
                  label={intl.formatMessage({
                    id: "app.crmTasksModal.prority",
                  })}
                  required
                  onChange={(v) => setPriority(v)}
                  value={priority}
                >
                  {Object.values(PriorityOptions).map(({ label, value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </InputWembii>
              </div>
            </div>
            <div className="d-flex w-100">
              <div className="w-50 mr-1">
                <LabelWembii>
                  {intl.formatMessage({ id: "app.crmTasksModal.contact" })}
                </LabelWembii>
                <div className="d-flex flex-wrap">
                  {contacts.map((contact) => (
                    <div key={contact.id}>
                      <Avatar
                        id={`contact-modal-${contact.id}`}
                        active={contact.status === UserStatus.ACTIVE.id}
                        className="text-dark-50 font-weight-bold pointer m-1"
                        label={`${getFirstLetters(contact.name)}`}
                        icon={
                          <FontAwesomeIcon
                            icon={["fas", "user"]}
                            size="1x"
                            className="pointer mr-1"
                          />
                        }
                        onClick={() => onShowAssignment(contact)}
                      />
                      {isStrNotNull(getContactNameOrEmailOrPhone(contact)) && (
                        <TooltipWembii
                          id={`contact-modal-${contact.id}`}
                          text={`${getContactNameOrEmailOrPhone(contact)}`}
                        />
                      )}
                    </div>
                  ))}
                  <Avatar
                    className="text-dark-50 pointer m-1"
                    onClick={openAssignmentContacts}
                    icon={
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        size="1x"
                        color={COLOR_PRIMARY}
                      />
                    }
                  />
                </div>
              </div>
              <div className="w-50 ml-1 d-flex justify-content-center align-items-center">
                {showGmailButton && (
                  <GmailButton
                    contacts={contacts as Contact[]}
                    cardId={task?.cardId ? task?.cardId! : null}
                  />
                )}
              </div>
            </div>

            <div className="mt-4">
              <InputWembii
                type="select"
                label={intl.formatMessage({ id: "app.crmTasksModal.assign" })}
                required
                onChange={(v) => setUser(v)}
                value={user}
              >
                {users.map(({ id, lastName, firstName }) => (
                  <option key={id} value={id}>
                    {firstName} {lastName}
                  </option>
                ))}
              </InputWembii>
            </div>

            <div className="d-flex w-100">
              <div className="d-flex flex-column w-75">
                <LabelWembii required>
                  {intl.formatMessage({ id: "app.crmTasksModal.date" })}
                </LabelWembii>
                <DatePicker
                  className="w-100"
                  format="dd/MM/y"
                  value={endDate}
                  clearIcon={null}
                  onChange={setEndDate}
                />
              </div>
              <div className="w-25 ml-2">
                <InputWembii
                  type="time"
                  name="endDate"
                  id="endDate"
                  required
                  label={intl.formatMessage({ id: "app.crmTasksModal.date1" })}
                  value={endTime}
                  onChange={(v) => setEndTime(v)}
                />
              </div>
            </div>
            <InputWembii
              type="select"
              label={intl.formatMessage({ id: "app.crmTasksModal.reminder" })}
              onChange={(v) => setReminder(v)}
              value={reminder}
            >
              {Object.values(ReminderOptions).map(({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </InputWembii>
            {+reminder === +TaskReminder.Custom && (
              <div className="d-flex w-100">
                <div className="d-flex flex-column w-75">
                  <LabelWembii required>
                    {intl.formatMessage({ id: "app.crmTasksModal.reminder" })}
                  </LabelWembii>
                  <DatePicker
                    className="w-100"
                    format="dd/MM/y"
                    value={reminderDate}
                    clearIcon={null}
                    onChange={setReminderDate}
                  />
                </div>
                <div className="w-25 ml-2">
                  <InputWembii
                    type="time"
                    name="endDate"
                    id="endDate"
                    label={intl.formatMessage({
                      id: "app.crmTasksModal.date1",
                    })}
                    value={reminderTime}
                    onChange={(v) => setReminderTime(v)}
                  />
                </div>
              </div>
            )}

            <InputWembii
              type="textarea"
              name="description"
              id="description"
              label={intl.formatMessage({ id: "app.crmTasksModal.notes" })}
              style={{ minHeight: 150 }}
              mb={false}
              value={description}
              onChange={(v) => setDescription(v)}
              invalid={isDescriptionInvalid}
            />
            {isDescriptionInvalid && (
              <div className="text-danger">
                {intl.formatMessage(
                  { id: "app.adminCompanies.error1" },
                  { length: 500 }
                )}
              </div>
            )}

            {(!taskId || canModify) && (
              <div className="d-flex justify-content-center">
                {task && (
                  <Button className="m-1" color="danger" onClick={handleDelete}>
                    {intl.formatMessage({ id: "app.crmTasksModal.delete" })}
                  </Button>
                )}
                <Button
                  className="m-1"
                  color="primary"
                  disabled={disabled()}
                  onClick={handleSave}
                >
                  {intl.formatMessage({ id: "app.crmTasksModal.save" })}
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
