import React, { useState } from "react";
import cn from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { useSocialChatCore } from "../../../../cores/socialChat";
import {
  WHATSAPP_COLOR,
  FACEBOOK_COLOR,
  INSTAGRAM_COLOR,
} from "../../../../constants/socialColors.constant";
import { Conversations } from "../../../../cores/socialChat/interfaces/conversations-group.interface";
import { ContactStateDropdownOptions } from "../SelectContactState/SelectContactState";
import { FilterOption } from "../filterOption/FilterOption";
import { LineDropdown } from "../LineDropdown/LineDropdown";
import { PeriodDropdownOptions } from "../PeriodDropdownOptions/PeriodDropwdownOptions";
import { useMeCore } from "../../../../cores/me";
import { useFilterDate } from "../../../../hooks/useFilterDate/useFilterDate.hook";
import moment from "moment";

const ChatBarFilter: React.FC<{ conversations: Conversations }> = ({
  conversations,
}) => {
  const { socialSettings } = useMeCore();
  const configuredSocials = {
    whatsapp: socialSettings?.whatsapp ? true : false,
    instagram: socialSettings?.instagramMensseger ? true : false,
    facebook: socialSettings?.facebook ? true : false,
  };
  const {
    conversationStatus,
    selectedSocial,
    setSelectedSocial,
    setConversationStatus,
    dateFilter,
  } = useSocialChatCore();
  const intl = useIntl();
  const [openContactsStateDropdown, setOpenContactsStateDropdown] =
    useState(false);
  const [openPeriod, setOpenPeriod] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>(
    intl.formatMessage({ id: "app.chat.filterLabel" })
  );
  const { filterDateConversation } = useFilterDate(dateFilter);

  const getLabel = () => {
    const formattedDates = {
      to: moment(dateFilter.to).format("DD MMM YYYY"),
      from: moment(dateFilter.from).format("DD MMM YYYY"),
    };
    if (dateFilter.from && !dateFilter.to)
      return `Desde ${formattedDates.from}`;
    if (dateFilter.to && !dateFilter.from) return `Hasta ${formattedDates.to}`;
    if (dateFilter.from && dateFilter.to)
      return `Desde ${formattedDates.from} - Hasta ${formattedDates.to}`;
    if (!dateFilter.from && !dateFilter.to) return "Fecha";
  };
  const getSocialCounters = () => {
    const { all, facebook, instagram, web, whatsapp } = conversations;

    return {
      instagram:
        instagram.active.filter(filterDateConversation).length +
        instagram.archived.filter(filterDateConversation).length +
        instagram.inTrash.filter(filterDateConversation).length,

      facebook:
        facebook.active.filter(filterDateConversation).length +
        facebook.archived.filter(filterDateConversation).length +
        facebook.inTrash.filter(filterDateConversation).length,
      whatsapp:
        whatsapp.active.filter(filterDateConversation).length +
        whatsapp.archived.filter(filterDateConversation).length +
        whatsapp.inTrash.filter(filterDateConversation).length,
      web:
        web.active.filter(filterDateConversation).length +
        web.archived.filter(filterDateConversation).length +
        web.inTrash.filter(filterDateConversation).length,
      all:
        all.active.filter(filterDateConversation).length +
        all.archived.filter(filterDateConversation).length +
        all.inTrash.filter(filterDateConversation).length,
    };
  };
  const getStatusesCounter = () => {
    return {
      active: conversations[selectedSocial].active.filter(
        filterDateConversation
      ).length,
      archived: conversations[selectedSocial].archived.filter(
        filterDateConversation
      ).length,
      inTrash: conversations[selectedSocial].inTrash.filter(
        filterDateConversation
      ).length,
    };
  };
  return (
    <div className="container-fluid p-2  my-auto mx-auto">
      <div className="row py-auto bg-light my-auto mx-auto">
        <div className="col mx-auto border-right my-auto mx-auto  ">
          <div className="d-flex justify-content-center">
            <div className="row  my-auto ">
              <div className="d-flex my-auto">
                <FilterOption
                  label={`(${getStatusesCounter().active})`}
                  selected={conversationStatus === "active"}
                  onClick={() => setConversationStatus("active")}
                  className={cn("pointer col-md-auto col-sm-12", {
                    "text-primary": conversationStatus === "active",
                  })}
                  icon={
                    <FontAwesomeIcon
                      className="mx-2"
                      icon={["fas", "comments"]}
                      size="lg"
                      color={conversationStatus !== "active" ? "grey" : ""}
                    />
                  }
                />
                <FilterOption
                  label={`(${getStatusesCounter().archived})`}
                  selected={conversationStatus === "archived"}
                  onClick={() => setConversationStatus("archived")}
                  className={cn("pointer col-md-auto col-sm-12")}
                  icon={
                    <FontAwesomeIcon
                      className="mx-2"
                      icon={["fas", "comments"]}
                      size="lg"
                      color={conversationStatus !== "archived" ? "grey" : ""}
                    />
                  }
                />
                <FilterOption
                  selected={conversationStatus === "inTrash"}
                  label={`(${getStatusesCounter().inTrash})`}
                  onClick={() => setConversationStatus("inTrash")}
                  className={cn("pointer col-md-auto col-sm-12")}
                  icon={
                    <FontAwesomeIcon
                      className="mx-2"
                      size="lg"
                      icon={["fas", "trash"]}
                      color={conversationStatus !== "inTrash" ? "grey" : ""}
                    />
                  }
                />{" "}
                <LineDropdown
                  className="col"
                  icon={["fas", "contact-book"]}
                  open={openContactsStateDropdown}
                  setOpen={setOpenContactsStateDropdown}
                  label={selectedLabel}
                >
                  <ContactStateDropdownOptions
                    onSelect={(stateLabel) => setSelectedLabel(stateLabel)}
                  />
                </LineDropdown>
                <LineDropdown
                  className="col text-center"
                  icon={["fas", "calendar"]}
                  open={openPeriod}
                  setOpen={setOpenPeriod}
                  label={getLabel()!}
                >
                  <PeriodDropdownOptions />
                </LineDropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex col mx-auto my-auto justify-content-center  ">
          <div className="row my-2">
            <div className="col d-flex my-auto">
              <FilterOption
                label={`(${getSocialCounters().all})`}
                onClick={() => setSelectedSocial("all")}
                selected={selectedSocial === "all"}
                className={cn("pointer col-md-auto col-sm-12 ")}
                icon={
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={["fas", "message"]}
                    size="lg"
                    color={selectedSocial !== "all" ? "grey" : ""}
                  />
                }
              />
              <FilterOption
                label={`(${getSocialCounters().web})`}
                onClick={() => setSelectedSocial("web")}
                selected={selectedSocial === "web"}
                className={cn("col-md-auto col-sm-12")}
                icon={
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={["fas", "globe"]}
                    size="lg"
                    color={selectedSocial !== "web" ? "" : "#0d4b7e"}
                  />
                }
              />
              <FilterOption
                enabled={configuredSocials.whatsapp}
                label={`(${getSocialCounters().whatsapp})`}
                onClick={() => setSelectedSocial("whatsapp")}
                selected={selectedSocial === "whatsapp"}
                className={cn("col-md-auto col-sm-12")}
                icon={
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={["fab", "whatsapp"]}
                    size="lg"
                    color={
                      !configuredSocials.whatsapp ? "grey" : WHATSAPP_COLOR
                    }
                  />
                }
              />
              <FilterOption
                enabled={configuredSocials.facebook}
                label={`(${getSocialCounters().facebook})`}
                onClick={() => setSelectedSocial("facebook")}
                selected={selectedSocial === "facebook"}
                className={cn("col-md-auto col-sm-12")}
                icon={
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={["fab", "facebook"]}
                    size="lg"
                    color={
                      !configuredSocials.facebook ? "grey" : FACEBOOK_COLOR
                    }
                  />
                }
              />
              <FilterOption
                enabled={configuredSocials.instagram}
                selected={selectedSocial === "instagram"}
                label={`(${getSocialCounters().instagram})`}
                onClick={() => setSelectedSocial("instagram")}
                className={cn("col-md-auto col-sm-12")}
                icon={
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={["fab", "instagram"]}
                    size="lg"
                    color={
                      !configuredSocials.instagram ? "grey" : INSTAGRAM_COLOR
                    }
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBarFilter;
