import React from "react";
import { Nav } from "reactstrap";
import { TitleWembii } from "../TitleWembii";
import { useRouterCore } from "../../../cores/router";
import { useIntl } from "react-intl";
import { NavItem2 } from "./NavItem2";

const SidebarAdminRoutes: React.FC<{ collapseSideBar: boolean }> = ({
  collapseSideBar,
}) => {
  const {
    routes,
    goToUsers,
    goToCompany,
    goToResellers,
    goToSocialManager,
  } = useRouterCore();
  const intl = useIntl();

  return (
    <>
      <hr className="horizontal-separator my-3" />
      {!collapseSideBar && (
        <TitleWembii Tag="h6">
          {intl.formatMessage({ id: "app.sidebar.adminWembii" })}
        </TitleWembii>
      )}
      <Nav vertical>
        <NavItem2
          onClick={goToUsers}
          label={intl.formatMessage({ id: "app.sidebar.users" })}
          iconName="users"
          activeUrl={routes.users}
        />
        <NavItem2
          onClick={goToCompany}
          label={intl.formatMessage({ id: "app.sidebar.company" })}
          iconName="building"
          activeUrl={routes.company}
        />
        <NavItem2
          onClick={goToResellers}
          label={intl.formatMessage({
            id: "app.sidebar.adminWembii.reseller",
          })}
          iconName="comment-dollar"
          activeUrl={routes.resellers}
        />
        <NavItem2
          onClick={goToSocialManager}
          label={intl.formatMessage({
            id: "app.sidebar.adminWembii.socialManager",
          })}
          iconName="thumbs-up"
          activeUrl={routes.socialManager}
        />
      </Nav>
    </>
  );
};

export default SidebarAdminRoutes;
