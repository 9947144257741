import cn from "classnames";
import React, { CSSProperties, ReactNode } from "react";

interface Props {
  className?: string;
  Tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children?: ReactNode;
  position?: "left" | "right";
  color?: "primary" | "secondary" | "white";
  toolbar?: ReactNode;
  style?: CSSProperties;
  mb?: boolean;
  onClick?: () => void;
}

export const TitleWembii = (props: Props) => {
  const {
    Tag = "h1",
    position = "left",
    color = "secondary",
    children,
    className,
    style,
    toolbar,
    mb = true,
    onClick
  } = props;

  return (
    <div
      className={cn(className, `d-flex text-${color} mt-1`, {
        "justify-content-end": position === "right" && !toolbar,
        "justify-content-between": toolbar,
        "m-0": !mb,
        "mb-1": mb
      })}
      style={{ ...style }}
      onClick={onClick}
    >
      <Tag className="font-weight-bold "> {children} </Tag>
      {toolbar && <> {toolbar} </>}
    </div>
  );
};
