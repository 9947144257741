import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { getCurrencies } from "../../../cores/boards/constants/currencies.constant";

interface Props {
  currency: string;
  onSubmit: (arg0: string) => void;
  width?: string;
}

export default function CurrencySelector(props: Props) {
  const { currency, width = "250px", onSubmit } = props;
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<{ value: string; label: string }>();

  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      width,
    }),
    input: (styles: any) => ({}),
    option: (styles: any) => ({ ...styles }),
    placeholder: (styles: any) => ({ ...styles }),
    singleValue: (styles: any) => ({ ...styles }),
  };

  const handleChange = useCallback(
    (option) => {
      onSubmit(option.value);
    }, [onSubmit]
  );

  useEffect(() => {
    setOptions(
      getCurrencies().map(({ code, name, symbol }) => ({ value: code, label: `${symbol} - ${name}` }))
    );
  }, []);
  
  useEffect(() => {
      setSelectedOption(options.find(({ value }) => value === currency));
    }, [currency, options]
  );

  return (
    <Select
      type="select"
      value={selectedOption}
      onChange={handleChange}
      isClearable={false}
      options={options}
      isSearchable
      classNamePrefix="select"
      placeholder="Moneda"
      styles={selectStyles}
    />
  );
}
