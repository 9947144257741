import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import {
  getChatInitAction,
  putChatFailureAction,
} from "../conversations/store/api-actions";
import { WhatsappChat } from "./interfaces/api/get-whatsapp-chat";
import { whatsappChatApiService } from "./services/api";
import { setChatAction } from "./store/actions";
import {
  deleteWhatsappUserFailureAction,
  deleteWhatsappUserInitAction,
  deleteWhatsappUserSuccessAction,
  getChatFailureAction,
  getChatSuccessAction,
  postWhatsappUserFailureAction,
  postWhatsappUserInitAction,
  postWhatsappUserSuccessAction,
  putChatInitAction,
  putChatSuccessAction,
  putWhatsappUserFailureAction,
  putWhatsappUserInitAction,
  putWhatsappUserSuccessAction,
} from "./store/api-actions";
import { chatSelector } from "./store/selector";

export function useWhatsappChatCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const chat = useSelector(chatSelector);
  const getChat = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(getChatInitAction());
        const response = await whatsappChatApiService.getChat(companyId);
        dispatch(getChatSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(getChatFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putChat = useCallback(
    async (
      companyId: string,
      chatId: string,
      title: string,
      subtitle: string,
      titleColor: string,
      backgroundColor: string,
      startChat: boolean,
      openButtonColor: string,
      openButtonIconColor: string,
      position: number,
      isActive: boolean,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putChatInitAction());
        const response = await whatsappChatApiService.putChat(
          companyId,
          chatId,
          title,
          subtitle,
          titleColor,
          backgroundColor,
          startChat,
          openButtonColor,
          openButtonIconColor,
          position,
          isActive
        );
        dispatch(putChatSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess40" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putChatFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const postChatUser = useCallback(
    async (
      companyId: string,
      chatId: string,
      phoneNumber: string,
      userId: string,
      alias: string,
      tag: string,
      skipLogin: boolean,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postWhatsappUserInitAction());
        const response = await whatsappChatApiService.postChatUser(
          companyId,
          chatId,
          phoneNumber,
          userId,
          alias,
          tag,
          skipLogin
        );
        dispatch(postWhatsappUserSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess41" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postWhatsappUserFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );

  const putChatUser = useCallback(
    async (
      companyId: string,
      chatId: string,
      notificationId: string,
      phoneNumber: string,
      userId: string,
      alias: string,
      tag: string,
      skipLogin: boolean,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putWhatsappUserInitAction());
        const response = await whatsappChatApiService.putChatUser(
          companyId,
          chatId,
          notificationId,
          phoneNumber,
          userId,
          alias,
          tag,
          skipLogin
        );
        dispatch(putWhatsappUserSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess42" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putWhatsappUserFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const deleteChatUser = useCallback(
    async (companyId: string, chatId: string, notificationId: string) => {
      try {
        dispatch(deleteWhatsappUserInitAction());
        const response = await whatsappChatApiService.deleteChatUser(
          companyId,
          chatId,
          notificationId
        );
        dispatch(deleteWhatsappUserSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess43" }));
      } catch (error: any) {
        console.error(error);
        dispatch(deleteWhatsappUserFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );

  return {
    chat,
    getChat,
    putChat,
    postChatUser,
    putChatUser,
    deleteChatUser,
    setChatConfig: (chat: WhatsappChat) => dispatch(setChatAction({ chat })),
  };
}
