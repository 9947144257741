import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const marketRoot = (state: State) => state.market;

export const wooCommerceOrderSelector = createSelector(
  marketRoot,
  ({ wooCommerce: { orders } }) => orders.ids.map((id) => orders.entities[id])
);

export const mercadolibreOrdersSelector = createSelector(
  marketRoot,
  ({ mercadolibre: { orders } }) => orders.ids.map((id) => orders.entities[id])
);

export const jumpsellerOrdersSelector = createSelector(
  marketRoot,
  ({ jumpseller: { orders } }) => orders.ids.map((id) => orders.entities[id])
);

export const jumpsellerOrderIdSelector = createSelector(
  marketRoot, ({ jumpseller: { orderId } }) => orderId
);

export const jumpsellerPerPageSelector = createSelector(
  marketRoot, ({ jumpseller: { perPage } }) => perPage
);

export const jumpsellerPageSelector = createSelector(
  marketRoot, ({ jumpseller: { page } }) => page
);

export const jumpsellerStatusSelector = createSelector(
  marketRoot, ({ jumpseller: { status } }) => status
);
