import { Dictionary } from "lodash";

export enum UserBoardRole {
  Owner = "owner",
  Admin = "admin",
  Member = "member",
}

export const  UserBoardRoleLabels: Dictionary<string> = {
  [`${UserBoardRole.Owner}`]: "app.crmBusiness.role.owner",
  [`${UserBoardRole.Admin}`]: "app.crmBusiness.role.admin",
  [`${UserBoardRole.Member}`]: "app.crmBusiness.role.member",
};

export const UserBoardRoleOptions = [
  { value: UserBoardRole.Owner, label: "app.crmBusiness.role.owner" },
  { value: UserBoardRole.Admin, label: "app.crmBusiness.role.admin" },
  { value: UserBoardRole.Member, label: "app.crmBusiness.role.member" },
];