import React, { useCallback, useMemo } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { File } from "../../../../cores/boards/interfaces/api/get-card";
import { UploadFileType } from "../../../../cores/utils";
import DocxPreview from "../preview/DocxPreview";
import ExcelPreview from "../preview/ExcelPreview";
import PdfPreview from "../preview/PdfPreview";

interface Props {
  file: File;
}

export const FileInfoPreview = (props: Props) => {
  const { file } = props;

  const onDownload = useCallback(() => {
    const link = document.createElement('a');
    link.href = file.url;
    link.setAttribute('download', file.key);
    document.body.appendChild(link);
    link.click();
  }, [file]);

  const previewSelector = useMemo(() => {
    if (file.type === UploadFileType.PDF)
      return <PdfPreview size="9x" />
    if (file.type === UploadFileType.EXCEL || file.type === UploadFileType.CSV)
      return <ExcelPreview size="9x" />
    if (file.type === UploadFileType.DOCX)
      return <DocxPreview size="9x" />
    if (file.type === UploadFileType.MOV || file.type === UploadFileType.MP4)
      return <video className="videoEditor" controls>
        <source src={file.url} type={file.type} />
        Your browser does not support HTML5 video.
      </video>

    return <img
      className="imageEditor"
      key={file.id}
      alt={file.key}
      src={file.url}
    />
  }, [file]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 align-items-center p-4">
            <h2 className="h2 text-center mb-4">Visualizar Archivo</h2>
            {previewSelector}
            <div className="px-2 my-3">
              <div className="my-3 text-center">
                <span className="font-weight-bolder">Nombre: </span>
                {file.key}
              </div>
              <div className="my-3 text-center">
                <span className="font-weight-bolder">Tipo: </span>
                {file.type}
              </div>
            </div>
            <Button className="w-50" color="primary" onClick={onDownload}>
              Descargar
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
