import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-date-picker";
import { useIntl } from "react-intl";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { useFacebookCore } from "../../../cores/facebook";
import { useInstagramCore } from "../../../cores/instagram";
import { useLinkedInCore } from "../../../cores/linkedIn";
import { useLinkedInBusinessCore } from "../../../cores/linkedInBusiness";
import { useMeCore } from "../../../cores/me";
import { useSocialPostCore } from "../../../cores/socialPost";
import { SocialProviderId } from "../../../cores/socialPost/config";
import { SmartTime, SMART_TIMES } from "../../../cores/socialPost/consts/smartTimes.constant";
import { SOCIAL_ACTION_TYPES } from "../../../cores/socialPost/consts/socialActionType.constant";
import { SOCIAL_POST_TYPES } from "../../../cores/socialPost/consts/socialPostType.constant";
import { SocialType } from "../../../cores/socialPost/interfaces/postError";
import { useTwitterCore } from "../../../cores/twitter";
import { InputWembii } from "../InputWembii";
import { LabelWembii } from "../LabelWembii";

interface Props {
  onClose?: () => void;
  onReset?: () => void;
  socialType: SocialType;
  repost: boolean;
}

export const PostForm = (props: Props) => {
  const { onClose, onReset, socialType, repost } = props;
  const [date, setDate] = useState<Date>(moment().add(1, "day").toDate());
  const [hour, setHour] = useState<string>(moment().format("HH:mm"));
  const {
    images,
    video,
    text = "",
    mentions,
    socialProviders,
    selectedPost,
    setSocialAction,
  } = useSocialPostCore();
  const {
    socialPost: socialPostTwitter,
    socialPostVideo: socialPostVideoTwitter,
    modifySocialPost: modifySocialPostTwitter,
    modifySocialPostVideo: modifySocialPostVideoTwitter,
  } = useTwitterCore();
  const {
    socialPost: socialPostFacebook,
    socialPostVideo: socialPostVideoFacebook,
    modifySocialPost: modifySocialPostFacebook,
    modifySocialPostVideo: modifySocialPostVideoFacebook,
    socialStory: socialStoryFacebook,
    socialStoryVideo: socialStoryVideoFacebook,
    modifySocialStory: modifySocialStoryFacebook,
    modifySocialStoryVideo: modifySocialStoryVideoFacebook
  } = useFacebookCore();
  const {
    socialPost: socialPostLinkedIn,
    modifySocialPost: modifySocialPostLinkedIn,
  } = useLinkedInCore();
  const {
    socialPost: socialPostLinkedInBusiness,
    socialPostVideo: socialPostVideoLinkedInBusiness,
    modifySocialPost: modifySocialPostLinkedInBusiness,
    modifySocialPostVideo: modifySocialPostVideoLinkedInBusiness,
  } = useLinkedInBusinessCore();
  const {
    socialPost: socialPostInstagram,
    socialPostVideo: socialPostVideoInstagram,
    modifySocialPost: modifySocialPostInstagram,
    modifySocialPostVideo: modifySocialPostVideoInstagram,
    socialStory: socialStoryInstagram,
    socialStoryVideo: socialStoryVideoInstagram,
    modifySocialStory: modifySocialStoryInstagram,
    modifySocialStoryVideo: modifySocialStoryVideoInstagram,
    cleanErrors
  } = useInstagramCore();
  const { company } = useMeCore();
  const intl = useIntl();

  const disabled = useMemo(() => !date || !hour, [date, hour]);

  useEffect(() => {
    if (selectedPost?.post.scheduleDate) {
      setDate(moment(selectedPost?.post.scheduleDate).toDate());
      setHour(moment(selectedPost?.post.scheduleDate).format("HH:mm"))
    } else {
      setDate(moment().add(1, "day").toDate());
      setHour(moment().format("HH:mm"));
    }
  }, [selectedPost]);

  const onSuccess = useCallback(() => {
    onReset?.();
    cleanErrors();
  }, [onReset, cleanErrors]);

  const onSubmitPost = useCallback(
    () => {
      setSocialAction(SOCIAL_ACTION_TYPES.SCHEDULE);
      const parseDate =
        date && hour ? moment(`${moment(date).format("yyyy-MM-DD")} ${hour}`) : "";

      const repostId = repost ? selectedPost?.post.id! : '';

      socialProviders.forEach((sp) => {
        switch (sp.id) {
          case SocialProviderId.Twitter:
            video || (selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO && selectedPost.media.length > 0)
              ? !selectedPost || repost
                ? socialPostVideoTwitter(company?.id!, text, mentions, video!, parseDate.toString(), repostId, onSuccess)
                : modifySocialPostVideoTwitter(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    mentions,
                    video,
                    parseDate.toString(),
                    selectedPost.media,
                    onSuccess
                  )
              : !selectedPost || repost
                ? socialPostTwitter(company?.id!, text, mentions, images, parseDate.toString(), repostId, onSuccess)
                : modifySocialPostTwitter(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    mentions,
                    images,
                    parseDate.toString(),
                    selectedPost.media,
                    onSuccess
                  );
            break;
          case SocialProviderId.LinkedInBusiness:
            video || (selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO && selectedPost.media.length > 0)
              ? !selectedPost || repost
                ? socialPostVideoLinkedInBusiness(company?.id!, text, mentions, video!, parseDate.toString(), repostId, onSuccess)
                : modifySocialPostVideoLinkedInBusiness(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    mentions,
                    video,
                    parseDate.toString(),
                    selectedPost.media,
                    onSuccess
                  )
              : !selectedPost || repost
                ? socialPostLinkedInBusiness(company?.id!, text, mentions, images, parseDate.toString(), repostId, onSuccess)
                : modifySocialPostLinkedInBusiness(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    mentions,
                    images,
                    parseDate.toString(),
                    selectedPost.media,
                    onSuccess
                  );
            break;
          case SocialProviderId.LinkedIn:
            !selectedPost || repost
              ? socialPostLinkedIn( company?.id!, text, mentions, images, parseDate.toString(), repostId, onSuccess)
              : modifySocialPostLinkedIn(
                  company?.id!,
                  selectedPost.post.id,
                  text,
                  mentions,
                  images,
                  parseDate.toString(),
                  selectedPost.media,
                  onSuccess
                );
            break;
          case SocialProviderId.Facebook:
            video || (selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO && selectedPost.media.length > 0)
              ? !selectedPost || repost
                ? socialPostVideoFacebook(company?.id!, text, mentions, video!, parseDate.toString(), repostId, onSuccess)
                : modifySocialPostVideoFacebook(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    mentions,
                    video,
                    parseDate.toString(),
                    selectedPost.media,
                    onSuccess
                  )
              : !selectedPost || repost
                ? socialPostFacebook(company?.id!, text, mentions, images, parseDate.toString(), repostId, onSuccess)
                : modifySocialPostFacebook(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    mentions,
                    images,
                    parseDate.toString(),
                    selectedPost.media,
                    onSuccess
                  );
            break;
          case SocialProviderId.Instagram:
            video || (selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO && selectedPost.media.length > 0)
              ? !selectedPost || repost
                ? socialPostVideoInstagram(company?.id!, text, mentions, video!, parseDate.toString(), repostId, onSuccess)
                : modifySocialPostVideoInstagram(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    mentions,
                    video,
                    parseDate.toString(),
                    selectedPost.media,
                    onSuccess
                  )
              : !selectedPost || repost
                ? socialPostInstagram(company?.id!, text, mentions, images, parseDate.toString(), repostId, onSuccess)
                : modifySocialPostInstagram(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    mentions,
                    images,
                    parseDate.toString(),
                    selectedPost.media,
                    onSuccess
                  );
            break;
          default:
            break;
        }
      });
      onClose?.();
    },
    [
      date,
      hour,
      socialProviders,
      video,
      company,
      text,
      images,
      mentions,
      repost,
      onClose,
      socialPostVideoTwitter,
      socialPostTwitter,
      socialPostVideoLinkedInBusiness,
      socialPostLinkedInBusiness,
      socialPostLinkedIn,
      socialPostVideoFacebook,
      socialPostFacebook,
      socialPostVideoInstagram,
      socialPostInstagram,
      modifySocialPostFacebook,
      modifySocialPostInstagram,
      modifySocialPostLinkedIn,
      modifySocialPostLinkedInBusiness,
      modifySocialPostTwitter,
      modifySocialPostVideoFacebook,
      modifySocialPostVideoInstagram,
      modifySocialPostVideoLinkedInBusiness,
      modifySocialPostVideoTwitter,
      onSuccess,
      setSocialAction,
      selectedPost,
    ]
  );

  const onSubmitStory = useCallback(
    () => {
      setSocialAction(SOCIAL_ACTION_TYPES.SCHEDULE);
      const parseDate =
        date && hour ? moment(`${moment(date).format("yyyy-MM-DD")} ${hour}`).toString() : "";

      socialProviders.forEach((sp) => {
        switch (sp.id) {
          case SocialProviderId.Instagram:
            video || (selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO && selectedPost.media.length > 0)
              ? !selectedPost
                ? socialStoryVideoInstagram(
                    company?.id!,
                    text,
                    video!,
                    parseDate,
                    onReset
                  )
                : modifySocialStoryVideoInstagram(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    video,
                    parseDate,
                    selectedPost.media,
                    onReset
                  )
              : !selectedPost
                ? socialStoryInstagram(company?.id!, text, images, parseDate, onReset)
                : modifySocialStoryInstagram(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    images,
                    parseDate,
                    selectedPost.media,
                    onReset
                  );
            break;
          case SocialProviderId.Facebook:
            video || (selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO && selectedPost.media.length > 0)
              ? !selectedPost
                ? socialStoryVideoFacebook(
                    company?.id!,
                    text,
                    video!,
                    parseDate,
                    onReset
                  )
                : modifySocialStoryVideoFacebook(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    video,
                    parseDate,
                    selectedPost.media,
                    onReset
                  )
              : !selectedPost
                ? socialStoryFacebook(company?.id!, text, images, parseDate, onReset)
                : modifySocialStoryFacebook(
                    company?.id!,
                    selectedPost.post.id,
                    text,
                    images,
                    parseDate,
                    selectedPost.media,
                    onReset
                  );
            break;
          default:
            break;
        }
      });
      onClose?.();
    }, [
      socialProviders,
      video,
      company,
      text,
      images,
      date,
      hour,
      selectedPost,
      onClose,
      socialStoryFacebook,
      socialStoryInstagram,
      socialStoryVideoFacebook,
      socialStoryVideoInstagram,
      onReset,
      setSocialAction,
      modifySocialStoryFacebook,
      modifySocialStoryInstagram,
      modifySocialStoryVideoFacebook,
      modifySocialStoryVideoInstagram
    ]
  );

  const getSmartTimes = useCallback(() => {
    return SMART_TIMES[`${moment(date).isoWeekday() - 1}`];
  }, [date]);

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      if (socialType === SocialType.Post) 
        onSubmitPost()
      else
        onSubmitStory()
    },
    [onSubmitPost, onSubmitStory, socialType]
  );

  const formatSmartTime = (time: SmartTime) => moment(time.hour+':'+time.minutes, "H:mm").format("HH:mm");
  
  return (
    <Container className="h-100" fluid>
      <Row>
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-2 py-3">
            <h2 className="h2 text-center mb-4">{intl.formatMessage({id: "app.programModalPost.title"})}</h2>
            <Form className="p-2 px-4" onSubmit={onSubmit}>
              <LabelWembii>{intl.formatMessage({id: "app.programModalPost.Date"})}</LabelWembii>
              <DatePicker
                className="w-100 mb-2"
                format="dd/MM/y"
                value={date}
                clearIcon={null}
                onChange={setDate}
              />
              <InputWembii
                type="time"
                label={intl.formatMessage({id: "app.programModalPost.time"})}
                name="time"
                id="exampleTime"
                placeholder="Ingrese Hora"
                value={hour}
                onChange={(v) => setHour(v)}
              />

              <div className="text-center">
                <UncontrolledDropdown>
                  <DropdownToggle className="whiteButton">
                  {intl.formatMessage({id: "app.programModalPost.SmartTime"})}
                  </DropdownToggle>
                  <DropdownMenu 
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: 'auto',
                              maxHeight: '250px',
                            },
                          };
                        },
                      },
                    }}
                  >
                    {getSmartTimes().map((time, index) => (
                      <DropdownItem 
                        key={`time${index}`}
                        className="p-2"
                        onClick={() => setHour(formatSmartTime(time))}
                      >
                        <div className="d-flex align-items-center pointer">
                          <span className="px-2">
                            {formatSmartTime(time)}
                          </span>

                          {_.range(time.score).map((item) => (
                            <FontAwesomeIcon
                              key={`score${time.hour}${item}`}
                              icon={["fas", "star"]}
                              size="1x"
                              color="grey"
                            />
                          ))}
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
    
              <Button
                className="w-auto mx-auto px-5 my-4"
                color="primary"
                block
                disabled={disabled}
              >
                {intl.formatMessage({id: "app.programModalPost.title"})}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
