import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import { MERCADOLIBRE_ORDER_LOADING } from "../../constants/loading.constant";
import { MERCADO_LIBRE_ORDER_EXPAND } from "../../constants/modal.constant";
import { useAnalyticsCore } from "../../cores/analytics";
import { ChartMetric } from "../../cores/analytics/interface/ChartMetric";
import { Metric2 } from "../../cores/analytics/interface/Metric2";
import { useGlobalCore } from "../../cores/globals";
import { useMarketCore } from "../../cores/market";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { ChartContainer } from "../component/analytics/ChartContainer";
import { BarChart } from "../component/analytics/charts/BarChart";
import { MercadolibreOrderBar } from "../component/market/mercadolibre/MercadolibreBar";
import { MercadolibreOrder } from "../component/market/mercadolibre/MercadolibreOrder";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";

export const MercadolibreMarket = () => {
  const intl = useIntl();
  const { mercadolibreOrders } = useMarketCore();
  const {
    company,
    socialSettings,
    companyPlan,
    companyRole,
    isAdmin,
    isReseller
  } = useMeCore();
  const { isModal, turnOffModal, getModalContent, getModalVisibility } = useGlobalCore();
  const {
    mercadolibreInsights,
    getMercadolibreInsights,
    toChartMetric,
  } = useAnalyticsCore();
  const { goToSocial } = useRouterCore();

  const isMercadoLibreSync = useMemo(
    () => socialSettings && socialSettings.mercadoLibre,
    [socialSettings]
  );

  useEffect(() => {
    if (
      !isMercadoLibreSync || !companyPlan?.rules.allowMarket || (
        !companyRole?.market &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [companyPlan, goToSocial, isMercadoLibreSync, companyRole, isAdmin, isReseller]);

  const dates = useMemo(
    () =>
      mercadolibreOrders.map(({ creationDate }) => moment(creationDate).unix()),
    [mercadolibreOrders]
  );
  const maxDate = useMemo(
    () => moment(Math.max.apply(null, dates) * 1000).format(),
    [dates]
  );
  const minDate = useMemo(
    () => moment(Math.min.apply(null, dates) * 1000).format(),
    [dates]
  );

  useEffect(() => {
    isMercadoLibreSync &&
      dates.length &&
      maxDate &&
      minDate &&
      getMercadolibreInsights(company!.id, minDate, maxDate);
  }, [
    company,
    dates,
    getMercadolibreInsights,
    maxDate,
    minDate,
    isMercadoLibreSync,
  ]);

  const orderData: Metric2[] = useMemo(
    () => [
      {
        ...mercadolibreInsights.ml_orders,
        type: "line",
      },
    ],
    [mercadolibreInsights]
  );

  const ordersChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(orderData),
    [orderData, toChartMetric]
  );

  return (
    <Layout>
      <Header2 
        title={intl.formatMessage({ id: "app.onlineSales.title" })} 
      />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <ChartContainer
                height={120}
                className="my-0 p-0"
                title=""
                isEmptyData={dates.length === 0}
              >
                <BarChart
                  height={50}
                  labels={ordersChartMetrics.labels}
                  datasets={ordersChartMetrics.datasets}
                />
              </ChartContainer>
              <MercadolibreOrderBar />
              <UpdateLoadingCard
                loadingId={MERCADOLIBRE_ORDER_LOADING}  
                text={intl.formatMessage({ id: "app.onlineSales.updating" })} 
              />
              <Container>
                {mercadolibreOrders.map((order) => (
                  <MercadolibreOrder order={order} key={order.id} />
                ))}
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(MERCADO_LIBRE_ORDER_EXPAND)}
        visible={getModalVisibility(MERCADO_LIBRE_ORDER_EXPAND)}
        onClose={() => turnOffModal(MERCADO_LIBRE_ORDER_EXPAND)}
        left
      >
        {getModalContent(MERCADO_LIBRE_ORDER_EXPAND)}
      </SideModal>
    </Layout>
  );
};
