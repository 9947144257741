import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import { SCHEDULE_POST_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useSocialPostCore } from "../../../../cores/socialPost";

export const RemindButton = () => {
  const intl = useIntl();
  const { turnOnModal } = useGlobalCore();
  const {
    socialProviders,
    storyErrors,
    images,
    video,
    text = "",
  } = useSocialPostCore();

  const disabled = useMemo(() => {
    const socialProviderIds = socialProviders.map((sp) => sp.id);
    const hasError =
      storyErrors.find((error) =>
        socialProviderIds.includes(error.socialProviderId)
      ) != null;
    return (
      socialProviders.length <= 0 ||
      hasError ||
      (!text && !video && !images.length)
    );
  }, [storyErrors, images.length, socialProviders, text, video]);

  return (
    <div className="wrap-container my-2">
      <Button
        outline
        color="primary"
        disabled={disabled}
        onClick={() => turnOnModal(SCHEDULE_POST_MODAL, true)}
      >
        {intl.formatMessage({ id: "app.socialStory.remind" })}
      </Button>
    </div>
  );
};
