import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const gmailRoot = (state: State) => state.gmail;

export const settingSelector = createSelector(
  gmailRoot,
  (gmail) => gmail.settings
);

export const userSettingSelector = createSelector(
  gmailRoot,
  (gmail) => gmail.userSettings
);
