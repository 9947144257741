import _ from "lodash";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "reactstrap";
import { CONTACT_COMPANY_EXPAND_EDIT_FORM } from "../../../constants/modal.constant";
import { useContactCore } from "../../../cores/contacts";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { PanelWembii } from "../PanelWembii";
import EditCompanyForm from "./modal/EditCompanyForm";
import { Company } from "../../../cores/contacts/interfaces/api/get-company";
import { ENTERPRISE_CONTACT_ASSIGNMENT } from "../../../constants/internalModal.constant";
import { TooltipWembii } from "../TooltipWembii";
import Avatar from "../Avatar";
import { getFirstLetters } from "../../utils/stringUtils";

interface Props {
  company: Company;
}

export const CompanyRow = (props: Props) => {
  const intl = useIntl();
  const {
    company: { id, name, sector, city, country, address, contacts, owners },
  } = props;
  const { turnOnModal, turnOffModal, turnOffInternalModal } = useGlobalCore();
  const { getCompany, deleteCompany, getCompanyCustomFields } = useContactCore();
  const { company } = useMeCore();

  const onClick = useCallback(() => {
    getCompany(company?.id!, id);
    getCompanyCustomFields(company?.id!);
    turnOnModal(
      CONTACT_COMPANY_EXPAND_EDIT_FORM,
      <EditCompanyForm
        onDelete={async () => {
          await deleteCompany(company?.id!, id);
          turnOffModal(CONTACT_COMPANY_EXPAND_EDIT_FORM);
          turnOffInternalModal(ENTERPRISE_CONTACT_ASSIGNMENT);
        }}
      />
    );
  }, [
    id,
    company,
    turnOnModal,
    turnOffModal,
    deleteCompany,
    getCompany,
    getCompanyCustomFields,
    turnOffInternalModal,
  ]);

  const moreOwners = useMemo(() => {
    const owns = [...owners];
    return owns.splice(1);
  }, [owners]);

  return (
    <PanelWembii className="w-100 px-4 py-3 my-2 pointer" onClick={onClick}>
      <Row style={{ minHeight: 44 }}>
        <Col xs="12" lg="1">
          <div className="user-cascade-container d-flex align-items-center justify-content-center justify-content-lg-start flex-wrap position-relative">
            {owners.map((owner, index) => (
              <div
                className={cn("user-avatar", {
                  "avatar-lg-hidden": index > 0
                })}
                style={{
                  "zIndex": index,
                  "left": index * 25,
                  "top": "50%"
                }}
              >
                <Avatar
                  key={index}
                  id={`owner-${id}-${owner.id}`}
                  className="text-dark-50 pointer"
                  image={owner.avatarUrl}
                  label={getFirstLetters(`${owner.lastName} ${owner.firstName}`)}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "user"]}
                      size="1x"
                    />
                  }
                />
                <TooltipWembii 
                  id={`owner-${id}-${owner.id}`}
                  text={`${owner.lastName} ${owner.firstName}`} 
                />
              </div>
            ))}
            {moreOwners.length > 0 && (
              <div
                className={cn("user-avatar avatar-md-hidden")}
                style={{
                  "zIndex": 1,
                  "left": 1 * 25,
                  "top": "50%"
                }}
              >
                <Avatar
                  id={`owner-${id}-rest`}
                  className="text-dark-50 pointer"
                  icon={
                    moreOwners.length > 1 ? (
                      <FontAwesomeIcon
                        icon={["fas", "users"]}
                        size="1x"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={["fas", "user"]}
                        size="1x"
                      />
                    )
                  }
                />
                <TooltipWembii 
                  id={`owner-${id}-rest`}
                  text={moreOwners
                    .map((owner) => `${owner.lastName} ${owner.firstName}`)
                    .join(", ")
                  } 
                />
              </div>
            )}
          </div>
        </Col>
        <Col xs="12" lg="4">
          <div className="h-100 d-flex align-items-center justify-content-center justify-content-lg-start my-1 w-100">
            <FontAwesomeIcon icon={["far", "building"]} className="mr-2" />
            <div>
              <div className="h6 mb-0">{name}</div>
              {sector && <div className="text-muted">{sector}</div>}
            </div>
          </div>
        </Col>
        <Col xs="12" lg="3">
          <div className="h-100 d-flex align-items-center justify-content-center justify-content-lg-start w-100 my-1">
            <div>
              {(city || country) && (
                <div className="h6 mb-0">
                  {_.compact([country, city]).join(" - ")}
                </div>
              )}
              {sector && <div className="h6 mb-0">{address}</div>}
            </div>
          </div>
        </Col>
        <Col xs="12" lg="4">
          <div className="w-fit mx-auto mr-lg-0 ml-lg-auto h-100">
            <span className="h6 mb-0 h-100 d-flex align-items-center">
              {intl.formatMessage(
                { id: "app.contacts.companies.contacts" },
                { quantity: contacts?.length }
              )}
            </span>
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
