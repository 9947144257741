import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const boardRoot = (state: State) => state.board;

export const boardsSelector = createSelector(boardRoot, (me) => {
  const boards = me.boards || { ids: [] };
  return boards.ids.map((id) => me.boards.entities[id])
});

export const listsSelector = createSelector(boardRoot, (me) => {
  const lists = me.lists || { ids: [] };
  return lists.ids.map((id) => me.lists.entities[id]);
});

export const commentsSelector = createSelector(boardRoot, (me) => {
  const comments = me.comments || { ids: [] };
  return comments.ids.map((id) => me.comments.entities[id])
});

export const filesSelector = createSelector(boardRoot, (me) => {
  const files = me.files || { ids: [] };
  return files.ids.map((id) => me.files.entities[id])
});

export const emailsSelector = createSelector(boardRoot, (me) => {
  const emails = me.emails || { ids: [] };
  return emails.ids.map((id) => me.emails.entities[id])
});

export const tasksSelector = createSelector(boardRoot, (me) => {
  const tasks = me.tasks || { ids: [] };
  return tasks.ids.map((id) => me.tasks.entities[id]);
});

export const eventsSelector = createSelector(boardRoot, (me) => {
  const events = me.events || { ids: [] };
  return events.ids.map((id) => me.events.entities[id]);
});

export const quotesSelector = createSelector(boardRoot, (me) => {
  const quotes = me.quotes || { ids: [] };
  return quotes.ids.map((id) => me.quotes.entities[id]);
});

export const activitiesSelector = createSelector(boardRoot, (me) => {
  const activities = me.activities || { ids: [] };
  return activities.ids.map((id) => me.activities.entities[id]);
});

export const productsSelector = createSelector(boardRoot, (me) => {
  const products = me.products || { ids: [] };
  return products.ids.map((id) => me.products.entities[id]);
});

export const tagsSelector = createSelector(boardRoot, (me) => {
  const tags = me.tags || { ids: [] };
  return tags.ids.map((id) => me.tags.entities[id]);
});

export const allTagsSelector = createSelector(boardRoot, (me) => {
  const allTags = me.allTags || { ids: [] };
  return allTags.ids.map((id) => me.allTags.entities[id]);
});

export const customFieldsSelector = createSelector(boardRoot, ({ customFields }) =>
  customFields.ids.map((id) => customFields.entities[id])
);

export const errorCodeSelector = createSelector(boardRoot, (me) => me.errorCode);

export const errorMessageSelector = createSelector(boardRoot, (me) => me.errorMessage);

export const boardActivitySelector = createSelector(boardRoot, (me) => me.boardActivity);

export const boardBusinessSelector = createSelector(boardRoot, (me) => me.boardBusiness);

export const cardSelector = createSelector(boardRoot, (me) => me.card);

export const filtersSelector = createSelector(boardRoot, (me) => me.filters.entities);

export const listNamesSelector = createSelector(boardRoot, (me) => me.listNames);

export const showTagTextSelector = createSelector(boardRoot, (me) => me.showTagText);

export const quoteSelector = createSelector(boardRoot, (me) => me.quote);

export const boardSelector = createSelector(boardRoot, (me) => me.board);
