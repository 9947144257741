import QueryString from "query-string";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGoogleCalendarCore } from "../../../../cores/googleCalendar";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";

export default function GmailPopUp() {
  const { search } = useLocation();
  const { bind } = useGoogleCalendarCore();
  const { company, getMeUserSettings } = useMeCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    const { code } = QueryString.parse(search);
    if (code)
      bind(company!.id, code as string, () => {
        getMeUserSettings();
      });
    goToSocial();
  }, [search, company, bind, goToSocial, getMeUserSettings]);

  return <div></div>;
}
