import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactJson from "react-json-view";
import { Button, Container, Row } from "reactstrap";
import { Errors } from "../../../../constants/errors";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { SocialPost } from "../../../../cores/socialPost/interfaces/api/get-social-posts";
import { jsonString } from "../../../utils/stringUtils";
import { PanelWembii } from "../../PanelWembii";
import { SocialProviderIcon } from "../../socialPost/selector/SocialProviderIcon";
import { FacebookPostPreview } from "./FacebookPostPreview";
import { InstagramPostPreview } from "./InstagramPostPreview";
import { LinkedInPostPreview } from "./LinkedInPostPreview";
import { TwitterPostPreview } from "./TwitterPostPreview";
import { useIntl } from "react-intl";
import { FacebookStoryPreview } from "./FacebookStoryPreview";
import { InstagramStoryPreview } from "./InstagramStoryPreview";

interface Props {
  provider: string;
  post: SocialPost;
  showError: boolean;
}

export const SocialPostPreview = (props: Props) => {
  const intl = useIntl();
  const { provider, post, showError } = props;
  const { status, attempt, postAt } = post.post;
  const { company, isAdmin, socialSettings } = useMeCore();
  const { goToSocialPost, goToSocialStory } = useRouterCore();
  const {
    deleteSocialPost,
    selectPost,
    removeAllImages,
    removeVideo,
    reportSocialPost,
  } = useSocialPostCore();
  const [errorCollapsed, setErrorCollapsed] = useState(true);

  useEffect(() => {
    setErrorCollapsed(!showError);
  }, [showError]);

  const onDelete = useCallback(
    () => deleteSocialPost(company?.id!, post.post.id),
    [deleteSocialPost, company, post]
  );

  const toggleErrorCollapsed = useCallback(() => {
    setErrorCollapsed(!errorCollapsed)
  }, [errorCollapsed]);

  const onReport = useCallback(
    () => reportSocialPost(company?.id!, post.post.id),
    [reportSocialPost, company, post.post.id]
  );

  const onEdit = useCallback(() => {
    if (post.post.target === "post")
      goToSocialPost("history");
    else
      goToSocialStory("history");

    removeVideo();
    removeAllImages();
    selectPost(post);
  }, [post, removeAllImages, selectPost, removeVideo, goToSocialPost, goToSocialStory]);

  const showPreview = useMemo(() => {
    switch (provider) {
      case SocialProviderId.Facebook:
        return (
          post.post.target === "post"
            ? <FacebookPostPreview post={post} onEdit={onEdit} onDelete={onDelete} />
            : <FacebookStoryPreview post={post} onEdit={onEdit} onDelete={onDelete} />
        );
      case SocialProviderId.Twitter:
        return (
          <TwitterPostPreview post={post} onEdit={onEdit} onDelete={onDelete} />
        );
      case SocialProviderId.LinkedIn:
        return (
          <LinkedInPostPreview post={post} onEdit={onEdit} onDelete={onDelete} />
        );
      case SocialProviderId.LinkedInBusiness:
        return (
          <LinkedInPostPreview
            business
            post={post}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        );
      case SocialProviderId.Instagram:
        return (
          post.post.target === "post"
            ? <InstagramPostPreview post={post} onEdit={onEdit} onDelete={onDelete} />
            : <InstagramStoryPreview post={post} onEdit={onEdit} onDelete={onDelete} />
        );
      default:
        return null;
    }
  }, [provider, post, onEdit, onDelete]);

  const error = useMemo(() => {
    switch (post.post.errorCode) {
      case Errors.InvalidatedSession:
        return `${intl.formatMessage({ id: "app.socialManager.err1" })}`;
      case Errors.ExpiredToken:
        return `${intl.formatMessage({ id: "app.socialManager.err3" })}`;
      case Errors.IsDisconnected:
        return `${intl.formatMessage({ id: "app.socialManager.err4" })}`;
      case Errors.InvalidUserRole:
        return `${intl.formatMessage({ id: "app.socialManager.err5" })}`;
      case Errors.NotBusinessAccount:
        return `${intl.formatMessage({ id: "app.social.instagram.businessError" })}`;
      default:
        const er = jsonString(post.post.error)
        return (er.userMessage)
          ? er.userMessage
          : intl.formatMessage({ id: "app.socialManager.err2" })
    }
  }, [post, intl]);

  const metaLink = useMemo(() => 
    post.post.error.includes('error_subcode') &&
    (provider === SocialProviderId.Facebook || provider === SocialProviderId.Instagram)
  , [post, provider]);

  const wasOk = useMemo(
    () =>
      status === 1 || (postAt && status !== 2 && status !== 3 && status !== 4 && status !== 0),
    [postAt, status]
  );

  const wasSchedule = useMemo(() => status === 0, [status]);
  const wasReported = useMemo(() => status === 3, [status]);
  const wasReposted = useMemo(() => status === 4, [status]);

  const onRepost = useCallback(() => {
    goToSocialPost("history", true);
    removeVideo();
    removeAllImages();
    selectPost(post);
  }, [post, removeAllImages, selectPost, removeVideo, goToSocialPost]);

  const canRepost = useMemo(() => socialSettings![post.post.socialProvider], [socialSettings, post.post.socialProvider]);

  return (
    <PanelWembii className={cn("facebookPreview-container p-2 m-2", {
      "bg-dark": post.post.target === "story"
    })}>
      <Container fluid>
        <Row className="d-flex justify-content-center my-2 position-relative">
          <SocialProviderIcon
            socialProvider={{ id: provider }}
            isSelected
            showErrors={false}
          />
          <FontAwesomeIcon
            icon={[
              "fas",
              wasOk
                ? "check-circle"
                : wasSchedule
                ? "clock"
                : wasReported
                ? "bug"
                : wasReposted
                ? "redo"
                : "times-circle",
            ]}
            size="2x"
            style={{ position: "absolute", right: "10px" }}
            className={cn({
              "text-danger": !wasSchedule && !wasReported && !wasReposted && !wasOk,
              "text-success": wasOk,
              "text-warning": wasSchedule || wasReported || wasReposted,
            })}
          />
        </Row>
        {showPreview}
        {wasReported && (
          <Row className="d-flex justify-content-center px-2 my-1">
            <div className="wembii-border p-2 small mb-2 w-100">
              {intl.formatMessage({ id: "app.socialManager.checkingPost" })}
            </div> 
          </Row>
        )}
        {((!wasReported && attempt >= 3) || post.post.errorCode) && (
          <Row className="d-flex justify-content-center px-2 my-1">
            <div className="wembii-border p-3 small mb-2">
              {error}
            </div>
          </Row>
        )}
        {metaLink && (
          <div className="text-center">
            {intl.formatMessage({ id: "app.social.linkError" })} 
            <a href="https://developers.facebook.com/docs/instagram-api/reference/error-codes/" target="_blanc">
              link
            </a>
          </div>
        )}
        {post.post.error && post.post.status !== 0 && (
          <Row className="d-flex flex-column align-items-center px-2 my-1">
            <Button className="pointer m-1" color="success" onClick={toggleErrorCollapsed}>
              {errorCollapsed ? (
                <span>
                  {intl.formatMessage({ id: "app.socialManager.showError" })} <FontAwesomeIcon icon={["fas", "chevron-down"]} size="sm"/>
                </span> 
              ) : (
                <span>
                  {intl.formatMessage({ id: "app.socialManager.hideError" })} <FontAwesomeIcon icon={["fas", "chevron-up"]} size="sm"/>
                </span>
              )} 
            </Button>
            {!errorCollapsed && (
              <div className="wembii-border p-2 small mb-2 w-100">
                <ReactJson
                  name="error"
                  displayDataTypes={false}
                  src={jsonString(post.post.error)}
                />
              </div>
            )}
            <div className="d-flex justify-content-center mt-2">
              {attempt >= 3 && (
                <>
                  {canRepost && (
                    <Button color="primary" className="mx-1" onClick={onRepost}>
                      {intl.formatMessage({ id: "app.socialManager.repost" })}
                    </Button>
                  )}
                  {isAdmin ? (
                    <Button color="danger" className="mx-1" onClick={onReport} disabled={wasReported}>
                      {wasReported 
                        ? intl.formatMessage({ id: "app.socialManager.report" })
                        : intl.formatMessage({ id: "app.socialManager.report?" })
                      }
                    </Button>
                  ) : (
                    !wasReported && (
                      <Button color="danger" className="mx-1" onClick={onReport}>
                        {intl.formatMessage({ id: "app.socialManager.report?" })}
                      </Button>
                    )
                  )}
                </>
              )}
            </div>
          </Row>
        )}
      </Container>
    </PanelWembii>
  );
};
