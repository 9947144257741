import cn from "classnames";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import { v4 } from "uuid";
import { SEND_EMAIL_FORM } from "../../../constants/modal.constant";
import { Contact } from "../../../cores/contacts/interfaces/api/get-contacts";
import { useEmailCore } from "../../../cores/email";
import { EMAIL_CONFIG_RULES } from "../../../cores/email/config";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { useTemplateCore } from "../../../cores/templates";
import { beforeUploadFile } from "../../../cores/utils";
import { GmailIcon } from "../social/Gmail/GmailIcon";
import SendEmailForm from "./modals/SendEmailForm";
import { addSign } from "../../utils/signUtils";

interface Props {
  contacts: Contact[];
  classname?: string;
  cardId?: string | null;
  quote?: File | null;
  onSubmit?: () => void;
}

export default function GmailButton({
  contacts,
  classname,
  cardId = null,
  quote = null,
  onSubmit,
}: Props) {
  const intl = useIntl();
  const { turnOnModal } = useGlobalCore();
  const { company, getMeSigns, setSign } = useMeCore();
  const { setMessage, addFiles } = useEmailCore();
  const { getEmailTemplates } = useTemplateCore();

  const onClick = useCallback(async () => {
    if (quote) {
      const { name, size, lastModified, type } = quote;

      const error = !beforeUploadFile(quote, EMAIL_CONFIG_RULES.maxFilesSize, []);
      
      const id = v4();
      addFiles([{
        id,
        lastModified,
        name,
        size,
        type,
        data: quote,
        error,
      }]);
    }

    const meSigns = await getMeSigns();
    const defaultSign = meSigns ? meSigns.find((sign) => sign.default) : null;
    if (defaultSign) {
      setSign(defaultSign);
      setMessage(addSign('<p><br></p>', defaultSign)); 
    } else {
      setSign(null);
      setMessage("");
    }
    getEmailTemplates(company?.id!);
    turnOnModal(
      SEND_EMAIL_FORM,
      <SendEmailForm contacts={contacts} onSuccess={onSubmit} cardId={cardId} quote={quote} />
    );
  }, [
    turnOnModal,
    onSubmit,
    getMeSigns,
    setSign,
    getEmailTemplates,
    setMessage,
    addFiles,
    company,
    contacts,
    cardId,
    quote
  ]);

  return (
    <Button
      className={cn(classname, "my-2 d-flex align-items-center text-dark justify-content-center")}
      onClick={onClick}
      size="sm"
      style={{ backgroundColor: "#fff", width: 160 }}
    >
      <GmailIcon active className="mx-1" />
      {intl.formatMessage({ id: "app.sendEmail.sendEmail" })}
    </Button>
  );
}
