import React, { useCallback, useMemo } from "react";
import InactivityRow from "../InactivityRow/InactivityRow";
import { Board } from "../../../../cores/boards/interfaces/api/get-board";
import { Button, Col, Container, Row } from "reactstrap";
import { useBoardCore } from "../../../../cores/boards";
import { useIntl } from "react-intl";

import { PanelWembii } from "../../PanelWembii";
import { useGlobalCore } from "../../../../cores/globals";
import {
  BOARD_ROBOT_INACTIVITY,
  CREATE_AUTOMATION_INACTIVITY,
} from "../../../../constants/modal.constant";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useMeCore } from "../../../../cores/me";

const Inactivities: React.FC<{ board: Board }> = ({ board }) => {
  const { lists } = useBoardCore();
  const { company } = useMeCore();
  const intl = useIntl();
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const automatedLists = useMemo(
    () =>
      lists
        .filter((list) => list.inactivity?.notify)
        .map((list) => {
          return {
            listId: list.id,
            listName: list.name,
            inactivity: list.inactivity,
          };
        }),
    [lists]
  );
  const onClickCreateAutomation = useCallback(() => {
    turnOnModal(CREATE_AUTOMATION_INACTIVITY, true, Position.RIGHT);

    turnOffModal(BOARD_ROBOT_INACTIVITY);
  }, [turnOnModal, turnOffModal]);
  return (
    <Container className="h-100">
      <div className="d-flex flex-column p-4">
        <h2 className="h2 text-center mb-4">
          {intl.formatMessage({ id: "app.crmBusiness.inactivityTitle" })}
        </h2>
        <div className="d-flex justify-content-end">
          <Button
            className="whiteButton border  w-fit"
            type="button"
            onClick={onClickCreateAutomation}
          >
            {intl.formatMessage({
              id: "app.crmBusiness.addInactivityAutomation",
            })}
          </Button>
        </div>
      </div>

      {automatedLists.length === 0 ? (
        <>
          <p className="text-center my-auto ">
            {" "}
            {intl.formatMessage({ id: "app.noData" })}
          </p>
        </>
      ) : (
        <div className="container">
          <PanelWembii
            rounded="0"
            className="border-bottom w-100 px-4 py-3 my-2"
          >
            <Row>
              <Col xs="12" lg="7">
                <div className="d-flex align-items-center justify-content-center justify-content-lg-start w-100">
                  <span className="h6 mb-0">Lista</span>
                </div>
              </Col>
              <Col xs="12" lg="5">
                <div className="d-flex h-100 align-items-center justify-content-center justify-content-lg-end w-100">
                  <span className="h6 mb-0 text-center">Notificar</span>
                </div>
              </Col>
            </Row>
          </PanelWembii>
          {automatedLists.map(
            ({ inactivity, listName, listId }, index) =>
              inactivity && (
                <div key={index}>
                  <InactivityRow
                    companyId={company?.id!}
                    listId={listId}
                    boardId={board.id}
                    listName={listName}
                    inactivity={inactivity}
                  />
                </div>
              )
          )}
        </div>
      )}
    </Container>
  );
};

export default Inactivities;
