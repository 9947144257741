import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { configApiService } from "./services/api";
import { deleteCommentTypeFailureAction, deleteCommentTypeInitAction, deleteCommentTypeSuccessAction, getCommentTypesFailureAction, getCommentTypesInitAction, getCommentTypesSuccessAction, getConfigFailureAction, getConfigInitAction, getConfigSuccessAction, postCommentTypeFailureAction, postCommentTypeInitAction, postCommentTypeSuccessAction, putCommentTypeFailureAction, putCommentTypeInitAction, putCommentTypeSuccessAction, putConfigFailureAction, putConfigInitAction, putConfigSuccessAction } from "./store/api-actions";
import { commentTypesSelector, configSelector, freeTrialDaysSelector, pathSelector } from "./store/selector";
import { useIntl } from "react-intl";
import { setPathAction } from "./store/actions";

export function useConfigCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const config = useSelector(configSelector);
  const freeTrialDays = useSelector(freeTrialDaysSelector);
  const commentTypes = useSelector(commentTypesSelector)
  const path = useSelector(pathSelector);
  const getConfig = useCallback(
    async () => {
      try {
        dispatch(getConfigInitAction());
        const response = await configApiService.getConfig();
        dispatch(getConfigSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getConfigFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putConfig = useCallback(
    async (configId: string, value: string) => {
      try {
        dispatch(putConfigInitAction());
        const response = await configApiService.putConfig(configId, value);
        dispatch(putConfigSuccessAction(response));     
        toast.success(intl.formatMessage({ id: "app.toast.sucess2" }));
      } catch (error: any) {
        toast.error(intl.formatMessage({ id: "app.toast.error13" }));
        console.error(error);
        dispatch(putConfigFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );

  const getCommentTypes = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getCommentTypesInitAction());
        const { types } = await configApiService.getCommentTypes(companyId);
        dispatch(getCommentTypesSuccessAction({ types }));
      } catch (error: any) {
        console.error(error);
        dispatch(getCommentTypesFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCommentType = useCallback(
    async (
      companyId: string,
      name: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCommentTypeInitAction());
        const commentType = await configApiService.postCommentType(
          companyId,
          name
        );
        dispatch(postCommentTypeSuccessAction({ commentType }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postCommentTypeFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putCommentType = useCallback(
    async (
      companyId: string,
      id: string,
      name: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCommentTypeInitAction());
        const commentType = await configApiService.putCommentType(
          companyId,
          id,
          name
        );
        dispatch(putCommentTypeSuccessAction({ commentType }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putCommentTypeFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteCommentType = useCallback(
    async (companyId: string, id: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteCommentTypeInitAction());
        await configApiService.deleteCommentType(id, companyId);
        dispatch(deleteCommentTypeSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCommentTypeFailureAction({ error }));
        if (error.request.status === 403) {
          toast.error(intl.formatMessage({ id: "app.toast.error73" }));
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error72" }));
        }
      }
    },
    [dispatch, intl]
  );

  const setPath = useCallback(
    (path: string) => dispatch(setPathAction({ path })),
    [dispatch]
  );

  return {
    config,
    path,
    commentTypes,
    freeTrialDays,
    getConfig,
    putConfig,
    getCommentTypes,
    postCommentType,
    putCommentType,
    deleteCommentType,
    setPath,
  };
}
