import React from "react";
import { VIDEO_UPLOAD_LOADING } from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { SOCIAL_POST_TYPES } from "../../../../cores/socialPost/consts/socialPostType.constant";
import { Media } from "../../../../cores/socialPost/interfaces/api/get-social-posts";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";
import { ImagesCarousel } from "./ImagesCarousel";

interface Props {
  className?: string;
  type: string;
  media: Media[]; 
}

export const MediaPreview = (props: Props) => {
  const { className, media, type } = props;
  const { isLoading } = useGlobalCore();
  return (
    <div>
      {type === SOCIAL_POST_TYPES.IMAGE && (
        media.length === 1 ? (
          <img className={className} src={media[0].url} alt="alt" />
        ) : (
          <ImagesCarousel images={media} />
        )
      )}
      <LoadingSpinnerWembii active={isLoading(VIDEO_UPLOAD_LOADING)}>
        {type === SOCIAL_POST_TYPES.VIDEO && (
          <video className={className} controls>
            <source src={media[0].url} />
            Your browser does not support HTML5 video.
          </video>
        )}
      </LoadingSpinnerWembii>
    </div>
  );
};
