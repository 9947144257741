import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Errors } from "../../constants/errors";
import {
  INSTAGRAM_GET_LOADING,
  INSTAGRAM_POST_LOADING,
  INSTAGRAM_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { Media } from "../socialPost/interfaces/api/get-social-posts";
import { PostImage } from "../socialPost/interfaces/postImage";
import { PostVideo } from "../socialPost/interfaces/postVideo";
import { InstagramAccount } from "./interface/api/get-instagram-accounts";
import { PostInstagramBindCodeRequest } from "./interface/api/post-instagram-bind";
import { instagramApiService } from "./services/api";
import {
  getInstagramAccountsFailureAction,
  getInstagramAccountsInitAction,
  getInstagramAccountsSuccessAction,
  getInstagramCodeFailureAction,
  getInstagramCodeInitAction,
  getInstagramCodeSuccessAction,
  getInstagramFailureAction,
  getInstagramInitAction,
  getInstagramSuccessAction,
  postBindInstagramFailureAction,
  postBindInstagramInitAction,
  postBindInstagramSuccessAction,
  postInstagramCodeFailureAction,
  postInstagramCodeInitAction,
  postInstagramSocialPostFailureAction,
  postInstagramSocialPostInitAction,
  postInstagramSocialPostSuccessAction,
  postInstagramSocialStoryFailureAction,
  postInstagramSocialStoryInitAction,
  postInstagramSocialStorySuccessAction,
  postUnbindInstagramFailureAction,
  postUnbindInstagramInitAction,
  postUnbindInstagramSuccessAction,
  putInstagramSocialPostFailureAction,
  putInstagramSocialPostInitAction,
  putInstagramSocialPostSuccessAction,
  putInstagramSocialStoryFailureAction,
  putInstagramSocialStoryInitAction,
  putInstagramSocialStorySuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import {
  accountsSelector,
  errorCodeSelector,
  errorMessageSelector,
  settingSelector,
} from "./store/selector";
import { Mentions } from "../socialPost/interfaces/Mention";
import { cleanErrorsAction } from "./store/actions";

export function useInstagramCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const accounts = useSelector(accountsSelector);
  const errorCode = useSelector(errorCodeSelector);
  const errorMessage = useSelector(errorMessageSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindInstagramInitAction());
        await instagramApiService.unbind(companyId);
        dispatch(postUnbindInstagramSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindInstagramFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      account: InstagramAccount,
      password: string,
      token: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindInstagramInitAction());
        turnOnLoading(INSTAGRAM_SYNC_LOADING);
        const settings = await instagramApiService.bind(companyId, {
          userName: account.userName,
          password,
          token,
          accountId: account.id,
        });
        dispatch(postBindInstagramSuccessAction({ settings }));
        turnOffLoading(INSTAGRAM_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess12" }));
        onSuccess?.();
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string =
            JSON.parse(error.request.response).message ||
            "Ingrese el código enviado al telefono de verificación";
          switch (code) {
            case Errors.Checkpoint:
              dispatch(
                postBindInstagramFailureAction({
                  errorCode: Errors.Checkpoint,
                  errorMessage: message,
                })
              );
              break;
            case Errors["2FA"]:
              dispatch(
                postBindInstagramFailureAction({
                  errorCode: Errors["2FA"],
                  errorMessage: message,
                })
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error26" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error26" }));
          console.error(error);
        }
        turnOffLoading(INSTAGRAM_SYNC_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getInstagramInitAction());
        turnOnLoading(INSTAGRAM_GET_LOADING);
        const settings = await instagramApiService.get(companyId);
        dispatch(getInstagramSuccessAction({ settings }));
        turnOffLoading(INSTAGRAM_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(INSTAGRAM_GET_LOADING);
        console.error(error);
        dispatch(getInstagramFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getAccounts = useCallback(
    async (companyId: string, token: string) => {
      try {
        dispatch(getInstagramAccountsInitAction());
        turnOnLoading(INSTAGRAM_SYNC_LOADING);
        const accounts = await instagramApiService.accounts(companyId, token);
        dispatch(getInstagramAccountsSuccessAction({ accounts }));
        turnOffLoading(INSTAGRAM_SYNC_LOADING, 500);
      } catch (error: any) {
        turnOffLoading(INSTAGRAM_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error27" }));
        console.error(error);
        dispatch(getInstagramAccountsFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialPost = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postInstagramSocialPostInitAction());
        turnOnLoading(INSTAGRAM_POST_LOADING);
        await instagramApiService.socialPost(
          text,
          mentions,
          images,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postInstagramSocialPostSuccessAction());
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.success(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.sucess13" })
            : intl.formatMessage({ id: "app.toast.sucess14" })
        );
        onSuccess?.();
      } catch (error: any) {    
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.NotBusinessAccount:
              dispatch(
                postInstagramSocialPostFailureAction({
                  errorCode: Errors.NotBusinessAccount,
                  errorMessage: message,
                })
              );
              break;
          }
          toast.error(
            scheduleDate
              ? intl.formatMessage({ id: "app.toast.error29" })
              : intl.formatMessage({ id: "app.toast.error30" })
          );
          console.error(error, code);
        } else {
          toast.error(
            scheduleDate
              ? intl.formatMessage({ id: "app.toast.error29" })
              : intl.formatMessage({ id: "app.toast.error30" })
          );
          console.error(error);
        }
        turnOffLoading(INSTAGRAM_POST_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialPostVideo = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo,
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postInstagramSocialPostInitAction());
        turnOnLoading(INSTAGRAM_POST_LOADING);
        await instagramApiService.socialPostVideo(
          text,
          mentions,
          video,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postInstagramSocialPostSuccessAction());
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess13" })
          : intl.formatMessage({ id: "app.toast.sucess14" })
        );
        onSuccess?.();
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.NotBusinessAccount:
              dispatch(
                postInstagramSocialPostFailureAction({
                  errorCode: Errors.NotBusinessAccount,
                  errorMessage: message,
                })
              );
              break;
          }
          toast.error(
            scheduleDate
              ? intl.formatMessage({ id: "app.toast.error29" })
              : intl.formatMessage({ id: "app.toast.error30" })
          );
          console.error(error, code);
        } else {
          toast.error(
            scheduleDate
              ? intl.formatMessage({ id: "app.toast.error29" })
              : intl.formatMessage({ id: "app.toast.error30" })
          );
          console.error(error);
        }
        turnOffLoading(INSTAGRAM_POST_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialStory = useCallback(
    async (
      companyId: string,
      text: string,
      images: PostImage[],
      scheduleDate: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postInstagramSocialStoryInitAction());
        turnOnLoading(INSTAGRAM_POST_LOADING);
        await instagramApiService.socialStory(
          text,
          images,
          scheduleDate,
          companyId
        );
        dispatch(postInstagramSocialStorySuccessAction());
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess13" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error29" }));
        console.error(error);
        dispatch(postInstagramSocialStoryFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialStoryVideo = useCallback(
    async (
      companyId: string,
      text: string,
      video: PostVideo,
      scheduleDate: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postInstagramSocialStoryInitAction());
        turnOnLoading(INSTAGRAM_POST_LOADING);
        await instagramApiService.socialStoryVideo(
          text,
          video,
          scheduleDate,
          companyId
        );
        dispatch(postInstagramSocialStorySuccessAction());
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess13" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error29" }));
        console.error(error);
        dispatch(postInstagramSocialStoryFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const requestCode = useCallback(
    async (companyId: string, codeType: string) => {
      try {
        dispatch(getInstagramCodeInitAction());
        await instagramApiService.getCode(companyId, codeType);
        dispatch(getInstagramCodeSuccessAction());
      } catch (error: any) {
        console.error(error);
        dispatch(getInstagramCodeFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const sendCode = useCallback(
    async (companyId: string, data: PostInstagramBindCodeRequest) => {
      try {
        turnOnLoading(INSTAGRAM_SYNC_LOADING);
        dispatch(postInstagramCodeInitAction());
        const settings = await instagramApiService.postCode(companyId, data);
        dispatch(postBindInstagramSuccessAction({ settings }));
        turnOffLoading(INSTAGRAM_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess12" }));
      } catch (error: any) {
        console.error(error);
        dispatch(postInstagramCodeFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialPost = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putInstagramSocialPostInitAction());
        turnOnLoading(INSTAGRAM_POST_LOADING);
        await instagramApiService.putSocialPost(
          postId,
          text,
          mentions,
          images,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putInstagramSocialPostSuccessAction());
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess13" })
          : intl.formatMessage({ id: "app.toast.sucess14" })
        );
        onSuccess?.();
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.NotBusinessAccount:
              dispatch(
                putInstagramSocialPostFailureAction({
                  errorCode: Errors.NotBusinessAccount,
                  errorMessage: message,
                })
              );
              break;
          }
          toast.error(
            scheduleDate
              ? intl.formatMessage({ id: "app.toast.error29" })
              : intl.formatMessage({ id: "app.toast.error30" })
          );
          console.error(error, code);
        } else {
          toast.error(
            scheduleDate
              ? intl.formatMessage({ id: "app.toast.error29" })
              : intl.formatMessage({ id: "app.toast.error30" })
          );
          console.error(error);
        }
        turnOffLoading(INSTAGRAM_POST_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialPostVideo = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo | null,
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putInstagramSocialPostInitAction());
        turnOnLoading(INSTAGRAM_POST_LOADING);
        await instagramApiService.putSocialPostVideo(
          postId,
          text,
          mentions,
          video,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putInstagramSocialPostSuccessAction());
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess13" })
          : intl.formatMessage({ id: "app.toast.sucess14" })
        );
        onSuccess?.();
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.NotBusinessAccount:
              dispatch(
                putInstagramSocialPostFailureAction({
                  errorCode: Errors.NotBusinessAccount,
                  errorMessage: message,
                })
              );
              break;
          }
          toast.error(
            scheduleDate
              ? intl.formatMessage({ id: "app.toast.error29" })
              : intl.formatMessage({ id: "app.toast.error30" })
          );
          console.error(error, code);
        } else {
          toast.error(
            scheduleDate
              ? intl.formatMessage({ id: "app.toast.error29" })
              : intl.formatMessage({ id: "app.toast.error30" })
          );
          console.error(error);
        }
        turnOffLoading(INSTAGRAM_POST_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialStory = useCallback(
    async (
      companyId: string,
      storyId: string,
      text: string,
      images: PostImage[],
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putInstagramSocialStoryInitAction());
        turnOnLoading(INSTAGRAM_POST_LOADING);
        await instagramApiService.putSocialStory(
          storyId,
          text,
          images,
          scheduleDate,
          media,
          companyId
        );
        dispatch(putInstagramSocialStorySuccessAction());
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess13" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error29" }));
        console.error(error);
        dispatch(putInstagramSocialStoryFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialStoryVideo = useCallback(
    async (
      companyId: string,
      storyId: string,
      text: string,
      video: PostVideo | null,
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putInstagramSocialStoryInitAction());
        turnOnLoading(INSTAGRAM_POST_LOADING);
        await instagramApiService.putSocialStoryVideo(
          storyId,
          text,
          video,
          scheduleDate,
          media,
          companyId
        );
        dispatch(putInstagramSocialStorySuccessAction());
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess13" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(INSTAGRAM_POST_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error29" }));
        console.error(error);
        dispatch(putInstagramSocialStoryFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const cleanErrors = useCallback(() => dispatch(cleanErrorsAction()), [dispatch]);

  return {
    settings,
    errorCode,
    errorMessage,
    unbind,
    bind,
    get,
    socialPost,
    socialPostVideo,
    requestCode,
    sendCode,
    getAccounts,
    accounts,
    modifySocialPost,
    modifySocialPostVideo,
    socialStory,
    socialStoryVideo,
    modifySocialStory,
    modifySocialStoryVideo,
    cleanErrors,
  };
}
