import { Dictionary } from "lodash";

export const Permissions: Dictionary<{value: string, label: string}> = {
  owner: { value: "owner", label: "app.permission.owner" },
  admin: { value: "admin", label: "app.permission.admin" },
  agent: { value: "agent", label: "app.permission.agent" },
  social: { value: "social", label: "app.permission.socialMedia" },
  market: { value: "market", label: "app.permission.onlineSales" },
  activities: { value: "activities", label: "app.permission.activities" },
  crm: { value: "crm", label: "app.permission.crm" },
  reports: { value: "reports", label: "app.permission.reports" },
  forms: { value: "forms", label: "app.permission.forms" },
  chat: { value: "chat", label: "app.permission.chat" },
  analitycs: { value: "analitycs", label: "app.permission.metrics" },
  ads: { value: "ads", label: "app.permission.advertisements" },
  contacts: { value: "contacts", label: "app.permission.contacts" },
  automations: { value: "automations", label: "app.permission.automations" },
};
