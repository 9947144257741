import React from "react";
import SideModal from "../../layout/SideModal";
import {
  EMAIL_WARNING_MODAL,
  GMAIL_CONTACTS_MODAL,
  SEND_EMAIL_FORM,
  SIGN_EMAIL_FORM,
  TEMPLATE_EMAIL_FORM
} from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import SignEmailForm from "./modals/SignEmailForm";
import TemplateEmailForm from "./modals/TemplateEmailForm";

export default function EmailSideModals() {
  const {
    isModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
  } = useGlobalCore();

  return (
    <>
      <SideModal
        active={isModal(SEND_EMAIL_FORM)}
        visible={getModalVisibility(SEND_EMAIL_FORM)}
        onClose={() => turnOffModal(SEND_EMAIL_FORM)}
      >
        {getModalContent(SEND_EMAIL_FORM)}
      </SideModal>
      <SideModal
        active={isModal(EMAIL_WARNING_MODAL)}
        visible={getModalVisibility(EMAIL_WARNING_MODAL)}
        onClose={() => turnOffModal(EMAIL_WARNING_MODAL)}
      >
        {getModalContent(EMAIL_WARNING_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(SIGN_EMAIL_FORM)}
        visible={getModalVisibility(SIGN_EMAIL_FORM)}
        onClose={() => turnOffModal(SIGN_EMAIL_FORM)}
        left
      >
        <SignEmailForm />
      </SideModal>
      <SideModal
        active={isModal(TEMPLATE_EMAIL_FORM)}
        visible={getModalVisibility(TEMPLATE_EMAIL_FORM)}
        onClose={() => turnOffModal(TEMPLATE_EMAIL_FORM)}
        left
      >
        <TemplateEmailForm />
      </SideModal>
      <SideModal
        active={isModal(GMAIL_CONTACTS_MODAL)}
        visible={getModalVisibility(GMAIL_CONTACTS_MODAL)}
        onClose={() => turnOffModal(GMAIL_CONTACTS_MODAL)}
        left
      >
        {getModalContent(GMAIL_CONTACTS_MODAL)}
      </SideModal>
    </>
  );
}
