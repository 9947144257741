import cn from "classnames";
import React, { ReactNode } from "react";

interface Props {
  className?: string;
  children: ReactNode;
}

export default function Content(props: Props) {
  const { className = "", children } = props;
  return <div className={cn("w-100", className)}>{children}</div>;
}
