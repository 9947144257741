import React, { useCallback, useState } from "react";
import { Button, Container, Label } from "reactstrap";
import { useBoardCore } from "../../../../cores/boards";
import { InputWembii } from "../../InputWembii";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import {
  AUTOMATION_CONTACTS_MODAL,
  CONTACT_EXPAND_EDIT_FORM,
  CREATE_AUTOMATION_INACTIVITY,
  DELETE_AUTOMATION_ITEM_MODAL,
} from "../../../../constants/modal.constant";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useAutomationCore } from "../../../../cores/automations";
import { useGlobalCore } from "../../../../cores/globals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_DANGER } from "../../../../constants/theme";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import { truncateText } from "../../../utils/stringUtils";
import AreYouSure from "../../AreYouSure";
import EditContactForm from "../../contacts/modal/EditContactForm";
import { useContactCore } from "../../../../cores/contacts";
import { useConfigCore } from "../../../../cores/config/index";
import { LabelWembii } from "../../LabelWembii";
import { toast } from "react-toastify";
import { useCompanyCore } from "../../../../cores/company";
import UserList from "./UserList";
import { CompanyUser } from "../../../../cores/company/interfaces/api/get-company";

const CreateInactivity: React.FC<{ boardId: string }> = ({ boardId }) => {
  const intl = useIntl();
  const { automation, deleteContact, getAutomation } = useAutomationCore();
  const { company, user: me } = useMeCore();
  const { users } = useCompanyCore();
  const { lists, putList } = useBoardCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { getContactTags, getContact, getCustomFields } = useContactCore();
  const { getCommentTypes } = useConfigCore();
  const [daysToNotify, setDaysToNotify] = useState(0);
  const [mustCompleteList, setMustCompleteLists] = useState<boolean | null>(
    null
  );
  const [mustProvideValidDay, setMustProvideValidDay] = useState<
    boolean | null
  >(null);
  const [listId, setListId] = useState("");
  const itsMe = users.find((user) => user.id === me?.id);
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([itsMe!]);
  const openAssignmentUsers = useCallback(() => {
    turnOnModal(
      AUTOMATION_CONTACTS_MODAL,
      <UserList
        onDelete={(contact) => {
          const updatedArray = companyUsers.filter(
            (currentContact) => currentContact !== contact
          );
          setCompanyUsers(updatedArray);
          turnOffModal(AUTOMATION_CONTACTS_MODAL);
        }}
        assignments={companyUsers}
        onAssign={(contact) => {
          setCompanyUsers([...companyUsers, contact]);
          turnOffModal(AUTOMATION_CONTACTS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, companyUsers]);
  const getContactIds = useCallback(
    () => companyUsers.map((contact) => Number(contact.id)),
    [companyUsers]
  );
  const disabled = useCallback(
    () => !boardId || !listId || mustCompleteList || mustProvideValidDay,
    [boardId, listId, mustProvideValidDay, mustCompleteList]
  );

  const handleSubmit = async () => {
    if (!disabled()) {
      await putList(
        company?.id!,
        boardId,
        listId,
        {
          inactivity: {
            days: daysToNotify,
            notify: true,
            users: getContactIds(),
          },
        },
        () => {
          turnOffModal(CREATE_AUTOMATION_INACTIVITY);
          setMustCompleteLists(null);
          setMustProvideValidDay(null);
          setDaysToNotify(1);
          toast.success(intl.formatMessage({ id: "app.toast.sucess57" }));
        }
      );
    }
  };
  const onShowAssignment = useCallback(
    (contact) => {
      getContactTags(company?.id!);
      getCommentTypes(company?.id!);
      getCustomFields(company?.id!);
      getContact(company?.id!, contact.id);
      turnOnModal(
        CONTACT_EXPAND_EDIT_FORM,
        <EditContactForm
          isUnassignedButton={true}
          closeOnUpdating={false}
          onDelete={() => {
            turnOnModal(
              DELETE_AUTOMATION_ITEM_MODAL,
              <AreYouSure
                message={`Está seguro de querer desasignar el contacto ${contact.name}`}
                onSubmit={() => {
                  deleteContact(company?.id!, automation?.id!, contact.id);
                  turnOffModal(DELETE_AUTOMATION_ITEM_MODAL);
                  turnOffModal(CONTACT_EXPAND_EDIT_FORM);
                }}
                onClose={() => turnOffModal(DELETE_AUTOMATION_ITEM_MODAL)}
              />,
              Position.LEFT
            );
          }}
          onUpdate={() => {
            getAutomation(company?.id!, automation?.id!);
          }}
        />,
        Position.LEFT
      );
    },
    [
      turnOnModal,
      turnOffModal,
      deleteContact,
      getContactTags,
      getCustomFields,
      getContact,
      getCommentTypes,
      getAutomation,
      company,
      automation?.id,
    ]
  );
  return (
    <Container className="h-100" fluid>
      <div className="d-flex flex-column p-4">
        <h2 className="h2 text-center mb-4">
          {intl.formatMessage({ id: "app.crmBusiness.notifyInactivity" })}
        </h2>
      </div>

      <div className="d-flex flex-column align-items-start">
        <Label>
          {" "}
          {intl.formatMessage({ id: "app.crmBusiness.listToNotify" })}
        </Label>
        <InputWembii
          type="select"
          required
          valid={(!mustCompleteList as boolean) && mustCompleteList !== null}
          invalid={mustCompleteList as boolean}
          onChange={(listId: string) => {
            setListId(listId);
            if (listId !== "null") {
              setMustCompleteLists(false);
            } else {
              setMustCompleteLists(true);
            }
          }}
          className="w-100"
          value={listId}
        >
          <option value="null">
            {intl.formatMessage({ id: "app.selectOption" })}
          </option>
          {lists
            .filter((list) => !list.inactivity?.notify)
            .map((list, index) => (
              <option key={index} value={list.id}>
                {list.name}
              </option>
            ))}
        </InputWembii>
        {mustCompleteList && (
          <Label className="text-danger">
            Debe especificar una lista del tablero
          </Label>
        )}
      </div>
      <div>
        <div className="row">
          <div className="col d-flex w-100">
            <InputWembii
              type="number"
              name="quantity"
              label={intl.formatMessage({
                id: "app.automations.action.automaticEmail.sendEmail",
              })}
              value={parseInt(`${daysToNotify}`)}
              valid={
                (!mustProvideValidDay as boolean) &&
                mustProvideValidDay !== null
              }
              invalid={mustProvideValidDay as boolean}
              required
              className="pr-2 w-100"
              onChange={(days) => {
                if (days < 1 || (!days && days % 1 === 0) || days > 365) {
                  setMustProvideValidDay(true);
                } else {
                  setMustProvideValidDay(false);
                  setDaysToNotify(days);
                }
              }}
              min={1}
              max={365}
              minLength={1}
              maxLength={365}
            />
            <Label className="my-auto">Días</Label>
          </div>
          {mustProvideValidDay && (
            <Label className="text-danger">
              Ingrese un número valido de días.
            </Label>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center my-2">
        <LabelWembii>
          {intl.formatMessage({ id: "app.sidebar.users" })}
        </LabelWembii>
        <Button
          className="whiteButton border"
          type="button"
          onClick={openAssignmentUsers}
        >
          {intl.formatMessage({ id: "app.add" })}
        </Button>
      </div>

      <div className="d-flex flex-column ">
        <div className="d-flex my-4 flex-wrap justify-content-center border rounded">
          {companyUsers.length === 0 ? (
            <>
              <p className="text-center my-auto p-4">
                No hay contactos asignados
              </p>
            </>
          ) : (
            companyUsers.map((contact) => (
              <div
                key={contact.id}
                className="text-dark-50 m-4 p-2 font-weight-bold pointer m-1  border rounded-pill position-relative"
                onClick={() => onShowAssignment(contact)}
              >
                {contact.status !== UserStatus.ACTIVE.id && (
                  <FontAwesomeIcon
                    icon={["fas", "ban"]}
                    size="1x"
                    style={{
                      top: "-5px",
                      right: "-5px",
                    }}
                    className="position-absolute"
                    color={COLOR_DANGER}
                  />
                )}
                {truncateText(contact.firstName) ||
                  contact.email ||
                  contact.phoneNumber}
              </div>
            ))
          )}
        </div>
      </div>

      <Button
        onClick={() => handleSubmit()}
        className="border-0 mx-auto bg-primary  my-5 d-flex justify-content-between "
      >
        Finalizar configuración
      </Button>
    </Container>
  );
};

export default CreateInactivity;
