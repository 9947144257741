import {
  GetWebFormsResponse,
  WebFormsPayload,
} from "../interfaces/api/get-web-forms";
import {
  GetWebFormResponse,
  WebFormPayload,
} from "../interfaces/api/get-web-form";
import { PostWebFormResponse } from "../interfaces/api/post-web-form";
import { PutWebFormResponse } from "../interfaces/api/put-web-form";
import {
  FormNotificationPayload,
  PostFormNotificationResponse,
} from "../interfaces/api/post-form-notification";
import {
  PostFormUserResponse,
  FormUserPayload,
} from "../interfaces/api/post-form-user";
import {
  FormResponsePayload,
  GetWebFormResponseResponse,
} from "../interfaces/api/get-webform-response.response";

export const webFormMapper = {
  mapResponseToWebForm(
    response: PostWebFormResponse | PutWebFormResponse | GetWebFormResponse
  ): WebFormPayload {
    const { form, inputs, notifications, users } = response?.payload;
    return {
      form,
      inputs,
      notifications,
      users,
    };
  },
  mapResponseToWebForms(response: GetWebFormsResponse): WebFormsPayload {
    const forms = response?.payload?.forms;
    return { forms };
  },
  mapResponseToWebFormResponse({
    payload,
  }: GetWebFormResponseResponse): FormResponsePayload[] {
    return payload;
  },
  mapResponseToFormNotification(
    response: PostFormNotificationResponse
  ): FormNotificationPayload {
    const notification = response?.payload?.notification;
    return { notification };
  },
  mapResponseToFormUser(response: PostFormUserResponse): FormUserPayload {
    const user = response?.payload?.user;
    return { user };
  },
};
