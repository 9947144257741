import { DeleteAssignmentResponse } from "../interfaces/api/delete-assignment";
import { DeleteBoardResponse } from "../interfaces/api/delete-board";
import { DeleteCardResponse } from "../interfaces/api/delete-card";
import { DeleteCommentPayload, DeleteCommentResponse } from "../interfaces/api/delete-comment";
import { DeleteContactResponse } from "../interfaces/api/delete-contact";
import { BoardPayload } from "../interfaces/api/get-board";
import { GetBoardsResponse, BoardsPayload } from "../interfaces/api/get-boards";
import { GetCardResponse } from "../interfaces/api/get-card";
import { GetListPayload, GetListsResponse } from "../interfaces/api/get-lists";
import { GetCardTagsPayload, GetCardTagsResponse } from "../interfaces/api/get-tags";
import { PostAssignmentResponse } from "../interfaces/api/post-assignment";
import { PostBoardResponse } from "../interfaces/api/post-board";
import { CardPayload, PostCardResponse } from "../interfaces/api/post-card";
import { CommentPayload, PostCommentResponse } from "../interfaces/api/post-comment";
import { PostContactResponse } from "../interfaces/api/post-contact";
import { FilesPayload, PostFilesResponse } from "../interfaces/api/post-files";
import { PostListPayload, PostListResponse } from "../interfaces/api/post-list";
import { CardTagPayload, PostCardTagResponse } from "../interfaces/api/post-card-tag";
import { PutBoardResponse } from "../interfaces/api/put-board";
import { PutCardResponse } from "../interfaces/api/put-card";
import { PutCardOrderResponse } from "../interfaces/api/put-card-order";
import { PutCardTagResponse } from "../interfaces/api/put-card-tag";
import { GetListCardsPayload, GetListCardsResponse } from "../interfaces/api/get-list-cards";
import { GetListBudgetsPayload, GetListBudgetsResponse } from "../interfaces/api/get-lists-budget";
import { PostCompanyResponse } from "../interfaces/api/post-company";
import { DeleteCompanyResponse } from "../interfaces/api/delete-company";
import { PostProductResponse } from "../interfaces/api/post-product";
import { PutProductResponse } from "../interfaces/api/put-product";
import { PostQuoteResponse } from "../interfaces/api/post-quote";
import { GetExportPayload, GetExportResponse } from "../interfaces/api/get-export";
import { GetCustomFieldsPayload, GetCustomFieldsResponse } from "../interfaces/api/get-custom-fields";
import { PostCustomFieldPayload, PostCustomFieldResponse } from "../interfaces/api/post-custom-field";
import { PutCustomFieldResponse } from "../interfaces/api/put-custom-field";

export const boardMapper = {
  mapResponseToId(
    response: DeleteCommentResponse | DeleteBoardResponse | DeleteCardResponse
  ): DeleteCommentPayload {
    const id = response?.payload?.id
    return { id };
  },
  mapResponseToBoards(response: GetBoardsResponse): BoardsPayload {
    const boards = response?.payload?.boards
    return { boards };
  },
  mapResponseToBoard(response: PostBoardResponse | PutBoardResponse): BoardPayload {
    const board = response?.payload?.board
    return { board };
  },
  mapResponseToLists(response: GetListsResponse): GetListPayload {
    const lists = response?.payload?.lists
    return { lists };
  },
  mapResponseToList(response: PostListResponse): PostListPayload {
    const list = response?.payload?.list
    return { list };
  },
  mapResponseToCard(
    response: PostCardResponse | GetCardResponse | PutCardResponse
  ): CardPayload {
    const card = response?.payload?.card
    return { card };
  },
  mapResponseToCards(response: GetListCardsResponse): GetListCardsPayload {
    const cards = response?.payload?.cards
    return { cards };
  },
  mapResponseToCardsExport(response: GetExportResponse): GetExportPayload {
    const cards = response?.payload?.cards
    return { cards };
  },
  mapResponseToBudgets(response: GetListBudgetsResponse): GetListBudgetsPayload {
    const { totalBudget, totalProbability } = response?.payload;
    return { totalBudget, totalProbability };
  },
  mapResponseToAssignments(
    response: PostAssignmentResponse | DeleteAssignmentResponse
  ) {
    const assignments = response?.payload?.assignments
    return { assignments };
  },
  mapResponseToCardPosition(
    response: PutCardOrderResponse
  ) {
    const position = response?.payload
    return position;
  },
  mapResponseToContacts(
    response: PostContactResponse | DeleteContactResponse
  ) {
    const contacts = response?.payload?.contacts
    return { contacts };
  },
  mapResponseToCompanies(
    response: PostCompanyResponse | DeleteCompanyResponse
  ) {
    const companies = response?.payload?.companies
    return { companies };
  },
  mapResponseToComment(response: PostCommentResponse): CommentPayload {
    const comment = response?.payload?.comment
    return { comment };
  },
  mapResponseToFiles(response: PostFilesResponse): FilesPayload {
    const files = response?.payload?.files
    return { files };
  },
  mapResponseToCardTags(response: GetCardTagsResponse): GetCardTagsPayload {
    const tags = response?.payload?.tags
    return { tags };
  },
  mapResponseToCardTag(response: PostCardTagResponse | PutCardTagResponse): CardTagPayload {
    const tag = response?.payload?.tag
    return { tag };
  },
  mapResponseToProduct(
    response: PostProductResponse | PutProductResponse
  ) {
    const product = response?.payload?.product
    return { product };
  },
  mapResponseToQuote(
    response: PostQuoteResponse
  ) {
    const quote = response?.payload?.quote
    return { quote };
  },
  mapResponseToCustomFields(response: GetCustomFieldsResponse): GetCustomFieldsPayload {
    const { fields } = response?.payload;
    return { fields };
  },
  mapResponseToCustomField(response: PostCustomFieldResponse | PutCustomFieldResponse): PostCustomFieldPayload {
    const { field } = response?.payload;
    return { field };
  },
};
