import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Label } from "reactstrap";
import { Errors } from "../../../../constants/errors";
import { MAILCHIMP_AUDIENCE_LOADING } from "../../../../constants/loading.constant";
import { MAILCHIMP_AUDIENCES_SIDE_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMailchimpCore } from "../../../../cores/mailchimp";
import { Audience } from "../../../../cores/mailchimp/interface/api/get-mailchimp-audiences";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";

interface Props {
  token: string;
  prefix: string;
}

export default function MailchimpAudiences(props: Props) {
  const { token, prefix } = props;
  const { audiences, errorCode, postAudience } = useMailchimpCore();
  const { company } = useMeCore();
  const [name, setName] = useState("");

  const handleCreate = useCallback(async () => {
    postAudience(company?.id!, name, token, prefix);
  }, [company, token, prefix, postAudience, name]);

  const disabled = useCallback(() => 
    !name
  , [name]);

  const error = useMemo(() => {
    switch (errorCode) {
      case Errors.NewAudienceNotAllowed:
        return (
          <Label className="text-muted text-center my-4">
            No se pudo crear la nueva audiencia. Esto puede deberse a que su cuenta ya posee la cantidad máxima de audiencias para su plan
          </Label>
        );
    }
  }, [errorCode]);

  return (
    <div className="p-4 overflow-auto">
      <h2 className="h2 text-center mb-4">Elige una audiencia</h2>
      {audiences.map((audience) => (
        <MailchimpAudience key={audience.id} audience={audience} token={token} prefix={prefix} />
      ))}
      <hr />
      <div className="align-items-center d-flex p-2 pointer">
        <InputWembii
          type="text"
          label="Nueva Audiencia"
          placeholder="Nombre"
          required
          flat
          onChange={(v) => setName(v)}
        />
        <Button
          className="ml-auto"
          color="primary"
          onClick={handleCreate}
          disabled={disabled()}
        >
          Crear
        </Button>
      </div>
      {errorCode && error}
      <UpdateLoadingCard
        loadingId={MAILCHIMP_AUDIENCE_LOADING}
        text="Creando audiencia"
      />
    </div>
  );
}

const MailchimpAudience = ({ audience, token, prefix }: {
  audience: Audience;
  token: string;
  prefix: string;
}) => {
  const { id, name } = audience;
  const { bind } = useMailchimpCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { turnOffModal } = useGlobalCore();
  const intl = useIntl();

  const handleBind = useCallback(async () => {
    await bind(company!.id, token, prefix, id, () => {
      getMeSocialSettings(company?.id!);
    });
    turnOffModal(MAILCHIMP_AUDIENCES_SIDE_MODAL);
  }, [bind, company, token, prefix, id, turnOffModal, getMeSocialSettings]);

  return (
    <div className="align-items-center d-flex p-2 neu-in pointer">
      <span className="h5 ml-3">{name}</span>
      <Button className="ml-auto" color="primary" onClick={handleBind}>
        {intl.formatMessage({id : "app.sincronyze"})}
      </Button>
    </div>
  );
};
