import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import { WOO_COMMERCE_ORDER_LOADING } from "../../constants/loading.constant";
import { WOO_COMMERCE_ORDER_EXPAND } from "../../constants/modal.constant";
import { useAnalyticsCore } from "../../cores/analytics";
import { ChartMetric } from "../../cores/analytics/interface/ChartMetric";
import { Metric2 } from "../../cores/analytics/interface/Metric2";
import { useGlobalCore } from "../../cores/globals";
import { useMarketCore } from "../../cores/market";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { ChartContainer } from "../component/analytics/ChartContainer";
import { BarChart } from "../component/analytics/charts/BarChart";
import { WooCommerceOrderBar } from "../component/market/wooCommerce/WooCommerceBar";
import { WooCommerceOrderComponent } from "../component/market/wooCommerce/WooCommerceOrder";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";

export const WooCommerceMarket = () => {
  const intl = useIntl();
  const { wooCommerceOrders } = useMarketCore();
  const {
    company,
    socialSettings,
    companyPlan,
    companyRole,
    isAdmin,
    isReseller
  } = useMeCore();
  const { isModal, turnOffModal, getModalContent, getModalVisibility } = useGlobalCore();
  const { wooCommerceInsights, getWooCommerceInsights, toChartMetric } =
    useAnalyticsCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    if (
      !socialSettings?.wooCommerce || !companyPlan?.rules.allowMarket || (
        !companyRole?.market &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial, socialSettings]);

  const dates = useMemo(
    () =>
      wooCommerceOrders.map(({ creationDate }) => moment(creationDate).unix()),
    [wooCommerceOrders]
  );
  const maxDate = useMemo(
    () => moment(Math.max.apply(null, dates) * 1000).format(),
    [dates]
  );
  const minDate = useMemo(
    () => moment(Math.min.apply(null, dates) * 1000).format(),
    [dates]
  );

  useEffect(() => {
    dates.length && minDate && maxDate &&
      getWooCommerceInsights(company!.id, minDate, maxDate);
  }, [company, dates, getWooCommerceInsights, maxDate, minDate]);

  const orderData: Metric2[] = useMemo(
    () => [
      {
        ...wooCommerceInsights.woo_orders,
        type: "line",
      },
    ],
    [wooCommerceInsights]
  );

  const ordersChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(orderData),
    [orderData, toChartMetric]
  );

  return (
    <Layout>
      <Header2 title={intl.formatMessage({ id: "app.wooCommerce.title" })} />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12" >
              <ChartContainer
                height={120}
                className="my-0 p-0"
                title=""
                isEmptyData={dates.length === 0}
              >
                <BarChart
                  height={50}
                  labels={ordersChartMetrics.labels}
                  datasets={ordersChartMetrics.datasets}
                />
              </ChartContainer>
            </Col>
            <Col xs="12">
              <WooCommerceOrderBar />
              <UpdateLoadingCard
                loadingId={WOO_COMMERCE_ORDER_LOADING}
                text={intl.formatMessage({ id: "app.wooCommerce.updating" })} 
              />
              <Container>
                {wooCommerceOrders.map((order) => (
                  <WooCommerceOrderComponent order={order} key={order.id} />
                ))}
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(WOO_COMMERCE_ORDER_EXPAND)}
        visible={getModalVisibility(WOO_COMMERCE_ORDER_EXPAND)}
        onClose={() => turnOffModal(WOO_COMMERCE_ORDER_EXPAND)}
        left
      >
        {getModalContent(WOO_COMMERCE_ORDER_EXPAND)}
      </SideModal>
    </Layout>
  );
};
