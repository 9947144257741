import React from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { FormLead } from "../../../../cores/facebookForm/interfaces/api/get-facebook-form";
import { useFacebookFormCore } from "../../../../cores/facebookForm";
import { View } from "../../../../cores/facebookForm/constants/view";
import cn from "classnames";

interface Props {
  facebookFormLead: FormLead;
  questions: string[];
}

export default function LeadCard(props: Props) {
  const { facebookFormLead, questions } = props;
  const { view } = useFacebookFormCore();

  return (
    <Card
      key={facebookFormLead.id}
      className="neu-out align-content-center border-0 d-flex justify-content-center p-3"
    >
      <CardBody className="p-2 d-flex flex-column">
        <Row>
          <Col
            md={view === View.GRID ? '12' : '8'}
            xs="12"
            className={cn('d-flex flex-column', {
              'text-center': view === View.GRID,
            })}
          >
            {facebookFormLead.data.map((data, index) => (
              <Label key={data}>
                {view === View.LIST && (
                  <span className="text-muted">{questions[index]}: </span>
                )}
                {data}
              </Label>
            ))}
          </Col>
          <Col
            md={view === View.GRID ? '12' : '4'}
            xs="12"
            className={cn({
              'text-center': view === View.GRID,
              'text-right': view === View.LIST,
            })}
          >
            { facebookFormLead.createdTime }
          </Col>
        </Row>        
      </CardBody>
    </Card>
  );
}
