import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useAutomationCore } from "../../../../cores/automations";
import { AutomationTriggerOptions } from "../../../../cores/automations/config";
import { LabelWembii } from "../../LabelWembii";
import ConditionForm from "../creator/ConditionForm";
import ActionForm from "../creator/ActionForm";
import Condition from "../creator/Condition";
import {
  AutomationAction,
  AutomationCondition,
  AutomationUser,
} from "../../../../cores/automations/interfaces/api/get-automation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMeCore } from "../../../../cores/me";
import {
  AUTOMATION_CONTACTS_MODAL,
  AUTOMATION_EDIT_FORM,
  BOARD_USERS_MODAL,
  CONTACT_EXPAND_EDIT_FORM,
  DELETE_AUTOMATION_ITEM_MODAL,
  DELETE_AUTOMATION_MODAL,
} from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import AreYouSure from "../../AreYouSure";
import { useEmailCore } from "../../../../cores/email";
import { useTemplateCore } from "../../../../cores/templates";
import ActionContainer from "../creator/ActionContainer";
import InternalModal from "../../../layout/InternalModal";
import {
  AUTOMATION_ACTION,
  AUTOMATION_CONDITION,
} from "../../../../constants/internalModal.constant";
import { Action } from "../../../../cores/automations/enum/Action.enum";
import { AutomaticEmailActionBody } from "../../../../cores/automations/interfaces/AutomaticEmailAction";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { AutomationTrigger } from "../../../../cores/automations/enum/AutomationTrigger.enum";
import ContactList from "../../boards/modal/ContactList";
import { COLOR_DANGER } from "../../../../constants/theme";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import { truncateText } from "../../../utils/stringUtils";
import EditContactForm from "../../contacts/modal/EditContactForm";
import { useContactCore } from "../../../../cores/contacts";
import { useConfigCore } from "../../../../cores/config";
import UserList from "../../boards/modal/UserList";
import { User } from "../../../../cores/session/interfaces/models/user";

export default function EditAutomationForm() {
  const intl = useIntl();
  const {
    automation,
    conditions,
    actions,
    contacts,
    users,
    deleteAutomation,
    postContact,
    deleteContact,
    getAutomation,
    deleteUser,
  } = useAutomationCore();
  const { company, getMeSigns } = useMeCore();
  const { getCommentTypes } = useConfigCore();
  const {
    turnOnModal,
    turnOffModal,
    isInternalModal,
    turnOffInternalModal,
    turnOnInternalModal,
  } = useGlobalCore();
  const { setMessage } = useEmailCore();
  const { getContactTags, getContact, getCustomFields } = useContactCore();
  const { postUser } = useAutomationCore();
  const { getEmailTemplates } = useTemplateCore();
  const [condition, setCondition] = useState<AutomationCondition | null>(null);
  const [action, setAction] = useState<AutomationAction | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<AutomationUser[]>(users);
  const [assignments, setAssignments] = useState<User[]>([]);

  useEffect(() => {
    setCondition(null);
    setAction(null);
  }, []);

  const onCreateCondition = useCallback(() => {
    setCondition(null);
    turnOnInternalModal(AUTOMATION_CONDITION, true);
  }, [turnOnInternalModal]);

  const onEditCondition = useCallback(
    (condition: AutomationCondition) => {
      setCondition(condition);
      turnOnInternalModal(AUTOMATION_CONDITION, true);
    },
    [turnOnInternalModal]
  );

  const onEditAction = useCallback(
    (action: AutomationAction) => {
      setMessage("");
      getMeSigns();
      getEmailTemplates(company?.id!);
      setAction(action);
      turnOnInternalModal(AUTOMATION_ACTION, true);
    },
    [getEmailTemplates, getMeSigns, setMessage, turnOnInternalModal, company]
  );

  const onCreateAction = useCallback(() => {
    setMessage("");
    getMeSigns();
    setAction(null);
    getEmailTemplates(company?.id!);
    turnOnInternalModal(AUTOMATION_ACTION, true);
  }, [getEmailTemplates, getMeSigns, setMessage, turnOnInternalModal, company]);

  const onDelete = useCallback(() => {
    turnOnModal(
      DELETE_AUTOMATION_MODAL,
      <AreYouSure
        message={`${intl.formatMessage({ id: "app.automations.delete?" })}`}
        onSubmit={() => {
          deleteAutomation(company?.id!, automation?.id!, () => {
            turnOffModal(AUTOMATION_EDIT_FORM);
          });
          turnOffModal(DELETE_AUTOMATION_MODAL);
        }}
        onClose={() => turnOffModal(DELETE_AUTOMATION_MODAL)}
      />,
      Position.LEFT
    );
  }, [company, automation, intl, deleteAutomation, turnOffModal, turnOnModal]);

  const trigger = useMemo(
    () =>
      AutomationTriggerOptions.find(
        (option) => option.value === automation?.trigger
      ),
    [automation]
  );

  const actionsOrdered = useMemo(() => {
    const newOpportunityAction = actions.filter(
      (action) => action.type === Action.NEW_OPPORTUNITY
    );
    const cascadeAssignmentAction = actions.filter(
      (action) => action.type === Action.CASCADE_ASSIGNMENT
    );
    let automaticEmailAction = actions.filter(
      (action) => action.type === Action.AUTOMATIC_EMAIL
    );
    automaticEmailAction = automaticEmailAction
      .sort(
        (actionA, actionB) =>
          (actionA.body as AutomaticEmailActionBody).days -
          (actionB.body as AutomaticEmailActionBody).days
      )
      .sort((actionA, actionB) =>
        (actionA.body as AutomaticEmailActionBody).days ===
        (actionB.body as AutomaticEmailActionBody).days
          ? (actionA.body as AutomaticEmailActionBody).time >
            (actionB.body as AutomaticEmailActionBody).time
            ? 1
            : -1
          : 1
      );

    return [
      ...newOpportunityAction,
      ...cascadeAssignmentAction,
      ...automaticEmailAction,
    ];
  }, [actions]);

  const conditionLbl = () => {
    switch (automation?.trigger) {
      case AutomationTrigger.CONTACT:
        return intl.formatMessage({
          id: "app.automations.automation.contactSelection",
        });
      case AutomationTrigger.ON_ASSIGN_OWNER:
        return intl.formatMessage({
          id: "app.automations.automation.ownerSelection",
        });
      case AutomationTrigger.NEW_CONTACT:
        return intl.formatMessage({
          id: "app.automations.automation.contactCreation",
        });
    }
  };

  const openAssignmentContacts = useCallback(() => {
    turnOnModal(
      AUTOMATION_CONTACTS_MODAL,
      <ContactList
        assignments={contacts}
        onAssign={(contact) => {
          postContact(company?.id!, automation?.id!, { contactId: contact.id });
          turnOffModal(AUTOMATION_CONTACTS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, postContact, company, contacts, automation]);

  const onShowAssignment = useCallback(
    (contact) => {
      getContactTags(company?.id!);
      getCommentTypes(company?.id!);
      getCustomFields(company?.id!);
      getContact(company?.id!, contact.id);
      turnOnModal(
        CONTACT_EXPAND_EDIT_FORM,
        <EditContactForm
          isUnassignedButton={true}
          closeOnUpdating={false}
          onDelete={() => {
            turnOnModal(
              DELETE_AUTOMATION_ITEM_MODAL,
              <AreYouSure
                message={`Está seguro de querer desasignar el contacto ${contact.name}`}
                onSubmit={() => {
                  deleteContact(company?.id!, automation?.id!, contact.id);
                  turnOffModal(DELETE_AUTOMATION_ITEM_MODAL);
                  turnOffModal(CONTACT_EXPAND_EDIT_FORM);
                }}
                onClose={() => turnOffModal(DELETE_AUTOMATION_ITEM_MODAL)}
              />,
              Position.LEFT
            );
          }}
          onUpdate={() => {
            getAutomation(company?.id!, automation?.id!);
          }}
        />,
        Position.LEFT
      );
    },
    [
      turnOnModal,
      turnOffModal,
      deleteContact,
      getContactTags,
      getCustomFields,
      getContact,
      getCommentTypes,
      getAutomation,
      company,
      automation,
    ]
  );

  const openAssignmentUsers = useCallback(() => {
    turnOnModal(
      BOARD_USERS_MODAL,
      <UserList
        assignments={selectedUsers}
        onAssign={(user) => {
          postUser(
            company?.id!,
            automation?.id!,
            {
              owner: { userId: user.id },
            },
            () => {
              setSelectedUsers((prevUsers) => [
                ...prevUsers,
                {
                  id: user.id,
                  name: `${user.firstName} ${user.lastName}`,
                },
              ]);
              setAssignments([...assignments, user]);
            }
          );

          turnOffModal(BOARD_USERS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [
    company?.id,
    turnOnModal,
    turnOffModal,
    setSelectedUsers,
    postUser,
    automation?.id,
    selectedUsers,
  ]);

  const conditionBtn = () => {
    switch (automation?.trigger) {
      case AutomationTrigger.CONTACT:
        return (
          <Button
            className="whiteButton border"
            type="button"
            onClick={openAssignmentContacts}
          >
            {intl.formatMessage({
              id: "app.automations.automation.addContacts",
            })}
          </Button>
        );
      case AutomationTrigger.ON_ASSIGN_OWNER:
        return (
          <Button
            className="whiteButton border"
            type="button"
            onClick={openAssignmentUsers}
          >
            {intl.formatMessage({
              id: "app.automations.automation.addUsers",
            })}
          </Button>
        );
      case AutomationTrigger.NEW_CONTACT:
        return (
          <Button
            className="whiteButton border"
            type="button"
            onClick={onCreateCondition}
          >
            {intl.formatMessage({
              id: "app.automations.automation.addConditions",
            })}
          </Button>
        );
    }
  };
  const onDeleteAssignment = useCallback(
    (removeUser) => {
      setSelectedUsers(
        selectedUsers.filter((user) => user.id !== removeUser.id)
      );
      setAssignments(assignments.filter((user) => user.id !== removeUser.id));
      deleteUser(company?.id!, automation?.id!, removeUser.id);
    },
    [selectedUsers, deleteUser]
  );

  const conditionsInfo = () => {
    switch (automation?.trigger) {
      case AutomationTrigger.CONTACT:
        return (
          <div className="d-flex flex-wrap justify-content-center">
            {contacts.map((contact) => (
              <div
                key={contact.id}
                className="text-dark-50 font-weight-bold pointer m-1 px-2 border rounded-pill position-relative"
                onClick={() => onShowAssignment(contact)}
              >
                {contact.status !== UserStatus.ACTIVE.id && (
                  <FontAwesomeIcon
                    icon={["fas", "ban"]}
                    size="1x"
                    style={{
                      top: "-5px",
                      right: "-5px",
                    }}
                    className="position-absolute"
                    color={COLOR_DANGER}
                  />
                )}
                {truncateText(contact.name) ||
                  contact.email ||
                  contact.phoneNumber}
              </div>
            ))}
          </div>
        );

      case AutomationTrigger.NEW_CONTACT:
        return conditions.map((condition, index) => (
          <Condition
            key={condition.id}
            condition={condition}
            isFirst={!index}
            onClick={() => onEditCondition(condition)}
          />
        ));
      case AutomationTrigger.ON_ASSIGN_OWNER:
        return (
          <div className="d-flex flex-wrap justify-content-center ">
            {selectedUsers.map((user) => (
              <div
                key={user.id}
                className="text-dark-50 font-weight-bold pointer m-1 px-2 border rounded-pill position-relative"
                onClick={() => onDeleteAssignment(user)}
              >
                {truncateText(user.name)}
              </div>
            ))}
          </div>
        );
    }
  };
  const renderAssignLabel = () => {
    switch (automation?.trigger) {
      case AutomationTrigger.NEW_CONTACT:
        return intl.formatMessage({
          id: "app.automations.automation.conditions",
        });
      case AutomationTrigger.ON_ASSIGN_OWNER:
        return intl.formatMessage({
          id: "app.automations.automation.users",
        });
      default:
        return intl.formatMessage({ id: "app.opportunity.contacts" });
    }
  };

  const renderInfo = () => {
    if (conditions.length === 0) {
      if (contacts.length === 0 && selectedUsers.length === 0) {
        return (
          <div
            className="d-flex align-items-center justify-content-center text-muted border rounded"
            style={{ height: 150 }}
          >
            {conditionLbl()}
          </div>
        );
      }
    }
    return (
      <div
        className="d-flex align-items-center justify-content-center text-muted border rounded"
        style={{ height: 150 }}
      >
        {conditionsInfo()}
      </div>
    );
  };
  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(AUTOMATION_CONDITION)}
        onClose={() => turnOffInternalModal(AUTOMATION_CONDITION)}
      >
        <ConditionForm
          condition={condition}
          isFirst={
            conditions.length === 0 || condition?.id === conditions[0].id
          }
        />
      </InternalModal>
      <InternalModal
        active={isInternalModal(AUTOMATION_ACTION)}
        onClose={() => turnOffInternalModal(AUTOMATION_ACTION)}
      >
        <ActionForm
          action={action}
          preloadData={{
            assignments,
          }}
          trigger={automation?.trigger!}
        />
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 text-center">{automation?.name}</h2>
              {trigger && (
                <div className="d-flex justify-content-center align-items-center mb-2">
                  <Badge color="success">
                    {intl.formatMessage({
                      id: "app.automations.automation.trigger",
                    })}
                  </Badge>
                  <h5 className="h5 text-center text-muted mb-0 ml-2">
                    {intl.formatMessage({ id: trigger.label })}
                  </h5>
                </div>
              )}
              <div className="text-center">
                <Badge
                  color="danger"
                  className="text-white pointer"
                  onClick={onDelete}
                >
                  <FontAwesomeIcon
                    icon={["fas", "trash"]}
                    size="1x"
                    className="mr-1"
                  />
                  {intl.formatMessage({
                    id: "app.automations.automation.delete",
                  })}
                </Badge>
              </div>
              <>
                <div className="d-flex justify-content-between align-items-center my-2">
                  <LabelWembii>{renderAssignLabel()}</LabelWembii>
                  {conditionBtn()}
                </div>

                {renderInfo()}
              </>
              <div className="d-flex justify-content-between align-items-center my-2">
                <LabelWembii>
                  {intl.formatMessage({
                    id: "app.automations.automation.actions",
                  })}
                </LabelWembii>
                <Button
                  className="whiteButton border"
                  type="button"
                  onClick={onCreateAction}
                >
                  {intl.formatMessage({
                    id: "app.automations.automation.addActions",
                  })}
                </Button>
              </div>
              {actions.length === 0 && (
                <div
                  className="d-flex align-items-center justify-content-center text-muted border rounded"
                  style={{ height: 150 }}
                >
                  {intl.formatMessage({
                    id: "app.automations.automation.noAction",
                  })}
                </div>
              )}
              {actionsOrdered.map((action) => (
                <ActionContainer
                  key={action.id}
                  action={action}
                  onClick={() => onEditAction(action)}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
