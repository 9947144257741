import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GOOGLE_GET_LOADING,
  GOOGLE_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { googleApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  getGoogleFailureAction,
  getGoogleInitAction,
  getGoogleProjectsFailureAction,
  getGoogleProjectsInitAction,
  getGoogleProjectsSuccessAction,
  getGoogleSuccessAction,
  postBindGoogleInitAction,
  postBindGoogleSuccessAction,
  postUnbindGoogleFailureAction,
  postUnbindGoogleInitAction,
  postUnbindGoogleSuccessAction,
} from "./store/api-actions";
import {
  projectsSelector,
  settingSelector,
  tokenSelector,
} from "./store/selector";

export function useGoogleCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const projects = useSelector(projectsSelector);
  const tokens = useSelector(tokenSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindGoogleInitAction());
        await googleApiService.unbind(companyId);
        dispatch(postUnbindGoogleSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindGoogleFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      token: string,
      refreshToken: string,
      projectId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindGoogleInitAction());
        turnOnLoading(GOOGLE_SYNC_LOADING);
        const response = await googleApiService.bind(companyId, {
          token,
          refreshToken,
          projectId,
        });
        dispatch(postBindGoogleSuccessAction({ settings: response }));
        turnOffLoading(GOOGLE_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess11" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(GOOGLE_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error24" }));
        console.error(error);
        dispatch(postUnbindGoogleFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getGoogleInitAction());
        turnOnLoading(GOOGLE_GET_LOADING);
        const response = await googleApiService.get(companyId);
        dispatch(getGoogleSuccessAction({ settings: response }));
        turnOffLoading(GOOGLE_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(GOOGLE_GET_LOADING);
        console.error(error);
        dispatch(getGoogleFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getProjects = useCallback(
    async (companyId: string, code: string) => {
      try {
        dispatch(getGoogleProjectsInitAction());
        turnOnLoading(GOOGLE_GET_LOADING);
        const { projects, tokens } = await googleApiService.getProjects(
          companyId,
          code
        );
        dispatch(getGoogleProjectsSuccessAction({ projects, tokens }));
        turnOffLoading(GOOGLE_GET_LOADING, 1500);
      } catch (error: any) {
        dispatch(getGoogleProjectsFailureAction({ error }));
        turnOffLoading(GOOGLE_GET_LOADING);
        console.error(error);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const login = useCallback(
    async (companyId: string) => {
      try {
        turnOnLoading(GOOGLE_SYNC_LOADING);
        const response = await googleApiService.login(companyId);
        return response.url;
      } catch (error: any) {
        turnOffLoading(GOOGLE_SYNC_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error25" }));
      }
    },
    [turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    unbind,
    bind,
    get,
    login,
    getProjects,
    projects,
    tokens,
  };
}
