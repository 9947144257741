import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Button, Form } from "reactstrap";
import { useMeCore } from "../../../../cores/me";
import { useIntl } from "react-intl";
import { useBoardCore } from "../../../../cores/boards";
import { InputWembii } from "../../InputWembii";
import { LabelWembii } from "../../LabelWembii";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant";
import { Board } from "../../../../cores/boards/interfaces/api/get-board";

interface Props {
  id: string;
  board: Board | null;
  onSuccess: () => void;
}

export default function AddCardContainer(props: Props) {
  const intl = useIntl();
  const { id, board, onSuccess } = props;
  const { company } = useMeCore();
  const { postCard } = useBoardCore();
  const [addCard, setAddCard] = useState(false);
  const [cardTitle, setCardTitle] = useState("");

  const toggleAddCard = useCallback(() => {
    setCardTitle("");
    setAddCard(!addCard);
  }, [addCard]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    
    postCard(company?.id!, id, cardTitle, board?.id!, [], onSuccess);
    toggleAddCard();
  };

  return (
    <div className="p-3 border-top">
      {!addCard ? (
        <div 
          className="d-flex align-items-center pointer"
          onClick={toggleAddCard}
        >
          <FontAwesomeIcon
            icon={["fas", "plus"]}
            className="pointer mr-2"
            size="1x"
            color="gray"
          />
          {intl.formatMessage({ id: "app.activity.add" })}{' '}
          { board?.type === BoardType.ACTIVITY 
            ? intl.formatMessage({ id: "app.activity.addCard" })
            : intl.formatMessage({ id: "app.activity.addChance" })
          }
        </div>
      ): (
        <Form onSubmit={onFormSubmit}>
          <LabelWembii forid="title" required={true}>
           {intl.formatMessage({ id: "app.activity.titleList" })}
          </LabelWembii>
          <InputWembii
            type="text"
            id="title"
            icon={
              <FontAwesomeIcon
                icon={["fas", "times"]}
                size="1x"
                color="gray"
                className="pointer"
                onClick={toggleAddCard}
              />
            }
            value={cardTitle}
            onChange={(v) => setCardTitle(v)}
          />
          <Button color="primary" disabled={!cardTitle}>
           {intl.formatMessage({ id: "app.activity.add" })}
          </Button>
        </Form>
      )}
    </div>
  );
}
