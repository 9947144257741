import React, { useCallback, useMemo } from "react";
import { Event } from "../../../../cores/boards/interfaces/api/get-card";
import { useIntl } from "react-intl";
import Avatar from "../../Avatar";
import { GoogleCalendarIcon } from "../../social/GoogleCalendar/GoogleCalendarIcon";
import moment from "moment";
import { EVENT_DURATION } from "../../../../cores/event/constants/eventDuration.constant";
import { useEventCore } from "../../../../cores/event";
import { DELETE_BOARD_ITEM_MODAL } from "../../../../constants/modal.constant";
import AreYouSure from "../../AreYouSure";
import { useMeCore } from "../../../../cores/me";
import { useGlobalCore } from "../../../../cores/globals";
import { useBoardCore } from "../../../../cores/boards";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";

interface Props {
  event: Event;
  board: string;
}

export default function EventCard({ event, board }: Props) {
  const intl = useIntl();
  const { deleteEvent } = useEventCore();
  const { company } = useMeCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { card, getCard } = useBoardCore();

  const participants = useMemo(() => {
    const users = event.users.map((user) => `${user.firstName} ${user.lastName}`);
    const contacts = event.contacts.map((contact) => contact.name);

    return [...users, ...contacts].join(", ");
  }, [event]);

  const duration = useMemo(() =>
    EVENT_DURATION.find((duration) => duration.minutes === event.duration)
  , [event.duration]);

  const onDelete = useCallback(() => {
    turnOnModal(
      DELETE_BOARD_ITEM_MODAL,
      <AreYouSure
        message={intl.formatMessage({ id: "app.activity.deleteComment" })}
        onSubmit={() => {
          deleteEvent(
            company?.id!,
            event.id,
            () => {
              getCard(company?.id!, board, card?.list!, card?.id!);
            }
          );
          turnOffModal(DELETE_BOARD_ITEM_MODAL);
        }}
        onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, company, board, card, deleteEvent, getCard, intl, event.id]);

  const createdAt = useMemo(() =>
    moment(event.createdAt).format("DD/MM/YYYY HH:mm a")
  , [event.createdAt]);

  return (
    <div className="d-flex mt-3 mb-4 w-100">
      <Avatar
        className="text-dark-50"
        icon={<GoogleCalendarIcon active />}
      />
      <div className="d-flex flex-column align-items-start ml-2 w-100">
        <div>
          <span className="font-weight-bold mr-2">
            {`${event.user.firstName} ${event.user.lastName}`}
          </span>
          <span className="text-muted">
            {createdAt}
          </span>
        </div>
        <div>{intl.formatMessage({ id: "app.createEvent.title" })}: {event.title}</div>
        <div>{intl.formatMessage({ id: "app.createEvent.participants" })}: {participants}</div>
        <div>{intl.formatMessage({ id: "app.createEvent.date" })}: {moment(event.date).format("DD/MM/YYYY")} {event.time} {duration?.name}</div>
        <div className="p-2 email-container">
          <div dangerouslySetInnerHTML={{__html: event.description}} />
        </div>
        {event.internalNote && (
          <>
            <hr className="horizontal-separator" />
            <div className="p-2">
              <div>{intl.formatMessage({ id: "app.createEvent.internalNote" })}: {event.internalNote}</div>
            </div>
          </>
        )}
        <div className="mt-2">
          <span className="mr-2 text-underline pointer" onClick={onDelete}>
            {intl.formatMessage({ id: "app.createEvent.event.delete" })}
          </span>
          {event.link && (
            <a
              href={event.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2 text-underline text-dark"
            >
              {intl.formatMessage({ id: "app.createEvent.event.open" })}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
