import { Moment } from "moment";
import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Media, SocialPost } from "../interfaces/api/get-social-posts";
import { Mentions } from "../interfaces/Mention";
import { PostError } from "../interfaces/postError";
import { PostImage } from "../interfaces/postImage";
import { PostVideo } from "../interfaces/postVideo";
import { SocialProvider } from "../interfaces/socialProvider";

export const setSocialProvider = createAction(
  "[Social Post] Set Social Provider",
  props<{ socialProvider: SocialProvider }>()
);

export const deleteSocialProvider = createAction(
  "[Social Post] Delete Social Provider",
  props<{ socialProvider: SocialProvider }>()
);

export const clearSocialProviders = createAction(
  "[Social Post] Clear Social Providers"
);

export const setImages = createAction(
  "[Social Post] Set Images",
  props<{ images: PostImage[] }>()
);

export const updateImage = createAction(
  "[Social Post] Update Image",
  props<{ image: PostImage }>()
);

export const deleteImage = createAction(
  "[Social Post] Delete Image",
  props<{ image: PostImage }>()
);

export const clearImages = createAction("[Social Post] Clear Images");

export const clear = createAction("[Social Post] Clear Post");

export const setVideo = createAction(
  "[Social Post] Set Video",
  props<{ video: PostVideo }>()
);

export const setVideoDuration = createAction(
  "[Social Post] Set Video Duration",
  props<{ duration: number }>()
);

export const setVideoSize = createAction(
  "[Social Post] Set Video Aspect Ratio",
  props<{ height: number, width: number }>()
);

export const deleteVideo = createAction("[Social Post] Delete Video");

export const setText = createAction(
  "[Social Post] Set Text",
  props<{ text: string }>()
);

export const setEmoji = createAction(
  "[Social Post] Set Emoji",
  props<{ emoji: string }>()
);

export const setHashtag = createAction(
  "[Social Post] Set Hashtag",
  props<{ hashtag: string }>()
);

export const moveMentions = createAction(
  "[Social Post] Move Mentions",
  props<{ offset: number, inputCursorIndex: number }>()
);

export const setInputCursorIndex = createAction(
  "[Social Post] Set Input Cursor Index",
  props<{ inputCursorIndex: number }>()
);

export const addPostErrorAction = createAction(
  "[Social Post] Add Post Error",
  props<{ error: PostError }>()
);

export const removePostErrorAction = createAction(
  "[Social Post] Remove Post Error",
  props<{ id: string }>()
);

export const addStoryErrorAction = createAction(
  "[Social Post] Add Story Error",
  props<{ error: PostError }>()
);

export const removeStoryErrorAction = createAction(
  "[Social Post] Remove Story Error",
  props<{ id: string }>()
);

export const selectPostAction = createAction(
  "[Social Post] Select Post",
  props<{ post: SocialPost }>()
);

export const clearPostMedia = createAction("[Social Post] Clear Post Media");

export const deletePostMedia = createAction(
  "[Social Post] Delete Post Media",
  props<{ image: Media }>()
);

export const resetSocialAction = createAction("[Social Post] Reset");

export const clearSelectedPostAction = createAction(
  "[Social Post] Clear Selected Post"
);

export const setSocialActionAction = createAction(
  "[Social Post] Set Social Action",
  props<{ action: string }>()
);

export const setDateAction = createAction(
  "[Social Post] Set Date",
  props<{ date: Moment }>()
);

export const addMentionAction = createAction(
  "[Social Post] Add Mention Action",
  props<{ mention: Mentions }>()
);

const actions = joinActions({
  setSocialProvider,
  deleteSocialProvider,
  clearSocialProviders,
  setImages,
  deleteImage,
  clearImages,
  setVideo,
  deleteVideo,
  setText,
  setEmoji,
  setHashtag,
  moveMentions,
  setInputCursorIndex,
  clear,
  addPostErrorAction,
  removePostErrorAction,
  selectPostAction,
  clearSelectedPostAction,
  clearPostMedia,
  deletePostMedia,
  resetSocialAction,
  setSocialActionAction,
  setDateAction,
  updateImage,
  addMentionAction,
  setVideoDuration,
  setVideoSize,
  addStoryErrorAction,
  removeStoryErrorAction,
});

export type SocialPostActions = typeof actions;
