import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { GmailSetting } from "../interface/api/post-gmail-bind";

export const postUnbindGmailInitAction = createAction(
  "[Gmail] Unbind Gmail Init"
);
export const postUnbindGmailSuccessAction = createAction(
  "[Gmail] Unbind Gmail Success",
  props<{ asUser: boolean }>()
);
export const postUnbindGmailFailureAction = createAction(
  "[Gmail] Unbind Gmail Failure",
  props<{ error: string }>()
);

export const postBindGmailInitAction = createAction(
  "[Gmail] Bind Gmail Init"
);
export const postBindGmailSuccessAction = createAction(
  "[Gmail] Bind Gmail Success",
  props<{ settings: GmailSetting, asUser: boolean }>()
);
export const postBindGmailFailureAction = createAction(
  "[Gmail] Bind Gmail Failure",
  props<{ error: string }>()
);

export const postGmailEmailInitAction = createAction(
  "[Gmail] Post Gmail Email Init"
);
export const postGmailEmailSuccessAction = createAction(
  "[Gmail] Post Gmail Email Success"
);
export const postGmailEmailFailureAction = createAction(
  "[Gmail] Post Gmail Email Failure",
  props<{ error: string }>()
);

export const getGmailInitAction = createAction(
  "[Gmail] Get Gmail Settings Init"
);
export const getGmailSuccessAction = createAction(
  "[Gmail] Get Gmail Settings Success",
  props<{ settings: GmailSetting, asUser: boolean }>()
);
export const getGmailFailureAction = createAction(
  "[Gmail] Get Gmail Settings Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindGmailInitAction,
  postUnbindGmailSuccessAction,
  postUnbindGmailFailureAction,
  postBindGmailInitAction,
  postBindGmailSuccessAction,
  postBindGmailFailureAction,
  getGmailInitAction,
  getGmailSuccessAction,
  getGmailFailureAction,
  postGmailEmailInitAction,
  postGmailEmailSuccessAction,
  postGmailEmailFailureAction,
});

export type GmailApiAction = typeof actions;
