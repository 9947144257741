import React, { useEffect, useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import { JUMPSELLER_ORDER_LOADING } from "../../constants/loading.constant";
import { JUMPSELLER_ORDER_EXPAND } from "../../constants/modal.constant";
import { useAnalyticsCore } from "../../cores/analytics";
import { ChartMetric } from "../../cores/analytics/interface/ChartMetric";
import { Metric2 } from "../../cores/analytics/interface/Metric2";
import { useGlobalCore } from "../../cores/globals";
import { useMarketCore } from "../../cores/market";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { ChartContainer } from "../component/analytics/ChartContainer";
import { BarChart } from "../component/analytics/charts/BarChart";
import { JumpsellerOrderBar } from "../component/market/jumpseller/JumpsellerBar";
import { JumpsellerOrder } from "../component/market/jumpseller/JumpsellerOrder";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";

export const JumpsellerMarket = () => {
  const intl = useIntl();
  const { jumpsellerOrders, page, perPage, status, orderId } = useMarketCore();
  const {
    company,
    socialSettings,
    companyPlan,
    companyRole,
    isAdmin,
    isReseller
  } = useMeCore();
  const { goToSocial } = useRouterCore();
  const { isModal, turnOffModal, getModalContent, getModalVisibility } = useGlobalCore();
  const { jumpsellerInsights, getJumpsellerInsights, toChartMetric } =
    useAnalyticsCore();

  const isJumpsellerSync = useMemo(
    () => socialSettings && socialSettings.jumpseller,
    [socialSettings]
  );

  useEffect(() => {
    if (
      !isJumpsellerSync || !companyPlan?.rules.allowMarket || (
        !companyRole?.market &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [companyPlan, goToSocial, isJumpsellerSync, companyRole, isAdmin, isReseller]);

  useEffect(() => {
    getJumpsellerInsights(company!.id, { page, perPage, status, orderId });
  }, [
    company,
    getJumpsellerInsights,
    isJumpsellerSync,
    page,
    perPage,
    status,
    orderId,
  ]);

  const orderData: Metric2 = useMemo(
    () => ({
      ...jumpsellerInsights.js_orders,
      type: "line",
    }),
    [jumpsellerInsights]
  );

  const ordersChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([orderData]),
    [orderData, toChartMetric]
  );

  return (
    <Layout>
      <Header2 
        title={intl.formatMessage({ id: "app.onlineSalesJump.title" })}
      />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <ChartContainer
                height={120}
                className="my-0 p-0"
                title=""
                isEmptyData={!orderData || orderData.values?.length === 0}
              >
                <BarChart
                  height={50}
                  labels={ordersChartMetrics.labels}
                  datasets={ordersChartMetrics.datasets}
                />
              </ChartContainer>
              <JumpsellerOrderBar />
              <UpdateLoadingCard
                loadingId={JUMPSELLER_ORDER_LOADING}
                text={intl.formatMessage({ id: "app.onlineSalesJump.updating" })}
              />  
              <Container>
                {jumpsellerOrders.map((order) => (
                  <JumpsellerOrder order={order} key={order.id} />
                ))}
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(JUMPSELLER_ORDER_EXPAND)}
        visible={getModalVisibility(JUMPSELLER_ORDER_EXPAND)}
        onClose={() => turnOffModal(JUMPSELLER_ORDER_EXPAND)}
        left
      >
        {getModalContent(JUMPSELLER_ORDER_EXPAND)}
      </SideModal>
    </Layout>
  );
};
