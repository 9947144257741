import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import {
  addNewNotificationAction,
  addOnlineClientAction,
  deleteOnlineClientAction,
  SocketAction,
} from "./actions";
import { SocketSocketAction } from "./socket-actions";

export interface SocketState {
  onlineClients: string[];
  notification: string;
}

const initialState: SocketState = {
  onlineClients: [],
  notification: "",
};

export default produce(
  (
    draft: SocketState = initialState,
    action: SocketAction | SocketSocketAction | GlobalActions
  ) => {
    switch (action.type) {
      case addOnlineClientAction.type:
        if (draft.onlineClients.indexOf(action.id) === -1) {
          draft.onlineClients = [...draft.onlineClients, action.id];
        }
        return draft;
      case deleteOnlineClientAction.type:
        let clients = [...draft.onlineClients];
        clients.splice(clients.indexOf(action.id), 1);
        draft.onlineClients = clients;
        return draft;
      case addNewNotificationAction.type:
        draft.notification = action.id;
        return draft;
      case resetAction.type:
        return initialState;
      default:
        return draft;
    }
  }
);
