import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";

export const setWooCommerceName = createAction(
  "[WooCommerce] Set Name",
  props<{ name: string }>()
);

export const setWooCommerceUrl = createAction(
  "[WooCommerce] Set Url",
  props<{ url: string }>()
);

const actions = joinActions({
  setWooCommerceName,
  setWooCommerceUrl,
});

export type WooCommerceAction = typeof actions;
