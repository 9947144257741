import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Badge } from "reactstrap";
import { AutomationAction } from "../../../../cores/automations/interfaces/api/get-automation";
import { AutomaticEmailActionBody } from "../../../../cores/automations/interfaces/AutomaticEmailAction";
import { LabelWembii } from "../../LabelWembii";

interface Props {
  action: AutomationAction;
}

export default function AutomaticEmailAction(props: Props) { 
  const intl = useIntl();
  const { action } = props;

  const days = useMemo(() => 
    (action.body as AutomaticEmailActionBody).days
  , [action]);

  const minutes = useMemo(() => 
    (action.body as AutomaticEmailActionBody).minutes
  , [action]);

  const time = useMemo(() => 
    (action.body as AutomaticEmailActionBody).time
  , [action]);

  const subject = useMemo(() => 
    (action.body as AutomaticEmailActionBody).subject
  , [action]);

  const message = useMemo(() => 
    (action.body as AutomaticEmailActionBody).message
  , [action]);

  const email = useMemo(() => 
    (action.body as AutomaticEmailActionBody).email
  , [action]);

  return (
    <>
      <div className="text-muted">
        {minutes ? 
          intl.formatMessage(
            { id: "app.automations.action.automaticEmail.minuteTime" },
            { minutes }
          ) :
          intl.formatMessage(
            { id: "app.automations.action.automaticEmail.dayTime" },
            { days, time }
          )
        }
      </div>

      <LabelWembii>
        {intl.formatMessage({ id: "app.sendEmail.from" })}
      </LabelWembii>
      <div className="text-muted">
        {email 
          ? email
          : <Badge color="warning" className="text-white ml-2">
              <FontAwesomeIcon
                icon={["fas", "exclamation-triangle"]}
                size="1x"
                className="mr-1"
              />
              {intl.formatMessage({ id: "app.automations.automation.warning.noSync" })}
            </Badge>
        }
      </div>

      <LabelWembii>
        {intl.formatMessage({ id: "app.sendEmail.subject" })}
      </LabelWembii>
      <div className="text-muted">
        {subject}
      </div>

      <LabelWembii>
        {intl.formatMessage({ id: "app.sendEmail.message" })}
      </LabelWembii>
      <div className="text-muted" dangerouslySetInnerHTML={{__html: message}} />
    </>
  );
}