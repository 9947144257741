import React from "react";
import { Badge } from "reactstrap";
import {
  JumpsellerOrderStatus,
  JumpsellerOrderStatusColor,
  JumpsellerOrderStatusDic
} from "../../../../cores/market/jumpseller.config";

interface Props {
  status: JumpsellerOrderStatus;
}

export const JumpsellerOrderStatusBadge = ({ status }: Props) => {
  return (
    <Badge
      pill
      className="p-2 px-3"
      style={{ backgroundColor: JumpsellerOrderStatusColor[status] }}
    >
      {JumpsellerOrderStatusDic[status]}
    </Badge>
  );
};
