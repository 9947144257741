import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Errors } from "../../constants/errors";
import {
  INSTAGRAM_MENSSEGER_GET_LOADING,
  INSTAGRAM_MENSSEGER_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { InstagramAccount } from "./interface/api/get-instagram-accounts";
import { instagramMenssegerApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  getInstagramMenssegerAccountsFailureAction,
  getInstagramMenssegerAccountsInitAction,
  getInstagramMenssegerAccountsSuccessAction,
  getInstagramMenssegerFailureAction,
  getInstagramMenssegerInitAction,
  getInstagramMenssegerSuccessAction,
  postBindInstagramMenssegerFailureAction,
  postBindInstagramMenssegerInitAction,
  postBindInstagramMenssegerSuccessAction,
  postUnbindInstagramMenssegerFailureAction,
  postUnbindInstagramMenssegerInitAction,
  postUnbindInstagramMenssegerSuccessAction,
} from "./store/api-actions";
import {
  accountsSelector,
  errorCodeSelector,
  errorMessageSelector,
  settingSelector,
} from "./store/selector";

export function useInstagramMenssegerCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const accounts = useSelector(accountsSelector);
  const errorCode = useSelector(errorCodeSelector);
  const errorMessage = useSelector(errorMessageSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindInstagramMenssegerInitAction());
        await instagramMenssegerApiService.unbind(companyId);
        dispatch(postUnbindInstagramMenssegerSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindInstagramMenssegerFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      account: InstagramAccount,
      userToken: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindInstagramMenssegerInitAction());
        turnOnLoading(INSTAGRAM_MENSSEGER_SYNC_LOADING);
        const settings = await instagramMenssegerApiService.bind(companyId, {
          oauthToken: account.token,
          userToken,
          accountId: account.id,
        });
        dispatch(postBindInstagramMenssegerSuccessAction({ settings }));
        turnOffLoading(INSTAGRAM_MENSSEGER_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess12" }));
        onSuccess?.();
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string =
            JSON.parse(error.request.response).message || "";
          switch (code) {
            case Errors.MessageDissabled:
              dispatch(
                postBindInstagramMenssegerFailureAction({
                  errorCode: Errors.MessageDissabled,
                  errorMessage: message,
                })
              );
              break;
            case Errors.MessageNotAllowed:
              dispatch(
                postBindInstagramMenssegerFailureAction({
                  errorCode: Errors.MessageNotAllowed,
                  errorMessage: message,
                })
              );
              break;
            case Errors.NotBusinessAccount:
              dispatch(
                postBindInstagramMenssegerFailureAction({
                  errorCode: Errors.NotBusinessAccount,
                  errorMessage: message,
                })
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error30" }));
              console.error(error, code);
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error30" }));
          console.error(error);
        }
        turnOffLoading(INSTAGRAM_MENSSEGER_SYNC_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getInstagramMenssegerInitAction());
        turnOnLoading(INSTAGRAM_MENSSEGER_GET_LOADING);
        const settings = await instagramMenssegerApiService.get(companyId);
        dispatch(getInstagramMenssegerSuccessAction({ settings }));
        turnOffLoading(INSTAGRAM_MENSSEGER_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(INSTAGRAM_MENSSEGER_GET_LOADING);
        console.error(error);
        dispatch(getInstagramMenssegerFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getAccounts = useCallback(
    async (companyId: string, token: string) => {
      try {
        dispatch(getInstagramMenssegerAccountsInitAction());
        turnOnLoading(INSTAGRAM_MENSSEGER_SYNC_LOADING);
        const accounts = await instagramMenssegerApiService.accounts(companyId, token);
        dispatch(getInstagramMenssegerAccountsSuccessAction({ accounts }));
        turnOffLoading(INSTAGRAM_MENSSEGER_SYNC_LOADING, 500);
      } catch (error: any) {
        turnOffLoading(INSTAGRAM_MENSSEGER_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error31" }));
        console.error(error);
        dispatch(getInstagramMenssegerAccountsFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    errorCode,
    errorMessage,
    unbind,
    bind,
    get,
    getAccounts,
    accounts,
  };
}
