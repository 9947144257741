import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import cn from "classnames";
import { useIntl } from "react-intl";
import { Badge } from "reactstrap";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { LabelWembii } from "../../LabelWembii";
import Avatar from "../../Avatar";
import { EmailContact } from "../../../../cores/contacts/interfaces/EmailContact";
import { TooltipWembii } from "../../TooltipWembii";

interface Props {
  label: string;
  contacts: EmailContact[];
  accContacts?: number;
  onRemoving: (e: any, arg0: string) => void;
  onEditing: (id: string) => void;
  onOpening: () => void;
}

export const ContactsInput = (props: Props) => {
  const intl = useIntl();
  const { label, contacts, accContacts = 0, onRemoving, onOpening, onEditing } = props;

  return (
    <div>
      <LabelWembii>
        {intl.formatMessage({ id: label })} {contacts.length > 1 && `(${contacts.length.toString()})`}
      </LabelWembii>
      <div className="d-flex justify-content-between border" style={{
        borderRadius: 15,
        overflowY: "scroll",
        maxHeight: 150
      }}>
      <div className="d-flex flex-wrap align-items-center">
        {contacts.map((contact, index) => (
          <div key={contact.id}>
            <Badge 
              key={contact.id}
              color={!contact.email ?
                "danger" :
                index + 1 + accContacts > 2000 ?
                  "light" :
                  "dark"
              }
              className={cn("m-1 d-flex align-items-center pointer", {
                "text-light": index + 1 + accContacts <= 2000,
                "text-dark": index + 1 + accContacts > 2000
              })}
              id={`contact-${contact.id}`}
              onClick={() => onEditing(contact.id)}
              pill
            >
              <span>
                {contact.name || contact.email || contact.phoneNumber}
              </span>
              <FontAwesomeIcon
                icon={["fas", "times"]}
                size="1x"
                color={index + 1 + accContacts > 2000 ?
                  "black" :
                  "white"}
                className="m-1 z-index-5 ml-1"
                onClick={(e) => onRemoving(e, contact.id)}
              />
            </Badge>
            {!contact.email && (
              <TooltipWembii 
                id={`contact-${contact.id}`}
                text={intl.formatMessage({ id: "app.sendEmail.contact.noEmail" })} 
              />
            )}
          </div>
        ))}
      </div>
      <Avatar
        className="text-dark-50 pointer m-1"
        onClick={onOpening}
        icon={
          <FontAwesomeIcon
            icon={["fas", "plus"]}
            size="1x"
            color={COLOR_PRIMARY}
          />
        }
      />
      </div>
    </div>
  );
};
