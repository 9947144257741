import "bootstrap/dist/css/bootstrap.min.css";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.scss";
import { history, persistor, store } from "./redux/store";
import LocaleWrapper from "./LocaleWrapper";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={persistor}>
        <LocaleWrapper>
          <Route path="/" component={App} />
        </LocaleWrapper>
      </PersistGate>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
