import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Col, Container, Label, Row } from "reactstrap";
import {
  FACEBOOK_POST_LOADING,
  INSTAGRAM_POST_LOADING,
  LINKED_IN_BUSINESS_POST_LOADING,
  LINKED_IN_POST_LOADING,
  OPENAI_GET_TEMPLATES_LOADING,
  TWITTER_POST_LOADING,
} from "../../constants/loading.constant";
import {
  EDIT_IMAGE_MODAL,
  EDIT_VIDEO_MODAL,
  SCHEDULE_POST_MODAL,
  MORE_INFO_SOCIAL_POST,
  ADD_SOCIAL_IMAGE_MODAL,
  ADD_SOCIAL_VIDEO_MODAL,
  EMOJI_FORM,
  TEMPLATE_SOCIAL_FORM,
  TEMPLATES_SOCIAL_FORM,
  HASHTAGS_SOCIAL_FORM,
  ADD_MENTION_MODAL,
  OPENAI_SOCIAL_FORM,
} from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useIntl } from "react-intl";
import { useRouterCore } from "../../cores/router";
import { useSocialPostCore } from "../../cores/socialPost";
import { PickerMode } from "../../cores/socialPost/config";
import { SOCIAL_ACTION_TYPES } from "../../cores/socialPost/consts/socialActionType.constant";
import { PanelWembii } from "../component/PanelWembii";
import { TextEditor } from "../component/socialPost/creator/TextEditor";
import { PostForm } from "../component/socialPost/PostForm";
import { SocialPostPreview } from "../component/socialPost/preview/SocialPostPreview";
import { SocialPostSelector } from "../component/socialPost/selector/SocialPostSelector";
import { TitleWembii } from "../component/TitleWembii";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import QueryString from "query-string";
import { useLocation } from "react-router-dom";
import { PostButtons } from "../component/socialPost/creator/PostButtons";
import { PostActionButtons } from "../component/socialPost/creator/PostActionButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoForm from "../component/socialPost/modals/VideoForm";
import ImageForm from "../component/socialPost/modals/ImageForm";
import { SocialType } from "../../cores/socialPost/interfaces/postError";
import { TooltipWembii } from "../component/TooltipWembii";
import { EmojiForm } from "../component/EmojiForm";
import TemplateSocialForm from "../component/socialPost/modals/TemplateSocialForm";
import TemplatesForm from "../component/socialPost/modals/TemplatesForm";
import { useTemplateCore } from "../../cores/templates";
import { useInstagramCore } from "../../cores/instagram";
import { Errors } from "../../constants/errors";
import { ErrorIcon } from "../component/socialPost/ErrorIcon";
import HashtagsForm from "../component/socialPost/modals/HashtagsForm";
import {MentionForm} from "../component/socialPost/modals/MentionForm";
import { Position } from "../../cores/globals/interfaces/globalEntity";
import { YoutubeModal } from "../component/YoutubeModal";
import { OpenAiIcon } from "../component/social/OpenAi/OpenAiIcon";
import OpenAiForm from "../component/socialPost/modals/OpenAiForm";

export default function SocialPost() {
  const intl = useIntl();
  const { search } = useLocation();
  const { getSocialTemplates } = useTemplateCore();
  const {
    getMeSocialSettings,
    socialSettings,
    company,
    companyRole,
    isAdmin,
    isReseller
  } = useMeCore();
  const {
    socialProviders,
    selectedPost,
    action,
    reset,
    addEmoji,
    updateMentions,
    inputCursorIndex,
    getHashtags,
  } = useSocialPostCore();
  const { errorCode } = useInstagramCore();
  const { goToSocial, goToSocialHistory, goToSocialCalendar } = useRouterCore();
  const { areLoading, isModal, isLoading, getModalContent, getModalVisibility, turnOffModal, turnOnModal } = useGlobalCore();
  const [picker, setPicker] = useState<PickerMode>(PickerMode.Off);
  const [from, setFrom] = useState("");
  const [repost, setRepost] = useState(false);

  const error = useMemo(() => {
    switch (errorCode) {
      case Errors.NotBusinessAccount:
        return (
          <Label className="text-muted text-center my-4 w-100">
            {intl.formatMessage({ id: "app.social.instagram.businessError" })}.
            {' Lee '}
            <a
              href="https://soporte.wembii.com/es/article/como-cambiar-una-cuenta-de-instagram-a-empresa-1d9gri7/"
              target="_blank"
              rel="noopener noreferrer"
            >
              aquí
            </a>
            {' como hacerlo'}
          </Label>
        );
    }
  }, [errorCode, intl]);
  
  useEffect(() => {
    if (
      !companyRole?.social &&
      !companyRole?.owner &&
      !companyRole?.admin &&
      !companyRole?.agent &&
      !isAdmin &&
      !isReseller
    ) goToSocial();
  }, [goToSocial, companyRole, isAdmin, isReseller]);

  const loadingIds = useMemo(
    (): string[] => ([
      FACEBOOK_POST_LOADING,
      TWITTER_POST_LOADING,
      LINKED_IN_BUSINESS_POST_LOADING,
      LINKED_IN_POST_LOADING,
      INSTAGRAM_POST_LOADING,
    ]), []
  )

  const shouldDisplay = useMemo(
    (): boolean => !areLoading(loadingIds),
    [areLoading, loadingIds]
  );

  useEffect(() => {
    const { from, repost } = QueryString.parse(search);
    if (from) {
      setFrom(from as string)
    }
    if (repost) {
      setRepost(repost as string === 'true');
    }
  }, [search]);

  useEffect(() => {
    getMeSocialSettings(company!.id);
  }, [company, getMeSocialSettings]);

  const openMentionsModal = useCallback(() => {
    turnOnModal(ADD_MENTION_MODAL, true);
  }, [turnOnModal]);
  
  const resetPost = useCallback(() => {
    setPicker(PickerMode.Off);
    reset();
  }, [reset]);

  const openMoreInfo = useCallback(() => {
    turnOnModal(MORE_INFO_SOCIAL_POST, true, Position.LEFT);
  }, [turnOnModal]);

  const handleAddEmoji = useCallback((emoji: any) => {
    const unifieds = emoji.unified.split('-');
    const emojiCode = unifieds.map((unified: any) => String.fromCodePoint(Number(`0x${unified}`))).join('');
    addEmoji(emojiCode);
    updateMentions(emojiCode.length, inputCursorIndex);
  }, [addEmoji, updateMentions, inputCursorIndex]);

  const openEmojiModal = useCallback(() => {
    turnOnModal(
      EMOJI_FORM,
      <EmojiForm onAdding={handleAddEmoji} />
    );
  }, [turnOnModal, handleAddEmoji]);

  const openImagePickerModal = useCallback(() => {
    turnOnModal(ADD_SOCIAL_IMAGE_MODAL, true);
  }, [turnOnModal]);

  const openHashtagModal = useCallback(() => {
    getHashtags(company?.id!);
    turnOnModal(HASHTAGS_SOCIAL_FORM, true);
  }, [turnOnModal, getHashtags, company]);

  const openTemplateModal = useCallback(() => {
    getSocialTemplates(company?.id!);
    turnOnModal(TEMPLATES_SOCIAL_FORM, true);
  }, [turnOnModal, getSocialTemplates, company]);

  const openOpenAiModal = useCallback(() => {
    turnOnModal(OPENAI_SOCIAL_FORM, true);
  }, [turnOnModal]);

  const openVideoPickerModal = useCallback(() => {
    turnOnModal(ADD_SOCIAL_VIDEO_MODAL, true);
  }, [turnOnModal]);

  const cancelEdition = useCallback(() => {
    resetPost();
    if (from === "history") {
      goToSocialHistory();
    } else {
      goToSocialCalendar();
    }
  }, [goToSocialHistory, goToSocialCalendar, resetPost, from]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.socialMediaPost.title" })}
        subtitle={!selectedPost 
          ? intl.formatMessage({ id: "app.socialMediaPost.selectSocialMedia" }) 
          : intl.formatMessage({ id: "app.socialMediaPost.modifyPost" })
        }
        mb={false}
        rightChildren={
          <>
            <FontAwesomeIcon 
              icon={["fas", "info-circle"]}
              size="2x"
              className="pointer m-2 ml-2"
              color="gray"
              id="info-SocialPost"
            />   
            <FontAwesomeIcon 
              icon={["fas", "video"]}
              size="2x"
              className="pointer m-2 ml-2"
              onClick={openMoreInfo}
              color="gray"
            />   
            <TooltipWembii 
              id="info-SocialPost"
              text={intl.formatMessage({id: "app.tooltipInfo.SocialPost"})}
            />
            {socialProviders.length > 0 && socialSettings?.openai && (
              <Button className="whiteButton" onClick={openOpenAiModal}>
                <OpenAiIcon
                  size="1x"
                  active={true}
                  className="mr-2"
                />
                {intl.formatMessage({ id: "app.socialPost.openai" })}
              </Button>
            )}
            {selectedPost && (
              <div className="d-flex">
                {!repost && <PostActionButtons from={from} onReset={resetPost} />}
                <Button color="danger" onClick={cancelEdition}>
                  {intl.formatMessage({ id: "app.socialMediaPost.cancel" })}
                </Button>
              </div>
            )}
          </>
        }
      />
      <Content>
        <UpdateLoadingCard
          loadingId={loadingIds}
          text={
            picker === PickerMode.Video 
            ? intl.formatMessage({ id: "app.socialMediaPost.preparingVideo" }) :
            (action === SOCIAL_ACTION_TYPES.POST 
              ? intl.formatMessage({ id: "app.socialMediaPost.posting" })
              : intl.formatMessage({ id: "app.socialMediaPost.programming" }) )
          }
        />
        
        {shouldDisplay &&
          <Container fluid>
            {errorCode && (
              <Row className="d-flex justify-content-center">
                <Col xs="12" md="8">  
                  <PanelWembii className="mb-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <ErrorIcon size="2x" className="m-2" />
                      { error }
                    </div>
                  </PanelWembii>
                </Col>
              </Row>
            )}
            
            <Row>
              <Col xs="12">  
                <SocialPostSelector />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col xs="12" lg="6">
                <div className="pt-4">
                  <PanelWembii shadow className="p-3 px-4">
                    <TitleWembii Tag="h5" color="primary">
                     {intl.formatMessage({ id: "app.socialMediaPost.yourPublish" })}
                    </TitleWembii>
                    <div className="p-3 wembii-border">
                      <TextEditor
                        activePicker={picker}
                        onClickEmoji={openEmojiModal}
                        onClickHashtag={openHashtagModal}
                        onClickMention={openMentionsModal}
                        onClickImages={openImagePickerModal}
                        onClickVideo={openVideoPickerModal}
                        onClickTemplate={openTemplateModal}
                        onClickOpenAi={socialSettings?.openai ? openOpenAiModal : undefined}
                        socialType={SocialType.Post}
                      />
                    </div>
                    
                    <PostButtons repost={repost} from={from} onReset={resetPost} />
                  </PanelWembii>
                </div>
              </Col>
              {socialProviders.length > 0 && (
                <Col xs="12" lg="6">
                  <div className="pt-4">
                    <PanelWembii shadow className="p-3 px-4">
                      <TitleWembii Tag="h5" color="primary">
                        {intl.formatMessage({ id: "app.socialMediaPost.preview" })}
                      </TitleWembii>
                      <SocialPostPreview />
                    </PanelWembii>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        }
      </Content>
      <SideModal
        active={isModal(SCHEDULE_POST_MODAL)}
        visible={getModalVisibility(SCHEDULE_POST_MODAL)}
        onClose={() => turnOffModal(SCHEDULE_POST_MODAL)}
      >
        <PostForm
          onClose={() => turnOffModal(SCHEDULE_POST_MODAL)}
          onReset={resetPost}
          repost={repost}
          socialType={SocialType.Post}
        />
      </SideModal>
      <SideModal
        active={isModal(ADD_SOCIAL_IMAGE_MODAL)}
        visible={getModalVisibility(ADD_SOCIAL_IMAGE_MODAL)}
        onClose={() => turnOffModal(ADD_SOCIAL_IMAGE_MODAL)}
      >
        <ImageForm />
      </SideModal>
      <SideModal
        active={isModal(ADD_SOCIAL_VIDEO_MODAL)}
        visible={getModalVisibility(ADD_SOCIAL_VIDEO_MODAL)}
        onClose={() => turnOffModal(ADD_SOCIAL_VIDEO_MODAL)}
      >
        <VideoForm />
      </SideModal>
      <SideModal
        active={isModal(EMOJI_FORM)}
        visible={getModalVisibility(EMOJI_FORM)}
        onClose={() => turnOffModal(EMOJI_FORM)}
      >
        {getModalContent(EMOJI_FORM)}
      </SideModal>
      <SideModal
        active={isModal(TEMPLATES_SOCIAL_FORM)}
        visible={getModalVisibility(TEMPLATES_SOCIAL_FORM)}
        onClose={() => turnOffModal(TEMPLATES_SOCIAL_FORM)}
      >
        <TemplatesForm />
      </SideModal>
      <SideModal
        active={isModal(OPENAI_SOCIAL_FORM)}
        visible={getModalVisibility(OPENAI_SOCIAL_FORM)}
        onClose={() => turnOffModal(OPENAI_SOCIAL_FORM)}
        showSppiner={isLoading(OPENAI_GET_TEMPLATES_LOADING)}
      >
        <OpenAiForm />
      </SideModal>
      <SideModal
        active={isModal(ADD_MENTION_MODAL)}
        visible={getModalVisibility(ADD_MENTION_MODAL)}
        onClose={() => turnOffModal(ADD_MENTION_MODAL)}
      >
        <MentionForm />
      </SideModal>
      <SideModal
        active={isModal(HASHTAGS_SOCIAL_FORM)}
        visible={getModalVisibility(HASHTAGS_SOCIAL_FORM)}
        onClose={() => turnOffModal(HASHTAGS_SOCIAL_FORM)}
      >
        <HashtagsForm />
      </SideModal>
      <SideModal
        active={isModal(EDIT_IMAGE_MODAL)}
        visible={getModalVisibility(EDIT_IMAGE_MODAL)}
        onClose={() => turnOffModal(EDIT_IMAGE_MODAL)}
        left
      >
        {getModalContent(EDIT_IMAGE_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(EDIT_VIDEO_MODAL)}
        visible={getModalVisibility(EDIT_VIDEO_MODAL)}
        onClose={() => turnOffModal(EDIT_VIDEO_MODAL)}
        left
      >
        {getModalContent(EDIT_VIDEO_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(MORE_INFO_SOCIAL_POST)}
        visible={getModalVisibility(MORE_INFO_SOCIAL_POST)}
        onClose={() => turnOffModal(MORE_INFO_SOCIAL_POST)}
        left
      >
        <YoutubeModal title="Como postear o programar posteos" videoId="GhWB_1VLxcw" />
      </SideModal>
      <SideModal
        active={isModal(TEMPLATE_SOCIAL_FORM)}
        visible={getModalVisibility(TEMPLATE_SOCIAL_FORM)}
        onClose={() => turnOffModal(TEMPLATE_SOCIAL_FORM)}
        left
      >
        <TemplateSocialForm />
      </SideModal>
    </Layout>
  );
}
