import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QueryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import {
  ASSIGN_USER_SIDE_MODAL_LEFT,
  USER_ASSING_FORM,
  USER_DELETE_FORM,
  USER_EXPAND_CREATE_FORM,
  USER_EXPAND_EDIT_FORM,
} from "../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../constants/theme";
import { useCompanyCore } from "../../cores/company";
import { CompanyUser } from "../../cores/company/interfaces/api/get-company";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useResellerCore } from "../../cores/reseller";
import { useRouterCore } from "../../cores/router";
import { useIntl } from "react-intl";
import { CreateUserForm } from "../component/admin/CreateUserForm";
import { ResellerSelector } from "../component/admin/users/ResellerSelector";
import AreYouSure from "../component/AreYouSure";
import AssignUserForm from "../component/companies/AssignUserForm";
import EditCompanyForm from "../component/companies/EditCompanyForm";
import { PlanBadge } from "../component/companies/PlanBadge";
import { ResellerBadge } from "../component/companies/ResellerBadge";
import SearchUserForm from "../component/companies/SearchUserForm";
import { InputWembii } from "../component/InputWembii";
import { PanelWembii } from "../component/PanelWembii";
import { UpgradePlanButton } from "../component/plan/UpgradePlanButton";
import { TitleWembii } from "../component/TitleWembii";
import UserRow from "../component/UserRow";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import Icofont from "react-icofont";
import { WhatsappIcon } from "../component/social/Whatsapp/WhatsappIcon";
import { useWhatsappCore } from "../../cores/whatsapp";
import _ from "lodash";

export default function EditCompany() {
  const intl = useIntl();
  const [editUser, setEditUser] = useState<CompanyUser | null>(null);
  const [deleteUser, setDeleteUser] = useState<CompanyUser | null>(null);
  const [from, setFrom] = useState("");
  const [token, setToken] = useState("");
  const [tooltip, showTooltip] = useState(false);

  const [pin, setPin] = useState<number>(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();
  const {
    getCompany,
    getCompanyIntegration,
    company,
    users,
    integration,
    deleteAssignment,
    getCompanies,
    putApikey,
    plan,
  } = useCompanyCore();
  const { goToMeCompany, goToCompany } = useRouterCore();
  const {
    isModal,
    getModalContent,
    turnOffModal,
    turnOnModal,
    getModalVisibility,
  } = useGlobalCore();
  const { companyPlan, isAdmin, getMeCompanies, getMeCompany, companyId } =
    useMeCore();
  const { unassignResellerToCompany, assignResellerToCompany } =
    useResellerCore();
  const { bind, unbind } = useWhatsappCore();

  const toggleSideModal = useCallback(() => {
    turnOnModal(USER_ASSING_FORM, true);
  }, [turnOnModal]);

  useEffect(() => {
    getCompany(id);
    getCompanyIntegration(id);
  }, [getCompany, getCompanyIntegration, id]);

  useEffect(() => {
    const { from } = QueryString.parse(search);
    if (from) {
      setFrom(from as string);
    }
  }, [search]);

  const onDeleting = useCallback(
    (user) => {
      setDeleteUser(user);
      turnOnModal(USER_DELETE_FORM, true);
    },
    [turnOnModal]
  );

  const onEditing = useCallback(
    (user) => {
      setEditUser(user);
      turnOnModal(USER_EXPAND_EDIT_FORM, true);
    },
    [turnOnModal]
  );

  const actions = useCallback(
    (user: CompanyUser) => (
      <div className="d-flex">
        <div onClick={() => onEditing(user)}>
          <Icofont className="pointer" icon="edit" />
        </div>
        <div onClick={() => onDeleting(user)}>
          <Icofont className="ml-2 pointer" icon="delete-alt" />
        </div>
      </div>
    ),
    [onDeleting, onEditing]
  );

  const goBack = () => {
    from && from === "companies" ? goToCompany() : goToMeCompany();
  };

  const canAssign = useMemo(
    () => isAdmin || (companyPlan && users.length < companyPlan.rules.teamSize),
    [companyPlan, users.length, isAdmin]
  );

  const handleDeleteAssign = useCallback(async () => {
    if (deleteUser && company) {
      await deleteAssignment(deleteUser.id, company.id);
      await getCompany(company.id);
      setDeleteUser(null);
      turnOffModal(USER_DELETE_FORM);
    }
  }, [deleteUser, company, deleteAssignment, getCompany, turnOffModal]);

  const handleRefreshApikey = useCallback(() => {
    putApikey(companyId!);
  }, [putApikey, companyId]);

  const onSuccess = useCallback(async () => {
    turnOffModal(USER_EXPAND_CREATE_FORM);
  }, [turnOffModal]);

  const onEditingCompany = useCallback(async () => {
    getMeCompanies();
    getCompanies({});
    if (companyId === company?.id) {
      getMeCompany(companyId);
    }
  }, [getMeCompanies, getCompanies, getMeCompany, company, companyId]);

  const onAssignReseller = useCallback(
    async (resellerId: number) => {
      if (resellerId) assignResellerToCompany(resellerId, +company!.id);
      else unassignResellerToCompany(+company!.id);
    },
    [assignResellerToCompany, company, unassignResellerToCompany]
  );

  useEffect(() => {
    if (!_.isEmpty(integration)) {
      setPhoneNumber(integration.phoneNumber);
      setToken(integration.token);
      setPin(integration.pin);
    } else {
      setPhoneNumber("");
      setToken("");
      setPin(0);
    }
  }, [integration]);

  const handleBind = useCallback(async () => {
    bind(company?.id!, token, phoneNumber, pin, () =>
      getCompanyIntegration(company?.id!)
    );
  }, [phoneNumber, token, company, bind, getCompanyIntegration]);

  const handleUnbind = useCallback(async () => {
    unbind(company?.id!, () => {
      getCompanyIntegration(company?.id!);
    });
  }, [company, getCompanyIntegration, unbind]);

  const whatsappBtn = useMemo(() => {
    return _.isEmpty(integration) ? (
      <Button
        color="primary"
        className="mx-auto"
        onClick={handleBind}
        disabled={!token && !phoneNumber}
      >
        {intl.formatMessage({ id: "app.home.syncUp" })}
      </Button>
    ) : (
      <Button color="primary" outline onClick={handleUnbind}>
        {intl.formatMessage({ id: "app.home.disconnect" })}
      </Button>
    );
  }, [handleBind, handleUnbind, integration, token, phoneNumber, intl]);

  return (
    <Layout>
      <Header2
        title={`${intl.formatMessage({ id: "app.editCompany.editCompany" })} ${company?.name
          }`}
        rightChildren={
          <>
            <div className="align-items-center d-flex ml-auto">
              {!canAssign ? (
                <>
                  <div className="flex">
                    <Button
                      id="campaign-limitUsersForPlan"
                      className={
                        !canAssign ? "disabledButton ml-2" : "whiteButton ml-2"
                      }
                      onMouseEnter={() => showTooltip(true)}
                      onMouseLeave={() => showTooltip(false)}
                      onClick={() =>
                        canAssign && turnOnModal(USER_EXPAND_CREATE_FORM, true)
                      }
                    >
                      {intl.formatMessage({ id: "app.editCompany.createUser" })}
                    </Button>
                    <Button
                      id="example-button"
                      className={
                        !canAssign ? "disabledButton ml-2" : "whiteButton ml-2"
                      }
                      onClick={() => canAssign && toggleSideModal}
                      onMouseEnter={() => showTooltip(true)}
                      onMouseLeave={() => showTooltip(false)}
                    >
                      {intl.formatMessage({ id: "app.editCompany.asignUser" })}
                    </Button>
                    {tooltip && (
                      <div className="tooltip-wembii rounded shadow mt-2 flex-column">
                        <p className="text-center">
                          {intl.formatMessage({
                            id: "app.tooltipInfo.exceededUsersPlan",
                          })}
                        </p>
                        <UpgradePlanButton />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Button
                    className="whiteButton"
                    onClick={() => turnOnModal(USER_EXPAND_CREATE_FORM, true)}
                    disabled={!canAssign}
                  >
                    {intl.formatMessage({ id: "app.editCompany.createUser" })},
                  </Button>
                  <Button
                    className="whiteButton ml-2"
                    onClick={toggleSideModal}
                    disabled={!canAssign}
                  >
                    {intl.formatMessage({ id: "app.editCompany.asignUser" })}
                  </Button>
                </>
              )}
            </div>
            <div className="ml-2">
              <Button className="whiteButton" onClick={goBack}>
                {intl.formatMessage({ id: "app.editCompany.goBack" })}
              </Button>
            </div>
          </>
        }
      />
      <Content>
        <Container fluid>
          <Row>
            <Col sm="12" md="7">
              <div className="w-100 mb-3 align-items-center d-flex">
                {company?.reseller && !isAdmin && (
                  <ResellerBadge show reseller={company?.reseller} />
                )}
                {isAdmin && (
                  <ResellerSelector
                    reseller={company?.reseller}
                    onAssignReseller={onAssignReseller}
                  />
                )}
                {plan && <PlanBadge show plan={plan} className="mx-2" />}
              </div>
              {isAdmin && (
                <>
                  <TitleWembii Tag="h5" color="primary">
                    <WhatsappIcon active className="mr-2" />
                    Whatsapp integration
                  </TitleWembii>
                  <PanelWembii className="px-4 py-3">
                    <div className="d-flex justify-content-between align-items-end">
                      <InputWembii
                        type="text"
                        label="Token"
                        rounded
                        className="mt-1 mr-2 w-75"
                        value={token}
                        onChange={setToken}
                        mb={false}
                      />
                      <InputWembii
                        type="tel"
                        label="Phone Number"
                        rounded
                        className="mt-1 w-25 mr-2"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        mb={false}
                      />
                      {whatsappBtn}
                    </div>
                  </PanelWembii>
                </>
              )}
              <TitleWembii Tag="h5" color="primary">
                Apikey
              </TitleWembii>
              <PanelWembii className="px-4 py-3">
                <InputWembii
                  type="text"
                  disabled={true}
                  rounded
                  className="mt-1"
                  value={company?.apiKey}
                  mb={false}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "redo"]}
                      size="1x"
                      color={COLOR_PRIMARY}
                      className="m-1 pointer z-index-50"
                      onClick={handleRefreshApikey}
                    />
                  }
                />
              </PanelWembii>

              <TitleWembii Tag="h5" color="primary">
                {intl.formatMessage({ id: "app.editCompany.companyUsers" })}
              </TitleWembii>
              {users.map((user) => (
                <UserRow
                  key={user.id}
                  user={user}
                  actions={!user.companyRole?.owner && actions(user)}
                  showCompanyRoles
                />
              ))}
            </Col>
            <Col sm="12" md="5">
              <EditCompanyForm onSubmit={onEditingCompany} />
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(USER_DELETE_FORM)}
        visible={getModalVisibility(USER_DELETE_FORM)}
        onClose={() => {
          turnOffModal(USER_DELETE_FORM);
          setDeleteUser(null);
        }}
      >
        {deleteUser && (
          <AreYouSure
            message={`${intl.formatMessage({ id: "app.editCompany.delete1" })}
              ${deleteUser.firstName} ${deleteUser.lastName} 
              ${intl.formatMessage({ id: "app.editCompany.delete2" })} "${company?.name
              }"`}
            onSubmit={handleDeleteAssign}
            onClose={() => {
              setDeleteUser(null);
              turnOffModal(USER_DELETE_FORM);
            }}
          />
        )}
      </SideModal>
      <SideModal
        active={isModal(USER_EXPAND_EDIT_FORM)}
        visible={getModalVisibility(USER_EXPAND_EDIT_FORM)}
        onClose={() => {
          turnOffModal(USER_EXPAND_EDIT_FORM);
          setEditUser(null);
        }}
      >
        {editUser && (
          <AssignUserForm
            user={editUser}
            role={editUser.companyRole}
            onSubmit={() => {
              setEditUser(null);
              turnOffModal(USER_EXPAND_EDIT_FORM);
            }}
          />
        )}
      </SideModal>
      <SideModal
        active={isModal(USER_ASSING_FORM)}
        visible={getModalVisibility(USER_ASSING_FORM)}
        onClose={() => turnOffModal(USER_ASSING_FORM)}
      >
        <SearchUserForm />
      </SideModal>
      <SideModal
        active={isModal(ASSIGN_USER_SIDE_MODAL_LEFT)}
        visible={getModalVisibility(ASSIGN_USER_SIDE_MODAL_LEFT)}
        onClose={() => turnOffModal(ASSIGN_USER_SIDE_MODAL_LEFT)}
        left
      >
        {getModalContent(ASSIGN_USER_SIDE_MODAL_LEFT)}
      </SideModal>
      <SideModal
        active={isModal(USER_EXPAND_CREATE_FORM)}
        visible={getModalVisibility(USER_EXPAND_CREATE_FORM)}
        onClose={() => turnOffModal(USER_EXPAND_CREATE_FORM)}
      >
        <CreateUserForm onCreate={onSuccess} openAssignModal={true} />
      </SideModal>
    </Layout>
  );
}
