import cn from "classnames";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { InputWembii } from "../../InputWembii";
import { useBoardCore } from "../../../../cores/boards";
import { Board, BoardList } from "../../../../cores/boards/interfaces/api/get-board";
import { useMeCore } from "../../../../cores/me";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";
import { MOVE_CARDS_LOADING } from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { MOVE_CARDS_MODAL } from "../../../../constants/modal.constant";
import { ListCard } from "../../../../cores/boards/interfaces/api/get-card";

interface Props {
  board: Board | null;
  card?: ListCard;
  list?: BoardList;
  suggested: any[];
}

export default function MoveCards({ board, list, card, suggested }: Props) {
  const intl = useIntl();
  const { company } = useMeCore();
  const { isLoading, turnOffModal } = useGlobalCore();
  const { boards, listNames, getFilters, getLists, putMoveCards, getBusinessBoard, getCard } = useBoardCore();
  const [boardId, setBoardId] = useState(board?.id || "");
  const [listId, setListId] = useState(list?.id || "");

  useEffect(() => {
    if (listNames.length) {
      setListId(listNames[0].id);
    }
  }, [listNames]);

  useEffect(() => {
    if (boardId) {
      getLists(company?.id!, boardId);
    }
  }, [boardId, company, getLists]);

  const disabled = useMemo(() =>
    !listId || list?.id === listId  
  , [list, listId]);

  const onMovingCard = useCallback(() => {
    putMoveCards(company?.id!, board?.id!, { 
      listId: list?.id,
      cardId: card?.id,
      destinationBoardId: boardId,
      destinationListId: listId
    }, () => {
      const filter = getFilters(board?.id!);
      getBusinessBoard(company?.id!, board?.id!, filter, false);
      if (card) {
        getCard(company?.id!, board?.id!, card?.list!, card?.id!);
      }
      turnOffModal(MOVE_CARDS_MODAL);
    });
  }, [list, card, boardId, listId, company, board, putMoveCards, getFilters, getBusinessBoard, getCard, turnOffModal]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.crmBusiness.moveCards.title" })}
            </h2>

            <div className="mb-2">{intl.formatMessage({ "id": "app.crmBusiness.moveCards.suggested" })}</div>
            {suggested.map((suggestion: any) => (
              <div
                className={cn("p-2 bg-light my-1 pointer", { 'text-primary': suggestion.id === listId })}
                onClick={() => setListId(suggestion.id)}
              >
                <FontAwesomeIcon
                  icon={["fas", suggestion.right ? "arrow-right" : "arrow-left" ]}
                  className="mr-2"
                />
                {suggestion.name}
              </div>
            ))}

            <hr className="horizontal-separator" />
            <div className="my-2">{intl.formatMessage({ "id": "app.crmBusiness.moveCards.destination" })}</div>
            <InputWembii
              type="select"
              label={intl.formatMessage({ "id": "app.sidebar.crm.business" })}
              value={boardId}
              onChange={setBoardId}
            >
              {boards.map((board) => (
                <option key={board.id} value={board.id}>
                  {board.name}
                </option>
              ))}
            </InputWembii>
            <InputWembii
              type="select"
              label={intl.formatMessage({ "id": "app.crmBusiness.list" })}
              value={listId}
              onChange={setListId}
            >
              {listNames.map((list) => (
                <option key={list.id} value={list.id}>
                  {list.name}
                </option>
              ))}
            </InputWembii>

            <LoadingSpinnerWembii active={isLoading(MOVE_CARDS_LOADING)}>
              <Button
                color="primary"
                className="mr-2"
                onClick={onMovingCard}
                disabled={disabled}
              >
                {intl.formatMessage({ id: "app.crmBusiness.moveCards.title" })}
              </Button>
            </LoadingSpinnerWembii>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
