import socialApiClient from "../../helpers/services/social-api-client";
import { GetFacebookAdsBindResponse } from "../interface/api/get-facebook-ads-bind";
import { GetFacebookAdsAccountResponse } from "../interface/api/get-facebook-ads-account";
import {
  PostFacebookAdsBindRequest,
  PostFacebookAdsBindResponse,
} from "../interface/api/post-facebook-ads-bind";
import { facebookAdsMapper } from "./mapper";

export const facebookAdsApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`facebook/ads/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostFacebookAdsBindRequest) {
    return socialApiClient()
      .post<PostFacebookAdsBindResponse>(`facebook/ads/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        facebookAdsMapper.mapResponseToFacebookAdsSetting(data)
      );
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetFacebookAdsBindResponse>(`facebook/ads`, {
        params: { companyId },
      })
      .then(({ data }) =>
        facebookAdsMapper.mapResponseToFacebookAdsSetting(data)
      );
  },
  accounts(companyId: string, token: string) {
    return socialApiClient()
      .get<GetFacebookAdsAccountResponse>(`facebook/ads/accounts`, {
        params: { companyId, token },
      })
      .then(({ data }) =>
        facebookAdsMapper.mapResponseToFacebookAdsAccounts(data)
      );
  },
};
