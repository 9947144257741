import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QueryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { COMPANY_CREATE_CONFIG, COMPANY_DELETE_FORM } from "../../constants/modal.constant";
import { useCompanyCore } from "../../cores/company";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import {
  Company,
  MeCompanyPayload,
} from "../../cores/me/interfaces/api/get-meCompany";
import { usePaymentCore } from "../../cores/payment";
import { usePlanCore } from "../../cores/plan";
import { useResellerCore } from "../../cores/reseller";
import { useRouterCore } from "../../cores/router";
import AreYouSure from "../component/AreYouSure";
import CompanyCard from "../component/companies/CompanyCard";
import CreateCompanyForm from "../component/companies/CreateCompanyForm";
import { InputWembii } from "../component/InputWembii";
import { NoData } from "../component/NoData";
import { PanelWembii } from "../component/PanelWembii";
import { UpgradePlanButton } from "../component/plan/UpgradePlanButton";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";

const searchOptions = [
  { value: "name__p", label: "Nombre" },
  { value: "descripcion__p", label: "Descripcion" },
  { value: "website__p", label: "Sitio Web" },
];

export default function MeCompanies() {
  const intl = useIntl();
  const { search } = useLocation();
  const {
    getMeCompanies,
    removeMeCompany,
    deselectCompany,
    companies,
    userPlan,
    user,
  } = useMeCore();
  const { turnOffModal, turnOnModal, reset, getModalVisibility, isModal } = useGlobalCore();
  const { postStripeCheckSubscription } = usePaymentCore();
  const { deleteCompany, getCompanies, company } = useCompanyCore();
  const { goToEditCompany } = useRouterCore();
  const { resellers } = useResellerCore();
  const { plans, getPlans } = usePlanCore();
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [filter, setFilter] = useState("");
  const [plan, setPlan] = useState("");
  const [reseller, setReseller] = useState(company?.reseller?.id);
  const [searchOption, setSearchOption] = useState("Descripcion__p");
  const [searchResults, setSearchResults] = useState<MeCompanyPayload[]>([]);

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  useEffect(() => {
    getCompanies();
  }, [
    getCompanies,
    plan,
    reseller,
  ]);

  useEffect(() => {
    const results = companies.filter((company) =>
      company.company.name.toLowerCase().includes(filter.toLowerCase()) ||
      company.company.description.toLowerCase().includes(filter.toLowerCase()) ||
      company.company.website.toLowerCase().includes(filter.toLowerCase()) 
    );
    setSearchResults(results);
  }, [filter, companies]);

  useEffect(() => {
    const { newSubscription, stripeSessionId, subscriptionId } = QueryString.parse(search);
    if (newSubscription) {
      reset();
      deselectCompany();
    }
    if (stripeSessionId && subscriptionId) {
      postStripeCheckSubscription(
        stripeSessionId as string,
        subscriptionId as string,
        () => {
          reset();
          deselectCompany();
        }
      );
    }
  }, [deselectCompany, postStripeCheckSubscription, reset, search]);

  const toggleSideModal = useCallback(() => {
    turnOnModal(COMPANY_CREATE_CONFIG, true);
  }, [turnOnModal]);

  const ownerCompanies = useMemo(
    () => companies.filter(({ role }) => role.owner === true).length,
    [companies]
  );
  const canCreate = useMemo(
    () => userPlan && ownerCompanies < userPlan?.rules.companyAmount,
    [userPlan, ownerCompanies]
  );

  const onEditCompany = (companyId: string) => {
    goToEditCompany(companyId);
  };

  const onDeleteCompany = () => {
    deleteCompany(selectedCompany?.id!, () => {
      removeMeCompany(selectedCompany?.id!);
      setSelectedCompany(null);
      turnOffModal(COMPANY_DELETE_FORM);
    });
  };

  useEffect(() => {
    getMeCompanies();
  }, [getMeCompanies]);

  useEffect(() => {
    if (companies.length === 0 && user?.verified) {
      turnOnModal(COMPANY_CREATE_CONFIG, true);
    }
  }, [companies, user, turnOnModal]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.company.title" })}
        rightChildren={
          <>
            {!canCreate && <UpgradePlanButton />}
            {canCreate && companies.length > 0 && (
              <Button
                className="whiteButton"
                onClick={toggleSideModal}
                disabled={!canCreate}
              >
                {intl.formatMessage({ id: "app.company.createCompany" })}
              </Button>
            )}
          </>
        }
      />
      <Content>
        {!companies.length && (
          <NoData
            image="generic3" 
            text={intl.formatMessage({ id: "app.company.notHaveCompanies" })}
            actionLabel="Crear Empresa"
            action={toggleSideModal}
            horizontal
          />
        )}

        {!companies.length ? null : (
          <PanelWembii className="w-100 px-4 py-3 my-3 pointer">
          <Row>
            <Col xs="12" lg="6">
              <InputWembii
                mb={false}
                label={intl.formatMessage({ id: "app.company.search" })}
                type="text"
                placeholder={intl.formatMessage({ id: "app.company.searchCompnay" })}
                rounded
                onChange={(v) => setFilter(v)}
                style={{ borderRadius: "0px 10px 10px 0px" }}
                icon={
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="1x"
                    color="grey"
                    className="pointer mb-2"
                  />
                }
                prepend={
                  <InputWembii
                    type="select"
                    onChange={(value: string) => setSearchOption(value)}
                    value={searchOption}
                    mb={false}
                    style={{ borderRadius: "10px 0px 0px 10px" }}
                  >
                    {searchOptions.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </InputWembii>
                }
              />
            </Col>
            <Col xs="6" lg="2">
            <InputWembii
                label="Plan"
                type="select"
                onChange={(value: string) => setPlan(value)}
                value={plan}
              >
                {[{ id: "", name: `${intl.formatMessage({ id: "app.adminUsers.all"  })}` }, 
                ...plans].map(({ id, name }) => 
                (
                  <option key={id} value={id}>
                    {name} 
                  </option>
                ))}
              </InputWembii>
          </Col>
          <Col xs="6" lg="2">
              <InputWembii
                label="Reseller"
                type="select"
                onChange={(value: string) => setReseller(value)}
                value={reseller}
                disabled={user?.userRole?.reseller}
              >
                {[{ id: "", name: `${intl.formatMessage({ id: "app.adminUsers.all"  })}`  },
                 ...resellers].map(
                  ({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  )
                )}
              </InputWembii>
          </Col>
        </Row>
          </PanelWembii>
        )}
        <Container fluid>
          <Row>
            {searchResults.map((company) => (
              <Col
                xs="12"
                sm="6"
                md="4"
                lg="3"
                className="mb-4 d-flex justify-content-center"
                key={company.company.id}
              >
                <CompanyCard
                  company={company.company}
                  role={company.role}
                  plan={company.plan}
                  onEditCompany={onEditCompany}
                  onDeleteCompany={() => setSelectedCompany(company.company)}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </Content>

      <SideModal
        active={isModal(COMPANY_CREATE_CONFIG)}
        visible={getModalVisibility(COMPANY_CREATE_CONFIG)}
        onClose={() => {
          turnOffModal(COMPANY_CREATE_CONFIG);
        }}
      >
        <CreateCompanyForm
          autoSelectOnCreation={true}
          onSubmit={() => {
            turnOffModal(COMPANY_CREATE_CONFIG);
            getMeCompanies();
          }}
        />
      </SideModal>
      <SideModal
        active={isModal(COMPANY_DELETE_FORM)}
        visible={getModalVisibility(COMPANY_DELETE_FORM)}
        onClose={() => {
          setSelectedCompany(null);
          turnOffModal(COMPANY_DELETE_FORM);
        }}
      >
        {selectedCompany && (
          <AreYouSure
            message={`${intl.formatMessage({ id: "app.adminCompanies.error4" })} "${selectedCompany?.name}"`}
            onSubmit={onDeleteCompany}
            onClose={() => {
              setSelectedCompany(null);
              turnOffModal(COMPANY_DELETE_FORM);
            }}
          />
        )}
      </SideModal>
    </Layout>
  );
}
