import React, { useCallback, useMemo } from "react";
import { Container } from "reactstrap";
import { useAnalyticsCore } from "../../../cores/analytics";
import { ChartMetric } from "../../../cores/analytics/interface/ChartMetric";
import { Metric2 } from "../../../cores/analytics/interface/Metric2";
import { ChartContainer } from "./ChartContainer";
import { BarChart } from "./charts/BarChart";
import { PieChart } from "./charts/PieChart";
import { Legend } from "./Legend";
import { Monitors } from "./Monitors";
import { useIntl } from "react-intl";


export const InstagramCharts = () => {
  const intl = useIntl();
  const {
    instagramInsights,
    //buildInstagramPostInsights,
    toChartMetric,
    //instagramFeedPost,
    // date,
    // setSelectedPostGroup,
  } = useAnalyticsCore();
  //const { turnOnModal } = useGlobalCore();

  // const instagramPostInsights = useMemo(
  //   () =>
  //     buildInstagramPostInsights(
  //       date.since,
  //       moment(date.until).add(1, "day").toString()
  //     ),
  //   [buildInstagramPostInsights, date.since, date.until]
  // );

  const impressionData: Metric2[] = useMemo(() => [
    { ...instagramInsights.impressions, type: "line" },
    { ...instagramInsights.reach, type: "line" },
    // {
    //   ...instagramPostInsights,
    //   type: "bar",
    //   axis: "right",
    //   color: COLOR_SECONDARY,
    // },
  ], [instagramInsights.impressions, instagramInsights.reach]);

  // const followersData: Metric2[] = [
  //   {
  //     ...instagramInsights.follower_count,
  //     type: "line",
  //   },
  //   // {
  //   //   ...instagramPostInsights,
  //   //   type: "bar",
  //   //   axis: "right",
  //   //   color: COLOR_SECONDARY,
  //   // },
  // ];

  const interactionData: Metric2[] = useMemo(() => [
    { ...instagramInsights.profile_views, type: "line" },
    { ...instagramInsights.website_clicks, type: "line" },
    // {
    //   ...instagramPostInsights,
    //   type: "bar",
    //   axis: "right",
    //   color: COLOR_SECONDARY,
    // },
  ], [instagramInsights.profile_views, instagramInsights.website_clicks]);

  const fansCitiesData: Metric2 = useMemo(() => ({
    ...instagramInsights.audience_city,
    type: "pie",
  }), [instagramInsights.audience_city]);

  const fansCountryData: Metric2 = useMemo(() => ({
    ...instagramInsights.audience_country,
    type: "pie",
  }), [instagramInsights.audience_country]);

  const fansGenderAgeData: Metric2 = useMemo(() => ({
    ...instagramInsights.audience_gender_age,
    type: "horizontalBar",
  }), [instagramInsights.audience_gender_age]);

  const impressionChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(impressionData),
    [impressionData, toChartMetric]
  );

  // const followersChartMetrics: ChartMetric = useMemo(
  //   () => toChartMetric(followersData),
  //   [followersData, toChartMetric]
  // );

  const interactionChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(interactionData),
    [interactionData, toChartMetric]
  );

  const fansCitiesChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([fansCitiesData]),
    [fansCitiesData, toChartMetric]
  );

  const fansCountryChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([fansCountryData]),
    [fansCountryData, toChartMetric]
  );

  const fansGenderAgeChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([fansGenderAgeData]),
    [fansGenderAgeData, toChartMetric]
  );

  const impressionMonitor = useCallback(
    () => <Monitors metrics={impressionData} />,
    [impressionData]
  );

  // const followersMonitor = useCallback(
  //   () => <Monitors metrics={followersData} />,
  //   [followersData]
  // );

  const interactionMonitor = useCallback(
    () => <Monitors metrics={interactionData} />,
    [interactionData]
  );

  // const onClickPostBar = useCallback(
  //   (chartElement: { _view: { label: string } }[]) => {
  //     if (!chartElement.length) return;
  //     const postIds = instagramFeedPost
  //       .filter(
  //         ({ timestamp }) =>
  //           moment(timestamp * 1000)
  //             .format("MMMM Do YYYY")
  //             .toString() === chartElement[0]._view.label
  //       )
  //       .map(({ id }) => id);
  //     setSelectedPostGroup(postIds);
  //     turnOnModal(ANALYTICS_POST_GROUP_EXPAND, true, Position.LEFT);
  //   },
  //   [instagramFeedPost, setSelectedPostGroup, turnOnModal]
  // );

  return (
    <Container>
      {/* <ChartContainer
        id="instagramChart"
        height={250}
        className="mb-4"
        title="Crecimiento"
        message="Haz click en las barras de publicaciones para ver detalles"
        monitors={followersMonitor()}
      >
        <BarChart
          labels={followersChartMetrics.labels}
          datasets={followersChartMetrics.datasets}
          //onElementClick={onClickPostBar}
        />
      </ChartContainer> */}
      <ChartContainer
        id="instagramChart"
        height={250}
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsInst.title1" })}
        monitors={impressionMonitor()}
      >
        <BarChart
          labels={impressionChartMetrics.labels}
          datasets={impressionChartMetrics.datasets}
          //onElementClick={onClickPostBar}
        />
      </ChartContainer>
      <ChartContainer
        id="instagramChart"
        height={250}
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsInst.title2" })}
        monitors={interactionMonitor()}
      >
        <BarChart
          labels={interactionChartMetrics.labels}
          datasets={interactionChartMetrics.datasets}
          //onElementClick={onClickPostBar}
        />
      </ChartContainer>
      <ChartContainer
        id="instagramChart"
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsInst.title3" })}
        height={300}
        legend={<Legend metric={fansCitiesData} />}
        isEmptyData={
          !fansCitiesData.values || fansCitiesData.values?.length === 0
        }
      >
        <PieChart
          labels={fansCitiesChartMetrics.labels}
          datasets={fansCitiesChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="instagramChart"
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsInst.title4" })}
        height={300}
        legend={<Legend metric={fansCountryData} />}
        isEmptyData={
          !fansCountryData.values || fansCountryData.values?.length === 0
        }
      >
        <PieChart
          labels={fansCountryChartMetrics.labels}
          datasets={fansCountryChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="instagramChart"
        height={300}
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticsInst.title5" })}
        legend={<Legend metric={fansGenderAgeData} />}
        isEmptyData={
          !fansGenderAgeData.values || fansGenderAgeData.values.length === 0
        }
      >
        <BarChart
          colorByBar
          horizontal
          labels={fansGenderAgeChartMetrics.labels}
          datasets={fansGenderAgeChartMetrics.datasets}
        />
      </ChartContainer>
    </Container>
  );
};
