import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { useContactCore } from "../../../cores/contacts";
import { useMeCore } from "../../../cores/me";
import { InputWembii } from "../InputWembii";
import { PanelWembii } from "../PanelWembii";
import { useIntl } from "react-intl";
import { PaginationWemmbii } from "../pagination/Pagination";
import { useCompanyCore } from "../../../cores/company";

const SearchOptions = [
  { value: "name__p", label: "app.contacts.companies.name" },
  { value: "rut__p", label: "app.contacts.companies.rut" },
  { value: "country__p", label: "app.contacts.companies.country" },
  { value: "city__p", label: "app.contacts.companies.city" },
  { value: "phone__p", label: "app.contacts.companies.phone" },
  { value: "website__p", label: "app.contacts.companies.website" }
];

export const CompaniesBar = () => {
  const intl = useIntl();
  const [debounceSearchText, setDebounceSearchText] = useState("");
  const { getCompanies, companies, companyFilters, setCompanyFilters, customFields } = useContactCore();
  const { company, isAdmin, isReseller, companyRole, user } = useMeCore();
  const { users } = useCompanyCore();
  const [searchOption, setSearchOption] = useState("name__p");
  const [search, setSearch] = useState("");

  const defaultOwner = useMemo(() => {
    if (!isAdmin && !isReseller && !companyRole?.owner && !companyRole?.admin) {
      return user?.id;
    } else {
      return "";
    }
  }, [
    isAdmin,
    isReseller,
    companyRole,
    user,
  ]);

  const assigments = useMemo(() => {
    if (!isAdmin && !isReseller && !companyRole?.owner && !companyRole?.admin) {
      return [
        { label: intl.formatMessage({ id: "app.source.all" }), value: "" },
        { label: `${user?.firstName} ${user?.lastName}`, value: user?.id }
      ];
    } else {
      return [
        { label: intl.formatMessage({ id: "app.source.all" }), value: "" },
        ...users.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id
        }))
      ];
    }
  }, [
    isAdmin,
    isReseller,
    companyRole,
    users,
    user,
    intl
  ]);

  const debouncedSearch = useRef(
    _.debounce((search) => setSearch(search), 1000)
  ).current;

  useEffect(() => {
    setCompanyFilters({
      page: 1,
      perPage: 10,
      owner: defaultOwner,
      [searchOption]: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchOption, setCompanyFilters]);

  useEffect(() => {
    debouncedSearch(debounceSearchText);
  }, [debounceSearchText, debouncedSearch]);

  useEffect(() => {
    getCompanies(company!.id, companyFilters);
  }, [company, companyFilters, getCompanies]);

  const changeFilters = useCallback((field) => {
    setCompanyFilters({ ...companyFilters, ...field });
  }, [companyFilters, setCompanyFilters]);

  const searchOptions = useMemo(() => [
    ...SearchOptions.map((option) => ({
      value: option.value,
      label: intl.formatMessage({id: option.label})
    })),
    ...customFields.map((field) => ({ value: field.id, label: field.name }))
  ], [customFields, intl]);

  return (
    <PanelWembii className="w-100 px-4 py-3 my-3 mb-5" shadow>
      <Row>
        <Col xs="12" lg="3">
          <InputWembii
            label={intl.formatMessage({ id: "app.contacts.search" })}
            placeholder={intl.formatMessage({ id: "app.contacts.search1" })}
            type="text"
            onChange={(v) => setDebounceSearchText(v)}
            value={debounceSearchText}
            style={{ borderRadius: "0px 10px 10px 0px" }}
            prepend={
              <InputWembii
                type="select"
                onChange={(v) => setSearchOption(v)}
                value={searchOption}
                mb={false}
                style={{
                  borderRadius: "10px 0px 0px 10px",
                  maxWidth: "125px"
                }}
              >
                {searchOptions.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </InputWembii>
            }
          />
        </Col>
        <Col xs="12" lg="3">
          <InputWembii
            type="select"
            label={intl.formatMessage({ id: "app.contacts.owners" })}
            onChange={(owner) => setCompanyFilters({ ...companyFilters, owner })}
            value={companyFilters.owner}
            mb={false}
          >
            {assigments.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="6">
          <div className="d-flex justify-content-start justify-content-lg-end">
            <PaginationWemmbii
              page={companyFilters.page}
              setPage={(page) => changeFilters({ page })}
              perPage={companyFilters.perPage}
              setPerPage={(perPage) => changeFilters({ perPage })}
              disabledPages={companies.length < companyFilters.perPage}
            />
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
