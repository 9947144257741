import React, { useCallback } from "react";
import { Board } from "../../../cores/boards/interfaces/api/get-board";
import { TitleWembii } from "../TitleWembii";
import { PanelWembii } from "../PanelWembii";
import { CardBody, CardTitle } from "reactstrap";
import { useRouterCore } from "../../../cores/router";

interface Props {
  board: Board;
}

export default function BoardCard(props: Props) {
  const { board } = props;
  const { goToEditBoard } = useRouterCore();

  const onClickCard = useCallback(() => {
    goToEditBoard(board.id);
  }, [goToEditBoard, board]);

  return (
    <PanelWembii
      shadow
      borderColor={board.color}
      className="align-content-center shadow-hover d-flex justify-content-center p-3 pointer"
      onClick={onClickCard}
      style={{ minHeight: 150 }}
    >
      <CardBody className="p-2 d-flex flex-column">
        <CardTitle>
          <TitleWembii
            Tag="h4"
            className="overflow-hidden"
          >
            {board.name}
          </TitleWembii>
        </CardTitle>
      </CardBody>
    </PanelWembii>
  );
}
