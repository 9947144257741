import socialApiClient from "../../helpers/services/social-api-client";


export const emailApiService = {

    postEmailTextEditorImage(image: File) {
      let bodyFormData = new FormData();
      bodyFormData.append("images", image);
  
      return socialApiClient()
        .post(`email/image`, bodyFormData, {})
        .then(({ data }) => data.payload);
    },
  };
