import { produce } from "immer";
import moment, { Moment } from "moment";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Hashtag } from "../interfaces/api/get-hashtags";
import { SocialPost } from "../interfaces/api/get-social-posts";
import { Mentions } from "../interfaces/Mention";
import { PostError } from "../interfaces/postError";
import { PostImage } from "../interfaces/postImage";
import { PostVideo } from "../interfaces/postVideo";
import { SocialProvider } from "../interfaces/socialProvider";
import {
  addMentionAction,
  addPostErrorAction,
  addStoryErrorAction,
  clear,
  clearImages,
  clearPostMedia,
  clearSelectedPostAction,
  clearSocialProviders,
  deleteImage,
  deletePostMedia,
  deleteSocialProvider,
  deleteVideo,
  moveMentions,
  removePostErrorAction,
  removeStoryErrorAction,
  resetSocialAction,
  selectPostAction,
  setDateAction,
  setEmoji,
  setHashtag,
  setImages,
  setInputCursorIndex,
  setSocialActionAction,
  setSocialProvider,
  setText,
  setVideo,
  setVideoSize,
  setVideoDuration,
  SocialPostActions,
  updateImage,
} from "./actions";
import {
  deleteHashtagFailureAction,
  deleteHashtagInitAction,
  deleteHashtagSuccessAction,
  deleteSocialPostFailureAction,
  deleteSocialPostInitAction,
  deleteSocialPostSuccessAction,
  getHashtagsFailureAction,
  getHashtagsInitAction,
  getHashtagsSuccessAction,
  getSocialPostsFailureAction,
  getSocialPostsInitAction,
  getSocialPostsSuccessAction,
  postHashtagFailureAction,
  postHashtagInitAction,
  postHashtagSuccessAction,
  SocialPostsApiAction,
  updateSocialPostSuccessAction,
} from "./api-actions";

export interface SocialPostState {
  socialPosts: {
    ids: string[];
    entities: Record<string, SocialPost>;
  };
  socialProviders: {
    ids: string[];
    entities: Record<string, SocialProvider>;
  };
  post: SocialPost | null;
  images: {
    ids: string[];
    entities: Record<string, PostImage>;
  };
  hashtags: {
    ids: string[];
    entities: Record<string, Hashtag>;
  };
  mentions: {
    ids: string[];
    entities: Record<string, Mentions>;
  };
  action: string;
  date: Moment;
  video: PostVideo | null;
  text: string;
  inputCursorIndex: number;
  postErrors: {
    ids: string[];
    entities: Record<string, PostError>;
  };
  storyErrors: {
    ids: string[];
    entities: Record<string, PostError>;
  };
}

const initialState: SocialPostState = {
  socialPosts: { ids: [], entities: {} },
  socialProviders: { ids: [], entities: {} },
  images: { ids: [], entities: {} },
  hashtags: { ids: [], entities: {} },
  mentions: { ids: [], entities: {} },
  post: null,
  text: "",
  inputCursorIndex: 0,
  action: "",
  date: moment(),
  video: null,
  postErrors: { ids: [], entities: {} },
  storyErrors: { ids: [], entities: {} },
};

const socialPostsAdapter = createEntityAdapter<SocialPost>(
  (payload) => payload.post.id
);
const socialProvidersAdapter = createEntityAdapter<SocialProvider>(
  (payload) => payload.id
);
const imagesAdapter = createEntityAdapter<PostImage>((payload) => payload.name);
const mentionsAdapter = createEntityAdapter<Mentions>((payload) => payload.id);
const hashtagsAdapter = createEntityAdapter<Hashtag>((payload) => payload.id);
const errorAdapter = createEntityAdapter<PostError>(
  (payload) => `${payload.socialProviderId}-${payload.type}`
);

export default produce(
  (
    draft: SocialPostState = initialState,
    action: SocialPostActions | GlobalActions | SocialPostsApiAction
  ) => {
    switch (action.type) {
      case setSocialProvider.type:
        socialProvidersAdapter.setOne(
          action.socialProvider,
          draft.socialProviders
        );
        return draft;
      case deleteSocialProvider.type:
        socialProvidersAdapter.removeOne(
          action.socialProvider.id,
          draft.socialProviders
        );
        return draft;
      case clearSocialProviders.type:
        socialProvidersAdapter.removeAll(draft.socialProviders);
        return draft;
      case setImages.type:
        imagesAdapter.updateMany(action.images, draft.images);
        return draft;
      case deleteImage.type:
        imagesAdapter.removeOne(action.image.name, draft.images);
        return draft;
      case clearImages.type:
        imagesAdapter.removeAll(draft.images);
        return draft;
      case updateImage.type:
        imagesAdapter.updateOne(action.image, draft.images);
        return draft;
      case moveMentions.type:
        const updateMentions = draft.mentions.ids
          .map((id) => draft.mentions.entities[id])
          .filter((mention) => mention.offset >= action.inputCursorIndex)
          .map((mention) => ({
            ...mention,
            offset: mention.offset + action.offset
          }));
        mentionsAdapter.updateMany(updateMentions, draft.mentions);
        const removeMentions = draft.mentions.ids
          .map((id) => draft.mentions.entities[id])
          .filter((mention) => {
            const substring = draft.text.substring(mention.offset + 1, mention.offset + 1 + mention.mentions[0].longitud);
            return substring !== mention.mentions[0].username
          })
          .map((mention) => mention.id);
        mentionsAdapter.removeMany(removeMentions, draft.mentions);

        return draft;
      case setText.type:
        draft.text = action.text;
        return draft;
      case setInputCursorIndex.type:
        draft.inputCursorIndex = action.inputCursorIndex;
        return draft;
      case setVideo.type:
        draft.video = action.video;
        return draft;
      case setVideoDuration.type:
        if (draft.video)
          draft.video.duration = action.duration;
        return draft;
      case setVideoSize.type:
        if (draft.video) {
          draft.video.width = action.width;
          draft.video.height = action.height;
        }
        return draft;
      case deleteVideo.type:
        draft.video = null;
        return draft;
      case getHashtagsSuccessAction.type:
        hashtagsAdapter.addMany(action.hashtags, draft.hashtags);
        return draft;
      case postHashtagSuccessAction.type:
        hashtagsAdapter.setOne(action.hashtag, draft.hashtags);
        return draft;
      case deleteHashtagSuccessAction.type:
        hashtagsAdapter.removeOne(+action.id, draft.hashtags);
        return draft;
      case addPostErrorAction.type:
        errorAdapter.setOne(action.error, draft.postErrors);
        return draft;
      case removePostErrorAction.type:
        errorAdapter.removeOne(action.id, draft.postErrors);
        return draft;
      case addStoryErrorAction.type:
        errorAdapter.setOne(action.error, draft.storyErrors);
        return draft;
      case removeStoryErrorAction.type:
        errorAdapter.removeOne(action.id, draft.storyErrors);
        return draft;
      case selectPostAction.type:
        draft.post = action.post;
        draft.text = action.post.post.text;
        mentionsAdapter.addMany(action.post.mention, draft.mentions);
        return draft;
      case clearSelectedPostAction.type:
        draft.post = null;
        draft.text = "";
        return draft;
      case clearPostMedia.type:
        if (draft.post) {
          draft.post.media = [];
        }
        return draft;
      case deletePostMedia.type:
        if (draft.post) {
          let media = draft.post.media;
          media = media.filter((image) => image.key !== action.image.key);
          draft.post.media = media;
        }
        return draft;
      case resetSocialAction.type:
        return { ...initialState, hashtags: draft.hashtags };
      case resetAction.type:
        return { ...initialState, hashtags: draft.hashtags };
      case getSocialPostsSuccessAction.type:
        socialPostsAdapter.addMany(action.posts, draft.socialPosts);
        return draft;
      case deleteSocialPostSuccessAction.type:
        socialPostsAdapter.removeOne(action.id, draft.socialPosts);
        return draft;
      case updateSocialPostSuccessAction.type:
        socialPostsAdapter.updateOne(action.post, draft.socialPosts);
        return draft;
      case setSocialActionAction.type:
        draft.action = action.action;
        return draft;
      case setDateAction.type:
        draft.date = action.date;
        return draft;
      case setEmoji.type:
        draft.text = draft.text.slice(0, draft.inputCursorIndex)
          + action.emoji
          + draft.text.slice(draft.inputCursorIndex);
        draft.inputCursorIndex += action.emoji.length;
        return draft;
      case setHashtag.type:
        draft.text = draft.text.slice(0, draft.inputCursorIndex)
          + `${action.hashtag} `
          + draft.text.slice(draft.inputCursorIndex);
        draft.inputCursorIndex += action.hashtag.length + 1;
        return draft;
      case addMentionAction.type:
        mentionsAdapter.setOne(action.mention, draft.mentions);
        draft.text = draft.text.slice(0, draft.inputCursorIndex)
          + `@${action.mention.mentions[0].username} `
          + draft.text.slice(draft.inputCursorIndex);
        draft.inputCursorIndex += action.mention.mentions[0].longitud + 2;
        return draft;
      case clear.type:
      case getSocialPostsInitAction.type:
      case getSocialPostsFailureAction.type:
      case deleteSocialPostInitAction.type:
      case deleteSocialPostFailureAction.type:
      case deleteHashtagInitAction.type:
      case deleteHashtagFailureAction.type:
      case postHashtagInitAction.type:
      case postHashtagFailureAction.type:
      case getHashtagsInitAction.type:
      case getHashtagsFailureAction.type:
      default:
        return draft;
    }
  }
);
