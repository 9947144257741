import cn from "classnames";
import React, { useCallback } from "react";
import { useCanvaCore } from "../../../../cores/canva";
import { urlToDataURL } from "../../../../cores/utils";
import { useIntl } from "react-intl";
import { TooltipWembii } from "../../TooltipWembii";

interface Props {
  onPublish: (file: any, picture: string) => void;
  disabled: boolean;
}

export const CanvaButton = ({ onPublish, disabled }: Props) => {
  const intl = useIntl();
  const { openCanva } = useCanvaCore();

  const onCanvaButtonClicked = useCallback(() => {
    if (!disabled) {
      openCanva(async ({ exportUrl, designTitle }) => {
        const file: any = {
          name: designTitle,
          lastModified: new Date().getTime(),
        };
  
        const picture: string = await urlToDataURL(exportUrl);
        onPublish(file, picture);
      });
    }
  }, [onPublish, openCanva, disabled]);

  return (
    <>
      <div id="canva" className="d-flex h-100 justify-content-center">
        <span
          onClick={onCanvaButtonClicked}
          className={cn("canva-btn canva-btn-theme-default canva-btn-size-s m-0", {
            'opacity-4': disabled,
            'cursor-not-allowed': disabled,
          })}
        >
          <span className="canva-btn-i" />
          {intl.formatMessage({ id: "app.socialMediaPost.canvaDesing" })}
        </span>
      </div>
      {disabled && (
        <TooltipWembii 
          id="canva"
          text={intl.formatMessage({ id: "app.plan.upgrade" })} 
        />
      )}
    </>
  );
};
