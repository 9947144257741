import React, { useEffect, useMemo } from "react";
import { FACEBOOK_INSIGHT_LOADING } from "../../constants/loading.constant";
import { ANALYTICS_EXPAND } from "../../constants/modal.constant";
import { useAnalyticsCore } from "../../cores/analytics";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { AnalyticsDatePicker } from "../component/analytics/AnalyticsDatePicker";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import { FacebookIcon } from "../component/social/Facebook/FacebookIcon";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { FacebookCharts } from "../component/analytics/FacebookCharts";
import moment from "moment";
import { useIntl } from "react-intl";

export const FacebookAnalytics = () => {
  const intl = useIntl();
  const { getFacebookInsights, date } = useAnalyticsCore();
  const {
    isLoading,
    isModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
  } = useGlobalCore();
  const {
    company,
    socialSettings,
    companyPlan,
    companyRole,
    isAdmin,
    isReseller,
  } = useMeCore();
  const { goToSocial } = useRouterCore();

  const isSync = useMemo(
    () => socialSettings && socialSettings.facebook,
    [socialSettings]
  );

  const isFutureDate = (date: string) => moment(date).isAfter(moment());

  const areDatesInverted = (since: string, until: string) =>
    moment(since).isAfter(moment(until));

  useEffect(() => {
    if (
      !isSync ||
      !companyPlan?.rules.allowAnalytics ||
      (!companyRole?.analitycs &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [goToSocial, isSync, companyPlan, companyRole, isAdmin, isReseller]);

  useEffect(() => {
    if (
      isSync &&
      !isFutureDate(date.since) &&
      !isFutureDate(date.until) &&
      !areDatesInverted(date.since, date.until)
    ) {
      getFacebookInsights(company!.id, date.since, date.until, "Daily");
      getFacebookInsights(company!.id, date.since, date.until, "Lifetime", 10);
    }
  }, [company, date, getFacebookInsights, isSync]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.analiyticsFace.title" })}
        icon={<FacebookIcon active size="3x" />}
        rightChildren={<AnalyticsDatePicker />}
      />
      {isLoading(FACEBOOK_INSIGHT_LOADING) ? (
        <UpdateLoadingCard
          loadingId={FACEBOOK_INSIGHT_LOADING}
          text={intl.formatMessage({
            id: "app.analiyticsFace.updatingMetrics",
          })}
        />
      ) : (
        <Content>
          <FacebookCharts />
        </Content>
      )}
      <SideModal
        active={isModal(ANALYTICS_EXPAND)}
        visible={getModalVisibility(ANALYTICS_EXPAND)}
        onClose={() => turnOffModal(ANALYTICS_EXPAND)}
        left
      >
        {getModalContent(ANALYTICS_EXPAND)}
      </SideModal>
    </Layout>
  );
};
