import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const webFormRoot = (state: State) => state.webForm;

export const webFormsSelector = createSelector(webFormRoot, (me) =>
  me.webForms.ids.map((id) => me.webForms.entities[id])
);
export const webFormResponseSelector = createSelector(
  webFormRoot,
  (me) => me.webFormResponses
);
export const webFormSelector = createSelector(webFormRoot, (me) => me.webForm);
