import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { useCompanyCore } from "../../../../cores/company";
import Avatar from "../../Avatar";
import { getFirstLetters } from "../../../utils/stringUtils";

interface Props {
  onSubmit: (arg0: string, arg1: string[]) => void;
  toggleEdition: () => void;
  description: string;
}

export default function DescriptionInput(props: Props) {
  const intl = useIntl();
  const { onSubmit, toggleEdition, description } = props;
  const { users } = useCompanyCore();
  const [newDescription, setNewDescription] = useState("");
  const [usersTagged, setUsersTagged] = useState<string[]>([]);
  
  useEffect(() => {
    setNewDescription(description);
  }, [description]);

  const disabled = useCallback(() => 
    newDescription === description, 
    [newDescription, description]
  );

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    
    const newTags = usersTagged.filter((tagged) => {
      const user = users.find(user => user.id === tagged);

      return !newDescription.includes(`@@@B[${user?.firstName} ${user?.lastName})@M@(${user?.id})E@@@`);
    });

    onSubmit(newDescription, newTags);
  }

  const handleChange = useCallback((text) => {
    setNewDescription(text.target.value);
  }, []);

  const onTagUser = useCallback((user) => {
    if (!usersTagged.includes(user)) {
      setUsersTagged([...usersTagged, user]);
    }
  }, [usersTagged, setUsersTagged]);

  const userList = useMemo(() => users.map((user) => ({
    id: user.id, display: `${user.firstName} ${user.lastName}`
  })
  ), [users]);

  return (
    <Form onSubmit={onFormSubmit}>
      <MentionsInput
        id="comment"
        value={newDescription}
        style={{ minHeight: 200 }}
        className="mention-input"
        onChange={handleChange}
      >
        <Mention
          trigger="@"
          markup="@@@B[__display__]@M@(__id__)E@@@"
          appendSpaceOnAdd={true}
          data={userList}
          onAdd={onTagUser}
          renderSuggestion={(
            suggestion,
            _search,
            highlightedDisplay,
            _index,
            focused
          ) => (
            <div className={cn("d-flex align-items-center", { focused: focused })}>
              <Avatar
                className="text-dark-50 mr-2"
                label={`${getFirstLetters(suggestion.display || "")}`}
              />
              {highlightedDisplay}
            </div>
          )}
        />
      </MentionsInput>
      <div className="d-flex align-items-center mt-2">
        <Button 
          color="primary" 
          className="rounded"
          size="sm"
          disabled={disabled()}
        >
          {intl.formatMessage({ id: "app.chatConfigArea.save" })}
        </Button>
        <FontAwesomeIcon
          icon={["fas", "times"]}
          size="1x"
          className="pointer ml-3"
          color="gray"
          onClick={toggleEdition}
        />
      </div>
    </Form>
  );
}
