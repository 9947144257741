import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const instagramRoot = (state: State) => state.instagram;

export const settingSelector = createSelector(
  instagramRoot,
  (instagram) => instagram.settings
);

export const accountsSelector = createSelector(
  instagramRoot,
  (instagram) => instagram.accounts
);

export const errorCodeSelector = createSelector(
  instagramRoot,
  (instagram) => instagram.errorCode
);

export const errorMessageSelector = createSelector(
  instagramRoot,
  (instagram) => instagram.errorMessage
);
