import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { socketMiddleware } from "../cores/socket/middlewares";
import reducers from "./reducers";

const history = createBrowserHistory();
const enhancers = [];
const middleware = routerMiddleware(history);
const enhancer =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

if (typeof enhancer === "function") {
  enhancers.push(enhancer());
}

export const VERSION = 150;

const composedEnhancers = compose(
  applyMiddleware(...[middleware, socketMiddleware()]),
  ...enhancers
);
const persistConfig = {
  key: "root",
  version: VERSION,
  storage,
  blacklist: ["globals"],
  migrate: (state: any) => {
    if (state && state._persist.version < VERSION) {
      console.log("Clean Persist State");
      return Promise.resolve({});
    }
    return Promise.resolve(state);
  },
};

const persistedReducer = persistReducer(persistConfig, reducers(history));
const store = createStore(persistedReducer, composedEnhancers);
const persistor = persistStore(store);

export { store, history, persistor };
