export const CampaignStatus = {
  ACTIVE: { id: "ACTIVE", name: "Activo" },
  PAUSED: { id: "PAUSED", name: "Pausado" },
  ARCHIVED: { id: "ARCHIVED", name: "Archivado" },
  DELETED: { id: "DELETED", name: "Eliminado" },
  PENDING_REVIEW: { id: "PENDING_REVIEW", name: "En revisión"},
  DISAPPROVED: { id: "DISAPPROVED", name: "No aprobado"},
  PREAPPROVED: { id: "PREAPPROVED", name: "Preaprobado"},
  PENDING_BILLING_INFO: { id: "PENDING_BILLING_INFO", name: "Pendiente información de pago"},
  CAMPAIGN_PAUSED: { id: "CAMPAIGN_PAUSED", name: "Campaña pausada" },
  ADSET_PAUSED: { id: "ADSET_PAUSED", name: "Grupo de anuncio pausado" },
  IN_PROCESS: { id: "IN_PROCESS", name: "En proceso" },
  WITH_ISSUES: { id: "WITH_ISSUES", name: "Con errores" },
  COMPLETED: { id: "COMPLETED", name: "Completado" }
};

export const getCampaignStatus = (id: string) => Object.values(CampaignStatus).find(item => item.id === id);