import React, { useCallback, useMemo } from "react";
import { Container } from "reactstrap";
import { COLOR_SECONDARY } from "../../../constants/theme";
import { useAnalyticsCore } from "../../../cores/analytics";
import { ChartMetric } from "../../../cores/analytics/interface/ChartMetric";
import { Metric2 } from "../../../cores/analytics/interface/Metric2";
import { useMarketCore } from "../../../cores/market";
import { CL$Format } from "../../../cores/utils";
import { ChartContainer } from "./ChartContainer";
import { BarChart } from "./charts/BarChart";
import { Monitors } from "./Monitors";
import { useIntl } from "react-intl";

export const MercadolibreCharts = () => {
  const intl = useIntl();
  const { mercadolibreInsights, toChartMetric } = useAnalyticsCore();
  const { mercadolibreOrders } = useMarketCore();

  const currency = useMemo(
    () => mercadolibreOrders[0]?.currency,
    [mercadolibreOrders]
  );

  const ordersCurrencyData: Metric2[] = useMemo(
    () => [
      {
        ...mercadolibreInsights.ml_sales,
        type: "line",
        valueFormatter: (value: number) => CL$Format(value, currency),
      },
      {
        ...mercadolibreInsights.ml_shipping,
        type: "line",
        valueFormatter: (value: number) => CL$Format(value, currency),
      },
      {
        ...mercadolibreInsights.ml_orders,
        type: "bar",
        color: COLOR_SECONDARY,
      },
    ],
    [
      currency,
      mercadolibreInsights.ml_orders,
      mercadolibreInsights.ml_sales,
      mercadolibreInsights.ml_shipping,
    ]
  );

  const ordersItemsData: Metric2[] = useMemo(
    () => [
      {
        ...mercadolibreInsights.ml_itemVisits,
        type: "line",
      },
      {
        ...mercadolibreInsights.ml_items,
        type: "bar",
        color: COLOR_SECONDARY,
      },
    ],
    [mercadolibreInsights]
  );

  const ordersChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(ordersCurrencyData),
    [ordersCurrencyData, toChartMetric]
  );

  const ordersMonitor = useCallback(
    () => <Monitors metrics={ordersCurrencyData} />,
    [ordersCurrencyData]
  );

  const ordersItemChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(ordersItemsData),
    [ordersItemsData, toChartMetric]
  );

  const ordersItemMonitor = useCallback(
    () => <Monitors metrics={ordersItemsData} />,
    [ordersItemsData]
  );

  return (
    <Container>
      <ChartContainer
        id="mercadolibreChart"
        height={250}
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticMercLibre.title1" })}
        monitors={ordersMonitor()}
      >
        <BarChart
          labels={ordersChartMetrics.labels}
          datasets={ordersChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="mercadolibreChart"
        height={250}
        className="mb-4"
        title={intl.formatMessage({ id: "app.analiyticMercLibre.title2" })}
        monitors={ordersItemMonitor()}
      >
        <BarChart
          labels={ordersItemChartMetrics.labels}
          datasets={ordersItemChartMetrics.datasets}
        />
      </ChartContainer>
    </Container>
  );
};
