import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { SocialProvider } from "../../socialPost/interfaces/socialProvider";
import { MetricDate } from "../interface/Metric2";

export const selectPostGroupAction = createAction(
  "[Analytics] Select Post Group",
  props<{ postIds: string[] }>()
);

export const updateInsightDateAction = createAction(
  "[Analytics] Update Insight Date",
  props<{ date: Partial<MetricDate> }>()
);

export const addSocialProviderAction = createAction(
  "[Analytics] Set Social Provider",
  props<{ socialProvider: SocialProvider }>()
);

export const removeSocialProviderAction = createAction(
  "[Analytics] Delete Social Provider",
  props<{ socialProvider: SocialProvider }>()
);

const actions = joinActions({
  updateInsightDateAction,
  selectPostGroupAction,
  addSocialProviderAction,
  removeSocialProviderAction,
});

export type AnalyticsAction = typeof actions;
