import cn from "classnames";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { FormGroup, InputProps, Label } from "reactstrap";
import { COLOR_PRIMARY, COLOR_LIGHT, COLOR_GRAY } from "../../constants/theme";

interface Props extends InputProps {
  className?: string;
  label?: string;
  name?: string;
  id?: string;
  checked?: boolean;
  mb?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  onChange?: (e: any) => void;
}

export const CheckboxWembii = (props: Props) => {
  const {
    className,
    id,
    label,
    checked,
    mb = true,
    disabled = false,
    icon,
    onChange,
  } = props;
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = useCallback(
    (e) => {
      e.stopPropagation();

      if (!disabled) {
        onChange?.(!isChecked);
      }
    },
    [isChecked, disabled, onChange]
  );

  return (
    <FormGroup
      id={id}
      check
      className={cn("p-0", className, { "m-0": !mb, "mb-3": mb })}
    >
      <Label
        check
        className="d-flex text-nowrap w-100  align-items-center  pointer"
        onClick={handleChange}
      >
        <Label
          className="rounded d-inline-block mb-0 mr-2  pointer border"
          for={id}
          style={{
            width: 22,
            height: 22,
            backgroundColor: disabled
              ? COLOR_GRAY
              : isChecked
              ? COLOR_PRIMARY
              : COLOR_LIGHT,
          }}
        >
          {isChecked && (
            <svg
              viewBox="0 2 24 24"
              style={{
                fill: "none",
                stroke: "white",
                strokeWidth: 3,
              }}
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
          )}
        </Label>{" "}
        {icon && icon}
        {label}
      </Label>
    </FormGroup>
  );
};
