import {
  Company,
  MeCompanyPayload,
  Plan,
} from "../../me/interfaces/api/get-meCompany";
import {
  CompanyPayload,
  GetCompaniesResponse,
} from "../interfaces/api/get-companies";
import { CompanyUser, GetCompanyResponse } from "../interfaces/api/get-company";
import { PostCompanyResponse } from "../interfaces/api/post-company";
import { PutCompanyApikeyPayload, PutCompanyApikeyResponse } from "../interfaces/api/put-company-apikey";

export const companyMapper = {
  mapResponseToCompany(response: PostCompanyResponse): MeCompanyPayload {
    const { company, role, plan } = response?.payload;
    return {
      company,
      role,
      plan,
    };
  },
  mapResponseToCompanyApiKey(response: PutCompanyApikeyResponse): PutCompanyApikeyPayload {
    const { apiKey } = response?.payload;
    return { apiKey };
  },
  mapResponseToCompanyUser(
    response: GetCompanyResponse
  ): { users: CompanyUser[]; company: Company; companyPlan: Plan | undefined } {
    return {
      users: response.payload.users,
      company: response.payload.company,
      companyPlan: response.payload.companyPlan,
    };
  },
  mapResponseToCompanies(response: GetCompaniesResponse): CompanyPayload[] {
    return response?.payload?.companies.map((company) => company);
  },
};
