import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Button, Col, Container, Row } from "reactstrap";
import Avatar from "../../Avatar";
import { LabelWembii } from "../../LabelWembii";
import moment from "moment";
import { useEventCore } from "../../../../cores/event";
import _ from "lodash";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import { COLOR_DANGER } from "../../../../constants/theme";
import { getFirstLetters } from "../../../utils/stringUtils";
import AreYouSure from "../../AreYouSure";
import { useMeCore } from "../../../../cores/me";
import { useGlobalCore } from "../../../../cores/globals";
import {
  DELETE_EVENT_MODAL,
  MEETING_EDIT_FORM,
} from "../../../../constants/modal.constant";
import ContactRow from "../creator/ContactRow";
import { GoogleCalendarIcon } from "../../social/GoogleCalendar/GoogleCalendarIcon";
import { GoogleMeetIcon } from "../../social/Google/GoogleMeetIcon";
import { EVENT_ORIGIN } from "../../../../cores/event/constants/eventOrigin.constant";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import ActivityContainer from "../creator/ActivityContainer";

export default function EditEventForm() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { event, comments, deleteEvent, deleteGoogleEvent, postComment } =
    useEventCore();

  const date = useMemo(() => {
    const date = moment(event?.startDate);
    return `${_.capitalize(date.format("dddd, MMMM Do YYYY"))} . ${date.format(
      "HH:mm"
    )} - ${date.add(event?.duration, "m").format("HH:mm")} . ${date.format(
      "Z"
    )}`;
  }, [event]);

  const onDelete = useCallback(() => {
    turnOnModal(
      DELETE_EVENT_MODAL,
      <AreYouSure
        message={intl.formatMessage(
          { id: "app.calendar.delete.event" },
          { title: event?.title }
        )}
        onSubmit={() => {
          if (event?.type === EVENT_ORIGIN.WEMBII_CALENDAR) {
            deleteEvent(company?.id!, event?.id!, () => {
              turnOffModal(MEETING_EDIT_FORM);
              turnOffModal(DELETE_EVENT_MODAL);
            });
          } else {
            deleteGoogleEvent(company?.id!, event?.id!, () => {
              turnOffModal(MEETING_EDIT_FORM);
              turnOffModal(DELETE_EVENT_MODAL);
            });
          }
        }}
        onClose={() => turnOffModal(DELETE_EVENT_MODAL)}
      />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    company,
    deleteEvent,
    deleteGoogleEvent,
    intl,
    event,
  ]);

  const onAddCommentSubmit = useCallback(
    (text: string) => {
      postComment(company?.id!, event?.id!, text, event?.type!);
    },
    [event, company, postComment]
  );

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-4">
            <h2 className="h2 d-flex justify-content-center align-items-center mb-0">
              {event?.title}
            </h2>
            <div className="mb-4 text-center">
              <div className="mb-2">{date}</div>
              <div>
                {event?.link && (
                  <span className="mb-2 mr-2">
                    <a href={event.link} target="_blank" rel="noreferrer">
                      <GoogleCalendarIcon active className="mx-1" />
                      Ver en Google Calendar
                    </a>
                  </span>
                )}
                {event?.hangoutLink && (
                  <span className="mb-2">
                    <a
                      href={event.hangoutLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GoogleMeetIcon active className="mx-1" />
                      Unirme con Google Meet
                    </a>
                  </span>
                )}
              </div>
            </div>

            <LabelWembii>
              {intl.formatMessage({ id: "app.createEvent.organizer" })}
            </LabelWembii>
            {event?.googleCalendarUser === null ||
            event?.type === "googleCalendar" ? (
              <div className="d-flex align-items-center mb-2">
                <div className="position-relative">
                  <Avatar
                    className="text-dark-50 font-weight-bold pointer m-1"
                    image={event?.user.avatarUrl}
                    label={
                      event?.user.firstName && event?.user.firstName
                        ? getFirstLetters(
                            `${event?.user.firstName} ${event?.user.firstName}`
                          )
                        : ""
                    }
                    icon={<FontAwesomeIcon icon={["fas", "user"]} size="1x" />}
                  />
                  {event?.user.status !== UserStatus.ACTIVE.id && (
                    <FontAwesomeIcon
                      icon={["fas", "ban"]}
                      size="1x"
                      style={{
                        top: "-3px",
                        right: "-3px",
                      }}
                      className="position-absolute"
                      color={COLOR_DANGER}
                    />
                  )}
                </div>
                <div>
                  <span>
                    {event?.user.firstName && event?.user.firstName
                      ? `${event?.user.firstName} ${event?.user.lastName} `
                      : " "}
                  </span>
                  <span className="text-muted">{`<${event?.user.email}>`}</span>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center mb-2">
                <div className="position-relative">
                  <Avatar
                    className="text-dark-50 font-weight-bold pointer m-1"
                    label={
                      event?.googleCalendarUser?.name
                        ? getFirstLetters(event?.googleCalendarUser.name)
                        : ""
                    }
                    icon={<FontAwesomeIcon icon={["fas", "user"]} size="1x" />}
                  />
                  {event?.user.status !== UserStatus.ACTIVE.id && (
                    <FontAwesomeIcon
                      icon={["fas", "ban"]}
                      size="1x"
                      style={{
                        top: "-3px",
                        right: "-3px",
                      }}
                      className="position-absolute"
                      color={COLOR_DANGER}
                    />
                  )}
                </div>
                <div>
                  <span>
                    {event?.googleCalendarUser?.name
                      ? event?.googleCalendarUser.name
                      : " "}
                  </span>
                  <span className="text-muted">{`<${event?.googleCalendarUser?.email}>`}</span>
                </div>
              </div>
            )}

            <LabelWembii>
              {intl.formatMessage({ id: "app.createEvent.participants" })}
            </LabelWembii>
            {event?.users &&
              event?.users.map((user) => (
                <div key={user.id} className="d-flex align-items-center">
                  <div className="position-relative">
                    <Avatar
                      className="text-dark-50 font-weight-bold pointer m-1"
                      image={user.avatarUrl}
                      label={getFirstLetters(
                        `${user.firstName} ${user.lastName}`
                      )}
                      icon={
                        <FontAwesomeIcon icon={["fas", "user"]} size="1x" />
                      }
                    />
                    {user.status !== UserStatus.ACTIVE.id && (
                      <FontAwesomeIcon
                        icon={["fas", "ban"]}
                        size="1x"
                        style={{
                          top: "-3px",
                          right: "-3px",
                        }}
                        className="position-absolute"
                        color={COLOR_DANGER}
                      />
                    )}
                  </div>
                  <div>
                    <span>{`${user.firstName} ${user.lastName} `}</span>
                    <span className="text-muted">{`<${user.email}>`}</span>
                  </div>
                </div>
              ))}
            {event?.contacts &&
              event?.contacts.map((contact, index) => (
                <ContactRow id={index} contact={contact} />
              ))}
            {event?.guests &&
              event?.guests.map((guest, index) => (
                <div key={index} className="d-flex align-items-center">
                  <div className="position-relative">
                    <Avatar
                      className="text-dark-50 font-weight-bold pointer m-1"
                      icon={
                        <FontAwesomeIcon icon={["fas", "user"]} size="1x" />
                      }
                    />
                  </div>
                  <div>
                    <span>
                      {intl.formatMessage({ id: "app.createEvent.external" })}
                    </span>
                    <span className="text-muted">{` <${guest}>`}</span>
                  </div>
                </div>
              ))}
            <LabelWembii className="mt-3">
              {intl.formatMessage({ id: "app.createEvent.description" })}
            </LabelWembii>
            {event?.description ? (
              <div
                className="bg-light pre-wrap p-2"
                dangerouslySetInnerHTML={{ __html: event?.description || "" }}
              />
            ) : (
              <div className="bg-light pre-wrap p-3 text-center text-muted">
                {intl.formatMessage({ id: "app.createEvent.noDescription" })}
              </div>
            )}

            {event?.internalNote && (
              <>
                <LabelWembii className="mt-3">
                  {intl.formatMessage({ id: "app.createEvent.internalNote" })}
                </LabelWembii>
                <div>{event?.internalNote}</div>
              </>
            )}

            <div className="px-2 pt-2">
              <LabelWembii className="mt-4">
                {intl.formatMessage({ id: "app.contacts.activity" })}
              </LabelWembii>
              <ActivityContainer
                comments={comments}
                onSubmit={onAddCommentSubmit}
              />
            </div>

            <div className="mt-3 d-flex align-items-center justify-content-center">
              <Button color="danger" onClick={onDelete}>
                {intl.formatMessage({ id: "app.createEvent.event.delete" })}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
