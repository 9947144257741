import {
  GetCurrencyResponse,
  WooCurrency,
} from "../interface/api/get-current-currency";
import {
  PostWooCommerceBindResponse,
  WooCommerceSetting,
} from "../interface/api/post-woocommerce-bind";
import { PostWooCommerceLoginResponse } from "../interface/api/post-woocommerce-login";

export const wooCommerceSettingMapper = {
  mapResponseToWooCommerceSetting(
    response: PostWooCommerceBindResponse
  ): WooCommerceSetting {
    const { wooCommerceSetting } = response?.payload;
    return wooCommerceSetting;
  },

  mapResponseToWooCommerceLoginUrl(
    response: PostWooCommerceLoginResponse
  ): { url: string } {
    const { url } = response?.payload;
    return { url };
  },

  mapResponseToWooCommerceCurrency(
    response: GetCurrencyResponse
  ): { currency: WooCurrency } {
    const { currency } = response?.payload;
    return { currency };
  },
};
