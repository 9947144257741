import React from "react";
import { useIntl } from "react-intl";
import { emailTypes } from "../../../constants/unsubscribeEmails.constant";

export const UnsubscribeMessage: React.FC<{
  group: string;
  mainUrl: string;
  unsubscribe: string;
}> = ({ group, mainUrl, unsubscribe }) => {
  const intl = useIntl();

  const isSuccess = unsubscribe === "success" && emailTypes[group];
  const isExpired = unsubscribe === "expired";
  return (
    <div className="container d-flex h-100 justify-content-center  flex-column ">
      <h1 className="text-center">
        {isExpired
          ? intl.formatMessage({ id: "app.unsubscribeEmail.expired" })
          : isSuccess
          ? intl.formatMessage({ id: "app.unsubscribeEmail.unsubscribed" })
          : intl.formatMessage({ id: "app.unsubscribeEmail.invalid" })}
        {isSuccess && group && intl.formatMessage({ id: emailTypes[group] })}
      </h1>{" "}
      <p className="text-center">
        {isExpired || !group || !emailTypes[group]
          ? intl.formatMessage({ id: "app.unsubscribeEmail.retry" })
          : intl.formatMessage({ id: "app.unsubscribeEmail.description" })}
      </p>
      <a href={mainUrl} className="text-center">
        {intl.formatMessage({ id: "app.unsubscribeEmail.backToWembii" })}
      </a>
    </div>
  );
};
