import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const companyRoot = (state: State) => state.company;

export const companySelector = createSelector(
  companyRoot,
  (companyRoot) => companyRoot.company
);


export const companyPlanSelector = createSelector(
  companyRoot,
  (companyRoot) => companyRoot.plan
);

export const companiesSelector = createSelector(companyRoot, ({ companies }) =>
  companies.ids.map((id) => companies.entities[id])
);

export const companiesFilter = createSelector(companyRoot, ({ filters }) =>
filters.ids?.map((id) => filters.entities[id])
);

export const usersSelector = createSelector(companyRoot, ({ users }) =>
  users.ids.map((id) => users.entities[id])
);
export const userIdsSelector = createSelector(
  companyRoot,
  ({ users }) => users.ids
);

export const integrationSelector = createSelector(
  companyRoot,
  ({ integration }) => integration
);