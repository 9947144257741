import QueryString from "query-string";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MAILCHIMP_AUDIENCES_SIDE_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMailchimpCore } from "../../../../cores/mailchimp";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";
import MailchimpAudiences from "./MailchimpAudiences";

export default function MailchimpPopUp() {
  const { search } = useLocation();
  const { getAudiences } = useMailchimpCore();
  const { company } = useMeCore();
  const { goToSocial } = useRouterCore();
  const { turnOnModal } = useGlobalCore();

  useEffect(() => {
    (async () => {
      const { code } = QueryString.parse(search);
      if (code) {
        const response = await getAudiences(company!.id, code as string);
        if (response) {
          turnOnModal(
            MAILCHIMP_AUDIENCES_SIDE_MODAL,
            <MailchimpAudiences token={response.token} prefix={response.prefix} />
          );
        }
      }
      goToSocial();
    })();
  }, [search, company, getAudiences, goToSocial, turnOnModal])

  return <LoadingSpinnerWembii active />;
}

