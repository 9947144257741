import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  FACEBOOK_ADS_GET_LOADING,
  FACEBOOK_ADS_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { facebookAdsApiService } from "./services/api";
import {
  getFacebookAdsFailureAction,
  getFacebookAdsInitAction,
  getFacebookAdsSuccessAction,
  postBindFacebookAdsInitAction,
  postBindFacebookAdsSuccessAction,
  getFacebookAdsAccountsFailureAction,
  getFacebookAdsAccountsInitAction,
  getFacebookAdsAccountsSuccessAction,
  postUnbindFacebookAdsFailureAction,
  postUnbindFacebookAdsInitAction,
  postUnbindFacebookAdsSuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import { accountsSelector, adsAccountsSelector, settingSelector } from "./store/selector";

export function useFacebookAdsCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const adsAccounts = useSelector(adsAccountsSelector);
  const accounts = useSelector(accountsSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindFacebookAdsInitAction());
        await facebookAdsApiService.unbind(companyId);
        dispatch(postUnbindFacebookAdsSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindFacebookAdsFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      oauthToken: string,
      accountId: string,
      userId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindFacebookAdsInitAction());
        turnOnLoading(FACEBOOK_ADS_SYNC_LOADING);
        const settings = await facebookAdsApiService.bind(companyId, {
          oauthToken,
          accountId,
          userId,
        });
        dispatch(postBindFacebookAdsSuccessAction({ settings }));
        turnOffLoading(FACEBOOK_ADS_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess9" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(FACEBOOK_ADS_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error21" }));
        console.error(error);
        dispatch(postUnbindFacebookAdsFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getFacebookAdsInitAction());
        turnOnLoading(FACEBOOK_ADS_GET_LOADING);
        const settings = await facebookAdsApiService.get(companyId);
        dispatch(getFacebookAdsSuccessAction({ settings }));
        turnOffLoading(FACEBOOK_ADS_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(FACEBOOK_ADS_GET_LOADING);
        console.error(error);
        dispatch(getFacebookAdsFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getAccounts = useCallback(
    async (companyId: string, token: string) => {
      try {
        dispatch(getFacebookAdsAccountsInitAction());
        turnOnLoading(FACEBOOK_ADS_SYNC_LOADING);
        const response = await facebookAdsApiService.accounts(companyId, token);
        dispatch(getFacebookAdsAccountsSuccessAction(response));
      } catch (error: any) {
        turnOffLoading(FACEBOOK_ADS_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error22" }));
        console.error(error);
        dispatch(getFacebookAdsAccountsFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    accounts,
    adsAccounts,
    getAccounts,
    unbind,
    bind,
    get,
  };
}
