import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { IconMapper } from "../selector/SocialPostSelector";
import { FacebookStoryPreview } from "./FacebookStoryPreview";
import { InstagramStoryPreview } from "./InstagramStoryPreview";

export const SocialStoryPreview = () => {
  const { storyErrors, socialProviders } = useSocialPostCore();
  const [previewId, setPreviewId] = useState<string>("");

  const showIcon =  useCallback(
    (id: string) => { 
      const error =  storyErrors.filter(
        ({ socialProviderId }) => socialProviderId === id
      )
      return error.length > 0 
        ? <FontAwesomeIcon 
          icon={["fas", "exclamation-circle"]} 
          size="xs" 
          color="red" 
          className="position-absolute"
          style={{ marginLeft: "22px", marginTop: "20px", zIndex: 100 }}
        />
        : null
    },
    [storyErrors]
  );

  const showPreview = useMemo(() => {
    switch (previewId) {
      case SocialProviderId.Facebook:
        return <FacebookStoryPreview />;
      case SocialProviderId.Instagram:
        return <InstagramStoryPreview />;
      default:
        return null;
    }
  }, [previewId]);

  useEffect(() => {
    if (socialProviders.length === 0) setPreviewId("");
    if (socialProviders.length > 0)
      if (
        !previewId ||
        !socialProviders.filter((sp) => sp.id === previewId).length
      )
        setPreviewId(socialProviders[0].id);
  }, [previewId, socialProviders]);

  return (
    <Container
      className="h-100 overflow-auto pb-3 mb-1"
      style={{
        maxHeight: "700px",
      }}
    >
      <Row className="justify-content-around my-4">
        {socialProviders.sort().map(({ id }) => (
          <Col xs="auto" key={id}>
            <div className="pointer" onClick={() => setPreviewId(id)}>
              {showIcon(id)}
              {_.isFunction(IconMapper[id]) &&
                IconMapper[id]({ active: id === previewId, size: "2x" })}
            </div>
          </Col>
        ))}
      </Row>
      {showPreview}
    </Container>
  );
};
