import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container, Draggable } from "react-smooth-dnd";
import {
  BOARD_ROBOT_INACTIVITY,
  DELETE_BOARD_ITEM_MODAL,
  MOVE_CARDS_MODAL,
  SEND_EMAIL_FORM,
} from "../../../constants/modal.constant";
import { useBoardCore } from "../../../cores/boards";
import { BoardType } from "../../../cores/boards/constants/board-type.constant";
import { getCurrencySymbol } from "../../../cores/boards/constants/currencies.constant";
import {
  Board,
  BoardList,
} from "../../../cores/boards/interfaces/api/get-board";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import AreYouSure from "../AreYouSure";
import { PanelWembii } from "../PanelWembii";
import { TitleWembii } from "../TitleWembii";
import AddCardContainer from "./creator/AddCardContainer";
import CardList from "./CardList";
import { DropdownOptions } from "./creator/DropdownOptions";
import NumberInput from "./creator/NumberInput";
import TextInput from "./creator/TextInput";
import { CL$Format } from "../../../cores/utils";
import { useIntl } from "react-intl";
import { PutListBody } from "../../../cores/boards/interfaces/api/put-list";
import { ListCard } from "../../../cores/boards/interfaces/api/get-card";
import { BOARD_LOADING } from "../../../constants/loading.constant";
import { useEmailCore } from "../../../cores/email";
import { useTemplateCore } from "../../../cores/templates";
import SendEmailForm from "../gmail/modals/SendEmailForm";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import MoveCards from "./modal/MoveCards";
import { addSign } from "../../utils/signUtils";
import { Contact } from "../../../cores/contacts/interfaces/api/get-contact";
import cn from "classnames";

interface Props {
  board: Board | null;
  list: BoardList;
}

interface DropResult {
  removedIndex: number | null;
  addedIndex: number | null;
  payload?: ListCard;
}

export default function ColumnBoard(props: Props) {
  const intl = useIntl();
  const { board, list } = props;
  const {
    company,
    isAdmin,
    isReseller,
    companyRole,
    user,
    getMeSigns,
    setSign,
  } = useMeCore();
  const {
    getFilters,
    putList,
    getLists,
    deleteList,
    putCardOrder,
    changeCardOrder,
    getListCards,
    getListContacts,
    getListBudgets,
  } = useBoardCore();
  const { setMessage } = useEmailCore();
  const { getEmailTemplates } = useTemplateCore();
  const { turnOnModal, turnOffModal, isLoading } = useGlobalCore();
  const [editName, setEditName] = useState(false);
  const [editPercentage, setEditPercentage] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const loader = useRef(null);
  const ref = useRef<any>(null);

  const filter = useMemo(() => {
    const filter = getFilters(board?.id!);
    return filter;
  }, [board, getFilters]);

  useEffect(() => {
    setHasMore(list.hasMore);
  }, [list.hasMore]);

  const boardLoading = useMemo(
    () => loading || isLoading(BOARD_LOADING),
    [loading, isLoading]
  );

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !boardLoading) {
        setPage((prev) => prev + 1);
      }
    },
    [boardLoading, hasMore]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader && loader.current && observer)
      observer.observe(loader.current as unknown as Element);
  }, [handleObserver]);

  useEffect(() => {
    const getCards = async () => {
      setLoading(true);
      await getListCards(
        company?.id!,
        board?.id!,
        list.id,
        filter,
        list.cards.length
      );
      setLoading(false);
    };

    if (hasMore) {
      getCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, list.id, hasMore, board, company, getListCards]);

  const canModify = useMemo(
    () =>
      isAdmin ||
      isReseller ||
      companyRole?.owner ||
      companyRole?.admin ||
      board?.userRoles.find((role) => role.user.id === user?.id)?.owner,
    [isAdmin, isReseller, user, companyRole, board]
  );

  const onUpdateSubmit = (body: PutListBody) => {
    putList(company?.id!, board?.id!, list.id, body, () => {
      setEditName(false);
      setEditPercentage(false);

      if (body.addToProbability) {
        getListBudgets(company?.id!, board?.id!, list.id, filter);
      }
    });
  };

  const onOpeningMoveCards = useCallback(async () => {
    const listNames = await getLists(company?.id!, board?.id!);
    let suggested = [];
    if (listNames) {
      const listIndex = listNames.findIndex((lst) => lst.id === list?.id);
      suggested = [-3, -2, -1, 1, 2, 3].reduce((prev: any, curr) => {
        const diff = listIndex + curr;
        if (diff >= 0 && diff < listNames.length) {
          return [
            ...prev,
            {
              ...listNames[diff],
              right: curr > 0,
            },
          ];
        } else {
          return prev;
        }
      }, []);
    }

    turnOnModal(
      MOVE_CARDS_MODAL,
      <MoveCards list={list} board={board} suggested={suggested} />
    );
  }, [turnOnModal, getLists, company, list, board]);

  const onSendingEmail = useCallback(async () => {
    const meSigns = await getMeSigns();
    const contacts = await getListContacts(
      company?.id!,
      board?.id!,
      list.id,
      filter
    );
    const defaultSign = meSigns ? meSigns.find((sign) => sign.default) : null;
    if (defaultSign) {
      setSign(defaultSign);
      setMessage(addSign("<p><br></p>", defaultSign));
    } else {
      setSign(null);
      setMessage("");
    }
    getEmailTemplates(company?.id!);
    turnOnModal(
      SEND_EMAIL_FORM,
      <SendEmailForm contacts={contacts as Contact[]} cardId={null} />
    );
  }, [
    turnOnModal,
    getMeSigns,
    setSign,
    getEmailTemplates,
    getListContacts,
    setMessage,
    company,
    board,
    list,
    filter,
  ]);

  const onDelete = useCallback(() => {
    turnOnModal(
      DELETE_BOARD_ITEM_MODAL,
      <AreYouSure
        message={intl.formatMessage({ id: "app.boardModal.delete" })}
        onSubmit={() => {
          deleteList(company?.id!, board?.id!, list.id);
          turnOffModal(DELETE_BOARD_ITEM_MODAL);
        }}
        onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, company, board, list, deleteList, intl]);

  const toggleEditName = useCallback(() => {
    if (canModify) setEditName(!editName);
  }, [editName, canModify]);

  const toggleEditPercentage = useCallback(() => {
    if (canModify) setEditPercentage(!editPercentage);
  }, [editPercentage, canModify]);

  const hasBudget = useMemo(
    () =>
      list.totalBudget
        ? Object.keys(list.totalBudget).filter(
            (currency) => list.totalBudget[currency] > 0
          ).length > 0
        : false,
    [list.totalBudget]
  );

  const totals = useMemo(
    () =>
      list.totalBudget
        ? Object.keys(list.totalBudget).filter(
            (currency) => list.totalBudget[currency] > 0
          )
        : [],
    [list.totalBudget]
  );

  const probabilities = useMemo(
    () =>
      list.totalProbability
        ? Object.keys(list.totalProbability).filter(
            (currency) => list.totalProbability[currency] > 0
          )
        : [],
    [list.totalProbability]
  );

  const onDragEnd = (list: BoardList, dropResult: DropResult) => {
    const { addedIndex, payload } = dropResult;

    if (addedIndex !== null) {
      changeCardOrder(payload?.id!, payload?.list!, list.id, addedIndex - 1);
      putCardOrder(
        company?.id!,
        board?.id!,
        payload?.list!,
        payload?.id!,
        list.id,
        addedIndex - 1,
        () => {
          if (payload?.list !== list.id) {
            getListBudgets(company?.id!, board?.id!, list.id, filter);
            getListBudgets(company?.id!, board?.id!, payload?.list!, filter);
          }
        }
      );
    }
  };

  const onCreationSuccess = useCallback(() => {
    if (ref !== null) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, []);
  const onClickRobot = useCallback(
    () => turnOnModal(BOARD_ROBOT_INACTIVITY, true, Position.LEFT),
    [turnOnModal]
  );
  return (
    <Draggable>
      <PanelWembii
        className={cn("panel-list mx-2 card-container", {
          "border border-primary": list.inactivity?.notify,
        })}
        backgroundColor="#F8F9FA"
        style={{ borderRadius: "20px" }}
      >
        <div className="p-3 border-bottom column-drag-handle position-relative">
          {!editName ? (
            <div className="d-flex justify-content-between align-items-center">
              <TitleWembii
                Tag="h5"
                className="pointer"
                onClick={toggleEditName}
              >
                {list.inactivity?.notify && (
                  <FontAwesomeIcon
                    className={cn("pointer text-primary my-auto")}
                    icon={["fas", "robot"]}
                  />
                )}
                <span style={{ fontSize: "1.1rem" }}>
                  {list.name} ({list.total})
                </span>
              </TitleWembii>

              <DropdownOptions
                onOpenAutomation={onClickRobot}
                children={
                  <FontAwesomeIcon
                    className="pointer"
                    icon={["fas", "ellipsis-h"]}
                  />
                }
                canModify={canModify}
                addToProbability={list.addToProbability}
                onSendingEmail={onSendingEmail}
                onDelete={onDelete}
                cardAmount={list.total}
                onChangeProbability={(addToProbability: boolean) =>
                  onUpdateSubmit({ addToProbability })
                }
                onOpeningMoveCards={onOpeningMoveCards}
                board={board}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-between align-items-center">
              <TextInput
                value={list.name}
                className="pb-2"
                onSubmit={(name) => onUpdateSubmit({ name })}
                toggleEdition={toggleEditName}
                showChek={true}
              />
            </div>
          )}

          {board?.type === BoardType.BUSINESS &&
            (!editPercentage ? (
              <div
                className="font-weight-bold pointer d-flex align-items-center"
                onClick={toggleEditPercentage}
                style={{ fontSize: 11.2 }}
              >
                Éxito: {list.percentage}%
              </div>
            ) : (
              <div
                className="d-flex align-items-center"
                style={{ fontSize: 11.2 }}
              >
                Éxito %:
                <NumberInput
                  value={list.percentage}
                  onSubmit={(percentage) => onUpdateSubmit({ percentage })}
                  toggleEdition={toggleEditPercentage}
                  showChek={true}
                />
              </div>
            ))}
        </div>

        <div className="list-body p-2">
          <Container
            orientation="vertical"
            groupName="col"
            dragClass="card-ghost card-selected"
            dropClass="card-ghost-drop"
            getChildPayload={(index) => list.cards[index - 1]}
            onDrop={(e) => onDragEnd(list, e)}
            dropPlaceholder={{
              animationDuration: 150,
              showOnTop: true,
              className: "drop-preview",
            }}
          >
            <div ref={ref} />
            {list.cards.map((card, index) => (
              <Suspense key={card.id} fallback={<div />}>
                <CardList
                  board={board}
                  card={card}
                  index={index}
                  list={list.id}
                />
              </Suspense>
            ))}
            {hasMore && !boardLoading && <div ref={loader} />}
            {loading && (
              <div className="d-flex justify-content-center">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </Container>
        </div>
        {board?.type === BoardType.BUSINESS && hasBudget && (
          <div className="border-top total-container">
            <div className="panel-1 text-center font-weight-bold">
              {intl.formatMessage({
                id: "app.crmBusiness.totalAndProbability",
              })}
            </div>
            <div className="text-center p-1 panel-2">
              <div className="font-weight-bold">
                {intl.formatMessage({ id: "app.crmBusiness.total" })}
              </div>
              <div className="d-flex p-1 flex-wrap justify-content-center">
                {totals
                  .map((currency) =>
                    CL$Format(
                      list.totalBudget[currency],
                      "",
                      getCurrencySymbol(currency)
                    )
                  )
                  .join(" + ")}
              </div>
            </div>
            {list.addToProbability && (
              <div className="text-center p-1 panel-2">
                <div className="font-weight-bold">
                  {intl.formatMessage({ id: "app.crmBusiness.probability" })}
                </div>
                <div className="d-flex p-1 flex-wrap justify-content-center">
                  {probabilities
                    .map((currency) =>
                      CL$Format(
                        list.totalProbability[currency],
                        "",
                        getCurrencySymbol(currency)
                      )
                    )
                    .join(" + ")}
                </div>
              </div>
            )}
          </div>
        )}
        <AddCardContainer
          id={list.id}
          board={board}
          onSuccess={onCreationSuccess}
        />
      </PanelWembii>
    </Draggable>
  );
}
