import React from "react";
import cn from "classnames";
import { useMeCore } from "../../../cores/me";
import { PanelWembii } from "../PanelWembii";
import { useGlobalCore } from "../../../cores/globals";
import SidebarNavigation from "./SidebarNavigation";
import SidebarAdminRoutes from "./SidebarAdminRoutes";
import SidebarResellerRoutes from "./SidebarResellerRoutes";
import SidebarUserRoutes from "./SidebarUserRoutes";

const Sidebar = () => {
  const { isAdmin, isReseller } = useMeCore();
  const { collapseSideBar, setCollapseSideBar } = useGlobalCore();

  return (
    <PanelWembii
      className={cn("h-100", {
        sidebarExpanded: !collapseSideBar,
        sidebarCollaped: collapseSideBar,
      })}
      onMouseEnter={() => setCollapseSideBar(false)}
      onMouseLeave={() => setCollapseSideBar(true)}
    >
      <div
        className={cn("p-3", {
          "overflow-auto": !collapseSideBar,
          "overflow-hidden": collapseSideBar,
        })}
      >
        <SidebarNavigation />

        {!isAdmin && <SidebarUserRoutes collapseSideBar={collapseSideBar} />}

        {isReseller && (
          <SidebarResellerRoutes collapseSideBar={collapseSideBar} />
        )}
        {isAdmin && <SidebarAdminRoutes collapseSideBar={collapseSideBar} />}
      </div>
    </PanelWembii>
  );
};

export default React.memo(Sidebar);
