import React, { ReactNode } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

interface Props {
  onEdit: () => void;
  onDelete: () => void;
  children: ReactNode; 
}

export const DropdownOptions = (props: Props) => {
  const { onEdit, onDelete, children } = props;
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav className="p-0">
        {children}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={onEdit}>
          Editar
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={onDelete}>
          Borrar
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
