import React, { useCallback, useMemo } from "react";
import { Col, Label, Row } from "reactstrap";
import { useAdvertisementCore } from "../../../cores/advertisements";
import { CampaignGoals } from "../../../cores/advertisements/constants/campaign-goals";
import { CheckboxWembii } from "../CheckboxWembii";
import { InputWembii } from "../InputWembii";
import { LabelWembii } from "../LabelWembii";
import { PanelWembii } from "../PanelWembii";
import { useIntl } from "react-intl";
import { TitleWembii } from "../TitleWembii";
import { BillingEvents } from "../../../cores/advertisements/constants/billing-events";
import { isUrl } from "../../utils/stringUtils";
import { TooltipWembii } from "../TooltipWembii";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const campaignsGoalds = [
  CampaignGoals.LINK_CLICKS,
  CampaignGoals.LEAD_GENERATION
]

export default function StartForm() {
  const intl = useIntl();
  const { campaign, setCampaign } = useAdvertisementCore();

  const handleConfigChange = useCallback(
    (field) => {
      setCampaign({ ...campaign, ...field });
    },
    [campaign, setCampaign]
  );

  const handleObjetiveConfigChange = useCallback(
    (objective) => {
      if (objective === CampaignGoals.LINK_CLICKS.id) {
        setCampaign({
          ...campaign,
          objective,
          optimizationGoal: BillingEvents.IMPRESSIONS.id
        });
      } else {
        setCampaign({
          ...campaign,
          objective,
          optimizationGoal: CampaignGoals.LEAD_GENERATION.id
        });
      }
    },
    [campaign, setCampaign]
  );

  const handleFacebookPublishConfigChange = useCallback(
    (value) => {
      let positions = [...campaign.targeting.facebookPositions];
      let platforms = [...campaign.targeting.publisherPlatforms];
      if (campaign.targeting.facebookPositions.includes(value)) {
        positions.splice(positions.indexOf(value), 1);
        if (positions.length === 0) {
          platforms.splice(platforms.indexOf("facebook"), 1);
        }

        setCampaign({
          ...campaign,
          targeting: {
            ...campaign.targeting,
            ...{
              facebookPositions: positions,
              publisherPlatforms: platforms,
            },
          },
        });
      } else {
        if (!campaign.targeting.publisherPlatforms.includes("facebook")) {
          platforms = [...campaign.targeting.publisherPlatforms, "facebook"];
        }

        setCampaign({
          ...campaign,
          targeting: {
            ...campaign.targeting,
            ...{
              facebookPositions: [
                ...campaign.targeting.facebookPositions,
                value,
              ],
              publisherPlatforms: platforms,
            },
          },
        });
      }
    },
    [campaign, setCampaign]
  );

  const handleInstagramPublishConfigChange = useCallback(
    (value) => {
      let positions = [...campaign.targeting.instagramPositions];
      let platforms = [...campaign.targeting.publisherPlatforms];
      if (campaign.targeting.instagramPositions.includes(value)) {
        positions.splice(positions.indexOf(value), 1);
        if (positions.length === 0) {
          platforms.splice(platforms.indexOf("instagram"), 1);
        }

        setCampaign({
          ...campaign,
          targeting: {
            ...campaign.targeting,
            ...{
              instagramPositions: positions,
              publisherPlatforms: platforms,
            },
          },
        });
      } else {
        if (!campaign.targeting.publisherPlatforms.includes("instagram")) {
          platforms = [...campaign.targeting.publisherPlatforms, "instagram"];
        }

        setCampaign({
          ...campaign,
          targeting: {
            ...campaign.targeting,
            ...{
              instagramPositions: [
                ...campaign.targeting.instagramPositions,
                value,
              ],
              publisherPlatforms: platforms,
            },
          },
        });
      }
    },
    [campaign, setCampaign]
  );

  const isFacebookConfigSet = (value: string) => {
    return campaign.targeting.facebookPositions.includes(value);
  };

  const isInstagramConfigSet = (value: string) => {
    return campaign.targeting.instagramPositions.includes(value);
  };

  const isUrlInvalid = useMemo(() =>
    campaign.url !== '' && !isUrl(campaign.url)
    , [campaign]
  );

  return (
    <PanelWembii shadow className="mb-4 p-4">
      <TitleWembii Tag="h5" color="primary">
        {intl.formatMessage({ id: "app.advertisements.start" })}
      </TitleWembii>
      <Row className="mb-4">
        <Col>
          <InputWembii
            type="text"
            name="campaignName"
            id="campaignName"
            label={intl.formatMessage({ id: "app.advertisements.campaingName" })}
            flat
            required
            value={campaign.name}
            onChange={(v) => handleConfigChange({ name: v })}
            icon={     
              <div className="mb-4 mr-3">         
                <FontAwesomeIcon 
                  icon={["fas", "info-circle"]}
                  size="1x"
                  className="position-absolute border-none"
                  id="campaign-name"
                  color="grey"
                />
                <TooltipWembii 
                  id="campaign-name" 
                  text={intl.formatMessage({ id: "app.tooltopAds1" })} 
                />
              </div>  
            }
          />
        </Col>
        <Col>
          <InputWembii
            type="text"
            name="url"
            id="url"
            label={intl.formatMessage({ id: "app.advertisements.campaingUrl" })}
            flat
            required
            invalid={isUrlInvalid}
            value={campaign.url}
            onChange={(v) => handleConfigChange({ url: v })}
            icon={     
              <div className="mb-4 mr-3">         
                <FontAwesomeIcon 
                  icon={["fas", "info-circle"]}
                  size="1x"
                  className="position-absolute border-none"
                  id="campaign-url"
                  color="grey"
                />
                <TooltipWembii 
                  id="campaign-url" 
                  text={intl.formatMessage({ id: "app.tooltopAds2" })} 
                />
              </div>
            }
          />
        </Col>
      </Row>
      <InputWembii
        type="select"
        name="goal"
        id="goal"
        label={intl.formatMessage({ id: "app.advertisements.campaingObjetives" })}
        value={campaign.objective}
        onChange={(v) => handleObjetiveConfigChange(v)}
        flat
        required
        icon={     
          <div className="mb-4 mr-4">         
            <FontAwesomeIcon 
              icon={["fas", "info-circle"]}
              size="1x"
              className="position-absolute border-none"
              id="campaign-objetives"
              color="grey"
            />
            <TooltipWembii 
              id="campaign-objetives" 
              text={intl.formatMessage({ id: "app.tooltopAds3" })}  
            />
          </div>
        }
      >
        {campaignsGoalds.map((goal) => (
          <option value={goal.id} key={goal.id}>
            {intl.formatMessage({ id: goal.name })}
          </option>
        ))}
      </InputWembii>
      <Label className="text-muted mb-5">
        {intl.formatMessage({ id: "app.advertisements.campaingMessage1" })}
      </Label>

      <LabelWembii required className="mb-2">
        {intl.formatMessage({ id: "app.advertisements.campaingMessage2" })}
      </LabelWembii>
      <Row className="mt-2">
        <Col>
          <Label className="text-muted mb-3">
            {intl.formatMessage({ id: "app.advertisements.campaingSectionNews" })}
          </Label>
          <FontAwesomeIcon 
            icon={["fas", "info-circle"]}
            size="1x"
            className="position-absolute border-none ml-2 mt-1"
            id="campaign-news"
            color="grey"
          />
          <TooltipWembii 
            id="campaign-news" 
            text={intl.formatMessage({ id: "app.tooltopAds4" })} 
          />
          <CheckboxWembii
            label={intl.formatMessage({ id: "app.advertisements.campaingSectionFace" })}
            checked={isFacebookConfigSet("feed")}
            onChange={() => handleFacebookPublishConfigChange("feed")}
          />
          <CheckboxWembii
            label={intl.formatMessage({ id: "app.advertisements.campaingSectionIns" })}
            checked={isInstagramConfigSet("stream")}
            onChange={() => handleInstagramPublishConfigChange("stream")}
          />
        </Col>
        <Col>
          <Label className="text-muted mb-3">
            {intl.formatMessage({ id: "app.advertisements.campaingHistory" })}
          </Label>
          <FontAwesomeIcon 
            icon={["fas", "info-circle"]}
            size="1x"
            className="position-absolute border-none ml-2 mt-1"
            id="campaign-history"
            color="grey"
          />
          <TooltipWembii 
            id="campaign-history"
            text={intl.formatMessage({ id: "app.tooltopAds5" })}  
          />
          <CheckboxWembii
            label={intl.formatMessage({ id: "app.advertisements.campaingHistoryFace" })}
            checked={isFacebookConfigSet("story")}
            onChange={() => handleFacebookPublishConfigChange("story")}
          />
          <CheckboxWembii
            label={intl.formatMessage({ id: "app.advertisements.campaingHistoryIns" })}
            checked={isInstagramConfigSet("story")}
            onChange={() => handleInstagramPublishConfigChange("story")}
          />
        </Col>
      </Row>
    </PanelWembii>
  );
}
