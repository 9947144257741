import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { ContactConditionOptions } from "../../../../cores/automations/config";
import { ContactSourceOptions } from "../../../../cores/contacts/config";
import { useContactCore } from "../../../../cores/contacts";
import { AutomationCondition } from "../../../../cores/automations/interfaces/api/get-automation";
import { ContactSource } from "../../../../cores/contacts/enum/ContactSource.enum";
import { NewContactCondition } from "../../../../cores/automations/enum/NewContactCondition.enum";
import { useAutomationCore } from "../../../../cores/automations";
import { ConditionOperator } from "../../../../cores/automations/enum/ConditionOperator";
import { AUTOMATION_CONDITION } from "../../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../../cores/globals";

interface Props {
  isFirst: Boolean;
  condition: AutomationCondition | null;
}

export default function ConditionForm(props: Props) { 
  const intl = useIntl();
  const { condition, isFirst } = props;
  const { company } = useMeCore();
  const { postCondition, putCondition, deleteCondition, automation } = useAutomationCore();
  const { tags } = useContactCore();
  const { turnOffInternalModal } = useGlobalCore();

  const [attribute, setAttribute] = useState(NewContactCondition.SOURCE);
  const [operator, setOperator] = useState(ConditionOperator.AND);
  const [value, setValue] = useState(ContactSource.MANUAL);

  useEffect(() => {
    if (condition) {
      setAttribute(condition.attribute as NewContactCondition);
      setValue(condition.value as ContactSource);
      setOperator(condition.operator as ConditionOperator);
    }
  }, [condition]);

  useEffect(() => {
    if (!condition) {
      setOperator(ConditionOperator.AND);
    }
  }, [isFirst, condition]);

  const onSuccess = useCallback(() => {
    setAttribute(NewContactCondition.SOURCE);
    setValue(ContactSource.MANUAL);
    turnOffInternalModal(AUTOMATION_CONDITION);
  }, [turnOffInternalModal]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (condition) {
      putCondition(
        company?.id!,
        automation?.id!,
        condition.id,
        {
          attribute,
          value,
          operator
        },
        onSuccess
      );
    } else {
      postCondition(
        company?.id!,
        automation?.id!,
        {
          attribute,
          value,
          operator
        },
        onSuccess
      );
    }
  };

  const disabled = useCallback(
    () => !attribute || !value,
    [attribute, value]
  );

  const onDelete = useCallback(() => {
    deleteCondition(
      company?.id!,
      automation?.id!,
      condition?.id!,
      onSuccess
    );
  }, [company, condition, automation, deleteCondition, onSuccess]);

  const tagsValues = useMemo(() => 
    [
      ...ContactSourceOptions.map((source) =>({value: source.value, label: intl.formatMessage({id: source.label})})), 
      ...tags.map((tag) => ({ value: tag.nameId, label: tag.name }))
    ]
  , [tags, intl]);

  return (
    <Form className="p-2" onSubmit={onSubmit}>
      <InputWembii
        type="select"
        label={intl.formatMessage({ id: "app.automations.automation.attribute" })}
        value={attribute}
        onChange={setAttribute}
      >
        {ContactConditionOptions.map(({ value, label }) => (
          <option key={value} value={value}>
            {intl.formatMessage({ id: label })}
          </option>
        ))}
      </InputWembii>

      <InputWembii
        type="select"
        label={intl.formatMessage({ id: "app.automations.automation.value" })}
        value={value}
        onChange={setValue}
      >
        {tagsValues.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </InputWembii>

      {!isFirst && (
        <div className="d-flex justify-content-center align-items-center mb-3">
          <div
            className={cn("border rounded p-3 mr-1 pointer font-weight-bold", {
              "text-primary": operator === ConditionOperator.AND
            })}
            onClick={() => setOperator(ConditionOperator.AND)}
          >
            {intl.formatMessage({ id: "app.automations.automation.and" })}
          </div>
          <div
            className={cn("border rounded p-3 mr-1 pointer font-weight-bold", {
              "text-primary": operator === ConditionOperator.OR
            })}
            onClick={() => setOperator(ConditionOperator.OR)}
          >
            {intl.formatMessage({ id: "app.automations.automation.or" })}
          </div>
        </div>
      )}

      {condition && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.automations.automation.delete" })}  
        </Button>
      )}
      <Button color="primary" disabled={disabled()} className="ml-1">
        {condition
          ? intl.formatMessage({ id: "app.automations.automation.update" })
          : intl.formatMessage({ id: "app.automations.automation.create" })
        }  
      </Button>
    </Form>
  );
}