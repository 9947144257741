import React, { useCallback, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { ADMIN_USER_LOADING } from "../../constants/loading.constant";
import {
  USER_EXPAND_CREATE_FORM,
  USER_EXPAND_EDIT_FORM,
} from "../../constants/modal.constant";
import { useConfigCore } from "../../cores/config";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useIntl } from "react-intl";
import { useRouterCore } from "../../cores/router";
import { User } from "../../cores/session/interfaces/models/user";
import { useUserCore } from "../../cores/user";
import EditUserForm from "../component/admin/users/EditUserForm";
import { UsersBar } from "../component/admin/users/UsersBar";
import { FreeTrialBadge } from "../component/companies/FreeTrialBadge";
import { PlanBadge } from "../component/companies/PlanBadge";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import UserRow from "../component/UserRow";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";

export default function Users() {
  const intl = useIntl();
  const { users, total } = useUserCore();
  const { getConfig } = useConfigCore();
  const { user } = useMeCore();
  const { turnOnModal } = useGlobalCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    getConfig();
  }, [getConfig]);
  
  useEffect(() => {
    if (!user?.userRole?.admin && !(user?.userRole?.reseller && user?.reseller))
      goToSocial();
  }, [user, goToSocial]);

  const onClick = useCallback((user: User) => {
    turnOnModal(USER_EXPAND_EDIT_FORM, <EditUserForm user={user} />);
  }, [turnOnModal]);

  return (
    <Layout>
      <Header2
        title={`${intl.formatMessage({ id: "app.adminUsers.title" })} (${total})`}
        rightChildren={
          <Button
            className="whiteButton"
            onClick={() => turnOnModal(USER_EXPAND_CREATE_FORM, true)}
          >
            {intl.formatMessage({ id: "app.adminUsers.creatUser" })}
          </Button>
        }
      />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <UsersBar />
              <UpdateLoadingCard
                loadingId={ADMIN_USER_LOADING}
                text={intl.formatMessage({ id: "app.adminUsers.updatingUser" })}
              />
              <Container fluid>
                {users.map((user) => (
                  <UserRow
                    key={user.id}
                    user={user}
                    actions={
                      <div className="d-flex justify-content-center align-items-center flex-wrap">
                        {user.freeTrial && (
                          <FreeTrialBadge freeTrial={user.freeTrial} />
                        )}
                        <PlanBadge plan={user.userPlan!} show />
                      </div>
                    }
                    onClick={() => onClick(user)}
                  />
                ))}
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
    </Layout>
  );
}
