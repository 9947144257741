import socialApiClient from "../../helpers/services/social-api-client";
import { DeleteHashtagResponse } from "../interfaces/api/delete-hashtag";
import { DeleteSocialPostsResponse } from "../interfaces/api/delete-social-posts";
import { GetHashtagsResponse } from "../interfaces/api/get-hashtags";
import { GetSocialPostsResponse } from "../interfaces/api/get-social-posts";
import { PostHasgtagResponse } from "../interfaces/api/post-hashtag";
import { PostReportPostResponse } from "../interfaces/api/post-social-posts-report";
import { socialPostsMapper } from "./mapper";

export const socialPostsApiService = {
  getSocialPosts(
    companyId: string,
    since: string,
    until: string,
    provider: string,
    status: string,
    target: string
  ) {
    return socialApiClient()
      .get<GetSocialPostsResponse>(`social/posts`, {
        params: { companyId, since, until, provider, status, target },
      })
      .then(({ data }) => socialPostsMapper.mapResponseToSocialPosts(data));
  },
  getAllAdminSocialPosts(
    since: string,
    until: string,
    provider: string,
    status: string,
    companyId: string
  ) {
    return socialApiClient()
      .get<GetSocialPostsResponse>(`social/posts/all`, {
        params: { since, until, provider, status, companyId },
      })
      .then(({ data }) => socialPostsMapper.mapResponseToSocialPosts(data));
  },
  deleteSocialPost(companyId: string, postId: string) {
    return socialApiClient()
      .delete<DeleteSocialPostsResponse>(`social/posts/${postId}`, {
        params: { companyId },
      })
      .then(({ data }) => socialPostsMapper.mapResponseToId(data));
  },
  reportSocialPost(companyId: string, postId: string) {
    return socialApiClient()
      .get<PostReportPostResponse>(`social/posts/report/${postId}`, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  getHashtags(companyId: string) {
    return socialApiClient()
      .get<GetHashtagsResponse>(`social/posts/hashtags`, {
        params: { companyId },
      })
      .then(({ data }) => socialPostsMapper.mapResponseToHashtags(data));
  },
  postHashtag(companyId: string, name: string) {
    return socialApiClient()
      .post<PostHasgtagResponse>(`social/posts/hashtags`, { name }, {
        params: { companyId },
      })
      .then(({ data }) => socialPostsMapper.mapResponseToHashtag(data));
  },
  deleteHashtag(companyId: string, id: string) {
    return socialApiClient()
      .delete<DeleteHashtagResponse>(`social/posts/hashtags/${id}`, {
        params: { companyId },
      })
      .then(({ data }) => socialPostsMapper.mapResponseToId(data));
  },
};
