import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { FacebookForm } from "../../../../cores/facebookForm/interfaces/api/get-facebook-form";
import { useIntl } from "react-intl";


interface Props {
  onView: () => void;
  facebookForm: FacebookForm;
}

export default function FormCard(props: Props) {
  const intl = useIntl();
  const { facebookForm, onView } = props;

  return (
    <Card
      key={facebookForm.id}
      className="neu-out align-content-center border-0 d-flex justify-content-center p-3"
    >
      <CardBody className="p-2 d-flex flex-column">
        <CardTitle>{facebookForm.name}</CardTitle>
        <div className="d-flex justify-content-between">
          <div className="text-muted">
            {intl.formatMessage({ id: "app.facebookForm.leads" })} : { facebookForm.leadsCount }
          </div>
          <div className="text-primary pointer" onClick={onView}>
            {intl.formatMessage({ id: "app.facebookForm.seeLeads" })} 
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
