import {
  GetNotificationsResponse,
  NotificationsPayload,
} from "../interfaces/api/get-notifications";
import {
  GetSystemNotificationsResponse,
  SystemNotificationsPayload,
} from "../interfaces/api/get-system-notifications";
import {
  PutReadNotificationPayload,
  PutReadNotificationResponse,
} from "../interfaces/api/put-read-notification";

export const notificationMapper = {
  mapResponseToId(
    response: PutReadNotificationResponse
  ): PutReadNotificationPayload {
    const id = response?.payload?.id;
    return { id };
  },
  mapResponseToNotifications(
    response: GetNotificationsResponse
  ): NotificationsPayload {
    const { notifications, count } = response?.payload;
    return { notifications, count };
  },
  mapResponseToSystemNotifications(
    response: GetSystemNotificationsResponse
  ): SystemNotificationsPayload {
    const { infoMessage, infoMessageEn, infoMessageEs, infoMessagePt } =
      response?.payload;
    return { infoMessage, infoMessageEn, infoMessageEs, infoMessagePt };
  },
};
