import React from "react";
import { MessageNotification } from "../../../cores/notifications/interfaces/api/get-notifications";
import { SocialProviderNameMapper } from "../../../cores/socialPost/config";
import { SocialProviderIcon } from "../socialPost/selector/SocialProviderIcon";
import { useIntl } from "react-intl";

interface Props {
  notification: MessageNotification;
}

export default function MessageNotificationItem({ notification }: Props) {
  const intl = useIntl();
  const getLabelSnippet = ({ message, messageType }: MessageNotification) => {
    switch (messageType) {
      case "audio":
        return intl.formatMessage({ id: "app.chat.sentAudio" });
      case "video":
        return intl.formatMessage({ id: "app.chat.sentVideo" });
      case "image":
        return intl.formatMessage({ id: "app.chat.sentImage" });
      case "document":
        return intl.formatMessage({ id: "app.chat.sentAttachment" });
      default:
        return message;
    }
  };
  return (
    <div className="d-flex align-items-center border-bottom">
      <SocialProviderIcon
        className="mr-3"
        isSelected
        showErrors={false}
        socialProvider={{
          id: notification.type,
        }}
      />
      <div className="d-flex flex-column overflow-hidden">
        <span>
          Nuevo Mensaje de {`${SocialProviderNameMapper[notification.type]}`}
        </span>
        <span className="text-truncate text-muted">
          {notification.from.name}: {`${getLabelSnippet(notification)}`}
        </span>
      </div>
    </div>
  );
}
