import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import QueryString from "query-string";
import wembiiImage from "../../assets/wembii-logo.png";
import { useRouterCore } from "../../cores/router";
import { useUserCore } from "../../cores/user";
import { PanelWembii } from "../component/PanelWembii";
import { ACCOUNT_PASSWORD_POST_LOADING } from "../../constants/loading.constant";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import { useGlobalCore } from "../../cores/globals";

export const RecoverPassword = () => {
  const { search } = useLocation();
  const { isLoading } = useGlobalCore();
  const [authToken, setAuthToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const { routes, goToLogin } = useRouterCore();
  const { putRecoverPassword } = useUserCore();

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    putRecoverPassword(password, authToken, goToLogin);
  };

  useEffect(() => {
    const { token } = QueryString.parse(search);
    if (token) {
      setAuthToken(token as string)
    } else {
      if (!authToken) goToLogin()
    }
  }, [search, authToken, goToLogin]);

  const isPasswordValid = () => {
    const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{6,32}$)/;
    return passwordRegex.test(password);
  };

  const isRepeatPasswordValid = () => {
    return password === passwordConfirmation;
  };

  const disabled = useCallback(
    () => !password || !passwordConfirmation,
    [password, passwordConfirmation]
  );

  return (
    <Container className="h-100" fluid>
      <Row className="h-100 w-100 m-0 d-flex align-items-center justify-content-center">
        <Col md="4" sm="6" xs="12">
          <PanelWembii className="p-4">
            <div className="w-100 d-flex flex-column align-items-center justify-content-center font-weight-bold">
              <img src={wembiiImage} width={200} alt="login" />
              <span className="mb-3">Recuperá tu contraseña</span>
            </div>
            <Form onSubmit={onFormSubmit}>
            <FormGroup>
                <Label for="login-password">Contraseña</Label>
                <Input
                  type="password"
                  name="password"
                  id="login-password"
                  placeholder="Contraseña"
                  invalid={!!password && !isPasswordValid()}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {!!password && !isPasswordValid() && (
                  <FormFeedback>
                    La contraseña debe contener minúsculas, mayúsculas y números con un mínimo de 6 y un máximo de 32 caracteres
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="login-password">Confirmar Contraseña</Label>
                <Input
                  type="password"
                  name="passwordConfirmation"
                  id="login-password-confirmation"
                  placeholder="Password"
                  invalid={!!password && !!passwordConfirmation && !isRepeatPasswordValid()}
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                {!!password && !!passwordConfirmation && !isRepeatPasswordValid() && (
                  <FormFeedback>Las contraseñas no coinciden</FormFeedback>
                )}
              </FormGroup>
              <UpdateLoadingCard
                loadingId={ACCOUNT_PASSWORD_POST_LOADING}
                text="Actualizando Contraseña"
              />
              {!isLoading(ACCOUNT_PASSWORD_POST_LOADING) && (
                <Button className="mb-2" color="primary" block disabled={disabled()}>
                  Recuperar contraseña
                </Button>
              )}
            </Form>
            <hr className="horizontal-separator my-4" />
            <div className="align-items-center d-flex flex-column justify-content-center">
              <Link to={routes.login}>Volver al login</Link>
            </div>
          </PanelWembii>
        </Col>
      </Row>
    </Container>
  );
};
