import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { WooCurrency } from "../interface/api/get-current-currency";
import { WooCommerceSetting } from "../interface/api/post-woocommerce-bind";

export const postUnbindWooCommerceInitAction = createAction(
  "[WooCommerce] Unbind WooCommerce Init"
);
export const postUnbindWooCommerceSuccessAction = createAction(
  "[WooCommerce] Unbind WooCommerce Success"
);
export const postUnbindWooCommerceFailureAction = createAction(
  "[WooCommerce] Unbind WooCommerce Failure",
  props<{ error: string }>()
);

export const postBindWooCommerceInitAction = createAction(
  "[WooCommerce] Bind WooCommerce Init"
);
export const postBindWooCommerceSuccessAction = createAction(
  "[WooCommerce] Bind WooCommerce Success",
  props<{ settings: WooCommerceSetting }>()
);
export const postBindWooCommerceFailureAction = createAction(
  "[WooCommerce] Bind WooCommerce Failure",
  props<{ error: string }>()
);

export const getWooCommerceInitAction = createAction(
  "[WooCommerce] Get WooCommerce Settings Init"
);
export const getWooCommerceSuccessAction = createAction(
  "[WooCommerce] Get WooCommerce Settings Success",
  props<{ settings: WooCommerceSetting }>()
);
export const getWooCommerceFailureAction = createAction(
  "[WooCommerce] Get WooCommerce Settings Failure",
  props<{ error: string }>()
);

export const getWooCommerceLoginInitAction = createAction(
  "[WooCommerce] Get WooCommerce Login Init"
);
export const getWooCommerceLoginSuccessAction = createAction(
  "[WooCommerce] Get WooCommerce Login Success"
);
export const getWooCommerceLoginFailureAction = createAction(
  "[WooCommerce] Get WooCommerce Login Failure",
  props<{ error: string }>()
);

export const getWooCommerceCurrencyInitAction = createAction(
  "[WooCommerce] Get WooCommerce Currency Init"
);
export const getWooCommerceCurrencySuccessAction = createAction(
  "[WooCommerce] Get WooCommerce Currency Success",
  props<{ currency: WooCurrency }>()
);
export const getWooCommerceCurrencyFailureAction = createAction(
  "[WooCommerce] Get WooCommerce Currency Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindWooCommerceInitAction,
  postUnbindWooCommerceSuccessAction,
  postUnbindWooCommerceFailureAction,
  postBindWooCommerceInitAction,
  postBindWooCommerceSuccessAction,
  postBindWooCommerceFailureAction,
  getWooCommerceInitAction,
  getWooCommerceSuccessAction,
  getWooCommerceFailureAction,
  getWooCommerceLoginInitAction,
  getWooCommerceLoginSuccessAction,
  getWooCommerceLoginFailureAction,
  getWooCommerceCurrencyInitAction,
  getWooCommerceCurrencySuccessAction,
  getWooCommerceCurrencyFailureAction,
});

export type WooCommerceApiAction = typeof actions;
