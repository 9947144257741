import socialApiClient from "../../helpers/services/social-api-client";
import { Media } from "../../socialPost/interfaces/api/get-social-posts";
import { Mentions } from "../../socialPost/interfaces/Mention";
import { PostImage } from "../../socialPost/interfaces/postImage";
import { PostVideo } from "../../socialPost/interfaces/postVideo";
import { blobFromDataURI } from "../../utils";
import { GetLinkedInBusinessResponse } from "../interface/api/get-linkedinBusiness-bind";
import { PostSocialPostResponse } from "../interface/api/post-linkedInBusiness-socialPost";
import {
  PostLinkedInBusinessBindRequest,
  PostLinkedInBusinessBindResponse,
} from "../interface/api/post-linkedinBussiness-bind";
import {
  PostLinkedInBusinessOrganizationsRequest,
  PostLinkedInBusinessOrganizationsResponse,
} from "../interface/api/post-linkedinBussiness-organizations";
import { PutSocialPostResponse } from "../interface/api/put-linkedinBussiness-socialPost";
import { linkedInBusinessSettingMapper } from "./mapper";

export const linkedInBusinessApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`linkedin/business/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostLinkedInBusinessBindRequest) {
    return socialApiClient()
      .post<PostLinkedInBusinessBindResponse>(`linkedin/business/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        linkedInBusinessSettingMapper.mapResponseToLinkedInBusinessSetting(data)
      );
  },
  organizations(
    companyId: string,
    body: PostLinkedInBusinessOrganizationsRequest
  ) {
    return socialApiClient()
      .post<PostLinkedInBusinessOrganizationsResponse>(
        `linkedin/business/organizations`,
        body,
        {
          params: { companyId },
        }
      )
      .then(({ data }) =>
        linkedInBusinessSettingMapper.mapResponseToLinkedInOrganizations(data)
      );
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetLinkedInBusinessResponse>(`linkedin/business`, {
        params: { companyId },
      })
      .then(({ data }) =>
        linkedInBusinessSettingMapper.mapResponseToLinkedInBusinessSetting(data)
      );
  },
  socialPost(
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(
        `linkedin/business/socialpost`,
        bodyFormData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },
  socialPostVideo(
    text: string,
    mentions: Mentions[],
    video: PostVideo,
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(
        `linkedin/business/socialpost/video`,
        bodyFormData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },
  putSocialPost(
    id: string,
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(`linkedin/business/socialpost/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  putSocialPostVideo(
    id: string,
    text: string,
    mentions: Mentions[],
    video: PostVideo | null,
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    video && bodyFormData.append("video", blobFromDataURI(video.data))
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(`linkedin/business/socialpost/video/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
};
