import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React from "react";
import { SocialPostNotification } from "../../../cores/notifications/interfaces/api/get-notifications";
import { SocialProviderNameMapper } from "../../../cores/socialPost/config";
import { SocialProviderIcon } from "../socialPost/selector/SocialProviderIcon";
import {useIntl} from 'react-intl';

interface Props {
  notification: SocialPostNotification; 
}

export default function SocialPostNotificationItem({
  notification
}: Props) {
  const intl = useIntl();
  return (
    <div className="d-flex align-items-center border-bottom mb-1">
      <SocialProviderIcon
        className="mr-3"
        isSelected
        showErrors={false}
        socialProvider={{
          id: notification.social,
        }}
      />
      <div className="d-flex flex-column overflow-hidden">
        <span className="capitalize">{`${SocialProviderNameMapper[notification.social]} Post`}</span>

        <span
          className={cn("text-truncate", {
            "text-danger": notification.status !== 1,
            "text-success": notification.status === 1,
          })}
        >
          <FontAwesomeIcon
            icon={["fas", notification.status === 1 ? "check" : "times"]}
            className="mr-2"
          />
          {notification.status === 1 
            ? intl.formatMessage({id: "app.socialMediaPost.postedCorecctly"})
            : intl.formatMessage({id: "app.socialMediaPost.postedError"})
          }
          
        </span>
      </div>
    </div>
  );
}
