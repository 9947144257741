import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BOARDS_INSIGHT_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { ChartMetric } from "./interface/ChartMetric";
import { Metric2 } from "./interface/Metric2";
import { boardAnalyticsApiService } from "./services/api";
import { boardAnalyticsMapper } from "./services/mapper";
import {
  getBoardCardsFailureAction,
  getBoardCardsInitAction,
  getBoardCardsSuccessAction,
  getCrmInsightFailureAction,
  getCrmInsightInitAction,
  getCrmInsightSuccessAction
} from "./store/api-actions";
import { cardsSelector, crmInsightEntitySelector, dateSelector, hasMoreSelector, offsetSelector } from "./store/selector";
import { useIntl } from "react-intl";
import { Filters } from "../boards/interfaces/PanelFilters";
import { setDateAction, setHasMoreAction } from "./store/actions";

export function useBoardAnalyticsCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const crmInsights = useSelector(crmInsightEntitySelector);
  const date = useSelector(dateSelector);
  const hasMore = useSelector(hasMoreSelector);
  const offset = useSelector(offsetSelector);
  const cards = useSelector(cardsSelector);
  const getCrmInsights = useCallback(
    async (
      companyId: string,
      boardId: string,
      filters: Filters,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getCrmInsightInitAction());
        turnOnLoading(BOARDS_INSIGHT_LOADING);
        const insights = await boardAnalyticsApiService.crmInsights(companyId, boardId, filters);
        dispatch(getCrmInsightSuccessAction({ insights }));
        turnOffLoading(BOARDS_INSIGHT_LOADING, 500);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(BOARDS_INSIGHT_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error9" }));
        console.error(error);
        dispatch(getCrmInsightFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getBoardCards = useCallback(
    async (
      companyId: string,
      boardId: string,
      filters: Filters,
      offset: number,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getBoardCardsInitAction());
        const response = await boardAnalyticsApiService.getBoardCards(
          companyId,
          boardId,
          filters,
          offset
        );
        dispatch(getBoardCardsSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(getBoardCardsFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const toChartMetric = useCallback((metrics: Metric2[]): ChartMetric => {
    return boardAnalyticsMapper.mapToChartMetric(metrics);
  }, []);

  const setDate = useCallback((date: string) => {
    dispatch(setDateAction({ date }))
  }, [dispatch]);

  const setHasMore = useCallback((hasMore: boolean) => {
    dispatch(setHasMoreAction({ hasMore }))
  }, [dispatch]);

  return {
    getCrmInsights,
    toChartMetric,
    getBoardCards,
    setDate,
    setHasMore,
    crmInsights,
    cards,
    offset,
    date,
    hasMore,
  };
}
