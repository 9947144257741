import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { Col, Container, Row } from "reactstrap";
import { useCompanyCore } from "../../../../../cores/company";
import { useMeCore } from "../../../../../cores/me";
import { useIntl } from "react-intl";
import { useWebFormCore } from "../../../../../cores/webForm";
import { PanelWembii } from "../../../PanelWembii";
import { TitleWembii } from "../../../TitleWembii";

export default function NotificationForm() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { users } = useCompanyCore();
  const {
    webForm,
    postFormNotification,
    deleteFormNotification,
  } = useWebFormCore();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [selected, setSelected] = useState<{ value: string; label: string }[]>(
    []
  );

  const handleChange = useCallback(
    async (_value, { action, removedValue, option }) => {
      switch (action) {
        case "remove-value":
          const notification = webForm.notifications.find(
            (item) => item.userId === removedValue.value
          );

          await deleteFormNotification(
            company?.id!,
            webForm?.form?.id!,
            notification?.id!
          );
          break;
        case "select-option":
          await postFormNotification(
            company?.id!,
            webForm?.form?.id!,
            option.value
          );
          break;
      }
    },
    [company, webForm, deleteFormNotification, postFormNotification]
  );

  useEffect(() => {
    setOptions(
      users.map((user) => {
        return { value: user.id, label: `${user.firstName} ${user.lastName}` };
      })
    );
  }, [users]);

  useEffect(() => {
    setSelected(
      options.filter((option) =>
        webForm.notifications.some((item) => item.userId === option.value)
      )
    );
  }, [options, webForm.notifications]);

  return (
    <Container className="p-0">
      <PanelWembii shadow className="mb-4 p-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.editForm.NotificationTitle" })}
        </TitleWembii>
        <Row className="h-100">
          <Col xs="12">
            {intl.formatMessage({ id: "app.editForm.NotificationSubTitle" })}
            <Select
              isMulti
              name="users"
              options={options}
              value={selected}
              className="mt-2"
              placeholder={intl.formatMessage({ id: "app.select.placeholder" })}
              noOptionsMessage={() => intl.formatMessage({ id: "app.select.noData" })}
              onChange={handleChange}
              classNamePrefix="select"
              isClearable={false}
            />
          </Col>
        </Row>
      </PanelWembii>
    </Container>
  );
}
