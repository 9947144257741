import { FacebookFormPayload, GetFacebookFormResponse } from "../interfaces/api/get-facebook-form";
import { FacebookFormsPayload, GetFacebookFormsResponse } from "../interfaces/api/get-facebook-forms";

export const facebookFormMapper = {
  mapResponseToFacebookForm(response: GetFacebookFormResponse): FacebookFormPayload {
    const { form, leads, paging } = response?.payload;
    return { form, leads, paging };
  },
  mapResponseToFacebookForms(response: GetFacebookFormsResponse): FacebookFormsPayload {
    const { forms, paging } = response?.payload;
    return { forms, paging };
  },
}
