import React, { useState } from "react";
import { Tooltip } from "reactstrap";

interface Props {
  id: string;
  text: string;
}

export const TooltipWembii = (props: Props) => {
  const { id, text } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip
      placement="auto"
      isOpen={tooltipOpen}
      target={id}
      toggle={toggle}
    >
      {text}
    </Tooltip>
  );
};
