import cn from "classnames";
import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { COLOR_DANGER, COLOR_PRIMARY, COLOR_WARNING } from "../../../constants/theme";
import { getUserStatus } from "../../../cores/user/constants/user-status";

interface Props {
  status: number;
  show?: boolean;
  className?: string;
}

const statusColorsMap: Record<string, string> = {
  "ACTIVO": COLOR_PRIMARY,
  "ELIMINADO": COLOR_WARNING,
  "BLOQUEADO": COLOR_DANGER,
};

export const StatusBadge = (props: Props) => {
  const { status, show, className } = props;
  const [statusName, setStatusName] = useState(getUserStatus(status)?.name.toUpperCase());
  const [isShown, setIsShown] = useState(false);
  
  useEffect(() => {
    setStatusName(getUserStatus(status)?.name.toUpperCase());
  }, [status]);

  return (
    <div className={cn("d-flex flex-row", className)}>
      <Badge
        onMouseEnter={() => !show && setIsShown(true)}
        onMouseLeave={() => !show && setIsShown(false)}
        key={`tooltip-${status}`}
        id={`tooltip-${status}`}
        pill
        style={{
          minWidth: "30px",
          height: "30px",
          backgroundColor: statusColorsMap[statusName!] || "#c5e384",
        }}
      >
        <h6
          className={cn("m-0", { h5: !isShown, "h6 px-2": isShown || show })}
          style={{ lineHeight: "25px" }}
        >
          {statusName}
        </h6>
      </Badge>
    </div>
  );
};
