import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { useAdvertisementCore } from "../../../cores/advertisements";
import { useMeCore } from "../../../cores/me";
import { useIntl } from "react-intl";
import { CheckboxWembii } from "../CheckboxWembii";
import { InputWembii } from "../InputWembii";
import { LabelWembii } from "../LabelWembii";
import { PanelWembii } from "../PanelWembii";
import { RadiobuttonWembii } from "../RadiobuttonWembii";
import { SelectWembii } from "../SelectWembii";
import { TitleWembii } from "../TitleWembii";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipWembii } from "../TooltipWembii";
import { LocationType } from "../../../cores/advertisements/constants/location-type";


interface selectItem {
  value: string;
  label: string;
  type: string;
}

export default function DemographyForm() {
  const intl = useIntl();
  const { company } = useMeCore();
  const {
    locations,
    languages,
    campaign,
    setCampaign,
    getLocations,
    getLanguages,
  } = useAdvertisementCore();
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState<selectItem[]>([]);

  useEffect(() => {
    getLanguages(company?.id!);
  }, [company, getLanguages]);

  useEffect(() => {
    setOptions(
      locations.map((location) => {
        return {
          value: location.key,
          label: location.type === LocationType.CITY
            ? `${location.name} (${location.countryName})`
            : location.name,
          type: location.type,
        };
      })
    );
    setIsSearching(false);
  }, [locations]);

  const debouncedSearch = useRef(
    _.debounce((search) => getLocations(company!.id, search), 1000)
  ).current;

  useEffect(() => {
    if (search) {
      setIsSearching(true);
      debouncedSearch(search);
    }
  }, [debouncedSearch, search]);

  const handleConfigChange = useCallback(
    (field) => {
      setCampaign({
        ...campaign,
        targeting: { ...campaign.targeting, ...field },
      });
    },
    [campaign, setCampaign]
  );

  const handleAgeConfigChange = useCallback(
    ({ min, max }) => {
      let nMin = campaign.targeting.ageMin;
      let nMax = campaign.targeting.ageMax;

      if (
        campaign.targeting.ageMin !== min ||
        campaign.targeting.ageMax !== max
      ) {
        if (
          Math.abs(campaign.targeting.ageMin - min) <=
          Math.abs(campaign.targeting.ageMax - max)
        ) {
          if (campaign.targeting.ageMin <= min) {
            nMin = max + 1;
          } else {
            nMin = min;
          }
        } else {
          if (campaign.targeting.ageMax >= max) {
            nMax = min - 1;
          } else {
            nMax = max;
          }
        }

        setCampaign({
          ...campaign,
          targeting: {
            ...campaign.targeting,
            ...{ ageMin: nMin, ageMax: nMax },
          },
        });
      }
    },
    [campaign, setCampaign]
  );

  const handleLocationConfigChange = useCallback(
    (values) => {
      if (values) {
        let countries = values
          .filter((item: selectItem) => item.type === LocationType.COUNTRY)
          .map((item: selectItem) => item.value);
        let cities = values
          .filter((item: selectItem) => item.type === LocationType.CITY)
          .map((item: selectItem) => ({ key: item.value, name: item.label }));

        setCampaign({
          ...campaign,
          targeting: {
            ...campaign.targeting,
            ...{ countries: countries, cities: cities },
          },
        });
      } else {
        setCampaign({
          ...campaign,
          targeting: {
            ...campaign.targeting,
            ...{ countries: [], cities: [] },
          },
        });
      }
    },
    [campaign, setCampaign]
  );

  const areEquals = (a: number[], b: number[]) => {
    return _.isEqual(a, b);
  };

  const isBetweenAge = (min: number, max: number) => {
    return campaign.targeting.ageMin <= min && campaign.targeting.ageMax >= max;
  };

  return (
    <PanelWembii shadow className="mb-4 p-4">
      <TitleWembii Tag="h5" color="primary">
        {intl.formatMessage({ id: "app.advertisements.campaingDemography" })}
      </TitleWembii>
      <Row>
        <Col>
          <FormGroup className="d-flex flex-column position-relative">
            <SelectWembii
              isMulti
              name="countries"
              label={intl.formatMessage({ id: "app.advertisements.campaingLocationTxt" })}
              required
              options={options}
              onInputChange={(v) => setSearch(v)}
              onChange={(v) => handleLocationConfigChange(v)}
              isClearable={false}
              isLoading={isSearching}
              icon={     
                <div  className="mb-4 ">         
                  <FontAwesomeIcon 
                    icon={["fas", "info-circle"]}
                    size="1x"
                    className="position-absolute border-none"
                    id="campaign-location"
                    color="grey"
                  />
                  <TooltipWembii 
                    id="campaign-location" 
                    text={intl.formatMessage({ id: "app.tooltopAds6" })} 
                  />
                </div>  
              }
            />
          </FormGroup>
        </Col>
        <Col>
          <InputWembii
            type="select"
            name="language"
            id="language"
            label={intl.formatMessage({ id: "app.advertisements.campaingIdiomTxt" })}
            flat
            required
            value={campaign.targeting.locales}
            onChange={(v) => handleConfigChange({ locales: v })}
            icon={     
              <div  className="mb-4 mr-4">         
                <FontAwesomeIcon 
                  icon={["fas", "info-circle"]}
                  size="1x"
                  className="position-absolute border-none"
                  id="campaign-lenguage"
                  color="grey"
                />
                <TooltipWembii 
                  id="campaign-lenguage" 
                  text={intl.formatMessage({ id: "app.tooltopAds7" })} 
                />
              </div>  
            }
          >
            {languages.map((language) => (
              <option key={language.key} value={language.key}>
                {language.name}
              </option>
            ))}
          </InputWembii>
        </Col>
      </Row>

      <Row>
        <Col>
        <FontAwesomeIcon 
            icon={["fas", "info-circle"]}
            size="1x"
            className="position-absolute ml-5 mt-1"
            id="campaign-sex"
            color="grey"
          />
          <TooltipWembii 
            id="campaign-sex"
            text={intl.formatMessage({ id: "app.tooltopAds8" })} 
          />
          <LabelWembii required>
            {intl.formatMessage({ id: "app.advertisements.campaingSexTxt" })}
          </LabelWembii>

          <RadiobuttonWembii
            label={intl.formatMessage({ id: "app.advertisements.campaingSexEveribody" })}
            checked={areEquals(campaign.targeting.genders, [1, 2])}
            onChange={() => handleConfigChange({ genders: [1, 2] })}
          />
          <RadiobuttonWembii
            label={intl.formatMessage({ id: "app.advertisements.campaingSexMen" })}
            checked={areEquals(campaign.targeting.genders, [1])}
            onChange={() => handleConfigChange({ genders: [1] })}
          />
          <RadiobuttonWembii
            label={intl.formatMessage({ id: "app.advertisements.campaingSexFemale" })}
            checked={areEquals(campaign.targeting.genders, [2])}
            onChange={() => handleConfigChange({ genders: [2] })}
          />
        </Col>
        <Col>
          <FontAwesomeIcon 
            icon={["fas", "info-circle"]}
            size="1x"
            className="position-absolute ml-5 mt-1"
            id="campaign-age"
            color="grey"
          />
          <TooltipWembii 
            id="campaign-age"
            text={intl.formatMessage({ id: "app.tooltopAds9" })} 
          />
          <LabelWembii required>
            {intl.formatMessage({ id: "app.advertisements.campaingAge" })}
          </LabelWembii>
          <Row>
            <Col>
              <CheckboxWembii
                label="18 - 24"
                checked={isBetweenAge(18, 24)}
                onChange={() => handleAgeConfigChange({ min: 18, max: 24 })}
              />
              <CheckboxWembii
                label="25 - 34"
                checked={isBetweenAge(25, 34)}
                onChange={() => handleAgeConfigChange({ min: 25, max: 34 })}
              />
              <CheckboxWembii
                label="35 - 44"
                checked={isBetweenAge(35, 44)}
                onChange={() => handleAgeConfigChange({ min: 35, max: 44 })}
              />
            </Col>
            <Col>
              <CheckboxWembii
                label="45 - 54"
                checked={isBetweenAge(45, 54)}
                onChange={() => handleAgeConfigChange({ min: 45, max: 54 })}
              />
              <CheckboxWembii
                label="55 - 64"
                checked={isBetweenAge(55, 64)}
                onChange={() => handleAgeConfigChange({ min: 55, max: 64 })}
              />
              <CheckboxWembii
                label="65+"
                checked={isBetweenAge(65, 65)}
                onChange={() => handleAgeConfigChange({ min: 65, max: 65 })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </PanelWembii>
  );
}
