import QueryString from "query-string";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import { useTwitterCore } from "../../../../cores/twitter";

export default function TwitterPopUp() {
  const { search } = useLocation();
  const { bind } = useTwitterCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    const { oauth_token: token, oauth_verifier: verifier } = QueryString.parse(
      search
    );
    if (token && verifier)
      bind(company!.id, token as string, verifier as string, () => {
        getMeSocialSettings(company?.id!);
      });
    goToSocial();
  }, [search, company, bind, goToSocial, getMeSocialSettings]);

  return <div></div>;
}
