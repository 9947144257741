import cn from "classnames";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import { FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT } from "../../../../constants/modal.constant";
import { useFacebookAdsCore } from "../../../../cores/facebookAds";
import { FacebookAdsAccount as AdsAccount } from "../../../../cores/facebookAds/interface/api/get-facebook-ads-account";
import { FacebookAccount as Account } from "../../../../cores/facebook/interface/api/get-facebook-account";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { Currency } from "../../analytics/Currency";

export default function FacebookAdsAccounts({ token }: { token: string }) {
  const intl = useIntl();
  const { adsAccounts, accounts } = useFacebookAdsCore();
  const [adsAccount, setAdsAccount] = useState<AdsAccount>();
  const [account, setAccount] = useState<Account>();
  const { bind } = useFacebookAdsCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { turnOffModal } = useGlobalCore();

  const handleBind = useCallback(async () => {
    await bind(company!.id, token, adsAccount?.id!, account?.id!, () => {
      getMeSocialSettings(company?.id!);
    });
    turnOffModal(FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT);
  }, [bind, company, adsAccount, account, token, turnOffModal, getMeSocialSettings]);

  return (
    <div className="p-4 overflow-auto">
      <h2 className="h2 text-center mb-4">
        {intl.formatMessage({ id: "app.social.selectAccount" })}
      </h2>
      {adsAccounts.map((acc) => (
        (!adsAccount || adsAccount.id === acc.id) && (
          <FacebookAdsAccount
            account={acc}
            key={acc.id}
            onSelect={setAdsAccount}
            showActions={!adsAccount}
          />
        )
      ))}
      {adsAccount && (
        <>
          <hr />
          <h4 className="h4 text-center my-3">
            {intl.formatMessage({ id: "app.social.ads.selectAccount" })}
          </h4>

          {accounts.map((acc) => (
            (!account || account.id === acc.id) && (
              <FacebookAccount
                account={acc}
                key={acc.id}
                onSelect={setAccount}
                showActions={!account}
              />
            )
          ))}
        </>
      )}

      {adsAccount && account && (
        <div className="m-2 d-flex justify-content-center mx-auto">
          <Button color="primary" onClick={handleBind}>
            {intl.formatMessage({ id: "app.home.syncUp" })}
          </Button>
        </div>
      )}
    </div>
  );
}

const FacebookAdsAccount = ({
  account,
  onSelect,
  showActions,
}: {
  account: AdsAccount;
  onSelect: (arg0: AdsAccount) => void;
  showActions: boolean;
}) => {
  const intl = useIntl();
  const { name, currency } = account;

  return (
    <div className={cn("align-items-center d-flex p-2 neu-in pointer", {
      "justify-content-center": !showActions
    })}>
      <span className="h5 mx-3 my-2">{name}</span>
      <Currency currency={currency} />
      {showActions && (
        <Button className="ml-auto" color="primary" onClick={() => onSelect(account)}>
          {intl.formatMessage({ id: "app.social.select" })}
        </Button>
      )}
    </div>
  );
};

const FacebookAccount = ({
  account,
  onSelect,
  showActions,
}: {
  account: Account;
  onSelect: (arg0: Account) => void;
  showActions: boolean;
}) => {
  const intl = useIntl();
  const { avatarUrl, name } = account;

  return (
    <div className={cn("align-items-center d-flex p-2 neu-in pointer", {
      "justify-content-center": !showActions
    })}>
      <img
        style={{ width: "50px", height: "50px" }}
        className="rounded-circle"
        src={avatarUrl}
        alt="Avatar"
      />
      <span className="h5 ml-3"> {name} </span>
      {showActions && (
        <Button className="ml-auto" color="primary" onClick={() => onSelect(account)}>
          {intl.formatMessage({ id: "app.social.select" })}
        </Button>
      )}
    </div>
  );
};