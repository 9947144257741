import socialApiClient from "../../helpers/services/social-api-client";
import { Media } from "../../socialPost/interfaces/api/get-social-posts";
import { PostImage } from "../../socialPost/interfaces/postImage";
import { PostVideo } from "../../socialPost/interfaces/postVideo";
import { blobFromDataURI } from "../../utils";
import { GetFacebookBindResponse } from "../interface/api/get-facebook-bind";
import { GetFacebookAccountResponse } from "../interface/api/get-facebook-account";
import {
  PostFacebookBindRequest,
  PostFacebookBindResponse,
} from "../interface/api/post-facebook-bind";
import { PostSocialPostResponse } from "../interface/api/post-facebook-socialPost";
import { PutSocialPostResponse } from "../interface/api/put-facebook-socialPost";
import { facebookMapper } from "./mapper";
import { GetFacebookPageResponse } from "../interface/api/get-facebook-page";
import { Mentions } from "../../socialPost/interfaces/Mention";

export const facebookApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`facebook/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostFacebookBindRequest) {
    return socialApiClient()
      .post<PostFacebookBindResponse>(`facebook/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) => facebookMapper.mapResponseToFacebookSetting(data));
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetFacebookBindResponse>(`facebook`, {
        params: { companyId },
      })
      .then(({ data }) => facebookMapper.mapResponseToFacebookSetting(data));
  },
  accounts(companyId: string, token: string) {
    return socialApiClient()
      .get<GetFacebookAccountResponse>(`facebook/accounts`, {
        params: { companyId, token },
      })
      .then(({ data }) => {
        return facebookMapper.mapResponseToFacebookAccounts(data);
      });
  },
  getPages(companyId: string, name: string) {
    return socialApiClient()
      .get<GetFacebookPageResponse>(`facebook/pages/${name}`, {
        params: { companyId },
      })
      .then(({ data }) => facebookMapper.mapResponseToFacebookPages(data));
  },
  socialPost(
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`facebook/socialpost`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  socialPostVideo(
    text: string,
    mentions: Mentions[],
    video: PostVideo,
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`facebook/socialpost/video`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  putSocialPost(
    id: string,
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(`facebook/socialpost/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  putSocialPostVideo(
    id: string,
    text: string,
    mentions: Mentions[],
    video: PostVideo | null,
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    video && bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(
        `facebook/socialpost/video/${id}`,
        bodyFormData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },
  socialStory(
    text: string,
    images: PostImage[],
    scheduleDate: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`facebook/socialstory`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  socialStoryVideo(
    text: string,
    video: PostVideo,
    scheduleDate: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(
        `facebook/socialstory/video`,
        bodyFormData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },
  putSocialStory(
    id: string,
    text: string,
    images: PostImage[],
    scheduleDate: string,
    media: Media[],
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    bodyFormData.append("media", JSON.stringify(media));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PostSocialPostResponse>(`facebook/socialstory/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  putSocialStoryVideo(
    id: string,
    text: string,
    video: PostVideo | null,
    scheduleDate: string,
    media: Media[],
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("media", JSON.stringify(media));
    video && bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PostSocialPostResponse>(
        `facebook/socialstory/video/${id}`,
        bodyFormData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },
};
