import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const templateRoot = (state: State) => state.templates;

export const emailTemplatesSelector = createSelector(templateRoot, ({ emailTemplates }) =>
  emailTemplates.ids.map((id) => emailTemplates.entities[id])
);

export const socialTemplatesSelector = createSelector(templateRoot, ({ socialTemplates }) =>
  socialTemplates.ids.map((id) => socialTemplates.entities[id])
);

export const chatTemplatesSelector = createSelector(templateRoot, ({ chatTemplates }) =>
  chatTemplates.ids.map((id) => chatTemplates.entities[id])
);
