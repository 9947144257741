import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { Col, Row } from "reactstrap";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import Avatar from "../Avatar";
import { PanelWembii } from "../PanelWembii";
import { TooltipWembii } from "../TooltipWembii";
import { useIntl } from "react-intl";
import { Card } from "../../../cores/contacts/interfaces/api/get-contact";
import { CardStatusLabels } from "../../../cores/boards/constants/card-type";
import { useCompanyCore } from "../../../cores/company";
import { useBoardCore } from "../../../cores/boards";
import { BOARD_CARD_MODAL } from "../../../constants/modal.constant";
import CardDetails from "../boards/modal/CardDetails";
import { useContactCore } from "../../../cores/contacts";
import { getFirstLetters } from "../../utils/stringUtils";

interface Props {
  card: Card;
}

export const CardRow = (props: Props) => {
  const intl = useIntl();
  const {
    card: { id, title, status, listId, list: listName, boardId, board: boardName, assignments }
  } = props;
  const { turnOnModal } = useGlobalCore();
  const { getCompany } = useCompanyCore();
  const { getContact, contact } = useContactCore();
  const { company } = useMeCore();
  const { getCard, getLists, getCustomFields, boards } = useBoardCore();

  const onGettingContact = useCallback(() => {
    getContact(company?.id!, contact?.id!);
  }, [getContact, company, contact]);

  const onClick = useCallback(() => {
    getCard(company?.id!, boardId, listId, id, true);
    getCustomFields(company?.id!, boardId);
    getLists(company?.id!, boardId);
    getCompany(company?.id!);
    const board = boards.find((board) => board.id === boardId);
    turnOnModal(
      BOARD_CARD_MODAL,
      <CardDetails board={board!} onSubmit={onGettingContact} onDeletion={onGettingContact} />
    );
  }, [id, listId, boardId, company, boards, getCard, getCompany, getLists, turnOnModal, getCustomFields, onGettingContact]);

  const moreAssignments = useMemo(() => {
    const assig = [...assignments];
    return assig.splice(1);
  }, [assignments]);

  return (
    <PanelWembii className="w-100 px-4 py-3 my-2 border rounded pointer" onClick={onClick}>
      <Row>
        <Col xs="12" lg="6">
          <div className="d-flex flex-column align-items-center align-items-lg-start">
            <div className="font-weight-bold">
              {title}
            </div>
            <div className="text-muted">
              {intl.formatMessage({ id: CardStatusLabels[status] })}
            </div>
          </div>
        </Col>
        <Col xs="12" lg="4">
          <div className="d-flex flex-row flex-lg-column justify-content-center">
            <div className="text-muted mx-1">
              {boardName}
            </div>
            <span className="d-lg-none">/</span>
            <div className="text-muted mx-1">
              {listName}
            </div>
          </div>
        </Col>
        <Col xs="12" lg="2">
          <div className="user-cascade-container d-flex align-items-center justify-content-center justify-content-lg-end flex-wrap position-relative">
            {assignments.map((assignment, index) => (
              <div
                className={cn("user-avatar", {
                  "avatar-lg-hidden": index > 0
                })}
                style={{
                  "zIndex": index,
                  "left": index * 25,
                  "top": "50%"
                }}
              >
                <Avatar
                  key={index}
                  id={`owner-${id}-${assignment.id}`}
                  className="text-dark-50 pointer"
                  image={assignment.avatarUrl}
                  label={getFirstLetters(`${assignment.lastName} ${assignment.firstName}`)}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "user"]}
                      size="1x"
                    />
                  }
                />
                <TooltipWembii 
                  id={`owner-${id}-${assignment.id}`}
                  text={`${assignment.lastName} ${assignment.firstName}`} 
                />
              </div>
            ))}
            {moreAssignments.length > 0 && (
              <div
                className={cn("user-avatar avatar-md-hidden")}
                style={{
                  "zIndex": 1,
                  "left": 1 * 25,
                  "top": "50%"
                }}
              >
                <Avatar
                  id={`owner-${id}-rest`}
                  className="text-dark-50 pointer"
                  icon={
                    moreAssignments.length > 1 ? (
                      <FontAwesomeIcon
                        icon={["fas", "users"]}
                        size="1x"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={["fas", "user"]}
                        size="1x"
                      />
                    )
                  }
                />
                <TooltipWembii 
                  id={`owner-${id}-rest`}
                  text={moreAssignments
                    .map((assignment) => `${assignment.lastName} ${assignment.firstName}`)
                    .join(", ")
                  } 
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
