import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const linkedInBusinessRoot = (state: State) => state.linkedInBusiness;

export const settingSelector = createSelector(
  linkedInBusinessRoot,
  (linkedInBusiness) => linkedInBusiness.settings
);

export const organizationsSelector = createSelector(
  linkedInBusinessRoot,
  (linkedInBusiness) => linkedInBusiness.organizations
);
