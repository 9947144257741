import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Metric } from "../interface/api/get-insights";
import { InstagramPost } from "../interface/api/get-instagram-feed-posts";

export const getFacebookInsightInitAction = createAction(
  "[Analytics] Get Facebook Insight Init"
);
export const getFacebookInsightSuccessAction = createAction(
  "[Analytics] Get Facebook Insight Success",
  props<{ insights: Metric[] }>()
);
export const getFacebookInsightFailureAction = createAction(
  "[Analytics] Get Facebook Insight Failure",
  props<{ errorCode: number | null }>()
);

export const getInstagramInsightInitAction = createAction(
  "[Analytics] Get Instagram Insight Init"
);
export const getInstagramInsightSuccessAction = createAction(
  "[Analytics] Get Instagram Insight Success",
  props<{ insights: Metric[] }>()
);
export const getInstagramInsightFailureAction = createAction(
  "[Analytics] Get Instagram Insight Failure",
  props<{ errorCode: number | null }>()
);

export const getGoogleInsightInitAction = createAction(
  "[Analytics] Get Google Insight Init"
);
export const getGoogleInsightSuccessAction = createAction(
  "[Analytics] Get Google Insight Success",
  props<{ insights: Metric[] }>()
);
export const getGoogleInsightFailureAction = createAction(
  "[Analytics] Get Google Insight Failure",
  props<{ errorCode: number | null }>()
);

export const getWooCommerceInsightInitAction = createAction(
  "[Analytics] Get WooCommerce Insight Init"
);
export const getWooCommerceInsightSuccessAction = createAction(
  "[Analytics] Get WooCommerce Insight Success",
  props<{ insights: Metric[] }>()
);
export const getWooCommerceInsightFailureAction = createAction(
  "[Analytics] Get WooCommerce Insight Failure",
  props<{ errorCode: number | null }>()
);

export const getMercadolibreInsightInitAction = createAction(
  "[Analytics] Get Mercadolibre Insight Init"
);
export const getMercadolibreInsightSuccessAction = createAction(
  "[Analytics] Get Mercadolibre Insight Success",
  props<{ insights: Metric[] }>()
);
export const getMercadolibreInsightFailureAction = createAction(
  "[Analytics] Get Mercadolibre Insight Failure",
  props<{ errorCode: number | null }>()
);

export const getJumpsellerInsightInitAction = createAction(
  "[Analytics] Get Jumpseller Insight Init"
);
export const getJumpsellerInsightSuccessAction = createAction(
  "[Analytics] Get Jumpseller Insight Success",
  props<{ insights: Metric[] }>()
);
export const getJumpsellerInsightFailureAction = createAction(
  "[Analytics] Get Jumpseller Insight Failure",
  props<{ errorCode: number | null }>()
);

export const getInstagramFeedPostsInitAction = createAction(
  "[Analytics] Get Instagram Feed Posts Init"
);
export const getInstagramFeedPostsSuccessAction = createAction(
  "[Analytics] Get Instagram Feed Posts Success",
  props<{ posts: InstagramPost[] }>()
);
export const getInstagramFeedPostsFailureAction = createAction(
  "[Analytics] Get Instagram Feed Posts Failure",
  props<{ errorCode: number | null }>()
);

export const getInsightReportInitAction = createAction(
  "[Analytics] Get Insight Report Init"
);
export const getInsightReportSuccessAction = createAction(
  "[Analytics] Get Insight Report Success"
);
export const getInsightReportFailureAction = createAction(
  "[Analytics] Get Insight Report Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getFacebookInsightInitAction,
  getFacebookInsightSuccessAction,
  getFacebookInsightFailureAction,
  getInstagramInsightInitAction,
  getInstagramInsightSuccessAction,
  getInstagramInsightFailureAction,
  getInstagramFeedPostsInitAction,
  getInstagramFeedPostsSuccessAction,
  getInstagramFeedPostsFailureAction,
  getGoogleInsightInitAction,
  getGoogleInsightSuccessAction,
  getGoogleInsightFailureAction,
  getWooCommerceInsightInitAction,
  getWooCommerceInsightSuccessAction,
  getWooCommerceInsightFailureAction,
  getMercadolibreInsightInitAction,
  getMercadolibreInsightSuccessAction,
  getMercadolibreInsightFailureAction,
  getInsightReportInitAction,
  getInsightReportSuccessAction,
  getInsightReportFailureAction,
  getJumpsellerInsightInitAction,
  getJumpsellerInsightSuccessAction,
  getJumpsellerInsightFailureAction,
});

export type AnalyticsApiAction = typeof actions;
  