import React, { ReactNode } from "react";
import { Col, Row } from "reactstrap";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";
import { PanelWembii } from "../../PanelWembii";

interface Props {
  social: SocialProvider;
  children?: ReactNode;
  bindLoading?: boolean;
  removeShadow?: boolean;
  getLoading?: boolean;
  isSync: boolean;
}

export default function SocialRow({
  social,
  children,
  bindLoading,
  removeShadow,
  getLoading,
  isSync,
}: Props) {
  const { id, name, email, icon, avatar, displayName, url } = social;

  return (
    <PanelWembii
      key={id}
      shadow={!removeShadow}
      className="align-content-center border-0 justify-content-center px-3 py-2 text-center my-2"
    >
      <Row>
        <Col xs="12" lg="2">
          <div className="d-flex align-items-center h-100">{icon}</div>
        </Col>
        <Col xs="12" lg="5">
          <div className="d-flex align-items-center h-100">
            {!isSync ? (
              <span className="font-weight-bold">{name}</span>
            ) : (
              <>
                <LoadingSpinnerWembii active={getLoading}>
                  {avatar && (
                    <img
                      className="socialCard-avatar"
                      src={avatar}
                      alt="avatar"
                    />
                  )}
                </LoadingSpinnerWembii>
                <div className="ml-2">
                  {displayName && <div className="truncate">{displayName}</div>}
                  {url && (
                    <div className="small truncate mb-2">
                      <a href={url} target="_black">
                        {url}
                      </a>
                    </div>
                  )}
                  {email && <div className="small truncate mb-2">{email}</div>}
                </div>
              </>
            )}
          </div>
        </Col>
        <Col xs="12" lg="5">
          <div className="d-flex align-items-center h-100">
            <LoadingSpinnerWembii active={bindLoading}>
              {children}
            </LoadingSpinnerWembii>
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
}
