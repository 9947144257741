import panelApiClient from "../../helpers/services/panel-api-client";
import { DeleteAssignmentResponse } from "../interfaces/api/delete-assignment";
import { DeleteBoardResponse } from "../interfaces/api/delete-board";
import { DeleteCardResponse } from "../interfaces/api/delete-card";
import { DeleteCommentResponse } from "../interfaces/api/delete-comment";
import { DeleteContactResponse } from "../interfaces/api/delete-contact";
import { DeleteFileResponse } from "../interfaces/api/delete-file";
import { DeleteListBoardResponse } from "../interfaces/api/delete-list";
import { GetBoardResponse, UserRoles } from "../interfaces/api/get-board";
import { GetBoardsResponse } from "../interfaces/api/get-boards";
import { GetCardResponse } from "../interfaces/api/get-card";
import { GetListsResponse } from "../interfaces/api/get-lists";
import { GetCardTagsResponse } from "../interfaces/api/get-tags";
import { PostAssignmentRequest, PostAssignmentResponse } from "../interfaces/api/post-assignment";
import { PostBoardRequest, PostBoardResponse } from "../interfaces/api/post-board";
import { PostCardRequest, PostCardResponse } from "../interfaces/api/post-card";
import { PostCommentRequest, PostCommentResponse } from "../interfaces/api/post-comment";
import { PostContactRequest, PostContactResponse } from "../interfaces/api/post-contact";
import { PostFilesResponse } from "../interfaces/api/post-files";
import { PostListRequest, PostListResponse } from "../interfaces/api/post-list";
import { PostCardTagRequest, PostCardTagResponse } from "../interfaces/api/post-card-tag";
import { PostTaggingRequest, PostTaggingResponse } from "../interfaces/api/post-tagging";
import { PutBoardRequest, PutBoardResponse } from "../interfaces/api/put-board";
import { PutCardRequest, PutCardResponse } from "../interfaces/api/put-card";
import { PutCardOrderRequest, PutCardOrderResponse } from "../interfaces/api/put-card-order";
import { PutCommentRequest, PutCommentResponse } from "../interfaces/api/put-comment";
import { PutListBody, PutListResponse } from "../interfaces/api/put-list";
import { PutListOrderRequest, PutListOrderResponse } from "../interfaces/api/put-list-order";
import { PutCardTagRequest, PutCardTagResponse } from "../interfaces/api/put-card-tag";
import { PanelFile } from "../interfaces/PanelFile";
import { Filters } from "../interfaces/PanelFilters";
import { boardMapper } from "./mapper";
import { PostTagResponse } from "../interfaces/api/post-tag";
import { GetListCardsResponse } from "../interfaces/api/get-list-cards";
import { GetListBudgetsResponse } from "../interfaces/api/get-lists-budget";
import { PostCompanyRequest, PostCompanyResponse } from "../interfaces/api/post-company";
import { DeleteCompanyResponse } from "../interfaces/api/delete-company";
import { DeleteProductResponse } from "../interfaces/api/delete-product";
import { PutProductRequest, PutProductResponse } from "../interfaces/api/put-product";
import { PostProductRequest, PostProductResponse } from "../interfaces/api/post-product";
import { PostQuoteRequest, PostQuoteResponse } from "../interfaces/api/post-quote";
import moment from "moment";
import { GetExportResponse } from "../interfaces/api/get-export";
import { PostCustomFieldRequest, PostCustomFieldResponse } from "../interfaces/api/post-custom-field";
import { PutCustomFieldRequest } from "../interfaces/api/put-custom-field";
import { GetCustomFieldsResponse } from "../interfaces/api/get-custom-fields";
import { GetListContactsResponse } from "../interfaces/api/get-list-contacts";
import { PutMoveCardsRequest } from "../interfaces/api/put-move-cards";

export const boardApiService = {
  getActivityBoards(companyId: string) {
    return panelApiClient()
      .get<GetBoardsResponse>(`boards/activity`, { params: { companyId } })
    .then(({ data }) => boardMapper.mapResponseToBoards(data));
  },
  getActivityBoard(companyId: string, boardId: string, filters: Filters) {
    const contacts = filters.contacts?.map((contact) => contact.id);

    return panelApiClient()
      .get<GetBoardResponse>(`boards/activity/${boardId}`,
        { params: { companyId, ...filters, contacts } }
      )
    .then(({ data }) => boardMapper.mapResponseToBoard(data));
  },
  getBusinessBoards(companyId: string) {
    return panelApiClient()
      .get<GetBoardsResponse>(`boards/business`, { params: { companyId } })
    .then(({ data }) => boardMapper.mapResponseToBoards(data));
  },
  getBusinessBoard(companyId: string, boardId: string, filters: Filters) {
    const contacts = filters.contacts?.map((contact) => contact.id);

    return panelApiClient()
      .get<GetBoardResponse>(`boards/business/${boardId}`,
        { params: { companyId, ...filters, contacts } }
      )
    .then(({ data }) => boardMapper.mapResponseToBoard(data));
  },
  getBoardExport(companyId: string, boardId: string, filters: Filters) {
    const contacts = filters.contacts?.map((contact) => contact.id);

    return panelApiClient()
      .get<GetExportResponse>(`boards/${boardId}/export`,
        { params: { companyId, ...filters, contacts } }
      )
    .then(({ data }) => boardMapper.mapResponseToCardsExport(data));
  },
  getCard(
    companyId: string,
    cardId: string,
    boardId: string,
    listId: string
  ) {
    return panelApiClient()
      .get<GetCardResponse>(`boards/${boardId}/list/${listId}/card/${cardId}`,
        { params: { companyId } })
    .then(({ data }) => boardMapper.mapResponseToCard(data));
  },
  postBoard(
    companyId: string,
    name: string,
    color: string,
    currency: string,
    type: string,
    userRoles: UserRoles[],
  ) {
    const roles = userRoles.map((role) => ({
      admin: role.admin,
      owner: role.owner,
      member: role.member,
      userId: role.user.id,
    }));
    const body: PostBoardRequest = {
      name,
      color,
      currency,
      type,
      roles,
    };
    return panelApiClient()
      .post<PostBoardResponse>(`boards`, body, {
        params: { companyId },
      })
      .then(({ data }) => boardMapper.mapResponseToBoard(data));
  },
  putBoard(
    companyId: string,
    boardId: string,
    name: string,
    color: string,
    currency: string,
    userRoles: UserRoles[],
  ) {
    const roles = userRoles.map((role) => ({
      id: role.id,
      admin: role.admin,
      owner: role.owner,
      member: role.member,
      userId: role.user.id,
    }));
    const body: PutBoardRequest = {
      name,
      color,
      currency,
      roles
    };
    return panelApiClient()
      .put<PutBoardResponse>(`boards/${boardId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => boardMapper.mapResponseToBoard(data));
  },
  deleteBoard(
    companyId: string,
    boardId: string,
  ) {
    return panelApiClient()
      .delete<DeleteBoardResponse>(`boards/${boardId}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
  getLists(
    companyId: string,
    boardId: string,
  ) {
    return panelApiClient()
      .get<GetListsResponse>(`boards/${boardId}/list`, {
        params: { companyId },
      })
      .then(({ data }) => boardMapper.mapResponseToLists(data));
  },
  postList(
    companyId: string,
    name: string,
    boardId: string,
  ) {
    const body: PostListRequest = {
      name,
    };
    return panelApiClient()
      .post<PostListResponse>(`boards/${boardId}/list`, body, {
        params: { companyId },
      })
      .then(({ data }) => boardMapper.mapResponseToList(data));
  },
  putList(
    companyId: string,
    boardId: string,
    listId: string,
    body: PutListBody
  ) {
    return panelApiClient()
      .put<PutListResponse>(`boards/${boardId}/list/${listId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => boardMapper.mapResponseToList(data));
  },
  putListOrder(
    companyId: string,
    boardId: string,
    listId: string,
    index: number,
  ) {
    const body: PutListOrderRequest = {
      index,
    };
    return panelApiClient()
      .put<PutListOrderResponse>(`boards/${boardId}/list/${listId}/order`, body, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  deleteList(
    companyId: string,
    boardId: string,
    listId: string,
  ) {
    return panelApiClient()
      .delete<DeleteListBoardResponse>(`boards/${boardId}/list/${listId}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
  getListCards(
    companyId: string,
    boardId: string,
    listId: string,
    filters: Filters,
    offset: number,
  ) {
    return panelApiClient()
      .get<GetListCardsResponse>(`boards/${boardId}/list/${listId}/cards`, {
        params: { companyId, ...filters, offset },
      })
      .then(({ data }) => boardMapper.mapResponseToCards(data));
  },
  getListBudgets(
    companyId: string,
    boardId: string,
    listId: string,
    filters: Filters,
  ) {
    return panelApiClient()
      .get<GetListBudgetsResponse>(`boards/${boardId}/list/${listId}/probability`, {
        params: { companyId, ...filters },
      })
      .then(({ data }) => boardMapper.mapResponseToBudgets(data));
  },
  postCard(
    companyId: string,
    title: string,
    boardId: string,
    listId: string,
    contacts: string[]
  ) {
    const body: PostCardRequest = {
      title,
      contacts,
    };
    return panelApiClient()
      .post<PostCardResponse>(`boards/${boardId}/list/${listId}/card`, body, {
        params: { companyId },
      })
      .then(({ data }) => boardMapper.mapResponseToCard(data));
  },
  putCard(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    body: PutCardRequest
  ) {
    return panelApiClient()
      .put<PutCardResponse>(`boards/${boardId}/list/${listId}/card/${cardId}`,
        body, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToCard(data));
  },
  putCardOrder(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    listDestinationId: string,
    index: number,
  ) {
    const body: PutCardOrderRequest = {
      id: listDestinationId,
      index,
    };
    return panelApiClient()
      .put<PutCardOrderResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/order`, body, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  postAssignment(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    user: string,
  ) {
    const body: PostAssignmentRequest = {
      user
    };
    return panelApiClient()
      .post<PostAssignmentResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/assign`, 
        body, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToAssignments(data));
  },
  deleteAssignment(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    userId: string,
  ) {
    return panelApiClient()
      .delete<DeleteAssignmentResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/assign/${userId}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToAssignments(data));
  },
  postContact(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    contact: string,
  ) {
    const body: PostContactRequest = {
      contact
    };
    return panelApiClient()
      .post<PostContactResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/contact`, 
        body, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToContacts(data));
  },
  deleteContact(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    contactId: string,
  ) {
    return panelApiClient()
      .delete<DeleteContactResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/contact/${contactId}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToContacts(data));
  },
  postCompany(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    company: string,
  ) {
    const body: PostCompanyRequest = {
      company
    };
    return panelApiClient()
      .post<PostCompanyResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/company`, 
        body, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToCompanies(data));
  },
  deleteCompany(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    company: string,
  ) {
    return panelApiClient()
      .delete<DeleteCompanyResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/company/${company}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToCompanies(data));
  },
  deleteCard(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
  ) {
    return panelApiClient()
      .delete<DeleteCardResponse>(`boards/${boardId}/list/${listId}/card/${cardId}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
  postComment(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    text: string,
    type: number,
  ) {
    const body: PostCommentRequest = {
      text,
      type,
    };
    return panelApiClient()
      .post<PostCommentResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/comment`, 
        body, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToComment(data));
  },
  postTagging(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    users: string[],
    text: string,
  ) {
    const body: PostTaggingRequest = {
      users,
      text,
    };
    return panelApiClient()
      .post<PostTaggingResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/tag`, 
        body, { params: { companyId } }
      )
      .then(({ data }) => data.payload);
  },
  putComment(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    commentId: string,
    text: string,
    type: number
  ) {
    const body: PutCommentRequest = {
      text,
      type,
    };
    return panelApiClient()
      .put<PutCommentResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/comment/${commentId}`, 
        body, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToComment(data));
  },
  deleteComment(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    commentId: string,
  ) {
    return panelApiClient()
      .delete<DeleteCommentResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/comment/${commentId}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
  postFiles(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    files: PanelFile[],
  ) {
    const bodyFormData = new FormData();
    files.map((file) =>
      bodyFormData.append("files", file.data)
    );
    return panelApiClient()
      .post<PostFilesResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/file`,
        bodyFormData, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToFiles(data));
  },
  deleteFile(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    fileId: string,
  ) {
    return panelApiClient()
      .delete<DeleteFileResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/file/${fileId}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
  postTag(
    companyId: string,
    boardId: string,
    cardId: string,
    tagId: string,
  ) {
    return panelApiClient()
      .post<PostTagResponse>(`boards/${boardId}/card/${cardId}/tags/${tagId}`, 
        {}, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToCardTag(data));
  },
  deleteTag(
    companyId: string,
    boardId: string,
    cardId: string,
    tagId: string,
  ) {
    return panelApiClient()
      .delete(`boards/${boardId}/card/${cardId}/tags/${tagId}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
  getCardTags(companyId: string, boardId: string) {
    return panelApiClient()
      .get<GetCardTagsResponse>(`boards/${boardId}/tags`, {
        params: { companyId },
      })
      .then(({ data }) => boardMapper.mapResponseToCardTags(data));
  },
  putCardTag(
    companyId: string,
    boardId: string,
    tagId: string,
    name: string,
    textColor: string,
    backgroundColor: string
  ) {
    const body: PutCardTagRequest = {
      name,
      textColor,
      backgroundColor,
    };
    return panelApiClient()
      .put<PutCardTagResponse>(`boards/${boardId}/tags/${tagId}`,
        body, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToCardTag(data));
  },
  postCardTag(
    companyId: string,
    boardId: string,
    name: string,
    textColor: string,
    backgroundColor: string
  ) {
    const body: PostCardTagRequest = {
      name,
      textColor,
      backgroundColor,
    };
    return panelApiClient()
      .post<PostCardTagResponse>(`boards/${boardId}/tags`, body, {
        params: { companyId },
      })
      .then(({ data }) => boardMapper.mapResponseToCardTag(data));
  },
  deleteCardTag(
    companyId: string,
    boardId: string,
    tagId: string
  ) {
    return panelApiClient()
      .delete(`boards/${boardId}/tags/${tagId}`,
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
  postProduct(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    body: PostProductRequest,
  ) {
    return panelApiClient()
      .post<PostProductResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/product`, 
        body, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToProduct(data));
  },
  putProduct(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    productId: string,
    body: PutProductRequest,
  ) {
    return panelApiClient()
      .put<PutProductResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/product/${productId}`, 
        body, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToProduct(data));
  },
  deleteProduct(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    productId: string,
  ) {
    return panelApiClient()
      .delete<DeleteProductResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/product/${productId}`, 
        { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
  postQuote(
    companyId: string,
    boardId: string,
    listId: string,
    cardId: string,
    quote: PostQuoteRequest,
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("files", quote.pdf);
    bodyFormData.append("code", quote.id);
    bodyFormData.append("name", quote.name);
    bodyFormData.append("creationDate", moment(quote.creationDate).format());
    bodyFormData.append("expirationDate", moment(quote.expirationDate).format());

    return panelApiClient()
      .post<PostQuoteResponse>(`boards/${boardId}/list/${listId}/card/${cardId}/quote`,
        bodyFormData, { params: { companyId } }
      )
      .then(({ data }) => boardMapper.mapResponseToQuote(data));
  },
  postCustomField(
    companyId: string,
    boardId: string,
    body: PostCustomFieldRequest
  ) {
    return panelApiClient()
      .post<PostCustomFieldResponse>(`boards/${boardId}/customfields`, body, { params: { companyId } })
      .then(({ data }) => boardMapper.mapResponseToCustomField(data));
  },
  putCustomField(
    companyId: string,
    boardId: string,
    id: string,
    body: PutCustomFieldRequest
  ) {
    return panelApiClient()
      .put(`boards/${boardId}/customfields/${id}`, body, { params: { companyId } })
      .then(({ data }) => boardMapper.mapResponseToCustomField(data));
  },
  getCustomFields(companyId: string, boardId: string) {
    return panelApiClient()
      .get<GetCustomFieldsResponse>(`boards/${boardId}/customfields`, {
        params: { companyId },
      })
      .then(({ data }) => boardMapper.mapResponseToCustomFields(data));
  },
  deletCustomField(id: string, companyId: string, boardId: string) {
    return panelApiClient()
      .delete(`boards/${boardId}/customfields/${id}`, { params: { companyId } })
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
  getListContacts(
    companyId: string,
    boardId: string,
    listId: string,
    filters: Filters
  ) {
    return panelApiClient()
      .get<GetListContactsResponse>(`boards/${boardId}/list/${listId}/contacts`, {
        params: { companyId, ...filters },
      })
      .then(({ data }) => boardMapper.mapResponseToContacts(data));
  },
  putMoveCards(
    companyId: string,
    boardId: string,
    body: PutMoveCardsRequest
  ) {
    return panelApiClient()
      .put(`boards/${boardId}/movecards`, body, { params: { companyId } })
      .then(({ data }) => data.payload);
  },
  postImport(
    companyId: string,
    name: string,
    file: File
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("files", file);
    bodyFormData.append("name", name);
    return panelApiClient()
      .post(`boards/new/import`, bodyFormData, { params: { companyId } })
      .then(({ data }) => boardMapper.mapResponseToId(data));
  },
};
