import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { INSTAGRAM_BIND_MODAL } from "../../../../constants/modal.constant";
import { INSTAGRAM_COLOR } from "../../../../constants/socialColors.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useInstagramCore } from "../../../../cores/instagram";
import { InstagramAccount } from "../../../../cores/instagram/interface/api/get-instagram-accounts";
import { useMeCore } from "../../../../cores/me";
import { useIntl } from 'react-intl';
import InstagramAccounts from "./InstagramAccounts";
import { InstagramIcon } from "./InstagramIcon";

export default function InstagramLoginForm({
  accessToken,
}: {
  accessToken: string;
}) {
  const intl = useIntl();
  const { turnOffModal, isModal } = useGlobalCore();
  const { bind, errorCode, settings } = useInstagramCore();
  const { company, getMeSocialSettings } = useMeCore();
  const [account, setAccount] = useState<InstagramAccount | null>(null);

  useEffect(() => {
    isModal(INSTAGRAM_BIND_MODAL) &&
      !_.isEmpty(settings) &&
      !errorCode &&
      turnOffModal(INSTAGRAM_BIND_MODAL);
  }, [errorCode, isModal, settings, turnOffModal]);

  const handleSubmit = useCallback(
    async (e) => {
      if (account) {
        e.preventDefault();
        bind(company!.id, account, "", accessToken, () => {
          getMeSocialSettings(company?.id!);
        });
      }
    },
    [account, bind, company, accessToken, getMeSocialSettings]
  );

  const disabled = useMemo(() => !account || !company, [company, account]);

  return (
    <div className="p-4">
      <h2 className="h2 text-center mb-4">
        {intl.formatMessage({ id: "app.syncInstagram.title" })}
      </h2>
      <div className="my-2 d-flex justify-content-center">
        <InstagramIcon size="6x" color={INSTAGRAM_COLOR} className="mb-2" />
      </div>
      <InstagramAccounts
        onSelectAccount={setAccount}
        selectedAccount={account}
      />
      {!!account && (
        <Button
          className="my-4 mx-auto w-50"
          color="primary"
          block
          disabled={disabled}
          onClick={handleSubmit}
        >
          {intl.formatMessage({ id: "app.syncInstagram.login" })}
        </Button>
      )}
    </div>
  );
}
