import React from "react";

interface Props {
  onClick: () => void;
}

export default function Backdrop(props: Props) {
  const { onClick } = props;
  return <div onClick={onClick} className="backdrop"></div>;
}
