import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { ADMIN_COMPANY_LOADING } from "../../constants/loading.constant";
import { useCompanyCore } from "../../cores/company";
import { useMeCore } from "../../cores/me";
import { useIntl } from "react-intl";
import { Company } from "../../cores/me/interfaces/api/get-meCompany";
import { useRouterCore } from "../../cores/router";
import AreYouSure from "../component/AreYouSure";
import CompanyCard from "../component/companies/CompanyCard";
import CreateCompanyForm from "../component/companies/CreateCompanyForm";
import { InputWembii } from "../component/InputWembii";
import { NoData } from "../component/NoData";
import { PanelWembii } from "../component/PanelWembii";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useResellerCore } from "../../cores/reseller";
import _ from "lodash";
import { useGlobalCore } from "../../cores/globals";
import { COMPANY_CREATE_CONFIG, COMPANY_DELETE_FORM } from "../../constants/modal.constant";

export default function Companies() {
  const intl = useIntl();
  const { companies, companiesFilters, company, deleteCompany, getSearchCompanies, getCompanies } = useCompanyCore();
  const { goToEditCompany, goToSocial } = useRouterCore();
  const { user, isReseller, isAdmin } = useMeCore();
  const { turnOffModal, turnOnModal, getModalVisibility, isModal } = useGlobalCore();
  const { resellers, getResellers } = useResellerCore();
  const [debounceSearchText, setDebounceSearchText] = useState("");
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [search, setSearch] = useState("");
  const [reseller, setReseller] = useState(user?.reseller?.id);

  useEffect(() => {
    if (!user?.userRole?.admin && !(user?.userRole?.reseller && user?.reseller))
      goToSocial();
  }, [user, goToSocial]);

  const debouncedSearch = useRef(
    _.debounce((search) => setSearch(search), 1000)
  ).current;

  const resellerId = useMemo(
    () => (user?.reseller?.id ? +user.reseller.id : undefined),
    [user]
  );

  useEffect(() => {
    debouncedSearch(debounceSearchText);
  }, [debounceSearchText, debouncedSearch]);

  useEffect(() => {
    getResellers();
  }, [getResellers]);

  useEffect(() => {
    getSearchCompanies({
      search,
      resellerId: reseller,
    });
  }, [company, search, getSearchCompanies, reseller]);

  useEffect(() => {
    if (company && company.reseller) setReseller(`${company.reseller.id}`);
  }, [resellers, company]);

  useEffect(() => {
    getCompanies({ resellerId });
  }, [getCompanies, resellerId, user]);

  const toggleSideModal = useCallback(() => {
    turnOnModal(COMPANY_CREATE_CONFIG, true);
  }, [turnOnModal]);

  const onDeleting = useCallback((company) => {
    turnOnModal(COMPANY_DELETE_FORM, true);
    setSelectedCompany(company);
  }, [turnOnModal]);

  const onEditCompany = (companyId: string) => {
    goToEditCompany(companyId, "companies");
  };

  const onDeleteCompany = () => {
    deleteCompany(selectedCompany?.id!, () => {
      setSelectedCompany(null);
      turnOffModal(COMPANY_DELETE_FORM);
    });
  };

  const canResellerCreate = useMemo(
    () =>
      isReseller &&
      user?.reseller &&
      user?.reseller.companyCreationLimit > companies.length,
    [companies.length, isReseller, user]
  );

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({id: "app.sidebar.company"})}
        rightChildren={
          <>
            {(isAdmin || canResellerCreate) && (
              <Button className="whiteButton" onClick={toggleSideModal}>
                {intl.formatMessage({ id: "app.adminCompanies.createCompany" })}
              </Button>
            )}
            {!isAdmin && !canResellerCreate && (
              <div className="py-2 px-3 wembii-border">
                <FontAwesomeIcon
                  className="mx-2"
                  size="1x"
                  icon={["fas", "exclamation-triangle"]}
                />{" "}
                {intl.formatMessage({ id: "app.adminCompanies.error3" })}
              </div>
            )}
          </>
        }
      />
      
      {!companies.length ? null : (
        <PanelWembii className="w-100 px-4 py-3 my-3 pointer" shadow>
          <Row>
            <Col xs="12" lg="4">
              <InputWembii
                label={intl.formatMessage({ id: "app.adminCompanies.search" })}
                type="text"
                placeholder={intl.formatMessage({ id: "app.adminCompanies.searchCompany" })}
                rounded
                onChange={(value) => setDebounceSearchText(value)}
                value={debounceSearchText}
                style={{ borderRadius: "0px 10px 10px 0px" }}
                icon={
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="1x"
                    color="grey"
                    className="pointer mb-2"
                  />
                }
              />
            </Col>
            <Col xs="6" lg="2">
              <InputWembii
                label="Reseller"
                type="select"
                onChange={(value: string) => setReseller(value)}
                value={reseller}
                disabled={user?.userRole?.reseller}
              >
                {[
                  { id: "", name: `${intl.formatMessage({ id: "app.adminUsers.all"  })}`  },
                  ...resellers
                ].map(
                  ({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  )
                )}
              </InputWembii>
            </Col>
          </Row>
        </PanelWembii>
      )}
      <Content className="justify-content-center">
        <UpdateLoadingCard
          loadingId={ADMIN_COMPANY_LOADING}
          text={intl.formatMessage({ id: "app.adminCompanies.updatingCompany" })}
        />
        {!companies.length && (
          <NoData
            image="generic3"
            text={intl.formatMessage({ id: "app.adminCompanies.nothaveCompany" })}
            actionLabel={intl.formatMessage({ id: "app.adminCompanies.createCompany" })}
            action={toggleSideModal}
            horizontal
          />
        )}
        <Container fluid > 
          <Row className="d-flex flex-wrap justify-content-center">
            {companiesFilters.map((company) => (
              <CompanyCard
                key={company.company.id}
                company={company.company}
                role={company.role}
                plan={company.plan}
                owner={company.owner}
                onEditCompany={onEditCompany}
                onDeleteCompany={() => onDeleting(company.company)}
              />
            ))}
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(COMPANY_CREATE_CONFIG)}
        visible={getModalVisibility(COMPANY_CREATE_CONFIG)}
        onClose={() => {
          turnOffModal(COMPANY_CREATE_CONFIG);
        }}
      >
        <CreateCompanyForm
          asReseller={isReseller}
          onSubmit={() => {
            turnOffModal(COMPANY_CREATE_CONFIG);
            getCompanies({ resellerId });
          }}
        />
      </SideModal>
      <SideModal
        active={isModal(COMPANY_DELETE_FORM)}
        visible={getModalVisibility(COMPANY_DELETE_FORM)}
        onClose={() => {
          setSelectedCompany(null);
          turnOffModal(COMPANY_DELETE_FORM);
        }}
      >
        {selectedCompany && (
          <AreYouSure
            message={`${intl.formatMessage({ id: "app.adminCompanies.error4" })} "${selectedCompany?.name}"`}
            onSubmit={onDeleteCompany}
            onClose={() => {
              setSelectedCompany(null);
              turnOffModal(COMPANY_DELETE_FORM);
            }}
          />
        )}
      </SideModal>
    </Layout>
  );
}
