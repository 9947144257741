import cn from "classnames";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { InstagramUserData } from "../../../../cores/analytics/interface/api/get-instagram-feed-posts";
import { makeid } from "../../../../cores/utils"

interface Props {
  user: InstagramUserData;
  className?: string;
}

export const InstagramUser = (props: Props) => {
  const {
    user: { displayName, profilePictureUrl },
    className,
  } = props;

  const target = makeid(16);

  return (
    <>
      <img
        style={{ width: "50px", height: "50px" }}
        className={cn(className, "rounded-circle")}
        src={profilePictureUrl}
        alt="profilePictureUrl"
        id={target}
      />
      <UncontrolledTooltip placement="bottom" target={target}>
        {displayName}
      </UncontrolledTooltip>
    </>
  );
};
