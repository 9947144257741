import React, { useCallback, useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMeCore } from "../../../cores/me";
import { BOARD_CARD_MODAL } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { Contact } from "../../../cores/contacts/interfaces/api/get-contacts";
import { useBoardCore } from "../../../cores/boards";
import { InputWembii } from "../InputWembii";
import { useCompanyCore } from "../../../cores/company";
import CardDetails from "../boards/modal/CardDetails";
import { BoardType } from "../../../cores/boards/constants/board-type.constant";
import { Board } from "../../../cores/boards/interfaces/api/get-board";

interface Props {
  contact: Contact;
  onClose: () => void;
}

export default function BoardForm({ contact, onClose }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const { getCompany } = useCompanyCore();
  const { boards, listNames, getLists, postCard, getCard, getCustomFields } = useBoardCore();
  const { turnOnModal } = useGlobalCore();

  const [board, setBoard] = useState<Board | null>(null);
  const [list, setList] = useState("");

  useEffect(() => {
    if (boards.length && !board && boards[0].type === BoardType.BUSINESS) {
      setBoard(boards[0]);
      getLists(company?.id!, boards[0].id)
    }
  }, [boards, board, company, getLists]);

  useEffect(() => {
    if (board) {
      getLists(company?.id!, board.id);
    }
  }, [board, company, getLists]);

  useEffect(() => {
    if (listNames.length) {
      setList(listNames[0].id);
    }
  }, [listNames]);

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault();

    const card = await postCard(
      company?.id!,
      list,
      `Oportunidad de ${contact.name}`,
      board?.id!,
      [contact.id]
    );

    if (card) {
      getCard(company?.id!, board?.id!, list, card?.id!);
      getCustomFields(company?.id!, board?.id!);
      getCompany(company?.id!);
      turnOnModal(
        BOARD_CARD_MODAL,
        <CardDetails board={board} />
      );
      onClose();
    }
  }, [postCard, getCard, getCompany, getCustomFields, turnOnModal, onClose, contact, board, company, list]);

  const disabled = useCallback(
    () => !board || !list,
    [board, list]
  );

  const changeBoard = useCallback((boardId) => {
    const board = boards.find((board) => board.id === boardId);
    if (board) {
      setBoard(board)
    }
  }, [boards]);

  return (
    <div
      className="h-100 w-100 position-fixed top-0 z-index-5 d-flex align-items-center justify-content-center bg-black-transparent"
      style={{ borderRadius: "30px"}}
    >
      <Form className="text-center bg-white rounded p-2" onSubmit={onSubmit}>
        <div className="text-right w-100">
          <FontAwesomeIcon
            icon={["fas", "times"]}
            size="1x"
            className="pointer"
            onClick={onClose}
          />
        </div>
        
        <InputWembii
          type="select"
          label={intl.formatMessage({ "id": "app.sidebar.crm.business" })}
          className="mx-2"
          style={{ width: 250 }}
          value={board?.id}
          onChange={(v) => changeBoard(v)}
        >
          {boards.map((board) => (
            <option key={board.id} value={board.id}>
              {board.name}
            </option>
          ))}
        </InputWembii>
        <InputWembii
          type="select"
          label={intl.formatMessage({ "id": "app.crmBusiness.list" })}
          className="mx-2"
          style={{ width: 250 }}
          value={list}
          onChange={setList}
        >
          {listNames.map((list) => (
            <option key={list.id} value={list.id}>
              {list.name}
            </option>
          ))}
        </InputWembii>

        <Button color="primary" disabled={disabled()} className="ml-1">
          {intl.formatMessage({ "id": "app.crmBusiness.create" })}
        </Button>
      </Form>
    </div>
  );
}