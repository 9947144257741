import React from "react";
import { Badge } from "reactstrap";
import { FreeTrialStatus } from "../../../cores/user/constants/free-trial-status";

interface Props {
  freeTrial: boolean;
  onClick?: () => void;
}

export const FreeTrialBadge = (props: Props) => {
  const { freeTrial, onClick } = props;

  return (
    <Badge
      key={`tooltip-freetrial`}
      id={`tooltip-freetrial`}
      pill
      className="m-1"
      style={{
        minWidth: "30px",
        minHeight: "30px",
      }}
      onClick={onClick}
    >
      <h5
        className="m-0 h6 px-2"
        style={{ lineHeight: "25px" }}
      >
        {freeTrial ? FreeTrialStatus.FREE_TRIAL : FreeTrialStatus.NO_FREE_TRIAL}
      </h5>
    </Badge>
  );
};
