import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { ChatTemplate } from "../interfaces/api/get-chat-templates";
import { EmailTemplate } from "../interfaces/api/get-email-templates";
import { SocialTemplate } from "../interfaces/api/get-social-templates";

export const postEmailTemplateInitAction = createAction(
  "[Template] Post Email Template Init"
);
export const postEmailTemplateSuccessAction = createAction(
  "[Template] Post Email Template Success",
  props< { template: EmailTemplate } >()
);
export const postEmailTemplateFailureAction = createAction(
  "[Template] Post Email Template Failure",
  props<{ error: string }>()
);

export const postSocialTemplateInitAction = createAction(
  "[Template] Post Social Template Init"
);
export const postSocialTemplateSuccessAction = createAction(
  "[Template] Post Social Template Success",
  props< { template: SocialTemplate } >()
);
export const postSocialTemplateFailureAction = createAction(
  "[Template] Post Social Template Failure",
  props<{ error: string }>()
);

export const postChatTemplateInitAction = createAction(
  "[Template] Post Chat Template Init"
);
export const postChatTemplateSuccessAction = createAction(
  "[Template] Post Chat Template Success",
  props< { template: ChatTemplate } >()
);
export const postChatTemplateFailureAction = createAction(
  "[Template] Post Chat Template Failure",
  props<{ error: string }>()
);

export const putEmailTemplateInitAction = createAction(
  "[Template] Put Email Template Init"
);
export const putEmailTemplateSuccessAction = createAction(
  "[Template] Put Email Template Success",
  props< { template: EmailTemplate } >()
);
export const putEmailTemplateFailureAction = createAction(
  "[Template] Put Email Template Failure",
  props<{ error: string }>()
);

export const putSocialTemplateInitAction = createAction(
  "[Template] Put Social Template Init"
);
export const putSocialTemplateSuccessAction = createAction(
  "[Template] Put Social Template Success",
  props< { template: SocialTemplate } >()
);
export const putSocialTemplateFailureAction = createAction(
  "[Template] Put Social Template Failure",
  props<{ error: string }>()
);

export const putChatTemplateInitAction = createAction(
  "[Template] Put Chat Template Init"
);
export const putChatTemplateSuccessAction = createAction(
  "[Template] Put Chat Template Success",
  props< { template: ChatTemplate } >()
);
export const putChatTemplateFailureAction = createAction(
  "[Template] Put Chat Template Failure",
  props<{ error: string }>()
);

export const getEmailTemplatesInitAction = createAction(
  "[Template] Get Email Templates Init"
);
export const getEmailTemplatesSuccessAction = createAction(
  "[Template] Get Email Templates Success",
  props< { templates: EmailTemplate[] } >()
);
export const getEmailTemplatesFailureAction = createAction(
  "[Template] Get Email Templates Failure",
  props<{ error: string }>()
);

export const getSocialTemplatesInitAction = createAction(
  "[Template] Get Social Templates Init"
);
export const getSocialTemplatesSuccessAction = createAction(
  "[Template] Get Social Templates Success",
  props< { templates: SocialTemplate[] } >()
);
export const getSocialTemplatesFailureAction = createAction(
  "[Template] Get Social Templates Failure",
  props<{ error: string }>()
);

export const getChatTemplatesInitAction = createAction(
  "[Template] Get Chat Templates Init"
);
export const getChatTemplatesSuccessAction = createAction(
  "[Template] Get Chat Templates Success",
  props< { templates: ChatTemplate[] } >()
);
export const getChatTemplatesFailureAction = createAction(
  "[Template] Get Chat Templates Failure",
  props<{ error: string }>()
);

export const deleteEmailTemplateInitAction = createAction(
  "[Template] Delete Email Template Init"
);
export const deleteEmailTemplateSuccessAction = createAction(
  "[Template] Delete Email Template Success",
  props< { id: string } >()
);
export const deleteEmailTemplateFailureAction = createAction(
  "[Template] Delete Email Template Failure",
  props<{ error: string }>()
);

export const deleteSocialTemplateInitAction = createAction(
  "[Template] Delete Social Template Init"
);
export const deleteSocialTemplateSuccessAction = createAction(
  "[Template] Delete Social Template Success",
  props< { id: string } >()
);
export const deleteSocialTemplateFailureAction = createAction(
  "[Template] Delete Social Template Failure",
  props<{ error: string }>()
);

export const deleteChatTemplateInitAction = createAction(
  "[Template] Delete Chat Template Init"
);
export const deleteChatTemplateSuccessAction = createAction(
  "[Template] Delete Chat Template Success",
  props< { id: string } >()
);
export const deleteChatTemplateFailureAction = createAction(
  "[Template] Delete Chat Template Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postEmailTemplateInitAction,
  postEmailTemplateSuccessAction,
  postEmailTemplateFailureAction,
  putEmailTemplateInitAction,
  putEmailTemplateSuccessAction,
  putEmailTemplateFailureAction,
  getEmailTemplatesInitAction,
  getEmailTemplatesSuccessAction,
  getEmailTemplatesFailureAction,
  deleteEmailTemplateInitAction,
  deleteEmailTemplateSuccessAction,
  deleteEmailTemplateFailureAction,
  postSocialTemplateInitAction,
  postSocialTemplateSuccessAction,
  postSocialTemplateFailureAction,
  putSocialTemplateInitAction,
  putSocialTemplateSuccessAction,
  putSocialTemplateFailureAction,
  getSocialTemplatesInitAction,
  getSocialTemplatesSuccessAction,
  getSocialTemplatesFailureAction,
  deleteSocialTemplateInitAction,
  deleteSocialTemplateSuccessAction,
  deleteSocialTemplateFailureAction,
  postChatTemplateInitAction,
  postChatTemplateSuccessAction,
  postChatTemplateFailureAction,
  putChatTemplateInitAction,
  putChatTemplateSuccessAction,
  putChatTemplateFailureAction,
  getChatTemplatesInitAction,
  getChatTemplatesSuccessAction,
  getChatTemplatesFailureAction,
  deleteChatTemplateInitAction,
  deleteChatTemplateSuccessAction,
  deleteChatTemplateFailureAction,
});

export type TemplateApiAction = typeof actions;
