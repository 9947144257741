import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const notificationRoot = (state: State) => state.notification;

export const notificationsSelector = createSelector(notificationRoot, (me) => 
  me.notifications.ids.map((id) => me.notifications.entities[id])
);
export const unreadNotificationsSelector = createSelector(notificationRoot, (me) => 
  me.unreadNotifications.ids.map((id) => me.unreadNotifications.entities[id])
);

export const countSelector = createSelector(notificationRoot, (me) => me.count);
export const unreadCountSelector = createSelector(notificationRoot, (me) => me.unreadCount);

export const infoMessageSelector = createSelector(notificationRoot, (me) => me.infoMessage);
export const infoMessageEsSelector = createSelector(notificationRoot, (me) => me.infoMessageEs);
export const infoMessageEnSelector = createSelector(notificationRoot, (me) => me.infoMessageEn);
export const infoMessagePtSelector = createSelector(notificationRoot, (me) => me.infoMessagePt);
