import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMeCore } from "../../../../cores/me";
import { useResellerCore } from "../../../../cores/reseller";
import { Reseller } from "../../../../cores/reseller/interfaces/api/get-resellers";
import { ResellerBadge } from "../../companies/ResellerBadge";
import { InputWembii } from "../../InputWembii";

interface Props {
  reseller?: Reseller;
  onAssignReseller: (value: number) => void;
}

export const ResellerSelector = (props: Props) => {
  const { reseller, onAssignReseller } = props;
  const [editReseller, setEditReseller] = useState(false);
  const [resellerId, setResellerId] = useState("");
  const { resellers, getResellers } = useResellerCore();
  const { isAdmin, isReseller, userReseller } = useMeCore();

  useEffect(() => {
    getResellers();
  }, [getResellers]);

  useEffect(() => {
    reseller && setResellerId(reseller.id);
  }, [reseller]);

  const handleAssignReseller = useCallback(
    async (resellerId: number) => {
      onAssignReseller(resellerId);
      setEditReseller(false);
      setResellerId("" + resellerId);
    },
    [onAssignReseller]
  );

  const options = useMemo(
    () => (isReseller && userReseller ? [userReseller] : resellers),
    [isReseller, resellers, userReseller]
  );

  const selectedReseller = useMemo(
    () => resellers.find(({ id }) => +id === +resellerId!),
    [resellers, resellerId]
  );

  const setEdit = useCallback(() => {
    if (
      (isReseller && !reseller) ||
      (isReseller && reseller && userReseller?.id === reseller?.id) ||
      isAdmin
    ) {
      setEditReseller(true);
    }
  }, [isAdmin, isReseller, reseller, userReseller]);

  return (
    <span className="w-fit m-1" onClick={setEdit}>
      {editReseller || !selectedReseller ? (
        <InputWembii
          mb={false}
          type="select"
          value={resellerId}
          onChange={(v) => handleAssignReseller(v)}
        >
          {[{ id: "", name: "No Reseller" }, ...options].map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
          :
        </InputWembii>
      ) : (
        selectedReseller && <ResellerBadge reseller={selectedReseller!} show />
      )}
    </span>
  );
};
