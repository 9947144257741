import React, { useCallback, useMemo } from "react";
import { Bar, HorizontalBar } from "react-chartjs-2";
import { CHART_THEME } from "../../../../constants/theme";
import { ChartMetric } from "../../../../cores/analytics/interface/ChartMetric";
import {
  charBasicDataset,
  barLineChartBasicOptions,
  createGradientFill,
} from "../../../utils/chartsUtils";

interface Props extends ChartMetric {
  horizontal?: boolean;
  colorByBar?: boolean;
  height?: number;
  onElementClick?: (element: any) => void;
}

export const BarChart = (props: Props) => {
  const { labels, datasets, horizontal, colorByBar, onElementClick, height } =
    props;

  const getColor = useCallback(
    (index: number) => CHART_THEME[index % CHART_THEME.length],
    []
  );

  const _colors = useMemo(
    (): string[] => datasets[0].data.map((_, index) => getColor(index)),
    [datasets, getColor]
  );

  const data = useCallback(
    (canvas?: any) => ({
      labels,
      datasets: datasets.map(
        (
          { label, color, data, fill, type = "bar", yAxisID = "left" },
          index
        ) => ({
          ...charBasicDataset,
          label,
          borderColor: colorByBar ? _colors : color || getColor(index),
          pointBackgroundColor: colorByBar ? _colors : color || getColor(index),
          fill,
          type: horizontal ? "horizontalBar" : type,
          yAxisID,
          backgroundColor: colorByBar
            ? _colors
            : canvas && type === "line"
            ? createGradientFill(canvas, color || getColor(index))
            : color || getColor(index),
          data: [...data],
        })
      ),
    }),
    [_colors, colorByBar, datasets, getColor, horizontal, labels]
  );

  return horizontal ? (
    <HorizontalBar data={data} options={barLineChartBasicOptions} height={height} />
  ) : (
    <Bar
      data={data}
      options={barLineChartBasicOptions}
      getElementAtEvent={onElementClick}
      height={height}
    />
  );
};
