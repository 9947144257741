import { EmailFile } from "../../email/interface/emailFile";
import socialApiClient from "../../helpers/services/social-api-client";
import { GetSmtpBindResponse } from "../interface/api/get-smtp-bind";
import { PostSmtpBindRequest, PostSmtpBindResponse } from "../interface/api/post-smtp-bind";
import { Email, PostSmtpEmailResponse } from "../interface/api/post-smtp-email";
import { smtpSettingMapper } from "./mapper";

export const smtpApiService = {
  unbind(companyId: string, asUser: boolean) {
    return socialApiClient()
      .post(`smtp/unbind`, {}, { params: { companyId, asUser } })
      .then(({ data }) => data);
  },
  bind(
    companyId: string,
    asUser: boolean,
    host: string,
    port: string,
    email: string,
    password: string,
    secure: boolean
  ) {
    const body: PostSmtpBindRequest = {
      host,
      port,
      email,
      password,
      secure
    } 
    return socialApiClient()
      .post<PostSmtpBindResponse>(`smtp/bind`, body, {
        params: { companyId, asUser },
      })
      .then(({ data }) => smtpSettingMapper.mapResponseToSmtpSetting(data));
  },
  get(companyId: string, asUser: boolean) {
    return socialApiClient()
      .get<GetSmtpBindResponse>(`smtp`, {
        params: { companyId, asUser },
      })
      .then(({ data }) => smtpSettingMapper.mapResponseToSmtpSetting(data));
  },
  getConnection(
    companyId: string,
    host: string,
    port: string,
    email: string,
    password: string,
    secure: boolean
  ) {
    const body: PostSmtpBindRequest = {
      host,
      port,
      email,
      password,
      secure
    } 
    return socialApiClient()
      .post(`smtp/verify`, body, { params: { companyId } })
      .then(({ data }) => data);
  },
  postEmail(
    companyId: string,
    body: Email,
    files: EmailFile[],
    asUser: boolean,
    cardId: string | null
  ) {
    const bodyFormData = new FormData();
    files.map((file) => 
      bodyFormData.append("files", file.data)
    );
    bodyFormData.append("subject", body.subject);
    bodyFormData.append("message", body.message);
    bodyFormData.append("to", JSON.stringify(body.to));
    if (cardId) {
      bodyFormData.append("cardId", cardId);
    }
    return socialApiClient()
      .post<PostSmtpEmailResponse>(`smtp/email`, bodyFormData,  {
        params: { companyId, asUser },
      })
      .then(({ data }) =>
        smtpSettingMapper.mapResponseToId(data)
      );
  },
};
