import {
  PostWhatsappBindResponse,
  WhatsappSetting,
} from "../interface/api/post-whatsapp-bind";
import {
  Auth0Payload,
  GetWhatsappAuth0BindResponse,
} from "../interface/api/get-whatsapp-auth0";

export const whatsappSettingMapper = {
  mapResponseToWhatsappSetting(
    response: PostWhatsappBindResponse
  ): WhatsappSetting {
    const { whatsappSetting } = response?.payload;
    return whatsappSetting;
  },
  mapResponseToAuth0Config(
    response: GetWhatsappAuth0BindResponse
  ): Auth0Payload {
    return response.payload;
  },
};
