import analyticsApiClient from "../../helpers/services/analytics-api-client";
import { GetJumpsellerOrders, GetJumpsellerOrdersParams } from "../interface/api/get-jumpseller-orders";
import { GetMercadolibreOrdersParams } from "../interface/api/get-mercadolibre-orders";
import {
  GetWooCommerceOrders,
  GetWooCommerceOrdersParams,
} from "../interface/api/get-woocommerce-orders";
import { PutJumpsellerOrderRequest, PutJumpsellerResponse } from "../interface/api/put-jumpseller-order";
import { PutWooCommerceOrderRequest, PutWooCommerceOrderResponse } from "../interface/api/put-woocommerce-order";
import { marketMapper } from "./mapper";

export const marketApiService = {
  wooCommerceOrders(companyId: string, params: GetWooCommerceOrdersParams) {
    return analyticsApiClient()
      .get<GetWooCommerceOrders>(`woocommerce/orders`, {
        params: { companyId, ...params },
      })
      .then(({ data }) => marketMapper.mapResponseToOrders(data));
  },
  putWooCommerceOrder(companyId: string, orderId: string, status: string) {
    const body: PutWooCommerceOrderRequest = {
      status
    }
    return analyticsApiClient()
      .put<PutWooCommerceOrderResponse>(`woocommerce/order/${orderId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => marketMapper.mapResponseToOrder(data));
  },
  mercadolibreOrders(companyId: string, params: GetMercadolibreOrdersParams) {
    return analyticsApiClient()
      .get<GetWooCommerceOrders>(`mercadolibre/orders`, {
        params: { companyId, ...params },
      })
      .then(({ data }) => marketMapper.mapResponseToOrders(data));
  },
  jumpsellerOrders(companyId: string, params: GetJumpsellerOrdersParams) {
    return analyticsApiClient()
      .get<GetJumpsellerOrders>(`jumpseller/orders`, {
        params: { companyId, ...params },
      })
      .then(({ data }) => marketMapper.mapResponseToOrders(data));
  },
  putJumpsellerOrder(companyId: string, orderId: string, status: string) {
    const body: PutJumpsellerOrderRequest = {
      status
    }
    return analyticsApiClient()
      .put<PutJumpsellerResponse>(`jumpseller/order/${orderId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => marketMapper.mapResponseToOrder(data));
  },
};
