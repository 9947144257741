import socialApiClient from "../../helpers/services/social-api-client";
import { GetMercadolibreBindResponse } from "../interface/api/get-mercadolibre-bind";
import { GetMercadolibreLoginResponse } from "../interface/api/get-mercadolibre-login";
import {
  PostMercadolibreBindRequest,
  PostMercadolibreBindResponse,
} from "../interface/api/post-mercadolibre-bind";
import { mercadolibreSettingMapper } from "./mapper";

export const mercadolibreApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`mercadolibre/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostMercadolibreBindRequest) {
    return socialApiClient()
      .post<PostMercadolibreBindResponse>(`mercadolibre/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        mercadolibreSettingMapper.mapResponseToMercadolibreSetting(data)
      );
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetMercadolibreBindResponse>(`mercadolibre`, {
        params: { companyId },
      })
      .then(({ data }) =>
        mercadolibreSettingMapper.mapResponseToMercadolibreSetting(data)
      );
  },
  login(companyId: string, country: string) {
    return socialApiClient()
      .get<GetMercadolibreLoginResponse>(`mercadolibre/login/${country}`, {
        params: { companyId },
      })
      .then(({ data }) =>
        mercadolibreSettingMapper.mapResponseToMercadolibreLoginUrl(data)
      );
  },
};
