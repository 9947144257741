import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FACEBOOK_FORMS_LOADING, FACEBOOK_FORM_LEADS_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { facebookFormApiService } from "./services/api";
import {
  getFacebookFormFailureAction,
  getFacebookFormInitAction,
  getFacebookFormSuccessAction,
  getFacebookFormsFailureAction,
  getFacebookFormsInitAction,
  getFacebookFormsSuccessAction,
  setViewAction,
} from "./store/api-actions";
import { facebookFormSelector, facebookFormsSelector, viewSelector } from "./store/selector";

export function useFacebookFormCore() {
  const dispatch = useDispatch();
  const facebookForms = useSelector(facebookFormsSelector);
  const facebookForm = useSelector(facebookFormSelector);
  const view = useSelector(viewSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const getFacebookForms = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getFacebookFormsInitAction());
        turnOnLoading(FACEBOOK_FORMS_LOADING);
        const response = await facebookFormApiService.getFacebookForms(companyId);
        dispatch(getFacebookFormsSuccessAction(response));
        turnOffLoading(FACEBOOK_FORMS_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(FACEBOOK_FORMS_LOADING);
        dispatch(getFacebookFormsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getFacebookForm = useCallback(
    async (companyId: string, formId: string) => {
      try {
        dispatch(getFacebookFormInitAction());
        turnOnLoading(FACEBOOK_FORM_LEADS_LOADING);
        const response = await facebookFormApiService.getFacebookForm(companyId, formId);
        dispatch(getFacebookFormSuccessAction(response));
        turnOffLoading(FACEBOOK_FORM_LEADS_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(FACEBOOK_FORM_LEADS_LOADING);
        dispatch(getFacebookFormFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  return {
    facebookForms,
    facebookForm,
    view,
    setView: (view: string) => dispatch(setViewAction({ view })),
    getFacebookForms,
    getFacebookForm,
  };
}
