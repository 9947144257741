import moment from "moment";
import React, { useMemo, useState } from "react";
import {
  Button,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap";
import { v4 } from "uuid";
import { InstagramPost } from "../../../../cores/analytics/interface/api/get-instagram-feed-posts";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import { useSocialPostCore } from "../../../../cores/socialPost";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { CommentLabel } from "./CommentLabel";
import { InstagramUser } from "./InstagramUser";
import { LikeLabel } from "./LikeLabel";
import { ViewLabel } from "./ViewLabel";

interface Props {
  post: InstagramPost;
}

export const InstagramPostModal = ({ post }: Props) => {
  const {
    timestamp,
    imageUrls = [],
    videoUrl,
    likeCount,
    commentCount,
    viewCount,
    topLikers = [],
    likers = [],
    text,
  } = post;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const {
    addImages,
    addVideo,
    addSocialProvider,
    addText,
    clear,
  } = useSocialPostCore();
  const { goToSocialPost } = useRouterCore();
  const { socialSettings } = useMeCore();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const createPost = () => {
    clear();
    //TODO data video and image are false, maybe is possible get it from server
    !videoUrl &&
      imageUrls.length &&
      addImages(
        imageUrls.map((url) => ({
          id: v4(),
          lastModified: 0,
          name: `imagefrompost_${v4()}`,
          size: 100,
          type: "png",
          data: url,
        }))
      );
    videoUrl &&
      addVideo({
        id: v4(),
        lastModified: 0,
        name: `videofrompost_${v4()}`,
        duration: 1,
        size: 100,
        type: "video/mp4",
        data: videoUrl,
      });
    addText(text);

    const instagramSocialProvider =
      socialSettings && socialSettings[SocialProviderId.Instagram];
    instagramSocialProvider &&
      addSocialProvider({
        id: SocialProviderId.Instagram,
        name: SocialProviderName.Instagram,
        displayName: instagramSocialProvider.name,
        avatar: instagramSocialProvider.avatarUrl,
      });

    goToSocialPost("");
  };

  const items = useMemo(
    () => imageUrls.map((src) => ({ src, altText: "Post Image" })),
    [imageUrls]
  );

  const slides = useMemo(
    () =>
      items.map((item) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
          >
            <img
              src={item.src}
              alt={item.altText}
              className="imageInstagramPostModal"
            />
          </CarouselItem>
        );
      }),
    [items]
  );

  const topLikerUsers = useMemo(
    () =>
      topLikers
        .map((displayName) =>
          likers.find((liker) => displayName === liker.displayName)
        )
        .filter((liker) => liker !== undefined),
    [likers, topLikers]
  );

  return (
    <div className="align-items-center justify-content-center d-flex flex-column px-2 py-4">
      {imageUrls.length > 0 && !videoUrl && (
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      )}
      {videoUrl && (
        <video className="videoEditor" controls>
          <source src={videoUrl} />
          Your browser does not support HTML5 video.
        </video>
      )}
      <div className="my-3 text-center">
        <Button onClick={createPost}>Reutilizar posteo</Button>
      </div>
      <div className="my-3 text-center">
        <h6 className="h5">Fecha de publicación</h6>
        <span className="d-block">
          {moment(timestamp * 1000)
            .format("DD/MM/YYYY")
            .toString()}
        </span>
        <span>
          {moment(timestamp * 1000)
            .format("hh:mm:ss")
            .toString()}
        </span>
      </div>
      <div className="my-3 ">
        <LikeLabel amount={likeCount} className="mx-2" />
        <CommentLabel amount={commentCount} className="mx-2" />
        <ViewLabel amount={viewCount} className="mx-2" />
      </div>
      {text && (
        <div className="my-3 px-4 text-center w-100">
          <h6 className="h5">Texto</h6>
          <div className=" neu-in px-3 py-3 text-center">{text}</div>
        </div>
      )}
      {topLikerUsers.length > 0 && (
        <div className="px-1 my-3 text-center">
          <h6 className="h5">Top Likers</h6>
          <div className="px-1">
            {topLikerUsers.map((liker) => (
              <InstagramUser key={liker!.id} user={liker!} className="m-1" />
            ))}
          </div>
        </div>
      )}
      {likers.slice(0, 50).length > 0 && (
        <div className="px-1 my-3 text-center">
          <h6 className="h5">Likers</h6>
          <div className="px-1">
            {likers.map((liker) => (
              <InstagramUser key={liker!.id} user={liker} className="m-1" />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
