import React from "react";
import { useIntl } from "react-intl";
import { Button, Label } from "reactstrap";
import { useInstagramCore } from "../../../../cores/instagram";
import { InstagramAccount as Account } from "../../../../cores/instagram/interface/api/get-instagram-accounts";

interface Props {
  onSelectAccount: (account: Account) => void;
  selectedAccount: Account | null;
}

export default function InstagramAccounts(props: Props) {
  const intl = useIntl();
  const { accounts } = useInstagramCore();
  const { onSelectAccount, selectedAccount } = props;

  return (
    <div className="p-4 overflow-auto">
      <h2 className="h2 text-center mb-4">
        {intl.formatMessage({ id: "app.social.selectAccount" })}
      </h2>
      {accounts
        .filter(
          (account) => !selectedAccount || account.id === selectedAccount?.id
        )
        .map((account) => (
          <InstagramAccount
            key={account.id}
            account={account}
            selected={account.id === selectedAccount?.id}
            onSelectAccount={onSelectAccount}
          /> 
        ))}
      {!selectedAccount && (
        <Label className="text-muted text-center my-4">
          {intl.formatMessage({ id: "app.syncInstagram.txt1" })}{' '}
          <a
            href="https://soporte.wembii.com/es/article/como-cambiar-una-cuenta-de-instagram-a-empresa-1d9gri7/?bust=1618583393673"
            target="_blank"
            rel="noopener noreferrer"
          >
             {intl.formatMessage({ id: "app.syncInstagram.txt2" })}
          </a>
          {' '}{intl.formatMessage({ id: "app.syncInstagram.txt3" })}
        </Label>
      )}
    </div>
  );
}

const InstagramAccount = ({
  account,
  selected,
  onSelectAccount,
}: {
  account: Account;
  selected: boolean;
  onSelectAccount: (account: Account) => void;
}) => {
  const { profilePictureUrl, name } = account;
  const intl = useIntl();

  return (
    <div className="align-items-center d-flex p-2 neu-in pointer">
      {profilePictureUrl && (
        <img
          style={{ width: "50px", height: "50px" }}
          className="rounded-circle"
          src={profilePictureUrl}
          alt="Avatar"
        />
      )}
      <span className="h5 ml-3"> {name} </span>
      {!selected && (
        <Button
          className="ml-auto" 
          color="primary"
          onClick={() => onSelectAccount(account)}
        >
          {intl.formatMessage({ id: "app.syncInstagram.select" })}
        </Button>
      )}
    </div>
  );
};
