import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { useMarketCore } from "../../../../cores/market";
import {
  WooCommerceOrderByOptions,
  WooCommerceOrderOptions,
  WooCommerceOrderStatus,
  WooCommerceStatusOptions,
} from "../../../../cores/market/woocommerce.config";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { PaginationWemmbii } from "../../pagination/Pagination";
import { PanelWembii } from "../../PanelWembii";
import { useIntl } from "react-intl";

export const WooCommerceOrderBar = () => {
  const intl = useIntl();
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<WooCommerceOrderStatus>(
    WooCommerceOrderStatus.any
  );
  const { getWooCommerceOrders, wooCommerceOrders: orders } = useMarketCore();
  const { company, socialSettings } = useMeCore();

  const isSync = useMemo(() => socialSettings && socialSettings.wooCommerce, [
    socialSettings,
  ]);

  const debouncedSearch = useRef(
    _.debounce(
      (search) =>
        getWooCommerceOrders(company!.id, {
          search,
          orderBy,
          order,
          status,
          page,
          perPage,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    isSync && debouncedSearch(search);
  }, [debouncedSearch, search, isSync]);

  useEffect(() => {
    setPerPage(10);
    setPage(1);
    isSync &&
      getWooCommerceOrders(company!.id, {
        orderBy,
        order,
        status,
      });
  }, [company, getWooCommerceOrders, order, orderBy, status, isSync]);

  useEffect(() => {
    isSync &&
      getWooCommerceOrders(company!.id, {
        orderBy,
        order,
        status,
        perPage,
        page,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, getWooCommerceOrders, perPage, page, isSync]);

  return (
    <PanelWembii className="w-100 px-4 py-3 mb-3 pointer" shadow>
      <Row>
        <Col xs="12" lg="3">
          <InputWembii
            label={intl.formatMessage({ id: "app.wooCommerce.search" })} 
            placeholder={intl.formatMessage({ id: "app.wooCommerce.search" })} 
            type="text"
            onChange={(value) => setSearch(value)}
            value={search}
          />
        </Col>
        <Col xs="6" lg="2">
          <InputWembii
            label={intl.formatMessage({ id: "app.wooCommerce.order" })} 
            type="select"
            onChange={(value) => setOrder(value)}
            value={order}
          >
            {WooCommerceOrderOptions.map(({ value, label }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="6" lg="2">
          <InputWembii
            label={intl.formatMessage({ id: "app.wooCommerce.orderfor" })} 
            type="select"
            onChange={(value) => setOrderBy(value)}
            value={orderBy}
          >
            {WooCommerceOrderByOptions.map(({ value, label }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="3">
          <InputWembii
            label={intl.formatMessage({ id: "app.wooCommerce.state" })} 
            type="select"
            onChange={(value) => setStatus(value)}
            value={status}
          >
            {WooCommerceStatusOptions.map(({ value, label }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="auto">
          <PaginationWemmbii
            className="w-fit mx-auto mr-lg-0 ml-lg-auto"
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            disabledPages={orders.length < perPage}
          />
        </Col>
      </Row>
    </PanelWembii>
  );
};
