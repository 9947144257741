import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Event } from "../interface/api/get-events";
import { CommentEvent, Event as SingleEvent } from "../interface/api/get-event";
import { Calendar, Input } from "../interface/api/get-calendar";
import { Calendar as PublicCalendar } from "../interface/api/get-public-calendar";
import { Hour } from "../interface/api/post-calendar-time";

export const postEventInitAction = createAction(
  "[Event] Post Event Init"
);
export const postEventSuccessAction = createAction(
  "[Event] Post Event Success",
  props<{ event: Event }>()
);
export const postEventFailureAction = createAction(
  "[Event] Post Event Failure",
  props<{ error: string }>()
);

export const deleteEventInitAction = createAction(
  "[Event] Delete Event Init"
);
export const deleteEventSuccessAction = createAction(
  "[Event] Delete Event Success",
  props<{ id: string }>()
);
export const deleteEventFailureAction = createAction(
  "[Event] Delete Event Failure",
  props<{ error: string }>()
);

export const getEventsInitAction = createAction("[Event] Get Events Init");
export const getEventsSuccessAction = createAction(
  "[Event] Get Events Success",
  props<{ events: Event[] }>()
);
export const getEventsFailureAction = createAction(
  "[Event] Get Events Failure",
  props<{ error: string }>()
);

export const getEventInitAction = createAction("[Event] Get Event Init");
export const getEventSuccessAction = createAction(
  "[Event] Get Event Success",
  props<{ event: SingleEvent }>()
);
export const getEventFailureAction = createAction(
  "[Event] Get Event Failure",
  props<{ error: string }>()
);

export const getAvailableTimeInitAction = createAction("[Event] Get Available Time Init");
export const getAvailableTimeSuccessAction = createAction(
  "[Event] Get Available Time Success",
  props<{ hours: string[] }>()
);
export const getAvailableTimeFailureAction = createAction(
  "[Event] Get Available Time Failure",
  props<{ errorCode: number, errorMessage: string }>()
);

export const postPublicEventInitAction = createAction(
  "[Event] Post Public Event Init"
);
export const postPublicEventSuccessAction = createAction(
  "[Event] Post Public Event Success",
  props<{ id: string }>()
);
export const postPublicEventFailureAction = createAction(
  "[Event] Post Public Event Failure",
  props<{ error: string }>()
);

export const getPublicCalendarInitAction = createAction("[Event] Get Public Calendar Init");
export const getPublicCalendarSuccessAction = createAction(
  "[Event] Get Public Calendar Success",
  props<{ calendar: PublicCalendar }>()
);
export const getPublicCalendarFailureAction = createAction(
  "[Event] Get Public Calendar Failure",
  props<{ error: string }>()
);

export const getCalendarInitAction = createAction("[Event] Get Calendar Init");
export const getCalendarSuccessAction = createAction(
  "[Event] Get Calendar Success",
  props<{ calendar: Calendar }>()
);
export const getCalendarFailureAction = createAction(
  "[Event] Get Calendar Failure",
  props<{ error: string }>()
);

export const putCalendarInitAction = createAction(
  "[Event] Put Calendar Init"
);
export const putCalendarSuccessAction = createAction(
  "[Event] Put Calendar Success",
  props<{ calendar: Calendar }>()
);
export const putCalendarFailureAction = createAction(
  "[Event] Put Calendar Failure",
  props<{ errorCode: number, errorMessage: string }>()
);

export const postCalendarTimeInitAction = createAction(
  "[Event] Post Calendar Time Init"
);
export const postCalendarTimeSuccessAction = createAction(
  "[Event] Post Calendar Time Success",
  props<{ time: Hour }>()
);
export const postCalendarTimeFailureAction = createAction(
  "[Event] Post Calendar Time Failure",
  props<{ error: string }>()
);

export const deleteCalendarTimeInitAction = createAction(
  "[Event] Delete Calendar Time Init"
);
export const deleteCalendarTimeSuccessAction = createAction(
  "[Event] Delete Calendar Time Success",
  props<{ id: string }>()
);
export const deleteCalendarTimeFailureAction = createAction(
  "[Event] Delete Calendar Time Failure",
  props<{ error: string }>()
);

export const postContactInputInitAction = createAction(
  "[Event] Post Contact Input Init"
);
export const postContactInputSuccessAction = createAction(
  "[Event] Post Contact Input Success",
  props<{ input: Input }>()
);
export const postContactInputFailureAction = createAction(
  "[Event] Post Contact Input Failure",
  props<{ error: string }>()
);

export const deleteContactInputInitAction = createAction(
  "[Event] Delete Contact Input Init"
);
export const deleteContactInputSuccessAction = createAction(
  "[Event] Delete Contact Input Success",
  props<{ id: string }>()
);
export const deleteContactInputFailureAction = createAction(
  "[Event] Delete Contact Input Failure",
  props<{ error: string }>()
);

export const postCommentInitAction = createAction(
  "[Event] Post Comment Init"
);
export const postCommentSuccessAction = createAction(
  "[Event] Post Comment Success",
  props<{ comment: CommentEvent }>()
);
export const postCommentFailureAction = createAction(
  "[Event] Post Comment Failure",
  props<{ error: string }>()
);

export const putCommentInitAction = createAction(
  "[Event] Put Comment Init"
);
export const putCommentSuccessAction = createAction(
  "[Event] Put Comment Success",
  props<{ comment: CommentEvent }>()
);
export const putCommentFailureAction = createAction(
  "[Event] Put Comment Failure",
  props<{ error: string }>()
);

export const deleteCommentInitAction = createAction(
  "[Event] Delete Comment Init"
);
export const deleteCommentSuccessAction = createAction(
  "[Event] Delete Comment Success",
  props<{ id: string }>()
);
export const deleteCommentFailureAction = createAction(
  "[Event] Delete Comment Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postEventInitAction,
  postEventSuccessAction,
  postEventFailureAction,
  deleteEventInitAction,
  deleteEventSuccessAction,
  deleteEventFailureAction,
  getEventsInitAction,
  getEventsSuccessAction,
  getEventsFailureAction,
  getEventInitAction,
  getEventSuccessAction,
  getEventFailureAction,
  getAvailableTimeInitAction,
  getAvailableTimeSuccessAction,
  getAvailableTimeFailureAction,
  postPublicEventInitAction,
  postPublicEventSuccessAction,
  postPublicEventFailureAction,
  getCalendarInitAction,
  getCalendarSuccessAction,
  getCalendarFailureAction,
  putCalendarInitAction,
  putCalendarSuccessAction,
  putCalendarFailureAction,
  postCalendarTimeInitAction,
  postCalendarTimeSuccessAction,
  postCalendarTimeFailureAction,
  deleteCalendarTimeInitAction,
  deleteCalendarTimeSuccessAction,
  deleteCalendarTimeFailureAction,
  postContactInputInitAction,
  postContactInputSuccessAction,
  postContactInputFailureAction,
  deleteContactInputInitAction,
  deleteContactInputSuccessAction,
  deleteContactInputFailureAction,
  getPublicCalendarInitAction,
  getPublicCalendarSuccessAction,
  getPublicCalendarFailureAction,
  postCommentInitAction,
  postCommentSuccessAction,
  postCommentFailureAction,
  deleteCommentInitAction,
  deleteCommentSuccessAction,
  deleteCommentFailureAction,
  putCommentInitAction,
  putCommentSuccessAction,
  putCommentFailureAction,
});

export type EventApiAction = typeof actions;
