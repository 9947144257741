import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import footerBusinessImage from "../../../../assets/preview/linkedIn-business-preview-footer.png";
import footerImage from "../../../../assets/preview/linkedin-buttons-footer.png";
import fakeTextHeader from "../../../../assets/preview/preview-fake-text-header.png";
import fakeText from "../../../../assets/preview/preview-fake-text.png";
import { useLinkedInCore } from "../../../../cores/linkedIn";
import { useLinkedInBusinessCore } from "../../../../cores/linkedInBusiness";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { PostError } from "../../../../cores/socialPost/interfaces/postError";
import { ErrorList } from "./ErrorList";
import { MediaPreview } from "./MediaPreview";

interface Props {
  business?: boolean;
}

export const LinkedInPostPreview = ({ business }: Props) => {
  const { settings: businessSettings } = useLinkedInBusinessCore();
  const { settings: normalSettings } = useLinkedInCore();
  const settings = business ? businessSettings : normalSettings;

  const { text, mentions, postErrors } = useSocialPostCore();
  const { name, avatarUrl } = settings || {};

  const providerErrors: PostError[] = useMemo(
    () =>
      postErrors.filter(({ socialProviderId }) =>
        !business
          ? socialProviderId === SocialProviderId.LinkedIn
          : socialProviderId === SocialProviderId.LinkedInBusiness
      ),
    [business, postErrors]
  );

  const formattedText = useMemo(() => {
    const provider = !business
      ? SocialProviderId.LinkedIn
      : SocialProviderId.LinkedInBusiness;
    const orderMentions = mentions.sort((a, b) =>
      a.offset > b.offset ? -1 : 1
    );

    let txt = text;
    orderMentions.forEach((mention) => {
      const submention = mention.mentions.find(
        (submention) => submention.socialProviderId === provider
      );
      const name = submention
        ? `<span style="color:#0d4b7e;">${submention.username}</span>`
        : mention.mentions[0].username;
      const offset = mention.offset + mention.mentions[0].username.length + 1;
      txt = txt.slice(0, mention.offset) + name + txt.slice(offset);
    });

    return txt;
  }, [text, mentions, business]);

  return providerErrors.length > 0 ? (
    <ErrorList errors={providerErrors} />
  ) : (
    <div className="linkedInPreview-container neu-out p-2">
      <Container fluid>
        <Row className="linkedInPreview-header">
          <Col xs="6" className="d-flex align-items-center p-0">
            <div>
              <img
                className="linkedInPreview-header-avatar"
                src={avatarUrl}
                alt="avatar"
              />
            </div>
            <div>
              <div className="linkedInPreview-header-subText">{name}</div>
              <div>
                <img
                  src={fakeTextHeader}
                  alt="avatar"
                  className="linkedInPreview-header-subText"
                />
                <img src={fakeTextHeader} alt="avatar" />
              </div>
            </div>
          </Col>
          <Col
            xs="6"
            className="align-content-center col-6 d-flex justify-content-end"
          >
            <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
          </Col>
        </Row>
        <Row className="linkedInPreview-description">
          <Col xs="12" className="line-break-anywhere p-0 pre-wrap">
            {formattedText ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: formattedText,
                }}
              ></div>
            ) : (
              <img
                src={fakeText}
                alt="fake-text"
                className="linkedInPreview-footer"
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="p-0">
            <MediaPreview className="linkedInPreview-image" />
          </Col>
        </Row>
        <Row>
          <img
            className="linkedInPreview-footer"
            src={business ? footerBusinessImage : footerImage}
            alt="footer"
          />
        </Row>
      </Container>
    </div>
  );
};
