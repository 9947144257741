import mainApiClient from "../../helpers/services/main-api-client";
import { GetResellersResponse } from "../interfaces/api/get-resellers";
import {
  PostResellerCompanyAssignRequest,
  PostResellerUserAssignRequest,
} from "../interfaces/api/post-assign-reseller";
import {
  PostResellerRequest,
  PostResellerResponse,
} from "../interfaces/api/post-reseller";
import {
  PutResellerRequest,
  PutResellerResponse,
} from "../interfaces/api/put-reseller";
import { resellerMapper } from "./mapper";

export const resellerApiService = {
  postReseller(name: string, companyCreationLimit: number) {
    const body: PostResellerRequest = {
      name,
      companyCreationLimit,
    };
    return mainApiClient()
      .post<PostResellerResponse>(`reseller`, body)
      .then(({ data }) => resellerMapper.mapResponseToReseller(data));
  },
  putReseller(id: string, body: PutResellerRequest) {
    return mainApiClient()
      .put<PutResellerResponse>(`reseller/${id}`, body)
      .then(({ data }) => resellerMapper.mapResponseToReseller(data));
  },
  getResellers() {
    return mainApiClient()
      .get<GetResellersResponse>(`reseller`)
      .then(({ data }) => resellerMapper.mapResponseToResellers(data));
  },
  postAssignUserReseller(resellerId: number, userId: number) {
    const body: PostResellerUserAssignRequest = {
      resellerId,
      userId,
    };
    return mainApiClient()
      .post(`reseller/assign/user`, body)
      .then(({ data }) => data);
  },
  deleteUnassignUserReseller(userId: number) {
    return mainApiClient()
      .delete(`reseller/unassign/user/${userId}`)
      .then(({ data }) => data);
  },
  postAssignCompanyReseller(resellerId: number, companyId: number) {
    const body: PostResellerCompanyAssignRequest = {
      resellerId,
      companyId,
    };
    return mainApiClient()
      .post(`reseller/assign/company`, body)
      .then(({ data }) => data);
  },
  deleteUnassignCompanyReseller(companyId: number) {
    return mainApiClient()
      .delete(`reseller/unassign/company/${companyId}`)
      .then(({ data }) => data);
  },
};
