import _ from "lodash";
import cn from "classnames";
import moment, { Moment } from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import { PanelWembii } from "../PanelWembii";
import { DAY_OF_WEEK } from "../../utils/dateUtils";
import { useSocialPostCore } from "../../../cores/socialPost";
import { PostItem } from "./PostItem";

export const Calendar = () => {
  const { date, socialPosts } = useSocialPostCore();

  const getPostsByDate = (date: Moment) => 
    socialPosts.filter(post =>
      post.post.postAt ?
        date.isSame(moment(post.post.postAt), "d") :
        date.isSame(moment(post.post.scheduleDate), "d"));

  const renderDays = () => (
    <Row className="days">
      {_.range(0, 7).map((d) => (
        <Col className="text-center text-truncate" key={d}>
          {DAY_OF_WEEK[moment(date).startOf("W").isoWeekday(0).add(d, 'd').format("d")]}
        </Col>
      ))}
    </Row>
  );

  const renderCells = () => {
    const monthStart = moment(date).startOf('M');
    const monthEnd = moment(date).endOf('M');
    const dateStart = moment(monthStart).startOf('W').isoWeekday(0);

    const rows = [];
    let day = dateStart.subtract(1, 'd');

    while (day <= monthEnd) {
      rows.push(
        <Row key={day.toString()}>
          {_.range(0, 7).map(() => {
            day.add(1, "d");

            return (
              <Col 
                className={cn("cell", {
                  "disabled": !day.isSame(moment(monthStart), "M"),
                  "today": day.isSame(moment(), "d")
                })} 
                key={day.toString()}
              >
                <span className="number-day">
                  {day.format("D")}
                </span>
                {getPostsByDate(day).map((post) => (
                  <PostItem post={post} key={post.post.id} />
                ))}
              </Col>
            );
          })}
        </Row>
      );

    }

    return <div>{rows}</div>;
  };

  return (
    <PanelWembii className="w-100 px-5 py-4 mb-2" shadow>
      <Row>
        <Col xs="12" className="d-flex justify-content-end">
          <div className="calendar w-100">
            {renderDays()}
            {renderCells()}
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
