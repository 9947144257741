import React, { useCallback } from "react";
import { useMeCore } from "../../../../cores/me";
import { useGlobalCore } from "../../../../cores/globals";
import { ContactEvent } from "../../../../cores/event/interface/api/get-event";
import Avatar from "../../Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_DANGER } from "../../../../constants/theme";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import { getFirstLetters } from "../../../utils/stringUtils";
import { CONTACT_DELETE_MODAL, CONTACT_EXPAND_EDIT_FORM } from "../../../../constants/modal.constant";
import EditContactForm from "../../contacts/modal/EditContactForm";
import AreYouSure from "../../AreYouSure";
import { useContactCore } from "../../../../cores/contacts";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useConfigCore } from "../../../../cores/config";

interface Props {
  id: number;
  contact: ContactEvent;
}

export default function ContactRow({ id, contact }: Props) { 
  const { company } = useMeCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { getCommentTypes } = useConfigCore();
  const { getCustomFields, getContact, getContactTags, deleteContacts } = useContactCore();

  const onShowingContact = useCallback((contact) => {
    if (contact.id) {
      getContactTags(company?.id!);
      getCommentTypes(company?.id!);
      getCustomFields(company?.id!);
      getContact(company?.id!, contact.id);
      turnOnModal(
        CONTACT_EXPAND_EDIT_FORM,
        <EditContactForm
          closeOnUpdating={false}
          onDelete={() => {
            turnOnModal(
              CONTACT_DELETE_MODAL,
              <AreYouSure
                message={`Está seguro de querer eliminar el contacto ${contact.name}`}
                onSubmit={() => {
                  deleteContacts(company?.id!, [contact.id]);
                  turnOffModal(CONTACT_DELETE_MODAL);
                  turnOffModal(CONTACT_EXPAND_EDIT_FORM);
                }}
                onClose={() => turnOffModal(CONTACT_DELETE_MODAL)}
              />,
              Position.LEFT
            );
          }}
        />,
        Position.LEFT
      );
    }
  }, [
    turnOnModal,
    turnOffModal,
    deleteContacts,
    getContactTags,
    getCustomFields,
    getCommentTypes,
    getContact,
    company,
  ]);

  return (
    <div key={id} className="d-flex align-items-center" onClick={() => onShowingContact(contact)}>
      <div className="position-relative">
        <Avatar
          className="text-dark-50 font-weight-bold pointer m-1"
          label={contact.name
            ? `${getFirstLetters(contact.name)}`
            : ""
          }
          icon={
            <FontAwesomeIcon
              icon={["fas", "user"]}
              size="1x"
            />
          }
        />
        {contact.status !== UserStatus.ACTIVE.id && (
          <FontAwesomeIcon
            icon={["fas", "ban"]}
            size="1x"
            style={{
              top: "-3px",
              right: "-3px"
            }}
            className="position-absolute"
            color={COLOR_DANGER}
          />
        )}
      </div>
      <div>
        <span>{contact.name
          ? `${contact.name} `
          : " "
        }</span>
        <span className="text-muted">{`<${contact.email || "No email"}>`}</span>
      </div>
    </div>
  );
}