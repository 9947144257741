import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Coupon } from "../interfaces/api/get-coupon";

export const postFlowSubscriptionInitAction = createAction(
  "[Payment] Post Flow Subscription Init"
);
export const postFlowSubscriptionSuccessAction = createAction(
  "[Payment] Post Flow Subscription Success"
);
export const postFlowSubscriptionFailureAction = createAction(
  "[Payment] Post Flow Subscription Failure",
  props<{ error: string }>()
);

export const deleteSubscriptionInitAction = createAction(
  "[Payment] Delete Subscription Init"
);
export const deleteSubscriptionSuccessAction = createAction(
  "[Payment] Delete Subscription Success"
);
export const deleteSubscriptionFailureAction = createAction(
  "[Payment] Delete Subscription Failure",
  props<{ error: string }>()
);

export const getFlowCouponInitAction = createAction(
  "[Payment] Get Flow Coupon Init"
);
export const getFlowCouponSuccessAction = createAction(
  "[Payment] Get Flow Coupon Success",
  props<{ coupon: Coupon }>()
);
export const getFlowCouponFailureAction = createAction(
  "[Payment] Get Flow Coupon Failure",
  props<{ error: string }>()
);

export const postStripeSubscriptionInitAction = createAction(
  "[Payment] Post Stripe Subscription Init"
);
export const postStripeSubscriptionSuccessAction = createAction(
  "[Payment] Post Stripe Subscription Success"
);
export const postStripeSubscriptionFailureAction = createAction(
  "[Payment] Post Stripe Subscription Failure",
  props<{ error: string }>()
);

export const postStripeCheckPaymentInitAction = createAction(
  "[Payment] Post Stripe Check Payment Init"
);
export const postStripeCheckPaymentSuccessAction = createAction(
  "[Payment] Post Stripe Check Payment Success"
);
export const postStripeCheckPaymentFailureAction = createAction(
  "[Payment] Post Stripe Check Payment Failure",
  props<{ error: string }>()
);

export const getStripeCouponInitAction = createAction(
  "[Payment] Get Stripe Coupon Init"
);
export const getStripeCouponSuccessAction = createAction(
  "[Payment] Get Stripe Coupon Success",
  props<{ coupon: Coupon }>()
);
export const getStripeCouponFailureAction = createAction(
  "[Payment] Get Stripe Coupon Failure",
  props<{ error: string }>()
);

export const getCardVerificationInitAction = createAction(
  "[Payment] Get Card Verification Init"
);
export const getCardVerificationSuccessAction = createAction(
  "[Payment] Get Card Verification Success",
  props<{ valid: boolean }>()
);
export const getCardVerificationFailureAction = createAction(
  "[Payment] Get Card Verification Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getFlowCouponInitAction,
  getFlowCouponSuccessAction,
  getFlowCouponFailureAction,
  postFlowSubscriptionInitAction,
  postFlowSubscriptionSuccessAction,
  postFlowSubscriptionFailureAction,
  deleteSubscriptionInitAction,
  deleteSubscriptionSuccessAction,
  deleteSubscriptionFailureAction,
  postStripeSubscriptionInitAction,
  postStripeSubscriptionSuccessAction,
  postStripeSubscriptionFailureAction,
  getStripeCouponInitAction,
  getStripeCouponSuccessAction,
  getStripeCouponFailureAction,
  postStripeCheckPaymentInitAction,
  postStripeCheckPaymentSuccessAction,
  postStripeCheckPaymentFailureAction,
  getCardVerificationInitAction,
  getCardVerificationSuccessAction,
  getCardVerificationFailureAction,
});

export type PaymentApiAction = typeof actions;
