import { Order } from "../../cores/market/interface/order";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import _ from "lodash";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

const generateMarketPdfContent = (
  order: Order, icon: string, statusName: string, statusColor: string, avatar?: string
) => {
  const {
    number,
    creationDate,
    currency: code,
    price,
    billing,
    shipping,
    paymentMethod,
    paidDate,
    items
  } = order;

  const articles = items.map(({ name, quantity, total }) => (
    [{
      text: `${name} X ${quantity} = ${currencyFormatter.format( +total, { code })}`,
      border: [true, true, true, true],
      alignment: "center"
    }]
  ));

  const content: any[] = [];

  if (avatar) {
    content.push({ image: `data:image/jpeg;base64,${avatar}`, alignment: "center", fit: [150, 100], margin: [0, 10] })
  } else {
    content.push({ svg: icon, alignment: "center", fit: [100, 100], margin: [0, 10] })
  }

  content.push(
    { text: `Venta #${number}`, alignment: "center", fontSize: "18", color: "#b2b2b2" },
    { text: _.upperCase(statusName), alignment: "center", fontSize: "18", color: statusColor, bold: true },
    { table : { headerRows : 1, widths: ["100%"], body: [ [""], [""] ] }, layout : "separator" },
    { text: "Fecha de creación", alignment: "center", color: "#b2b2b2", margin: 5 },
    { text: moment(creationDate).format("DD/MM/YYYY hh:mm a"), alignment: "center" },
    { text: `Total (${code}):`, alignment: "center", color: "#b2b2b2", margin: 5 },
    { text: currencyFormatter.format(+price, { code }), alignment: "center" },
    { table : { headerRows : 1, widths: ["100%"], body: [ [""], [""] ] }, layout : "separator" },
    { text: "Facturación", alignment: "center", color: "#b2b2b2", margin: 5 },
    { text: [ 
        { text: "Comprador: ", fontSize: 8, color: "#b2b2b2" },
        { text: `${_.capitalize(billing.lastName)} ${_.capitalize(billing.firstName)}` }
      ], alignment: "center"
    },
  );

  billing.company && content.push({ text: [ 
    { text: "Companía: ", fontSize: 8, color: "#b2b2b2" },
    { text: `${_.capitalize(billing.company)}` }
  ], alignment: "center" });
  (billing.country || billing.city) && content.push({ text: [ 
    { text: "País / Ciudad: ", fontSize: 8, color: "#b2b2b2" },
    { text: `${_.capitalize(billing.country)} - ${_.capitalize(billing.city)} ${_.capitalize(billing.state)}` }
  ], alignment: "center" });
  (billing.address1 || billing.address2) && content.push({ text: [ 
    { text: "Dirección: ", fontSize: 8, color: "#b2b2b2" },
    { text: `${_.capitalize(billing.address1 || billing.address2)}` }
  ], alignment: "center" });
  (billing.email && content.push({ text: [ 
    { text: "Email: ", fontSize: 8, color: "#b2b2b2" },
    { text: `${_.capitalize(billing.email)}` }
  ], alignment: "center"}));
  (billing.phone && content.push({ text: [ 
    { text: "Teléfono: ", fontSize: 8, color: "#b2b2b2" },
    { text: `${_.capitalize(billing.phone)}` }
  ], alignment: "center"}));

  content.push(
    { table : { headerRows : 1, widths: ["100%"], body: [ [""], [""] ] }, layout : "separator" },
    { text: "Envío", alignment: "center", color: "#b2b2b2", margin: 5 },
    { text: [ 
        { text: "Total: ", fontSize: 8, color: "#b2b2b2" },
        { text: currencyFormatter.format(+shipping.total, { code }) }
      ], alignment: "center"
    },
    { text: [ 
        { text: "Comprador: ", fontSize: 8, color: "#b2b2b2" },
        { text: `${_.capitalize(shipping.lastName)} ${_.capitalize(shipping.firstName)}` }
      ], alignment: "center"
    }
  );

  shipping.company && content.push({ text: [ 
    { text: "Companía: ", fontSize: 8, color: "#b2b2b2" },
    { text: `${_.capitalize(shipping.company)}` }
  ], alignment: "center" });
  (shipping.country || shipping.state) && content.push({ text: [ 
    { text: "País / Ciudad: ", fontSize: 8, color: "#b2b2b2" },
    { text: `${_.capitalize(shipping.country)} - ${_.capitalize(shipping.state)}` }
  ], alignment: "center" });
  (shipping.address1 || shipping.address2) && content.push({ text: [ 
    { text: "Dirección: ", fontSize: 8, color: "#b2b2b2" },
    { text: `${_.capitalize(shipping.address1 || shipping.address2)}` }
  ], alignment: "center" });

  if (paymentMethod.length > 0) {
    content.push(
      { table : { headerRows : 1, widths: ["100%"], body: [ [""], [""] ] }, layout : "separator" },
      { text: "Método de Pago", alignment: "center", color: "#b2b2b2", margin: 5 }
    );

    paymentMethod.forEach((method) => {
      const amount = method.amount ? currencyFormatter.format(
        method.amount,
        { code }
      ) : ""

      content.push(
        { text: `${_.capitalize(method.title)} ${amount}`, alignment: "center" }
      );
    })
  
    paidDate && moment(paidDate).isValid() && content.push(
      { text: "Fecha de Pago: ", color: "#b2b2b2", alignment: "center", margin: 5 },
      { text: `${moment(paidDate).format("DD/MM/YYYY - hh:mm")}`, alignment: "center" }
    );
  }

  content.push(
    { table : { headerRows : 1, widths: ["100%"], body: [ [""], [""] ] }, layout : "separator" },
    { text: "Artículos", alignment: "center", color: "#b2b2b2", margin: 5 },
    { 
      columns: [
        { width: "*", text: "" },
        {
          layout: "custom",
          width: "auto",
          table: {
            body: articles,
          },
        },
        { width: "*", text: "" },
      ]
    }
  );

  return { 
    info: {
      title: 'Orden de venta',
      author: 'Wembii',
    },
    content
  };
};

export const downloadOrderPDF = (
  order: Order, icon: string, statusName: string, statusColor: string, avatar?: string
) => {
  let pdfMk = pdfMake
  pdfMk.vfs = pdfFonts.pdfMake.vfs;
  pdfMk.tableLayouts = {
    custom: { hLineColor: "#c4c4c4", vLineColor: "#c4c4c4" },
    separator: { hLineColor: "#c4c4c4", vLineWidth: (_i, _n) => 0, hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 0 : 1 },
  };

  const content = generateMarketPdfContent(order, icon, statusName, statusColor, avatar);

  pdfMk.createPdf(content as any).download(`Venta_${order.number}.pdf`);
}

export const printOrderPDF = (
  order: Order, icon: string, statusName: string, statusColor: string, avatar?: string
) => {
  let pdfMk = pdfMake
  pdfMk.vfs = pdfFonts.pdfMake.vfs;
  pdfMk.tableLayouts = {
    custom: { hLineColor: "#c4c4c4", vLineColor: "#c4c4c4" },
    separator: { hLineColor: "#c4c4c4", vLineWidth: (_i, _n) => 0, hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 0 : 1 },
  };

  const content = generateMarketPdfContent(order, icon, statusName, statusColor, avatar);

  pdfMk.createPdf(content as any).print();
}
