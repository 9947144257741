import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const mailchimpRoot = (state: State) => state.mailchimp;

export const settingSelector = createSelector(
  mailchimpRoot,
  (mailchimp) => mailchimp.settings
);

export const audiencesSelector = createSelector(mailchimpRoot,
  (mailchimp) => mailchimp.audiences
);

export const errorCodeSelector = createSelector(mailchimpRoot,
  (mailchimp) => mailchimp.errorCode
);

export const errorMessageSelector = createSelector(mailchimpRoot,
  (mailchimp) => mailchimp.errorMessage
);
