import { GetOpenAiBindResponse } from "../interface/api/get-openai-bind";
import { GetSocialTemplatesResponse, SocialTemplate } from "../interface/api/get-social-templates";
import { OpenAiSetting, PostOpenAiBindResponse } from "../interface/api/post-openai-bind";

export const openAiSettingMapper = {
  mapResponseToOpenAiSetting(
    response: PostOpenAiBindResponse | GetOpenAiBindResponse
  ): OpenAiSetting {
    const { openaiSetting } = response?.payload;
    return openaiSetting;
  },
  mapResponseToSocialTemplates(
    response: GetSocialTemplatesResponse
  ): SocialTemplate[] {
    const { templates } = response?.payload;
    return templates;
  },
};
