import React from "react";
import { IconProps } from "../Facebook/FacebookIcon";
import { sizeReference } from "../WooCommerce/WooCommerceIcon";

export const GmailIcon = (props: IconProps) => {
  const { active, className, size = "1x", color } = props;
  return (
    <svg
      width={sizeReference[size]}
      height={sizeReference[size]}
      className={className}
      viewBox="0 0 102 102"
      fill="none"
    >
      <g transform="translate(-45,-23)">
        <path d="M58 108h14V74L52 59v43c0 3.315 2.685 6 6 6z" fill={color || active ? "#4285F4" : "gray"} />
        <path d="M120 108h14c3.315 0 6-2.685 6-6V59l-20 15v34z" fill={color || active ? "#34A853" : "gray"} />
        <path d="M120 48v26l20-15v-8c0-7.415-8.465-11.65-14.4-7.2L120 48z" fill={color || active ? "#FBBC04" : "gray"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M72 74V48l24 18 24-18v26L96 92 72 74z" fill={color || active ? "#EA4335" : "gray"} />
        <path d="M52 51v8l20 15V48l-5.6-4.2C60.465 39.35 52 43.585 52 51z" fill={color || active ? "#C5221F" : "gray"} />
      </g>
    </svg>
  );
};
