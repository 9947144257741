import Papa from "papaparse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, Col, Container, Label, Row } from "reactstrap"
import { useContactCore } from "../../../../cores/contacts"
import { useMeCore } from "../../../../cores/me"
import { useGlobalCore } from "../../../../cores/globals"
import { CONTACT_IMPORT_EXPORT, CONTACT_TAGS_FORM } from "../../../../constants/modal.constant"
import { useIntl } from "react-intl";
import { isEmail, isPhone, matchWithWords } from "../../../utils/stringUtils";
import { ContactTable } from "../ContactTable";
import { RadiobuttonWembii } from "../../RadiobuttonWembii";
import { InputWembii } from "../../InputWembii";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";
import { CONTACTS_CSV_LOADING } from "../../../../constants/loading.constant";
import { ContactSourceOptions } from "../../../../cores/contacts/config";
import { ContactSourceBadge } from "../ContactSource";
import { ContactSource } from "../../../../cores/contacts/enum/ContactSource.enum";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import InternalModal from "../../../layout/InternalModal";
import { CUSTOM_FIELD } from "../../../../constants/internalModal.constant";
import { ContactOwner } from "../../../../cores/contacts/interfaces/api/get-contact";
import { LabelWembii } from "../../LabelWembii";
import OwnerContainer from "../OwnerContainer";

export default function ImportContacts() {
  const intl = useIntl();
  const { company } = useMeCore();
  const {
    turnOffModal,
    turnOnModal,
    turnOnLoading,
    turnOffLoading,
    getInternalModalContent,
    turnOffInternalModal,
    isInternalModal,
    isLoading
  } = useGlobalCore();
  const {
    headers,
    tags,
    customFields,
    contactFilters,
    setHeaders,
    resetHeaders,
    getContacts,
    postContacts,
    getContactTags,
  } = useContactCore();
  const [owners, setOwners] = useState<ContactOwner[]>([]);
  const [data, setData] = useState<string[][]>([]);
  const [dataError, setDataError] = useState(false);
  const [exceedDataError, setExceedDataError] = useState(false);
  const [uploadCsv, setUploadCsv] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [tag, setTag] = useState(ContactSource.MANUAL);

  const ref = useRef<any>(null);

  useEffect(() => {
    setUploadCsv(true);
    setExceedDataError(false);
    setDataError(false);
  }, []);

  const toggleSelect = useCallback(() => {
    setIsActive(!isActive)
  }, [isActive]);

  const tagsValues = useMemo(() =>
    [
      ...ContactSourceOptions.map((source) =>({value: source.value, label: intl.formatMessage({id: source.label}) }) )  ,
      ...tags.map((tag) => ({ value: tag.nameId, label: tag.name }))
    ]
  , [tags, intl]);

  const onSelectRole = useCallback(
    (tag) => {
      setTag(tag);
      toggleSelect();
    }, [toggleSelect]
  );

  const reconizeHeader = useCallback((values: string[][]) => {
    const headerPattern = {
      name: ["name", "nombre", "nome", "first name"],
      surname: ["surname", "apellido", "sobrenome", "last name"],
      phone: ["phone", "teléfono", "telefono"],
      email: ["email", "correo", "mail"],
      enterprise: ["enterprise", "empresa"],
    }
    let isHeaders = true;
    const hds = { ...headers };
    customFields.forEach((field) => {
      hds[`${field.id}`] = -1;
    });

    values[0].forEach((header, index) => {
      if (matchWithWords(header, headerPattern.surname))
        hds.surname = index;
      else if (matchWithWords(header, headerPattern.name))
        hds.name = index;
      else if (matchWithWords(header, headerPattern.phone) || isPhone(header))
        hds.phone = index;
      else if (matchWithWords(header, headerPattern.email) || isEmail(header))
        hds.email = index;
      else if (matchWithWords(header, headerPattern.enterprise))
        hds.enterprise = index;

      if (isPhone(header) || isEmail(header))
        isHeaders = false;
    });

    const rows = (isHeaders) ? values.slice(1) : values;

    rows.forEach((row) => {
      row.forEach((value, index) => {
        if (isPhone(value))
          hds.phone = index;
        else if (isEmail(value))
          hds.email = index;
      });
    });

    setHeaders(hds);
    return isHeaders
  }, [headers, customFields, setHeaders]);

  const loadData = (source: File | string) => {
    turnOnLoading(CONTACTS_CSV_LOADING);
    setDataError(false);
    setExceedDataError(false);
    resetHeaders();

    Papa.parse(source, {
      skipEmptyLines: true,
      complete: (results: { data: string | any[]; }) => {
        if (results.data.length > 0) {
          if (results.data.length > 10001) {
            setExceedDataError(true);
          } else {
            const isHeaders = reconizeHeader(results.data as string[][]);
            const rows = (isHeaders) ? results.data.slice(1) : results.data;
  
            setData(rows as string[][]);
          }
          turnOffLoading(CONTACTS_CSV_LOADING);
        }
      },
      error: () => {
        setDataError(true);
        turnOffLoading(CONTACTS_CSV_LOADING);
      }
    });
  }

  const handleSelectFile = (files: FileList | null) => {
    if (files?.length) {
      loadData(files[0]);
      ref.current.files = null;
    }
  }

  const handleTxtChange = (text: string) => {
    if (text) {
      loadData(text);
    }
  }

  const onClickImportContacts = useCallback(() => {
    const formatedData = data.map((data) => {
      let name = headers.name > -1 ? data[headers.name] : null;
      if (headers.surname > -1)
        name = `${name} ${data[headers.surname]}`

      const email = headers.email > -1 ? data[headers.email] : null;
      const phoneNumber = headers.phone > -1 ? data[headers.phone] : null;
      const enterprise = headers.enterprise > -1 ? data[headers.enterprise] : null;
      const fields = customFields.map((field) => ({
        customFieldId: field.id,
        value: data[headers[`${field.id}`]] || null
      }));
      const ownerIds = owners.map((owner) => owner.id);

      return {
        name,
        email,
        phoneNumber,
        fields,
        enterprise,
        owners: ownerIds
      };
    });

    postContacts(
      company?.id!,
      formatedData,
      tag,
      () => {
        setData([]);
        setOwners([]);
        getContacts(company?.id!, contactFilters, true, () => {
          turnOffModal(CONTACT_IMPORT_EXPORT);
        });
      },
    )
  }, [
    postContacts,
    turnOffModal,
    getContacts,
    company,
    data,
    tag,
    headers,
    contactFilters,
    customFields,
    owners
  ]);

  const missingRequiredField = useMemo(() =>
    (headers.name === -1 && headers.surname === -1) ||
    (headers.email === -1 && headers.phone === -1)
  , [headers]);

  const disabled = useCallback(
    () => data.length === 0 || missingRequiredField || exceedDataError,
    [data.length, missingRequiredField, exceedDataError],
  );

  const onOpenContactTags = useCallback(() => {
    getContactTags(company?.id!);
    turnOnModal(CONTACT_TAGS_FORM, true);
  }, [turnOnModal, getContactTags, company]);

  const onAddingOwner = useCallback((user) => {
    setOwners([
      ...owners,
      user
    ]);
  }, [owners]);

  const onRemovingOwner = useCallback((id) => {
    const newOwners = owners.filter((owner) => owner.id !== id);
    setOwners(newOwners);
  }, [owners]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(CUSTOM_FIELD)}
        onClose={() => turnOffInternalModal(CUSTOM_FIELD)}
      >
        {getInternalModalContent(CUSTOM_FIELD)}
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 text-center mb-4">
                {intl.formatMessage({ id: "app.contacts.importContact" })}
              </h2>
              <div className="d-flex flex-column align-items-center">
                <Label>
                  {intl.formatMessage({ id: "app.contacts.downloadExample" })}
                </Label>
                <Button className="excelButton m-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    href="https://asset.wembii.ar/template/contacts-template.csv"
                  >
                    {intl.formatMessage({ id: "app.contacts.download" })}
                  </a>
                </Button>
              </div>
              <hr className="horizontal-separator mt-2 mb-4"/>
              <div className="d-flex flex-column align-items-center">
                <Label>
                  {intl.formatMessage({ id: "app.contacts.load" })}
                </Label>
                <div className="d-flex">
                  <RadiobuttonWembii
                    className="mx-2"
                    label={intl.formatMessage({ id: "app.contacts.loadCsv" })}
                    checked={uploadCsv}
                    onChange={() => setUploadCsv(true)}
                  />
                  <RadiobuttonWembii
                    className="mx-2"
                    label={intl.formatMessage({ id: "app.contacts.loadCopyPast" })}
                    checked={!uploadCsv}
                    onChange={() => setUploadCsv(false)}
                  />
                </div>

                {uploadCsv ? (
                  <Label
                    for="imageUploader"
                    style={{ height: 200 }}
                    className="pointer border rounded w-100 d-flex flex-column justify-content-center align-items-center m-2"
                  >
                    <FontAwesomeIcon
                      icon={["fas", "file-csv"]}
                      size="2x"
                      color="green"
                      className="mb-1"
                    />
                    <span className="text-muted" style={{ fontSize: 12 }}>
                      {intl.formatMessage({ id: "app.contacts.selectFile" })}
                    </span>
                  </Label>
                ) : (
                  <InputWembii
                    type="textarea"
                    className="w-100"
                    style={{ height: 200 }}
                    placeholder="Email			        		Name		Surname
                    amelia@wembii.com			Amelia		Breiner
                    lilah.morrison@wembii.edu		Lilah		Morrison
                    freddie@wembii.com			Freddie		Jones"
                    onChange={(v) => handleTxtChange(v)}
                  />
                )}

                <Row className="w-100 mb-2">
                  <Col>
                    <LabelWembii>
                      {intl.formatMessage({ id: "app.contacts.origin" })}
                    </LabelWembii>
                    <div className="d-flex align-items-center">
                      {isActive ?
                        <InputWembii
                          type="select"
                          name="roleValue"
                          value={tag}
                          className="w-50"
                          mb={false}
                          onChange={onSelectRole}
                        >
                          {tagsValues.map(tag =>
                            <option key={tag.value} value={tag.value}>
                              {tag.label}
                            </option>)
                          }
                        </InputWembii>
                      :
                        <ContactSourceBadge
                          source={tag as ContactSource}
                          onClick={toggleSelect}
                        />
                      }
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        size="2x"
                        className="m-2 pointer"
                        color={COLOR_PRIMARY}
                        onClick={onOpenContactTags}
                      />
                    </div>
                  </Col>
                  <Col>
                    <LabelWembii>
                      {intl.formatMessage({ id: "app.contacts.owners" })}
                    </LabelWembii>
                    <OwnerContainer contacts={owners} onAdding={onAddingOwner} onRemoving={onRemovingOwner} />
                  </Col>
                </Row>

                <input
                  type="file"
                  id="imageUploader"
                  className="d-none"
                  disabled={isLoading(CONTACTS_CSV_LOADING)}
                  ref={ref}
                  accept=".csv"
                  onChange={(e) => handleSelectFile(e.target.files)}
                />
                {dataError && (
                  <Label className="w-100 text-center text-danger">
                    {intl.formatMessage({ id: "app.contacts.incorrectFormat" })}
                  </Label>
                )}

                {isLoading(CONTACTS_CSV_LOADING) ? (
                  <UpdateLoadingCard loadingId={CONTACTS_CSV_LOADING} />
                ) : (
                  <ContactTable data={data} />
                )}

                {data.length > 0 && missingRequiredField && (
                  <Label className="text-danger text-center w-100">
                    {intl.formatMessage({ id: "app.contacts.danger" })}
                  </Label>
                )}
                {exceedDataError && (
                  <Label className="text-danger text-center w-100">
                    {intl.formatMessage({ id: "app.contacts.exceedImport" })}
                  </Label>
                )}
                <Button
                  className="excelButton m-2"
                  onClick={onClickImportContacts}
                  disabled={disabled()}
                >
                  {intl.formatMessage({ id: "app.contacts.import" })}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
