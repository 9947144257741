import formApiClient from "../../helpers/services/form-api-client";
import { FormInput, GetWebFormResponse } from "../interfaces/api/get-web-form";
import { GetWebFormsResponse } from "../interfaces/api/get-web-forms";
import { GetWebFormResponseResponse } from "../interfaces/api/get-webform-response.response";
import {
  PostFormNotificationRequest,
  PostFormNotificationResponse,
} from "../interfaces/api/post-form-notification";
import {
  PostFormUserRequest,
  PostFormUserResponse,
} from "../interfaces/api/post-form-user";
import {
  PostPublicFormRequest,
  PostPublicFormResponse,
} from "../interfaces/api/post-public-form";
import {
  PostWebFormRequest,
  PostWebFormResponse,
} from "../interfaces/api/post-web-form";
import {
  PutFormInputsRequest,
  PutFormInputsResponse,
} from "../interfaces/api/put-form-inputs";
import {
  PutWebFormRequest,
  PutWebFormResponse,
} from "../interfaces/api/put-web-form";
import { webFormMapper } from "./mapper";

export const webFormApiService = {
  getWebForms(companyId: string) {
    return formApiClient()
      .get<GetWebFormsResponse>(`form/web`, { params: { companyId } })
      .then(({ data }) => webFormMapper.mapResponseToWebForms(data));
  },
  getWebForm(companyId: string, formId: string) {
    return formApiClient()
      .get<GetWebFormResponse>(`form/web/${formId}`, { params: { companyId } })
      .then(({ data }) => webFormMapper.mapResponseToWebForm(data));
  },
  getWebFormResponse(companyId: string, contactId: string) {
    return formApiClient()
      .get<GetWebFormResponseResponse>(`form/web/responses/contact`, {
        params: { companyId, contactId },
      })
      .then(({ data }) => webFormMapper.mapResponseToWebFormResponse(data));
  },
  getPublicWebForm(companyId: string, formId: string) {
    return formApiClient()
      .get<GetWebFormResponse>(`public/form/web/config`, {
        params: { companyId, formId },
      })
      .then(({ data }) => webFormMapper.mapResponseToWebForm(data));
  },
  postPublicWebForm(companyId: string, body: PostPublicFormRequest) {
    return formApiClient()
      .post<PostPublicFormResponse>(`public/form/web/send`, body, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  postWebForm(
    name: string,
    title: string,
    titleSize: number,
    titleColor: string,
    buttonColor: string,
    buttonSize: number,
    buttonPosition: string,
    buttonText: string,
    buttonTextColor: string,
    buttonTextSize: number,
    redirectUrl: string,
    successfulMessage: string,
    inputs: FormInput[],
    action: string,
    companyId: string
  ) {
    const body: PostWebFormRequest = {
      name,
      title,
      titleSize,
      titleColor,
      buttonColor,
      buttonSize,
      buttonPosition,
      buttonText,
      buttonTextColor,
      buttonTextSize,
      redirectUrl,
      successfulMessage,
      inputs,
      action,
      companyId,
    };
    return formApiClient()
      .post<PostWebFormResponse>(`form/web`, body, { params: { companyId } })
      .then(({ data }) => webFormMapper.mapResponseToWebForm(data));
  },
  putWebForm(companyId: string, formId: string, body: PutWebFormRequest) {
    return formApiClient()
      .put<PutWebFormResponse>(`form/web/${formId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => webFormMapper.mapResponseToWebForm(data));
  },
  copyWebForm(companyId: string, formId: string) {
    return formApiClient()
      .post<PostWebFormResponse>(
        `form/web/${formId}/copy`,
        {},
        { params: { companyId } }
      )
      .then(({ data }) => webFormMapper.mapResponseToWebForm(data));
  },
  deleteWebForm(companyId: string, formId: string) {
    return formApiClient()
      .delete(`form/web/${formId}`, { params: { companyId } })
      .then(({ data }) => data);
  },
  putFormInputs(companyId: string, formId: string, inputs: FormInput[]) {
    const body: PutFormInputsRequest = {
      inputs,
    };
    return formApiClient()
      .put<PutFormInputsResponse>(`form/web/${formId}/input`, body, {
        params: { companyId },
      })
      .then(({ data }) => webFormMapper.mapResponseToWebForm(data));
  },
  postFormNotification(companyId: string, formId: string, userId: number) {
    const body: PostFormNotificationRequest = {
      formId,
      userId,
    };
    return formApiClient()
      .post<PostFormNotificationResponse>(
        `form/web/${formId}/notification`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => webFormMapper.mapResponseToFormNotification(data));
  },
  deleteFormNotification(
    companyId: string,
    formId: string,
    notificationId: string
  ) {
    return formApiClient()
      .delete(`form/web/${formId}/notification/${notificationId}`, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  postFormUser(companyId: string, formId: string, userId: number) {
    const body: PostFormUserRequest = {
      formId,
      userId,
    };
    return formApiClient()
      .post<PostFormUserResponse>(`form/web/${formId}/user`, body, {
        params: { companyId },
      })
      .then(({ data }) => webFormMapper.mapResponseToFormUser(data));
  },
  deleteFormUser(companyId: string, formId: string, userId: string) {
    return formApiClient()
      .delete(`form/web/${formId}/user/${userId}`, { params: { companyId } })
      .then(({ data }) => data.payload);
  },
};
