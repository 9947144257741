export const ActivityType = {
  CREATE_CARD: "CREATE_CARD",
  ADD_TAG: "ADD_TAG",
  DELETE_TAG: "DELETE_TAG",
  CHANGE_INTERNAL_ID: "CHANGE_INTERNAL_ID",
  DELETE_INTERNAL_ID: "DELETE_INTERNAL_ID",
  CHANGE_DESCRIPTION: "CHANGE_DESCRIPTION",
  DELETE_DESCRIPTION: "DELETE_DESCRIPTION",
  CHANGE_BUDGET: "CHANGE_BUDGET",
  CHANGE_END_DATE: "CHANGE_END_DATE",
  DELETE_END_DATE: "DELETE_END_DATE",
  CHANGE_TITLE: "CHANGE_TITLE",
  CHANGE_STATUS: "CHANGE_STATUS",
  ADD_ASSIGNMENT: "ADD_ASSIGNMENT",
  DELETE_ASSIGNMENT: "DELETE_ASSIGNMENT",
  ADD_CONTACT: "ADD_CONTACT",
  DELETE_CONTACT: "DELETE_CONTACT",
  ADD_COMPANY: "ADD_COMPANY",
  DELETE_COMPANY: "DELETE_COMPANY",
  MOVE_CARD: "MOVE_CARD",
  MASS_MOVE_CARD: "MASS_MOVE_CARD",
  ORDER_CARD: "ORDER_CARD",
  ADD_TASK: "ADD_TASK",
  TASK_STATUS: "TASK_STATUS",
  DELETE_TASK: "DELETE_TASK",
  ADD_FILE: "ADD_FILE",
  DELETE_FILE: "DELETE_FILE",
  ADD_PRODUCT: "ADD_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  EDIT_PRODUCT: "EDIT_PRODUCT",
  CHANGE_CUSTOM_FIELD: "CHANGE_CUSTOM_FIELD",
  DELETE_CUSTOM_FIELD: "DELETE_CUSTOM_FIELD",
  DELETE_CARD: "DELETE_CARD",
}
