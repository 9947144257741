import { GetJumpsellerLoginResponse } from "../interface/api/get-jumpseller-login";
import { JumpsellerSetting, PostJumpsellerBindResponse } from "../interface/api/post-jumpseller-bind";

export const jumpsellerSettingMapper = {
  mapResponseToJumpsellerSetting(response: PostJumpsellerBindResponse): JumpsellerSetting {
    const { jumpsellerSetting } = response?.payload;
    return jumpsellerSetting;
  },
  mapResponseToJumpsellerLoginUrl(
    response: GetJumpsellerLoginResponse
  ): { url: string } {
    const { url } = response?.payload;
    return { url };
  },
};
