import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { ContactOwner } from "../../tasks/interfaces/api/get-task";

import {
  Conversation,
  ConversationContact,
  Message,
} from "../interfaces/conversations.interface";
import { ConversationStatus } from "../types/conversation-status.types";
import { SocialType } from "../types/social.types";

export const setSelectedInstagramConversationAction = createAction(
  "[SocialChat] Select Instagram Conversation",
  props<{
    id: number | string | null;
  }>()
);
export const setDateFilters = createAction(
  "[SocialChat] Set Date Filters",
  props<{
    date: string;
    dateToUpdate: "from" | "to";
  }>()
);

export const addInstagramMessageAction = createAction(
  "[SocialChat] Set new Instagram Message",
  props<{
    message: Message;
    id: string;
  }>()
);

export const setSelectedFacebookConversationAction = createAction(
  "[SocialChat] Select Facebook Conversation",
  props<{
    id: number | string | null;
  }>()
);

export const addFacebookMessageAction = createAction(
  "[SocialChat] Set new Facebook Message",
  props<{
    message: Message;
    id: string;
  }>()
);
export const syncFacebookConversationWithContactAction = createAction(
  "[SocialChat] Sync Facebook Conversation With Contact Action",
  props<{ contact: ConversationContact; social: "facebook" | "instagram" }>()
);
export const removeConversationFromStackByConversationId = createAction(
  "[SocialChat] Remove Conversation From Stack By Social Name",
  props<{
    conversationId: string;
    statusToUpdate: ConversationStatus;
    socialType: SocialType;
    newStatus: ConversationStatus;
  }>()
);
export const updateConversationOwners = createAction(
  "[SocialChat] update conversation owners",
  props<{
    conversationId: string;
    statusToUpdate: ConversationStatus;
    socialType: SocialType;
    newOwners: ContactOwner[];
    participantId: string;
  }>()
);

export const setSelectedWhatsappConversationAction = createAction(
  "[SocialChat] Select Whatsapp Conversation",
  props<{
    id: number | string | null;
  }>()
);

export const addWhatsappMessageAction = createAction(
  "[SocialChat] Set new Whatsapp Message",
  props<{
    message: Message;
    id: string;
  }>()
);

export const addWhatsappConversationAction = createAction(
  "[SocialChat] Set new Whatsapp Conversation",
  props<{
    conversation: Conversation;
  }>()
);
export const changeSocialConversationStatusAction = createAction(
  "[SocialChat] Change conversation status",
  props<{ conversation: Conversation }>()
);
export const setConversationSocialAction = createAction(
  "[SocialChat] Set Conversation Social Action",
  props<{ social: SocialType }>()
);
const actions = joinActions({
  setSelectedInstagramConversationAction,
  addInstagramMessageAction,
  setSelectedFacebookConversationAction,
  addFacebookMessageAction,
  setSelectedWhatsappConversationAction,
  addWhatsappMessageAction,
  addWhatsappConversationAction,
  changeConversationStatusAction: changeSocialConversationStatusAction,
  removeConversationFromStackByConversationId,
  syncFacebookConversationWithContactAction,
  setConversationSocialAction,
  setDateFilters,
  updateConversationOwners,
});

export type SocialChatAction = typeof actions;
