import moment from "moment";
import { CHAT_THEME } from "../../constants/theme";

export const getFirstLetters = (fullname: string) => {
  const names = (fullname ?? "").trim().split(" ");
  if (names.length === 0) return "";

  const firstLetter = names[0].charAt(0);
  let lastLetter = "";
  if (names.length > 1) {
    lastLetter = names[names.length - 1].charAt(0);
  }
  return `${firstLetter}${lastLetter}`;
};

export const truncateText = (text: string, limit = 20) => {
  if (text.length <= limit) {
    return text;
  }
  return text.slice(0, limit) + "...";
};

export const jsonString = (error: string) => {
  try {
    return JSON.parse(error);
  } catch (e) {
    return { error };
  }
};

export const getColor = (id: number) => CHAT_THEME[id % CHAT_THEME.length];

export const matchWithWords = (string: string, words: string[]) =>
  words.find((word) => string.toLowerCase().indexOf(word.toLowerCase()) >= 0) !=
  null;

export const isEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const isAlphanumeric = (value: string) => {
  const alphanumericRegex = /^[a-zA-Z0-9_.-]*$/;
  return alphanumericRegex.test(value);
};

export const isNumeric = (value: string) => {
  const alphanumericRegex = /^[0-9_-]*$/;
  return alphanumericRegex.test(value);
};

export const isPhone = (phone: string) => {
  const phoneRegex = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im;
  return phoneRegex.test(phone);
};

export const isUrl = (url: string) => {
  //eslint-disable-next-line
  const urlRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  return urlRegex.test(url);
};

export const isDate = (date: string) => {
  return moment(date).isValid();
};

export const isStrNotNull = (strOrNullString?: string) => {
  return !!strOrNullString && strOrNullString.trim() !== "";
};
