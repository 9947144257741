import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, ReactNode } from "react";
import { Media } from "reactstrap";
import { COLOR_DANGER } from "../../constants/theme";

interface Props {
  id?: string;
  label?: string;
  className?: string;
  image?: string;
  active?: boolean;
  icon?: ReactNode;
  style?: CSSProperties;
  height?: string;
  width?: string;
  backgroundColor?: string;
  color?: string;
  labelFontSize?: number;
  onClick?: () => void;
}

export default function Avatar(props: Props) {
  const {
    id,
    label,
    className,
    image,
    active = true,
    icon,
    backgroundColor = "#f8f9fa",
    color = "#000",
    height = "40px",
    width = "40px",
    style,
    labelFontSize,
    onClick,
  } = props;
  return (
    <div
      id={id}
      style={{
        width,
        height,
        minHeight: height,
        minWidth: width,
        backgroundColor,
        ...style,
      }}
      className={`${className} d-flex rounded-circle position-relative`}
      onClick={onClick}
    >
      {!active && (
        <FontAwesomeIcon
          icon={["fas", "ban"]}
          size="1x"
          className="position-absolute"
          color={COLOR_DANGER}
        />
      )}
      {image ? (
        <Media
          style={{ width, height, objectFit: "cover", borderRadius: "50%" }}
          object
          src={image}
          alt="Generic placeholder image"
        />
      ) : label ? (
        <span className="m-auto text-primary " style={{ color, fontSize: labelFontSize }}>
          {label?.toUpperCase()}
        </span>
      ) : (
        <span className="m-auto" style={{ color }}>
          {icon}
        </span>
      )}
    </div>
  );
}
