import moment from "moment";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { Quote } from "../../cores/boards/interfaces/Quote";
import { COLOR_PRIMARY } from "../../constants/theme";
import { getCurrencySymbol } from "../../cores/boards/constants/currencies.constant";
import { CL$Format } from "../../cores/utils";

const generateQuotePdfContent = (quote: Quote) => {
  const {
    id,
    name,
    creationDate,
    expirationDate,
    owner,
    ownerCompany,
    comments,
    terms,
    contacts,
    company,
    signature,
    currency,
    products,
    discounts,
    fees,
    taxes,
  } = quote;

  const content: any[] = [];

  if (ownerCompany?.avatar) {
    content.push({
      image: `data:image/jpeg;base64,${ownerCompany?.avatar}`,
      alignment: "center",
      fit: [100, 100],
      margin: [0, 5]
    });
  }

  const contactLines = contacts.map((contact) => [
    { text: contact.name, bold: true },
    { text: contact.email, color: "#5D5D5D" },
    { text: contact.phoneNumber, color: "#5D5D5D", margin: [0, 0, 0, 5] }
  ]);

  const companyLine = company
    ? [
      { text: company.name, bold: true },
      { text: company.country, color: "#5D5D5D" },
      { text: company.city, color: "#5D5D5D" },
      { text: company.phone, color: "#5D5D5D" }
    ]
    : [];

  const clientLines = [
    ...companyLine,
    ...contactLines
  ];

  content.push(
    { text: name, style: "header" },
    {
      columns: [
        { stack: clientLines },
        {
          stack: [
            { text: `Referencia: ${id}`, bold: true, alignment: "right", style: "line" },
            {
              text: `Fecha de creación del presupuesto: ${moment(creationDate).format("DD/MM/YYYY")}`,
              alignment: "right",
              style: "line",
              color: "#5D5D5D"
            },
            {
              text: `Fecha de vencimiento del presupuesto: ${moment(expirationDate).format("DD/MM/YYYY")}`,
              alignment: "right",
              style: "line",
              color: "#5D5D5D"
            },
            {
              text: `Presupuesto creado por: ${owner?.firstName} ${owner?.lastName}`,
              alignment: "right",
              style: "line",
              color: "#5D5D5D"
            },
          ]
        }
      ],
      columnGap: 10
    },
  );

  if (comments) {
    content.push({
      layout: "cell",
      margin: [ 0, 15, 0, 15 ],
      table: {
        widths: ["*"],
        body: [
          [{
            stack: [
              { text: "Comentarios", style: "subheader" },
              { text: comments, color: "#5D5D5D" }
            ],
            margin: 10
          }]
        ]
      }
    });
  }

  const productLines = products.map((product) => {
    const discount = product.discountType === 0
      ? product.price * product.quantity * product.discount / 100
      : product.discount;

    const discountComment = discount > 0
      ? {
          text: `tras ${getCurrencySymbol(currency)} ${CL$Format(discount.toFixed(2))} de descuento`,
          alignment: "right",
          color: "#5D5D5D"
        }
      : {}

    return [
      {
        stack: [
          { text: product.name },
          { text: product.code, color: "#5D5D5D" },
          { text: product.description, color: "#5D5D5D" }
        ]
      },
      { text: product.quantity, alignment: "right" },
      { text: `${getCurrencySymbol(currency)} ${CL$Format(product.price)}`, alignment: "right" },
      {
        stack: [
          {
            text: `${getCurrencySymbol(currency)} ${CL$Format((product.price * product.quantity - discount).toFixed(2))}`,
            alignment: "right",
          },
          discountComment
        ]
      },
    ];
  });

  content.push(
    { text: "Productos", style: "subheader" },
    {
      style: "table",
      table: {
        headerRows: 1,
        widths: [ "*", "*", "*", "*" ],
        body: [
          [
            { text: "Artículo y descripción", style: "tableHeader" },
            { text: "Cantidad", style: "tableHeader", alignment: "right" },
            { text: "Precio Unitario", style: "tableHeader", alignment: "right" },
            { text: "Total", style: "tableHeader", alignment: "right" }
          ],
          ...productLines
        ]
      },
      layout: "lightHorizontalLines"
    }
  );

  const subtotal = products.reduce((prev, curr) => {
    const discount = curr.discountType === 0
      ? curr.price * curr.quantity * curr.discount / 100
      : curr.discount;
    return prev + curr.price * curr.quantity - discount;
  }, 0);

  const discountLines = discounts.map((discount) => {
    const value = discount.type === 0
      ? subtotal * discount.value / 100
      : discount.value;

    return [
      { text: discount.name || "Descuento", color: "#5D5D5D" },
      {
        text: `-${getCurrencySymbol(currency)} ${CL$Format(value)}`,
        alignment: "right",
        color: "#5D5D5D"
      },
    ]
  });

  const feeLines = fees.map((fee) => {
    const value = fee.type === 0
      ? subtotal * fee.value / 100
      : fee.value;

    return [
      { text: fee.name || "Tarifa", color: "#5D5D5D" },
      {
        text: `${getCurrencySymbol(currency)} ${CL$Format(value)}`,
        alignment: "right",
        color: "#5D5D5D"
      },
    ]
  });

  const taxLines = taxes.map((tax) => {
    const value = tax.type === 0
      ? subtotal * tax.value / 100
      : tax.value;

    return [
      { text: tax.name || "Impuesto", color: "#5D5D5D"},
      {
        text: `${getCurrencySymbol(currency)} ${CL$Format(value)}`,
        alignment: "right",
        color: "#5D5D5D"
      },
    ]
  });

  const totalDiscount = quote.discounts.reduce((prev, curr) => {
    const discount = curr.type === 0
      ? subtotal * curr.value / 100
      : curr.value;
    return prev + discount;
  }, 0);
  const totalFee = quote.fees.reduce((prev, curr) => {
    const fee = curr.type === 0
      ? subtotal * curr.value / 100
      : curr.value;
    return prev + fee;
  }, 0);
  const totalTax = quote.taxes.reduce((prev, curr) => {
    const tax = curr.type === 0
      ? subtotal * curr.value / 100
      : curr.value;
    return prev + tax;
  }, 0);
  const total = subtotal - totalDiscount + totalFee + totalTax;

  content.push(
    { text: "Subtotales", style: "subheader" },
    {
      style: "table",
      table: {
        widths: [ "*", "*" ],
        body: [
          [
            { text: "Subtotal", color: "#5D5D5D" },
            {
              text: `${getCurrencySymbol(currency)} ${CL$Format(subtotal.toFixed(2))}`,
              alignment: "right",
              color: "#5D5D5D"
            },
          ],
          ...feeLines,
          ...taxLines,
          ...discountLines,
        ]
      },
      layout: "noBorders"
    },
    {
      text: `Total: ${getCurrencySymbol(currency)} ${CL$Format(total.toFixed(2))}`,
      style: "subheader",
      alignment: "right"
    }
  );

  if (terms) {
    content.push({
      stack: [
        { text: "Condiciones de compra", style: "subheader" },
        { text: terms, color: "#5D5D5D" }
      ]
    });
  }

  if (signature) {
    content.push(
      { text: "", margin: [0, 100, 0, 0] },
      {
        columns: [
          {
            stack: [
              {
                table: {
                  headerRows: 1,
                  widths: ["100%"],
                  body: [ [""], [""] ]
                },
                layout : "separator",
              },
              { text: "Firma" }
            ]
          },
          {},
          {
            stack: [
              {
                table: {
                  headerRows: 1,
                  widths: ["100%"],
                  body: [ [""], [""] ]
                },
                layout : "separator",
              },
              { text: "Fecha" }
            ]
          }
        ]
      }
    );
  }

  content.push(
    { text: "¿Preguntas? Póngase en contacto conmigo", style: "subheader", margin: [0, 20, 0, 5] },
    { text: `${owner?.firstName} ${owner?.lastName}`, color: "#5D5D5D" },
    { text: owner?.email, color: "#5D5D5D" },
    { text: owner?.phoneNumber, color: "#5D5D5D" },
    { text: ownerCompany?.name, color: "#5D5D5D", margin: [0, 20, 0, 0] },
    { text: ownerCompany?.country, color: "#5D5D5D" },
    { text: ownerCompany?.city, color: "#5D5D5D" },
    { text: ownerCompany?.phoneNumber, color: "#5D5D5D" }
  );

  return {
    info: {
      title: "Cotización",
      author: "Wembii",
    },
    content,
    styles: {
      header: {
        fontSize: 20,
        bold: true,
        margin: [ 0, 0, 0, 10 ]
      },
      subheader: {
        fontSize: 12,
        bold: true,
        margin: [ 0, 0, 0, 5 ]
      },
      line: {
        margin: [ 0, 0, 0, 5 ]
      },
      table: {
        margin: [0, 10, 0, 25]
      },
    },
    pageMargins: [ 20, 40, 20, 40 ],
  };
};

export const createQuotePDF = (quote: Quote) => {
  let pdfMk = pdfMake
  pdfMk.vfs = pdfFonts.pdfMake.vfs;
  pdfMk.tableLayouts = {
    cell: { hLineColor: COLOR_PRIMARY, vLineColor: COLOR_PRIMARY, vLineWidth: (_i, _n) => 0.75, hLineWidth: (i, node) => 0.75 },
    separator: { vLineWidth: (_i, _n) => 0, hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 0 : 1 },
  };

  const content = generateQuotePdfContent(quote);

  return pdfMk.createPdf(content as any);
}
