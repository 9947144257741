import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Input, Label, Media, Row } from "reactstrap";
import logo from "../../assets/wembii-logo.png";
import {
  FACEBOOK_INSIGHT_LOADING,
  GENERATING_REPORT,
  GOOGLE_INSIGHT_LOADING,
  INSTAGRAM_INSIGHT_LOADING,
  MERCADOLIBRE_INSIGHT_LOADING,
  WOO_COMMERCE_INSIGHT_LOADING,
} from "../../constants/loading.constant";
import { useAnalyticsCore } from "../../cores/analytics";
import { MetricDateMode } from "../../cores/analytics/interface/Metric2";
import { useMarketCore } from "../../cores/market";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { SocialProviderId } from "../../cores/socialPost/config";
import { useWooCommerceCore } from "../../cores/wooCommerce";
import { AnalyticsDatePicker } from "../component/analytics/AnalyticsDatePicker";
import { FacebookCharts } from "../component/analytics/FacebookCharts";
import { GoogleCharts } from "../component/analytics/GoogleCharts";
import { InstagramCharts } from "../component/analytics/InstagramCharts";
import { MercadolibreCharts } from "../component/analytics/MercadolibreCharts";
import { WooCommerceCharts } from "../component/analytics/WooCommerceCharts";
import { PanelWembii } from "../component/PanelWembii";
import { ActionButtons } from "../component/reports/ActionButtons";
import { SocialReportSelector } from "../component/reports/SocialReportSelector";
import { FacebookIcon } from "../component/social/Facebook/FacebookIcon";
import { GooglenAnalyticsIcon } from "../component/social/GoogleAnalitycs/GoogleAnalitycsIcon";
import { InstagramIcon } from "../component/social/Instagram/InstagramIcon";
import { MercadolibreIcon } from "../component/social/Mercadolibre/MercadolibreIcon";
import { WooCommerceIcon } from "../component/social/WooCommerce/WooCommerceIcon";
import { TitleWembii } from "../component/TitleWembii";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import { useIntl } from "react-intl";
import { ErrorIcon } from "../component/socialPost/ErrorIcon";
import { Errors } from "../../constants/errors";

export const ReportsScreen = () => {
  const intl = useIntl();
  const {
    socialProviders,
    date,
    instagramErrorCode,
    getFacebookInsights,
    getGoogleInsights,
    getInstagramInsights,
    getMercadolibreInsights,
    getWooCommerceInsights,
    updateDate,
  } = useAnalyticsCore();
  const { getCurrency } = useWooCommerceCore();
  const { getMercadolibreOrders } = useMarketCore();
  const {
    company,
    socialSettings,
    companyPlan,
    companyRole,
    isAdmin,
    isReseller
  } = useMeCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    if (
      companyPlan?.rules.reportsAmount === 0 || (
        !companyRole?.reports &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  const [logoFile, setLogoFile] = useState<{ file: File | null; url: string }>({
    file: null,
    url: "",
  });

  const onSelectLogo = (files: FileList | null) => {
    if (files) {
      setLogoFile({
        file: files[0],
        url: URL.createObjectURL(files[0]),
      });
    }
  };

  const handleUpdateDate = useCallback((date) => {
    updateDate(date);
  }, [updateDate]);

  const isSelected = useCallback(
    (id: string): boolean =>
      Boolean(socialProviders.filter((sp) => sp.id === id).length),
    [socialProviders]
  );

  const isFutureDate = (date: string) => moment(date).isAfter(moment());
  
  const areDatesInverted = (since: string, until: string) => 
    moment(since).isAfter(moment(until));

  useEffect(() => {
    if (
      !isFutureDate(date.since) &&
      !isFutureDate(date.until) &&
      !areDatesInverted(date.since, date.until)
    ) {
      if (isSelected(SocialProviderId.Facebook)) {
        getFacebookInsights(company!.id, date.since, date.until, "Daily");
        getFacebookInsights(company!.id, date.since, date.until, "Lifetime", 10);
      }
      if (isSelected(SocialProviderId.Instagram)) {
        getInstagramInsights(company!.id, date.since, date.until, "Daily");
        getInstagramInsights(company!.id, date.since, date.until, "Lifetime", 10);
      }
      if (isSelected(SocialProviderId.Google)) {
        getGoogleInsights(company!.id, date.since, date.until, "Daily");
        getGoogleInsights(company!.id, date.since, date.until, "Lifetime", 10);
      }
      if (isSelected(SocialProviderId.Mercadolibre)) {
        getMercadolibreInsights(company!.id, date.since, date.until);
        getMercadolibreOrders(company!.id, { perPage: 1 });
      }
      if (isSelected(SocialProviderId.WooCommerce)) {
        getWooCommerceInsights(company!.id, date.since, date.until);
        getCurrency(company!.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    company,
    date,
    getFacebookInsights,
    getGoogleInsights,
    getInstagramInsights,
    getMercadolibreInsights,
    getMercadolibreOrders,
    getWooCommerceInsights,
    getCurrency,
    socialProviders.length,
  ]);

  useEffect(() => {
    handleUpdateDate({
      mode: "Custom" as MetricDateMode,
      since: moment().startOf("month").format("YYYY-MM-DD"),
      until: moment().format("YYYY-MM-DD"),
    });
  }, [handleUpdateDate]);

  const reportsAvailable = useMemo(() => {
    if (moment().isSame(moment(company?.lastReportDate), "M")) {
      return companyPlan?.rules.reportsAmount! - company?.reports!;
    } else {
      return companyPlan?.rules.reportsAmount!;
    }
  }, [companyPlan, company]);

  const instagramError = useMemo(() => {
    switch (instagramErrorCode) {
      case Errors.PermissionsMissing:
        return (
          <Label className="text-muted text-center my-4 w-100">
            {intl.formatMessage({ id: "app.social.permissionsError" })}.
          </Label>
        );
    }
  }, [instagramErrorCode, intl]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.reports.title" })}
        rightChildren={
          <span>
           {`${intl.formatMessage({ id: "app.reports.reports?1" })}
            ${reportsAvailable} 
            ${intl.formatMessage({ id: "app.reports.reports?2" })}`}
          </span>
        }
      />
      <Content>
        <UpdateLoadingCard
          loadingId={GENERATING_REPORT}
          text= {intl.formatMessage({ id: "app.reports.generating" })}
        />
        <Container fluid>
          <PanelWembii shadow className="mb-4 p-4">
            <Row>
              <Col sm="12" md="4">
                <TitleWembii Tag="h5" color="primary">
                 {intl.formatMessage({ id: "app.reports.social" })}
                </TitleWembii>

                {!socialSettings || Object.keys(socialSettings).length === 0 ? (
                  <span className="d-flex h-100 justify-content-center align-items-center">
                    {intl.formatMessage({ id: "app.reports.notSocialPost" })}
                  </span>
                ) : (
                  <SocialReportSelector />
                )}
              </Col>
              <Col sm="12" md="8">
                <TitleWembii Tag="h5" color="primary">
                  {intl.formatMessage({ id: "app.reports.period" })}
                </TitleWembii>
                <div className="d-flex">
                  <AnalyticsDatePicker fixed={false} />
                </div>
                
                <TitleWembii Tag="h5" color="primary">
                 {intl.formatMessage({ id: "app.reports.customLogo" })}
                </TitleWembii>
                <div className="d-flex flex-column">
                  <Label
                    className="position-relative border rounded m-0 bg-light"
                    style={{
                      height: 100,
                      width: 200,
                    }}
                  >
                    {logoFile.file ? (
                      <>
                        <Media
                          src={logoFile.url}
                          className="w-100 h-100"
                          style={{
                            objectFit: "cover",
                            objectPosition: "50% top",
                          }}
                        />
                        <FontAwesomeIcon
                          icon={["far", "times-circle"]}
                          className="position-absolute float-top-right pointer"
                          size="2x"
                          color="red"
                          onClick={() => setLogoFile({ file: null, url: "" })}
                        />
                      </>
                    ) : (
                      <Label
                        for="imageUploader"
                        className="h-100 pointer d-flex flex-column justify-content-center align-items-center"
                      >
                        <Media
                          style={{ width: "80px" }}
                          object
                          src={logo}
                          alt="Powered by"
                        />
                      </Label>
                    )}
                  </Label>
                  <span className="text-muted"> 
                   {intl.formatMessage({ id: "app.reports.selectLogo" })}
                  </span>
                  <Input
                    type="file"
                    id="imageUploader"
                    className="d-none"
                    onChange={(e) => onSelectLogo(e.target.files)}
                  />
                </div>
              </Col>
            </Row>

            <ActionButtons />
          </PanelWembii>
          <Row>
            <Col>
              <UpdateLoadingCard
                loadingId={[
                  FACEBOOK_INSIGHT_LOADING,
                  INSTAGRAM_INSIGHT_LOADING,
                  GOOGLE_INSIGHT_LOADING,
                  WOO_COMMERCE_INSIGHT_LOADING,
                  MERCADOLIBRE_INSIGHT_LOADING,
                ]}
                text={intl.formatMessage({ id: "app.reports.updating" })}
              />
              {isSelected(SocialProviderId.Facebook) && (
                <>
                  <Header2
                    title={intl.formatMessage({ id: "app.reports.title1" })}
                    icon={<FacebookIcon active size="2x" />}
                    id="facebookHeader"
                    rightChildren={
                      <Media
                        style={{ maxWidth: 150, maxHeight: 60 }}
                        src={logoFile.file ? logoFile.url : logo}
                      />
                    }
                  />
                  <FacebookCharts />
                </>
              )}
              {isSelected(SocialProviderId.Instagram) && (
                <>
                  <Header2
                    title={intl.formatMessage({ id: "app.reports.title2" })}
                    icon={<InstagramIcon active size="2x" />}
                    id="instagramHeader"
                    rightChildren={
                      <Media
                        style={{ maxWidth: 200, maxHeight: 80 }}
                        src={logoFile.file ? logoFile.url : logo}
                      />
                    }
                  />

                  {instagramErrorCode && (
                    <Row className="d-flex justify-content-center">
                      <Col xs="12" md="8">  
                        <PanelWembii className="mb-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <ErrorIcon size="2x" className="m-2 ml-4" />
                            { instagramError }
                          </div>
                        </PanelWembii>
                      </Col>
                    </Row>
                  )}
                  <InstagramCharts />
                </>
              )}
              {isSelected(SocialProviderId.Google) && (
                <>
                  <Header2
                    title={intl.formatMessage({ id: "app.reports.title3" })}
                    icon={<GooglenAnalyticsIcon active size="2x" />}
                    id="googleHeader"
                    rightChildren={
                      <Media
                        style={{ maxWidth: 200, maxHeight: 80 }}
                        src={logoFile.file ? logoFile.url : logo}
                      />
                    }
                  />
                  <GoogleCharts />
                </>
              )}
              {isSelected(SocialProviderId.Mercadolibre) && (
                <>
                  <Header2
                    title={intl.formatMessage({ id: "app.reports.title4" })}
                    icon={<MercadolibreIcon active size="2x" />}
                    id="wooCommerceHeader"
                    rightChildren={
                      <Media
                        style={{ maxWidth: 200, maxHeight: 80 }}
                        src={logoFile.file ? logoFile.url : logo}
                      />
                    }
                  />
                  <MercadolibreCharts />
                </>
              )}
              {isSelected(SocialProviderId.WooCommerce) && (
                <>
                  <Header2
                    title={intl.formatMessage({ id: "app.reports.title5" })}
                    icon={<WooCommerceIcon active size="2x" />}
                    id="wooCommerceHeader"
                    rightChildren={
                      <Media
                        style={{ maxWidth: 200, maxHeight: 80 }}
                        src={logoFile.file ? logoFile.url : logo}
                      />
                    }
                  />
                  <WooCommerceCharts />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Content>
    </Layout>
  );
};
