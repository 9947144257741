import { DeleteCommentPayload, DeleteCommentResponse } from "../interface/api/delete-comment";
import { GetAvailableTimePayload, GetAvailableTimeResponse } from "../interface/api/get-available-time";
import { GetCalendarPayload, GetCalendarResponse } from "../interface/api/get-calendar";
import { GetEventResponse } from "../interface/api/get-event";
import { GetEventsPayload, GetEventsResponse } from "../interface/api/get-events";
import { GetPublicCalendarPayload, GetPublicCalendarResponse } from "../interface/api/get-public-calendar";
import { PostCalendarTimePayload, PostCalendarTimeResponse } from "../interface/api/post-calendar-time";
import { CommentPayload, PostCommentResponse } from "../interface/api/post-comment";
import { PostContactInputPayload, PostContactInputResponse } from "../interface/api/post-contact-input";
import { PostEventPayload, PostEventResponse } from "../interface/api/post-event";
import { PutCalendarResponse } from "../interface/api/put-calendar";
import { PutCommentResponse } from "../interface/api/put-comment";

export const eventMapper = {
  mapResponseToEvent(
    response: PostEventResponse | GetEventResponse
  ): PostEventPayload {
    const { event } = response?.payload;
    return { event };
  },
  mapResponseToEvents(response: GetEventsResponse): GetEventsPayload {
    const { events } = response?.payload;
    return { events };
  },
  mapResponseToAvailableTime(response: GetAvailableTimeResponse): GetAvailableTimePayload {
    const { hours } = response?.payload;
    return { hours };
  },
  mapResponseToPublicCalendar(response: GetPublicCalendarResponse): GetPublicCalendarPayload {
    const { calendar } = response?.payload;
    return { calendar };
  },
  mapResponseToCalendar(response: GetCalendarResponse | PutCalendarResponse): GetCalendarPayload {
    const { calendar } = response?.payload;
    return { calendar };
  },
  mapResponseToTime(response: PostCalendarTimeResponse): PostCalendarTimePayload {
    const { time } = response?.payload;
    return { time };
  },
  mapResponseToInput(response: PostContactInputResponse): PostContactInputPayload {
    const { input } = response?.payload;
    return { input };
  },
  mapResponseToComment(response: PostCommentResponse | PutCommentResponse): CommentPayload {
    const { comment } = response?.payload;
    return { comment };
  },
  mapResponseToId(response: DeleteCommentResponse): DeleteCommentPayload {
    const { id } = response?.payload;
    return { id };
  },
};
