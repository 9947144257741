import mainApiClient from "../../helpers/services/main-api-client";
import { GetForgotPasswordResponse } from "../interfaces/api/get-forgot-password";
import { GetUserResponse } from "../interfaces/api/get-user";
import { GetUsersParams, GetUsersResponse } from "../interfaces/api/get-users";
import {
  PostForgotPasswordRequest,
  PostForgotPasswordResponse,
} from "../interfaces/api/post-forgot-password";
import { PostResendConfirmationResponse } from "../interfaces/api/post-resend-confirmation";
import { PostUserRequest, PostUserResponse } from "../interfaces/api/post-user";
import { PutUserRequest, PutUserResponse } from "../interfaces/api/put-user";
import { userMapper } from "./mapper";

export const userApiService = {
  postRegisterUser(body: PostUserRequest) {
    return mainApiClient()
      .post<PostUserResponse>(`public/user`, body)
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  postRegisterEntelUser(body: PostUserRequest) {
    return mainApiClient()
      .post<PostUserResponse>(`public/user/entel`, body)
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  postUser(body: PostUserRequest) {
    return mainApiClient()
      .post<PostUserResponse>(`user`, body)
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  putUser(id: string, body: PutUserRequest) {
    return mainApiClient()
      .put<PutUserResponse>(`user/${id}`, body)
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  getUsers(params?: GetUsersParams) {
    return mainApiClient()
      .get<GetUsersResponse>(`user`, { params })
      .then(({ data }) => userMapper.mapResponseToUsers(data));
  },
  getUser(email: string) {
    return mainApiClient()
      .get<GetUserResponse>(`user/search/${email}`, {})
      .then(({ data }) => userMapper.mapResponseToUser(data));
  },
  putRecoverPassword(password: string, token: string) {
    const body: PostForgotPasswordRequest = { password, token };
    return mainApiClient()
      .put<PostForgotPasswordResponse>(`public/user/recover_password`, body)
      .then(({ data }) => data);
  },
  postResendConfirmation(id: string) {
    return mainApiClient()
      .post<PostResendConfirmationResponse>(`public/user/resend_confirmation`, {
        id,
      })
      .then(({ data }) => data);
  },
  getForgotPassword(email: string) {
    return mainApiClient()
      .get<GetForgotPasswordResponse>(`public/user/forgot_password/${email}`)
      .then(({ data }) => data);
  },
};
