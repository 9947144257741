import _ from "lodash";
import moment from "moment";
import { calculateTotal, getMax, getMin } from "../../analyticsUtils";
import {
  GetInsightResponse,
  Metric,
  Value,
} from "../interface/api/get-insights";
import {
  GetInstagramFeedPosts,
  InstagramPost,
} from "../interface/api/get-instagram-feed-posts";
import { ChartMetric } from "../interface/ChartMetric";
import { Metric2 } from "../interface/Metric2";

export const analyticsMapper = {
  mapResponseToMetrics(response: GetInsightResponse): Metric[] {
    return response.payload.metrics;
  },

  mapResponseToInstagramPost(response: GetInstagramFeedPosts): InstagramPost[] {
    return response.payload.post;
  },

  mapToChartMetric(metrics: Metric2[]): ChartMetric {
    return metrics
      .filter((metric) => metric !== undefined)
      .reduce(
        (
          prev: ChartMetric,
          { values = [], title = "", type, color, axis }: Metric2
        ): ChartMetric => ({
          labels: _.uniq([
            ...prev.labels,
            ...this.mapMetricValueToLabels(values),
          ]),
          datasets: [
            ...prev.datasets,
            {
              label: title,
              data: this.mapMetricValueToData(values),
              type,
              color,
              yAxisID: axis || "",
            },
          ],
        }),
        { labels: [], datasets: [] }
      );
  },

  mapInstagramPostToMetric(posts: InstagramPost[]): Metric {
    const values = posts.reduce(
      (prev: Value[], { timestamp }: InstagramPost): Value[] => {
        const label = moment(timestamp * 1000)
          .format("MMMM Do YYYY")
          .toString();
        const _value = prev.find((value) => label === value.label);
        if (_value) {
          return [
            ...prev.filter((value) => label !== value.label),
            { label, value: _value.value + 1 },
          ];
        }
        return [...prev, { label, value: 1 }];
      },
      []
    );

    return {
      title: "Instagram Posts",
      description: "Posteos realizados en Instagram",
      name: "Instagram Posts",
      period: "Daily",
      values,
      maxValue: getMax(values),
      minValue: getMin(values),
      total: calculateTotal(values),
    };
  },

  mapMetricValueToLabels(values: Value[]): string[] {
    return values.map(({ label }) => `${label}`);
  },

  mapMetricValueToData(values: Value[]): number[] {
    return values.map(({ value }) => value);
  },
};
