import cn from "classnames";
import React, { ReactNode } from "react";
import { Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { PanelWembii } from "../PanelWembii";

interface Props {
  children: ReactNode;
  title: string;
  selector?: ReactNode;
  monitors?: ReactNode;
  legend?: ReactNode;
  className?: string;
  height?: number;
  isEmptyData?: boolean;
  message?: string;
  id?: string;
  deprecated?: boolean;
}

export const ChartContainer = (props: Props) => {
  const intl = useIntl();
  const {
    children,
    title,
    selector,
    monitors,
    className,
    legend,
    height,
    isEmptyData,
    message,
    id,
    deprecated,
  } = props;

  return (
    <Container
      id={id}
      fluid
      className={cn("my-4 py-3 d-flex flex-column", className)}
    >
      <PanelWembii className="p-4">
        <Row className="mb-1">
          <Col xs="6">
            <h6 className="h3">{title}</h6>
          </Col>
          <Col xs="6">{selector}</Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12" className="d-flex justify-content-end flex-wrap">
            {monitors}
          </Col>
        </Row>
        <Row style={{ height: `${height}px` }}>
          {!isEmptyData && legend != null && (
            <Col xs="6" className="h-100">
              <div className="overflow-auto h-100">{legend}</div>
            </Col>
          )}
          <Col
            className={"align-items-center col d-flex justify-content-center"}
          >
            {deprecated && (
              <h2 className="h2 font-weight-bolder text-black-50 text-center">
                {intl.formatMessage({ id: "app.analiyticsFace.deprecated" })}
              </h2>
            )}
            {isEmptyData && !deprecated && (
              <h2 className="h2 font-weight-bolder text-black-50 text-center">
                {intl.formatMessage({ id: "app.analiyticsFace.noDate" })}
              </h2>
            )}

            {!deprecated && !isEmptyData && children}
          </Col>
        </Row>
        {message != null && (
          <Row className="d-block px-4 row small text-black-50 text-right">
            {message}
          </Row>
        )}
      </PanelWembii>
    </Container>
  );
};
