import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Container, FormGroup } from "reactstrap";
import { useMeCore } from "../../../../../cores/me";
import { useIntl } from "react-intl";
import { useWebFormCore } from "../../../../../cores/webForm";
import { PanelWembii } from "../../../PanelWembii";
import { TitleWembii } from "../../../TitleWembii";
import { FORM_CONFIG } from "../../../../../cores/webForm/config";

export default function UrlForm() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { webForm } = useWebFormCore();
  const domain = process.env.REACT_APP_EMBEDDED_FORM_DOMAIN;
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (webForm.form) {
      setUrl(`${FORM_CONFIG.webUrl}/webform/${btoa(company?.id!)}/${btoa(webForm?.form!.id)}`);
    }
  }, [webForm, company, domain]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(url);
    toast.success(intl.formatMessage({ id: "app.editForm.instalationMsg" }));
  }, [url, intl]);

  return (
    <Container className="p-0">
      <PanelWembii shadow className="mb-4 p-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.editForm.url" })}
        </TitleWembii>
        <FormGroup className="d-flex align-items-start justify-content-between mb-0">
          <pre className="d-flex flex-column p-2 bg-light">
            {url}
          </pre>
          <div
            className="border border-white-50 text-dark shadow-sm btn btn-sm"
            color="white"
            id="copyCode"
            onClick={handleCopy}
          >
             {intl.formatMessage({ id: "app.editForm.instalationCopy" })}
          </div>
        </FormGroup>
      </PanelWembii>
    </Container>
  );
}
