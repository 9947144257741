import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Email } from "../../../cores/contacts/interfaces/api/get-contact";
import { GmailIcon } from "../social/Gmail/GmailIcon";
import Avatar from "../Avatar";

interface Props {
  email: Email;
}

export default function EmailCard({ email }: Props) {
  const intl = useIntl();

  const createdAt = useMemo(() =>
    moment(email.createdAt).format("DD/MM/YYYY HH:mm a")
  , [email.createdAt]);

  return (
    <div className="d-flex mt-3 mb-4 w-100">
      <Avatar
        className="text-dark-50"
        icon={<GmailIcon active />}
      />
      <div className="d-flex flex-column align-items-start ml-2 w-100">
        <div>
          <span className="font-weight-bold mr-2">
            {email.from}
          </span>
          <span className="text-muted">
            {createdAt}
          </span>
        </div>
        <div>{intl.formatMessage({ id: "app.sendEmail.subject" })} {email.subject}</div>
        <div className="p-2 border rounded pre-wrap email-container">
          <div dangerouslySetInnerHTML={{__html: email.text}} />
        </div>
        <div className="mt-2">
          <a
            href={`https://mail.google.com/mail?authuser=${email.from}#all/${email.emailId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-2 text-underline text-dark"
          >
            Abrir original
          </a>
        </div>
      </div>
    </div>
  );
}
