import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { FacebookProvider, Login } from "react-facebook";
import { Button } from "reactstrap";
import {
  FACEBOOK_GET_LOADING,
  FACEBOOK_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT } from "../../../../constants/modal.constant";
import { useFacebookCore } from "../../../../cores/facebook";
import { APP_ID, SCOPE } from "../../../../cores/facebook/config";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import SocialCard from "../SocialCard";
import FacebookAccounts from "./FacebookAccounts";
import { FacebookIcon } from "./FacebookIcon";

interface FacebookProviderResponse {
  tokenDetail: { accessToken: string };
}

export default function FacebookBindCard() {
  const intl = useIntl();
  const { get, settings, unbind, postAccounts } = useFacebookCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { turnOnModal, isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Facebook,
      name: SocialProviderName.Facebook,
      icon: <FacebookIcon size="5x" active={!_.isEmpty(settings)} />,
      displayName: settings?.name,
      avatar: settings?.avatarUrl,
    }),
    [settings]
  );

  const remainingBindDays = useMemo(
    () =>
      settings?.expiresIn ? daysRemainingFromToday(settings?.expiresIn) : null,
    [settings]
  );

  const handleOnError = useCallback(
    (error: string) => console.error(error),
    []
  );

  const handleOnCompleted = useCallback(
    async ({ tokenDetail: { accessToken } }: FacebookProviderResponse) => {
      
      await postAccounts(company!.id, accessToken);
      turnOnModal(
        FACEBOOK_ACCOUNTS_SIDE_MODAL_RIGHT,
        <FacebookAccounts userToken={accessToken} />
      );
    },
    [company, postAccounts, turnOnModal]
  );

  const handleUnbind = useCallback(() => {
    unbind(company!.id, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  const button = useMemo(() => {
    return _.isEmpty(settings) ? (
      <FacebookProvider version="v9.0" appId={APP_ID}>
        <Login
          scope={SCOPE}
          onCompleted={handleOnCompleted}
          onError={handleOnError}
        >
          {({ handleClick }: { handleClick: () => void }) => (
            <Button
              className="w-100 mx-auto"
              color="primary"
              onClick={handleClick}
            >
              {intl.formatMessage({ id: "app.home.syncUp" })}
            </Button>
          )}
        </Login>
      </FacebookProvider>
    ) : (
      <Button className="w-100" color="primary" outline onClick={handleUnbind}>
        {intl.formatMessage({ id: "app.home.disconnect" })}
      </Button>
    );
  }, [settings, handleOnCompleted, handleOnError, handleUnbind, intl]);

  return (
    <SocialCard
      social={socialProvider}
      bindLoading={isLoading(FACEBOOK_SYNC_LOADING)}
      getLoading={isLoading(FACEBOOK_GET_LOADING)}
      videoId="nkgEVaSXLx8"
      title={intl.formatMessage({ id: "app.videosHome.facebook" })}
      remainingBindDays={remainingBindDays}
    >
      <div className="w-100 px-2">{button}</div>
    </SocialCard>
  );
}
