export enum Errors {
  Checkpoint = 1001,
  "2FA" = 1002,
  MessageDissabled = 1003,
  MessageNotAllowed = 1004,
  NewAudienceNotAllowed = 1005,
  InvalidatedSession = 1006,
  BatchRunning = 1007,
  ExpiredToken = 1008,
  IsDisconnected = 1009,
  NotBusinessAccount = 1010,
  InvalidUserRole = 1011,
  DuplicatedData = 1013,
  WrongSmtpHost = 1014,
  Timeout = 1015,
  WrongAuth = 1016,
  PermissionsMissing = 1017,
  DuplicatedEmail = 1021,
  NoExistResource = 1022,
  NotVerified = 1023,
  InsufficientQuota = 1024,
  InvalidApiKey = 1025,
  CardLoad = 2001,
}
