import { SocialProviderId } from "../config";

export enum SocialType {
  Post = 1,
  Story = 2,
}

export interface PostError {
  type: PostErrorType;
  message?: string;
  socialProviderId: SocialProviderId;
  linkText?: string;
  link?: string;
}

export enum PostErrorType {
  MediaRequired,
  TextSize,
  VideoNotAllowed,
  ImageAspectRatio,
  ImageAspectRatioSize,
  VideoAspectRatioSize,
  MaxImagesPerPost,
  MaxVideoSize,
  MaxImageSize,
  MaxVideoDuration,
}

export interface SocialPostRules {
  maxVideoSize: number;
  maxImageSize: number,
  maxCharactersAllowed: number;
  maxPostPhotoAllowed: number;
  videoAllowed: boolean;
  postMediaRequired: boolean;
  maxStoryPhotoAllowed?: number;
  storyMediaRequired?: boolean;
  maxVideoDuration?: number;
  imageAspectRatioSizeAllowed?: number[];
  videoAspectRatioSizeAllowed?: number[];
  imageAspectRatioAllowed?: string[];
}
