import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { GetTasksParams } from "../interfaces/api/get-tasks";

export const clearTaskAction = createAction(
  "[Task] Clear Task"
);

export const setTaskFiltersAction = createAction(
  "[Task] Set contact filters",
  props<{ filters: GetTasksParams }>()
);

const actions = joinActions({
  clearTaskAction,
  setTaskFiltersAction,
});

export type TaskAction = typeof actions;
