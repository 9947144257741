import React, { useCallback, useState } from "react";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBoardCore } from "../../../../cores/boards";
import TagForm from "../creator/TagForm";
import { CardTag } from "../../../../cores/boards/interfaces/api/get-tags";
import { useMeCore } from "../../../../cores/me";
import InternalModal from "../../../layout/InternalModal";
import { CARD_TAGS } from "../../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../../cores/globals";

interface Props {
  board: string;
  tags: CardTag[];
  onSelecting: (arg0: CardTag, arg1: boolean) => void;
}

export default function CardTags({ onSelecting, tags, board }: Props) {
  const intl = useIntl();
  const { allTags, card, getCard } = useBoardCore();
  const { turnOffInternalModal, isInternalModal, turnOnInternalModal } = useGlobalCore();
  const { company } = useMeCore();
  const [tag, setTag] = useState<CardTag | null>(null);

  const editTag = useCallback((tag: CardTag) => {
    turnOnInternalModal(CARD_TAGS, true);
    setTag(tag);
  }, [turnOnInternalModal]);

  const createTag = useCallback(() => {
    turnOnInternalModal(CARD_TAGS, true);
    setTag(null);
  }, [turnOnInternalModal]);

  const onSuccess = useCallback(() => {
    getCard(company?.id!, board, card?.list!, card?.id!);
  }, [getCard, company, board, card]);

  const toogleTag = useCallback((cardTag: CardTag) => {
    const exist = tags.find((tag) => tag.id === cardTag.id);

    onSelecting(cardTag, !!exist);
  }, [onSelecting, tags]);

  const isTagAdded = useCallback((cardTag: CardTag) => {
    const exist = tags.find((tag) => tag.id === cardTag.id);

    return exist !== undefined;
  }, [tags]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(CARD_TAGS)}
        onClose={() => turnOffInternalModal(CARD_TAGS)}
      >
        <TagForm
          board={board}
          tag={tag!}
          onSuccess={onSuccess}
        />
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 text-center mb-4">
                {intl.formatMessage({ id: "app.activity.tags.title" })}
              </h2>

              <Button color="ligth" className="mb-3" onClick={createTag}>
                {intl.formatMessage({ id: "app.activity.tags.create.new" })}
              </Button>

              {allTags.length === 0 && (
                <div className="d-flex align-items-center justify-content-center h-100 text-muted">
                  {intl.formatMessage({ id: "app.activity.noTags" })}
                </div>
              )}
              {allTags.map((tag) => (
                <div className="d-flex justify-content-between align-items-center my-1" key={tag.id}>
                  <Badge
                    pill
                    className="w-100 px-3 py-2 pointer d-flex justify-content-between align-items-center"
                    style={{ backgroundColor: tag.backgroundColor }}
                    onClick={() => toogleTag(tag)}
                  >
                    <span className="h5 mb-0 font-weight-bold" style={{ color: tag.textColor }}>
                      {tag.name}
                    </span>

                    {isTagAdded(tag) && (
                      <FontAwesomeIcon
                        icon={["fas", "check"]}
                        size="2x"
                        color={tag.textColor}
                      />
                    )}
                  </Badge>
                  <FontAwesomeIcon
                    icon={["fas", "edit"]}
                    size="1x"
                    className="ml-3 pointer"
                    color="gray"
                    onClick={() => editTag(tag)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
