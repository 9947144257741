import { GetOffice365LoginResponse } from "../interface/api/get-office365-login";
import {
  Office365Setting,
  PostOffice365BindResponse,
} from "../interface/api/post-office365-bind";
import { PostOffice365EmailResponse } from "../interface/api/post-office365-email";

export const office365SettingMapper = {
  mapResponseToId(response: PostOffice365EmailResponse) {
    const { id } = response?.payload;
    return id;
  },

  mapResponseToOffice365Setting(response: PostOffice365BindResponse): Office365Setting {
    const { office365Setting } = response?.payload;
    return office365Setting;
  },

  mapResponseToOffice365LoginUrl(
    response: GetOffice365LoginResponse
  ): { url: string } {
    const { url } = response?.payload;
    return { url };
  },
};
