import { produce } from "immer";
import moment from "moment";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { SocialProvider } from "../../socialPost/interfaces/socialProvider";
import { Metric } from "../interface/api/get-insights";
import { InstagramPost } from "../interface/api/get-instagram-feed-posts";
import { MetricDate } from "../interface/Metric2";
import {
  AnalyticsAction,
  removeSocialProviderAction,
  selectPostGroupAction,
  addSocialProviderAction,
  updateInsightDateAction,
} from "./actions";
import {
  AnalyticsApiAction,
  getFacebookInsightFailureAction,
  getFacebookInsightSuccessAction,
  getGoogleInsightFailureAction,
  getGoogleInsightSuccessAction,
  getInstagramFeedPostsFailureAction,
  getInstagramFeedPostsSuccessAction,
  getInstagramInsightFailureAction,
  getInstagramInsightSuccessAction,
  getJumpsellerInsightFailureAction,
  getJumpsellerInsightSuccessAction,
  getMercadolibreInsightFailureAction,
  getMercadolibreInsightSuccessAction,
  getWooCommerceInsightFailureAction,
  getWooCommerceInsightSuccessAction,
} from "./api-actions";

export interface AnalyticsState {
  date: MetricDate;
  facebookInsights: {
    ids: string[];
    entities: Record<string, Metric>;
  };
  googleInsights: {
    ids: string[];
    entities: Record<string, Metric>;
  };
  instagramInsights: {
    ids: string[];
    entities: Record<string, Metric>;
  };
  wooCommerceInsights: {
    ids: string[];
    entities: Record<string, Metric>;
  };
  mercadolibreInsights: {
    ids: string[];
    entities: Record<string, Metric>;
  };
  jumpsellerInsights: {
    ids: string[];
    entities: Record<string, Metric>;
  };
  instagramFeed: {
    posts: { ids: string[]; entities: Record<string, InstagramPost> };
    selectedPostGroupIds: string[];
  };
  socialProviders: {
    ids: string[];
    entities: Record<string, SocialProvider>;
  };
  instagramErrorCode: number | null;
  facebookErrorCode: number | null;
  googleErrorCode: number | null;
  wooCommerceErrorCode: number | null;
  mercadolibreErrorCode: number | null;
  jumpsellerErrorCode: number | null;
}

const initialState: AnalyticsState = {
  date: {
    mode: "Days",
    since: moment().subtract(30, "days").format("MM/DD/YY"),
    until: moment(moment.now()).format("MM/DD/YY"),
  },
  facebookInsights: { ids: [], entities: {} },
  instagramInsights: { ids: [], entities: {} },
  googleInsights: { ids: [], entities: {} },
  wooCommerceInsights: { ids: [], entities: {} },
  mercadolibreInsights: { ids: [], entities: {} },
  jumpsellerInsights: { ids: [], entities: {} },
  instagramFeed: {
    posts: { ids: [], entities: {} },
    selectedPostGroupIds: [],
  },
  socialProviders: { ids: [], entities: {} },
  instagramErrorCode: null,
  facebookErrorCode: null,
  googleErrorCode: null,
  wooCommerceErrorCode: null,
  mercadolibreErrorCode: null,
  jumpsellerErrorCode: null,
};

const insightAdapter = createEntityAdapter<Metric>((insight) => insight.name);
const feedPostAdapter = createEntityAdapter<InstagramPost>((post) => post.id);
const socialProvidersAdapter = createEntityAdapter<SocialProvider>(
  (payload) => payload.id
);

export default produce(
  (
    draft: AnalyticsState = initialState,
    action: AnalyticsApiAction | AnalyticsAction | GlobalActions
  ) => {
    switch (action.type) {
      case getFacebookInsightSuccessAction.type:
        insightAdapter.updateMany(action.insights, draft.facebookInsights);
        draft.facebookErrorCode = null;
        return draft;
      case getFacebookInsightFailureAction.type:
        draft.facebookErrorCode = action.errorCode;
        return draft;
      case getInstagramInsightSuccessAction.type:
        insightAdapter.updateMany(action.insights, draft.instagramInsights);
        draft.instagramErrorCode = null;
        return draft;
      case getInstagramInsightFailureAction.type:
        draft.instagramErrorCode = action.errorCode;
        return draft;
      case getGoogleInsightSuccessAction.type:
        insightAdapter.updateMany(action.insights, draft.googleInsights);
        draft.googleErrorCode = null;
        return draft;
      case getGoogleInsightFailureAction.type:
        draft.googleErrorCode = action.errorCode;
        return draft;
      case getWooCommerceInsightSuccessAction.type:
        insightAdapter.updateMany(action.insights, draft.wooCommerceInsights);
        draft.wooCommerceErrorCode = null;
        return draft;
      case getWooCommerceInsightFailureAction.type:
        draft.wooCommerceErrorCode = action.errorCode;
        return draft;
      case getMercadolibreInsightSuccessAction.type:
        insightAdapter.updateMany(action.insights, draft.mercadolibreInsights);
        draft.mercadolibreErrorCode = null;
        return draft;
      case getMercadolibreInsightFailureAction.type:
        draft.mercadolibreErrorCode = action.errorCode;
        return draft;
      case getJumpsellerInsightSuccessAction.type:
        insightAdapter.updateMany(action.insights, draft.jumpsellerInsights);
        draft.jumpsellerErrorCode = null;
        return draft;
      case getJumpsellerInsightFailureAction.type:
        draft.jumpsellerErrorCode = action.errorCode;
        return draft;
      case getInstagramFeedPostsSuccessAction.type:
        feedPostAdapter.addMany(action.posts, draft.instagramFeed.posts);
        draft.instagramErrorCode = null;
        return draft;
        case getInstagramFeedPostsFailureAction.type:
          draft.instagramErrorCode = action.errorCode;
          return draft;
      case updateInsightDateAction.type:
        draft.date = { ...draft.date, ...action.date };
        return draft;
      case selectPostGroupAction.type:
        draft.instagramFeed.selectedPostGroupIds = action.postIds;
        return draft;
      case addSocialProviderAction.type:
        socialProvidersAdapter.setOne(
          action.socialProvider,
          draft.socialProviders
        );
        return draft;
      case removeSocialProviderAction.type:
        socialProvidersAdapter.removeOne(
          action.socialProvider.id,
          draft.socialProviders
        );
        return draft;
      case resetAction.type:
        return initialState;
      default:
        return draft;
    }
  }
);
