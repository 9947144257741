import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback } from "react";
import { Label } from "reactstrap";
import { useIntl } from "react-intl";
import { useAdvertisementCore } from "../../../cores/advertisements";
import { InputWembii } from "../InputWembii";
import { PanelWembii } from "../PanelWembii";
import { TitleWembii } from "../TitleWembii";
import { TooltipWembii } from "../TooltipWembii";

export default function BudgetForm() {
  const intl = useIntl();
  const { campaign, setCampaign } = useAdvertisementCore();

  const handleConfigChange = useCallback(
    (field) => {
      setCampaign({ ...campaign, ...field });
    },
    [campaign, setCampaign]
  );

  return (
    <PanelWembii shadow className="mb-4 p-4">
      <TitleWembii Tag="h5" color="primary">
       {intl.formatMessage({ id: "app.advertisements.budget" })} 
      </TitleWembii>
      <InputWembii
        type="number"
        name="averageBudget"
        id="averageBudget"
        label={intl.formatMessage({ id: "app.advertisements.budgetDaily" })} 
        flat
        required
        icon={
          <>
            <FontAwesomeIcon
              icon={["fas", "dollar-sign"]}
              size="1x"
              color="gray"
              className="m-1"
            />   
            <div className="mb-3 ml-1 mr-3">         
              <FontAwesomeIcon 
                icon={["fas", "info-circle"]}
                size="1x"
                className="position-absolute border-none"
                id="campaign-budgetDaily"
                color="grey"
              />
              <TooltipWembii 
                id="campaign-budgetDaily"
                text={intl.formatMessage({ id: "app.tooltopAds14" })} 
              />
            </div>  
          </>
        }
        value={campaign.dailyBudget}
        onChange={(v) => handleConfigChange({ dailyBudget: parseInt(v) })}
      />
      <InputWembii
        type="number"
        name="cidAmount"
        id="cidAmount"
        label={intl.formatMessage({ id: "app.advertisements.budgetDailylimit" })}
        flat
        icon={
          <>
            <FontAwesomeIcon
              icon={["fas", "dollar-sign"]}
              size="1x"
              color="gray"
              className="m-1"
            />   
            <div className="mb-3 ml-1 mr-3">         
              <FontAwesomeIcon 
                icon={["fas", "info-circle"]}
                size="1x"
                className="position-absolute border-none"
                id="campaign-budgetDailylimit"
                color="grey"
              />
              <TooltipWembii 
                id="campaign-budgetDailylimit" 
                text={intl.formatMessage({ id: "app.tooltopAds15" })} 
              />
            </div>  
          </>
        }
        value={campaign.bidAmount}
        onChange={(v) => handleConfigChange({ bidAmount: parseInt(v) })}
      />
      <Label className="text-muted">
       {intl.formatMessage({ id: "app.advertisements.budgetDailyAutomatic" })}
      </Label>
      <InputWembii
        type="date"
        name="startDate"
        id="startDate"
        label={intl.formatMessage({ id: "app.advertisements.budgetStartDate" })}
        flat
        required
        value={campaign.startTime}
        onChange={(v) =>
          handleConfigChange({
            startTime: moment(v).format("YYYY-MM-DD"),
          })
        }
        icon={
          <div className="mb-4 mr-3">         
            <FontAwesomeIcon 
              icon={["fas", "info-circle"]}
              size="1x"
              className="position-absolute border-none"
              id="campaign-budgetStartDate"
              color="grey"
            />
            <TooltipWembii 
              id="campaign-budgetStartDate"
              text={intl.formatMessage({ id: "app.tooltopAds16" })} 
            />
          </div>  
        }
      />
      <InputWembii
        type="date"
        name="endDate"
        id="endDate"
        label={intl.formatMessage({ id: "app.advertisements.budgetEndDate" })}
        flat
        value={campaign.endTime}
        onChange={(v) =>
          handleConfigChange({
            endTime: moment(v).format("YYYY-MM-DD"),
          })
        }
        icon={
          <div className="mb-4 mr-3">         
            <FontAwesomeIcon 
              icon={["fas", "info-circle"]}
              size="1x"
              className="position-absolute border-none"
              id="campaign-budgetEndDate"
              color="grey"
            />
            <TooltipWembii 
              id="campaign-budgetEndDate"
              text={intl.formatMessage({ id: "app.tooltopAds17" })}  
            />
          </div>  
          }
      />
      <Label className="text-muted">
       {intl.formatMessage({ id: "app.advertisements.budgetWhiteCamp" })}
      </Label>
    </PanelWembii>
  );
}
