import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  MERCADOLIBRE_GET_LOADING,
  MERCADOLIBRE_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { mercadolibreApiService } from "./services/api";
import {
  getMercadolibreFailureAction,
  getMercadolibreInitAction,
  getMercadolibreSuccessAction,
  postBindMercadolibreInitAction,
  postBindMercadolibreSuccessAction,
  postUnbindMercadolibreFailureAction,
  postUnbindMercadolibreInitAction,
  postUnbindMercadolibreSuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import { settingSelector } from "./store/selector";

export function useMercadolibreCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindMercadolibreInitAction());
        await mercadolibreApiService.unbind(companyId);
        dispatch(postUnbindMercadolibreSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindMercadolibreFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (companyId: string, code: string, onSuccess?: () => void) => {
      try {
        dispatch(postBindMercadolibreInitAction());
        turnOnLoading(MERCADOLIBRE_SYNC_LOADING);
        const response = await mercadolibreApiService.bind(companyId, {
          code,
        });
        dispatch(postBindMercadolibreSuccessAction({ settings: response }));
        turnOffLoading(MERCADOLIBRE_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess24" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(MERCADOLIBRE_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error48" }));
        console.error(error);
        dispatch(postUnbindMercadolibreFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getMercadolibreInitAction());
        turnOnLoading(MERCADOLIBRE_GET_LOADING);
        const settings = await mercadolibreApiService.get(companyId);
        dispatch(getMercadolibreSuccessAction({ settings }));
        turnOffLoading(MERCADOLIBRE_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(MERCADOLIBRE_GET_LOADING);
        console.error(error);
        dispatch(getMercadolibreFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const login = useCallback(
    async (companyId: string, country: string) => {
      try {
        turnOnLoading(MERCADOLIBRE_SYNC_LOADING);
        const response = await mercadolibreApiService.login(companyId, country);
        return response.url;
      } catch (error: any) {
        turnOffLoading(MERCADOLIBRE_SYNC_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error49" }));
      }
    },
    [turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    unbind,
    bind,
    get,
    login,
  };
}
