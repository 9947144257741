import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row, Col } from "reactstrap";
import { CheckboxWembii } from "../../CheckboxWembii";
import { PanelWembii } from "../../PanelWembii";
import { useGlobalCore } from "../../../../cores/globals";
import { BOARD_ROBOT_INACTIVITY } from "../../../../constants/modal.constant";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useIntl } from "react-intl";
import { useBoardCore } from "../../../../cores/boards";
import { useMeCore } from "../../../../cores/me";

const InactivityRow: React.FC<{
  listName: string;
  inactivity: {
    notify: boolean;
    days: number;
    users: number[];
  };
  boardId: string;
  listId: string;
  companyId: string;
}> = ({ inactivity, listName, boardId, listId, companyId }) => {
  const { turnOnModal } = useGlobalCore();
  const { putList } = useBoardCore();
  const { user } = useMeCore();
  const intl = useIntl();
  const formatMessage = () => {
    if (inactivity.days <= 1)
      return `${intl.formatMessage({ id: "app.frequency" })} ${
        inactivity.days
      } ${intl.formatMessage({
        id: "app.day",
      })}`;

    return `${intl.formatMessage({ id: "app.frequency" })}${
      inactivity.days
    } ${intl.formatMessage({
      id: "app.automations.action.automaticEmail.days",
    })}`;
  };
  const onlyMeIsNotified =
    inactivity.users.includes(Number(user?.id)) &&
    inactivity.users.length === 1;
  const onUpdateInactivity = (notify: boolean) => {
    putList(
      companyId,
      boardId,
      listId,
      {
        inactivity: {
          ...inactivity,
          notify,
        },
      },
      () => {}
    );
  };
  return (
    <PanelWembii
      rounded="0"
      className="border-bottom w-100 px-4 py-3 my-2"
      onClick={() => turnOnModal(BOARD_ROBOT_INACTIVITY, true, Position.LEFT)}
    >
      <Row>
        <Col xs="12" lg="7">
          <div className="d-flex align-items-center justify-content-center justify-content-lg-start w-100">
            <FontAwesomeIcon icon={["fas", "robot"]} className="mr-2" />
            <span className="h6 mb-0">{listName}</span>
          </div>
          <span className="h6 mb-0 ">{formatMessage()}</span>
          {onlyMeIsNotified ? (
            <span className="d-flex align-items-center justify-content-center justify-content-lg-start text-muted">
              {intl.formatMessage({ id: "app.crmBusiness.notifyTo" })}{" "}
              {user?.email}
            </span>
          ) : (
            <span className="d-flex align-items-center justify-content-center justify-content-lg-start text-muted">
              {inactivity.users.length} {" "}
              {intl.formatMessage({ id: "app.crmBusiness.assignedUsers" })}
            </span>
          )}
        </Col>
        <Col xs="12" lg="5">
          <div className="d-flex   h-100 align-items-center justify-content-center justify-content-lg-end w-100">
            <CheckboxWembii
              mb={false}
              className="pointer"
              checked={inactivity.notify}
              label={intl.formatMessage({
                id: "app.automations.automation.active",
              })}
              onChange={() => onUpdateInactivity(!inactivity.notify)}
            />
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};

export default InactivityRow;
