import { createSelector } from "reselect";
import { State } from "../../../redux/state";
import { configType } from "../constants/config.constants";

export const configRoot = (state: State) => state.config;

export const configSelector = createSelector(configRoot, (me) => 
  me.config.ids.map((id) => me.config.entities[id])
);

export const freeTrialDaysSelector = createSelector(configRoot, (me) => 
  me.config.entities[configType.FREE_TRIAL_DAYS]
);

export const commentTypesSelector = createSelector(configRoot, ({ commentTypes }) =>
  commentTypes.ids.map((id) => commentTypes.entities[id])
);

export const pathSelector = createSelector(configRoot, ({ path }) => path);
