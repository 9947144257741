import { DeleteHashtagResponse } from "../interfaces/api/delete-hashtag";
import { DeleteSocialPostsPayload, DeleteSocialPostsResponse } from "../interfaces/api/delete-social-posts";
import { GetHashtagsResponse, GetHastagsPayload } from "../interfaces/api/get-hashtags";
import { GetSocialPostsPayload, GetSocialPostsResponse } from "../interfaces/api/get-social-posts";
import { PostHasgtagResponse, PostHashtagPayload } from "../interfaces/api/post-hashtag";

export const socialPostsMapper = {
  mapResponseToSocialPosts(
    response: GetSocialPostsResponse
  ): GetSocialPostsPayload {
    return { posts: response.payload.posts };
  },
  mapResponseToHashtags(
    response: GetHashtagsResponse
  ): GetHastagsPayload {
    return { hashtags: response.payload.hashtags };
  },
  mapResponseToHashtag(
    response: PostHasgtagResponse
  ): PostHashtagPayload {
    return { hashtag: response.payload.hashtag };
  },
  mapResponseToId(
    response: DeleteSocialPostsResponse | DeleteHashtagResponse
  ): DeleteSocialPostsPayload {
    return { id: response.payload.id };
  },
};
