import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { InputWembii } from "../../InputWembii";
import { COLOR_DANGER, COLOR_SUCCESS } from "../../../../constants/theme";

interface Props {
  onSubmit: (e: any) => void;
  toggleEdition: () => void;
  className?: string;
  allowEmpty?: boolean;
  value: number;
  showChek?: boolean;
}

export default function NumberInput(props: Props) {
  const {
    onSubmit,
    toggleEdition,
    value,
    className,
    allowEmpty = false,
    showChek = false
  } = props;
  const [newValue, setNewValue] = useState(0);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    if (allowEmpty || (!allowEmpty && newValue >= 0)) {
      onSubmit(newValue);
    }
  };

  return (
    <Form onSubmit={onFormSubmit} className={cn("m-0 d-flex align-items-center", className)}>
      <InputWembii
        type="number"
        id="percentage"
        className="m-0 pr-1 w-100"
        style={{ width: 150 }}
        mb={false}
        step=".01"
        value={newValue}
        onChange={(v) => setNewValue(v)}
      />
      {showChek && 
        <FontAwesomeIcon
          icon={["fas", "check"]}
          size="2x"
          color={COLOR_SUCCESS}
          className="pointer mx-1"
          onClick={onFormSubmit}
        />
      }
      <FontAwesomeIcon
        icon={["fas", "times"]}
        size="2x"
        color={COLOR_DANGER}
        className="pointer mx-1"
        onClick={toggleEdition}
      />
    </Form>
  );
}
