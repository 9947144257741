import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Task } from "../interfaces/api/get-task";
import { GetTasksParams } from "../interfaces/api/get-tasks";
import { clearTaskAction, setTaskFiltersAction, TaskAction } from "./actions";
import {
  deleteContactTaskSuccessAction,
  deleteTaskFailureAction,
  deleteTaskInitAction,
  deleteTaskSuccessAction,
  getContactTasksSuccessAction,
  getTaskFailureAction,
  getTaskInitAction,
  getTasksExportSuccessAction,
  getTasksFailureAction,
  getTasksInitAction,
  getTasksSuccessAction,
  getTaskSuccessAction,
  postTaskFailureAction,
  postTaskInitAction,
  postTaskSuccessAction,
  putTaskFailureAction,
  putTaskInitAction,
  putTaskSuccessAction,
  TaskApiAction,
} from "./api-actions";

export interface TaskState {
  tasks: {
    ids: string[];
    entities: Record<string, Task>;
  };
  tasksExport: {
    ids: string[];
    entities: Record<string, Task>;
  };
  contactTasks: {
    ids: string[];
    entities: Record<string, Task>;
  };
  task: Task | null;
  taskFilters: GetTasksParams;
  total: number;
}

const initialState: TaskState = {
  tasks: {
    ids: [],
    entities: {},
  },
  tasksExport: {
    ids: [],
    entities: {},
  },
  contactTasks: {
    ids: [],
    entities: {},
  },
  task: null,
  taskFilters: {
    page: 1,
    perPage: 10,
    status: "",
  },
  total: 0,
};

const tasksAdapter = createEntityAdapter<Task>((payload) => payload.id);
const tasksExportAdapter = createEntityAdapter<Task>((payload) => payload.id);
const contactTasksAdapter = createEntityAdapter<Task>((payload) => payload.id);

export default produce(
  (
    draft: TaskState = initialState,
    action: TaskApiAction | TaskAction | GlobalActions
  ) => {
    switch (action.type) {
      case getTasksSuccessAction.type:
        tasksAdapter.addMany(action.tasks, draft.tasks);
        draft.total = action.total;
        return draft;
      case getTasksExportSuccessAction.type:
        tasksExportAdapter.addMany(action.tasks, draft.tasksExport);
        return draft;
      case getContactTasksSuccessAction.type:
        draft.total = action.total;
        contactTasksAdapter.addMany(action.tasks, draft.contactTasks);
        return draft;

      case getTaskSuccessAction.type:
        draft.task = action.task;
        return draft;
      case postTaskSuccessAction.type:
        draft.task = null;
        if (action.taskType === "contactTask") {
          contactTasksAdapter.setOne(action.task, draft.contactTasks);
          contactTasksAdapter.moveToFirstPosition(
            action.task.id,
            draft.contactTasks
          );
        } else {
          tasksAdapter.setOne(action.task, draft.tasks);
          tasksAdapter.moveToFirstPosition(action.task.id, draft.tasks);
        }
        return draft;
      case putTaskSuccessAction.type:
        draft.task = null;
        tasksAdapter.updateOne(action.task, draft.tasks);
        return draft;
      case deleteTaskSuccessAction.type:
        tasksAdapter.removeOne(+action.id, draft.tasks);
        return draft;
      case deleteContactTaskSuccessAction.type:
        contactTasksAdapter.removeOne(+action.id, draft.contactTasks);
        return draft;
      case clearTaskAction.type:
        draft.task = null;
        return draft;
      case setTaskFiltersAction.type:
        draft.taskFilters = action.filters;
        return draft;
      case resetAction.type:
        return initialState;
      case getTaskInitAction.type:
      case getTaskFailureAction.type:
      case postTaskInitAction.type:
      case postTaskFailureAction.type:
      case putTaskInitAction.type:
      case putTaskFailureAction.type:
      case getTasksInitAction.type:
      case getTasksFailureAction.type:
      case deleteTaskInitAction.type:
      case deleteTaskFailureAction.type:
      default:
        return draft;
    }
  }
);
