import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Button, Col, Container, FormGroup, Row } from "reactstrap";
import { useCompanyCore } from "../../../cores/company";
import { CompanyRole } from "../../../cores/company/interfaces/api/get-company";
import { Permissions } from "../../../cores/company/interfaces/roles";
import { useMeCore } from "../../../cores/me";
import { User } from "../../../cores/session/interfaces/models/user";
import { CheckboxWembii } from "../CheckboxWembii";

interface Props {
  user: User;
  role?: CompanyRole;
  onSubmit?: () => void;
}

export default function AssignUserForm({ user, role = {}, onSubmit }: Props) {
  const intl = useIntl();
  const [agent, setAgent] = useState(false);
  const [owner, setOwner] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [permissions, setPermissions] = useState<CompanyRole>({});
  const { user: actioner } = useMeCore();
  const { postAssignment, putAssignment, getCompany } = useCompanyCore();
  const { id: companyId } = useParams<{ id: string }>();

  useEffect(() => {
    setAgent(role.agent || false);
    setOwner(role.owner || false);
    setAdmin(role.admin || false);
    setPermissions({
      social: role.social || false,
      market: role.market || false,
      activities: role.activities || false,
      crm: role.crm || false,
      reports: role.reports || false,
      forms: role.forms || false,
      chat: role.chat || false,
      analitycs: role.analitycs || false,
      ads: role.ads || false,
      contacts: role.contacts || false,
      automations: role.automations || false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabled = useCallback(() =>
    !agent && !owner && !admin && !permissions.social && !permissions.market &&
    !permissions.activities && !permissions.crm && !permissions.reports &&
    !permissions.forms && !permissions.chat && !permissions.analitycs &&
    !permissions.ads && !permissions.contacts && !permissions.automations
  , [agent, owner, admin, permissions]);

  const disabled2 = useCallback(
    () =>
      role &&
      agent === (role.agent || false) &&
      owner === (role.owner || false) &&
      admin === (role.admin || false) &&
      permissions.social === (role.social || false) &&
      permissions.market === (role.market || false) &&
      permissions.activities === (role.activities || false) &&
      permissions.crm === (role.crm || false) &&
      permissions.reports === (role.reports || false) &&
      permissions.forms === (role.forms || false) &&
      permissions.chat === (role.chat || false) &&
      permissions.analitycs === (role.analitycs || false) &&
      permissions.ads === (role.ads || false) &&
      permissions.contacts === (role.contacts || false) &&
      permissions.automations === (role.automations || false),
    [agent, owner, admin, permissions, role]
  );

  const handleAssign = async () => {
    _.isEmpty(role)
      ? await postAssignment(user.id, companyId, {
          ...permissions,
          agent,
          owner,
          admin,
        })
      : await putAssignment(user.id, companyId, {
          ...permissions,
          agent,
          owner,
          admin,
        });
    await getCompany(companyId);
    onSubmit?.();
  };

  const changeOwnerPermission = useCallback((value: boolean) => {
    setOwner(value);
    
    if (value) {
      setAgent(false);
      setAdmin(false);
      setPermissions({
        social: false,
        market: false,
        activities: false,
        crm: false,
        reports: false,
        forms: false,
        chat: false,
        analitycs: false,
        ads: false,
        contacts: false,
        automations: false,
      });
    }
  }, []);

  const changeAgentPermission = useCallback((value: boolean) => {
    setAgent(value);
    
    if (value) {
      setOwner(false);
      setAdmin(false);
      setPermissions({
        social: false,
        market: false,
        activities: false,
        crm: false,
        reports: false,
        forms: false,
        chat: false,
        analitycs: false,
        ads: false,
        contacts: false,
        automations: false,
      });
    }
  }, []);

  const changeAdminPermission = useCallback((value: boolean) => {
    setAdmin(value);
    
    if (value) {
      setOwner(false);
      setAgent(false);
      setPermissions({
        social: false,
        market: false,
        activities: false,
        crm: false,
        reports: false,
        forms: false,
        chat: false,
        analitycs: false,
        ads: false,
        contacts: false,
        automations: false,
      });
    }
  }, []);

  const updateFeaturePermission = useCallback(
    (feature: string, value: boolean) => {
      const newPermissions = { ...permissions }

      const actions = {
        [Permissions.social.value]:
          (value: boolean) => newPermissions.social = value,
        [Permissions.market.value]:
          (value: boolean) => newPermissions.market = value,
        [Permissions.activities.value]:
          (value: boolean) => newPermissions.activities = value,
        [Permissions.crm.value]:
          (value: boolean) => newPermissions.crm = value,
        [Permissions.reports.value]:
          (value: boolean) => newPermissions.reports = value,
        [Permissions.forms.value]:
          (value: boolean) => newPermissions.forms = value,
        [Permissions.chat.value]:
          (value: boolean) => newPermissions.chat = value,
        [Permissions.analitycs.value]:
          (value: boolean) => newPermissions.analitycs = value,
        [Permissions.ads.value]:
          (value: boolean) => newPermissions.ads = value,
        [Permissions.contacts.value]:
          (value: boolean) => newPermissions.contacts = value,
        [Permissions.automations.value]:
          (value: boolean) => newPermissions.automations = value,
      };
      actions[feature]?.(value);
    
      return newPermissions;
    }, [permissions]
  );

  const featurePermissions = useMemo(() => 
    Object.values(Permissions).filter((permission) =>
      permission !== Permissions.agent && permission !== Permissions.owner && permission !== Permissions.admin
    )
  , []);

  const changeFeaturePermission = useCallback((feature: string, value: boolean) => {
    setPermissions({
      ...updateFeaturePermission(feature, value),
    });
    
    if (value) {
      setOwner(false);
      setAgent(false);
      setAdmin(false);
    }
  }, [updateFeaturePermission]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 align-items-center p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({id: "app.permissions.userPermissions"})}
            </h2>
            <h3 className="h4 text-center mb-1">{`${intl.formatMessage({id: "app.permissions.user"})}: ${user?.firstName!} ${user?.lastName!}`}</h3>
            <FormGroup
              tag="fieldset"
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <h4>{intl.formatMessage({id: "app.permissions.permises"})}</h4>
              <div className="d-flex">
                <CheckboxWembii
                  label={intl.formatMessage({ id: Permissions.admin.label })}
                  className="mx-1"
                  checked={admin}
                  onChange={changeAdminPermission}
                />
                <CheckboxWembii
                  label={intl.formatMessage({ id: Permissions.agent.label })}
                  className="mx-1"
                  checked={agent}
                  onChange={changeAgentPermission}
                />
              </div>

              <div className="d-flex justify-content-center flex-wrap wembii-border p-3">
                {featurePermissions.map((permission) => (
                  <CheckboxWembii
                    key={permission.value}
                    className="mx-1"
                    mb={false}
                    label={intl.formatMessage({ id: permission.label })}
                    checked={(permissions as any)[permission.value]}
                    onChange={(v) => changeFeaturePermission(
                      permission.value,
                      v
                    )}
                  />
                ))}
              </div>

              <hr className="w-100" />
              {(actioner?.userRole?.admin || actioner?.userRole?.reseller) && (
                <>
                  <CheckboxWembii
                    label={intl.formatMessage({ id: Permissions.owner.label })}
                    checked={owner}
                    onChange={changeOwnerPermission}
                  />
                  {owner && (
                    <div className="p-3 wembii-border my-2">
                     {intl.formatMessage({id: "app.permissions.txt"})}
                    </div>
                  )}
                </>
              )}
            </FormGroup>

            <Button
              color="primary"
              onClick={handleAssign}
              disabled={disabled() || disabled2()}
              className="w-50 "
            >
              {intl.formatMessage({id: "app.permissions.assign"})}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
