import React, { useMemo } from "react";
import { Quote } from "../../../../cores/boards/interfaces/api/get-card";
import { useIntl } from "react-intl";
import moment from "moment";
import Avatar from "../../Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

interface Props {
  quote: Quote;
}

export default function QuoteCard({ quote }: Props) {
  const intl = useIntl();

  const createdAt = useMemo(() =>
    moment(quote.createdAt).format("DD/MM/YYYY HH:mm a")
  , [quote.createdAt]);

  const expireAt = useMemo(() =>
    moment(quote.expirationDate).format("DD/MM/YYYY HH:mm a")
  , [quote.expirationDate]);

  return (
    <div className="d-flex mt-3 mb-4 w-100">
      <Avatar
        className="text-dark-50"
        icon={
          <FontAwesomeIcon
            icon={["fas", "file-invoice-dollar"]}
            size="1x"
          />
        }
      />
      <div className="d-flex flex-column align-items-start ml-2 w-100">
        <div>
          <span className="font-weight-bold mr-2">
            {quote.name}
          </span>
          <span className="text-muted">
            {createdAt}
          </span>
        </div>
        <div>{intl.formatMessage({ id: "app.crmBusiness.quote.expiration" })}: {expireAt}</div>
        <div className="w-100 d-flex justify-content-end">
          <Button className="wembiiButton">
            <a
              target="_blank"
              rel="noopener noreferrer"
              download
              href={quote.url}
            >
              {intl.formatMessage({ id: "app.contacts.download" })}
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
}
