import { produce } from "immer";
import {
  createUserFailureAction,
  createUserInitAction,
  createUserSuccessAction,
  getUsersInitAction,
  getUsersSuccessAction,
  getUsersFailureAction,
  UserApiAction,
  postForgotPasswordInitAction,
  postForgotPasswordFailureAction,
  postForgotPasswordSuccessAction,
  getForgotPasswordInitAction,
  getForgotPasswordFailureAction,
  getForgotPasswordSuccessAction,
  postResendConfirmationInitAction,
  postResendConfirmationdFailureAction,
  postResendConfirmationSuccessAction,
  getUserFailureAction,
  getUserSuccessAction,
  getUserInitAction,
  putForgotPasswordInitAction,
  putForgotPasswordSuccessAction,
  putForgotPasswordFailureAction,
  putUserInitAction,
  putUserFailureAction,
  putUserSuccessAction,
} from "./api-actions";
import { User } from "../../session/interfaces/models/user";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";

export interface UserState {
  lastUserCreated: User | null;
  user: User | null;
  total: number;
  ids: number[];
  entities: Record<string, User>;
}

const initialState: UserState = {
  lastUserCreated: null,
  user: null,
  total: 0,
  ids: [],
  entities: {},
};
const userAdapter = createEntityAdapter<User>((user) => user.id);

export default produce(
  (draft: UserState = initialState, action: UserApiAction) => {
    switch (action.type) {
      case getUsersSuccessAction.type:
        userAdapter.addMany(action.users, draft);
        draft.total = action.total;
        return draft;
      case createUserSuccessAction.type:
        draft.lastUserCreated = action.user;
        return draft;
      case getUserSuccessAction.type:
        draft.user = action.user;
        return draft;
      case putUserSuccessAction.type:
        userAdapter.updateOne(action.user, draft);
        return draft;
      case getUsersInitAction.type:
      case getUsersFailureAction.type:
      case createUserInitAction.type:
      case createUserFailureAction.type:
      case postForgotPasswordInitAction.type:
      case postForgotPasswordFailureAction.type:
      case postForgotPasswordSuccessAction.type:
      case getForgotPasswordInitAction.type:
      case getForgotPasswordFailureAction.type:
      case getForgotPasswordSuccessAction.type:
      case postResendConfirmationInitAction.type:
      case postResendConfirmationdFailureAction.type:
      case postResendConfirmationSuccessAction.type:
      case putForgotPasswordInitAction.type:
      case putForgotPasswordSuccessAction.type:
      case putForgotPasswordFailureAction.type:
      case getUserInitAction.type:
      case getUserFailureAction.type:
      case putUserInitAction.type:
      case putUserFailureAction.type:
      default:
        return draft;
    }
  }
);
