import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { useResellerCore } from "../../../cores/reseller";
import { Reseller } from "../../../cores/reseller/interfaces/api/get-resellers";
import { InputWembii } from "../InputWembii";
import { useIntl } from "react-intl";

interface Props {
  onSubmit?: () => void;
  reseller?: Reseller;
}

export default function CreateEditResellerForm(props: Props) {
  const intl = useIntl();
  const { onSubmit, reseller } = props;
  const [name, setName] = useState("");
  const [companyCreationLimit, setCompanyCreationLimit] = useState(100);

  const { postReseller, putReseller } = useResellerCore();

  useEffect(() => {
    if (reseller) {
      setCompanyCreationLimit(reseller.companyCreationLimit);
      setName(reseller.name);
    }
  }, [reseller]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    reseller
      ? await putReseller(reseller.id, { name, companyCreationLimit })
      : await postReseller(name, companyCreationLimit);
    onSubmit?.();
  };

  const disabled = useMemo(() => !name || !companyCreationLimit, [
    companyCreationLimit,
    name,
  ]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column justify-content-center py-5 my-2">
            <h2 className="h2 text-center mb-4"> 
             {intl.formatMessage({ id: "app.adminResellers.buttonTxt" })}
            </h2>
            <Form className="px-2" onSubmit={onFormSubmit}>
              <InputWembii
                label={intl.formatMessage({ id: "app.nameTxt" })}
                type="text"
                value={name}
                onChange={(value) => setName(value)}
              />
              <InputWembii
                label={intl.formatMessage({ id: "app.adminResellers.limitCompanies" })}
                type="number"
                value={companyCreationLimit}
                onChange={(value) => setCompanyCreationLimit(value)}
              />
              <Button color="primary" block disabled={disabled}>
                {reseller 
                  ? intl.formatMessage({ id: "app.adminResellers.limitCompanies" })
                  : intl.formatMessage({ id: "app.adminResellers.createReseller" })
                }
              </Button>
            </Form>
          </div>
        </Col>  
      </Row>
    </Container>
  );
}
