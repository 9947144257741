import React, { useCallback, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useIntl } from "react-intl";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ACCOUNT_POST_LOADING } from "../../../constants/loading.constant";
import { useConfigCore } from "../../../cores/config";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { useUserCore } from "../../../cores/user";
import { CheckboxWembii } from "../CheckboxWembii";
import { InputWembii } from "../InputWembii";
import { UpdateLoadingCard } from "../UpdateLoadingCard";
import AssignUserForm from "../companies/AssignUserForm";
import { ASSIGN_USER_SIDE_MODAL_LEFT, USER_EXPAND_CREATE_FORM } from "../../../constants/modal.constant";
import { Position } from "../../../cores/globals/interfaces/globalEntity";

interface Props {
  openAssignModal?: boolean;
  onCreate: () => void;
}

export const CreateUserForm = (props: Props) => {
  const intl = useIntl();
  const { onCreate, openAssignModal = false } = props;
  const { postUser } = useUserCore();
  const { isLoading, turnOnModal, turnOffModal } = useGlobalCore();
  const { getConfig, freeTrialDays: freeTrialDaysConfig } = useConfigCore();
  const { isAdmin, isReseller } = useMeCore();
  const [email, setEmail] = useState("");
  const [freeTrial, setFreeTrial] = useState(false);
  const [freeTrialDays, setFreeTrialDays] = useState(0);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [phoneType, setPhoneType] = useState("");

  const toggle = useCallback( (user) => {
    turnOnModal(
      ASSIGN_USER_SIDE_MODAL_LEFT,
      <AssignUserForm
        user={user!}
        onSubmit={() => turnOffModal(ASSIGN_USER_SIDE_MODAL_LEFT)}
      />,
      Position.LEFT
    );
    turnOffModal(USER_EXPAND_CREATE_FORM);
  }, [turnOffModal, turnOnModal]);
  
  useEffect(() => {
    getConfig();
  }, [getConfig]);

  useEffect(() => {
    freeTrialDaysConfig &&
      setFreeTrialDays(parseInt(freeTrialDaysConfig.value));
  }, [freeTrialDaysConfig]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    const ftDays = freeTrial ? freeTrialDays : 0;
    
    const user = await postUser({
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      country,
      phoneType,
      freeTrial,
      freeTrialDays: ftDays,
    }, onCreate);
    if(user && openAssignModal){
      toggle(user);
    }

    setEmail("");
    setPassword("");
    setPasswordConfirmation("");
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setCountry("");
    setPhoneType("");
    setFreeTrial(false);
    setFreeTrialDays(parseInt(freeTrialDaysConfig.value));
  };

  const isEmailValid = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const isPasswordValid = () => {
    const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{6,})/;
    return passwordRegex.test(password);
  };

  const isRepeatPasswordValid = () => {
    return password === passwordConfirmation;
  };

  const disabled = useCallback(
    () =>
      !email ||
      !password ||
      !passwordConfirmation ||
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !country ||
      !phoneType ||
      password !== passwordConfirmation,
    [email, password, passwordConfirmation, firstName, lastName, phoneNumber, country, phoneType]
  );
  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 justify-content-center py-5">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.adminUsers.creatNewUser" })}
            </h2>
            <Form onSubmit={onFormSubmit}> 
              <FormGroup>
                <Label for="login-name">
                  {intl.formatMessage({ id: "app.nameTxt" })}
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="login-name"
                  placeholder={intl.formatMessage({ id: "app.nameTxt" })}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login-lastName">
                  {intl.formatMessage({ id: "app.surnameTxt" })}
                </Label>
                <Input
                  type="text"
                  name="lastName"
                  id="login-lastName"
                  placeholder={intl.formatMessage({ id: "app.surnameTxt" })}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login-country">
                  {intl.formatMessage({ id: "app.countryTxt" })}
                </Label>
                <Input
                  type="text"
                  name="country"
                  id="login-country"
                  placeholder={intl.formatMessage({ id: "app.countryTxt" })}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login-email">
                 {intl.formatMessage({ id: "app.emailTxt" })}
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="login-email"
                  placeholder={intl.formatMessage({ id: "app.emailTxt" })}
                  invalid={!!email && !isEmailValid()}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {!!email && !isEmailValid() && (
                  <FormFeedback>
                    {intl.formatMessage({ id: "app.adminUsers.createUserError1" })}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="login-phonetype">
                  {intl.formatMessage({ id: "app.phoneTypeTxt" })}
                </Label>
                <Input
                  type="select"
                  name="phonetype"
                  id="login-phonetype"
                  placeholder={intl.formatMessage({ id: "app.phoneTypeTxt" })}
                  value={phoneType}
                  onChange={(e) => setPhoneType(e.target.value)}
                >
                  <option value=''></option>
                  <option value='celphone'>
                    Celular
                  </option>
                  <option value='telephone'>
                    Teléfono fijo
                  </option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="login-Phone">
                  {intl.formatMessage({ id: "app.phoneTxt" })}
                </Label>
                <PhoneInput
                  name="phone"
                  id="login-phone"
                  placeholder={intl.formatMessage({ id: "app.phoneTxt" })}
                  value={phoneNumber}
                  onChange={(v) => setPhoneNumber(v || "")}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login-password">
                  {intl.formatMessage({ id: "app.passwordTxt" })}
                </Label>
                <Input
                  type="password"
                  name="password"
                  id="login-password"
                  placeholder={intl.formatMessage({ id: "app.passwordTxt" })}
                  invalid={!!password && !isPasswordValid()}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {!!password && !isPasswordValid() && (
                  <FormFeedback>  
                   {intl.formatMessage({ id: "app.adminUsers.createUserError2" })}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="login-password">
                  {intl.formatMessage({ id: "app.confirmPaswoord" })}
                </Label>
                <Input
                  type="password"
                  name="passwordConfirmation"
                  id="login-password-confirmation"
                  placeholder={intl.formatMessage({ id: "app.passwordTxt" })}
                  invalid={
                    !!password &&
                    !!passwordConfirmation &&
                    !isRepeatPasswordValid()
                  }
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                {!!password &&
                  !!passwordConfirmation &&
                  !isRepeatPasswordValid() && (
                    <FormFeedback>
                      {intl.formatMessage({ id: "app.passwordsDoNotMatch" })}
                    </FormFeedback>
                  )}
              </FormGroup>

              {(isAdmin || isReseller) && (
                <FormGroup className="d-flex align-items-center">
                  <CheckboxWembii
                    label="Free Trial"
                    mb={false}
                    checked={freeTrial}
                    onChange={(v) => setFreeTrial(v)}
                  />
                  <InputWembii
                    disabled={!freeTrial}
                    type="number"
                    mb={false}
                    className="mx-1"
                    min="1"
                    style={{ width: 100 }}
                    value={freeTrialDays}
                    onChange={(v) => setFreeTrialDays(v)}
                  />
                </FormGroup>
              )}

              {!isLoading(ACCOUNT_POST_LOADING) ? (
                <Button color="primary" block disabled={disabled()}>
                  {intl.formatMessage({ id: "app.adminUsers.creatUser" })}
                </Button>
              ) : (
                <UpdateLoadingCard
                  loadingId={ACCOUNT_POST_LOADING}
                  text={intl.formatMessage({ id: "app.adminUsers.creatingUser" })}
                />
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
