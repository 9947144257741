import moment from "moment";

export enum Month {
  "Enero" = 0,
  "Febrero" = 1,
  "Marzo" = 2,
  "Abril" = 3,
  "Mayo" = 4,
  "Junio" = 5,
  "Julio" = 6,
  "Agosto" = 7,
  "Septiembre" = 8,
  "Octubre" = 9,
  "Noviembre" = 10,
  "Diciembre" = 11,
}

export const getMonthsFromNow = (
  amount: number
): { label: string; value: number }[] => {
  moment().date();
  const month = moment().month();
  const months = [{ label: Month[month], value: month }];
  for (let index = 1; index < amount; index++) {
    months.push({ label: Month[month - index], value: month - index });
  }
  return months;
};

export const getMonthDateRange = (year: number, month: number) => {
  const start = moment([year, month]);
  const end =
    moment(start).endOf("month") > moment()
      ? moment()
      : moment(start).endOf("month");
  return { start, end };
};

export const stringToDate = (date: string) =>
  date ? moment(date).toDate() : null;
