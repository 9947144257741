import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { ChatUser, WhatsappChat } from "../interfaces/api/get-whatsapp-chat";
import { setChatAction, WhatsappChatAction } from "./actions";
import {
  deleteWhatsappUserFailureAction,
  deleteWhatsappUserInitAction,
  deleteWhatsappUserSuccessAction,
  getChatFailureAction,
  getChatInitAction,
  getChatSuccessAction,
  postWhatsappUserFailureAction,
  postWhatsappUserInitAction,
  postWhatsappUserSuccessAction,
  putChatFailureAction,
  putChatInitAction,
  putChatSuccessAction,
  putWhatsappUserFailureAction,
  putWhatsappUserInitAction,
  putWhatsappUserSuccessAction,
  WhatsappChatApiAction,
} from "./api-actions";

export interface WhatsappChatState {
  chat: {
    config: WhatsappChat | null;
    whatsappUsers: ChatUser[];
  };
}

const initialState: WhatsappChatState = {
  chat: {
    config: null,
    whatsappUsers: [],
  },
};

export default produce(
  (
    draft: WhatsappChatState = initialState,
    action: WhatsappChatAction | WhatsappChatApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case setChatAction.type:
        draft.chat.config = action.chat;
        return draft;
      case getChatSuccessAction.type:
        draft.chat = {
          config: action.chat,
          whatsappUsers: action.whatsappUsers,
        };
        return draft;
      case putChatSuccessAction.type:
        draft.chat.config = action.chat;
        return draft;
      case postWhatsappUserSuccessAction.type:
        draft.chat.whatsappUsers = [
          ...draft.chat.whatsappUsers,
          action.whatsappUser,
        ];
        return draft;
      case putWhatsappUserSuccessAction.type:
        const index1 = draft.chat.whatsappUsers.findIndex(
          (whatsappUser) => whatsappUser.id === action.whatsappUser.id
        );
        draft.chat.whatsappUsers[index1] = action.whatsappUser;
        return draft;
      case deleteWhatsappUserSuccessAction.type:
        let whatsappUsers = [...draft.chat.whatsappUsers];
        const index2 = draft.chat.whatsappUsers.findIndex(
          (whatsappUser) => whatsappUser.id === action.id
        );
        whatsappUsers.splice(index2, 1);
        draft.chat.whatsappUsers = whatsappUsers;
        return draft;
      case resetAction.type:
        return initialState;
      case getChatInitAction.type:
      case getChatFailureAction.type:
      case putChatInitAction.type:
      case putChatFailureAction.type:
      case postWhatsappUserInitAction.type:
      case postWhatsappUserFailureAction.type:
      case putWhatsappUserInitAction.type:
      case putWhatsappUserFailureAction.type:
      case deleteWhatsappUserInitAction.type:
      case deleteWhatsappUserFailureAction.type:
      default:
        return draft;
    }
  }
);
