export interface SmartTime {
  hour: number,
  minutes: number,
  score: number
}

export const SMART_TIMES: Record<string, SmartTime[]> = {
  "1": [
    {
      hour: 0,
      minutes: 0,
      score: 1
    },
    {
      hour: 0,
      minutes: 30,
      score: 1
    },
    {
      hour: 1,
      minutes: 0,
      score: 1
    },
    {
      hour: 1,
      minutes: 30,
      score: 1
    },
    {
      hour: 2,
      minutes: 0,
      score: 1
    },
    {
      hour: 2,
      minutes: 30,
      score: 1
    },
    {
      hour: 3,
      minutes: 0,
      score: 1
    },
    {
      hour: 3,
      minutes: 30,
      score: 1
    },
    {
      hour: 4,
      minutes: 0,
      score: 1
    },
    {
      hour: 4,
      minutes: 30,
      score: 1
    },
    {
      hour: 5,
      minutes: 0,
      score: 1
    },
    {
      hour: 5,
      minutes: 30,
      score: 1
    },
    {
      hour: 6,
      minutes: 0,
      score: 1
    },
    {
      hour: 6,
      minutes: 30,
      score: 1
    },
    {
      hour: 7,
      minutes: 0,
      score: 1
    },
    {
      hour: 7,
      minutes: 30,
      score: 1
    },
    {
      hour: 8,
      minutes: 0,
      score: 2
    },
    {
      hour: 8,
      minutes: 30,
      score: 2
    },
    {
      hour: 9,
      minutes: 0,
      score: 4
    },
    {
      hour: 9,
      minutes: 30,
      score: 4
    },
    {
      hour: 10,
      minutes: 0,
      score: 4
    },
    {
      hour: 10,
      minutes: 30,
      score: 4
    },
    {
      hour: 11,
      minutes: 0,
      score: 4
    },
    {
      hour: 11,
      minutes: 30,
      score: 2
    },
    {
      hour: 12,
      minutes: 0,
      score: 2
    },
    {
      hour: 12,
      minutes: 30,
      score: 2
    },
    {
      hour: 13,
      minutes: 0,
      score: 2
    },
    {
      hour: 13,
      minutes: 30,
      score: 3
    },
    {
      hour: 14,
      minutes: 0,
      score: 2
    },
    {
      hour: 14,
      minutes: 30,
      score: 2
    },
    {
      hour: 15,
      minutes: 0,
      score: 5
    },
    {
      hour: 15,
      minutes: 30,
      score: 5
    },
    {
      hour: 16,
      minutes: 0,
      score: 5
    },
    {
      hour: 16,
      minutes: 30,
      score: 2
    },
    {
      hour: 17,
      minutes: 0,
      score: 2
    },
    {
      hour: 17,
      minutes: 30,
      score: 4
    },
    {
      hour: 18,
      minutes: 0,
      score: 2
    },
    {
      hour: 18,
      minutes: 30,
      score: 3
    },
    {
      hour: 19,
      minutes: 0,
      score: 2
    },
    {
      hour: 19,
      minutes: 30,
      score: 3
    },
    {
      hour: 20,
      minutes: 0,
      score: 2
    },
    {
      hour: 20,
      minutes: 30,
      score: 3
    },
    {
      hour: 21,
      minutes: 0,
      score: 3
    },
    {
      hour: 21,
      minutes: 30,
      score: 2
    },
    {
      hour: 22,
      minutes: 0,
      score: 2
    },
    {
      hour: 22,
      minutes: 30,
      score: 2
    },
    {
      hour: 23,
      minutes: 0,
      score: 2
    },
    {
      hour: 23,
      minutes: 30,
      score: 2
    }
  ],
  "2": [
    {
      hour: 0,
      minutes: 0,
      score: 1
    },
    {
      hour: 0,
      minutes: 30,
      score: 1
    },
    {
      hour: 1,
      minutes: 0,
      score: 1
    },
    {
      hour: 1,
      minutes: 30,
      score: 1
    },
    {
      hour: 2,
      minutes: 0,
      score: 1
    },
    {
      hour: 2,
      minutes: 30,
      score: 1
    },
    {
      hour: 3,
      minutes: 0,
      score: 1
    },
    {
      hour: 3,
      minutes: 30,
      score: 1
    },
    {
      hour: 4,
      minutes: 0,
      score: 1
    },
    {
      hour: 4,
      minutes: 30,
      score: 1
    },
    {
      hour: 5,
      minutes: 0,
      score: 1
    },
    {
      hour: 5,
      minutes: 30,
      score: 1
    },
    {
      hour: 6,
      minutes: 0,
      score: 1
    },
    {
      hour: 6,
      minutes: 30,
      score: 1
    },
    {
      hour: 7,
      minutes: 0,
      score: 2
    },
    {
      hour: 7,
      minutes: 30,
      score: 2
    },
    {
      hour: 8,
      minutes: 0,
      score: 2
    },
    {
      hour: 8,
      minutes: 30,
      score: 2
    },
    {
      hour: 9,
      minutes: 0,
      score: 4
    },
    {
      hour: 9,
      minutes: 30,
      score: 4
    },
    {
      hour: 10,
      minutes: 0,
      score: 4
    },
    {
      hour: 10,
      minutes: 30,
      score: 4
    },
    {
      hour: 11,
      minutes: 0,
      score: 4
    },
    {
      hour: 11,
      minutes: 30,
      score: 2
    },
    {
      hour: 12,
      minutes: 0,
      score: 3
    },
    {
      hour: 12,
      minutes: 30,
      score: 2
    },
    {
      hour: 13,
      minutes: 0,
      score: 2
    },
    {
      hour: 13,
      minutes: 30,
      score: 2
    },
    {
      hour: 14,
      minutes: 0,
      score: 3
    },
    {
      hour: 14,
      minutes: 30,
      score: 2
    },
    {
      hour: 15,
      minutes: 0,
      score: 4
    },
    {
      hour: 15,
      minutes: 30,
      score: 3
    },
    {
      hour: 16,
      minutes: 0,
      score: 2
    },
    {
      hour: 16,
      minutes: 30,
      score: 3
    },
    {
      hour: 17,
      minutes: 0,
      score: 2
    },
    {
      hour: 17,
      minutes: 30,
      score: 2
    },
    {
      hour: 18,
      minutes: 0,
      score: 2
    },
    {
      hour: 18,
      minutes: 30,
      score: 3
    },
    {
      hour: 19,
      minutes: 0,
      score: 2
    },
    {
      hour: 19,
      minutes: 30,
      score: 3
    },
    {
      hour: 20,
      minutes: 0,
      score: 2
    },
    {
      hour: 20,
      minutes: 30,
      score: 2
    },
    {
      hour: 21,
      minutes: 0,
      score: 3
    },
    {
      hour: 21,
      minutes: 30,
      score: 3
    },
    {
      hour: 22,
      minutes: 0,
      score: 2
    },
    {
      hour: 22,
      minutes: 30,
      score: 2
    },
    {
      hour: 23,
      minutes: 0,
      score: 2
    },
    {
      hour: 23,
      minutes: 30,
      score: 2
    }
  ],
  "3": [
    {
      hour: 0,
      minutes: 0,
      score: 1
    },
    {
      hour: 0,
      minutes: 30,
      score: 1
    },
    {
      hour: 1,
      minutes: 0,
      score: 1
    },
    {
      hour: 1,
      minutes: 30,
      score: 1
    },
    {
      hour: 2,
      minutes: 0,
      score: 1
    },
    {
      hour: 2,
      minutes: 30,
      score: 1
    },
    {
      hour: 3,
      minutes: 0,
      score: 1
    },
    {
      hour: 3,
      minutes: 30,
      score: 1
    },
    {
      hour: 4,
      minutes: 0,
      score: 1
    },
    {
      hour: 4,
      minutes: 30,
      score: 1
    },
    {
      hour: 5,
      minutes: 0,
      score: 1
    },
    {
      hour: 5,
      minutes: 30,
      score: 1
    },
    {
      hour: 6,
      minutes: 0,
      score: 1
    },
    {
      hour: 6,
      minutes: 30,
      score: 1
    },
    {
      hour: 7,
      minutes: 0,
      score: 1
    },
    {
      hour: 7,
      minutes: 30,
      score: 1
    },
    {
      hour: 8,
      minutes: 0,
      score: 2
    },
    {
      hour: 8,
      minutes: 30,
      score: 2
    },
    {
      hour: 9,
      minutes: 0,
      score: 4
    },
    {
      hour: 9,
      minutes: 30,
      score: 4
    },
    {
      hour: 10,
      minutes: 0,
      score: 4
    },
    {
      hour: 10,
      minutes: 30,
      score: 4
    },
    {
      hour: 11,
      minutes: 0,
      score: 4
    },
    {
      hour: 11,
      minutes: 30,
      score: 2
    },
    {
      hour: 12,
      minutes: 0,
      score: 2
    },
    {
      hour: 12,
      minutes: 30,
      score: 3
    },
    {
      hour: 13,
      minutes: 0,
      score: 2
    },
    {
      hour: 13,
      minutes: 30,
      score: 2
    },
    {
      hour: 14,
      minutes: 0,
      score: 2
    },
    {
      hour: 14,
      minutes: 30,
      score: 3
    },
    {
      hour: 15,
      minutes: 0,
      score: 5
    },
    {
      hour: 15,
      minutes: 30,
      score: 3
    },
    {
      hour: 16,
      minutes: 0,
      score: 4
    },
    {
      hour: 16,
      minutes: 30,
      score: 2
    },
    {
      hour: 17,
      minutes: 0,
      score: 3
    },
    {
      hour: 17,
      minutes: 30,
      score: 3
    },
    {
      hour: 18,
      minutes: 0,
      score: 2
    },
    {
      hour: 18,
      minutes: 30,
      score: 2
    },
    {
      hour: 19,
      minutes: 0,
      score: 2
    },
    {
      hour: 19,
      minutes: 30,
      score: 3
    },
    {
      hour: 20,
      minutes: 0,
      score: 3
    },
    {
      hour: 20,
      minutes: 30,
      score: 3
    },
    {
      hour: 21,
      minutes: 0,
      score: 3
    },
    {
      hour: 21,
      minutes: 30,
      score: 2
    },
    {
      hour: 22,
      minutes: 0,
      score: 2
    },
    {
      hour: 22,
      minutes: 30,
      score: 2
    },
    {
      hour: 23,
      minutes: 0,
      score: 2
    },
    {
      hour: 23,
      minutes: 30,
      score: 2
    }
  ],
  "4": [
    {
      hour: 0,
      minutes: 0,
      score: 1
    },
    {
      hour: 0,
      minutes: 30,
      score: 1
    },
    {
      hour: 1,
      minutes: 0,
      score: 1
    },
    {
      hour: 1,
      minutes: 30,
      score: 1
    },
    {
      hour: 2,
      minutes: 0,
      score: 1
    },
    {
      hour: 2,
      minutes: 30,
      score: 1
    },
    {
      hour: 3,
      minutes: 0,
      score: 1
    },
    {
      hour: 3,
      minutes: 30,
      score: 1
    },
    {
      hour: 4,
      minutes: 0,
      score: 1
    },
    {
      hour: 4,
      minutes: 30,
      score: 1
    },
    {
      hour: 5,
      minutes: 0,
      score: 1
    },
    {
      hour: 5,
      minutes: 30,
      score: 1
    },
    {
      hour: 6,
      minutes: 0,
      score: 1
    },
    {
      hour: 6,
      minutes: 30,
      score: 1
    },
    {
      hour: 7,
      minutes: 0,
      score: 2
    },
    {
      hour: 7,
      minutes: 30,
      score: 1
    },
    {
      hour: 8,
      minutes: 0,
      score: 2
    },
    {
      hour: 8,
      minutes: 30,
      score: 2
    },
    {
      hour: 9,
      minutes: 0,
      score: 4
    },
    {
      hour: 9,
      minutes: 30,
      score: 5
    },
    {
      hour: 10,
      minutes: 0,
      score: 3
    },
    {
      hour: 10,
      minutes: 30,
      score: 4
    },
    {
      hour: 11,
      minutes: 0,
      score: 4
    },
    {
      hour: 11,
      minutes: 30,
      score: 2
    },
    {
      hour: 12,
      minutes: 0,
      score: 2
    },
    {
      hour: 12,
      minutes: 30,
      score: 2
    },
    {
      hour: 13,
      minutes: 0,
      score: 4
    },
    {
      hour: 13,
      minutes: 30,
      score: 4
    },
    {
      hour: 14,
      minutes: 0,
      score: 5
    },
    {
      hour: 14,
      minutes: 30,
      score: 5
    },
    {
      hour: 15,
      minutes: 0,
      score: 4
    },
    {
      hour: 15,
      minutes: 30,
      score: 5
    },
    {
      hour: 16,
      minutes: 0,
      score: 5
    },
    {
      hour: 16,
      minutes: 30,
      score: 3
    },
    {
      hour: 17,
      minutes: 0,
      score: 3
    },
    {
      hour: 17,
      minutes: 30,
      score: 2
    },
    {
      hour: 18,
      minutes: 0,
      score: 4
    },
    {
      hour: 18,
      minutes: 30,
      score: 4
    },
    {
      hour: 19,
      minutes: 0,
      score: 5
    },
    {
      hour: 19,
      minutes: 30,
      score: 4
    },
    {
      hour: 20,
      minutes: 0,
      score: 5
    },
    {
      hour: 20,
      minutes: 30,
      score: 4
    },
    {
      hour: 21,
      minutes: 0,
      score: 4
    },
    {
      hour: 21,
      minutes: 30,
      score: 3
    },
    {
      hour: 22,
      minutes: 0,
      score: 3
    },
    {
      hour: 22,
      minutes: 30,
      score: 4
    },
    {
      hour: 23,
      minutes: 0,
      score: 3
    },
    {
      hour: 23,
      minutes: 30,
      score: 3
    }
  ],
  "5": [
    {
      hour: 0,
      minutes: 0,
      score: 1
    },
    {
      hour: 0,
      minutes: 30,
      score: 1
    },
    {
      hour: 1,
      minutes: 0,
      score: 1
    },
    {
      hour: 1,
      minutes: 30,
      score: 1
    },
    {
      hour: 2,
      minutes: 0,
      score: 1
    },
    {
      hour: 2,
      minutes: 30,
      score: 1
    },
    {
      hour: 3,
      minutes: 0,
      score: 1
    },
    {
      hour: 3,
      minutes: 30,
      score: 1
    },
    {
      hour: 4,
      minutes: 0,
      score: 1
    },
    {
      hour: 4,
      minutes: 30,
      score: 1
    },
    {
      hour: 5,
      minutes: 0,
      score: 1
    },
    {
      hour: 5,
      minutes: 30,
      score: 1
    },
    {
      hour: 6,
      minutes: 0,
      score: 1
    },
    {
      hour: 6,
      minutes: 30,
      score: 1
    },
    {
      hour: 7,
      minutes: 0,
      score: 2
    },
    {
      hour: 7,
      minutes: 30,
      score: 2
    },
    {
      hour: 8,
      minutes: 0,
      score: 2
    },
    {
      hour: 8,
      minutes: 30,
      score: 3
    },
    {
      hour: 9,
      minutes: 0,
      score: 4
    },
    {
      hour: 9,
      minutes: 30,
      score: 4
    },
    {
      hour: 10,
      minutes: 0,
      score: 3
    },
    {
      hour: 10,
      minutes: 30,
      score: 2
    },
    {
      hour: 11,
      minutes: 0,
      score: 1
    },
    {
      hour: 11,
      minutes: 30,
      score: 2
    },
    {
      hour: 12,
      minutes: 0,
      score: 2
    },
    {
      hour: 12,
      minutes: 30,
      score: 2
    },
    {
      hour: 13,
      minutes: 0,
      score: 4
    },
    {
      hour: 13,
      minutes: 30,
      score: 4
    },
    {
      hour: 14,
      minutes: 0,
      score: 5
    },
    {
      hour: 14,
      minutes: 30,
      score: 5
    },
    {
      hour: 15,
      minutes: 0,
      score: 4
    },
    {
      hour: 15,
      minutes: 30,
      score: 5
    },
    {
      hour: 16,
      minutes: 0,
      score: 5
    },
    {
      hour: 16,
      minutes: 30,
      score: 3
    },
    {
      hour: 17,
      minutes: 0,
      score: 3
    },
    {
      hour: 17,
      minutes: 30,
      score: 2
    },
    {
      hour: 18,
      minutes: 0,
      score: 4
    },
    {
      hour: 18,
      minutes: 30,
      score: 5
    },
    {
      hour: 19,
      minutes: 0,
      score: 5
    },
    {
      hour: 19,
      minutes: 30,
      score: 4
    },
    {
      hour: 20,
      minutes: 0,
      score: 5
    },
    {
      hour: 20,
      minutes: 30,
      score: 4
    },
    {
      hour: 21,
      minutes: 0,
      score: 4
    },
    {
      hour: 21,
      minutes: 30,
      score: 3
    },
    {
      hour: 22,
      minutes: 0,
      score: 3
    },
    {
      hour: 22,
      minutes: 30,
      score: 4
    },
    {
      hour: 23,
      minutes: 0,
      score: 3
    },
    {
      hour: 23,
      minutes: 30,
      score: 3
    }
  ],
  "6": [
    {
      hour: 0,
      minutes: 0,
      score: 1
    },
    {
      hour: 0,
      minutes: 30,
      score: 1
    },
    {
      hour: 1,
      minutes: 0,
      score: 1
    },
    {
      hour: 1,
      minutes: 30,
      score: 1
    },
    {
      hour: 2,
      minutes: 0,
      score: 1
    },
    {
      hour: 2,
      minutes: 30,
      score: 1
    },
    {
      hour: 3,
      minutes: 0,
      score: 1
    },
    {
      hour: 3,
      minutes: 30,
      score: 1
    },
    {
      hour: 4,
      minutes: 0,
      score: 1
    },
    {
      hour: 4,
      minutes: 30,
      score: 1
    },
    {
      hour: 5,
      minutes: 0,
      score: 1
    },
    {
      hour: 5,
      minutes: 30,
      score: 1
    },
    {
      hour: 6,
      minutes: 0,
      score: 1
    },
    {
      hour: 6,
      minutes: 30,
      score: 1
    },
    {
      hour: 7,
      minutes: 0,
      score: 2
    },
    {
      hour: 7,
      minutes: 30,
      score: 2
    },
    {
      hour: 8,
      minutes: 0,
      score: 3
    },
    {
      hour: 8,
      minutes: 30,
      score: 3
    },
    {
      hour: 9,
      minutes: 0,
      score: 3
    },
    {
      hour: 9,
      minutes: 30,
      score: 3
    },
    {
      hour: 10,
      minutes: 0,
      score: 4
    },
    {
      hour: 10,
      minutes: 30,
      score: 3
    },
    {
      hour: 11,
      minutes: 0,
      score: 4
    },
    {
      hour: 11,
      minutes: 30,
      score: 3
    },
    {
      hour: 12,
      minutes: 0,
      score: 5
    },
    {
      hour: 12,
      minutes: 30,
      score: 5
    },
    {
      hour: 13,
      minutes: 0,
      score: 5
    },
    {
      hour: 13,
      minutes: 30,
      score: 3
    },
    {
      hour: 14,
      minutes: 0,
      score: 3
    },
    {
      hour: 14,
      minutes: 30,
      score: 2
    },
    {
      hour: 15,
      minutes: 0,
      score: 2
    },
    {
      hour: 15,
      minutes: 30,
      score: 2
    },
    {
      hour: 16,
      minutes: 0,
      score: 3
    },
    {
      hour: 16,
      minutes: 30,
      score: 3
    },
    {
      hour: 17,
      minutes: 0,
      score: 3
    },
    {
      hour: 17,
      minutes: 30,
      score: 3
    },
    {
      hour: 18,
      minutes: 0,
      score: 3
    },
    {
      hour: 18,
      minutes: 30,
      score: 3
    },
    {
      hour: 19,
      minutes: 0,
      score: 3
    },
    {
      hour: 19,
      minutes: 30,
      score: 3
    },
    {
      hour: 20,
      minutes: 0,
      score: 2
    },
    {
      hour: 20,
      minutes: 30,
      score: 2
    },
    {
      hour: 21,
      minutes: 0,
      score: 2
    },
    {
      hour: 21,
      minutes: 30,
      score: 2
    },
    {
      hour: 22,
      minutes: 0,
      score: 2
    },
    {
      hour: 22,
      minutes: 30,
      score: 2
    },
    {
      hour: 23,
      minutes: 0,
      score: 2
    },
    {
      hour: 23,
      minutes: 30,
      score: 2
    }
  ],
  "0": [
    {
      hour: 0,
      minutes: 0,
      score: 1
    },
    {
      hour: 0,
      minutes: 30,
      score: 1
    },
    {
      hour: 1,
      minutes: 0,
      score: 1
    },
    {
      hour: 1,
      minutes: 30,
      score: 1
    },
    {
      hour: 2,
      minutes: 0,
      score: 1
    },
    {
      hour: 2,
      minutes: 30,
      score: 1
    },
    {
      hour: 3,
      minutes: 0,
      score: 1
    },
    {
      hour: 3,
      minutes: 30,
      score: 1
    },
    {
      hour: 4,
      minutes: 0,
      score: 1
    },
    {
      hour: 4,
      minutes: 30,
      score: 1
    },
    {
      hour: 5,
      minutes: 0,
      score: 1
    },
    {
      hour: 5,
      minutes: 30,
      score: 1
    },
    {
      hour: 6,
      minutes: 0,
      score: 1
    },
    {
      hour: 6,
      minutes: 30,
      score: 1
    },
    {
      hour: 7,
      minutes: 0,
      score: 2
    },
    {
      hour: 7,
      minutes: 30,
      score: 2
    },
    {
      hour: 8,
      minutes: 0,
      score: 3
    },
    {
      hour: 8,
      minutes: 30,
      score: 3
    },
    {
      hour: 9,
      minutes: 0,
      score: 3
    },
    {
      hour: 9,
      minutes: 30,
      score: 3
    },
    {
      hour: 10,
      minutes: 0,
      score: 4
    },
    {
      hour: 10,
      minutes: 30,
      score: 4
    },
    {
      hour: 11,
      minutes: 0,
      score: 3
    },
    {
      hour: 11,
      minutes: 30,
      score: 3
    },
    {
      hour: 12,
      minutes: 0,
      score: 5
    },
    {
      hour: 12,
      minutes: 30,
      score: 5
    },
    {
      hour: 13,
      minutes: 0,
      score: 5
    },
    {
      hour: 13,
      minutes: 30,
      score: 3
    },
    {
      hour: 14,
      minutes: 0,
      score: 3
    },
    {
      hour: 14,
      minutes: 30,
      score: 2
    },
    {
      hour: 15,
      minutes: 0,
      score: 2
    },
    {
      hour: 15,
      minutes: 30,
      score: 2
    },
    {
      hour: 16,
      minutes: 0,
      score: 3
    },
    {
      hour: 16,
      minutes: 30,
      score: 3
    },
    {
      hour: 17,
      minutes: 0,
      score: 3
    },
    {
      hour: 17,
      minutes: 30,
      score: 3
    },
    {
      hour: 18,
      minutes: 0,
      score: 3
    },
    {
      hour: 18,
      minutes: 30,
      score: 3
    },
    {
      hour: 19,
      minutes: 0,
      score: 3
    },
    {
      hour: 19,
      minutes: 30,
      score: 3
    },
    {
      hour: 20,
      minutes: 0,
      score: 2
    },
    {
      hour: 20,
      minutes: 30,
      score: 2
    },
    {
      hour: 21,
      minutes: 0,
      score: 2
    },
    {
      hour: 21,
      minutes: 30,
      score: 2
    },
    {
      hour: 22,
      minutes: 0,
      score: 2
    },
    {
      hour: 22,
      minutes: 30,
      score: 2
    },
    {
      hour: 23,
      minutes: 0,
      score: 2
    },
    {
      hour: 23,
      minutes: 30,
      score: 2
    }
  ]
}