import React, { useCallback, useState } from "react";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContactCore } from "../../../../cores/contacts";
import StateForm from "../StateForm";
import InternalModal from "../../../layout/InternalModal";
import { CONTACT_STATES } from "../../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { ContactState } from "../../../../cores/contacts/interfaces/api/get-contact-states";

interface Props {
  onUpdate?: () => void;
}

export default function ContactStates({ onUpdate }: Props) {
  const intl = useIntl();
  const { states } = useContactCore();
  const { turnOffInternalModal, isInternalModal, turnOnInternalModal } = useGlobalCore();
  const [state, setState] = useState<ContactState | null>(null);

  const editStatus = useCallback((state: ContactState) => {
    turnOnInternalModal(CONTACT_STATES, true);
    setState(state);
  }, [turnOnInternalModal]);

  const createStatus = useCallback(() => {
    turnOnInternalModal(CONTACT_STATES, true);
    setState(null);
  }, [turnOnInternalModal]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(CONTACT_STATES)}
        onClose={() => turnOffInternalModal(CONTACT_STATES)}
      >
        <StateForm
          state={state!}
          onUpdate={() => onUpdate?.()}
        />
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 text-center mb-4">
                {intl.formatMessage({ id: "app.contacts.states" })}
              </h2>

              <Button color="ligth" className="mb-3 border" onClick={createStatus}>
                {intl.formatMessage({ id: "app.contacts.state.create" })}
              </Button>

              {states.length === 0 && (
                <div className="d-flex align-items-center justify-content-center h-100 text-muted">
                  {intl.formatMessage({ id: "app.contacts.noStates" })}
                </div>
              )}
              {states.map((state: ContactState) => (
                <div className="d-flex justify-content-between align-items-center my-1" key={state.id}>
                  <Badge
                    pill
                    className="w-100 px-3 py-2 pointer text-left"
                  >
                    <span className="h5 mb-0 font-weight-bold">
                      {state.name}
                    </span>
                  </Badge>
                  <FontAwesomeIcon
                    icon={["fas", "edit"]}
                    size="1x"
                    className="ml-3 pointer"
                    color="gray"
                    onClick={() => editStatus(state)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
