import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { Col, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useBoardCore } from "../../../../cores/boards";
import { InputWembii } from "../../InputWembii";
import { Fee } from "../../../../cores/boards/interfaces/Quote";
import { getCurrencySymbol } from "../../../../cores/boards/constants/currencies.constant";
import { COLOR_DANGER } from "../../../../constants/theme";
import { CL$Format } from "../../../../cores/utils";

interface Props {
  fee: Fee;
  index: number;
  subtotal: number;
}

export const FeeRow = ({ fee, index, subtotal }: Props) => {
  const intl = useIntl();
  const { quote, card, setQuote } = useBoardCore();

  const changeFeeName = useCallback((value) => {
    let fees = [...quote.fees];
    let fee = { ...fees[index] };
    fee.name = value;
    fees[index] = fee;

    setQuote({ ...quote, fees });
  }, [quote, index, setQuote]);

  const changeFee = useCallback((value) => {
    let fees = [...quote.fees];
    let fee = { ...fees[index] };
    fee.value = parseFloat(value);
    fees[index] = fee;

    setQuote({ ...quote, fees });
  }, [quote, index, setQuote]);

  const changeFeeType = useCallback((value) => {
    let fees = [...quote.fees];
    let fee = { ...fees[index] };
    fee.type = parseInt(value);
    fees[index] = fee;

    setQuote({ ...quote, fees });
  }, [quote, index, setQuote]);

  const removeFee = useCallback(() => {
    let fees = [...quote.fees];
    fees.splice(index, 1);

    setQuote({ ...quote, fees });
  }, [quote, index, setQuote]);

  const feeOptions = useMemo(() => [
    { value: 0, label: "%" },
    { value: 1, label: getCurrencySymbol(card?.currency!) },
  ], [card]);

  const calculateFee = useMemo(() => {
    const value = (!fee.type || fee.type === 0)
      ? subtotal * fee.value / 100
      : fee.value;
    return value.toFixed(2);
  }, [subtotal, fee]);

  return (
    <div>
      <div>{intl.formatMessage({ id: "app.products.uniqueFee" })}</div>
      <Row key={index} className="py-1">
        <Col>
          <InputWembii
            type="text"
            name="name"
            id="name"
            mb={false}
            value={fee.name}
            onChange={(v) => changeFeeName(v)}
          />
        </Col>
        <Col>
          <InputWembii
            type="number"
            name="fee"
            id="fee"
            mb={false}
            min="0"
            step=".01"
            value={fee.value}
            style={{ borderRadius: "0px 10px 10px 0px" }}
            onChange={(v) => changeFee(v)}
            prepend={
              <InputWembii
                type="select"
                value={fee.type}
                mb={false}
                style={{
                  borderRadius: "10px 0px 0px 10px",
                  maxWidth: "125px"
                }}
                onChange={(v) => changeFeeType(v)}
              >
                {feeOptions.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </InputWembii>
            }
          />
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          {getCurrencySymbol(card?.currency!)}{` ${CL$Format(calculateFee)}`}

          <FontAwesomeIcon
            icon={["fas", "trash"]}
            className="ml-2 pointer"
            color={COLOR_DANGER}
            onClick={removeFee}
          />
        </Col>
      </Row>
    </div>
  );
};
