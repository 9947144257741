import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { Button } from "reactstrap"
import { BOARD_CONTACTS_MODAL, CONTACT_COMPANY_BOARD_MODAL } from "../../../../constants/modal.constant"
import { useBoardCore } from "../../../../cores/boards"
import { useGlobalCore } from "../../../../cores/globals"
import { Position } from "../../../../cores/globals/interfaces/globalEntity"
import { useMeCore } from "../../../../cores/me"
import { getFirstLetters } from "../../../utils/stringUtils"
import Avatar from "../../Avatar"
import { CheckboxWembii } from "../../CheckboxWembii"
import CompanyList from "../../contacts/modal/CompanyList"
import { LabelWembii } from "../../LabelWembii"
import { RadiobuttonWembii } from "../../RadiobuttonWembii"
import ContactList from "../modal/ContactList"

export default function QuoteBuyerInfo() {
  const intl = useIntl()
  const { quote, card, setQuote, postContact, getCard, postCompany, boardBusiness: board } = useBoardCore()
  const { turnOnModal, turnOffModal } = useGlobalCore()
  const { company } = useMeCore()

  const setQuoteConfig = useCallback((field) => {
    setQuote({ ...quote, ...field })
  }, [quote, setQuote])

  const toggleContact = useCallback((checked, contact) => {
    let contacts
    if (checked) {
      contacts = [...quote.contacts, contact]
    } else {
      contacts = quote.contacts.filter((cnt) => cnt.id !== contact.id)
    }
    setQuote({ ...quote, contacts })
  }, [quote, setQuote])

  const isCompanyAdded = useCallback((company) =>
      quote.company?.id === company.id
    , [quote])

  const isContactAdded = useCallback((contact) =>
      quote.contacts.some((cnt) => cnt.id === contact.id)
    , [quote])

  const openContacts = useCallback(() => {
    turnOnModal(
      BOARD_CONTACTS_MODAL,
      <ContactList
        assignments={card?.contacts!}
        onAssign={(contact) => {
          setQuote({
            ...quote,
            contacts: [
              ...quote.contacts,
              {
                id: contact.id,
                name: contact.name,
                email: contact.email,
                reference: contact.reference,
                phoneNumber: contact.phoneNumber || "",
                status: contact.status || 0,
                state: contact.state || 0,
                source: contact.source,
                owners: [],
                fields: [],
                comments: [],
                companies: [],
                emails: [],
                whatsapp: contact.whatsapp || ""
              },
            ],
          })

          postContact(
            company?.id!,
            board?.id!,
            card?.list!,
            card?.id!,
            contact.id,
            () => {
              getCard(company?.id!, board?.id!, card?.list!, card?.id!)
            },
          )
          turnOffModal(BOARD_CONTACTS_MODAL)
        }}
      />,
      Position.LEFT
    )
  }, [
    turnOnModal,
    turnOffModal,
    postContact,
    getCard,
    setQuote,
    quote,
    company,
    board,
    card,
  ])

  const openCompanies = useCallback(() => {
    turnOnModal(
      CONTACT_COMPANY_BOARD_MODAL,
      <CompanyList
        assignments={card?.companies!}
        onAssign={(boardCompany) => {
          setQuote({ ...quote, company: boardCompany })

          postCompany(
            company?.id!,
            board?.id!,
            card?.list!,
            card?.id!,
            boardCompany.id,
            () => {
              getCard(company?.id!, board?.id!, card?.list!, card?.id!)
            },
          )
          turnOffModal(CONTACT_COMPANY_BOARD_MODAL)
        }}
      />,
      Position.LEFT
    )
  }, [
    company,
    board,
    card,
    quote,
    setQuote,
    turnOnModal,
    turnOffModal,
    postCompany,
    getCard,
  ])

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between my-2">
        <LabelWembii>
          {intl.formatMessage({ id: "app.crmBusiness.quote.company" })}
        </LabelWembii>
        <Button
          className="whiteButton shadow m-0"
          onClick={openCompanies}
        >
          Agregar
        </Button>
      </div>
      {card?.companies.map((company) => (
        <div className="border d-flex align-items-center p-2" key={company.id}>
          <RadiobuttonWembii
            checked={isCompanyAdded(company)}
            onChange={() => setQuoteConfig({ company })}
            mb={false}
          />
          <Avatar
            className="text-dark-50 pointer mx-2"
            icon={
              <FontAwesomeIcon
                icon={["far", "building"]}
                className="mx-2"
                size="1x"
              />
            }
          />
          <div>
            <div>{company.name}</div>
            {company.address && <div>{company.address}</div>}
            {company.city && <div>{company.city}</div>}
            {company.country && <div>{company.country}</div>}
          </div>
        </div>
      ))}

      <div className="d-flex align-items-center justify-content-between my-2">
        <LabelWembii className="mt-2">
          {intl.formatMessage({ id: "app.crmBusiness.quote.contacts" })}
        </LabelWembii>
        <Button
          className="whiteButton shadow m-0"
          onClick={openContacts}
        >
          Agregar
        </Button>
      </div>
      {card?.contacts.map((contact) => (
        <div className="border d-flex align-items-center p-2" key={contact.id}>
          <CheckboxWembii
            checked={isContactAdded(contact)}
            onChange={(v) => toggleContact(v, contact)}
            mb={false}
          />
          <Avatar
            className="text-dark-50 pointer mx-2"
            label={getFirstLetters(contact.name)}
          />
          <div>
            <div>{contact.name}</div>
            {contact.email && <div>{contact.email}</div>}
            {contact.phoneNumber && <div>{contact.phoneNumber}</div>}
          </div>
        </div>
      ))}
    </div>
  )
}
