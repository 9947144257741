import { SingleValue, Value } from "./analytics/interface/api/get-insights";

export const calculateTotal = (values: Value[]): SingleValue =>
  values.reduce(
    (prev: SingleValue, curr: Value) => ({
      samples: prev.samples + 1,
      value: prev.value + curr.value,
    }),
    { samples: 0, value: 0 }
  );

export const getMax = (values: Value[]): Value => {
  const max = Math.max(...values.map(({ value }) => value));
  return values.find(({ value }) => value === max)!;
};

export const getMin = (values: Value[]): Value => {
  const min = Math.min(...values.map(({ value }) => value));
  return values.find(({ value }) => value === min)!;
};
