import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-date-picker";
import { Col, Row } from "reactstrap";
import { useCompanyCore } from "../../../cores/company";
import { useSocialPostCore } from "../../../cores/socialPost";
import { SocialPostStatusOptions } from "../../../cores/socialPost/config";
import { SocialProvider } from "../../../cores/socialPost/interfaces/socialProvider";
import { InputWembii } from "../InputWembii";
import { LabelWembii } from "../LabelWembii";
import { PanelWembii } from "../PanelWembii";
import { ableToPost } from "../socialPost/selector/SocialPostSelector";
import { useIntl } from "react-intl";

export const SocialManagerBar = () => {
  const intl = useIntl();
  const { getAllAdminSocialPosts } = useSocialPostCore();
  const { getCompanies, companies } = useCompanyCore();
  const [since, setSince] = useState(
    moment().subtract(1, "month").toDate()
  );
  const [until, setUntil] = useState(moment().toDate());
  const [provider, setProvider] = useState("all");
  const [status, setStatus] = useState("2");
  const [companyId, setCompanyId] = useState("all");

  const socialProviders: SocialProvider[] = useMemo(
    () =>
      ableToPost.map(
        (social): SocialProvider => ({
          id: social,
          name: social,
        })
      ),
    []
  );

  useEffect(() => {
    getCompanies({});
  }, [getCompanies]);

  useEffect(() => {
    if (since && until) {
      getAllAdminSocialPosts(
        moment(since).format("YYYY-MM-DD"),
        moment(until).format("YYYY-MM-DD"),
        provider,
        status,
        companyId
      );
    }
  }, [until, since, provider, getAllAdminSocialPosts, status, companyId]);

  return (
    <PanelWembii className="w-100 px-4 py-3 mb-3 pointer" shadow>
      <Row className="justify-content-between">
        <Col xs="12" lg="2" className="d-flex">
          <InputWembii
            label={intl.formatMessage({ id: "app.socialManager.socialTxt" })}
            type="select"
            mb={false}
            value={provider}
            onChange={(v) => setProvider(v)}
          >
            <option key="all" value="all">
             {intl.formatMessage({ id: "app.socialManager.allTxt" })}
            </option>
            {socialProviders.map((provider) => (
              <option
                key={provider.id}
                value={provider.id}
                className="text-capitalize"
              >
                {provider.name}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="2" className="d-flex">
          <InputWembii
            label={intl.formatMessage({ id: "app.socialManager.stateTxt" })}
            type="select"
            mb={false}
            value={status}
            onChange={(v) => setStatus(v)}
          >
            {SocialPostStatusOptions.slice(2, 5).map(({ value, label }) => (
              <option key={value} value={value} className="text-capitalize">
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="2" className="d-flex">
          <InputWembii
            label={intl.formatMessage({ id: "app.socialManager.company" })}
            type="select" 
            mb={false}
            value={companyId}
            onChange={(v) => setCompanyId(v)}
          >
            <option key="all" value="all">
              {intl.formatMessage({ id: "app.socialManager.allTxt" })}
            </option>
            {companies.map(({ company: { id, name } }) => (
              <option key={id} value={id} className="text-capitalize">
                {name}
              </option>  
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="6" className="d-flex justify-content-end">
          <div className="d-flex flex-column mr-1">
            <LabelWembii>
              {intl.formatMessage({ id: "app.socialManager.from" })}
            </LabelWembii>
            <DatePicker
              onChange={setSince}
              value={since}
              clearIcon={null}
              format="dd/MM/y"
            />
          </div>
          <div className="d-flex flex-column ml-1">
            <LabelWembii>
              {intl.formatMessage({ id: "app.socialManager.to" })}
            </LabelWembii>
            <DatePicker
              onChange={setUntil}
              value={until}
              clearIcon={null}
              format="dd/MM/y"
            />
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
