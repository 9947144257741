import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { TwitterProfile } from "../interface/api/get-twitter-profiles";
import { TwitterSetting } from "../interface/api/post-twitter-bind";
import {
  getTwitterFailureAction,
  getTwitterInitAction,
  getTwitterLoginFailureAction,
  getTwitterLoginInitAction,
  getTwitterLoginSuccessAction,
  getTwitterProfilesFailureAction,
  getTwitterProfilesInitAction,
  getTwitterProfilesSuccessAction,
  getTwitterSuccessAction,
  postBindTwitterFailureAction,
  postBindTwitterInitAction,
  postBindTwitterSuccessAction,
  postUnbindTwitterFailureAction,
  postUnbindTwitterInitAction,
  postUnbindTwitterSuccessAction,
  putTwitterSocialPostFailureAction,
  putTwitterSocialPostInitAction,
  putTwitterSocialPostSuccessAction,
  TwitterApiAction,
} from "./api-actions";

export interface TwitterState {
  settings: TwitterSetting | null;
  url: string;
  profiles: TwitterProfile[];
  error: string;
}

const initialState: TwitterState = {
  settings: null,
  profiles: [],
  url: "",
  error: "",
};

export default produce(
  (
    draft: TwitterState = initialState,
    action: TwitterApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindTwitterSuccessAction.type:
      case getTwitterSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindTwitterFailureAction.type:
      case postBindTwitterFailureAction.type:
      case getTwitterFailureAction.type:
      case getTwitterLoginFailureAction.type:
        draft.error = action.error;
        return draft;
      case getTwitterProfilesSuccessAction.type:
        draft.profiles = action.profiles;
        return draft;
      case postUnbindTwitterSuccessAction.type:
        draft.settings = null;
        return draft;
      case getTwitterLoginSuccessAction.type:
        draft.url = action.url;
        return draft;
      case getTwitterLoginInitAction.type:
        draft.url = "";
        return draft;
      case resetAction.type:
        return initialState;
      case postBindTwitterInitAction.type:
      case postUnbindTwitterInitAction.type:
      case getTwitterInitAction.type:
      case putTwitterSocialPostInitAction.type:
      case putTwitterSocialPostSuccessAction.type:
      case putTwitterSocialPostFailureAction.type:
      case getTwitterProfilesInitAction.type:
      case getTwitterProfilesFailureAction.type:
      default:
        return draft;
    }
  }
);
