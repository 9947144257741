import { GetCardVerificationPayload, GetCardVerificationResponse } from "../interfaces/api/get-card-verification";
import { GetCouponPayload, GetCouponResponse } from "../interfaces/api/get-coupon";

export const flowMapper = {
  mapResponseToCoupon(response: GetCouponResponse): GetCouponPayload {
    const { coupon } = response?.payload;
    return { coupon };
  },
  mapResponseToBoolean(response: GetCardVerificationResponse): GetCardVerificationPayload {
    const { valid } = response?.payload;
    return { valid };
  },
};
