import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const analyticsRoot = (state: State) => state.analytics;

export const facebookInsightEntitySelector = createSelector(
  analyticsRoot,
  ({ facebookInsights }) => facebookInsights.entities
);

export const instagramInsightEntitySelector = createSelector(
  analyticsRoot,
  ({ instagramInsights }) => instagramInsights.entities
);

export const wooCommerceInsightEntitySelector = createSelector(
  analyticsRoot,
  ({ wooCommerceInsights }) => wooCommerceInsights.entities
);

export const mercadolibreInsightEntitySelector = createSelector(
  analyticsRoot,
  ({ mercadolibreInsights }) => mercadolibreInsights.entities
);

export const jumpsellerInsightEntitySelector = createSelector(
  analyticsRoot,
  ({ jumpsellerInsights }) => jumpsellerInsights.entities
);


export const googleInsightEntitySelector = createSelector(
  analyticsRoot,
  ({ googleInsights }) => googleInsights.entities
);

export const instagramFeedPostsSelector = createSelector(
  analyticsRoot,
  ({ instagramFeed: { posts } }) => posts.ids.map((id) => posts.entities[id])
);

export const instagramFeedSelectedPostGroupSelector = createSelector(
  analyticsRoot,
  ({ instagramFeed: { posts, selectedPostGroupIds } }) =>
    selectedPostGroupIds.map((id) => posts.entities[id])
);

export const socialProvidersSelector = createSelector(
  analyticsRoot,
  ({ socialProviders }) =>
    socialProviders.ids.map((id) => socialProviders.entities[id])
);

export const socialProvidersEntitiesSelector = createSelector(
  analyticsRoot,
  ({ socialProviders }) => socialProviders.entities
);

export const dateSelector = createSelector(analyticsRoot, ({ date }) => date);

export const instagramErrorCodeSelector = createSelector(
  analyticsRoot,
  (analytics) => analytics.instagramErrorCode
);

export const facebookErrorMessageSelector = createSelector(
  analyticsRoot,
  (analytics) => analytics.facebookErrorCode
);

export const googleErrorCodeSelector = createSelector(
  analyticsRoot,
  (analytics) => analytics.googleErrorCode
);

export const wooCommerceErrorMessageSelector = createSelector(
  analyticsRoot,
  (analytics) => analytics.wooCommerceErrorCode
);

export const jumpsellerErrorCodeSelector = createSelector(
  analyticsRoot,
  (analytics) => analytics.jumpsellerErrorCode
);

export const mercadolibreErrorMessageSelector = createSelector(
  analyticsRoot,
  (analytics) => analytics.mercadolibreErrorCode
);
