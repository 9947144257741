import QueryString from "query-string";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMeCore } from "../../../../cores/me";
import { useMercadolibreCore } from "../../../../cores/mercadolibre";
import { useRouterCore } from "../../../../cores/router";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";

export default function MercadolibrePopUp() {
  const { search } = useLocation();
  const { bind } = useMercadolibreCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    const { code } = QueryString.parse(search);
    if (code) bind(company!.id, code as string, () => {
      getMeSocialSettings(company?.id!);
    });
    goToSocial();
  }, [search, company, bind, goToSocial, getMeSocialSettings]);

  return <LoadingSpinnerWembii active />;
}
