import {
  createAction,
  joinActions,
} from "../../helpers/store/actions-helpers";

export const cleanErrorsAction = createAction(
  "[Instagram] Clean errors"
);

const actions = joinActions({
  cleanErrorsAction,
});

export type InstagramAction = typeof actions;
