import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { CommentType } from "../interfaces/api/get-comment-types";
import { Config } from "../interfaces/api/get-config";

export const getConfigInitAction = createAction(
  "[Config] Get Config Init"
);
export const getConfigSuccessAction = createAction(
  "[Config] Get Config Success",
  props<{ config: Config[] }>()
);
export const getConfigFailureAction = createAction(
  "[Config] Get Config Failure",
  props<{ error: string }>()
);

export const putConfigInitAction = createAction(
  "[Config] Put Config Init"
);
export const putConfigSuccessAction = createAction(
  "[Config] Put Config Success",
  props<{ config: Config }>()
);
export const putConfigFailureAction = createAction(
  "[Config] Put Config Failure",
  props<{ error: string }>()
);

export const postCommentTypeInitAction = createAction(
  "[Config] Post Comment Type Init"
);
export const postCommentTypeSuccessAction = createAction(
  "[Config] Post Comment Type Success",
  props< { commentType: CommentType } >()
);
export const postCommentTypeFailureAction = createAction(
  "[Config] Post Comment Type Failure",
  props<{ error: string }>()
);

export const putCommentTypeInitAction = createAction(
  "[Config] Put Comment Type Init"
);
export const putCommentTypeSuccessAction = createAction(
  "[Config] Put Comment Type Success",
  props< { commentType: CommentType } >()
);
export const putCommentTypeFailureAction = createAction(
  "[Config] Put Comment Type Failure",
  props<{ error: string }>()
);

export const deleteCommentTypeInitAction = createAction(
  "[Config] Delete Comment Type Init"
);
export const deleteCommentTypeSuccessAction = createAction(
  "[Config] Delete Comment Type Success",
  props< { id: string } >()
);
export const deleteCommentTypeFailureAction = createAction(
  "[Config] Delete Comment Type Failure",
  props<{ error: string }>()
);

export const getCommentTypesInitAction = createAction(
  "[Config] Get Comment Types Init"
);
export const getCommentTypesSuccessAction = createAction(
  "[Config] Get Comment Type Success",
  props< { types: CommentType[] } >()
);
export const getCommentTypesFailureAction = createAction(
  "[Config] Get Comment Type Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getConfigInitAction,
  getConfigSuccessAction,
  getConfigFailureAction,
  putConfigInitAction,
  putConfigSuccessAction,
  putConfigFailureAction,
  postCommentTypeInitAction,
  postCommentTypeSuccessAction,
  postCommentTypeFailureAction,
  putCommentTypeInitAction,
  putCommentTypeSuccessAction,
  putCommentTypeFailureAction,
  getCommentTypesInitAction,
  getCommentTypesSuccessAction,
  getCommentTypesFailureAction,
  deleteCommentTypeInitAction,
  deleteCommentTypeSuccessAction,
  deleteCommentTypeFailureAction,
});

export type ConfigApiAction = typeof actions;
