import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { COLOR_DANGER } from "../../../../constants/theme";
import { TooltipWembii } from "../../TooltipWembii";
import { useIntl } from "react-intl";
import { EmailFile } from "../../../../cores/email/interface/emailFile";
import { useEmailCore } from "../../../../cores/email";

interface Props {
  file: EmailFile;
}

export const Attachment = ({ file }: Props) => {
  const intl = useIntl();
  const { deleteFile } = useEmailCore();

  const removeFile = useCallback(() => {
    deleteFile(file.id)
  }, [file, deleteFile]);

  return (
    <>
      <div
        id={`attachment${file.id}`}
        className={cn("d-flex align-items-center m-1 px-2 rounded", {
          'bg-danger': file.error,
          'bg-light': !file.error,
          'text-white': file.error,
        })}
      >
        {file.name} 
        <FontAwesomeIcon
          icon={["fas", "times"]}
          size="1x"
          className="ml-2 pointer"
          color={file.error ? "white" : COLOR_DANGER}
          onClick={removeFile}
        />
      </div>
      {file.error && (
        <TooltipWembii 
          id={`attachment${file.id}`}
          text={intl.formatMessage(
            { id: "app.contacts.sizeTooltip" },
            { sizeLimit: 25 }
          )} 
        />
      )}
    </>
  );
};
