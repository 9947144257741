import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  LINKED_IN_BUSINESS_GET_LOADING,
  LINKED_IN_BUSINESS_POST_LOADING,
  LINKED_IN_BUSINESS_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { Media } from "../socialPost/interfaces/api/get-social-posts";
import { PostImage } from "../socialPost/interfaces/postImage";
import { PostVideo } from "../socialPost/interfaces/postVideo";
import { linkedInBusinessApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  getLinkedInBusinessFailureAction,
  getLinkedInBusinessInitAction,
  getLinkedInBusinessSuccessAction,
  postBindLinkedInBusinessInitAction,
  postBindLinkedInBusinessSuccessAction,
  postLinkedInBusinessSocialPostFailureAction,
  postLinkedInBusinessSocialPostInitAction,
  postLinkedInBusinessSocialPostSuccessAction,
  postOrganizationsLinkedInBusinessFailureAction,
  postOrganizationsLinkedInBusinessInitAction,
  postOrganizationsLinkedInBusinessSuccessAction,
  postUnbindLinkedInBusinessFailureAction,
  postUnbindLinkedInBusinessInitAction,
  postUnbindLinkedInBusinessSuccessAction,
  putLinkedInBusinessSocialPostFailureAction,
  putLinkedInBusinessSocialPostInitAction,
  putLinkedInBusinessSocialPostSuccessAction,
} from "./store/api-actions";
import { organizationsSelector, settingSelector } from "./store/selector";
import { Mentions } from "../socialPost/interfaces/Mention";

export function useLinkedInBusinessCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const organizations = useSelector(organizationsSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindLinkedInBusinessInitAction());
        await linkedInBusinessApiService.unbind(companyId);
        dispatch(postUnbindLinkedInBusinessSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindLinkedInBusinessFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      token: string,
      refreshToken: string,
      expiresIn: string,
      organizationId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindLinkedInBusinessInitAction());
        turnOnLoading(LINKED_IN_BUSINESS_SYNC_LOADING);
        const response = await linkedInBusinessApiService.bind(companyId, {
          token,
          refreshToken,
          expiresIn,
          organizationId,
        });
        dispatch(postBindLinkedInBusinessSuccessAction({ settings: response }));
        turnOffLoading(LINKED_IN_BUSINESS_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess19" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(LINKED_IN_BUSINESS_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error37" }));
        console.error(error);
        dispatch(postUnbindLinkedInBusinessFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const postOrganizations = useCallback(
    async (companyId: string, token: string) => {
      try {
        dispatch(postOrganizationsLinkedInBusinessInitAction());
        turnOnLoading(LINKED_IN_BUSINESS_SYNC_LOADING);
        const {
          organizations,
          token: accessToken,
          refreshToken,
          expiresIn,
        } = await linkedInBusinessApiService.organizations(companyId, {
          token,
        });
        dispatch(
          postOrganizationsLinkedInBusinessSuccessAction({
            organizations,
          })
        );
        return {
          accessToken,
          refreshToken,
          expiresIn,
        };
      } catch (error: any) {
        turnOffLoading(LINKED_IN_BUSINESS_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error38" }));
        console.error(error);
        dispatch(postOrganizationsLinkedInBusinessFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getLinkedInBusinessInitAction());
        turnOnLoading(LINKED_IN_BUSINESS_GET_LOADING);
        const response = await linkedInBusinessApiService.get(companyId);
        dispatch(getLinkedInBusinessSuccessAction({ settings: response }));
        turnOffLoading(LINKED_IN_BUSINESS_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(LINKED_IN_BUSINESS_GET_LOADING);
        console.error(error);
        dispatch(getLinkedInBusinessFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const socialPost = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postLinkedInBusinessSocialPostInitAction());
        turnOnLoading(LINKED_IN_BUSINESS_POST_LOADING);
        await linkedInBusinessApiService.socialPost(
          text,
          mentions,
          images,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postLinkedInBusinessSocialPostSuccessAction());
        turnOffLoading(LINKED_IN_BUSINESS_POST_LOADING);
        toast.success(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.sucess20" })
            : intl.formatMessage({ id: "app.toast.sucess21" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(LINKED_IN_BUSINESS_POST_LOADING);
        toast.error(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.error39" })
            : intl.formatMessage({ id: "app.toast.error40" })
        );
        console.error(error);
        dispatch(postLinkedInBusinessSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialPostVideo = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo,
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postLinkedInBusinessSocialPostInitAction());
        turnOnLoading(LINKED_IN_BUSINESS_POST_LOADING);
        await linkedInBusinessApiService.socialPostVideo(
          text,
          mentions,
          video,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postLinkedInBusinessSocialPostSuccessAction());
        turnOffLoading(LINKED_IN_BUSINESS_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess20" })
          : intl.formatMessage({ id: "app.toast.sucess21" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(LINKED_IN_BUSINESS_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error39" })
          : intl.formatMessage({ id: "app.toast.error40" })
        );
        console.error(error);
        dispatch(postLinkedInBusinessSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );
  const modifySocialPost = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putLinkedInBusinessSocialPostInitAction());
        turnOnLoading(LINKED_IN_BUSINESS_POST_LOADING);
        await linkedInBusinessApiService.putSocialPost(
          postId,
          text,
          mentions,
          images,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putLinkedInBusinessSocialPostSuccessAction());
        turnOffLoading(LINKED_IN_BUSINESS_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess20" })
          : intl.formatMessage({ id: "app.toast.sucess21" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(LINKED_IN_BUSINESS_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error39" })
          : intl.formatMessage({ id: "app.toast.error40" })
        );
        console.error(error);
        dispatch(putLinkedInBusinessSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );
  const modifySocialPostVideo = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo | null,
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putLinkedInBusinessSocialPostInitAction());
        turnOnLoading(LINKED_IN_BUSINESS_POST_LOADING);
        await linkedInBusinessApiService.putSocialPostVideo(
          postId,
          text,
          mentions,
          video,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putLinkedInBusinessSocialPostSuccessAction());
        turnOffLoading(LINKED_IN_BUSINESS_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess20" })
          : intl.formatMessage({ id: "app.toast.sucess21" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(LINKED_IN_BUSINESS_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error39" })
          : intl.formatMessage({ id: "app.toast.error40" })
        );
        console.error(error);
        dispatch(putLinkedInBusinessSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    unbind,
    bind,
    get,
    postOrganizations,
    organizations,
    socialPost,
    socialPostVideo,
    modifySocialPost,
    modifySocialPostVideo,
  };
}
