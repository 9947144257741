import React, { useMemo, useState } from "react";
import { Button, Container, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import { EDIT_ME_USER_LOADING } from "../../constants/loading.constant";
import { useMeCore } from "../../cores/me";
import { PanelWembii } from "./PanelWembii";
import { useIntl } from "react-intl";
import { LabelWembii } from "./LabelWembii";
import { LoadingSpinnerWembii } from "./LoadingSpinnerWembii";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_PRIMARY } from "../../constants/theme";

export default function TemporalPasswordModal() {
  const intl = useIntl();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const { putMePassword, getMeUser } = useMeCore();

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    await putMePassword(password, getMeUser);
  };

  const enabled = useMemo(
    () =>
      password && passwordConfirmation && password === passwordConfirmation,
    [password, passwordConfirmation]
  );

  const isPasswordValid = () => {
    const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{6,})/;
    return passwordRegex.test(password);
  };

  const isRepeatPasswordValid = () => {
    return password === passwordConfirmation;
  };

  return (
    <Container className="h-100 w-100 position-absolute p-0" style={{ zIndex: 10000, }} fluid>
      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <div className="h-100 w-100 bg-secondary opacity-4"></div>
        <PanelWembii className="p-4 position-absolute" style={{ width: 400, maxWidth: 400 }}>
          <Form onSubmit={onFormSubmit}>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon
                icon={["fas", "key"]}
                size="6x"
                color={COLOR_PRIMARY}
                className="m-5"
              />
            </div>
            <h2>{intl.formatMessage({id: "app.resetPassword.title" })}</h2>

            <FormGroup>
              <LabelWembii required forid="login-password">
                {intl.formatMessage({ id: "app.passwordTxt" })}
              </LabelWembii>
              <Input
                type="password"
                name="password"
                id="login-password"
                placeholder={intl.formatMessage({ id: "app.passwordTxt" })}
                invalid={!!password && !isPasswordValid()}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {!!password && !isPasswordValid() && (
                <FormFeedback>
                  {intl.formatMessage({ id: "app.navbar.errorPass" })}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <LabelWembii required forid="login-password">
                {intl.formatMessage({ id: "app.navbar.confirmPass" })}
              </LabelWembii>
              <Input
                type="password"
                name="passwordConfirmation"
                id="login-password-confirmation"
                placeholder={intl.formatMessage({ id: "app.passwordTxt" })}
                invalid={
                  !!password &&
                  !!passwordConfirmation &&
                  !isRepeatPasswordValid()
                }
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
              {!!password &&
                !!passwordConfirmation &&
                !isRepeatPasswordValid() && (
                  <FormFeedback>
                    {intl.formatMessage({ id: "app.navbar.passNotCoincideError" })}
                  </FormFeedback>
                )}
            </FormGroup>
            <LoadingSpinnerWembii id={EDIT_ME_USER_LOADING}>
              <Button color="primary" block disabled={!enabled}>
                {intl.formatMessage({ id: "app.navbar.save" })}
              </Button>
            </LoadingSpinnerWembii>
          </Form>
        </PanelWembii>
      </div>
    </Container>
  );
}
