import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";

export const setOrderIdAction = createAction(
  "[Market] Set Jumpseller order id",
  props<{ orderId: string }>()
);

export const setPageAction = createAction(
  "[Market] Set Jumpseller page",
  props<{ page: number }>()
);

export const setPerPageAction = createAction(
  "[Market] Set Jumpseller per page",
  props<{ perPage: number }>()
);

export const setStatusAction = createAction(
  "[Market] Set Jumpseller status",
  props<{ status: string }>()
);

const actions = joinActions({
  setOrderIdAction,
  setPageAction,
  setPerPageAction,
  setStatusAction,
});

export type MarketAction = typeof actions;
