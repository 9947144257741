import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { CARD_PRODUCTS_MODAL } from "../../../../constants/modal.constant";
import { useBoardCore } from "../../../../cores/boards";
import { getCurrencySymbol } from "../../../../cores/boards/constants/currencies.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useMeCore } from "../../../../cores/me";
import { CL$Format } from "../../../../cores/utils";
import { InputWembii } from "../../InputWembii";
import { LabelWembii } from "../../LabelWembii";
import CurrencySelector from "../CurrencySelector";
import ProductList from "../modal/ProductList";
import { DiscountRow } from "./DiscountRow";
import { FeeRow } from "./FeeRow";
import { TaxRow } from "./TaxRow";

export default function QuoteProducts() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { quote, card, boardBusiness: board, setQuote, getCard, postProduct } = useBoardCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const changeItemQuantity = useCallback((index, value) => {
    let products = [...quote.products];
    let product = { ...products[index] };
    product.quantity = parseInt(value || "0");
    products[index] = product;

    setQuote({ ...quote, products });
  }, [quote, setQuote]);

  const changeItemPrice = useCallback((index, value) => {
    let products = [...quote.products];
    let product = { ...products[index] };
    product.price = parseFloat(value || "0");
    products[index] = product;

    setQuote({ ...quote, products });
  }, [quote, setQuote]);

  const changeItemDiscount = useCallback((index, value) => {
    let products = [...quote.products];
    let product = { ...products[index] };
    product.discount = parseFloat(value || "0");
    products[index] = product;

    setQuote({ ...quote, products });
  }, [quote, setQuote]);

  const changeItemDiscountType = useCallback((index, value) => {
    let products = [...quote.products];
    let product = { ...products[index] };
    product.discountType = parseInt(value || "0");
    products[index] = product;

    setQuote({ ...quote, products });
  }, [quote, setQuote]);

  const setCurrency = useCallback((currency) => {
    setQuote({
      ...quote,
      currency,
    });
  }, [quote, setQuote]);

  const openProducts = useCallback(() => {
    turnOnModal(
      CARD_PRODUCTS_MODAL,
      <ProductList
        products={card?.products!}
        onAssign={(product) => {
          const price = product.prices.find((price) => price.currency === board?.currency!);

          postProduct(
            company?.id!,
            board?.id!,
            card?.list!,
            card?.id!,
            {
              product: product.id,
              quantity: 1,
              price: price ? price.price : 0,
              discount: 0,
              discountType: 0,
            },
            () => {
              getCard(company?.id!, board?.id!, card?.list!, card?.id!);
              setQuote({
                ...quote,
                products: [
                  ...quote.products,
                  {
                    id: "",
                    product: product.id,
                    name: product.name,
                    description: product.description,
                    code: product.code,
                    quantity: 1,
                    price: price ? price.price : 0,
                    discountType: 0,
                    discount: 0,
                    status: 0
                  }
                ]
              });
            }
          );

          turnOffModal(CARD_PRODUCTS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [
    company,
    board,
    card,
    quote,
    setQuote,
    turnOnModal,
    turnOffModal,
    getCard,
    postProduct
  ]);

  const discountOptions = useMemo(() => [
    { value: 0, label: "%" },
    { value: 1, label: getCurrencySymbol(quote.currency) },
  ], [quote.currency]);

  const getProduct = useCallback((id) => {
    const product = card?.products.find((product) => product.product === id);
    return product;
  }, [card]);

  const subtotal = useMemo(() =>
    quote.products.reduce((prev, curr) => {
      const discount = curr.discountType === 0
        ? curr.price * curr.quantity * curr.discount / 100
        : curr.discount;
      return prev + curr.price * curr.quantity - discount;
    }, 0)
  , [quote]);

  const total = useMemo(() => {
    const discount = quote.discounts.reduce((prev, curr) => {
      const discount = curr.type === 0
        ? subtotal * curr.value / 100
        : curr.value;
      return prev + discount;
    }, 0);
    const fee = quote.fees.reduce((prev, curr) => {
      const fee = curr.type === 0
        ? subtotal * curr.value / 100
        : curr.value;
      return prev + fee;
    }, 0);
    const tax = quote.taxes.reduce((prev, curr) => {
      const tax = curr.type === 0
        ? subtotal * curr.value / 100
        : curr.value;
      return prev + tax;
    }, 0);

    return subtotal - discount + fee + tax;
  }, [quote, subtotal]);

  const calculateLinePrice = useCallback((product) => {
    const discount = product.discountType === 0
      ? product.price * product.quantity * product.discount / 100
      : product.discount;
    return CL$Format((product.price * product.quantity - discount).toFixed(2));
  }, []);

  const addDiscount = useCallback(() => {
    setQuote({
      ...quote,
      discounts: [
        ...quote.discounts,
        {
          name: "",
          value: 0,
          type: 0,
        }
      ]
    });
  }, [setQuote, quote]);

  const addFee = useCallback(() => {
    setQuote({
      ...quote,
      fees: [
        ...quote.fees,
        {
          name: "",
          value: 0,
          type: 0,
        }
      ]
    });
  }, [setQuote, quote]);

  const addTax = useCallback(() => {
    setQuote({
      ...quote,
      taxes: [
        ...quote.taxes,
        {
          name: "",
          value: 0,
          type: 0,
        }
      ]
    });
  }, [setQuote, quote]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between my-2">
        <LabelWembii>
          {intl.formatMessage({ id: "app.crmBusiness.quote.products" })}
        </LabelWembii>
        <div className="d-flex align-items-center">
          <CurrencySelector
            currency={quote.currency}
            onSubmit={setCurrency}
          />
          <Button
            className="whiteButton shadow m-0 ml-2"
            onClick={openProducts}
          >
            Agregar
          </Button>
        </div>
      </div>
      <div className="border overflow-auto p-2">
        <div className="d-flex mx-1">
          <div className="mx-1 border-right" style={{ minWidth: '200px' }}>
            {intl.formatMessage({ id: "app.products.name" })}
          </div>
          <div className="mx-1 border-right" style={{ minWidth: '100px' }}>
            {intl.formatMessage({ id: "app.products.code" })}
          </div>
          <div className="mx-1 border-right" style={{ minWidth: '100px' }}>
            {intl.formatMessage({ id: "app.products.quantity" })}
          </div>
          <div className="mx-1 border-right" style={{ minWidth: '200px' }}>
            {intl.formatMessage({ id: "app.products.unitPrice" })}
          </div>
          <div className="mx-1 border-right" style={{ minWidth: '150px' }}>
            {intl.formatMessage({ id: "app.products.unitDiscount" })}
          </div>
          <div className="mx-1" style={{ minWidth: '150px' }}>
            {intl.formatMessage({ id: "app.products.netPrice" })}
          </div>
        </div>
        {quote.products.map((product, index) => (
          <div className="d-flex align-items-center mt-1" key={product.product}>
            <div className="d-flex align-items-center mx-1" style={{ minWidth: '200px' }}>
              {getProduct(product.product)?.name}
            </div>
            <div className="d-flex align-items-center mx-1" style={{ minWidth: '100px' }}>
              {getProduct(product.product)?.code}
            </div>
            <div className="mx-1" style={{ minWidth: '100px' }}>
              <InputWembii
                type="number"
                name="quantity"
                id="quantity"
                mb={false}
                min="0"
                value={product.quantity}
                onChange={(v) => changeItemQuantity(index, v)}
              />
            </div>
            <div className="mx-1" style={{ minWidth: '200px' }}>
              <InputWembii
                type="number"
                name="price"
                id="price"
                mb={false}
                min="0"
                step=".01"
                value={product.price}
                style={{ borderRadius: "0px 10px 10px 0px" }}
                onChange={(v) => changeItemPrice(index, v)}
                prepend={
                  <span
                    className="border d-flex align-items-center px-1"
                    style={{
                      borderRadius: "10px 0px 0px 10px",
                    }}
                  >
                    {getCurrencySymbol(quote.currency)}
                  </span>
                }
              />
            </div>
            <div className="mx-1" style={{ minWidth: '150px' }}>
              <InputWembii
                type="number"
                name="discount"
                id="discount"
                mb={false}
                min="0"
                step=".01"
                value={product.discount}
                style={{ borderRadius: "0px 10px 10px 0px" }}
                onChange={(v) => changeItemDiscount(index, v)}
                prepend={
                  <InputWembii
                    type="select"
                    value={product.discountType}
                    mb={false}
                    style={{
                      borderRadius: "10px 0px 0px 10px",
                      maxWidth: "125px"
                    }}
                    onChange={(v) => changeItemDiscountType(index, v)}
                  >
                    {discountOptions.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </InputWembii>
                }
              />
            </div>
            <div className="mx-1 text-right" style={{ minWidth: '150px' }}>
              {getCurrencySymbol(quote.currency)}{` ${calculateLinePrice(product)}`}
            </div>
          </div>
        ))}
      </div>

      <Row className="my-2">
        <Col md="4">
          {intl.formatMessage({ id: "app.products.subtotal" })}
        </Col>
        <Col md="8" className="text-right">
          {getCurrencySymbol(quote.currency)}{` ${CL$Format(subtotal.toFixed(2))}`}
        </Col>
      </Row>

      {quote.fees.map((fee, index) => (
        <FeeRow key={index} fee={fee} index={index} subtotal={subtotal} />
      ))}

      {quote.taxes.map((tax, index) => (
        <TaxRow key={index} tax={tax} index={index} subtotal={subtotal} />
      ))}

      {quote.discounts.map((discount, index) => (
        <DiscountRow key={index} discount={discount} index={index} subtotal={subtotal} />
      ))}

      <UncontrolledDropdown>
        <DropdownToggle nav>
          + Agregar descuento, tarifa o impuesto
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={addFee}>
            Agregar tarifa única
          </DropdownItem>
          <DropdownItem onClick={addTax}>
            Agregar impuesto único
          </DropdownItem>
          <DropdownItem onClick={addDiscount}>
            Agregar descuento único
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <Row className="my-2">
        <Col md="4" className="font-weight-bold">
          {intl.formatMessage({ id: "app.products.total" })}
        </Col>
        <Col md="8" className="text-right">
          {getCurrencySymbol(quote.currency)}{` ${CL$Format(total.toFixed(2))}`}
        </Col>
      </Row>
    </div>
  );
}
