import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import {
  OPENAI_GET_LOADING,
  OPENAI_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { OPENAI_SIDE_MODAL_RIGHT } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import SocialCard from "../SocialCard";
import { useOpenAiCore } from "../../../../cores/openAi";
import { OpenAiIcon } from "./OpenAiIcon";
import OpenAiToken from "./OpenAiToken";

export default function OpenAiBindCard() {
  const intl = useIntl();
  const { get, settings, unbind } = useOpenAiCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { turnOnModal, isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.OpenAI,
      name: SocialProviderName.OpenAI,
      icon: <OpenAiIcon size="5x" active={!_.isEmpty(settings)} />,
      displayName: settings?.name,
    }),
    [settings]
  );

  const isActive = useMemo((): boolean => !_.isEmpty(settings), [settings]);

  const handleBind = useCallback(async () => {
    turnOnModal(OPENAI_SIDE_MODAL_RIGHT, <OpenAiToken />);
  }, [turnOnModal]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  return (
    <SocialCard
      social={socialProvider}
      bindLoading={isLoading(OPENAI_SYNC_LOADING)}
      getLoading={isLoading(OPENAI_GET_LOADING)}
      showSynchronizer={true}
      videoId="8DlFKI9Z2w"
      remainingBindDays={null}
    >
      <div className="w-100 px-2">
        <Button
          className="w-100 mx-auto"
          color="primary"
          outline={isActive}
          onClick={!isActive ? handleBind : handleUnbind}
        >
          {!isActive
            ? intl.formatMessage({ id: "app.home.syncUp" })
            : intl.formatMessage({ id: "app.home.disconnect" })}
        </Button>
      </div>
    </SocialCard>
  );
}
