// export const COLOR_PRIMARY = "#3279CE";
export const COLOR_PRIMARY = "#0d4b7e";
export const COLOR_SUCCESS = "#52cb4c";
export const COLOR_SECONDARY = "#41463d";
export const COLOR_LIGHT = "#eff0f8";
export const COLOR_DANGER = "#ef767a";
export const COLOR_WARNING = "#FC8E09";
export const COLOR_BLACK = "#000";
export const COLOR_GRAY = "#c4c4c4";

export const CHART_THEME = [
  "rgba(54, 162, 235, 1)",
  "rgba(255, 99, 132, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];

export const CHAT_THEME = [
  "rgba(54, 162, 235, 1)",
  "rgba(255, 99, 132, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];
