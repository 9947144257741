import { GetCommentTypesPayloadResponse, GetCommentTypesResponse } from "../interfaces/api/get-comment-types";
import { GetConfigPayload, GetConfigResponse } from "../interfaces/api/get-config";
import { PutConfigPayload, PutConfigResponse } from "../interfaces/api/put-config";

export const configMapper = {
  mapResponseToConfigs(response: GetConfigResponse): GetConfigPayload {
    const config = response?.payload?.config
    return { config };
  },
  mapResponseToConfig(response: PutConfigResponse): PutConfigPayload {
    const config = response?.payload?.config
    return { config };
  },
  mapResponseToCommentType(response: GetCommentTypesResponse): GetCommentTypesPayloadResponse {
    const { types } = response?.payload;
    return { types };
  },
};
