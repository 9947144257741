import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Conversation, Message } from "../interfaces/conversations.interface";
import { ConversationStatus } from "../types/conversation-status.types";
import {
  addInstagramMessageAction,
  removeConversationFromStackByConversationId,
  SocialChatAction,
  setSelectedInstagramConversationAction,
  setSelectedFacebookConversationAction,
  addFacebookMessageAction,
  setSelectedWhatsappConversationAction,
  addWhatsappMessageAction,
  addWhatsappConversationAction,
  setConversationSocialAction,
  setDateFilters,
  updateConversationOwners,
} from "./actions";
import {
  SocialChatApiAction,
  getInstagramConversationsFailureAction,
  getInstagramConversationsInitAction,
  getInstagramConversationsSuccessAction,
  getInstagramMessagesFailureAction,
  getInstagramMessagesInitAction,
  getInstagramMessagesSuccessAction,
  postInstagramMessageFailureAction,
  postInstagramMessageInitAction,
  postInstagramMessageSuccessAction,
  postFacebookMessageSuccessAction,
  getFacebookConversationsSuccessAction,
  getFacebookMessagesSuccessAction,
  getFacebookConversationsInitAction,
  getFacebookConversationsFailureAction,
  getFacebookMessagesFailureAction,
  postFacebookMessageInitAction,
  postFacebookMessageFailureAction,
  postFacebookSeenMessageInitAction,
  postFacebookSeenMessageSuccessAction,
  postFacebookSeenMessageFailureAction,
  getWhatsappConversationsSuccessAction,
  getWhatsappMessagesSuccessAction,
  postWhatsappMessageSuccessAction,
  selectConversationStatusToShow,
} from "./api-actions";
import { SocialType } from "../types/social.types";
import { syncFacebookConversationWithContactAction as syncMetaConversationWithContactAction } from "./actions";
import { DateFilter } from "../interfaces/date-filter.interface";
export interface SocialChatState {
  selectedSocial: SocialType;
  dateFilter: DateFilter;
  instagram: {
    active: {
      ids: number[];
      entities: Record<string, Conversation>;
    };
    inTrash: {
      ids: number[];
      entities: Record<string, Conversation>;
    };
    archived: {
      ids: number[];
      entities: Record<string, Conversation>;
    };
    selected: Conversation | null;
    messages: {
      ids: number[];
      entities: Record<string, Message>;
    };
  };
  conversationStatus: ConversationStatus;
  facebook: {
    active: {
      ids: number[];
      entities: Record<string, Conversation>;
    };
    inTrash: {
      ids: number[];
      entities: Record<string, Conversation>;
    };
    archived: {
      ids: number[];
      entities: Record<string, Conversation>;
    };
    selected: Conversation | null;
    messages: {
      ids: number[];
      entities: Record<string, Message>;
    };
  };
  whatsapp: {
    active: {
      ids: number[];
      entities: Record<string, Conversation>;
    };
    inTrash: {
      ids: number[];
      entities: Record<string, Conversation>;
    };
    archived: {
      ids: number[];
      entities: Record<string, Conversation>;
    };
    filteredConversations: {
      active: Conversation[];
      archived: Conversation[];
      inTrash: Conversation[];
    };
    selected: Conversation | null;
    messages: {
      ids: number[];
      entities: Record<string, Message>;
    };
  };
}

const initialState: SocialChatState = {
  conversationStatus: "active",
  selectedSocial: "all",
  dateFilter: {
    from: "",
    to: "",
  },
  instagram: {
    active: {
      ids: [],
      entities: {},
    },
    archived: {
      ids: [],
      entities: {},
    },
    inTrash: {
      ids: [],
      entities: {},
    },

    selected: null,
    messages: {
      ids: [],
      entities: {},
    },
  },
  facebook: {
    active: {
      ids: [],
      entities: {},
    },
    archived: {
      ids: [],
      entities: {},
    },
    inTrash: {
      ids: [],
      entities: {},
    },

    selected: null,
    messages: {
      ids: [],
      entities: {},
    },
  },
  whatsapp: {
    active: {
      ids: [],
      entities: {},
    },
    archived: {
      ids: [],
      entities: {},
    },
    inTrash: {
      ids: [],
      entities: {},
    },
    filteredConversations: {
      active: [],
      archived: [],
      inTrash: [],
    },
    selected: null,
    messages: {
      ids: [],
      entities: {},
    },
  },
};

const conversationsAdapter = createEntityAdapter<Conversation>(
  (payload) => payload.id
);
const messagesAdapter = createEntityAdapter<Message>((payload) => payload.id);

export default produce(
  (
    draft: SocialChatState = initialState,
    action: SocialChatAction | SocialChatApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case setSelectedInstagramConversationAction.type:
        if (draft.instagram.selected?.id === action.id || !action.id) {
          draft.instagram.selected = null;
        } else {
          const conv1 =
            draft.instagram[draft.conversationStatus].entities[action.id];
          draft.instagram.selected = conv1;

          conversationsAdapter.updateOne(
            { ...draft.instagram.selected, unreadCount: 0 },
            draft.instagram[draft.conversationStatus]
          );
        }
        return draft;
      case updateConversationOwners.type:
        if (action.socialType !== "all" && action.socialType !== "web") {
          const conversation =
            draft[action.socialType][action.statusToUpdate].entities[
              action.conversationId
            ];

          const participant = conversation.participants.find(
            (participant) => participant.id === action.participantId
          );
          const restParticipants = conversation.participants.filter(
            (participant) => participant.id != action.participantId
          );
          conversationsAdapter.updateOne(
            {
              ...conversation,
              participants: [
                ...restParticipants,
                {
                  ...participant,
                  owners: action.newOwners,
                  id: action.participantId,
                  name: `${participant?.name}`,
                },
              ],
            },
            draft[action.socialType][draft.conversationStatus]
          );
          conversationsAdapter.moveToFirstPosition(
            conversation.id,
            draft[action.socialType][draft.conversationStatus]
          );
        }

        return draft;
      case setDateFilters.type:
        draft.dateFilter[action.dateToUpdate] = action.date;

        return draft;

      case addInstagramMessageAction.type:
        let conv2 =
          draft.instagram[draft.conversationStatus].entities[action.id];

        if (conv2) {
          if (draft.whatsapp.selected?.id === conv2.id) {
            messagesAdapter.setOne(action.message, draft.instagram.messages);
          } else {
            conv2 = { ...conv2, unreadCount: conv2.unreadCount + 1 };
          }

          conversationsAdapter.updateOne(
            {
              ...conv2,
              snippet: action.message.message,
              messageType: action.message.type,
              timestamp: action.message.timestamp,
            },
            draft.instagram[draft.conversationStatus]
          );
          conversationsAdapter.moveToFirstPosition(
            conv2.id,
            draft.instagram[draft.conversationStatus]
          );
        }

        return draft;
      case setConversationSocialAction.type:
        draft.selectedSocial = action.social;
        return draft;
      case postInstagramMessageSuccessAction.type:
        let conv3 =
          draft.instagram[draft.conversationStatus].entities[action.id];
        if (conv3) {
          if (draft.instagram.selected?.id === conv3.id) {
            messagesAdapter.setOne(action.message, draft.instagram.messages);
          } else {
            conv3 = { ...conv3, unreadCount: conv3.unreadCount + 1 };
          }

          messagesAdapter.setOne(action.message, draft.instagram.messages);

          conversationsAdapter.updateOne(
            {
              ...conv3,
              snippet: action.message.message,
              messageType: action.message.type,
              timestamp: action.message.timestamp,
            },
            draft.instagram[draft.conversationStatus]
          );
          conversationsAdapter.moveToFirstPosition(
            conv3.id,
            draft.instagram[draft.conversationStatus]
          );
        }

        return draft;
      case getInstagramConversationsSuccessAction.type:
        conversationsAdapter.addMany(action.active, draft.instagram.active);
        conversationsAdapter.addMany(action.inTrash, draft.instagram.inTrash);
        conversationsAdapter.addMany(action.archived, draft.instagram.archived);
        return draft;
      case removeConversationFromStackByConversationId.type:
        if (action.socialType !== "all" && action.socialType !== "web") {
          const conv1 =
            draft[action.socialType][action.statusToUpdate].entities[
              action.conversationId
            ];

          conversationsAdapter.removeOne(
            action.conversationId,
            draft[action.socialType][action.statusToUpdate]
          );
          conversationsAdapter.setOne(
            conv1,
            draft[action.socialType][action.newStatus]
          );
        }

        return draft;
      case getInstagramMessagesSuccessAction.type:
        messagesAdapter.addMany(action.messages, draft.instagram.messages);
        return draft;
      case getInstagramMessagesInitAction.type:
        messagesAdapter.removeAll(draft.instagram.messages);
        return draft;
      case setSelectedFacebookConversationAction.type:
        if (draft.facebook.selected?.fromId === action.id || !action.id) {
          draft.facebook.selected = null;
        } else {
          const conv4 =
            draft.facebook[draft.conversationStatus].entities[action.id];
          draft.facebook.selected = conv4;

          conversationsAdapter.updateOne(
            { ...conv4, unreadCount: 0 },
            draft.facebook[draft.conversationStatus]
          );
        }
        return draft;

      case addFacebookMessageAction.type:
        let conv5 =
          draft.facebook[draft.conversationStatus].entities[action.id];

        if (conv5) {
          if (draft.facebook.selected?.id === conv5.id) {
            messagesAdapter.setOne(action.message, draft.facebook.messages);
          } else {
            conv5 = { ...conv5, unreadCount: conv5.unreadCount + 1 };
          }

          conversationsAdapter.updateOne(
            {
              ...conv5,
              snippet: action.message.message,
              messageType: action.message.type,
              timestamp: action.message.timestamp,
            },
            draft.facebook[draft.conversationStatus]
          );
          conversationsAdapter.moveToFirstPosition(
            conv5.id,
            draft.facebook[draft.conversationStatus]
          );
        }

        return draft;
      case postFacebookMessageSuccessAction.type:
        let conv6 =
          draft.facebook[draft.conversationStatus].entities[action.id];
        if (conv6) {
          if (draft.facebook.selected?.id === conv6.id) {
            messagesAdapter.setOne(action.message, draft.facebook.messages);
          } else {
            conv6 = { ...conv6, unreadCount: conv6.unreadCount + 1 };
          }

          messagesAdapter.setOne(action.message, draft.facebook.messages);

          conversationsAdapter.updateOne(
            {
              ...conv6,
              snippet: action.message.message,
              messageType: action.message.type,
              timestamp: action.message.timestamp,
            },
            draft.facebook[draft.conversationStatus]
          );
          conversationsAdapter.moveToFirstPosition(
            conv6.id,
            draft.facebook[draft.conversationStatus]
          );
        }

        return draft;
      case getFacebookConversationsSuccessAction.type:
        conversationsAdapter.addMany(action.active, draft.facebook.active);
        return draft;
      case getFacebookMessagesSuccessAction.type:
        messagesAdapter.addMany(action.messages, draft.facebook.messages);
        return draft;
      case setSelectedWhatsappConversationAction.type:
        if (draft.whatsapp.selected?.id === action.id || !action.id) {
          draft.whatsapp.selected = null;
        } else {
          const conv9 =
            draft.whatsapp[draft.conversationStatus].entities[action.id];
          draft.whatsapp.selected = conv9;

          conversationsAdapter.updateOne(
            { ...conv9, unreadCount: 0 },
            draft.whatsapp[draft.conversationStatus]
          );
        }
        return draft;
      case addWhatsappMessageAction.type:
        let conv7 =
          draft.whatsapp[draft.conversationStatus].entities[action.id];

        if (conv7) {
          if (draft.whatsapp.selected?.id === conv7.id) {
            messagesAdapter.setOne(action.message, draft.whatsapp.messages);
          } else {
            conv7 = { ...conv7, unreadCount: conv7.unreadCount + 1 };
          }

          conversationsAdapter.updateOne(
            {
              ...conv7,
              snippet: action.message.message,
              messageType: action.message.type,
              timestamp: action.message.timestamp,
            },
            draft.whatsapp[draft.conversationStatus]
          );
          conversationsAdapter.moveToFirstPosition(
            conv7.id,
            draft.whatsapp[draft.conversationStatus]
          );
        }

        return draft;
      case addWhatsappConversationAction.type:
        conversationsAdapter.setOne(
          action.conversation,
          draft.whatsapp[draft.conversationStatus]
        );
        return draft;
      case postWhatsappMessageSuccessAction.type:
        let conv8 =
          draft.whatsapp[draft.conversationStatus].entities[action.id];

        if (conv8) {
          if (draft.whatsapp.selected?.id === conv8.id) {
            messagesAdapter.setOne(action.message, draft.whatsapp.messages);
          } else {
            conv8 = { ...conv8, unreadCount: conv8.unreadCount + 1 };
          }

          conversationsAdapter.updateOne(
            {
              ...conv8,
              snippet: action.message.message,
              messageType: action.message.type,
              timestamp: action.message.timestamp,
            },
            draft.whatsapp[draft.conversationStatus]
          );
          conversationsAdapter.moveToFirstPosition(
            conv8.id,
            draft.whatsapp[draft.conversationStatus]
          );
        }

        return draft;

      case getWhatsappConversationsSuccessAction.type:
        conversationsAdapter.addMany(action.inTrash, draft.whatsapp.inTrash);
        conversationsAdapter.addMany(action.archived, draft.whatsapp.archived);
        conversationsAdapter.addMany(action.active, draft.whatsapp.active);
        return draft;
      case getWhatsappMessagesSuccessAction.type:
        messagesAdapter.addMany(action.messages, draft.whatsapp.messages);
        return draft;
      case resetAction.type:
        return initialState;
      case selectConversationStatusToShow.type:
        draft.conversationStatus = action.conversationStatus;
        return draft;
      case syncMetaConversationWithContactAction.type:
        draft[action.social].selected!.participants[0] = action.contact;
        let conv9 =
          draft[action.social][draft.conversationStatus].entities[
            draft[action.social].selected!.id
          ];
        conv9.participants[0] = action.contact;
        conversationsAdapter.updateOne(
          {
            ...conv9,
          },
          draft[action.social][draft.conversationStatus]
        );
        return draft;
      case getInstagramConversationsInitAction.type:
      case getInstagramConversationsFailureAction.type:
      case getInstagramMessagesFailureAction.type:
      case postInstagramMessageInitAction.type:
      case postInstagramMessageFailureAction.type:
      case getFacebookConversationsInitAction.type:
      case getFacebookConversationsFailureAction.type:
      case getFacebookMessagesFailureAction.type:
      case postFacebookMessageInitAction.type:
      case postFacebookMessageFailureAction.type:
      case postFacebookSeenMessageInitAction.type:
      case postFacebookSeenMessageSuccessAction.type:
      case postFacebookSeenMessageFailureAction.type:
        return draft;
      default:
        return draft;
    }
  }
);
