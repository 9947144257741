import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { WebChat } from "../interfaces/api/get-web-chat";
import { WebMessage } from "../interfaces/api/get-messages";
import { Avatar } from "../interfaces/avatar";
import { Attachment } from "../interfaces/attachment/attachment.interface";

import { ConversationStatus } from "../../socialChat/types/conversation-status.types";
import { Conversation } from "../../socialChat/interfaces/conversations.interface";

export const setChatAction = createAction(
  "[Conversations] Set Chat",
  props<{ chat: WebChat }>()
);

export const setSelectedConversationAction = createAction(
  "[Conversations] Select Conversation",
  props<{ id: string | null }>()
);

export const addWebMessageAction = createAction(
  "[Conversations] Set new Message",
  props<{
    message: WebMessage;
    id: string;
  }>()
);

export const setAvatarAction = createAction(
  "[Conversations] Set Avatar",
  props<{ avatar: Avatar | null }>()
);

export const addConversationAction = createAction(
  "[Conversations] Add Conversation",
  props<{
    conversation: Conversation;
  }>()
);
export const removeConversationAndUpdateById = createAction(
  "[Conversations] remove Conversation And Set New Conversations",
  props<{
    conversationId: string;
    newStatus: ConversationStatus;
  }>()
);

export const setAttachmentAction = createAction(
  "[Conversations] Add Attachment To Staging Area",
  props<{ attachments: Attachment[] }>()
);
export const removeAttachmentFromStageAreaAction = createAction(
  "[Conversations] Remove Attachment From Stage Area",
  props<{ attachmentId: string }>()
);
export const changeConversationStatusAction = createAction(
  "[Conversations] Change conversation status",
  props<{ conversation: Conversation; conversationStatus: string }>()
);
const actions = joinActions({
  setChatAction,
  setSelectedConversationAction,
  addWebMessageAction,
  setAvatarAction,
  addConversationAction,
  setAttachmentAction,
  removeAttachmentFromStageAreaAction,
  changeConversationStatusAction,
  removeConversationAndUpdateById,
});

export type ConversationsAction = typeof actions;
