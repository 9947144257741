export const CUSTOM_FIELD = "CUSTOM_FIELD";
export const CARD_TAGS = "CARD_TAGS";
export const CARD_PRODUCT = "CARD_PRODUCT";
export const PRODUCT_PRICE = "PRODUCT_PRICE";
export const CONTACT_TAGS = "CONTACT_TAGS";
export const CONTACT_STATES = "CONTACT_STATES";
export const ENTERPRISE_CONTACT_ASSIGNMENT = "ENTERPRISE_CONTACT_ASSIGNMENT";
export const AUTOMATION_CONDITION = "AUTOMATION_CONDITION";
export const AUTOMATION_ACTION = "AUTOMATION_ACTION";
export const TIME_AVAILABILITY = "TIME_AVAILABILITY";
export const COMMENT_TYPES = "COMMENT_TYPES";
