import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useState } from "react";
import { Label } from "reactstrap";
import { useIntl } from "react-intl";
import { Activity, Comment, Email, Event, Quote } from "../../../../cores/boards/interfaces/api/get-card";
import { Task } from "../../../../cores/tasks/interfaces/api/get-task";
import CommentCard from "./CommentCard";
import CommentInput from "./CommentInput";
import { TaskRow } from "./TaskRow";
import _ from "lodash";
import EmailCard from "./EmailCard";
import EventCard from "./EventCard";
import ActivityCard from "./ActivityCard";
import QuoteCard from "./QuoteCard";

interface Props {
  board: string;
  comments: Comment[];
  emails: Email[];
  tasks: Task[];
  events: Event[];
  quotes: Quote[];
  activities: Activity[];
  onSubmit: (arg0: string, arg1: number, arg2: string[]) => void;
}

export default function ActivityContainer(props: Props) {
  const intl = useIntl();
  const { board, comments, emails, tasks, events, quotes, activities, onSubmit } = props;
  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = useCallback(() => {
    setShowDetails(!showDetails);
  }, [showDetails]);

  const activitiesFiltered = useMemo(() => {
    const com = comments.map((comment) => ({ ...comment, object: "comment" }));
    const ema = emails.map((email) => ({ ...email, object: "email" }));
    const eve = events.map((event) => ({ ...event, object: "event" }));
    const quo = quotes.map((quote) => ({ ...quote, object: "quote" }));
    let details = [];
    
    if (showDetails) {
      const act = activities.map((activity) => ({ ...activity, object: "activity" }));
      details = [...com, ...ema, ...eve, ...quo, ...act];
    } else {
      details = [...com, ...ema, ...eve, ...quo]
    }

    return _.orderBy(details, "createdAt", "desc");
  }, [emails, comments, events, quotes, activities, showDetails]);

  const showActivity = useCallback((activity) => {
    switch(activity.object) {
      case "comment":
        return <CommentCard 
          key={activity.id}
          board={board}
          comment={activity as Comment}
        />
      case "email":
        return <EmailCard key={activity.id} email={activity as Email} />
      case "event":
        return <EventCard
          key={activity.id}
          board={board}
          event={activity as Event}
        />
      case "activity":
        return <ActivityCard
          key={activity.id}
          activity={activity as Activity}
        />
      case "quote":
        return <QuoteCard
          key={activity.id}
          quote={activity as Quote}
        />
    }
  }, [board]);

  return (
    <div className="py-2">
      <div className="w-100">
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon
              icon={["fas", "comment-alt"]}
              size="1x"
              className="mr-2"
              color="gray"
            />
            <Label forid="comment" className="font-weight-bold">
              {intl.formatMessage({ id: "app.activity.activity" })} 
            </Label>
          </div>
          <Label className="pointer" onClick={toggleShowDetails}>
            {showDetails
              ? intl.formatMessage({ id: "app.opportunity.activity.hide" })
              : intl.formatMessage({ id: "app.opportunity.activity.show" })
            }
          </Label>
        </div>

        {tasks.map((task) => (
          <TaskRow key={task.id} task={task} board={board} />
        ))}

        <CommentInput onSubmit={onSubmit} board={board} />
        {activitiesFiltered.map((activity) => (
          showActivity(activity)
        ))}
      </div>
    </div>
  );
}
