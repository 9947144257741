import React, { useCallback, useEffect, useMemo } from "react";
import {
  OFFICE365_GET_LOADING,
  OFFICE365_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import SocialCard from "../SocialCard";
import { Office365Icon } from "./Office365Icon";
import { useOffice365Core } from "../../../../cores/office365";
import { Button } from "reactstrap";

export default function Office365BindCard() {
  const intl = useIntl();
  const { get, settings, unbind, login } = useOffice365Core();
  const { company, getMeSocialSettings } = useMeCore();
  const { isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get, login]);

  const remainingBindDays = useMemo(
    () =>
      settings?.expiresIn ? daysRemainingFromToday(settings?.expiresIn) : null,
    [settings]
  );

  const isActive = useMemo(
    (): boolean => !!settings?.email,
    [settings]
  );

  const handleBind = useCallback(async () => {
    const loginUrl = await login(company!.id);
    if (loginUrl) window.open(loginUrl, "_self");
  }, [company, login]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, false, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Office365,
      name: SocialProviderName.Office365,
      icon: <Office365Icon size="6x" active={isActive} />,
      displayName: settings?.name,
      avatar: settings?.avatarUrl,
      email: settings?.email
    }),
    [settings, isActive]
  );

  return (
    <SocialCard
      social={socialProvider}
      bindLoading={isLoading(OFFICE365_SYNC_LOADING)}
      getLoading={isLoading(OFFICE365_GET_LOADING)}
      showSynchronizer={false}
      remainingBindDays={remainingBindDays}
    >
      <div className="w-100 px-2">
        <Button
          className="w-100 mx-auto"
          color="primary"
          outline={isActive}
          onClick={!isActive ? handleBind : handleUnbind}
        >
          {!isActive
            ? intl.formatMessage({ id: "app.home.syncUp" })
            : intl.formatMessage({ id: "app.home.disconnect" })}
        </Button>
      </div>
    </SocialCard>
  );
}
