import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Form } from "reactstrap";
import { OPENAI_SYNC_LOADING } from "../../../../constants/loading.constant";
import { OPENAI_SIDE_MODAL_RIGHT } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { useOpenAiCore } from "../../../../cores/openAi";
import { InputWembii } from "../../InputWembii";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";

export default function OpenAiToken() {
  const intl = useIntl();
  const { bind } = useOpenAiCore();
  const { company } = useMeCore();
  const { turnOffModal } = useGlobalCore();

  const [name, setName] = useState('');
  const [token, setToken] = useState('');

  const disabled = useMemo(() =>
    !name || !token
  , [name, token]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      bind(
        company?.id!,
        name,
        token,
        () => {
          turnOffModal(OPENAI_SIDE_MODAL_RIGHT);
        }
      )
    },
    [company, name, token, bind, turnOffModal]
  );

  return (
    <div className="p-4 overflow-auto">
      <h2 className="h2 text-center mb-4">
        {intl.formatMessage({ id: "app.social.openai.title" })}
      </h2>
      
      <Form onSubmit={handleSubmit} className="px-4">
        <InputWembii
          type="text"
          name="name"
          label={intl.formatMessage({ id: "app.social.openai.name" })}
          id="name"
          required
          placeholder={intl.formatMessage({ id: "app.social.openai.name" })}
          value={name}
          onChange={setName}
        />
        <InputWembii
          type="text"
          name="token"
          label={intl.formatMessage({ id: "app.social.openai.token" })}
          id="token"
          required
          placeholder={intl.formatMessage({ id: "app.social.openai.token" })}
          value={token}
          onChange={setToken}
        />

        <div className="text-center">
          {intl.formatMessage({ id: "app.social.openai.message" })}
          <a href="https://platform.openai.com/account/api-keys" target="_blank" rel="noreferrer">https://platform.openai.com/account/api-keys</a>
        </div>

        <div className="d-flex flex-colum justify-content-center mt-4">
          <LoadingSpinnerWembii
            id={OPENAI_SYNC_LOADING}
          >
            <Button disabled={disabled} color="primary" className="ml-1">
              {intl.formatMessage({ id: "app.social.smtp.sync" })}
            </Button>
          </LoadingSpinnerWembii>
        </div>
      </Form>
    </div>
  );
}
