import { WHATSAPP_ENVIROMENTS } from '../config';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

export async function launchWhatsAppSignup(callback: (res: any) => void) {
  const auth0Options = {
    response_type: "token,signed_request,graph_domain",

    //TODO: REDIRECCIONAR A /social/whatsapp/bind así queda acoplado al standard

    redirect_uri: WHATSAPP_ENVIROMENTS.redirectUrl,
    version: "v17.0",
    origin: 1,
    scope: "whatsapp_business_management, whatsapp_business_messaging",
    sdk: "joey",
    extras: {
      feature: "whatsapp_embedded_signup",
      version: 2,
      setup: {
        preVerifiedPhone: { ids: [] },
      },
    },
  };

  window.FB.login(callback, auth0Options);
}
