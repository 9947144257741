import React, { useCallback, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import { InputWembii } from "../../InputWembii";
import { useIntl } from "react-intl";
import CurrencySelector from "../CurrencySelector";
import { LabelWembii } from "../../LabelWembii";
import { useBoardCore } from "../../../../cores/boards";
import { useMeCore } from "../../../../cores/me";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { BOARD_CREATE_CONFIG, BOARD_USERS_MODAL } from "../../../../constants/modal.constant";
import UserList from "./UserList";
import { UserBoardRole, UserBoardRoleLabels, UserBoardRoleOptions } from "../../../../cores/boards/constants/user-board-role";
import Avatar from "../../Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_DANGER } from "../../../../constants/theme";
import { UserRoles } from "../../../../cores/boards/interfaces/api/get-board";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { getFirstLetters } from "../../../utils/stringUtils";

interface Props {
  onSuccess?: () => void;
  type: string;
}

export default function CreateBoard(props: Props) {
  const intl = useIntl();
  const { onSuccess, type } = props;
  const { turnOffModal, turnOnModal } = useGlobalCore();
  const { postBoard } = useBoardCore();
  const { company, user } = useMeCore();
  const [name, setName] = useState("");
  const [color, setColor] = useState("#fff");
  const [currency, setCurrency] = useState("CLP");
  const [userRoles, setUserRoles] = useState<UserRoles[]>([]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    postBoard(
      company?.id!,
      name,
      color,
      currency,
      type,
      userRoles,
      onSuccess
    );
    turnOffModal(BOARD_CREATE_CONFIG);
  };

  const disabled = useCallback(() => 
    !name || !currency || !color, 
    [name, currency, color]
  );

  const getRole = useCallback((userRole: UserRoles) => 
    userRole.owner
      ? UserBoardRole.Owner
      : userRole.admin
        ? UserBoardRole.Admin
        : UserBoardRole.Member
  , []);

  const changeUserRole = useCallback((role: string, index: number) => {
    const newRoles = [...userRoles];
    newRoles[index] = {
      ...newRoles[index],
      admin: false,
      owner: false,
      member: false,
      [`${role}`]: true
    };
    setUserRoles(newRoles);
  }, [userRoles]);

  const removeUserRole = useCallback((userRole: UserRoles) => {
    setUserRoles(userRoles.filter((role) => role !== userRole));
  }, [userRoles]);

  const openAssignmentUsers = useCallback(() => {
    let users = userRoles.map((role) => role.user);
    users = [
      ...users, {
        id: user?.id!,
        lastName: user?.lastName!,
        firstName: user?.firstName!,
        status: user?.status!,
        avatarUrl: user?.avatarUrl!,
        email: user?.email!,
        phoneNumber: user?.phoneNumber!
      }
    ];
    turnOnModal(
      BOARD_USERS_MODAL,
      <UserList assignments={users} onAssign={(user) => {
        setUserRoles([
          ...userRoles,
          {
            id: "",
            owner: false,
            admin: false,
            member: true,
            user
          }
        ]);
        turnOffModal(BOARD_USERS_MODAL);
      }} />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    user,
    userRoles
  ]);

  return (
    <Container className="h-100" fluid>
      <div className="d-flex flex-column h-100 py-5">
        <h2 className="h2 text-center mb-4">
          {intl.formatMessage({ id: "app.activity.createBoard" })}
        </h2>
        <Form className="px-2" onSubmit={onFormSubmit}>
          <InputWembii
            type="text"
            name="boardName"
            id="boardName"
            label={intl.formatMessage({ id: "app.activity.boardName" })}
            value={name}
            required
            onChange={(v) => setName(v)}
          />

          {type === BoardType.BUSINESS && (
            <>
              <LabelWembii required={true}>
                {intl.formatMessage({ id: "app.crmBusiness.defaultCurrency" })}
              </LabelWembii>
              <CurrencySelector
                currency={currency}
                onSubmit={setCurrency}
                width="100%"
              />
            </>
          )}

          {type === BoardType.ACTIVITY && (
            <div className="d-flex align-items-end mb-4">
              <div 
                style={{ backgroundColor: color, height: 40, width: 70 }}
                className="rounded-pill shadow mr-2"
              />
              <InputWembii
                type="color"
                name="color"
                id="color"
                label={intl.formatMessage({ id: "app.activity.boardColor" })}
                mb={false}
                value={color}
                autoComplete="off"
                required
                onChange={(v) => setColor(v)}
              />
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mt-3">
            <LabelWembii className="">
              {intl.formatMessage({ id: "app.crmBusiness.users" })}
            </LabelWembii>
            <Button
              className="whiteButton border m-0"
              onClick={openAssignmentUsers}
            >
              Add user
            </Button>
          </div>
          <Row className="mb-2">
            <Col className="d-flex align-items-center">
              <Avatar
                className="text-dark-50 pointer mr-2"
                image={user?.avatarUrl}
                label={getFirstLetters(`${user?.lastName} ${user?.firstName}`)}
                icon={
                  <FontAwesomeIcon
                    icon={["fas", "user"]}
                    size="1x"
                  />
                }
              />
              {user?.firstName} {user?.lastName}
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              {intl.formatMessage({ id: UserBoardRoleLabels[UserBoardRole.Owner] })}
            </Col>
          </Row>
          {userRoles.map((userRole, index) => (
            <Row className="mb-2">
              <Col className="d-flex align-items-center">
                <Avatar
                  className="text-dark-50 pointer mr-2"
                  image={userRole.user.avatarUrl}
                  label={getFirstLetters(`${userRole.user.lastName} ${userRole.user.firstName}`)}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "user"]}
                      size="1x"
                    />
                  }
                />
                {userRole.user.firstName} {userRole.user.lastName}
              </Col>
              <Col className="d-flex align-items-center">
                <InputWembii
                  type="select"
                  className="w-100"
                  mb={false}
                  value={getRole(userRole)}
                  onChange={(v) => changeUserRole(v, index)}
                >
                  {UserBoardRoleOptions.map(({ label, value }, index) => (
                    <option key={index} value={value}>
                      {intl.formatMessage({ id: label })}
                    </option>
                  ))}
                </InputWembii>
                <FontAwesomeIcon
                  icon={["fas", "trash"]}
                  className="m-3 pointer"
                  color={COLOR_DANGER}
                  onClick={() => removeUserRole(userRole)}
                />
              </Col>
            </Row>
          ))}
          
          <div className="d-flex justify-content-center mt-3">
            <Button color="primary" disabled={disabled()}>
              {intl.formatMessage({ id: "app.activity.accept" })}
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
}
