import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Sign } from "../interfaces/api/get-meSign";

export const selectCompanyAction = createAction(
  "[Me] Select Company",
  props<{ companyId: string }>()
);

export const deselectCompanyAction = createAction(
  "[Me] Deselect Company"
);

export const removeCompanyAction = createAction(
  "[Me] Remove Company",
  props<{ companyId: string }>()
);

export const updateReportsCompanyAction = createAction(
  "[Me] Update Report Selected Company",
  props<{ amount: number }>()
);

export const setSignAction = createAction(
  "[Me] Set Sign",
  props<{ sign: Sign | null }>()
);

const actions = joinActions({
  selectCompanyAction,
  updateReportsCompanyAction,
  removeCompanyAction,
  deselectCompanyAction,
  setSignAction,
});

export type MeAction = typeof actions;
