import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CALENDAR_TIME_LOADING,
  EVENTS_GET_LOADING,
  EVENT_CREATION_LOADING,
  EVENT_GET_LOADING,
  EVENT_POST_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { eventApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  deleteCalendarTimeFailureAction,
  deleteCalendarTimeInitAction,
  deleteCalendarTimeSuccessAction,
  deleteCommentFailureAction,
  deleteCommentInitAction,
  deleteCommentSuccessAction,
  deleteContactInputFailureAction,
  deleteContactInputInitAction,
  deleteContactInputSuccessAction,
  deleteEventFailureAction,
  deleteEventInitAction,
  deleteEventSuccessAction,
  getAvailableTimeFailureAction,
  getAvailableTimeInitAction,
  getAvailableTimeSuccessAction,
  getCalendarFailureAction,
  getCalendarInitAction,
  getCalendarSuccessAction,
  getEventFailureAction,
  getEventInitAction,
  getEventsFailureAction,
  getEventsInitAction,
  getEventsSuccessAction,
  getEventSuccessAction,
  getPublicCalendarFailureAction,
  getPublicCalendarInitAction,
  getPublicCalendarSuccessAction,
  postCalendarTimeFailureAction,
  postCalendarTimeInitAction,
  postCalendarTimeSuccessAction,
  postCommentFailureAction,
  postCommentInitAction,
  postCommentSuccessAction,
  postContactInputFailureAction,
  postContactInputInitAction,
  postContactInputSuccessAction,
  postEventFailureAction,
  postEventInitAction,
  postEventSuccessAction,
  postPublicEventFailureAction,
  postPublicEventInitAction,
  postPublicEventSuccessAction,
  putCalendarFailureAction,
  putCalendarInitAction,
  putCalendarSuccessAction,
  putCommentFailureAction,
  putCommentInitAction,
  putCommentSuccessAction,
} from "./store/api-actions";
import {
  calendarSelector,
  commentsSelector,
  descriptionSelector,
  errorCodeSelector,
  errorMessageSelector,
  eventSelector,
  eventsSelector,
  hoursSelector,
  inputsSelector,
  publicCalendarSelector,
  timeSelector,
  timezoneSelector,
  titleSelector,
} from "./store/selector";
import { PostEventRequest } from "./interface/api/post-event";
import {
  setCalendarTimezoneAction,
  setCalendarTitleAction,
  setEventDescriptionAction,
} from "./store/actions";
import { PostPublicEventRequest } from "./interface/api/post-public-event";
import { PutCalendarRequest } from "./interface/api/put-calendar";
import { Errors } from "../../constants/errors";
import { PostCalendarTimeRequest } from "./interface/api/post-calendar-time";
import { PostContactInputRequest } from "./interface/api/post-contact-input";

export function useEventCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const description = useSelector(descriptionSelector);
  const title = useSelector(titleSelector);
  const timezone = useSelector(timezoneSelector);
  const events = useSelector(eventsSelector);
  const event = useSelector(eventSelector);
  const time = useSelector(timeSelector);
  const calendar = useSelector(calendarSelector);
  const publicCalendar = useSelector(publicCalendarSelector);
  const hours = useSelector(hoursSelector);
  const comments = useSelector(commentsSelector);
  const inputs = useSelector(inputsSelector);
  const errorCode = useSelector(errorCodeSelector);
  const errorMessage = useSelector(errorMessageSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const postEvent = useCallback(
    async (
      companyId: string,
      event: PostEventRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postEventInitAction());
        turnOnLoading(EVENT_POST_LOADING);
        const response = await eventApiService.postEvent(companyId, event);
        turnOffLoading(EVENT_POST_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess53" }));
        dispatch(postEventSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(EVENT_POST_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error78" }));
        dispatch(postEventFailureAction());
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const deleteEvent = useCallback(
    async (companyId: string, event: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteEventInitAction());
        const response = await eventApiService.deleteEvent(companyId, event);
        toast.success(intl.formatMessage({ id: "app.toast.sucess54" }));
        dispatch(deleteEventSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error79" }));
        dispatch(deleteEventFailureAction());
      }
    },
    [dispatch, intl]
  );

  const getEvents = useCallback(
    async (companyId: string, startDate: string, endDate: string) => {
      try {
        dispatch(getEventsInitAction());
        turnOnLoading(EVENTS_GET_LOADING);
        const response = await eventApiService.getEvents(
          companyId,
          startDate,
          endDate
        );
        turnOffLoading(EVENTS_GET_LOADING, 500);
        dispatch(getEventsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        turnOffLoading(EVENTS_GET_LOADING);
        dispatch(getEventsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  const getEvent = useCallback(
    async (companyId: string, eventId: string) => {
      try {
        dispatch(getEventInitAction());
        turnOnLoading(EVENT_GET_LOADING);
        const response = await eventApiService.getEvent(companyId, eventId);
        turnOffLoading(EVENT_GET_LOADING, 500);
        dispatch(getEventSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        turnOffLoading(EVENT_GET_LOADING);
        dispatch(getEventFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  const getGoogleEvent = useCallback(
    async (companyId: string, eventId: string) => {
      try {
        dispatch(getEventInitAction());
        turnOnLoading(EVENT_GET_LOADING);
        const response = await eventApiService.getGoogleEvent(
          companyId,
          eventId
        );
        turnOffLoading(EVENT_GET_LOADING, 500);
        dispatch(getEventSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        turnOffLoading(EVENT_GET_LOADING);
        dispatch(getEventFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  const deleteGoogleEvent = useCallback(
    async (companyId: string, event: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteEventInitAction());
        const response = await eventApiService.deleteGoogleEvent(
          companyId,
          event
        );
        toast.success(intl.formatMessage({ id: "app.toast.sucess54" }));
        dispatch(deleteEventSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error79" }));
        dispatch(deleteEventFailureAction());
      }
    },
    [dispatch, intl]
  );

  const getAvailableTime = useCallback(
    async (
      calendarId: string,
      date: string,
      timezone: string,
      duration: number
    ) => {
      try {
        dispatch(getAvailableTimeInitAction());
        turnOnLoading(CALENDAR_TIME_LOADING);
        const response = await eventApiService.getAvailableTime(
          calendarId,
          date,
          timezone,
          duration
        );
        turnOffLoading(CALENDAR_TIME_LOADING, 500);
        dispatch(getAvailableTimeSuccessAction(response));
      } catch (error: any) {
        turnOffLoading(CALENDAR_TIME_LOADING);
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.NoExistResource:
              dispatch(
                getAvailableTimeFailureAction({
                  errorCode: Errors.NoExistResource,
                  errorMessage: message,
                })
              );
              break;
            default:
              console.error(error, code);
          }
        } else {
          console.error(error);
        }
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  const getCalendar = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getCalendarInitAction());
        const response = await eventApiService.getCalendar(companyId);
        dispatch(getCalendarSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getCalendarFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const getPublicCalendar = useCallback(
    async (calendarId: string) => {
      try {
        dispatch(getPublicCalendarInitAction());
        const response = await eventApiService.getPublicCalendar(calendarId);
        dispatch(getPublicCalendarSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getPublicCalendarFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const putCalendar = useCallback(
    async (
      companyId: string,
      calendarId: string,
      body: PutCalendarRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCalendarInitAction());
        const response = await eventApiService.putCalendar(
          companyId,
          calendarId,
          body
        );
        dispatch(putCalendarSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.DuplicatedData:
              dispatch(
                putCalendarFailureAction({
                  errorCode: Errors.DuplicatedData,
                  errorMessage: message,
                })
              );
              break;
            default:
              console.error(error, code);
          }
        } else {
          console.error(error);
        }
      }
    },
    [dispatch]
  );

  const refreshCalendarId = useCallback(
    async (companyId: string, calendarId: string) => {
      try {
        dispatch(putCalendarInitAction());
        const response = await eventApiService.refreshCalendarId(
          companyId,
          calendarId
        );
        dispatch(putCalendarSuccessAction(response));
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string = JSON.parse(error.request.response).message;
          switch (code) {
            case Errors.DuplicatedData:
              dispatch(
                putCalendarFailureAction({
                  errorCode: Errors.DuplicatedData,
                  errorMessage: message,
                })
              );
              break;
            default:
              console.error(error, code);
          }
        } else {
          console.error(error);
        }
      }
    },
    [dispatch]
  );

  const postPublicEvent = useCallback(
    async (
      calendarId: string,
      event: PostPublicEventRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postPublicEventInitAction());
        turnOnLoading(EVENT_CREATION_LOADING);
        await eventApiService.postPublicEvent(calendarId, event);
        turnOffLoading(EVENT_CREATION_LOADING, 500);
        toast.success("Se creo una reunion correctamente");
        dispatch(postPublicEventSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(EVENT_CREATION_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error78" }));
        dispatch(postPublicEventFailureAction());
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const postCalendarTime = useCallback(
    async (
      companyId: string,
      calendarId: string,
      time: PostCalendarTimeRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCalendarTimeInitAction());
        const response = await eventApiService.postCalendarTime(
          companyId,
          calendarId,
          time
        );
        dispatch(postCalendarTimeSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postCalendarTimeFailureAction());
      }
    },
    [dispatch]
  );

  const deleteCalendarTime = useCallback(
    async (
      companyId: string,
      calendarId: string,
      id: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteCalendarTimeInitAction());
        const response = await eventApiService.deleteCalendarTime(
          companyId,
          calendarId,
          id
        );
        dispatch(deleteCalendarTimeSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCalendarTimeFailureAction());
      }
    },
    [dispatch]
  );

  const postContactInput = useCallback(
    async (
      companyId: string,
      calendarId: string,
      input: PostContactInputRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postContactInputInitAction());
        const response = await eventApiService.postContactInput(
          companyId,
          calendarId,
          input
        );
        dispatch(postContactInputSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postContactInputFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const deleteContactInput = useCallback(
    async (
      companyId: string,
      calendarId: string,
      inputId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteContactInputInitAction());
        const response = await eventApiService.deleteContactInput(
          companyId,
          calendarId,
          inputId
        );
        dispatch(deleteContactInputSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteContactInputFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const postComment = useCallback(
    async (
      companyId: string,
      eventId: string,
      text: string,
      from: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCommentInitAction());
        const response = await eventApiService.postComment(
          companyId,
          eventId,
          text,
          from
        );
        dispatch(postCommentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postCommentFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const putComment = useCallback(
    async (
      companyId: string,
      eventId: string,
      commentId: string,
      text: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCommentInitAction());
        const response = await eventApiService.putComment(
          companyId,
          eventId,
          commentId,
          text
        );
        dispatch(putCommentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putCommentFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const deleteComment = useCallback(
    async (
      companyId: string,
      eventId: string,
      commentId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteCommentInitAction());
        const response = await eventApiService.deleteComment(
          companyId,
          eventId,
          commentId
        );
        dispatch(deleteCommentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCommentFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const setDescription = useCallback(
    (description: string) => {
      dispatch(setEventDescriptionAction({ description }));
    },
    [dispatch]
  );

  const setTitle = useCallback(
    (title: string) => {
      dispatch(setCalendarTitleAction({ title }));
    },
    [dispatch]
  );

  const setTimezone = useCallback(
    (timezone: string) => {
      dispatch(setCalendarTimezoneAction({ timezone }));
    },
    [dispatch]
  );

  return {
    description,
    title,
    timezone,
    event,
    events,
    comments,
    time,
    calendar,
    hours,
    inputs,
    errorCode,
    publicCalendar,
    errorMessage,
    setDescription,
    setTitle,
    setTimezone,
    postEvent,
    getEvent,
    getGoogleEvent,
    getEvents,
    getAvailableTime,
    postPublicEvent,
    deleteEvent,
    getPublicCalendar,
    deleteGoogleEvent,
    getCalendar,
    putCalendar,
    refreshCalendarId,
    postCalendarTime,
    deleteCalendarTime,
    postContactInput,
    deleteContactInput,
    postComment,
    putComment,
    deleteComment,
  };
}
