import QueryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import {
  AUTOMATION_LOADING,
  BOARD_CARD_LOADING,
  CONTACTS_EXPORT_LOADING,
  CONTACTS_IMPORT_LOADING,
  CONTACTS_LOADING,
} from "../../constants/loading.constant";
import {
  CONTACT_EXPAND_CREATION_FORM,
  CONTACT_IMPORT_EXPORT,
  CONTACT_TAGS_FORM,
  CONTACT_CONFIG_FORM,
  CONTACT_DELETE_MODAL,
  AUTOMATION_CREATE_FORM,
  AUTOMATION_EDIT_FORM,
  DELETE_AUTOMATION_MODAL,
  CONTACT_STATES_FORM,
  BOARD_CARD_MODAL,
  CONTACT_MASS_ASSIGNMENT_MODAL,
  CONTACT_FILTER_MODAL,
} from "../../constants/modal.constant";
import { useContactCore } from "../../cores/contacts";
import { useGlobalCore } from "../../cores/globals";
import { useMailchimpCore } from "../../cores/mailchimp";
import { useMeCore } from "../../cores/me";
import { ContactRow } from "../component/contacts/ContactRow";
import { ContactsBar } from "../component/contacts/ContactsBar";
import ImportContacts from "../component/contacts/modal/ImportContacts";
import { NoData } from "../component/NoData";
import { UpgradePlanButton } from "../component/plan/UpgradePlanButton";
import { MailchimpIcon } from "../component/social/Mailchimp/MailchimpIcon";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";
import { useRouterCore } from "../../cores/router";
import { CSVLink } from "react-csv";
import moment from "moment";
import GmailButton from "../component/gmail/GmailButton";
import { CheckboxWembii } from "../component/CheckboxWembii";
import { useLocation } from "react-router-dom";
import ContactTags from "../component/contacts/modal/ContactTags";
import Config from "../component/contacts/modal/Config";
import {
  AUTOMATION_ACTION,
  AUTOMATION_CONDITION,
  CONTACT_TAGS,
  CUSTOM_FIELD,
  CONTACT_STATES,
  CARD_PRODUCT,
} from "../../constants/internalModal.constant";
import AreYouSure from "../component/AreYouSure";
import TaskSideModals from "../component/tasks/TaskSideModals";
import CompanySideModals from "../component/contactCompanies/CompanySideModals";
import BoardSideModals from "../component/boards/BoardSideModals";
import EventSideModals from "../component/event/EventSideModals";
import EmailSideModals from "../component/gmail/EmailSideModals";
import ContactSideModals from "../component/contacts/ContactSideModals";
import { Position } from "../../cores/globals/interfaces/globalEntity";
import CreateAutomationForm from "../component/automations/modals/CreateAutomationForm";
import EditAutomationForm from "../component/automations/modals/EditAutomationForm";
import { AutomationTrigger } from "../../cores/automations/enum/AutomationTrigger.enum";
import { ContactSource } from "../../cores/contacts/enum/ContactSource.enum";
import CreateContactForm from "../component/contacts/modal/CreateContactForm";
import { utils, writeFileXLSX } from "xlsx";
import { ContactStateLabels } from "../../cores/contacts/interfaces/ContactState";
import ContactStates from "../component/contacts/modal/ContactStates";
import { Errors } from "../../constants/errors";
import { useBoardCore } from "../../cores/boards";
import UserList from "../component/contacts/modal/UserList";
import { LoadingSpinner } from "../component/LoadingSpinner";
import { PaginationWemmbii } from "../component/pagination/Pagination";

export const ContactsScreen = () => {
  const intl = useIntl();
  const {
    loadings,
    isModal,
    getModalVisibility,
    turnOffModal,
    turnOnModal,
    turnOffInternalModal,
    isLoading,
    getModalContent,
  } = useGlobalCore();
  const {
    company,
    user,
    companyRole,
    companyPlan,
    socialSettings,
    userSettings,
    isAdmin,
    isReseller,
  } = useMeCore();
  const {
    contacts,
    total,
    states,
    source: contactSource,
    contactFilters,
    customFields,
    selectedContacts,
    massAssignment,
    replaceAssignment,
    getExportContacts,
    getContactTags,
    getContactStates,
    getCustomFields,
    getContacts,
    deselectAllContacts,
    selectContact,
    setSource,
    deleteContacts,
    getContactConfig,
    setContactFilters,
    cleanAssigments,
    postMassAssignment,
  } = useContactCore();
  const { syncContacts } = useMailchimpCore();
  const { goToSocial } = useRouterCore();
  const { cleanCard, errorCode } = useBoardCore();
  const { search } = useLocation();
  const [exportData, setExportData] = useState<any[]>([]);
  const headers = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "app.contacts.name" }),
        key: intl.formatMessage({ id: "app.contacts.name" }),
      },
      {
        label: intl.formatMessage({ id: "app.contacts.email" }),
        key: intl.formatMessage({ id: "app.contacts.email" }),
      },
      {
        label: intl.formatMessage({ id: "app.contacts.phone" }),
        key: intl.formatMessage({ id: "app.contacts.phone" }),
      },
      { label: "Whatsapp", key: "Whatsapp" },
      {
        label: intl.formatMessage({ id: "app.contacts.origin" }),
        key: intl.formatMessage({ id: "app.contacts.origin" }),
      },
      {
        label: intl.formatMessage({ id: "app.contacts.state" }),
        key: intl.formatMessage({ id: "app.contacts.state" }),
      },
      {
        label: intl.formatMessage({ id: "app.contacts.createdAt" }),
        key: intl.formatMessage({ id: "app.contacts.createdAt" }),
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.title" }),
        key: intl.formatMessage({ id: "app.contacts.companies.title" }),
      },
      ...customFields.map((field) => ({ label: field.name, key: field.name })),
    ],
    [intl, customFields]
  );

  const ref = useRef<any>(null);

  useEffect(() => {
    if (
      !companyPlan?.rules.allowDatabase ||
      (!companyRole?.contacts &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  useEffect(() => {
    setContactFilters({
      page: 1,
      perPage: 10,
    });
  }, [setContactFilters]);

  useEffect(() => {
    const { source } = QueryString.parse(search);
    if (source !== contactSource) {
      setSource(source as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setSource]);

  const canSyncMailchimp = useMemo(
    () =>
      socialSettings &&
      socialSettings.mailchimp &&
      (companyRole?.owner || companyRole?.admin),
    [socialSettings, companyRole]
  );

  const onClickImportExportContacts = useCallback(() => {
    getContactTags(company?.id!);
    turnOnModal(CONTACT_IMPORT_EXPORT, true);
  }, [turnOnModal, getContactTags, company]);

  const onClickCreateContact = useCallback(() => {
    getContactTags(company?.id!);
    turnOnModal(
      CONTACT_EXPAND_CREATION_FORM,
      <CreateContactForm source={ContactSource.MANUAL} />,
      Position.LEFT
    );
  }, [turnOnModal, getContactTags, company]);

  const onClickConfig = useCallback(() => {
    getCustomFields(company?.id!);
    getContactConfig(company?.id!);
    turnOnModal(CONTACT_CONFIG_FORM, true);
  }, [turnOnModal, getCustomFields, getContactConfig, company]);

  const onClickExport = useCallback(
    async (extension: string) => {
      const contacts = await getExportContacts(company?.id!, contactFilters);
      if (contacts) {
        const data = contacts.map((contact) => ({
          [intl.formatMessage({ id: "app.contacts.name" })]: contact.name,
          [intl.formatMessage({ id: "app.contacts.email" })]: contact.email,
          [intl.formatMessage({ id: "app.contacts.phone" })]:
            contact.phoneNumber,
          Whatsapp: contact.whatsapp,
          [intl.formatMessage({ id: "app.contacts.origin" })]: contact.source,

          [intl.formatMessage({ id: "app.contacts.state" })]:
            contact.state < 100
              ? intl.formatMessage({ id: ContactStateLabels[contact.state] })
              : states.find((st) => +st.id === contact.state)?.name,
          [intl.formatMessage({ id: "app.contacts.createdAt" })]: moment(
            contact.createdAt
          ).format("DD/MM/YYYY"),
          [intl.formatMessage({ id: "app.contacts.companies.title" })]:
            contact.companies,
          ...contact.fields.reduce((prev, curr) => {
            return {
              ...prev,
              [curr.name]: curr.value,
            };
          }, {}),
          [intl.formatMessage({ id: "app.contacts.owners" })]:
            contact.owners.join(","),
        }));

        if (extension === "csv") {
          setExportData(data);
          ref.current.link.click();
        } else {
          const wb = utils.book_new();
          const ws = utils.json_to_sheet(data);

          utils.book_append_sheet(wb, ws);
          writeFileXLSX(
            wb,
            `Contacts_${company?.id!}_${moment().format("DD-MM-YYYY")}.xlsx`
          );
        }
      }
    },
    [getExportContacts, states, company, contactFilters, intl]
  );

  const onClickCSVExport = useCallback(() => {
    onClickExport("csv");
  }, [onClickExport]);

  const onClickExcelExport = useCallback(() => {
    onClickExport("xlsx");
  }, [onClickExport]);

  useEffect(() => {
    getContactTags(company?.id!);
    getContactStates(company?.id!);
    getCustomFields(company?.id!);
    getContactConfig(company?.id!);
  }, [
    getContactTags,
    getCustomFields,
    getContactConfig,
    getContactStates,
    company,
  ]);

  const handleSyncContacts = useCallback(() => {
    syncContacts(company?.id!);
  }, [syncContacts, company]);

  const isPageSelected = useMemo(() => {
    const canSelectContacts =
      isAdmin || companyRole?.owner
        ? contacts
        : contacts.filter(
          (contact) =>
            contact.owners.find((owner) => owner.id === user?.id) ||
            contact.owners.length === 0
        );

    return canSelectContacts.reduce(
      (prev, curr) =>
        prev && !!selectedContacts.find((contact) => contact.id === curr.id),
      true
    );
  }, [contacts, selectedContacts, user?.id, isAdmin, companyRole]);

  const toggleAllContacts = useCallback(() => {
    if (isPageSelected) {
      deselectAllContacts();
    } else {
      const canSelectContacts =
        isAdmin || companyRole?.owner
          ? contacts
          : contacts.filter(
            (addContact) =>
              !selectedContacts.find(
                (selectedContact) => addContact.id === selectedContact.id
              ) &&
              (addContact.owners.find((owner) => owner.id === user?.id) ||
                addContact.owners.length === 0)
          );
      canSelectContacts.forEach((contact) => {
        selectContact(contact);
      });
    }
  }, [
    deselectAllContacts,
    selectContact,
    isAdmin,
    companyRole,
    contacts,
    selectedContacts,
    isPageSelected,
    user?.id,
  ]);

  const deleteSelected = useCallback(() => {
    turnOnModal(
      CONTACT_DELETE_MODAL,
      <AreYouSure
        message={intl.formatMessage({ id: "app.contacts.delete.message" })}
        onSubmit={() => {
          const contacts = selectedContacts.map((contact) => contact.id);
          deleteContacts(company?.id!, contacts, () => {
            deselectAllContacts();
            getContacts(company?.id!, contactFilters);
          });

          turnOffModal(CONTACT_DELETE_MODAL);
        }}
        onClose={() => turnOffModal(CONTACT_DELETE_MODAL)}
      />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    deleteContacts,
    getContacts,
    deselectAllContacts,
    selectedContacts,
    company,
    contactFilters,
    intl,
  ]);

  const createAutomation = useCallback(() => {
    turnOnModal(AUTOMATION_CREATE_FORM, true);
  }, [turnOnModal]);

  const openMassAssignment = useCallback(() => {
    cleanAssigments();
    turnOnModal(CONTACT_MASS_ASSIGNMENT_MODAL, true);
  }, [turnOnModal, cleanAssigments]);

  const closeMassAssignment = useCallback(() => {
    if (selectedContacts.length && massAssignment.length) {
      const contacts = selectedContacts.map((contact) => contact.id);
      postMassAssignment(
        company?.id!,
        {
          contacts,
          users: massAssignment,
          replace: replaceAssignment,
        },
        () => {
          deselectAllContacts();
          getContacts(company?.id!, contactFilters);
        }
      );
    }
    turnOffModal(CONTACT_MASS_ASSIGNMENT_MODAL);
  }, [
    selectedContacts,
    massAssignment,
    replaceAssignment,
    company,
    contactFilters,
    turnOffModal,
    postMassAssignment,
    deselectAllContacts,
    getContacts,
  ]);

  const canExport = useMemo(
    () =>
      companyRole?.owner ||
      companyRole?.admin ||
      companyPlan?.rules.allowExportContact,
    [companyPlan, companyRole]
  );

  const canImport = useMemo(
    () => companyRole?.owner || companyRole?.admin,
    [companyRole]
  );

  const showGmailButton = useMemo(
    () =>
      socialSettings?.gmail ||
      userSettings?.gmail ||
      socialSettings?.smtp ||
      userSettings?.smtp ||
      socialSettings?.office365 ||
      userSettings?.office365,
    [socialSettings, userSettings]
  );
  const changeFilters = useCallback(
    (field) => {
      setContactFilters({ ...contactFilters, ...field });
    },
    [contactFilters, setContactFilters]
  );

  useEffect(() => {
    getContacts(company!.id, contactFilters);
  }, [getContacts, company, contactFilters]);

  return (
    <Layout>
      <Header2
        title={`${intl.formatMessage({ id: "app.contacts.title" })} (${total})`}
        rightChildren={
          <>
            <PaginationWemmbii
              className="d-none d-md-flex"
              hideTitle
              page={contactFilters.page}
              setPage={(page) => changeFilters({ page })}
              perPage={contactFilters.perPage}
              setPerPage={(perPage) => changeFilters({ perPage })}
              disabledPages={contacts.length < contactFilters.perPage}
            />
            {showGmailButton && (
              <GmailButton
                contacts={selectedContacts}
                classname="border-0 mr-2"
              />
            )}

            {canSyncMailchimp && (
              <Button
                className="mailchimpButton mr-2"
                onClick={handleSyncContacts}
              >
                <MailchimpIcon active={true} className="mr-1" />
                {intl.formatMessage({ id: "app.contacts.mailchimp.sync" })}
              </Button>
            )}
            {!canExport ? (
              <UpgradePlanButton />
            ) : (
              <div className="d-none d-md-flex ">
                <CSVLink
                  className="d-none "
                  headers={headers}
                  filename={`Contacts_${company?.id!}_${moment().format(
                    "DD-MM-YYYY"
                  )}.csv`}
                  data={exportData}
                  ref={ref}
                />
                <ButtonGroup className="mx-2">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      className="excelButton d-flex align-items-center"
                    >
                      <LoadingSpinner id={CONTACTS_EXPORT_LOADING} />
                      {intl.formatMessage({ id: "app.contacts.export" })}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={onClickCSVExport}>
                        <FontAwesomeIcon
                          icon={["fas", "file-csv"]}
                          size="1x"
                          className="mr-2"
                        />
                        CSV
                      </DropdownItem>
                      <DropdownItem onClick={onClickExcelExport}>
                        <FontAwesomeIcon
                          icon={["fas", "file-excel"]}
                          size="1x"
                          className="mr-2"
                        />
                        Excel
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ButtonGroup>
              </div>
            )}
            {canImport && (
              <Button
                className="excelButton mr-2 d-none d-md-flex"
                onClick={onClickImportExportContacts}
              >
                <FontAwesomeIcon
                  icon={["fas", "file-csv"]}
                  size="1x"
                  className="mr-1"
                />
                {intl.formatMessage({ id: "app.contacts.import" })}
              </Button>
            )}
            <Button className="whiteButton " onClick={onClickCreateContact}>
              {intl.formatMessage({ id: "app.contacts.createContact" })}
            </Button>
            <Button className="whiteButton ml-2" onClick={onClickConfig}>
              <FontAwesomeIcon icon={["fas", "gear"]} />
            </Button>
            <Button
              type="button"
              className="whiteButton ml-2"
              onClick={() =>
                turnOnModal(CONTACT_FILTER_MODAL, true, Position.RIGHT)
              }
            >
              <FontAwesomeIcon icon={["fas", "filter"]} />
            </Button>
          </>
        }
      />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <UpdateLoadingCard
                loadingId={CONTACTS_LOADING}
                text={intl.formatMessage({
                  id: "app.contacts.updatingContacts",
                })}
              />
              {!isLoading(CONTACTS_LOADING) && (
                <Container>
                  <div className="d-flex flex-column my-4  justify-content-between align-items-center">
                    <PaginationWemmbii
                      className="d-flex d-md-none"
                      hideTitle
                      page={contactFilters.page}
                      setPage={(page) => changeFilters({ page })}
                      perPage={contactFilters.perPage}
                      setPerPage={(perPage) => changeFilters({ perPage })}
                      disabledPages={contacts.length < contactFilters.perPage}
                    />
                    <div className="my-4">
                      <CheckboxWembii
                        className="ml-4 my-auto"
                        checked={isPageSelected}
                        label={
                          isPageSelected
                            ? intl.formatMessage({
                              id: "app.contacts.deselect",
                            })
                            : intl.formatMessage({ id: "app.contacts.select" })
                        }
                        onChange={toggleAllContacts}
                      />
                    </div>

                    {selectedContacts.length > 0 && (
                      <div className="d-flex my-4">
                        <button
                          type="button"
                          className="btn d-flex mx-auto w-100 py-1"
                          onClick={openMassAssignment}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "user"]}
                            size="1x"
                            className="mr-1 my-auto"
                          />
                          <p className="d-none my-auto d-md-flex w-100 mx-4 text-nowrap">
                            {intl.formatMessage({
                              id: "app.contacts.assignment",
                            })}
                          </p>
                        </button>
                        <button
                          type="button"
                          className="btn my-auto d-flex w-100 py-1"
                          onClick={createAutomation}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "robot"]}
                            size="1x"
                            className="mr-1 my-auto"
                          />
                          <p className="d-none my-auto d-md-flex w-100 mx-1 text-nowrap">
                            {intl.formatMessage({
                              id: "app.contacts.automate.emails",
                            })}
                          </p>
                        </button>
                        <button
                          type="button"
                          className="text-white my-auto d-flex btn btn-danger  my-auto py-2"
                          onClick={deleteSelected}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "trash"]}
                            size="1x"
                            className="mr-1 my-auto"
                          />
                          <p className="d-none my-auto d-md-flex w-100 mx-4 text-nowrap">
                            {intl.formatMessage({
                              id: "app.contacts.deleteAll",
                            })}
                          </p>
                        </button>
                      </div>
                    )}
                  </div>
                  {contacts.map((contact) => (
                    <ContactRow key={contact.id} contact={contact} />
                  ))}
                  {!loadings[CONTACTS_LOADING] && contacts.length === 0 && (
                    <NoData
                      image="generic4"
                      text={intl.formatMessage({
                        id: "app.contacts.notContacts",
                      })}
                      horizontal
                    />
                  )}
                </Container>
              )}
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(CONTACT_FILTER_MODAL)}
        visible={getModalVisibility(CONTACT_FILTER_MODAL)}
        onClose={() => turnOffModal(CONTACT_FILTER_MODAL)}
      >
        <ContactsBar />
      </SideModal>
      <SideModal
        active={isModal(CONTACT_IMPORT_EXPORT)}
        visible={getModalVisibility(CONTACT_IMPORT_EXPORT)}
        onClose={() => turnOffModal(CONTACT_IMPORT_EXPORT)}
        showSppiner={isLoading(CONTACTS_IMPORT_LOADING)}
        sppinerMessage="Importando contactos. El proceso puede tardar varios minutos."
      >
        <ImportContacts />
      </SideModal>
      <SideModal
        active={isModal(CONTACT_TAGS_FORM)}
        visible={getModalVisibility(CONTACT_TAGS_FORM)}
        onClose={() => {
          turnOffModal(CONTACT_TAGS_FORM);
          turnOffInternalModal(CONTACT_TAGS);
        }}
      >
        <ContactTags
          onUpdate={() => {
            getContacts(company?.id!, contactFilters, false);
          }}
        />
      </SideModal>
      <SideModal
        active={isModal(CONTACT_STATES_FORM)}
        visible={getModalVisibility(CONTACT_STATES_FORM)}
        onClose={() => {
          turnOffModal(CONTACT_STATES_FORM);
          turnOffInternalModal(CONTACT_STATES);
        }}
      >
        <ContactStates
          onUpdate={() => {
            getContacts(company?.id!, contactFilters, false);
          }}
        />
      </SideModal>
      <SideModal
        active={isModal(CONTACT_CONFIG_FORM)}
        visible={getModalVisibility(CONTACT_CONFIG_FORM)}
        onClose={() => {
          turnOffModal(CONTACT_CONFIG_FORM);
          turnOffInternalModal(CUSTOM_FIELD);
        }}
      >
        <Config />
      </SideModal>
      <SideModal
        active={isModal(AUTOMATION_CREATE_FORM)}
        visible={getModalVisibility(AUTOMATION_CREATE_FORM)}
        onClose={() => turnOffModal(AUTOMATION_CREATE_FORM)}
      >
        <CreateAutomationForm
          automation={AutomationTrigger.CONTACT}
          contacts={selectedContacts}
        />
      </SideModal>
      <SideModal
        active={isModal(AUTOMATION_EDIT_FORM)}
        visible={getModalVisibility(AUTOMATION_EDIT_FORM)}
        showSppiner={isLoading(AUTOMATION_LOADING)}
        onClose={() => {
          turnOffModal(AUTOMATION_EDIT_FORM);
          turnOffInternalModal(AUTOMATION_CONDITION);
          turnOffInternalModal(AUTOMATION_ACTION);
        }}
      >
        <EditAutomationForm />
      </SideModal>
      <SideModal
        active={isModal(DELETE_AUTOMATION_MODAL)}
        visible={getModalVisibility(DELETE_AUTOMATION_MODAL)}
        onClose={() => turnOffModal(DELETE_AUTOMATION_MODAL)}
        left
      >
        {getModalContent(DELETE_AUTOMATION_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_CARD_MODAL)}
        visible={getModalVisibility(BOARD_CARD_MODAL)}
        onClose={() => {
          turnOffModal(BOARD_CARD_MODAL);
          turnOffInternalModal(CARD_PRODUCT);
          cleanCard();
        }}
        showSppiner={isLoading(BOARD_CARD_LOADING)}
        showError={errorCode === Errors.CardLoad}
      >
        {getModalContent(BOARD_CARD_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_MASS_ASSIGNMENT_MODAL)}
        visible={getModalVisibility(CONTACT_MASS_ASSIGNMENT_MODAL)}
        onClose={closeMassAssignment}
      >
        <UserList />
      </SideModal>

      <TaskSideModals />
      <ContactSideModals />
      <CompanySideModals />
      <BoardSideModals />
      <EventSideModals />
      <EmailSideModals />
    </Layout>
  );
};
