import React, { useCallback } from "react";
import { Button } from "reactstrap";
import { ASSIGN_USER_SIDE_MODAL_LEFT } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import { User } from "../../../cores/session/interfaces/models/user";
import { getFirstLetters } from "../../utils/stringUtils";
import Avatar from "../Avatar";
import AssignUserForm from "./AssignUserForm";

interface Props {
  user: User;
}

export default function AssignUserRow(props: Props) {
  const {
    user: { firstName, lastName, avatarUrl, email },
  } = props;
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const toggle = useCallback(() => {
    turnOnModal(
      ASSIGN_USER_SIDE_MODAL_LEFT,
      <AssignUserForm
        user={props.user}
        onSubmit={() => turnOffModal(ASSIGN_USER_SIDE_MODAL_LEFT)}
      />,
      Position.LEFT
    );
  }, [turnOffModal, turnOnModal, props.user]);

  return (
    <>
      <div className="d-flex flex-row my-2 neu-in py-2 px-3">
        <Avatar
          className="text-dark-50 pointer mx-auto"
          label={getFirstLetters(`${lastName} ${firstName}`)}
          image={avatarUrl}
          width={"43px"}
          height={"43px"}
        />
        <div className="text-center flex-grow-1 align-items-center d-flex flex-column">
          <small className="mr-3">{email}</small>
          <small>{`${firstName} ${lastName}`}</small>
        </div>
        <Button className="ml-auto" color="primary" onClick={toggle}>
          Asignar
        </Button>
      </div>
    </>
  );
}
