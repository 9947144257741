import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { User } from "../../session/interfaces/models/user";

export const createUserInitAction = createAction("[User] Create Init");
export const createUserSuccessAction = createAction(
  "[User] Create Success",
  props<{ user: User }>()
);
export const createUserFailureAction = createAction(
  "[User] Create Failure",
  props<{ error: string }>()
);

export const getUserInitAction = createAction("[User] Fetch User Init");
export const getUserSuccessAction = createAction(
  "[User] Fetch User Success",
  props<{ user: User }>()
);
export const getUserFailureAction = createAction(
  "[User] Fetch User Failure",
  props<{ error: string }>()
);

export const getUsersInitAction = createAction("[User] Fetch Users Init");
export const getUsersSuccessAction = createAction(
  "[User] Fetch Users Success",
  props<{ users: User[], total: number }>()
);
export const getUsersFailureAction = createAction(
  "[User] Fetch Users Failure",
  props<{ error: string }>()
);

export const putUserInitAction = createAction("[User] Put User Init");
export const putUserSuccessAction = createAction(
  "[User] Put User Success",
  props<{ user: User }>()
);
export const putUserFailureAction = createAction(
  "[User] Put User Failure",
  props<{ error: string }>()
);

export const postForgotPasswordInitAction = createAction(
  "[Session] Post Forgot password Init"
);
export const postForgotPasswordSuccessAction = createAction(
  "[Session] Post Forgot password Success"
);
export const postForgotPasswordFailureAction = createAction(
  "[Session] Post Forgot password Failure",
  props<{ error: string }>()
);

export const putForgotPasswordInitAction = createAction(
  "[Session] Put Forgot password Init"
);
export const putForgotPasswordSuccessAction = createAction(
  "[Session] Put Forgot password Success"
);
export const putForgotPasswordFailureAction = createAction(
  "[Session] Put Forgot password Failure",
  props<{ error: string }>()
);

export const getForgotPasswordInitAction = createAction(
  "[Session] Get forgot password Init"
);
export const getForgotPasswordSuccessAction = createAction(
  "[Session] Get forgot password Success"
);
export const getForgotPasswordFailureAction = createAction(
  "[Session] Get forgot password Failure",
  props<{ error: string }>()
);

export const postResendConfirmationInitAction = createAction(
  "[Session] Post resend confirmation Init"
);
export const postResendConfirmationSuccessAction = createAction(
  "[Session] Post resend confirmation Success"
);
export const postResendConfirmationdFailureAction = createAction(
  "[Session] Post resend confirmation Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  createUserInitAction,
  createUserSuccessAction,
  createUserFailureAction,
  getUsersInitAction,
  getUsersSuccessAction,
  getUsersFailureAction,
  postForgotPasswordInitAction,
  postForgotPasswordSuccessAction,
  postForgotPasswordFailureAction,
  putForgotPasswordInitAction,
  putForgotPasswordSuccessAction,
  putForgotPasswordFailureAction,
  getForgotPasswordInitAction,
  getForgotPasswordSuccessAction,
  getForgotPasswordFailureAction,
  postResendConfirmationInitAction,
  postResendConfirmationSuccessAction,
  postResendConfirmationdFailureAction,
  getUserInitAction,
  getUserSuccessAction,
  getUserFailureAction,
  putUserInitAction,
  putUserSuccessAction,
  putUserFailureAction,
});

export type UserApiAction = typeof actions;
