import _ from "lodash";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import React from "react";
import { Order } from "../../../../cores/market/interface/order";
import { useIntl } from "react-intl";

interface Props {
  order: Order;
}

export const PaymentSection = (props: Props) => {
  const intl = useIntl();
  const {
    order: {
      currency: code,
      paidDate,
      paymentMethod: methods,
    },
  } = props;
  return (
    <div className="my-2 text-center">
      {methods.length > 0 && (  
        <h5 className="h5 text-black-50">
          {intl.formatMessage({ id: "app.onlinesales.order12" })}
        </h5>
      )}
      {methods.map(({ title, amount }, i) => (
        <h5 className="h5" key={i}>
          {_.capitalize(title)} {amount && (`${currencyFormatter.format(
            amount,
            { code }
          )}`)}
        </h5>
      ))}  
      {paidDate && moment(paidDate).isValid() && (
        <>
          <h5 className="h5 text-black-50">
            {intl.formatMessage({ id: "app.onlineSalesJump.datepay " })}
          </h5>
          <h5 className="h5">{moment(paidDate).format("DD/MM/YYYY - hh:mm")}</h5>
        </>
      )}
    </div>
  );
};
