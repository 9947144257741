import { Dictionary } from "lodash";

export enum ContactState {
  New = 0,
  Open = 1,
  In_progress = 2,
  Open_deal = 3,
  Unqualified = 4,
  Attempt_to_contact = 5,
  Connected = 6,
  Bad_timing = 7
}

export const  ContactStateLabels: Dictionary<string> = {
  [`${ContactState.New}`]: "app.contact.state.new",
  [`${ContactState.Open}`]: "app.contact.state.open",
  [`${ContactState.In_progress}`]: "app.contact.state.inProgress",
  [`${ContactState.Open_deal}`]: "app.contact.state.openDeal",
  [`${ContactState.Unqualified}`]: "app.contact.state.unqualified",
  [`${ContactState.Attempt_to_contact}`]: "app.contact.state.attemptToContact",
  [`${ContactState.Connected}`]: "app.contact.state.connected",
  [`${ContactState.Bad_timing}`]: "app.contact.state.badTiming",
};

export const ContactStateOptions = [
  { value: ContactState.New, label: "app.contact.state.new" },
  { value: ContactState.Open, label: "app.contact.state.open" },
  { value: ContactState.In_progress, label: "app.contact.state.inProgress" },
  { value: ContactState.Open_deal, label: "app.contact.state.openDeal" },
  { value: ContactState.Unqualified, label: "app.contact.state.unqualified" },
  { value: ContactState.Attempt_to_contact, label: "app.contact.state.attemptToContact" },
  { value: ContactState.Connected, label: "app.contact.state.connected" },
  { value: ContactState.Bad_timing, label: "app.contact.state.badTiming" },
];