import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { Col, Row } from "reactstrap";
import { useMarketCore } from "../../../../cores/market";
import { JumpsellerStatusOptions } from "../../../../cores/market/jumpseller.config";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { PaginationWemmbii } from "../../pagination/Pagination";
import { PanelWembii } from "../../PanelWembii";
import { useIntl } from "react-intl";

export const JumpsellerOrderBar = () => {
  const intl = useIntl();
  const { 
    getJumpsellerOrders, 
    jumpsellerOrders: orders,
    page,
    perPage,
    status,
    orderId,
    setOrderId,
    setPage,
    setPerPage,
    setStatus
  } = useMarketCore();
  const { company, socialSettings } = useMeCore();

  const isSync = useMemo(() => socialSettings && socialSettings.jumpseller, [
    socialSettings,
  ]);

  const debouncedSearch = useRef(
    _.debounce(
      (orderId) =>
      getJumpsellerOrders(company!.id, {
          orderId,
          perPage,
          page,
        }),
      1000
    )
  ).current;

  useEffect(() => {
    setPage(1);
    setPerPage(10);
    setStatus("");
    isSync && orderId && debouncedSearch(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, orderId, isSync]);

  useEffect(() => {
    setPage(1);
    setPerPage(10);
    isSync &&
      getJumpsellerOrders(company!.id, {
        status,
        perPage,
        page,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ company, getJumpsellerOrders, status, isSync]);

  useEffect(() => {
    isSync &&
      getJumpsellerOrders(company!.id, {
        orderId,
        status,
        perPage,
        page,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, getJumpsellerOrders, perPage, page, isSync]);

  return (
    <PanelWembii className="w-100 px-4 py-3 mb-3 pointer" shadow>
      <Row>
        <Col xs="12" lg="3">
          <InputWembii
            label={intl.formatMessage({ id: "app.onlineSalesJump.searchForId" })}
            placeholder={intl.formatMessage({ id: "app.onlineSales.search1" })}
            type="text"
            onChange={(value) => setOrderId(value)}
            value={orderId}
          />
            
        </Col>
        <Col xs="12" lg="3">
          <InputWembii
            label={intl.formatMessage({ id: "app.onlineSales.state" })}
            type="select"
            onChange={(value) => setStatus(value)}
            value={status}
          >
            {JumpsellerStatusOptions.map(({ value, label }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="auto">
          <PaginationWemmbii
            className="w-fit mx-auto mr-lg-0 ml-lg-auto"
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            disabledPages={orders.length < perPage}
          />
        </Col>
      </Row>
    </PanelWembii>
  );
};
