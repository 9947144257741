import cn from "classnames";
import React, { ReactNode } from "react";
import { MAIN_LAYOUT_LOADING } from "../../constants/loading.constant";
import { LoadingSpinnerWembii } from "../component/LoadingSpinnerWembii";

interface Props {
  className?: string;
  children: ReactNode;
  style?: React.CSSProperties;
}

export default function Layout(props: Props) {
  const { className, children, style } = props;
  return (
    <>
      <LoadingSpinnerWembii
        size="lg"
        id={MAIN_LAYOUT_LOADING}
        style={{ top: "40%" }}
      />
      <div style={style} className={cn("d-flex flex-column w-100", className)}>
        {children}
      </div>
    </>
  );
}
