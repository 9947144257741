import mainApiClient from "../../helpers/services/main-api-client";
import { GetCommentTypesResponse } from "../interfaces/api/get-comment-types";
import { GetConfigResponse } from "../interfaces/api/get-config";
import { PostCommentTypeRequest, PostCommentTypeResponse } from "../interfaces/api/post-comment-type";
import { PutCommentTypeRequest } from "../interfaces/api/put-comment-type";
import { PutConfigRequest, PutConfigResponse } from "../interfaces/api/put-config";
import { configMapper } from "./mapper";

export const configApiService = {
  getConfig() {
    return mainApiClient()
      .get<GetConfigResponse>(`config`)
    .then(({ data }) => configMapper.mapResponseToConfigs(data));
  },
  putConfig(configId: string, value: string) {
    const body: PutConfigRequest = {
      value,
    };
    return mainApiClient()
      .put<PutConfigResponse>(`config/${configId}`, body)
    .then(({ data }) => configMapper.mapResponseToConfig(data));
  },
  postCommentType(
    companyId: string,
    name: string
  ) {
    const body: PostCommentTypeRequest = {
      name,
    };
    return mainApiClient()
      .post<PostCommentTypeResponse>(`config/comment/types`, body, { params: { companyId } })
      .then(({ data }) => data.payload.type);
  },
  putCommentType(
    companyId: string,
    id: string,
    name: string
  ) {
    const body: PutCommentTypeRequest = {
      name,
    };
    return mainApiClient()
      .put(`config/comment/types/${id}`, body, { params: { companyId } })
      .then(({ data }) => data.payload.type);
  },
  getCommentTypes(companyId: string) {
    return mainApiClient()
      .get<GetCommentTypesResponse>(`config/comment/types`, {
        params: { companyId },
      })
      .then(({ data }) => configMapper.mapResponseToCommentType(data));
  },
  deleteCommentType(id: string, companyId: string) {
    return mainApiClient()
      .delete(`config/comment/types/${id}`, { params: { companyId } })
      .then(({ data }) => data);
  },
};
