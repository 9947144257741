import React, { ReactNode, useCallback, useMemo } from "react";
import { Order } from "../../../../cores/market/interface/order";
import { CL$Format } from "../../../../cores/utils";
import moment from "moment";
import { Button } from "reactstrap";
import {
  JUMPSELLER_ORDER_UPDATING,
  WOO_COMMERCE_ORDER_UPDATING,
} from "../../../../constants/loading.constant";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";
import { BillingSection } from "./BillingSection";
import { ItemsSection } from "./ItemsSection";
import { PaymentSection } from "./PaymentSection";
import { ShippingSection } from "./ShippingSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadOrderPDF, printOrderPDF } from "../../../utils/marketPdfUtils";
import { useMeCore } from "../../../../cores/me";
import { useIntl } from "react-intl";

interface Props {
  order: Order;
  icon: ReactNode;
  status?: ReactNode;
  avatar?: string;
  iconString: string;
  statusName: string;
  statusColor: string;
}

export const OrderModal = (props: Props) => {
  const intl = useIntl();
  const {
    order: { number, creationDate, currency: code, price },
    icon,
    avatar,
    iconString,
    statusName,
    statusColor,
    status,
  } = props;
  const { company } = useMeCore();

  const avatarSrc = useMemo(() =>
    (!company?.avatar) ? avatar : company?.avatar
  , [company, avatar]);

  const onPrintPDF = useCallback(async () => {
    printOrderPDF(props.order, iconString, statusName, statusColor, avatarSrc);
  }, [props.order, iconString, statusName, statusColor, avatarSrc]);

  const onDownloadPDF = useCallback(async () => {
    downloadOrderPDF(props.order, iconString, statusName, statusColor, avatarSrc);
  }, [props.order, iconString, statusName, statusColor, avatarSrc]);

  return (
    <div className="align-items-center justify-content-center d-flex flex-column px-2 py-4">
      <div className="my-2">{avatarSrc ? (
        <img className="marketCard-avatar" src={`data:image/jpeg;base64,${avatarSrc}`} alt="avatar" />
      ) : (
        icon
      )}</div>
      <h3 className="h3 text-black-50">{
      `${intl.formatMessage({ id: "app.onlineSales.search1" })} #${number}`}</h3>
      <div className="my-1">{status}</div>
      <UpdateLoadingCard
        loadingId={[WOO_COMMERCE_ORDER_UPDATING, JUMPSELLER_ORDER_UPDATING]}
        text={intl.formatMessage({ id: "app.onlineSalesJump.updatingState" })}
      />  
      <hr className="horizontal-separator mt-1 mb-3" />
      <div className="my-1 text-center">
        <h5 className="h5 text-black-50"> 
          {intl.formatMessage({ id: "app.onlinesales.order5" })}
        </h5>
        <h5 className="h5">
          {moment(creationDate).format("DD/MM/YYYY hh:mm a")}{" "}
        </h5>
      </div>
      <div className="my-1 text-center">
        <h5 className="h5 text-black-50"> {
        `${intl.formatMessage({ id: "app.onlinesales.order2" })} (${code}): `}</h5>
        <h5 className="h5"> {CL$Format(+price, "", "$")} </h5>
      </div>
      <hr className="horizontal-separator my-3" />
      <BillingSection order={props.order} />
      <hr className="horizontal-separator my-3" />
      <ShippingSection order={props.order} />
      <hr className="horizontal-separator my-3" />
      <PaymentSection order={props.order} />
      <hr className="horizontal-separator my-3" />
      <ItemsSection order={props.order} />

      <div className="mt-2">
        <Button className="my-1 mr-1" onClick={onDownloadPDF} color="primary">
          <FontAwesomeIcon
            icon={["fas", "download"]}
            size="1x"
            className="mr-2"
          />
          {intl.formatMessage({ id: "app.onlinesales.order3" })}  
        </Button>
        <Button className="my-1 ml-1" onClick={onPrintPDF} color="success">
          <FontAwesomeIcon icon={["fas", "print"]} size="1x" className="mr-2" />
          {intl.formatMessage({ id: "app.onlinesales.order4" })} 
        </Button>
      </div>
    </div>
  );
};
