import { DeleteEmailTemplateResponse } from "../interfaces/api/delete-email-template";
import { GetChatTemplatesPayload, GetChatTemplatesResponse } from "../interfaces/api/get-chat-templates";
import { GetEmailTemplatesPayload, GetEmailTemplatesResponse } from "../interfaces/api/get-email-templates";
import { GetSocialTemplatesPayload, GetSocialTemplatesResponse } from "../interfaces/api/get-social-templates";
import { PostEmailTemplateResponse } from "../interfaces/api/post-email-template";
import { PostSocialTemplateResponse } from "../interfaces/api/post-social-template";
import { PostChatTemplateResponse } from "../interfaces/api/post-chat-template";
import { PutChatTemplateResponse } from "../interfaces/api/put-chat-template";
import { PutEmailTemplateResponse } from "../interfaces/api/put-email-template";
import { PutSocialTemplateResponse } from "../interfaces/api/put-social-template";

export const templatesMapper = {
  mapResponseToEmailTemplates(response: GetEmailTemplatesResponse): GetEmailTemplatesPayload {
    const { templates } = response?.payload;
    return { templates };
  },
  mapResponseToSocialTemplates(response: GetSocialTemplatesResponse): GetSocialTemplatesPayload {
    const { templates } = response?.payload;
    return { templates };
  },
  mapResponseToChatTemplates(response: GetChatTemplatesResponse): GetChatTemplatesPayload {
    const { templates } = response?.payload;
    return { templates };
  },
  mapResponseToEmailTemplate(response: PostEmailTemplateResponse | PutEmailTemplateResponse) {
    const { template } = response?.payload;
    return { template };
  },
  mapResponseToSocialTemplate(response: PostSocialTemplateResponse | PutSocialTemplateResponse) {
    const { template } = response?.payload;
    return { template };
  },
  mapResponseToChatTemplate(response: PostChatTemplateResponse | PutChatTemplateResponse) {
    const { template } = response?.payload;
    return { template };
  },
  mapResponseToId(response: DeleteEmailTemplateResponse) {
    return response?.payload?.id;
  },
};
