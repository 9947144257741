import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Form, Label } from "reactstrap";
import { Errors } from "../../../../constants/errors";
import { SMTP_SYNC_LOADING } from "../../../../constants/loading.constant";
import { useMeCore } from "../../../../cores/me";
import { useSmtpCore } from "../../../../cores/smtp";
import { isNumeric } from "../../../utils/stringUtils";
import { InputWembii } from "../../InputWembii";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";
import { CheckboxWembii } from "../../CheckboxWembii";

interface Props {
  asUser: boolean;
  onSuccess: () => void;
}

export default function SmtpLoginForm({ asUser, onSuccess }: Props) {
  const intl = useIntl();
  const { bind, verifyConnection, errorCode } = useSmtpCore();
  const { company } = useMeCore();
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secure, setSecure] = useState(false);
  const [verified, setVerified] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      bind(
        company?.id!,
        host,
        port,
        email,
        password,
        secure,
        asUser,
        onSuccess
      );
    },
    [company, host, port, email, password, secure, asUser, bind, onSuccess]
  );

  const disabled = useMemo(
    () => !host || !port || !email || !password,
    [host, port, email, password]
  );

  const handleVerify = useCallback(() => {
    verifyConnection(company?.id!, host, port, email, password, secure, () => {
      setVerified(true);
    });
  }, [company, verifyConnection, host, port, email, password, secure]);

  useEffect(() => {
    setVerified(false);
  }, [host, port, email, password, secure]);

  const error = useMemo(() => {
    switch (errorCode) {
      case Errors.WrongSmtpHost:
        return (
          <Label className="text-center my-2 w-100 text-danger">
            {intl.formatMessage({ id: "app.social.smtp.error.wronghost" })}
          </Label>
        );
      case Errors.Timeout:
        return (
          <Label className="text-center my-2 w-100 text-danger">
            {intl.formatMessage({ id: "app.social.smtp.error.timeout" })}
          </Label>
        );
      case Errors.WrongAuth:
        return (
          <Label className="text-center my-2 w-100 text-danger">
            {intl.formatMessage({ id: "app.social.smtp.error.wrongauth" })}
          </Label>
        );
      default:
        return (
          <Label className="text-center my-2 w-100 text-danger">
            {intl.formatMessage({ id: "app.social.smtp.error.default" })}
          </Label>
        );
    }
  }, [errorCode, intl]);

  return (
    <div className="p-4 overflow-auto">
      <h2 className="h2 text-center mb-4">
        {intl.formatMessage({ id: "app.social.smtp" })}
      </h2>

      <Form onSubmit={handleSubmit} className="px-4">
        <div className="d-flex">
          <InputWembii
            type="text"
            name="host"
            id="host"
            label={intl.formatMessage({ id: "app.social.smtp.host" })}
            required
            className="w-100 mr-1"
            placeholder={intl.formatMessage({ id: "app.social.smtp.host" })}
            value={host}
            onChange={setHost}
          />
          <InputWembii
            type="text"
            name="port"
            label={intl.formatMessage({ id: "app.social.smtp.port" })}
            style={{ width: 100 }}
            id="port"
            validateTxt={isNumeric}
            required
            placeholder={intl.formatMessage({ id: "app.social.smtp.port" })}
            className="ml-1"
            value={port}
            onChange={setPort}
          />
        </div>
        <InputWembii
          type="text"
          name="user"
          label={intl.formatMessage({ id: "app.social.smtp.email" })}
          id="user"
          required
          placeholder={intl.formatMessage({ id: "app.social.smtp.email" })}
          value={email}
          onChange={setEmail}
        />
        <InputWembii
          type="password"
          name="password"
          label={intl.formatMessage({ id: "app.social.smtp.password" })}
          id="password"
          required
          placeholder={intl.formatMessage({ id: "app.social.smtp.password" })}
          value={password}
          onChange={setPassword}
        />

        <CheckboxWembii
          label={intl.formatMessage({ id: "app.social.smtp.secure" })}
          checked={secure}
          onChange={setSecure}
        />
        {verified && (
          <div className="text-muted text-center mb-2">
            {intl.formatMessage({ id: "app.social.smtp.verified" })}
          </div>
        )}
        {errorCode && error}
        <div className="d-flex flex-colum justify-content-center">
          <LoadingSpinnerWembii id={SMTP_SYNC_LOADING}>
            <Button
              type="button"
              disabled={disabled}
              onClick={handleVerify}
              className="mr-1"
            >
              {intl.formatMessage({ id: "app.social.smtp.verify" })}
            </Button>
            <Button
              disabled={disabled || !verified}
              color="primary"
              className="ml-1"
            >
              {intl.formatMessage({ id: "app.social.smtp.sync" })}
            </Button>
          </LoadingSpinnerWembii>
        </div>
      </Form>
    </div>
  );
}
