import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { CampaignCreation } from "../interfaces/api/post-campaign";

export const setCampaignAction = createAction(
  "[Advertisements] Set campaign",
  props<{ campaign: CampaignCreation }>()
);

export const selectAdAccountAction = createAction(
  "[Advertisements] Select adaccount",
  props<{ id: string }>()
);

export const selectImageAction = createAction(
  "[Advertisements] Select image",
  props<{ file: File | null, url: string }>()
);

export const resetCampaignAction = createAction(
  "[Advertisements] Reset Campaign"
);

const actions = joinActions({
  setCampaignAction,
  selectAdAccountAction,
  selectImageAction,
  resetCampaignAction,
});

export type AdvertisementsAction = typeof actions;
