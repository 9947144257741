import cn from "classnames";
import React, { ReactNode } from "react";
import { Col, Container, Row } from "reactstrap";
import { TitleWembii } from "../component/TitleWembii";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  className?: string;
  rightChildren?: ReactNode;
  bottomChildren?: ReactNode;
  title: string;
  icon?: ReactNode;
  subtitle?: ReactNode;
  topRow?: ReactNode;
  id?: string;
  mb?: boolean;
  showHelp?: boolean;
  modalHelpAction?: () => void;
}

export const Header2 = (props: Props) => {
  const {
    className,
    rightChildren,
    bottomChildren,
    title,
    topRow,
    subtitle,
    icon,
    id,
    mb = true,
  } = props;

  return (
    <div
      className={cn(" flex-grow-0 flex-shrink-1", className, {
        "mb-0": !mb,
        "mb-3": mb,
      })}
      id={id}
    >
      <Container fluid>
        <Row>
          <Col
            xs={{ size: "12", order: 2 }}
            lg={{ size: "3", order: 1 }}
            className="my-2"
          >
            <Row>
              <Col
                xs={{ size: "12", order: "2" }}
                lg={{ size: "auto", order: "1" }}
                className="d-flex align-items-center justify-content-start"
              >
                <TitleWembii Tag="h3" className=" my-auto">
                  {title}
                </TitleWembii>
                {topRow}
                {props.showHelp && (
                  <FontAwesomeIcon
                    icon={["fas", "info-circle"]}
                    style={{ left: 20, bottom: 8, zIndex: 1 }}
                    className="pointer position-relative"
                    size="lg"
                    color="grey"
                    onClick={props.modalHelpAction}
                  />
                )}
              </Col>
              <Col
                xs={{ size: "12", order: "1" }}
                lg={{ size: "auto", order: "2" }}
                className="d-flex align-items-center justify-content-center justify-content-lg-start"
              >
                {icon}
              </Col>
            </Row>
            {subtitle && (
              <div className="d-flex justify-content-center justify-content-lg-start">
                <TitleWembii
                  Tag="h5"
                  color="primary"
                  className="d-none d-md-block"
                >
                  {subtitle}
                </TitleWembii>
              </div>
            )}
            {bottomChildren && (
              <div className="d-flex justify-content-center justify-content-lg-start">
                {bottomChildren}
              </div>
            )}
          </Col>
          <Col
            xs={{ size: "12", order: 2 }}
            lg={{ size: "9", order: 2 }}
            className="d-flex my-2"
          >
            <div className="w-100 d-flex align-items-center justify-content-center justify-content-lg-end">
              {rightChildren}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
