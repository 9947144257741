import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Office365Setting } from "../interface/api/post-office365-bind";

export const postUnbindOffice365InitAction = createAction(
  "[Office365] Unbind Office365 Init"
);
export const postUnbindOffice365SuccessAction = createAction(
  "[Office365] Unbind Office365 Success",
  props<{ asUser: boolean }>()
);
export const postUnbindOffice365FailureAction = createAction(
  "[Office365] Unbind Office365 Failure",
  props<{ error: string }>()
);

export const postBindOffice365InitAction = createAction(
  "[Office365] Bind Office365 Init"
);
export const postBindOffice365SuccessAction = createAction(
  "[Office365] Bind Office365 Success",
  props<{ settings: Office365Setting, asUser: boolean }>()
);
export const postBindOffice365FailureAction = createAction(
  "[Office365] Bind Office365 Failure",
  props<{ error: string }>()
);

export const postOffice365EmailInitAction = createAction(
  "[Office365] Post Office365 Email Init"
);
export const postOffice365EmailSuccessAction = createAction(
  "[Office365] Post Office365 Email Success"
);
export const postOffice365EmailFailureAction = createAction(
  "[Office365] Post Office365 Email Failure",
  props<{ error: string }>()
);

export const getOffice365InitAction = createAction(
  "[Office365] Get Office365 Settings Init"
);
export const getOffice365SuccessAction = createAction(
  "[Office365] Get Office365 Settings Success",
  props<{ settings: Office365Setting, asUser: boolean }>()
);
export const getOffice365FailureAction = createAction(
  "[Office365] Get Office365 Settings Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindOffice365InitAction,
  postUnbindOffice365SuccessAction,
  postUnbindOffice365FailureAction,
  postBindOffice365InitAction,
  postBindOffice365SuccessAction,
  postBindOffice365FailureAction,
  getOffice365InitAction,
  getOffice365SuccessAction,
  getOffice365FailureAction,
  postOffice365EmailInitAction,
  postOffice365EmailSuccessAction,
  postOffice365EmailFailureAction,
});

export type Office365ApiAction = typeof actions;
