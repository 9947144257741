import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import {
  COMPANIES_EXPORT_LOADING,
  CONTACT_COMPANIES_LOADING,
} from "../../constants/loading.constant";
import {
  COMPANY_CUSTOM_FIELDS_FORM,
  CONTACT_COMPANY_CONTACTS_MODAL,
  CONTACT_COMPANY_EXPAND_CREATION_FORM,
  CONTACT_COMPANY_EXPAND_EDIT_FORM,
  CONTACT_EXPAND_CREATION_FORM,
  CONTACT_EXPAND_EDIT_FORM,
  CONTACT_USERS_MODAL,
  DELETE_CONTACT_COMMENT_MODAL,
  DELETE_CONTACT_OWNER_MODAL,
} from "../../constants/modal.constant";
import { useContactCore } from "../../cores/contacts";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { NoData } from "../component/NoData";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";
import { useRouterCore } from "../../cores/router";
import CreateCompanyForm from "../component/contactCompanies/modal/CreateCompanyForm";
import { CompanyRow } from "../component/contactCompanies/CompanyRow";
import { CompaniesBar } from "../component/contactCompanies/CompaniesBar";
import CreateContactForm from "../component/contacts/modal/CreateContactForm";
import { ContactSource } from "../../cores/contacts/enum/ContactSource.enum";
import {
  CUSTOM_FIELD,
  ENTERPRISE_CONTACT_ASSIGNMENT,
} from "../../constants/internalModal.constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Company } from "../../cores/contacts/interfaces/api/get-companies-export";
import { UpgradePlanButton } from "../component/plan/UpgradePlanButton";
import CustomFields from "../component/contactCompanies/modal/CustomFields";
import { LoadingSpinner } from "../component/LoadingSpinner";

export const ContactCompaniesScreen = () => {
  const intl = useIntl();
  const {
    loadings,
    isModal,
    turnOffModal,
    turnOnModal,
    turnOffInternalModal,
    getModalContent,
    getModalVisibility,
  } = useGlobalCore();
  const { companyRole, company, companyPlan, isAdmin, isReseller } =
    useMeCore();
  const {
    companies,
    total,
    companyFilters,
    getExportCompanies,
    getCompanyCustomFields,
  } = useContactCore();
  const { goToSocial } = useRouterCore();
  const [extension, setExtension] = useState("csv");
  const [exportData, setExportData] = useState<Company[]>([]);

  const ref = useRef<any>(null);

  useEffect(() => {
    if (
      !companyPlan?.rules.allowDatabase ||
      (!companyRole?.contacts &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  const headers = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "app.contacts.companies.name" }),
        key: "name",
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.rut" }),
        key: "rut",
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.address" }),
        key: "address",
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.city" }),
        key: "city",
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.country" }),
        key: "country",
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.sector" }),
        key: "sector",
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.phone" }),
        key: "phone",
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.website" }),
        key: "website",
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.employees" }),
        key: "employees",
      },
      {
        label: intl.formatMessage({ id: "app.contacts.companies.owners" }),
        key: "owners",
      },
    ],
    [intl]
  );

  const onClickCreateCompany = useCallback(() => {
    getCompanyCustomFields(company?.id!);
    turnOnModal(CONTACT_COMPANY_EXPAND_CREATION_FORM, true);
  }, [company, turnOnModal, getCompanyCustomFields]);

  const onClickExport = useCallback(async () => {
    const companies = await getExportCompanies(company?.id!, companyFilters);
    if (companies) {
      setExportData(companies);
      ref.current.link.click();
    }
  }, [company, companyFilters, getExportCompanies]);

  const onClickCSVExport = useCallback(() => {
    setExtension("csv");
    onClickExport();
  }, [onClickExport]);

  const onClickExcelExport = useCallback(() => {
    setExtension("xls");
    onClickExport();
  }, [onClickExport]);

  const onClickCustomFields = useCallback(() => {
    getCompanyCustomFields(company?.id!);
    turnOnModal(COMPANY_CUSTOM_FIELDS_FORM, true);
  }, [turnOnModal, getCompanyCustomFields, company]);

  const canExport = useMemo(
    () =>
      companyRole?.owner ||
      companyRole?.admin ||
      companyPlan?.rules.allowExportContact,
    [companyPlan, companyRole]
  );

  return (
    <Layout>
      <Header2
        title={`${intl.formatMessage({
          id: "app.contacts.companies.title",
        })} (${total})`}
        rightChildren={
          <>
            {!canExport ? (
              <UpgradePlanButton />
            ) : (
              <>
                <CSVLink
                  className="d-none"
                  headers={headers}
                  filename={`companies_${moment().format(
                    "DD-MM-YYYY"
                  )}.${extension}`}
                  data={exportData}
                  ref={ref}
                />
                <ButtonGroup className="ml-2">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      className="excelButton d-flex align-items-center"
                    >
                      <LoadingSpinner id={COMPANIES_EXPORT_LOADING} />
                      {intl.formatMessage({ id: "app.contacts.export" })}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={onClickCSVExport}>
                        <FontAwesomeIcon
                          icon={["fas", "file-csv"]}
                          size="1x"
                          className="mr-2"
                        />
                        CSV
                      </DropdownItem>
                      <DropdownItem onClick={onClickExcelExport}>
                        <FontAwesomeIcon
                          icon={["fas", "file-excel"]}
                          size="1x"
                          className="mr-2"
                        />
                        Excel
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ButtonGroup>
              </>
            )}
            <Button className="whiteButton ml-2" onClick={onClickCreateCompany}>
              {intl.formatMessage({
                id: "app.contacts.companies.createCompany",
              })}
            </Button>
            <Button className="whiteButton ml-2" onClick={onClickCustomFields}>
              <FontAwesomeIcon icon={["fas", "gear"]} />
            </Button>
          </>
        }
      />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <CompaniesBar />
              <UpdateLoadingCard
                loadingId={CONTACT_COMPANIES_LOADING}
                text={intl.formatMessage({
                  id: "app.contacts.companies.updatingCompanies",
                })}
              />
              <Container>
                {companies.map((company) => (
                  <CompanyRow key={company.id} company={company} />
                ))}
                {!loadings[CONTACT_COMPANIES_LOADING] &&
                  companies.length === 0 && (
                    <NoData
                      image="generic3"
                      text={intl.formatMessage({
                        id: "app.contacts.companies.notCompanies",
                      })}
                      horizontal
                    />
                  )}
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(CONTACT_COMPANY_EXPAND_CREATION_FORM)}
        visible={getModalVisibility(CONTACT_COMPANY_EXPAND_CREATION_FORM)}
        onClose={() => turnOffModal(CONTACT_COMPANY_EXPAND_CREATION_FORM)}
      >
        <CreateCompanyForm />
      </SideModal>
      <SideModal
        active={isModal(CONTACT_COMPANY_EXPAND_EDIT_FORM)}
        visible={getModalVisibility(CONTACT_COMPANY_EXPAND_EDIT_FORM)}
        onClose={() => {
          turnOffModal(CONTACT_COMPANY_EXPAND_EDIT_FORM);
          turnOffInternalModal(ENTERPRISE_CONTACT_ASSIGNMENT);
        }}
      >
        {getModalContent(CONTACT_COMPANY_EXPAND_EDIT_FORM)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_EXPAND_CREATION_FORM)}
        visible={getModalVisibility(CONTACT_EXPAND_CREATION_FORM)}
        onClose={() => turnOffModal(CONTACT_EXPAND_CREATION_FORM)}
        left
      >
        <CreateContactForm source={ContactSource.MANUAL} />
      </SideModal>
      <SideModal
        active={isModal(CONTACT_COMPANY_CONTACTS_MODAL)}
        visible={getModalVisibility(CONTACT_COMPANY_CONTACTS_MODAL)}
        onClose={() => turnOffModal(CONTACT_COMPANY_CONTACTS_MODAL)}
        left
      >
        {getModalContent(CONTACT_COMPANY_CONTACTS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_USERS_MODAL)}
        visible={getModalVisibility(CONTACT_USERS_MODAL)}
        onClose={() => turnOffModal(CONTACT_USERS_MODAL)}
        left
      >
        {getModalContent(CONTACT_USERS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(COMPANY_CUSTOM_FIELDS_FORM)}
        visible={getModalVisibility(COMPANY_CUSTOM_FIELDS_FORM)}
        onClose={() => {
          turnOffModal(COMPANY_CUSTOM_FIELDS_FORM);
          turnOffInternalModal(CUSTOM_FIELD);
        }}
      >
        <CustomFields />
      </SideModal>
      <SideModal
        active={isModal(DELETE_CONTACT_OWNER_MODAL)}
        visible={getModalVisibility(DELETE_CONTACT_OWNER_MODAL)}
        onClose={() => turnOffModal(DELETE_CONTACT_OWNER_MODAL)}
        left
      >
        {getModalContent(DELETE_CONTACT_OWNER_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_EXPAND_EDIT_FORM)}
        visible={getModalVisibility(CONTACT_EXPAND_EDIT_FORM)}
        onClose={() => turnOffModal(CONTACT_EXPAND_EDIT_FORM)}
        left
      >
        {getModalContent(CONTACT_EXPAND_EDIT_FORM)}
      </SideModal>
      <SideModal
        active={isModal(DELETE_CONTACT_COMMENT_MODAL)}
        visible={getModalVisibility(DELETE_CONTACT_COMMENT_MODAL)}
        onClose={() => turnOffModal(DELETE_CONTACT_COMMENT_MODAL)}
        left
      >
        {getModalContent(DELETE_CONTACT_COMMENT_MODAL)}
      </SideModal>
    </Layout>
  );
};
