import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  Badge,
  Button,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useMeCore } from "../../../../cores/me";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { Hashtag } from "../../../../cores/socialPost/interfaces/api/get-hashtags";
import HashtagForm from "../creator/HashtagForm";

export default function HashtagsForm() {
  const intl = useIntl();
  const { company } = useMeCore(); 
  const {
    hashtags,
    deleteHashtag,
    addHashtag,
    updateMentions,
    inputCursorIndex,
  } = useSocialPostCore();
  const [showHashtagForm, setShowHashtagForm] = useState(false);

  const handleRemoveHashtag = useCallback((hashtag: Hashtag) => {
    deleteHashtag(company?.id!, hashtag.id);
  }, [deleteHashtag, company]);

  const handleAddHashtagToText = useCallback((hashtag: Hashtag) => {
    addHashtag(hashtag.name);
    updateMentions(hashtag.name.length + 1, inputCursorIndex);
  }, [inputCursorIndex, addHashtag, updateMentions]);

  const allHashtags = useMemo(() => [
    { id: '', name: "#wembii", always: true },
    ...hashtags.map((hashtag) => ({ ...hashtag, always: false }))
  ], [hashtags]);

  return (
    <>
      {showHashtagForm && (
        <HashtagForm onClose={() => setShowHashtagForm(false)} />
      )}
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column justify-content-between h-100 p-3">
              <div>
                <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
                  {intl.formatMessage({ id: "app.socialStory.hashtags" })}
                </h2>

                <div className="w-100 p-3 d-flex justify-content-center flex-wrap">
                  {allHashtags.map((hashtag) => (
                    <Badge 
                      key={hashtag.id}
                      color="primary"
                      className="text-light font-size-2 m-2 d-flex align-items-center pointer"
                      pill
                    >
                      <span onClick={() => handleAddHashtagToText(hashtag)}>
                        {hashtag.name}
                      </span>

                      {!hashtag.always && (
                        <FontAwesomeIcon
                          icon={["fas", "times"]}
                          size="1x"
                          color="white"
                          className="m-1 z-index-2 ml-2"
                          onClick={() => handleRemoveHashtag(hashtag)}
                        />
                      )}
                    </Badge>
                  ))}
                </div>
              </div>

              <div className="mt-3">
                <Button
                  className="my-1"
                  color="white"
                  block
                  onClick={() => setShowHashtagForm(true)}
                >
                  {intl.formatMessage({ id: "app.socialStory.hashtag.create" })}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
