import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { CheckboxWembii } from "../../../CheckboxWembii";
import { useIntl } from "react-intl";
import { InputWembii } from "../../../InputWembii";
import { RadiobuttonWembii } from "../../../RadiobuttonWembii";

interface Props {
  onSubmit: (arg0: any) => void;
  commercialTime: string;
}

export default function CommercialTimeForm(props: Props) {
  const intl = useIntl();
  const { onSubmit, commercialTime } = props;

  const [days, setDays] = useState<boolean[]>([false,false,false,false,false,false,false]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [customTime, setCustomTime] = useState(false);

  useEffect(() => {
    if (commercialTime) {
      const conf = JSON.parse(commercialTime)[0];

      setDays(conf.days);
      setFrom(conf.from);
      setTo(conf.to);
      setCustomTime(true);
    }
  }, [commercialTime]);

  const handleSetDay = useCallback(
    async (value, index) => {
      const values = [...days];
      values[index] = value;
      setDays(values);
    },
    [setDays, days]
  );

  const handleSetCommercialTime = () => {
    let commercial
    if (customTime) {
      const conf = {
        from: from,
        to: to,
        days: days,
      };

      commercial = JSON.stringify([conf]);
    } else {
      commercial = null;
    }

    onSubmit({ commercialTime: commercial });
  };

  return (
    <Container className="h-100 p-4" fluid>
      <Row>
        <Col xs="12">
          <h2 className="h2 text-center mb-4"> 
            {intl.formatMessage({ id: "app.chatConfigArea.area" })}
          </h2>
          <div className="d-flex justify-content-around mb-2">
            <RadiobuttonWembii
              label={intl.formatMessage({ id: "app.chatConfigCommertial.foreverTxt" })}
              checked={!customTime}
              onChange={() => setCustomTime(false)}
            /> 
            <RadiobuttonWembii
              label={intl.formatMessage({ id: "app.chatConfigCommertial.customTxt" })} 
              checked={customTime}
              onChange={() => setCustomTime(true)}
            />
          </div>
          <div className="d-flex justify-content-around">
            <CheckboxWembii
              label={intl.formatMessage({ id: "app.chatConfigCommertial.day1" })}
              checked={days[0]}
              onChange={(v) => handleSetDay(v, 0)}
              disabled={!customTime}
            />
            <CheckboxWembii
              label={intl.formatMessage({ id: "app.chatConfigCommertial.day1" })}
              checked={days[1]}
              onChange={(v) => handleSetDay(v, 1)}
              disabled={!customTime}
            />
            <CheckboxWembii
              label={intl.formatMessage({ id: "app.chatConfigCommertial.day1" })}
              checked={days[2]}
              onChange={(v) => handleSetDay(v, 2)}
              disabled={!customTime}
            />
            <CheckboxWembii
              label={intl.formatMessage({ id: "app.chatConfigCommertial.day1" })}
              checked={days[3]}
              onChange={(v) => handleSetDay(v, 3)}
              disabled={!customTime}
            />
            <CheckboxWembii
              label={intl.formatMessage({ id: "app.chatConfigCommertial.day1" })}
              checked={days[4]}
              onChange={(v) => handleSetDay(v, 4)}
              disabled={!customTime}
            />
            <CheckboxWembii
              label={intl.formatMessage({ id: "app.chatConfigCommertial.day1" })}
              checked={days[5]}
              onChange={(v) => handleSetDay(v, 5)}
              disabled={!customTime}
            />
            <CheckboxWembii
              label={intl.formatMessage({ id: "app.chatConfigCommertial.day1" })}
              checked={days[6]}
              onChange={(v) => handleSetDay(v, 6)}
              disabled={!customTime}
            />
          </div>
          <div className="d-flex justify-content-around">
            <InputWembii
              type="time"
              name="from"
              id="from"
              label={intl.formatMessage({ id: "app.chatConfigCommertial.from" })}
              value={from}
              flat
              required
              onChange={(v) => setFrom(v)}
              disabled={!customTime}
            />
            <InputWembii
              type="time"
              name="to"
              id="to"
              label={intl.formatMessage({ id: "app.chatConfigCommertial.to" })}
              value={to}
              flat
              required
              onChange={(v) => setTo(v)}
              disabled={!customTime}
            />
          </div>
          <div className="d-flex justify-content-center">
            <Button color="primary" onClick={handleSetCommercialTime}>
              {intl.formatMessage({ id: "app.chatConfigCommertial.accept" })}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
