import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "reactstrap";
import {
  BOARDS_INSIGHT_LOADING,
  BOARD_CARD_LOADING,
} from "../../constants/loading.constant";
import {
  ANALYTICS_EXPAND,
  BOARD_CARD_MODAL,
  BOARD_FILTERS_MODAL,
  BOARD_METRIC_MODAL,
} from "../../constants/modal.constant";
import { useBoardAnalyticsCore } from "../../cores/boardAnalytics";
import { ChartMetric } from "../../cores/boardAnalytics/interface/ChartMetric";
import { Metric2 } from "../../cores/boardAnalytics/interface/Metric2";
import { useBoardCore } from "../../cores/boards";
import { getCurrencySymbol } from "../../cores/boards/constants/currencies.constant";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { ChartContainer } from "../component/analytics/ChartContainer";
import { BarChart } from "../component/analytics/charts/BarChart";
import { PieChart } from "../component/analytics/charts/PieChart";
import { CameFrom } from "../component/analytics/enum/CameFrom";
import { Legend } from "../component/analytics/Legend";
import { Monitors } from "../component/analytics/Monitors";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";
import { CL$Format } from "../../cores/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BoardSelector from "../component/boards/BoardSelector";
import FiltersForm from "../component/boards/modal/FiltersForm";
import Opportunities from "../component/boards/modal/Opportunities";
import moment from "moment";
import TaskSideModals from "../component/tasks/TaskSideModals";
import BoardSideModals from "../component/boards/BoardSideModals";
import CompanySideModals from "../component/contactCompanies/CompanySideModals";
import ContactSideModals from "../component/contacts/ContactSideModals";
import EventSideModals from "../component/event/EventSideModals";
import EmailSideModals from "../component/gmail/EmailSideModals";
import { Position } from "../../cores/globals/interfaces/globalEntity";
import { CARD_PRODUCT } from "../../constants/internalModal.constant";
import { Errors } from "../../constants/errors";
import { CardStatusOptions } from "../../cores/boards/constants/comment-type";
import { useConfigCore } from "../../cores/config";
import { InputWembii } from "../component/InputWembii";
import _ from "lodash";
import { TaskStatus } from "../../cores/tasks/config";

export const BusinessBoardAnalyticsScreen = () => {
  const intl = useIntl();
  const { company, companyPlan, companyRole, isAdmin, isReseller } =
    useMeCore();
  const {
    isModal,
    turnOnModal,
    turnOffModal,
    getModalVisibility,
    turnOffInternalModal,
    isLoading,
    getModalContent,
  } = useGlobalCore();
  const { goToSocial } = useRouterCore();
  const { commentTypes, getCommentTypes } = useConfigCore();
  const {
    boardBusiness: board,
    filters,
    errorCode,
    getBusinessBoards,
    cleanCard,
    getFilters,
    getBusinessBoard,
  } = useBoardCore();
  const {
    getCrmInsights,
    toChartMetric,
    setDate,
    setHasMore,
    date,
    crmInsights,
  } = useBoardAnalyticsCore();
  const [commentType, setCommentType] = useState("all");
  const [taskType, setTaskType] = useState("all");

  useEffect(() => {
    if (
      !companyPlan?.rules.allowBusinessBoard ||
      (!companyRole?.crm &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  useEffect(() => {
    getBusinessBoards(company?.id!);
    getCommentTypes(company?.id!);
  }, [company, getBusinessBoards, getCommentTypes]);

  const cardByDayData: Metric2 = useMemo(
    () => ({
      ...crmInsights.cardsByDay,
      type: "line",
    }),
    [crmInsights.cardsByDay]
  );

  const cardsByListData: Metric2 = useMemo(
    () => ({
      ...crmInsights.cardsByList,
      type: "pie",
    }),
    [crmInsights.cardsByList]
  );

  const commentsByUserData: any = useMemo(() => {
    const values = !crmInsights.commentsByUser
      ? []
      : commentType === "all"
      ? crmInsights.commentsByUser.values.map((values) => {
          const value = (values.value as any).reduce(
            (prev: number, curr: any) => prev + curr.count,
            0
          );
          return {
            label: values.label,
            value,
          };
        })
      : crmInsights.commentsByUser.values
          .filter((values) =>
            (values.value as any).find(
              (value: any) => `${value.type}` === commentType
            )
          )
          .map((values) => {
            return {
              label: values.label,
              value: (values.value as any).find(
                (value: any) => `${value.type}` === commentType
              )?.count,
            };
          });

    const data = crmInsights.commentsByUser
      ? {
          description: crmInsights.commentsByUser.description,
          name: crmInsights.commentsByUser.name,
          title: crmInsights.commentsByUser.title,
          total: crmInsights.commentsByUser.total,
          values: _.orderBy(values, ["value"], ["desc"]),
        }
      : {};

    return {
      ...data,
      type: "pie",
    };
  }, [crmInsights.commentsByUser, commentType]);
  const tasksByUserData: any = useMemo(() => {
    const values = !crmInsights.tasksByUser
      ? []
      : taskType === "all"
      ? crmInsights.tasksByUser.values.map((values) => {
          const value = (values.value as any).reduce(
            (prev: number, curr: any) => prev + curr.count,
            0
          );
          return {
            label: values.label,
            value,
          };
        })
      : crmInsights.tasksByUser.values
          .filter((values) =>
            (values.value as any).find(
              (value: any) => `${value.type}` === taskType
            )
          )
          .map((values) => {
            return {
              label: values.label,
              value: (values.value as any).find(
                (value: any) => `${value.type}` === taskType
              )?.count,
            };
          });

    const data = crmInsights.tasksByUser
      ? {
          description: crmInsights.tasksByUser.description,
          name: crmInsights.tasksByUser.name,
          title: crmInsights.tasksByUser.title,
          total: crmInsights.tasksByUser.total,
          values: _.orderBy(values, ["value"], ["desc"]),
        }
      : {};

    return {
      ...data,
      type: "pie",
    };
  }, [crmInsights.tasksByUser, taskType]);

  const budgetByListData: Metric2 = useMemo(
    () => ({
      ...crmInsights.budgetByList,
      type: "pie",
      valueFormatter: CL$Format,
    }),
    [crmInsights.budgetByList]
  );
  const tasksByUser: Metric2 = useMemo(
    () => ({
      ...crmInsights.tasksByUser,
      type: "pie",
    }),
    [crmInsights.tasksByUser]
  );
  const cardByDayChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([cardByDayData]),
    [cardByDayData, toChartMetric]
  );

  const cardsListChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([cardsByListData]),
    [cardsByListData, toChartMetric]
  );

  const commentsListChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([commentsByUserData]),
    [commentsByUserData, toChartMetric]
  );

  const budgetListChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([budgetByListData]),
    [budgetByListData, toChartMetric]
  );
  const taskListChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([tasksByUser]),
    [budgetByListData, toChartMetric]
  );

  const cardByDayMonitor = useCallback(
    () => <Monitors metrics={[cardByDayData]} cameFrom={CameFrom.boards} />,
    [cardByDayData]
  );

  const toggleFilterModal = useCallback(() => {
    turnOnModal(
      BOARD_FILTERS_MODAL,
      <FiltersForm
        board={board}
        onFilter={(filters, onSuccess) => {
          if (board) {
            getCrmInsights(company?.id!, board.id, filters, onSuccess);
          }
        }}
        onClearFilter={() => {
          if (board) {
            getCrmInsights(company?.id!, board.id, {});
          }
        }}
      />
    );
  }, [turnOnModal, getCrmInsights, company, board]);

  const onSearching = useCallback(
    (companyId: string, boardId: string) => {
      const filter = getFilters(boardId);
      getBusinessBoard(companyId, boardId, filter, false);
      getCrmInsights(companyId, boardId, filter);
    },
    [getCrmInsights, getFilters, getBusinessBoard]
  );

  const isFiltered = useMemo(() => {
    return filters[`${board?.id!}`];
  }, [filters, board]);

  const showBarCards = useCallback(
    (element) => {
      if (!element.length) return;
      const metricDate = cardByDayChartMetrics.labels[element[0]._index];

      if (date !== metricDate) {
        setDate(metricDate);
      }

      setHasMore(true);

      turnOnModal(BOARD_METRIC_MODAL, true, Position.LEFT);
    },
    [cardByDayChartMetrics, date, setDate, setHasMore, turnOnModal]
  );

  const typesValues = useMemo(
    () => [
      { value: "all", label: intl.formatMessage({ id: "app.source.all" }) },
      ...CardStatusOptions.map((status) => ({
        value: status.value,
        label: intl.formatMessage({ id: status.label }),
      })),
      ...commentTypes.map((type) => ({ value: type.id, label: type.name })),
    ],
    [commentTypes, intl]
  );
  const taskTypesValues = useMemo(
    () => [
      { value: "all", label: intl.formatMessage({ id: "app.source.all" }) },

      {
        value: TaskStatus.Completed,
        label: intl.formatMessage({ id: "app.tasks.completed" }),
      },
      {
        value: TaskStatus.Incompleted,
        label: intl.formatMessage({ id: "app.tasks.incompleted" }),
      },
    ],
    [commentTypes, intl]
  );
  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.metricsCrm.title" })}
        rightChildren={
          <>
            <BoardSelector onSearching={onSearching} />
            <button
              className={cn("ml-3 btn", {
                whiteButton: !isFiltered,
                "btn-primary": isFiltered,
              })}
              onClick={toggleFilterModal}
            >
              <FontAwesomeIcon icon={["fas", "sliders-h"]} className="mr-2" />
              {intl.formatMessage({ id: "app.crmBusiness.filters" })}
            </button>
          </>
        }
      />
      {isLoading(BOARDS_INSIGHT_LOADING) ? (
        <UpdateLoadingCard
          loadingId={BOARDS_INSIGHT_LOADING}
          text={intl.formatMessage({ id: "app.metricsCrm.updating" })}
        />
      ) : (
        <Content>
          <Container fluid>
            <Container>
              <ChartContainer
                id="crmChart"
                height={250}
                className="mb-4"
                title={intl.formatMessage({ id: "app.metricsCrm.oportunitys" })}
                monitors={cardByDayMonitor()}
                isEmptyData={
                  !cardByDayData.values || cardByDayData.values?.length === 0
                }
              >
                <BarChart
                  labels={cardByDayChartMetrics.labels.map((date) =>
                    moment(date).format("dddd, MMMM Do YYYY")
                  )}
                  datasets={cardByDayChartMetrics.datasets}
                  onElementClick={showBarCards}
                />
              </ChartContainer>
              <ChartContainer
                id="crmChart"
                className="mb-4"
                title={intl.formatMessage({
                  id: "app.metricsCrm.oportunitys1",
                })}
                height={300}
                legend={
                  <Legend metric={cardsByListData} cameFrom={CameFrom.boards} />
                }
              >
                <PieChart
                  labels={cardsListChartMetrics.labels}
                  datasets={cardsListChartMetrics.datasets}
                />
              </ChartContainer>
              <ChartContainer
                id="crmChart"
                className="mb-4"
                title={intl.formatMessage({
                  id: "app.metricsCrm.oportunitys3",
                })}
                selector={
                  <InputWembii
                    type="select"
                    className="mx-1"
                    onChange={setCommentType}
                    value={commentType}
                    mb={false}
                  >
                    {typesValues.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </InputWembii>
                }
                height={300}
                legend={
                  <Legend
                    metric={commentsByUserData}
                    cameFrom={CameFrom.boards}
                  />
                }
              >
                <PieChart
                  labels={commentsListChartMetrics.labels}
                  datasets={commentsListChartMetrics.datasets}
                />
              </ChartContainer>
              <ChartContainer
                id="crmChart"
                className="mb-4"
                title={"Tarea por usuario"}
                selector={
                  <InputWembii
                    type="select"
                    className="mx-1"
                    onChange={setTaskType}
                    value={taskType}
                    mb={false}
                  >
                    {taskTypesValues.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </InputWembii>
                }
                height={300}
                legend={
                  <Legend metric={tasksByUserData} cameFrom={CameFrom.boards} />
                }
              >
                <PieChart
                  labels={taskListChartMetrics.labels}
                  datasets={taskListChartMetrics.datasets}
                />
              </ChartContainer>
              <ChartContainer
                id="crmChart"
                className="mb-4"
                title={`${intl.formatMessage({
                  id: "app.metricsCrm.oportunitys2",
                })} 
                (${board?.currency && getCurrencySymbol(board.currency)})`}
                height={300}
                legend={
                  <Legend
                    metric={budgetByListData}
                    cameFrom={CameFrom.boards}
                  />
                }
              >
                <PieChart
                  labels={budgetListChartMetrics.labels}
                  datasets={budgetListChartMetrics.datasets}
                />
              </ChartContainer>
            </Container>
          </Container>
          <SideModal
            active={isModal(ANALYTICS_EXPAND)}
            visible={getModalVisibility(ANALYTICS_EXPAND)}
            onClose={() => turnOffModal(ANALYTICS_EXPAND)}
            left
          >
            {getModalContent(ANALYTICS_EXPAND)}
          </SideModal>
          <SideModal
            active={isModal(BOARD_FILTERS_MODAL)}
            visible={getModalVisibility(BOARD_FILTERS_MODAL)}
            onClose={() => turnOffModal(BOARD_FILTERS_MODAL)}
          >
            {getModalContent(BOARD_FILTERS_MODAL)}
          </SideModal>
          <SideModal
            active={isModal(BOARD_METRIC_MODAL)}
            visible={getModalVisibility(BOARD_METRIC_MODAL)}
            onClose={() => {
              turnOffModal(BOARD_METRIC_MODAL);
              setHasMore(false);
            }}
            left
          >
            <Opportunities />
          </SideModal>
          <SideModal
            active={isModal(BOARD_CARD_MODAL)}
            visible={getModalVisibility(BOARD_CARD_MODAL)}
            onClose={() => {
              turnOffModal(BOARD_CARD_MODAL);
              turnOffInternalModal(CARD_PRODUCT);
              cleanCard();
            }}
            showSppiner={isLoading(BOARD_CARD_LOADING)}
            showError={errorCode === Errors.CardLoad}
          >
            {getModalContent(BOARD_CARD_MODAL)}
          </SideModal>

          <TaskSideModals />
          <ContactSideModals />
          <CompanySideModals />
          <BoardSideModals />
          <EventSideModals />
          <EmailSideModals />
        </Content>
      )}
    </Layout>
  );
};
