import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { WhatsappChat } from "../interfaces/api/get-whatsapp-chat";

export const setChatAction = createAction(
  "[Chat] Set Chat",
  props<{ chat: WhatsappChat }>()
);

const actions = joinActions({
  setChatAction,
});

export type WhatsappChatAction = typeof actions;
