import React, { useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import wembiiImage from "../../assets/wembii-logo.png";
import { ACCOUNT_POST_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../../cores/globals";
import { useRouterCore } from "../../cores/router";
import { useUserCore } from "../../cores/user";
import { Context } from "../../LocaleWrapper";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import useVisibility from "../../hooks/useVisibility/useVisibility.hook";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CreateAccount = () => {
  const intl = useIntl();
  const { locale } = useContext(Context);
  const { routes, goToLogin } = useRouterCore();
  const { postRegisterUser } = useUserCore();
  const { isLoading } = useGlobalCore();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [firstName, setFirtName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [phoneType, setPhoneType] = useState("");
  const { toggleVisibility, visible } = useVisibility({ initValue: false });

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    postRegisterUser(
      {
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        locale,
        phoneType,
        country,
      },
      goToLogin
    );
  };

  const isEmailValid = useCallback(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  }, [email]);

  const isPasswordValid = () => {
    const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{6,32}$)/;
    return passwordRegex.test(password);
  };

  const isRepeatPasswordValid = () => {
    return password === passwordConfirmation;
  };

  const disabled = useCallback(
    () =>
      !email ||
      !password ||
      !passwordConfirmation ||
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !country ||
      !phoneType ||
      password !== passwordConfirmation ||
      !isEmailValid(),
    [
      email,
      password,
      passwordConfirmation,
      firstName,
      lastName,
      phoneNumber,
      country,
      phoneType,
      isEmailValid,
    ]
  );

  return (
    <Container id="register" className="h-100" fluid>
      <Row className="h-100 d-flex justify-content-center overflow-auto">
        <Col
          xs="9"
          className="d-none d-md-flex p-0 bg border-right fixed "
        ></Col>
        <Col md="3" sm="8" xs="12">
          <div className="d-flex flex-column h-100 justify-content-center">
            <div className="w-100 d-flex flex-column align-items-center justify-content-center font-weight-bold">
              <img src={wembiiImage} width={200} alt="login" />
              <span className="mb-3">
                {intl.formatMessage({ id: "app.createAnAccount" })}
              </span>
            </div>
            <Form onSubmit={onFormSubmit}>
              <FormGroup>
                <Label for="login-name">
                  {intl.formatMessage({ id: "app.nameTxt" })}
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="login-name"
                  placeholder={intl.formatMessage({ id: "app.nameTxt" })}
                  value={firstName}
                  onChange={(e) => setFirtName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login-lastName">
                  {intl.formatMessage({ id: "app.surnameTxt" })}
                </Label>
                <Input
                  type="text"
                  name="lastName"
                  id="login-lastName"
                  placeholder={intl.formatMessage({ id: "app.surnameTxt" })}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login-country">
                  {intl.formatMessage({ id: "app.countryTxt" })}
                </Label>
                <Input
                  type="text"
                  name="country"
                  id="login-country"
                  placeholder={intl.formatMessage({ id: "app.countryTxt" })}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login-email">
                  {intl.formatMessage({ id: "app.emailTxt" })}
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="login-email"
                  placeholder={intl.formatMessage({ id: "app.emailTxt" })}
                  invalid={!!email && !isEmailValid()}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {!!email && !isEmailValid() && (
                  <FormFeedback>
                    {intl.formatMessage({ id: "app.emailIsNotValid" })}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="login-phonetype">
                  {intl.formatMessage({ id: "app.phoneTypeTxt" })}
                </Label>
                <Input
                  type="select"
                  name="phonetype"
                  id="login-phonetype"
                  placeholder={intl.formatMessage({ id: "app.phoneTypeTxt" })}
                  value={phoneType}
                  onChange={(e) => setPhoneType(e.target.value)}
                >
                  <option value=""></option>
                  <option value="celphone">Celular</option>
                  <option value="telephone">Teléfono fijo</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="login-Phone">
                  {intl.formatMessage({ id: "app.phoneTxt" })}
                </Label>
                <PhoneInput
                  name="phone"
                  id="login-phone"
                  placeholder={intl.formatMessage({ id: "app.phoneTxt" })}
                  value={phoneNumber}
                  onChange={(v) => setPhoneNumber(v || "")}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login-password" className="w-100">
                  {intl.formatMessage({ id: "app.passwordTxt" })}
                  <div className="d-flex border">
                    <Input
                      type={visible ? "text" : "password"}
                      invalid={!!password && !isPasswordValid()}
                      name="password"
                      id="login-password"
                      placeholder={intl.formatMessage({
                        id: "app.passwordTxt",
                      })}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={toggleVisibility}
                      className=" border border-0"
                    >
                      <FontAwesomeIcon
                        className="d-flex fixed flex-col justify-content-center p-2 "
                        icon={visible ? faEyeSlash : faEye}
                        size="1x"
                      />
                    </button>
                  </div>
                </Label>
                {!!password && !isPasswordValid() && (
                  <p className="text-danger">
                    {intl.formatMessage({ id: "app.passwordIsNotValid" })}
                  </p>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="login-password">
                  {intl.formatMessage({ id: "app.confirmPaswoord" })}
                </Label>
                <Input
                  type="password"
                  name="passwordConfirmation"
                  id="login-password-confirmation"
                  placeholder={intl.formatMessage({
                    id: "app.confirmPaswoord",
                  })}
                  invalid={
                    !!password &&
                    !!passwordConfirmation &&
                    !isRepeatPasswordValid()
                  }
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                {!!password &&
                  !!passwordConfirmation &&
                  !isRepeatPasswordValid() && (
                    <FormFeedback>
                      {intl.formatMessage({ id: "app.passwordsDoNotMatch" })}
                    </FormFeedback>
                  )}
              </FormGroup>
              <UpdateLoadingCard
                loadingId={ACCOUNT_POST_LOADING}
                text={intl.formatMessage({ id: "app.creatingAccount" })}
              />
              {!isLoading(ACCOUNT_POST_LOADING) && (
                <Button color="primary" block disabled={disabled()}>
                  {intl.formatMessage({ id: "app.createAccount" })}
                </Button>
              )}
            </Form>
            <hr className="horizontal-separator my-4" />
            <div className="align-items-center d-flex flex-column justify-content-center mb-3">
              <span>{intl.formatMessage({ id: "app.haveaccount" })}</span>
              <Link to={routes.login}>
                {intl.formatMessage({ id: "app.login" })}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
