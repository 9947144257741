import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const emailRoot = (state: State) => state.email;

export const filesSelector = createSelector(emailRoot, ({ files }) =>
  files.ids.map((id) => files.entities[id])
);

export const messageSelector = createSelector(emailRoot, ({ message }) => message);

export const subjectSelector = createSelector(emailRoot, ({ subject }) => subject);
