import { produce } from "immer";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Company } from "../../me/interfaces/api/get-meCompany";
import { SessionAction, sessionClearAction } from "../../session/store/actions";
import { CompanyPayload, Plan } from "../interfaces/api/get-companies";
import { CompanyUser } from "../interfaces/api/get-company";
import { Setting } from "../interfaces/api/get-integration";
import {
  CompanyApiAction,
  deleteCompanyFailureAction,
  deleteCompanyInitAction,
  deleteCompanySuccessAction,
  getCompaniesFailureAction,
  getCompaniesInitAction,
  getCompaniesSuccessAction,
  getCompanyFailureAction,
  getCompanyInitAction,
  getCompanySuccessAction,
  postCompanyFailureAction,
  postCompanyInitAction,
  postCompanySuccessAction,
  putCompanyApiKeyFailureAction,
  putCompanyApiKeyInitAction,
  putCompanyApiKeySuccessAction,
  putCompanyAvatarFailureAction,
  putCompanyAvatarInitAction,
  putCompanyAvatarSuccessAction,
  putCompanyFailureAction,
  putCompanyInitAction,
  putCompanySuccessAction,
  getCompaniesFiltersSuccessAction,
  getCompanyIntegrationSuccessAction
} from "./api-actions";

export interface CompanyState {
  filters: {
    ids: string[];
    entities: Record<string, CompanyPayload>;
  };
  companies: {
    ids: string[];
    entities: Record<string, CompanyPayload>;
  };
  integration: Setting | null;
  plan: Plan | null;
  company: Company | null;
  users: {
    ids: number[];
    entities: Record<string, CompanyUser>;
  };
}

const initialState: CompanyState = {
  filters: {
    ids: [],
    entities: {},
  },
  companies: {
    ids: [],
    entities: {},
  },
  company: null,
  plan: null,
  integration: null,
  users: {
    ids: [],
    entities: {},
  },
};

const companyUsersAdapter = createEntityAdapter<CompanyUser>(
  (payload) => payload.id
);
const companiesAdapter = createEntityAdapter<CompanyPayload>(
  (payload) => payload.company.id
);

export default produce(
  (
    draft: CompanyState = initialState,
    action: CompanyApiAction | SessionAction
  ) => {
    switch (action.type) {
      case getCompanySuccessAction.type:
        companyUsersAdapter.addMany(action.users, draft.users);
        draft.company = action.company;
        draft.plan = action.companyPlan || null;
        return draft;
      case getCompaniesSuccessAction.type:
        companiesAdapter.addMany(action.companies, draft.companies);
        return draft;
      case getCompaniesFiltersSuccessAction.type:
        companiesAdapter.addMany(action.companiesFilters, draft.filters);
        return draft;
      case putCompanySuccessAction.type:
        draft.company = action.company.company;
        return draft;
      case putCompanyAvatarSuccessAction.type:
        draft.company = action.company.company;
        return draft;
      case deleteCompanySuccessAction.type:
        companiesAdapter.removeOne(action.id, draft.companies);
        return draft;
      case putCompanyApiKeySuccessAction.type:
        if (draft.company) {
          draft.company.apiKey = action.apiKey;
        }
        return draft;
      case getCompanyIntegrationSuccessAction.type:
        draft.integration = action.whatsappSetting;
        return draft;
      case sessionClearAction.type:
        return initialState;
      case getCompanyInitAction.type:
      case getCompanyFailureAction.type:
      case getCompaniesInitAction.type:
      case getCompaniesFailureAction.type:
      case postCompanyInitAction.type:
      case postCompanyFailureAction.type:
      case postCompanySuccessAction.type:
      case putCompanyInitAction.type:
      case putCompanyFailureAction.type:
      case deleteCompanyInitAction.type:
      case deleteCompanyFailureAction.type:
      case putCompanyAvatarInitAction.type:
      case putCompanyAvatarFailureAction.type:
      case putCompanyApiKeyInitAction.type:
      case putCompanyApiKeyFailureAction.type:
      default:
        return draft;
    }
  }
);
