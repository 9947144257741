import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { User } from "../../session/interfaces/models/user";
import { MeCompanyPayload } from "../interfaces/api/get-meCompany";
import { Sign } from "../interfaces/api/get-meSign";
import { SocialSettings } from "../interfaces/api/get-meSocialSettings";
import { UserSettings } from "../interfaces/api/get-meUserSettings";

export const getMeUserInitAction = createAction("[Me] Get User Init");
export const getMeUserSuccessAction = createAction(
  "[Me] Get User Success",
  props<{ user: User }>()
);
export const getMeUserFailureAction = createAction(
  "[Me] Get User Failure",
  props<{ error: string }>()
);

export const getMeCompaniesInitAction = createAction("[Me] Get Companies Init");
export const getMeCompaniesSuccessAction = createAction(
  "[Me] Get Companies Success",
  props<{ companies: MeCompanyPayload[] }>()
);
export const getMeCompaniesFailureAction = createAction(
  "[Me] Get Companies Failure",
  props<{ error: string }>()
);

export const getMeCompanyInitAction = createAction("[Me] Get Company Init");
export const getMeCompanySuccessAction = createAction(
  "[Me] Get Company Success",
  props<{ company: MeCompanyPayload }>()
);
export const getMeCompanyFailureAction = createAction(
  "[Me] Get Company Failure",
  props<{ error: string }>()
);

export const getMeSocialInitAction = createAction("[Me] Get Social Init");
export const getMeSocialSuccessAction = createAction(
  "[Me] Get Social Success",
  props<{ social: SocialSettings }>()
);
export const getMeSocialFailureAction = createAction(
  "[Me] Get Social Failure",
  props<{ error: string }>()
);

export const getMeUserSocialInitAction = createAction("[Me] Get User Social Init");
export const getMeUserSocialSuccessAction = createAction(
  "[Me] Get User Social Success",
  props<{ social: UserSettings }>()
);
export const getMeUserSocialFailureAction = createAction(
  "[Me] Get User Social Failure",
  props<{ error: string }>()
);

export const getMeAreasInitAction = createAction("[Me] Get Areas Init");
export const getMeAreasSuccessAction = createAction(
  "[Me] Get Areas Success",
  props<{ areas: any[] }>()
);
export const getMeAreasFailureAction = createAction(
  "[Me] Get Areas Failure",
  props<{ error: string }>()
);

export const putMeAvatarInitAction = createAction(
  "[Me] Put Avatar Init"
);
export const putMeAvatarSuccessAction = createAction(
  "[Me] Put Avatar Success",
  props<{ user: User }>()
);
export const putMeAvatarFailureAction = createAction(
  "[Me] Put Avatar Failure",
  props<{ error: string }>()
);

export const postMeLanguageInitAction = createAction(
  "[Me] Post Language Init"
);
export const postMeLanguageSuccessAction = createAction(
  "[Me] Post Language Success",
  props<{ user: User }>()
);
export const postMeLanguageFailureAction = createAction(
  "[Me] Post Language Failure",
  props<{ error: string }>()
);

export const putMePasswordInitAction = createAction(
  "[Me] Put Password Init"
);
export const putMePasswordSuccessAction = createAction(
  "[Me] Put Password Success",
  props<{ user: User }>()
);
export const putMePasswordFailureAction = createAction(
  "[Me] Put Password Failure",
  props<{ error: string }>()
);

export const getMeSignsInitAction = createAction("[Me] Get Me Signs Init");
export const getMeSignsSuccessAction = createAction(
  "[Me] Get Me Signs Success",
  props<{ signs: Sign[] }>()
);
export const getMeSignsFailureAction = createAction(
  "[Me] Get Me Signs Failure",
  props<{ error: string }>()
);

export const postMeSignInitAction = createAction(
  "[Me] Post Me Sign Init"
);
export const postMeSignSuccessAction = createAction(
  "[Me] Post Me Sign Success",
  props<{ sign: Sign }>()
);
export const postMeSignFailureAction = createAction(
  "[Me] Post Me Sign Failure",
  props<{ error: string }>()
);

export const putMeSignInitAction = createAction(
  "[Me] Put Me Sign Init"
);
export const putMeSignSuccessAction = createAction(
  "[Me] Put Me Sign Success",
  props<{ sign: Sign }>()
);
export const putMeSignFailureAction = createAction(
  "[Me] Put Me Sign Failure",
  props<{ error: string }>()
);

export const deleteMeSignInitAction = createAction(
  "[Me] Delete Me Sign Init"
);
export const deleteMeSignSuccessAction = createAction(
  "[Me] Delete Me Sign Success",
  props<{ id: string }>()
);
export const deleteMeSignFailureAction = createAction(
  "[Me] Delete Me Sign Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getMeUserInitAction,
  getMeUserSuccessAction,
  getMeUserFailureAction,
  getMeCompaniesInitAction,
  getMeCompaniesSuccessAction,
  getMeCompaniesFailureAction,
  getMeCompanyInitAction,
  getMeCompanySuccessAction,
  getMeCompanyFailureAction,
  getMeSocialInitAction,
  getMeSocialSuccessAction,
  getMeSocialFailureAction,
  getMeAreasInitAction,
  getMeAreasSuccessAction,
  getMeAreasFailureAction,
  putMeAvatarInitAction,
  putMeAvatarSuccessAction,
  putMeAvatarFailureAction,
  postMeLanguageInitAction,
  postMeLanguageSuccessAction,
  postMeLanguageFailureAction,
  putMePasswordInitAction,
  putMePasswordSuccessAction,
  putMePasswordFailureAction,
  getMeUserSocialInitAction,
  getMeUserSocialSuccessAction,
  getMeUserSocialFailureAction,
  getMeSignsInitAction,
  getMeSignsSuccessAction,
  getMeSignsFailureAction,
  postMeSignInitAction,
  postMeSignSuccessAction,
  postMeSignFailureAction,
  putMeSignInitAction,
  putMeSignSuccessAction,
  putMeSignFailureAction,
  deleteMeSignInitAction,
  deleteMeSignSuccessAction,
  deleteMeSignFailureAction,
});

export type MeApiAction = typeof actions;
