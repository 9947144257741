import React, { useCallback, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { AUTOMATIONS_LOADING, AUTOMATION_LOADING } from "../../constants/loading.constant";
import {
  AUTOMATION_CONTACTS_MODAL,
  AUTOMATION_CREATE_FORM,
  AUTOMATION_EDIT_FORM,
  BOARD_USERS_MODAL,
  CARD_TAGS_MODAL,
  CONTACT_EXPAND_CREATION_FORM,
  CONTACT_EXPAND_EDIT_FORM,
  DELETE_AUTOMATION_ITEM_MODAL,
  DELETE_AUTOMATION_MODAL,
  SIGN_EMAIL_FORM,
  TEMPLATE_EMAIL_FORM
} from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { NoData } from "../component/NoData";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";
import { useRouterCore } from "../../cores/router";
import { useAutomationCore } from "../../cores/automations";
import { AutomationRow } from "../component/automations/AutomationRow";
import CreateAutomationForm from "../component/automations/modals/CreateAutomationForm";
import EditAutomationForm from "../component/automations/modals/EditAutomationForm";
import SignEmailForm from "../component/gmail/modals/SignEmailForm";
import TemplateEmailForm from "../component/gmail/modals/TemplateEmailForm";
import { AUTOMATION_ACTION, AUTOMATION_CONDITION, CARD_TAGS } from "../../constants/internalModal.constant";

export const AutomationsScreen = () => {
  const intl = useIntl();
  const {
    isModal,
    turnOffModal,
    turnOnModal,
    turnOffInternalModal,
    getModalContent,
    isLoading,
    getModalVisibility,
  } = useGlobalCore();
  const {
    company,
    companyRole,
    companyPlan,
    isAdmin,
    isReseller
  } = useMeCore();
  const { goToSocial } = useRouterCore();
  const { getAutomations, automations } = useAutomationCore();

  useEffect(() => {
    if (
      !companyPlan?.rules.allowAutomation || (
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !companyRole?.automations &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  const onClickCreateContact = useCallback(() => {
    turnOnModal(AUTOMATION_CREATE_FORM, true);
  }, [turnOnModal]);

  useEffect(() => {
    getAutomations(company?.id!);
  }, [company, getAutomations])

  return (
    <Layout>
      <Header2
        title={`${intl.formatMessage({ id: "app.automations.title" })}`}
        rightChildren={
          <Button className="whiteButton" onClick={onClickCreateContact}>
            {intl.formatMessage({ id: "app.automations.createAutomation" })}
          </Button>
        }
      />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <UpdateLoadingCard
                loadingId={AUTOMATIONS_LOADING}
                text={intl.formatMessage({ id: "app.automations.updatingAutomations" })}
              />
              <Container>
                {automations.map((automation) => (
                  <AutomationRow key={automation.id} automation={automation} />
                ))}
                {automations.length === 0 && (
                  <NoData
                    image="generic4"
                    text={intl.formatMessage({ id: "app.automations.notAutomations" })}
                    horizontal
                  />
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(CONTACT_EXPAND_CREATION_FORM)}
        visible={getModalVisibility(CONTACT_EXPAND_CREATION_FORM)}
        onClose={() => turnOffModal(CONTACT_EXPAND_CREATION_FORM)}
        left
      >
        {getModalContent(CONTACT_EXPAND_CREATION_FORM)}
      </SideModal>
      <SideModal
        active={isModal(AUTOMATION_CREATE_FORM)}
        visible={getModalVisibility(AUTOMATION_CREATE_FORM)}
        onClose={() => turnOffModal(AUTOMATION_CREATE_FORM)}
      >
        <CreateAutomationForm />
      </SideModal>
      <SideModal
        active={isModal(AUTOMATION_EDIT_FORM)}
        visible={getModalVisibility(AUTOMATION_EDIT_FORM)}
        showSppiner={isLoading(AUTOMATION_LOADING)}
        onClose={() => {
          turnOffModal(AUTOMATION_EDIT_FORM);
          turnOffInternalModal(AUTOMATION_CONDITION);
          turnOffInternalModal(AUTOMATION_ACTION);
        }}
      >
        <EditAutomationForm />
      </SideModal>
      <SideModal
        active={isModal(CARD_TAGS_MODAL)}
        visible={getModalVisibility(CARD_TAGS_MODAL)}
        onClose={() => {
          turnOffModal(CARD_TAGS_MODAL);
          turnOffInternalModal(CARD_TAGS);
        }}
        left
      >
        {getModalContent(CARD_TAGS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_USERS_MODAL)}
        visible={getModalVisibility(BOARD_USERS_MODAL)}
        onClose={() => turnOffModal(BOARD_USERS_MODAL)}
        left
      >
        {getModalContent(BOARD_USERS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(DELETE_AUTOMATION_MODAL)}
        visible={getModalVisibility(DELETE_AUTOMATION_MODAL)}
        onClose={() => turnOffModal(DELETE_AUTOMATION_MODAL)}
        left
      >
        {getModalContent(DELETE_AUTOMATION_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(SIGN_EMAIL_FORM)}
        visible={getModalVisibility(SIGN_EMAIL_FORM)}
        onClose={() => turnOffModal(SIGN_EMAIL_FORM)}
        left
      >
        <SignEmailForm />
      </SideModal>
      <SideModal
        active={isModal(TEMPLATE_EMAIL_FORM)}
        visible={getModalVisibility(TEMPLATE_EMAIL_FORM)}
        onClose={() => turnOffModal(TEMPLATE_EMAIL_FORM)}
        left
      >
        <TemplateEmailForm />
      </SideModal>
      <SideModal
        active={isModal(AUTOMATION_CONTACTS_MODAL)}
        visible={getModalVisibility(AUTOMATION_CONTACTS_MODAL)}
        onClose={() => turnOffModal(AUTOMATION_CONTACTS_MODAL)}
        left
      >
        {getModalContent(AUTOMATION_CONTACTS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(DELETE_AUTOMATION_ITEM_MODAL)}
        visible={getModalVisibility(DELETE_AUTOMATION_ITEM_MODAL)}
        onClose={() => turnOffModal(DELETE_AUTOMATION_ITEM_MODAL)}
        left
      >
        {getModalContent(DELETE_AUTOMATION_ITEM_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_EXPAND_EDIT_FORM)}
        visible={getModalVisibility(CONTACT_EXPAND_EDIT_FORM)}
        onClose={() => turnOffModal(CONTACT_EXPAND_EDIT_FORM)}
        left
      >
        {getModalContent(CONTACT_EXPAND_EDIT_FORM)}
      </SideModal>
    </Layout>
  );
};
