import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Container, Label } from "reactstrap";
import { useWhatsappChatCore } from "../../../../cores/whatsappChat";
import { PanelWembii } from "../../PanelWembii";
import { useIntl } from "react-intl";
import PoweredBy from "../../PoweredBy";
import { TitleWembii } from "../../TitleWembii";
import { POSITION } from "../../../../constants/position.constant";

export default function ChatPreview() {
  const intl = useIntl();
  const { chat } = useWhatsappChatCore();
  
  return (
    <Container className="sticky-top p-0 z-index-50">
      <PanelWembii shadow className="p-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.chatConfigPreview.preview" })}
        </TitleWembii>

        <PanelWembii
          shadow
          header={
            <div
              className="d-flex align-items-center p-2 overflow-hidden"
              style={{
                color: chat?.config?.titleColor,
                backgroundColor: chat?.config?.backgroundColor,
                borderRadius: "20px 20px 0 0",
              }}
            >
              <FontAwesomeIcon
                icon={["fab", "whatsapp"]}
                size="3x"
                className="m-1"
              />
              <div className="d-flex flex-column p-2 overflow-hidden">
                <Label className="font-weight-bold">
                  {chat?.config?.title}
                </Label>
                <Label className="m-0">{chat?.config?.subtitle}</Label>
              </div>
            </div>
          }
        >
          <div
            className="overflow-auto hide-scrollbar"
            style={{ maxHeight: 300 }}
          >
            {chat?.whatsappUsers.length > 0 ? (
              chat?.whatsappUsers.map((whatsappUser) => (
                <div
                  key={whatsappUser.id}
                  className="d-flex justify-content-between align-items-center p-3 m-2 pointer bg-light"
                  style={{
                    borderLeft: "3px solid",
                    borderColor: chat?.config?.backgroundColor,
                  }}
                >
                  {whatsappUser.alias}

                  <FontAwesomeIcon
                    icon={["fab", "whatsapp"]}
                    size="1x"
                    color={chat?.config?.backgroundColor}
                    className="m-1"
                  />
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center text-center p-3 m-2 pointer bg-light">
                 {intl.formatMessage({ id: "app.configWsppPreview.thereIsPhone?" })}
              </div>
            )}
          </div>

          <div className="d-flex justify-content-center">
            <PoweredBy />
          </div>
        </PanelWembii>

        <div
          className={cn(["d-flex mt-2"], {
            "justify-content-end": chat?.config?.position === POSITION.RIGHT,
          })}
        >
          <Button
            className="shadow p-0 border-0"
            style={{
              width: 40,
              height: 40,
              backgroundColor: chat?.config?.openButtonColor,
            }}
          >
            <FontAwesomeIcon
              icon={["fas", "times"]}
              size="1x"
              color={chat?.config?.openButtonIconColor}
              className="pointer"
            />
          </Button>
        </div>
      </PanelWembii>
    </Container>
  );
}
