import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useIntl } from "react-intl";
import wembiiImage from "../../assets/wembii-logo.png";
import { ACCOUNT_PASSWORD_EMAIL_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../../cores/globals";
import { useRouterCore } from "../../cores/router";
import { useUserCore } from "../../cores/user";
import { PanelWembii } from "../component/PanelWembii";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";

export const ForgotPassword = () => {
  const intl = useIntl();
  const { isLoading } = useGlobalCore();
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const { routes } = useRouterCore();
  const { getForgotPassword } = useUserCore();

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    getForgotPassword(email, () => setSuccess(true));
  };

  const disabled = useCallback(() =>
    !email,
    [email]
  );

  return (
    <Container className="h-100" fluid>
      <Row className="h-100 w-100 m-0 d-flex align-items-center justify-content-center">
        <Col md="4" sm="6" xs="12">
          <PanelWembii className="p-4">
            <div className="w-100 d-flex flex-column align-items-center justify-content-center font-weight-bold">
              <img src={wembiiImage} width={200} alt="login" />
              <span className="mb-3">   
                 {intl.formatMessage({ id: "app.recoverYourPassword" })}
              </span>
              {success && (
                <span className="mb-3 text-primary text-center text-wrap">
                  {intl.formatMessage({ id: "app.ifThereIsEmail" })}
                </span>
              )}
            </div>
            <Form onSubmit={onFormSubmit}>
              <FormGroup>
                <Label for="login-email">
                   {intl.formatMessage({ id: "app.emailTxt" })}
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="login-email"
                  placeholder={intl.formatMessage({ id: "app.emailTxt" })}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <UpdateLoadingCard
                loadingId={ACCOUNT_PASSWORD_EMAIL_LOADING}
                text={intl.formatMessage({ id: "app.sendingEmail" })}
              />
              {!isLoading(ACCOUNT_PASSWORD_EMAIL_LOADING) && (
                <Button className="mb-2" color="primary" block disabled={disabled()}>
                  {intl.formatMessage({ id: "app.sendEmail" })}
                </Button>
              )}
            </Form>
            <hr className="horizontal-separator my-4" />
            <div className="align-items-center d-flex flex-column justify-content-center">
            <Link to={routes.login}>
              {intl.formatMessage({ id: "app.returnToLogin" })}
            </Link>
            </div>
          </PanelWembii>
        </Col>
      </Row>
    </Container>
  );
};
