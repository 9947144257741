import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useContactCore } from "../../../../cores/contacts";
import { InputWembii } from "../../InputWembii";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { useGlobalCore } from "../../../../cores/globals";
import { CONTACT_COMPANY_EXPAND_CREATION_FORM } from "../../../../constants/modal.constant";
import { isUrl } from "../../../utils/stringUtils";
import PhoneInput from "react-phone-number-input";
import { LabelWembii } from "../../LabelWembii";
import { CompanyOwner } from "../../../../cores/contacts/interfaces/api/get-company";
import OwnerContainer from "../../contacts/OwnerContainer";

export default function CreateCompanyForm() {
  const intl = useIntl();
  const { company, user } = useMeCore();
  const { turnOffModal } = useGlobalCore();
  const { postCompany, customFields } = useContactCore();
  
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [rut, setRut] = useState("");
  const [website, setWebsite] = useState("");
  const [sector, setSector] = useState("");
  const [employees, setEmployees] = useState("0");
  const [owners, setOwners] = useState<CompanyOwner[]>([
    user!
  ]);
  const [activeTab, setActiveTab] = useState("1");
  const [fields, setFields] = useState<any>({});

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    const ownerIds = owners.map((owner) => owner.id);
    
    postCompany(
      company?.id!, {
        name,
        address,
        city,
        country,
        phone,
        rut,
        website,
        sector,
        employees: parseInt(employees),
        customFields: fields,
        owners: ownerIds
      },
      () => {
        turnOffModal(CONTACT_COMPANY_EXPAND_CREATION_FORM);
        setName("");
        setAddress("");
      }
    )
  }

  useEffect(() => {
    customFields.forEach((field) => {
      fields[`${field.id}`] = "";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);

  const onSettingField = useCallback((value: string, field: string) => {
    const newFields = { ...fields };
    newFields[`${field}`] = value;
    setFields(newFields);
  }, [fields]);

  const isUrlInvalid = useMemo(() =>
    website !== '' && !isUrl(website)
    , [website]
  );

  const disabled = useCallback(
    () => !name || isUrlInvalid,
    [name, isUrlInvalid]
  );

  const onAddingOwner = useCallback((user) => {
    setOwners([
      ...owners,
      user
    ]);
  }, [owners]);

  const onRemovingOwner = useCallback((id) => {
    const newOwners = owners.filter((owner) => owner.id !== id);
    setOwners(newOwners);
  }, [owners]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.contacts.companies.createCompany" })}
            </h2>
            <div className="mb-2 d-flex justify-content-center flex-column align-items-center">
              <FontAwesomeIcon
                icon={["far", "building"]}
                className="mr-2 mb-2"
                size="6x"
                color={COLOR_PRIMARY}
              />          
            </div>
            <Nav tabs className="justify-content-center mt-2">
              <NavItem>
                <NavLink
                  className={cn("pointer", { active: activeTab === "1" })}
                  onClick={() => { setActiveTab("1") }}
                >
                  {intl.formatMessage({ id: "app.contacts.generalInformation" })}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={cn("pointer", { active: activeTab === "2" })}
                  onClick={() => { setActiveTab("2") }}
                >
                  {intl.formatMessage({ id: "app.contacts.customFields" })}
                </NavLink>
              </NavItem>
            </Nav>
            <Form className="px-2" onSubmit={onFormSubmit}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="py-4">
                  <div className="mb-2">
                    <LabelWembii>
                      {intl.formatMessage({ id: "app.contacts.owners" })}
                    </LabelWembii>
                    <OwnerContainer contacts={owners} onAdding={onAddingOwner} onRemoving={onRemovingOwner} />
                  </div>
                  <InputWembii
                    label={intl.formatMessage({ id: "app.contacts.companies.name" })}
                    type="text"
                    name="name"
                    id="company-name"
                    required
                    placeholder={intl.formatMessage({ id: "app.contacts.companies.name" })}
                    value={name}
                    onChange={setName}
                  />
                  <InputWembii
                    label={intl.formatMessage({ id: "app.contacts.companies.rut" })}
                    type="text"
                    name="sector"
                    id="company-rut" 
                    placeholder={intl.formatMessage({ id: "app.contacts.companies.rut" })}
                    value={rut}
                    onChange={setRut}
                  />
                  <InputWembii
                    label={intl.formatMessage({ id: "app.contacts.companies.sector" })}
                    type="text"
                    name="sector"
                    id="company-sector" 
                    placeholder={intl.formatMessage({ id: "app.contacts.companies.sector" })}
                    value={sector}
                    onChange={setSector}
                  />
                  <InputWembii
                    label={intl.formatMessage({ id: "app.contacts.companies.country" })}
                    type="text"
                    name="country"
                    id="company-country" 
                    placeholder={intl.formatMessage({ id: "app.contacts.companies.country" })}
                    value={country}
                    onChange={setCountry}
                  />
                  <InputWembii
                    label={intl.formatMessage({ id: "app.contacts.companies.city" })}
                    type="text"
                    name="city"
                    id="company-city" 
                    placeholder={intl.formatMessage({ id: "app.contacts.companies.city" })}
                    value={city}
                    onChange={setCity}
                  />
                  <InputWembii
                    label={intl.formatMessage({ id: "app.contacts.companies.address" })}
                    type="text"
                    name="address"
                    id="company-address" 
                    placeholder={intl.formatMessage({ id: "app.contacts.companies.address" })}
                    value={address}
                    onChange={setAddress}
                  />
                  <FormGroup>
                    <LabelWembii forid="company-phone">
                      {intl.formatMessage({ id: "app.contacts.companies.phone" })}
                    </LabelWembii>
                    <PhoneInput
                      name="phone"
                      id="company-ohone"
                      placeholder={intl.formatMessage({ id: "app.contacts.companies.phone" })}
                      value={phone}
                      onChange={(v) => setPhone(v || "")}
                    />
                  </FormGroup>
                  <InputWembii
                    label={intl.formatMessage({ id: "app.contacts.companies.website" })}
                    type="text"
                    name="website"
                    id="company-website"
                    invalid={isUrlInvalid}
                    placeholder={intl.formatMessage({ id: "app.contacts.companies.website" })}
                    value={website}
                    onChange={setWebsite}
                  />
                  <InputWembii
                    label={intl.formatMessage({ id: "app.contacts.companies.employees" })}
                    type="number"
                    name="employees"
                    id="company-employees"
                    className="w-50"
                    placeholder={intl.formatMessage({ id: "app.contacts.companies.employees" })}
                    value={employees}
                    onChange={setEmployees}
                  />
                </TabPane>
                <TabPane tabId="2" className="py-4">
                  {customFields.length === 0 && (
                    <div className="d-flex align-items-center justify-content-center text-muted my-2">
                      {intl.formatMessage({ id: "app.contacts.noCustomFields" })}
                    </div>
                  )}

                  {customFields.map((field) => (
                    <InputWembii
                      label={field.name}
                      type="text"
                      key={field.id}
                      placeholder={field.name}
                      onChange={(v) => onSettingField(v, field.id)}
                      value={fields[`${field.id}`]}
                    />
                  ))}
                </TabPane>
              </TabContent>

              <Button color="primary" block disabled={disabled()}>
                {intl.formatMessage({ id: "app.contacts.create" })}  
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
