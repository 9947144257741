import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { Button } from "reactstrap";
import {
  LINKED_IN_BUSINESS_GET_LOADING,
  LINKED_IN_BUSINESS_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useLinkedInBusinessCore } from "../../../../cores/linkedInBusiness";
import { APP_ID, SCOPE } from "../../../../cores/linkedInBusiness/config";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import SocialCard from "../SocialCard";
import LinkedInAccounts from "./LinkedInAccounts";
import { LinkedInBusinessIcon } from "./LinkedInBusinessIcon";

export default function LinkedInBusinessBindCard() {
  const intl = useIntl();
  const { get, settings, unbind, postOrganizations } =
    useLinkedInBusinessCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { routes } = useRouterCore();
  const { turnOnModal, isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.LinkedInBusiness,
      name: SocialProviderName.LinkedInBusiness,
      icon: <LinkedInBusinessIcon size="5x" active={!_.isEmpty(settings)} />,
      displayName: settings?.name,
      avatar: settings?.avatarUrl,
    }),
    [settings]
  );

  const remainingBindDays = useMemo(
    () =>
      settings?.expiresIn ? daysRemainingFromToday(settings?.expiresIn) : null,
    [settings]
  );

  const onFailure = useCallback((error: string) => console.error(error), []);
  const handleBind = useCallback(
    async ({ code }: { code: string }) => {
      const bindData = await postOrganizations(company!.id, code);
      bindData &&
        turnOnModal(
          LINKED_IN_ACCOUNTS_SIDE_MODAL_RIGHT,
          <LinkedInAccounts bindData={bindData} />
        );
    },
    [postOrganizations, company, turnOnModal]
  );

  const handleUnbind = useCallback(() => {
    unbind(company!.id, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  const button = useMemo(() => {
    return _.isEmpty(settings) ? (
      <LinkedIn
        clientId={APP_ID}
        onFailure={onFailure}
        onSuccess={handleBind}
        redirectUri={`${window.location.origin}${routes.linkedInPopUp}`}
        scope={SCOPE}
        renderElement={({ onClick }: { onClick: () => void }) => (
          <Button className="w-100 mx-auto" color="primary" onClick={onClick}>
            {intl.formatMessage({ id: "app.home.syncUp" })}
          </Button>
        )}
      ></LinkedIn>
    ) : (
      <Button
        className="w-100 "
        color="primary"
        outline
        onClick={handleUnbind}
      >  
        {intl.formatMessage({ id: "app.home.disconnect" })}
      </Button>
    );
  }, [handleBind, handleUnbind, onFailure, routes.linkedInPopUp, settings, intl]);

  return (
    <SocialCard
      social={socialProvider}
      bindLoading={isLoading(LINKED_IN_BUSINESS_SYNC_LOADING)}
      getLoading={isLoading(LINKED_IN_BUSINESS_GET_LOADING)}
      showSynchronizer={false}
      remainingBindDays={remainingBindDays}
    >
      <div className="w-100 px-2">
       
        {button}
      </div>
    </SocialCard>
  );
}
