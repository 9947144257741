import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import {
  BOARD_CARD_MODAL,
  DELETE_BOARD_ITEM_MODAL,
  MOVE_CARDS_MODAL,
} from "../../../../constants/modal.constant";
import { useBoardCore } from "../../../../cores/boards";
import { Board } from "../../../../cores/boards/interfaces/api/get-board";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import AreYouSure from "../../AreYouSure";
import ActivityContainer from "../creator/ActivityContainer";
import AttachmentContainer from "../creator/AttachmentContainer";
import DescriptionContainer from "../creator/DescriptionContainer";
import TextInput from "../creator/TextInput";
import { useIntl } from "react-intl";
import CardOptions from "../creator/CardOptions";
import { PutCardRequest } from "../../../../cores/boards/interfaces/api/put-card";
import { CardStatus } from "../../../../cores/boards/constants/card-type";
import InternalModal from "../../../layout/InternalModal";
import { CARD_PRODUCT } from "../../../../constants/internalModal.constant";
import { EVENT_CONFIG } from "../../../../cores/event/config";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant";
import { useEventCore } from "../../../../cores/event";
import { toast } from "react-toastify";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import MoveCards from "./MoveCards";

interface Props {
  onDeletion?: () => void;
  onSubmit?: () => void;
  board: Board | null;
}

export default function CardDetails(
  { onDeletion, onSubmit, board }: Props
) {
  const intl = useIntl();
  const { calendar } = useEventCore();
  const {
    company,
    user,
    isAdmin,
    isReseller,
    companyRole
  } = useMeCore();
  const {
    turnOnModal,
    turnOffModal,
    isInternalModal,
    turnOffInternalModal,
    getInternalModalContent,
  } = useGlobalCore();
  const {
    card,
    comments,
    emails,
    tasks,
    events,
    quotes,
    activities,
    lists,
    putCard,
    postComment,
    deleteCard,
    postTagging,
    getCard,
    getListBudgets,
    getFilters,
    getLists,
  } = useBoardCore();
  
  const [editTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState(card?.title!);

  useEffect(() => {
    setTitle(card?.title!);
  }, [card, lists]);

  const canModify = useMemo(() => 
    isAdmin ||
    isReseller ||
    companyRole?.owner ||
    companyRole?.admin ||
    board?.userRoles.find((role) => role.user.id === user?.id)?.owner ||
    card?.assignments.findIndex((usr) => usr.id === user?.id) !== -1 ||
    card?.assignments.length === 0
  , [isAdmin, isReseller, user, card, companyRole, board]);

  const toggleEditTitle = useCallback(() => {
    if (canModify)
      setEditTitle(!editTitle);
  }, [editTitle, canModify]);

  const onUpdateSubmit = useCallback(
    (body: PutCardRequest, close = true, users?: string[]) => {
      putCard(
        company?.id!,
        board?.id!,
        card?.list!,
        card?.id!,
        body,
        () => {
          onSubmit?.();
          if (users && users.length > 0 && body.description) {
            postTagging(
              company?.id!,
              board?.id!,
              card?.list!,
              card?.id!,
              users,
              body.description
            );
          }
          getCard(company?.id!, board?.id!, card?.list!, card?.id!);
        }
      );

      if (
        body.budget ||
        body.currency ||
        body.status === CardStatus.Lost ||
        body.status === CardStatus.Oppened || 
        body.status === CardStatus.Won
      ) {
        const filter = getFilters(board?.id!);
        getListBudgets(company?.id!, board?.id!, card?.list!, filter);
      }

      if (close) {
        setEditTitle(false);
      }
    },
    [company, board, card, putCard, postTagging, getCard, getListBudgets, getFilters, onSubmit]
  );

  const onDeleteCard = useCallback(() => {
    turnOnModal(
      DELETE_BOARD_ITEM_MODAL,
      <AreYouSure
        message={`${intl.formatMessage({ id: "app.crmBusiness.delete?" })} ${card?.title}?`}
        onSubmit={() => {
          deleteCard(company?.id!, board?.id!, card?.list!, card?.id!, () => {
            onDeletion?.();
            turnOffModal(BOARD_CARD_MODAL);
          });
          turnOffModal(DELETE_BOARD_ITEM_MODAL);
        }}
        onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
      />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    company,
    board,
    card,
    deleteCard,
    onDeletion,
    intl
  ]);

  const onAddCommentSubmit = (text: string, type: number, users: string[]) => {
    postComment(
      company?.id!,
      board?.id!,
      card?.list!,
      card?.id!,
      text,
      type,
      () => {
        if (users.length > 0) {
          postTagging(
            company?.id!,
            board?.id!,
            card?.list!,
            card?.id!,
            users,
            text
          );
        }
        getCard(company?.id!, board?.id!, card?.list!, card?.id!);
      }
    );
  };

  const onSharing = useCallback(() => {
    navigator.clipboard.writeText(`${EVENT_CONFIG.webUrl}/meeting/${calendar?.calendarId}/?card=${card?.id}`);
    toast.success(intl.formatMessage({ id: "app.calendar.share.copy" }));
  }, [intl, calendar, card]);

  const onOpeningMoveCards = useCallback(async () => {
    const listNames = await getLists(company?.id!, board?.id!);
    let suggested = [];
    if (listNames) {
      const listIndex = listNames.findIndex((lst) => lst.id === card?.list);
      suggested =  [-3, -2, -1, 1, 2, 3].reduce((prev: any, curr) => {
        const diff = listIndex + curr;
        if (diff >= 0 && diff < listNames.length) {
          return [
            ...prev,
            { 
              ...listNames[diff],
              right: curr > 0
            }
          ]
        } else {
          return prev;
        }
      }, []);
    }

    turnOnModal(
      MOVE_CARDS_MODAL,
      <MoveCards card={card!} board={board} suggested={suggested} />
    );
  }, [turnOnModal, getLists, company, card, board]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(CARD_PRODUCT)}
        onClose={() => turnOffInternalModal(CARD_PRODUCT)}
      >
        {getInternalModalContent(CARD_PRODUCT)}
      </InternalModal>
      <Container className="h-100" fluid>
        <span className="text-muted w-100 d-flex justify-content-between">
          <span>#{card?.cardId}</span>
          {!canModify && (
            <span>
              {intl.formatMessage({ id: "app.crmBusiness.readOnly" })}
            </span>
          )}
        </span>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 py-4">
              <div className="d-flex justify-content-center align-items-center position-sticky top-0 bg-white z-index-5">
                {!editTitle ? (
                  <h2
                    className="h2 text-center pointer"
                    onClick={toggleEditTitle}
                  >
                    {title}
                  </h2>
                ) : (
                  <TextInput
                    value={title!}
                    className="mb-2"
                    onSubmit={(title) => onUpdateSubmit({ title })}
                    toggleEdition={toggleEditTitle}
                    showChek={true}
                  />
                )}
              </div>
              {board?.type === BoardType.BUSINESS && (
                <div
                  className="text-center mb-3 text-primary pointer"
                  onClick={onSharing}
                >
                  Link de reuniones
                </div>
              )}

              <CardOptions
                canModify={canModify}
                board={board}
                onUpdateSubmit={onUpdateSubmit}
              />

              <DescriptionContainer
                canModify={canModify}
                type={board?.type!}
                onSubmit={(description, users) =>
                  onUpdateSubmit({ description }, true, users)
                }
              />

              <AttachmentContainer canModify={canModify} board={board} />

              <ActivityContainer
                board={board?.id!}
                comments={comments}
                emails={emails}
                tasks={tasks}
                events={events}
                activities={activities}
                quotes={quotes}
                onSubmit={onAddCommentSubmit}
              />

              {card?.user && (
                <div className="text-muted">
                  {intl.formatMessage(
                    { id: "app.crmBusiness.createdBy" },
                    {
                      user: `${card?.user.firstName} ${card?.user.lastName}`,
                      date: moment(card.createdAt).format("DD/MM/yyyy HH:mm"),
                    }
                  )}
                </div>
              )}
              {card?.createdByAutomation && (
                <div className="text-muted">
                  {intl.formatMessage(
                    { id: "app.crmBusiness.createdByAutomation" },
                    {
                      date: moment(card.createdAt).format("DD/MM/yyyy HH:mm"),
                    }
                  )}
                </div>
              )}

              {canModify && (
                <div className="d-flex justify-content-center mt-2">
                  <Button
                    className="m-1 upgradePlan"
                    onClick={onOpeningMoveCards}
                  >
                    {intl.formatMessage({
                      id: "app.crmBusiness.moveCards.title",
                    })}
                  </Button>
                  <Button className="m-1" color="danger" onClick={onDeleteCard}>
                    {intl.formatMessage({ id: "app.activity.delete" })}
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
