import cn from "classnames";
import QueryString from "query-string";
import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { useBoardCore } from "../../cores/boards";
import { useRouterCore } from "../../cores/router";
import Content from "../layout/Content";
import { useIntl } from "react-intl";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import { useMeCore } from "../../cores/me";
import SideModal from "../layout/SideModal";
import { useGlobalCore } from "../../cores/globals";
import {
  BOARD_CARD_MODAL,
  BOARD_CREATE_CONFIG,
  BOARD_EDIT_CONFIG,
  BOARD_FILES_MODAL,
  BOARD_FILE_PREVIEW_MODAL,
  BOARD_FILTERS_MODAL,
  BOARD_USERS_MODAL,
  CARD_TAGS_MODAL,
  BOARD_CUSTOM_FIELDS_FORM,
  DELETE_BOARD_ITEM_MODAL,
  COMMENT_TYPES_FORM,
} from "../../constants/modal.constant";
import { useLocation, useParams } from "react-router-dom";
import BoardPanel from "../component/boards/Board";
import { useCompanyCore } from "../../cores/company";
import CardDetails from "../component/boards/modal/CardDetails";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import {
  BOARD_CARD_LOADING,
  BOARD_ACTIVITY_EXPORT_LOADING,
  BOARD_LOADING,
} from "../../constants/loading.constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardTags from "../component/boards/modal/CardTags";
import { CardTag } from "../../cores/boards/interfaces/api/get-tags";
import {
  CARD_PRODUCT,
  CARD_TAGS,
  COMMENT_TYPES,
  CUSTOM_FIELD,
} from "../../constants/internalModal.constant";
import FiltersForm from "../component/boards/modal/FiltersForm";
import { Errors } from "../../constants/errors";
import { BoardType } from "../../cores/boards/constants/board-type.constant";
import CreateBoard from "../component/boards/modal/CreateBoard";
import EditBoard from "../component/boards/modal/EditBoard";
import UserBoardContainer from "../component/boards/creator/UserBoardContainer";
import CustomFields from "../component/boards/modal/CustomFields";
import Icofont from "react-icofont";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";
import { CSVLink } from "react-csv";
import { LoadingSpinner } from "../component/LoadingSpinner";

export default function ActivityBoard() {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const [exportData, setExportData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<{ label: string; key: string }[]>([]);

  const { search } = useLocation();
  const {
    isModal,
    isLoading,
    turnOffModal,
    turnOnModal,
    getModalContent,
    getModalVisibility,
    turnOffInternalModal,
  } = useGlobalCore();
  const { user, company, companyPlan, companyRole, isAdmin, isReseller } =
    useMeCore();
  const { goToActivityBoards, goToSocial } = useRouterCore();
  const { getCompany } = useCompanyCore();
  const { getBoardExport, customFields } = useBoardCore();
  const ref = useRef<any>(null);

  const {
    getActivityBoard,
    getCard,
    deleteTag,
    postTag,
    getFilters,
    cleanCard,
    putBoard,
    setBoardUpdate,
    getCustomFields,
    board: updateBoard,
    boardActivity: board,
    lists,
    tags,
    card,
    filters,
    errorCode,
  } = useBoardCore();

  const filter = useMemo(() => {
    const filter = getFilters(id);
    return filter;
  }, [id, getFilters]);
  const canExport = useMemo(
    () => companyRole?.owner || companyRole?.admin || isAdmin,
    [companyRole, isAdmin]
  );
  const onClickExport = useCallback(
    async (extension: string) => {
      if (!isLoading(BOARD_ACTIVITY_EXPORT_LOADING)) {
        const activities = await getBoardExport(
          company?.id!,
          board?.id!,
          filter
        );
        if (activities) {

          setHeaders([
            {
              label: intl.formatMessage({ id: "app.activity.activity" }),
              key: intl.formatMessage({ id: "app.activity.activity" }),
            },
            {
              label: intl.formatMessage({ id: "app.activity.description" }),
              key: intl.formatMessage({ id: "app.activity.description" }),
            },
            {
              label: intl.formatMessage({ id: "app.activity.inList" }),
              key: intl.formatMessage({ id: "app.activity.inList" }),
            },

            {
              label: intl.formatMessage({ id: "app.activity.tags" }),
              key: intl.formatMessage({ id: "app.activity.tags" }),
            },
            {
              label: intl.formatMessage({ id: "app.activity.users" }),
              key: intl.formatMessage({ id: "app.activity.users" }),
            },
            {
              label: intl.formatMessage({ id: "app.contacts.createdAt" }),
              key: intl.formatMessage({ id: "app.contacts.createdAt" }),
            },
          ]);
          const cards = activities.cards.map((card) => ({
            [intl.formatMessage({ id: "app.activity.activity" })]: card.title,
            [intl.formatMessage({ id: "app.activity.description" })]:
              card.description,
            [intl.formatMessage({
              id: "app.activity.inList",
            })]: `${card.list.name} (${card.list.percentage}%)`,

            [intl.formatMessage({ id: "app.activity.tags" })]:
              card.tags.join(", "),
            [intl.formatMessage({ id: "app.activity.users" })]:
              card.assignments.join(", "),
            [intl.formatMessage({ id: "app.contacts.createdAt" })]:
              card.createdAt,
          }));

          if (extension === "csv") {
            setExportData(cards);
            ref.current.link.click();
          } else {
            const wb = utils.book_new();
            const ws = utils.json_to_sheet(cards);

            utils.book_append_sheet(wb, ws);
            writeFileXLSX(
              wb,
              `${board?.name}_${moment().format("DD-MM-YYYY")}.xlsx`
            );
          }
        }
      }
    },
    [getBoardExport, isLoading, customFields, board, filter, company, intl]
  );

  const onClickCSVExport = useCallback(() => {
    onClickExport("csv");
  }, [onClickExport]);

  const onClickExcelExport = useCallback(() => {
    onClickExport("xlsx");
  }, [onClickExport]);

  const isFiltered = useMemo(() => {
    return filters[`${board?.id!}`];
  }, [filters, board]);

  useEffect(() => {
    if (
      !companyPlan?.rules.allowActivityBoard ||
      (!companyRole?.activities &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  useEffect(() => {
    getActivityBoard(company?.id!, id, filter);
  }, [id, company, filter, getActivityBoard]);

  useEffect(() => {
    const { cardId, listId } = QueryString.parse(search);
    if (cardId && listId) {
      getCard(company?.id!, board?.id!, listId as string, cardId as string);
      getCompany(company?.id!);
      turnOnModal(BOARD_CARD_MODAL, true);
    }
  }, [search, board, company, getCard, getCompany, turnOnModal]);

  const toggleFilterModal = useCallback(() => {
    turnOnModal(
      BOARD_FILTERS_MODAL,
      <FiltersForm
        board={board}
        onFilter={(filters, onSuccess) => {
          getActivityBoard(company?.id!, id, filters, true, onSuccess);
        }}
        onClearFilter={() => {
          getActivityBoard(company?.id!, id, {});
        }}
      />
    );
  }, [turnOnModal, getActivityBoard, company, id, board]);

  const onEditing = useCallback(() => {
    setBoardUpdate({
      name: board?.name!,
      color: board?.color!,
      currency: board?.currency!,
      roles: board?.userRoles!,
      isChanged: false,
    });

    turnOnModal(
      BOARD_EDIT_CONFIG,
      <EditBoard board={board!} onDelete={goToActivityBoards} />
    );
  }, [turnOnModal, setBoardUpdate, board, goToActivityBoards]);

  const toggleTag = useCallback(
    (cardTag: CardTag, selected: boolean) => {
      if (selected) {
        deleteTag(company?.id!, board?.id!, card?.id!, cardTag.id, () => {
          getCard(company?.id!, board?.id!, card?.list!, card?.id!);
        });
      } else {
        postTag(company?.id!, board?.id!, card?.id!, cardTag.id, () => {
          getCard(company?.id!, board?.id!, card?.list!, card?.id!);
        });
      }
    },
    [postTag, deleteTag, getCard, card, board, company]
  );

  const totalCards = useMemo(
    () => lists.reduce((prev, curr) => prev + curr.total, 0),
    [lists]
  );

  const canModify = useMemo(
    () =>
      isAdmin ||
      isReseller ||
      companyRole?.owner ||
      companyRole?.admin ||
      board?.userRoles.find((role) => role.user.id === user?.id)?.owner,
    [isAdmin, isReseller, companyRole, board, user]
  );

  const onClickCustomFields = useCallback(() => {
    getCustomFields(company?.id!, board?.id!);
    turnOnModal(BOARD_CUSTOM_FIELDS_FORM, true);
  }, [turnOnModal, getCustomFields, company, board]);

  return (
    <Layout>
      <Header2
        title={`${board?.name!} (${totalCards})`}
        bottomChildren={board && <UserBoardContainer board={board} />}
        rightChildren={
          <>
            {canExport && (
              <>
                <CSVLink
                  className="d-none"
                  headers={headers}
                  filename={`${board?.name}_${moment().format(
                    "DD-MM-YYYY"
                  )}.csv`}
                  data={exportData}
                  ref={ref}
                />
                <ButtonGroup className="ml-2">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      className="excelButton d-flex align-items-center"
                    >
                      <LoadingSpinner id={BOARD_ACTIVITY_EXPORT_LOADING} />
                      {intl.formatMessage({ id: "app.contacts.export" })}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={onClickCSVExport}>
                        <FontAwesomeIcon
                          icon={["fas", "file-csv"]}
                          size="1x"
                          color="green"
                          className="mr-2"
                        />
                        CSV
                      </DropdownItem>
                      <DropdownItem onClick={onClickExcelExport}>
                        <FontAwesomeIcon
                          icon={["fas", "file-excel"]}
                          size="1x"
                          color="green"
                          className="mr-2"
                        />
                        Excel
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ButtonGroup>
              </>
            )}
            <Button className="whiteButton mr-1" onClick={onEditing}>
              <Icofont className="mr-1 pointer" icon="edit" />
              {intl.formatMessage({ id: "app.activity.modify" })}
            </Button>
            <button
              className={cn("mx-1 btn", {
                whiteButton: !isFiltered,
                "btn-primary": isFiltered,
              })}
              onClick={toggleFilterModal}
            >
              <FontAwesomeIcon icon={["fas", "sliders-h"]} className="mr-2" />
              {intl.formatMessage({ id: "app.crmBusiness.filters" })}
            </button>
            <Button className="whiteButton ml-1" onClick={goToActivityBoards}>
              {intl.formatMessage({ id: "app.activity.goBack" })}
            </Button>

            {canModify && (
              <Button
                className="whiteButton ml-2"
                onClick={onClickCustomFields}
              >
                <FontAwesomeIcon icon={["fas", "gear"]} />
              </Button>
            )}
          </>
        }
      />

      <Content>
        <Container fluid>
          <UpdateLoadingCard
            loadingId={BOARD_LOADING}
            text={intl.formatMessage({ id: "app.activity.updatingBoard2" })}
          />
          <Row>
            <Col xs="12">
              <BoardPanel board={board} />
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(BOARD_CARD_MODAL)}
        visible={getModalVisibility(BOARD_CARD_MODAL)}
        onClose={() => {
          turnOffModal(BOARD_CARD_MODAL);
          turnOffInternalModal(CARD_PRODUCT);
          cleanCard();
        }}
        showSppiner={isLoading(BOARD_CARD_LOADING)}
        showError={errorCode === Errors.CardLoad}
      >
        <CardDetails
          board={board}
          onDeletion={() => {
            getActivityBoard(company?.id!, id, filter, false);
          }}
        />
      </SideModal>
      <SideModal
        active={isModal(BOARD_CREATE_CONFIG)}
        visible={getModalVisibility(BOARD_CREATE_CONFIG)}
        onClose={() => turnOffModal(BOARD_CREATE_CONFIG)}
      >
        <CreateBoard type={BoardType.ACTIVITY} />
      </SideModal>
      <SideModal
        active={isModal(BOARD_FILTERS_MODAL)}
        visible={getModalVisibility(BOARD_FILTERS_MODAL)}
        onClose={() => turnOffModal(BOARD_FILTERS_MODAL)}
      >
        {getModalContent(BOARD_FILTERS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_EDIT_CONFIG)}
        visible={getModalVisibility(BOARD_EDIT_CONFIG)}
        onClose={() => {
          turnOffModal(BOARD_EDIT_CONFIG);
          if (updateBoard.isChanged) {
            putBoard(
              company?.id!,
              board?.id!,
              updateBoard.name,
              updateBoard.color,
              updateBoard.currency,
              updateBoard.roles
            );
          }
        }}
      >
        {getModalContent(BOARD_EDIT_CONFIG)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_CUSTOM_FIELDS_FORM)}
        visible={getModalVisibility(BOARD_CUSTOM_FIELDS_FORM)}
        onClose={() => {
          turnOffModal(BOARD_CUSTOM_FIELDS_FORM);
          turnOffInternalModal(CUSTOM_FIELD);
        }}
      >
        <CustomFields board={board?.id!} />
      </SideModal>
      <SideModal
        active={isModal(DELETE_BOARD_ITEM_MODAL)}
        visible={getModalVisibility(DELETE_BOARD_ITEM_MODAL)}
        onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
        left
      >
        {getModalContent(DELETE_BOARD_ITEM_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_USERS_MODAL)}
        visible={getModalVisibility(BOARD_USERS_MODAL)}
        onClose={() => turnOffModal(BOARD_USERS_MODAL)}
        left
      >
        {getModalContent(BOARD_USERS_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_FILES_MODAL)}
        visible={getModalVisibility(BOARD_FILES_MODAL)}
        onClose={() => turnOffModal(BOARD_FILES_MODAL)}
        left
      >
        {getModalContent(BOARD_FILES_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(BOARD_FILE_PREVIEW_MODAL)}
        visible={getModalVisibility(BOARD_FILE_PREVIEW_MODAL)}
        onClose={() => turnOffModal(BOARD_FILE_PREVIEW_MODAL)}
        left
      >
        {getModalContent(BOARD_FILE_PREVIEW_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CARD_TAGS_MODAL)}
        visible={getModalVisibility(CARD_TAGS_MODAL)}
        onClose={() => {
          turnOffModal(CARD_TAGS_MODAL);
          turnOffInternalModal(CARD_TAGS);
        }}
        left
      >
        <CardTags onSelecting={toggleTag} tags={tags} board={board?.id!} />
      </SideModal>
      <SideModal
        active={isModal(COMMENT_TYPES_FORM)}
        visible={getModalVisibility(COMMENT_TYPES_FORM)}
        onClose={() => {
          turnOffModal(COMMENT_TYPES_FORM);
          turnOffInternalModal(COMMENT_TYPES);
        }}
      >
        {getModalContent(COMMENT_TYPES_FORM)}
      </SideModal>
    </Layout>
  );
}
