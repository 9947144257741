import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Col, Form, Row } from "reactstrap";
import { useBoardCore } from "../../../../cores/boards";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { CardTag } from "../../../../cores/boards/interfaces/api/get-tags";
import { InputWembii } from "../../InputWembii";
import { useGlobalCore } from "../../../../cores/globals";
import { CARD_TAGS } from "../../../../constants/internalModal.constant";

interface Props {
  board: string;
  tag?: CardTag;
  onSuccess: () => void;
}

export default function TagForm({ tag, board, onSuccess }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const { turnOffInternalModal } = useGlobalCore();
  const { postCardTag, putCardTag, deleteCardTag } = useBoardCore();

  const [name, setName] = useState("");
  const [textColor, setTextColor] = useState("#fff");
  const [backgroundColor, setBackgroundColor] = useState("#000");

  useEffect(() => {
    if (tag) {
      setTextColor(tag.textColor);
      setBackgroundColor(tag.backgroundColor);
      setName(tag.name);
    }
  }, [tag]);

  const cleanForm = useCallback(() => {
      setTextColor("#fff");
      setBackgroundColor("#000");
      setName("");
      onSuccess();
      turnOffInternalModal(CARD_TAGS);
  }, [turnOffInternalModal, onSuccess]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!tag) {
      postCardTag(
        company?.id!,
        board,
        name,
        textColor,
        backgroundColor,
        cleanForm
      );
    } else {
      putCardTag(
        company?.id!,
        board,
        tag.id,
        name,
        textColor,
        backgroundColor,
        cleanForm
      );
    }
  };

  const onDelete = useCallback(() => {
    deleteCardTag(
      company?.id!,
      board,
      tag?.id!,
      cleanForm
    );
  }, [deleteCardTag, cleanForm, company, tag, board]);

  const disabled = useCallback(
    () => !name || !textColor || !backgroundColor,
    [name, textColor, backgroundColor]
  );

  return (
    <Form className="p-2" onSubmit={onSubmit}>
      <Row>
        <Col>
          <InputWembii
            label={intl.formatMessage({ id: "app.activity.tags.textColor" })}
            type="color"
            name="color"
            id="tag-color"
            className="px-1"
            required
            placeholder={intl.formatMessage({ id: "app.activity.tags.textColor" })}
            value={textColor}
            onChange={setTextColor}
          />
          <InputWembii
            label={intl.formatMessage({ id: "app.activity.tags.backgroundColor" })}
            type="color"
            name="color"
            id="tag-color"
            className="pl-1"
            required
            placeholder={intl.formatMessage({ id: "app.activity.tags.backgroundColor" })}
            value={backgroundColor}
            onChange={setBackgroundColor}
          />
        </Col>
        <Col>
          <InputWembii
            label={intl.formatMessage({ id: "app.activity.tags.name" })}
            type="text"
            name="name"
            id="tag-name"
            className="pr-1"
            required
            placeholder={intl.formatMessage({ id: "app.activity.tags.name" })}
            value={name}
            onChange={setName}
          />
          {name && (
            <Badge pill className="my-2" style={{ backgroundColor: backgroundColor }}>
              <div className="px-2 py-1">
                <span className="h6 mb-0 font-weight-bold" style={{ color: textColor }}>
                  {name}
                </span>
              </div>
            </Badge>
          )}
        </Col>
      </Row>

      {tag && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.activity.tags.delete" })}  
        </Button>
      )}
      <Button color="primary" disabled={disabled()} className="ml-1">
        {tag 
          ? intl.formatMessage({ id: "app.activity.tags.update" })
          : intl.formatMessage({ id: "app.activity.tags.create" })
        }  
      </Button>
    </Form>
  );
}