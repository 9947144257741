import React, { useCallback, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { ADVERTISEMENT_LOADING } from "../../constants/loading.constant";
import { useAdvertisementCore } from "../../cores/advertisements";
import { useFacebookAdsCore } from "../../cores/facebookAds";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { useIntl } from "react-intl";
import CampaingsTable from "../component/advertisements/CampaignsTable";
import { NoData } from "../component/NoData";
import { PanelWembii } from "../component/PanelWembii";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalCore } from "../../cores/globals";
import { MORE_INFO_FACEBOOK_CAMPAIGN } from "../../constants/modal.constant";
import SideModal from "../layout/SideModal";
import { TooltipWembii } from "../component/TooltipWembii";
import { Position } from "../../cores/globals/interfaces/globalEntity";
import { YoutubeModal } from "../component/YoutubeModal";

export default function FacebookCampaigns() {
  const intl = useIntl();
  const { goToCreateCampaign, goToSocial } = useRouterCore();
  const { company, companyPlan, companyRole, isAdmin, isReseller } =
    useMeCore();
  const { campaigns, getCampaigns, getMinBudget } = useAdvertisementCore();
  const { turnOnModal, turnOffModal, isModal, getModalVisibility, isLoading } =
    useGlobalCore();

  const { settings } = useFacebookAdsCore();

  const openMoreInfo = useCallback(() => {
    turnOnModal(MORE_INFO_FACEBOOK_CAMPAIGN, true, Position.LEFT);
  }, [turnOnModal]);

  useEffect(() => {
    if (
      !companyPlan?.rules.allowFacebookAdvertisements ||
      (!companyRole?.ads &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  useEffect(() => {
    if (settings && settings.accountId) {
      getCampaigns(company?.id!, settings.accountId);
      getMinBudget(company?.id!, settings.accountId, settings.currency);
    }
  }, [company, getCampaigns, getMinBudget, settings]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.advertisements.title" })}
        rightChildren={
          <>
            <FontAwesomeIcon
              icon={["fas", "info-circle"]}
              size="2x"
              className="pointer m-2 ml-2"
              color="gray"
              id="info-campaign"
            />
            <TooltipWembii
              id="info-campaign"
              text={intl.formatMessage({ id: "app.tooltipInfo.campaign" })}
            />
            <FontAwesomeIcon
              icon={["fas", "video"]}
              size="2x"
              className="pointer m-2 ml-2"
              onClick={openMoreInfo}
              color="gray"
            />
            {campaigns.length > 0 && (
              <Button className="whiteButton" onClick={goToCreateCampaign}>
                {intl.formatMessage({
                  id: "app.advertisements.createCampaing",
                })}
              </Button>
            )}
          </>
        }
      />
      <Content>
        <UpdateLoadingCard
          loadingId={ADVERTISEMENT_LOADING}
          text={intl.formatMessage({
            id: "app.advertisements.updatingCampaigns",
          })}
        />
        <Container>
          {campaigns.length > 0 && !isLoading(ADVERTISEMENT_LOADING) ? (
            <Row>
              <Col xs="12">
                <PanelWembii
                  shadow
                  className="mb-4 p-4"
                  style={{
                    height: "calc(100vh - 300px)",
                  }}
                >
                  <CampaingsTable />
                </PanelWembii>
              </Col>
            </Row>
          ) : (
            <NoData
              image="generic6"
              text={intl.formatMessage({
                id: "app.advertisements.notHaveCampaign",
              })}
              actionLabel={intl.formatMessage({
                id: "app.advertisements.createNewCampaing",
              })}
              action={goToCreateCampaign}
              horizontal
            />
          )}
        </Container>
        <SideModal
          active={isModal(MORE_INFO_FACEBOOK_CAMPAIGN)}
          visible={getModalVisibility(MORE_INFO_FACEBOOK_CAMPAIGN)}
          onClose={() => turnOffModal(MORE_INFO_FACEBOOK_CAMPAIGN)}
          left
        >
          <YoutubeModal
            title="Como hacer campañas de Anuncios para tus Redes"
            videoId="S42uZX6sZd0"
          />
        </SideModal>
      </Content>
    </Layout>
  );
}
