import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Col,
  Container,
  Row,
} from "reactstrap";
import { TEMPLATES_SOCIAL_FORM, TEMPLATE_SOCIAL_FORM } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useMeCore } from "../../../../cores/me";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { useTemplateCore } from "../../../../cores/templates";
import { RadiobuttonWembii } from "../../RadiobuttonWembii";

const TemplateType = {
  all: 0,
  global: 1,
  user: 2
}

export default function TemplatesForm() {
  const intl = useIntl();
  const { clear, addText } = useSocialPostCore();
  const { socialTemplates } = useTemplateCore();
  const {
    company,
  } = useMeCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const [selected, setSelected] = useState(0);
  const [templateType, setTemplateType] = useState(TemplateType.all);

  const onSelecting = useCallback((template) => {
    clear();
    addText(template.message);
    turnOffModal(TEMPLATES_SOCIAL_FORM);
  },[clear, addText, turnOffModal]);

  const onChangingFilter = useCallback((type) => {
    setSelected(0);
    setTemplateType(type);
  },[]);

  const openTemplateModal = useCallback(() => {
    turnOffModal(TEMPLATES_SOCIAL_FORM);
    turnOnModal(TEMPLATE_SOCIAL_FORM, true, Position.LEFT);
  }, [turnOnModal, turnOffModal]);

  const templatesList = useMemo(() => 
    (templateType === TemplateType.all)
      ? socialTemplates
      : (templateType === TemplateType.global)
        ? socialTemplates.filter((template) => template.global)
        : socialTemplates.filter((template) => !template.global)
  , [socialTemplates, templateType]);

  const template = useMemo(() => 
    templatesList[selected] || null
  , [templatesList, selected]);

  const increase = useCallback(() => {
    if (selected < templatesList.length - 1) {
      setSelected(selected + 1);
    }
  }, [selected, templatesList]);

  const decrease = useCallback(() => {
    if (selected > 0) {
      setSelected(selected - 1);
    }
  }, [selected]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column justify-content-between h-100 p-3">
            <div>
              <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
                {intl.formatMessage({ id: "app.socialStory.template" })}
              </h2>

              <div className="d-flex align-items-center justify-content-around">
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.socialStory.template.all" })}
                  checked={templateType === TemplateType.all}
                  onChange={() => onChangingFilter(TemplateType.all)}
                />
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.socialStory.template.recommended" })}
                  checked={templateType === TemplateType.global}
                  onChange={() => onChangingFilter(TemplateType.global)}
                />
                <RadiobuttonWembii
                  label={company?.name}
                  checked={templateType === TemplateType.user}
                  onChange={() => onChangingFilter(TemplateType.user)}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <FontAwesomeIcon
                icon={["fas", "angle-left"]}
                size="2x"
                className="mr-4 pointer textEditor-icon"
                onClick={decrease}
              />

              <div className="d-flex align-items-center align-items-between">
                {template ? (
                  <div className="d-flex flex-column align-items-center">
                    <h3 className="mb-3">{template.name}</h3>
                    <div className="pre-wrap">{template.message}</div>

                    <div className="text-muted mt-3">
                      {selected + 1} / {templatesList.length}
                    </div>

                    <Button color="primary" className="mt-3" onClick={() => onSelecting(template)}>
                      {intl.formatMessage({ id: "app.socialStory.template.select" })}
                    </Button>
                  </div>
                ) : (
                  <label className="text-muted">
                    No existe ningun template
                  </label>
                )}
              </div>

              <FontAwesomeIcon
                icon={["fas", "angle-right"]}
                size="2x"
                className="ml-4 pointer textEditor-icon"
                onClick={increase}
              />
            </div>
            
            <div className="mt-3">
              <Button
                className="my-1"
                color="white"
                block
                onClick={openTemplateModal}
              >
                {intl.formatMessage({ id: "app.socialStory.template.manage" })}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
