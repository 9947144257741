import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Company } from "../../me/interfaces/api/get-meCompany";
import { CompanyPayload, Plan } from "../interfaces/api/get-companies";
import { CompanyUser } from "../interfaces/api/get-company";
import { Setting } from "../interfaces/api/get-integration";

export const postCompanyInitAction = createAction(
  "[Company] Post Company Init"
);
export const postCompanySuccessAction = createAction(
  "[Company] Post Company Success"
);
export const postCompanyFailureAction = createAction(
  "[Company] Post Company Failure",
  props<{ error: string }>()
);

export const putCompanyInitAction = createAction(
  "[Company] Put Company Init"
);
export const putCompanySuccessAction = createAction(
  "[Company] Put Company Success",
  props<{ company: CompanyPayload }>()
);
export const putCompanyFailureAction = createAction(
  "[Company] Put Company Failure",
  props<{ error: string }>()
);

export const putCompanyApiKeyInitAction = createAction(
  "[Company] Put Company ApiKey Init"
);
export const putCompanyApiKeySuccessAction = createAction(
  "[Company] Put Company ApiKey Success",
  props<{ apiKey: string }>()
);
export const putCompanyApiKeyFailureAction = createAction(
  "[Company] Put Company ApiKey Failure",
  props<{ error: string }>()
);

export const getCompanyInitAction = createAction("[Company] Get Company Init");
export const getCompanySuccessAction = createAction(
  "[Company] Get Company Success",
  props<{
    company: Company;
    users: CompanyUser[];
    companyPlan: Plan | undefined;
  }>()
);
export const getCompanyFailureAction = createAction(
  "[Company] Get Company Failure",
  props<{ error: string }>()
);

export const getCompaniesInitAction = createAction(
  "[Company] Get Companies Init"
);
export const getCompaniesSuccessAction = createAction(
  "[Company] Get Companies Success",
  props<{ companies: CompanyPayload[]}>()
);

export const getCompaniesFiltersSuccessAction = createAction(
  "[Company] Get Companies Filters Success",
  props<{companiesFilters: CompanyPayload[] }>()
);

export const getCompaniesFailureAction = createAction(
  "[Company] Get Companies Failure",
  props<{ error: string }>()
);

export const deleteCompanyInitAction = createAction(
  "[Company] Delete Company Init"
);
export const deleteCompanySuccessAction = createAction(
  "[Company] Delete Company Success",
  props<{ id: string }>()
);
export const deleteCompanyFailureAction = createAction(
  "[Company] Delete Company Failure",
  props<{ error: string }>()
);

export const putCompanyAvatarInitAction = createAction(
  "[Company] Put Company Avatar Init"
);
export const putCompanyAvatarSuccessAction = createAction(
  "[Company] Put Company Avatar Success",
  props<{ company: CompanyPayload }>()
);
export const putCompanyAvatarFailureAction = createAction(
  "[Company] Put Company Avatar Failure",
  props<{ error: string }>()
);

export const getCompanyIntegrationInitAction = createAction(
  "[Company] Get Company Integration Init"
);
export const getCompanyIntegrationSuccessAction = createAction(
  "[Company] Get Company Integration Success",
  props<{ whatsappSetting: Setting }>()
);
export const getCompanyIntegrationFailureAction = createAction(
  "[Company] Get Company Integration Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postCompanyInitAction,
  postCompanySuccessAction,
  postCompanyFailureAction,
  getCompanyInitAction,
  getCompanySuccessAction,
  getCompanyFailureAction,
  getCompaniesInitAction,
  getCompaniesSuccessAction,
  getCompaniesFailureAction,
  getCompaniesFiltersSuccessAction,
  putCompanyInitAction,
  putCompanySuccessAction,
  putCompanyFailureAction,
  deleteCompanyInitAction,
  deleteCompanySuccessAction,
  deleteCompanyFailureAction,
  putCompanyAvatarInitAction,
  putCompanyAvatarSuccessAction,
  putCompanyAvatarFailureAction,
  putCompanyApiKeyInitAction,
  putCompanyApiKeySuccessAction,
  putCompanyApiKeyFailureAction,
  getCompanyIntegrationInitAction,
  getCompanyIntegrationSuccessAction,
  getCompanyIntegrationFailureAction,
});

export type CompanyApiAction = typeof actions;
