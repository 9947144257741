import React, { useCallback, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useWebChatCore } from "../../cores/conversations";
import { useMeCore } from "../../cores/me";
import ChatPreview from "../component/chat/web/ChatPreview";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";
import { useGlobalCore } from "../../cores/globals";
import {
  CONFIGURE_CHAT_AREA_SIDE_MODAL,
  CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL,
  MORE_INFO_CHAT_WEB,
} from "../../constants/modal.constant";
import { Header2 } from "../layout/Header2";
import GeneralForm from "../component/chat/web/creator/GeneralForm";
import NotificationForm from "../component/chat/web/creator/NotificationForm";
import InstalationForm from "../component/chat/web/creator/InstalationForm";
import CommertialTimeForm from "../component/chat/web/creator/CommertialTimeForm";
import { useRouterCore } from "../../cores/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipWembii } from "../component/TooltipWembii";
import { Position } from "../../cores/globals/interfaces/globalEntity";
import { YoutubeModal } from "../component/YoutubeModal";

export default function EditWebChat() {
  const intl = useIntl();
  const { company, companyPlan, companyRole, isAdmin, isReseller } =
    useMeCore();
  const { chat, avatar, getChat, putChat, postChatAvatar } = useWebChatCore();
  const {
    isModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
    turnOnModal,
  } = useGlobalCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    if (
      !companyPlan?.rules.allowWebChat ||
      (!companyRole?.chat &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  useEffect(() => {
    getChat(company?.id!);
  }, [getChat, company]);

  useEffect(() => {}, [chat]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    putChat(
      company?.id!,
      chat?.config?.id!,
      chat?.config?.title!,
      chat?.config?.subtitle!,
      chat?.config?.titleColor!,
      chat?.config?.backgroundColor!,
      chat?.config?.startButtonColor!,
      chat?.config?.startChat!,
      chat?.config?.openButtonColor!,
      chat?.config?.openButtonIconColor!,
      chat?.config?.welcomeMessage!,
      chat?.config?.commercialTime!,
      chat?.config?.offline!,
      chat?.config?.avatar!,
      chat?.config?.isActive!
    );

    if (avatar) {
      postChatAvatar(company?.id!, chat?.config?.id!, avatar);
    }
  };

  const openMoreInfo = useCallback(() => {
    turnOnModal(MORE_INFO_CHAT_WEB, true, Position.LEFT);
  }, [turnOnModal]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.chatConfig.tittle" })}
        rightChildren={
          <>
            <FontAwesomeIcon
              icon={["fas", "info-circle"]}
              size="2x"
              className="pointer m-2 mr-2"
              color="grey"
              id="info-webChat"
            />
            <FontAwesomeIcon
              icon={["fas", "video"]}
              size="2x"
              className="pointer m-2 mr-3"
              color="grey"
              onClick={openMoreInfo}
            />
            <TooltipWembii
              id="info-webChat"
              text={intl.formatMessage({ id: "app.tooltipInfo.webChat" })}
            />
          </>
        }
      />
      <Content>
        <Container fluid>
          <Row>
            <Col md="8" sm="12">
              <GeneralForm onSubmit={onFormSubmit} />
              <CommertialTimeForm onSubmit={onFormSubmit} />
              <NotificationForm />
              <InstalationForm />
            </Col>
            <Col md="4" sm="12">
              <ChatPreview />
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(CONFIGURE_CHAT_AREA_SIDE_MODAL)}
        visible={getModalVisibility(CONFIGURE_CHAT_AREA_SIDE_MODAL)}
        onClose={() => turnOffModal(CONFIGURE_CHAT_AREA_SIDE_MODAL)}
      >
        {getModalContent(CONFIGURE_CHAT_AREA_SIDE_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL)}
        visible={getModalVisibility(CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL)}
        onClose={() => turnOffModal(CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL)}
      >
        {getModalContent(CONFIGURE_CHAT_COMMERCIAL_TIME_SIDE_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(MORE_INFO_CHAT_WEB)}
        visible={getModalVisibility(MORE_INFO_CHAT_WEB)}
        onClose={() => turnOffModal(MORE_INFO_CHAT_WEB)}
        left
      >
        <YoutubeModal
          title="Cómo insertar el Widget de Chat"
          videoId="QF0x3FVNF6A"
        />
      </SideModal>
    </Layout>
  );
}
