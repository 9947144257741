import React from "react";
import { InputWembii } from "../InputWembii";
import { LabelWembii } from "../LabelWembii";
import { Page } from "./Page";
import { useIntl } from "react-intl";

interface Props {
  perPageOption?: number[];
  setPerPage?: (value: number) => void;
  perPage?: number;
  page: number;
  setPage: (value: number) => void;
  className?: string;
  disabledPages?: boolean;
  hideTitle?: boolean;
}

export const PaginationWemmbii = (props: Props) => {
  const intl = useIntl();
  const {
    className,
    perPageOption = [10, 15, 20, 100, 500],
    setPerPage,
    perPage,
    page,
    setPage,
    hideTitle,
    disabledPages: disabled = false,
  } = props;
  return (
    <div className={className}>
      {hideTitle ? (
        <></>
      ) : (
        <LabelWembii>
          {intl.formatMessage({ id: "app.contacts.page" })}
        </LabelWembii>
      )}
      <div className="d-flex align-items-center">
        {page === 1 && (
          <div className="d-flex justify-content-center align-items-center mr-2">
            <Page pageNumber={page} onClick={() => setPage(page)} active />
            <Page
              pageNumber={page + 1}
              onClick={() => setPage(page + 1)}
              disabled={disabled}
            />
            <Page
              pageNumber={page + 2}
              onClick={() => setPage(page + 2)}
              disabled={disabled}
            />
          </div>
        )}
        {page !== 1 && (
          <div className="d-flex justify-content-center align-items-center mr-2">
            {page > 2 && (
              <>
                <Page pageNumber={1} onClick={() => setPage(1)} />
                ...
              </>
            )}
            <Page pageNumber={page - 1} onClick={() => setPage(page - 1)} />
            <Page pageNumber={page} onClick={() => setPage(page)} active />
            <Page
              pageNumber={page + 1}
              onClick={() => setPage(page + 1)}
              disabled={disabled}
            />
          </div>
        )}
        {setPerPage && perPage !== 0 && (
          <InputWembii
            type="select"
            onChange={(value) => setPerPage(value)}
            value={perPage}
            mb={false}
          >
            {perPageOption.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </InputWembii>
        )}
      </div>
    </div>
  );
};
