import React, { useCallback, useState } from "react";
import { Badge, Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMeCore } from "../../../../cores/me";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { InputWembii } from "../../InputWembii";

interface Props {
  onClose: () => void;
}

export default function HashtagForm({ onClose }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const { postHashtag } = useSocialPostCore();

  const [name, setName] = useState("");

  const cleanForm = useCallback(() => {
    setName("");
    onClose();
  }, [onClose]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    postHashtag(
      company?.id!,
      `#${name}`,
      cleanForm
    );
  };

  const disabled = useCallback(
    () => !name,
    [name]
  );

  return (
    <div
      className="h-100 w-100 position-fixed top-0 z-index-5 d-flex align-items-center justify-content-center bg-black-transparent"
      style={{ borderRadius: "30px" }}
    >
      <Form className="text-center bg-white rounded p-2" onSubmit={onSubmit}>
        <div className="text-right w-100">
          <FontAwesomeIcon
            icon={["fas", "times"]}
            size="1x"
            className="pointer"
            onClick={onClose}
          />
        </div>
        <InputWembii
          label={intl.formatMessage({ id: "app.contacts.origins.name" })}
          type="text"
          name="name"
          id="name"
          required
          placeholder={intl.formatMessage({ id: "app.contacts.origins.name" })}
          value={name}
          onChange={setName}
        />
        <div className="d-flex justify-content-center">
          <Badge 
            color="primary"
            className="text-light font-size-2 m-2 d-flex align-items-center"
            pill
          >
            #{name}
          </Badge>
        </div>

        <Button color="primary" disabled={disabled()} className="ml-1">
          {intl.formatMessage({ id: "app.contacts.origins.create" })}  
        </Button>
      </Form>
    </div>
  );
}