import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { Col, Label, Row } from "reactstrap";
import {
  ContactTask,
  Task,
} from "../../../cores/tasks/interfaces/api/get-task";
import { PanelWembii } from "../PanelWembii";
import {
  PriorityOptions,
  TaskStatus,
  TaskType,
} from "../../../cores/tasks/config";
import { COLOR_SUCCESS } from "../../../constants/theme";
import { useGlobalCore } from "../../../cores/globals";
import { TASK_CARD_MODAL } from "../../../constants/modal.constant";
import TaskDetails from "./modal/TaskDetails";
import { getFirstLetters, isStrNotNull } from "../../utils/stringUtils";
import { TooltipWembii } from "../TooltipWembii";
import { UserStatus } from "../../../cores/user/constants/user-status";
import { useIntl } from "react-intl";
import Avatar from "../Avatar";
import { useTaskCore } from "../../../cores/tasks";
import { useMeCore } from "../../../cores/me";

export const TaskRow: React.FC<{
  task: Task;
}> = ({ task }) => {
  const intl = useIntl();
  const {
    id,
    title,
    status,
    user,
    type,
    contacts,
    endDate,
    reminderDate,
    priority,
    cardId,
  } = task;
  const { putTask } = useTaskCore();
  const { company } = useMeCore();
  const { turnOnModal } = useGlobalCore();

  const onClick = useCallback(() => {
    turnOnModal(TASK_CARD_MODAL, <TaskDetails task={id} />);
  }, [turnOnModal, id]);

  const changeStatus = useCallback(
    (e) => {
      e.stopPropagation();

      const newStatus =
        status === TaskStatus.Completed
          ? TaskStatus.Incompleted
          : TaskStatus.Completed;
      putTask(company?.id!, id, {
        status: newStatus,
      });
    },
    [putTask, company, status, id]
  );

  const companies = contacts.reduce(
    (acc: any[], currentValue: ContactTask) => [
      ...acc,
      ...currentValue.companies.filter(
        ({ name }) => !acc.find((element) => element.name === name)
      ),
    ],
    []
  );

  const contactCompanies = () => {
    if (companies.length > 1) {
      const totalCompanies = companies.length;
      return `${companies[0].name} y ${
        totalCompanies - 1 === 1
          ? "una companía"
          : `${totalCompanies - 1} companías`
      } más`;
    }
    if (companies.length) return companies[0].name;
  };

  const getLastCompanies = () =>
    companies
      .map((company) => company.name)
      .slice(1)
      .join(", ");

  const typeLabel = useMemo(() => {
    switch (type) {
      case TaskType.Other:
        return <Label className="ml-2 mb-0">Otro</Label>;
      case TaskType.Call:
        return (
          <FontAwesomeIcon icon={["fas", "phone"]} size="2x" className="ml-2" />
        );
      case TaskType.Email:
        return (
          <FontAwesomeIcon
            icon={["far", "envelope"]}
            size="2x"
            className="ml-2"
          />
        );
    }
  }, [type]);

  const getContactNameOrEmailOrPhone = (contact: ContactTask) => {
    let text = contact.name || contact.email || contact.phoneNumber;
    if (contact.status !== UserStatus.ACTIVE.id) {
      text = `${text} ${intl.formatMessage({ id: "app.crmBusiness.active?" })}`;
    }

    return text;
  };

  return (
    <PanelWembii className="w-100 px-4 py-3 my-2 pointer" onClick={onClick}>
      <Row>
        <Col xs="12" lg="1">
          <div
            className="d-flex h-100 align-items-center justify-content-center justify-content-lg-start"
            onClick={changeStatus}
          >
            {status === TaskStatus.Incompleted ? (
              <FontAwesomeIcon icon={["far", "circle"]} size="2x" />
            ) : (
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                size="2x"
                color={COLOR_SUCCESS}
              />
            )}
          </div>
        </Col>
        <Col xs="12" lg="4">
          <div className="d-flex h-100 flex-column align-items-center align-items-lg-start justify-content-center">
            <span className="h5 mb-0 font-weight-bold">
              {!!cardId && (
                <FontAwesomeIcon icon={["fas", "briefcase"]} className="mr-1" />
              )}
              {title}
            </span>
            <span>{`${user.firstName} ${user.lastName} `}</span>
          </div>
        </Col>
        <Col xs="12" lg="2">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="d-flex">
              <FontAwesomeIcon icon={["far", "clock"]} className="mr-2" />
              <span
                className={cn("mb-0 small", {
                  "text-danger":
                    moment(endDate).isBefore(moment()) &&
                    status === TaskStatus.Incompleted,
                })}
              >
                {moment(endDate).format("DD/MM/YYYY HH:mm")}
              </span>
            </div>
            {reminderDate && (
              <div className="d-flex">
                <FontAwesomeIcon icon={["far", "bell"]} className="mr-2" />
                <span className="mb-0 small">
                  {moment(reminderDate).format("DD/MM/YYYY HH:mm")}
                </span>
              </div>
            )}
            <div className="d-flex">
              <span className="text-muted">Prioridad:</span>
              <span
                className="font-weight-bold ml-2"
                style={{ color: PriorityOptions[`${priority}`].color }}
              >
                {PriorityOptions[`${priority}`].label}
              </span>
            </div>
          </div>
        </Col>
        <Col xs="12" lg="2" className="my-auto">
          <div className="d-flex flex-wrap">
            {contacts.map((contact) => (
              <div key={contact.id}>
                <Avatar
                  id={`contact-${contact.id}`}
                  active={contact.status === UserStatus.ACTIVE.id}
                  className="text-dark-50 font-weight-bold pointer m-1"
                  label={`${getFirstLetters(contact.name)}`}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "user"]}
                      size="1x"
                      className="pointer mr-1"
                    />
                  }
                />
                {isStrNotNull(getContactNameOrEmailOrPhone(contact)) && (
                  <TooltipWembii
                    id={`contact-${contact.id}`}
                    text={`${getContactNameOrEmailOrPhone(contact)}`}
                  />
                )}
              </div>
            ))}
          </div>
        </Col>
        <Col xs="12" lg="2">
          <span className="d-flex h-100 align-items-center justify-content-center justify-content-lg-start">
            <span id={`company-${task.id}`}> {contactCompanies()}</span>
            {companies.length > 1 && (
              <TooltipWembii
                id={`company-${task.id}`}
                text={getLastCompanies()}
              />
            )}
          </span>
        </Col>
        <Col xs="12" lg="1">
          <div className="d-flex h-100 align-items-center justify-content-center justify-content-lg-end">
            {typeLabel}
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
