import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  WHATSAPP_GET_LOADING,
  WHATSAPP_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { whatsappApiService } from "./services/api";
import { settingSelector } from "./store/selector";
import {
  postUnbindWhatsappInitAction,
  postUnbindWhatsappSuccessAction,
  postUnbindWhatsappFailureAction,
  postBindWhatsappInitAction,
  postBindWhatsappSuccessAction,
  getWhatsappInitAction,
  getWhatsappSuccessAction,
  getWhatsappFailureAction,
} from "./store/api-actions";
import { toast } from "react-toastify";

export function useWhatsappCore() {
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindWhatsappInitAction());
        await whatsappApiService.unbind(companyId);
        dispatch(postUnbindWhatsappSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindWhatsappFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const completeAuth0Step = async (companyId: string, accessToken: string) => {
    try {
      const result = await whatsappApiService.auth0(companyId, accessToken);
      await bind(
        companyId,
        result.access_token,
        result.phoneData.phoneNumberId,
        result.pin
      );
      toast.success("Whastapp sincronizado exitosamente");
    } catch (error) {
      toast.error("Ha ocurrido un error al sincronizar whatsapp");
    }
  };
  const bind = useCallback(
    async (
      companyId: string,
      token: string,
      phoneNumber: string,
      pin: number,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindWhatsappInitAction());
        turnOnLoading(WHATSAPP_SYNC_LOADING);
        const response = await whatsappApiService.bind(companyId, {
          companyId,
          phoneNumber,
          token,
          pin,
        });
        dispatch(postBindWhatsappSuccessAction({ settings: response }));
        turnOffLoading(WHATSAPP_SYNC_LOADING, 500);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(WHATSAPP_SYNC_LOADING);
        console.error(error);
        dispatch(postUnbindWhatsappFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getWhatsappInitAction());
        turnOnLoading(WHATSAPP_GET_LOADING);
        const response = await whatsappApiService.get(companyId);
        dispatch(getWhatsappSuccessAction({ settings: response }));
        turnOffLoading(WHATSAPP_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(WHATSAPP_GET_LOADING);
        console.error(error);
        dispatch(getWhatsappFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  return {
    settings,
    unbind,
    bind,
    get,
    completeAuth0Step,
  };
}
