import cn from "classnames";
import React, { useMemo } from "react";
import {
  Activity,
  AssignmentActivity,
  ChangeBudgetActivity,
  ChangeCustomFieldActivity,
  ChangeDescriptionActivity,
  ChangeEndDateActivity,
  ChangeInternalIdActivity,
  ChangeStatusActivity,
  ChangeTitleActivity,
  CompanyActivity,
  ContactActivity,
  CreateActivity,
  DeleteCustomFieldActivity,
  FileActivity,
  MassMoveActivity,
  MoveActivity,
  OrderActivity,
  ProductActivity,
  TagActivity,
  TaskActivity
} from "../../../../cores/boards/interfaces/api/get-card";
import { useIntl } from "react-intl";
import Avatar from "../../Avatar";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import moment from "moment";
import { ActivityType } from "../../../../cores/boards/constants/activity-type.constant";
import { TaskStatusOptions } from "../../../../cores/tasks/config";
import { getCurrencySymbol } from "../../../../cores/boards/constants/currencies.constant";
import { CL$Format } from "../../../../cores/utils";
import { CardStatusLabels } from "../../../../cores/boards/constants/card-type";
import { CardCustomField } from "../../../../cores/boards/constants/custom-field-type";
import { getFirstLetters } from "../../../utils/stringUtils";

interface Props {
  activity: Activity;
}

export default function ActivityCard({ activity }: Props) {
  const intl = useIntl();

  const createdAt = useMemo(() =>
    moment(activity.createdAt).format("DD/MM/YYYY HH:mm a")
  , [activity.createdAt]);

  const message = useMemo(() => {
    switch(activity.type) {
      case ActivityType.CREATE_CARD:
        return intl.formatMessage(
          { id: "app.opportunity.activity.createCard" },
          { list: (activity.data as CreateActivity).list }
        )
      case ActivityType.ADD_ASSIGNMENT:
        return intl.formatMessage(
          { id: "app.opportunity.activity.addAssignment" },
          { user: `${(activity.data as AssignmentActivity).assignment.firstName} ${(activity.data as AssignmentActivity).assignment.lastName}` }
        )
      case ActivityType.DELETE_ASSIGNMENT:
        return intl.formatMessage(
          { id: "app.opportunity.activity.deleteAssignment" },
          { user: `${(activity.data as AssignmentActivity).assignment.firstName} ${(activity.data as AssignmentActivity).assignment.lastName}` }
        )
      case ActivityType.ADD_CONTACT:
        return intl.formatMessage(
          { id: "app.opportunity.activity.addContact" },
          { contact: (activity.data as ContactActivity).contact.name }
        )
      case ActivityType.DELETE_CONTACT:
        return intl.formatMessage(
          { id: "app.opportunity.activity.deleteContact" },
          { contact: (activity.data as ContactActivity).contact.name }
        )
      case ActivityType.ADD_COMPANY:
        return intl.formatMessage(
          { id: "app.opportunity.activity.addCompany" },
          { company: (activity.data as CompanyActivity).company }
        )
      case ActivityType.DELETE_COMPANY:
        return intl.formatMessage(
          { id: "app.opportunity.activity.deleteCompany" },
          { company: (activity.data as CompanyActivity).company }
        )
      case ActivityType.ADD_TAG:
        return intl.formatMessage(
          { id: "app.opportunity.activity.addTag" },
          { tag: (activity.data as TagActivity).tag }
        )
      case ActivityType.DELETE_TAG:
        return intl.formatMessage(
          { id: "app.opportunity.activity.deleteTag" },
          { tag: (activity.data as TagActivity).tag }
        )
      case ActivityType.ADD_FILE:
        return intl.formatMessage(
          { id: "app.opportunity.activity.addFile" },
          { file: (activity.data as FileActivity).file }
        )
      case ActivityType.DELETE_FILE:
        return intl.formatMessage(
          { id: "app.opportunity.activity.deleteFile" },
          { file: (activity.data as FileActivity).file }
        )
      case ActivityType.ADD_TASK:
        return intl.formatMessage(
          { id: "app.opportunity.activity.addTask" },
          { task: (activity.data as TaskActivity).task }
        )
      case ActivityType.TASK_STATUS:
        return intl.formatMessage(
          { id: "app.opportunity.activity.taskStatus" },
          { 
            task: (activity.data as TaskActivity).task,
            status: TaskStatusOptions[(activity.data as TaskActivity).status].label
          }
        )
      case ActivityType.DELETE_TASK:
        return intl.formatMessage(
          { id: "app.opportunity.activity.deleteTask" },
          { task: (activity.data as TaskActivity).task }
        )
      case ActivityType.MOVE_CARD:
        return intl.formatMessage(
          { id: "app.opportunity.activity.moveCard" },
          { 
            source: (activity.data as MoveActivity).source,
            destination: (activity.data as MoveActivity).destination
          }
        )
      case ActivityType.MASS_MOVE_CARD:
        if ((activity.data as MassMoveActivity).sourceBoard === (activity.data as MassMoveActivity).destinationBoard) {
          return intl.formatMessage(
            { id: "app.opportunity.activity.moveCard" },
            { 
              source: (activity.data as MassMoveActivity).sourceList,
              destination: (activity.data as MassMoveActivity).destinationList
            }
          )
        } else {
          return intl.formatMessage(
            { id: "app.opportunity.activity.moveCards" },
            { 
              sourceBoard: (activity.data as MassMoveActivity).sourceBoard,
              sourceList: (activity.data as MassMoveActivity).sourceList,
              destinationBoard: (activity.data as MassMoveActivity).destinationBoard,
              destinationList: (activity.data as MassMoveActivity).destinationList,
            }
          )
        }
      case ActivityType.ORDER_CARD:
        return intl.formatMessage({ id: (activity.data as OrderActivity).order === "LOWER"
          ? "app.opportunity.activity.orderCard.lower"
          : "app.opportunity.activity.orderCard.upper"
        });
      case ActivityType.CHANGE_TITLE:
        return intl.formatMessage(
          { id: "app.opportunity.activity.changeTitle" },
          { title: (activity.data as ChangeTitleActivity).title }
        )
      case ActivityType.CHANGE_DESCRIPTION:
        return intl.formatMessage(
          { id: "app.opportunity.activity.changeDescription" },
          { description: (activity.data as ChangeDescriptionActivity).description }
        )
      case ActivityType.DELETE_DESCRIPTION:
        return intl.formatMessage({ id: "app.opportunity.activity.deleteDescription" })
      case ActivityType.CHANGE_BUDGET:
        return intl.formatMessage(
          { id: "app.opportunity.activity.changeBudget" },
          {
            budget: CL$Format((activity.data as ChangeBudgetActivity).budget),
            currency: getCurrencySymbol((activity.data as ChangeBudgetActivity).currency)
          }
        )
      case ActivityType.CHANGE_END_DATE:
        return intl.formatMessage(
          { id: "app.opportunity.activity.changeEndDate" },
          { endDate: moment((activity.data as ChangeEndDateActivity).endDate).format("DD/MM/YYYY") }
        )
      case ActivityType.DELETE_END_DATE:
        return intl.formatMessage({ id: "app.opportunity.activity.deleteEndDate" })
      case ActivityType.CHANGE_INTERNAL_ID:
        return intl.formatMessage(
          { id: "app.opportunity.activity.changeInternalId" },
          { internalId: (activity.data as ChangeInternalIdActivity).internalId }
        )
      case ActivityType.DELETE_INTERNAL_ID:
        return intl.formatMessage({ id: "app.opportunity.activity.deleteInternalId" })
      case ActivityType.CHANGE_STATUS:
        return intl.formatMessage(
          { id: "app.opportunity.activity.changeStatus" },
          { 
            status: intl.formatMessage({
              id: CardStatusLabels[`${(activity.data as ChangeStatusActivity).status}`]
            })
          }
        )
      case ActivityType.ADD_PRODUCT:
        return intl.formatMessage(
          { id: "app.opportunity.activity.addProduct" },
          { product: (activity.data as ProductActivity).product }
        )
      case ActivityType.EDIT_PRODUCT:
        return intl.formatMessage(
          { id: "app.opportunity.activity.editProduct" },
          { product: (activity.data as ProductActivity).product }
        )
      case ActivityType.DELETE_PRODUCT:
        return intl.formatMessage(
          { id: "app.opportunity.activity.deleteProduct" },
          { product: (activity.data as ProductActivity).product }
        )
      case ActivityType.CHANGE_CUSTOM_FIELD:
        const value = (activity.data as ChangeCustomFieldActivity).type === CardCustomField.Date
          ? moment((activity.data as ChangeCustomFieldActivity).value).format("DD/MM/YYYY")
          : (activity.data as ChangeCustomFieldActivity).type === CardCustomField.Money
            ? CL$Format((activity.data as ChangeCustomFieldActivity).value)
            : (activity.data as ChangeCustomFieldActivity).value
        const unit = (activity.data as ChangeCustomFieldActivity).type === CardCustomField.Money
          ? getCurrencySymbol((activity.data as ChangeCustomFieldActivity).unit)
          : ""
        return intl.formatMessage(
          { id: "app.opportunity.activity.editCustomField" },
          {
            name: (activity.data as ChangeCustomFieldActivity).name,
            value,
            unit
          }
        )
      case ActivityType.DELETE_CUSTOM_FIELD:
        return intl.formatMessage(
          { id: "app.opportunity.activity.deleteCustomField" },
          { name: (activity.data as DeleteCustomFieldActivity).name }
        )
      case ActivityType.DELETE_CARD:
        return intl.formatMessage({ id: "app.opportunity.activity.delete" })
    }
  }, [activity, intl]);

  return (
    <div className="d-flex mt-3 mb-4 w-100">
      <Avatar
        className="text-dark-50"
        active={activity.user.status === UserStatus.ACTIVE.id}
        label={getFirstLetters(`${activity.user.lastName} ${activity.user.firstName}`)}
        image={activity.user.avatarUrl}
      />
      <div className="d-flex flex-column align-items-start ml-2 w-100">
        <div>
          <span className={cn("font-weight-bold mr-2", {
            "text-line-through": activity.user.status !== UserStatus.ACTIVE.id
          })}>
            {activity.user.firstName} {activity.user.lastName}
          </span>
          {message}
        </div>
        <span className="text-muted">
          {createdAt}
        </span>
      </div>
    </div>
  );
}
