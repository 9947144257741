import { EmailFile } from "../../email/interface/emailFile";
import socialApiClient from "../../helpers/services/social-api-client";
import { GetGmailBindResponse } from "../interface/api/get-gmail-bind";
import { GetGmailLoginResponse } from "../interface/api/get-gmail-login";
import { PostGmailBindResponse } from "../interface/api/post-gmail-bind";
import { Email, PostGmailEmailResponse } from "../interface/api/post-gmail-email";
import { gmailSettingMapper } from "./mapper";

export const gmailApiService = {
  unbind(companyId: string, asUser: boolean) {
    return socialApiClient()
      .post(`gmail/unbind`, {}, { params: { companyId, asUser } })
      .then(({ data }) => data);
  },
  bind(companyId: string, code: string, asUser: boolean) {
    return socialApiClient()
      .post<PostGmailBindResponse>(`gmail/bind`, { code }, {
        params: { companyId, asUser },
      })
      .then(({ data }) => gmailSettingMapper.mapResponseToGmailSetting(data));
  },
  get(companyId: string, asUser: boolean) {
    return socialApiClient()
      .get<GetGmailBindResponse>(`gmail`, {
        params: { companyId, asUser },
      })
      .then(({ data }) => gmailSettingMapper.mapResponseToGmailSetting(data));
  },
  login(companyId: string, asUser: boolean) {
    return socialApiClient()
      .get<GetGmailLoginResponse>(`gmail/login`, {
        params: { companyId, asUser },
      })
      .then(({ data }) =>
        gmailSettingMapper.mapResponseToGmailLoginUrl(data)
      );
  },
  postEmail(
    companyId: string,
    body: Email,
    files: EmailFile[],
    asUser: boolean,
    cardId: string | null
  ) {
    const bodyFormData = new FormData();
    files.map((file) => 
      bodyFormData.append("files", file.data)
    );
    bodyFormData.append("subject", body.subject);
    bodyFormData.append("message", body.message);
    bodyFormData.append("to", JSON.stringify(body.to));
    if (cardId) {
      bodyFormData.append("cardId", cardId);
    }
    return socialApiClient()
      .post<PostGmailEmailResponse>(`gmail/email`, bodyFormData,  {
        params: { companyId, asUser },
      })
      .then(({ data }) =>
        gmailSettingMapper.mapResponseToId(data)
      );
  },
};
