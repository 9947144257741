import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import {
  Badge,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { ME_USER_EDIT_FORM } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { useNotificationCore } from "../../../cores/notifications";
import { useSessionCore } from "../../../cores/session";
import { useIntl } from "react-intl";
import Avatar from "../Avatar";
import { EditMeUserForm } from "../editMeUser/EditMeUserForm";
import { LocalSelector } from "../LocaleSelector";
import NotificationContainer from "../notifications/NotificationContainer";
import { PanelWembii } from "../PanelWembii";
import { CompanySelector } from "./CompanySelector";
import { PlanType } from "../../../cores/plan/contants/planType.constant";
import { UpgradePlanButton } from "../plan/UpgradePlanButton";
import { LOCALE } from "../../../constants/locale.constant";
import { getFirstLetters } from "../../utils/stringUtils";
import { useRouterCore } from "../../../cores/router";

export default function NavBar() {
  const intl = useIntl();
  const { user: userSession, logout } = useSessionCore();
  const { reset, turnOnModal, setOpenSideBar, openSideBar } = useGlobalCore();
  const {
    unreadNotifications,
    unreadCount,
    infoMessage,
    infoMessageEn,
    infoMessageEs,
    infoMessagePt,
    putReadNotifications,
  } = useNotificationCore();
  const { goToPlans, goToNotifications } = useRouterCore();
  const { userPlan, companyRole, user, company, postMeLanguage } = useMeCore();
  const [showInfo, setShowInfo] = useState(true);

  const toggleShowInfo = useCallback(() => {
    setShowInfo(!showInfo);
  }, [showInfo]);

  const toggleSidebar = () => setOpenSideBar(!openSideBar);

  const onLogout = useCallback(() => {
    reset();
    logout();
  }, [logout, reset]);

  const onEditUser = useCallback(() => {
    turnOnModal(ME_USER_EDIT_FORM, <EditMeUserForm />);
  }, [turnOnModal]);

  const onEditIntegrations = useCallback(() => {
    turnOnModal(ME_USER_EDIT_FORM, <EditMeUserForm tab={"3"} />);
  }, [turnOnModal]);

  const onRemoveNotifications = useCallback(() => {
    putReadNotifications(company?.id!);
  }, [putReadNotifications, company]);

  const infoMessageTxt = useMemo(() => {
    switch (user?.locale) {
      case LOCALE.SPANISH:
        return infoMessageEs ? infoMessageEs : infoMessage;
      case LOCALE.ENGLISH:
        return infoMessageEn ? infoMessageEn : infoMessage;
      case LOCALE.PORTUGUESE:
        return infoMessagePt ? infoMessagePt : infoMessage;
    }
  }, [user, infoMessage, infoMessageEn, infoMessageEs, infoMessagePt]);

  return (
    <PanelWembii>
      <div className="d-flex d-md-none d-lg-none d-xl-none justify-content-center align-items-center h-100 p-2">
        <div
          className="pointer my-auto"
          style={{
            maxHeight: 50,
            maxWidth: 50,
            backgroundColor: "white",
            border: "none",
          }}
          onClick={toggleSidebar}
        >
          {!openSideBar ? (
            <FontAwesomeIcon
              icon={["fas", "bars"]}
              className="text-secondary p-2 ml-2"
              size="2x"
            />
          ) : (
            <FontAwesomeIcon
              icon={["fas", "times"]}
              className="text-secondary p-2 ml-2"
              size="2x"
            />
          )}
        </div>
        <div className="ml-auto px-2">
          <CompanySelector />
        </div>
      </div>

      <Navbar light expand="md" className="d-none d-md-flex container-fluid">
        <CompanySelector />
        <Container fluid className="d-flex justify-content-end">
          {company &&
            companyRole?.owner &&
            (userPlan?.name === PlanType.FREE || !!userSession?.freeTrial) && (
              <Nav className="mr-auto mx-2 " navbar>
                <UpgradePlanButton />
              </Nav>
            )}

          <Nav className="ml-auto align-items-center" navbar>
            {!!userSession?.freeTrial && (
              <h6 className="mx-2 my-0 p-0">
                {userSession.freeTrialDays -
                  moment().diff(moment(userSession.freeTrialDate), "days")}{" "}
                días restantes
              </h6>
            )}
            {infoMessageTxt && (
              <Badge
                pill
                className="mx-2 p-1 d-flex align-items-center text-dark bg-notification pointer"
                onClick={toggleShowInfo}
              >
                <FontAwesomeIcon icon={["fas", "info-circle"]} size="2x" />
                {showInfo && (
                  <div className="text-wrap mx-2">{infoMessageTxt}</div>
                )}
              </Badge>
            )}
            <LocalSelector
              nav={true}
              inNavbar={true}
              right={true}
              onChange={postMeLanguage}
            />
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav className="p-0 position-relative">
                <Avatar
                  className="text-dark-50 pointer"
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "bell"]}
                      size="1x"
                      color="grey"
                      className="pointer pos"
                    />
                  }
                />
                {unreadCount > 0 && (
                  <Badge
                    color="danger"
                    className="text-light position-absolute float-right-corner"
                    pill
                  >
                    {unreadCount}
                  </Badge>
                )}
              </DropdownToggle>
              <DropdownMenu
                right
                className="border-0 py-3 px-2 shadow"
                style={{ borderRadius: "20px", width: "450px" }}
              >
                <div className="d-flex justify-content-between ">
                  <span
                    className="d-flex align-items-center small mr-3 mb-2 hover-color-primary justify-content-end"
                    onClick={goToNotifications}
                  >
                    <FontAwesomeIcon
                      size="1x"
                      className="m-1"
                      icon={["fas", "bell"]}
                    />
                    {intl.formatMessage({
                      id: "app.navbar.seeAll",
                    })}
                  </span>
                  <span
                    className="d-flex align-items-center small mr-3 mb-2 hover-color-danger justify-content-end"
                    onClick={onRemoveNotifications}
                  >
                    <FontAwesomeIcon
                      size="1x"
                      className="m-1"
                      icon={["fas", "check"]}
                    />
                    {intl.formatMessage({
                      id: "app.navbar.readAllNotifications",
                    })}
                  </span>
                </div>
                {unreadNotifications.length > 0 ? (
                  <>
                    {unreadNotifications.map((notification, index) => (
                      <NotificationContainer
                        unread
                        key={index}
                        notification={notification}
                      />
                    ))}
                  </>
                ) : (
                  <div className="w-100 text-muted text-center">
                    {intl.formatMessage({ id: "app.navbar.notNotification" })}
                  </div>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav className="p-0">
                <Avatar
                  className="text-dark-50 pointer mx-2"
                  label={getFirstLetters(
                    `${user?.lastName} ${user?.firstName}`
                  )}
                  image={user?.avatarUrl}
                />
              </DropdownToggle>
              <DropdownMenu
                right
                className="border-0 py-3 px-2 shadow"
                style={{ borderRadius: "20px" }}
              >
                <DropdownItem onClick={onEditUser}>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={["fas", "cog"]} className="mr-3" />
                    {intl.formatMessage({ id: "app.navbar.configuration" })}
                  </div>
                </DropdownItem>
                <DropdownItem onClick={onEditIntegrations}>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={["fas", "puzzle-piece"]}
                      className="mr-3"
                    />
                    {intl.formatMessage({ id: "app.navbar.integrations" })}
                  </div>
                </DropdownItem>
                {userPlan && (
                  <DropdownItem onClick={goToPlans}>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={["fas", "crown"]}
                        className="mr-3"
                      />
                      {intl.formatMessage({ id: "app.navbar.subscription" })}
                    </div>
                  </DropdownItem>
                )}
                <DropdownItem divider />
                <DropdownItem onClick={onLogout}>
                  {intl.formatMessage({ id: "app.navbar.onLogout" })}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </PanelWembii>
  );
}
