import socialApiClient from "../../helpers/services/social-api-client";
import { GetGoogleBindResponse } from "../interface/api/get-google-bind";
import { GetGoogleLoginResponse } from "../interface/api/get-google-login";
import { GetGoogleProjectsResponse } from "../interface/api/get-google-projects";
import {
  PostGoogleBindRequest,
  PostGoogleBindResponse,
} from "../interface/api/post-google-bind";
import { googleSettingMapper } from "./mapper";

export const googleApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`googleanalytics/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostGoogleBindRequest) {
    return socialApiClient()
      .post<PostGoogleBindResponse>(`googleanalytics/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) => googleSettingMapper.mapResponseToGoogleSetting(data));
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetGoogleBindResponse>(`googleanalytics`, {
        params: { companyId },
      })
      .then(({ data }) => googleSettingMapper.mapResponseToGoogleSetting(data));
  },
  getProjects(companyId: string, code: string) {
    return socialApiClient()
      .get<GetGoogleProjectsResponse>(`googleanalytics/projects`, {
        params: { companyId, code },
      })
      .then(({ data }) =>
        googleSettingMapper.mapResponseToGoogleProjects(data)
      );
  },
  login(companyId: string) {
    return socialApiClient()
      .get<GetGoogleLoginResponse>(`googleanalytics/login`, {
        params: { companyId },
      })
      .then(({ data }) =>
        googleSettingMapper.mapResponseToGoogleLoginUrl(data)
      );
  },
};
