import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { RadiobuttonWembii } from "../../RadiobuttonWembii";
import { useTemplateCore } from "../../../../cores/templates";
import { CheckboxWembii } from "../../CheckboxWembii";
import { SocialTemplate } from "../../../../cores/templates/interfaces/api/get-social-templates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EMOJI_FORM } from "../../../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { EmojiForm } from "../../EmojiForm";
import { useGlobalCore } from "../../../../cores/globals";
import { INFO_TAGS } from "../../../../cores/templates/constants/infoTags";

export default function TemplateChatForm() {
  const intl = useIntl();
  const [name, setName] = useState("");
  const [addTemplate, setAddTemplate] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [message, setMessage] = useState("");
  const [global, setGlobal] = useState(false);
  const [inputCursorIndex, setInputCursorIndex] = useState(0);
  const {
    chatTemplates,
    getChatTemplates,
    postChatTemplate,
    putChatTemplate,
    deleteChatTemplate,
  } = useTemplateCore();
  const { company, isAdmin } = useMeCore();
  const { isModal, turnOnModal, turnOffModal } = useGlobalCore();

  const toggleAddTemplate = useCallback(() => {
    setAddTemplate(!addTemplate);

    if (!addTemplate) {
      setSelectedTemplate("");
      setName("");
      setGlobal(false);
      setMessage("");
    }
  },[addTemplate]);

  const onSuccess = useCallback(() => {
    setSelectedTemplate("");
    setName("");
    setGlobal(false);
    setMessage("");
    setAddTemplate(true);
    getChatTemplates(company?.id!);
  }, [getChatTemplates, company]);

  const onDelete = useCallback(() => {
    deleteChatTemplate(company?.id!, selectedTemplate, onSuccess);  
  }, [deleteChatTemplate, onSuccess, selectedTemplate, company]);

  const onFormSubmit = useCallback((e: any) => {
    e.preventDefault();

    if (addTemplate) {
      postChatTemplate(
        company?.id!,
        {
          name,
          global,
          message
        },
        onSuccess
      );
    } else {
      putChatTemplate(
        company?.id!,
        selectedTemplate, {
          name,
          global,
          message
        },
        onSuccess
      );
    }
  }, [
    postChatTemplate,
    putChatTemplate,
    onSuccess,
    global,
    selectedTemplate,
    name,
    message,
    addTemplate,
    company
  ]);

  const disabled = useCallback(
    () => addTemplate && (!name || !message),
    [addTemplate, name, message]
  );

  const disabled2 = useCallback(
    () => {
      const template = chatTemplates.find((tp) => +tp.id === +selectedTemplate);

      return !addTemplate && ((
        template &&
        name === template.name &&
        message === template.message
      ) || (
        !template
      ));
    },
    [selectedTemplate, name, addTemplate, message, chatTemplates]
  );

  const disabled3 = useCallback(
    () => !addTemplate && !selectedTemplate,
    [addTemplate, selectedTemplate]
  );

  const onSelecting = useCallback((value: string) => {
    setSelectedTemplate(value);

    const template = chatTemplates.find((tp) => +tp.id === +value);
    if (template) {
      setName(template.name);
      setGlobal(template.global);
      setMessage(template.message);
    } else {
      setName("");
      setGlobal(false);
      setMessage("");
    }
  },[setSelectedTemplate, chatTemplates]);

  const toggleGlobal = useCallback(() => {
    setGlobal(!global);
  },[global]);

  const templatesAvailable = useMemo(() => 
    (isAdmin)
      ? chatTemplates
      : chatTemplates.filter((template) => !template.global)
  , [chatTemplates, isAdmin]);

  const handleAddEmoji = useCallback((emoji: any) => {
    const unifieds = emoji.unified.split('-');
    const emojiCode = unifieds.map((unified: any) => String.fromCodePoint(Number(`0x${unified}`))).join('');
    
    setMessage(message.slice(0, inputCursorIndex)
      + emojiCode
      + message.slice(inputCursorIndex)
    );
    setInputCursorIndex(inputCursorIndex + emojiCode.length);
    turnOffModal(EMOJI_FORM);
  }, [message, inputCursorIndex, turnOffModal]);

  const addMessageInfoTag = useCallback((tag) => {
    setMessage(message.slice(0, inputCursorIndex)
      + tag.label
      + message.slice(inputCursorIndex)
    );
    setInputCursorIndex(inputCursorIndex + tag.label.length);
  }, [message, inputCursorIndex]);

  const openEmojiModal = useCallback(() => {
    turnOnModal(
      EMOJI_FORM,
      <EmojiForm onAdding={handleAddEmoji} />
    );
  }, [turnOnModal, handleAddEmoji]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-3">
            <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
              {intl.formatMessage({ id: "app.socialStory.template" })}
            </h2>
            <Form className="px-2 h-50" onSubmit={onFormSubmit}>
              <div className="d-flex align-items-center justify-content-around">
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.socialStory.template.add" })}
                  checked={addTemplate}
                  onChange={toggleAddTemplate}
                />
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.socialStory.template.modify" })}
                  checked={!addTemplate}
                  onChange={toggleAddTemplate}
                />
              </div>
              <InputWembii
                type="select"
                onChange={onSelecting}
                value={selectedTemplate}
                disabled={addTemplate}
              >
                <option value="">
                  {intl.formatMessage({ id: "app.select.placeholder" })}
                </option>
                {templatesAvailable.map((template: SocialTemplate) => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </InputWembii>

              <hr />

              <div className="d-flex align-items-center justify-content-between">
                <InputWembii
                  label={intl.formatMessage({ id: "app.socialStory.template.name" })}
                  type="text"
                  name="name"
                  className={cn({ "w-75": isAdmin, "w-100": !isAdmin })}
                  required
                  placeholder={intl.formatMessage({ id: "app.socialStory.template.name" })}
                  value={name}
                  onChange={setName}
                />
                {isAdmin && (
                  <CheckboxWembii
                    mb={false}
                    label={intl.formatMessage({ id: "app.socialStory.template.global" })}
                    checked={global}
                    onChange={toggleGlobal}
                  />
                )}
              </div>
              
              <div className="p-2 wembii-border-small">
                <Input
                  className="border-0 textEditor-textArea bg-white"
                  type="textarea"
                  value={message}
                  placeholder={intl.formatMessage({ id: "app.socialMediaPost.addText" })}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={(ev: any) => setInputCursorIndex(ev.target.selectionStart)}
                  onClick={(ev: any) => setInputCursorIndex(ev.target.selectionStart)}
                />
                <hr className="my-1" />
                <div className="d-flex align-items-center px-2">
                  <div className="p-2">
                    <FontAwesomeIcon
                      icon={["far", "smile-beam"]}
                      color={isModal(EMOJI_FORM) ? COLOR_PRIMARY : "grey"}
                      className="mx-1 pointer"
                      onClick={openEmojiModal}
                    />
                  </div>
                  <UncontrolledDropdown>
                    <DropdownToggle nav classNfame="mb-0 mx-1 p-0">
                      <FontAwesomeIcon
                        icon={["fas", "asterisk"]}
                        color="grey"
                        className="mx-1 pointer"
                      />
                    </DropdownToggle>
                    <DropdownMenu right className="border-0 py-3 px-2 shadow">
                      {Object.values(INFO_TAGS).map((tag, i) => (
                        <DropdownItem key={i} onClick={() => addMessageInfoTag(tag)}>
                          <div className="d-flex align-items-center">
                            {intl.formatMessage({ id: tag.name })}
                          </div>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>

              <div className="mt-3">
                <Button
                  color="primary"
                  block
                  disabled={disabled() || disabled2()}
                >
                  {intl.formatMessage({ id: "app.socialStory.template.save" })}
                </Button>
                {!addTemplate && (
                  <Button
                    className="my-1"
                    color="danger"
                    block
                    onClick={onDelete}
                    disabled={disabled3()}
                  >
                    {intl.formatMessage({ id: "app.socialStory.template.delete" })}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
