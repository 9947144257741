import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { GoogleProject, Tokens } from "../interface/api/get-google-projects";
import { GoogleSetting } from "../interface/api/post-google-bind";
import {
  getGoogleFailureAction,
  getGoogleInitAction,
  getGoogleProjectsFailureAction,
  getGoogleProjectsInitAction,
  getGoogleProjectsSuccessAction,
  getGoogleSuccessAction,
  GoogleApiAction,
  postBindGoogleFailureAction,
  postBindGoogleInitAction,
  postBindGoogleSuccessAction,
  postUnbindGoogleFailureAction,
  postUnbindGoogleInitAction,
  postUnbindGoogleSuccessAction,
} from "./api-actions";

export interface GoogleState {
  settings: GoogleSetting | null;
  projects: GoogleProject[] | null;
  tokens: Tokens | null;
  error: string;
}

const initialState: GoogleState = {
  settings: null,
  projects: null,
  tokens: null,
  error: "",
};

export default produce(
  (
    draft: GoogleState = initialState,
    action: GoogleApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindGoogleSuccessAction.type:
      case getGoogleSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case getGoogleProjectsSuccessAction.type:
        draft.projects = action.projects;
        draft.tokens = action.tokens;
        return draft;
      case postUnbindGoogleFailureAction.type:
      case getGoogleFailureAction.type:
      case postUnbindGoogleSuccessAction.type:
      case postBindGoogleFailureAction.type:
      case getGoogleProjectsFailureAction.type:
        draft.settings = null;
        draft.projects = null;
        draft.tokens = null;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindGoogleInitAction.type:
      case postUnbindGoogleInitAction.type:
      case getGoogleInitAction.type:
      case getGoogleProjectsInitAction.type:
      default:
        return draft;
    }
  }
);
