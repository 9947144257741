import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import {
  Automation,
  AutomationAction,
  AutomationCondition,
  AutomationContact,
  AutomationUser,
} from "../interfaces/api/get-automation";

export const getAutomationsInitAction = createAction(
  "[Automation] Get Automations Init"
);
export const getAutomationsSuccessAction = createAction(
  "[Automation] Get Automations Success",
  props<{ automations: Automation[] }>()
);
export const getAutomationsFailureAction = createAction(
  "[Automation] Get Automations Failure",
  props<{ error: string }>()
);

export const getAutomationInitAction = createAction(
  "[Automation] Get Automation Init"
);
export const getAutomationSuccessAction = createAction(
  "[Automation] Get Automation Success",
  props<{
    automation: Automation;
    actions: AutomationAction[];
    conditions: AutomationCondition[];
    contacts: AutomationContact[];
    users: AutomationUser[];
  }>()
);
export const getAutomationFailureAction = createAction(
  "[Automation] Get Automation Failure",
  props<{ error: string }>()
);

export const postAutomationInitAction = createAction(
  "[Automation] Post Automation Init"
);
export const postAutomationSuccessAction = createAction(
  "[Automation] Post Automation Success",
  props<{ automation: Automation }>()
);
export const postAutomationFailureAction = createAction(
  "[Automation] Post Automation Failure",
  props<{ error: string }>()
);

export const putAutomationInitAction = createAction(
  "[Automation] Put Automation Init"
);
export const putAutomationSuccessAction = createAction(
  "[Automation] Put Automation Success",
  props<{ automation: Automation }>()
);
export const putAutomationFailureAction = createAction(
  "[Automation] Put Automation Failure",
  props<{ error: string }>()
);

export const deleteAutomationInitAction = createAction(
  "[Automation] Delete Automation Init"
);
export const deleteAutomationSuccessAction = createAction(
  "[Automation] Delete Automation Success",
  props<{ id: string }>()
);
export const deleteAutomationFailureAction = createAction(
  "[Automation] Delete Automation Failure",
  props<{ error: string }>()
);

export const postActionInitAction = createAction(
  "[Automation] Post Automation Action Init"
);
export const postActionSuccessAction = createAction(
  "[Automation] Post Automation Action Success",
  props<{ action: AutomationAction }>()
);
export const postActionFailureAction = createAction(
  "[Automation] Post Automation Action Failure",
  props<{ error: string }>()
);

export const putActionInitAction = createAction(
  "[Automation] Put Automation Action Init"
);
export const putActionSuccessAction = createAction(
  "[Automation] Put Automation Action Success",
  props<{ action: AutomationAction }>()
);
export const putActionFailureAction = createAction(
  "[Automation] Put Automation Action Failure",
  props<{ error: string }>()
);

export const deleteActionInitAction = createAction(
  "[Automation] Delete Automation Action Init"
);
export const deleteActionSuccessAction = createAction(
  "[Automation] Delete Automation Action Success",
  props<{ id: string }>()
);
export const deleteActionFailureAction = createAction(
  "[Automation] Delete Automation Action Failure",
  props<{ error: string }>()
);

export const postConditionInitAction = createAction(
  "[Automation] Post Automation Condition Init"
);
export const postConditionSuccessAction = createAction(
  "[Automation] Post Automation Condition Success",
  props<{ condition: AutomationCondition }>()
);
export const postConditionFailureAction = createAction(
  "[Automation] Post Automation Condition Failure",
  props<{ error: string }>()
);

export const putConditionInitAction = createAction(
  "[Automation] Put Automation Condition Init"
);
export const putConditionSuccessAction = createAction(
  "[Automation] Put Automation Condition Success",
  props<{ condition: AutomationCondition }>()
);
export const putConditionFailureAction = createAction(
  "[Automation] Put Automation Condition Failure",
  props<{ error: string }>()
);

export const deleteConditionInitAction = createAction(
  "[Automation] Delete Automation Condition Init"
);
export const deleteConditionSuccessAction = createAction(
  "[Automation] Delete Automation Condition Success",
  props<{ id: string }>()
);
export const deleteConditionFailureAction = createAction(
  "[Automation] Delete Automation Condition Failure",
  props<{ error: string }>()
);

export const postContactInitAction = createAction(
  "[Automation] Post Contact Condition Init"
);
export const postContactSuccessAction = createAction(
  "[Automation] Post Contact Condition Success",
  props<{ contact: AutomationContact }>()
);
export const postContactFailureAction = createAction(
  "[Automation] Post Contact Condition Failure",
  props<{ error: string }>()
);
export const postUserInitAction = createAction(
  "[Automation] Post User Condition Init"
);
export const postUserSuccessAction = createAction(
  "[Automation] Post User Condition Success"
);
export const postUserFailureAction = createAction(
  "[Automation] Post User Condition Failure",
  props<{ error: string }>()
);

export const deleteContactInitAction = createAction(
  "[Automation] Delete Contact Condition Init"
);
export const deleteContactSuccessAction = createAction(
  "[Automation] Delete Contact Condition Success",
  props<{ id: string }>()
);
export const deleteUserSuccessAction = createAction(
  "[Automation] Delete User Condition Success",
  props<{ id: string }>()
);
export const deleteContactFailureAction = createAction(
  "[Automation] Delete Contact Condition Failure",
  props<{ error: string }>()
);
const actions = joinActions({
  postUserInitAction,
  postUserSuccessAction,
  postUserFailureAction,
  deleteUserSuccessAction,
  postAutomationInitAction,
  postAutomationSuccessAction,
  postAutomationFailureAction,
  getAutomationsInitAction,
  getAutomationsSuccessAction,
  getAutomationsFailureAction,
  deleteAutomationInitAction,
  deleteAutomationSuccessAction,
  deleteAutomationFailureAction,
  putAutomationInitAction,
  putAutomationSuccessAction,
  putAutomationFailureAction,
  getAutomationInitAction,
  getAutomationSuccessAction,
  getAutomationFailureAction,
  postActionInitAction,
  postActionSuccessAction,
  postActionFailureAction,
  putActionInitAction,
  putActionSuccessAction,
  putActionFailureAction,
  deleteActionInitAction,
  deleteActionSuccessAction,
  deleteActionFailureAction,
  postConditionInitAction,
  postConditionSuccessAction,
  postConditionFailureAction,
  putConditionInitAction,
  putConditionSuccessAction,
  putConditionFailureAction,
  deleteConditionInitAction,
  deleteConditionSuccessAction,
  deleteConditionFailureAction,
  postContactInitAction,
  postContactSuccessAction,
  postContactFailureAction,
  deleteContactInitAction,
  deleteContactSuccessAction,
  deleteContactFailureAction,
});

export type AutomationApiAction = typeof actions;
