import { ReactNode, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { delay } from "../utils";
import {
  LoadingEntity,
  ModalEntity,
  Position,
  SidebarGroupEntity,
} from "./interfaces/globalEntity";
import {
  deleteInternalModalAction,
  deleteModalAction,
  globalInitAction,
  resetAction,
  setGlobalLoadingAction,
  setInternalModalAction,
  setLoadingAction,
  setModalAction,
  setOpenSidebarGroups,
  setSideBarCollapsedAction,
  setSideBarOpenAction,
} from "./store/actions";
import {
  activeGroupsSelector,
  globalLoadingSelector,
  internalModalEntitiessSelector,
  internalModalsSelector,
  isSideBarCollapsedSelector,
  isSideBarOpenSelector,
  loadingsSelector,
  modalEntitiesSelector,
  modalsSelector,
} from "./store/selector";

export function useGlobalCore() {
  const dispatch = useDispatch();
  const globalLoading = useSelector(globalLoadingSelector);
  const loadings = useSelector(loadingsSelector);
  const modals = useSelector(modalsSelector);
  const sidebarActiveGroups = useSelector(activeGroupsSelector);
  const modalsEntities = useSelector(modalEntitiesSelector);
  const internalModals = useSelector(internalModalsSelector);
  const internalModalEntities = useSelector(internalModalEntitiessSelector);
  const openSideBar = useSelector(isSideBarOpenSelector);
  const collapseSideBar = useSelector(isSideBarCollapsedSelector);

  const setModal = useCallback(
    (entity: ModalEntity) => dispatch(setModalAction({ entity })),
    [dispatch]
  );

  const setInternalModal = useCallback(
    (entity: ModalEntity) => dispatch(setInternalModalAction({ entity })),
    [dispatch]
  );

  const setOpenSideBar = useCallback(
    (value: boolean) => dispatch(setSideBarOpenAction({ value })),
    [dispatch]
  );

  const setCollapseSideBar = useCallback(
    (value: boolean) => dispatch(setSideBarCollapsedAction({ value })),
    [dispatch]
  );

  const setLoading = useCallback(
    (entity: LoadingEntity) => dispatch(setLoadingAction({ entity })),
    [dispatch]
  );

  const setGlobalModal = useCallback(
    (value: boolean) => dispatch(setGlobalLoadingAction({ value })),
    [dispatch]
  );
  const setSidebarGroup = useCallback(
    (entity: SidebarGroupEntity) => dispatch(setOpenSidebarGroups({ entity })),
    [dispatch]
  );

  const getModalContent = (id: string): ReactNode =>
    modalsEntities[id]?.value || null;
  const getModalPosition = useCallback(
    (id: string): Position => modalsEntities[id]?.position,
    [modalsEntities]
  );
  const getModalVisibility = useCallback(
    (id: string): boolean => {
      const position = getModalPosition(id);
      const mdls = modals.filter((modal) => modal.position === position);
      const index = mdls.findIndex((modal) => modal.id === id);
      return index === mdls.length - 1;
    },
    [getModalPosition, modals]
  );
  const isModal = (id: string): boolean => Boolean(modalsEntities[id] || false);
  const isLoading = (id: string): boolean => loadings[id] || false;
  const areLoading = (ids: string[]): boolean =>
    ids.map((id) => isLoading(id)).includes(true);
  const getInternalModalContent = (id: string): ReactNode =>
    internalModalEntities[id]?.value || null;
  const getInternalModalPosition = (id: string): ReactNode =>
    internalModalEntities[id]?.position || Position.RIGHT;
  const getInternalModalVisibility = (
    id: string,
    position: Position.RIGHT
  ): boolean => {
    const mdls = internalModals.filter((modal) => modal.position === position);
    const index = mdls.findIndex((modal) => modal.id === id);
    return index === mdls.length;
  };
  const isInternalModal = (id: string): boolean =>
    Boolean(internalModalEntities[id] || false);

  const turnOnModal = useCallback(
    (id: string, value: ReactNode | boolean, position = Position.RIGHT) =>
      setModal({ id, value, position }),
    [setModal]
  );

  const turnOffModal = useCallback(
    (id: string) => dispatch(deleteModalAction({ id })),
    [dispatch]
  );

  const turnOnInternalModal = useCallback(
    (id: string, value: ReactNode | boolean, position = Position.RIGHT) =>
      setInternalModal({ id, value, position }),
    [setInternalModal]
  );

  const turnOffInternalModal = useCallback(
    (id: string) => dispatch(deleteInternalModalAction({ id })),
    [dispatch]
  );

  const turnOffLoading = useCallback(
    async (id: string, delayTime: number = 0) => {
      delayTime && (await delay(delayTime));
      setLoading({ id, value: false });
    },
    [setLoading]
  );
  const turnOnLoading = useCallback(
    (id: string) => setLoading({ id, value: true }),
    [setLoading]
  );

  return {
    modals,
    internalModals,
    loadings,
    globalLoading,
    openSideBar,
    collapseSideBar,
    getModalContent,
    getModalPosition,
    getModalVisibility,
    getInternalModalVisibility,
    turnOnModal,
    turnOffModal,
    turnOnInternalModal,
    turnOffInternalModal,
    turnOnLoading,
    turnOffLoading,
    setSidebarGroup,
    sidebarActiveGroups,
    init: () => dispatch(globalInitAction()),
    reset: () => dispatch(resetAction()),
    isModal,
    isInternalModal,
    isLoading,
    areLoading,
    setModal,
    setInternalModal,
    getInternalModalPosition,
    getInternalModalContent,
    setLoading,
    setGlobalModal,
    setOpenSideBar,
    setCollapseSideBar,
  };
}
