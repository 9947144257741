const routes = {
  login: "/login",
  emails: "/emails",
  forgotPassword: "/forgot_password",
  recoverPassword: "/auth/forgot-password",
  meeting: "/meeting/:token",
  webForm: "/webform/:company/:form",
  createAccount: "/create_account",
  createAccountEntel: "/create_account/entel",
  users: "/users",
  plans: "/plans",
  company: "/company",
  meCompany: "/me/company",
  editCompany: "/company/:id",
  social: "/social",
  socialPost: "/social/post",
  socialStory: "/social/story",
  socialHistory: "/social/history",
  socialManager: "/social/manager",
  socialCalendar: "/social/calendar",
  linkedInPopUp: "/social/linkedin",
  twitterPopUp: "/social/twitter",
  googlePopUp: "/social/google",
  googleCalendarPopUp: "/social/google/calendar",
  gmailPopUp: "/social/gmail",
  office365PopUp1: "/social/office365",
  office365PopUp2: "/social/office365/asUser",
  mercadolibrePopUp: "/social/mercadolibre",
  jumpsellerPopUp: "/social/jumpseller",
  mailchimpPopUp: "/social/mailchimp",
  wooPopUp: "/social/woo",
  facebookAnalytics: "/analytics/facebook",
  googleAnalytics: "/analytics/google",
  instagramAnalytics: "/analytics/instagram",
  analytics: "/analytics",
  webForms: "/form/web",
  editWebForm: "/form/web/:id",
  facebookForm: "/form/facebook",
  viewFacebookForm: "/form/facebook/:id",
  wooCommerceAnalytics: "/analytics/woocommerce",
  mercadolibreAnalytics: "/analytics/mercadolibre",
  wooCommerceOrders: "/market/woocommerce/orders",
  mercadolibreOrders: "/market/mercadolibre/orders",
  jumpsellerOrders: "/market/jumpseller/orders",
  editWebChat: "/chat/web/configuration",
  webChat: "/chat/web",
  editWhatsappChat: "/chat/whatsapp/configuration",
  facebookCampaigns: "/facebook/campaigns",
  createCampaign: "/campaign/create",
  contacts: "/contacts",
  contactCompanies: "/companies/contact",
  reports: "/reports",
  resellers: "/resellers",
  activityBoards: "/boards/activity",
  businessBoard: "/boards/business",
  tasks: "/boards/tasks",
  businessBoardAnalytics: "/boards/business/analytics",
  boards: "/boards",
  editBoards: "/boards/:id",
  calendar: "/calendar",
  automations: "/automations",
  notifications: "/notifications",
};

export default routes;
