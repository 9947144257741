import React, { useEffect, useRef } from "react";
import { useWebChatCore } from "../../../../cores/conversations";
import { PanelWembii } from "../../PanelWembii";
import { useMeCore } from "../../../../cores/me";
import { useSocketCore } from "../../../../cores/socket";
import MessageRow from "../MessageRow";
import { WebMessage } from "../../../../cores/conversations/interfaces/api/get-messages";
import SubmitInput from "../SubmitInput";
import ScrollButton from "../scrollButton/ScrollButton";

export default function WebChatForm() {
  const { company, user } = useMeCore();
  const { selected, messages, getMessages, attachments } = useWebChatCore();
  const { sendMessageToChannel } = useSocketCore();
  const ref = useRef<any>(null);

  useEffect(() => {
    getMessages(company?.id!, selected?.id!);
  }, [getMessages, selected, company]);

  useEffect(() => {
    if (ref !== null) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [messages]);

  const onSubmit = (message: string) => {
    if (message) {
      const messageContent: any = {
        content: message,
        type: "text",
        conversationId: selected?.id,
        userId: user?.id!,
        attachments,
      };
      sendMessageToChannel(selected?.room!, messageContent);
    }
  };

  const itsMe = (message: WebMessage) => !!message.from.id;

  return (
    <PanelWembii className="mb-3 position-relative">
      <div style={{ height: 512 }} className="position-relative mb-4">
        <div
          className="backdrop-chat p-2"
          style={{ maxHeight: "512px", overflowY: "auto" }}
        >
          {messages.map((message) => (
            <MessageRow
              key={message.id}
              message={message}
              itsMe={itsMe(message)}
            />
          ))}
          <div ref={ref} />
        </div>
      </div>
      <ScrollButton reference={ref} positionY={80} />
      <SubmitInput
        attachments={attachments}
        conversation={selected}
        disableAttachments={selected?.type !== "whatsapp"}
        onSubmit={onSubmit}
      />
    </PanelWembii>
  );
}
