import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface Props extends RouteProps {
  isLogged: boolean;
}

export default function PrivateRoute(props: Props) {
  const { isLogged } = props;

  return (
    !isLogged ?
      <Redirect to="/login" /> : 
      <Route {...props} />
  );
}
