import cn from "classnames";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import {
  FACEBOOK_POST_LOADING,
  INSTAGRAM_POST_LOADING,
  LINKED_IN_BUSINESS_POST_LOADING,
  LINKED_IN_POST_LOADING,
  TWITTER_POST_LOADING,
} from "../../../../constants/loading.constant";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { PostError, SocialType } from "../../../../cores/socialPost/interfaces/postError";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { LoadingSpinnerWembii } from "../../LoadingSpinnerWembii";
import { ErrorIcon } from "../ErrorIcon";
import { IconMapper } from "./SocialPostSelector";

interface Props {
  socialProvider: SocialProvider;
  isSelected?: boolean;
  avatar?: boolean;
  onClick?: () => void;
  className?: string;
  showErrors?: boolean;
  socialType?: SocialType;
}

const socialPostLoadingIdMap: Dictionary<string> = {
  twitter: TWITTER_POST_LOADING,
  facebook: FACEBOOK_POST_LOADING,
  linkedIn: LINKED_IN_POST_LOADING,
  linkedInBusiness: LINKED_IN_BUSINESS_POST_LOADING,
  instagram: INSTAGRAM_POST_LOADING,
};

export const SocialProviderIcon = (props: Props) => {
  const {
    socialProvider,
    isSelected,
    onClick,
    avatar,
    className,
    showErrors = true,
    socialType
  } = props;
  const { icon = IconMapper[socialProvider.id] } = socialProvider;
  const { postErrors, storyErrors } = useSocialPostCore();
  const providerErrors: PostError[] = useMemo(
    () => {
      const errors = socialType === SocialType.Post ? postErrors : storyErrors;
      return errors.filter(
        ({ socialProviderId }) => socialProviderId === socialProvider.id
      )
    },
    [postErrors, storyErrors, socialType, socialProvider]
  );

  return (
    <div
      className={cn("socialProviderIcon pointer", className)}
      key={socialProvider.id}
      onClick={onClick} 
    >
      <LoadingSpinnerWembii id={socialPostLoadingIdMap[socialProvider.id]}>
        {avatar ? (
          <>
            <img
              className={cn("socialProviderIcon-avatar", {
                "socialProviderIcon-avatar-inactive": !isSelected,
              })}
              src={socialProvider.avatar}
              alt="s"
            />
            <div className="socialProviderIcon-icon">
              {showErrors && providerErrors.length > 0 && isSelected ? (
                <ErrorIcon size="2x" />
              ) : (
                _.isFunction(icon) && icon({ active: isSelected, size: "2x" })
              )}
            </div>
          </>
        ) : (
          <div 
            className="d-flex flex-column justify-content-center align-items-center" 
            style={{height: 75}}
          >
            {showErrors && providerErrors.length > 0 && isSelected && (
              <div style={{position: 'absolute', left: 22, bottom: 15}}>
                <ErrorIcon size="xs" />
              </div> 
            )}
            {_.isFunction(icon) && icon({ active: isSelected, size: "2x" })}
          </div>
        )}
      </LoadingSpinnerWembii>
    </div>
  );
};
