import {
  LinkedInBusinessSetting,
  PostLinkedInBusinessBindResponse,
} from "../interface/api/post-linkedinBussiness-bind";
import {
  Organization,
  PostLinkedInBusinessOrganizationsResponse,
} from "../interface/api/post-linkedinBussiness-organizations";

export const linkedInBusinessSettingMapper = {
  mapResponseToLinkedInBusinessSetting(
    response: PostLinkedInBusinessBindResponse
  ): LinkedInBusinessSetting {
    const { linkedInBusinessSetting } = response?.payload;
    return linkedInBusinessSetting;
  },
  mapResponseToLinkedInOrganizations(
    response: PostLinkedInBusinessOrganizationsResponse
  ): {
    organizations: Organization[];
    token: string;
    refreshToken: string;
    expiresIn: string;
  } {
    const {
      organizations: { organizations },
      token,
      refreshToken,
      expiresIn,
    } = response?.payload;
    return { organizations, token, refreshToken, expiresIn };
  },
};
