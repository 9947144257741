import _ from "lodash";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { Col, Row } from "reactstrap";
import { JUMPSELLER_ORDER_EXPAND } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMarketCore } from "../../../../cores/market";
import { Order } from "../../../../cores/market/interface/order";
import {
  JumpsellerOrderStatus,
  JumpsellerOrderStatusColor,
  JumpsellerOrderStatusDic,
  JumpsellerStatusOptions,
} from "../../../../cores/market/jumpseller.config";
import { useMeCore } from "../../../../cores/me";
import { CL$Format } from "../../../../cores/utils";
import { PanelWembii } from "../../PanelWembii";
import { JumpsellerIcon } from "../../social/Jumpseller/JumpsellerIcon";
import { OrderModal } from "../orderModal/OrderModal";
import { OrderStatusSelector } from "../orderModal/OrderStatusSelector";
import { JumpsellerOrderStatusBadge } from "./JumpsellerOrderStatusBadge";
import { useIntl } from "react-intl";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";

interface Props {
  order: Order;
}

const icon =
  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 230.000000 230.000000' preserveAspectRatio='xMidYMid meet'><g transform='translate(10.000000,210.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'> <path d='M 700, 120 a 900,900 0 1,0 550,0' fill='#99c633' /> <path d='M719 1956 c-325 -97 -579 -356 -670 -682 -19 -68 -22 -105 -23 -264 0 -114 4 -200 12 -225 6 -22 14 -57 16 -77 3 -20 21 -71 40 -112 118 -257 351 -456 627 -537 103 -30 352 -38 465 -16 44 9 94 19 113 22 48 8 167 65 242 116 150 100 291 272 358 435 18 43 37 84 41 89 5 6 16 48 25 94 24 118 17 364 -14 470 -49 167 -140 319 -264 438 -110 106 -173 145 -382 238 -67 29 -116 35 -310 34 -178 0 -208 -3 -276 -23z m492 -166 c18 -10 156 -188 282 -364 51 -71 57 -84 57 -129 0 -52 -175 -999 -190 -1027 -13 -23 -42 -47 -69 -53 -37 -9 -762 126 -798 148 -50 33 -49 57 17 407 110 583 137 709 157 729 10 10 119 82 242 159 226 141 256 154 302 130z' fill='#353d3f' /> <path d='M1083 1626 c-23 -20 -28 -32 -28 -69 0 -78 59 -115 130 -81 41 19 57 43 58 90 2 77 -97 114 -160 60z' fill='#353d3f' /> <path d='M1025 1348 c-2 -7 -34 -166 -70 -353 -36 -187 -72 -363 -80 -392 -19 -66 -61 -103 -115 -103 -21 0 -41 -3 -43 -7 -3 -5 -8 -33 -12 -64 l-7 -56 46 -7 c63 -10 146 9 194 44 75 54 94 112 177 531 41 205 77 384 80 397 5 22 3 22 -80 22 -60 0 -87 -4 -90 -12z' fill='#353d3f'/></g></svg>";

export const JumpsellerOrder = (props: Props) => {
  const intl = useIntl();
  const {
    order: {
      id,
      number,
      creationDate,
      status,
      billing: { firstName, lastName },
      currency: code,
      price,
    },
  } = props;
  const { company, socialSettings } = useMeCore();
  const { putJumpsellerOrder } = useMarketCore();
  const { turnOnModal } = useGlobalCore();

  const jumpsellerStatus = useMemo(
    () =>
      JumpsellerStatusOptions.filter(
        (status) => status.value !== JumpsellerOrderStatus.any
      ),
    []
  );

  const handleStatusChange = useCallback(
    (status: string) => {
      putJumpsellerOrder(company?.id!, id, status);
    },
    [company, id, putJumpsellerOrder]
  );

  const onClick = useCallback(() => {
    turnOnModal(
      JUMPSELLER_ORDER_EXPAND,
      <OrderModal
        icon={<JumpsellerIcon size="5x" active />}
        order={props.order}
        avatar={socialSettings?.jumpseller?.avatar}
        iconString={icon}
        statusName={JumpsellerOrderStatusDic[status as JumpsellerOrderStatus]}
        statusColor={
          JumpsellerOrderStatusColor[status as JumpsellerOrderStatus]
        }
        status={
          <OrderStatusSelector
            orderStatus={jumpsellerStatus}
            status={status}
            onChange={handleStatusChange}
          />
        }
      />,
      Position.LEFT
    );
  }, [props.order, handleStatusChange, turnOnModal, socialSettings, jumpsellerStatus, status]);

  return (
    <PanelWembii className="w-100 px-4 py-3 my-2 pointer" onClick={onClick}>
      <Row>
        <Col
          xs="12"
          lg="1"
          className="text-center text-lg-start text-black-50"
        >{`#${number}`}</Col>
        <Col xs="12" lg="2">
          <div className="w-fit mx-auto">
            <JumpsellerOrderStatusBadge
              status={status as JumpsellerOrderStatus}
            />
          </div>
        </Col>
        <Col xs="12" lg="2" className="text-center">
          {moment(creationDate).format("DD/MM/YYYY")}
        </Col>
        <Col xs="12" lg="2" className="text-center">{`${CL$Format(
          +price,
          code
        )}(${code})`}</Col>
        <Col xs="12" lg="auto">
          <div className="text-center">
            <span className="text-black-50">
              {intl.formatMessage({ id: "app.onlinesales.order6" })}
            </span>
            {`${_.capitalize(lastName)} ${_.capitalize(firstName)}`}
          </div>
        </Col>
      </Row> 
    </PanelWembii>
  );
};
