import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { FacebookAccount } from "../interface/api/get-facebook-account";
import { FacebookPage } from "../interface/api/get-facebook-page";
import { FacebookSetting } from "../interface/api/post-facebook-bind";
import {
  FacebookApiAction,
  getFacebookFailureAction,
  getFacebookInitAction,
  getFacebookSuccessAction,
  postBindFacebookFailureAction,
  postBindFacebookInitAction,
  postBindFacebookSuccessAction,
  getFacebookAccountsFailureAction,
  getFacebookAccountsInitAction,
  getFacebookAccountsSuccessAction,
  postUnbindFacebookFailureAction,
  postUnbindFacebookInitAction,
  postUnbindFacebookSuccessAction,
  putFacebookSocialPostFailureAction,
  putFacebookSocialPostInitAction,
  putFacebookSocialPostSuccessAction,
  getFacebookPagesInitAction,
  getFacebookPagesFailureAction,
  getFacebookPagesSuccessAction,
} from "./api-actions";

export interface FacebookState {
  settings: FacebookSetting | null;
  accounts: FacebookAccount[];
  pages: FacebookPage[];
  error: string;
}

const initialState: FacebookState = {
  settings: null,
  accounts: [],
  pages: [],
  error: "",
};

export default produce(
  (
    draft: FacebookState = initialState,
    action: FacebookApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindFacebookSuccessAction.type:
      case getFacebookSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindFacebookFailureAction.type:
      case postBindFacebookFailureAction.type:
      case getFacebookFailureAction.type:
      case getFacebookAccountsFailureAction.type:
        draft.error = action.error;
        return draft;
      case postUnbindFacebookSuccessAction.type:
        draft.settings = null;
        return draft;
      case getFacebookAccountsSuccessAction.type:
        draft.accounts = action.accounts;
        return draft;
      case getFacebookPagesSuccessAction.type:
        draft.pages = action.pages;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindFacebookInitAction.type:
      case postUnbindFacebookInitAction.type:
      case getFacebookInitAction.type:
      case getFacebookAccountsInitAction.type:
      case putFacebookSocialPostInitAction.type:
      case putFacebookSocialPostSuccessAction.type:
      case putFacebookSocialPostFailureAction.type:
      case getFacebookPagesInitAction.type:
      case getFacebookPagesFailureAction.type:
      default:
        return draft;
    }
  }
);
