import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../cores/me";
import { InputWembii } from "../InputWembii";
import { COMMENT_TYPES } from "../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { CommentType } from "../../../cores/config/interfaces/api/get-comment-types";
import { useConfigCore } from "../../../cores/config";

interface Props {
  type?: CommentType;
  onUpdate: () => void;
}

export default function TypeForm({ type, onUpdate }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const { turnOffInternalModal } = useGlobalCore();
  const { postCommentType, putCommentType, deleteCommentType } = useConfigCore();

  const [name, setName] = useState("");

  useEffect(() => {
    if (type) {
      setName(type.name);
    }
  }, [type]);

  const cleanForm = useCallback(() => {
    setName("");
    turnOffInternalModal(COMMENT_TYPES);
  }, [turnOffInternalModal]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!type) {
      postCommentType(
        company?.id!,
        name,
        cleanForm
      );
    } else {
      putCommentType(
        company?.id!,
        type.id,
        name,
        () => {
          cleanForm();
          onUpdate();
        }
      );
    }
  };

  const onDelete = useCallback(() => {
    deleteCommentType(
      company?.id!,
      type?.id!,
      cleanForm
    );
  }, [deleteCommentType, cleanForm, company, type]);

  const disabled = useCallback(
    () => !name,
    [name]
  );

  return (
    <Form className="p-2" onSubmit={onSubmit}>
      <Row>
        <Col>
          <InputWembii
            label={intl.formatMessage({ id: "app.contacts.origins.name" })}
            type="text"
            name="name"
            id="tag-name"
            className="pr-1"
            required
            placeholder={intl.formatMessage({ id: "app.contacts.origins.name" })}
            value={name}
            onChange={setName}
          />
        </Col>
      </Row>

      {type && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.contacts.origins.delete" })}  
        </Button>
      )}
      <Button color="primary" disabled={disabled()} className="ml-1">
        {type 
          ? intl.formatMessage({ id: "app.contacts.origins.update" })
          : intl.formatMessage({ id: "app.contacts.origins.create" })
        }  
      </Button>
    </Form>
  );
}