import cn from "classnames";
import moment from "moment";
import React, { useCallback, useMemo, } from "react";
import DatePicker from "react-date-picker";
import { Input } from "reactstrap";
import { useAnalyticsCore } from "../../../cores/analytics";
import {
  MetricDate,
  MetricDateMode,
} from "../../../cores/analytics/interface/Metric2";
import {
  getMonthDateRange,
  getMonthsFromNow,
  Month,
} from "../../../cores/timeUtils";
import { FromFeedback2 } from "../FromFeedback2";
import { useIntl } from "react-intl";

interface Props {
  fixed?: boolean;
}

export const AnalyticsDatePicker = ({ fixed = true }: Props) => {
  const intl = useIntl();
  const { date, updateDate } = useAnalyticsCore();

  const options = [
    { 
      label: intl.formatMessage({ id: "app.metricsGoogle.option1" }), 
      value: "Month" 
    },
    { 
      label: intl.formatMessage({ id: "app.metricsGoogle.option2" }),
      value: "Days" 
    },
    { 
      label: intl.formatMessage({ id: "app.metricsGoogle.option3" }), 
      value: "Custom" 
    },
  ];

  const daysOptions = [
    { 
      label: intl.formatMessage({ id: "app.metricsGoogle.option4" }), 
      value: 7 
    },
    { 
      label: intl.formatMessage({ id: "app.metricsGoogle.option5" }), 
      value: 15 
    },
    {
       label: intl.formatMessage({ id: "app.metricsGoogle.option6" }),
       value: 30 
      },
  ];

  const handleUpdateDate = useCallback(
    (date: Partial<MetricDate>) => {
      updateDate(date);
    },
    [updateDate]
  );

  const getLastDaysFromDate = (date: string) => {
    return moment().diff(date, "days");
  };

  const onChangeMonth = (month: Month) => {
    const year = moment().year();
    const { start, end } = getMonthDateRange(year, month);
    handleUpdateDate({
      since: start.format("YYYY-MM-DD"),
      until: end.format("YYYY-MM-DD"),
    });
  };

  const isFutureDate = (date: string) => moment(date).isAfter(moment());
  
  const areDatesInverted = (since: string, until: string) => 
    moment(since).isAfter(moment(until));

  const isSinceDateInvalid = useMemo(
    () => isFutureDate(date.since) || areDatesInverted(date.since, date.until),
    [date]
  );

  const isUntilDateInvalid = useMemo(
    () => isFutureDate(date.until) || areDatesInverted(date.since, date.until),
    [date]
  );

  const futureDateFeedback = (date: string) => (
    <FromFeedback2
      show={isFutureDate(date)}
      message={intl.formatMessage({ id: "app.metricsGoogle.err1" })}
    />
  );  

  const invertedDatesFeedback = (since: string, until: string) => (
    <FromFeedback2
      show={areDatesInverted(since, until) && !isFutureDate(since)}
      message={intl.formatMessage({ id: "app.metricsGoogle.err2" })}
    />
  );

  return (
    <div 
      className={cn("d-flex flex-justify-between btn whiteButton mt-4 z-index-1", {
        "position-fixed": fixed
      })}
    >
      <Input
        type="select"
        className="border-0 m-2"
        onChange={(e) =>
          handleUpdateDate({ mode: e.target.value as MetricDateMode })
        }
        value={date.mode}
      >
        {options.map((opt) => (
          <option key={opt.label} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Input>
      {date.mode === "Custom" && (
        <div className="d-flex m-2">
          <div className="d-flex flex-column mr-2">
            <DatePicker
              onChange={
                (d: any) => handleUpdateDate({ since: moment(d).format("YYYY-MM-DD") })
              }
              value={moment(date.since).toDate()}
              clearIcon={null}
              format="dd/MM/y"
              className={cn({ "is-invalid": isSinceDateInvalid })}
            />
            {futureDateFeedback(date.since)}
            {invertedDatesFeedback(date.since, date.until)}
          </div>
          <div className="d-flex flex-column ml-1">
            <DatePicker
              onChange={
                (d: any) => handleUpdateDate({ until: moment(d).format("YYYY-MM-DD") })
              }
              value={moment(date.until).toDate()}
              clearIcon={null}
              format="dd/MM/y"
              className={cn({ "is-invalid": isUntilDateInvalid })}
            />
            {futureDateFeedback(date.until)}
          </div>
        </div>
      )}
      {date.mode === "Days" && (
        <div className="d-flex m-2 w-100">
          <Input
            type="select"
            className="border-0"
            onChange={(e) =>
              handleUpdateDate({
                until: moment().format("YYYY-MM-DD"),
                since: moment()
                  .subtract(e.target.value, "days")
                  .format("YYYY-MM-DD"),
              })
            }
            value={getLastDaysFromDate(date.since)}
          >
            {daysOptions.map((opt) => (
              <option key={opt.label} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Input>
        </div>
      )}
      {date.mode === "Month" && (
        <div className="d-flex m-2 w-100">
          <Input
            type="select"
            className="border-0"
            onChange={(e) =>
              onChangeMonth((e.target.value as unknown) as Month)
            }
          >
            {getMonthsFromNow(2).map((opt) => (
              <option key={opt.label} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Input>
        </div>
      )}
    </div>
  );
};
