import humanizeDuration from "humanize-duration";
const humanizer = humanizeDuration.humanizer();
humanizer.languages.shortEs = {
  y: () => "año",
  mo: () => "mes",
  w: () => "sem",
  d: () => "dia",
  h: () => "hora",
  m: () => "min",
  s: () => "seg",
  ms: () => "ms",
};

export const timeFormatter = (value: number) =>
  humanizer(value * 1000, {
    language: "shortEs",
    round: true,
    units: ["m", "h"],
  });

export const createGradientFill = (canvas: any, color: string) => {
  const ctx = canvas.getContext("2d");
  const gradientFill = ctx.createLinearGradient(0, 230, 0, 50);
  gradientFill.addColorStop(0, "#FFF");
  gradientFill.addColorStop(1, color);
  return gradientFill;
};

export const pieChartBasicOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    bodySpacing: 4,
    mode: "nearest",
    intersect: 1,
    position: "nearest",
    xPadding: 10,
    yPadding: 10,
    caretPadding: 10,
  },
  responsive: 1,
  scales: {
    yAxes: [
      {
        display: 0,
        ticks: {
          display: false,
        },
        position: "left",
        id: "left",
        gridLines: {
          zeroLineColor: "transparent",
          drawTicks: false,
          display: false,
          drawBorder: false,
        },
      },
      {
        display: 0,
        ticks: {
          display: false,
        },
        position: "right",
        id: "right",
        gridLines: {
          zeroLineColor: "transparent",
          drawTicks: false,
          display: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        display: 0,
        ticks: {
          display: false,
        },
        gridLines: {
          zeroLineColor: "transparent",
          drawTicks: false,
          display: false,
          drawBorder: false,
        },
      },
    ],
  },
  layout: {
    padding: { left: 0, right: 0, top: 10, bottom: 10 },
  },
};

export const barLineChartBasicOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    bodySpacing: 4,
    mode: "nearest",
    intersect: 1,
    position: "nearest",
    xPadding: 10,
    yPadding: 10,
    caretPadding: 10,
  },
  responsive: 1,
  scales: {
    yAxes: [
      {
        display: 1,
        ticks: {
          display: true,
        },
        position: "left",
        id: "left",
        gridLines: {
          zeroLineColor: "transparent",
          drawTicks: true,
          display: true,
          drawBorder: false,
        },
        scaleLabel: {
          display: true,
        }
      },
    ],
    xAxes: [
      {
        display: 1,
        ticks: {
          display: true,
        },
        gridLines: {
          zeroLineColor: "transparent",
          drawTicks: true,
          display: true,
          drawBorder: false,
        },
        scaleLabel: {
          display: true,
        }
      },
    ],
  },
  layout: {
    padding: { left: 0, right: 0, top: 10, bottom: 10 },
  },
};

export const charBasicDataset = {
  borderWidth: 2,
  pointBorderWidth: 2,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 1,
  pointRadius: 4,
  pointBorderColor: "#FFF",
};
