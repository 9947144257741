import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  COMPANIES_EXPORT_LOADING,
  CONTACTS_EXPORT_LOADING,
  CONTACTS_IMPORT_LOADING,
  CONTACTS_LOADING,
  CONTACT_COMPANIES_LOADING,
  CONTACT_LOADING,
  CONTACT_POST_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { Contact, GetContactsParams } from "./interfaces/api/get-contacts";
import { CsvContact } from "./interfaces/api/post-contacts";
import { contactApiService } from "./services/api";
import {
  deleteCompanyFailureAction,
  deleteCompanyInitAction,
  deleteCompanySuccessAction,
  deleteContactCompanyFailureAction,
  deleteContactCompanyInitAction,
  deleteContactCompanySuccessAction,
  deleteContactTagFailureAction,
  deleteContactTagInitAction,
  deleteContactTagSuccessAction,
  getCompaniesFailureAction,
  getCompaniesInitAction,
  getCompaniesSuccessAction,
  getCompanyFailureAction,
  getCompanyInitAction,
  getCompanySuccessAction,
  getContactFailureAction,
  getContactInitAction,
  getContactSuccessAction,
  getContactsFailureAction,
  getContactsInitAction,
  getContactsSuccessAction,
  getContactTagsFailureAction,
  getContactTagsInitAction,
  getContactTagsSuccessAction,
  getExportContactsFailureAction,
  getExportContactsInitAction,
  getExportContactsSuccessAction,
  postCompanyFailureAction,
  postCompanyInitAction,
  postCompanySuccessAction,
  postContactCompanyFailureAction,
  postContactCompanyInitAction,
  postContactCompanySuccessAction,
  postContactTagFailureAction,
  postContactTagInitAction,
  postContactTagSuccessAction,
  postImportContactsFailureAction,
  postImportContactsInitAction,
  postImportContactsSuccessAction,
  putCompanyFailureAction,
  putCompanyInitAction,
  putCompanySuccessAction,
  putContactCompanyFailureAction,
  putContactCompanyInitAction,
  putContactCompanySuccessAction,
  putContactTagFailureAction,
  putContactTagInitAction,
  putContactTagSuccessAction,
  getCustomFieldsInitAction,
  getCustomFieldsSuccessAction,
  getCustomFieldsFailureAction,
  postCustomFieldInitAction,
  postCustomFieldFailureAction,
  postCustomFieldSuccessAction,
  putCustomFieldInitAction,
  putCustomFieldSuccessAction,
  putCustomFieldFailureAction,
  deleteCustomFieldInitAction,
  deleteCustomFieldSuccessAction,
  deleteCustomFieldFailureAction,
  putContactInitAction,
  putContactSuccessAction,
  getContactConfigInitAction,
  getContactConfigSuccessAction,
  getContactConfigFailureAction,
  postContactConfigInitAction,
  postContactConfigSuccessAction,
  postContactConfigFailureAction,
  deleteContactConfigInitAction,
  deleteContactConfigSuccessAction,
  deleteContactConfigFailureAction,
  postCommentInitAction,
  postCommentSuccessAction,
  postCommentFailureAction,
  putCommentInitAction,
  putCommentSuccessAction,
  putCommentFailureAction,
  deleteCommentInitAction,
  deleteCommentSuccessAction,
  deleteCommentFailureAction,
  getExportCompaniesInitAction,
  getExportCompaniesFailureAction,
  getExportCompaniesSuccessAction,
  getContactStatesFailureAction,
  getContactStatesInitAction,
  getContactStatesSuccessAction,
  postContactStateFailureAction,
  postContactStateInitAction,
  postContactStateSuccessAction,
  putContactStateSuccessAction,
  putContactStateFailureAction,
  putContactStateInitAction,
  deleteContactStateFailureAction,
  deleteContactStateInitAction,
  deleteContactStateSuccessAction,
  postMassAssignmentInitAction,
  postMassAssignmentSuccessAction,
  postMassAssignmentFailureAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import {
  contactsSelector,
  contactTagsSelector,
  headersSelector,
  totalContactsSelector,
  selectedContactsSelector,
  contactFiltersSelector,
  companyFiltersSelector,
  companiesSelector,
  companySelector,
  contactCompaniesSelector,
  contactEmailsSelector,
  contactSelector,
  customFieldValuesSelector,
  sourceSelector,
  configSelector,
  contactCardsSelector,
  contactCommentsSelector,
  contactStatesSelector,
  customFieldsSelector,
  massAssignmentSelector,
  replaceAssignmentSelector,
  contactStateRequestSelector,
} from "./store/selector";
import {
  deselectAllContactsAction,
  deselectContactAction,
  resetHeadersAction,
  selectContactAction,
  setHeadersAction,
  setContactFiltersAction,
  setCompanyFiltersAction,
  setSourceAction,
  addMassAssignmentAction,
  removeMassAssignmentAction,
  cleanMassAssignmentAction,
  setReplaceAssignmentAction,
} from "./store/actions";
import { CONTACT_EXPAND_CREATION_FORM } from "../../constants/modal.constant";
import { PostCompanyRequest } from "./interfaces/api/post-company";
import { PutCompanyRequest } from "./interfaces/api/put-company";
import { PutContactRequest } from "./interfaces/api/put-contact";
import { PostContactRequest } from "./interfaces/api/post-contact";
import { GetCompaniesParams } from "./interfaces/api/get-companies";
import { Errors } from "../../constants/errors";
import { PostContactConfigRequest } from "./interfaces/api/post-config";
import { PostCommentRequest } from "./interfaces/api/post-comment";
import { PutCommentRequest } from "./interfaces/api/put-comment";
import { PostMassAssignmentRequest } from "./interfaces/api/post-mass-assignment";
import { setContactStateRequestAction } from "../contacts/store/actions";
import { ContactStateRequest } from "./interfaces/contact-state-request";

export function useContactCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const contactStateRequest = useSelector(contactStateRequestSelector);
  const contacts = useSelector(contactsSelector);
  const companies = useSelector(companiesSelector);
  const contact = useSelector(contactSelector);
  const contactCompanies = useSelector(contactCompaniesSelector);
  const contactEmails = useSelector(contactEmailsSelector);
  const contactComments = useSelector(contactCommentsSelector);
  const customFieldValues = useSelector(customFieldValuesSelector);
  const contactCards = useSelector(contactCardsSelector);
  const selectedContacts = useSelector(selectedContactsSelector);
  const tags = useSelector(contactTagsSelector);
  const states = useSelector(contactStatesSelector);
  const customFields = useSelector(customFieldsSelector);
  const total = useSelector(totalContactsSelector);
  const headers = useSelector(headersSelector);
  const contactFilters = useSelector(contactFiltersSelector);
  const companyFilters = useSelector(companyFiltersSelector);
  const source = useSelector(sourceSelector);
  const company = useSelector(companySelector);
  const config = useSelector(configSelector);
  const massAssignment = useSelector(massAssignmentSelector);
  const replaceAssignment = useSelector(replaceAssignmentSelector);
  const { turnOnLoading, turnOffLoading, turnOffModal } = useGlobalCore();

  const getContacts = useCallback(
    async (
      companyId: string,
      params: GetContactsParams,
      showLoading = true,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getContactsInitAction());
        showLoading && turnOnLoading(CONTACTS_LOADING);
        const { contacts, total } = await contactApiService.getContacts(
          companyId,
          params
        );
        dispatch(getContactsSuccessAction({ contacts, total }));
        showLoading && turnOffLoading(CONTACTS_LOADING, 1500);
        onSuccess?.();
      } catch (error: any) {
        showLoading && turnOffLoading(CONTACTS_LOADING);
        console.error(error);
        dispatch(getContactsFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const getContact = useCallback(
    async (companyId: string, contactId: string) => {
      try {
        dispatch(getContactInitAction());
        turnOnLoading(CONTACT_LOADING);
        const response = await contactApiService.getContact(
          companyId,
          contactId
        );
        dispatch(getContactSuccessAction(response));
        turnOffLoading(CONTACT_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(CONTACT_LOADING);
        console.error(error);
        dispatch(getContactFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const getExportContacts = useCallback(
    async (companyId: string, params: GetContactsParams) => {
      try {
        dispatch(getExportContactsInitAction());
        turnOnLoading(CONTACTS_EXPORT_LOADING);
        const { contacts } = await contactApiService.getExportContacts(
          companyId,
          params
        );
        dispatch(getExportContactsSuccessAction());
        turnOffLoading(CONTACTS_EXPORT_LOADING, 1500);
        return contacts;
      } catch (error: any) {
        console.error(error);
        turnOffLoading(CONTACTS_EXPORT_LOADING);
        dispatch(getExportContactsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const postContacts = useCallback(
    async (
      companyId: string,
      contacts: CsvContact[],
      tag: string,
      onSuccess?: () => void
    ) => {
      try {
        turnOnLoading(CONTACTS_IMPORT_LOADING);
        dispatch(postImportContactsInitAction());
        await contactApiService.postContacts(companyId, contacts, tag);
        dispatch(postImportContactsSuccessAction());
        turnOffLoading(CONTACTS_IMPORT_LOADING, 1500);
        onSuccess?.();
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            case Errors.BatchRunning:
              dispatch(postImportContactsFailureAction({ error }));
              toast.info(
                "Ya existe un proceso ejecutandose para sincronizar los contactos. Espere que termine para iniciar uno nuevo."
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error14" }));
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error14" }));
        }

        console.log(error);
        turnOffLoading(CONTACTS_IMPORT_LOADING, 1500);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );
  const postContact = useCallback(
    async (
      companyId: string,
      body: PostContactRequest,
      onSuccess?: () => void
    ) => {
      try {
        turnOnLoading(CONTACT_POST_LOADING);
        const response = await contactApiService.postContact(companyId, body);
        onSuccess?.();
        turnOffModal(CONTACT_EXPAND_CREATION_FORM);
        toast.success(intl.formatMessage({ id: "app.toast.sucess4" }));
        turnOffLoading(CONTACT_POST_LOADING, 1000);
        return response;
      } catch (error: any) {
        console.log(error);
        turnOffLoading(CONTACT_POST_LOADING);
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            case Errors.DuplicatedEmail:
              toast.error(intl.formatMessage({ id: "app.toast.error80" }));
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error15" }));
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error15" }));
          console.error(error);
        }
      }
    },
    [intl, turnOffLoading, turnOffModal, turnOnLoading]
  );
  const putContact = useCallback(
    async (
      companyId: string,
      contactId: string,
      body: PutContactRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putContactInitAction());
        const response = await contactApiService.putContact(
          companyId,
          contactId,
          body
        );
        toast.success(intl.formatMessage({ id: "app.toast.sucess5" }));
        onSuccess?.();
        dispatch(putContactSuccessAction(response));
        return response;
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          switch (code) {
            case Errors.DuplicatedEmail:
              toast.error(intl.formatMessage({ id: "app.toast.error80" }));
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error16" }));
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error16" }));
          console.error(error);
        }
      }
    },
    [intl, dispatch]
  );
  const deleteContacts = useCallback(
    async (companyId: string, contacts: string[], onSuccess?: () => void) => {
      try {
        await contactApiService.deleteContacts(contacts, companyId);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
      }
    },
    []
  );
  const getContactTags = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getContactTagsInitAction());
        const { tags } = await contactApiService.getContactTags(companyId);
        dispatch(getContactTagsSuccessAction({ tags }));
      } catch (error: any) {
        console.error(error);
        dispatch(getContactTagsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postContactTag = useCallback(
    async (
      companyId: string,
      name: string,
      textColor: string,
      backgroundColor: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postContactTagInitAction());
        const tag = await contactApiService.postContactTag(
          companyId,
          name,
          textColor,
          backgroundColor
        );
        toast.success(intl.formatMessage({ id: "app.toast.sucess48" }));
        dispatch(postContactTagSuccessAction({ tag }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postContactTagFailureAction({ error }));
        toast.error(intl.formatMessage({ id: "app.toast.error70" }));
      }
    },
    [dispatch, intl]
  );
  const putContactTag = useCallback(
    async (
      companyId: string,
      id: string,
      name: string,
      textColor: string,
      backgroundColor: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putContactTagInitAction());
        const tag = await contactApiService.putContactTag(
          companyId,
          id,
          name,
          textColor,
          backgroundColor
        );
        toast.success(intl.formatMessage({ id: "app.toast.sucess49" }));
        dispatch(putContactTagSuccessAction({ tag }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putContactTagFailureAction({ error }));
        toast.error(intl.formatMessage({ id: "app.toast.error71" }));
      }
    },
    [dispatch, intl]
  );
  const deleteContactTag = useCallback(
    async (companyId: string, id: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteContactTagInitAction());
        await contactApiService.deleteContactTag(id, companyId);
        toast.success(intl.formatMessage({ id: "app.toast.sucess50" }));
        dispatch(deleteContactTagSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteContactTagFailureAction({ error }));
        if (error.request.status === 403) {
          toast.error(intl.formatMessage({ id: "app.toast.error73" }));
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error72" }));
        }
      }
    },
    [dispatch, intl]
  );
  const getContactStates = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getContactStatesInitAction());
        const { states } = await contactApiService.getContactStates(companyId);
        dispatch(getContactStatesSuccessAction({ states }));
      } catch (error: any) {
        console.error(error);
        dispatch(getContactStatesFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postContactState = useCallback(
    async (companyId: string, name: string, onSuccess?: () => void) => {
      try {
        dispatch(postContactStateInitAction());
        const state = await contactApiService.postContactState(companyId, name);
        dispatch(postContactStateSuccessAction({ state }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postContactStateFailureAction({ error }));
        toast.error(intl.formatMessage({ id: "app.toast.error70" }));
      }
    },
    [dispatch, intl]
  );
  const putContactState = useCallback(
    async (
      companyId: string,
      id: string,
      name: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putContactStateInitAction());
        const state = await contactApiService.putContactState(
          companyId,
          id,
          name
        );
        dispatch(putContactStateSuccessAction({ state }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putContactStateFailureAction({ error }));
        toast.error(intl.formatMessage({ id: "app.toast.error71" }));
      }
    },
    [dispatch, intl]
  );
  const deleteContactState = useCallback(
    async (companyId: string, id: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteContactStateInitAction());
        await contactApiService.deleteContactState(id, companyId);
        dispatch(deleteContactStateSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteContactStateFailureAction({ error }));
        if (error.request.status === 403) {
          toast.error(intl.formatMessage({ id: "app.toast.error73" }));
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error72" }));
        }
      }
    },
    [dispatch, intl]
  );
  const getCustomFields = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getCustomFieldsInitAction());
        const response = await contactApiService.getCustomFields(companyId);
        dispatch(getCustomFieldsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getCustomFieldsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCustomField = useCallback(
    async (companyId: string, name: string, onSuccess?: () => void) => {
      try {
        dispatch(postCustomFieldInitAction());
        const response = await contactApiService.postCustomField(
          companyId,
          name
        );
        dispatch(postCustomFieldSuccessAction(response));
        onSuccess?.();
        return response.field.id;
      } catch (error: any) {
        console.error(error);
        dispatch(postCustomFieldFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putCustomField = useCallback(
    async (
      companyId: string,
      id: string,
      name: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCustomFieldInitAction());
        const response = await contactApiService.putCustomField(
          companyId,
          id,
          name
        );
        dispatch(putCustomFieldSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putCustomFieldFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteCustomField = useCallback(
    async (companyId: string, id: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteCustomFieldInitAction());
        await contactApiService.deleteCustomField(id, companyId);
        dispatch(deleteCustomFieldSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCustomFieldFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getCompanies = useCallback(
    async (companyId: string, filters: GetCompaniesParams) => {
      try {
        dispatch(getCompaniesInitAction());
        turnOnLoading(CONTACT_COMPANIES_LOADING);
        const response = await contactApiService.getCompanies(
          companyId,
          filters
        );
        dispatch(getCompaniesSuccessAction(response));
        turnOffLoading(CONTACT_COMPANIES_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(CONTACT_COMPANIES_LOADING);
        console.error(error);
        dispatch(getCompaniesFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const getExportCompanies = useCallback(
    async (companyId: string, filters: GetCompaniesParams) => {
      try {
        dispatch(getExportCompaniesInitAction());
        turnOnLoading(COMPANIES_EXPORT_LOADING);
        const { companies } = await contactApiService.getExportCompanies(
          companyId,
          filters
        );
        dispatch(getExportCompaniesSuccessAction());
        turnOffLoading(COMPANIES_EXPORT_LOADING, 1500);
        return companies;
      } catch (error: any) {
        console.error(error);
        turnOffLoading(COMPANIES_EXPORT_LOADING);
        dispatch(getExportCompaniesFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getCompany = useCallback(
    async (companyId: string, contactCompanyId: string) => {
      try {
        dispatch(getCompanyInitAction());
        const response = await contactApiService.getCompany(
          companyId,
          contactCompanyId
        );
        dispatch(getCompanySuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCompany = useCallback(
    async (
      companyId: string,
      body: PostCompanyRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCompanyInitAction());
        const response = await contactApiService.postCompany(companyId, body);
        dispatch(postCompanySuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putCompany = useCallback(
    async (
      companyId: string,
      contactCompanyId: string,
      body: PutCompanyRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCompanyInitAction());
        const response = await contactApiService.putCompany(
          companyId,
          contactCompanyId,
          body
        );
        dispatch(putCompanySuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteCompany = useCallback(
    async (companyId: string, contactCompanyId: string) => {
      try {
        dispatch(deleteCompanyInitAction());
        const response = await contactApiService.deleteCompany(
          companyId,
          contactCompanyId
        );
        dispatch(deleteCompanySuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postContactCompany = useCallback(
    async (
      companyId: string,
      contactCompanyId: string,
      contactId: string,
      role: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postContactCompanyInitAction());
        await contactApiService.postContactCompany(
          companyId,
          contactCompanyId,
          contactId,
          role
        );
        dispatch(postContactCompanySuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postContactCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putContactCompany = useCallback(
    async (
      companyId: string,
      contactCompanyId: string,
      contactId: string,
      role: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putContactCompanyInitAction());
        await contactApiService.putContactCompany(
          companyId,
          contactCompanyId,
          contactId,
          role
        );
        dispatch(putContactCompanySuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putContactCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteContactCompany = useCallback(
    async (
      companyId: string,
      contactCompanyId: string,
      contactId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteContactCompanyInitAction());
        await contactApiService.deleteContactCompany(
          companyId,
          contactCompanyId,
          contactId
        );
        dispatch(deleteContactCompanySuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteContactCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getContactConfig = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(getContactConfigInitAction());
        const response = await contactApiService.getContactConfig(companyId);
        dispatch(getContactConfigSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(getContactConfigFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postContactConfig = useCallback(
    async (
      companyId: string,
      config: PostContactConfigRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postContactConfigInitAction());
        const response = await contactApiService.postContactConfig(
          companyId,
          config
        );
        dispatch(postContactConfigSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postContactConfigFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteContactConfig = useCallback(
    async (companyId: string, configId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteContactConfigInitAction());
        const response = await contactApiService.deleteContactConfig(
          companyId,
          configId
        );
        dispatch(deleteContactConfigSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteContactConfigFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postComment = useCallback(
    async (
      companyId: string,
      contactId: string,
      body: PostCommentRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postCommentInitAction());
        const response = await contactApiService.postComment(
          companyId,
          contactId,
          body
        );
        dispatch(postCommentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postCommentFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putComment = useCallback(
    async (
      companyId: string,
      contactId: string,
      commentId: string,
      body: PutCommentRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCommentInitAction());
        const response = await contactApiService.putComment(
          companyId,
          contactId,
          commentId,
          body
        );
        dispatch(putCommentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putCommentFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteComment = useCallback(
    async (
      companyId: string,
      contactId: string,
      commentId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(deleteCommentInitAction());
        const response = await contactApiService.deleteComment(
          companyId,
          contactId,
          commentId
        );
        dispatch(deleteCommentSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCommentFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getCompanyCustomFields = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getCustomFieldsInitAction());
        const response = await contactApiService.getCompanyCustomFields(
          companyId
        );
        dispatch(getCustomFieldsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getCustomFieldsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCompanyCustomField = useCallback(
    async (companyId: string, name: string, onSuccess?: () => void) => {
      try {
        dispatch(postCustomFieldInitAction());
        const response = await contactApiService.postCompanyCustomField(
          companyId,
          name
        );
        dispatch(postCustomFieldSuccessAction(response));
        onSuccess?.();
        return response.field.id;
      } catch (error: any) {
        console.error(error);
        dispatch(postCustomFieldFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putCompanyCustomField = useCallback(
    async (
      companyId: string,
      id: string,
      name: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putCustomFieldInitAction());
        const response = await contactApiService.putCompanyCustomField(
          companyId,
          id,
          name
        );
        dispatch(putCustomFieldSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putCustomFieldFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteCompanyCustomField = useCallback(
    async (companyId: string, id: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteCustomFieldInitAction());
        await contactApiService.deleteCompanyCustomField(id, companyId);
        dispatch(deleteCustomFieldSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteCustomFieldFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postMassAssignment = useCallback(
    async (
      companyId: string,
      body: PostMassAssignmentRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postMassAssignmentInitAction());
        await contactApiService.postMassAssignment(companyId, body);
        dispatch(postMassAssignmentSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postMassAssignmentFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const setHeaders = useCallback(
    (headers: Record<string, number>) => {
      dispatch(setHeadersAction({ headers }));
    },
    [dispatch]
  );
  const resetHeaders = useCallback(() => {
    dispatch(resetHeadersAction());
  }, [dispatch]);
  const selectContact = useCallback(
    (contact: Contact) => {
      dispatch(selectContactAction({ contact }));
    },
    [dispatch]
  );
  const deselectContact = useCallback(
    (id: string) => {
      dispatch(deselectContactAction({ id }));
    },
    [dispatch]
  );
  const deselectAllContacts = useCallback(() => {
    dispatch(deselectAllContactsAction());
  }, [dispatch]);
  const setSource = useCallback(
    (source: string) => {
      dispatch(setSourceAction({ source }));
    },
    [dispatch]
  );
  const setContactFilters = useCallback(
    (filters: GetContactsParams) => {
      dispatch(setContactFiltersAction({ filters }));
    },
    [dispatch]
  );
  const setCompanyFilters = useCallback(
    (filters: GetCompaniesParams) => {
      dispatch(setCompanyFiltersAction({ filters }));
    },
    [dispatch]
  );
  const addMassAssignment = useCallback(
    (id: string) => {
      dispatch(addMassAssignmentAction({ id }));
    },
    [dispatch]
  );
  const removeMassAssignment = useCallback(
    (id: string) => {
      dispatch(removeMassAssignmentAction({ id }));
    },
    [dispatch]
  );
  const cleanAssigments = useCallback(() => {
    dispatch(cleanMassAssignmentAction());
  }, [dispatch]);
  const toggleReplaceAssigments = useCallback(() => {
    dispatch(setReplaceAssignmentAction({ replace: !replaceAssignment }));
  }, [replaceAssignment, dispatch]);

  return {
    contacts,
    selectedContacts,
    headers,
    tags,
    states,
    total,
    contactFilters,
    companyFilters,
    source,
    companies,
    company,
    contact,
    contactCompanies,
    contactEmails,
    contactComments,
    customFieldValues,
    contactCards,
    customFields,
    config,
    massAssignment,
    replaceAssignment,
    contactStateRequest,
    postContact,
    getContacts,
    putContact,
    deleteContacts,
    getExportContacts,
    postContacts,
    setHeaders,
    resetHeaders,
    getContactTags,
    postContactTag,
    putContactTag,
    deleteContactTag,
    getContactStates,
    postContactState,
    putContactState,
    deleteContactState,
    selectContact,
    deselectAllContacts,
    deselectContact,
    setContactFilters,
    setCompanyFilters,
    setSource,
    getCompanies,
    getCompany,
    postCompany,
    putCompany,
    deleteCompany,
    postContactCompany,
    putContactCompany,
    deleteContactCompany,
    getContact,
    getCustomFields,
    postCustomField,
    putCustomField,
    deleteCustomField,
    getContactConfig,
    postContactConfig,
    deleteContactConfig,
    postComment,
    putComment,
    deleteComment,
    getExportCompanies,
    getCompanyCustomFields,
    postCompanyCustomField,
    putCompanyCustomField,
    deleteCompanyCustomField,
    addMassAssignment,
    removeMassAssignment,
    cleanAssigments,
    toggleReplaceAssigments,
    postMassAssignment,
    setConversationContactState: (contactState: ContactStateRequest) =>
      dispatch(
        setContactStateRequestAction({ contactStateRequest: contactState })
      ),
  };
}
