import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  JUMPSELLER_GET_LOADING,
  JUMPSELLER_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { jumpsellerApiService } from "./services/api";
import {
  getJumpsellerFailureAction,
  getJumpsellerInitAction,
  getJumpsellerSuccessAction,
  postBindJumpsellerFailureAction,
  postBindJumpsellerInitAction,
  postBindJumpsellerSuccessAction,
  postUnbindJumpsellerFailureAction,
  postUnbindJumpsellerInitAction,
  postUnbindJumpsellerSuccessAction
} from "./store/api-actions";
import { settingSelector } from "./store/selector";
import { useIntl } from "react-intl";

export function useJumpsellerCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindJumpsellerInitAction());
        await jumpsellerApiService.unbind(companyId);
        dispatch(postUnbindJumpsellerSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindJumpsellerFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      code: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindJumpsellerInitAction());
        turnOnLoading(JUMPSELLER_SYNC_LOADING);
        const response = await jumpsellerApiService.bind(companyId, code);
        dispatch(postBindJumpsellerSuccessAction({ settings: response }));
        jumpsellerApiService.generateContacts(companyId);
        turnOffLoading(JUMPSELLER_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess15" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(JUMPSELLER_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error32" }));
        console.error(error);
        dispatch(postBindJumpsellerFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getJumpsellerInitAction());
        turnOnLoading(JUMPSELLER_GET_LOADING);
        const settings = await jumpsellerApiService.get(companyId);
        dispatch(getJumpsellerSuccessAction({ settings }));
        turnOffLoading(JUMPSELLER_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(JUMPSELLER_GET_LOADING);
        console.error(error);
        dispatch(getJumpsellerFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const login = useCallback(
    async (companyId: string) => {
      try {
        turnOnLoading(JUMPSELLER_SYNC_LOADING);
        const response = await jumpsellerApiService.login(companyId);
        return response.url;
      } catch (error: any) {
        turnOffLoading(JUMPSELLER_SYNC_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error33" }));
      }
    },
    [turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    unbind,
    login,
    bind,
    get,
  };
}
