import paymentsApiClient from "../../helpers/services/payments-api-client";
import { GetCardVerificationResponse } from "../interfaces/api/get-card-verification";
import { GetCouponResponse } from "../interfaces/api/get-coupon";
import { PostStripeCheckPaymentRequest, PostStripeCheckPaymentResponse } from "../interfaces/api/post-stripe-check-payment";
import { PostSubscriptionRequest, PostSubscriptionResponse } from "../interfaces/api/post-subscription";
import { flowMapper } from "./mapper";

export const flowApiService = {
  postFlowSubscription(planId: string, couponId: string | null) {
    const body: PostSubscriptionRequest = {
      planId,
      couponId,
    }
    return paymentsApiClient()
      .post<PostSubscriptionResponse>(`flow/subscribe`, body)
      .then(({ data }) => data.payload);
  },
  deleteSubscription() {
    return paymentsApiClient()
      .delete(`subscription/cancel`)
      .then(({ data }) => data.payload);
  },
  getFlowCoupon(couponId: string) {
    return paymentsApiClient()
      .get<GetCouponResponse>(`flow/coupon/${couponId}`)
      .then(({ data }) => flowMapper.mapResponseToCoupon(data));
  },
  getCardVerification(card: string) {
    return paymentsApiClient()
      .get<GetCardVerificationResponse>(`flow/card/${card}`)
      .then(({ data }) => flowMapper.mapResponseToBoolean(data));
  },
  postStripeSubscription(planId: string, couponId: string | null) {
    const body: PostSubscriptionRequest = {
      planId,
      couponId,
    }
    return paymentsApiClient()
      .post<PostSubscriptionResponse>(`stripe/subscribe`, body)
      .then(({ data }) => data.payload);
  },
  postStripeCheckSubscription(sessionId: string, subscriptionId: string) {
    const body: PostStripeCheckPaymentRequest = {
      sessionId,
      subscriptionId,
    }
    return paymentsApiClient()
      .post<PostStripeCheckPaymentResponse>(`stripe/check/payment`, body)
      .then(({ data }) => data.payload);
  },
  getStripeCoupon(couponId: string) {
    return paymentsApiClient()
      .get<GetCouponResponse>(`stripe/coupon/${couponId}`)
      .then(({ data }) => flowMapper.mapResponseToCoupon(data));
  },
};
