import React from "react";
import { Badge } from "reactstrap";

interface Props {
  pageNumber: number;
  onClick: () => void;
  active?: boolean;
  disabled?: boolean;
}

export const Page = (props: Props) => {
  const { pageNumber, onClick, active, disabled } = props;
  return (
    <Badge
      style={{ opacity: disabled ? 0.5 : 1 }}
      onClick={() => !disabled && onClick()}
      color={active ? "primary" : "secondary"}
      className="p-2 mx-2 text-white"
    >
      {pageNumber}
    </Badge>
  );
};
