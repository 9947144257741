import {
  SmtpSetting,
  PostSmtpBindResponse,
} from "../interface/api/post-smtp-bind";
import { PostSmtpEmailResponse } from "../interface/api/post-smtp-email";

export const smtpSettingMapper = {
  mapResponseToId(response: PostSmtpEmailResponse) {
    const { id } = response?.payload;
    return id;
  },

  mapResponseToSmtpSetting(response: PostSmtpBindResponse): SmtpSetting {
    const { smtpSetting } = response?.payload;
    return smtpSetting;
  },
};
