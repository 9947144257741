import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { useAdvertisementCore } from "../../../cores/advertisements";
import { InputWembii } from "../InputWembii";
import { PanelWembii } from "../PanelWembii";
import { useIntl } from "react-intl";
import { TitleWembii } from "../TitleWembii";
import AutoQuestions from "./AutoQuestions";
import { closingButtonTypes, formTypes } from "../../../cores/advertisements/interfaces/AdsForm";
import CustomQuestions from "./CustomQuestions";
import { isUrl } from "../../utils/stringUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipWembii } from "../TooltipWembii";

export default function FormsForm() {
  const intl = useIntl();
  const { campaign, setCampaign } = useAdvertisementCore();
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleFormConfigChange = useCallback(
    (field) => {
      setCampaign({ ...campaign, form: { ...campaign.form, ...field } });
    },
    [campaign, setCampaign]
  );

  const handlePolicyConfigChange = useCallback(
    (field) => {
      setCampaign({
        ...campaign,
        form: {
          ...campaign.form,
          privacyPolicy: {
            ...campaign.form.privacyPolicy,
            ...field
          }
        }
      });
    },
    [campaign, setCampaign]
  );

  const handleThankYouPageConfigChange = useCallback(
    (field) => {
      setCampaign({
        ...campaign,
        form: {
          ...campaign.form,
          thankYouPage: {
            ...campaign.form.thankYouPage,
            ...field
          }
        }
      });
    },
    [campaign, setCampaign]
  );

  const handleTContextCardConfigChange = useCallback(
    (field) => {
      setCampaign({
        ...campaign,
        form: {
          ...campaign.form,
          contextCard: {
            ...campaign.form.contextCard,
            ...field
          }
        }
      });
    },
    [campaign, setCampaign]
  );

  const isFieldToLong = (field: string, length: number) =>
    field.length > length;

  const isGreetingTitleInvalid = useMemo(() =>
    isFieldToLong(campaign.form.contextCard.title, 60)
    , [campaign]
  );

  const isClosingButtonTextInvalid = useMemo(() =>
    isFieldToLong(campaign.form.thankYouPage.buttonText, 60)
    , [campaign]
  );

  const isClosingTitleInvalid = useMemo(() =>
    isFieldToLong(campaign.form.thankYouPage.title, 60)
    , [campaign]
  );

  const isPrivacyTitleInvalid = useMemo(() =>
    isFieldToLong(campaign.form.privacyPolicy.linkText, 70)
    , [campaign]
  );

  const isPrivacyButtonUrlInvalid = useMemo(() =>
    campaign.form.privacyPolicy.url !== '' && !isUrl(campaign.form.privacyPolicy.url)
    , [campaign]
  );

  const isClosingButtonUrlInvalid = useMemo(() =>
    campaign.form.thankYouPage.websiteUrl !== '' && !isUrl(campaign.form.thankYouPage.websiteUrl)
    , [campaign]
  );

  return (
    <PanelWembii shadow className="mb-4 p-4">
      <TitleWembii Tag="h5" color="primary">
        {intl.formatMessage({ id: "app.advertisements.forms" })}
      </TitleWembii>
      <Row className="mb-4">
        <Col md="8">
          <InputWembii
            type="text"
            name="formName"
            id="formName"
            label={intl.formatMessage({ id: "app.advertisements.formName" })}
            flat
            required
            value={campaign.form.name}
            onChange={(v) => handleFormConfigChange({ name: v })}
            icon={     
              <div  className="mb-4 mr-3">         
                <FontAwesomeIcon 
                  icon={["fas", "info-circle"]}
                  size="1x"
                  className="position-absolute border-none"
                  id="campaign-formName"
                  color="grey"
                />
                <TooltipWembii 
                  id="campaign-formName"
                  text={intl.formatMessage({ id: "app.tooltopAds18" })} 
                />
              </div>  
            }
          />
        </Col>
        <Col md="4">
          <InputWembii
            type="select"
            name="formType"
            id="formType"
            label={intl.formatMessage({ id: "app.advertisements.formType" })}
            flat
            required
            value={campaign.form.isOptimizedForQuality}
            onChange={(v) => handleFormConfigChange({ isOptimizedForQuality: v })}
            icon={     
              <div  className="mb-4 mr-4">         
                <FontAwesomeIcon 
                  icon={["fas", "info-circle"]}
                  size="1x"
                  className="position-absolute border-none"
                  id="campaign-formType"
                  color="grey"
                />
                <TooltipWembii 
                  id="campaign-formType"
                  text={intl.formatMessage({ id: "app.tooltopAds19" })} 
                />
              </div>  
            }
          >
            {formTypes.map((type) => (
              <option key={type.key} value={type.key}>
                {type.label}
              </option>
            ))}
          </InputWembii>
        </Col>
      </Row>

      <Row>
        <Col md="7">
          <TitleWembii Tag="h6" color="primary">
            {intl.formatMessage({ id: "app.advertisements.greeting" })}
          </TitleWembii>
          <div className="mb-4">
            <InputWembii
              type="text"
              name="formTitle"
              id="formTitle"
              label={intl.formatMessage({ id: "app.advertisements.formTitle" })}
              flat
              required
              invalid={isGreetingTitleInvalid}
              value={campaign.form.contextCard.title}
              onChange={(v) => handleTContextCardConfigChange({ title: v })}
              icon={     
                <div  className="mb-4 mr-3">         
                  <FontAwesomeIcon 
                    icon={["fas", "info-circle"]}
                    size="1x"
                    className="position-absolute border-none"
                    id="campaign-formTitle"
                    color="grey"
                  />
                  <TooltipWembii 
                    id="campaign-formTitle"
                    text={intl.formatMessage({ id: "app.tooltopAds20" })} 
                  />
                </div>  
              }
            />
            <InputWembii
              type="textarea"
              name="formDescription"
              id="formDescription"
              label={intl.formatMessage({ id: "app.advertisements.formDescription" })}
              flat
              required
              value={campaign.form.contextCard.content}
              onChange={(v) => handleTContextCardConfigChange({ content: v })}
              icon={     
                <div  className="mb-4 mr-3">         
                  <FontAwesomeIcon 
                    icon={["fas", "info-circle"]}
                    size="1x"
                    className="position-absolute border-none"
                    id="campaign-formDescription"
                    color="grey"
                  />
                  <TooltipWembii 
                    id="campaign-formDescription" 
                    text={intl.formatMessage({ id: "app.tooltopAds21" })} 
                  />
                </div>  
              }
            />
          </div>

          <TitleWembii Tag="h6" color="primary">
            {intl.formatMessage({ id: "app.advertisements.privacy" })}
          </TitleWembii>
          <Row className="mb-4">
            <Col>
              <InputWembii
                type="text"
                name="formPrivacyTitle"
                id="formPrivacyTitle"
                label={intl.formatMessage({ id: "app.advertisements.formPrivacyTitle" })}
                flat
                required
                invalid={isPrivacyTitleInvalid}
                value={campaign.form.privacyPolicy.linkText}
                onChange={(v) => handlePolicyConfigChange({ linkText: v })}
                icon={     
                  <div  className="mb-4 mr-3">         
                    <FontAwesomeIcon 
                      icon={["fas", "info-circle"]}
                      size="1x"
                      className="position-absolute border-none"
                      id="campaign-formPrivacyTitle"
                      color="grey"
                    />
                    <TooltipWembii 
                      id="campaign-formPrivacyTitle"
                      text={intl.formatMessage({ id: "app.tooltopAds22" })}  
                    />
                  </div>  
                }
              />
            </Col>
            <Col>
              <InputWembii
                type="text"
                name="formPrivacyUrl"
                id="formPrivacyUrl"
                label={intl.formatMessage({ id: "app.advertisements.formPrivacyUrl" })}
                flat
                required
                invalid={isPrivacyButtonUrlInvalid}
                value={campaign.form.privacyPolicy.url}
                onChange={(v) => handlePolicyConfigChange({ url: v })}
                icon={     
                  <div  className="mb-4 mr-3">         
                    <FontAwesomeIcon 
                      icon={["fas", "info-circle"]}
                      size="1x"
                      className="position-absolute border-none"
                      id="campaign-formPrivacyUrl"
                      color="grey"
                    />
                    <TooltipWembii 
                      id="campaign-formPrivacyUrl"
                      text={intl.formatMessage({ id: "app.tooltopAds23" })} 
                    />
                  </div>  
                }
              />
            </Col>
          </Row>

          <TitleWembii Tag="h6" color="primary">
            {intl.formatMessage({ id: "app.advertisements.closing" })}
          </TitleWembii>
          <div className="mb-4">
            <InputWembii
              type="text"
              name="closingTitle"
              id="closingTitle"
              label={intl.formatMessage({ id: "app.advertisements.closingTitle" })}
              flat
              required
              invalid={isClosingTitleInvalid}
              value={campaign.form.thankYouPage.title}
              onChange={(v) => handleThankYouPageConfigChange({ title: v })}
              icon={     
                <div  className="mb-4 mr-3">         
                  <FontAwesomeIcon 
                    icon={["fas", "info-circle"]}
                    size="1x"
                    className="position-absolute border-none"
                    id="campaign-closingTitle"
                    color="grey"
                  />
                  <TooltipWembii 
                    id="campaign-closingTitle"
                    text={intl.formatMessage({ id: "app.tooltopAds24" })} 
                  />
                </div>  
              }
            />
            <InputWembii
              type="textarea"
              name="closingDescription"
              id="closingDescription"
              label={intl.formatMessage({ id: "app.advertisements.closingDescription" })}
              flat
              required
              value={campaign.form.thankYouPage.body}
              onChange={(v) => handleThankYouPageConfigChange({ body: v })}
              icon={     
                <div  className="mb-4 mr-3">         
                  <FontAwesomeIcon 
                    icon={["fas", "info-circle"]}
                    size="1x"
                    className="position-absolute border-none"
                    id="campaign-closingDescription"
                    color="grey"
                  />
                  <TooltipWembii 
                    id="campaign-closingDescription"
                    text={intl.formatMessage({ id: "app.tooltopAds25" })} 
                  />
                </div>  
              }
            />
          </div>
          <Row>
            <Col md="4">
              <InputWembii
                type="select"
                name="closingButtonType"
                id="closingButtonType"
                label={intl.formatMessage({ id: "app.advertisements.closingButtonType" })}
                flat
                required
                value={campaign.form.thankYouPage.buttonType}
                onChange={(v) => handleThankYouPageConfigChange({ buttonType: v })}
                icon={     
                  <div  className="mb-4 mr-4">         
                    <FontAwesomeIcon 
                      icon={["fas", "info-circle"]}
                      size="1x"
                      className="position-absolute border-none"
                      id="campaign-closingButtonType"
                      color="grey"
                    />
                    <TooltipWembii 
                      id="campaign-closingButtonType"
                      text={intl.formatMessage({ id: "app.tooltopAds26" })}  
                    />
                  </div>  
                }
              >
                {closingButtonTypes.map((type) => (
                  <option key={type.key} value={type.key}>
                    {type.label}
                  </option>
                ))}
              </InputWembii>
            </Col>
            <Col md="4">
              <InputWembii
                type="text"
                name="closingButtonText"
                id="closingButtonText"
                label={intl.formatMessage({ id: "app.advertisements.closingButtonText" })}
                flat
                required
                invalid={isClosingButtonTextInvalid}
                value={campaign.form.thankYouPage.buttonText}
                onChange={(v) => handleThankYouPageConfigChange({ buttonText: v })}
                icon={     
                  <div  className="mb-4 mr-3">         
                    <FontAwesomeIcon 
                      icon={["fas", "info-circle"]}
                      size="1x"
                      className="position-absolute border-none"
                      id="campaign-closingButtonText"
                      color="grey"
                    />
                    <TooltipWembii 
                      id="campaign-closingButtonText"
                      text={intl.formatMessage({ id: "app.tooltopAds27" })}  
                    />
                  </div>  
                }
              />
            </Col>
            <Col md="4">
              <InputWembii
                type="text"
                name="closingUrl"
                id="closingUrl"
                label={intl.formatMessage({ id: "app.advertisements.closingUrl" })}
                flat
                required
                invalid={isClosingButtonUrlInvalid}
                value={campaign.form.thankYouPage.websiteUrl}
                onChange={(v) => handleThankYouPageConfigChange({ websiteUrl: v })}
                icon={     
                  <div  className="mb-4 mr-3">         
                    <FontAwesomeIcon 
                      icon={["fas", "info-circle"]}
                      size="1x"
                      className="position-absolute border-none"
                      id="campaign-closingUrl"
                      color="grey"
                    />
                    <TooltipWembii 
                      id="campaign-closingUrl"
                      text={intl.formatMessage({ id: "app.tooltopAds28" })}  
                    />
                  </div>  
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md="5">
          <TitleWembii Tag="h6" color="primary">
            {intl.formatMessage({ id: "app.advertisements.questions" })}
          </TitleWembii>

          <Nav tabs className="justify-content-center flex-nowrap">
            <NavItem>
              <NavLink
                className={cn("pointer text-black-50", { active: activeTab === 1 })}
                onClick={() => {
                  toggle(1);
                }}
              >
                Personalizadas
                {' '}({campaign.form.customQuestions.length})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={cn("pointer text-black-50", { active: activeTab === 2 })}
                onClick={() => {
                  toggle(2);
                }}
              >
                Completadas automáticamente
                {' '}({campaign.form.autoQuestions.length})
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <CustomQuestions />
            </TabPane>
            <TabPane tabId={2}>
              <AutoQuestions />
            </TabPane>
          </TabContent>
        </Col>
      </Row>

    </PanelWembii>
  );
}
