import React from "react";
import SideModal from "../../layout/SideModal";
import {
  CONTACT_COMPANY_EXPAND_CREATION_FORM,
  CONTACT_COMPANY_EXPAND_EDIT_FORM,
} from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { ENTERPRISE_CONTACT_ASSIGNMENT } from "../../../constants/internalModal.constant";
import CreateCompanyForm from "./modal/CreateCompanyForm";

export default function CompanySideModals() {
  const {
    isModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
    turnOffInternalModal,
  } = useGlobalCore();

  return (
    <>
      <SideModal
        active={isModal(CONTACT_COMPANY_EXPAND_CREATION_FORM)}
        visible={getModalVisibility(CONTACT_COMPANY_EXPAND_CREATION_FORM)}
        onClose={() => turnOffModal(CONTACT_COMPANY_EXPAND_CREATION_FORM)}
        left
      >
        <CreateCompanyForm />
      </SideModal>
      <SideModal
        active={isModal(CONTACT_COMPANY_EXPAND_EDIT_FORM)}
        visible={getModalVisibility(CONTACT_COMPANY_EXPAND_EDIT_FORM)}
        onClose={() => {
          turnOffModal(CONTACT_COMPANY_EXPAND_EDIT_FORM);
          turnOffInternalModal(ENTERPRISE_CONTACT_ASSIGNMENT);
        }}
        left
      >
        {getModalContent(CONTACT_COMPANY_EXPAND_EDIT_FORM)}
      </SideModal>
    </>
  );
}
