import _ from "lodash";
import React from "react";
import { Order } from "../../../../cores/market/interface/order";
import { useIntl } from "react-intl";

interface Props {
  order: Order;
}

export const BillingSection = (props: Props) => {
  const intl = useIntl();
  const {
    order: {
      billing: {
        firstName,
        lastName,
        company,
        address1,
        address2,
        city = "",
        state = "",
        postcode,
        country = "",
        email,
        phone,
      },
    },
  } = props;
  return (
    <div className="my-2 text-center">
      <h5 className="h5 text-black-50"> 
        {intl.formatMessage({ id: "app.onlinesales.order6" })}
      </h5>
      <h5 className="h5"> 
        <small className="text-black-50">
          {intl.formatMessage({ id: "app.onlinesales.order6" })}
        </small>
        {`${_.capitalize(lastName)} ${_.capitalize(firstName)}`}
      </h5>
      {company && (
        <h5 className="h5">
          <small className="text-black-50">
          {intl.formatMessage({ id: "app.onlinesales.order6" })}
          </small>
          {_.capitalize(company)}
        </h5>
      )}
      {(country || city) && (
        <h5 className="h5"> 
          <small className="text-black-50"> 
           {intl.formatMessage({ id: "app.onlinesales.order7" })}
          </small>
          {`${_.capitalize(country)} - ${_.capitalize(city)} ${_.capitalize(
            state
          )} - (${postcode})`}
        </h5>
      )}
      {(address1 || address2) && (
        <h5 className="h5"> 
          <small className="text-black-50">
            {intl.formatMessage({ id: "app.onlinesales.order8" })}
          </small>
          {_.capitalize(address1 || address2)}
        </h5>
      )}
      {email && (
        <h5 className="h5"> 
          <small className="text-black-50">
            {intl.formatMessage({ id: "app.onlinesales.order9" })} 
          </small>
          {email}
        </h5>
      )}
      {phone && (
        <h5 className="h5">
          <small className="text-black-50"> 
            {intl.formatMessage({ id: "app.onlinesales.order5" })} 
          </small>
          {phone}
        </h5>
      )}
    </div>
  );
};
