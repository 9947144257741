import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { useWebFormCore } from "../../cores/webForm";
import { useIntl } from "react-intl";
import InputsForm from "../component/forms/web/creator/InputsForm";
import FormNameModal from "../component/forms/web/modal/FormNameModal";
import FormPreview from "../component/forms/web/FormPreview";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { Header2 } from "../layout/Header2";
import GeneralForm from "../component/forms/web/creator/GeneralForm";
import InstalationForm from "../component/forms/web/creator/InstalationForm";
import NotificationForm from "../component/forms/web/creator/NotificationForm";
import ContactForm from "../component/forms/web/creator/ContactForm";
import { CONTACT_TAGS_FORM, WEBFORM_CONFIG } from "../../constants/modal.constant";
import ContactTags from "../component/contacts/modal/ContactTags";
import { useGlobalCore } from "../../cores/globals";
import { useContactCore } from "../../cores/contacts";
import { useCompanyCore } from "../../cores/company";
import { CONTACT_TAGS } from "../../constants/internalModal.constant";
import Icofont from "react-icofont";
import UrlForm from "../component/forms/web/creator/UrlForm";

export default function EditWebForm() {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const { company, companyPlan, companyRole, isAdmin, isReseller } = useMeCore();
  const { webForm, getWebForm, putWebForm } = useWebFormCore();
  const { goToWebForms, goToSocial } = useRouterCore();
  const { getContactTags, getCustomFields } = useContactCore();
  const { turnOffModal, turnOnModal, isModal, turnOffInternalModal, getModalVisibility } = useGlobalCore();
  const { getCompany } = useCompanyCore();

  useEffect(() => {
    if (
      companyPlan?.rules.webFormsAmount === 0 || (
        !companyRole?.forms &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [companyPlan, goToSocial, companyRole, isAdmin, isReseller]);

  useEffect(() => {
    getWebForm(company?.id!, id);
    getContactTags(company?.id!);
    getCustomFields(company?.id!);
    getCompany(company?.id!);
  }, [company, getWebForm, getContactTags, getCustomFields, getCompany, id]);

  const toggleSideModal = useCallback(() => {
    turnOnModal(WEBFORM_CONFIG, true);
  }, [turnOnModal]);

  const handleModifyWebForm = useCallback((fields) => {
    putWebForm(
      company?.id!,
      webForm.form?.id!, {
        name: webForm.form?.name!,
        title: webForm.form?.title!,
        titleSize: webForm.form?.titleSize!,
        titleColor: webForm.form?.titleColor!,
        buttonSize: webForm.form?.buttonSize!,
        buttonColor: webForm.form?.buttonColor!,
        buttonPosition: webForm.form?.buttonPosition!,
        buttonText: webForm.form?.buttonText!,
        buttonTextColor: webForm.form?.buttonTextColor!,
        buttonTextSize: webForm.form?.buttonTextSize!,
        redirectUrl: webForm.form?.redirectUrl!,
        successfulMessage: webForm.form?.successfulMessage!,
        action: webForm.form?.action!,
        tag: webForm.form?.tag!,
        isActive: webForm.form?.isActive!,
        ...fields
      }
    );
  }, [putWebForm, company, webForm]);

  return (
    <Layout>
       <Header2
        title={`${intl.formatMessage({ id: "app.editWebTitle" })} ${webForm.form?.name}`}
        rightChildren={
          <>
            <Button className="whiteButton" onClick={toggleSideModal}>
              <Icofont className="mr-2 pointer" icon="edit" />
              {intl.formatMessage({ id: "app.editForm.changeName" })}
            </Button>
            <Button className="whiteButton ml-2" onClick={goToWebForms}>
              {intl.formatMessage({ id: "app.editForm.goBack" })}
            </Button>
          </>
        }
      />
      <Content>
        <Container fluid>
          <Row>
            <Col md="8" sm="12">
              <GeneralForm onSubmit={() => {
                handleModifyWebForm({});
              }} />
              <InputsForm />
              <NotificationForm />
              <ContactForm onSubmit={() => {
                handleModifyWebForm({});
              }} />
              <UrlForm />
              <InstalationForm />
            </Col>
            <Col md="4" sm="12">
              <FormPreview />
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(WEBFORM_CONFIG)}
        visible={getModalVisibility(WEBFORM_CONFIG)}
        onClose={() => {
          turnOffModal(WEBFORM_CONFIG);
        }}
      >
        <FormNameModal
          onSubmit={(name) => {
            handleModifyWebForm({ name });
            turnOffModal(WEBFORM_CONFIG);
          }}
          previousName={webForm.form?.name}
        />
      </SideModal>
      <SideModal
        active={isModal(CONTACT_TAGS_FORM)}
        visible={getModalVisibility(CONTACT_TAGS_FORM)}
        onClose={() => {
          turnOffModal(CONTACT_TAGS_FORM);
          turnOffInternalModal(CONTACT_TAGS);
        }}
      >
        <ContactTags onUpdate={() => {
          getWebForm(company?.id!, id);
        }} />
      </SideModal>
    </Layout>
  );
}
