import React, { useEffect, useMemo } from "react";
import { WOO_COMMERCE_INSIGHT_LOADING } from "../../constants/loading.constant";
import { ANALYTICS_EXPAND } from "../../constants/modal.constant";
import { useAnalyticsCore } from "../../cores/analytics";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { useWooCommerceCore } from "../../cores/wooCommerce";
import { AnalyticsDatePicker } from "../component/analytics/AnalyticsDatePicker";
import { Currency } from "../component/analytics/Currency";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import { WooCommerceIcon } from "../component/social/WooCommerce/WooCommerceIcon";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { WooCommerceCharts } from "../component/analytics/WooCommerceCharts";
import moment from "moment";
import { useIntl } from "react-intl";

export const WooCommerceAnalytics = () => {
  const intl = useIntl();
  const { getWooCommerceInsights, date } = useAnalyticsCore();
  const { isModal, turnOffModal, getModalContent, getModalVisibility } = useGlobalCore();
  const {
    company,
    socialSettings,
    companyPlan,
    companyRole,
    isAdmin,
    isReseller
  } = useMeCore();
  const { currency, getCurrency } = useWooCommerceCore();
  const { goToSocial } = useRouterCore();

  const isSync = useMemo(() => socialSettings && socialSettings.wooCommerce, [
    socialSettings,
  ]);

  const isFutureDate = (date: string) => moment(date).isAfter(moment());
  
  const areDatesInverted = (since: string, until: string) => 
    moment(since).isAfter(moment(until));

  useEffect(() => {
    if (
      !isSync || !companyPlan?.rules.allowAnalytics || (
        !companyRole?.analitycs &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [goToSocial, isSync, companyPlan, companyRole, isAdmin, isReseller]);

  useEffect(() => {
    if (
      isSync &&
      !isFutureDate(date.since) &&
      !isFutureDate(date.until) &&
      !areDatesInverted(date.since, date.until)
    ) {
      getWooCommerceInsights(company!.id, date.since, date.until);
    }
  }, [company, date, getWooCommerceInsights, isSync]);

  useEffect(() => {
    isSync && getCurrency(company!.id);
  }, [company, getCurrency, isSync]);
  
  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.metricsWocommerce.title" })}
        icon={<WooCommerceIcon active size="4x" className="ml-3" />}
        subtitle={currency && <Currency currency={currency.name} />}
        rightChildren={<AnalyticsDatePicker />}
      />
      <Content>
        <UpdateLoadingCard
          loadingId={WOO_COMMERCE_INSIGHT_LOADING}
          text={intl.formatMessage({ id: "app.metricsWocommerce.updating" })}
        />
        <WooCommerceCharts />
      </Content>
      <SideModal
        active={isModal(ANALYTICS_EXPAND)}
        visible={getModalVisibility(ANALYTICS_EXPAND)}
        onClose={() => turnOffModal(ANALYTICS_EXPAND)}
        left
      >
        {getModalContent(ANALYTICS_EXPAND)}
      </SideModal>
    </Layout>
  );
};
