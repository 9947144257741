import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useIntl } from "react-intl";
import { useSocialPostCore } from "../../../../cores/socialPost";
import {
  PickerMode,
  SocialProviderId,
} from "../../../../cores/socialPost/config";
import { ableToMention } from "./MentionPicker";
import { useGlobalCore } from "../../../../cores/globals";
import {
  ADD_SOCIAL_IMAGE_MODAL,
  ADD_SOCIAL_VIDEO_MODAL,
  EMOJI_FORM,
  OPENAI_SOCIAL_FORM,
  TEMPLATES_SOCIAL_FORM,
} from "../../../../constants/modal.constant";
import { SocialType } from "../../../../cores/socialPost/interfaces/postError";
import { TextMeter } from "./TextMeter";
import { OpenAiIcon } from "../../social/OpenAi/OpenAiIcon";

interface Props {
  onClickEmoji?: () => void;
  onClickHashtag?: () => void;
  onClickMention?: () => void;
  onClickImages?: () => void;
  onClickVideo?: () => void;
  onClickTemplate?: () => void;
  onClickOpenAi?: () => void;
  activePicker: PickerMode;
  socialType: SocialType;
}

export const TextEditor = (props: Props) => {
  const intl = useIntl();
  const {
    onClickEmoji,
    onClickHashtag,
    onClickMention,
    onClickImages,
    onClickVideo,
    onClickTemplate,
    onClickOpenAi,
    activePicker,
    socialType,
  } = props;
  const {
    text,
    addText,
    images,
    video,
    socialProviders,
    selectedPost,
    inputCursorIndex,
    addInputCursorIndex,
    updateMentions,
  } = useSocialPostCore();
  const { isModal } = useGlobalCore();

  const isProviderSelected = useMemo(
    () => socialProviders.length > 0,
    [socialProviders.length]
  );

  const isProvider2Selected = useMemo(
    () =>
      socialProviders.filter((provider) =>
        ableToMention.includes(provider.id as SocialProviderId)
      ).length > 0,
    [socialProviders]
  );

  const hangleAddText = useCallback(
    (txt) => {
      const offset = txt.length - text.length;
      addText(txt);
      updateMentions(offset, inputCursorIndex);
    },
    [inputCursorIndex, text, addText, updateMentions]
  );

  return (
    <>
      <TextMeter socialType={socialType} />
      <hr></hr>
      <textarea
        className="border-0 w-100 bg-white"
        value={text}
        rows={4}
        placeholder={intl.formatMessage({ id: "app.socialMediaPost.addText" })}
        disabled={!isProviderSelected}
        style={{ resize: isProviderSelected ? "vertical" : "none" }}
        onChange={(e) => hangleAddText(e.target.value)}
        onKeyUp={(ev: any) => addInputCursorIndex(ev.target.selectionStart)}
        onClick={(ev: any) => addInputCursorIndex(ev.target.selectionStart)}
      />
      <hr></hr>
      <div className="d-flex justify-content-between py1 px-3">
        <div className="d-flex">
          {onClickEmoji && isProviderSelected && (
            <FontAwesomeIcon
              icon={["far", "smile-beam"]}
              size="2x"
              color={isModal(EMOJI_FORM) ? COLOR_PRIMARY : "grey"}
              className="mx-1 pointer textEditor-icon"
              onClick={onClickEmoji}
            />
          )}
          {onClickHashtag && isProviderSelected && (
            <FontAwesomeIcon
              icon={["fas", "hashtag"]}
              size="2x"
              color={
                activePicker === PickerMode.HashTag ? COLOR_PRIMARY : "grey"
              }
              className="mx-1 pointer textEditor-icon"
              onClick={onClickHashtag}
            />
          )}
          {onClickMention && isProvider2Selected && (
            <FontAwesomeIcon
              icon={["fas", "at"]}
              size="2x"
              color={
                activePicker === PickerMode.Mention ? COLOR_PRIMARY : "grey"
              }
              className="mx-1 pointer textEditor-icon"
              onClick={onClickMention}
            />
          )}
          {onClickTemplate && isProviderSelected && (
            <FontAwesomeIcon
              icon={["fas", "clipboard-list"]}
              size="2x"
              color={isModal(TEMPLATES_SOCIAL_FORM) ? COLOR_PRIMARY : "grey"}
              className="mx-1 pointer textEditor-icon"
              onClick={onClickTemplate}
            />
          )}
          {onClickOpenAi && isProviderSelected && (
            <OpenAiIcon
              size="1x"
              active={isModal(OPENAI_SOCIAL_FORM)}
              className="mx-1 pointer textEditor-icon"
              onClick={onClickOpenAi}
            />
          )}
        </div>
        <div>
          {onClickImages && isProviderSelected && (
            <FontAwesomeIcon
              icon={["fas", "images"]}
              size="2x"
              color={
                isModal(ADD_SOCIAL_IMAGE_MODAL) ||
                images.length ||
                (selectedPost?.post.type === "image" &&
                  selectedPost?.media.length)
                  ? COLOR_PRIMARY
                  : "grey"
              }
              className="mx-1 pointer textEditor-icon"
              onClick={onClickImages}
            />
          )}
          {onClickVideo && isProviderSelected && (
            <FontAwesomeIcon
              icon={["fas", "film"]}
              size="2x"
              color={
                isModal(ADD_SOCIAL_VIDEO_MODAL) ||
                video ||
                (selectedPost?.post.type === "video" &&
                  selectedPost?.media.length)
                  ? COLOR_PRIMARY
                  : "grey"
              }
              className="mx-1 pointer textEditor-icon"
              onClick={onClickVideo}
            />
          )}
        </div>
      </div>
    </>
  );
};
