import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Notification } from "../interfaces/api/get-notifications";
import {
  clearNotifications,
  getNotificationsFailureAction,
  getNotificationsInitAction,
  getNotificationsSuccessAction,
  getSystemNotificationsFailureAction,
  getSystemNotificationsInitAction,
  getSystemNotificationsSuccessAction,
  getUnreadNotificationsSuccessAction,
  NotificationApiAction,
  putReadNotificationFailureAction,
  putReadNotificationInitAction,
  putReadNotificationsFailureAction,
  putReadNotificationsInitAction,
  putReadNotificationsSuccessAction,
  putReadNotificationSuccessAction,
} from "./api-actions";

export interface NotificationState {
  count: number;
  unreadCount: number;
  infoMessage: string | null;
  infoMessageEs: string | null;
  infoMessageEn: string | null;
  infoMessagePt: string | null;
  notifications: {
    ids: string[];
    entities: Record<string, Notification>;
  };
  unreadNotifications: {
    ids: string[];
    entities: Record<string, Notification>;
  };
}

const initialState: NotificationState = {
  count: 0,
  unreadCount: 0,
  infoMessage: null,
  infoMessageEs: null,
  infoMessageEn: null,
  infoMessagePt: null,
  notifications: {
    ids: [],
    entities: {},
  },
  unreadNotifications: {
    ids: [],
    entities: {},
  },
};

const notificationsAdapter = createEntityAdapter<Notification>(
  (payload) => payload.id
);
const unreadNotificationsAdapter = createEntityAdapter<Notification>(
  (payload) => payload.id
);

export default produce(
  (
    draft: NotificationState = initialState,
    action: NotificationApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case clearNotifications.type:
        notificationsAdapter.removeAll(draft.notifications);

        return draft;
      case getNotificationsSuccessAction.type:
        notificationsAdapter.updateMany(
          action.notifications,
          draft.notifications
        );
        draft.count = action.count;
        return draft;
      case getUnreadNotificationsSuccessAction.type:
        unreadNotificationsAdapter.addMany(
          action.notifications,
          draft.unreadNotifications
        );
        draft.unreadCount = action.count;
        return draft;
      case putReadNotificationsSuccessAction.type:
        unreadNotificationsAdapter.removeAll(draft.unreadNotifications);
        draft.unreadCount = 0;
        return draft;
      case putReadNotificationSuccessAction.type:
        unreadNotificationsAdapter.removeOne(
          action.id,
          draft.unreadNotifications
        );
        draft.unreadCount -= 1;
        return draft;
      case getSystemNotificationsSuccessAction.type:
        draft.infoMessage = action.infoMessage;
        draft.infoMessageEs = action.infoMessageEs;
        draft.infoMessageEn = action.infoMessageEn;
        draft.infoMessagePt = action.infoMessagePt;
        return draft;
      case resetAction.type:
        return initialState;
      case getNotificationsInitAction.type:
      case getNotificationsFailureAction.type:
      case putReadNotificationsInitAction.type:
      case putReadNotificationsFailureAction.type:
      case putReadNotificationInitAction.type:
      case putReadNotificationFailureAction.type:
      case getSystemNotificationsInitAction.type:
      case getSystemNotificationsFailureAction.type:
      default:
        return draft;
    }
  }
);
