import React from "react";
import SideModal from "../../layout/SideModal";
import {
  DELETE_TASK_MODAL,
  TASK_CARD_MODAL,
  TASK_CONTACTS_MODAL
} from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";

export default function TaskSideModals() {
  const {
    isModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
  } = useGlobalCore();

  return (
    <>
      <SideModal
        active={isModal(TASK_CARD_MODAL)}
        visible={getModalVisibility(TASK_CARD_MODAL)}
        onClose={() => turnOffModal(TASK_CARD_MODAL)}
      >
        {getModalContent(TASK_CARD_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(DELETE_TASK_MODAL)}
        visible={getModalVisibility(DELETE_TASK_MODAL)}
        onClose={() => turnOffModal(DELETE_TASK_MODAL)}
        left
      >
        {getModalContent(DELETE_TASK_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(TASK_CONTACTS_MODAL)}
        visible={getModalVisibility(TASK_CONTACTS_MODAL)}
        onClose={() => turnOffModal(TASK_CONTACTS_MODAL)}
        left
      >
        {getModalContent(TASK_CONTACTS_MODAL)}
      </SideModal>
    </>
  );
}
