import React, { useEffect, useState } from "react";
import { File } from "../../../../cores/boards/interfaces/api/get-card";
import { PanelFile } from "../../../../cores/boards/interfaces/PanelFile";

interface Props {
  file: PanelFile | File;
}

export default function VideoPreview({ file }: Props) {
  const [src, setSrc] = useState("");

  useEffect(() => {
    if (file.data) {
      setSrc(URL.createObjectURL((file as PanelFile).data));
    } else {
      setSrc((file as File).url);
    }
  }, [file]);
  
  return (
    <video className="filePreview">
      <source src={src} type={file.type} />
      Your browser does not support HTML5 video.
    </video>
  );
}