import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { InputWembii } from "../../InputWembii";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { useAutomationCore } from "../../../../cores/automations";
import { AutomationTriggerOptions } from "../../../../cores/automations/config";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { LabelWembii } from "../../LabelWembii";
import { useGlobalCore } from "../../../../cores/globals";
import { AUTOMATION_CREATE_FORM, AUTOMATION_EDIT_FORM } from "../../../../constants/modal.constant";
import { Contact } from "../../../../cores/contacts/interfaces/api/get-contacts";
import { AutomationTrigger } from "../../../../cores/automations/enum/AutomationTrigger.enum";

interface Props {
  contacts?: Contact[];
  automation?: AutomationTrigger;
}

export default function CreateAutomationForm(props: Props) {
  const { contacts = [], automation } = props;
  const intl = useIntl();
  const { company } = useMeCore();
  const { postAutomation, getAutomation } = useAutomationCore();
  const { turnOffModal, turnOnModal } = useGlobalCore();
  
  const [name, setName] = useState("");
  const [trigger, setTrigger] = useState("");

  useEffect(() => {
    if (automation) {
      setTrigger(automation);
    }
  }, [automation]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    const contactsIds = contacts.map((contact) => contact.id);
    const id = await postAutomation(
      company?.id!,
      { name, trigger, contacts: contactsIds },
      () => {
        turnOffModal(AUTOMATION_CREATE_FORM);
      }
    );

    if (id) {
      turnOnModal(AUTOMATION_EDIT_FORM, true);
      getAutomation(company?.id!, id);
    }
  }

  const disabled = useCallback(
    () => !name || !trigger,
    [name, trigger]
  );

  const options = useMemo(() => {
    if (automation) {
      return AutomationTriggerOptions.filter((option) => option.value === automation);
    } else {
      return AutomationTriggerOptions;
    }
  }, [automation]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column justify-content-center h-100 p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.automations.automation" })}
            </h2>

            <Form className="px-2" onSubmit={onFormSubmit}>
              <InputWembii
                label={intl.formatMessage({ id: "app.automations.automation.name" })}
                type="text"
                name="name"
                id="name"
                placeholder={intl.formatMessage({ id: "app.automations.automation.name" })}
                value={name}
                required
                onChange={setName}
              />

              <LabelWembii required>
                {intl.formatMessage({ id: "app.automations.automation.trigger" })}
              </LabelWembii>
              <div className="d-flex justify-content-center align-items-center my-4">
                {options.map((option) => (
                  <div
                    key={option.value}
                    onClick={() => setTrigger(option.value)}
                    className={cn("d-flex flex-column justify-content-center align-items-center border rounded pointer m-2 p-2", {
                      "bg-light": trigger === option.value
                    })}
                    style={{ width: 150, height: 150 }}
                  >
                    <FontAwesomeIcon
                      icon={["fas", option.icon as IconName]}
                      className="m-2"
                      size="2x"
                      color={trigger === option.value ? COLOR_PRIMARY : "gray"}
                    />
                    <span className={cn("text-center", { "text-primary": trigger === option.value })}>
                      {intl.formatMessage({ id: option.label })}
                    </span>
                  </div>
                ))}
              </div>

              <Button color="primary" block disabled={disabled()}>
                {intl.formatMessage({ id: "app.automations.automation.create" })}  
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
