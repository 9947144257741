import React from "react";
import SideModal from "../../layout/SideModal";
import {
  COMMENT_TYPES_FORM,
  CONTACT_COMPANY_CONTACTS_MODAL,
  CONTACT_COMPANY_MODAL,
  CONTACT_DELETE_MODAL,
  CONTACT_EXPAND_CREATION_FORM,
  CONTACT_EXPAND_EDIT_FORM,
  CONTACT_USERS_MODAL,
  DELETE_CONTACT_COMMENT_MODAL,
  DELETE_CONTACT_OWNER_MODAL,
} from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { COMMENT_TYPES } from "../../../constants/internalModal.constant";
import { CONTACT_LOADING } from "../../../constants/loading.constant";

export default function ContactSideModals() {
  const {
    isModal,
    turnOffModal,
    getModalContent,
    getModalVisibility,
    turnOffInternalModal,
    isLoading,
  } = useGlobalCore();

  return (
    <>
      <SideModal
        active={isModal(CONTACT_EXPAND_CREATION_FORM)}
        visible={getModalVisibility(CONTACT_EXPAND_CREATION_FORM)}
        onClose={() => turnOffModal(CONTACT_EXPAND_CREATION_FORM)}
        left
      >
        {getModalContent(CONTACT_EXPAND_CREATION_FORM)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_DELETE_MODAL)}
        visible={getModalVisibility(CONTACT_DELETE_MODAL)}
        onClose={() => turnOffModal(CONTACT_DELETE_MODAL)}
        left
      >
        {getModalContent(CONTACT_DELETE_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_USERS_MODAL)}
        visible={getModalVisibility(CONTACT_USERS_MODAL)}
        onClose={() => turnOffModal(CONTACT_USERS_MODAL)}
        left
      >
        {getModalContent(CONTACT_USERS_MODAL)}
      </SideModal>

      <SideModal
        active={isModal(CONTACT_EXPAND_EDIT_FORM)}
        visible={getModalVisibility(CONTACT_EXPAND_EDIT_FORM)}
        onClose={() => turnOffModal(CONTACT_EXPAND_EDIT_FORM)}
        showSppiner={isLoading(CONTACT_LOADING)}
        left
      >
        {getModalContent(CONTACT_EXPAND_EDIT_FORM)}
      </SideModal>

      <SideModal
        active={isModal(DELETE_CONTACT_COMMENT_MODAL)}
        visible={getModalVisibility(DELETE_CONTACT_COMMENT_MODAL)}
        onClose={() => turnOffModal(DELETE_CONTACT_COMMENT_MODAL)}
        left
      >
        {getModalContent(DELETE_CONTACT_COMMENT_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_COMPANY_MODAL)}
        visible={getModalVisibility(CONTACT_COMPANY_MODAL)}
        onClose={() => turnOffModal(CONTACT_COMPANY_MODAL)}
      >
        {getModalContent(CONTACT_COMPANY_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(DELETE_CONTACT_OWNER_MODAL)}
        visible={getModalVisibility(DELETE_CONTACT_OWNER_MODAL)}
        onClose={() => turnOffModal(DELETE_CONTACT_OWNER_MODAL)}
        left
      >
        {getModalContent(DELETE_CONTACT_OWNER_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(COMMENT_TYPES_FORM)}
        visible={getModalVisibility(COMMENT_TYPES_FORM)}
        onClose={() => {
          turnOffModal(COMMENT_TYPES_FORM);
          turnOffInternalModal(COMMENT_TYPES);
        }}
      >
        {getModalContent(COMMENT_TYPES_FORM)}
      </SideModal>
      <SideModal
        active={isModal(CONTACT_COMPANY_CONTACTS_MODAL)}
        visible={getModalVisibility(CONTACT_COMPANY_CONTACTS_MODAL)}
        onClose={() => turnOffModal(CONTACT_COMPANY_CONTACTS_MODAL)}
        left
      >
        {getModalContent(CONTACT_COMPANY_CONTACTS_MODAL)}
      </SideModal>
    </>
  );
}
