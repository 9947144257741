import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { BOARD_USERS_MODAL, DELETE_BOARD_ITEM_MODAL } from "../../../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useBoardCore } from "../../../../cores/boards";
import { AssignmentBoard } from "../../../../cores/boards/interfaces/api/get-board";
import { useGlobalCore } from "../../../../cores/globals";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useMeCore } from "../../../../cores/me";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import { getFirstLetters } from "../../../utils/stringUtils";
import AreYouSure from "../../AreYouSure";
import Avatar from "../../Avatar";
import { TooltipWembii } from "../../TooltipWembii";
import UserList from "../modal/UserList";

interface Props {
  canModify: boolean;
  board: string;
}

export default function AssignmentContainer({ canModify, board }: Props) {
  const { company, user, isAdmin, isReseller, companyRole } = useMeCore();
  const { card, postAssignment, deleteAssignment, getCard } = useBoardCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const openAssignmentUsers = useCallback(() => {
    turnOnModal(
      BOARD_USERS_MODAL,
      <UserList assignments={card?.assignments!} onAssign={(user) => {
        postAssignment(
          company?.id!,
          board,
          card?.list!,
          card?.id!,
          user.id,
          () => {
            getCard(company?.id!, board, card?.list!, card?.id!);
          }
        );
        turnOffModal(BOARD_USERS_MODAL);
      }} />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    company,
    board,
    card,
    postAssignment,
    getCard
  ]);

  const onDeleteAssignment = useCallback((removeUser) => {
    const canUnassig = isAdmin ||
      isReseller ||
      companyRole?.owner ||
      companyRole?.admin ||
      removeUser.id === user?.id;

    if (canModify && canUnassig)
      turnOnModal(
        DELETE_BOARD_ITEM_MODAL,
        <AreYouSure
          message={`Está seguro de querer desasignar el usuario ${removeUser.firstName} ${removeUser.lastName}?`}
          onSubmit={() => {
            deleteAssignment(
              company?.id!,
              board,
              card?.list!,
              card?.id!,
              removeUser.id,
              () => {
                getCard(company?.id!, board, card?.list!, card?.id!);
              }
            );
            turnOffModal(DELETE_BOARD_ITEM_MODAL);
          }}
          onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
        />,
        Position.LEFT
      );
  }, [
    turnOnModal,
    turnOffModal,
    deleteAssignment,
    getCard,
    company,
    isAdmin,
    isReseller,
    companyRole,
    user,
    board,
    card,
    canModify
  ]);

  const getUserName = (user: AssignmentBoard) => {
    let text = `${user.firstName} ${user.lastName}`;
    if (user.status !== UserStatus.ACTIVE.id) {
      text = `${text} - No activo`
    }

    return text
  }

  return (
    <div className="d-flex flex-wrap">
      {card && card.assignments.map((assignment) => (
        <div key={assignment.id}>
          <Avatar
            id={`user-modal-${assignment.id}`}
            active={assignment.status === UserStatus.ACTIVE.id}
            className="text-dark-50 font-weight-bold pointer m-1"
            label={getFirstLetters(`${assignment.lastName} ${assignment.firstName}`)}
            image={assignment.avatarUrl}
            onClick={() => onDeleteAssignment(assignment)}
          />
          <TooltipWembii id={`user-modal-${assignment.id}`} text={getUserName(assignment)} />
        </div>
      ))}
      {canModify && (
        <Avatar
          className="text-dark-50 pointer m-1"
          onClick={openAssignmentUsers}
          icon={
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              size="1x"
              color={COLOR_PRIMARY}
            />
          }
        />
      )}
    </div>
  );
}
