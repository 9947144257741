import React from "react";
import { Media } from "reactstrap";
import logo from "../../assets/wembii-logo.png";

export default function PoweredBy() {
  return (
    <div className="text-muted">
      Powered by
      <Media
        style={{ width: "80px" }}
        object
        src={logo}
        alt="Powered by"
      />
    </div>
  );
}
