import { Action } from "./enum/Action.enum";
import { AutomationTrigger } from "./enum/AutomationTrigger.enum";
import { ConditionOperator } from "./enum/ConditionOperator";
import { NewContactCondition } from "./enum/NewContactCondition.enum";

export const AutomationTriggerOptions = [
  {
    icon: "user-plus",
    label: "app.automations.trigger.newContact",
    value: AutomationTrigger.NEW_CONTACT,
  },
  {
    icon: "user",
    label: "app.automations.trigger.contact",
    value: AutomationTrigger.CONTACT,
  },
  {
    icon: "users",
    label: "app.automations.trigger.onAssignPersonInCharge",
    value: AutomationTrigger.ON_ASSIGN_OWNER,
  },
];

export const ContactConditionOptions = [
  {
    label: "app.automations.condition.contact.source",
    value: NewContactCondition.SOURCE,
  },
];

export const ActionOptions = [
  {
    label: "app.automations.action.automaticEmail",
    value: Action.AUTOMATIC_EMAIL,
    triggers: [
      AutomationTrigger.NEW_CONTACT,
      AutomationTrigger.CONTACT,
      AutomationTrigger.ON_ASSIGN_OWNER,
    ],
  },
  {
    label: "app.automations.action.newOpportunity",
    value: Action.NEW_OPPORTUNITY,
    triggers: [
      AutomationTrigger.NEW_CONTACT,
      AutomationTrigger.ON_ASSIGN_OWNER,
    ],
  },
  {
    label: "app.automations.action.cascadeAssignment",
    value: Action.CASCADE_ASSIGNMENT,
    triggers: [
      AutomationTrigger.NEW_CONTACT
    ],
  },
];

export const ConditionOperatorOptions = [
  {
    label: "app.automations.automation.and",
    value: ConditionOperator.AND,
  },
  {
    label: "app.automations.automation.or",
    value: ConditionOperator.OR,
  },
];
