import { GetGmailLoginResponse } from "../interface/api/get-gmail-login";
import {
  GmailSetting,
  PostGmailBindResponse,
} from "../interface/api/post-gmail-bind";
import { PostGmailEmailResponse } from "../interface/api/post-gmail-email";

export const gmailSettingMapper = {
  mapResponseToId(response: PostGmailEmailResponse) {
    const { id } = response?.payload;
    return id;
  },

  mapResponseToGmailSetting(response: PostGmailBindResponse): GmailSetting {
    const { gmailSetting } = response?.payload;
    return gmailSetting;
  },

  mapResponseToGmailLoginUrl(
    response: GetGmailLoginResponse
  ): { url: string } {
    const { url } = response?.payload;
    return { url };
  },
};
