import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { InstagramAccount } from "../interface/api/get-instagram-accounts";
import { InstagramMenssegerSetting } from "../interface/api/post-instagram-bind";

export const postUnbindInstagramMenssegerInitAction = createAction(
  "[InstagramMensseger] Unbind Instagram Init"
);
export const postUnbindInstagramMenssegerSuccessAction = createAction(
  "[InstagramMensseger] Unbind Instagram Success"
);
export const postUnbindInstagramMenssegerFailureAction = createAction(
  "[InstagramMensseger] Unbind Instagram Failure",
  props<{ error: string }>()
);

export const postBindInstagramMenssegerInitAction = createAction(
  "[InstagramMensseger] Bind Instagram Init"
);
export const postBindInstagramMenssegerSuccessAction = createAction(
  "[InstagramMensseger] Bind Instagram Success",
  props<{ settings: InstagramMenssegerSetting }>()
);
export const postBindInstagramMenssegerFailureAction = createAction(
  "[InstagramMensseger] Bind Instagram Failure",
  props<{ errorCode: number | null, errorMessage: string | null }>()
);

export const getInstagramMenssegerInitAction = createAction(
  "[InstagramMensseger] Get Instagram Settings Init"
);
export const getInstagramMenssegerSuccessAction = createAction(
  "[InstagramMensseger] Get Instagram Settings Success",
  props<{ settings: InstagramMenssegerSetting }>()
);
export const getInstagramMenssegerFailureAction = createAction(
  "[InstagramMensseger] Get Instagram Settings Failure",
  props<{ error: string }>()
);

export const getInstagramMenssegerAccountsInitAction = createAction(
  "[InstagramMensseger] Get Instagram Accounts Init"
);
export const getInstagramMenssegerAccountsSuccessAction = createAction(
  "[InstagramMensseger] Get Instagram Accounts Success",
  props<{ accounts: InstagramAccount[] }>()
);
export const getInstagramMenssegerAccountsFailureAction = createAction(
  "[InstagramMensseger] Get Instagram Accounts Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindInstagramMenssegerInitAction,
  postUnbindInstagramMenssegerSuccessAction,
  postUnbindInstagramMenssegerFailureAction,
  postBindInstagramMenssegerInitAction,
  postBindInstagramMenssegerSuccessAction,
  postBindInstagramMenssegerFailureAction,
  getInstagramMenssegerInitAction,
  getInstagramMenssegerSuccessAction,
  getInstagramMenssegerFailureAction,
  getInstagramMenssegerAccountsInitAction,
  getInstagramMenssegerAccountsSuccessAction,
  getInstagramMenssegerAccountsFailureAction,
});

export type InstagramMenssegerApiAction = typeof actions;
