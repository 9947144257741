import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { CONTACT_USERS_MODAL, DELETE_CONTACT_OWNER_MODAL } from "../../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../../constants/theme";
import { CompanyOwner } from "../../../cores/contacts/interfaces/api/get-company";
import { ContactOwner } from "../../../cores/contacts/interfaces/api/get-contact";
import { useGlobalCore } from "../../../cores/globals";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import { useMeCore } from "../../../cores/me";
import { User } from "../../../cores/session/interfaces/models/user";
import { UserStatus } from "../../../cores/user/constants/user-status";
import { getFirstLetters } from "../../utils/stringUtils";
import AreYouSure from "../AreYouSure";
import Avatar from "../Avatar";
import UserList from "../boards/modal/UserList";
import { TooltipWembii } from "../TooltipWembii";

interface Props {
  contacts: ContactOwner[] | CompanyOwner[];
  canModify?: boolean;
  onAdding: (arg0: User) => void;
  onRemoving: (arg0: string) => void;
}

export default function OwnerContainer({
  contacts,
  canModify = true,
  onAdding,
  onRemoving
}: Props) {
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { isAdmin, isReseller, companyRole, user } = useMeCore();

  const openAssignmentUsers = useCallback(() => {
    turnOnModal(
      CONTACT_USERS_MODAL,
      <UserList assignments={contacts} onAssign={(user) => {
        onAdding(user);
        turnOffModal(CONTACT_USERS_MODAL);
      }} />,
      Position.LEFT
    );
  }, [
    contacts,
    onAdding,
    turnOnModal,
    turnOffModal,
  ]);

  const onDeleteAssignment = useCallback((removeUser) => {
    const canUnassig = isAdmin ||
      isReseller ||
      companyRole?.owner ||
      companyRole?.admin ||
      removeUser.id === user?.id;

    if (canModify && canUnassig) {
      turnOnModal(
        DELETE_CONTACT_OWNER_MODAL,
        <AreYouSure
          message={`Está seguro de querer desasignar el usuario ${removeUser.firstName} ${removeUser.lastName}?`}
          onSubmit={() => {
            onRemoving(removeUser.id);
            turnOffModal(DELETE_CONTACT_OWNER_MODAL);
          }}
          onClose={() => turnOffModal(DELETE_CONTACT_OWNER_MODAL)}
        />,
        Position.LEFT
      );
    }
  }, [
    isAdmin,
    isReseller,
    companyRole,
    user,
    canModify,
    turnOnModal,
    turnOffModal,
    onRemoving
  ]);

  const getUserName = (user: ContactOwner) => {
    let text = `${user.firstName} ${user.lastName}`;
    if (user.status !== UserStatus.ACTIVE.id) {
      text = `${text} - No activo`;
    }

    return text;
  }

  return (
    <div className="d-flex flex-wrap">
      {contacts.map((owner) => (
        <div key={owner.id}>
          <Avatar
            id={`user-modal-${owner.id}`}
            active={owner.status === UserStatus.ACTIVE.id}
            className="text-dark-50 font-weight-bold pointer m-1"
            label={getFirstLetters(`${owner.lastName} ${owner.firstName}`)}
            image={owner.avatarUrl}
            onClick={() => onDeleteAssignment(owner)}
          />
          <TooltipWembii id={`user-modal-${owner.id}`} text={getUserName(owner)} />
        </div>
      ))}
      {canModify && (
        <Avatar
          className="text-dark-50 pointer m-1"
          onClick={openAssignmentUsers}
          icon={
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              size="1x"
              color={COLOR_PRIMARY}
            />
          }
        />
      )}
    </div>
  );
}
