import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "reactstrap";
import { AUTOMATION_EDIT_FORM } from "../../../constants/modal.constant";
import { useAutomationCore } from "../../../cores/automations";
import { AutomationTriggerOptions } from "../../../cores/automations/config";
import { Automation } from "../../../cores/automations/interfaces/api/get-automation";
import { useBoardCore } from "../../../cores/boards";
import { useContactCore } from "../../../cores/contacts";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { CheckboxWembii } from "../CheckboxWembii";
import { PanelWembii } from "../PanelWembii";

interface Props {
  automation: Automation;
}

export const AutomationRow = (props: Props) => {
  const intl = useIntl();
  const {
    automation: { id, name, active, trigger },
  } = props;
  const { turnOnModal } = useGlobalCore();
  const { company } = useMeCore();
  const { getContactTags } = useContactCore();
  const { getAutomation, putAutomation } = useAutomationCore();
  const { getBusinessBoards } = useBoardCore();

  const onClick = useCallback(() => {
    getContactTags(company?.id!);
    getBusinessBoards(company?.id!);
    turnOnModal(AUTOMATION_EDIT_FORM, true);
    getAutomation(company?.id!, id);
  }, [
    getAutomation,
    getContactTags,
    turnOnModal,
    getBusinessBoards,
    company,
    id,
  ]);

  const onUpdateAutomation = useCallback(() => {
    putAutomation(company?.id!, id, !active);
  }, [putAutomation, company, id, active]);

  const triggerOption = useMemo(
    () => AutomationTriggerOptions.find((option) => option.value === trigger),
    [trigger]
  );

  return (
    <PanelWembii className="w-100 px-4 py-3 my-2 pointer" onClick={onClick}>
      <Row>
        <Col xs="12" lg="7">
          <div className="d-flex align-items-center justify-content-center justify-content-lg-start w-100">
            <FontAwesomeIcon icon={["fas", "robot"]} className="mr-2" />
            <span className="h6 mb-0">{name}</span>
          </div>
          <span className="d-flex align-items-center justify-content-center justify-content-lg-start text-muted">
            {intl.formatMessage({
              id: triggerOption?.label,
            })}
          </span>
        </Col>
        <Col xs="12" lg="5">
          <div className="d-flex h-100 align-items-center justify-content-center justify-content-lg-end w-100">
            <CheckboxWembii
              mb={false}
              checked={active}
              label={intl.formatMessage({
                id: "app.automations.automation.active",
              })}
              onChange={onUpdateAutomation}
            />
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
