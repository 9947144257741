import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Col, Container, Label, Row } from "reactstrap";
import { useAdvertisementCore } from "../../cores/advertisements";
import { useFacebookAdsCore } from "../../cores/facebookAds";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { useIntl } from "react-intl";
import AudienceForm from "../component/advertisements/AudienceForm";
import BudgetForm from "../component/advertisements/BudgetForm";
import DemographyForm from "../component/advertisements/DemographyForm";
import DesignForm from "../component/advertisements/DesignForm";
import CampaignReview from "../component/advertisements/modal/CampaignReview";
import StartForm from "../component/advertisements/StartForm";
import { PanelWembii } from "../component/PanelWembii";
import { TitleWembii } from "../component/TitleWembii";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { CampaignGoals } from "../../cores/advertisements/constants/campaign-goals";
import FormsForm from "../component/advertisements/FormsForm";
import { isUrl } from "../utils/stringUtils";
import { CAMPAIGN_REVIEW } from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";

export default function CreateCampaign() {
  const intl = useIntl()
  const { company, companyPlan, companyRole, isAdmin, isReseller } = useMeCore();
  const { goToFacebookCampaigns, goToSocial } = useRouterCore();
  const {
    campaign,
    image,
    minBudget,
    deliveryEstimate,
    getDeliveryEstimate,
    postCampaign,
    reset,
  } = useAdvertisementCore();
  const { turnOffModal, turnOnModal, getModalVisibility, isModal } = useGlobalCore();
  const { settings } = useFacebookAdsCore();

  useEffect(() => {
    if (
      !companyPlan?.rules.allowFacebookAdvertisements || (
        !companyRole?.ads &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  const toggleSideModal = useCallback(() => {
    if (campaign.dailyBudget < minBudget?.minDailyBudgetImp!) {
      toast.error(
        `${intl.formatMessage({ id: "app.toast.error64" })} ${minBudget?.minDailyBudgetImp}`
      );
      return null;
    }
    if (
      campaign.endTime &&
      moment(campaign.endTime).isBefore(moment(campaign.startTime).add(1, "d"))
    ) {
      toast.error(intl.formatMessage({ id: "app.toast.error65" }));
      return null;
    }
    if (deliveryEstimate?.estimateDau! <= 2000) {
      toast.error(intl.formatMessage({ id: "app.toast.error66" }));
      return null;
    }

    turnOnModal(CAMPAIGN_REVIEW, true);
  }, [turnOnModal, minBudget, campaign, deliveryEstimate, intl]);

  const onSubmit = useCallback(async () => {
    await postCampaign(
      company?.id!,
      settings?.accountId!,
      campaign,
      image.file!
    );

    reset();
    goToFacebookCampaigns();
  }, [
    postCampaign,
    company,
    settings,
    campaign,
    image.file,
    reset,
    goToFacebookCampaigns,
  ]);

  useEffect(() => {
    if (campaign.targeting.countries.length) {
      getDeliveryEstimate(
        company?.id!,
        settings?.accountId!,
        campaign.objective,
        campaign.targeting
      );
    }
  }, [
    campaign.targeting,
    campaign.objective,
    company,
    getDeliveryEstimate,
    settings,
  ]);

  const formatDeliveryEstimate = useCallback(() => {
    if (
      !deliveryEstimate ||
      (!campaign.targeting.countries.length &&
        !campaign.targeting.cities.length)
    ) {
      return 0;
    } else if (deliveryEstimate.estimateDau > 1000000) {
      return `${(deliveryEstimate.estimateDau / 1000).toFixed(0)}K`;
    } else {
      return deliveryEstimate.estimateDau;
    }
  }, [deliveryEstimate, campaign.targeting]);

  const disabled = useCallback(
    () =>
      !campaign.name ||
      !campaign.url ||
      !isUrl(campaign.url) ||
      !campaign.startTime ||
      !campaign.dailyBudget ||
      !campaign.titleBusiness ||
      !campaign.urlBusiness ||
      !isUrl(campaign.urlBusiness) ||
      campaign.targeting.publisherPlatforms.length === 0 ||
      !campaign.description ||
      !image.file ||
      (campaign.targeting.countries.length === 0 &&
        campaign.targeting.cities.length === 0),
    [campaign, image]
  );

  const isFieldToLong = (field: string, length: number) =>
    field.length > length;

  const disabled2 = useCallback(
    () =>
      campaign.objective === CampaignGoals.LEAD_GENERATION.id && (
        !campaign.form.name ||
        !campaign.form.contextCard.title ||
        isFieldToLong(campaign.form.contextCard.title, 60) ||
        !campaign.form.contextCard.content ||
        !campaign.form.privacyPolicy.linkText ||
        isFieldToLong(campaign.form.privacyPolicy.linkText, 70) ||
        !campaign.form.privacyPolicy.url ||
        !isUrl(campaign.form.privacyPolicy.url) ||
        !campaign.form.thankYouPage.title ||
        isFieldToLong(campaign.form.thankYouPage.title, 60) ||
        !campaign.form.thankYouPage.body ||
        !campaign.form.thankYouPage.buttonText ||
        isFieldToLong(campaign.form.thankYouPage.buttonText, 60) ||
        !campaign.form.thankYouPage.websiteUrl ||
        !isUrl(campaign.form.thankYouPage.websiteUrl) ||
        !campaign.form.thankYouPage.title ||
        (campaign.form.autoQuestions.length === 0 &&
          campaign.form.customQuestions.length === 0)
      ),
    [campaign]
  );

  return (
    <Layout>
      <Content>
        <Container fluid>
          <div className="d-flex justify-content-between flex-wrap">
            <TitleWembii Tag="h3" className="mb-3">
              {`${intl.formatMessage({ id: "app.advertisements.createCampaing" })} - ${settings?.name}`}
            </TitleWembii>
            <Label className="d-flex align-items-center">
              {intl.formatMessage(
                { id: "app.advertisements.scope" },
                {
                  quantity: formatDeliveryEstimate(),
                }
              )}
            </Label>
          </div>
          <Row>
            <Col lg="6" md="12">
              <StartForm />
            </Col>
            <Col lg="6" md="12">
              <DemographyForm />
              <AudienceForm />
            </Col>
          </Row>
          <Row>
            <Col lg="9" md="12">
              <DesignForm />
            </Col>
            <Col lg="3" md="12">
              <BudgetForm />
            </Col>
          </Row>
          {campaign.objective === CampaignGoals.LEAD_GENERATION.id && (
            <FormsForm />
          )}
          <PanelWembii shadow className="mb-4 p-4">
            <Row>
              <Col className="text-center">
                <Button
                  color="primary"
                  onClick={toggleSideModal}
                  disabled={disabled() || disabled2()}
                >
                  {intl.formatMessage({ id: "app.advertisements.createCampaing" })} 
                </Button>
              </Col>
            </Row>
          </PanelWembii>
        </Container>
      </Content>
      <SideModal
        active={isModal(CAMPAIGN_REVIEW)}
        visible={getModalVisibility(CAMPAIGN_REVIEW)}
        onClose={() => {
          turnOffModal(CAMPAIGN_REVIEW);
        }}
      >
        <CampaignReview onSubmit={onSubmit} />
      </SideModal>
    </Layout>
  );
}
