import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import {
  SMTP_GET_LOADING,
  SMTP_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import SocialCard from "../SocialCard";
import { SmtpIcon } from "./SmtpIcon";
import { useSmtpCore } from "../../../../cores/smtp";
import { SMTP_BIND_MODAL } from "../../../../constants/modal.constant";
import SmtpLoginForm from "./SmtpLoginForm";

export default function SmtpBindCard() {
  const intl = useIntl();
  const { get, settings, unbind } = useSmtpCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { isLoading, turnOnModal, turnOffModal } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get]);

  const isActive = useMemo(
    (): boolean => !!settings?.email,
    [settings]
  );

  const handleBind = useCallback(async () => {
    turnOnModal(
      SMTP_BIND_MODAL,
      <SmtpLoginForm asUser={false} onSuccess={() => {
        turnOffModal(SMTP_BIND_MODAL);
        getMeSocialSettings(company?.id!);
      }} />
    );
  }, [turnOnModal, getMeSocialSettings, turnOffModal, company]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, false, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Smtp,
      name: _.upperCase(SocialProviderName.Smtp),
      icon: <SmtpIcon size="6x" active={isActive} />,
      displayName: settings?.email,
    }),
    [settings, isActive]
  );

  return (
    <SocialCard
      social={socialProvider}
      bindLoading={isLoading(SMTP_SYNC_LOADING)}
      getLoading={isLoading(SMTP_GET_LOADING)}
      showSynchronizer={false}
      remainingBindDays={null}
    >
      <div className="w-100 px-2">
        <Button
          className="w-100 mx-auto"
          color="primary"
          outline={isActive}
          onClick={!isActive ? handleBind : handleUnbind}
        >
          {!isActive
            ? intl.formatMessage({ id: "app.home.syncUp" })
            : intl.formatMessage({ id: "app.home.disconnect" })}
        </Button>
      </div>
    </SocialCard>
  );
}
