import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { MERCADOLIBRE_INSIGHT_LOADING } from "../../constants/loading.constant";
import { ANALYTICS_EXPAND } from "../../constants/modal.constant";
import { useAnalyticsCore } from "../../cores/analytics";
import { useGlobalCore } from "../../cores/globals";
import { useMarketCore } from "../../cores/market";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { AnalyticsDatePicker } from "../component/analytics/AnalyticsDatePicker";
import { Currency } from "../component/analytics/Currency";
import { MercadolibreCharts } from "../component/analytics/MercadolibreCharts";
import { MercadolibreIcon } from "../component/social/Mercadolibre/MercadolibreIcon";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";

export const MercadolibreAnalytics = () => {
  const intl = useIntl();
  const { getMercadolibreInsights, date } = useAnalyticsCore();
  const { isModal, turnOffModal, getModalContent, getModalVisibility } = useGlobalCore();
  const {
    company,
    socialSettings,
    companyPlan,
    companyRole,
    isReseller,
    isAdmin
  } = useMeCore();
  const { mercadolibreOrders, getMercadolibreOrders } = useMarketCore();
  const { goToSocial } = useRouterCore();

  const isSync = useMemo(() => socialSettings && socialSettings.mercadoLibre, [
    socialSettings,
  ]);

  const isFutureDate = (date: string) => moment(date).isAfter(moment());
  
  const areDatesInverted = (since: string, until: string) => 
    moment(since).isAfter(moment(until));

  useEffect(() => {
    if (
      !isSync || !companyPlan?.rules.allowAnalytics || (
        !companyRole?.analitycs &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [goToSocial, isSync, companyPlan, companyRole, isAdmin, isReseller]);

  useEffect(() => {
    if (
      isSync &&
      !isFutureDate(date.since) &&
      !isFutureDate(date.until) &&
      !areDatesInverted(date.since, date.until)
    ) {
      getMercadolibreInsights(company!.id, date.since, date.until);
    }
  }, [company, date, getMercadolibreInsights, isSync]);

  useEffect(() => {
    getMercadolibreOrders(company!.id, { perPage: 1 });
  }, [company, getMercadolibreOrders]);

  const currency = useMemo(() => mercadolibreOrders[0]?.currency, [
    mercadolibreOrders,
  ]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.analiyticMercLibre.title" })}
        icon={<MercadolibreIcon active size="4x" />}
        subtitle={currency && <Currency currency={currency} />}
        rightChildren={<AnalyticsDatePicker />}
      />
      <Content>
        <UpdateLoadingCard
          loadingId={MERCADOLIBRE_INSIGHT_LOADING}
          text={intl.formatMessage({ id: "app.analiyticMercLibre.updating" })}
        />
        <MercadolibreCharts />
      </Content>
      <SideModal
        active={isModal(ANALYTICS_EXPAND)}
        visible={getModalVisibility(ANALYTICS_EXPAND)}
        onClose={() => turnOffModal(ANALYTICS_EXPAND)}
        left
      >
        {getModalContent(ANALYTICS_EXPAND)}
      </SideModal>
    </Layout>
  );
};
