import { User } from "../../session/interfaces/models/user";
import { GetUserResponse } from "../interfaces/api/get-user";
import { GetUsersResponse, GetUsersResponsePayload } from "../interfaces/api/get-users";
import { PostUserResponse } from "../interfaces/api/post-user";

export const userMapper = {
  mapResponseToUser(response: PostUserResponse | GetUserResponse): User {
    return response?.payload?.user;
  },

  mapResponseToUsers(response: GetUsersResponse): GetUsersResponsePayload {
    const { users, total } = response?.payload;
    return { users, total };
  },
};
