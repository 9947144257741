import React, { useCallback } from "react";
import { POST_MODAL } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { useRouterCore } from "../../../cores/router";
import { useSocialPostCore } from "../../../cores/socialPost";
import { SocialProviderId } from "../../../cores/socialPost/config";
import { SocialPost } from "../../../cores/socialPost/interfaces/api/get-social-posts";
import { IconMapper } from "../socialPost/selector/SocialPostSelector";
import { PostModal } from "./modal/PostModal";

interface Props {
  post: SocialPost;
}

export const PostItem = (props: Props) => {
  const { post } = props;
  const { company } = useMeCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { goToSocialPost, goToSocialStory } = useRouterCore();
  const { deleteSocialPost, selectPost, removeAllImages, removeVideo } = useSocialPostCore();

  const onDelete = useCallback(
    () => {
      deleteSocialPost(company?.id!, post.post.id);
      turnOffModal(POST_MODAL);
    },
    [deleteSocialPost, turnOffModal, company, post]
  );

  const onEdit = useCallback(
    () => {
      turnOffModal(POST_MODAL);

      if (post.post.target === "post")
        goToSocialPost("calendar");
      else
        goToSocialStory("calendar");
      removeVideo();
      removeAllImages();
      selectPost(post);
    },
    [post, removeAllImages, goToSocialStory, selectPost, removeVideo, goToSocialPost, turnOffModal]
  );

  const togglePostModal = useCallback(() => {
    turnOnModal(
      POST_MODAL,
      <PostModal post={post} onEdit={onEdit} onDelete={onDelete} />
    );
  }, [post, turnOnModal, onEdit, onDelete]);

  const showIcon = useCallback(() => {
    switch (post.post.socialProvider) {
      case SocialProviderId.Facebook:
        return IconMapper[SocialProviderId.Facebook]({ active: true, size: "2x" });
      case SocialProviderId.Twitter:
        return IconMapper[SocialProviderId.Twitter]({ active: true, size: "2x" });
      case SocialProviderId.LinkedIn:
        return IconMapper[SocialProviderId.LinkedIn]({ active: true, size: "2x" });
      case SocialProviderId.LinkedInBusiness:
        return IconMapper[SocialProviderId.LinkedInBusiness]({ active: true, size: "2x" });
      case SocialProviderId.Instagram:
        return IconMapper[SocialProviderId.Instagram]({ active: true, size: "2x" });
      default:
        return null;
    }
  }, [post.post.socialProvider]);

  return (
    <div className="p-2 pointer" onClick={togglePostModal}>
      {showIcon()}
    </div>
  );
};
