import React, { useCallback, useMemo } from "react";
import { Container } from "reactstrap";
import { COLOR_SECONDARY } from "../../../constants/theme";
import { useAnalyticsCore } from "../../../cores/analytics";
import { ChartMetric } from "../../../cores/analytics/interface/ChartMetric";
import { Metric2 } from "../../../cores/analytics/interface/Metric2";
import { timeFormatter } from "../../utils/chartsUtils";
import { ChartContainer } from "./ChartContainer";
import { BarChart } from "./charts/BarChart";
import { PieChart } from "./charts/PieChart";
import { Legend } from "./Legend";
import { Monitors } from "./Monitors";
import { useIntl } from "react-intl";

export const GoogleCharts = () => {
  const intl = useIntl();
  const { googleInsights, toChartMetric } = useAnalyticsCore();

  const commonData: Metric2[] = useMemo(() => [
    { ...googleInsights.sessionsPerDay, type: "line" },
    { ...googleInsights.pageViewsPerDay, type: "line" },
    { ...googleInsights.organicSearchPerDay, type: "line" },
    { ...googleInsights.userPerDay, type: "bar", color: COLOR_SECONDARY },
  ], [
    googleInsights.sessionsPerDay,
    googleInsights.pageViewsPerDay,
    googleInsights.organicSearchPerDay,
    googleInsights.userPerDay
  ]);

  const sessionData: Metric2[] = useMemo(() => [
    {
      ...googleInsights.sessionsDurationPerDay,
      type: "line",
      valueFormatter: timeFormatter,
    },
    { ...googleInsights.sessionsPerDay, type: "bar", color: COLOR_SECONDARY },
  ], [googleInsights.sessionsDurationPerDay, googleInsights.sessionsPerDay]);

  const userCountryData: Metric2 = useMemo(() => ({
    ...googleInsights.usersPerCountry,
    type: "pie",
  }), [googleInsights.usersPerCountry]);

  const pageViewsPerCountryData: Metric2 = useMemo(() => ({
    ...googleInsights.pageViewsPerCountry,
    type: "pie",
  }), [googleInsights.pageViewsPerCountry]);

  const organicSearchPerCountryData: Metric2 = useMemo(() => ({
    ...googleInsights.organicSearchPerCountry,
    type: "pie",
  }), [googleInsights.organicSearchPerCountry]);

  const commonChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(commonData),
    [commonData, toChartMetric]
  );

  const sessionChartMetrics: ChartMetric = useMemo(
    () => toChartMetric(sessionData),
    [sessionData, toChartMetric]
  );

  const userCountryChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([userCountryData]),
    [userCountryData, toChartMetric]
  );

  const pageViewsCountryChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([pageViewsPerCountryData]),
    [pageViewsPerCountryData, toChartMetric]
  );

  const organicSearchCountryChartMetrics: ChartMetric = useMemo(
    () => toChartMetric([organicSearchPerCountryData]),
    [organicSearchPerCountryData, toChartMetric]
  );

  const commonMonitor = useCallback(() => <Monitors metrics={commonData} />, [
    commonData,
  ]);

  const sessionMonitor = useCallback(() => <Monitors metrics={sessionData} />, [
    sessionData,
  ]);

  return (
    <Container>
      <ChartContainer
        id="googleChart"
        height={250}
        className="mb-4"
        title={intl.formatMessage({ id: "app.metricsGoogle.title1" })}
        monitors={commonMonitor()}
      >
        <BarChart
          labels={commonChartMetrics.labels}
          datasets={commonChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="googleChart"
        height={250}
        className="mb-4"
        title={intl.formatMessage({ id: "app.metricsGoogle.title2" })}
        monitors={sessionMonitor()}
      >
        <BarChart
          labels={sessionChartMetrics.labels}
          datasets={sessionChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="googleChart"
        className="mb-4"
        title={intl.formatMessage({ id: "app.metricsGoogle.title3" })}
        height={300}
        legend={<Legend metric={userCountryData} />}
      >
        <PieChart
          labels={userCountryChartMetrics.labels}
          datasets={userCountryChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="googleChart"
        className="mb-4"
        title={intl.formatMessage({ id: "app.metricsGoogle.title4" })}
        height={300}
        legend={<Legend metric={pageViewsPerCountryData} />}
      >
        <PieChart
          labels={pageViewsCountryChartMetrics.labels}
          datasets={pageViewsCountryChartMetrics.datasets}
        />
      </ChartContainer>
      <ChartContainer
        id="googleChart"
        className="mb-4"
        title={intl.formatMessage({ id: "app.metricsGoogle.title5" })}
        height={300}
        legend={<Legend metric={organicSearchPerCountryData} />}
      >
        <PieChart
          labels={organicSearchCountryChartMetrics.labels}
          datasets={organicSearchCountryChartMetrics.datasets}
        />
      </ChartContainer>
    </Container>
  );
};
