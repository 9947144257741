import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { EmailFile } from "../interface/emailFile";

export const setFilesAction = createAction(
  "[Email] Set Files",
  props<{ files: EmailFile[] }>()
);

export const updateFileAction = createAction(
  "[Email] Update File",
  props<{ file: EmailFile }>()
);

export const deleteFileAction = createAction(
  "[Email] Delete File",
  props<{ id: string }>()
);

export const clearAction = createAction(
  "[Email] Clear"
);

export const setSubjectAction = createAction(
  "[Email] Set Subject",
  props<{ subject: string }>()
);

export const setMessageAction = createAction(
  "[Email] Set Message",
  props<{ message: string }>()
);

const actions = joinActions({
  setFilesAction,
  deleteFileAction,
  updateFileAction,
  clearAction,
  setSubjectAction,
  setMessageAction,
});

export type EmailActions = typeof actions;
