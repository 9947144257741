import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { EventAction, setCalendarTimezoneAction, setCalendarTitleAction, setEventDescriptionAction } from "./actions";
import { Event } from "../interface/api/get-events";
import { CommentEvent, Event as SingleEvent } from "../interface/api/get-event";
import {
  postEventInitAction,
  postEventSuccessAction,
  postEventFailureAction,
  EventApiAction,
  deleteEventInitAction,
  deleteEventSuccessAction,
  deleteEventFailureAction,
  getEventsSuccessAction,
  getEventsFailureAction,
  getEventsInitAction,
  getEventSuccessAction,
  getAvailableTimeFailureAction,
  getAvailableTimeInitAction,
  getAvailableTimeSuccessAction,
  postPublicEventFailureAction,
  postPublicEventInitAction,
  postPublicEventSuccessAction,
  getCalendarSuccessAction,
  putCalendarSuccessAction,
  getCalendarInitAction,
  getCalendarFailureAction,
  putCalendarInitAction,
  putCalendarFailureAction,
  postCalendarTimeInitAction,
  postCalendarTimeFailureAction,
  deleteCalendarTimeInitAction,
  deleteCalendarTimeFailureAction,
  postCalendarTimeSuccessAction,
  deleteCalendarTimeSuccessAction,
  postContactInputInitAction,
  deleteContactInputSuccessAction,
  postContactInputSuccessAction,
  postContactInputFailureAction,
  deleteContactInputInitAction,
  deleteContactInputFailureAction,
  getPublicCalendarSuccessAction,
  getPublicCalendarInitAction,
  getPublicCalendarFailureAction,
  postCommentSuccessAction,
  putCommentSuccessAction,
  deleteCommentSuccessAction,
} from "./api-actions";
import { Calendar, Input } from "../interface/api/get-calendar";
import { Calendar as PublicCalendar } from "../interface/api/get-public-calendar";
import { Hour } from "../interface/api/post-calendar-time";

export interface EventState {
  calendar: Calendar | null;
  hours: {
    ids: number[];
    entities: Record<string, Hour>;
  };
  inputs: {
    ids: number[];
    entities: Record<string, Input>;
  };
  title: string;
  timezone: string;
  description: string;
  event: SingleEvent | null;
  events: {
    ids: number[];
    entities: Record<string, Event>;
  };
  comments: {
    ids: string[];
    entities: Record<string, CommentEvent>;
  };
  publicCalendar: PublicCalendar | null;
  availability: string[];
  errorCode: number | null;
  errorMessage: string | null;
}

const initialState: EventState = {
  calendar: null,
  hours: {
    ids: [],
    entities: {},
  },
  inputs: {
    ids: [],
    entities: {},
  },
  title: "",
  timezone: "",
  description: "",
  event: null,
  events: {
    ids: [],
    entities: {},
  },
  comments: {
    ids: [],
    entities: {},
  },
  publicCalendar: null,
  availability: [],
  errorCode: null,
  errorMessage: null,
};

const eventsAdapter = createEntityAdapter<Event>((payload) => payload.id);
const hoursAdapter = createEntityAdapter<Hour>((payload) => payload.id);
const inputsAdapter = createEntityAdapter<Input>((payload) => payload.id);
const commentsAdapter = createEntityAdapter<CommentEvent>((payload) => payload.id);

export default produce(
  (
    draft: EventState = initialState,
    action: EventApiAction | EventAction | GlobalActions
  ) => {
    switch (action.type) {
      case setEventDescriptionAction.type:
        draft.description = action.description;
        return draft;
      case setCalendarTitleAction.type:
        draft.title = action.title;
        return draft;
      case setCalendarTimezoneAction.type:
        draft.timezone = action.timezone;
        return draft;
      case getEventsSuccessAction.type:
        eventsAdapter.addMany(action.events, draft.events);
        return draft;
      case getEventSuccessAction.type:
        draft.event = action.event;
        commentsAdapter.addMany(action.event.comments, draft.comments);
        return draft;
      case deleteEventSuccessAction.type:
        eventsAdapter.removeOne(action.id, draft.events);
        return draft;
      case postEventSuccessAction.type:
        eventsAdapter.setOne(action.event, draft.events);
        return draft;
      case getPublicCalendarSuccessAction.type:
        draft.publicCalendar = action.calendar;
        return draft;
      case getAvailableTimeSuccessAction.type:
        draft.availability = action.hours;
        return draft;
      case getCalendarSuccessAction.type:
        draft.calendar = { 
          ...action.calendar,
          hours: [],
          contactInputs: [],
        };
        hoursAdapter.addMany(action.calendar.hours, draft.hours);
        inputsAdapter.addMany(action.calendar.contactInputs, draft.inputs);
        return draft;
      case putCalendarSuccessAction.type:
        draft.calendar = action.calendar;
        draft.errorCode = null;
        draft.errorMessage = null;
        return draft;
      case putCalendarFailureAction.type:
      case getAvailableTimeFailureAction.type:
        draft.errorCode = action.errorCode;
        draft.errorMessage = action.errorMessage;
        return draft;
      case getAvailableTimeInitAction.type:
      case getCalendarInitAction.type:
        draft.errorCode = null;
        draft.errorMessage = null;
        return draft;
      case postCalendarTimeSuccessAction.type:
        hoursAdapter.setOne(action.time, draft.hours);
        return draft;
      case deleteCalendarTimeSuccessAction.type:
        hoursAdapter.removeOne(+action.id, draft.hours);
        return draft;
      case postContactInputSuccessAction.type:
        inputsAdapter.setOne(action.input, draft.inputs);
        return draft;
      case deleteContactInputSuccessAction.type:
        inputsAdapter.removeOne(+action.id, draft.inputs);
        return draft;
      case postCommentSuccessAction.type:
        commentsAdapter.setOne(action.comment, draft.comments);
        commentsAdapter.moveToFirstPosition(action.comment.id, draft.comments);
        return draft;
      case putCommentSuccessAction.type:
        commentsAdapter.updateOne(action.comment, draft.comments);
        return draft;
      case deleteCommentSuccessAction.type:
        commentsAdapter.removeOne(action.id, draft.comments);
        return draft;
      case resetAction.type:
        return initialState;
      case postEventInitAction.type:
      case postEventFailureAction.type:
      case postPublicEventInitAction.type:
      case postPublicEventSuccessAction.type:
      case getEventsInitAction.type:
      case postPublicEventFailureAction.type:
      case deleteEventInitAction.type:
      case deleteEventFailureAction.type:
      case getEventsFailureAction.type:
      case getCalendarFailureAction.type:
      case putCalendarInitAction.type:
      case postCalendarTimeInitAction.type:
      case postCalendarTimeFailureAction.type:
      case deleteCalendarTimeInitAction.type:
      case deleteCalendarTimeFailureAction.type:
      case postContactInputInitAction.type:
      case postContactInputFailureAction.type:
      case deleteContactInputInitAction.type:
      case deleteContactInputFailureAction.type:
      case getPublicCalendarInitAction.type:
      case getPublicCalendarFailureAction.type:
      default:
        return draft;
    }
  }
);
