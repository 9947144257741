import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ADVERTISEMENT_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { getMeCompanyInitAction } from "../me/store/api-actions";
import { CampaignCreation, Targeting } from "./interfaces/api/post-campaign";
import { advertisementsApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  resetCampaignAction,
  selectImageAction,
  setCampaignAction,
} from "./store/actions";
import {
  getAdsetsFailureAction,
  getAdsetsInitAction,
  getAdsetsSuccessAction,
  getAdsFailureAction,
  getAdsInitAction,
  getAdsSuccessAction,
  getCampaignsFailureAction,
  getCampaignsInitAction,
  getCampaignsSuccessAction,
  getDeliveryEstimateFailureAction,
  getDeliveryEstimateInitAction,
  getDeliveryEstimateSuccessAction,
  getInterestsFailureAction,
  getInterestsInitAction,
  getInterestsSuccessAction,
  getLanguagesFailureAction,
  getLanguagesInitAction,
  getLanguagesSuccessAction,
  getLocationsFailureAction,
  getLocationsSuccessAction,
  getMinBudgetFailureAction,
  getMinBudgetInitAction,
  getMinBudgetSuccessAction,
  postCampaignFailureAction,
  postCampaignInitAction,
  postCampaignSuccessAction,
  putAdFailureAction,
  putAdInitAction,
  putAdsetFailureAction,
  putAdsetInitAction,
  putAdsetSuccessAction,
  putAdSuccessAction,
  putCampaignFailureAction,
  putCampaignInitAction,
  putCampaignSuccessAction,
} from "./store/api-actions";
import {
  adsetsSelector,
  adsSelector,
  campaignSelector,
  campaignsSelector,
  deliveryEstimateSelector,
  interestsSelector,
  languagesEntitiesSelector,
  languagesSelector,
  locationsSelector,
  minBudgetSelector,
  selectedImageSelector,
} from "./store/selector";

export function useAdvertisementCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const locations = useSelector(locationsSelector);
  const interests = useSelector(interestsSelector);
  const languages = useSelector(languagesSelector);
  const campaigns = useSelector(campaignsSelector);
  const adsets = useSelector(adsetsSelector);
  const ads = useSelector(adsSelector);
  const campaign = useSelector(campaignSelector);
  const image = useSelector(selectedImageSelector);
  const minBudget = useSelector(minBudgetSelector);
  const deliveryEstimate = useSelector(deliveryEstimateSelector);
  const languagesEntities = useSelector(languagesEntitiesSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const getLocations = useCallback(
    async (companyId: string, pattern: string) => {
      try {
        dispatch(getMeCompanyInitAction());
        const response = await advertisementsApiService.getLocations(
          companyId,
          pattern
        );
        dispatch(getLocationsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getLocationsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getInterests = useCallback(
    async (companyId: string, pattern: string) => {
      try {
        dispatch(getInterestsInitAction());
        const response = await advertisementsApiService.getInterests(
          companyId,
          pattern
        );
        dispatch(getInterestsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getInterestsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getLanguages = useCallback(
    async (companyId: string, pattern?: string) => {
      try {
        dispatch(getLanguagesInitAction());
        const response = await advertisementsApiService.getLanguages(
          companyId,
          pattern
        );
        dispatch(getLanguagesSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getLanguagesFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getCampaigns = useCallback(
    async (companyId: string, adAccount: string) => {
      try {
        dispatch(getCampaignsInitAction());
        turnOnLoading(ADVERTISEMENT_LOADING);
        const response = await advertisementsApiService.getCampaigns(
          companyId,
          adAccount
        );
        dispatch(getCampaignsSuccessAction(response));
        turnOffLoading(ADVERTISEMENT_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(ADVERTISEMENT_LOADING);
        dispatch(getCampaignsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getAdsets = useCallback(
    async (companyId: string, campaign: string) => {
      try {
        dispatch(getAdsetsInitAction());
        const response = await advertisementsApiService.getAdsets(
          companyId,
          campaign
        );
        dispatch(getAdsetsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getAdsetsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getAds = useCallback(
    async (companyId: string, adset: string) => {
      try {
        dispatch(getAdsInitAction());
        const response = await advertisementsApiService.getAds(
          companyId,
          adset
        );
        dispatch(getAdsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getAdsFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getDeliveryEstimate = useCallback(
    async (
      companyId: string,
      adAccount: string,
      objective: string,
      targeting: Targeting
    ) => {
      try {
        dispatch(getDeliveryEstimateInitAction());
        const response = await advertisementsApiService.getDeliveryEstimate(
          companyId,
          adAccount,
          objective,
          targeting
        );
        dispatch(getDeliveryEstimateSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getDeliveryEstimateFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getMinBudget = useCallback(
    async (companyId: string, adAccount: string, currency: string) => {
      try {
        dispatch(getMinBudgetInitAction());
        const response = await advertisementsApiService.getMinBudget(
          companyId,
          adAccount,
          currency
        );
        dispatch(getMinBudgetSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getMinBudgetFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postCampaign = useCallback(
    async (
      companyId: string,
      adAccount: string,
      campaign: CampaignCreation,
      image: File
    ) => {
      try {
        dispatch(postCampaignInitAction());
        const response = await advertisementsApiService.postCampaign(
          companyId,
          adAccount,
          campaign,
          image
        );
        dispatch(postCampaignSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error1" }));
        dispatch(postCampaignFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const putCampaign = useCallback(
    async (companyId: string, campaign: string, status: string) => {
      try {
        dispatch(putCampaignInitAction());
        turnOnLoading(ADVERTISEMENT_LOADING);
        const response = await advertisementsApiService.putCampaign(
          companyId,
          campaign,
          status
        );
        dispatch(putCampaignSuccessAction(response));
        turnOffLoading(ADVERTISEMENT_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(ADVERTISEMENT_LOADING);
        dispatch(putCampaignFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const putAdset = useCallback(
    async (
      companyId: string,
      campaign: string,
      status: string,
      dailyBudget: string
    ) => {
      try {
        dispatch(putAdsetInitAction());
        turnOnLoading(ADVERTISEMENT_LOADING);
        const response = await advertisementsApiService.putAdset(
          companyId,
          campaign,
          status,
          dailyBudget
        );
        dispatch(putAdsetSuccessAction(response));
        turnOffLoading(ADVERTISEMENT_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(ADVERTISEMENT_LOADING);
        dispatch(putAdsetFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const putAd = useCallback(
    async (companyId: string, campaign: string, status: string) => {
      try {
        dispatch(putAdInitAction());
        turnOnLoading(ADVERTISEMENT_LOADING);
        const response = await advertisementsApiService.putAd(
          companyId,
          campaign,
          status
        );
        dispatch(putAdSuccessAction(response));
        turnOffLoading(ADVERTISEMENT_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(ADVERTISEMENT_LOADING);
        dispatch(putAdFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  return {
    locations,
    interests,
    languages,
    campaign,
    campaigns,
    adsets,
    ads,
    image,
    minBudget,
    deliveryEstimate,
    languagesEntities,
    getLocations,
    getInterests,
    getLanguages,
    getCampaigns,
    getAdsets,
    getAds,
    getMinBudget,
    getDeliveryEstimate,
    postCampaign,
    putCampaign,
    putAdset,
    putAd,
    setCampaign: (campaign: CampaignCreation) =>
      dispatch(setCampaignAction({ campaign })),
    selectImage: (file: File | null, url: string) =>
      dispatch(selectImageAction({ file, url })),
    reset: () => dispatch(resetCampaignAction()),
  };
}
