import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { LinkedInBusinessSetting } from "../interface/api/post-linkedinBussiness-bind";
import { Organization } from "../interface/api/post-linkedinBussiness-organizations";
import {
  getLinkedInBusinessFailureAction,
  getLinkedInBusinessInitAction,
  getLinkedInBusinessSuccessAction,
  LinkedInBusinessApiAction,
  postBindLinkedInBusinessFailureAction,
  postBindLinkedInBusinessInitAction,
  postBindLinkedInBusinessSuccessAction,
  postOrganizationsLinkedInBusinessFailureAction,
  postOrganizationsLinkedInBusinessInitAction,
  postOrganizationsLinkedInBusinessSuccessAction,
  postUnbindLinkedInBusinessFailureAction,
  postUnbindLinkedInBusinessInitAction,
  postUnbindLinkedInBusinessSuccessAction,
  putLinkedInBusinessSocialPostFailureAction,
  putLinkedInBusinessSocialPostInitAction,
  putLinkedInBusinessSocialPostSuccessAction,
} from "./api-actions";

export interface LinkedInBusinessState {
  settings: LinkedInBusinessSetting | null;
  organizations: Organization[];
  error: string;
}

const initialState: LinkedInBusinessState = {
  settings: null,
  organizations: [],
  error: "",
};

export default produce(
  (
    draft: LinkedInBusinessState = initialState,
    action: LinkedInBusinessApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindLinkedInBusinessSuccessAction.type:
      case getLinkedInBusinessSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindLinkedInBusinessFailureAction.type:
      case postBindLinkedInBusinessFailureAction.type:
      case getLinkedInBusinessFailureAction.type:
        draft.error = action.error;
        return draft;
      case postOrganizationsLinkedInBusinessFailureAction.type:
        draft.error = action.error;
        draft.organizations = [];
        return draft;
      case postOrganizationsLinkedInBusinessSuccessAction.type:
        draft.organizations = action.organizations;
        return draft;
      case postUnbindLinkedInBusinessSuccessAction.type:
        draft.settings = null;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindLinkedInBusinessInitAction.type:
      case postUnbindLinkedInBusinessInitAction.type:
      case getLinkedInBusinessInitAction.type:
      case postOrganizationsLinkedInBusinessInitAction.type:
      case putLinkedInBusinessSocialPostInitAction.type:
      case putLinkedInBusinessSocialPostFailureAction.type:
      case putLinkedInBusinessSocialPostSuccessAction.type:
      default:
        return draft;
    }
  }
);
