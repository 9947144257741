import { Conversation } from "../../cores/conversations/interfaces/api/get-conversations";
import { DateFilter } from "../../cores/socialChat/interfaces/date-filter.interface";

export const useFilterDate = (dateFilter: DateFilter) => {
  const filterDateConversation = (conversation: Conversation) => {
    const { from, to } = dateFilter;

    if (!to && from) {
      return new Date(from) <= new Date(conversation.timestamp.full);
    }
    if (!from && to) {
      return new Date(to) >= new Date(conversation.timestamp.full);
    }
    if (to && from) {
      return (
        new Date(conversation.timestamp.full) >= new Date(from) &&
        new Date(conversation.timestamp.full) <= new Date(to)
      );
    } else {
      return conversation;
    }
  };
  return {
    filterDateConversation,
  };
};
