import { produce } from "immer";
import { FacebookForm, FormLead } from "../interfaces/api/get-facebook-form";
import { Paging } from "../../globals/interfaces/paging";
import {
  FacebookFormApiAction,
  getFacebookFormsInitAction,
  getFacebookFormsSuccessAction,
  getFacebookFormsFailureAction,
  getFacebookFormInitAction,
  getFacebookFormSuccessAction,
  getFacebookFormFailureAction,
  setViewAction,
} from "./api-actions";
import { View } from "../constants/view";

export interface FacebookFormState {
  facebookForms: {
    forms: FacebookForm[],
    paging: Paging | null
  };
  facebookForm: {
    form: FacebookForm | null,
    leads: FormLead[],
    paging: Paging | null
  };
  view: string;
}

const initialState: FacebookFormState = {
  facebookForms: {
    forms: [],
    paging: null
  },
  facebookForm: {
    form: null,
    leads: [],
    paging: null
  },
  view: View.GRID,
};

export default produce(
  (draft: FacebookFormState = initialState, action: FacebookFormApiAction) => {
    switch (action.type) {
      case getFacebookFormsSuccessAction.type:
        draft.facebookForms = action;
        return draft;
      case getFacebookFormSuccessAction.type:
        draft.facebookForm = action;
        return draft;
      case setViewAction.type:
        draft.view = action.view;
        return draft;
      case getFacebookFormInitAction.type:
      case getFacebookFormFailureAction.type:
      case getFacebookFormsInitAction.type:
      case getFacebookFormsFailureAction.type:
      default:
        return draft;
    }
  }
);
