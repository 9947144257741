import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { COLOR_DANGER, COLOR_SUCCESS } from "../../../../constants/theme";
import { File } from "../../../../cores/boards/interfaces/api/get-card";
import { UploadFileType } from "../../../../cores/utils";
import DocxPreview from "./DocxPreview";
import ExcelPreview from "./ExcelPreview";
import ImagePreview from "./ImagePreview";
import PdfPreview from "./PdfPreview";
import VideoPreview from "./VideoPreview";

interface Props {
  file: File;
  canModify: boolean;
  onDelete?: (arg0: File) => void;
  onPreview?: (arg0: File) => void;
}

export default function FilePreview(props: Props) {
  const { file, canModify, onDelete, onPreview } = props;

  const previewSelector = useMemo(() => {
    if (file.type === UploadFileType.PDF)
      return <PdfPreview />
    if (file.type === UploadFileType.EXCEL || file.type === UploadFileType.CSV)
      return <ExcelPreview />
    if (file.type === UploadFileType.DOCX)
      return <DocxPreview />
    if (file.type === UploadFileType.MOV || file.type === UploadFileType.MP4)
      return <VideoPreview file={file} />

    return <ImagePreview file={file} />
  }, [file]);

  return (
    <div className="panelFilePreview neu-out m-2 position-relative">
      {previewSelector}
      <div className="fileButtons w-100 h-100">
        <FontAwesomeIcon
          icon={["fas", "eye"]}
          size="2x"
          className="m-2 pointer"
          color={COLOR_SUCCESS}
          onClick={() => onPreview?.(file)}
        />
        {canModify && (
          <FontAwesomeIcon
            icon={["fas", "times"]}
            size="2x"
            className="m-2 pointer"
            color={COLOR_DANGER}
            onClick={() => onDelete?.(file)}
          />
        )}
      </div>
    </div>
  );
}