import React, { useCallback, useEffect, useMemo } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { v4 } from "uuid";
import { WEB_FORMS_LOADING } from "../../constants/loading.constant";
import { COLOR_PRIMARY } from "../../constants/theme";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { useWebFormCore } from "../../cores/webForm";
import { useIntl } from "react-intl";
import { ContactInputType } from "../../cores/webForm/constants/InputType.constant";
import { PositionType } from "../../cores/webForm/constants/PositionType.constant";
import FormCard from "../component/forms/web/FormCard";
import FormNameModal from "../component/forms/web/modal/FormNameModal";
import { NoData } from "../component/NoData";
import { UpgradePlanButton } from "../component/plan/UpgradePlanButton";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useGlobalCore } from "../../cores/globals";
import { MORE_INFO_EDIT_WEB_FORM, WEBFORM_CONFIG } from "../../constants/modal.constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipWembii } from "../component/TooltipWembii";
import { Action } from "../../cores/webForm/constants/Action.constant";
import { Position } from "../../cores/globals/interfaces/globalEntity";
import { YoutubeModal } from "../component/YoutubeModal";

export default function WebForms() {
  const intl = useIntl();
  const { company, companyRole, isAdmin, isReseller, companyPlan } = useMeCore();
  const { turnOnModal, turnOffModal, isModal, getModalVisibility } = useGlobalCore();
  const { webForms, postWebForm, getWebForms, deleteWebForm, copyWebForm } =
    useWebFormCore();
  const { goToEditWebForm, goToSocial } = useRouterCore();

  const openMoreInfo = useCallback(() => {
    turnOnModal(MORE_INFO_EDIT_WEB_FORM, true, Position.LEFT);
  }, [turnOnModal]);

  useEffect(() => {
    if (
      companyPlan?.rules.webFormsAmount === 0 || (
        !companyRole?.forms &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [companyPlan, goToSocial, companyRole, isAdmin, isReseller]);

  useEffect(() => {
    getWebForms(company?.id!);
  }, [company, getWebForms]);

  useEffect(() => {}, [webForms]);

  const toggleSideModal = useCallback(() => {
    turnOnModal(WEBFORM_CONFIG, true);
  }, [turnOnModal]);

  const handleDeleteWebForm = useCallback(
    async (webForm) => {
      await deleteWebForm(webForm.companyId, webForm.id);
      await getWebForms(webForm.companyId);
    },
    [deleteWebForm, getWebForms]
  );

  const handleEditWebForm = useCallback(
    async (webForm) => {
      goToEditWebForm(`${webForm.id}`);
    },
    [goToEditWebForm]
  );

  const handleCopyWebForm = useCallback(
    async (webForm) => {
      await copyWebForm(webForm.companyId, webForm.id);
    },
    [copyWebForm]
  );

  const canCreate = useMemo(
    () => companyPlan && webForms.length < companyPlan?.rules.webFormsAmount,
    [companyPlan, webForms.length]
  );

  const handleCreateWebForm = useCallback(
    async (name) => {
      await postWebForm(
        name,
        "Formulario de Contacto",
        40,
        "#555555",
        COLOR_PRIMARY,
        100,
        PositionType.MIDDLE.id,
        "Enviar",
        "#ffffff",
        15,
        "",
        "Gracias por dejarnos tu mensaje!",
        Action.MESSAGE,
        [
          {
            id: v4(),
            type: ContactInputType.CONTACT_NAME.id,
            placeholder: "Nombre completo",
            required: true,
          },
          {
            id: v4(),
            type: ContactInputType.CONTACT_EMAIL.id,
            placeholder: "Email",
            required: true,
          },
          {
            id: v4(),
            type: ContactInputType.CONTACT_PHONE.id,
            placeholder: "Teléfono",
            required: true,
          },
        ],
        company?.id!
      );
    },
    [postWebForm, company]
  );

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.advertisements.formWebTitle" })}
        rightChildren={
          <>
            <FontAwesomeIcon 
              icon={["fas", "info-circle"]}
              size="2x"
              className="pointer m-2 mr-2"
              color="grey"
              id="info-WebForm"
            />  
            <FontAwesomeIcon 
              icon={["fas", "video"]}
              size="2x"
              className="pointer m-2 mr-3"
              color="grey"
              onClick={openMoreInfo}
            />
            <TooltipWembii 
              id="info-WebForm"
              text={intl.formatMessage({id:  "app.tooltipInfo.webForm"})}
            />
            {!canCreate && <UpgradePlanButton />}
            {canCreate && webForms.length !== 0 && (
              <Button className="whiteButton" onClick={toggleSideModal}>
                {intl.formatMessage({ id: "app.advertisements.formWeb" })} 
              </Button>
            )}
          </>
        }
      />
      <Content>
        <UpdateLoadingCard
          loadingId={WEB_FORMS_LOADING}
          text={intl.formatMessage({ id: "app.advertisements.formWebUpdating" })} 
        />
        {!webForms.length && (
          <NoData
            image="generic1"
            text={intl.formatMessage({ id: "app.advertisements.formWebNotHaveCompany" })} 
            actionLabel={intl.formatMessage({ id: "app.advertisements.formWeb" })} 
            action={toggleSideModal}
            horizontal
          />
        )}
        <Container>
          <Row>
            {webForms.map((webForm) => (
              <Col md="4" sm="6" xs="12" key={webForm.id} className="mb-4">
                <FormCard
                  webForm={webForm}
                  canCreate={canCreate!}
                  onEdit={() => handleEditWebForm(webForm)}
                  onDelete={() => handleDeleteWebForm(webForm)}
                  onCopy={() => handleCopyWebForm(webForm)}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(WEBFORM_CONFIG)}
        visible={getModalVisibility(WEBFORM_CONFIG)}
        onClose={() => turnOffModal(WEBFORM_CONFIG)}
      >
        <FormNameModal
          onSubmit={(name) => {
            handleCreateWebForm(name);
            turnOffModal(WEBFORM_CONFIG);
          }}
        />
      </SideModal>
      <SideModal
        active={isModal(MORE_INFO_EDIT_WEB_FORM)}
        visible={getModalVisibility(MORE_INFO_EDIT_WEB_FORM)}
        onClose={() => turnOffModal(MORE_INFO_EDIT_WEB_FORM)}
        left
      >
        <YoutubeModal title={intl.formatMessage({id: "app.webForm.video"})} videoId="Zu6erGbNoak" />
      </SideModal>
    </Layout>
  );
}
