import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";

import {
  AutomationApiAction,
  postAutomationInitAction,
  postAutomationSuccessAction,
  postAutomationFailureAction,
  getAutomationsInitAction,
  getAutomationsSuccessAction,
  getAutomationsFailureAction,
  deleteAutomationInitAction,
  deleteAutomationSuccessAction,
  deleteAutomationFailureAction,
  putAutomationInitAction,
  putAutomationSuccessAction,
  putAutomationFailureAction,
  getAutomationSuccessAction,
  getAutomationFailureAction,
  getAutomationInitAction,
  postActionInitAction,
  postActionSuccessAction,
  postActionFailureAction,
  putActionInitAction,
  putActionSuccessAction,
  putActionFailureAction,
  deleteActionInitAction,
  deleteActionSuccessAction,
  deleteActionFailureAction,
  postConditionInitAction,
  postConditionSuccessAction,
  postConditionFailureAction,
  putConditionInitAction,
  putConditionSuccessAction,
  putConditionFailureAction,
  deleteConditionInitAction,
  deleteConditionSuccessAction,
  deleteConditionFailureAction,
  postContactInitAction,
  postContactFailureAction,
  deleteContactInitAction,
  deleteContactFailureAction,
  postContactSuccessAction,
  deleteContactSuccessAction,
} from "./api-actions";
import {
  Automation,
  AutomationAction,
  AutomationCondition,
  AutomationContact,
  AutomationUser,
} from "../interfaces/api/get-automation";

export interface AutomationState {
  automations: {
    ids: number[];
    entities: Record<string, Automation>;
  };
  automation: Automation | null;
  actions: {
    ids: number[];
    entities: Record<string, AutomationAction>;
  };
  conditions: {
    ids: number[];
    entities: Record<string, AutomationCondition>;
  };
  contacts: {
    ids: number[];
    entities: Record<string, AutomationContact>;
  };
  automationUsers: {
    ids: number[];
    entities: Record<string, AutomationUser>;
  };
}

const initialState: AutomationState = {
  automations: {
    ids: [],
    entities: {},
  },
  automation: null,
  actions: {
    ids: [],
    entities: {},
  },
  conditions: {
    ids: [],
    entities: {},
  },
  contacts: {
    ids: [],
    entities: {},
  },
  automationUsers: {
    ids: [],
    entities: {},
  },
};

const automationsAdapter = createEntityAdapter<Automation>(
  (payload) => payload.id
);
const automationActionsAdapter = createEntityAdapter<AutomationAction>(
  (payload) => payload.id
);
const automationConditionsAdapter = createEntityAdapter<AutomationCondition>(
  (payload) => payload.id
);
const automationContactAdapter = createEntityAdapter<AutomationContact>(
  (payload) => payload.id
);
const automationUserAdapter = createEntityAdapter<AutomationUser>(
  (payload) => payload.id
);

export default produce(
  (
    draft: AutomationState = initialState,
    action: AutomationApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case getAutomationsSuccessAction.type:
        automationsAdapter.addMany(action.automations, draft.automations);
        return draft;
      case getAutomationSuccessAction.type:
        draft.automation = action.automation;
        automationActionsAdapter.addMany(action.actions, draft.actions);
        automationConditionsAdapter.addMany(
          action.conditions,
          draft.conditions
        );
        automationContactAdapter.addMany(action.contacts, draft.contacts);
        automationUserAdapter.addMany(action.users, draft.automationUsers);
        return draft;
      case postAutomationSuccessAction.type:
        automationsAdapter.setOne(action.automation, draft.automations);
        return draft;
      case putAutomationSuccessAction.type:
        automationsAdapter.updateOne(action.automation, draft.automations);
        return draft;
      case deleteAutomationSuccessAction.type:
        automationsAdapter.removeOne(+action.id, draft.automations);
        return draft;
      case postConditionSuccessAction.type:
        automationConditionsAdapter.setOne(action.condition, draft.conditions);
        return draft;
      case putConditionSuccessAction.type:
        automationConditionsAdapter.updateOne(
          action.condition,
          draft.conditions
        );
        return draft;
      case deleteConditionSuccessAction.type:
        automationConditionsAdapter.removeOne(+action.id, draft.conditions);
        return draft;
      case postActionSuccessAction.type:
        automationActionsAdapter.setOne(action.action, draft.actions);
        return draft;
      case putActionSuccessAction.type:
        automationActionsAdapter.updateOne(action.action, draft.actions);
        return draft;
      case deleteActionSuccessAction.type:
        automationActionsAdapter.removeOne(+action.id, draft.actions);
        return draft;
      case postContactSuccessAction.type:
        automationContactAdapter.setOne(action.contact, draft.contacts);

        return draft;
      case deleteContactSuccessAction.type:
        automationContactAdapter.removeOne(+action.id, draft.contacts);
        return draft;
      case resetAction.type:
        return initialState;
      case postAutomationInitAction.type:
      case postAutomationFailureAction.type:
      case getAutomationsInitAction.type:
      case getAutomationsFailureAction.type:
      case deleteAutomationInitAction.type:
      case deleteAutomationFailureAction.type:
      case putAutomationInitAction.type:
      case putAutomationFailureAction.type:
      case getAutomationInitAction.type:
      case getAutomationFailureAction.type:
      case postActionInitAction.type:
      case postActionFailureAction.type:
      case putActionInitAction.type:
      case putActionFailureAction.type:
      case deleteActionInitAction.type:
      case deleteActionFailureAction.type:
      case postConditionInitAction.type:
      case postConditionFailureAction.type:
      case putConditionInitAction.type:
      case putConditionFailureAction.type:
      case deleteConditionInitAction.type:
      case deleteConditionFailureAction.type:
      case postContactInitAction.type:
      case postContactFailureAction.type:
      case deleteContactInitAction.type:
      case deleteContactFailureAction.type:
      default:
        return draft;
    }
  }
);
