import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import {
  LoadingEntity,
  ModalEntity,
  SidebarGroupEntity,
} from "../interfaces/globalEntity";

export const setModalAction = createAction(
  "[Global] Set Modal",
  props<{ entity: ModalEntity }>()
);

export const deleteModalAction = createAction(
  "[Global] Delete Modal",
  props<{ id: string }>()
);

export const setInternalModalAction = createAction(
  "[Global] Set Internal Modal",
  props<{ entity: ModalEntity }>()
);

export const deleteInternalModalAction = createAction(
  "[Global] Delete Internal Modal",
  props<{ id: string }>()
);

export const setSideBarOpenAction = createAction(
  "[Global] Set SideBar Open",
  props<{ value: boolean }>()
);

export const setSideBarCollapsedAction = createAction(
  "[Global] Set SideBar Collapsed",
  props<{ value: boolean }>()
);

export const setLoadingAction = createAction(
  "[Global] Set Loading",
  props<{ entity: LoadingEntity }>()
);

export const setGlobalLoadingAction = createAction(
  "[Global] Set Global Loading",
  props<{ value: boolean }>()
);

export const setOpenSidebarGroups = createAction(
  "[Global] Set Open Sidebar group",
  props<{ entity: SidebarGroupEntity }>()
);

export const resetAction = createAction("[Global] Reset");

export const globalInitAction = createAction("[Global] Init");

const actions = joinActions({
  setModalAction,
  setOpenSidebarGroups,
  setInternalModalAction,
  setSideBarOpenAction,
  setLoadingAction,
  setGlobalLoadingAction,
  setSideBarCollapsedAction,
  globalInitAction,
  deleteModalAction,
  deleteInternalModalAction,
  resetAction,
});

export type GlobalActions = typeof actions;
