import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { FacebookAccount } from "../interface/api/get-facebook-account";
import { FacebookPage } from "../interface/api/get-facebook-page";
import { FacebookSetting } from "../interface/api/post-facebook-bind";

export const postUnbindFacebookInitAction = createAction(
  "[Facebook] Unbind Facebook Init"
);
export const postUnbindFacebookSuccessAction = createAction(
  "[Facebook] Unbind Facebook Success"
);
export const postUnbindFacebookFailureAction = createAction(
  "[Facebook] Unbind Facebook Failure",
  props<{ error: string }>()
);

export const postBindFacebookInitAction = createAction(
  "[Facebook] Bind Facebook Init"
);
export const postBindFacebookSuccessAction = createAction(
  "[Facebook] Bind Facebook Success",
  props<{ settings: FacebookSetting }>()
);
export const postBindFacebookFailureAction = createAction(
  "[Facebook] Bind Facebook Failure",
  props<{ error: string }>()
);

export const getFacebookInitAction = createAction(
  "[Facebook] Get Facebook Settings Init"
);
export const getFacebookSuccessAction = createAction(
  "[Facebook] Get Facebook Settings Success",
  props<{ settings: FacebookSetting }>()
);
export const getFacebookFailureAction = createAction(
  "[Facebook] Get Facebook Settings Failure",
  props<{ error: string }>()
);

export const getFacebookAccountsInitAction = createAction(
  "[Facebook] Get Facebook Accounts Init"
);
export const getFacebookAccountsSuccessAction = createAction(
  "[Facebook] Get Facebook Accounts Success",
  props<{ accounts: FacebookAccount[] }>()
);
export const getFacebookAccountsFailureAction = createAction(
  "[Facebook] Get Facebook Accounts Failure",
  props<{ error: string }>()
);

export const postFacebookSocialPostInitAction = createAction(
  "[Facebook] Social Post Init"
);
export const postFacebookSocialPostSuccessAction = createAction(
  "[Facebook] Social Post Success"
);
export const postFacebookSocialPostFailureAction = createAction(
  "[Facebook] Social Post Failure",
  props<{ error: string }>()
);

export const putFacebookSocialPostInitAction = createAction(
  "[Facebook] Social Put Init"
);
export const putFacebookSocialPostSuccessAction = createAction(
  "[Facebook] Social Put Success"
);
export const putFacebookSocialPostFailureAction = createAction(
  "[Facebook] Social Put Failure",
  props<{ error: string }>()
);

export const getFacebookPagesInitAction = createAction(
  "[Facebook] Get Facebook Pages Init"
);
export const getFacebookPagesSuccessAction = createAction(
  "[Facebook] Get Facebook Pages Success",
  props<{ pages: FacebookPage[] }>()
);
export const getFacebookPagesFailureAction = createAction(
  "[Facebook] Get Facebook Pages Failure",
  props<{ error: string }>()
);


const actions = joinActions({
  postUnbindFacebookInitAction,
  postUnbindFacebookSuccessAction,
  postUnbindFacebookFailureAction,
  postBindFacebookInitAction,
  postBindFacebookSuccessAction,
  postBindFacebookFailureAction,
  getFacebookInitAction,
  getFacebookSuccessAction,
  getFacebookFailureAction,
  getFacebookAccountsInitAction,
  getFacebookAccountsSuccessAction,
  getFacebookAccountsFailureAction,
  postFacebookSocialPostInitAction,
  postFacebookSocialPostSuccessAction,
  postFacebookSocialPostFailureAction,
  putFacebookSocialPostInitAction,
  putFacebookSocialPostSuccessAction,
  putFacebookSocialPostFailureAction,
  getFacebookPagesInitAction,
  getFacebookPagesSuccessAction,
  getFacebookPagesFailureAction,
});

export type FacebookApiAction = typeof actions;
