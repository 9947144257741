import mainSocketClient from "../../helpers/services/main-socket-client";
import {
  addInstagramMessageAction,
  addFacebookMessageAction,
  addWhatsappMessageAction,
  addWhatsappConversationAction,
  changeSocialConversationStatusAction,
} from "../../socialChat/store/actions";
import {
  addConversationAction,
  addWebMessageAction,
  changeConversationStatusAction,
} from "../../conversations/store/actions";
import { MessageType } from "../constant/messageType.constant";
import { SocketAction } from "../constant/socketAction.constant";
import {
  addNewNotificationAction,
  addOnlineClientAction,
  deleteOnlineClientAction,
} from "../store/actions";
import {
  leaveFromChannelAction,
  sendMessageToChannelAction,
  subscribeToChannelAction,
} from "../store/socket-actions";

export const socketMiddleware = () => {
  return (store: any) => {
    let socket = mainSocketClient();

    socket.on(SocketAction.MESSAGE, (data: any) => {
      switch (data.type) {
        case MessageType.FACEBOOK:
          store.dispatch(
            addFacebookMessageAction({
              message: data.message,
              id: data.id,
            })
          );
          break;
        case MessageType.INSTAGRAM:
          store.dispatch(
            addInstagramMessageAction({
              message: data.message,
              id: data.id,
            })
          );
          break;
        case MessageType.WEB:
          store.dispatch(
            addWebMessageAction({
              message: data.message,
              id: data.id,
            })
          );
          break;
        case MessageType.WHATSAPP:
          store.dispatch(
            addWhatsappMessageAction({
              message: data.message,
              id: data.id,
            })
          );
          break;
      }
    });

    socket.on(SocketAction.CONVERSATION, (data: any) => {
      switch (data.type) {
        case MessageType.WHATSAPP:
          store.dispatch(
            addWhatsappConversationAction({
              conversation: data.conversation,
            })
          );
          break;
        case MessageType.WEB:
          store.dispatch(
            addConversationAction({
              conversation: data.conversation,
            })
          );
          break;
      }
    });

    socket.on(SocketAction.NOTIFICATION, (data: any) => {
      store.dispatch(addNewNotificationAction({ id: data.id }));
    });

    socket.on(SocketAction.ONLINE, (data: any) => {
      store.dispatch(addOnlineClientAction({ id: data.contactId }));
    });

    socket.on(SocketAction.OFFLINE, (id: string) => {
      store.dispatch(deleteOnlineClientAction({ id }));
    });
    socket.on(SocketAction.CONVERSATION_STATUS, (data: any) => {
      if (data.type === "web") {
        store.dispatch(
          changeConversationStatusAction({
            conversation: data,
            conversationStatus: store.getState().socialChat.conversationStatus,
          })
        );
      } else {
        store.dispatch(
          changeSocialConversationStatusAction({
            conversation: data,
          })
        );
      }
    });
    return (next: any) => (action: any) => {
      switch (action.type) {
        case subscribeToChannelAction.type:
          socket.emit(SocketAction.SUBSCRIBE, {
            userId: action.userId,
            conversationId: action.conversationId,
            channel: action.channel,
            companyId: action.companyId,
          });
          return;
        case leaveFromChannelAction.type:
          socket.emit(SocketAction.LEAVE, { channel: action.channel });
          return;
        case sendMessageToChannelAction.type:
          socket.emit(SocketAction.WEB_MESSAGE, {
            ...action.message,
            channel: action.channel,
          });
          return;
        default:
          return next(action);
      }
    };
  };
};
