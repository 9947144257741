import React from "react";
import { ConversationNotification } from "../../../cores/notifications/interfaces/api/get-notifications";
import { getFirstLetters } from "../../utils/stringUtils";
import Avatar from "../Avatar";

interface Props {
  notification: ConversationNotification; 
}

export default function ConversationNotificationItem({ notification }: Props) {
  return (
    <div className="d-flex align-items-center border-bottom mb-1">
      <Avatar label={getFirstLetters(notification.contact.name)} className="mr-1" />
      <div className="d-flex flex-column overflow-hidden">
        <span>Nueva Conversación</span>
        <span className="text-truncate text-muted">
          {notification.contact.email}
        </span>
      </div>
    </div>           
  );
}
