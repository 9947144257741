import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { HISTORY_POST_LOADING } from "../../constants/loading.constant";
import { useRouterCore } from "../../cores/router";
import { useSocialPostCore } from "../../cores/socialPost";
import { NoData } from "../component/NoData";
import { SocialPostPreview } from "../component/socialHistory/preview/SocialPostPreview";
import { SocialHistoryBar } from "../component/socialHistory/SocialHistoryBar";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import { useIntl } from "react-intl";
import { useMeCore } from "../../cores/me";

export default function SocialHistory() {
  const intl = useIntl();
  const { socialPosts } = useSocialPostCore();
  const { goToSocialPost } = useRouterCore();
  const { companyRole, isAdmin, isReseller } = useMeCore();
  const { goToSocial } = useRouterCore();
  
  useEffect(() => {
    if (
      !companyRole?.social &&
      !companyRole?.owner &&
      !companyRole?.admin &&
      !companyRole?.agent &&
      !isAdmin &&
      !isReseller
    ) goToSocial();
  }, [goToSocial, companyRole, isAdmin, isReseller]);

  useEffect(() => {}, [socialPosts]);
  
  return (
    <Layout>
      <Header2 title={intl.formatMessage({ id: "app.sidebar.socialMediaRecord" })} />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <SocialHistoryBar />
              <UpdateLoadingCard
                loadingId={HISTORY_POST_LOADING}
                text={intl.formatMessage({ id: "app.social.updating" })}
              />
              <Container
                fluid
                className="d-flex flex-wrap justify-content-center align-items-baseline"
              >
                {socialPosts.map((post) => (
                  <SocialPostPreview
                    key={post.post.id}
                    provider={post.post.socialProvider}
                    post={post}
                    showError={false}
                  />
                ))}
                {socialPosts.length === 0 && (
                  <NoData
                    image="generic5"
                    text={intl.formatMessage({ id: "app.social.noPost" })}
                    actionLabel={intl.formatMessage({ id: "app.social.post" })}
                    action={() => goToSocialPost("")}
                    horizontal
                  />
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
    </Layout>
  );
}
