import chatApiClient from "../../helpers/services/chat-api-client";
import { GetInstagramConversationsResponse } from "../interfaces/api/get-instagram-conversations";
import { GetInstagramMessagesResponse } from "../interfaces/api/get-instagram-messages";
import { socialChatMapper } from "./mapper";
import {
  PostInstagramMessageRequest,
  PostInstagramMessageResponse,
} from "../interfaces/api/post-instagram-message";
import { GetFacebookConversationsResponse } from "../interfaces/api/get-facebook-conversations";
import { GetFacebookMessagesResponse } from "../interfaces/api/get-facebook-messages";
import {
  PostFacebookMessageRequest,
  PostFacebookMessageResponse,
} from "../interfaces/api/post-facebook-message";
import {
  PostFacebookSeenMessageRequest,
  PostFacebookSeenMessageResponse,
} from "../interfaces/api/post-seen-message";
import { GetWhatsappConversationsResponse } from "../interfaces/api/get-whatsapp-conversations";
import { GetWhatsappMessagesResponse } from "../interfaces/api/get-whatsapp-messages";
import { PostWhatsappMessageResponse } from "../interfaces/api/post-whatsapp-message";
import { WhatsappMessageRequest } from "../interfaces/whatsapp-message-request.interface";
import { ContactStateRequest } from "../../contacts/interfaces/contact-state-request";

export const socialChatApiService = {
  getInstagramConversations(companyId: string, status = "active") {
    return chatApiClient()
      .get<GetInstagramConversationsResponse>(`chat/instagram`, {
        params: { companyId, status },
      })
      .then(({ data }) => socialChatMapper.mapResponseToConversations(data));
  },

  getInstagramMessages(companyId: string, conversationId: string) {
    return chatApiClient()
      .get<GetInstagramMessagesResponse>(
        `chat/instagram/${conversationId}/messages`,
        { params: { companyId } }
      )
      .then(({ data }) => socialChatMapper.mapResponseToMessages(data));
  },
  postInstagramMessage(
    companyId: string,
    conversationId: string,
    recipientId: string,
    message: string
  ) {
    const body: PostInstagramMessageRequest = {
      recipientId,
      message,
    };
    return chatApiClient()
      .post<PostInstagramMessageResponse>(
        `chat/instagram/${conversationId}/message/send`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => socialChatMapper.mapResponseToMessage(data));
  },
  getFacebookConversations(companyId: string, status = "active") {
    return chatApiClient()
      .get<GetFacebookConversationsResponse>(`chat/facebook`, {
        params: { companyId, status },
      })
      .then(({ data }) => socialChatMapper.mapResponseToConversations(data));
  },
  getFacebookMessages(companyId: string, conversationId: string) {
    return chatApiClient()
      .get<GetFacebookMessagesResponse>(
        `chat/facebook/${conversationId}/messages`,
        { params: { companyId } }
      )
      .then(({ data }) => socialChatMapper.mapResponseToMessages(data));
  },
  postFacebookMessage(
    companyId: string,
    conversationId: string,
    recipientId: string,
    message: string
  ) {
    const body: PostFacebookMessageRequest = {
      recipientId,
      message,
    };
    return chatApiClient()
      .post<PostFacebookMessageResponse>(
        `chat/facebook/${conversationId}/message/send`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => socialChatMapper.mapResponseToMessage(data));
  },
  postFacebookSeenMessage(
    companyId: string,
    conversationId: string,
    recipientId: string
  ) {
    const body: PostFacebookSeenMessageRequest = {
      recipientId,
    };
    return chatApiClient()
      .post<PostFacebookSeenMessageResponse>(
        `chat/facebook/${conversationId}/message/seen`,
        body,
        { params: { companyId } }
      )
      .then(({ data }) => data.payload);
  },
  getWhatsappConversations(
    companyId: string,
    contactState: ContactStateRequest = "all"
  ) {
    return chatApiClient()
      .get<GetWhatsappConversationsResponse>(`chat/whatsapp`, {
        params: { companyId, contactState },
      })
      .then(({ data }) => socialChatMapper.mapResponseToConversations(data));
  },

  getWhatsappMessages(companyId: string, conversationId: string) {
    return chatApiClient()
      .get<GetWhatsappMessagesResponse>(
        `chat/whatsapp/${conversationId}/messages`,
        { params: { companyId } }
      )
      .then(({ data }) => socialChatMapper.mapResponseToMessages(data));
  },
  postWhatsappMessage(data: WhatsappMessageRequest) {
    const {
      companyId,
      conversationId,
      recipientId,
      type,
      message,
      attachments,
    } = data;
    const formData = new FormData();

    if (attachments.length > 0) {
      formData.append("files", attachments[0].resource);
    }
    formData.append("recipientId", recipientId.toString());
    formData.append("type", type.toString());
    formData.append("message", message!.toString());

    return chatApiClient()
      .post<PostWhatsappMessageResponse>(
        `chat/whatsapp/${conversationId}/message/send`,
        formData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => socialChatMapper.mapResponseToMessage(data));
  },
};
