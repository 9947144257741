import React from "react";
import { Button } from "reactstrap";
import { FacebookForm } from "../../../../cores/facebookForm/interfaces/api/get-facebook-form";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ViewButtons } from "../../ViewButtons";
import { useFacebookFormCore } from "../../../../cores/facebookForm";

// Todo fix make global form
interface Props {
  onBack: () => void;
  form: FacebookForm;
}

export const ViewFormOptions = (props: Props) => {
  const intl = useIntl();
  const { onBack, form } = props;
  const { view, setView } = useFacebookFormCore();

  return (
    <div className="d-flex justify-content-center align-items-center flex-wrap">
      <Button className="whiteButton my-1 mx-1" onClick={onBack}>
        {intl.formatMessage({ id: "app.facebookForm.questions"})} 
      </Button>

      <Button className="excelButton m-1">
        <a
          target="_blank"
          rel="noopener noreferrer"
          download
          href={`https://www.facebook.com/ads/lead_gen/export_csv/?id=${form?.id}&type=form&source_type=graph_api`}
        >
          <FontAwesomeIcon
            icon={["fas", "file-csv"]}
            size="1x"
            color="white"
            className="mr-1"
          />
          {intl.formatMessage({ id: "app.contacts.export" })} 
        </a>
      </Button>

      <ViewButtons setView={setView} view={view} />
    </div>
  );
};
