import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import cn from "classnames";
import React, { ReactNode, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { NavLink, UncontrolledTooltip } from "reactstrap";
import { useGlobalCore } from "../../../cores/globals";
import { Icon } from "../companies/icons/Icon";

export const NavItemGroup: React.FC<{
  groupId: string;
  children: ReactNode;
  label: string;
  iconName?: IconName;
  iconPrefix?: IconPrefix;
  activeUrls?: string[];
  disabled?: boolean;
  disabledMessage?: string;
}> = ({
  children,
  groupId,
  label,
  iconName,
  iconPrefix,
  activeUrls,
  disabled,
  disabledMessage,
}) => {
  const { pathname } = useLocation();
  const { collapseSideBar, sidebarActiveGroups, setSidebarGroup } =
    useGlobalCore();

  const open = sidebarActiveGroups.entities[groupId] ? true : false;
  const active = useMemo(
    () => activeUrls?.includes(pathname),
    [activeUrls, pathname]
  );

  return (
    <div
      id={label.replace(/\s/g, "")}
      className={cn("pointer mb-2 sidebar-item", {
        "active-group": active,
      })}
    >
      <NavLink
        onClick={() =>
          setSidebarGroup({
            id: groupId,
          })
        }
        className={cn({
          disabled,
          "text-center": collapseSideBar,
        })}
      >
        {iconName && (
          <Icon className="group-icon" name={iconName} prefix={iconPrefix} />
        )}
        {!collapseSideBar && <span className="ml-3 group-label">{label}</span>}
      </NavLink>
      {(open || active) && <div className="pl-3">{children}</div>}
      {disabledMessage && disabled && (
        <UncontrolledTooltip
          placement="right"
          target={label.replace(/\s/g, "")}
        >
          {disabledMessage}
        </UncontrolledTooltip>
      )}
    </div>
  );
};
