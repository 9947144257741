import { Dictionary } from "lodash";

export enum CardStatus {
  Oppened = 0,
  Won = 1,
  Lost = 2,
}

export const  CardStatusLabels: Dictionary<string> = {
  [`${CardStatus.Oppened}`]: "app.opportunity.cardStatus.oppened",
  [`${CardStatus.Won}`]: "app.opportunity.cardStatus.won",
  [`${CardStatus.Lost}`]: "app.opportunity.cardStatus.lost",
};

export const CardStatusOptions = [
  { value: CardStatus.Oppened, label: "app.opportunity.cardStatus.oppened" },
  { value: CardStatus.Won, label: "app.opportunity.cardStatus.won" },
  { value: CardStatus.Lost, label: "app.opportunity.cardStatus.lost" },
];