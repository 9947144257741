import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import moment from "moment";
import { COLOR_DANGER, COLOR_SUCCESS } from "../../../../constants/theme";

interface Props {
  onSubmit: (e: any) => void;
  toggleEdition: () => void;
  date: string;
  className?: string;
}

export default function DateInput(props: Props) {
  const { onSubmit, toggleEdition, date, className } = props;
  const [newDate, setNewDate] = useState<Date>(moment().toDate());

  useEffect(() => {
    if (date) {
      setNewDate(moment(date).toDate());
    }
  }, [date]);

  const onFormSubmit = () => {   
    if (newDate !== moment(date).toDate()) {
      onSubmit(newDate);
    }
  }

  return (
    <div className={cn("w-100 d-flex align-items-center", className)}>
      <DatePicker
        onChange={setNewDate}
        value={newDate}
        format="dd/MM/y"
      />
      <FontAwesomeIcon
        icon={["fas", "check"]}
        size="2x"
        color={COLOR_SUCCESS}
        className="pointer mx-1"
        onClick={onFormSubmit}
      />
      <FontAwesomeIcon
        icon={["fas", "times"]}
        size="2x"
        color={COLOR_DANGER}
        className="pointer mx-1"
        onClick={toggleEdition}
      />
    </div>
  );
}
