import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button, Col, Container, Row } from "reactstrap";
import { DELETE_PRODUCT_FORM, PRODUCT_EXPAND_CREATION_FORM, PRODUCT_EXPAND_EDIT_FORM } from "../../../../constants/modal.constant";
import { useBoardCore } from "../../../../cores/boards";
import { ProductBoard } from "../../../../cores/boards/interfaces/api/get-card";
import { useGlobalCore } from "../../../../cores/globals";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useMeCore } from "../../../../cores/me";
import { useProductCore } from "../../../../cores/products";
import { Product } from "../../../../cores/products/interfaces/api/get-product";
import AreYouSure from "../../AreYouSure";
import { InputWembii } from "../../InputWembii";
import { PaginationWemmbii } from "../../pagination/Pagination";
import CreateProductForm from "../../products/modals/CreateProductForm";
import EditProductForm from "../../products/modals/EditProductForm";

interface Props {
  products: ProductBoard[];
  onAssign: (product: Product) => void;
}

export default function ProductList(props: Props) {
  const intl = useIntl();
  const { company } = useMeCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { card, boardBusiness: board, getCard } = useBoardCore();
  const { products, getProducts, getProduct, deleteProduct } = useProductCore();
  const { products: cardProducts, onAssign } = props;
  const [debounceSearchText, setDebounceSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const debouncedSearch = useRef(
    _.debounce((name) => {
      getProducts(company?.id!, {
        page: 1,
        perPage: 10,
        name,
      });
    }, 1000)
  ).current;

  useEffect(() => {
    debouncedSearch(debounceSearchText);
  }, [debounceSearchText, debouncedSearch]);

  useEffect(() => {
    getProducts(company!.id, {
      page,
      perPage,
      name: debounceSearchText
    });
  }, [company, page, debounceSearchText, perPage, getProducts]);

  const isProductAssigned = useCallback((product) => 
    cardProducts.some((assignment: ProductBoard) =>
      assignment.product === product.id
    )
  , [cardProducts]);

  const onAssigment = useCallback((e, product) => {
    e.stopPropagation();

    onAssign(product);
  }, [onAssign]);

  const onEditing = useCallback((product) => {
    getProduct(company?.id!, product.id);
    turnOnModal(
      PRODUCT_EXPAND_EDIT_FORM,
      <EditProductForm
        onDelete={() => {
          turnOnModal(
            DELETE_PRODUCT_FORM,
            <AreYouSure
              message={`Está seguro de querer eliminar el producto ${product.name}`}
              onSubmit={() => {
                deleteProduct(
                  company?.id!,
                  product.id,
                  () => {
                    getCard(company?.id!, board?.id!, card?.list!, card?.id!);
                  }
                );
                turnOffModal(DELETE_PRODUCT_FORM);
                turnOffModal(PRODUCT_EXPAND_EDIT_FORM);
              }}
              onClose={() => turnOffModal(DELETE_PRODUCT_FORM)}
            />,
            Position.LEFT
          );
        }}
        onUpdate={() => {
          turnOffModal(PRODUCT_EXPAND_EDIT_FORM);
          getCard(company?.id!, board?.id!, card?.list!, card?.id!);
        }}
      />,
      Position.LEFT
    );
  }, [
    deleteProduct,
    getProduct,
    getCard,
    turnOffModal,
    turnOnModal,
    company,
    board,
    card
  ]);

  const onClickCreateProduct = useCallback(() => {
    turnOnModal(
      PRODUCT_EXPAND_CREATION_FORM,
      <CreateProductForm onSuccess={
        () => {
          getProducts(company!.id, {
            page,
            perPage,
            name: debounceSearchText
          });
        }
      } />,
      Position.LEFT
    );
  }, [turnOnModal, getProducts, company, page, perPage, debounceSearchText]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 py-5">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.crmBusiness.products" })}
            </h2>

            <div className="d-flex justify-content-between align-items-center">
              <InputWembii
                type="text"
                placeholder={intl.formatMessage({ id: "app.activity.usersSearch" })}
                rounded
                onChange={setDebounceSearchText}
                mb={false}
                icon={
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="1x"
                    color="grey"
                    className="pointer"
                  />
                }
              />
              <Button
                className="whiteButton shadow m-0"
                onClick={onClickCreateProduct}
              >
                {intl.formatMessage({ id: "app.crmBusiness.product.create" })}
              </Button>
            </div>
            <div className="d-flex justify-content-start justify-content-lg-end">
              <PaginationWemmbii
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                disabledPages={products.length < perPage}
              />
            </div>

            {products.length === 0 && (
              <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center text-muted">
                <FontAwesomeIcon
                  icon={["fas", "shopping-cart"]}
                  size="5x"
                  color="gray"
                />
                {intl.formatMessage({ id: "app.crmBusiness.thereNotProducts" })}
              </div>
            )}

            {products.map((product) => (
              <div
                className="d-flex flex-row my-2 neu-in py-2 px-3 pointer"
                key={product.id}
                onClick={() => onEditing(product)}
              >
                <div className="text-center flex-grow-1 align-items-center d-flex flex-column">
                  <small className="mr-3">{product.name}</small>
                  <small className="text-muted">{product.code}</small>
                </div>
                {!isProductAssigned(product) && (
                  <Button
                    className="ml-auto"
                    color="primary"
                    onClick={(e) => onAssigment(e, product)}
                  >
                    {intl.formatMessage({ id: "app.activity.productAdd" })}
                  </Button>
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
