import QueryString from "query-string";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import {
  BOARD_CARD_LOADING,
  TASKS_EXPORT_LOADING,
  TASKS_LOADING,
} from "../../constants/loading.constant";
import { useTaskCore } from "../../cores/tasks";
import { NoData } from "../component/NoData";
import {
  BOARD_CARD_MODAL,
  TASK_CARD_MODAL,
} from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { useIntl } from "react-intl";
import TaskDetails from "../component/tasks/modal/TaskDetails";
import { useCompanyCore } from "../../cores/company";
import { TaskRow } from "../component/tasks/TaskRow";
import { TasksBar } from "../component/tasks/TasksBar";
import { useLocation } from "react-router-dom";
import TaskSideModals from "../component/tasks/TaskSideModals";
import ContactSideModals from "../component/contacts/ContactSideModals";
import CompanySideModals from "../component/contactCompanies/CompanySideModals";
import BoardSideModals from "../component/boards/BoardSideModals";
import EventSideModals from "../component/event/EventSideModals";
import EmailSideModals from "../component/gmail/EmailSideModals";
import { Errors } from "../../constants/errors";
import { CARD_PRODUCT } from "../../constants/internalModal.constant";
import SideModal from "../layout/SideModal";
import { useBoardCore } from "../../cores/boards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingSpinner } from "../component/LoadingSpinner";
import { CSVLink } from "react-csv";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";
import {
  PriorityOptions,
  TaskStatusOptions,
  TypeOptions,
} from "../../cores/tasks/config";

export const TasksScreen = () => {
  const intl = useIntl();
  const { search } = useLocation();
  const { companyPlan, company, companyRole, isAdmin, isReseller } =
    useMeCore();
  const { getCompany } = useCompanyCore();
  const { cleanCard, errorCode } = useBoardCore();
  const { goToSocial } = useRouterCore();
  const { tasks, total, clearTask, getTasksExport, tasksExport, taskFilters } =
    useTaskCore();
  const {
    isModal,
    turnOffModal,
    turnOnModal,
    getModalVisibility,
    getModalContent,
    isLoading,
    turnOffInternalModal,
  } = useGlobalCore();

  useEffect(() => {
    if (
      !companyPlan?.rules.allowBusinessBoard ||
      (!companyRole?.crm &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  useEffect(() => {
    const { taskId } = QueryString.parse(search);
    if (taskId) {
      turnOnModal(TASK_CARD_MODAL, <TaskDetails task={taskId as string} />);
    }
  }, [search, turnOnModal]);

  useEffect(() => {
    getCompany(company?.id!);
  }, [getCompany, company]);

  const toggleSideModal = useCallback(() => {
    clearTask();
    turnOnModal(TASK_CARD_MODAL, <TaskDetails />);
  }, [turnOnModal, clearTask]);
  const [exportData, setExportData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<{ label: string; key: string }[]>([]);
  const ref = useRef<any>(null);

  const setExportHeaders = () => {
    setHeaders([
      {
        label: intl.formatMessage({ id: "app.crmTasksModal.Task" }),
        key: intl.formatMessage({ id: "app.crmTasksModal.Task" }),
      },
      {
        label: intl.formatMessage({ id: "app.opportunity.status" }),
        key: intl.formatMessage({ id: "app.opportunity.status" }),
      },
      {
        label: intl.formatMessage({ id: "app.crmTasksModal.type" }),
        key: intl.formatMessage({ id: "app.crmTasksModal.type" }),
      },
      {
        label: intl.formatMessage({ id: "app.crmTasksModal.prority" }),
        key: intl.formatMessage({ id: "app.crmTasksModal.prority" }),
      },

      {
        label: intl.formatMessage({ id: "app.crmTasksModal.contact" }),
        key: intl.formatMessage({ id: "app.crmTasksModal.contact" }),
      },
      {
        label: intl.formatMessage({ id: "app.crmTasksModal.assign" }),
        key: intl.formatMessage({ id: "app.crmTasksModal.assign" }),
      },
      {
        label: intl.formatMessage({ id: "app.crmTasksModal.date" }),
        key: intl.formatMessage({ id: "app.crmTasksModal.date" }),
      },
      {
        label: intl.formatMessage({ id: "app.crmTasksModal.assign" }),
        key: intl.formatMessage({ id: "app.crmTasksModal.assign" }),
      },

      {
        label: intl.formatMessage({ id: "app.crmTasksModal.reminder" }),
        key: intl.formatMessage({ id: "app.crmTasksModal.reminder" }),
      },
      {
        label: intl.formatMessage({ id: "app.crmTasksModal.notes" }),
        key: intl.formatMessage({ id: "app.crmTasksModal.notes" }),
      },
      {
        label: intl.formatMessage({ id: "app.contacts.createdAt" }),
        key: intl.formatMessage({ id: "app.contacts.createdAt" }),
      },
    ]);
  };

  const exportTasks = async (extension: "xlsx" | "csv") => {
    if (!isLoading(TASKS_EXPORT_LOADING)) {
      await getTasksExport(company!.id, taskFilters);
      setExportHeaders();
      const rows = tasksExport.map(
        ({
          title,
          type,
          priority,
          contacts,
          user,
          status,
          createdAt,
          description,
          endDate,
          reminderDate,
        }) => ({
          [intl.formatMessage({ id: "app.crmTasksModal.Task" })]: title,
          [intl.formatMessage({ id: "app.opportunity.status" })]:
            TaskStatusOptions[`${status}`].label,
          [intl.formatMessage({ id: "app.crmTasksModal.type" })]:
            TypeOptions[`${type}`].label,
          [intl.formatMessage({ id: "app.crmTasksModal.prority" })]:
            PriorityOptions[`${priority}`].label,
          [intl.formatMessage({ id: "app.crmTasksModal.contact" })]: contacts
            .map((contact) => contact.name)
            .join(","),
          [intl.formatMessage({ id: "app.crmTasksModal.date" })]:
            moment(endDate).format("DD/MM/YYYY HH:mm"),
          [intl.formatMessage({
            id: "app.crmTasksModal.assign",
          })]: `${user.firstName} ${user.lastName}`,
          [intl.formatMessage({ id: "app.crmTasksModal.reminder" })]:
            reminderDate != null
              ? moment(reminderDate).format("DD/MM/YYYY HH:mm")
              : "",
          [intl.formatMessage({ id: "app.crmTasksModal.notes" })]: description,
          [intl.formatMessage({ id: "app.contacts.createdAt" })]: createdAt,
        })
      );
      if (extension === "csv") {
        setExportData(rows);
        ref.current.link.click();
      } else {
        const wb = utils.book_new();
        const ws = utils.json_to_sheet(rows);

        utils.book_append_sheet(wb, ws);
        writeFileXLSX(wb, `tasks_${moment().format("DD-MM-YYYY")}.xlsx`);
      }
    }
  };
  return (
    <Layout>
      <Header2
        title={`${intl.formatMessage({ id: "app.crmTasks.tasks" })} (${total})`}
        rightChildren={
          <>
            <CSVLink
              className="d-none"
              headers={headers}
              filename={`tasks_${moment().format("DD-MM-YYYY")}.csv`}
              data={exportData}
              ref={ref}
            />
            <ButtonGroup className="ml-2">
              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  className="excelButton d-flex align-items-center"
                >
                  <LoadingSpinner id={TASKS_EXPORT_LOADING} />
                  {intl.formatMessage({ id: "app.contacts.export" })}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => exportTasks("csv")}>
                    <FontAwesomeIcon
                      icon={["fas", "file-csv"]}
                      size="1x"
                      color="green"
                      className="mr-2"
                    />
                    CSV
                  </DropdownItem>
                  <DropdownItem onClick={() => exportTasks("xlsx")}>
                    <FontAwesomeIcon
                      icon={["fas", "file-excel"]}
                      size="1x"
                      color="green"
                      className="mr-2"
                    />
                    Excel
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
            <Button className="whiteButton" onClick={toggleSideModal}>
              {intl.formatMessage({ id: "app.crmTasks.CreateTasks" })}
            </Button>
          </>
        }
      />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <TasksBar />
              {isLoading(TASKS_LOADING) ? (
                <UpdateLoadingCard
                  loadingId={TASKS_LOADING}
                  text={intl.formatMessage({
                    id: "app.crmTasks.updatingTasks",
                  })}
                />
              ) : (
                <Container>
                  {tasks.map((task) => (
                    <TaskRow key={task.id} task={task} />
                  ))}
                  {!tasks.length && (
                    <NoData
                      image="generic4"
                      text={intl.formatMessage({
                        id: "app.crmTasks.updatingTasks",
                      })}
                      actionLabel={intl.formatMessage({
                        id: "app.crmTasks.noTasks",
                      })}
                      action={toggleSideModal}
                      horizontal
                    />
                  )}
                </Container>
              )}
            </Col>
          </Row>
        </Container>
      </Content>

      <SideModal
        active={isModal(BOARD_CARD_MODAL)}
        visible={getModalVisibility(BOARD_CARD_MODAL)}
        onClose={() => {
          turnOffModal(BOARD_CARD_MODAL);
          turnOffInternalModal(CARD_PRODUCT);
          cleanCard();
        }}
        showSppiner={isLoading(BOARD_CARD_LOADING)}
        showError={errorCode === Errors.CardLoad}
      >
        {getModalContent(BOARD_CARD_MODAL)}
      </SideModal>

      <TaskSideModals />
      <ContactSideModals />
      <CompanySideModals />
      <BoardSideModals />
      <EventSideModals />
      <EmailSideModals />
    </Layout>
  );
};
