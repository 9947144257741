import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { BOARD_EDIT_CONFIG } from "../../../../constants/modal.constant";
import { useBoardCore } from "../../../../cores/boards";
import { Board } from "../../../../cores/boards/interfaces/api/get-board";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { getFirstLetters } from "../../../utils/stringUtils";
import Avatar from "../../Avatar";
import { TooltipWembii } from "../../TooltipWembii";
import EditBoard from "../modal/EditBoard";

interface Props {
  board: Board;
}

export default function UserBoardContainer({ board }: Props) {
  const intl = useIntl();
  const { turnOnModal } = useGlobalCore();
  const { setBoardUpdate } = useBoardCore();
  const {
    user,
    isAdmin,
    isReseller,
    companyRole
  } = useMeCore();

  const openAssignmentUsers = useCallback(() => {
    setBoardUpdate({
      name: board.name,
      color: board.color,
      currency: board.currency,
      roles: board.userRoles,
      isChanged: false,
    });

    turnOnModal(
      BOARD_EDIT_CONFIG,
      <EditBoard board={board} />
    );
  }, [turnOnModal, setBoardUpdate, board]);

  const canModifyBoard = useMemo(() => 
    isAdmin ||
    isReseller ||
    companyRole?.owner ||
    companyRole?.admin ||
    board.userRoles.find((role) => role.user.id === user?.id)?.owner
  , [isAdmin, isReseller, companyRole, board, user]);
  
  return (
    <div
      className="user-cascade-container d-flex align-items-center justify-content-center justify-content-lg-start flex-wrap position-relative"
      style={{ minHeight: 40 }}
    >
      {board.userRoles.map((userRole, index) => (
        <div
          key={userRole.id}
          className="user-avatar"
          style={{
            "zIndex": index,
            "left": index * 35,
          }}
        >
          <Avatar
            key={index}
            id={`user-${index}`}
            className="text-dark-50 pointer"
            image={userRole.user.avatarUrl}
            label={getFirstLetters(`${userRole.user.lastName} ${userRole.user.firstName}`)}
            icon={
              <FontAwesomeIcon
                icon={["fas", "user"]}
                size="1x"
              />
            }
          />
          <TooltipWembii 
            id={`user-${index}`}
            text={`${userRole.user.lastName} ${userRole.user.firstName}`} 
          />
        </div>
      ))}
      {canModifyBoard && (
        <div
          className="user-avatar d-flex align-items-center whiteButton rounded p-2 pointer"
          style={{
            "zIndex": board.userRoles.length,
            "left": board.userRoles.length * 40,
          }}
          onClick={openAssignmentUsers}
        >
          <FontAwesomeIcon
            className="mr-1"
            icon={["fas", "user-plus"]}
            size="1x"
          />
          {intl.formatMessage({ id: "app.crmBusiness.share" })}
        </div>
      )}
    </div>
  );
}
