import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  TWITTER_GET_LOADING,
  TWITTER_POST_LOADING,
  TWITTER_PROFILES_GET_LOADING,
  TWITTER_SYNC_LOADING
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { Media } from "../socialPost/interfaces/api/get-social-posts";
import { PostImage } from "../socialPost/interfaces/postImage";
import { PostVideo } from "../socialPost/interfaces/postVideo";
import { twitterApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  getTwitterFailureAction,
  getTwitterInitAction,
  getTwitterLoginFailureAction,
  getTwitterLoginInitAction,
  getTwitterLoginSuccessAction,
  getTwitterProfilesInitAction,
  getTwitterProfilesSuccessAction,
  getTwitterSuccessAction,
  postBindTwitterInitAction,
  postBindTwitterSuccessAction,
  postTwitterSocialPostFailureAction,
  postTwitterSocialPostInitAction,
  postTwitterSocialPostSuccessAction,
  postUnbindTwitterFailureAction,
  postUnbindTwitterInitAction,
  postUnbindTwitterSuccessAction,
  putTwitterSocialPostFailureAction,
  putTwitterSocialPostInitAction,
  putTwitterSocialPostSuccessAction
} from "./store/api-actions";
import { loginUrlSelector, profilesSelector, settingSelector } from "./store/selector";
import { Mentions } from "../socialPost/interfaces/Mention";

export function useTwitterCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const loginUrl = useSelector(loginUrlSelector);
  const profiles = useSelector(profilesSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindTwitterInitAction());
        await twitterApiService.unbind(companyId);
        dispatch(postUnbindTwitterSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindTwitterFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      oauthToken: string,
      oauthVerifier: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindTwitterInitAction());
        turnOnLoading(TWITTER_SYNC_LOADING);
        const response = await twitterApiService.bind(companyId, {
          oauthToken,
          oauthVerifier,
        });
        dispatch(postBindTwitterSuccessAction({ settings: response }));
        turnOffLoading(TWITTER_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess26" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TWITTER_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error55" }));
        console.error(error);
        dispatch(postUnbindTwitterFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getTwitterInitAction());
        turnOnLoading(TWITTER_GET_LOADING);
        const response = await twitterApiService.get(companyId);
        dispatch(getTwitterSuccessAction({ settings: response }));
        turnOffLoading(TWITTER_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(TWITTER_GET_LOADING);
        console.error(error);
        dispatch(getTwitterFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const login = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getTwitterLoginInitAction());
        turnOnLoading(TWITTER_SYNC_LOADING);
        const response = await twitterApiService.login(companyId);
        dispatch(getTwitterLoginSuccessAction(response));
        return response.url;
      } catch (error: any) {
        turnOffLoading(TWITTER_SYNC_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error56" }));
        dispatch(getTwitterLoginFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getProfiles = useCallback(
    async (companyId: string, name: string) => {
      try {
        dispatch(getTwitterProfilesInitAction());
        turnOnLoading(TWITTER_PROFILES_GET_LOADING);
        const response = await twitterApiService.getProfiles(companyId, name);
        dispatch(getTwitterProfilesSuccessAction(response));
        turnOffLoading(TWITTER_PROFILES_GET_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(TWITTER_PROFILES_GET_LOADING);
        toast.error(`${intl.formatMessage({ id: "app.toast.error56" })}`);
        dispatch(getTwitterLoginFailureAction({ error }));
      }
    },
    [dispatch, intl, turnOffLoading, turnOnLoading]
  );

  const socialPostVideo = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo,
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(postTwitterSocialPostInitAction());
        turnOnLoading(TWITTER_POST_LOADING);
        await twitterApiService.socialPostVideo(
          text,
          mentions,
          video,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postTwitterSocialPostSuccessAction());
        turnOffLoading(TWITTER_POST_LOADING);
        toast.success(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.sucess27" })
            : intl.formatMessage({ id: "app.toast.sucess28" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TWITTER_POST_LOADING);
        toast.error(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.error57" })
            : intl.formatMessage({ id: "app.toast.error58" })
        );
        console.error(error);
        dispatch(postTwitterSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const socialPost = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(postTwitterSocialPostInitAction());
        turnOnLoading(TWITTER_POST_LOADING);
        await twitterApiService.socialPost(
          text,
          mentions,
          images,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postTwitterSocialPostSuccessAction());
        turnOffLoading(TWITTER_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess27" })
          : intl.formatMessage({ id: "app.toast.sucess28" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TWITTER_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error57" })
          : intl.formatMessage({ id: "app.toast.error58" })
        );
        console.error(error);
        dispatch(postTwitterSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialPost = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(putTwitterSocialPostInitAction());
        turnOnLoading(TWITTER_POST_LOADING);
        await twitterApiService.putSocialPost(
          postId,
          text,
          mentions,
          images,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putTwitterSocialPostSuccessAction());
        turnOffLoading(TWITTER_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess27" })
          : intl.formatMessage({ id: "app.toast.sucess28" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TWITTER_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error57" })
          : intl.formatMessage({ id: "app.toast.error58" })
        );
        console.error(error);
        dispatch(putTwitterSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialPostVideo = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      video: PostVideo | null,
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(putTwitterSocialPostInitAction());
        turnOnLoading(TWITTER_POST_LOADING);
        await twitterApiService.putSocialPostVideo(
          postId,
          text,
          mentions,
          video,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putTwitterSocialPostSuccessAction());
        turnOffLoading(TWITTER_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess27" })
          : intl.formatMessage({ id: "app.toast.sucess28" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(TWITTER_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error57" })
          : intl.formatMessage({ id: "app.toast.error58" })
        );
        console.error(error);
        dispatch(putTwitterSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    loginUrl,
    profiles,
    unbind,
    bind,
    get,
    login,
    socialPost,
    socialPostVideo,
    modifySocialPost,
    modifySocialPostVideo,
    getProfiles,
  };
}
