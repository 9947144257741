import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Order } from "../interface/order";

export const getWooCommerceOrdersInitAction = createAction(
  "[Market] Get WooCommerce Orders Init"
);
export const getWooCommerceOrderSuccessAction = createAction(
  "[Market] Get WooCommerce Orders Success",
  props<{ orders: Order[] }>()
);
export const getWooCommerceOrderFailureAction = createAction(
  "[Market] Get WooCommerce Orders Failure",
  props<{ error: string }>()
);

export const getJumpsellerOrdersInitAction = createAction(
  "[Market] Get Jumpseller Orders Init"
);
export const getJumpsellerOrdersSuccessAction = createAction(
  "[Market] Get Jumpseller Orders Success",
  props<{ orders: Order[] }>()
);
export const getJumpsellerOrdersFailureAction = createAction(
  "[Market] Get Jumpseller Orders Failure",
  props<{ error: string }>()
);

export const getMercadolibreOrdersInitAction = createAction(
  "[Market] Get Mercado Libre Orders Init"
);
export const getMercadolibreOrderSuccessAction = createAction(
  "[Market] Get Mercado Libre Orders Success",
  props<{ orders: Order[] }>()
);
export const getMercadolibreOrderFailureAction = createAction(
  "[Market] Get Mercado Libre Orders Failure",
  props<{ error: string }>()
);

export const putWooCommerceOrderInitAction = createAction(
  "[Market] Put WooCommerce Order Settings Init"
);
export const putWooCommerceOrderSuccessAction = createAction(
  "[Market] Put WooCommerce Order Settings Success",
  props<{ order: Order }>()
);
export const putWooCommerceOrderFailureAction = createAction(
  "[Market] Put WooCommerce Order Settings Failure",
  props<{ error: string }>()
);

export const putJumpsellerOrderInitAction = createAction(
  "[Market] Put Jumpseller Order Settings Init"
);
export const putJumpsellerOrderSuccessAction = createAction(
  "[Market] Put Jumpseller Order Settings Success",
  props<{ order: Order }>()
);
export const putJumpsellerOrderFailureAction = createAction(
  "[Market] Put Jumpseller Order Settings Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getWooCommerceOrderSuccessAction,
  getWooCommerceOrdersInitAction,
  getWooCommerceOrderFailureAction,
  getMercadolibreOrdersInitAction,
  getMercadolibreOrderSuccessAction,
  getMercadolibreOrderFailureAction,
  getJumpsellerOrdersInitAction,
  getJumpsellerOrdersSuccessAction,
  getJumpsellerOrdersFailureAction,
  putWooCommerceOrderInitAction,
  putWooCommerceOrderSuccessAction,
  putWooCommerceOrderFailureAction,
  putJumpsellerOrderInitAction,
  putJumpsellerOrderSuccessAction,
  putJumpsellerOrderFailureAction,
});

export type MarketApiAction = typeof actions;
