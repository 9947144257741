import React, { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMeCore } from "../../../../cores/me";
import { AutomationAction } from "../../../../cores/automations/interfaces/api/get-automation";
import { useAutomationCore } from "../../../../cores/automations";
import { LabelWembii } from "../../LabelWembii";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { BOARD_USERS_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import Avatar from "../../Avatar";
import { User } from "../../../../cores/session/interfaces/models/user";
import { TooltipWembii } from "../../TooltipWembii";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import UserList from "../../boards/modal/UserList";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { getFirstLetters } from "../../../utils/stringUtils";
import { AssignmentActionBody } from "../../../../cores/automations/interfaces/AssignmentAction";

interface Props {
  action: AutomationAction | null;
  type: string;
  onSuccess: () => void;
}

export default function CascadeAssignmentActionForm(props: Props) { 
  const intl = useIntl();
  const { onSuccess, action, type } = props;
  const { company } = useMeCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();
  const { postAction, putAction, deleteAction, automation } = useAutomationCore();

  const [assignments, setAssignments] = useState<User[]>([]);

  useEffect(() => {
    if (action) {
      setAssignments((action.body as AssignmentActionBody).assignments);
    }
  }, [action]);

  const onSubmit = () => {
    const newAssignments = assignments.map((user) => user.id);

    if (action) {
      putAction(
        company?.id!,
        automation?.id!,
        action.id,
        { type, body: { assignments: newAssignments } },
        onSuccess
      );
    } else {
      postAction(
        company?.id!,
        automation?.id!,
        { type, body: { assignments: newAssignments } },
        onSuccess
      );
    }
  };

  const disabled = useCallback(
    () => !type || !assignments.length,
    [type, assignments]
  );

  const onDelete = useCallback(() => {
    deleteAction(
      company?.id!,
      automation?.id!,
      action?.id!,
      onSuccess
    );
  }, [company, action, automation, deleteAction, onSuccess]);

  const openAssignmentUsers = useCallback(() => {
    turnOnModal(
      BOARD_USERS_MODAL,
      <UserList assignments={assignments} onAssign={(user) => {
        setAssignments([...assignments, user]);
        turnOffModal(BOARD_USERS_MODAL);
      }} />,
      Position.LEFT
    );
  }, [
    turnOnModal,
    turnOffModal,
    assignments
  ]);

  const onDeleteAssignment = useCallback((removeUser) => {
    setAssignments(assignments.filter((user) => user.id !== removeUser.id));
  }, [assignments]);

  const getUserName = (user: User) => {
    let text = `${user.firstName} ${user.lastName}`;
    if (user.status !== UserStatus.ACTIVE.id) {
      text = `${text} - No activo`
    }

    return text;
  };

  return (
    <>
      <LabelWembii className="font-weight-bold">
        {intl.formatMessage({ id: "app.activity.toAssign" })}
      </LabelWembii>
      <div className="d-flex flex-wrap">
        {assignments.map((assignment) => (
          <div key={assignment.id}>
            <Avatar
              id={`user-modal-${assignment.id}`}
              active={assignment.status === UserStatus.ACTIVE.id}
              className="text-dark-50 font-weight-bold pointer m-1"
              label={getFirstLetters(`${assignment.lastName} ${assignment.firstName}`)}
              image={assignment.avatarUrl}
              onClick={() => onDeleteAssignment(assignment)}
            />
            <TooltipWembii id={`user-modal-${assignment.id}`} text={getUserName(assignment)} />
          </div>
        ))}
        <Avatar
          className="text-dark-50 pointer m-1"
          onClick={openAssignmentUsers}
          icon={
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              size="1x"
              color={COLOR_PRIMARY}
            />
          }
        />
      </div>

      {action && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.automations.automation.delete" })}  
        </Button>
      )}
      <Button color="primary" disabled={disabled()} className="ml-1" onClick={onSubmit}>
        {action
          ? intl.formatMessage({ id: "app.automations.automation.update" })
          : intl.formatMessage({ id: "app.automations.automation.create" })
        }  
      </Button>
    </>
  );
}