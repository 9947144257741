import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { SmtpSetting } from "../interface/api/post-smtp-bind";
import {
  getSmtpFailureAction,
  getSmtpInitAction,
  getSmtpSuccessAction,
  SmtpApiAction,
  postBindSmtpFailureAction,
  postBindSmtpInitAction,
  postBindSmtpSuccessAction,
  postUnbindSmtpFailureAction,
  postUnbindSmtpInitAction,
  postUnbindSmtpSuccessAction,
  getSmtpConnectionFailureAction,
  getSmtpConnectionInitAction,
} from "./api-actions";

export interface SmtpState {
  settings: SmtpSetting | null;
  userSettings: SmtpSetting | null;
  errorCode: number | null;
  errorMessage: string | null;
}

const initialState: SmtpState = {
  settings: null,
  userSettings: null,
  errorCode: null,
  errorMessage: null,
};

export default produce(
  (
    draft: SmtpState = initialState,
    action: SmtpApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindSmtpSuccessAction.type:
      case getSmtpSuccessAction.type:
        if (action.asUser) {
          draft.userSettings = action.settings;
        } else {
          draft.settings = action.settings;
        }
        return draft;
      case getSmtpConnectionInitAction.type:
        draft.errorCode = null;
        draft.errorMessage = null;
        return draft;
      case postUnbindSmtpSuccessAction.type:
        if (action.asUser) {
          draft.userSettings = null;
        } else {
          draft.settings = null;
        }
        return draft;
      case getSmtpConnectionFailureAction.type:
        draft.errorCode = action.errorCode;
        draft.errorMessage = action.errorMessage;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindSmtpFailureAction.type:
      case postUnbindSmtpFailureAction.type:
      case getSmtpFailureAction.type:
      case postBindSmtpInitAction.type:
      case postUnbindSmtpInitAction.type:
      case getSmtpInitAction.type:
      default:
        return draft;
    }
  }
);
