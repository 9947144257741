import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { BOARD_CARD_LOADING } from "../../constants/loading.constant";
import {
  BOARD_CARD_MODAL,
  CHAT_FILTERS_MODAL,
  CONTACT_EXPAND_EDIT_FORM,
  EMOJI_FORM,
  TEMPLATE_CHAT_FORM,
} from "../../constants/modal.constant";
import { useContactCore } from "../../cores/contacts";
import { useGlobalCore } from "../../cores/globals";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import { useWebChatCore } from "../../cores/conversations";
import PanelChatTitle from "../component/chat/PanelChatTitle";
import WebChatForm from "../component/chat/web/WebChatForm";
import SocialChatForm from "../component/chat/social/SocialChatForm";
import EditContactForm from "../component/contacts/modal/EditContactForm";
import { NoData } from "../component/NoData";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import TaskSideModals from "../component/tasks/TaskSideModals";
import ContactSideModals from "../component/contacts/ContactSideModals";
import CompanySideModals from "../component/contactCompanies/CompanySideModals";
import EventSideModals from "../component/event/EventSideModals";
import EmailSideModals from "../component/gmail/EmailSideModals";
import BoardSideModals from "../component/boards/BoardSideModals";
import { useConfigCore } from "../../cores/config";
import { Errors } from "../../constants/errors";
import { CARD_PRODUCT } from "../../constants/internalModal.constant";
import { useBoardCore } from "../../cores/boards";
import { ConversationList } from "../component/chat/ConversationList";
import { Position } from "../../cores/globals/interfaces/globalEntity";
import TemplateChatForm from "../component/chat/modals/TemplateChatForm";
import { useTemplateCore } from "../../cores/templates";
import { useSocialChatCore } from "../../cores/socialChat/index";
import ChatBarFilter from "../component/chat/chatBarFilter/ChatBarFilter";
import { Conversations } from "../../cores/socialChat/interfaces/conversations-group.interface";
import { SOCIAL_CHAT_FILTERS_MODAL } from "../../constants/modal.constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatFilterModal from "../component/chat/modals/ChatFilterModal";
import { useTaskCore } from "../../cores/tasks";
import { InputWembii } from "../component/InputWembii";

export default function WebChat() {
  const {
    company,
    companyPlan,
    companyRole,
    isAdmin,
    isReseller,
    socialSettings,
  } = useMeCore();
  const {
    selected,
    getConversations,
    selectWebConversation,
    conversations: webConversations,
  } = useWebChatCore();

  const {
    conversationStatus,
    selectedSocial,
    facebookMessages,
    instagramConversations,
    facebookConversations,
    whatsappConversations,
    facebookSelected,
    instagramMessages,
    instagramSelected,
    whatsappMessages,
    whatsappSelected,
    selectFacebookConversation,
    selectInstagramConversation,
    selectWhatsappConversation,
    getFacebookConversations,
    getInstagramConversations,
    getWhatsappConversations,
    getFacebookMessages,
    postFacebookMessage,
    postFacebookSeenMessage,
    getInstagramMessages,
    postInstagramMessage,
    getWhatsappMessages,
    postWhatsappMessage,
  } = useSocialChatCore();

  const { getCommentTypes } = useConfigCore();
  const { goToSocial } = useRouterCore();
  const { getBusinessBoards, cleanCard, errorCode } = useBoardCore();
  const {
    deleteContacts,
    getContactTags,
    getContactStates,
    getContact,
    contactStateRequest,
  } = useContactCore();
  const { getTasksByContact } = useTaskCore();
  const { getChatTemplates } = useTemplateCore();
  const [filter, setFilter] = useState("");
  const openFiltersModal = () =>
    turnOnModal(CHAT_FILTERS_MODAL, true, Position.RIGHT);

  const getAllConversations = useCallback(() => {
    const active = [
      ...webConversations.active,
      ...instagramConversations.active,
      ...facebookConversations.active,
      ...whatsappConversations.active,
    ];
    const archived = [
      ...webConversations.archived,
      ...instagramConversations.archived,
      ...facebookConversations.archived,
      ...whatsappConversations.archived,
    ];
    const inTrash = [
      ...webConversations.inTrash,
      ...instagramConversations.inTrash,
      ...facebookConversations.inTrash,
      ...whatsappConversations.inTrash,
    ];
    return {
      active,
      archived,
      inTrash,
    };
  }, [
    facebookConversations,
    webConversations,
    instagramConversations,
    whatsappConversations,
  ]);

  const conversations: Conversations = useMemo(() => {
    return {
      instagram: instagramConversations,
      facebook: facebookConversations,
      whatsapp: whatsappConversations,
      web: webConversations,
      all: getAllConversations(),
    };
  }, [
    facebookConversations,
    webConversations,
    instagramConversations,
    whatsappConversations,
    getAllConversations,
  ]);
  const {
    isModal,
    turnOffModal,
    turnOnModal,
    getModalVisibility,
    getModalContent,
    isLoading,
    turnOffInternalModal,
  } = useGlobalCore();
  useEffect(() => {
    if (
      !companyPlan?.rules.allowWebChat ||
      (!companyRole?.chat &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller)
    )
      goToSocial();
  }, [companyPlan, companyRole, isAdmin, isReseller, goToSocial]);

  useEffect(() => {
    getContactStates(company?.id!);
  }, [company?.id, getContactStates]);

  useEffect(() => {
    getChatTemplates(company?.id!);
  }, [company?.id, getChatTemplates]);

  useEffect(() => {
    switch (selectedSocial) {
      case "all":
        getConversations(company?.id!, contactStateRequest);
        if (socialSettings?.facebook) {
          getFacebookConversations(company?.id!);
        }
        if (socialSettings?.whatsapp) {
          getWhatsappConversations(company?.id!, contactStateRequest);
        }
        if (socialSettings?.instagramMensseger) {
          getInstagramConversations(company?.id!);
        }
        break;
      case "instagram":
        if (socialSettings?.instagramMensseger) {
          getInstagramConversations(company?.id!);
        }
        break;
      case "facebook":
        if (socialSettings?.facebook) {
          getFacebookConversations(company?.id!);
        }
        break;
      case "whatsapp":
        if (socialSettings?.whatsapp) {
          getWhatsappConversations(company?.id!, contactStateRequest);
        }
        break;
      default:
        getConversations(company?.id!, contactStateRequest);
        break;
    }
  }, [
    getConversations,
    getWhatsappConversations,
    getFacebookConversations,
    getInstagramConversations,
    company?.id,
    conversationStatus,
    contactStateRequest,
    socialSettings?.instagram,
    socialSettings?.whatsapp,
    socialSettings?.facebook,
    socialSettings?.instagramMensseger,
    selectedSocial,
  ]);

  const onClickAvatar = useCallback(
    (contact) => {
      getContactTags(company?.id!);
      getCommentTypes(company?.id!);
      getContactStates(company?.id!);
      getBusinessBoards(company?.id!);
      getContact(company?.id!, contact.id);
      getTasksByContact(company?.id!, { contactId: contact.id });
      turnOnModal(
        CONTACT_EXPAND_EDIT_FORM,
        <EditContactForm
          closeOnUpdating={false}
          onDelete={async () => {
            await deleteContacts(company?.id!, [contact.id]);
            await getConversations(company?.id!, contactStateRequest);

            turnOffModal(CONTACT_EXPAND_EDIT_FORM);
          }}
          onUpdate={async () => {
            await getConversations(company?.id!, contactStateRequest);
            if (socialSettings?.whatsapp) {
              await getWhatsappConversations(company?.id!, contactStateRequest);
            }
            selectWebConversation(selected?.id!);
            selectWebConversation(selected?.id!);
          }}
        />,
        Position.LEFT
      );
    },
    [
      company,
      selected,
      turnOnModal,
      getTasksByContact,
      getWhatsappConversations,
      socialSettings?.whatsapp,
      contactStateRequest,
      getCommentTypes,
      turnOffModal,
      getConversations,
      deleteContacts,
      getContactTags,
      selectWebConversation,
      getBusinessBoards,
      getContactStates,
      getContact,
    ]
  );
  const chatForm = useMemo(() => {
    if (selected) return <WebChatForm />;
    if (facebookSelected)
      return (
        <SocialChatForm
          selected={facebookSelected}
          messages={facebookMessages}
          getMessages={getFacebookMessages}
          postMessage={({ companyId, conversationId, recipientId, content }) =>
            postFacebookMessage(companyId, conversationId, recipientId, content)
          }
          postSeenMessage={postFacebookSeenMessage}
        />
      );
    if (instagramSelected)
      return (
        <SocialChatForm
          selected={instagramSelected}
          messages={instagramMessages}
          getMessages={getInstagramMessages}
          postMessage={(props) =>
            postInstagramMessage(
              props.companyId,
              props.conversationId,
              props.recipientId,
              props.content
            )
          }
        />
      );
    if (whatsappSelected)
      return (
        <SocialChatForm
          selected={whatsappSelected}
          messages={whatsappMessages}
          getMessages={getWhatsappMessages}
          postMessage={(props) =>
            postWhatsappMessage(
              props.companyId,
              props.conversationId,
              props.recipientId,
              props.content,
              props.type,
              props.attachments
            )
          }
        />
      );
  }, [
    selected,
    instagramMessages,
    instagramSelected,
    facebookMessages,
    facebookSelected,
    whatsappMessages,
    whatsappSelected,
    getFacebookMessages,
    postFacebookMessage,
    postFacebookSeenMessage,
    getInstagramMessages,
    postInstagramMessage,
    getWhatsappMessages,
    postWhatsappMessage,
  ]);

  const onSelect = useCallback(
    (conversation) => {
      if (conversation.type === "web") {
        selectWebConversation(conversation.id);
        selectFacebookConversation(null);
        selectInstagramConversation(null);
        selectWhatsappConversation(null);
      }
      if (conversation.type === "facebook") {
        selectFacebookConversation(conversation.id);
        selectWebConversation(null);
        selectInstagramConversation(null);
        selectWhatsappConversation(null);
      }
      if (conversation.type === "instagram") {
        selectInstagramConversation(conversation.id);
        selectFacebookConversation(null);
        selectWhatsappConversation(null);
        selectWebConversation(null);
      }
      if (conversation.type === "whatsapp") {
        selectWhatsappConversation(conversation.id);
        selectWebConversation(null);
        selectFacebookConversation(null);
        selectInstagramConversation(null);
      }
    },
    [
      selectWebConversation,
      selectFacebookConversation,
      selectInstagramConversation,
      selectWhatsappConversation,
    ]
  );

  const selectedConv = useMemo(
    () => selected || facebookSelected || instagramSelected || whatsappSelected,
    [selected, facebookSelected, instagramSelected, whatsappSelected]
  );

  return (
    <Layout>
      <Content>
        <Container
          className="bg-light shadow-sm h-100 w-100"
          style={{ borderRadius: "2rem" }}
          fluid
        >
          {/* <Header2
            title={intl.formatMessage({
              id: "app.chat.WembSiteTitle",
            })}
          /> */}

          <Row className="justify-content-between ">
            <Col xs={{ size: "12", order: 2 }} sm={{ size: "6", order: 1 }}>
              <div
                style={{ zIndex: 999 }}
                className="w-auto  my-2 mx-auto  py-2 rounded-pill d-flex justify-content-between"
              >
                <InputWembii
                  type="text"
                  placeholder="Búsqueda"
                  rounded
                  showShadow
                  className="mx-auto my-auto w-100"
                  style={{ borderRadius: "2rem" }}
                  onChange={(v) => setFilter(v)}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "search"]}
                      size="1x"
                      color="grey"
                      className="pointer"
                    />
                  }
                />
                <button
                  className="btn rounded-pill p-2 mx-2 shadow-lg border-none bg-primary text-light md-hidden"
                  onClick={openFiltersModal}
                >
                  {" "}
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={["fas", "filter"]}
                    size="lg"
                    color={conversationStatus !== "active" ? "grey" : ""}
                  />
                </button>
              </div>
              <ConversationList
                filter={filter}
                conversations={conversations}
                onSelect={(conversation: string) => onSelect(conversation)}
              />
            </Col>
            <Col
              xs={{ size: "12", order: 1 }}
              sm={{ size: "6", order: 2 }}
              className="overflow-auto"
            >
              <Container className="sticky-top p-0 z-index-50 ">
                {!selectedConv ? (
                  <NoData
                    image="generic2"
                    text="Selecciona un contacto para comenzar la conversación"
                    horizontal
                  />
                ) : (
                  <main
                    className="py-2 d-flex flex-column justify-content-between"
                    style={{ borderRadius: "10px" }}
                  >
                    <PanelChatTitle
                      companyId={company?.id!}
                      color="#d0d0d0d0"
                      type={selectedConv.type}
                      contacts={selectedConv.participants}
                      avatarAction={onClickAvatar}
                    />
                    <div className="overflow-auto container">{chatForm}</div>
                  </main>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>

      <TaskSideModals />
      <ContactSideModals />
      <CompanySideModals />
      <BoardSideModals />
      <EventSideModals />
      <EmailSideModals />

      <SideModal
        active={isModal(BOARD_CARD_MODAL)}
        visible={getModalVisibility(BOARD_CARD_MODAL)}
        onClose={() => {
          turnOffModal(BOARD_CARD_MODAL);
          turnOffInternalModal(CARD_PRODUCT);
          cleanCard();
        }}
        showSppiner={isLoading(BOARD_CARD_LOADING)}
        showError={errorCode === Errors.CardLoad}
      >
        {getModalContent(BOARD_CARD_MODAL)}
      </SideModal>
      <SideModal
        active={isModal(TEMPLATE_CHAT_FORM)}
        visible={getModalVisibility(TEMPLATE_CHAT_FORM)}
        onClose={() => {
          turnOffModal(TEMPLATE_CHAT_FORM);
        }}
        left
      >
        <TemplateChatForm />
      </SideModal>
      <SideModal
        active={isModal(SOCIAL_CHAT_FILTERS_MODAL)}
        visible={getModalVisibility(SOCIAL_CHAT_FILTERS_MODAL)}
        onClose={() => {
          turnOffModal(SOCIAL_CHAT_FILTERS_MODAL);
        }}
        left={false}
      >
        <ChatBarFilter conversations={conversations} />
      </SideModal>
      <SideModal
        active={isModal(EMOJI_FORM)}
        visible={getModalVisibility(EMOJI_FORM)}
        onClose={() => turnOffModal(EMOJI_FORM)}
      >
        {getModalContent(EMOJI_FORM)}
      </SideModal>
      <SideModal
        active={isModal(CHAT_FILTERS_MODAL)}
        visible={getModalVisibility(CHAT_FILTERS_MODAL)}
        onClose={() => turnOffModal(CHAT_FILTERS_MODAL)}
      >
        <ChatFilterModal conversations={conversations} />
      </SideModal>
    </Layout>
  );
}
