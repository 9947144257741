import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Card } from "../interface/api/get-board-cards";
import { Metric } from "../interface/api/get-insights";
import { BoardAnalyticsAction, setDateAction, setHasMoreAction } from "./actions";
import {
  BoardAnalyticsApiAction,
  getBoardCardsFailureAction,
  getBoardCardsInitAction,
  getBoardCardsSuccessAction,
  getCrmInsightFailureAction,
  getCrmInsightInitAction,
  getCrmInsightSuccessAction
} from "./api-actions";

export interface BoardAnalyticsState {
  crmInsights: {
    ids: string[];
    entities: Record<string, Metric>;
  };
  cards: {
    ids: string[];
    entities: Record<string, Card>;
  };
  hasMore: boolean;
  date: string;
  offset: number;
}

const initialState: BoardAnalyticsState = {
  crmInsights: {
    ids: [],
    entities: {}
  },
  cards: {
    ids: [],
    entities: {},
  },
  hasMore: false,
  date: "",
  offset: 0,
};

const insightAdapter = createEntityAdapter<Metric>((insight) => insight.name);
const cardsAdapter = createEntityAdapter<Card>((payload) => payload.id);

export default produce(
  (
    draft: BoardAnalyticsState = initialState,
    action: BoardAnalyticsApiAction | BoardAnalyticsAction | GlobalActions
  ) => {
    switch (action.type) {
      case getCrmInsightSuccessAction.type:
        insightAdapter.updateMany(action.insights, draft.crmInsights);
        return draft;
      case getBoardCardsSuccessAction.type:
        action.cards.forEach((card) => {
          cardsAdapter.setOne(card, draft.cards);
        });
        draft.offset = draft.cards.ids.length;
        if (action.cards.length === 0) {
          draft.hasMore = false;
        } else {
          draft.hasMore = true;  
        }

        return draft;
      case setDateAction.type:
        draft.date = action.date;
        draft.offset = 0;
        cardsAdapter.removeAll(draft.cards);
        return draft;
      case setHasMoreAction.type:
        draft.hasMore = action.hasMore;
        return draft;
      case resetAction.type:
        return initialState;
      case getCrmInsightInitAction.type:
      case getCrmInsightFailureAction.type:
      case getBoardCardsInitAction.type:
      case getBoardCardsFailureAction.type:
      default:
        return draft;
    }
  }
);
