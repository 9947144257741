import React from "react";
import Icofont from "react-icofont";
import { CardBody, CardTitle } from "reactstrap";
import { WebForm } from "../../../../cores/webForm/interfaces/api/get-web-form";
import { PanelWembii } from "../../PanelWembii";

interface Props {
  onEdit: () => void;
  onDelete: () => void;
  onCopy: () => void;
  canCreate: boolean;
  webForm: WebForm;
}

export default function FormCard(props: Props) {
  const { webForm, canCreate, onEdit, onDelete, onCopy } = props;

  return (
    <PanelWembii
      shadow
      key={webForm.id}
      className="align-content-center border-0 d-flex justify-content-center p-3"
    >
      <CardBody className="p-2 d-flex flex-column">
        <CardTitle>{webForm.name}</CardTitle>
        <div className="d-flex ml-auto">
          <div onClick={onEdit}>
            <Icofont className="ml-2 pointer" icon="edit" />
          </div>
          {canCreate && (
            <div onClick={onCopy}>
              <Icofont className="ml-2 pointer" icon="copy" />
            </div>
          )}
          <div onClick={onDelete}>
            <Icofont className="ml-2 pointer" icon="ui-delete" />
          </div>
        </div>
      </CardBody>
    </PanelWembii>
  );
}
