import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { HISTORY_POST_LOADING } from "../../constants/loading.constant";
import { POST_MODAL } from "../../constants/modal.constant";
import { useGlobalCore } from "../../cores/globals";
import { useSocialPostCore } from "../../cores/socialPost";
import { Calendar } from "../component/socialCalendar/Calendar";
import { SocialCalendarBar } from "../component/socialCalendar/SocialCalendarBar";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import SideModal from "../layout/SideModal";
import { useIntl } from "react-intl";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";

export default function SocialCalendar() {
  const intl = useIntl();
  const { socialPosts } = useSocialPostCore();
  const { isModal, turnOffModal, getModalContent, getModalVisibility } = useGlobalCore();
  const { companyRole, isAdmin, isReseller } = useMeCore();
  const { goToSocial } = useRouterCore();
  
  useEffect(() => {
    if (
      !companyRole?.social &&
      !companyRole?.owner &&
      !companyRole?.admin &&
      !companyRole?.agent &&
      !isAdmin &&
      !isReseller
    ) goToSocial();
  }, [goToSocial, companyRole, isAdmin, isReseller]);

  useEffect(() => {}, [socialPosts]);
 
  return (
    <Layout>
      <Header2 
        title={intl.formatMessage({ id: "app.sidebar.socialMediaCalendar" })}
      />
      <Content>
        <Container fluid>
          <Row>
            <Col xs="12">
              <SocialCalendarBar />
              <UpdateLoadingCard
                loadingId={HISTORY_POST_LOADING}
                text="Actualizando Publicaciones"
              />
              <Container fluid className="d-flex flex-wrap justify-content-center align-items-baseline">
                <Calendar />
              </Container>
            </Col>
          </Row>
        </Container>
      </Content>
      <SideModal
        active={isModal(POST_MODAL)}
        visible={getModalVisibility(POST_MODAL)}
        onClose={() => turnOffModal(POST_MODAL)}
      >
        {getModalContent(POST_MODAL)}
      </SideModal>
    </Layout>
  );
}
