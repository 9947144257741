import React, { createContext, ReactChild, useState } from "react";
import { IntlProvider } from "react-intl";

import Portuguese from "./lang/pt.json";
import Spanish from "./lang/es.json";
import English from "./lang/en.json";
import { LOCALE } from "./constants/locale.constant";

export const Context = createContext({ locale: LOCALE.SPANISH, selectLanguage: (_language: string) => {} });

const LocaleWrapper = (props: { children: ReactChild }) => {
  const [locale, setLocale] = useState(LOCALE.SPANISH);
  const [messages, setMessages] = useState(Spanish);

  const selectLanguage = (language: string) => {
    setLocale(language);

    if (language === LOCALE.ENGLISH) {
      setMessages(English);
    } else {
      if (language === LOCALE.PORTUGUESE){
        setMessages(Portuguese);
      } else {
        setMessages(Spanish);
      }
    }
  }
  
  return (
    <Context.Provider value = {{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
}

export default LocaleWrapper;