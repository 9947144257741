import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import { COLOR_DANGER } from "../../../constants/theme";
import { useAdvertisementCore } from "../../../cores/advertisements";
import { FormFields } from "../../../cores/advertisements/interfaces/AdsForm";
import { InputWembii } from "../InputWembii";
import { TooltipWembii } from "../TooltipWembii";

export default function CustomQuestions() {
  const intl = useIntl();
  const { campaign, setCampaign } = useAdvertisementCore();
  const [question, setQuestion] = useState("");

  const handleQuestionConfigChange = useCallback(
    () => {
      if (question !== "") {
        setCampaign({
          ...campaign,
          form: { 
            ...campaign.form,
            customQuestions: [
              ...campaign.form.customQuestions,
              { type: FormFields.custom.key, label: question }
            ]
          }
        });
        setQuestion("");
      }
    },
    [campaign, question, setCampaign]
  );

  const handleRemoveQuestions = useCallback(
    (label) => {
      const questions = campaign.form.customQuestions.filter((question) => 
        question.label !== label
      );
      setCampaign({
        ...campaign,
        form: { 
          ...campaign.form,
          customQuestions: questions
        }
      });
    },
    [campaign, setCampaign]
  );

  return (
    <Row>
      <Col sm="12">
        <InputWembii
          type="text"
          name="question"
          className="my-2"
          id="question"
          label={intl.formatMessage({ id: "app.advertisements.question" })}
          flat
          required
          value={question}
          onChange={setQuestion}
          icon={     
            <div  className="mb-4 mr-3">         
              <FontAwesomeIcon 
                icon={["fas", "info-circle"]}
                size="1x"
                className="position-absolute border-none"
                id="campaign-question"
                color="grey"
              />
              <TooltipWembii 
                id="campaign-question"
                text={intl.formatMessage({ id: "app.tooltopAds29" })} 
              />
            </div>  
          }
        />
        <Button className="my-2" onClick={handleQuestionConfigChange}>
          <FontAwesomeIcon
            icon={["fas", "plus"]}
            className="mr-1"
          />
          Agregar pregunta
        </Button>
        <div className="my-3 overflow-auto" style={{ height: 300 }}>
          {campaign.form.customQuestions.map((question) => (
            <div
              key={question.type}
              className="d-flex justify-content-between align-items-center p-2 border rounded"
            >
              {question.label}
              <FontAwesomeIcon
                icon={["fas", "trash"]}
                className="ml-2 pointer"
                color={COLOR_DANGER}
                onClick={() => handleRemoveQuestions(question.label)}
              />
            </div>
          ))}

          {campaign.form.customQuestions.length === 0 && (
            <div className="d-flex justify-content-center align-items-center h-100 text-muted">
              No hay preguntas agregadas
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}
