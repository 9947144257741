import moment from "moment";
import React, { useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import { useAnalyticsCore } from "../../../cores/analytics";
import { Metric2 } from "../../../cores/analytics/interface/Metric2";
import { CameFrom } from "./enum/CameFrom";
import { useIntl } from "react-intl";

interface Props {
  metric: Metric2;
  cameFrom: CameFrom;
}

export const AnalyticsExpand = (props: Props) => {
  const intl = useIntl();
  const {
    metric: {
      title,
      description,
      total,
      maxValue,
      minValue,
      valueFormatter
    },
    cameFrom
  } = props;
  const { date } = useAnalyticsCore();

  const minLabel = useMemo(() => 
    (moment(minValue?.label, "MMMM Do YYYY").isValid()) ?
      `(${intl.formatMessage({ id: "app.reports.min" })}
        - ${moment(minValue?.label, "MMMM Do YYYY").format("DD/MM/YYYY")})` :
        `(${intl.formatMessage({ id: "app.reports.min" })})`
  , [minValue?.label, intl]);

  const maxLabel = useMemo(() => 
    (moment(maxValue?.label, "MMMM Do YYYY").isValid()) ?
      `(${intl.formatMessage({ id: "app.reports.max" })}
       - ${moment(maxValue?.label, "MMMM Do YYYY").format("DD/MM/YYYY")})` :
       `(${intl.formatMessage({ id: "app.reports.max" })})`
  , [maxValue?.label, intl]);   

  return (
    <Container className="h-100" fluid>
      <Row className="h-100 d-flex flex-column h-100 align-items-center p-4">
        <Col xs="12">
          <div className="d-flex flex-column h-100 align-items-center p-4">
            <h2 className="h2 text-center mb-4">{title}</h2>
            <div className="px-2 my-3">
              <div className="my-3 text-center">
                <div className="font-weight-bolder h2 text-primary">
                 {valueFormatter ? valueFormatter(total.value) : total.value}
                </div>
                <div className="small">{`(en ${(total.samples)} días)`}</div>
              </div>

              <div className="my-3 text-center d-flex flex-row py-2">
                <div className="w-50 text-secondary">
                  <div className="font-weight-bolder h2">
                    {valueFormatter
                      ? valueFormatter(maxValue?.value!)
                      : maxValue?.value
                    }
                  </div>
                  <div className="small">
                    {maxLabel}
                  </div>
                </div>
                <div className="w-50 text-secondary">
                  <div className="font-weight-bolder h2 danger">
                    {valueFormatter
                      ? valueFormatter(minValue?.value!)
                      : minValue?.value
                    }
                  </div>
                  <div className="small">
                    {minLabel}
                  </div>
                </div>
              </div>
              { description && <div className="my-3 text-center">
                <div className="font-weight-bolder h5"> 
                 {intl.formatMessage({ id: "app.reports.descript" })} 
                </div>
                {description}
              </div> }
              {cameFrom === CameFrom.analytics && (
                <div className="my-3 text-center">
                  <div className="font-weight-bolder h5">
                    {intl.formatMessage({ id: "app.reports.period" })}  
                  </div>
                  <span className="font-weight-bolder h5"> 
                  {intl.formatMessage({ id: "app.reports.from" })} 
                  </span>
                  {moment(date.since).format("DD/MM/YYYY")}
                  <span className="font-weight-bolder h5">
                   {intl.formatMessage({ id: "app.reports.to" })}  
                  </span>
                  {moment(date.until).format("DD/MM/YYYY")} 
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
