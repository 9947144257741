import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { Office365Setting } from "../interface/api/post-office365-bind";
import {
  getOffice365FailureAction,
  getOffice365InitAction,
  getOffice365SuccessAction,
  Office365ApiAction,
  postBindOffice365FailureAction,
  postBindOffice365InitAction,
  postBindOffice365SuccessAction,
  postUnbindOffice365FailureAction,
  postUnbindOffice365InitAction,
  postUnbindOffice365SuccessAction,
  postOffice365EmailInitAction,
  postOffice365EmailFailureAction,
  postOffice365EmailSuccessAction,
} from "./api-actions";

export interface Office365State {
  settings: Office365Setting | null;
  userSettings: Office365Setting | null;
  error: string;
}

const initialState: Office365State = {
  settings: null,
  userSettings: null,
  error: "",
};

export default produce(
  (
    draft: Office365State = initialState,
    action: Office365ApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindOffice365SuccessAction.type:
      case getOffice365SuccessAction.type:
        if (action.asUser) {
          draft.userSettings = action.settings;
        } else {
          draft.settings = action.settings;
        }
        return draft;
      case postUnbindOffice365SuccessAction.type:
        if (action.asUser) {
          draft.userSettings = null;
        } else {
          draft.settings = null;
        }
        return draft;
      case resetAction.type:
        return initialState;
      case postBindOffice365FailureAction.type:
      case postUnbindOffice365FailureAction.type:
      case getOffice365FailureAction.type:
      case postBindOffice365InitAction.type:
      case postUnbindOffice365InitAction.type:
      case getOffice365InitAction.type:
      case postOffice365EmailInitAction.type:
      case postOffice365EmailFailureAction.type:
      case postOffice365EmailSuccessAction.type:
      default:
        return draft;
    }
  }
);
