import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Product } from "../interfaces/api/get-product";

export const getProductsInitAction = createAction(
  "[Product] Get Products Init"
);
export const getProductsSuccessAction = createAction(
  "[Product] Get Products Success",
  props< { products: Product[] } >()
);
export const getProductsFailureAction = createAction(
  "[Product] Get Products Failure",
  props<{ error: string }>()
);

export const getProductInitAction = createAction(
  "[Product] Get Product Init"
);
export const getProductSuccessAction = createAction(
  "[Product] Get Product Success",
  props< { product: Product } >()
);
export const getProductFailureAction = createAction(
  "[Product] Get Product Failure",
  props<{ error: string }>()
);

export const postProductInitAction = createAction(
  "[Product] Post Product Init"
);
export const postProductSuccessAction = createAction(
  "[Product] Post Product Success",
  props< { product: Product } >()
);
export const postProductFailureAction = createAction(
  "[Product] Post Product Failure",
  props<{ error: string }>()
);

export const putProductInitAction = createAction(
  "[Product] Put Product Init"
);
export const putProductSuccessAction = createAction(
  "[Product] Put Product Success",
  props< { product: Product } >()
);
export const putProductFailureAction = createAction(
  "[Product] Put Product Failure",
  props<{ error: string }>()
);

export const deleteProductInitAction = createAction(
  "[Product] Delete Product Init"
);
export const deleteProductSuccessAction = createAction(
  "[Product] Delete Product Success",
  props< { id: string } >()
);
export const deleteProductFailureAction = createAction(
  "[Product] Delete Product Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postProductInitAction,
  postProductSuccessAction,
  postProductFailureAction,
  getProductsInitAction,
  getProductsSuccessAction,
  getProductsFailureAction,
  deleteProductInitAction,
  deleteProductSuccessAction,
  deleteProductFailureAction,
  putProductInitAction,
  putProductSuccessAction,
  putProductFailureAction,
  getProductInitAction,
  getProductSuccessAction,
  getProductFailureAction,
});

export type ProductApiAction = typeof actions;
