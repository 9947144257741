import chatApiClient from "../../helpers/services/chat-api-client";
import { blobFromDataURI } from "../../utils";
import { GetChatResponse } from "../interfaces/api/get-web-chat";
import { GetConversationsResponse } from "../interfaces/api/get-conversations";
import { GetMessagesResponse } from "../interfaces/api/get-messages";
import { PostAreaRequest, PostAreaResponse } from "../interfaces/api/post-area";
import { PutAreaRequest, PutAreaResponse } from "../interfaces/api/put-area";
import {
  PutChatRequest,
  PutChatResponse,
} from "../interfaces/api/put-web-chat";
import { chatMapper } from "./mapper";
import { Avatar } from "../interfaces/avatar";
import { ContactStateRequest } from "../../contacts/interfaces/contact-state-request";
import {
  ConversationStatusRequest,
  UpdateConversationStatusResponse,
} from "../interfaces/api/update-conversations";

export const webChatApiService = {
  getChat(companyId: string) {
    return chatApiClient()
      .get<GetChatResponse>(`chat/web/config`, { params: { companyId } })
      .then(({ data }) => chatMapper.mapResponseToChat(data));
  },
  putChat(
    companyId: string,
    chatId: string,
    title: string,
    subtitle: string,
    titleColor: string,
    backgroundColor: string,
    startButtonColor: string,
    startChat: boolean,
    openButtonColor: string,
    openButtonIconColor: string,
    welcomeMessage: string,
    commercialTime: string,
    offline: boolean,
    avatar: string | null,
    isActive: boolean
  ) {
    const body: PutChatRequest = {
      title,
      subtitle,
      titleColor,
      backgroundColor,
      startButtonColor,
      startChat,
      openButtonColor,
      openButtonIconColor,
      welcomeMessage,
      commercialTime,
      offline,
      avatar,
      isActive,
    };
    return chatApiClient()
      .put<PutChatResponse>(`chat/web/config/${chatId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => chatMapper.mapResponseToChatConfig(data));
  },
  postChatAvatar(companyId: string, chatId: string, avatar: Avatar) {
    let bodyFormData = new FormData();
    bodyFormData.append("images", blobFromDataURI(avatar.data));

    return chatApiClient()
      .post<PutChatResponse>(`chat/web/config/${chatId}/avatar`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => chatMapper.mapResponseToChatConfig(data));
  },
  postArea(companyId: string, chatId: string, name: string, users: number[]) {
    const body: PostAreaRequest = {
      name,
      users,
      chatId,
    };
    return chatApiClient()
      .post<PostAreaResponse>(`chat/web/config/${chatId}/area`, body, {
        params: { companyId },
      })
      .then(({ data }) => chatMapper.mapResponseToArea(data));
  },
  putArea(
    companyId: string,
    chatId: string,
    areaId: string,
    name: string,
    users: number[]
  ) {
    const body: PutAreaRequest = {
      name,
      users,
    };
    return chatApiClient()
      .put<PutAreaResponse>(`chat/web/config/${chatId}/area/${areaId}`, body, {
        params: { companyId },
      })
      .then(({ data }) => chatMapper.mapResponseToArea(data));
  },
  deleteArea(companyId: string, chatId: string, areaId: string) {
    return chatApiClient()
      .delete(`chat/web/config/${chatId}/area/${areaId}`, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
  getConversations(companyId: string, contactState: ContactStateRequest) {
    return chatApiClient()
      .get<GetConversationsResponse>(`chat/web`, {
        params: {
          companyId,
          contactState,
        },
      })
      .then(({ data }) => chatMapper.mapResponseToConversations(data));
  },

  updateConversationStatus({
    companyId,
    conversationId,
    socialName,
    status,
  }: ConversationStatusRequest) {
    return chatApiClient()
      .post<UpdateConversationStatusResponse>(
        `chat/conversationStatus?companyId=${companyId}&conversationId=${conversationId}&socialName=${socialName}&status=${status}`,
        {
          params: { companyId, status },
        }
      )
      .then(({ data }) => chatMapper.mapResponseToConversationsStatus(data));
  },
  deleteConversation(companyId: string, conversationId: string) {
    return chatApiClient()
      .delete(`chat/web/${conversationId}`, { params: { companyId } })
      .then(({ data }) => data.payload);
  },
  getMessages(companyId: string, conversationId: string) {
    return chatApiClient()
      .get<GetMessagesResponse>(`chat/web/${conversationId}/messages`, {
        params: { companyId },
      })
      .then(({ data }) => chatMapper.mapResponseToMessages(data));
  },
};
