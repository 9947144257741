import React, { useCallback } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { ANALYTICS_EXPAND } from "../../../constants/modal.constant";
import { CHART_THEME } from "../../../constants/theme";
import { Metric2 } from "../../../cores/analytics/interface/Metric2";
import { useGlobalCore } from "../../../cores/globals";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import { AnalyticsExpand } from "./AnalyticsExpand";
import { CameFrom } from "./enum/CameFrom";

interface Props {
  metric: Metric2;
  cameFrom?: CameFrom;
}

export const Legend = (props: Props) => {
  const { metric, cameFrom = CameFrom.analytics } = props;

  const { turnOnModal } = useGlobalCore();
  const getColor = useCallback(
    (index: number) => CHART_THEME[index % CHART_THEME.length],
    []
  );

  const handleOnClick = useCallback(() => {
    turnOnModal(
      ANALYTICS_EXPAND,
      <AnalyticsExpand metric={metric} cameFrom={cameFrom} />,
      Position.LEFT
    );
  }, [metric, cameFrom, turnOnModal]);

  return metric.values ? (
    <ListGroup>
      {metric.values.map(({ label, value }, index) => (
        <ListGroupItem
          key={label}
          className="d-flex align-items-center border-right-0 border-left-0 border-top-0 mx-2 pointer"
          onClick={handleOnClick}
        >
          <div
            style={{ backgroundColor: getColor(index) }}
            className="dot-10"
          />
          <span> {label} </span>
          <span className="ml-auto">
              {metric.valueFormatter 
                ? metric.valueFormatter(value) 
                : value
              }
          </span>
        </ListGroupItem>
      ))}
    </ListGroup>
  ) : null;
};
