import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import { COLOR_DANGER } from "../../../constants/theme";
import { useAdvertisementCore } from "../../../cores/advertisements";
import { FormFields } from "../../../cores/advertisements/interfaces/AdsForm";
import { InputWembii } from "../InputWembii";
import { TooltipWembii } from "../TooltipWembii";

export default function AutoQuestions() {
  const intl = useIntl();
  const { campaign, setCampaign } = useAdvertisementCore();  
  const [menu] = useState([
    { label: "Datos del contacto", icon: "comments", children: [
      FormFields.email,
      FormFields.phoneNumber,
      FormFields.streetAddress,
      FormFields.city,
      FormFields.state,
      FormFields.province,
      FormFields.country,
      FormFields.postCode,
      FormFields.zipCode
    ] },
    { label: "Información del usuario", icon: "user", children: [
      FormFields.fullName,
      FormFields.firstName,
      FormFields.lastName
    ] },
    { label: "Datos demográficos", icon: "users", children: [
      FormFields.dateOfBirth,
      FormFields.gender,
      FormFields.maritalStatus,
      FormFields.relationshipStatus,
      FormFields.militaryStatus
    ] },
    { label: "Información laboral", icon: "briefcase", children: [
      FormFields.jobTitle,
      FormFields.workPhoneNumber,
      FormFields.workEmail,
      FormFields.companyName
    ] },
    { label: "Número de indentificación nacional", icon: "globe", children: [
      FormFields.cpfBrazil,
      FormFields.dniArgentina,
      FormFields.dniPeru,
      FormFields.rutChile,
      FormFields.ccColombia,
      FormFields.ciEcuador
    ] }
  ]);

  const handleFormConfigChange = useCallback(
    (field) => {
      setCampaign({ ...campaign, form: { ...campaign.form, ...field } });
    },
    [campaign, setCampaign]
  );

  const handleQuestionConfigChange = useCallback(
    (type) => {
      setCampaign({
        ...campaign,
        form: { 
          ...campaign.form,
          autoQuestions: [
            ...campaign.form.autoQuestions,
            { type }
          ]
        }
      });
    },
    [campaign, setCampaign]
  );

  const handleRemoveQuestions = useCallback(
    (type) => {
      const questions = campaign.form.autoQuestions.filter((question) => 
        question.type !== type
      );
      setCampaign({
        ...campaign,
        form: { 
          ...campaign.form,
          autoQuestions: questions
        }
      });
    },
    [campaign, setCampaign]
  );

  const disabledOption = useCallback(
    (type) => {
      return campaign.form.autoQuestions.some((question) => question.type === type)
    },
    [campaign]
  );

  const getQuestion = (value: string) =>
    Object.values(FormFields).find((type) => type.key === value)?.label;

  return (
    <Row>
      <Col sm="12">
        <Label className="text-muted text-center mt-2">
          Solicita información del usuario. Se completará automáticamente con la información de su cuenta de Facebook. 
        </Label>
        <InputWembii
          type="textarea"
          name="questionDescription"
          id="questionDescription"
          label={intl.formatMessage({ id: "app.advertisements.closingDescription" })}
          flat
          value={campaign.form.questionPageCustomHeadline}
          onChange={(v) => handleFormConfigChange({ questionPageCustomHeadline: v })}
          icon={     
            <div  className="mb-4 mr-3">         
              <FontAwesomeIcon 
                icon={["fas", "info-circle"]}
                size="1x"
                className="position-absolute border-none"
                id="campaign-questiosnCompleteAutomatic"
                color="grey"
              />
              <TooltipWembii 
                id="campaign-questiosnCompleteAutomatic"
                text={intl.formatMessage({ id: "app.tooltopAds31" })} 
              />
            </div>  
          }
        />

        <UncontrolledDropdown>
          <DropdownToggle>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={["fas", "plus"]}
                className="mr-1"
              />
              Agregar categoría
              <FontAwesomeIcon
                icon={["fas", "caret-down"]}
                className="ml-2"
              />
            </div>
          </DropdownToggle>
          <DropdownMenu
            modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: (data) => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: 'auto',
                      maxHeight: '200px',
                    },
                  };
                },
              },
            }}
          >
            {menu.map((option, index) => (
              <div key={index}>
                <DropdownItem header>
                  <div className="d-flex">
                    <FontAwesomeIcon
                      icon={["fas", option.icon as IconName]}
                      className="mr-1"
                    />
                    {option.label}
                  </div>
                </DropdownItem>
                {option.children.map((children) => (
                  <DropdownItem
                    key={children.key}
                    disabled={disabledOption(children.key)}
                    onClick={() => handleQuestionConfigChange(children.key)}
                  >
                    {children.label}
                  </DropdownItem>
                ))}
              </div>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <div className="my-3 overflow-auto" style={{ height: 250 }}>
          {campaign.form.autoQuestions.map((question) => (
            <div
              key={question.type}
              className="d-flex justify-content-between align-items-center p-2 border rounded"
            >
              {getQuestion(question.type)}
              <FontAwesomeIcon
                icon={["fas", "trash"]}
                className="ml-2 pointer"
                color={COLOR_DANGER}
                onClick={() => handleRemoveQuestions(question.type)}
              />
            </div>
          ))}

          {campaign.form.autoQuestions.length === 0 && (
            <div className="d-flex justify-content-center align-items-center h-100 text-muted">
              No hay preguntas agregadas
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}
