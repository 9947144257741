import cn from "classnames";
import React, { useState } from "react";
import { Badge } from "reactstrap";
import { Reseller } from "../../../cores/reseller/interfaces/api/get-resellers";

interface Props {
  reseller: Reseller;
  show?: boolean;
}

export const ResellerBadge = (props: Props) => {
  const {
    reseller: { name },
    show,
  } = props;
  const [isShown, setIsShown] = useState(false);
  return (
    <Badge
      onMouseEnter={() => !show && setIsShown(true)}
      onMouseLeave={() => !show && setIsShown(false)}
      key={`tooltip-${name}`}
      id={`tooltip-${name}`}
      pill
      style={{
        minWidth: "30px",
        minHeight: "30px",
        backgroundColor: "#14A",
      }}
    >
      <h5
        className={cn("m-0", { h5: !isShown, "h6 px-2": isShown || show })}
        style={{ lineHeight: "25px" }}
      >
        {isShown || show ? name : name[0]}
      </h5>
    </Badge>
  );
};
