export const formTypes = [
  { key: 0, label: "Más volumen" },
  { key: 1, label: "Mayor grado de intención" }
];

export const closingButtonTypes = [
  { key: 'VIEW_WEBSITE', label: "Ver sitio web" },
  { key: 'DOWNLOAD', label: "Descargar" }
];

export const FormFields = {
  custom: { label: "Custom", key: "CUSTOM" },
  email: { label: "Email", key: "EMAIL" },
  phoneNumber: { label: "Phone number", key: "PHONE" },
  streetAddress: { label: "Street address", key: "STREET_ADDRESS" },
  city: { label: "City", key: "CITY" },
  state: { label: "State", key: "STATE" },
  province: { label: "Province", key: "PROVINCE" },
  country: { label: "Country", key: "COUNTRY" },
  postCode: { label: "Post Cod", key: "POST_CODE" },
  zipCode: { label: "Zip Code", key: "ZIP" },
  fullName: { label: "Full Name", key: "FULL_NAME" },
  firstName: { label: "First Name", key: "FIRST_NAME" },
  lastName: { label: "Last Name", key: "LAST_NAME" },
  dateOfBirth: { label: "Date of birth", key: "DOB" },
  gender: { label: "Gender", key: "GENDER" },
  maritalStatus: { label: "Marital status", key: "MARITAL_STATUS" },
  relationshipStatus: { label: "Relationship status", key: "RELATIONSHIP_STATUS" },
  militaryStatus: { label: "Military status", key: "MILITARY_STATUS" },
  jobTitle: { label: "Job title", key: "JOB_TITLE" },
  workPhoneNumber: { label: "Work phone number", key: "WORK_PHONE_NUMBER" },
  workEmail: { label: "Work email", key: "WORK_EMAIL" },
  companyName: { label: "Company name", key: "COMPANY_NAME" },
  cpfBrazil: { label: "CPF (Brazil)", key: "ID_CPF" },
  dniArgentina: { label: "DNI (Argentina)", key: "ID_AR_DNI" },
  dniPeru: { label: "DNI (Peru)", key: "ID_PE_DNI" },
  rutChile: { label: "RUT (Chile)", key: "ID_CL_RUT" },
  ccColombia: { label: "CC (Colombia)", key: "ID_CO_CC" },
  ciEcuador: { label: "CI (Ecuador)", key: "ID_EC_CI" },
};
