import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useCompanyCore } from "../../../../cores/company";
import { CompanyPayload } from "../../../../cores/company/interfaces/api/get-companies";
import { useRouterCore } from "../../../../cores/router";
import { PlanBadge } from "../../companies/PlanBadge";
import { RoleBadges } from "../../companies/RoleBadge";
import { PanelWembii } from "../../PanelWembii";

interface Props {
  id: string;
}

export default function UserCompanies(props: Props) {
  const { id } = props;

  const [companies, setCompanies] = useState<CompanyPayload[]>([]);
  const { getUserCompanies } = useCompanyCore();

  useEffect(() => {
    async function fetchData() {
      const companies = await getUserCompanies(id);
      companies && setCompanies(companies);
    }
    fetchData();
  }, [getUserCompanies, id]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column mt-4 py-4 px-2">
            <h2 className="h2 text-center mb-4">
              <FontAwesomeIcon
                icon={["fas", "building"]}
                className="mx-2"
                size="1x"
              />
              Empresas
            </h2>
            <div className="mb-2 d-flex justify-content-center flex-column align-items-center ">
              {companies.map((company) => (
                <CompanyRow key={company.company.id} id={id} company={company} />
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export const CompanyRow = ({
  company,
  id,
}: {
  company: CompanyPayload;
  id: string;
}) => {
  const { goToEditCompany } = useRouterCore();

  return (
    <PanelWembii
      className="w-100 px-4 py-3 my-2 pointer"
      shadow
      onClick={() => goToEditCompany(company.company.id)}
    >
      <Row className="d-flex align-items-center justify-content-center">
        <Col md="5">
          <h6>
            # {company.company.id} {company.company.name}
          </h6>
          {company.plan && <PlanBadge show plan={company.plan} />}
        </Col>
        <Col md="7">
          <div className="d-flex justify-content-end">
            {company.role && <RoleBadges roles={company.role} id={id} />}
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
