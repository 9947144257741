import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import {
  MERCADOLIBRE_GET_LOADING,
  MERCADOLIBRE_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { useMercadolibreCore } from "../../../../cores/mercadolibre";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import SocialCard from "../SocialCard";
import { MercadolibreIcon } from "./MercadolibreIcon";
import { MERCADO_COUNTRIES } from "../../../../constants/modal.constant";

export default function MercadolibreBindCard() {
  const intl = useIntl();
  const { get, settings, unbind, login } = useMercadolibreCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { isLoading, turnOnModal } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get, login]);

  const isActive = useMemo((): boolean => !_.isEmpty(settings), [settings]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Mercadolibre,
      name: SocialProviderName.Mercadolibre,
      icon: <MercadolibreIcon size="6x" active={isActive} />,
      displayName: settings?.name,
      url: settings?.url,
      avatar: settings?.avatarUrl,
    }),
    [isActive, settings]
  );

  const showCountries = useCallback(async () => {
    turnOnModal(MERCADO_COUNTRIES, true);
  }, [turnOnModal]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, () => {
      getMeSocialSettings(company?.id!);
    });
  }, [company, unbind, getMeSocialSettings]);

  return (
    <SocialCard
      social={socialProvider}
      bindLoading={isLoading(MERCADOLIBRE_SYNC_LOADING)}
      getLoading={isLoading(MERCADOLIBRE_GET_LOADING)}
      showSynchronizer={false}
      remainingBindDays={null}
    >
      <Button
        className="w-100 mx-auto"
        color="primary"
        outline={isActive}
        onClick={!isActive ? showCountries : handleUnbind}
      >
        {!isActive
          ? intl.formatMessage({ id: "app.home.syncUp" })
          : intl.formatMessage({ id: "app.home.disconnect" })}
      </Button>
    </SocialCard>
  );
}
