import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useState } from "react"
import { Button, Col, Container, Label, Row } from "reactstrap"
import { useMeCore } from "../../../../cores/me"
import { useGlobalCore } from "../../../../cores/globals"
import { useIntl } from "react-intl";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";
import { BOARD_IMPORT } from "../../../../constants/modal.constant"
import { useBoardCore } from "../../../../cores/boards"
import { InputWembii } from "../../InputWembii"
import { BOARD_IMPORT_LOADING } from "../../../../constants/loading.constant"

export default function Import() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { isLoading, turnOffModal } = useGlobalCore();
  const { postImport, getBusinessBoards } = useBoardCore();

  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState('');

  const handleSelectFile = (files: FileList | null) => {
    if (files?.length) {
      setFile(files[0]);
    }
  }

  const onClickImportContacts = useCallback(() => {
    postImport(company?.id!, name, file!, () => {
      turnOffModal(BOARD_IMPORT);
      getBusinessBoards(company?.id!);
    });
  }, [company, name, file, postImport, turnOffModal, getBusinessBoards]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.board.import" })}
            </h2>
            <div className="d-flex flex-column align-items-center">
              <Label>
                {intl.formatMessage({ id: "app.contacts.downloadExample" })}
              </Label>
              <Button className="excelButton m-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  href="https://asset.wembii.ar/template/board-template.csv"
                >
                  {intl.formatMessage({ id: "app.contacts.download" })}
                </a>
              </Button>
            </div>
            <hr className="horizontal-separator mt-2 mb-4"/>
            <div className="d-flex flex-column align-items-center">
              <Label className="text-center">
                {intl.formatMessage({ id: "app.board.import.subtitle" })}
              </Label>

              <Label
                for="csvUploader"
                style={{ height: 200 }}
                className="pointer border rounded w-100 d-flex flex-column justify-content-center align-items-center m-2 mb-3"
              >
                <FontAwesomeIcon
                  icon={["fas", "file-csv"]}
                  size="2x"
                  color={file ? "" : "gray"}
                  className="mb-1"
                />
                <span className="text-muted" style={{ fontSize: 12 }}>
                  {!file ? intl.formatMessage({ id: "app.contacts.selectFile" }) : intl.formatMessage({ id: "app.contacts.changeFile" })}
                </span>
              </Label>

              <InputWembii
                type="text"
                name="name"
                id="name"
                className="w-100"
                label={intl.formatMessage({ id: "app.activity.boardName" })}
                value={name}
                required
                onChange={setName}
              />

              <input
                type="file"
                id="csvUploader"
                className="d-none"
                accept=".csv"
                onChange={(e) => handleSelectFile(e.target.files)}
              />

              {isLoading(BOARD_IMPORT_LOADING) ? (
                <UpdateLoadingCard loadingId={BOARD_IMPORT_LOADING} />
              ) : (
                <Button
                  className="excelButton m-2"
                  onClick={onClickImportContacts}
                  disabled={!name || !file}
                >
                  {intl.formatMessage({ id: "app.contacts.import" })}
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
