import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Button } from "reactstrap";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { SOCIAL_POST_TYPES } from "../../../../cores/socialPost/consts/socialPostType.constant";
import { SocialPost } from "../../../../cores/socialPost/interfaces/api/get-social-posts";
import { ImagesCarousel } from "../../socialHistory/preview/ImagesCarousel";
import { IconMapper } from "../../socialPost/selector/SocialPostSelector";

interface Props {
  post: SocialPost;
  onEdit: () => void;
  onDelete: () => void;
}

export const PostModal = (props: Props) => {
  const intl = useIntl();
  const {
    post: { post, media, mention },
    onEdit,
    onDelete,
  } = props;

  const showIcon = useCallback(() => {
    const title =
      post.target === "post"
        ? intl.formatMessage({ id: "app.socialCalendar.postTitle" })
        : intl.formatMessage({ id: "app.socialCalendar.storyTitle" });
    switch (post.socialProvider) {
      case SocialProviderId.Facebook:
        return (
          <div className="d-flex align-items-center p-3">
            {IconMapper[SocialProviderId.Facebook]({
              active: true,
              size: "2x",
            })}
            <span className="font-size-2 ml-3">
              {title} {SocialProviderName.Facebook}
            </span>
          </div>
        );
      case SocialProviderId.Twitter:
        return (
          <div className="d-flex align-items-center p-3">
            {IconMapper[SocialProviderId.Twitter]({ active: true, size: "2x" })}
            <span className="font-size-2 ml-3">
              {title} {SocialProviderName.Twitter}
            </span>
          </div>
        );
      case SocialProviderId.LinkedIn:
        return (
          <div className="d-flex align-items-center p-3">
            {IconMapper[SocialProviderId.LinkedIn]({
              active: true,
              size: "2x",
            })}
            <span className="font-size-2 ml-3">
              {title} {SocialProviderName.LinkedIn}
            </span>
          </div>
        );
      case SocialProviderId.LinkedInBusiness:
        return (
          <div className="d-flex align-items-center p-3">
            {IconMapper[SocialProviderId.LinkedInBusiness]({
              active: true,
              size: "2x",
            })}
            <span className="font-size-2 ml-3">
              {title} {SocialProviderName.LinkedInBusiness}
            </span>
          </div>
        );
      case SocialProviderId.Instagram:
        return (
          <div className="d-flex align-items-center p-3">
            {IconMapper[SocialProviderId.Instagram]({
              active: true,
              size: "2x",
            })}
            <span className="font-size-2 ml-3">
              {title} {SocialProviderName.Instagram}
            </span>
          </div>
        );
      default:
        return null;
    }
  }, [post.socialProvider, post.target, intl]);

  const formattedText = useMemo(() => {
    const orderMentions = (mention || [])
      .slice()
      .sort((a, b) => (a.offset > b.offset ? -1 : 1));

    let txt = post.text;
    orderMentions.forEach((mention) => {
      const name =
        post.socialProvider === SocialProviderId.Twitter
          ? `<span style="color:#0d4b7e;">@${mention.mentions[0].username}</span>`
          : `<span style="color:#0d4b7e;">${mention.mentions[0].username}</span>`;
      const offset = mention.offset + mention.mentions[0].username.length + 1;
      txt = txt.slice(0, mention.offset) + name + txt.slice(offset);
    });

    return txt;
  }, [mention, post.text, post.socialProvider]);

  return (
    <div className="align-items-center justify-content-center d-flex flex-column px-2 py-4">
      <div>{showIcon()}</div>
      {media.length > 0 && post.type === SOCIAL_POST_TYPES.IMAGE && (
        <ImagesCarousel images={media} />
      )}
      {media.length > 0 && post.type === SOCIAL_POST_TYPES.VIDEO && (
        <video className="videoEditor" controls>
          <source src={media[0].url} />
          Your browser does not support HTML5 video.
        </video>
      )}
      <div className="my-3 text-center">
        {!post.postAt ? (
          <>
            <h6 className="h5">Fecha programada</h6>
            <span className="d-block text-warning">
              {moment(post.scheduleDate).format("DD MMM YYYY hh:mm a")}{" "}
              <FontAwesomeIcon icon={["far", "clock"]} />
            </span>
          </>
        ) : (
          <>
            <h6 className="h5">Fecha de publicación</h6>
            <span className="d-block">
              {moment(post.postAt).format("DD MMM YYYY hh:mm a")}
            </span>
          </>
        )}
      </div>
      {post.text && (
        <div className="my-3 px-4 text-center w-100">
          <h6 className="h5">Texto</h6>
          <div className="px-3 py-3 text-center pre-wrap">
            <div
              dangerouslySetInnerHTML={{
                __html: formattedText,
              }}
            ></div>
          </div>
        </div>
      )}
      {!post.postAt && (
        <div className="my-3 d-flex">
          <Button color="secondary" className="mx-2" onClick={onEdit}>
            Editar
          </Button>
          <Button color="danger" className="mx-2" onClick={onDelete}>
            Eliminar
          </Button>
        </div>
      )}
    </div>
  );
};
