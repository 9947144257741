import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const whatsappRoot = (state: State) => state.whatsapp;

export const settingSelector = createSelector(
  whatsappRoot,
  (whatsapp) => whatsapp.settings
);
export const codeSelector = createSelector(
  whatsappRoot,
  (whatsapp) => whatsapp.code
);

