import React, { useCallback, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Row,
} from "reactstrap";
import { useIntl } from "react-intl";
import { useCompanyCore } from "../../../../../cores/company";
import { ChatUser } from "../../../../../cores/whatsappChat/interfaces/api/get-whatsapp-chat";
import { InputWembii } from "../../../InputWembii";
import { LabelWembii } from "../../../LabelWembii";
import { ContactSourceBadge } from "../../../contacts/ContactSource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_PRIMARY } from "../../../../../constants/theme";
import { ContactSource } from "../../../../../cores/contacts/enum/ContactSource.enum";
import { ContactSourceOptions } from "../../../../../cores/contacts/config";
import { useContactCore } from "../../../../../cores/contacts";
import { CONTACT_TAGS_FORM } from "../../../../../constants/modal.constant";
import { useMeCore } from "../../../../../cores/me";
import { useGlobalCore } from "../../../../../cores/globals";
import { CheckboxWembii } from "../../../CheckboxWembii";
import { Position } from "../../../../../cores/globals/interfaces/globalEntity";

interface Props {
  onSubmit: (arg0: string, arg1: string, arg2: string, arg3: string, arg4: boolean) => void;
  onDelete: () => void;
  whatsappUser?: ChatUser;
}

export default function WhatsappNumberForm(props: Props) {
  const intl = useIntl();
  const { whatsappUser, onSubmit, onDelete } = props;
  const { users } = useCompanyCore();
  const { company } = useMeCore();
  const { turnOnModal } = useGlobalCore();
  const { tags, getContactTags } = useContactCore();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userId, setUserId] = useState("");
  const [alias, setAlias] = useState("");
  const [tag, setTag] = useState("Whatsapp-Chat");
  const [skipLogin, setSkipLogin] = useState(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [selected, setSelected] = useState<{ value: string; label: string }>();

  const disabled = useCallback(
    () => !phoneNumber || userId === "" || alias === "" || alias.length >= 35,
    [phoneNumber, userId, alias]
  );

  useEffect(() => {
    if (!!whatsappUser) {
      setPhoneNumber(whatsappUser.phoneNumber);
      setUserId(whatsappUser.userId);
      setAlias(whatsappUser.alias);
      setTag(whatsappUser.tag);
      setSkipLogin(whatsappUser.skipLogin);
    }
  }, [whatsappUser]);

  useEffect(() => {
    setOptions(
      users.map((user) => {
        return { value: user.id, label: `${user.firstName} ${user.lastName}` };
      })
    );
  }, [users]);

  useEffect(() => {
    setSelected(options.find((option) => option.value === userId));
  }, [userId, options, whatsappUser]);

  const handleChange = useCallback(async (value, { action }) => {
    switch (action) {
      case "remove-value":
        setUserId("");
        break;
      case "select-option":
        setUserId(value.value);
        break;
    }
  }, []);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    onSubmit(phoneNumber, userId, alias, tag, skipLogin);
  };

  const tagsValues = useMemo(() => 
    [
      ...ContactSourceOptions.map((source) =>({
        value: source.value,
        label: intl.formatMessage({id: source.label})
      })),
      ...tags.map((tag) => ({ value: tag.nameId, label: tag.name }))
    ]
  , [tags, intl]);

  const onOpenContactTags = useCallback(() => {
    if (!skipLogin) {
      getContactTags(company?.id!);
      turnOnModal(CONTACT_TAGS_FORM, true, Position.LEFT);
    }
  }, [turnOnModal, getContactTags, skipLogin, company]);

  const toggleSkipContact = useCallback(() => {
    setSkipLogin(!skipLogin);
  }, [skipLogin]);

  return (
    <Container className="h-100 p-4" fluid>
      <Row>
        <Col xs="12">
          <div className="d-flex flex-column h-100 justify-content-center">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id: "app.configWsppUsers.wspNumbers" })}
            </h2>
            <Form onSubmit={onFormSubmit}>
              <LabelWembii forid="phoneNumber" required>
                {intl.formatMessage({ id: "app.phoneTxt" })}
              </LabelWembii>
              <PhoneInput
                name="phone"
                id="phoneNumber"
                placeholder={intl.formatMessage({ id: "app.phoneTxt" })}
                className="mb-3"
                value={phoneNumber}
                onChange={(v) => setPhoneNumber(v || "")}
              />
              <FormGroup>
                <InputWembii
                  name="alias"
                  label="area"
                  id="alias"
                  type="text"
                  placeholder={intl.formatMessage({ id: "app.configWsppUsers.areaTxt" })}
                  value={alias}
                  for="alias"
                  flat
                  required
                  isinvalid={!!alias}
                  onChange={(v) => setAlias(v)}
                />
                {alias.length >= 35 ? null : (
                  <FormFeedback>
                    {intl.formatMessage({ id: "app.configWsppUsers.errMsg" })}
                  </FormFeedback>
                )}
              </FormGroup>

              <LabelWembii forid="user" required>
                 {intl.formatMessage({ id: "app.configWsppUsers.userTxt" })}
              </LabelWembii>

              <Select
                id="user"
                name="users"
                options={options}
                value={selected}
                className="mb-4" 
                placeholder= {intl.formatMessage({ id: "app.select.placeholder" })}
                noOptionsMessage={() => intl.formatMessage({ id: "app.configWsppUsers.errMsg" })}
                onChange={handleChange}
                classNamePrefix="select"
                isClearable={false}
              />

              <div>
                <CheckboxWembii 
                  label={intl.formatMessage({ id: "app.configWsppUsers.createContact" })}
                  checked={!skipLogin}
                  mb={false}
                  onChange={toggleSkipContact}
                />
                <div className="d-flex align-items-center mt-2">
                  <InputWembii
                    type="select"
                    name="tag"
                    value={tag}
                    className="mb-0"
                    onChange={setTag}
                    disabled={skipLogin}
                  >
                    {tagsValues.map(tag => 
                      <option key={tag.value} value={tag.value}>
                        {tag.label}
                      </option>)
                    }
                  </InputWembii>
                  <FontAwesomeIcon
                    icon={["fas", "plus"]}
                    size="2x"
                    className="mx-3 pointer"
                    color={skipLogin ? "gray" : COLOR_PRIMARY}
                    onClick={onOpenContactTags}
                  />
                  <ContactSourceBadge source={tag as ContactSource} />
                </div>
              </div>

              <div className="d-flex justify-content-center mt-3">
                {whatsappUser && (
                  <div className="btn btn-danger mr-1" onClick={onDelete}>
                    {intl.formatMessage({ id: "app.chatConfigArea.delete" })}
                  </div>
                )}
                <Button color="primary" disabled={disabled()}>
                 {intl.formatMessage({ id: "app.chatConfigArea.save" })}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
