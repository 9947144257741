import { useState } from "react";
import { useVisibilityProps } from "./useVisibility.props";

export default function useVisibility({ initValue }: useVisibilityProps) {
  const [visible, setVisible] = useState(initValue ?? false);
  const toggleVisibility = () => setVisible(!visible);

  return {
    visible,
    toggleVisibility,
  };
}
