import React from "react";
import { JUMPSELLER_COLOR } from "../../../../constants/socialColors.constant";
import { IconProps } from "../Facebook/FacebookIcon";
import { sizeReference } from "../WooCommerce/WooCommerceIcon";

export const JumpsellerIcon = (props: IconProps) => {
  const { active, className, size = "1x", color } = props;

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={sizeReference[size]}
      height={sizeReference[size]}
      viewBox="0 0 220.000000 220.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(10.000000,210.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M 700, 120 a 900,900 0 1,0 550,0"
          fill={active ? JUMPSELLER_COLOR : "none"}
        />
        <path
          d="M719 1956 c-325 -97 -579 -356 -670 -682 -19 -68 -22 -105 -23 -264 0 -114 4 -200 12 -225 6 -22 14 -57 16 -77 3 -20 21 -71 40 -112 118 -257 351 -456 627 -537 103 -30 352 -38 465 -16 44 9 94 19 113 22 48 8 167 65 242 116 150 100 291 272 358 435 18 43 37 84 41 89 5 6 16 48 25 94 24 118 17 364 -14 470 -49 167 -140 319 -264 438 -110 106 -173 145 -382 238 -67 29 -116 35 -310 34 -178 0 -208 -3 -276 -23z m492 -166 c18 -10 156 -188 282 -364 51 -71 57 -84 57 -129 0 -52 -175 -999 -190 -1027 -13 -23 -42 -47 -69 -53 -37 -9 -762 126 -798 148 -50 33 -49 57 17 407 110 583 137 709 157 729 10 10 119 82 242 159 226 141 256 154 302 130z"
          fill={color || (active ? "#353d3f" : "gray")}
        />
        <path
          d="M1083 1626 c-23 -20 -28 -32 -28 -69 0 -78 59 -115 130 -81 41 19 57 43 58 90 2 77 -97 114 -160 60z"
          fill={color || (active ? "#353d3f" : "gray")}
        />
        <path
          d="M1025 1348 c-2 -7 -34 -166 -70 -353 -36 -187 -72 -363 -80 -392 -19 -66 -61 -103 -115 -103 -21 0 -41 -3 -43 -7 -3 -5 -8 -33 -12 -64 l-7 -56 46 -7 c63 -10 146 9 194 44 75 54 94 112 177 531 41 205 77 384 80 397 5 22 3 22 -80 22 -60 0 -87 -4 -90 -12z"
          fill={color || (active ? "#353d3f" : "gray")}
        />
      </g>
    </svg>
  );
};
