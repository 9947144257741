import React, { useMemo } from "react";
import CommentCard from "./CommentCard";
import CommentInput from "./CommentInput";
import _ from "lodash";
import { CommentEvent } from "../../../../cores/event/interface/api/get-event";

interface Props {
  comments: CommentEvent[];
  onSubmit: (arg0: string) => void;
}

export default function ActivityContainer(props: Props) {
  const { comments, onSubmit } = props;

  const activitiesFiltered = useMemo(() => {
    const com = comments.map((comment) => ({ ...comment, object: "comment" }));
    return _.orderBy(com, "createdAt", "desc");
  }, [comments]);

  return (
    <div className="py-2">
      <div className="w-100">
        <CommentInput onSubmit={onSubmit} />
        {activitiesFiltered.map((activity) => (
          <CommentCard 
            key={activity.id}
            comment={activity as CommentEvent}
          />
        ))}
      </div>
    </div>
  );
}
