import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { GoogleCalendarSetting } from "../interface/api/post-google-calendar-bind";
import {
  getGoogleCalendarFailureAction,
  getGoogleCalendarInitAction,
  getGoogleCalendarSuccessAction,
  GoogleCalendarApiAction,
  postBindGoogleCalendarFailureAction,
  postBindGoogleCalendarInitAction,
  postBindGoogleCalendarSuccessAction,
  postUnbindGoogleCalendarFailureAction,
  postUnbindGoogleCalendarInitAction,
  postUnbindGoogleCalendarSuccessAction,
} from "./api-actions";

export interface GoogleCalendarState {
  userSettings: GoogleCalendarSetting | null;
  error: string;
}

const initialState: GoogleCalendarState = {
  userSettings: null,
  error: "",
};

export default produce(
  (
    draft: GoogleCalendarState = initialState,
    action: GoogleCalendarApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindGoogleCalendarSuccessAction.type:
      case getGoogleCalendarSuccessAction.type:
        draft.userSettings = action.userSettings;
        return draft;
      case postUnbindGoogleCalendarSuccessAction.type:
        draft.userSettings = null;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindGoogleCalendarFailureAction.type:
      case postUnbindGoogleCalendarFailureAction.type:
      case getGoogleCalendarFailureAction.type:
      case postBindGoogleCalendarInitAction.type:
      case postUnbindGoogleCalendarInitAction.type:
      case getGoogleCalendarInitAction.type:
      default:
        return draft;
    }
  }
);
