import { FacebookAccount } from "../../facebook/interface/api/get-facebook-account";
import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { FacebookAdsAccount } from "../interface/api/get-facebook-ads-account";
import { FacebookAdsSetting } from "../interface/api/post-facebook-ads-bind";

export const postUnbindFacebookAdsInitAction = createAction(
  "[Facebook Ads] Unbind Facebook Ads Init"
);
export const postUnbindFacebookAdsSuccessAction = createAction(
  "[Facebook Ads] Unbind Facebook Ads Success"
);
export const postUnbindFacebookAdsFailureAction = createAction(
  "[Facebook Ads] Unbind Facebook Ads Failure",
  props<{ error: string }>()
);

export const postBindFacebookAdsInitAction = createAction(
  "[Facebook Ads] Bind Facebook Ads Init"
);
export const postBindFacebookAdsSuccessAction = createAction(
  "[Facebook Ads] Bind Facebook Ads Success",
  props<{ settings: FacebookAdsSetting }>()
);
export const postBindFacebookAdsFailureAction = createAction(
  "[Facebook Ads] Bind Facebook Ads Failure",
  props<{ error: string }>()
);

export const getFacebookAdsInitAction = createAction(
  "[Facebook Ads] Get Facebook Ads Settings Init"
);
export const getFacebookAdsSuccessAction = createAction(
  "[Facebook Ads] Get Facebook Ads Settings Success",
  props<{ settings: FacebookAdsSetting }>()
);
export const getFacebookAdsFailureAction = createAction(
  "[Facebook Ads] Get Facebook Ads Settings Failure",
  props<{ error: string }>()
);

export const getFacebookAdsAccountsInitAction = createAction(
  "[Facebook Ads] Get Facebook Ads Accounts Init"
);
export const getFacebookAdsAccountsSuccessAction = createAction(
  "[Facebook Ads] Get Facebook Ads Accounts Success",
  props<{ adsAccounts: FacebookAdsAccount[], accounts: FacebookAccount[] }>()
);
export const getFacebookAdsAccountsFailureAction = createAction(
  "[Facebook Ads] Get Facebook Ads Accounts Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindFacebookAdsInitAction,
  postUnbindFacebookAdsSuccessAction,
  postUnbindFacebookAdsFailureAction,
  postBindFacebookAdsInitAction,
  postBindFacebookAdsSuccessAction,
  postBindFacebookAdsFailureAction,
  getFacebookAdsInitAction,
  getFacebookAdsSuccessAction,
  getFacebookAdsFailureAction,
  getFacebookAdsAccountsInitAction,
  getFacebookAdsAccountsSuccessAction,
  getFacebookAdsAccountsFailureAction,
});

export type FacebookAdsApiAction = typeof actions;
