import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Media, Row } from "reactstrap";
import footerImage1 from "../../../../assets/preview/instagram-buttons-footer.png";
import fakeText from "../../../../assets/preview/preview-fake-text.png";
import { useAdvertisementCore } from "../../../../cores/advertisements";
import { useFacebookCore } from "../../../../cores/facebook";

export const InstagramStreamPreview = () => {
  const { settings } = useFacebookCore();
  const { name, avatarUrl } = settings || {};
  const { image, campaign } = useAdvertisementCore();

  return (
    <div
      className="neu-out p-2 overflow-hidden"
      style={{ width: 250, height: 320 }}
    >
      <Row className="instagramPreview-header px-4">
        <Col xs="8" className="d-flex align-items-center p-0">
          <div>
            <img
              className="rounded-circle mr-1"
              src={avatarUrl}
              style={{
                width: 30,
                height: 30,
              }}
              alt="avatar"
            />
          </div>
          <div>
            <div style={{ fontSize: 10 }} className="font-weight-bold">
              {name}
            </div>
            <div style={{ fontSize: 10 }}>Publicidad</div>
          </div>
        </Col>
        <Col
          xs="4"
          className="d-flex align-items-center justify-content-end p-0"
        >
          <FontAwesomeIcon icon={["fas", "ellipsis-h"]} size="1x" />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center px-2">
        {image.url ? (
          <Media
            src={image.url}
            className="w-100 h-100"
            style={{
              objectFit: "cover",
              objectPosition: "50% top",
            }}
          />
        ) : (
          <FontAwesomeIcon
            size="6x"
            className="my-4"
            icon={["fas", "camera"]}
          />
        )}
      </Row>
      {campaign.urlBusiness && (
        <Row
          className="bg-light p-1 d-flex flex-column"
          style={{ fontSize: 10 }}
        >
          <div className="px-2 d-flex justify-content-between">
            <span>MAS INFORMACION</span>
            <span>{">"}</span>
          </div>
        </Row>
      )}
      <Row className="px-2">
        <img
          className="instagramPreview-footer-1 w-100"
          src={footerImage1}
          alt="footer"
        />
      </Row>
      <Row className="instagramPreview-description px-4">
        {campaign.description ? (
          <div style={{ fontSize: 10 }} className="text-nowrap text-truncate">
            {name}: {campaign.description}
          </div>
        ) : (
          <img
            className="facebookPreview-footer"
            src={fakeText}
            alt="fake-text"
          />
        )}
      </Row>
    </div>
  );
};
