import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { Contact } from "../../../cores/contacts/interfaces/api/get-contacts";
import { useMeCore } from "../../../cores/me";
import { InputWembii } from "../InputWembii";
import { CONTACT_COMPANY_CONTACTS_MODAL } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { useContactCore } from "../../../cores/contacts";
import { ENTERPRISE_CONTACT_ASSIGNMENT } from "../../../constants/internalModal.constant";

interface Props {
  contact: Contact;
  onSuccess: () => void;
}

export default function AssignmentForm({ contact, onSuccess }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const {
    postContactCompany,
    putContactCompany,
    deleteContactCompany,
    company: contactCompany
  } = useContactCore();
  const { turnOffModal, turnOffInternalModal } = useGlobalCore();

  const [role, setRole] = useState("");

  useEffect(() => {
    if (contact.role) {
      setRole(contact.role);
    }
  }, [contact]);

  const cleanForm = useCallback(() => {
    setRole("");
    onSuccess();
    turnOffInternalModal(ENTERPRISE_CONTACT_ASSIGNMENT);
    turnOffModal(CONTACT_COMPANY_CONTACTS_MODAL);
  }, [turnOffInternalModal, onSuccess, turnOffModal]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!contact.role) {
      postContactCompany(
        company?.id!,
        contactCompany?.id!,
        contact.id,
        role,
        cleanForm
      );
    } else {
      putContactCompany(
        company?.id!,
        contactCompany?.id!,
        contact.id,
        role,
        cleanForm
      );
    }
  };

  const disabled = useCallback(
    () => !role || contact.role === role,
    [role, contact.role]
  );

  const onDelete = useCallback(() => {
    deleteContactCompany(
      company?.id!,
      contactCompany?.id!,
      contact.id,
      cleanForm
    );
  }, [deleteContactCompany, cleanForm, contactCompany, contact, company]);

  return (
    <Form className="p-2" onSubmit={onSubmit}>
      <Row>
        <Col className="d-flex align-items-center justify-content-center">
          {contact.name}
        </Col>
        <Col>
          <InputWembii
            label={intl.formatMessage({ id: "app.contacts.companies.role" })}
            type="text"
            name="role"
            id="role"
            required
            placeholder={intl.formatMessage({ id: "app.contacts.companies.role" })}
            value={role}
            onChange={setRole}
          />
        </Col>
      </Row>

      {contact.role  && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.activity.tags.delete" })}  
        </Button>
      )}
      <Button color="primary" disabled={disabled()} className="ml-1">
        {contact.role 
          ? intl.formatMessage({ id: "app.contacts.companies.role.update" })
          : intl.formatMessage({ id: "app.contacts.companies.role.assign" })
        }  
      </Button>
    </Form>
  );
}