import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import {
  FormInput,
  FormNotification,
  FormUser,
  WebForm,
} from "../interfaces/api/get-web-form";
import { FormResponsePayload } from "../interfaces/api/get-webform-response.response";

export const getWebFormsInitAction = createAction(
  "[WebForm] Get WebForms Init"
);
export const getWebFormsSuccessAction = createAction(
  "[WebForm] Get WebForms Success",
  props<{ forms: WebForm[] }>()
);
export const getWebFormsFailureAction = createAction(
  "[WebForm] Get WebForms Failure",
  props<{ error: string }>()
);

export const getWebFormInitAction = createAction("[WebForm] Get WebForm Init");

export const getWebFormSuccessAction = createAction(
  "[WebForm] Get WebForm Success",
  props<{
    form: WebForm;
    inputs: FormInput[];
    notifications: FormNotification[];
    users: FormUser[];
  }>()
);
export const getWebFormResponseSuccessAction = createAction(
  "[WebForm] Get WebForm Response Success",
  props<{ payload: FormResponsePayload[] }>()
);
export const getWebFormFailureAction = createAction(
  "[WebForm] Get WebForm Failure",
  props<{ error: string }>()
);

export const postWebFormInitAction = createAction(
  "[WebForm] Post WebForm Init"
);
export const postWebFormSuccessAction = createAction(
  "[WebForm] Post WebForm Success",
  props<{ form: WebForm }>()
);
export const postWebFormFailureAction = createAction(
  "[WebForm] Post WebForm Failure",
  props<{ error: string }>()
);

export const putWebFormInitAction = createAction("[WebForm] Put WebForm Init");
export const putWebFormSuccessAction = createAction(
  "[WebForm] Put WebForm Success",
  props<{ form: WebForm }>()
);
export const putWebFormFailureAction = createAction(
  "[WebForm] Put WebForm Failure",
  props<{ error: string }>()
);

export const deleteWebFormInitAction = createAction(
  "[WebForm] Delete WebForm Init"
);
export const deleteWebFormSuccessAction = createAction(
  "[WebForm] Delete WebForm Success",
  props<{ id: string }>()
);
export const deleteWebFormFailureAction = createAction(
  "[WebForm] Delete WebForm Failure",
  props<{ error: string }>()
);

export const putFormInputsInitAction = createAction(
  "[WebForm] Put Form Inputs Init"
);
export const putFormInputsSuccessAction = createAction(
  "[WebForm] Put Form Inputs Success",
  props<{
    form: WebForm;
    inputs: FormInput[];
    notifications: FormNotification[];
    users: FormUser[];
  }>()
);
export const putFormInputsFailureAction = createAction(
  "[WebForm] Put Form Inputs Failure",
  props<{ error: string }>()
);

export const postFormNotificationInitAction = createAction(
  "[WebForm] Post Form Notification Init"
);
export const postFormNotificationSuccessAction = createAction(
  "[WebForm] Post Form Notification Success",
  props<{ notification: FormNotification }>()
);
export const postFormNotificationFailureAction = createAction(
  "[WebForm] Post Form Notification Failure",
  props<{ error: string }>()
);

export const deleteFormNotificationInitAction = createAction(
  "[WebForm] Delete Form Notification Init"
);
export const deleteFormNotificationSuccessAction = createAction(
  "[WebForm] Delete Form Notification Success",
  props<{ id: string }>()
);
export const deleteFormNotificationFailureAction = createAction(
  "[WebForm] Delete Form Notification Failure",
  props<{ error: string }>()
);

export const postFormUserInitAction = createAction(
  "[WebForm] Post Form User Init"
);
export const postFormUserSuccessAction = createAction(
  "[WebForm] Post Form User Success",
  props<{ user: FormUser }>()
);
export const postFormUserFailureAction = createAction(
  "[WebForm] Post Form User Failure",
  props<{ error: string }>()
);

export const deleteFormUserInitAction = createAction(
  "[WebForm] Delete Form User Init"
);
export const deleteFormUserSuccessAction = createAction(
  "[WebForm] Delete Form User Success",
  props<{ id: string }>()
);
export const deleteFormUserFailureAction = createAction(
  "[WebForm] Delete Form User Failure",
  props<{ error: string }>()
);

export const postFormSendInitAction = createAction(
  "[WebForm] Post Form Send Init"
);
export const postFormSendSuccessAction = createAction(
  "[WebForm] Post Form Send Success"
);
export const postFormSendFailureAction = createAction(
  "[WebForm] Post Form Send Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getWebFormsInitAction,
  getWebFormsSuccessAction,
  getWebFormsFailureAction,
  getWebFormInitAction,
  getWebFormSuccessAction,
  getWebFormFailureAction,
  postWebFormInitAction,
  postWebFormSuccessAction,
  postWebFormFailureAction,
  putWebFormInitAction,
  putWebFormSuccessAction,
  putWebFormFailureAction,
  deleteWebFormInitAction,
  deleteWebFormSuccessAction,
  deleteWebFormFailureAction,
  putFormInputsInitAction,
  putFormInputsSuccessAction,
  putFormInputsFailureAction,
  postFormNotificationInitAction,
  postFormNotificationSuccessAction,
  postFormNotificationFailureAction,
  deleteFormNotificationInitAction,
  deleteFormNotificationSuccessAction,
  deleteFormNotificationFailureAction,
  postFormUserInitAction,
  postFormUserSuccessAction,
  postFormUserFailureAction,
  deleteFormUserInitAction,
  deleteFormUserSuccessAction,
  deleteFormUserFailureAction,
  postFormSendInitAction,
  postFormSendSuccessAction,
  postFormSendFailureAction,
  getWebFormResponseSuccessAction,
});

export type WebFormApiAction = typeof actions;
