import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-date-picker";
import { Col, Row } from "reactstrap";
import { useMeCore } from "../../../cores/me";
import { useSocialPostCore } from "../../../cores/socialPost";
import { SocialPostStatusOptions, SocialStoryStatusOptions, SocialTargetOptions } from "../../../cores/socialPost/config";
import { SocialProvider } from "../../../cores/socialPost/interfaces/socialProvider";
import { InputWembii } from "../InputWembii";
import { LabelWembii } from "../LabelWembii";
import { PanelWembii } from "../PanelWembii";
import { ableToPost } from "../socialPost/selector/SocialPostSelector";
import { useIntl } from "react-intl";
import { ableToStory } from "../socialPost/selector/SocialStorySelector";

export const SocialHistoryBar = () => {
  const intl = useIntl();
  const { company, socialSettings } = useMeCore();
  const { getSocialPosts } = useSocialPostCore();
  const [since, setSince] = useState(
    moment().startOf("month").toDate()
  );
  const [until, setUntil] = useState(
    moment().add(1, "month").endOf("month").toDate()
  );
  const [provider, setProvider] = useState("all");
  const [status, setStatus] = useState("");
  const [target, setTarget] = useState("post");

  const socialProviders: SocialProvider[] = useMemo(
    () =>
      (target === "post" ? ableToPost : ableToStory)
        .filter((social) => socialSettings![social])
        .map(
          (social): SocialProvider => ({
            id: social,
            name: social,
          })
        ),
    [socialSettings, target]
  );

  const socialStatus = useMemo(() => 
    target === "post" ? SocialPostStatusOptions : SocialStoryStatusOptions
  , [target]);

  const onChangeTarget = useCallback((target) => {
    setTarget(target);
    setProvider("all");
    setStatus("");
  }, []);

  useEffect(() => {
    if (since && until) {
      getSocialPosts(
        company?.id!,
        moment(since).format("YYYY-MM-DD"),
        moment(until).format("YYYY-MM-DD"),
        provider,
        status,
        target
      );
    }
  }, [company, until, since, provider, status, target, getSocialPosts]);

  return (
    <PanelWembii className="w-100 px-4 py-3 mb-3 pointer" shadow>
      <Row>
        <Col xs="12" lg="2" md="6" sm="12" className="d-flex">
          <InputWembii
            label={intl.formatMessage({ id: "app.socialManager.socialTxt" })}
            type="select"
            mb={false}
            value={provider}
            onChange={(v) => setProvider(v)}
            className="w-100"
          >
            <option key="all" value="all">
             {intl.formatMessage({ id: "app.socialManager.allTxt" })} 
            </option>
            {socialProviders.map((provider) => (
              <option
                key={provider.id}
                value={provider.id}
                className="text-capitalize"
              >
                {provider.name}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="2" md="6" sm="12" className="d-flex">
          <InputWembii
            label={intl.formatMessage({ id: "app.socialManager.stateTxt" })} 
            type="select"
            mb={false}
            value={status}
            onChange={(v) => setStatus(v)}
            className="w-100"
          >
            <option key="all" value="">
             {intl.formatMessage({ id: "app.socialManager.allTxt" })} 
            </option>
            {socialStatus.map(({ value, label }) => (
              <option key={value} value={value} className="text-capitalize">
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="2" md="6" sm="12" className="d-flex">
          <InputWembii
            label={intl.formatMessage({ id: "app.socialManager.typeTxt" })}
            type="select"
            mb={false}
            value={target}
            onChange={(v) => onChangeTarget(v)}
            className="w-100"
          >
            {SocialTargetOptions.map(({ value, label }) => (
              <option key={value} value={value} className="text-capitalize">
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="6" md="6" sm="12" className="d-flex justify-content-end">
          <div className="d-flex flex-column mr-2">
            <LabelWembii>
              {intl.formatMessage({ id: "app.socialManager.from" })}
            </LabelWembii>
            <DatePicker
              onChange={setSince}
              value={since}
              clearIcon={null}
              format="dd/MM/y"
            />
          </div>
          <div className="d-flex flex-column ml-1">
            <LabelWembii>
              {intl.formatMessage({ id: "app.socialManager.to" })}
            </LabelWembii>
            <DatePicker
              onChange={setUntil}
              value={until}
              clearIcon={null}
              format="dd/MM/y"
            />
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
