import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { AutomationAction } from "../../../../cores/automations/interfaces/api/get-automation";
import { LabelWembii } from "../../LabelWembii";
import { AssignmentActionBody } from "../../../../cores/automations/interfaces/AssignmentAction";
import Avatar from "../../Avatar";
import { getFirstLetters } from "../../../utils/stringUtils";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import { TooltipWembii } from "../../TooltipWembii";

interface Props {
  action: AutomationAction;
}

export default function CascadeAssignmentAction(props: Props) { 
  const intl = useIntl();
  const { action } = props;

  const assignments = useMemo(() => 
    (action.body as AssignmentActionBody).assignments
  , [action]);

  return (
    <>
      <LabelWembii>
        {intl.formatMessage({ "id": "app.activity.toAssign" })}
      </LabelWembii>
      <div className="d-flex flex-wrap">
        {assignments.map((assignment) => (
          <div key={assignment.id}>
            <Avatar
              id={`user-modal-${assignment.id}`}
              active={assignment.status === UserStatus.ACTIVE.id}
              className="text-dark-50 font-weight-bold pointer m-1"
              label={getFirstLetters(`${assignment.lastName} ${assignment.firstName}`)}
              image={assignment.avatarUrl}
            />
            <TooltipWembii id={`user-modal-${assignment.id}`} text={`${assignment.firstName} ${assignment.lastName}`} />
          </div>
        ))}
      </div>
    </>
  );
}