import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { WEB_FORMS_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { FormInput, WebForm } from "./interfaces/api/get-web-form";
import { webFormApiService } from "./services/api";
import {
  setFormAction,
  addFormInputAction,
  removeFormInputAction,
  updateFormInputAction,
} from "./store/actions";
import {
  deleteFormNotificationFailureAction,
  deleteFormNotificationInitAction,
  deleteFormNotificationSuccessAction,
  deleteFormUserFailureAction,
  deleteFormUserInitAction,
  deleteFormUserSuccessAction,
  deleteWebFormFailureAction,
  deleteWebFormInitAction,
  deleteWebFormSuccessAction,
  getWebFormFailureAction,
  getWebFormInitAction,
  getWebFormResponseSuccessAction,
  getWebFormsFailureAction,
  getWebFormsInitAction,
  getWebFormsSuccessAction,
  getWebFormSuccessAction,
  postFormNotificationFailureAction,
  postFormNotificationInitAction,
  postFormNotificationSuccessAction,
  postFormSendFailureAction,
  postFormSendInitAction,
  postFormSendSuccessAction,
  postFormUserFailureAction,
  postFormUserInitAction,
  postFormUserSuccessAction,
  postWebFormFailureAction,
  postWebFormInitAction,
  postWebFormSuccessAction,
  putFormInputsFailureAction,
  putFormInputsInitAction,
  putFormInputsSuccessAction,
  putWebFormFailureAction,
  putWebFormInitAction,
  putWebFormSuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import {
  webFormsSelector,
  webFormSelector,
  webFormResponseSelector,
} from "./store/selector";
import { PutWebFormRequest } from "./interfaces/api/put-web-form";
import { PostPublicFormRequest } from "./interfaces/api/post-public-form";

export function useWebFormCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const webForms = useSelector(webFormsSelector);
  const webForm = useSelector(webFormSelector);
  const webFormResponses = useSelector(webFormResponseSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const getWebForms = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getWebFormsInitAction());
        turnOnLoading(WEB_FORMS_LOADING);
        const response = await webFormApiService.getWebForms(companyId);
        dispatch(getWebFormsSuccessAction(response));
        turnOffLoading(WEB_FORMS_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(WEB_FORMS_LOADING);
        dispatch(getWebFormsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getWebForm = useCallback(
    async (companyId: string, webFormId: string) => {
      try {
        dispatch(getWebFormInitAction());
        const response = await webFormApiService.getWebForm(
          companyId,
          webFormId
        );
        dispatch(getWebFormSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getWebFormFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getWebFormResponse = useCallback(
    async (companyId: string, contactId: string) => {
      try {
        dispatch(getWebFormInitAction());
        const response = await webFormApiService.getWebFormResponse(
          companyId,
          contactId
        );
        dispatch(getWebFormResponseSuccessAction({ payload: response }));
      } catch (error: any) {
        console.error(error);
        dispatch(getWebFormFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getPublicWebForm = useCallback(
    async (companyId: string, webFormId: string) => {
      try {
        dispatch(getWebFormInitAction());
        const response = await webFormApiService.getPublicWebForm(
          companyId,
          webFormId
        );
        dispatch(getWebFormSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getWebFormFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postWebForm = useCallback(
    async (
      name: string,
      title: string,
      titleSize: number,
      titleColor: string,
      buttonColor: string,
      buttonSize: number,
      buttonPosition: string,
      buttonText: string,
      buttonTextColor: string,
      buttonTextSize: number,
      redirectUrl: string,
      successfulMessage: string,
      action: string,
      inputs: FormInput[],
      companyId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postWebFormInitAction());
        const response = await webFormApiService.postWebForm(
          name,
          title,
          titleSize,
          titleColor,
          buttonColor,
          buttonSize,
          buttonPosition,
          buttonText,
          buttonTextColor,
          buttonTextSize,
          redirectUrl,
          successfulMessage,
          inputs,
          action,
          companyId
        );
        dispatch(postWebFormSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postWebFormFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putWebForm = useCallback(
    async (
      companyId: string,
      formId: string,
      body: PutWebFormRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putWebFormInitAction());
        const response = await webFormApiService.putWebForm(
          companyId,
          formId,
          body
        );
        dispatch(putWebFormSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess36" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putWebFormFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const copyWebForm = useCallback(
    async (companyId: string, webFormId: string) => {
      try {
        dispatch(postWebFormInitAction());
        const response = await webFormApiService.copyWebForm(
          companyId,
          webFormId
        );
        dispatch(postWebFormSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(postWebFormFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteWebForm = useCallback(
    async (companyId: string, webFormId: string) => {
      try {
        dispatch(deleteWebFormInitAction());
        const response = await webFormApiService.deleteWebForm(
          companyId,
          webFormId
        );
        dispatch(deleteWebFormSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(deleteWebFormFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putFormInputs = useCallback(
    async (
      companyId: string,
      formId: string,
      inputs: FormInput[],
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putFormInputsInitAction());
        const response = await webFormApiService.putFormInputs(
          companyId,
          formId,
          inputs
        );
        dispatch(putFormInputsSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess37" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putFormInputsFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const postFormNotification = useCallback(
    async (
      companyId: string,
      formId: string,
      userId: number,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postFormNotificationInitAction());
        const response = await webFormApiService.postFormNotification(
          companyId,
          formId,
          userId
        );
        dispatch(postFormNotificationSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess38" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postFormNotificationFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const deleteFormNotification = useCallback(
    async (companyId: string, formId: string, notificationId: string) => {
      try {
        dispatch(deleteFormNotificationInitAction());
        const response = await webFormApiService.deleteFormNotification(
          companyId,
          formId,
          notificationId
        );
        dispatch(deleteFormNotificationSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess39" }));
      } catch (error: any) {
        console.error(error);
        dispatch(deleteFormNotificationFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const postFormUser = useCallback(
    async (
      companyId: string,
      formId: string,
      userId: number,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postFormUserInitAction());
        const response = await webFormApiService.postFormUser(
          companyId,
          formId,
          userId
        );
        dispatch(postFormUserSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess38" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postFormUserFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const deleteFormUser = useCallback(
    async (companyId: string, formId: string, userId: string) => {
      try {
        dispatch(deleteFormUserInitAction());
        const response = await webFormApiService.deleteFormUser(
          companyId,
          formId,
          userId
        );
        dispatch(deleteFormUserSuccessAction(response));
        toast.success(intl.formatMessage({ id: "app.toast.sucess39" }));
      } catch (error: any) {
        console.error(error);
        dispatch(deleteFormUserFailureAction({ error }));
      }
    },
    [dispatch, intl]
  );
  const postPublicWebForm = useCallback(
    async (
      companyId: string,
      body: PostPublicFormRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postFormSendInitAction());
        const response = await webFormApiService.postPublicWebForm(
          companyId,
          body
        );
        dispatch(postFormSendSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postFormSendFailureAction({ error }));
      }
    },
    [dispatch]
  );

  return {
    webForms,
    webForm,
    setForm: (form: WebForm) => dispatch(setFormAction({ form })),
    addInput: (input: FormInput) => dispatch(addFormInputAction({ input })),
    updateInput: (input: FormInput) =>
      dispatch(updateFormInputAction({ input })),
    removeInput: (id: string) => dispatch(removeFormInputAction({ id })),
    getWebForms,
    getWebForm,
    getPublicWebForm,
    postWebForm,
    putWebForm,
    deleteWebForm,
    copyWebForm,
    getWebFormResponse,
    putFormInputs,
    postFormNotification,
    deleteFormNotification,
    postFormUser,
    deleteFormUser,
    postPublicWebForm,
    webFormResponses,
  };
}
