import { DeleteTaskPayload, DeleteTaskResponse } from "../interfaces/api/delete-task";
import { TaskPayload } from "../interfaces/api/get-task";
import { GetTasksResponse, TasksPayload } from "../interfaces/api/get-tasks";
import { PostTaskResponse } from "../interfaces/api/post-task";
import { PutTaskResponse } from "../interfaces/api/put-task";

export const taskMapper = {
  mapResponseToId(
    response: DeleteTaskResponse
  ): DeleteTaskPayload {
    const id = response?.payload?.id
    return { id };
  },
  mapResponseToTasks(response: GetTasksResponse): TasksPayload {
    const {tasks, total} = response?.payload
    return { tasks, total };
  },
  mapResponseToTask(response: PostTaskResponse | PutTaskResponse): TaskPayload {
    const task = response?.payload?.task
    return { task };
  },
};
