import mainApiClient from "../../helpers/services/main-api-client";
import {
  GetCompaniesQuery,
  GetCompaniesResponse,
  GetCompanyParams,
} from "../interfaces/api/get-companies";
import { CompanyRole, GetCompanyResponse } from "../interfaces/api/get-company";
import { GetCompanyIntegrationResponse } from "../interfaces/api/get-integration";
import { GetUserCompaniesResponse } from "../interfaces/api/get-user-companies";
import {
  PostCompanyRequest,
  PostCompanyResponse,
} from "../interfaces/api/post-company";
import {
  PutCompanyRequest,
  PutCompanyResponse,
} from "../interfaces/api/put-company";
import { PutCompanyApikeyResponse } from "../interfaces/api/put-company-apikey";
import { companyMapper } from "./mapper";

export const companyApiService = {
  async getCompanies(params?: GetCompaniesQuery) {
    return mainApiClient()
      .get<GetCompaniesResponse>(`company`, { params })
      .then(({ data }) => companyMapper.mapResponseToCompanies(data));
  },
  async getSearchCompanies(params?: GetCompanyParams) {
    return mainApiClient()
      .get<GetCompaniesResponse>(`company/searchcompany`, { params })
      .then(({ data }) => companyMapper.mapResponseToCompanies(data));
  },
  postCompany(
    name: string,
    description: string,
    website: string,
    phoneNumber: string,
    country: string,
    city: string,
    sendWeeklyReport: boolean,
    asReseller: boolean
  ) {
    const body: PostCompanyRequest = {
      name,
      description,
      website,
      phoneNumber,
      country,
      city,
      sendWeeklyReport,
      asReseller,
    };
    return mainApiClient()
      .post<PostCompanyResponse>(`company`, body)
      .then(({ data }) => companyMapper.mapResponseToCompany(data));
  },
  async putCompany(
    companyId: string,
    name: string,
    description: string,
    website: string,
    phoneNumber: string,
    country: string,
    city: string,
    sendWeeklyReport: boolean
  ) {
    const body: PutCompanyRequest = {
      name,
      description,
      website,
      phoneNumber,
      country,
      city,
      sendWeeklyReport,
    };
    return mainApiClient()
      .put<PutCompanyResponse>(`company`, body, { params: { companyId } })
      .then(({ data }) => companyMapper.mapResponseToCompany(data));
  },
  async getCompany(companyId: string) {
    return mainApiClient()
      .get<GetCompanyResponse>(`company/${companyId}`, {
        params: { companyId },
      })
      .then(({ data }) => companyMapper.mapResponseToCompanyUser(data));
  },
  async getUserCompanies(userId: string) {
    return mainApiClient()
      .get<GetUserCompaniesResponse>(`company/user/${userId}`)
      .then(({ data }) => companyMapper.mapResponseToCompanies(data));
  },
  async postAssign(userId: string, companyId: string, userRole: CompanyRole) {
    return mainApiClient()
      .post<PostCompanyResponse>(
        `company/assign`,
        { userId, userRole },
        { params: { companyId } }
      )
      .then(({ data }) => data);
  },
  async putAssign(userId: string, companyId: string, userRole: CompanyRole) {
    return mainApiClient()
      .put<PostCompanyResponse>(
        `company/assign`,
        { userId, userRole },
        { params: { companyId } }
      )
      .then(({ data }) => data);
  },
  async putApiKey(companyId: string) {
    return mainApiClient()
      .put<PutCompanyApikeyResponse>(
        `company/apikey`,
        {},
        { params: { companyId } }
      )
      .then(({ data }) => companyMapper.mapResponseToCompanyApiKey(data));
  },
  async deleteAssign(userId: string, companyId: string) {
    return mainApiClient()
      .delete(`company/assign`, { params: { companyId, userId } })
      .then(({ data }) => data);
  },
  async deleteCompany(companyId: string) {
    return mainApiClient()
      .delete(`company/${companyId}`, { params: { companyId } })
      .then(({ data }) => data.payload);
  },
  async postCompanyAvatar(companyId: string, avatar: File) {
    let bodyFormData = new FormData();
    bodyFormData.append("images", avatar);

    return mainApiClient()
      .post<PutCompanyResponse>(`company/avatar`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => companyMapper.mapResponseToCompany(data));
  },
  async getCompanyIntegration(companyId: string) {
    return mainApiClient()
      .get<GetCompanyIntegrationResponse>(`company/integration`, {
        params: { companyId },
      })
      .then(({ data }) => data.payload);
  },
};
