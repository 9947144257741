import React from "react";
import { Button, CardBody, CardImg, CardSubtitle } from "reactstrap";
import yourLogoHere from "../../../assets/yourLogoHere.png";
import { CREATE_EDIT_RESELLER_MODAL } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { Reseller } from "../../../cores/reseller/interfaces/api/get-resellers";
import { PanelWembii } from "../PanelWembii";
import CreateEditResellerForm from "./CreateEditResellerForm";
import { useIntl } from "react-intl";

interface Props {
  reseller: Reseller;
}

export default function ResellerCard(props: Props) {
  const intl = useIntl();
  const { reseller } = props;
  const { id, name, companyCreationLimit } = reseller;
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const handleEditReseller = () => {
    turnOnModal(
      CREATE_EDIT_RESELLER_MODAL,
      <CreateEditResellerForm
        reseller={reseller}
        onSubmit={() => turnOffModal(CREATE_EDIT_RESELLER_MODAL)}
      />
    );
  };

  return (
    <PanelWembii
      key={id}
      style={{ width: "300px" }}
      className="align-content-center border-0 d-flex justify-content-center p-3 text-center position-relative"
      shadow
    >
      <CardImg
        top
        width="90%"
        src={yourLogoHere}
        alt="Card image cap"
        style={{ height: "120px", objectFit: "contain" }}
      /> 
      <CardBody className="p-2 d-flex flex-column">
        <CardSubtitle>{name}</CardSubtitle>
        <CardSubtitle className="my-1">
          {intl.formatMessage({ id: "app.adminResellers.limitCompanies" })}
        </CardSubtitle>
        <CardSubtitle className="h6 text-black-50">
          {companyCreationLimit}
        </CardSubtitle>
        <div className="d-flex mt-2 wrap-container">
          <Button
            onClick={handleEditReseller}
            className="m-1"
            color="secondary"
          >
            {intl.formatMessage({ id: "app.adminResellers.editTxt" })}
          </Button>
        </div>
      </CardBody>
    </PanelWembii>
  );
}
