import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  JUMPSELLER_ORDER_LOADING,
  JUMPSELLER_ORDER_UPDATING,
  MERCADOLIBRE_ORDER_LOADING,
  WOO_COMMERCE_ORDER_LOADING,
  WOO_COMMERCE_ORDER_UPDATING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { getMercadolibreInitAction } from "../mercadolibre/store/api-actions";
import { GetJumpsellerOrdersParams } from "./interface/api/get-jumpseller-orders";
import { GetMercadolibreOrdersParams } from "./interface/api/get-mercadolibre-orders";
import { GetWooCommerceOrdersParams } from "./interface/api/get-woocommerce-orders";
import { marketApiService } from "./services/api";
import { setOrderIdAction, setPageAction, setPerPageAction, setStatusAction } from "./store/actions";
import {
  getJumpsellerOrdersFailureAction,
  getJumpsellerOrdersInitAction,
  getJumpsellerOrdersSuccessAction,
  getMercadolibreOrderFailureAction,
  getMercadolibreOrderSuccessAction,
  getWooCommerceOrderFailureAction,
  getWooCommerceOrdersInitAction,
  getWooCommerceOrderSuccessAction,
  putJumpsellerOrderFailureAction,
  putJumpsellerOrderInitAction,
  putJumpsellerOrderSuccessAction,
  putWooCommerceOrderFailureAction,
  putWooCommerceOrderInitAction,
  putWooCommerceOrderSuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import {
  jumpsellerOrderIdSelector,
  jumpsellerOrdersSelector,
  jumpsellerPageSelector,
  jumpsellerPerPageSelector,
  jumpsellerStatusSelector,
  mercadolibreOrdersSelector,
  wooCommerceOrderSelector,
} from "./store/selector";

export function useMarketCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const wooCommerceOrders = useSelector(wooCommerceOrderSelector);
  const mercadolibreOrders = useSelector(mercadolibreOrdersSelector);
  const jumpsellerOrders = useSelector(jumpsellerOrdersSelector);
  const orderId = useSelector(jumpsellerOrderIdSelector);
  const perPage = useSelector(jumpsellerPerPageSelector);
  const page = useSelector(jumpsellerPageSelector);
  const status = useSelector(jumpsellerStatusSelector);

  const getWooCommerceOrders = useCallback(
    async (companyId: string, params: GetWooCommerceOrdersParams) => {
      try {
        turnOnLoading(WOO_COMMERCE_ORDER_LOADING);
        dispatch(getWooCommerceOrdersInitAction());
        const orders = await marketApiService.wooCommerceOrders(
          companyId,
          params
        );
        dispatch(getWooCommerceOrderSuccessAction({ orders }));
        turnOffLoading(WOO_COMMERCE_ORDER_LOADING, 500);
      } catch (error: any) {
        toast.error(intl.formatMessage({ id: "app.toast.error44" }));
        turnOffLoading(WOO_COMMERCE_ORDER_LOADING);
        console.error(error);
        dispatch(getWooCommerceOrderFailureAction());
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const putWooCommerceOrder = useCallback(
    async (companyId: string, orderId: string, status: string) => {
      try {
        turnOnLoading(WOO_COMMERCE_ORDER_UPDATING);
        dispatch(putWooCommerceOrderInitAction());
        const order = await marketApiService.putWooCommerceOrder(
          companyId,
          orderId,
          status
        );
        turnOffLoading(WOO_COMMERCE_ORDER_UPDATING, 500);
        dispatch(putWooCommerceOrderSuccessAction({ order }));
      } catch (error: any) {
        console.error(error);
        turnOffLoading(WOO_COMMERCE_ORDER_UPDATING);
        dispatch(putWooCommerceOrderFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getMercadolibreOrders = useCallback(
    async (companyId: string, params: GetMercadolibreOrdersParams) => {
      try {
        turnOnLoading(MERCADOLIBRE_ORDER_LOADING);
        dispatch(getMercadolibreInitAction());
        const orders = await marketApiService.mercadolibreOrders(
          companyId,
          params
        );
        dispatch(getMercadolibreOrderSuccessAction({ orders }));
        turnOffLoading(MERCADOLIBRE_ORDER_LOADING, 500);
      } catch (error: any) {
        toast.error(intl.formatMessage({ id: "app.toast.error45" }));
        turnOffLoading(MERCADOLIBRE_ORDER_LOADING);
        console.error(error);
        dispatch(getMercadolibreOrderFailureAction());
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const getJumpsellerOrders = useCallback(
    async (companyId: string, params: GetJumpsellerOrdersParams) => {
      try {
        turnOnLoading(JUMPSELLER_ORDER_LOADING);
        dispatch(getJumpsellerOrdersInitAction());
        const orders = await marketApiService.jumpsellerOrders(
          companyId,
          params
        );
        dispatch(getJumpsellerOrdersSuccessAction({ orders }));
        turnOffLoading(JUMPSELLER_ORDER_LOADING, 500);
      } catch (error: any) {
        toast.error(intl.formatMessage({ id: "app.toast.error46" }));
        turnOffLoading(JUMPSELLER_ORDER_LOADING);
        console.error(error);
        dispatch(getJumpsellerOrdersFailureAction());
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const putJumpsellerOrder = useCallback(
    async (companyId: string, orderId: string, status: string) => {
      try {
        turnOnLoading(JUMPSELLER_ORDER_UPDATING);
        dispatch(putJumpsellerOrderInitAction());
        const order = await marketApiService.putJumpsellerOrder(
          companyId,
          orderId,
          status
        );
        turnOffLoading(JUMPSELLER_ORDER_UPDATING, 500);
        dispatch(putJumpsellerOrderSuccessAction({ order }));
      } catch (error: any) {
        console.error(error);
        turnOffLoading(JUMPSELLER_ORDER_UPDATING);
        dispatch(putJumpsellerOrderFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  return {
    getWooCommerceOrders,
    getMercadolibreOrders,
    getJumpsellerOrders,
    putWooCommerceOrder,
    putJumpsellerOrder,
    setPage: (page: number) => dispatch(setPageAction({ page })),
    setPerPage: (perPage: number) => dispatch(setPerPageAction({ perPage })),
    setOrderId: (orderId: string) => dispatch(setOrderIdAction({ orderId })),
    setStatus: (status: string) => dispatch(setStatusAction({ status })),
    wooCommerceOrders,
    mercadolibreOrders,
    jumpsellerOrders,
    orderId,
    status,
    perPage,
    page,
  };
}
