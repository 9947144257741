import cn from "classnames";
import React, { ReactNode, useState } from "react";
import { PanelWembii } from "../component/PanelWembii";
import Backdrop from "./Backdrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingSpinnerWembii } from "../component/LoadingSpinnerWembii";
import { useIntl } from "react-intl";

interface Props {
  children: ReactNode;
  active: boolean;
  visible?: boolean;
  showSppiner?: boolean;
  sppinerMessage?: string;
  showError?: boolean;
  left?: boolean;
  onClose: () => void;
}

export default function SideModal(props: Props) {
  const intl = useIntl();
  const {
    children,
    active,
    onClose,
    visible = false,
    showSppiner = false,
    showError = false,
    sppinerMessage,
    left = false,
  } = props;
  const [fullScreen, setFullShow] = useState(false);

  return (
    <>
      {active && (
        <>
          {active && visible && <Backdrop onClick={onClose} />}
          <PanelWembii
            shadow
            className={cn({
              sideModalHidden: !visible,
              sideModalOn: active,
              sideModalRight: !left,
              sideModalLeft: left,
              sideModalOffLeft: left && !active,
              sideModalOffRight: !left && !active,
              fullScreenModal: fullScreen,
              sideModal: !fullScreen,
            })}
          >
            <div className="d-flex justify-content-end mt-2 mr-3 pointer position-stick right-0">
              <FontAwesomeIcon
                className="mr-3"
                icon={
                  fullScreen ? ["fas", "compress-alt"] : ["fas", "expand-alt"]
                }
                onClick={() => setFullShow(!fullScreen)}
              />
              <FontAwesomeIcon icon={["fas", "times"]} onClick={onClose} />
            </div>

            <div className="overflow-auto h-100 position-relative">
              {showSppiner && (
                <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                  <LoadingSpinnerWembii active sppinerMessage={sppinerMessage} />
                </div>
              )}
              {showError && (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    icon={["fas", "exclamation-circle"]}
                    className="mr-2"
                  />
                  {intl.formatMessage({ id: "app.modal.error.loading.data" })}
                </div>
              )}
              {!showSppiner && !showError && children}
            </div>
          </PanelWembii>
        </>
      )}
    </>
  );
}
