import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const facebookRoot = (state: State) => state.facebook;

export const settingSelector = createSelector(
  facebookRoot,
  (facebook) => facebook.settings
);
export const accountsSelector = createSelector(
  facebookRoot,
  (facebook) => facebook.accounts
);
export const pagesSelector = createSelector(
  facebookRoot,
  (facebook) => facebook.pages
);