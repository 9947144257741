import cn from "classnames";
import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { PostError } from "../../../../cores/socialPost/interfaces/postError";
import { ErrorIcon } from "../ErrorIcon";

interface ListProps {
  errors: PostError[];
  className?: string;
}

interface ItemProps {
  error: PostError;
}

export const ErrorList = (props: ListProps) => {
  const { errors, className } = props;
  return (
    <ListGroup className={cn("align-items-center p-2 text-danger", className)}>
      {errors.map((error, index) => (
        <ErrorItem key={index} error={error} />
      ))}
    </ListGroup>
  );
};

export const ErrorItem = (props: ItemProps) => {
  const { error } = props;
  return (
    <ListGroupItem className="align-items-center border-0 d-flex flex-column p-2 w-100">
      <div className="d-flex align-items-center">
        <ErrorIcon size="2x" />
        <div className="ml-3 text-center">
          {error.message}
          {error.link && <a href={error.link} target="_blank" rel="noopener noreferrer">
            {error.linkText}
          </a>}
        </div>
      </div>
      <hr className="horizontal-separator my-3" />
    </ListGroupItem>
  );
};
