import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { FormInput, WebForm } from "../interfaces/api/get-web-form";

export const setFormAction = createAction(
  "[WebForm] Set Form",
  props<{ form: WebForm }>()
);

export const addFormInputAction = createAction(
  "[WebForm] Add Form Input",
  props<{ input: FormInput }>()
);

export const updateFormInputAction = createAction(
  "[WebForm] Update Form Input",
  props<{ input: FormInput }>()
);

export const removeFormInputAction = createAction(
  "[WebForm] Remove Form Input",
  props<{ id: string }>()
);

const actions = joinActions({
  setFormAction,
  addFormInputAction,
  removeFormInputAction,
  updateFormInputAction
});

export type WebFormAction = typeof actions;
