import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { Col, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useBoardCore } from "../../../../cores/boards";
import { InputWembii } from "../../InputWembii";
import { Discount } from "../../../../cores/boards/interfaces/Quote";
import { getCurrencySymbol } from "../../../../cores/boards/constants/currencies.constant";
import { COLOR_DANGER } from "../../../../constants/theme";
import { CL$Format } from "../../../../cores/utils";

interface Props {
  discount: Discount;
  index: number;
  subtotal: number;
}

export const DiscountRow = ({ discount, index, subtotal }: Props) => {
  const intl = useIntl();
  const { quote, card, setQuote } = useBoardCore();

  const changeDiscountName = useCallback((value) => {
    let discounts = [...quote.discounts];
    let discount = { ...discounts[index] };
    discount.name = value;
    discounts[index] = discount;

    setQuote({ ...quote, discounts });
  }, [quote, index, setQuote]);

  const changeDiscount = useCallback((value) => {
    let discounts = [...quote.discounts];
    let discount = { ...discounts[index] };
    discount.value = parseFloat(value);
    discounts[index] = discount;

    setQuote({ ...quote, discounts });
  }, [quote, index, setQuote]);

  const changeDiscountType = useCallback((value) => {
    let discounts = [...quote.discounts];
    let discount = { ...discounts[index] };
    discount.type = parseInt(value);
    discounts[index] = discount;

    setQuote({ ...quote, discounts });
  }, [quote, index, setQuote]);

  const removeDiscount = useCallback(() => {
    let discounts = [...quote.discounts];
    discounts.splice(index, 1);

    setQuote({ ...quote, discounts });
  }, [quote, index, setQuote]);

  const discountOptions = useMemo(() => [
    { value: 0, label: "%" },
    { value: 1, label: getCurrencySymbol(card?.currency!) },
  ], [card]);

  const calculateDiscount = useMemo(() => {
    const value = (!discount.type || discount.type === 0)
      ? subtotal * discount.value / 100
      : discount.value;
    return value.toFixed(2);
  }, [subtotal, discount]);

  return (
    <div>
      <div>{intl.formatMessage({ id: "app.products.uniqueDiscount" })}</div>
      <Row key={index} className="py-1">
        <Col>
          <InputWembii
            type="text"
            name="name"
            id="name"
            mb={false}
            value={discount.name}
            onChange={(v) => changeDiscountName(v)}
          />
        </Col>
        <Col>
          <InputWembii
            type="number"
            name="discount"
            id="discount"
            mb={false}
            min="0"
            step=".01"
            value={discount.value}
            style={{ borderRadius: "0px 10px 10px 0px" }}
            onChange={(v) => changeDiscount(v)}
            prepend={
              <InputWembii
                type="select"
                value={discount.type}
                mb={false}
                style={{
                  borderRadius: "10px 0px 0px 10px",
                  maxWidth: "125px"
                }}
                onChange={(v) => changeDiscountType(v)}
              >
                {discountOptions.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </InputWembii>
            }
          />
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          -{getCurrencySymbol(card?.currency!)}{` ${CL$Format(calculateDiscount)}`}

          <FontAwesomeIcon
            icon={["fas", "trash"]}
            className="ml-2 pointer"
            color={COLOR_DANGER}
            onClick={removeDiscount}
          />
        </Col>
      </Row>
    </div>
  );
};
