import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Product } from "../interfaces/api/get-product";
import {
  deleteProductFailureAction,
  deleteProductInitAction,
  deleteProductSuccessAction,
  getProductFailureAction,
  getProductInitAction,
  getProductsFailureAction,
  getProductsInitAction,
  getProductsSuccessAction,
  getProductSuccessAction,
  postProductFailureAction,
  postProductInitAction,
  postProductSuccessAction,
  ProductApiAction,
  putProductFailureAction,
  putProductInitAction,
  putProductSuccessAction,
} from "./api-actions";

export interface ProductState {
  products: {
    ids: number[];
    entities: Record<string, Product>;
  };
  product: Product | null;
}

const initialState: ProductState = {
  products: {
    ids: [],
    entities: {},
  },
  product: null,
};

const productsAdapter = createEntityAdapter<Product>(
  (payload) => payload.id
);

export default produce(
  (
    draft: ProductState = initialState,
    action: ProductApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case getProductsSuccessAction.type:
        productsAdapter.addMany(action.products, draft.products);
        return draft;
      case getProductSuccessAction.type:
        draft.product = action.product;
        return draft;
      case postProductSuccessAction.type:
        productsAdapter.setOne(action.product, draft.products);
        return draft;
      case putProductSuccessAction.type:
        productsAdapter.updateOne(action.product, draft.products);
        return draft;
      case deleteProductSuccessAction.type:
        productsAdapter.removeOne(action.id, draft.products);
        return draft;
      case resetAction.type:
        return initialState;
      case getProductsInitAction.type:
      case getProductsFailureAction.type:
      case getProductInitAction.type:
      case getProductFailureAction.type:
      case postProductInitAction.type:
      case postProductFailureAction.type:
      case putProductInitAction.type:
      case putProductFailureAction.type:
      case deleteProductInitAction.type:
      case deleteProductFailureAction.type:
      default:
        return draft;
    }
  }
);
