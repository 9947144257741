import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const smtpRoot = (state: State) => state.smtp;

export const settingSelector = createSelector(smtpRoot, (smtp) => smtp.settings);

export const userSettingSelector = createSelector(smtpRoot, (smtp) => smtp.userSettings);

export const errorCodeSelector = createSelector(smtpRoot, (smtp) => smtp.errorCode);

export const errorMessageSelector = createSelector(smtpRoot, (smtp) => smtp.errorMessage);
