import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Order } from "../interface/order";
import { JumpsellerOrderStatus } from "../jumpseller.config";
import { MarketAction, setOrderIdAction, setPageAction, setPerPageAction, setStatusAction } from "./actions";
import {
  getJumpsellerOrdersFailureAction,
  getJumpsellerOrdersInitAction,
  getJumpsellerOrdersSuccessAction,
  getMercadolibreOrderFailureAction,
  getMercadolibreOrdersInitAction,
  getMercadolibreOrderSuccessAction,
  getWooCommerceOrderFailureAction,
  getWooCommerceOrdersInitAction,
  getWooCommerceOrderSuccessAction,
  MarketApiAction,
  putJumpsellerOrderFailureAction,
  putJumpsellerOrderInitAction,
  putJumpsellerOrderSuccessAction,
  putWooCommerceOrderFailureAction,
  putWooCommerceOrderInitAction,
  putWooCommerceOrderSuccessAction,
} from "./api-actions";

export interface MarketState {
  wooCommerce: {
    orders: {
      ids: string[];
      entities: Record<string, Order>;
    };
  };
  mercadolibre: {
    orders: {
      ids: string[];
      entities: Record<string, Order>;
    };
  };
  jumpseller: {
    orders: {
      ids: string[];
      entities: Record<string, Order>;
    };
    orderId: string;
    perPage: number;
    page: number;
    status: JumpsellerOrderStatus | string;
  };
}

const initialState: MarketState = {
  wooCommerce: {
    orders: {
      ids: [],
      entities: {},
    },
  },
  mercadolibre: {
    orders: {
      ids: [],
      entities: {},
    },
  },
  jumpseller: {
    orders: {
      ids: [],
      entities: {},
    },
    orderId: "",
    perPage: 10,
    page: 1,
    status: ""
  },
};

const ordersAdapter = createEntityAdapter<Order>((order) => order.id);

export default produce(
  (
    draft: MarketState = initialState,
    action: MarketApiAction | MarketAction | GlobalActions
  ) => {
    switch (action.type) {
      case getWooCommerceOrderSuccessAction.type:
        ordersAdapter.addMany(action.orders, draft.wooCommerce.orders);
        return draft;
      case getMercadolibreOrderSuccessAction.type:
        ordersAdapter.addMany(action.orders, draft.mercadolibre.orders);
        return draft;
      case getJumpsellerOrdersSuccessAction.type:
        ordersAdapter.addMany(action.orders, draft.jumpseller.orders);
        return draft;
      case setOrderIdAction.type:
        draft.jumpseller.orderId = action.orderId;
        return draft;
      case setPageAction.type:
        draft.jumpseller.page = action.page;
        return draft;
      case setPerPageAction.type:
        draft.jumpseller.perPage = action.perPage;
        return draft;
      case setStatusAction.type:
        draft.jumpseller.status = action.status;
        return draft;
      case putWooCommerceOrderSuccessAction.type:
        ordersAdapter.updateOne(action.order, draft.wooCommerce.orders);
        return draft;
      case putJumpsellerOrderSuccessAction.type:
        ordersAdapter.updateOne(action.order, draft.jumpseller.orders);
        return draft;
      case resetAction.type:
        return initialState;
      case getMercadolibreOrdersInitAction.type:
      case getWooCommerceOrdersInitAction.type:
      case getMercadolibreOrderFailureAction.type:
      case getWooCommerceOrderFailureAction.type:
      case getJumpsellerOrdersInitAction.type:
      case getJumpsellerOrdersFailureAction.type:
      case putWooCommerceOrderInitAction.type:
      case putWooCommerceOrderFailureAction.type:
      case putJumpsellerOrderInitAction.type:
      case putJumpsellerOrderFailureAction.type:
      default:
        return draft;
    }
  }
);
