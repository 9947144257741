import { GetGoogleLoginResponse } from "../interface/api/get-google-login";
import {
  GetGoogleProjectsResponse,
  GoogleProject,
  Tokens,
} from "../interface/api/get-google-projects";
import {
  GoogleSetting,
  PostGoogleBindResponse,
} from "../interface/api/post-google-bind";

export const googleSettingMapper = {
  mapResponseToGoogleSetting(response: PostGoogleBindResponse): GoogleSetting {
    const { googleAnalyticsSetting } = response?.payload;
    return googleAnalyticsSetting;
  },

  mapResponseToGoogleProjects(
    response: GetGoogleProjectsResponse
  ): { projects: GoogleProject[]; tokens: Tokens } {
    const { projects, tokens } = response?.payload;
    return { projects, tokens };
  },

  mapResponseToGoogleLoginUrl(
    response: GetGoogleLoginResponse
  ): { url: string } {
    const { url } = response?.payload;
    return { url };
  },
};
