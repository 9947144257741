import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { Button, Col, Container, Media, Row } from "reactstrap";
import { ADVERTISEMENT_POST_LOADING } from "../../../../constants/loading.constant";
import { useAdvertisementCore } from "../../../../cores/advertisements";
import { CampaignGoals, getCampaignGoalById } from "../../../../cores/advertisements/constants/campaign-goals";
import { closingButtonTypes, FormFields, formTypes } from "../../../../cores/advertisements/interfaces/AdsForm";
import { useGlobalCore } from "../../../../cores/globals";
import { TitleWembii } from "../../TitleWembii";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";

interface Props {
  onSubmit: () => void;
}

export default function CampaignReview(props: Props) {
  const intl = useIntl();
  const { onSubmit } = props;
  const { campaign, image, languagesEntities } = useAdvertisementCore();
  const { isLoading, turnOnLoading, turnOffLoading } = useGlobalCore();

  const onCampaignSubmit = async () => {
    turnOnLoading(ADVERTISEMENT_POST_LOADING);
    await onSubmit();
    turnOffLoading(ADVERTISEMENT_POST_LOADING);
  };

  const getLanguageName = (value: number) => {
    if (languagesEntities[value]) return languagesEntities[value].name;
  };

  const isFacebookConfigSet = (value: string) => {
    return campaign.targeting.facebookPositions.includes(value);
  };

  const isInstagramConfigSet = (value: string) => {
    return campaign.targeting.instagramPositions.includes(value);
  };

  const areEquals = (a: number[], b: number[]) => {
    return _.isEqual(a, b);
  };

  const getFormType = (value: number) =>
    formTypes.find((type) => type.key === value)?.label;

  const getClosingButtonType = (value: string) =>
    closingButtonTypes.find((type) => type.key === value)?.label;

  const getQuestion = (value: string) =>
    Object.values(FormFields).find((type) => type.key === value)?.label;

  return (
    <Container className="h-100 p-4" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column justify-content-center">
            <h2 className="h2 text-center mb-0">Campaña</h2>

            <TitleWembii
              Tag="h5"
              color="primary"
              className="align-self-center mt-3"
            >
              General
            </TitleWembii>
            <Row>
              <Col xs="6">
                <div>Nombre: {campaign.name}</div>
                <div>Url destino:{' '}
                  <a href={campaign.url} target="_blank" rel="noopener noreferrer">
                    {campaign.url}
                  </a>
                </div>
                <div>Objetivo: {
                  intl.formatMessage({ id: getCampaignGoalById(campaign.objective)?.name })
                }</div>
              </Col>
              <Col xs="6">
                <div>Se muestra en:</div>
                {isFacebookConfigSet("feed") && (
                  <div className="pl-4">Sección noticias de Facebook</div>
                )}
                {isInstagramConfigSet("stream") && (
                  <div className="pl-4">Feed de Instagram</div>
                )}
                {isFacebookConfigSet("story") && (
                  <div className="pl-4">Historia de Facebook</div>
                )}
                {isInstagramConfigSet("story") && (
                  <div className="pl-4">Historia de Instagram</div>
                )}
              </Col>
            </Row>

            <TitleWembii
              Tag="h5"
              color="primary"
              className="align-self-center mt-3"
            >
              Presupuesto y calendario
            </TitleWembii>
            <Row>
              <Col xs="6">
                <div>Presupuesto diario: {campaign.dailyBudget}</div>
                <div>
                  Límite de puja:{" "}
                  {campaign.bidAmount ? campaign.bidAmount : "Automático"}
                </div>
              </Col>
              <Col xs="6">
                <div>Fecha de inicio: {campaign.startTime}</div>
                <div>
                  Fecha de fin:{" "}
                  {campaign.endTime ? campaign.endTime : "Indefinido"}
                </div>
              </Col>
            </Row>

            <TitleWembii
              Tag="h5"
              color="primary"
              className="align-self-center mt-3"
            >
              Público
            </TitleWembii>
            <Row>
              <Col xs="6">
                <div>Lugares:</div>
                {campaign.targeting.countries.map((country) => (
                  <div key={country} className="pl-4">
                    {country}
                  </div>
                ))}
                {campaign.targeting.cities.map((city) => (
                  <div key={city.key} className="pl-4">
                    {city.name}
                  </div>
                ))}
                <div>Idioma: {getLanguageName(campaign.targeting.locales)}</div>
              </Col>
              <Col xs="6">
                <div>
                  Edad: {campaign.targeting.ageMin}-{campaign.targeting.ageMax}
                  {campaign.targeting.ageMax === 65 ? "+" : ""}
                </div>
                <div>
                  Sexo:{" "}
                  {areEquals(campaign.targeting.genders, [1, 2])
                    ? "Todos"
                    : areEquals(campaign.targeting.genders, [1])
                      ? "Hombres"
                      : "Mujeres"
                  }
                </div>
                <div>Intereses:</div>
                {campaign.targeting.interests.map((interest) => (
                  <div className="pl-4" key={interest.id}>{interest.name}</div>
                ))}
              </Col>
            </Row>

            <TitleWembii
              Tag="h5"
              color="primary"
              className="align-self-center mt-3"
            >
              Diseño
            </TitleWembii>
            <Row>
              <Col xs="6" className="d-flex justify-content-center">
                <Media
                  src={image.url}
                  style={{
                    height: 150,
                    width: 150,
                    objectFit: "cover",
                    objectPosition: "50% top",
                  }}
                />
              </Col>
              <Col xs="6">
                <div>Nombre del negocio: {campaign.titleBusiness}</div>
                <div>Url del negocio:{' '}
                  <a href={campaign.urlBusiness} target="_blank" rel="noopener noreferrer">
                    {campaign.urlBusiness}
                  </a>
                </div>
                <div>Descipción: {campaign.description}</div>
              </Col>
            </Row>
            {campaign.objective === CampaignGoals.LEAD_GENERATION.id && (
              <>
                <TitleWembii
                  Tag="h5"
                  color="primary"
                  className="align-self-center mt-3"
                >
                  Formulario
                </TitleWembii>
                <Row>
                  <Col xs="6">
                    <div>Nombre: {campaign.form.name}</div>
                    <div>Tipo: {getFormType(campaign.form.isOptimizedForQuality)}</div>
                    <div>Politica de privacidad:{' '}
                      <a href={campaign.form.privacyPolicy.url} target="_blank" rel="noopener noreferrer">
                        {campaign.form.privacyPolicy.linkText}
                      </a>
                    </div>
                    <div>Saludo:</div>
                    <div className="pl-4">{campaign.form.contextCard.title}</div>
                    <div className="pl-4">{campaign.form.contextCard.content}</div>
                    <div>Preguntas personalizadas:</div>
                    {campaign.form.customQuestions.map((question) => (
                      <div className="pl-4">{question.label}</div>
                    ))}
                    {campaign.form.customQuestions.length === 0 && (
                      <div className="pl-4">-</div>
                    )}
                  </Col>
                  <Col xs="6">
                    <div>Cierre:</div>
                    <div className="pl-4">
                      {getClosingButtonType(campaign.form.thankYouPage.buttonType)}
                    </div>
                    <div className="pl-4">{campaign.form.thankYouPage.title}</div>
                    <div className="pl-4">{campaign.form.thankYouPage.body}</div>
                    <div className="pl-4">
                      <a href={campaign.form.thankYouPage.websiteUrl} target="_blank" rel="noopener noreferrer">
                        {campaign.form.thankYouPage.buttonText}
                      </a>
                    </div>
                    <div>Preguntas completadas automáticamente:</div>
                    {campaign.form.autoQuestions.map((question) => (
                      <div className="pl-4">{getQuestion(question.type)}</div>
                    ))}
                    {campaign.form.autoQuestions.length === 0 && (
                      <div className="pl-4">-</div>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div className="text-center m-4">
            <UpdateLoadingCard
              loadingId={ADVERTISEMENT_POST_LOADING}
              text="Creando Campaña"
            />
            {!isLoading(ADVERTISEMENT_POST_LOADING) && (
              <Button color="primary" onClick={onCampaignSubmit}>
                CREAR
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
