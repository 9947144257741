export const NotificationType = {
  MESSAGE: "message",
  CONVERSATION: "conversation",
  SOCIAL_POST: "socialPost",
  SUBSCRIPTION: "subscription",
  CONTACTS_IMPORTED: "contactsImported",
  IMPORTING_PROCESS: "importingProcess",
  IMPORTING_PROCESS_FINISHED: "importingProcessFinished",
  CONTACT_EXPORTED: "contactExported",
  NEW_CONTACT: "newContact",
  TASK_ASSIGNMENT: "taskAssignment"
};
