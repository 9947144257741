import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-number-input";
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  CONTACT_COMPANY_MODAL,
  CONTACT_EXPAND_EDIT_FORM,
  TASK_CARD_MODAL,
} from "../../../../constants/modal.constant";
import { useContactCore } from "../../../../cores/contacts";
import { ContactSource } from "../../../../cores/contacts/enum/ContactSource.enum";
import { useGlobalCore } from "../../../../cores/globals";
import { InputWembii } from "../../InputWembii";
import { LabelWembii } from "../../LabelWembii";
import { ContactSourceBadge } from "../ContactSource";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import GmailButton from "../../gmail/GmailButton";
import { ContactSourceOptions } from "../../../../cores/contacts/config";
import { useBoardCore } from "../../../../cores/boards";
import AssignmentForm from "../AssignmentForm";
import { Company } from "../../../../cores/contacts/interfaces/api/get-company";
import CompanyList from "./CompanyList";
import BoardForm from "../BoardForm";
import { ContactOwner } from "../../../../cores/contacts/interfaces/api/get-contact";
import { Contact } from "../../../../cores/contacts/interfaces/api/get-contacts";
import { WHATSAPP_COLOR } from "../../../../constants/socialColors.constant";
import {
  ContactStateLabels,
  ContactStateOptions,
} from "../../../../cores/contacts/interfaces/ContactState";
import OwnerContainer from "../OwnerContainer";
import { CardRow } from "../CardRow";
import ActivityContainer from "../ActivityContainer";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useTaskCore } from "../../../../cores/tasks";
import TaskDetails from "../../tasks/modal/TaskDetails";
import { CompanyRow } from "../CompanyRow";
import { useWebFormCore } from "../../../../cores/webForm";
interface Props {
  onDelete: () => void;
  onUpdate?: (contact: Contact) => void;
  isUnassignedButton?: boolean;
  closeOnUpdating?: Boolean;
}

export default function EditContactForm(props: Props) {
  const intl = useIntl();
  const {
    onDelete,
    onUpdate,
    isUnassignedButton = false,
    closeOnUpdating = true,
  } = props;
  const { turnOffModal, turnOnModal } = useGlobalCore();
  const {
    company,
    socialSettings,
    userSettings,
    companyRole,
    isAdmin,
    isReseller,
    user,
  } = useMeCore();
  const { getBusinessBoards } = useBoardCore();
  const {
    putContact,
    getContact,
    postComment,
    customFields,
    contact,
    contactEmails,
    contactComments,
    contactCompanies,
    customFieldValues,
    contactCards,
    tags,
    states,
  } = useContactCore();
  const { clearTask, getTasksByContact, contactTasks } = useTaskCore();
  const { getWebFormResponse, webFormResponses } = useWebFormCore();
  const [showAssignmentForm, setShowAssignmentForm] = useState(false);
  const [showBoardForm, setShowBoardForm] = useState(false);
  const [enterprise, setEnterprise] = useState<Company | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState(0);
  const [owners, setOwners] = useState<ContactOwner[]>([]);
  const [whatsapp, setWhatsapp] = useState("");
  const [roleValue, setRoleValue] = useState("");
  const [reference, setReference] = useState("");
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    getWebFormResponse(`${company?.id}`, `${contact?.id}`);
  }, [getWebFormResponse, contact?.id]);

  useEffect(() => {
    getTasksByContact(company?.id!, {
      contactId: contact?.id,
    });
  }, [getTasksByContact, company?.id, contact?.id]);
  useEffect(() => {
    customFields.forEach((field) => {
      fields[`${field.id}`] = "";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);

  useEffect(() => {
    customFieldValues.forEach((field) => {
      fields[`${field.customFieldId}`] = field.value;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFieldValues]);

  const onSuccess = useCallback(() => {
    getContact(company?.id!, contact?.id!);
  }, [getContact, company, contact]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    const phoneNumber = phone || "";
    const ownerIds = owners.map((owner) => owner.id);

    const updated = await putContact(company?.id!, contact?.id!, {
      name,
      email,
      source: roleValue,
      phoneNumber,
      state,
      customFields: fields,
      whatsapp,
      owners: ownerIds,
      reference,
    });

    if (updated) {
      onUpdate?.(updated.contact);
      if (closeOnUpdating) {
        turnOffModal(CONTACT_EXPAND_EDIT_FORM);
      } else {
        onSuccess();
      }
    }
  };

  const disabled = useCallback(() => !name || !company, [name, company]);

  const disabled2 = useCallback(
    () =>
      name === contact?.name &&
      email === contact.email &&
      phone === contact.phoneNumber &&
      roleValue === contact.source &&
      whatsapp === contact.whatsapp &&
      state === contact.state &&
      reference === contact.reference &&
      owners === contact?.owners,
    [contact, name, email, phone, whatsapp, state, owners, reference, roleValue]
  );

  useEffect(() => {
    setName(contact?.name!);
    setEmail(contact?.email!);
    setState(contact?.state || 0);
    setPhone(contact?.phoneNumber || "");
    setWhatsapp(contact?.whatsapp || "");
    setRoleValue(contact?.source || "");
    setOwners(contact?.owners || []);
    setReference(contact?.reference || "");
  }, [contact]);

  const showGmailButton = useMemo(
    () =>
      socialSettings?.gmail ||
      userSettings?.gmail ||
      socialSettings?.smtp ||
      userSettings?.smtp ||
      socialSettings?.office365 ||
      userSettings?.office365,
    [userSettings, socialSettings]
  );

  const showCrmButton = useMemo(
    () =>
      companyRole?.owner ||
      companyRole?.admin ||
      companyRole?.agent ||
      companyRole?.crm,
    [companyRole]
  );

  const canModify = useMemo(
    () =>
      isAdmin ||
      isReseller ||
      companyRole?.owner ||
      companyRole?.admin ||
      contact?.owners.findIndex((owner) => owner.id === user?.id) !== -1 ||
      contact?.owners.length === 0,
    [isAdmin, isReseller, companyRole, contact, user]
  );

  const showWhatsappButton = useMemo(() => contact?.whatsapp, [contact]);

  const toggleSelect = useCallback(() => {
    if (canModify) {
      setIsActive(!isActive);
    }
  }, [isActive, canModify]);

  const onSelectRole = useCallback(
    (role) => {
      setRoleValue(role);
      toggleSelect();
    },
    [toggleSelect]
  );

  const tagsValues = useMemo(
    () => [
      ...ContactSourceOptions.map((source) => ({
        value: source.value,
        label: intl.formatMessage({ id: source.label }),
      })),
      ...tags.map((tag) => ({ value: tag.nameId, label: tag.name })),
    ],
    [tags, intl]
  );

  const statesValues = useMemo(
    () => [
      ...ContactStateOptions.map((state) => ({
        value: state.value,
        label: intl.formatMessage({ id: state.label }),
      })),
      ...states.map((state) => ({ value: state.id, label: state.name })),
    ],
    [states, intl]
  );

  const onAddingOwner = useCallback(
    (user) => {
      setOwners([...owners, user]);
    },
    [owners]
  );

  const onRemovingOwner = useCallback(
    (id) => {
      const newOwners = owners.filter((owner) => owner.id !== id);
      setOwners(newOwners);
    },
    [owners]
  );

  const onCreateOpportunity = useCallback(() => {
    getBusinessBoards(company?.id!);
    setShowBoardForm(true);
  }, [getBusinessBoards, company]);

  const selectCompany = useCallback(
    (company: Company) => {
      setEnterprise(company);
      setShowAssignmentForm(true);
    },
    [setShowAssignmentForm]
  );
  const toggleSideModal = useCallback(() => {
    clearTask();
    turnOnModal(
      TASK_CARD_MODAL,
      <TaskDetails contacts={[contact!]} contactTask />
    );
  }, [turnOnModal, clearTask, contact]);

  const onAddingCompany = useCallback(() => {
    turnOnModal(
      CONTACT_COMPANY_MODAL,
      <CompanyList
        assignments={contactCompanies}
        onAssign={(company) => selectCompany(company)}
      />,
      Position.RIGHT
    );
  }, [contactCompanies, selectCompany, turnOnModal]);

  const openWhatsapp = useCallback(() => {
    window.open(`https://wa.me/${contact?.whatsapp}`, "_blank");
  }, [contact]);

  const onSettingField = useCallback(
    (value: string, field: string) => {
      const newFields = { ...fields };
      newFields[`${field}`] = value;
      setFields(newFields);
    },
    [fields]
  );

  const onAddCommentSubmit = useCallback(
    (text: string, type: number) => {
      postComment(company?.id!, contact?.id!, {
        text,
        type,
      });
    },
    [contact, company, postComment]
  );

  return (
    <>
      <>
        {showAssignmentForm && (
          <AssignmentForm
            contact={contact!}
            company={enterprise!}
            onClose={() => setShowAssignmentForm(false)}
            onSuccess={onSuccess}
          />
        )}
        {showBoardForm && (
          <BoardForm
            contact={contact!}
            onClose={() => setShowBoardForm(false)}
          />
        )}
        <Container className="h-100" fluid>
          <Row className="h-100">
            <Col xs="12">
              {!canModify && (
                <span className="text-muted w-100 d-flex justify-content-end">
                  {intl.formatMessage({ id: "app.crmBusiness.readOnly" })}
                </span>
              )}
              <div className="d-flex flex-column h-100 p-4">
                <h2 className="h2 text-center mb-4">
                  <FontAwesomeIcon icon={["far", "id-card"]} className="mr-2" />
                  {intl.formatMessage({ id: "app.contacts.contact" })}
                </h2>
                <Row>
                  <Col>
                    <LabelWembii forid="contact-whatsapp" className="mb-2">
                      {intl.formatMessage({ id: "app.contacts.state" })}
                    </LabelWembii>
                    {canModify ? (
                      <InputWembii
                        type="select"
                        onChange={(v) => setState(parseInt(v))}
                        value={state}
                      >
                        {statesValues.length > 0 &&
                          statesValues.map(({ label, value }) => (
                            <option key={label} value={value}>
                              {label}
                            </option>
                          ))}
                      </InputWembii>
                    ) : (
                      <div>
                        {intl.formatMessage({
                          id: ContactStateLabels[state],
                        })}
                      </div>
                    )}
                  </Col>
                  <Col>
                    <LabelWembii>
                      {intl.formatMessage({ id: "app.contacts.owners" })}
                    </LabelWembii>
                    <OwnerContainer
                      contacts={owners}
                      canModify={canModify}
                      onAdding={onAddingOwner}
                      onRemoving={onRemovingOwner}
                    />
                  </Col>
                </Row>
                <div className="mb-2 d-flex justify-content-center flex-column align-items-center">
                  <div className="d-flex">
                    {showGmailButton && canModify && (
                      <GmailButton contacts={[contact!]} onSubmit={onSuccess} />
                    )}
                    {showCrmButton && canModify && (
                      <Button
                        className="m-2 d-flex justify-content-center align-items-center text-dark"
                        onClick={onCreateOpportunity}
                        size="sm"
                        style={{ backgroundColor: "#fff" }}
                      >
                        {intl.formatMessage({
                          id: "app.contacts.createOpportunity",
                        })}
                      </Button>
                    )}
                    {showWhatsappButton && canModify && (
                      <Button
                        className="my-2 d-flex justify-content-center align-items-center border-0 text-white"
                        onClick={openWhatsapp}
                        size="sm"
                        style={{ backgroundColor: WHATSAPP_COLOR }}
                      >
                        <FontAwesomeIcon
                          icon={["fab", "whatsapp"]}
                          className="mr-2"
                        />
                        {intl.formatMessage({
                          id: "app.contacts.sendWhatsapp",
                        })}
                      </Button>
                    )}
                  </div>
                  <div className="mt-2 d-flex justify-content-center">
                    {isActive ? (
                      <InputWembii
                        type="select"
                        name="roleValue"
                        value={roleValue}
                        className="w-50"
                        onChange={(v) => onSelectRole(v)}
                      >
                        {tagsValues.map((tag) => (
                          <option key={tag.value} value={tag.value}>
                            {tag.label}
                          </option>
                        ))}
                      </InputWembii>
                    ) : (
                      <ContactSourceBadge
                        source={roleValue as ContactSource}
                        onClick={toggleSelect}
                      />
                    )}
                  </div>
                </div>

                <Nav tabs className="justify-content-center mt-2">
                  <NavItem>
                    <NavLink
                      className={cn("pointer", { active: activeTab === "1" })}
                      onClick={() => {
                        setActiveTab("1");
                      }}
                    >
                      {intl.formatMessage({
                        id: "app.contacts.generalInformation",
                      })}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={cn("pointer", { active: activeTab === "2" })}
                      onClick={() => {
                        setActiveTab("2");
                      }}
                    >
                      {intl.formatMessage({
                        id: "app.contacts.customFields",
                      })}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={cn("pointer", { active: activeTab === "3" })}
                      onClick={() => {
                        setActiveTab("3");
                      }}
                    >
                      {intl.formatMessage({
                        id: "app.contacts.opportunities",
                      })}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Form className="px-2 pt-2" onSubmit={onFormSubmit}>
                      <InputWembii
                        label={intl.formatMessage({ id: "app.nameTxt" })}
                        type="text"
                        name="name"
                        disabled={!canModify}
                        id="contact-name"
                        placeholder={intl.formatMessage({
                          id: "app.nameTxt",
                        })}
                        value={name}
                        onChange={setName}
                      />
                      <InputWembii
                        label={intl.formatMessage({ id: "app.emailTxt" })}
                        type="email"
                        name="email"
                        disabled={!canModify}
                        id="contact-email"
                        placeholder={intl.formatMessage({
                          id: "app.emailTxt",
                        })}
                        value={email}
                        onChange={setEmail}
                      />
                      <LabelWembii forid="contact-telefono">
                        {intl.formatMessage({ id: "app.phoneTxt" })}
                      </LabelWembii>
                      <PhoneInput
                        name="telefono"
                        id="contact-telefono"
                        disabled={!canModify}
                        placeholder={intl.formatMessage({
                          id: "app.phoneTxt",
                        })}
                        className="mb-3"
                        value={phone}
                        onChange={(v) => setPhone(v || "")}
                      />
                      <LabelWembii forid="contact-whatsapp">
                        Whatsapp
                      </LabelWembii>
                      <PhoneInput
                        name="whatsapp"
                        id="contact-whatsapp"
                        disabled={!canModify}
                        placeholder="Whatsapp"
                        className="mb-3"
                        value={whatsapp}
                        onChange={(v) => setWhatsapp(v || "")}
                      />
                      <InputWembii
                        label="Reference"
                        type="text"
                        name="reference"
                        disabled={!canModify}
                        id="contact-reference"
                        value={reference}
                        onChange={setReference}
                      />

                      <div className="d-flex align-items-center mb-3">
                        {canModify && (
                          <Button
                            color="primary"
                            block
                            disabled={disabled() || disabled2()}
                            className="mr-1 my-0"
                          >
                            {intl.formatMessage({ id: "app.contacts.save" })}
                          </Button>
                        )}
                        {(canModify || isUnassignedButton) && (
                          <Button
                            color="danger"
                            className="ml-1 my-0"
                            block
                            onClick={onDelete}
                          >
                            {!isUnassignedButton
                              ? intl.formatMessage({
                                  id: "app.contacts.delete",
                                })
                              : intl.formatMessage({
                                  id: "app.contacts.unassigned",
                                })}
                          </Button>
                        )}
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <LabelWembii>
                          {intl.formatMessage({
                            id: "app.contacts.companies.title",
                          })}
                        </LabelWembii>
                        {canModify && (
                          <Button
                            className="whiteButton border"
                            type="button"
                            onClick={onAddingCompany}
                          >
                            {intl.formatMessage({
                              id: "app.contacts.addCompany",
                            })}
                          </Button>
                        )}
                      </div>
                      {contactCompanies.map((company: Company) => (
                        <CompanyRow
                          company={company}
                          key={company.id}
                          canModify={canModify}
                          onEdit={selectCompany}
                        />
                      ))}
                      {contactCompanies.length === 0 && (
                        <div className="m-4 text-center text-muted">
                          {intl.formatMessage({
                            id: "app.contacts.notCompanies",
                          })}
                        </div>
                      )}
                      <div className="d-flex my-4 justify-content-between align-items-center">
                        <LabelWembii>
                          {intl.formatMessage({
                            id: "app.sidebar.tasks",
                          })}
                        </LabelWembii>
                        {canModify && (
                          <Button
                            className="whiteButton border"
                            type="button"
                            onClick={toggleSideModal}
                          >
                            {intl.formatMessage({
                              id: "app.crmTasks.CreateTasks",
                            })}
                          </Button>
                        )}
                      </div>
                    </Form>
                    <div className="px-2 pt-2">
                      <LabelWembii className="mt-4">
                        {intl.formatMessage({ id: "app.contacts.activity" })}
                      </LabelWembii>
                      <ActivityContainer
                        formResponses={webFormResponses}
                        tasks={contactTasks}
                        comments={contactComments}
                        emails={contactEmails}
                        onSubmit={onAddCommentSubmit}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <Form className="px-2 pt-2" onSubmit={onFormSubmit}>
                      {customFields.length === 0 && (
                        <div className="d-flex align-items-center justify-content-center text-muted my-2">
                          {intl.formatMessage({
                            id: "app.contacts.noCustomFields",
                          })}
                        </div>
                      )}

                      {customFields.map((field) => (
                        <InputWembii
                          label={field.name}
                          type="text"
                          key={field.id}
                          disabled={!canModify}
                          placeholder={field.name}
                          onChange={(v) => onSettingField(v, field.id)}
                          value={fields[`${field.id}`]}
                        />
                      ))}

                      {customFields.length > 0 && canModify && (
                        <div className="d-flex align-items-center mb-3">
                          <Button color="primary" block className="mr-1 my-0">
                            {intl.formatMessage({ id: "app.contacts.save" })}
                          </Button>
                        </div>
                      )}
                    </Form>
                  </TabPane>
                  <TabPane tabId="3">
                    {contactCards.map((card) => (
                      <CardRow card={card} key={card.id} />
                    ))}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    </>
  );
}
