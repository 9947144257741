import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const planRoot = (state: State) => state.plan;

export const plansSelector = createSelector(planRoot, ({ plans }) =>
  plans.ids.map((id) => plans.entities[id])
);

export const selectedPlanSelector = createSelector(planRoot, ({ selectedPlan }) => selectedPlan);
