import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Media, Row } from "reactstrap";
import { useAdvertisementCore } from "../../../../cores/advertisements";
import { useFacebookCore } from "../../../../cores/facebook";

export const FacebookStoryPreview = () => {
  const { settings } = useFacebookCore();
  const { name, avatarUrl } = settings || {};
  const { image, campaign } = useAdvertisementCore();

  return (
    <div
      className="neu-out p-2 overflow-hidden d-flex flex-column justify-content-between bg-dark"
      style={{ width: 250, height: 320 }}
    >
      <div
        className="rounded mx-4"
        style={{ height: 3, backgroundColor: "grey" }}
      ></div>
      <Row className="facebookPreview-header px-4">
        <Col xs="8" className="d-flex align-items-center p-0">
          <div>
            <img
              className="rounded-circle mr-1"
              src={avatarUrl}
              style={{
                width: 30,
                height: 30,
              }}
              alt="avatar"
            />
          </div>
          <div>
            <div
              className="font-weight-bold text-light"
              style={{ fontSize: 10 }}
            >
              {name}
            </div>
            <div className="text-light" style={{ fontSize: 10 }}>
              Publicidad
            </div>
          </div>
        </Col>
        <Col
          xs="4"
          className="d-flex align-items-center justify-content-end p-0"
        >
          <FontAwesomeIcon icon={["fas", "ellipsis-v"]} color="gray" />
          <FontAwesomeIcon
            icon={["fas", "times"]}
            color="gray"
            className="ml-2"
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center px-2">
        {image.url ? (
          <Media
            src={image.url}
            className="w-100 h-100"
            style={{
              objectFit: "cover",
              objectPosition: "50% top",
            }}
          />
        ) : (
          <FontAwesomeIcon
            size="6x"
            className="my-4"
            icon={["fas", "camera"]}
          />
        )}
      </Row>
      <Row className="px-4 text-center">
        {campaign.description && (
          <div
            className="text-nowrap text-truncate text-light"
            style={{ fontSize: 10 }}
          >
            {campaign.description}
          </div>
        )}
      </Row>
      <Row className="px-2 d-flex justify-content-center">
        <div className="btn bg-light text-dark" style={{ fontSize: 10 }}>
          Mas información
        </div>
      </Row>
    </div>
  );
};
