import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  ACCOUNT_PASSWORD_EMAIL_LOADING,
  ACCOUNT_PASSWORD_POST_LOADING,
  ACCOUNT_POST_LOADING,
  ADMIN_USER_LOADING,
  RESEND_CONFIRMATION_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { GetUsersParams } from "./interfaces/api/get-users";
import { PutUserRequest } from "./interfaces/api/put-user";
import { userApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  createUserFailureAction,
  createUserInitAction,
  createUserSuccessAction,
  getForgotPasswordFailureAction,
  getForgotPasswordInitAction,
  getForgotPasswordSuccessAction,
  getUserFailureAction,
  getUserInitAction,
  getUsersFailureAction,
  getUsersInitAction,
  getUsersSuccessAction,
  getUserSuccessAction,
  postForgotPasswordFailureAction,
  postForgotPasswordInitAction,
  postForgotPasswordSuccessAction,
  putForgotPasswordFailureAction,
  putForgotPasswordInitAction,
  putForgotPasswordSuccessAction,
  putUserFailureAction,
  putUserInitAction,
  putUserSuccessAction,
} from "./store/api-actions";
import {
  totalUserSelector,
  userSearchedSelector,
  userSelector,
  usersSelector,
} from "./store/selector";
import { PostUserRequest } from "./interfaces/api/post-user";

export function useUserCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const total = useSelector(totalUserSelector);
  const userSearched = useSelector(userSearchedSelector);
  const users = useSelector(usersSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const postRegisterUser = useCallback(
    async (
      body: PostUserRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(createUserInitAction());
        turnOnLoading(ACCOUNT_POST_LOADING);
        const user = await userApiService.postRegisterUser(body);
        dispatch(createUserSuccessAction({ user }));
        turnOffLoading(ACCOUNT_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess29" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        turnOffLoading(ACCOUNT_POST_LOADING);
        dispatch(createUserFailureAction({ error }));
        toast.error(intl.formatMessage({ id: "app.toast.error59" }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading, intl]
  );
  const postRegisterEntelUser = useCallback(
    async (
      body: PostUserRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(createUserInitAction());
        turnOnLoading(ACCOUNT_POST_LOADING);
        const user = await userApiService.postRegisterEntelUser(body);
        dispatch(createUserSuccessAction({ user }));
        turnOffLoading(ACCOUNT_POST_LOADING);
        toast.success(intl.formatMessage({ id: "app.toast.sucess29" }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        turnOffLoading(ACCOUNT_POST_LOADING);
        dispatch(createUserFailureAction({ error }));
        toast.error(intl.formatMessage({ id: "app.toast.error59" }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading, intl]
  );
  const postUser = useCallback(
    async (
      body: PostUserRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(createUserInitAction());
        turnOnLoading(ACCOUNT_POST_LOADING);
        const user = await userApiService.postUser(body);
        dispatch(createUserSuccessAction({ user }));
        turnOffLoading(ACCOUNT_POST_LOADING);
        onSuccess?.();
        return user;
      } catch (error: any) {
        console.error(error);
        turnOffLoading(ACCOUNT_POST_LOADING);
        dispatch(createUserFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getUser = useCallback(
    async (email: string) => {
      try {
        dispatch(getUserInitAction());
        const user = await userApiService.getUser(email);
        dispatch(getUserSuccessAction({ user }));
      } catch (error: any) {
        console.error(error);
        dispatch(getUserFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putUser = useCallback(
    async (id: string, body: PutUserRequest) => {
      try {
        dispatch(putUserInitAction());
        const user = await userApiService.putUser(id, body);
        dispatch(putUserSuccessAction({ user }));
      } catch (error: any) {
        console.error(error);
        dispatch(putUserFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getUsers = useCallback(
    async (params?: GetUsersParams) => {
      try {
        turnOnLoading(ADMIN_USER_LOADING);
        dispatch(getUsersInitAction());
        const response = await userApiService.getUsers(params);
        turnOffLoading(ADMIN_USER_LOADING, 500);
        dispatch(getUsersSuccessAction(response));
      } catch (error: any) {
        turnOffLoading(ADMIN_USER_LOADING);
        console.error(error);
        dispatch(getUsersFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const getForgotPassword = useCallback(
    async (email: string, onSuccess?: () => void) => {
      try {
        dispatch(getForgotPasswordInitAction());
        turnOnLoading(ACCOUNT_PASSWORD_EMAIL_LOADING);
        await userApiService.getForgotPassword(email);
        dispatch(getForgotPasswordSuccessAction({}));
        turnOffLoading(ACCOUNT_PASSWORD_EMAIL_LOADING);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        turnOffLoading(ACCOUNT_PASSWORD_EMAIL_LOADING);
        dispatch(getForgotPasswordFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const putRecoverPassword = useCallback(
    async (email: string, token: string, onSuccess?: () => void) => {
      try {
        dispatch(putForgotPasswordInitAction());
        turnOnLoading(ACCOUNT_PASSWORD_POST_LOADING);
        await userApiService.putRecoverPassword(email, token);
        dispatch(putForgotPasswordSuccessAction({}));
        turnOffLoading(ACCOUNT_PASSWORD_POST_LOADING);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        turnOffLoading(ACCOUNT_PASSWORD_POST_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error60" }));
        dispatch(putForgotPasswordFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading, intl]
  );
  const postResendConfirmation = useCallback(
    async (id: string, onSuccess?: () => void) => {
      try {
        dispatch(postForgotPasswordInitAction());
        turnOnLoading(RESEND_CONFIRMATION_LOADING);
        await userApiService.postResendConfirmation(id);
        dispatch(postForgotPasswordSuccessAction({}));
        turnOffLoading(RESEND_CONFIRMATION_LOADING);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        turnOffLoading(RESEND_CONFIRMATION_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error61" }));
        dispatch(postForgotPasswordFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading, intl]
  );

  return {
    getUser,
    postRegisterUser,
    postRegisterEntelUser,
    postUser,
    getUsers,
    putUser,
    putRecoverPassword,
    getForgotPassword,
    postResendConfirmation,
    user,
    userSearched,
    users,
    total,
  };
}
