import React, { useCallback, useEffect, useState } from "react";
import { InputWembii } from "../../InputWembii";

interface Props {
  orderStatus: { label: string, value: string }[]
  status: string;
  onChange: (arg0: string) => void;
}

export const OrderStatusSelector = (props: Props) => {
  const { status, orderStatus, onChange } = props;
  const [selectedStatus, setSelectedStatus] = useState(status);

  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  const handleChange = useCallback((status) => {
    setSelectedStatus(status);
    onChange(status);
  }, [onChange]);
  
  return (
    <InputWembii
      mb={false}
      type="select"
      value={selectedStatus}
      onChange={handleChange}
    >
      {orderStatus.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </InputWembii>
  );
};
