import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { CommentType } from "../interfaces/api/get-comment-types";
import { Config } from "../interfaces/api/get-config";
import { ConfigActions, setPathAction } from "./actions";
import {
  ConfigApiAction,
  deleteCommentTypeFailureAction,
  deleteCommentTypeInitAction,
  deleteCommentTypeSuccessAction,
  getCommentTypesFailureAction,
  getCommentTypesInitAction,
  getCommentTypesSuccessAction,
  getConfigFailureAction,
  getConfigInitAction,
  getConfigSuccessAction,
  postCommentTypeFailureAction,
  postCommentTypeInitAction,
  postCommentTypeSuccessAction,
  putCommentTypeFailureAction,
  putCommentTypeInitAction,
  putCommentTypeSuccessAction,
  putConfigFailureAction,
  putConfigInitAction,
  putConfigSuccessAction
} from "./api-actions";

export interface ConfigState {
  config: {
    ids: string[];
    entities: Record<string, Config>;
  };
  commentTypes: {
    ids: number[];
    entities: Record<string, CommentType>;
  };
  path: string;
}

const initialState: ConfigState = {
  config: {
    ids: [],
    entities: {},
  },
  commentTypes: {
    ids: [],
    entities: {},
  },
  path: "",
};

const configAdapter = createEntityAdapter<Config>(
  (payload) => payload.name
);
const commentTypeAdapter = createEntityAdapter<CommentType>(
  (payload) => payload.id
);

export default produce(
  (
    draft: ConfigState = initialState,
    action: ConfigApiAction | ConfigActions | GlobalActions
  ) => {
    switch (action.type) {
      case getConfigSuccessAction.type:
        configAdapter.addMany(action.config, draft.config);
        return draft;
      case putConfigSuccessAction.type:
        configAdapter.updateOne(action.config, draft.config);
        return draft;
      case setPathAction.type:
        draft.path = action.path;
        return draft;
      case getCommentTypesSuccessAction.type:
        commentTypeAdapter.addMany(action.types, draft.commentTypes);
        return draft;
      case postCommentTypeSuccessAction.type:
        commentTypeAdapter.setOne(action.commentType, draft.commentTypes);
        return draft;
      case putCommentTypeSuccessAction.type:
        commentTypeAdapter.updateOne(action.commentType, draft.commentTypes);
        return draft;
      case deleteCommentTypeSuccessAction.type:
        commentTypeAdapter.removeOne(action.id, draft.commentTypes);
        return draft;
      case resetAction.type:
        return initialState;
      case getConfigInitAction.type:
      case getConfigFailureAction.type:
      case putConfigInitAction.type:
      case putConfigFailureAction.type:
      case postCommentTypeInitAction.type:
      case postCommentTypeFailureAction.type:
      case putCommentTypeInitAction.type:
      case putCommentTypeFailureAction.type:
      case getCommentTypesInitAction.type:
      case getCommentTypesFailureAction.type:
      case deleteCommentTypeInitAction.type:
      case deleteCommentTypeFailureAction.type:
      default:
        return draft;
    }
  }
);
