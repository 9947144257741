import { Dictionary } from "lodash";

export enum CardCustomField {
  Text = "text",
  Number = "number",
  Date = "date",
  Money = "money",
}

export const  CustomFieldLabels: Dictionary<string> = {
  [`${CardCustomField.Text}`]: "app.opportunity.customField.text",
  [`${CardCustomField.Number}`]: "app.opportunity.customField.number",
  [`${CardCustomField.Date}`]: "app.opportunity.customField.date",
  [`${CardCustomField.Money}`]: "app.opportunity.customField.money",
};

export const CustomFieldOptions = [
  { value: CardCustomField.Text, label: "app.opportunity.customField.text" },
  { value: CardCustomField.Number, label: "app.opportunity.customField.number" },
  { value: CardCustomField.Date, label: "app.opportunity.customField.date" },
  { value: CardCustomField.Money, label: "app.opportunity.customField.money" },
];