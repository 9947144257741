import React, { useCallback } from "react";
import { ANALYTICS_EXPAND } from "../../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../../constants/theme";
import { Metric2 } from "../../../cores/analytics/interface/Metric2";
import { useGlobalCore } from "../../../cores/globals";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import { AnalyticsExpand } from "./AnalyticsExpand";
import { CameFrom } from "./enum/CameFrom";

export interface MonitorProps {
  metric: Metric2;
  color?: string;
  backgroundColor?: string;
  cameFrom: CameFrom;
}

export const Monitor = (props: MonitorProps) => {
  const { metric, color = "white", backgroundColor = COLOR_PRIMARY, cameFrom } = props;
  const { title: label, total, valueFormatter } = metric;
  const { turnOnModal } = useGlobalCore();

  const handleOnClick = useCallback(() => {
    turnOnModal(
      ANALYTICS_EXPAND,
      <AnalyticsExpand metric={metric} cameFrom={cameFrom} />,
      Position.LEFT
    );
  }, [metric, cameFrom, turnOnModal]);

  return (
    <div
      className="monitor pointer mb-2"
      style={{ backgroundColor, color }}
      onClick={handleOnClick}
    >
      <h3 className="h4 mb-1">
        {valueFormatter ? valueFormatter(total.value) : total.value}
      </h3>
      <h6 className="h6 m-0">{label}</h6>
    </div>
  );
};
