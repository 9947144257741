import { Moment } from "moment";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HISTORY_POST_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { SocialProviderId } from "./config";
import { Media, SocialPost } from "./interfaces/api/get-social-posts";
import { Mentions } from "./interfaces/Mention";
import { PostError, PostErrorType } from "./interfaces/postError";
import { PostImage } from "./interfaces/postImage";
import { PostVideo } from "./interfaces/postVideo";
import { SocialProvider } from "./interfaces/socialProvider";
import { socialPostsApiService } from "./services/api";
import {
  addMentionAction,
  addPostErrorAction,
  addStoryErrorAction,
  clear as clearPost,
  clearImages,
  clearPostMedia,
  clearSelectedPostAction,
  clearSocialProviders as clearSocialProvidersAction,
  deleteImage,
  deletePostMedia,
  deleteSocialProvider,
  deleteVideo,
  moveMentions,
  removePostErrorAction,
  removeStoryErrorAction,
  resetSocialAction,
  selectPostAction,
  setDateAction,
  setEmoji,
  setHashtag,
  setImages,
  setInputCursorIndex,
  setSocialActionAction,
  setSocialProvider,
  setText,
  setVideo,
  setVideoDuration,
  setVideoSize,
  updateImage as _updateImage,
} from "./store/actions";
import {
  deleteHashtagFailureAction,
  deleteHashtagInitAction,
  deleteHashtagSuccessAction,
  deleteSocialPostFailureAction,
  deleteSocialPostInitAction,
  deleteSocialPostSuccessAction,
  getHashtagsFailureAction,
  getHashtagsInitAction,
  getHashtagsSuccessAction,
  getSocialPostsFailureAction,
  getSocialPostsInitAction,
  getSocialPostsSuccessAction,
  postHashtagFailureAction,
  postHashtagInitAction,
  postHashtagSuccessAction,
  updateSocialPostInitAction,
  updateSocialPostSuccessAction,
} from "./store/api-actions";
import {
  dateSelector,
  postErrorsSelector,
  storyErrorsSelector,
  hashtagsSelector,
  imagesSelector,
  inputCursorIndexSelector,
  mentionsSelector,
  selectedPostSelector,
  socialActionSelector,
  socialPostsSelector,
  socialProviderEntitiesSelector,
  socialProvidersSelector,
  textSelector,
  videoSelector,
} from "./store/selector";

export function useSocialPostCore() {
  const dispatch = useDispatch();
  const socialProviders = useSelector(socialProvidersSelector);
  const socialProvidersEntities = useSelector(socialProviderEntitiesSelector);
  const images = useSelector(imagesSelector);
  const postErrors = useSelector(postErrorsSelector);
  const storyErrors = useSelector(storyErrorsSelector);
  const text = useSelector(textSelector);
  const inputCursorIndex = useSelector(inputCursorIndexSelector);
  const video = useSelector(videoSelector);
  const socialPosts = useSelector(socialPostsSelector);
  const selectedPost = useSelector(selectedPostSelector);
  const action = useSelector(socialActionSelector);
  const hashtags = useSelector(hashtagsSelector);
  const mentions = useSelector(mentionsSelector);
  const date = useSelector(dateSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const addSocialProvider = useCallback(
    (socialProvider: SocialProvider) =>
      dispatch(setSocialProvider({ socialProvider })),
    [dispatch]
  );

  const removeSocialProvider = useCallback(
    (socialProvider: SocialProvider) =>
      dispatch(deleteSocialProvider({ socialProvider })),
    [dispatch]
  );

  const clearSocialProviders = useCallback(
    () => dispatch(clearSocialProvidersAction()),
    [dispatch]
  );

  const addVideo = useCallback(
    (video: PostVideo) => dispatch(setVideo({ video })),
    [dispatch]
  );

  const addVideoDuration = useCallback(
    (duration: number) => dispatch(setVideoDuration({ duration })),
    [dispatch]
  );

  const addVideoSize = useCallback(
    (width: number, height: number) => dispatch(setVideoSize({ width, height })),
    [dispatch]
  );

  const removeVideo = useCallback(() => dispatch(deleteVideo()), [dispatch]);

  const addImages = useCallback(
    (images: PostImage[]) => dispatch(setImages({ images })),
    [dispatch]
  );

  const updateImage = useCallback(
    (image: PostImage) => dispatch(_updateImage({ image })),
    [dispatch]
  );

  const removeImage = useCallback(
    (image: PostImage) => dispatch(deleteImage({ image })),
    [dispatch]
  );

  const removeAllImages = useCallback(() => dispatch(clearImages()), [
    dispatch,
  ]);

  const addText = useCallback((text: string) => dispatch(setText({ text })), [
    dispatch,
  ]);

  const addEmoji = useCallback((emoji: string) => dispatch(setEmoji({ emoji })), [
    dispatch,
  ]);

  const addHashtag = useCallback((hashtag: string) => dispatch(setHashtag({ hashtag })), [
    dispatch,
  ]);

  const updateMentions = useCallback((offset: number, inputCursorIndex: number) =>
    dispatch(moveMentions({ offset, inputCursorIndex })), [
    dispatch,
  ]);

  const addInputCursorIndex = useCallback(
    (inputCursorIndex: number) =>
      dispatch(setInputCursorIndex({ inputCursorIndex })),
    [dispatch]
  );

  const clear = useCallback(() => dispatch(clearPost()), [dispatch]);

  const reset = useCallback(() => dispatch(resetSocialAction()), [dispatch]);

  const toggleSocialProvider = useCallback(
    (socialProvider: SocialProvider) =>
      socialProvidersEntities[socialProvider.id]
        ? removeSocialProvider(socialProvider)
        : addSocialProvider(socialProvider),
    [addSocialProvider, removeSocialProvider, socialProvidersEntities]
  );

  const selectSocialProvider = useCallback(
    (socialProvider: SocialProvider) => {
      if (!socialProvidersEntities[socialProvider.id]) {
        addSocialProvider(socialProvider);
      }
    },
    [addSocialProvider, socialProvidersEntities]
  );

  const addPostError = useCallback(
    (error: PostError) => dispatch(addPostErrorAction({ error })),
    [dispatch]
  );

  const removePostError = useCallback(
    (socialProviderId: SocialProviderId, type: PostErrorType) =>
      dispatch(removePostErrorAction({ id: `${socialProviderId}-${type}` })),
    [dispatch]
  );

  const addStoryError = useCallback(
    (error: PostError) => dispatch(addStoryErrorAction({ error })),
    [dispatch]
  );

  const removeStoryError = useCallback(
    (socialProviderId: SocialProviderId, type: PostErrorType) =>
      dispatch(removeStoryErrorAction({ id: `${socialProviderId}-${type}` })),
    [dispatch]
  );

  const selectPost = useCallback(
    (post: SocialPost) => dispatch(selectPostAction({ post })),
    [dispatch]
  );

  const clearSelectedPost = useCallback(
    () => dispatch(clearSelectedPostAction()),
    [dispatch]
  );

  const removePostAllMedia = useCallback(() => dispatch(clearPostMedia()), [
    dispatch,
  ]);

  const setSocialAction = useCallback(
    (action: string) => dispatch(setSocialActionAction({ action })),
    [dispatch]
  );

  const setDate = useCallback(
    (date: Moment) => dispatch(setDateAction({ date })),
    [dispatch]
  );

  const removePostMedia = useCallback(
    (image: Media) => dispatch(deletePostMedia({ image })),
    [dispatch]
  );

  const getSocialPosts = useCallback(
    async (
      companyId: string,
      since: string,
      until: string,
      provider: string,
      status: string,
      target: string
    ) => {
      try {
        dispatch(getSocialPostsInitAction());
        turnOnLoading(HISTORY_POST_LOADING);
        const response = await socialPostsApiService.getSocialPosts(
          companyId,
          since,
          until,
          provider,
          status,
          target
        );
        dispatch(getSocialPostsSuccessAction(response));
        turnOffLoading(HISTORY_POST_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(HISTORY_POST_LOADING);
        dispatch(getSocialPostsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  const getAllAdminSocialPosts = useCallback(
    async (
      since: string,
      until: string,
      provider: string,
      status: string,
      companyId: string
    ) => {
      try {
        dispatch(getSocialPostsInitAction());
        turnOnLoading(HISTORY_POST_LOADING);
        const response = await socialPostsApiService.getAllAdminSocialPosts(
          since,
          until,
          provider,
          status,
          companyId
        );
        dispatch(getSocialPostsSuccessAction(response));
        turnOffLoading(HISTORY_POST_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(HISTORY_POST_LOADING);
        dispatch(getSocialPostsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  const deleteSocialPost = useCallback(
    async (companyId: string, postId: string) => {
      try {
        dispatch(deleteSocialPostInitAction());
        const response = await socialPostsApiService.deleteSocialPost(
          companyId,
          postId
        );
        dispatch(deleteSocialPostSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(deleteSocialPostFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const reportSocialPost = useCallback(
    async (companyId: string, postId: string, callback?: () => void) => {
      try {
        dispatch(updateSocialPostInitAction());
        const post = await socialPostsApiService.reportSocialPost(
          companyId,
          postId
        );
        dispatch(updateSocialPostSuccessAction({ post }));
        callback?.();
      } catch (error: any) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const addMention = useCallback(
    (mention: Mentions) =>
      dispatch(addMentionAction({ mention })),
    [dispatch]
  );

  const getHashtags = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getHashtagsInitAction());
        const response = await socialPostsApiService.getHashtags(companyId);
        dispatch(getHashtagsSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getHashtagsFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const postHashtag = useCallback(
    async (companyId: string, name: string, onSuccess?: () => void) => {
      try {
        dispatch(postHashtagInitAction());
        const response = await socialPostsApiService.postHashtag(companyId, name);
        dispatch(postHashtagSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postHashtagFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const deleteHashtag = useCallback(
    async (companyId: string, id: string) => {
      try {
        dispatch(deleteHashtagInitAction());
        const response = await socialPostsApiService.deleteHashtag(companyId, id);
        dispatch(deleteHashtagSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(deleteHashtagFailureAction({ error }));
      }
    },
    [dispatch]
  );

  return {
    socialProviders,
    action,
    hashtags,
    mentions,
    addSocialProvider,
    removeSocialProvider,
    clearSocialProviders,
    toggleSocialProvider,
    selectSocialProvider,
    images,
    text,
    inputCursorIndex,
    video,
    postErrors,
    storyErrors,
    addVideo,
    removeVideo,
    clear,
    addImages,
    removeImage,
    updateImage,
    removeAllImages,
    addText,
    addInputCursorIndex,
    addPostError,
    removePostError,
    addEmoji,
    updateMentions,
    socialPosts,
    selectedPost,
    date,
    selectPost,
    addVideoDuration,
    getSocialPosts,
    getAllAdminSocialPosts,
    deleteSocialPost,
    clearSelectedPost,
    removePostAllMedia,
    removePostMedia,
    reset,
    setSocialAction,
    reportSocialPost,
    addHashtag,
    getHashtags,
    postHashtag,
    deleteHashtag,
    setDate,
    addMention,
    addStoryError,
    removeStoryError,
    addVideoSize,
  };
}
