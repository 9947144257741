import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { MercadolibreSetting } from "../interface/api/post-mercadolibre-bind";
import {
  getMercadolibreFailureAction,
  getMercadolibreInitAction,
  getMercadolibreSuccessAction,
  MercadolibreApiAction,
  postBindMercadolibreFailureAction,
  postBindMercadolibreInitAction,
  postBindMercadolibreSuccessAction,
  postUnbindMercadolibreFailureAction,
  postUnbindMercadolibreInitAction,
  postUnbindMercadolibreSuccessAction,
} from "./api-actions";

export interface MercadolibreState {
  settings: MercadolibreSetting | null;
  error: string;
}

const initialState: MercadolibreState = {
  settings: null,
  error: "",
};

export default produce(
  (
    draft: MercadolibreState = initialState,
    action: MercadolibreApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindMercadolibreSuccessAction.type:
      case getMercadolibreSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindMercadolibreFailureAction.type:
      case postBindMercadolibreFailureAction.type:
      case getMercadolibreFailureAction.type:
      case postUnbindMercadolibreSuccessAction.type:
        draft.settings = null;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindMercadolibreInitAction.type:
      case postUnbindMercadolibreInitAction.type:
      case getMercadolibreInitAction.type:
      default:
        return draft;
    }
  }
);
