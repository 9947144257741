import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CARD_CHECK_LOADING, PLAN_COUPON_LOADING, UPGRADE_PLAN_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { flowApiService } from "./services/api";
import { resetCouponAction } from "./store/actions";
import {
  deleteSubscriptionFailureAction,
  deleteSubscriptionInitAction,
  deleteSubscriptionSuccessAction,
  getCardVerificationFailureAction,
  getCardVerificationInitAction,
  getCardVerificationSuccessAction,
  getFlowCouponFailureAction,
  getFlowCouponInitAction,
  getFlowCouponSuccessAction,
  getStripeCouponFailureAction,
  getStripeCouponInitAction,
  getStripeCouponSuccessAction,
  postStripeCheckPaymentFailureAction,
  postStripeCheckPaymentInitAction,
  postStripeCheckPaymentSuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import { couponSelector } from "./store/selector";

export function usePaymentCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const coupon = useSelector(couponSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  
  const getFlowCoupon = useCallback(
    async (couponId: string, onSuccess?: () => void) => {
      try {
        dispatch(getFlowCouponInitAction());
        turnOnLoading(PLAN_COUPON_LOADING);
        const { coupon } = await flowApiService.getFlowCoupon(couponId);
        dispatch(getFlowCouponSuccessAction({ coupon }));
        turnOffLoading(PLAN_COUPON_LOADING, 500);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(PLAN_COUPON_LOADING);
        console.error(error);
        dispatch(getFlowCouponFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const deleteSubscription = useCallback(
    async (onSuccess?: () => void) => {
      try {
        dispatch(deleteSubscriptionInitAction());
        turnOnLoading(UPGRADE_PLAN_LOADING);
        await flowApiService.deleteSubscription();
        dispatch(deleteSubscriptionSuccessAction());
        turnOffLoading(UPGRADE_PLAN_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess10" }));
        onSuccess?.();
      } catch (error: any) {
        toast.error(intl.formatMessage({ id: "app.toast.error23" }));
        turnOffLoading(UPGRADE_PLAN_LOADING);
        console.error(error);
        dispatch(deleteSubscriptionFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );
  const postFlowSubscription = useCallback(
    async (planId: string, couponId: string | null) => {
      try {
        turnOnLoading(UPGRADE_PLAN_LOADING);
        const response = await flowApiService.postFlowSubscription(planId, couponId);
        turnOffLoading(UPGRADE_PLAN_LOADING, 500);
        return response;
      } catch (error: any) {
        turnOffLoading(UPGRADE_PLAN_LOADING);
        console.error(error);
      }
    },
    [turnOffLoading, turnOnLoading]
  );
  const postStripeSubscription = useCallback(
    async (planId: string, couponId: string | null) => {
      try {
        turnOnLoading(UPGRADE_PLAN_LOADING);
        const response = await flowApiService.postStripeSubscription(planId, couponId);
        turnOffLoading(UPGRADE_PLAN_LOADING, 500);
        return response;
      } catch (error: any) {
        turnOffLoading(UPGRADE_PLAN_LOADING);
        console.error(error);
      }
    },
    [turnOffLoading, turnOnLoading]
  );
  const postStripeCheckSubscription = useCallback(
    async (sessionId: string, subscriptionId: string, onSuccess?: () => void) => {
      try {
        dispatch(postStripeCheckPaymentInitAction());
        await flowApiService.postStripeCheckSubscription(sessionId, subscriptionId);
        dispatch(postStripeCheckPaymentSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postStripeCheckPaymentFailureAction());
      }
    },
    [dispatch]
  );
  const getStripeCoupon = useCallback(
    async (couponId: string, onSuccess?: () => void) => {
      try {
        dispatch(getStripeCouponInitAction());
        turnOnLoading(PLAN_COUPON_LOADING);
        const { coupon } = await flowApiService.getStripeCoupon(couponId);
        dispatch(getStripeCouponSuccessAction({ coupon }));
        turnOffLoading(PLAN_COUPON_LOADING, 500);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(PLAN_COUPON_LOADING);
        console.error(error);
        dispatch(getStripeCouponFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const getCardVerification = useCallback(
    async (cardDigits: string, onSuccess?: () => void) => {
      try {
        dispatch(getCardVerificationInitAction());
        turnOnLoading(CARD_CHECK_LOADING);
        const { valid } = await flowApiService.getCardVerification(cardDigits);
        dispatch(getCardVerificationSuccessAction({ valid }));
        turnOffLoading(CARD_CHECK_LOADING, 500);
        onSuccess?.();
        return valid;
      } catch (error: any) {
        turnOffLoading(CARD_CHECK_LOADING);
        console.error(error);
        dispatch(getCardVerificationFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const resetCoupon = useCallback(
    async () => {
      dispatch(resetCouponAction());
    },
    [dispatch]
  );

  return {
    coupon,
    postFlowSubscription,
    deleteSubscription,
    getFlowCoupon,
    postStripeSubscription,
    getStripeCoupon,
    postStripeCheckSubscription,
    resetCoupon,
    getCardVerification,
  };
}
