import cn from "classnames";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";
import { FormGroup, InputProps } from "reactstrap";
import { LabelWembii } from "./LabelWembii";

interface Props extends InputProps {
  className?: string;
  label?: string;
  name?: string;
  id?: string;
  required?: boolean;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  mb?: boolean;
  options?: any[];
  value?: any[];
  isLoading?: boolean;
  isClearable?: boolean;
  onChange?: (e: any) => void;
  onInputChange?: (e: any) => void;
}

const customStyles = {
  control: (styles: any) => ({
    ...styles,
    borderColor: "#ced4da",
    boxShadow: "none",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: 0,
    ':hover': {
      ...styles[':hover'],
      borderColor: "#b8e5ff",
      boxShadow: "0 0 0 0.2rem rgb(56 182 255 / 25%)"
    }
  })
}

export const SelectWembii = (props: Props) => {
  const intl = useIntl();
  const {
    className,
    id,
    name,
    label,
    required,
    mb,
    icon,
    iconPosition,
    options,
    value,
    isLoading,
    isClearable,
    onInputChange,
    onChange
  } = props;

  return (
    <FormGroup className={cn("d-flex flex-column position-relative", { "m-0": !mb })}>
      {label && (
        <LabelWembii forid={id} required={required}>
          {label}
        </LabelWembii>
      )}
      <Select
        isMulti
        id={id}
        name={name}
        placeholder="Buscar"
        loadingMessage={() => "Buscando"}
        noOptionsMessage={() => intl.formatMessage({ id: "app.select.noData" })}
        options={options}
        value={value}
        onInputChange={(v) => onInputChange?.(v)}
        onChange={(v) => onChange?.(v)}
        styles={customStyles}
        classNamePrefix="select"
        isClearable={isClearable}
        isLoading={isLoading}
        className={cn(className, {
          "pr-5": icon && iconPosition === "right",
          "pl-5": icon && iconPosition === "left",
        })}
      />  
        {icon && (
          <div
            style={{marginLeft: 200, marginTop: 13}}
            className={cn(
              "position-absolute p-1 h-100 d-flex align-items-center z-index-50 ",
              {
                "right-9": iconPosition === "right",
                "left-9": iconPosition === "left",
                "mt-6": label,
              }
            )}
          >
            {icon}
          </div>
        )}
    </FormGroup>
  );
};
