import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { SmtpSetting } from "../interface/api/post-smtp-bind";

export const postUnbindSmtpInitAction = createAction(
  "[Smtp] Unbind Smtp Init"
);
export const postUnbindSmtpSuccessAction = createAction(
  "[Smtp] Unbind Smtp Success",
  props<{ asUser: boolean }>()
);
export const postUnbindSmtpFailureAction = createAction(
  "[Smtp] Unbind Smtp Failure",
  props<{ error: string }>()
);

export const postBindSmtpInitAction = createAction(
  "[Smtp] Bind Smtp Init"
);
export const postBindSmtpSuccessAction = createAction(
  "[Smtp] Bind Smtp Success",
  props<{ settings: SmtpSetting, asUser: boolean }>()
);
export const postBindSmtpFailureAction = createAction(
  "[Smtp] Bind Smtp Failure",
  props<{ error: string }>()
);

export const getSmtpInitAction = createAction(
  "[Smtp] Get Smtp Settings Init"
);
export const getSmtpSuccessAction = createAction(
  "[Smtp] Get Smtp Settings Success",
  props<{ settings: SmtpSetting, asUser: boolean }>()
);
export const getSmtpFailureAction = createAction(
  "[Smtp] Get Smtp Settings Failure",
  props<{ error: string }>()
);

export const getSmtpConnectionInitAction = createAction(
  "[Smtp] Get Smtp Connection Init"
);
export const getSmtpConnectionSuccessAction = createAction(
  "[Smtp] Get Smtp Connection Success"
);
export const getSmtpConnectionFailureAction = createAction(
  "[Smtp] Get Smtp Connection Failure",
  props<{ errorCode: number | null, errorMessage: string | null }>()
);

export const postSmtpEmailInitAction = createAction(
  "[Smtp] Post Smtp Email Init"
);
export const postSmtpEmailSuccessAction = createAction(
  "[Smtp] Post Smtp Email Success"
);
export const postSmtpEmailFailureAction = createAction(
  "[Smtp] Post Smtp Email Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindSmtpInitAction,
  postUnbindSmtpSuccessAction,
  postUnbindSmtpFailureAction,
  postBindSmtpInitAction,
  postBindSmtpSuccessAction,
  postBindSmtpFailureAction,
  getSmtpInitAction,
  getSmtpSuccessAction,
  getSmtpFailureAction,
  getSmtpConnectionInitAction,
  getSmtpConnectionSuccessAction,
  getSmtpConnectionFailureAction,
  postSmtpEmailInitAction,
  postSmtpEmailSuccessAction,
  postSmtpEmailFailureAction,
});

export type SmtpApiAction = typeof actions;
