import {
  GetInstagramAccounts,
  InstagramAccount,
} from "../interface/api/get-instagram-accounts";
import {
  InstagramMenssegerSetting,
  PostInstagramBindResponse,
} from "../interface/api/post-instagram-bind";

export const instagramSettingMapper = {
  mapResponseToInstagramSetting(
    response: PostInstagramBindResponse
  ): InstagramMenssegerSetting {
    const { instagramMenssegerSetting } = response?.payload;
    return instagramMenssegerSetting;
  },

  mapResponseToInstagramAccounts(
    response: GetInstagramAccounts
  ): InstagramAccount[] {
    const { accounts } = response?.payload;
    return accounts;
  },
};
