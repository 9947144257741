import React, { useCallback, useState } from "react";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useConfigCore } from "../../../cores/config";
import { CommentType } from "../../../cores/config/interfaces/api/get-comment-types";
import { COMMENT_TYPES } from "../../../constants/internalModal.constant";
import InternalModal from "../../layout/InternalModal";
import { useGlobalCore } from "../../../cores/globals";
import TypeForm from "./TypeForm";

interface Props {
  onUpdate?: () => void;
}

export default function CommentTypes({ onUpdate }: Props) {
  const intl = useIntl();
  const { commentTypes } = useConfigCore();
  const { turnOffInternalModal, isInternalModal, turnOnInternalModal } = useGlobalCore();
  const [type, setType] = useState<CommentType | null>(null);

  const editType = useCallback((type: CommentType) => {
    turnOnInternalModal(COMMENT_TYPES, true);
    setType(type);
  }, [turnOnInternalModal]);

  const createType = useCallback(() => {
    turnOnInternalModal(COMMENT_TYPES, true);
    setType(null);
  }, [turnOnInternalModal]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(COMMENT_TYPES)}
        onClose={() => turnOffInternalModal(COMMENT_TYPES)}
      >
        <TypeForm
          type={type!}
          onUpdate={() => onUpdate?.()}
        />
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 text-center mb-4">
                {intl.formatMessage({ id: "app.contacts.commentTypes" })}
              </h2>

              <Button color="ligth" className="mb-3 border" onClick={createType}>
                {intl.formatMessage({ id: "app.contacts.commentTypes.create" })}
              </Button>

              {commentTypes.length === 0 && (
                <div className="d-flex align-items-center justify-content-center h-100 text-muted">
                  {intl.formatMessage({ id: "app.contacts.noCommentTypes" })}
                </div>
              )}
              {commentTypes.map((type: CommentType) => (
                <div className="d-flex justify-content-between align-items-center my-1" key={type.id}>
                  <Badge
                    pill
                    className="w-100 px-3 py-2 pointer text-left"
                  >
                    <span className="h5 mb-0 font-weight-bold">
                      {type.name}
                    </span>
                  </Badge>
                  <FontAwesomeIcon
                    icon={["fas", "edit"]}
                    size="1x"
                    className="ml-3 pointer"
                    color="gray"
                    onClick={() => editType(type)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}