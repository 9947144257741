import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const productRoot = (state: State) => state.product;

export const productsSelector = createSelector(productRoot, ({ products }) =>
  products.ids.map((id) => products.entities[id])
);

export const productSelector = createSelector(productRoot, ({ product }) => product);
