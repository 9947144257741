import React from "react";
import { IconProps } from "../Facebook/FacebookIcon";
import { sizeReference } from "../WooCommerce/WooCommerceIcon";

export const Office365Icon = (props: IconProps) => {
  const { active, className, size = "1x", color } = props;
  return (
    <svg
      width={sizeReference[size]}
      height={sizeReference[size]}
      className={className}
      version="1.1"
      viewBox="0 0 512 512"
    >
      <g id="_x37_8-microsoft">
        <g>
          <g>
            <path d="M270.912,241.049h223.691V31.708c0-8.26-6.68-14.958-14.914-14.958H270.912V241.049z      M270.912,241.049" fill={color || active ? "#5ACF5F" : "gray"}/>
            <path d="M241.09,241.049V16.75H32.313c-8.236,0-14.916,6.698-14.916,14.958v209.341H241.09z      M241.09,241.049" fill={color || active ? "#F84437" : "gray"}/>
            <path d="M241.09,270.953H17.397v209.343c0,8.251,6.68,14.954,14.916,14.954H241.09V270.953z      M241.09,270.953" fill={color || active ? "#2299F8" : "gray"}/>
            <path d="M270.912,270.953V495.25h208.777c8.234,0,14.914-6.703,14.914-14.954V270.953H270.912z      M270.912,270.953" fill={color || active ? "#FFC107" : "gray"}/>
          </g>
        </g>
      </g>
      <g id="Layer_1"/>
    </svg>
  );
};
