import { SocialPostRules } from "../socialPost/interfaces/postError";

export const APP_ID = process.env.REACT_APP_LINKEDIN_APP_ID;
export const SCOPE =
  "r_emailaddress,r_ads,w_organization_social,rw_ads,r_basicprofile,r_ads_reporting,r_liteprofile,r_organization_social,rw_organization_admin,w_member_social,r_1st_connections_size";

  export const SOCIAL_POST_RULES : SocialPostRules = {
    maxImageSize: parseInt(
      process.env.REACT_APP_LINKED_IN_MAX_IMAGE_SIZE ||
      process.env.REACT_APP_DEFAULT_MAX_IMAGE_SIZE ||
      "100"
    ),
    maxVideoSize: -1,
    maxCharactersAllowed: 1300,
    maxPostPhotoAllowed: 9,
    postMediaRequired: false,
    videoAllowed: false,
  }
