import React from "react";
import { AttachmentProps } from "./AttachmentCard.props";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWebChatCore } from "../../../../../cores/conversations";

const AttachmentCard = ({ data }: AttachmentProps) => {
  const { alt, file, id, type } = data;
  const { handleDeleteAttachment } = useWebChatCore();

  return (
    <div key={id} className="card mx-2 d-flex rounded ">
      <div className="bg-danger w-100 ">
        <button
          type="button"
          onClick={() => handleDeleteAttachment(id)}
          className="text-end d-flex mx-auto btn p-0 text-white"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {type === "image" && (
        <img
          src={file as string}
          alt={alt}
          height={60}
          width={60}
          style={{ width: "60px", height: "60px" }}
        />
      )}
      {type === "video" && (
        <video
          controls
          height={60}
          width={60}
          style={{ width: "60px", height: "60px" }}
        >
          <source src={file as string} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {type === "document" && (
        <div className="shadow rounded border my-auto p-2">
          <p className="text-sm">
            <FontAwesomeIcon icon={faPaperclip} size="sm" className="my-auto" />
            Adjunto
          </p>
        </div>
      )}
      {type === "audio" && (
        <audio controls style={{ width: "60px" }}>
          <source src={file as string} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  );
};

export default AttachmentCard;
