import { produce } from "immer";
import { FacebookAccount } from "../../facebook/interface/api/get-facebook-account";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { FacebookAdsAccount } from "../interface/api/get-facebook-ads-account";
import { FacebookAdsSetting } from "../interface/api/post-facebook-ads-bind";
import {
  FacebookAdsApiAction,
  getFacebookAdsFailureAction,
  getFacebookAdsInitAction,
  getFacebookAdsSuccessAction,
  postBindFacebookAdsFailureAction,
  postBindFacebookAdsInitAction,
  postBindFacebookAdsSuccessAction,
  getFacebookAdsAccountsFailureAction,
  getFacebookAdsAccountsInitAction,
  getFacebookAdsAccountsSuccessAction,
  postUnbindFacebookAdsFailureAction,
  postUnbindFacebookAdsInitAction,
  postUnbindFacebookAdsSuccessAction,
} from "./api-actions";

export interface FacebookAdsState {
  settings: FacebookAdsSetting | null;
  adsAccounts: FacebookAdsAccount[];
  accounts: FacebookAccount[];
  error: string;
}

const initialState: FacebookAdsState = {
  settings: null,
  adsAccounts: [],
  accounts: [],
  error: "",
};

export default produce(
  (
    draft: FacebookAdsState = initialState,
    action: FacebookAdsApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindFacebookAdsSuccessAction.type:
      case getFacebookAdsSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindFacebookAdsFailureAction.type:
      case postBindFacebookAdsFailureAction.type:
      case getFacebookAdsFailureAction.type:
      case getFacebookAdsAccountsFailureAction.type:
        draft.error = action.error;
        return draft;
      case postUnbindFacebookAdsSuccessAction.type:
        draft.settings = null;
        return draft;
      case getFacebookAdsAccountsSuccessAction.type:
        draft.adsAccounts = action.adsAccounts;
        draft.accounts = action.accounts;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindFacebookAdsInitAction.type:
      case postUnbindFacebookAdsInitAction.type:
      case getFacebookAdsInitAction.type:
      case getFacebookAdsAccountsInitAction.type:
      default:
        return draft;
    }
  }
);
