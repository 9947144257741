import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  EDIT_ME_AVATAR_USER_LOADING,
  EDIT_ME_USER_LOADING,
} from "../../constants/loading.constant";
import { FacebookSetting } from "../facebook/interface/api/post-facebook-bind";
import { getFacebookSuccessAction } from "../facebook/store/api-actions";
import { FacebookAdsSetting } from "../facebookAds/interface/api/post-facebook-ads-bind";
import { getFacebookAdsSuccessAction } from "../facebookAds/store/api-actions";
import { useGlobalCore } from "../globals";
import { GoogleSetting } from "../google/interface/api/post-google-bind";
import { getGoogleSuccessAction } from "../google/store/api-actions";
import { InstagramSetting } from "../instagram/interface/api/post-instagram-bind";
import { getInstagramSuccessAction } from "../instagram/store/api-actions";
import { InstagramMenssegerSetting } from "../instagramMensseger/interface/api/post-instagram-bind";
import { getInstagramMenssegerSuccessAction } from "../instagramMensseger/store/api-actions";
import { LinkedInSetting } from "../linkedIn/interface/api/post-linkedin-bind";
import { getLinkedInSuccessAction } from "../linkedIn/store/api-actions";
import { LinkedInBusinessSetting } from "../linkedInBusiness/interface/api/post-linkedinBussiness-bind";
import { getLinkedInBusinessSuccessAction } from "../linkedInBusiness/store/api-actions";
import { MercadolibreSetting } from "../mercadolibre/interface/api/post-mercadolibre-bind";
import { getMercadolibreSuccessAction } from "../mercadolibre/store/api-actions";
import { TwitterSetting } from "../twitter/interface/api/post-twitter-bind";
import { getTwitterSuccessAction } from "../twitter/store/api-actions";
import { WooCommerceSetting } from "../wooCommerce/interface/api/post-woocommerce-bind";
import { getWooCommerceSuccessAction } from "../wooCommerce/store/api-actions";
import { meApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  deselectCompanyAction,
  removeCompanyAction,
  selectCompanyAction,
  setSignAction,
  updateReportsCompanyAction,
} from "./store/actions";
import {
  deleteMeSignFailureAction,
  deleteMeSignInitAction,
  deleteMeSignSuccessAction,
  getMeAreasFailureAction,
  getMeAreasInitAction,
  getMeAreasSuccessAction,
  getMeCompaniesFailureAction,
  getMeCompaniesInitAction,
  getMeCompaniesSuccessAction,
  getMeCompanyFailureAction,
  getMeCompanyInitAction,
  getMeCompanySuccessAction,
  getMeSignsFailureAction,
  getMeSignsInitAction,
  getMeSignsSuccessAction,
  getMeSocialFailureAction,
  getMeSocialInitAction,
  getMeSocialSuccessAction,
  getMeUserFailureAction,
  getMeUserInitAction,
  getMeUserSocialSuccessAction,
  getMeUserSuccessAction,
  postMeLanguageFailureAction,
  postMeLanguageInitAction,
  postMeLanguageSuccessAction,
  postMeSignFailureAction,
  postMeSignInitAction,
  postMeSignSuccessAction,
  putMeAvatarFailureAction,
  putMeAvatarInitAction,
  putMeAvatarSuccessAction,
  putMePasswordFailureAction,
  putMePasswordInitAction,
  putMePasswordSuccessAction,
  putMeSignFailureAction,
  putMeSignInitAction,
  putMeSignSuccessAction,
} from "./store/api-actions";
import {
  areasSelector,
  companiesSelector,
  selectedCompanyIdSelector,
  selectedCompanySelector,
  signSelector,
  signsSelector,
  socialSettingsSelector,
  userSelector,
  userSettingsSelector,
} from "./store/selector";
import { getGmailSuccessAction } from "../gmail/store/api-actions";
import { GmailSetting } from "../gmail/interface/api/post-gmail-bind";
import { PostMeSignRequest } from "./interfaces/api/post-meSign";
import { PutMeSignRequest } from "./interfaces/api/put-meSign";
import { Sign } from "./interfaces/api/get-meSign";
import { getWhatsappSuccessAction } from "../whatsapp/store/api-actions";
import { WhatsappSetting } from "../whatsapp/interface/api/post-whatsapp-bind";

export function useMeCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const companies = useSelector(companiesSelector);
  const selectedCompany = useSelector(selectedCompanySelector);
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const socialSettings = useSelector(socialSettingsSelector);
  const userSettings = useSelector(userSettingsSelector);
  const areas = useSelector(areasSelector);
  const signs = useSelector(signsSelector);
  const sign = useSelector(signSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const getMeUser = useCallback(async () => {
    try {
      dispatch(getMeUserInitAction());
      const user = await meApiService.getMeUser();
      dispatch(getMeUserSuccessAction({ user }));
    } catch (error: any) {
      console.error(error);
      dispatch(getMeUserFailureAction({ error }));
    }
  }, [dispatch]);

  const putMeUser = useCallback(
    async (
      firstName: string,
      lastName: string,
      country: string,
      phoneNumber: string,
      phoneType: string
    ) => {
      try {
        turnOnLoading(EDIT_ME_USER_LOADING);
        dispatch(getMeUserInitAction());
        const user = await meApiService.putMeUser({
          firstName,
          lastName,
          country,
          phoneNumber,
          phoneType,
        });
        dispatch(getMeUserSuccessAction({ user }));
        turnOffLoading(EDIT_ME_USER_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(EDIT_ME_USER_LOADING);
        dispatch(getMeUserFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const putMePassword = useCallback(
    async (password: string, onSuccess?: () => void) => {
      try {
        turnOnLoading(EDIT_ME_USER_LOADING);
        dispatch(putMePasswordInitAction());
        const user = await meApiService.putMePassword({ password });
        dispatch(putMePasswordSuccessAction({ user }));
        turnOffLoading(EDIT_ME_USER_LOADING);
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        turnOffLoading(EDIT_ME_USER_LOADING);
        dispatch(putMePasswordFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const postMeAvatar = useCallback(
    async (avatar: File) => {
      try {
        turnOnLoading(EDIT_ME_AVATAR_USER_LOADING);
        dispatch(putMeAvatarInitAction());
        const user = await meApiService.postMeAvatar(avatar);
        dispatch(putMeAvatarSuccessAction({ user }));
        toast.success(intl.formatMessage({ id: "app.toast.sucess23" }));
        turnOffLoading(EDIT_ME_AVATAR_USER_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(EDIT_ME_AVATAR_USER_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error47" }));
        dispatch(putMeAvatarFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const postMeLanguage = useCallback(
    async (locale: string) => {
      try {
        dispatch(postMeLanguageInitAction());
        const user = await meApiService.postMeLanguage(locale);
        dispatch(postMeLanguageSuccessAction({ user }));
      } catch (error: any) {
        console.error(error);
        dispatch(postMeLanguageFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const getMeCompanies = useCallback(
    async (onSuccess?: () => void) => {
      try {
        dispatch(getMeCompaniesInitAction());
        const companies = await meApiService.getMeCompanies();
        dispatch(getMeCompaniesSuccessAction({ companies }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(getMeCompaniesFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const getMeCompany = useCallback(
    async (
      companyId: string,
      onSuccess?: () => void,
      onFailure?: () => void
    ) => {
      try {
        dispatch(getMeCompanyInitAction());
        const company = await meApiService.getMeCompany(companyId);
        dispatch(getMeCompanySuccessAction({ company }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        onFailure?.();
        dispatch(getMeCompanyFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const getMeSocialSettings = useCallback(
    async (companyId: string) => {
      try {
        if (!companyId) {
          return;
        }
        dispatch(getMeSocialInitAction());
        const social = await meApiService.getMeSocialSetting(companyId);
        dispatch(getMeSocialSuccessAction({ social }));
        dispatch(
          getFacebookSuccessAction({
            settings: social.facebook as FacebookSetting,
          })
        );
        dispatch(
          getFacebookAdsSuccessAction({
            settings: social.facebookAds as FacebookAdsSetting,
          })
        );
        dispatch(
          getInstagramSuccessAction({
            settings: social.instagram as InstagramSetting,
          })
        );
        dispatch(
          getInstagramMenssegerSuccessAction({
            settings: social.instagramMensseger as InstagramMenssegerSetting,
          })
        );
        dispatch(
          getTwitterSuccessAction({
            settings: social.twitter as TwitterSetting,
          })
        );
        dispatch(
          getLinkedInSuccessAction({
            settings: social.linkedIn as LinkedInSetting,
          })
        );
        dispatch(
          getLinkedInBusinessSuccessAction({
            settings: social.linkedInBusiness as LinkedInBusinessSetting,
          })
        );
        dispatch(
          getWooCommerceSuccessAction({
            settings: social.wooCommerce as WooCommerceSetting,
          })
        );
        dispatch(
          getMercadolibreSuccessAction({
            settings: social.mercadoLibre as MercadolibreSetting,
          })
        );
        dispatch(
          getGoogleSuccessAction({
            settings: social.google as GoogleSetting,
          })
        );
        dispatch(
          getGmailSuccessAction({
            settings: social.gmail as GmailSetting,
            asUser: false,
          })
        );
        dispatch(
          getWhatsappSuccessAction({
            settings: social.whatsapp as WhatsappSetting,
          })
        );
      } catch (error: any) {
        console.error(error);
        dispatch(getMeSocialFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const getMeUserSettings = useCallback(async () => {
    try {
      dispatch(getMeSocialInitAction());
      const social = await meApiService.getMeUserSetting();
      dispatch(getMeUserSocialSuccessAction({ social }));
      dispatch(
        getGmailSuccessAction({
          settings: social.gmail as GmailSetting,
          asUser: true,
        })
      );
    } catch (error: any) {
      console.error(error);
      dispatch(getMeSocialFailureAction({ error }));
    }
  }, [dispatch]);

  const getMeAreas = useCallback(
    async (companyId: string) => {
      try {
        if (!companyId) {
          return;
        }
        dispatch(getMeAreasInitAction());
        const areas = await meApiService.getMeAreas(companyId);
        dispatch(getMeAreasSuccessAction({ areas }));
      } catch (error: any) {
        console.error(error);
        dispatch(getMeAreasFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const getMeSigns = useCallback(async () => {
    try {
      dispatch(getMeSignsInitAction());
      const signs = await meApiService.getMeSigns();
      dispatch(getMeSignsSuccessAction({ signs }));
      return signs;
    } catch (error: any) {
      console.error(error);
      dispatch(getMeSignsFailureAction({ error }));
    }
  }, [dispatch]);

  const postMeSign = useCallback(
    async (body: PostMeSignRequest, onSuccess?: () => void) => {
      try {
        dispatch(postMeSignInitAction());
        const sign = await meApiService.postMeSign(body);
        dispatch(postMeSignSuccessAction({ sign }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postMeSignFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const putMeSign = useCallback(
    async (id: string, body: PutMeSignRequest, onSuccess?: () => void) => {
      try {
        dispatch(putMeSignInitAction());
        const sign = await meApiService.putMeSign(id, body);
        dispatch(putMeSignSuccessAction({ sign }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putMeSignFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const deleteMeSign = useCallback(
    async (id: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteMeSignInitAction());
        await meApiService.deleteMeSign(id);
        dispatch(deleteMeSignSuccessAction({ id }));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteMeSignFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const postMeSignImage = useCallback(async (image: File) => {
    try {
      const { link } = await meApiService.postSignImage(image);
      return { data: { link } };
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const selectCompany = useCallback(
    (companyId: string) => {
      dispatch(selectCompanyAction({ companyId }));
    },
    [dispatch]
  );

  const deselectCompany = useCallback(async () => {
    dispatch(deselectCompanyAction());
  }, [dispatch]);

  const updateReportsCompany = useCallback(
    (amount: number) => dispatch(updateReportsCompanyAction({ amount })),
    [dispatch]
  );

  const removeMeCompany = useCallback(
    (companyId: string) => dispatch(removeCompanyAction({ companyId })),
    [dispatch]
  );

  const setSign = useCallback(
    (sign: Sign | null) => {
      dispatch(setSignAction({ sign }));
    },
    [dispatch]
  );

  return {
    getMeUser,
    putMeUser,
    getMeCompany,
    getMeCompanies,
    getMeSocialSettings,
    getMeAreas,
    selectCompany,
    deselectCompany,
    updateReportsCompany,
    removeMeCompany,
    postMeAvatar,
    postMeLanguage,
    putMePassword,
    getMeUserSettings,
    getMeSigns,
    postMeSign,
    putMeSign,
    deleteMeSign,
    setSign,
    postMeSignImage,
    user,
    userPlan: user?.userPlan || null,
    userReseller: user?.reseller || null,
    companies,
    company: selectedCompany?.company || null,
    companyId: selectedCompanyId,
    companyRole: selectedCompany?.role || null,
    companyPlan: selectedCompany?.plan || null,
    socialSettings,
    userSettings,
    areas,
    isAdmin: user?.userRole?.admin,
    isReseller: user?.userRole?.reseller,
    signs,
    sign,
  };
}
