import cn from "classnames";
import _ from "lodash";
import React, { useCallback } from "react";
import { SocialProviderId, SocialProviderRules } from "../../../../cores/socialPost/config";
import { isUrl } from "../../../utils/stringUtils";
import { ableToPost, IconMapper } from "../selector/SocialPostSelector";

interface Props {
  text: string;
}

export const TextTemplateMeter = ({ text }: Props) => {
  const counter = useCallback((id : string) => {
    // Twitter counts any url with 23 characters
    let length = id === SocialProviderId.Twitter
      ? text.split(" ").reduce((prev, curr) =>
        prev + 1 + ((isUrl(curr)) ? 23 : curr.length)
      , 0) - 1
      : text.length;

    return SocialProviderRules[id].maxCharactersAllowed - length;
  }, [text]);

  return (
    <div>
      {ableToPost.map((id) => (
        <span className="p-2 mr-3" key={id}>
          <span className="mr-1">
            {_.isFunction(IconMapper[id]) &&
              IconMapper[id]({ active: true, size: "1x" })}
          </span>
          <span
            className={cn({
              "text-danger": counter(id) < 0
            })}
          >
            {counter(id)}
          </span>
        </span>
      ))}
    </div>
  );
};
