import { GetMailchimpAudiencesResponse, MailchimpAudiencesPayload } from "../interface/api/get-mailchimp-audiences";
import { GetMailchimpLoginResponse } from "../interface/api/get-mailchimp-login";
import { PostMailchimpAudiencePayload, PostMailchimpAudienceResponse } from "../interface/api/post-mailchimp-audience";
import { MailchimpSetting, PostMailchimpBindResponse } from "../interface/api/post-mailchimp-bind";

export const mailchimpSettingMapper = {
  mapResponseToMailchimpSetting(response: PostMailchimpBindResponse): MailchimpSetting {
    const { mailchimpSetting } = response?.payload;
    return mailchimpSetting;
  },
  mapResponseToMailchimpLoginUrl(
    response: GetMailchimpLoginResponse
  ): { url: string } {
    const { url } = response?.payload;
    return { url };
  },
  mapResponseToMailchimpAudiences(
    response: GetMailchimpAudiencesResponse
  ): MailchimpAudiencesPayload {
    const { audiences, token, prefix } = response?.payload;
    return { audiences, token, prefix };
  },
  mapResponseToMailchimpAudience(
    response: PostMailchimpAudienceResponse
  ): PostMailchimpAudiencePayload {
    const { audience } = response?.payload;
    return { audience };
  },
};
