import { useMemo } from "react";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { COLOR_PRIMARY } from "../../../constants/theme";
import { useFacebookCore } from "../../../cores/facebook";
import { useFacebookAdsCore } from "../../../cores/facebookAds";
import { useGoogleCore } from "../../../cores/google";
import { useInstagramCore } from "../../../cores/instagram";
import { useJumpsellerCore } from "../../../cores/jumpseller";
import { useMeCore } from "../../../cores/me";
import { useMercadolibreCore } from "../../../cores/mercadolibre";
import { useRouterCore } from "../../../cores/router";
import { useWooCommerceCore } from "../../../cores/wooCommerce";
import { JumpsellerIcon } from "../social/Jumpseller/JumpsellerIcon";
import { MercadolibreIcon } from "../social/Mercadolibre/MercadolibreIcon";
import { WooCommerceIcon } from "../social/WooCommerce/WooCommerceIcon";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import cn from "classnames";

interface SidebarSubItem {
  activeUrl: string;
  onClick: () => void;
  label: string;
  iconName?: IconName | undefined;
  disabled?: boolean | undefined;
  iconPrefix?: IconPrefix | undefined;
  disabledMessage?: string | undefined;
  icon?: ReactNode;
}

interface Props {
  planNotAllowedMessage: string;
}

const SidebarSubItems = ({ planNotAllowedMessage }: Props) => {
  const {
    routes,
    goToSocialPost,
    goToSocialHistory,
    goToInstagramAnalytics,
    goToFacebookAnalytics,
    goToWooCommerceAnalytics,
    goToGoogleAnalytics,
    goToWebForms,
    goToFacebookForms,
    goToEditWebChat,
    goToWebChat,
    goToWooCommerceOrders,
    goToEditWhatsappChat,
    goToMercadolibreOrders,
    goToFacebookCampaigns,
    goToMercadolibreAnalytics,
    goToContacts,
    goToContactCompanies,
    goToCalendar,
    goToSocialCalendar,
    goToSocialStory,
    goToBusinessBoard,
    goToBusinessBoardAnalytics,
    goToJumpsellerOrders,
    goToTasks,
  } = useRouterCore();

  const intl = useIntl();
  const { companyPlan } = useMeCore();
  const {
    rules: {
      allowWebChat = false,
      allowWhatsappChat = false,
      webFormsAmount = 0,
    } = {},
  } = companyPlan! || { name: "." };

  const { pathname } = useLocation();
  const { settings: facebookSettings } = useFacebookCore();
  const { settings: facebookAdsSettings } = useFacebookAdsCore();
  const { settings: googleSettings } = useGoogleCore();
  const { settings: instagramSettings } = useInstagramCore();
  const { settings: wooSettings } = useWooCommerceCore();
  const { settings: mercadolibreSettings } = useMercadolibreCore();
  const { settings: jumpsellerSettings } = useJumpsellerCore();
  const isFacebookSync = !_.isEmpty(facebookSettings);
  const isFacebookAdsSync = !_.isEmpty(facebookAdsSettings);
  const isInstagramSync = !_.isEmpty(instagramSettings);
  const isGoogleSync = !_.isEmpty(googleSettings);
  const isWooSync = !_.isEmpty(wooSettings);
  const isMercadolibreSync = !_.isEmpty(mercadolibreSettings);
  const isJumpsellerSync = !_.isEmpty(jumpsellerSettings);
  const isWebFormAllow = webFormsAmount > 0;

  const socialSubItems: SidebarSubItem[] = useMemo(() => {
    return [
      {
        activeUrl: routes.socialPost,
        onClick: () => goToSocialPost(""),
        label: intl.formatMessage({ id: "app.sidebar.socialMediaPost" }),
        iconName: "thumbs-up",
      },
      {
        activeUrl: routes.socialStory,
        onClick: () => goToSocialStory(""),
        label: intl.formatMessage({ id: "app.sidebar.socialStory" }),
        iconName: "history",
      },
      {
        activeUrl: routes.socialCalendar,
        onClick: goToSocialCalendar,
        label: intl.formatMessage({ id: "app.sidebar.socialMediaCalendar" }),
        iconName: "calendar",
      },
      {
        activeUrl: routes.socialHistory,
        onClick: goToSocialHistory,
        label: intl.formatMessage({ id: "app.sidebar.socialMediaRecord" }),
        iconName: "history",
      },
    ];
  }, [
    routes.socialPost,
    routes.socialStory,
    routes.socialCalendar,
    routes.socialHistory,
    intl,
    goToSocialHistory,
    goToSocialCalendar,
    goToSocialStory,
    goToSocialPost
  ]);

  const advertisementsSubItems: SidebarSubItem[] = useMemo(() => {
    return [
      {
        activeUrl: routes.facebookCampaigns,
        onClick: goToFacebookCampaigns,
        label: intl.formatMessage({
          id: "app.sidebar.advertisements.FaceAndIns",
        }),
        iconName: "ad",
        disabled: !isFacebookAdsSync,
      },
    ];
  }, [routes.facebookCampaigns, isFacebookAdsSync, intl, goToFacebookCampaigns]);

  const formsSubItems: SidebarSubItem[] = useMemo(() => {
    return [
      {
        activeUrl: routes.webForms,
        onClick: goToWebForms,
        label: intl.formatMessage({ id: "app.sidebar.forms.Web" }),
        iconName: "wpforms",
        iconPrefix: "fab",
        disabled: !isWebFormAllow,
      },
      {
        activeUrl: routes.facebookForm,
        onClick: goToFacebookForms,
        label: intl.formatMessage({ id: "app.sidebar.forms.Facebook" }),
        iconName: "facebook",
        iconPrefix: "fab",
        disabled: !isFacebookSync,
      },
    ];
  }, [
    routes.webForms,
    routes.facebookForm,
    isWebFormAllow,
    isFacebookSync,
    intl,
    goToFacebookForms,
    goToWebForms
  ]);

  const chatSubItems: SidebarSubItem[] = useMemo(() => {
    return [
      {
        activeUrl: routes.webChat,
        onClick: goToWebChat,
        label: intl.formatMessage({ id: "app.sidebar.chat.conversations" }),
        iconName: "comments",
        disabled: !allowWebChat,
        disabledMessage: planNotAllowedMessage,
      },
      {
        activeUrl: routes.editWebChat,
        onClick: goToEditWebChat,
        label: intl.formatMessage({ id: "app.sidebar.chat.WebChatConfig" }),
        iconName: "cog",
        disabled: !allowWebChat,
        disabledMessage: planNotAllowedMessage,
      },
      {
        activeUrl: routes.editWhatsappChat,
        onClick: goToEditWhatsappChat,
        label: intl.formatMessage({
          id: "app.sidebar.chat.whatsappConfig",
        }),
        iconPrefix: "fab",
        iconName: "whatsapp",
        disabled: !allowWhatsappChat,
        disabledMessage: planNotAllowedMessage,
      },
    ];
  }, [
    routes.webChat,
    routes.editWebChat,
    routes.editWhatsappChat,
    allowWebChat,
    allowWhatsappChat,
    planNotAllowedMessage,
    intl,
    goToWebChat,
    goToEditWhatsappChat,
    goToEditWebChat
  ]);

  const onlineSellsSubItems: SidebarSubItem[] = useMemo(() => {
    return [
      {
        activeUrl: routes.wooCommerceOrders,
        onClick: goToWooCommerceOrders,
        label: intl.formatMessage({
          id: "app.sidebar.OnlineSales.WooCommerce",
        }),
        icon: (
          <WooCommerceIcon
            color={
              pathname === routes.wooCommerceOrders ? COLOR_PRIMARY : "grey"
            }
            className={cn("mr-3", { "text-black-50": !isWooSync })}
            size="1x"
          />
        ),
        disabled: !isWooSync,
      },
      {
        activeUrl: routes.mercadolibreOrders,
        onClick: goToMercadolibreOrders,
        label: intl.formatMessage({
          id: "app.sidebar.OnlineSales.Mercadolibre",
        }),
        icon: (
          <MercadolibreIcon
            color={
              pathname === routes.mercadolibreOrders ? COLOR_PRIMARY : "grey"
            }
            className={cn("mr-3", {
              "text-black-50": !isMercadolibreSync,
            })}
            size="1x"
          />
        ),
        disabled: !isMercadolibreSync,
      },
      {
        activeUrl: routes.jumpsellerOrders,
        onClick: goToJumpsellerOrders,
        label: intl.formatMessage({
          id: "app.sidebar.OnlineSales.Jumpseller",
        }),
        icon: (
          <JumpsellerIcon
            color={
              pathname === routes.jumpsellerOrders ? COLOR_PRIMARY : "grey"
            }
            className={cn("mr-3", {
              "text-black-50": !isJumpsellerSync,
            })}
            size="1x"
          />
        ),
        disabled: !isJumpsellerSync,
      },
    ];
  }, [
    routes.wooCommerceOrders,
    routes.jumpsellerOrders,
    routes.mercadolibreOrders,
    intl,
    isJumpsellerSync,
    isMercadolibreSync,
    isWooSync,
    pathname,
    goToJumpsellerOrders,
    goToMercadolibreOrders,
    goToWooCommerceOrders
  ]);

  const crmSubItems: SidebarSubItem[] = useMemo(() => {
    return [
      {
        onClick: goToTasks,
        label: intl.formatMessage({ id: "app.sidebar.tasks" }),
        iconName: "tasks",
        activeUrl: routes.tasks,
      },
      {
        activeUrl: routes.calendar,
        onClick: goToCalendar,
        label: intl.formatMessage({ id: "app.sidebar.meetings" }),
        iconName: "calendar",
      },
      {
        onClick: goToBusinessBoard,
        label: intl.formatMessage({ id: "app.sidebar.crm.business" }),
        iconName: "briefcase",
        activeUrl: routes.businessBoard,
      },
      {
        onClick: goToBusinessBoardAnalytics,
        label: intl.formatMessage({ id: "app.sidebar.crm.metrics" }),
        iconName: "chart-bar",
        activeUrl: routes.businessBoardAnalytics,
      },
    ];
  }, [
    routes.businessBoardAnalytics,
    routes.businessBoard,
    routes.calendar,
    routes.tasks,
    intl,
    goToTasks,
    goToCalendar,
    goToBusinessBoard,
    goToBusinessBoardAnalytics
  ]);

  const databaseSubItems: SidebarSubItem[] = useMemo(() => {
    return [
      {
        activeUrl: routes.contacts,
        onClick: goToContacts,
        label: intl.formatMessage({ id: "app.sidebar.contacts" }),
        iconName: "address-book",
      },
      {
        activeUrl: routes.contactCompanies,
        onClick: goToContactCompanies,
        label: intl.formatMessage({ id: "app.sidebar.contact.companies" }),
        iconName: "building",
      },
    ];
  }, [routes.contacts, routes.contactCompanies, intl, goToContactCompanies, goToContacts]);

  const metricsSubItems: SidebarSubItem[] = useMemo(() => {
    return [
      {
        activeUrl: routes.facebookAnalytics,
        onClick: goToFacebookAnalytics,
        label: intl.formatMessage({ id: "app.sidebar.metrics.Facebook" }),
        iconName: "facebook",
        iconPrefix: "fab",
        disabled: !isFacebookSync,
      },
      {
        activeUrl: routes.instagramAnalytics,
        onClick: goToInstagramAnalytics,
        label: intl.formatMessage({ id: "app.sidebar.metrics.Instagram" }),
        iconName: "instagram",
        iconPrefix: "fab",
        disabled: !isInstagramSync,
      },
      {
        activeUrl: routes.googleAnalytics,
        onClick: goToGoogleAnalytics,
        label: intl.formatMessage({
          id: "app.sidebar.metrics.GoogleAnalytics",
        }),
        iconName: "google",
        iconPrefix: "fab",
        disabled: !isGoogleSync,
      },
      {
        activeUrl: routes.wooCommerceAnalytics,
        onClick: goToWooCommerceAnalytics,
        label: intl.formatMessage({
          id: "app.sidebar.metrics.WooCommerce",
        }),
        icon: (
          <WooCommerceIcon
            color={
              pathname === routes.wooCommerceAnalytics ? COLOR_PRIMARY : "grey"
            }
            className={cn("mr-3", { "text-black-50": !isWooSync })}
            size="1x"
          />
        ),
        disabled: !isWooSync,
      },
      {
        activeUrl: routes.mercadolibreAnalytics,
        onClick: goToMercadolibreAnalytics,
        label: intl.formatMessage({ id: "app.sidebar.metrics.MercLibre" }),
        icon: (
          <MercadolibreIcon
            color={
              pathname === routes.mercadolibreAnalytics ? COLOR_PRIMARY : "grey"
            }
            className={cn("mr-3", {
              "text-black-50": !isMercadolibreSync,
            })}
            size="1x"
          />
        ),
        disabled: !isMercadolibreSync,
      },
    ];
  }, [
    routes.mercadolibreAnalytics,
    routes.wooCommerceAnalytics,
    routes.googleAnalytics,
    routes.facebookAnalytics,
    routes.instagramAnalytics,
    intl,
    isMercadolibreSync,
    isGoogleSync,
    isInstagramSync,
    isWooSync,
    isFacebookSync,
    pathname,
    goToMercadolibreAnalytics,
    goToWooCommerceAnalytics,
    goToFacebookAnalytics,
    goToGoogleAnalytics,
    goToInstagramAnalytics
  ]);

  return {
    socialSubItems,
    advertisementsSubItems,
    formsSubItems,
    chatSubItems,
    onlineSellsSubItems,
    crmSubItems,
    databaseSubItems,
    metricsSubItems,
  };
};

export default SidebarSubItems;
