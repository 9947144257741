import AR from "../../assets/flags/ar.svg";
import BO from "../../assets/flags/bo.svg";
import BR from "../../assets/flags/br.svg";
import CL from "../../assets/flags/cl.svg";
import CO from "../../assets/flags/co.svg";
import CR from "../../assets/flags/cr.svg";
import DO from "../../assets/flags/do.svg";
import EC from "../../assets/flags/ec.svg";
import ES from "../../assets/flags/es.svg";
import GT from "../../assets/flags/gt.svg";
import HN from "../../assets/flags/hn.svg";
import MX from "../../assets/flags/mx.svg";
import NI from "../../assets/flags/ni.svg";
import PA from "../../assets/flags/pa.svg";
import PE from "../../assets/flags/pe.svg";
import PY from "../../assets/flags/py.svg";
import SV from "../../assets/flags/sv.svg";
import US from "../../assets/flags/us.svg";
import UY from "../../assets/flags/uy.svg";
import VE from "../../assets/flags/ve.svg";
import React from "react";

interface Props {
  flag: string;
  width?: number;
}

const flags: Record<string, string> = {
  ar: AR,
  bo: BO,
  br: BR,
  cl: CL,
  co: CO,
  cr: CR,
  do: DO,
  ec: EC,
  es: ES,
  gt: GT,
  hn: HN,
  mx: MX,
  ni: NI,
  pa: PA,
  pe: PE,
  py: PY,
  sv: SV,
  us: US,
  uy: UY,
  ve: VE,
  pt: BR,
  en: US
};

export const Flag = ({ flag, width = 30 }: Props) => {
  return (
    <img
      src={flags[flag]}
      alt={`flag${flag}`}
      className="rounded"
      style={{ width }}
    />
  );
};
