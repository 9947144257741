import React, { useCallback, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import FieldForm from "../FieldForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InternalModal from "../../../layout/InternalModal";
import { CUSTOM_FIELD } from "../../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useContactCore } from "../../../../cores/contacts";
import { CustomField } from "../../../../cores/contacts/interfaces/api/get-custom-fields";

export default function CustomFields() {
  const intl = useIntl();
  const { customFields } = useContactCore();
  const {
    turnOnInternalModal,
    turnOffInternalModal,
    isInternalModal,
  } = useGlobalCore();
  const [field, setField] = useState<CustomField | null>(null);

  const editTag = useCallback((field: CustomField) => {
    turnOnInternalModal(CUSTOM_FIELD, true);
    setField(field);
  }, [turnOnInternalModal]);

  const createTag = useCallback(() => {
    turnOnInternalModal(CUSTOM_FIELD, true);
    setField(null);
  }, [turnOnInternalModal]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(CUSTOM_FIELD)}
        onClose={() => turnOffInternalModal(CUSTOM_FIELD)}
      >
        <FieldForm field={field!} />
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 text-center mb-4">
                {intl.formatMessage({ id: "app.contacts.customFields" })}
              </h2>

              <Button color="ligth" className="mb-3 border" onClick={createTag}>
                {intl.formatMessage({ id: "app.contacts.customFields.create.new" })}
              </Button>

              {customFields.length === 0 && (
                <div className="d-flex align-items-center justify-content-center h-100 text-muted">
                  {intl.formatMessage({ id: "app.contacts.noCustomFields" })}
                </div>
              )}
              {customFields.map((field) => (
                <div className="d-flex justify-content-between align-items-center my-1" key={field.id}>
                  {field.name}

                  <FontAwesomeIcon
                    icon={["fas", "edit"]}
                    size="1x"
                    className="ml-3 pointer"
                    color="gray"
                    onClick={() => editTag(field)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
