import cn from "classnames";
import React from "react";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { Icon } from "../../companies/icons/Icon";

interface Props {
  amount: number;
  showLabel?: boolean;
  color?: string;
  className?: string;
}

export const ViewLabel = (props: Props) => {
  const { amount = 0, showLabel, color, className } = props;
  return (
    <span className={cn(className, "font-weight-bolder")}>
      <Icon name="eye" color={color || COLOR_PRIMARY} className="mr-2" />
      {showLabel && "Vistas: "} {amount}
    </span>
  );
};
