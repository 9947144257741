import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Conversation } from "../interfaces/api/get-conversations";
import { WebMessage } from "../interfaces/api/get-messages";
import { Area, WebChat } from "../interfaces/api/get-web-chat";
import { Avatar } from "../interfaces/avatar";
import {
  setAttachmentAction,
  addConversationAction,
  addWebMessageAction,
  removeAttachmentFromStageAreaAction,
  setAvatarAction,
  setChatAction,
  setSelectedConversationAction,
  ConversationsAction,
  removeConversationAndUpdateById,
  changeConversationStatusAction,
} from "./actions";
import {
  deleteAreaFailureAction,
  deleteAreaInitAction,
  deleteAreaSuccessAction,
  deleteConversationFailureAction,
  deleteConversationInitAction,
  deleteConversationSuccessAction,
  getChatFailureAction,
  getChatInitAction,
  getChatSuccessAction,
  getConversationsFailureAction,
  getConversationsInitAction,
  getConversationsSuccessAction,
  getMessagesFailureAction,
  getMessagesInitAction,
  getMessagesSuccessAction,
  postAreaFailureAction,
  postAreaInitAction,
  postAreaSuccessAction,
  putAreaFailureAction,
  putAreaInitAction,
  putAreaSuccessAction,
  putChatFailureAction,
  putChatInitAction,
  putChatSuccessAction,
  updateWebConversationOwners,
  WebChatApiAction,
} from "./api-actions";
import { Attachment } from "../interfaces/attachment/attachment.interface";

import { ConversationStatus } from "../../socialChat/types/conversation-status.types";

export interface WebChatState {
  chat: {
    config: WebChat | null;
    areas: Area[];
  };
  conversationStatus: ConversationStatus;
  active: {
    ids: string[];
    entities: Record<string, Conversation>;
  };
  archived: {
    ids: string[];
    entities: Record<string, Conversation>;
  };
  inTrash: {
    ids: string[];
    entities: Record<string, Conversation>;
  };
  selected: Conversation | null;
  messages: {
    ids: string[];
    entities: Record<string, WebMessage>;
  };
  attachments: Attachment[];
  avatar: Avatar | null;
}

const initialState: WebChatState = {
  chat: {
    config: null,
    areas: [],
  },
  conversationStatus: "active",

  attachments: [],
  active: {
    ids: [],
    entities: {},
  },
  archived: {
    ids: [],
    entities: {},
  },
  inTrash: {
    ids: [],
    entities: {},
  },
  selected: null,
  messages: {
    ids: [],
    entities: {},
  },
  avatar: null,
};

const conversationsAdapter = createEntityAdapter<Conversation>(
  (payload) => payload.id
);
const messagesAdapter = createEntityAdapter<WebMessage>(
  (payload) => payload.id
);

export default produce(
  (
    draft: WebChatState = initialState,
    action: ConversationsAction | WebChatApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case setChatAction.type:
        draft.chat.config = action.chat;
        return draft;
      case setAvatarAction.type:
        draft.avatar = action.avatar;
        return draft;
      case setSelectedConversationAction.type:
        if (draft.selected?.id === action.id || !action.id) {
          draft.selected = null;
        } else {
          const conv1 = draft[draft.conversationStatus].entities[action.id];
          draft.selected = conv1;
          conversationsAdapter.updateOne(
            { ...conv1, unreadCount: 0 },
            draft[draft.conversationStatus]
          );
        }
        return draft;
      case updateWebConversationOwners.type:
        const conversation =
          draft[draft.conversationStatus].entities[action.conversationId];

        const participant = conversation.participants.find(
          (participant) => participant.id === action.participantId
        );
        const restParticipants = conversation.participants.filter(
          (participant) => participant.id != action.participantId
        );
        conversationsAdapter.updateOne(
          {
            ...conversation,
            participants: [
              ...restParticipants,
              {
                ...participant,
                owners: action.newOwners,
                id: action.participantId,
                name: `${participant?.name}`,
              },
            ],
          },
          draft[draft.conversationStatus]
        );
        conversationsAdapter.moveToFirstPosition(
          conversation.id,
          draft[draft.conversationStatus]
        );
        return draft;
      case removeConversationAndUpdateById.type:
        const conv1 =
          draft[draft.conversationStatus].entities[action.conversationId];

        conversationsAdapter.removeOne(
          action.conversationId,
          draft[draft.conversationStatus]
        );
        conversationsAdapter.setOne(conv1, draft[action.newStatus]);
        return draft;
      case addWebMessageAction.type:
        let conv2 = draft[draft.conversationStatus].entities[action.id];
        if (conv2) {
          if (draft.selected?.id === action.id) {
            messagesAdapter.setOne(action.message, draft.messages);
          } else {
            conv2 = { ...conv2, unreadCount: conv2.unreadCount + 1 };
          }

          conversationsAdapter.updateOne(
            {
              ...conv2,
              snippet: action.message.message,
              messageType: action.message.messageType,
              timestamp: action.message.timestamp,
            },
            draft[draft.conversationStatus]
          );

          conversationsAdapter.moveToFirstPosition(
            action.id,
            draft[draft.conversationStatus]
          );
        }

        return draft;
      case addConversationAction.type:
        conversationsAdapter.setOne(
          action.conversation,
          draft[draft.conversationStatus]
        );
        conversationsAdapter.moveToFirstPosition(
          action.conversation.id,
          draft[draft.conversationStatus]
        );
        return draft;
      case setAttachmentAction.type:
        draft.attachments = action.attachments;
        return draft;
      case removeAttachmentFromStageAreaAction.type:
        const newAttachmentsArray = draft.attachments.filter(
          (attachment) => attachment.id !== action.attachmentId
        );
        draft.attachments = newAttachmentsArray;
        return draft;
      case getChatSuccessAction.type:
        draft.chat = { config: action.chat, areas: action.areas };
        return draft;
      case putChatSuccessAction.type:
        draft.chat.config = action.chat;
        return draft;
      case getConversationsSuccessAction.type:
        conversationsAdapter.addMany(action.active, draft.active);
        conversationsAdapter.addMany(action.archived, draft.archived);
        conversationsAdapter.addMany(action.inTrash, draft.inTrash);
        return draft;
      case getMessagesSuccessAction.type:
        messagesAdapter.addMany(action.messages, draft.messages);
        return draft;
      case postAreaSuccessAction.type:
        draft.chat.areas = [...draft.chat.areas, action.area];
        return draft;
      case putAreaSuccessAction.type:
        const index3 = draft.chat.areas.findIndex(
          (area) => area.id === action.area.id
        );
        draft.chat.areas[index3] = action.area;
        return draft;
      case deleteAreaSuccessAction.type:
        let areas = [...draft.chat.areas];
        const index4 = draft.chat.areas.findIndex(
          (area) => +area.id === +action.id
        );
        areas.splice(index4, 1);
        draft.chat.areas = areas;
        return draft;
      case deleteConversationSuccessAction.type:
        conversationsAdapter.removeOne(
          action.id,
          draft[action.conversationStatus]
        );
        return draft;

      case resetAction.type:
        return initialState;
      case changeConversationStatusAction.type:
        if (action.conversationStatus === "active") {
          conversationsAdapter.setOne(
            action.conversation,
            draft[draft.conversationStatus]
          );
        } else {
          conversationsAdapter.removeOne(
            action.conversation.id,
            draft[draft.conversationStatus]
          );
        }
        return draft;
      case getChatInitAction.type:
      case getChatFailureAction.type:
      case putChatInitAction.type:
      case putChatFailureAction.type:
      case getConversationsInitAction.type:
      case getConversationsFailureAction.type:
      case getMessagesInitAction.type:
      case getMessagesFailureAction.type:
      case postAreaInitAction.type:
      case postAreaFailureAction.type:
      case putAreaInitAction.type:
      case putAreaFailureAction.type:
      case deleteAreaInitAction.type:
      case deleteAreaFailureAction.type:
      case deleteConversationInitAction.type:
      case deleteConversationFailureAction.type:
      default:
        return draft;
    }
  }
);
