import {
  GetResellersResponse,
  Reseller,
} from "../interfaces/api/get-resellers";
import { PostResellerResponse } from "../interfaces/api/post-reseller";
import { PutResellerResponse } from "../interfaces/api/put-reseller";

export const resellerMapper = {
  mapResponseToReseller(
    response: PostResellerResponse | PutResellerResponse
  ): Reseller {
    return response?.payload?.reseller;
  },

  mapResponseToResellers(response: GetResellersResponse): Reseller[] {
    return response?.payload?.resellers;
  },
};
