import React, { RefObject } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsVisible } from "../../../../hooks/useIsVisible/useIsVisible.hook";

const ScrollButton: React.FC<{
  reference: RefObject<HTMLElement>;
  positionY: number;
}> = ({ reference, positionY }) => {
  const isVisible = useIsVisible(reference);

  return (
    <>
      {!isVisible && (
        <button
          onClick={() =>
            reference.current &&
            reference.current.scrollIntoView({ behavior: "smooth" })
          }
          className="position-absolute p-2 right-0 btn btn-primary"
          style={{ bottom: positionY, borderRadius: "50rem" }}
        >
          <FontAwesomeIcon
            icon={["fas", "chevron-down"]}
            size="1x"
            className="position-relative"
          />
        </button>
      )}
    </>
  );
};

export default ScrollButton;
