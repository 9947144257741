import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { JumpsellerSetting } from "../interface/api/post-jumpseller-bind";
import { getJumpsellerFailureAction, getJumpsellerInitAction, getJumpsellerSuccessAction, JumpsellerApiAction, postBindJumpsellerFailureAction, postBindJumpsellerInitAction, postBindJumpsellerSuccessAction, postUnbindJumpsellerFailureAction, postUnbindJumpsellerInitAction, postUnbindJumpsellerSuccessAction } from "./api-actions";

export interface JumpsellerState {
  settings: JumpsellerSetting | null;
  error: string;
}

const initialState: JumpsellerState = {
  settings: null,
  error: "",
};

export default produce(
  (
    draft: JumpsellerState = initialState,
    action: JumpsellerApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindJumpsellerSuccessAction.type:
      case getJumpsellerSuccessAction.type:
        draft.settings = action.settings;
        return draft;
      case postUnbindJumpsellerFailureAction.type:
      case getJumpsellerFailureAction.type:
      case postUnbindJumpsellerSuccessAction.type:
      case postBindJumpsellerFailureAction.type:
        draft.settings = null;
        return draft;
      case resetAction.type:
        return initialState;
      case postBindJumpsellerInitAction.type:
      case postUnbindJumpsellerInitAction.type:
      case getJumpsellerInitAction.type:
      default:
        return draft;
    }
  }
);
