import React, { useCallback, useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { useGlobalCore } from "../../../cores/globals";
import { Price } from "../../../cores/products/interfaces/api/get-product";
import { PRODUCT_PRICE } from "../../../constants/internalModal.constant";
import { LabelWembii } from "../LabelWembii";
import CurrencySelector from "../boards/CurrencySelector";
import { InputWembii } from "../InputWembii";

interface Props {
  productPrice?: Price | null;
  onAdding?: (arg0: string, arg1: number) => void;
  onEdditing?: (arg0: Price) => void;
}

export default function PriceForm({ productPrice, onAdding, onEdditing }: Props) { 
  const intl = useIntl();
  const { turnOffInternalModal } = useGlobalCore();

  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    if (productPrice) {
      setPrice(`${productPrice.price}`);
      setCurrency(productPrice.currency);
    }
  }, [productPrice]);

  const cleanForm = useCallback(() => {
    setPrice("");
    setCurrency("");
    turnOffInternalModal(PRODUCT_PRICE);
  }, [turnOffInternalModal]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!productPrice) {
      onAdding?.(currency, parseFloat(price));
    } else {
      onEdditing?.({
        ...productPrice,
        currency,
        price: parseFloat(price),
      });
    }
    cleanForm();
  };

  const disabled = useCallback(
    () => !price || !currency,
    [price, currency]
  );

  return (
    <Form className="p-2" onSubmit={onSubmit}>      
      <div className="mb-2">
        <LabelWembii required>
          {intl.formatMessage({ id: "app.products.currency" })}
        </LabelWembii>
        <CurrencySelector
          currency={currency}
          onSubmit={setCurrency}
        />
      </div>
      <InputWembii
        label={intl.formatMessage({ id: "app.products.unitPrice" })}
        type="number"
        name="price"
        step=".01"
        min="0"
        id="price"
        required
        value={price}
        onChange={setPrice}
      />

      <Button color="primary" disabled={disabled()} className="ml-1">
        {productPrice 
          ? intl.formatMessage({ id: "app.contacts.companies.role.update" })
          : intl.formatMessage({ id: "app.contacts.companies.role.assign" })
        }  
      </Button>
    </Form>
  );
}