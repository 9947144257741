import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Label, UncontrolledDropdown } from "reactstrap";
import { v4 } from "uuid";
import { SIGN_EMAIL_FORM, TEMPLATE_EMAIL_FORM } from "../../../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { Sign } from "../../../../cores/me/interfaces/api/get-meSign";
import { useTemplateCore } from "../../../../cores/templates";
import { beforeUploadFile, UploadFileType } from "../../../../cores/utils";
import { INFO_TAGS } from "../../../../cores/templates/constants/infoTags";
import { EMAIL_CONFIG_RULES } from "../../../../cores/email/config";
import { EmailFile } from "../../../../cores/email/interface/emailFile";
import { useEmailCore } from "../../../../cores/email";
import ReactQuill from "react-quill";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { addSign } from "../../../utils/signUtils";

export const EmailTextEditor = () => {
  const intl = useIntl();
  const { emailTemplates } = useTemplateCore();
  const {
    addFiles,
    updateFile,
    setSubject,
    files,
    message,
    setMessage,
    postEmailTextEditorImage
  } = useEmailCore();
  const { setSign, sign: selectedSign, signs } = useMeCore();
  const { isModal, turnOnModal } = useGlobalCore();

  const ref = useRef<any>(null);

  const filesAllowed = useMemo(
    () => [UploadFileType.JPEG, UploadFileType.JPG, UploadFileType.PNG],
    []
  );

  const onChange = async (fls: FileList | null) => {
    const newFiles: EmailFile[] = [];
    if (fls) {
      _.forEach(fls, (file) => {
        const { name, size, lastModified, type } = file;

        const error = !beforeUploadFile(file, EMAIL_CONFIG_RULES.maxFilesSize, []);
        
        const id = v4();
        newFiles.push({
          id,
          lastModified,
          name,
          size,
          type,
          data: file,
          error,
        });
      });
        
      addFiles([...files, ...newFiles]);
    }
  };

  useEffect(() => {
    let totalSize = 0;
    files.forEach((file) => {
      const error = (totalSize + file.size) / 1024 > EMAIL_CONFIG_RULES.maxFilesSize * 1024;
      totalSize += file.size;
      if (error !== file.error) {
        updateFile({ ...file, error });
      }
    });
  }, [files, updateFile]);

  const onClickSign = useCallback(() => {
    turnOnModal(SIGN_EMAIL_FORM, true, Position.LEFT);
  }, [turnOnModal]);

  const onClickTemplate = useCallback(() => {
    turnOnModal(TEMPLATE_EMAIL_FORM, true, Position.LEFT);
  }, [turnOnModal]);

  const addInfoTag = useCallback((tag) => {
    const editor = ref.current.getEditor();
    const unprivilegedEditor = ref.current.makeUnprivilegedEditor(editor);
    const selection = unprivilegedEditor.getSelection()
      ? unprivilegedEditor.getSelection().index
      : 0;

    editor.insertText(selection, tag.label);
  }, [ref]);

  const selectTemplate = useCallback((template) => {
    setSubject(template.subject);
    
    if (selectedSign) {
      setMessage(addSign(template.message, selectedSign));
    } else {
      setMessage(template.message);
    }
  }, [selectedSign, setMessage, setSubject]);

  const selectSign = useCallback((sign) => {
    if (selectedSign) {
      const newMessage = message.replace(selectedSign.sign, "");
      setMessage(addSign(newMessage, sign));
    } else {
      setMessage(addSign(message, sign));
    }

    setSign(sign);
  }, [selectedSign, message, setMessage, setSign]);

  const removeSign = useCallback(() => {
    if (selectedSign) {
      setMessage(message.replace(selectedSign.sign, ""));
    }

    setSign(null);
  }, [selectedSign, message, setMessage,setSign]);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", filesAllowed.join(", "));
    input.click();
    input.onchange = async () => {
      if (input && input.files) {
        const editor = ref.current.getEditor();
        const data = await postEmailTextEditorImage(input.files[0])
        const unprivilegedEditor = ref.current.makeUnprivilegedEditor(editor);
        const selection = unprivilegedEditor.getSelection()
          ? unprivilegedEditor.getSelection().index
          : 0;
        editor.insertEmbed(selection, "image", data?.data.link);
      }
    };
  }, [postEmailTextEditorImage, filesAllowed]);

  const imageDropHandler = useCallback(async (imageDataUrl, type, imageData) => {
    if (filesAllowed.find((file) => file === type)) {
      const file = imageData.toFile();
      const editor = ref.current.getEditor();
      const data = await postEmailTextEditorImage(file)
      const unprivilegedEditor = ref.current.makeUnprivilegedEditor(editor);
      const selection = unprivilegedEditor.getSelection()
        ? unprivilegedEditor.getSelection().index
        : 0;
      editor.insertEmbed(selection, "image", data?.data.link);
    }
  }, [postEmailTextEditorImage, filesAllowed]);

  return (
    <>
      <ReactQuill
        value={message}
        ref={ref}
        className="textEditor"
        onChange={setMessage}
        modules={{
          toolbar: {
            container: [
              ['bold', 'italic', 'underline','strike', 'blockquote'],
              [{ 'align': [] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'header': [] }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              ['link', 'image'],
              ['clean']
            ],
            handlers: {
              image: imageHandler
            }
          },
          imageDropAndPaste: {
            handler: imageDropHandler
          },
          imageActions: {},
          imageFormats: {},
        }}
        formats={[
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'align',
          'color', 'background',
          'header',
          'list', 'bullet',
          'indent',
          'script',
          'link', 'image',
          'clean',
          'width', 'height'
        ]}
      />
      <hr className="my-1" />
      <div className="d-flex justify-content-between py-1 px-3">
        <div className="d-flex">
          <Label for="fileUploader" className="mb-0 mx-1">
            <FontAwesomeIcon
              icon={["fas", "paperclip"]}
              color={files.length > 0 ? COLOR_PRIMARY : "grey"}
              className="mx-1 pointer"
            />
          </Label>
          <UncontrolledDropdown>
            <DropdownToggle nav className="mb-0 mx-1 p-0">
              <FontAwesomeIcon
                icon={["fas", "pen-fancy"]}
                color={isModal(SIGN_EMAIL_FORM) || selectedSign ? COLOR_PRIMARY : "grey"}
                className="mx-1 pointer"
              />
            </DropdownToggle>
            <DropdownMenu right className="border-0 py-3 px-2 shadow option-menu">
              <DropdownItem onClick={onClickSign}>
                <div className="d-flex align-items-center">
                  {intl.formatMessage({ id: "app.sendEmail.sign.manage" })}
                </div>
              </DropdownItem>
              <DropdownItem divider />
              {signs.map((sign: Sign) => (
                <DropdownItem key={sign.id} onClick={() => selectSign(sign)}>
                  <div className="d-flex align-items-center">
                    {sign.id === selectedSign?.id && (
                      <FontAwesomeIcon icon={["fas", "check"]} className="mr-1" />
                    )}
                    {sign.name}
                  </div>
                </DropdownItem>
              ))}
              <DropdownItem onClick={removeSign}>
                <div className="d-flex align-items-center">
                  {!selectedSign && (
                    <FontAwesomeIcon icon={["fas", "check"]} className="mr-1" />
                  )}
                  {intl.formatMessage({ id: "app.sendEmail.noSign" })}
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown>
            <DropdownToggle nav className="mb-0 mx-1 p-0">
              <FontAwesomeIcon
                icon={["fas", "clipboard-list"]}
                color={isModal(TEMPLATE_EMAIL_FORM) ? COLOR_PRIMARY : "grey"}
                className="mx-1 pointer"
              />
            </DropdownToggle>
            <DropdownMenu right className="border-0 py-3 px-2 shadow option-menu">
              <DropdownItem onClick={onClickTemplate}>
                <div className="d-flex align-items-center">
                  {intl.formatMessage({ id: "app.sendEmail.template.manage" })}
                </div>
              </DropdownItem>
              <DropdownItem divider />
              {emailTemplates.map((template) => (
                <DropdownItem key={template.id} onClick={() => selectTemplate(template)}>
                  <div className="d-flex align-items-center">
                    {template.name}
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown>
            <DropdownToggle nav className="mb-0 mx-1 p-0">
              <FontAwesomeIcon
                icon={["fas", "asterisk"]}
                color="grey"
                className="mx-1 pointer"
              />
            </DropdownToggle>
            <DropdownMenu right className="border-0 py-3 px-2 shadow">
              {Object.values(INFO_TAGS).map((tag, i) => (
                <DropdownItem key={i} onClick={() => addInfoTag(tag)}>
                  <div className="d-flex align-items-center">
                    {intl.formatMessage({ id: tag.name })}
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <Input
          type="file"
          id="fileUploader" 
          className="d-none"
          multiple
          onChange={(e) => onChange(e.target.files)}
        />
      </div>
    </>
  );
};
