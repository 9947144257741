import React, { useCallback } from "react";
import { Container, FormGroup } from "reactstrap";
import { useWhatsappChatCore } from "../../../../../cores/whatsappChat";
import { useMeCore } from "../../../../../cores/me";
import { LabelWembii } from "../../../LabelWembii";
import { useIntl } from "react-intl";
import { PanelWembii } from "../../../PanelWembii";
import { TitleWembii } from "../../../TitleWembii";
import { CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL } from "../../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../../cores/globals";
import WhatsappNumberForm from "../modal/WhatsappUserForm";
import { ChatUser } from "../../../../../cores/whatsappChat/interfaces/api/get-whatsapp-chat";
import { useCompanyCore } from "../../../../../cores/company";
import { useContactCore } from "../../../../../cores/contacts";

export default function UsersForm() {
  const intl = useIntl();
  const { company } = useMeCore();
  const { getCompany } = useCompanyCore();
  const { getContactTags } = useContactCore();
  const { chat, postChatUser, putChatUser, deleteChatUser } =
    useWhatsappChatCore();
  const { turnOnModal, turnOffModal } = useGlobalCore();

  const toggleWhatsappNumberSideModal = useCallback(
    (whatsappUser?: ChatUser) => {
      getContactTags(company?.id!);
      getCompany(company?.id!);
      turnOnModal(
        CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL,
        <WhatsappNumberForm
          whatsappUser={whatsappUser}
          onSubmit={(phoneNumber, userId, alias, tag, skipLogin) => {
            if (!whatsappUser) {
              postChatUser(
                company?.id!,
                chat?.config!.id,
                phoneNumber,
                userId,
                alias,
                tag,
                skipLogin
              );
            } else {
              putChatUser(
                company?.id!,
                chat?.config!.id,
                whatsappUser.id,
                phoneNumber,
                userId,
                alias,
                tag,
                skipLogin
              );
            }
            turnOffModal(CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL);
          }}
          onDelete={() => {
            deleteChatUser(company?.id!, chat?.config!.id, whatsappUser?.id!);
            turnOffModal(CONFIGURE_WHATSAPP_NUMBER_SIDE_MODAL);
          }}
        />
      );
    },
    [
      postChatUser,
      putChatUser,
      deleteChatUser,
      turnOffModal,
      turnOnModal,
      getCompany,
      getContactTags,
      company,
      chat,
    ]
  );

  return (
    <Container className="p-0">
      <PanelWembii shadow className="p-4 mb-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.configWspp.titleTxt" })} 
        </TitleWembii>
        <FormGroup>
          <div
            className="border border-white-50 text-dark shadow-sm btn btn-sm"
            color="white"
            id="notifications"
            onClick={() => toggleWhatsappNumberSideModal()}
          >
             {intl.formatMessage({ id: "app.configWsppUsers.phoneConfig" })} 
          </div>
          <div>
            <LabelWembii forid="notifications">
              {intl.formatMessage({ id: "app.configWsppUsers.areasTxt" })} 
            </LabelWembii>
            <div className="d-flex flex-wrap">
              {chat?.whatsappUsers.map((whatsappUser) => (
                <div
                  key={whatsappUser.id}
                  className="border text-dark shadow-sm rounded-0 btn btn-sm"
                  color="gray"
                  onClick={() => toggleWhatsappNumberSideModal(whatsappUser)}
                >
                  {whatsappUser.alias}
                </div>
              ))}
            </div>
          </div>
        </FormGroup>
      </PanelWembii>
    </Container>
  );
}
