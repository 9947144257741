import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { GoogleCalendarSetting } from "../interface/api/post-google-calendar-bind";

export const postUnbindGoogleCalendarInitAction = createAction(
  "[GoogleCalendar] Unbind GoogleCalendar Init"
);
export const postUnbindGoogleCalendarSuccessAction = createAction(
  "[GoogleCalendar] Unbind GoogleCalendar Success"
);
export const postUnbindGoogleCalendarFailureAction = createAction(
  "[GoogleCalendar] Unbind GoogleCalendar Failure",
  props<{ error: string }>()
);

export const postBindGoogleCalendarInitAction = createAction(
  "[GoogleCalendar] Bind GoogleCalendar Init"
);
export const postBindGoogleCalendarSuccessAction = createAction(
  "[GoogleCalendar] Bind GoogleCalendar Success",
  props<{ userSettings: GoogleCalendarSetting }>()
);
export const postBindGoogleCalendarFailureAction = createAction(
  "[GoogleCalendar] Bind GoogleCalendar Failure",
  props<{ error: string }>()
);

export const getGoogleCalendarInitAction = createAction(
  "[GoogleCalendar] Get GoogleCalendar Settings Init"
);
export const getGoogleCalendarSuccessAction = createAction(
  "[GoogleCalendar] Get GoogleCalendar Settings Success",
  props<{ userSettings: GoogleCalendarSetting }>()
);
export const getGoogleCalendarFailureAction = createAction(
  "[GoogleCalendar] Get GoogleCalendar Settings Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindGoogleCalendarInitAction,
  postUnbindGoogleCalendarSuccessAction,
  postUnbindGoogleCalendarFailureAction,
  postBindGoogleCalendarInitAction,
  postBindGoogleCalendarSuccessAction,
  postBindGoogleCalendarFailureAction,
  getGoogleCalendarInitAction,
  getGoogleCalendarSuccessAction,
  getGoogleCalendarFailureAction,
});

export type GoogleCalendarApiAction = typeof actions;
