import cn from "classnames";
import Select from "react-select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { useBoardCore } from "../../../../cores/boards";
import { useIntl } from "react-intl";
import { useCompanyCore } from "../../../../cores/company";
import DatePicker from "react-date-picker";
import { stringToDate } from "../../../../cores/timeUtils";
import { InputWembii } from "../../InputWembii";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LabelWembii } from "../../LabelWembii";
import { useGlobalCore } from "../../../../cores/globals";
import {
  BOARD_CONTACTS_MODAL,
  BOARD_FILTERS_MODAL,
  CONTACT_EXPAND_EDIT_FORM,
  DELETE_BOARD_ITEM_MODAL,
} from "../../../../constants/modal.constant";
import Avatar from "../../Avatar";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { TooltipWembii } from "../../TooltipWembii";
import { getFirstLetters } from "../../../utils/stringUtils";
import ContactList from "./ContactList";
import { UserStatus } from "../../../../cores/user/constants/user-status";
import {
  Board,
  ContactBoard,
} from "../../../../cores/boards/interfaces/api/get-board";
import EditContactForm from "../../contacts/modal/EditContactForm";
import AreYouSure from "../../AreYouSure";
import moment from "moment";
import { FromFeedback2 } from "../../FromFeedback2";
import { Filters } from "../../../../cores/boards/interfaces/PanelFilters";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant";
import { useMeCore } from "../../../../cores/me";
import { useContactCore } from "../../../../cores/contacts";
import { CardStatusOptions } from "../../../../cores/boards/constants/card-type";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { useConfigCore } from "../../../../cores/config";

interface Props {
  onFilter: (arg0: Filters, arg1: () => void) => void;
  onClearFilter: () => void;
  board: Board | null;
}

const InactivityComparatorOptions = [
  { id: 1, label: "Mayor a" },
  { id: 2, label: "Menor a" },
];

export default function FiltersForm(props: Props) {
  const intl = useIntl();
  const { onFilter, onClearFilter, board } = props;
  const { getCommentTypes } = useConfigCore();
  const {
    listNames,
    allTags,
    getLists,
    getCardTags,
    setFilters,
    clearFilters,
    getFilters,
  } = useBoardCore();
  const { getContactTags, getContact } = useContactCore();
  const { company } = useMeCore();
  const { users } = useCompanyCore();
  const { turnOffModal, turnOnModal } = useGlobalCore();
  const [submitted, setSubmitted] = useState(false);
  const [optionsUsers, setOptionsUsers] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedUsers, setSelectedUsers] = useState<
    { value: string; label: string }[]
  >([]);
  const [optionsLists, setOptionsLists] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedLists, setSelectedLists] = useState<
    { value: string; label: string }[]
  >([]);
  const [optionsTags, setOptionsTags] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedTags, setSelectedTags] = useState<
    { value: string; label: string }[]
  >([]);
  const [optionsStatus, setOptionsStatus] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedStatus, setSelectedStatus] = useState<
    { value: string; label: string }[]
  >([]);
  const [assignTo, setAssignTo] = useState<string[]>([]);
  const [contacts, setContacts] = useState<ContactBoard[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [lists, setLists] = useState<string[]>([]);
  const [status, setStatus] = useState<number[]>([]);
  const [createdSince, setCreatedSince] = useState("");
  const [createdUntil, setCreatedUntil] = useState("");
  const [expiresSince, setExpiresSince] = useState("");
  const [expiresUntil, setExpiresUntil] = useState("");
  const [inactivityComparator, setInactivityComparatos] = useState(1);
  const [inactivityDays, setInactivityDays] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    getLists(company?.id!, board?.id!);
    setSubmitted(false);
  }, [getLists, company, board]);

  useEffect(() => {
    const filter = getFilters(board?.id!);
    if (filter) {
      setAssignTo(filter.assignTo!);
      setContacts(filter.contacts!);
      setTags(filter.tags!);
      setLists(filter.lists!);
      setStatus(filter.status!);
      setCreatedSince(filter.createdSince!);
      setCreatedUntil(filter.createdUntil!);
      setExpiresSince(filter.expiresSince!);
      setExpiresUntil(filter.expiresUntil!);
      setText(filter.text!);
      setInactivityComparatos(filter.inactivityComparator!);
      setInactivityDays(filter.inactivityDays!);
    }
  }, [board, getFilters]);

  useEffect(() => {
    getCardTags(company?.id!, board?.id!);
    setSubmitted(false);
  }, [getCardTags, company, board]);

  useEffect(() => {
    setOptionsUsers(
      users.map((user) => {
        return { value: user.id, label: `${user.firstName} ${user.lastName}` };
      })
    );
  }, [users]);

  useEffect(() => {
    setSelectedUsers(
      optionsUsers.filter((option) =>
        assignTo.some((item) => item === option.value)
      )
    );
  }, [assignTo, optionsUsers]);

  useEffect(() => {
    setOptionsLists(
      listNames.map((list) => {
        return { value: list.id, label: list.name };
      })
    );
  }, [listNames]);

  useEffect(() => {
    setOptionsStatus(
      CardStatusOptions.map((status) => {
        return {
          value: `${status.value}`,
          label: intl.formatMessage({ id: status.label }),
        };
      })
    );
  }, [intl]);

  useEffect(() => {
    setSelectedLists(
      optionsLists.filter((option) =>
        lists.some((item) => item === option.value)
      )
    );
  }, [lists, optionsLists]);

  useEffect(() => {
    setSelectedStatus(
      optionsStatus.filter((option) =>
        status.some((item) => item === +option.value)
      )
    );
  }, [status, optionsStatus]);

  useEffect(() => {
    setOptionsTags(
      allTags.map((tag) => {
        return { value: tag.id, label: tag.name };
      })
    );
  }, [allTags]);

  useEffect(() => {
    setSelectedTags(
      optionsTags.filter((option) => tags.some((item) => item === option.value))
    );
  }, [tags, optionsTags]);

  const handleUserFilter = useCallback((value) => {
    let assignTo = [];
    if (value) {
      assignTo = value.map(
        (option: { value: string; label: string }) => option.value
      );
    }

    setAssignTo(assignTo);
  }, []);

  const handleListFilter = useCallback((value) => {
    let lists = [];
    if (value) {
      lists = value.map(
        (option: { value: string; label: string }) => option.value
      );
    }

    setLists(lists);
  }, []);

  const handleStatusFilter = useCallback((value) => {
    let status = [];
    if (value) {
      status = value.map(
        (option: { value: string; label: string }) => +option.value
      );
    }

    setStatus(status);
  }, []);

  const handleTagFilter = useCallback((value) => {
    let tags = [];
    if (value) {
      tags = value.map(
        (option: { value: string; label: string }) => option.value
      );
    }

    setTags(tags);
  }, []);

  const openAssignmentContacts = useCallback(() => {
    turnOnModal(
      BOARD_CONTACTS_MODAL,
      <ContactList
        assignments={contacts!}
        onAssign={(contact) => {
          setContacts([
            ...contacts,
            {
              ...(contact as ContactBoard),
              status: UserStatus.ACTIVE.id,
            },
          ]);
          turnOffModal(BOARD_CONTACTS_MODAL);
        }}
      />,
      Position.LEFT
    );
  }, [turnOnModal, turnOffModal, contacts]);

  const onShowAssignment = useCallback(
    (contact) => {
      getContactTags(company?.id!);
      getCommentTypes(company?.id!);
      getContact(company?.id!, contact.id);
      turnOnModal(
        CONTACT_EXPAND_EDIT_FORM,
        <EditContactForm
          isUnassignedButton={true}
          onDelete={() => {
            turnOnModal(
              DELETE_BOARD_ITEM_MODAL,
              <AreYouSure
                message={`Está seguro de querer desasignar el contacto ${contact.name}`}
                onSubmit={() => {
                  setContacts([
                    ...contacts.filter((ctt) => ctt.id !== contact.id),
                  ]);
                  turnOffModal(DELETE_BOARD_ITEM_MODAL);
                  turnOffModal(CONTACT_EXPAND_EDIT_FORM);
                }}
                onClose={() => turnOffModal(DELETE_BOARD_ITEM_MODAL)}
              />,
              Position.LEFT
            );
          }}
        />,
        Position.LEFT
      );
    },
    [
      turnOnModal,
      turnOffModal,
      getContact,
      company,
      contacts,
      getContactTags,
      getCommentTypes,
    ]
  );

  const areDatesInverted = (since: string, until: string) =>
    moment(since).isAfter(moment(until));

  const isCreatedSinceDateInvalid = useMemo(
    () =>
      areDatesInverted(createdSince!, createdUntil!) ||
      (!createdSince && !!createdUntil),
    [createdSince, createdUntil]
  );

  const isCreatedUntilDateInvalid = useMemo(
    () =>
      areDatesInverted(createdSince!, createdUntil!) ||
      (!createdUntil && !!createdSince),
    [createdSince, createdUntil]
  );

  const isExpiresSinceDateInvalid = useMemo(
    () =>
      areDatesInverted(expiresSince!, expiresUntil!) ||
      (!expiresSince && !!expiresUntil),
    [expiresSince, expiresUntil]
  );

  const isExpiresUntilDateInvalid = useMemo(
    () =>
      areDatesInverted(expiresSince!, expiresUntil!) ||
      (!expiresUntil && !!expiresSince),
    [expiresSince, expiresUntil]
  );

  const invertedDatesFeedback = (since: string, until: string) => (
    <FromFeedback2
      show={areDatesInverted(since, until) && submitted}
      message={intl.formatMessage({ id: "app.metricsGoogle.err2" })}
    />
  );

  const missingDateFeedback = (first: string, second: string) => (
    <FromFeedback2
      show={!first && !!second && submitted}
      message={intl.formatMessage({ id: "app.crmBusiness.dateEmptyErr" })}
    />
  );

  const onApplyFilters = useCallback(
    (e) => {
      e.preventDefault();
      setSubmitted(true);

      if (
        !isCreatedSinceDateInvalid &&
        !isCreatedUntilDateInvalid &&
        !isExpiresSinceDateInvalid &&
        !isExpiresUntilDateInvalid
      ) {
        turnOffModal(BOARD_FILTERS_MODAL);

        const filter = {
          text,
          assignTo,
          contacts,
          tags,
          lists,
          status,
          createdSince,
          createdUntil,
          expiresSince,
          expiresUntil,
          inactivityComparator,
          inactivityDays,
        };

        setFilters({
          id: board?.id!,
          ...filter,
        });
        onFilter?.(filter, () => {
          setSubmitted(false);
        });
      }
    },
    [
      onFilter,
      turnOffModal,
      setFilters,
      text,
      assignTo,
      contacts,
      tags,
      lists,
      status,
      createdSince,
      createdUntil,
      expiresSince,
      expiresUntil,
      board,
      inactivityComparator,
      inactivityDays,
      isCreatedSinceDateInvalid,
      isCreatedUntilDateInvalid,
      isExpiresSinceDateInvalid,
      isExpiresUntilDateInvalid,
    ]
  );

  const onClearFilters = useCallback(() => {
    clearFilters(board?.id!);
    turnOffModal(BOARD_FILTERS_MODAL);
    onClearFilter();
  }, [onClearFilter, clearFilters, turnOffModal, board]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <Form
            className="d-flex flex-column h-100 py-4"
            onSubmit={onApplyFilters}
          >
            <h2 className="h2 text-center pointer">
              {intl.formatMessage({ id: "app.crmBusiness.filters" })}
            </h2>

            <LabelWembii>
              {intl.formatMessage({ id: "app.searchTxt" })}
            </LabelWembii>
            <InputWembii
              type="text"
              placeholder={intl.formatMessage({
                id: "app.crmBusiness.filters.searchTxt",
              })}
              rounded
              value={text}
              onChange={setText}
              icon={
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="1x"
                  color="grey"
                  className="pointer"
                />
              }
            />

            <LabelWembii>
              {intl.formatMessage({ id: "app.activity.toAssign" })}
            </LabelWembii>
            <Select
              isMulti
              id="user"
              name="users"
              options={optionsUsers}
              value={selectedUsers}
              className="mb-3"
              onChange={handleUserFilter}
              placeholder={intl.formatMessage({ id: "app.select.placeholder" })}
              noOptionsMessage={() =>
                intl.formatMessage({ id: "app.select.noData" })
              }
              classNamePrefix="select"
            />

            {board?.type === BoardType.BUSINESS && (
              <>
                <LabelWembii>
                  {intl.formatMessage({ id: "app.crmBusiness.contacts" })}
                </LabelWembii>
                <div className="d-flex flex-wrap mb-3">
                  {contacts.map((contact) => (
                    <div key={contact.id}>
                      <Avatar
                        id={`contact-modal-${contact.id}`}
                        className="text-dark-50 font-weight-bold pointer"
                        label={`${getFirstLetters(contact.name)}`}
                        onClick={() => onShowAssignment(contact)}
                      />
                      <TooltipWembii
                        id={`contact-modal-${contact.id}`}
                        text={contact.name}
                      />
                    </div>
                  ))}
                  <Avatar
                    className="text-dark-50 pointer m-1"
                    onClick={openAssignmentContacts}
                    icon={
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        size="1x"
                        color={COLOR_PRIMARY}
                      />
                    }
                  />
                </div>
              </>
            )}

            <LabelWembii>
              {intl.formatMessage({ id: "app.crmBusiness.list" })}
            </LabelWembii>
            <Select
              isMulti
              id="list"
              name="lists"
              options={optionsLists}
              value={selectedLists}
              className="mb-3"
              onChange={handleListFilter}
              placeholder={intl.formatMessage({ id: "app.select.placeholder" })}
              noOptionsMessage={() =>
                intl.formatMessage({ id: "app.select.noData" })
              }
              classNamePrefix="select"
            />

            <LabelWembii>
              {intl.formatMessage({ id: "app.crmBusiness.status" })}
            </LabelWembii>
            <Select
              isMulti
              id="status"
              name="status"
              options={optionsStatus}
              value={selectedStatus}
              className="mb-3"
              onChange={handleStatusFilter}
              placeholder={intl.formatMessage({ id: "app.select.placeholder" })}
              noOptionsMessage={() =>
                intl.formatMessage({ id: "app.select.noData" })
              }
              classNamePrefix="select"
            />

            <LabelWembii>
              {intl.formatMessage({ id: "app.activity.tags" })}
            </LabelWembii>
            <Select
              isMulti
              id="tag"
              name="tags"
              options={optionsTags}
              value={selectedTags}
              className="mb-3"
              onChange={handleTagFilter}
              placeholder={intl.formatMessage({ id: "app.select.placeholder" })}
              noOptionsMessage={() =>
                intl.formatMessage({ id: "app.select.noData" })
              }
              classNamePrefix="select"
            />

            <LabelWembii>
              {intl.formatMessage({ id: "app.crmBusiness.createdDate" })}
            </LabelWembii>
            <div className="d-flex mb-3">
              <div className="d-flex flex-column mr-1 w-50">
                <DatePicker
                  onChange={(d: any) => setCreatedSince(d)}
                  value={stringToDate(createdSince)}
                  format="dd/MM/y"
                  className={cn("w-100", {
                    "is-invalid": isCreatedSinceDateInvalid,
                  })}
                />
                {missingDateFeedback(createdSince, createdUntil)}
                {invertedDatesFeedback(createdSince, createdUntil)}
              </div>
              <div className="d-flex flex-column ml-1 w-50">
                <DatePicker
                  onChange={(d: any) => setCreatedUntil(d)}
                  value={stringToDate(createdUntil)}
                  format="dd/MM/y"
                  className={cn("w-100", {
                    "is-invalid": isCreatedUntilDateInvalid,
                  })}
                />
                {missingDateFeedback(createdUntil, createdSince)}
              </div>
            </div>

            {board?.type === BoardType.BUSINESS && (
              <>
                <LabelWembii>
                  {intl.formatMessage({ id: "app.crmBusiness.closeDate" })}
                </LabelWembii>
                <div className="d-flex mb-3">
                  <div className="d-flex flex-column mr-1 w-50">
                    <DatePicker
                      onChange={(d: any) => setExpiresSince(d)}
                      value={stringToDate(expiresSince)}
                      format="dd/MM/y"
                      className={cn("w-100", {
                        "is-invalid": isExpiresSinceDateInvalid,
                      })}
                    />
                    {missingDateFeedback(expiresSince, expiresUntil)}
                    {invertedDatesFeedback(expiresSince, expiresUntil)}
                  </div>
                  <div className="d-flex flex-column ml-1 w-50">
                    <DatePicker
                      onChange={(d: any) => setExpiresUntil(d)}
                      value={stringToDate(expiresUntil)}
                      format="dd/MM/y"
                      className={cn("w-100", {
                        "is-invalid": isExpiresUntilDateInvalid,
                      })}
                    />
                    {missingDateFeedback(expiresUntil, expiresSince)}
                  </div>
                </div>

                <LabelWembii>
                  {intl.formatMessage({ id: "app.crmBusiness.inactivityDays" })}
                </LabelWembii>
                <div className="d-flex mb-3">
                  <InputWembii
                    mb={false}
                    type="select"
                    style={{ width: "200" }}
                    className="mr-2"
                    value={inactivityComparator}
                    onChange={setInactivityComparatos}
                  >
                    {InactivityComparatorOptions.map(({ id, label }) => (
                      <option key={id} value={id}>
                        {label}
                      </option>
                    ))}
                    :
                  </InputWembii>
                  <InputWembii
                    type="number"
                    mb={false}
                    style={{ width: "100" }}
                    className="mr-2"
                    value={inactivityDays}
                    onChange={setInactivityDays}
                  />
                  <div className="d-flex align-items-center">Dias</div>
                </div>
              </>
            )}

            <div className="d-flex justify-content-center my-2">
              <Button
                type="button"
                className="mr-1"
                color="secondary"
                onClick={onClearFilters}
              >
                {intl.formatMessage({ id: "app.crmBusiness.clean" })}
              </Button>
              <Button className="ml-1" color="primary">
                {intl.formatMessage({ id: "app.crmBusiness.apply" })}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
