import React from "react";

interface Props {
  title: string;
  videoId: string;
}

export const YoutubeModal = (props: Props) => {
  const { title, videoId } = props;
  return  (
    <div className="mx-3">
      <h4 className="my-3 text-center">
        {title}
      </h4>
      <div className="video-responsive">
        {videoId && (
          <iframe
            className="d-flex justify-content-center mx-2"
            src={`https://www.youtube.com/embed/${videoId}`}
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title={title}
          />
        )}
      </div>
    </div>
  );
};



