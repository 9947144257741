import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { AutomationAction } from "../../../../cores/automations/interfaces/api/get-automation";
import { useAutomationCore } from "../../../../cores/automations";
import { LabelWembii } from "../../LabelWembii";
import { InputWembii } from "../../InputWembii";
import moment from "moment";
import { EmailTextEditor } from "./EmailTextEditor";
import { useEmailCore } from "../../../../cores/email";
import { AutomaticEmailActionBody } from "../../../../cores/automations/interfaces/AutomaticEmailAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { INFO_TAGS } from "../../../../cores/templates/constants/infoTags";
import { ActionTime } from "../../../../cores/automations/enum/ActionTime";
import { useGlobalCore } from "../../../../cores/globals";
import { EditMeUserForm } from "../../editMeUser/EditMeUserForm";
import { ME_USER_EDIT_FORM } from "../../../../constants/modal.constant";

interface Props {
  action: AutomationAction | null;
  type: string;
  onSuccess: () => void;
}

export default function AutomaticEmailActionForm(props: Props) {
  const intl = useIntl();
  const { onSuccess, action, type } = props;
  const { socialSettings, userSettings, company, sign } = useMeCore();
  const { message, subject, setMessage, setSubject } = useEmailCore();
  const { postAction, putAction, deleteAction, automation } =
    useAutomationCore();
  const { turnOnModal } = useGlobalCore();

  const [cursorIndex, setCursorIndex] = useState(0);
  const [periodType, setPeriodType] = useState(ActionTime.DAYS);
  const [quantity, setQuantity] = useState("1");
  const [from, setFrom] = useState(-1);
  const [time, setTime] = useState(moment().format("HH:mm"));

  const fromEmails = useMemo(() => {
    return [
      { settings: userSettings?.gmail, asUser: true, provider: "gmail" },
      { settings: socialSettings?.gmail, asUser: false, provider: "gmail" },
      {
        settings: userSettings?.office365,
        asUser: true,
        provider: "office365",
      },
      {
        settings: socialSettings?.office365,
        asUser: false,
        provider: "office365",
      },
      { settings: userSettings?.smtp, asUser: true, provider: "smtp" },
      { settings: socialSettings?.smtp, asUser: false, provider: "smtp" },
    ].filter((item) => !!item.settings);
  }, [userSettings, socialSettings]);

  useEffect(() => {
    let message = "";
    if (action) {
      if ((action.body as AutomaticEmailActionBody).minutes) {
        setQuantity(`${(action.body as AutomaticEmailActionBody).minutes}`);
        setTime("12:00");
        setPeriodType(ActionTime.MINUTES);
      } else {
        setQuantity(`${(action.body as AutomaticEmailActionBody).days}`);
        setTime((action.body as AutomaticEmailActionBody).time);
        setPeriodType(ActionTime.DAYS);
      }
      setSubject((action.body as AutomaticEmailActionBody).subject);

      const index = fromEmails.findIndex(
        (email) =>
          email.provider ===
            (action.body as AutomaticEmailActionBody).provider &&
          email.asUser === (action.body as AutomaticEmailActionBody).asUser
      );
      setFrom(index);
      message = (action.body as AutomaticEmailActionBody).message;
      if (sign?.sign) {
        message = `${message} ${sign?.sign}`;
      }
    } else {
      if (sign?.sign) {
        message = ` ${sign?.sign}`;
      }
    }
    setMessage(message);
  }, [action, sign, fromEmails, setMessage, setSubject]);

  const onChangeFrom = useCallback(
    (value) => {
      if (value === "sync") {
        turnOnModal(ME_USER_EDIT_FORM, <EditMeUserForm tab={"3"} />);
      } else {
        setFrom(value);
      }
    },
    [turnOnModal]
  );

  const onSubmit = () => {
    const setting = fromEmails[from];
    const body = {
      type,
      body: {
        days: periodType === ActionTime.DAYS ? parseInt(quantity) : null,
        minutes: periodType === ActionTime.MINUTES ? parseInt(quantity) : null,
        time: periodType === ActionTime.DAYS ? time : moment().format("HH:mm"),
        subject,
        message,
        asUser: setting.asUser,
        provider: setting.provider,
      },
    };

    if (action) {
      putAction(company?.id!, automation?.id!, action.id, body, onSuccess);
    } else {
      postAction(company?.id!, automation?.id!, body, onSuccess);
    }
  };

  const disabled = useCallback(
    () => !type || from === -1 || !subject || !message,
    [type, from, subject, message]
  );

  const onDelete = useCallback(() => {
    deleteAction(company?.id!, automation?.id!, action?.id!, onSuccess);
  }, [company, action, automation, deleteAction, onSuccess]);

  const addSubjectInfoTag = useCallback(
    (tag) => {
      setSubject(
        subject.slice(0, cursorIndex) +
          `${tag.label} ` +
          subject.slice(cursorIndex)
      );
    },
    [subject, setSubject, cursorIndex]
  );

  return (
    <>
      <InputWembii
        type="select"
        name="from"
        required
        label={intl.formatMessage({ id: "app.sendEmail.from" })}
        id="from"
        value={from}
        onChange={onChangeFrom}
      >
        <option value="-1">Seleccionar</option>
        {fromEmails.length === 0 && (
          <option value="sync">
            {intl.formatMessage({ id: "app.social.sync.email" })}
          </option>
        )}
        {fromEmails.map((email, index) => (
          <option key={index} value={index}>
            {email.settings?.email}{" "}
            {email.asUser ? "(Cuenta personal)" : "(Cuenta de empresa)"}
          </option>
        ))}
      </InputWembii>

      <div className="row">
        <div className="col">
          <InputWembii
            type="number"
            name="quantity"
            label={intl.formatMessage({
              id: "app.automations.action.automaticEmail.sendEmail",
            })}
            value={quantity}
            required
            className="pr-2"
            onChange={setQuantity}
            min="1"
          />
        </div>
        <div className="col d-flex align-items-end">
          <InputWembii
            type="select"
            name="timeType"
            onChange={setPeriodType}
            value={periodType}
          >
            <option value={ActionTime.DAYS}>
              {intl.formatMessage({
                id: "app.automations.action.automaticEmail.days",
              })}
            </option>
            <option value={ActionTime.MINUTES}>
              {intl.formatMessage({
                id: "app.automations.action.automaticEmail.minutes",
              })}
            </option>
          </InputWembii>
        </div>
        <div className="col">
          <InputWembii
            type="time"
            name="time"
            disabled={periodType === ActionTime.MINUTES}
            required
            label={intl.formatMessage({ id: "app.crmTasksModal.date1" })}
            value={time}
            onChange={setTime}
          />
        </div>
      </div>

      <InputWembii
        label={intl.formatMessage({ id: "app.sendEmail.subject" })}
        type="text"
        name="subject"
        id="subject"
        required
        placeholder={intl.formatMessage({ id: "app.sendEmail.subject" })}
        value={subject}
        onChange={(value) => setSubject(value)}
        onKeyUp={(ev: any) => setCursorIndex(ev.target.selectionStart)}
        onClick={(ev: any) => setCursorIndex(ev.target.selectionStart)}
        icon={
          <div className="border-left pl-2">
            <UncontrolledDropdown>
              <DropdownToggle nav className="mb-0 mx-1 p-0">
                <FontAwesomeIcon
                  icon={["fas", "asterisk"]}
                  color="grey"
                  className="mx-1 pointer"
                />
              </DropdownToggle>
              <DropdownMenu right className="border-0 py-3 px-2 shadow">
                {Object.values(INFO_TAGS).map((tag, i) => (
                  <DropdownItem key={i} onClick={() => addSubjectInfoTag(tag)}>
                    <div className="d-flex align-items-center">
                      {intl.formatMessage({ id: tag.name })}
                    </div>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        }
        iconPosition="right"
      />

      <LabelWembii required>
        {intl.formatMessage({ id: "app.sendEmail.message" })}
      </LabelWembii>
      <div className="pt-2 px-2 wembii-border-small">
        <EmailTextEditor />
      </div>

      {action && (
        <Button color="danger" className="mr-1 mt-2" onClick={onDelete}>
          {intl.formatMessage({ id: "app.automations.automation.delete" })}
        </Button>
      )}
      <Button
        color="primary"
        disabled={disabled()}
        className="ml-1 mt-2"
        onClick={onSubmit}
      >
        {action
          ? intl.formatMessage({ id: "app.automations.automation.update" })
          : intl.formatMessage({ id: "app.automations.automation.create" })}
      </Button>
    </>
  );
}
