import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  GOOGLE_CALENDAR_ME_GET_LOADING,
  GOOGLE_CALENDAR_ME_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useGoogleCalendarCore } from "../../../../cores/googleCalendar";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import { GoogleButton } from "../../social/Google/GoogleButton";
import { GoogleCalendarIcon } from "../../social/GoogleCalendar/GoogleCalendarIcon";
import SocialRow from "./SocialRow";

export const GoogleCalendarBindRow: React.FC<{
  onlySyncText?: boolean;
  removeShadow?: boolean;
}> = ({ onlySyncText, removeShadow }) => {
  const intl = useIntl();
  const { get, userSettings, unbind, login } = useGoogleCalendarCore();
  const { company, getMeUserSettings } = useMeCore();
  const { isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get, login]);

  const remainingBindDays = useMemo(
    () =>
      userSettings?.expiresIn
        ? daysRemainingFromToday(userSettings?.expiresIn)
        : null,
    [userSettings]
  );

  const canBind = useMemo(
    () => remainingBindDays && remainingBindDays <= 10,
    [remainingBindDays]
  );

  const isActive = useMemo(
    (): boolean => !_.isEmpty(userSettings),
    [userSettings]
  );

  const handleBind = useCallback(async () => {
    const loginUrl = await login(company!.id);
    if (loginUrl) window.open(loginUrl, "_self");
  }, [company, login]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, () => {
      getMeUserSettings();
    });
  }, [company, unbind, getMeUserSettings]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.GoogleCalendar,
      name: SocialProviderName.GoogleCalendar,
      icon: <GoogleCalendarIcon size="2x" active={isActive} />,
      displayName: userSettings?.name,
      avatar: userSettings?.avatarUrl,
      email: userSettings?.email,
    }),
    [userSettings, isActive]
  );

  return (
    <SocialRow
      removeShadow={removeShadow}
      social={socialProvider}
      bindLoading={isLoading(GOOGLE_CALENDAR_ME_SYNC_LOADING)}
      getLoading={isLoading(GOOGLE_CALENDAR_ME_GET_LOADING)}
      isSync={isActive}
    >
      <div className="w-100 px-1">
        {canBind && (
          <div className="small text-danger mb-1 d-flex">
            <FontAwesomeIcon
              size={"2x"}
              icon={["fas", "exclamation-circle"]}
              className="mr-2"
            />
            {intl.formatMessage(
              { id: "app.home.expiration" },
              {
                remainingDays: remainingBindDays,
              }
            )}
          </div>
        )}
        <GoogleButton
          onlySyncText={onlySyncText}
          isActive={isActive}
          onClick={!isActive ? handleBind : handleUnbind}
        />
      </div>
    </SocialRow>
  );
};
