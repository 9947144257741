import socialApiClient from "../../helpers/services/social-api-client";
import { GetMailchimpAudiencesResponse } from "../interface/api/get-mailchimp-audiences";
import { GetMailchimpBindResponse } from "../interface/api/get-mailchimp-bind";
import { GetMailchimpLoginResponse } from "../interface/api/get-mailchimp-login";
import { PostMailchimpAudienceRequest, PostMailchimpAudienceResponse } from "../interface/api/post-mailchimp-audience";
import { PostMailchimpBindRequest, PostMailchimpBindResponse } from "../interface/api/post-mailchimp-bind";
import { mailchimpSettingMapper } from "./mapper";

export const mailchimpApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`mailchimp/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, token: string, prefix: string, audienceId: string) {
    const body: PostMailchimpBindRequest = {
      token,
      prefix,
      audienceId
    }
    return socialApiClient()
      .post<PostMailchimpBindResponse>(`mailchimp/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) => mailchimpSettingMapper.mapResponseToMailchimpSetting(data));
  },
  getAudiences(companyId: string, code: string) {
    return socialApiClient()
      .get<GetMailchimpAudiencesResponse>(`mailchimp/audiences`, {
        params: { companyId, code },
      })
      .then(({ data }) => mailchimpSettingMapper.mapResponseToMailchimpAudiences(data));
  },
  postAudience(companyId: string, name: string, token: string, prefix: string) {
    const body: PostMailchimpAudienceRequest = {
      token,
      prefix,
      name
    }
    return socialApiClient()
      .post<PostMailchimpAudienceResponse>(`mailchimp/audience`, body, {
        params: { companyId },
      })
      .then(({ data }) => mailchimpSettingMapper.mapResponseToMailchimpAudience(data));
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetMailchimpBindResponse>(`mailchimp`, {
        params: { companyId },
      })
      .then(({ data }) => mailchimpSettingMapper.mapResponseToMailchimpSetting(data));
  },
  syncContacts(companyId: string) {
    return socialApiClient()
      .post(`mailchimp/sync/contacts`, {}, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  login(companyId: string) {
    return socialApiClient()
      .get<GetMailchimpLoginResponse>(`mailchimp/login`, {
        params: { companyId },
      })
      .then(({ data }) => mailchimpSettingMapper.mapResponseToMailchimpLoginUrl(data));
  },
};
