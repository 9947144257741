import React, { useCallback, useMemo, useRef } from "react";
import ImageUploader from "react-images-upload";
import { useIntl } from "react-intl";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { PostImage } from "../../../../cores/socialPost/interfaces/postImage";
import { v4 } from "uuid";
import { getAspectRatio } from "../../../../cores/utils";
import { SocialProviderRules } from "../../../../cores/socialPost/config";
import AreYouSure from "../../AreYouSure";
import { SOCIAL_POST_TYPES } from "../../../../cores/socialPost/consts/socialPostType.constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CanvaButton } from "../creator/CanvaButton";
import { ImagePreview } from "../creator/ImagePreview";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useGlobalCore } from "../../../../cores/globals";
import { ADD_SOCIAL_IMAGE_MODAL } from "../../../../constants/modal.constant";
import _ from "lodash";
import { useMeCore } from "../../../../cores/me";

interface Props {
  singleImage?: boolean;
}

export default function ImageForm(props: Props) {
  const { singleImage = false } = props;
  const intl = useIntl(); 
  const {
    addImages,
    updateImage,
    images,
    video,
    removeVideo,
    selectedPost,
    removePostAllMedia,
    socialProviders,
  } = useSocialPostCore();
  const { turnOffModal } = useGlobalCore();
  const { companyPlan } = useMeCore();

  const ref = useRef<any>(null);

  const maxSizeImage = useMemo(() => 
    _.max(socialProviders.map(
      ({ id }) => SocialProviderRules[id].maxImageSize
    ))! * 1024 * 1024,
  [socialProviders]);

  const onChange = (files: File[], pictures: string[]) => {
    const images: PostImage[] = files.map((file, index): PostImage => {
      const { name, size, lastModified, type = "image/jpeg" } = file;
      const id = v4();
      const image = new Image();
      image.onload = () => {
        const { width, height } = image;
        const aspectRatio = getAspectRatio(width, height);
        const postImage = images.find(({ id: imageId }) => id === imageId);
        if (postImage)
          updateImage({ ...postImage, aspectRatio, width, height });
      };
      image.src = pictures[index];
      return {
        id,
        lastModified,
        name,
        size,
        type,
        data: pictures[index],
      };
    });
    addImages(images);

    ref.current.state.files = [];
    ref.current.state.pictures = [];
  };

  const removeVideos = useCallback(() => {
    removeVideo();
    if (selectedPost) {
      removePostAllMedia();
    }
  }, [removeVideo, selectedPost, removePostAllMedia]);

  return (
    <div className="d-flex h-100">
      <div className="p-2 w-100">
        {(video ||
          (selectedPost &&
            selectedPost.post.type === SOCIAL_POST_TYPES.VIDEO &&
            selectedPost.media.length > 0)) && (
          <AreYouSure
            message={intl.formatMessage({ id: "app.socialMediaPost.warningError" })}
            onClose={() => turnOffModal(ADD_SOCIAL_IMAGE_MODAL)}
            onSubmit={removeVideos} 
          />
        )}
        {!video &&
          (!selectedPost ||
            selectedPost?.post.type === SOCIAL_POST_TYPES.IMAGE ||
            (selectedPost?.post.type === SOCIAL_POST_TYPES.VIDEO &&
              !selectedPost.media.length)) && (
            <>
              <div className="wrap-container">
                <ImageUploader
                  className="w-fit"
                  withIcon={false}
                  buttonClassName="btn"
                  buttonStyles={{ background: COLOR_PRIMARY }}
                  buttonText={intl.formatMessage({ id: "app.socialMediaPost.selectImage" })}
                  onChange={onChange}
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  withLabel={false}
                  maxFileSize={maxSizeImage}
                  fileContainerStyle={{
                    boxShadow: "0 0 0 0",
                    margin: "0",
                    padding: "0",
                    width: "fit-content",
                  }}
                  singleImage={singleImage}
                  ref={ref}
                />
                <CanvaButton
                  disabled={!companyPlan?.rules.allowCanva}
                  onPublish={(file, picture) => onChange([file], [picture])}
                />
              </div>
              <hr />
              <div className="my-2 align-items-center justify-content-center p-1 d-flex flex-wrap">
                {images.map((image) => (
                  <ImagePreview image={image} key={image.id} />
                ))}
                {selectedPost?.media.map((image) => (
                  <ImagePreview image={image} key={image.key} />
                ))}
              </div>
              {images.length === 0 && (!selectedPost || selectedPost?.media.length === 0) && (
                <div className="w-100 h-75 d-flex flex-column justify-content-center align-items-center text-muted">
                  <FontAwesomeIcon
                    icon={["fas", "images"]}
                    size="5x"
                    className="mb-1"
                    color="gray"
                  />
                  {intl.formatMessage({ id: "app.socialMediaPost.notSelectImage" })}
                </div>
              )}
            </>
          )}
      </div>
    </div>
  );
}
