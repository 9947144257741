import socialApiClient from "../../helpers/services/social-api-client";
import { Media } from "../../socialPost/interfaces/api/get-social-posts";
import { Mentions } from "../../socialPost/interfaces/Mention";
import { PostImage } from "../../socialPost/interfaces/postImage";
import { blobFromDataURI } from "../../utils";
import { GetLinkedInResponse } from "../interface/api/get-linkedin-bind";
import { GetLinkedinProfilesResponse } from "../interface/api/get-linkedin-profiles";
import {
  PostLinkedInBindRequest,
  PostLinkedInBindResponse,
} from "../interface/api/post-linkedin-bind";
import { PostSocialPostResponse } from "../interface/api/post-linkedin-socialPost";
import { PutSocialPostResponse } from "../interface/api/put-linkedin-socialPost";
import { linkedInSettingMapper } from "./mapper";

export const linkedInApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`linkedin/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostLinkedInBindRequest) {
    return socialApiClient()
      .post<PostLinkedInBindResponse>(`linkedin/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        linkedInSettingMapper.mapResponseToLinkedInSetting(data)
      );
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetLinkedInResponse>(`linkedin`, {
        params: { companyId },
      })
      .then(({ data }) =>
        linkedInSettingMapper.mapResponseToLinkedInSetting(data)
      );
  },
  getProfiles(companyId: string, name: string) {
    return socialApiClient()
      .get<GetLinkedinProfilesResponse>(`linkedin/profiles/${name}`, {
        params: { companyId },
      })
      .then(({ data }) =>
        linkedInSettingMapper.mapResponseToLinkedInProfiles(data)
      );
  },
  socialPost(
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`linkedIn/socialpost`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
  putSocialPost(
    id: string,
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(`linkedIn/socialpost/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },
};
