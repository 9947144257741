import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useCallback, useMemo } from "react";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  UncontrolledDropdown,
} from "reactstrap";

import { COLOR_PRIMARY } from "../../../constants/theme";
import { useIntl } from "react-intl";
import "emoji-mart/css/emoji-mart.css";

import { EmojiForm } from "../EmojiForm";
import AttachmentView from "./web/AttachmentsView/AttachmentView";
import { Attachment } from "../../../cores/conversations/interfaces/attachment/attachment.interface";
import { uniqueId } from "lodash";
import { ContentMessageType } from "../../../cores/socialChat/types/whatsapp-message.types";
import { beforeUploadFile } from "../../../cores/utils";
import { useWebChatCore } from "../../../cores/conversations/index";
import { useTemplateCore } from "../../../cores/templates";
import { Position } from "../../../cores/globals/interfaces/globalEntity";
import { TEMPLATE_CHAT_FORM } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import Handlebars from "handlebars";
import { useMeCore } from "../../../cores/me";
import { Conversation as SocialConversation } from "../../../cores/socialChat/interfaces/conversations.interface";
import { Conversation } from "../../../cores/conversations/interfaces/api/get-conversations";
import { CHAT_MESSAGES_SENDING } from "../../../constants/loading.constant";
import { LoadingSpinnerWembii } from "../LoadingSpinnerWembii";

const MAX_MESSAGE_LENGTH = 4096;
interface Props {
  onSubmit: (message: string) => void;
  conversation: Conversation | SocialConversation | null;
  disabled?: boolean;
  attachments: Attachment[];
  disableAttachments?: boolean;
}

export default function SubmitInput(props: Props) {
  const intl = useIntl();
  const {
    onSubmit,
    conversation,
    disabled = false,
    attachments,
    disableAttachments,
  } = props;

  const { user } = useMeCore();
  const { chatTemplates } = useTemplateCore();
  const { setAttachments } = useWebChatCore();
  const { isModal, turnOnModal, turnOnLoading, isLoading } = useGlobalCore();
  const [text, setText] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const fileInputRef = useRef<any>();
  const acceptedExtensions =
    ".aac, .mp4, .mpeg, .amr, .ogg, .pdf, .ppt, .doc, .xls, .docx, .pptx, .xlsx, .jpeg, .png";

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let fileIsAccepted: boolean = false;
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = e.target.files;

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const documentType = file.type;
        let maxSize = 0;
        if (!file) return;
        if (documentType.includes("audio") || documentType.includes("video")) {
          maxSize = 16000000; // 16MB
        } else if (documentType.includes("image")) {
          maxSize = 5000000; // 5MB
        } else if (
          documentType.includes("application/pdf") ||
          documentType.includes("application/vnd.ms-powerpoint") ||
          documentType.includes("application/msword") ||
          documentType.includes("application/vnd.ms-excel") ||
          documentType.includes(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) ||
          documentType.includes(
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) ||
          documentType.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          )
        ) {
          maxSize = 100000000; // 100MB
        }

        fileIsAccepted = beforeUploadFile(file, maxSize, []);
        if (fileIsAccepted) {
          const reader = new FileReader();
          const fileType =
            file.type.split("/")[0] === "application"
              ? "document"
              : (file.type.split("/")[0] as ContentMessageType);
          reader.onloadend = () => {
            setAttachments([
              {
                id: uniqueId(),
                file: reader.result,
                alt: file.name,
                type: fileType,
                resource: file,
              },
            ]);
          };
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const onFormSubmit = (e?: any) => {
    if (e) e.preventDefault();
    if (!disabled) {
      turnOnLoading(CHAT_MESSAGES_SENDING);
      onSubmit(text.trim());
      setAttachments([]);
      setText("");
      fileInputRef.current.value = null;
    }
  };

  const onClickTemplate = useCallback(() => {
    if (!disabled) {
      turnOnModal(TEMPLATE_CHAT_FORM, true, Position.LEFT);
    }
  }, [disabled, turnOnModal]);

  const onClickEmoji = useCallback(() => {
    if (!disabled) {
      setShowPicker(!showPicker);
    }
  }, [disabled, showPicker]);

  const selectTemplate = useCallback(
    (template) => {
      const messageTemplate = Handlebars.compile(template.message);
      const tags = {
        nombre_usuario_completo: `${user?.firstName} ${user?.lastName}`,
        nombre_usuario: user?.firstName,
        apellido_usuario: user?.lastName,
        nombre_contacto_completo: conversation?.participants[0].name,
      };

      setText(messageTemplate(tags));
    },
    [user, conversation]
  );

  const iconColor = useCallback(
    (active) => {
      if (disabled) {
        return "#c2c2c2";
      } else {
        if (active) {
          return COLOR_PRIMARY;
        } else {
          return "grey";
        }
      }
    },
    [disabled]
  );

  const handleChangeText = ({ target }: any) => {
    setText(target.value);
  };
  const onPressKeys = ({ key, shiftKey }: any) => {
    if (key === "Enter" && shiftKey) return;

    if (key === "Enter" && text.trim().length > 0) {
      onFormSubmit();
      setText("");
    }
  };
  const remainingCharacters = useMemo(
    () =>
      MAX_MESSAGE_LENGTH -
      text.length -
      (text.match(/\r?\n/g) || []).length * 2,
    [text]
  );

  return (
    <main className="w-100 border-top ">
      {attachments.length > 0 && !disableAttachments && (
        <AttachmentView attachments={attachments} />
      )}
      <Form
        onSubmit={onFormSubmit}
        method="post"
        className="container p-2"
        encType="multipart/form-data"
      >
        <div className="d-flex ">
          <div className="my-auto d-flex align-items-center">
            {!disableAttachments && (
              <>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                  accept={acceptedExtensions}
                />
                <div className="p-1">
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    size="lg"
                    color={iconColor(attachments.length)}
                    className="mx-1 pointer"
                    onClick={() => !disabled && fileInputRef!.current!.click()}
                  />
                </div>
              </>
            )}
            <div className="p-1">
              <FontAwesomeIcon
                icon={["far", "smile-beam"]}
                color={iconColor(showPicker)}
                size="lg"
                className="mx-1 pointer"
                onClick={onClickEmoji}
              />
            </div>
            <UncontrolledDropdown disabled={disabled}>
              <DropdownToggle nav className="mb-0 mx-1 p-1">
                <FontAwesomeIcon
                  icon={["fas", "clipboard-list"]}
                  size="lg"
                  color={iconColor(isModal(TEMPLATE_CHAT_FORM))}
                  className="mx-1 pointer"
                />
              </DropdownToggle>
              <DropdownMenu
                right
                className="border-0 py-3 px-2 shadow option-menu"
              >
                <DropdownItem onClick={onClickTemplate}>
                  <div className="d-flex align-items-center">
                    {intl.formatMessage({
                      id: "app.sendEmail.template.manage",
                    })}
                  </div>
                </DropdownItem>
                <DropdownItem divider />
                {chatTemplates.length === 0 && (
                  <DropdownItem>
                    <div className="d-flex align-items-center text-center text-muted">
                      {intl.formatMessage({ id: "app.sendEmail.noTemplate" })}
                    </div>
                  </DropdownItem>
                )}
                {chatTemplates.map((template) => (
                  <DropdownItem
                    key={template.id}
                    onClick={() => selectTemplate(template)}
                  >
                    <div className="d-flex align-items-center">
                      {template.name}
                    </div>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className="my-auto w-100 ">
            <div className="relative d-flex">
              <textarea
                className="d-flex   w-100  p-2 pr-5 border"
                disabled={disabled}
                placeholder={intl.formatMessage({
                  id: "app.chatFacebook.writeMsg",
                })}
                value={text}
                onKeyDown={onPressKeys}
                maxLength={4096}
                onChange={handleChangeText}
                rows={1}
                style={{
                  boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.5)",
                  alignItems: "left",
                  verticalAlign: "middle",
                  resize: "none",
                  border: "none",
                  pointerEvents: "auto",
                  borderRadius: "2rem",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  outline: "none",
                }}
              />
              <div
                className={cn("position-absolute my-auto", {
                  "text-muted": remainingCharacters >= 1000,
                  "text-danger": remainingCharacters < 0,
                  "text-warning": remainingCharacters < 1000,
                })}
                style={{ right: 60, bottom: 15 }}
              >
                {remainingCharacters}
              </div>
            </div>
          </div>
          {!isLoading(CHAT_MESSAGES_SENDING) ? (
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={["far", "paper-plane"]}
                size="lg"
                color={disabled ? "#c2c2c2" : COLOR_PRIMARY}
                className="m-2 pointer"
                onClick={(e) =>
                  remainingCharacters <= 0 || text.length === 0
                    ? null
                    : onFormSubmit(e)
                }
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <LoadingSpinnerWembii active size="xs" />
            </div>
          )}
        </div>
        {showPicker && !disabled && (
          <div className="border-top position-relative">
            <EmojiForm
              onAdding={(emoji: any) => setText(`${text} ${emoji.native}`)}
            />
          </div>
        )}
      </Form>
    </main>
  );
}
