import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React, { ReactNode } from "react";
import { CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { MORE_INFO_SOCIAL } from "../../../constants/modal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { SocialProvider } from "../../../cores/socialPost/interfaces/socialProvider";
import { LoadingSpinnerWembii } from "../LoadingSpinnerWembii";
import { PanelWembii } from "../PanelWembii";
import { YoutubeModal } from "../YoutubeModal";
import { useIntl } from "react-intl";

interface Props {
  social: SocialProvider;
  children?: ReactNode;
  bindLoading?: boolean;
  getLoading?: boolean;
  title?: string;
  videoId?: string;
  showSynchronizer?: boolean;
  canSync?: boolean;
  planAllowsFeature?: boolean;
  remainingBindDays?: number | null;
}

export default function SocialCard({
  social,
  children,
  bindLoading,
  getLoading,
  videoId,
  title,
  remainingBindDays = null,
  showSynchronizer = true,
  canSync = true,
  planAllowsFeature = false,
}: Props) {
  const { id, name, email, description, icon, avatar, displayName, url } =
    social;
  const { turnOnModal } = useGlobalCore();
  const intl = useIntl();

  return (
    <PanelWembii
      key={id}
      shadow
      style={{
        border: !canSync && !planAllowsFeature ? "solid 2.5px  #FF7A59" : "",
      }}
      className="d-flex flex-column text-center  w-100 h-100 justify-content-center my-auto"
    >
      <div className="d-flex justify-content-end">
        <button
          style={{ visibility: showSynchronizer ? "visible" : "hidden" }}
          type="button"
          className="btn btn-link "
          onClick={
            !showSynchronizer
              ? () => null
              : () =>
                  turnOnModal(
                    MORE_INFO_SOCIAL,
                    <YoutubeModal title={title!} videoId={videoId!} />
                  )
          }
        >
          <FontAwesomeIcon
            icon={["fas", "info-circle"]}
            className="pointer position-relative"
            size="lg"
            color="grey"
          />
        </button>
      </div>
      <CardBody className="px-2 py-2 d-flex flex-column justify-content-between">
        <CardTitle className="font-weight-bolder position-relative">
          {name}
        </CardTitle>
        <div className={cn("position-relative my-4")}>
          <div className="flex  mx-auto my-auto">
            <div className="justify-content-between">
              {getLoading ? (
                <LoadingSpinnerWembii
                  active={getLoading}
                ></LoadingSpinnerWembii>
              ) : (
                <div className="d-flex">
                  <div
                    style={{ width: "100%", height: "100px" }}
                    className="mx-auto mb-4  position-relative "
                  >
                    {icon}
                    {avatar !== undefined && (
                      <img
                        className="socialCard-avatar rounded-full position-absolute"
                        src={avatar}
                        loading="lazy"
                        alt="avatar"
                        style={{
                          width: "45px",
                          height: "45px ",
                          top: 40,
                          right: 90,
                        }}
                        width={45}
                        height={45}
                      />
                    )}

                    <CardSubtitle
                      style={{
                        visibility: displayName ? "visible" : "hidden",
                      }}
                      className="truncate my-2"
                    >
                      {displayName}
                    </CardSubtitle>

                    <div>
                      <CardSubtitle
                        style={{
                          visibility: email ? "visible" : "hidden",
                        }}
                        className="truncate my-2"
                      >
                        {email}
                      </CardSubtitle>
                    </div>
                    {url && (
                      <span className="small truncate my-4">
                        <a href={url} target="_black">
                          {url}
                        </a>
                      </span>
                    )}
                  </div>
                </div>
              )}

              <CardSubtitle
                style={{
                  visibility: !displayName && !email ? "visible" : "hidden",
                }}
                className="truncate"
              >
                {intl.formatMessage({ id: "app.social.offline" })}
              </CardSubtitle>

              {remainingBindDays &&
                remainingBindDays > 0 &&
                remainingBindDays <= 10 && (
                  <div className="small text-danger mb-1 text-center d-flex justify-content-center">
                    <FontAwesomeIcon
                      size={"2x"}
                      icon={["fas", "exclamation-circle"]}
                      className="mr-2"
                    />
                    {intl.formatMessage(
                      { id: "app.home.expiration" },
                      {
                        remainingDays: remainingBindDays,
                      }
                    )}
                  </div>
                )}

              {remainingBindDays && remainingBindDays <= 0 && (
                <div className="small text-danger text-center mb-1 d-flex px-2">
                  <FontAwesomeIcon
                    size={"2x"}
                    icon={["fas", "exclamation-circle"]}
                    className="mr-2"
                  />
                  {intl.formatMessage({ id: "app.home.expired" })}
                </div>
              )}
            </div>

            <div className="socialCard-avatar-container border"></div>
          </div>
        </div>

        <div className="d-flex">
          <CardSubtitle className="truncate">{description}</CardSubtitle>
          <LoadingSpinnerWembii className="p-4" active={bindLoading}>
            {children}
          </LoadingSpinnerWembii>
        </div>
      </CardBody>
    </PanelWembii>
  );
}
