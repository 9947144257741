import cn from "classnames";
import React, { ReactNode } from "react";
import { Spinner } from "reactstrap";
import wembiiLogoCircle from "../../assets/wembii-logo-circle.png";
import { useGlobalCore } from "../../cores/globals";

interface Props {
  size?: "xs" |"sm" | "lg" | "md" ;
  id?: string;
  active?: boolean;
  children?: ReactNode;
  className?: string;
  sppinerMessage?: string;
  style?: object;
}

export const LoadingSpinnerWembii = (props: Props) => {
  const { size = "sm", id, children, active, className, style, sppinerMessage } = props;
  const { isLoading } = useGlobalCore();
  return (id && isLoading(id)) || active ? (
    <div
      className={cn(className, "d-flex flex-column justify-conten-center align-items-center m-auto z-index-50")}
      style={style}
    >
      <div className="d-flex justify-conten-center align-items-center position-relative w-fit">
        <Spinner color="primary" className={`loadingSpinner-${size}`} />
        <img
          className={`loadingSpinnerImg-${size}`}
          src={wembiiLogoCircle}
          alt="LOADING"
        />
      </div>
      {sppinerMessage}
    </div>
  ) : (
    <>{children}</>
  );
};
