import React, { useState } from "react";
import { useEventCore } from "../../../../cores/event";
import ReactQuill from "react-quill";

export const EventTextEditor = () => {
  const { setDescription: setEventDescription } = useEventCore();
  const [description, setDescription] = useState("");

  const onEditorStateChange = (description: string) => {
    setDescription(description);
    setEventDescription(description);
  }

  return (
    <ReactQuill
      value={description}
      className="textEditor"
      onChange={onEditorStateChange}
      modules={{
        toolbar: {
          container: [
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'header': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            ['link'],
            ['clean']
          ]
        },
      }}
      formats={[
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'align',
        'color', 'background',
        'size',
        'list', 'bullet',
        'indent',
        'script',
        'link',
        'clean'
      ]}
    />
  );
};
