import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "reactstrap";
import { useIntl } from "react-intl";
import { useRouterCore } from "../../../cores/router";
import cn from "classnames";

export const UpgradePlanButton: React.FC<{ expanded?: boolean }> = ({
  expanded,
}) => {
  const intl = useIntl();
  const { goToPlans } = useRouterCore();
  return (
    <Button
      className={cn("mx-2 upgradePlan", {
        "mx-auto my-auto": expanded,
      })}
      onClick={goToPlans}
    >
      <FontAwesomeIcon icon={["fas", "angle-double-up"]} className="mx-2" />
      {intl.formatMessage({ id: "app.contacts.upgradePlan" })}
    </Button>
  );
};
