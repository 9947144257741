import React, { useCallback, useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../cores/me";
import { InputWembii } from "../InputWembii";
import { useContactCore } from "../../../cores/contacts";
import { CustomField } from "../../../cores/contacts/interfaces/api/get-custom-fields";
import { CUSTOM_FIELD } from "../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../cores/globals";

interface Props {
  field?: CustomField;
  onCreation?: (arg0: string) => void;
}

export default function FieldForm({ field, onCreation }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const { turnOffInternalModal } = useGlobalCore();
  const { postCustomField, putCustomField, deleteCustomField } = useContactCore();

  const [name, setName] = useState("");

  useEffect(() => {
    if (field) {
      setName(field.name);
    }
  }, [field]);

  const cleanForm = useCallback(() => {
    setName("");
    turnOffInternalModal(CUSTOM_FIELD);
  }, [turnOffInternalModal]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!field) {
      const id = await postCustomField(
        company?.id!,
        name,
        cleanForm
      );

      if (id) onCreation?.(id);
    } else {
      putCustomField(
        company?.id!,
        field.id,
        name,
        cleanForm
      );
    }
  };

  const onDelete = useCallback(() => {
    deleteCustomField(
      company?.id!,
      field?.id!,
      cleanForm
    );
  }, [deleteCustomField, cleanForm, company, field]);

  const disabled = useCallback(
    () => !name,
    [name]
  );

  return (
    <Form onSubmit={onSubmit} >
      <InputWembii
        label={intl.formatMessage({ id: "app.customField.name" })}
        type="text"
        name="name"
        id="tag-name"
        className="pr-1"
        required
        placeholder={intl.formatMessage({ id: "app.customField.name" })}
        value={name}
        onChange={setName}
      />
      <h6><small className="text-muted">{intl.formatMessage({ id: "app.customField.hint" })}</small></h6>
      {field && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.customField.delete" })}  
        </Button>
      )}
      <Button color="primary" disabled={disabled()} className="ml-1">
        {field 
          ? intl.formatMessage({ id: "app.customField.update" })
          : intl.formatMessage({ id: "app.customField.create" })
        }  
      </Button>
    </Form>
  );
}