import React from "react";
import { useGlobalCore } from "../../cores/globals";

interface Props {
  id: string;
}

export const LoadingSpinner = (props: Props) => {
  const { id } = props;
  const { isLoading } = useGlobalCore();

  return (
    isLoading(id) ? (
      <div className="sk-fading-circle mx-1">
        <div className="sk-circle1 sk-circle"></div>
        <div className="sk-circle2 sk-circle"></div>
        <div className="sk-circle3 sk-circle"></div>
        <div className="sk-circle4 sk-circle"></div>
        <div className="sk-circle5 sk-circle"></div>
        <div className="sk-circle6 sk-circle"></div>
        <div className="sk-circle7 sk-circle"></div>
        <div className="sk-circle8 sk-circle"></div>
        <div className="sk-circle9 sk-circle"></div>
        <div className="sk-circle10 sk-circle"></div>
        <div className="sk-circle11 sk-circle"></div>
        <div className="sk-circle12 sk-circle"></div>
      </div>
    ) : null
  );
};
