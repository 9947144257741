import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useMemo, useState } from "react"
import { Label } from "reactstrap"
import { useBoardCore } from "../../../../cores/boards"
import { useIntl } from "react-intl";
import { BoardType } from "../../../../cores/boards/constants/board-type.constant"
import { useMeCore } from "../../../../cores/me"
import DescriptionInput from "./DescriptionInput"

interface Props {
  type: string;
  canModify: boolean;
  onSubmit: (arg0: string, arg1: string[]) => void;
}

export default function DescriptionContainer(props: Props) {
  const intl = useIntl();
  const { type, canModify, onSubmit } = props
  const { user } = useMeCore()
  const { card } = useBoardCore()
  const [editDescription, setEditDescription] = useState(false)

  const toggleEditDescription = useCallback(() => {
    if (canModify)
      setEditDescription(!editDescription)
  }, [editDescription, canModify])

  const onUpdateDescrition = useCallback((description: string, users: string[]) => {
    onSubmit(description, users)
    setEditDescription(false)
  }, [onSubmit])

  const formatedDescription = useMemo(() => ({
    __html:
      (card?.description || "")
        .replaceAll(/(?=@@@B\[)(.*?)(?=\)E@@@)/g, (value) => {
          const data = value.split("]@M@(")
          return `<span class="font-weight-bold pointer tagged ${+user?.id! === +data[1] ? "text-primary" : ""}">@${data[0]}</span>`
        })
        .replace(/@@@B\[/g, "")
        .replace(/\)E@@@/g, ""),
  }), [card, user])

  return (
    <div className="py-2 mt-4">
      <div className="w-100">
        <FontAwesomeIcon
          icon={["fas", "align-justify"]}
          size="1x"
          className="mr-2"
          color="primary"
        /> 
        <Label forid="description" className="font-weight-bold">
          {type === BoardType.BUSINESS 
            ? intl.formatMessage({ id: "app.activity.Resume" })
            : intl.formatMessage({ id: "app.activity.description" })
          }
        </Label>
      </div>
      {!editDescription ? (
        !card?.description ? (
          <Label
            className="w-100 d-flex justify-content-center align-items-center p-3 text-muted bg-light pointer"
            onClick={toggleEditDescription}
          >
            <FontAwesomeIcon
              icon={["fas", "font"]}
              size="2x"
              className="mr-2"
              color="gray"
            />
            {type === BoardType.BUSINESS 
              ? intl.formatMessage({ id: "app.activity.notInformation" })
              : intl.formatMessage({ id: "app.activity.notdesciption" })
            }
          </Label>
        ) : (
          <div
            className="w-100 bg-light p-3 pre-wrap pointer"
            onClick={toggleEditDescription}
            dangerouslySetInnerHTML={formatedDescription}
          />
        )
      ) : (
        <DescriptionInput
          description={card?.description!}
          onSubmit={onUpdateDescrition}
          toggleEdition={toggleEditDescription}
        />
      )}
    </div>
  )
}
