import socialApiClient from "../../helpers/services/social-api-client";
import { GetWhatsappAuth0BindResponse } from "../interface/api/get-whatsapp-auth0";
import { GetWhatsappBindResponse } from "../interface/api/get-whatsapp-bind";
import {
  PostWhatsappBindRequest,
  PostWhatsappBindResponse,
} from "../interface/api/post-whatsapp-bind";
import { whatsappSettingMapper } from "./mapper";

export const whatsappApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`whatsapp/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  auth0(companyId: string, accessToken: string) {
    return socialApiClient()
      .get<GetWhatsappAuth0BindResponse>(
        `whatsapp/bind/auth0?accessToken=${accessToken}`,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => {
        return whatsappSettingMapper.mapResponseToAuth0Config(data);
      });
  },
  bind(companyId: string, body: PostWhatsappBindRequest) {
    return socialApiClient()
      .post<PostWhatsappBindResponse>(`whatsapp/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        whatsappSettingMapper.mapResponseToWhatsappSetting(data)
      );
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetWhatsappBindResponse>(`whatsapp`, {
        params: { companyId },
      })
      .then(({ data }) =>
        whatsappSettingMapper.mapResponseToWhatsappSetting(data)
      );
  },
};
