import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { Button, Container, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useIntl } from "react-intl";
import { useContactCore } from "../../../../cores/contacts";
import FieldForm from "../FieldForm";
import { CustomField } from "../../../../cores/contacts/interfaces/api/get-custom-fields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InternalModal from "../../../layout/InternalModal";
import { CUSTOM_FIELD } from "../../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { CheckboxWembii } from "../../CheckboxWembii";
import { DefaultInformationOptions } from "../../../../cores/contacts/interfaces/DefaultInformation";
import { ConfigRow } from "../ConfigRow";

interface Props {
  tab?: string;
}

export default function Config({ tab = "1" }: Props) {
  const intl = useIntl();
  const { customFields, config } = useContactCore();
  const {
    turnOnInternalModal,
    turnOffInternalModal,
    isInternalModal,
  } = useGlobalCore();
  const [activeTab, setActiveTab] = useState(tab);
  const [field, setField] = useState<CustomField | null>(null);

  const editTag = useCallback((field: CustomField) => {
    turnOnInternalModal(CUSTOM_FIELD, true);
    setField(field);
  }, [turnOnInternalModal]);

  const createTag = useCallback(() => {
    turnOnInternalModal(CUSTOM_FIELD, true);
    setField(null);
  }, [turnOnInternalModal]);

  const defaultFieldConfig = useMemo(() =>
    config.filter((config) => !config.customField)
      .reduce((prev: Record<string, boolean>, curr) => {
        prev[curr.field] = true;
        return prev;
      }, {})
  , [config]);

  const customFieldConfig = useMemo(() =>
    config.filter((config) => config.customField)
      .reduce((prev: Record<string, boolean>, curr) => {
        prev[curr.field] = true;
        return prev;
      }, {})
  , [config]);

  return (
    <div className="position-relative">
      <InternalModal
        active={isInternalModal(CUSTOM_FIELD)}
        onClose={() => turnOffInternalModal(CUSTOM_FIELD)}
      >
        <FieldForm field={field!} />
      </InternalModal>
      <Container>
        <Nav tabs className="justify-content-center mt-2">
          <NavItem>
            <NavLink
              className={cn("pointer", { active: activeTab === "1" })}
              onClick={() => { setActiveTab("1") }}
            >
              {intl.formatMessage({ id: "app.contacts.tableInfo" })}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={cn("pointer", { active: activeTab === "2" })}
              onClick={() => { setActiveTab("2") }}
            >
              {intl.formatMessage({ id: "app.contacts.customFields" })}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="d-flex flex-column p-4">
              <div className="d-flex justify-content-between align-items-center my-1">
                {intl.formatMessage({ id: "app.contacts.name" })}

                <CheckboxWembii
                  mb={false}
                  disabled={true}
                  checked={true}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center my-1">
                {intl.formatMessage({ id: "app.contacts.email" })}

                <CheckboxWembii
                  mb={false}
                  disabled={true}
                  checked={true}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center my-1">
                {intl.formatMessage({ id: "app.contacts.phone" })}

                <CheckboxWembii
                  mb={false}
                  disabled={true}
                  checked={true}
                />
              </div>
              {DefaultInformationOptions.map((info, index) => (
                <ConfigRow
                  key={index}
                  label={intl.formatMessage({ id: info.label })}
                  checked={defaultFieldConfig[info.value]}
                  field={info.value}
                  customField={false}
                />
              ))}
              {customFields.map((field, index) => (
                <ConfigRow
                  key={index}
                  label={field.name}
                  checked={customFieldConfig[field.id]}
                  field={`${field.id}`}
                  customField={true}
                />
              ))}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="d-flex flex-column p-4">
              <Button color="ligth" className="mb-3 border" onClick={createTag}>
                {intl.formatMessage({ id: "app.contacts.customFields.create.new" })}
              </Button>

              {customFields.length === 0 && (
                <div className="d-flex align-items-center justify-content-center py-5 text-muted">
                  {intl.formatMessage({ id: "app.contacts.noCustomFields" })}
                </div>
              )}
              {customFields.map((field) => (
                <div className="d-flex justify-content-between align-items-center my-1" key={field.id}>
                  {field.name}

                  <FontAwesomeIcon
                    icon={["fas", "edit"]}
                    size="1x"
                    className="ml-3 pointer"
                    color="gray"
                    onClick={() => editTag(field)}
                  />
                </div>
              ))}
            </div>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  );
}
