import analyticsApiClient from "../../helpers/services/analytics-api-client";
import socialApiClient from "../../helpers/services/social-api-client";
import { GetCurrencyResponse } from "../interface/api/get-current-currency";
import { GetWooCommerceBindResponse } from "../interface/api/get-woocommerce";
import {
  PostWooCommerceBindRequest,
  PostWooCommerceBindResponse,
} from "../interface/api/post-woocommerce-bind";
import {
  PostWooCommerceLoginRequest,
  PostWooCommerceLoginResponse,
} from "../interface/api/post-woocommerce-login";
import { wooCommerceSettingMapper } from "./mapper";

export const wooCommerceApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`woocommerce/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },
  bind(companyId: string, body: PostWooCommerceBindRequest) {
    return socialApiClient()
      .post<PostWooCommerceBindResponse>(`woocommerce/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        wooCommerceSettingMapper.mapResponseToWooCommerceSetting(data)
      );
  },
  generateContacts(companyId: string) {
    return socialApiClient()
      .post<PostWooCommerceBindResponse>(
        `woocommerce/importContacts`,
        {},
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },
  get(companyId: string) {
    return socialApiClient()
      .get<GetWooCommerceBindResponse>(`woocommerce`, {
        params: { companyId },
      })
      .then(({ data }) =>
        wooCommerceSettingMapper.mapResponseToWooCommerceSetting(data)
      );
  },
  getCurrency(companyId: string) {
    return analyticsApiClient()
      .get<GetCurrencyResponse>(`woocommerce/currency`, {
        params: { companyId },
      })
      .then(({ data }) =>
        wooCommerceSettingMapper.mapResponseToWooCommerceCurrency(data)
      );
  },
  login(companyId: string, body: PostWooCommerceLoginRequest) {
    return socialApiClient()
      .post<PostWooCommerceLoginResponse>(`woocommerce/login`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        wooCommerceSettingMapper.mapResponseToWooCommerceLoginUrl(data)
      );
  },
};
