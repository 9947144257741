import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MentionsInput, Mention } from 'react-mentions';
import React, { useCallback, useMemo, useState } from "react";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import Avatar from "../../Avatar";
import { getFirstLetters } from "../../../utils/stringUtils";
import { useCompanyCore } from "../../../../cores/company";

interface Props {
  onSubmit: (arg0: string) => void;
  onClose?: () => void;
  comment?: string;
}

export default function CommentInput(props: Props) {
  const intl = useIntl();
  const { onSubmit, onClose, comment = "" } = props;
  const { users } = useCompanyCore();
  const [newComment, setNewComment] = useState(comment);
  const [addComment, setAddComment] = useState(false || !!comment);
  const [usersTagged, setUsersTagged] = useState<string[]>([]);

  const toggleAddComment = useCallback(() => {
    setAddComment(true);
  }, []);

  const closeComment = useCallback(() => {
    onClose?.();
    setNewComment("");
    setAddComment(!addComment);
  }, [addComment, onClose]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    
    onSubmit(newComment);
    setNewComment("");
    setAddComment(false);
  }

  const handleChange = useCallback((text) => {
    setNewComment(text.target.value);
    setAddComment(true);
  }, []);

  const onTagUser = useCallback((user) => {
    if (!usersTagged.includes(user)) {
      setUsersTagged([...usersTagged, user]);
    }
  }, [usersTagged, setUsersTagged]);

  const userList = useMemo(() => users.map((user) => ({
    id: user.id, display: `${user.firstName} ${user.lastName}`
  })), [users]);

  return (
    <Form onSubmit={onFormSubmit}>
      <MentionsInput
        id="comment"
        value={newComment}
        style={{ minHeight: addComment ? 80 : 45 }}
        className="mention-input"
        onChange={handleChange}
        onClick={toggleAddComment}
        placeholder={intl.formatMessage({ id: "app.activity.addComent" })}
      >
        <Mention
          trigger="@"
          markup="@@@B[__display__]@M@(__id__)E@@@"
          appendSpaceOnAdd={true}
          data={userList}
          onAdd={onTagUser}
          renderSuggestion={(
            suggestion,
            _search,
            highlightedDisplay,
            _index,
            focused
          ) => (
            <div className={cn("d-flex align-items-center", { focused: focused })}>
              <Avatar
                className="text-dark-50 mr-2"
                label={`${getFirstLetters(suggestion.display || "")}`}
              />
              {highlightedDisplay}
            </div>
          )}
        />
      </MentionsInput>
      {addComment && (
        <div className="d-flex align-items-center mt-2">
          <Button
            color="primary"
            className="rounded"
            size="sm"
            disabled={!newComment}
          >
            {intl.formatMessage({ id: "app.chatConfigArea.save" })}
          </Button>
          <FontAwesomeIcon
            icon={["fas", "times"]}
            size="1x"
            className="pointer ml-3"
            color="gray"
            onClick={closeComment}
          />
        </div>
      )}
    </Form>
  );
}
