import socialApiClient from "../../helpers/services/social-api-client";
import { Media } from "../../socialPost/interfaces/api/get-social-posts";
import { Mentions } from "../../socialPost/interfaces/Mention";
import { PostImage } from "../../socialPost/interfaces/postImage";
import { PostVideo } from "../../socialPost/interfaces/postVideo";
import { blobFromDataURI } from "../../utils";
import { GetInstagramAccounts } from "../interface/api/get-instagram-accounts";
import { GetInstagramBindResponse } from "../interface/api/get-instagram-bind";
import {
  PostInstagramBindCodeRequest,
  PostInstagramBindRequest,
  PostInstagramBindResponse,
} from "../interface/api/post-instagram-bind"
import { PostSocialPostResponse } from "../interface/api/post-instagram-socialPost";
import { PutSocialPostResponse } from "../interface/api/put-instagram-socialPost";
import { instagramSettingMapper } from "./mapper";

export const instagramApiService = {
  unbind(companyId: string) {
    return socialApiClient()
      .post(`instagram/unbind`, {}, { params: { companyId } })
      .then(({ data }) => data);
  },

  bind(companyId: string, body: PostInstagramBindRequest) {
    return socialApiClient()
      .post<PostInstagramBindResponse>(`instagram/bind`, body, {
        params: { companyId },
      })
      .then(({ data }) =>
        instagramSettingMapper.mapResponseToInstagramSetting(data)
      );
  },

  get(companyId: string) {
    return socialApiClient()
      .get<GetInstagramBindResponse>(`instagram`, {
        params: { companyId },
      })
      .then(({ data }) =>
        instagramSettingMapper.mapResponseToInstagramSetting(data)
      );
  },

  accounts(companyId: string, token: string) {
    return socialApiClient()
      .get<GetInstagramAccounts>(`instagram/accounts`, {
        params: { companyId, token },
      })
      .then(({ data }) =>
        instagramSettingMapper.mapResponseToInstagramAccounts(data)
      );
  },

  socialPost(
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`instagram/socialpost`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },

  socialPostVideo(
    text: string,
    mentions: Mentions[],
    video: PostVideo,
    scheduleDate: string,
    repostId: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("repostId", repostId);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(
        `instagram/socialpost/video`,
        bodyFormData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },

  socialStory(
    text: string,
    images: PostImage[],
    scheduleDate: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(`instagram/socialstory`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },

  socialStoryVideo(
    text: string,
    video: PostVideo,
    scheduleDate: string,
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .post<PostSocialPostResponse>(
        `instagram/socialstory/video`,
        bodyFormData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },

  getCode(companyId: string, codeType: string) {
    return socialApiClient()
      .get(`instagram/code`, {
        params: { companyId, codeType },
      })
      .then(({ data }) => data);
  },

  postCode(companyId: string, data: PostInstagramBindCodeRequest) {
    return socialApiClient()
      .post(
        `instagram/code`,
        data,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => instagramSettingMapper.mapResponseToInstagramSetting(data));
  },

  putSocialPost(
    id: string,
    text: string,
    mentions: Mentions[],
    images: PostImage[],
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(`instagram/socialpost/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },

  putSocialPostVideo(
    id: string,
    text: string,
    mentions: Mentions[],
    video: PostVideo | null,
    scheduleDate: string,
    companyId: string,
    media: Media[]
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("mentions", JSON.stringify(mentions));
    bodyFormData.append("media", JSON.stringify(media));
    video && bodyFormData.append("video", blobFromDataURI(video.data))
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PutSocialPostResponse>(`instagram/socialpost/video/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },

  putSocialStory(
    id: string,
    text: string,
    images: PostImage[],
    scheduleDate: string,
    media: Media[],
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    images.map((image) =>
      bodyFormData.append("images", blobFromDataURI(image.data))
    );
    bodyFormData.append("media", JSON.stringify(media));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PostSocialPostResponse>(`instagram/socialstory/${id}`, bodyFormData, {
        params: { companyId },
      })
      .then(({ data }) => data);
  },

  putSocialStoryVideo(
    id: string,
    text: string,
    video: PostVideo | null,
    scheduleDate: string,
    media: Media[],
    companyId: string
  ) {
    const bodyFormData = new FormData();
    bodyFormData.append("text", text);
    bodyFormData.append("media", JSON.stringify(media));
    video && bodyFormData.append("video", blobFromDataURI(video.data));
    scheduleDate && bodyFormData.append("scheduleDate", scheduleDate);
    return socialApiClient()
      .put<PostSocialPostResponse>(
        `instagram/socialstory/video/${id}`,
        bodyFormData,
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data);
  },
};
