import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { GmailSetting } from "../interface/api/post-gmail-bind";
import {
  getGmailFailureAction,
  getGmailInitAction,
  getGmailSuccessAction,
  GmailApiAction,
  postBindGmailFailureAction,
  postBindGmailInitAction,
  postBindGmailSuccessAction,
  postUnbindGmailFailureAction,
  postUnbindGmailInitAction,
  postUnbindGmailSuccessAction,
  postGmailEmailInitAction,
  postGmailEmailFailureAction,
  postGmailEmailSuccessAction,
} from "./api-actions";

export interface GmailState {
  settings: GmailSetting | null;
  userSettings: GmailSetting | null;
  error: string;
}

const initialState: GmailState = {
  settings: null,
  userSettings: null,
  error: "",
};

export default produce(
  (
    draft: GmailState = initialState,
    action: GmailApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindGmailSuccessAction.type:
      case getGmailSuccessAction.type:
        if (action.asUser) {
          draft.userSettings = action.settings;
        } else {
          draft.settings = action.settings;
        }
        return draft;
      case postUnbindGmailSuccessAction.type:
        if (action.asUser) {
          draft.userSettings = null;
        } else {
          draft.settings = null;
        }
        return draft;
      case resetAction.type:
        return initialState;
      case postBindGmailFailureAction.type:
      case postUnbindGmailFailureAction.type:
      case getGmailFailureAction.type:
      case postBindGmailInitAction.type:
      case postUnbindGmailInitAction.type:
      case getGmailInitAction.type:
      case postGmailEmailInitAction.type:
      case postGmailEmailFailureAction.type:
      case postGmailEmailSuccessAction.type:
      default:
        return draft;
    }
  }
);
