import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import Avatar from "../Avatar";
import { Task } from "../../../cores/tasks/interfaces/api/get-task";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PriorityOptions,
  TaskStatus,
  TaskType,
} from "../../../cores/tasks/config";
import cn from "classnames";
import { COLOR_SUCCESS } from "../../../constants/theme";
import { useGlobalCore } from "../../../cores/globals/index";
import { useMeCore } from "../../../cores/me/index";
import { useTaskCore } from "../../../cores/tasks/index";
import { Label } from "reactstrap";
import { TASK_CARD_MODAL } from "../../../constants/modal.constant";
import TaskDetails from "./modal/TaskDetails";

export const ModalTaskRow: React.FC<{ task: Task }> = ({ task }) => {
  const { putTask } = useTaskCore();
  const { company } = useMeCore();
  const { turnOnModal } = useGlobalCore();
  const [currentTask, setCurrentTask] = useState<Task>(task);
  const { user, id, endDate, priority, type, title, status, createdAt } =
    currentTask;
  const onClick = useCallback(() => {
    turnOnModal(
      TASK_CARD_MODAL,
      <TaskDetails
        task={id}
        contactTask
        onSuccess={(task) => setCurrentTask(task!)}
      />
    );
  }, [turnOnModal, id]);

  const changeStatus = useCallback(
    (e) => {
      e.stopPropagation();

      const newStatus =
        status === TaskStatus.Completed
          ? TaskStatus.Incompleted
          : TaskStatus.Completed;
      putTask(
        company?.id!,
        id,
        {
          status: newStatus,
        },
        (task) => setCurrentTask(task!)
      );
    },
    [putTask, company, status, id]
  );
  const typeLabel = useMemo(() => {
    switch (type) {
      case TaskType.Other:
        return <Label className="ml-2 mb-0">Otro</Label>;
      case TaskType.Call:
        return (
          <FontAwesomeIcon icon={["fas", "phone"]} size="1x" className="ml-2" />
        );
      case TaskType.Email:
        return (
          <FontAwesomeIcon
            icon={["far", "envelope"]}
            size="1x"
            className="ml-2"
          />
        );
    }
  }, [type]);

  return (
    <div className="d-flex mt-3 mb-4 w-100">
      <Avatar
        className="text-dark-50"
        icon={<FontAwesomeIcon icon={["fas", "tasks"]} />}
      />
      <div className="d-flex flex-column align-items-start ml-2 w-100">
        <div>
          <span className="font-weight-bold mr-2">{`${user.firstName} ${user.lastName}`}</span>
          <FontAwesomeIcon icon={["far", "clock"]} className="mr-2" />
          <span
            className={cn("mb-0 small", {
              "text-danger":
                moment(endDate).isBefore(moment()) &&
                status === TaskStatus.Incompleted,
            })}
          >
            {moment(createdAt).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
        <div className="d-flex pointer p-2 border rounded pre-wrap email-container">
          <div
            className="mr-2 d-flex h-100 align-items-center justify-content-center justify-content-lg-start"
            onClick={changeStatus}
          >
            {status === TaskStatus.Incompleted ? (
              <FontAwesomeIcon icon={["far", "circle"]} size="1x" />
            ) : (
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                size="1x"
                color={COLOR_SUCCESS}
              />
            )}
          </div>
          <div onClick={onClick}>
            <div className="text-muted fs-12">
              Prioridad:
              <span
                className="font-weight-bold ml-2"
                style={{ color: PriorityOptions[`${priority}`].color }}
              >
                {PriorityOptions[`${priority}`].label}
              </span>
              {typeLabel}
            </div>
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};
