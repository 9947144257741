import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Col, Container, Form, Label, Row } from "reactstrap";
import { useCompanyCore } from "../../../cores/company";
import { useUserCore } from "../../../cores/user";
import { InputWembii } from "../InputWembii";
import AssignUserRow from "./AssignUserRow";

export default function SearchUserForm() {
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [searched, setSearched] = useState(false);
  const { userSearched, getUser } = useUserCore();
  const { userIds } = useCompanyCore();

  const onSubmit = useCallback(
    (e?: any) => {
      if (e) e.preventDefault();

      getUser(email);
      setSearched(true);
    },
    [getUser, email]
  );

  const isUserAssigned = useCallback(() => {
    if (userSearched) {
      return userIds.includes(parseInt(userSearched?.id!));
    } else {
      return false;
    }
  }, [userIds, userSearched]);

  return (
    <Container className="h-100" fluid>
      <Row>
        <Col>
          <div className="d-flex flex-column h-100 justify-content-center p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id : "app.asiggnUser.title"})}
            </h2>
            <Form className="px-2" onSubmit={onSubmit}>
              <InputWembii
                type="email"
                placeholder="Email"
                rounded
                className="mt-1"
                value={email}
                mb={false}
                flat
                onChange={(v) => setEmail(v)}
                icon={
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="1x"
                    color="grey"
                    className="m-1 pointer z-index-50"
                    onClick={onSubmit}
                  />
                }
              />
              <div className="mt-4">
                {!searched && ( 
                  <Label className="text-muted text-center w-100 p-4">
                   {intl.formatMessage({ id :  "app.asiggnUser.logEmail" })}
                  </Label>
                )}
                {searched && !userSearched && (
                  <Label className="text-muted text-center w-100 p-4">
                    {intl.formatMessage({ id :  "app.asiggnUser.noFindUser" })}
                  </Label>
                )}
                {searched &&
                  userSearched &&
                  (!isUserAssigned() ? (
                    <AssignUserRow key={userSearched.id} user={userSearched} />
                  ) : (
                    <Label className="text-muted text-center w-100 p-4">
                       {intl.formatMessage({ id :  "app.asiggnUser.UserAsiggnUser" })}
                    </Label>
                  ))}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
