import React, { useEffect, useState } from "react";
import CurrencySelector from "../CurrencySelector";
import NumberInput from "./NumberInput";

interface Props {
  onSubmit: (arg0: string, arg1: string) => void;
  toggleEdition: () => void;
  value: string;
  unit: string;
  allowEmpty?: boolean; 
  showChek?: boolean;
}

export default function TextInput(props: Props) {
  const {
    onSubmit,
    toggleEdition,
    value,
    allowEmpty = false,
    unit,
    showChek = false
  } = props;
  const [newValue, setNewValue] = useState("");
  const [newUnit, setNewUnit] = useState("CLP");

  useEffect(() => {
    setNewValue(value || "");
    setNewUnit(unit || "CLP");
  }, [value, unit]);

  return (
    <>
      <CurrencySelector
        currency={newUnit}
        onSubmit={setNewUnit}
      />
      <NumberInput
        value={parseFloat(newValue)}
        className="pr-4 pt-2"
        onSubmit={(v) =>
          onSubmit(v, newUnit)
        }
        toggleEdition={toggleEdition}
        showChek={showChek}
        allowEmpty={allowEmpty}
      />
    </>
  );
}
