import React from "react";
import { useIntl } from "react-intl";
import { Nav } from "reactstrap";
import { TitleWembii } from "../TitleWembii";
import { NavItem2 } from "./NavItem2";
import { useRouterCore } from "../../../cores/router";
import { useMeCore } from "../../../cores/me";

const SidebarResellerRoutes: React.FC<{
  collapseSideBar: boolean;
}> = ({ collapseSideBar }) => {
  const intl = useIntl();
  const { routes, goToUsers, goToCompany } = useRouterCore();
  const { user } = useMeCore();

  return (
    <>
      <hr className="horizontal-separator my-3" />
      {!collapseSideBar && (
        <TitleWembii Tag="h6">
          {user && user.reseller?.name}
          {intl.formatMessage({ id: "app.sidebar.isReseller" })}
        </TitleWembii>
      )}
      <Nav vertical>
        <NavItem2
          onClick={goToUsers}
          label={intl.formatMessage({ id: "app.sidebar.users" })}
          iconName="users"
          activeUrl={routes.users}
        />
        <NavItem2
          onClick={goToCompany}
          label={intl.formatMessage({ id: "app.sidebar.company" })}
          iconName="building"
          activeUrl={routes.company}
        />
      </Nav>
    </>
  );
};

export default SidebarResellerRoutes;
