import cn from "classnames";
import React, { ReactNode } from "react";
import { Label } from "reactstrap";

interface Props {
  className?: string;
  forid?: string;
  required?: boolean;
  onClick?: () => void;
  children: ReactNode;
}

export const LabelWembii = (props: Props) => {
  const { forid, children, required, className, onClick } = props;

  return (
    <Label
      for={forid}
      className={cn("text-uppercase font-weight-bold mb-0", className)}
      style={{
        fontSize: 10,
      }}
      onClick={onClick}
    >
      {children} {required && <span className="text-primary">*</span>}
    </Label>
  );
};
