import React from "react";
import { Badge } from "reactstrap";
import {
  MercadolibreOrderStatus,
  MercadolibreOrderStatusColor,
  MercadolibreOrderStatusDic,
} from "../../../../cores/market/mercadolibre.config";

interface Props {
  status: MercadolibreOrderStatus;
}

export const MercadolibreOrderStatusBadge = ({ status }: Props) => {
  return (
    <Badge
      pill
      className="p-2 px-3"
      style={{ backgroundColor: MercadolibreOrderStatusColor[status] }}
    >
      {MercadolibreOrderStatusDic[status]}
    </Badge>
  );
};
