import { Dictionary } from "lodash";

export enum CommentType {
  Comment = 1,
  CallAttept = 2,
  SecondCallAttept = 3,
  CallPlaced = 4,
  PhoneBusy = 5,
  WrongPhone = 6,
  MeetingScheduled = 7,
  MeetingCancelled = 8,
  MeetingHeld = 9,
  MeetingComment = 10,
}

export const  CommentTypeLabels: Dictionary<string> = {
  [`${CommentType.Comment}`]: "app.opportunity.comment.comment",
  [`${CommentType.CallAttept}`]: "app.opportunity.comment.callAttempt",
  [`${CommentType.SecondCallAttept}`]: "app.opportunity.comment.secondCallAttempt",
  [`${CommentType.CallPlaced}`]: "app.opportunity.comment.callPlaced",
  [`${CommentType.PhoneBusy}`]: "app.opportunity.comment.phoneBusy",
  [`${CommentType.WrongPhone}`]: "app.opportunity.comment.wrongPhone",
  [`${CommentType.MeetingScheduled}`]: "app.opportunity.comment.meetingScheduled",
  [`${CommentType.MeetingCancelled}`]: "app.opportunity.comment.meetingCancelled",
  [`${CommentType.MeetingHeld}`]: "app.opportunity.comment.meetingHeld",
  [`${CommentType.MeetingComment}`]: "app.opportunity.comment.meetingComment",
};

export const CardStatusOptions = [
  { value: CommentType.Comment, label: "app.opportunity.comment.comment" },
  { value: CommentType.CallAttept, label: "app.opportunity.comment.callAttempt" },
  { value: CommentType.SecondCallAttept, label: "app.opportunity.comment.secondCallAttempt" },
  { value: CommentType.CallPlaced, label: "app.opportunity.comment.callPlaced" },
  { value: CommentType.PhoneBusy, label: "app.opportunity.comment.phoneBusy" },
  { value: CommentType.WrongPhone, label: "app.opportunity.comment.wrongPhone" },
  { value: CommentType.MeetingScheduled, label: "app.opportunity.comment.meetingScheduled" },
  { value: CommentType.MeetingCancelled, label: "app.opportunity.comment.meetingCancelled" },
  { value: CommentType.MeetingHeld, label: "app.opportunity.comment.meetingHeld" },
];