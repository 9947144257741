import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { USER_COMPANIES } from "../../../../constants/modal.constant";
import Avatar from "../../Avatar";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import { usePlanCore } from "../../../../cores/plan";
import { useResellerCore } from "../../../../cores/reseller";
import { User } from "../../../../cores/session/interfaces/models/user";
import { useUserCore } from "../../../../cores/user";
import { useIntl } from "react-intl";
import { getAllUserStatus } from "../../../../cores/user/constants/user-status";
import { PlanBadge } from "../../companies/PlanBadge";
import { StatusBadge } from "../../companies/StatusBadge";
import { InputWembii } from "../../InputWembii";
import { FreeTrialSelector } from "./FreeTrialSelector";
import { ResellerSelector } from "./ResellerSelector";
import UserCompanies from "./UserCompanies";
import { Position } from "../../../../cores/globals/interfaces/globalEntity";
import { getFirstLetters } from "../../../utils/stringUtils";
import { useRouterCore } from "../../../../cores/router";

interface Props {
  user: User;
}

export default function EditUserForm(props: Props) {
  const intl = useIntl();
  const {
    user: {
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneType,
      country,
      userRole,
      userPlan,
      id,
      avatarUrl,
      status,
      reseller,
      freeTrial,
      freeTrialDays,
      freeTrialDate,
    },
  } = props;
  const { goToPlans } = useRouterCore();
  const { plans, assignPlan, assignFreeTrial, unassignFreeTrial } =
    usePlanCore();
  const { putUser } = useUserCore();
  const { isAdmin, isReseller } = useMeCore();
  const [editPlan, setEditPlan] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [planId, setPlanId] = useState("");
  const [statusId, setStatusId] = useState(status);
  const { turnOnModal } = useGlobalCore();
  const { unassignResellerToUser, assignResellerToUser } = useResellerCore();

  useEffect(() => {
    userPlan && setPlanId("" + userPlan.id);
  }, [userPlan]);

  const oldPlan = useMemo(
    () => !plans.find(({ id }) => +id === +planId),
    [planId, plans]
  );

  const onAssignPlan = useCallback(
    async (_planId: string) => {
      await assignPlan(id + "", _planId);
      setPlanId(_planId);
      setEditPlan(false);
    },
    [assignPlan, id]
  );

  const onAssignStatus = useCallback(
    async (status: number) => {
      await putUser(id, { status });
      setStatusId(+status);
      setEditStatus(false);
    },
    [setEditStatus, putUser, id]
  );

  const onAssignReseller = useCallback(
    async (resellerId: number) => {
      if (resellerId) assignResellerToUser(resellerId, +id);
      else unassignResellerToUser(+id);
    },
    [assignResellerToUser, id, unassignResellerToUser]
  );

  const onAssignFreeTrial = useCallback(
    async (value: boolean, days: number) => {
      if (value) assignFreeTrial(id, days);
      else unassignFreeTrial(id);
    },
    [assignFreeTrial, id, unassignFreeTrial]
  );

  const onClickCompanies = useCallback(() => {
    turnOnModal(USER_COMPANIES, <UserCompanies id={id} />, Position.LEFT);
  }, [id, turnOnModal]);

  const plan = useMemo(
    () => {
      const plan = plans.find(({ id }) => +id === +planId);

      return (plan) ? plan : userPlan
    },
    [planId, userPlan, plans]
  );


  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 justify-content-center p-4">
            <h2 className="h2 text-center mb-4">{`Usuario #${id}`}</h2>
            <div className="mb-2 d-flex justify-content-center flex-column align-items-center ">
              <Avatar
                className="text-dark-50 pointer mx-2"
                label={getFirstLetters(`${lastName} ${firstName}`)}
                width={"150px"}
                image={avatarUrl}
                height={"150px"}
              />
              <h4 className="h4 my-2">
                {userRole?.admin 
                  ? intl.formatMessage({ id: "app.adminUsers.admin?" })
                  : intl.formatMessage({ id: "app.adminUsers.user?" })
                }
              </h4>
              <div className="d-flex align-items-center justify-content-center">
                <div className="m-2 d-flex pointer align-items-center">
                  <FontAwesomeIcon
                    icon={["fas", "info-circle"]}
                    className="mx-2"
                    size="1x"
                    onClick={goToPlans}
                  />
                  <div onClick={() => setEditPlan(true)}>
                    {(isAdmin || isReseller) && editPlan ? (
                      <InputWembii
                        mb={false}
                        type="select"
                        value={planId}
                        onChange={(value) => onAssignPlan(value)}
                      >
                        {oldPlan && (
                          <option key={planId} value="" />
                        )}
                        {plans.map(({ id, name }) => (
                          <option key={id} value={"" + id}>
                            {intl.formatMessage({ id: name })}
                          </option>
                        ))}
                        :
                      </InputWembii>
                    ) : (
                      plan && <PlanBadge show plan={plan} />
                    )}
                  </div>
                </div>
                <div className="m-2 d-flex pointer align-items-center">
                  <div onClick={() => setEditStatus(true)}>
                    {isAdmin && editStatus ? (
                      <InputWembii
                        mb={false}
                        type="select"
                        value={statusId}
                        onChange={(v) => onAssignStatus(v)}
                      >
                        {getAllUserStatus().map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                        :
                      </InputWembii>
                    ) : (
                      <StatusBadge status={statusId} show />
                    )}
                  </div>
                </div>
                <div className="m-2 d-flex pointer align-items-center">
                  <ResellerSelector
                    reseller={reseller}
                    onAssignReseller={onAssignReseller}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="m-2 d-flex flex-column pointer justify-content-center">
                  <FreeTrialSelector
                    isFreeTrial={freeTrial}
                    freeTrialDays={freeTrialDays}
                    freeTrialDate={freeTrialDate}
                    onAssignFreeTrial={onAssignFreeTrial}
                  />
                </div>
              </div>
            </div>
            <Form className="px-2">
              <InputWembii
                label={intl.formatMessage({ id: "app.nameTxt" })}
                type="text"
                name="firstname"
                id="contact-firstname"
                placeholder={intl.formatMessage({ id: "app.nameTxt" })}
                value={firstName}
                disabled
              />
              <InputWembii
                label={intl.formatMessage({ id: "app.surnameTxt" })}
                type="text"
                name="lastname"
                id="contact-lastname"
                placeholder={intl.formatMessage({ id: "app.surnameTxt" })}
                value={lastName}
                disabled
              />
              <InputWembii
                label={intl.formatMessage({ id: "app.countryTxt" })}
                type="text"
                name="country"
                id="contact-country"
                placeholder={intl.formatMessage({ id: "app.countryTxt" })}
                value={country}
                disabled
              />
              <InputWembii
                label={intl.formatMessage({ id: "app.emailTxt" })}
                type="email"
                name="email"
                id="contact-email"
                placeholder={intl.formatMessage({ id: "app.emailTxt" })}
                value={email}
                disabled
              />
              <InputWembii
                label={intl.formatMessage({ id: "app.phoneTypeTxt" })}
                type="select"
                name="telephone-type"
                id="contact-telephone-type"
                placeholder={intl.formatMessage({ id: "app.phoneTypeTxt" })}
                value={phoneType}
                disabled
              >
                <option value=''></option>
                <option value='celphone'>
                  Celular
                </option>
                <option value='telephone'>
                  Teléfono fijo
                </option>
              </InputWembii>
              <InputWembii
                label={intl.formatMessage({ id: "app.phoneTxt" })}
                type="text"
                name="telephone"
                id="contact-telephone"
                placeholder={intl.formatMessage({ id: "app.phoneTxt" })}
                value={phoneNumber}
                disabled
              />
            </Form>
            {isAdmin && (
              <Button className="my-2" onClick={onClickCompanies}>
                <FontAwesomeIcon
                  icon={["fas", "building"]}
                  className="mx-2"
                  size="1x"
                />
                {intl.formatMessage({ id: "app.adminUsers.vinculatedCompanies" })}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
