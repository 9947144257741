import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import {
  WOO_COMMERCE_GET_LOADING,
  WOO_COMMERCE_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { wooCommerceApiService } from "./services/api";
import { setWooCommerceName, setWooCommerceUrl } from "./store/actions";
import {
  getWooCommerceCurrencyFailureAction,
  getWooCommerceCurrencyInitAction,
  getWooCommerceCurrencySuccessAction,
  getWooCommerceFailureAction,
  getWooCommerceInitAction,
  getWooCommerceLoginFailureAction,
  getWooCommerceLoginInitAction,
  getWooCommerceLoginSuccessAction,
  getWooCommerceSuccessAction,
  postBindWooCommerceInitAction,
  postBindWooCommerceSuccessAction,
  postUnbindWooCommerceFailureAction,
  postUnbindWooCommerceInitAction,
  postUnbindWooCommerceSuccessAction,
} from "./store/api-actions";
import {
  currencySelector,
  nameSelector,
  settingSelector,
  urlSelector,
} from "./store/selector";

export function useWooCommerceCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const url = useSelector(urlSelector);
  const name = useSelector(nameSelector);
  const currency = useSelector(currencySelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindWooCommerceInitAction());
        await wooCommerceApiService.unbind(companyId);
        dispatch(postUnbindWooCommerceSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindWooCommerceFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (companyId: string, url: string, name: string, onSuccess?: () => void) => {
      try {
        dispatch(postBindWooCommerceInitAction());
        turnOnLoading(WOO_COMMERCE_SYNC_LOADING);
        const response = await wooCommerceApiService.bind(companyId, {
          url,
          name,
        });
        wooCommerceApiService.generateContacts(companyId);
        dispatch(postBindWooCommerceSuccessAction({ settings: response }));
        turnOffLoading(WOO_COMMERCE_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess44" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(WOO_COMMERCE_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error62" }));
        console.error(error);
        dispatch(postUnbindWooCommerceFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getWooCommerceInitAction());
        turnOnLoading(WOO_COMMERCE_GET_LOADING);
        const settings = await wooCommerceApiService.get(companyId);
        dispatch(getWooCommerceSuccessAction({ settings }));
        turnOffLoading(WOO_COMMERCE_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(WOO_COMMERCE_GET_LOADING);
        console.error(error);
        dispatch(getWooCommerceFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getCurrency = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getWooCommerceCurrencyInitAction());
        const { currency } = await wooCommerceApiService.getCurrency(companyId);
        dispatch(getWooCommerceCurrencySuccessAction({ currency }));
      } catch (error: any) {
        console.error(error);
        dispatch(getWooCommerceCurrencyFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const setName = useCallback(
    (name: string) => {
      try {
        dispatch(setWooCommerceName({ name }));
      } catch (error: any) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const setUrl = useCallback(
    (url: string) => {
      try {
        dispatch(setWooCommerceUrl({ url }));
      } catch (error: any) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const login = useCallback(
    async (
      companyId: string,
      scope: string,
      callbackUrl: string,
      url: string
    ) => {
      try {
        dispatch(getWooCommerceLoginInitAction());
        turnOnLoading(WOO_COMMERCE_SYNC_LOADING);
        const response = await wooCommerceApiService.login(companyId, {
          scope,
          callbackUrl,
          url,
        });
        dispatch(getWooCommerceLoginSuccessAction(response));
        return response.url;
      } catch (error: any) {
        turnOffLoading(WOO_COMMERCE_SYNC_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error63" }));
        dispatch(getWooCommerceLoginFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    unbind,
    bind,
    get,
    getCurrency,
    login,
    setUrl,
    setName,
    url,
    name,
    currency,
  };
}
