import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Plan } from "../interfaces/api/get-plans";

export const getPlansInitAction = createAction("[Me] Get Plans Init");
export const getPlansSuccessAction = createAction(
  "[Me] Get Plans Success",
  props<{ plans: Plan[] }>()
);
export const getPlansFailureAction = createAction(
  "[Me] Get Plans Failure",
  props<{ error: string }>()
);

export const postUpgradePlansInitAction = createAction("[Me] Post Upgrade Plans Init");
export const postUpgradePlansSuccessAction = createAction(
  "[Me] Post Upgrade Plans Success"
);
export const postUpgradePlansFailureAction = createAction(
  "[Me] Post Upgrade Plans Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getPlansInitAction,
  getPlansSuccessAction,
  getPlansFailureAction,
  postUpgradePlansInitAction,
  postUpgradePlansSuccessAction,
  postUpgradePlansFailureAction,
});

export type PlanApiAction = typeof actions;
