import React from "react";
import { MERCADOLIBRE_COLOR } from "../../../../constants/socialColors.constant";
import { IconProps } from "../Facebook/FacebookIcon";
import { sizeReference } from "../WooCommerce/WooCommerceIcon";

export const MercadolibreIcon = (props: IconProps) => {
  const { active, className, size = "1x", color } = props;

  return (
    <svg
      className={className}
      width={sizeReference[size]}
      height={sizeReference[size]}
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 153"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M234.1,85.56V90c0,44.13-44.76,79.86-115.45,79.86-54.38,0-93.73-21.42-108.73-51.57A63,63,0,0,1,3.23,90c0-.38,0-3.35,0-4.42s0-2.1.11-3.14C4.56,62.9,17.17,45.25,37,32.27a130.15,130.15,0,0,1,35.2-15.82,168.14,168.14,0,0,1,46.51-6.39C182.42,10.06,234.1,44,234.1,85.56Z"
        transform="translate(-3.23 -3.04)"
        fill={color || (active ? "#283483" : "gray")}
      />
      <path
        d="M229.68,85.52c0,39.21-49.69,71-111,71-42.7,0-79.77-15.43-98.33-38-8-9.7-12.51-20.71-12.65-32.41v-.55c0-21.85,15.42-41.39,39.68-54.41Q49.49,30,51.7,28.9c2.29-1.11,4.65-2.17,7.08-3.16q6.11-2.5,12.73-4.51a164.35,164.35,0,0,1,47.18-6.72C180,14.51,229.68,46.3,229.68,85.52Z"
        transform="translate(-3.23 -3.04)"
        fill={color || (active ? MERCADOLIBRE_COLOR : "gray")}
      />
      <path
        d="M119.22,111.06c-.19,9.11-5.26,11.7-11.6,13s-20.54,2-34.11,2.5-26.18-1.26-32.61-2.16-13.24-2.78-15-2.78-2.73,1-5.41,4.23A60.5,60.5,0,0,1,10,119.07,64,64,0,0,1,3.23,90.35c0-.38,0-3.4,0-4.49s0-2.12.1-3.17c2.88-.67,7.55-1.12,15.07-1.45,12.32-.53,20.55.58,36.17,5.14S77.07,93.8,77.07,93.8c-1.69-5.8-1.76-14.11-1.4-23.13s-.07-14.76-1.8-20.41a114.31,114.31,0,0,0-4-10.81,42.47,42.47,0,0,1-4.91.36c-3.22.09-7.28-1.06-11.21-3s-7.8-3-13.79-4.21a11.68,11.68,0,0,1-2.48-.79c-3.79-1.75-4.07-5.11-2.79-7.52,1.52-2.85,5.09-2.77,5.09-2.77a4.84,4.84,0,0,1-1.25-3.92c.18-2.68,3-5.36,5.54-7.15S49.76,6.33,53,4.27a7.63,7.63,0,0,1,8.31,0,78.29,78.29,0,0,1,7,6.61c1.26,1.26,3,2.93,4.86,4.85a134,134,0,0,1,12,13.54c5,6.79,9.46,16.08,12.77,24.11s12,27.41,14.37,33.13S119.41,102,119.22,111.06Z"
        transform="translate(-3.23 -3.04)"
        fill={color || (active ? "#283483" : "gray")}
      />
      <path
        d="M114,116.42c-3,3.33-7.24,4.34-14.67,4.78-6.4.37-17.81,1.45-23.52,1.37s-12,0-23.39-1.08c-18.75-1.74-22.26-4-26.6-4.12a7.64,7.64,0,0,0-5.16,1.91c-8.08-9.84-12.7-21-12.84-32.89,10.16-1.29,22.31-1.93,41.85,2.74C72,94.46,83.92,101.66,83.92,101.66s-2.7-4.53-3.42-13.86-.15-26.09-1.38-33.6a52.25,52.25,0,0,0-6.07-16.7c-2.1-2.6-5-1.37-7.37-1.37s-8.89-2.24-11.42-3.76A23.94,23.94,0,0,0,48,30.6c-3.12-.59-6.29-1-7-1.27-1.3-.43-3.68-1.44-2.67-3.54s5.85-.22,8.24,1c2.17,1.12,3.33,2.31,5.82,1.57a6.86,6.86,0,0,0,.75-.27c3-1.22,4.2-1.8,5.72-.14s3.68,4.48,5.42,4.69a8.47,8.47,0,0,0,3-.14s-5.49-5-7.73-7.36l-.29-.3c-2-2.24-2.87-4-1.64-6A16,16,0,0,0,59,16.42a8.84,8.84,0,0,0-2,1.18c-.64.48-2.3,1.6-2.3,1.6a2.77,2.77,0,0,1-1.8-1.62c-.41-1.26-.94-3-.94-3a12.23,12.23,0,0,0-.65,4.84s-.48.7-2.58.51a3.32,3.32,0,0,0,2.34.94s-.05,1.27-.09,1.9a3.75,3.75,0,0,0,1.61-1.2,1.39,1.39,0,0,1,2.14-.1,10.82,10.82,0,0,1,1.5,2.46c.17.57-.94,1.05-2.15,1.32s-2.48.92-3.76.65a8.61,8.61,0,0,1-4.41-3.08C44.4,21,42.14,19,42.17,17.58s4.67-4.32,6.81-5.77,5.55-3.75,6.15-4.23S57.7,5.84,60,8.06L72.53,20.58,74.93,23c2.17,2.17,6.85,7.37,9,11.27S95.38,57.3,99.5,66.55,112.44,96.91,114,101.1,116.92,113.1,114,116.42Z"
        transform="translate(-3.23 -3.04)"
        fill="#fff"
      />
      <path
        d="M118.39,111c.19,9.09,5.25,11.68,11.58,12.92s20.5,2,34,2.5,26.12-1.27,32.54-2.16,13.22-2.78,15-2.78,2.72,1,5.39,4.23A60.51,60.51,0,0,0,227.38,119a63.94,63.94,0,0,0,6.77-28.66c0-.39,0-3.39,0-4.48s0-2.12-.1-3.17c-2.87-.67-7.54-1.11-15-1.44-12.3-.53-20.5.58-36.1,5.13s-22.43,7.4-22.43,7.4c1.69-5.79,1.75-14.08,1.4-23.08s.07-14.73,1.79-20.37a114,114,0,0,1,4-10.79,42,42,0,0,0,4.89.36c3.21.09,7.27-1,11.19-3s7.78-3,13.77-4.21a11.22,11.22,0,0,0,2.47-.78c3.78-1.75,4.06-5.1,2.78-7.5-1.51-2.86-5.08-2.77-5.08-2.77a4.84,4.84,0,0,0,1.25-3.92c-.18-2.67-3-5.34-5.52-7.13s-5.71-4.1-8.91-6.15a7.6,7.6,0,0,0-8.29,0,78.16,78.16,0,0,0-7,6.6c-1.26,1.25-3,2.92-4.85,4.84a134.83,134.83,0,0,0-12,13.52c-5,6.77-9.44,16-12.74,24.06s-11.94,27.35-14.35,33.06S118.21,101.88,118.39,111Z"
        transform="translate(-3.23 -3.04)"
        fill={color || (active ? "#283483" : "gray")}
      />
      <path
        d="M123.64,116.32c3,3.32,7.23,4.33,14.64,4.76,6.39.37,17.78,1.45,23.48,1.38s12,0,23.34-1.09c18.71-1.73,22.21-4,26.54-4.11a7.64,7.64,0,0,1,5.16,1.91c8.06-9.82,12.67-21,12.81-32.82-10.14-1.29-22.27-1.93-41.77,2.73-22.28,5.32-34.21,12.51-34.21,12.51s2.69-4.53,3.41-13.83.14-26,1.37-33.54a52.15,52.15,0,0,1,6.06-16.67c2.09-2.59,5-1.37,7.36-1.37s8.87-2.23,11.39-3.75a24,24,0,0,1,6.21-1.76c3.12-.59,6.28-1,7-1.27,1.3-.43,3.68-1.44,2.67-3.53s-5.84-.22-8.22,1c-2.17,1.12-3.32,2.3-5.81,1.56-.24-.07-.49-.16-.75-.26-3-1.23-4.19-1.81-5.7-.15s-3.68,4.48-5.41,4.69a8.44,8.44,0,0,1-3-.14s5.48-5,7.72-7.35l.28-.3c2-2.23,2.87-4,1.64-5.94a16.13,16.13,0,0,1-1.29-2.47,9.34,9.34,0,0,1,2,1.17c.64.49,2.29,1.61,2.29,1.61a2.8,2.8,0,0,0,1.8-1.63c.4-1.25.93-3,.93-3a12.15,12.15,0,0,1,.66,4.84s.47.69,2.57.5a3.28,3.28,0,0,1-2.34.93s.05,1.28.1,1.9a3.6,3.6,0,0,1-1.61-1.2,1.39,1.39,0,0,0-2.14-.09A10.71,10.71,0,0,0,181.3,24c-.17.58.94,1.06,2.14,1.32s2.48.92,3.75.65a8.51,8.51,0,0,0,4.4-3.08c1.47-1.77,3.73-3.72,3.7-5.19s-4.66-4.3-6.8-5.75S183,8.17,182.36,7.69s-2.57-1.73-4.81.48c-1.76,1.74-8.64,8.58-12.56,12.5l-2.4,2.39c-2.16,2.16-6.83,7.35-9,11.25s-11.45,23-15.56,32.24S125.16,96.85,123.64,101,120.68,113,123.64,116.32Z"
        transform="translate(-3.23 -3.04)"
        fill="#fff"
      />
    </svg>
  );
};
