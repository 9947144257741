import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import cn from "classnames";

interface Props {
  className?: string;
  onClick?: () => void;
  color?: string;
  label: string;
  icon?: JSX.Element;
  enabled?: boolean;
  selected?: boolean;
}
export const FilterOption = ({
  label,
  className,
  onClick,
  color,
  icon,
  enabled = true,
  selected = false,
}: Props) => {
  return (
    <span
      className={cn(`d-flex   my-auto ${className}`, {
        "text-secondary": !enabled,
        "text-primary": selected,
        pointer: enabled,
      })}
      onClick={enabled ? onClick : () => null}
    >
      {icon ? (
        icon
      ) : (
        <FontAwesomeIcon
          className={cn("mx-2 my-auto", {
            "text-secondary": !enabled,
            "text-primary": selected,
            pointer: enabled,
          })}
          icon={["fas", "comments"]}
          size="lg"
          color={!selected ? "#E4E4E4" : color}
        />
      )}

      <p className="must-hide my-auto"> {label}</p>
    </span>
  );
};
