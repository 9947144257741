import React from "react";
import Avatar from "../Avatar";
import { FormResponsePayload } from "../../../cores/webForm/interfaces/api/get-webform-response.response";
import { Icon } from "../companies/icons/Icon";
import { useIntl } from "react-intl";
import moment from "moment";
interface Props {
  formResponsePayload: FormResponsePayload;
}

export default function FormResponseCard({ formResponsePayload }: Props) {
  const { response, formTitle, createdAt } = formResponsePayload;
  const intl = useIntl();
  function safeParseJSON() {
    if (!response) return "";
    try {
      return JSON.parse(response);
    } catch (e) {
      return "";
    }
  }

  const keys = safeParseJSON();
  const transformedKeys: any = Object.values(keys).reduce((acc: any, item: any) => {
    return { ...acc, ...item };
  }, {} as any);
  return (
    <div className=" mt-3 mb-4 w-100 border shadow-sm rounded-lg">
      <div className="mx-4 my-4 d-flex">
        <Avatar
          className="text-dark-50"
          width="100"
          height="100"
          icon={<Icon className="group-icon" name="wpforms" prefix="fab" />}
        />
        <p className="ml-2 font-weight-bold my-auto text-center ">
          {intl.formatMessage({ id: "app.sidebar.forms" })}{" "}
        </p>
        <p className="text-muted my-auto mx-2">
          {" "}
          {moment(createdAt).format("DD/MM/YYYY HH:mm a")}
        </p>
      </div>
      <p className="font-weight-bold my-auto text-center ">{formTitle}</p>
      <div className="d-flex flex-column align-items-start ml-2 w-100">
        <div
          className="mt-4 container pre-wrap   email-container overflow-auto "
          style={{ height: "20vh" }}
        >
          <ul className="list-group list-group-flush small ">
            {Object.entries(transformedKeys).map(([key, value]) => (
              <li className="list-group-item text-sm" key={key}>
                <strong>{key}</strong>
                <p className="text-muted text-sm">
                  {value}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
