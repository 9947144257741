import React, { useState } from "react";
import { ContactStateDropdownOptions } from "./SelectContactState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { useSocialChatCore } from "../../../../cores/socialChat";
import cn from "classnames";

const SelectContactStateDropdown: React.FC<{ className?: string }> = ({
  className,
}) => {
  const intl = useIntl();
  const { conversationStatus } = useSocialChatCore();
  const [openMore, setOpenMore] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>(
    intl.formatMessage({ id: "app.chat.filterLabel" })
  );
  return (
    <span
      onClick={() => setOpenMore(!openMore)}
      style={{ borderRadius: "2rem" }}
      className={cn(
        `my-auto d-flex col pointer position-relative border p-2 ${className}`,
        {
          "text-primary": conversationStatus === "inTrash",
        }
      )}
    >
      <FontAwesomeIcon
        className={cn(" mx-2 my-auto ", {
          "text-primary": openMore,
        })}
        icon={["fas", "contact-book"]}
        size="lg"
        color="gray"
      />
      <p className="w-100 text-center my-auto "> {selectedLabel}</p>
      <div
        hidden={!openMore}
        className="bg-light shadow position-absolute"
        style={{ zIndex: 999, top: 30, left: -50 }}
      >
        <ContactStateDropdownOptions
          onSelect={(stateLabel) => setSelectedLabel(stateLabel)}
        />
      </div>
      <FontAwesomeIcon
        className={cn("mx-2 my-auto ", {
          "text-primary": openMore,
        })}
        icon={openMore ? ["fas", "chevron-up"] : ["fas", "chevron-down"]}
        size="lg"
        color="gray"
      />
    </span>
  );
};

export default SelectContactStateDropdown;
