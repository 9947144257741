import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QueryString from "query-string";
import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  BOARD_CARD_MODAL,
  BOARD_CREATE_CONFIG,
  BOARD_EDIT_CONFIG,
} from "../../../constants/modal.constant";
import { COLOR_PRIMARY } from "../../../constants/theme";
import { useBoardCore } from "../../../cores/boards";
import { BoardType } from "../../../cores/boards/constants/board-type.constant";
import { Board } from "../../../cores/boards/interfaces/api/get-board";
import { useCompanyCore } from "../../../cores/company";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import CreateBoard from "./modal/CreateBoard";
import EditBoard from "./modal/EditBoard";

interface Props {
  onSearching: (arg0: string, arg1: string) => void;
  showOptions?: boolean;
}

export default function BoardSelector({
  onSearching,
  showOptions = false,
}: Props) {
  const intl = useIntl();
  const { search } = useLocation();
  const { turnOnModal } = useGlobalCore();
  const { company, isAdmin, isReseller, user, companyRole } = useMeCore();
  const { getCompany } = useCompanyCore();
  const {
    boards,
    boardBusiness: board,
    getCard,
    setBoardUpdate,
    getLists,
  } = useBoardCore();
  const [selected, setSelected] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [boardIdParam, setBoardIdParam] = useState("");
  const [listIdParam, setListIdParam] = useState("");
  const [cardIdParam, setCardIdParam] = useState("");

  const toggle = useCallback(() => {
    setDropdownOpen(!dropdownOpen);
  }, [dropdownOpen]);

  useEffect(() => {
    const { boardId, cardId, listId } = QueryString.parse(search);
    if (boardId && cardId && listId) {
      setBoardIdParam(boardId as string);
      setListIdParam(listId as string);
      setCardIdParam(cardId as string);
    }
  }, [search]);

  useEffect(() => {
    if (boardIdParam && listIdParam && cardIdParam) {
      if (!selected) {
        setSelected(boardIdParam);
        onSearching(company?.id!, boardIdParam);
        getLists(company?.id!, boardIdParam);
        getCompany(company?.id!);
        getCard(
          company?.id!,
          boardIdParam,
          listIdParam,
          cardIdParam,
          true,
          () => {
            turnOnModal(BOARD_CARD_MODAL, true);
          }
        );
      }
    }
  }, [
    company,
    selected,
    boardIdParam,
    listIdParam,
    cardIdParam,
    onSearching,
    getCompany,
    getLists,
    getCard,
    turnOnModal,
  ]);

  useEffect(() => {
    const { boardId } = QueryString.parse(search);
    if (
      boards.length > 0 &&
      boards[0].type === BoardType.BUSINESS &&
      !boardId
    ) {
      if (!selected) {
        if (board) {
          setSelected(board.id);
          onSearching(company?.id!, board.id);
        } else {
          setSelected(boards[0].id);
          onSearching(company?.id!, boards[0].id);
        }
      }
    }
  }, [company, board, search, boards, boardIdParam, selected, onSearching]);

  const selectBoard = useCallback(
    (board: Board) => {
      if (board.id !== selected) {
        onSearching(company?.id!, board.id);
        setSelected(board.id);
      }
    },
    [company, selected, onSearching]
  );

  const onCreating = useCallback(() => {
    turnOnModal(BOARD_CREATE_CONFIG, <CreateBoard type={BoardType.BUSINESS} />);
  }, [turnOnModal]);

  const onEditing = useCallback(
    (e, board: Board) => {
      e.stopPropagation();
      toggle();
      setBoardUpdate({
        name: board.name,
        color: board.color,
        currency: board.currency,
        roles: board.userRoles,
        isChanged: false,
      });

      turnOnModal(
        BOARD_EDIT_CONFIG,
        <EditBoard
          board={board}
          onDelete={() => {
            if (board.id === selected) {
              const selectNext = boards.find((brd) => brd.id !== board.id);

              if (selectNext) {
                setSelected(selectNext.id);
                onSearching(company?.id!, selectNext.id);
              } else {
                setSelected("");
              }
            }
          }}
        />
      );
    },
    [
      selected,
      boards,
      company?.id,
      onSearching,
      turnOnModal,
      toggle,
      setBoardUpdate,
    ]
  );

  const canModify = useCallback(
    (board: Board) =>
      (isAdmin ||
        isReseller ||
        companyRole?.owner ||
        companyRole?.admin ||
        board?.userRoles.find((role) => role.user.id === user?.id)?.owner) &&
      showOptions,
    [isAdmin, isReseller, user, companyRole, showOptions]
  );

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        nav
        className="mb-0 mx-1 p-0 whiteButton px-3 py-2 rounded"
      >
        <>
          {board?.name}
          <span className="mx-3">|</span>
          <FontAwesomeIcon icon={["fas", "chevron-down"]} />
        </>
      </DropdownToggle>
      <DropdownMenu right className="border-0 py-3 px-2 shadow">
        {boards.map((board: Board) => (
          <DropdownItem key={board.id}>
            <div
              className="d-flex align-items-center justify-content-between show-on-hover"
              onClick={() => selectBoard(board)}
            >
              {board.name}
              {board.id === selected && (
                <FontAwesomeIcon
                  icon={["fas", "check"]}
                  className={cn("ml-3", { "hide-on-hover": canModify(board) })}
                  color={COLOR_PRIMARY}
                />
              )}
              {canModify(board) && (
                <FontAwesomeIcon
                  icon={["fas", "edit"]}
                  className="ml-3 showed-on-hover"
                  onClick={(e) => onEditing(e, board)}
                />
              )}
            </div>
          </DropdownItem>
        ))}
        {showOptions && (
          <>
            <DropdownItem divider />
            <DropdownItem onClick={onCreating}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-3" />
                {intl.formatMessage({ id: "app.crmBusiness.create" })}
              </div>
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
