import React, { useMemo, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { EDIT_ME_USER_LOADING } from "../../../constants/loading.constant";
import { useSessionCore } from "../../../cores/session";
import { useIntl } from "react-intl";
import { LoadingSpinnerWembii } from "../LoadingSpinnerWembii";
import { LabelWembii } from "../LabelWembii";
import { useMeCore } from "../../../cores/me";

export const SecurityForm = () => {
  const intl = useIntl();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const { putMePassword } = useMeCore();
  const { logout } = useSessionCore();

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    await putMePassword(password, logout);
  };

  const enabled = useMemo(
    () =>
      password && passwordConfirmation && password === passwordConfirmation,
    [password, passwordConfirmation]
  );

  const isPasswordValid = () => {
    const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{6,})/;
    return passwordRegex.test(password);
  };

  const isRepeatPasswordValid = () => {
    return password === passwordConfirmation;
  };

  return (
    <Row className="my-4">
      <Col sm="12">
        <Form onSubmit={onFormSubmit}>
          <FormGroup>
            <LabelWembii required forid="login-password">
              {intl.formatMessage({ id: "app.passwordTxt" })}
            </LabelWembii>
            <Input
              type="password"
              name="password"
              id="login-password"
              placeholder={intl.formatMessage({ id: "app.passwordTxt" })}
              invalid={!!password && !isPasswordValid()}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {!!password && !isPasswordValid() && (
              <FormFeedback>
                {intl.formatMessage({ id: "app.navbar.errorPass" })}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <LabelWembii required forid="login-password">
              {intl.formatMessage({ id: "app.navbar.confirmPass" })}
            </LabelWembii>
            <Input
              type="password"
              name="passwordConfirmation"
              id="login-password-confirmation"
              placeholder={intl.formatMessage({ id: "app.passwordTxt" })}
              invalid={
                !!password &&
                !!passwordConfirmation &&
                !isRepeatPasswordValid()
              }
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
            {!!password &&
              !!passwordConfirmation &&
              !isRepeatPasswordValid() && (
                <FormFeedback>
                  {intl.formatMessage({ id: "app.navbar.passNotCoincideError" })}
                </FormFeedback>
              )}
          </FormGroup>
          <LoadingSpinnerWembii id={EDIT_ME_USER_LOADING}>
            <Button color="primary" block disabled={!enabled}>
              {intl.formatMessage({ id: "app.navbar.save" })}
            </Button>
          </LoadingSpinnerWembii>
        </Form>
      </Col>
    </Row>
  );
};
