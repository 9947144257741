import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { GMAIL_ME_GET_LOADING, GMAIL_ME_SYNC_LOADING } from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useGmailCore } from "../../../../cores/gmail";
import { useMeCore } from "../../../../cores/me";
import { SocialProviderId, SocialProviderName } from "../../../../cores/socialPost/config";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import { daysRemainingFromToday } from "../../../utils/dateUtils";
import { GmailIcon } from "../../social/Gmail/GmailIcon";
import { GoogleButton } from "../../social/Google/GoogleButton";
import SocialRow from "./SocialRow";

export const GmailBindRow = () => {
  const intl = useIntl();
  const { get, userSettings, unbind, login } = useGmailCore();
  const { company, getMeUserSettings } = useMeCore();
  const { isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id, true);
  }, [company, get, login]);

  const remainingBindDays = useMemo(
    () =>
      userSettings?.expiresIn ? daysRemainingFromToday(userSettings?.expiresIn) : null,
    [userSettings]
  );

  const isActive = useMemo(
    (): boolean => !!userSettings?.email,
    [userSettings]
  );

  const handleBind = useCallback(async () => {
    const loginUrl = await login(company!.id, true);
    if (loginUrl) window.open(loginUrl, "_self");
  }, [company, login]);

  const handleUnbind = useCallback(() => {
    unbind(company!.id, true, () => {
      getMeUserSettings();
    });
  }, [company, unbind, getMeUserSettings]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Gmail,
      name: SocialProviderName.Gmail,
      icon: <GmailIcon size="2x" active={isActive} />,
      displayName: userSettings?.name,
      avatar: userSettings?.avatarUrl,
      email: userSettings?.email
    }),
    [userSettings, isActive]
  );

  return (
    <SocialRow
      social={socialProvider}
      bindLoading={isLoading(GMAIL_ME_SYNC_LOADING)}
      getLoading={isLoading(GMAIL_ME_GET_LOADING)}
      isSync={isActive}
    >
      <div className="w-100 px-1">
        {remainingBindDays !== null && remainingBindDays > 0 && remainingBindDays <= 10 && (
          <div className="small text-danger mb-1 d-flex">
            <FontAwesomeIcon
              size="2x"
              icon={["fas", "exclamation-circle"]}
              className="mr-2"
            />
            {intl.formatMessage(
              { id: "app.home.expiration" },
              {
                remainingDays: remainingBindDays,
              }
            )}
          </div>
        )}

        {remainingBindDays !== null && remainingBindDays <= 0 &&
          <div className="small text-danger mb-1 d-flex px-2"> 
            <FontAwesomeIcon
              size="2x"
              icon={["fas", "exclamation-circle"]}
              className="mr-2"
            />
              {intl.formatMessage({id: "app.home.expired"})}
          </div>   
        }
        <GoogleButton
          isActive={isActive}
          onClick={!isActive ? handleBind : handleUnbind}
        />
      </div>
    </SocialRow>
  );
};
