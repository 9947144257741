import axios from "axios";
import { store } from "../../../redux/store";
import { refreshTokenInterceptor } from "./interceptors/refreshToken.interceptor";

const create = () => {
  const { token, refreshToken, user } = store.getState().session;
  const instance = axios.create({
    baseURL: process.env.REACT_APP_ADVERTISEMENTS_URL || "http://localhost:5007/api",
    headers: { Authorization: token },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    refreshTokenInterceptor(instance, refreshToken, user)
  );

  return instance;
};

export default create;
