import React from "react";
import { AttachmentViewProps } from "./AttachmentView.props";
import AttachmentCard from "../Attachment/AttachmentCard";

const AttachmentView = ({ attachments }: AttachmentViewProps) => {
  return (
    <div className="container mx-auto center  border-top rounded-top justify-content-center align-items-center w-100 p-2 ">
      <div className="d-flex">
        {attachments.map((attachment) => (
          <main key={attachment.id}>
            <AttachmentCard key={attachment.id} data={attachment} />
          </main>
        ))}
      </div>
    </div>
  );
};

export default AttachmentView;
