import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const facebookAdsRoot = (state: State) => state.facebookAds;

export const settingSelector = createSelector(
  facebookAdsRoot,
  (facebookAds) => facebookAds.settings
);
export const adsAccountsSelector = createSelector(
  facebookAdsRoot,
  (facebookAds) => facebookAds.adsAccounts
);
export const accountsSelector = createSelector(
  facebookAdsRoot,
  (facebookAds) => facebookAds.accounts
);
