import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Nav } from "reactstrap";
import { useCompanyCore } from "../../../cores/company";
import { useGlobalCore } from "../../../cores/globals";
import { useMeCore } from "../../../cores/me";
import { useRouterCore } from "../../../cores/router";
import { PlanBadge } from "../companies/PlanBadge";

const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "none",
    width: "200px",
  }),
  input: (styles: any) => ({}),
  option: (styles: any) => ({ ...styles }),
  placeholder: (styles: any) => ({ ...styles }),
  singleValue: (styles: any) => ({ ...styles }),
};

export const CompanySelector = () => {
  const { reset } = useGlobalCore();
  const { getCompanies, companies: adminCompanies } = useCompanyCore();
  const {
    company,
    companies,
    selectCompany,
    companyPlan,
    isAdmin,
  } = useMeCore();
  const { goToSocial } = useRouterCore();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  useEffect(() => {
    getCompanies({});
  }, [getCompanies]);

  const allCompanies = useMemo(
    () => (isAdmin ? [...adminCompanies] : [...companies]),
    [isAdmin, adminCompanies, companies]
  );

  const onChangeCompany = useCallback(
    (option) => {
      reset();
      const payload = allCompanies.find(
        ({ company: { id } }) => +id === +option.value
      );
      payload && selectCompany(payload.company.id);
      goToSocial();
    },
    [allCompanies, reset, selectCompany, goToSocial]
  );

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === company?.id),
    [company, options]
  );

  useEffect(() => {
    setOptions(
      allCompanies.map(({ company: { id, name } }) => ({
        value: id,
        label: name,
      }))
    );
  }, [allCompanies, company, isAdmin]);

  return (
    company && (
      <Nav navbar>
        <div className="px-3 py-1 d-flex align-items-center company-selector" style={{ minWidth: "initial" }}>
          <Select
            type="select"
            value={selectedOption}
            onChange={onChangeCompany}
            classNamePrefix="company-selector"
            isClearable={false}
            options={options}
            isSearchable
            styles={selectStyles}
          />
          {companyPlan && <PlanBadge plan={companyPlan} show />}
        </div>
      </Nav>
    )
  );
};
