import { DeleteAutomationResponse } from "../interfaces/api/delete-automation";
import {
  GetAutomationsPayloadResponse,
  GetAutomationsResponse,
} from "../interfaces/api/get-automations";
import {
  PostActionPayload,
  PostActionResponse,
} from "../interfaces/api/post-action";
import {
  PostAutomationPayload,
  PostAutomationResponse,
} from "../interfaces/api/post-automation";
import {
  PostConditionPayload,
  PostConditionResponse,
} from "../interfaces/api/post-condition";
import {
  PostContactPayload,
  PostContactResponse,
} from "../interfaces/api/post-contact";
import { PostAutomationUserResponse } from "../interfaces/api/post-user";
import { PutActionResponse } from "../interfaces/api/put-action";
import { PutAutomationResponse } from "../interfaces/api/put-automation";
import { PutConditionResponse } from "../interfaces/api/put-condition";

export const automationsMapper = {
  mapResponseToAutomations(
    response: GetAutomationsResponse
  ): GetAutomationsPayloadResponse {
    const { automations } = response?.payload;
    return { automations };
  },
  mapResponseToAutomation(
    response: PostAutomationResponse | PutAutomationResponse
  ): PostAutomationPayload {
    const { automation, actions, conditions, contacts, users } =
      response?.payload;
    return { automation, actions, conditions, contacts, users };
  },
  mapResponseToId(response: DeleteAutomationResponse) {
    const { id } = response?.payload;
    return { id };
  },
  mapResponseToAction(
    response: PostActionResponse | PutActionResponse
  ): PostActionPayload {
    const { action } = response?.payload;
    return { action };
  },
  mapResponseToCondition(
    response: PostConditionResponse | PutConditionResponse
  ): PostConditionPayload {
    const { condition } = response?.payload;
    return { condition };
  },
  mapResponseToContact(response: PostContactResponse): PostContactPayload {
    const { contact } = response?.payload;
    return { contact };
  },
  mapResponseToUser(
    response: PostAutomationUserResponse
  ): PostAutomationUserResponse {
    return response;
  },
};
