import cn from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import { useMeCore } from "../../../../cores/me";
import { InputWembii } from "../../InputWembii";
import { RadiobuttonWembii } from "../../RadiobuttonWembii";
import { useTemplateCore } from "../../../../cores/templates";
import { EmailTemplate } from "../../../../cores/templates/interfaces/api/get-email-templates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { INFO_TAGS } from "../../../../cores/templates/constants/infoTags";
import { CheckboxWembii } from "../../CheckboxWembii";
import ReactQuill from "react-quill";

export default function TemplateEmailForm() {
  const intl = useIntl();
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [addTemplate, setAddTemplate] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [message, setMessage] = useState("");
  const [global, setGlobal] = useState(false);
  const [cursorIndex, setCursorIndex] = useState(0);
  const {
    emailTemplates,
    getEmailTemplates,
    postEmailTemplate,
    putEmailTemplate,
    deleteEmailTemplate,
  } = useTemplateCore();
  const { company, isAdmin } = useMeCore();

  const ref = useRef<any>(null);

  const toggleAddTemplate = useCallback(() => {
    setAddTemplate(!addTemplate);

    if (!addTemplate) {
      setSelectedTemplate("");
      setName("");
      setSubject("");
      setGlobal(false);
      setMessage("");
    }
  },[addTemplate]);

  const onSuccess = useCallback(() => {
    setSelectedTemplate("");
    setName("");
    setSubject("");
    setGlobal(false);
    setMessage("");
    setAddTemplate(true);
    getEmailTemplates(company?.id!);
  }, [getEmailTemplates, company]);

  const onDelete = useCallback(() => {
    deleteEmailTemplate(company?.id!, selectedTemplate, onSuccess);  
  }, [deleteEmailTemplate, onSuccess, selectedTemplate, company]);

  const onFormSubmit = useCallback((e: any) => {
    e.preventDefault();

    if (addTemplate) {
      postEmailTemplate(
        company?.id!,
        {
          name,
          global,
          subject,
          message
        },
        onSuccess
      );
    } else {
      putEmailTemplate(
        company?.id!,
        selectedTemplate, {
          name,
          global,
          subject,
          message
        },
        onSuccess
      );
    }
  }, [
    postEmailTemplate,
    putEmailTemplate,
    onSuccess,
    global,
    selectedTemplate,
    name,
    subject,
    message,
    addTemplate,
    company
  ]);

  const disabled = useCallback(
    () => addTemplate && (!name || !subject || !message),
    [addTemplate, name, subject, message]
  );

  const disabled2 = useCallback(
    () => {
      const template = emailTemplates.find((tp) => +tp.id === +selectedTemplate);

      return !addTemplate && ((
        template &&
        name === template.name &&
        subject === template.subject &&
        global === template.global &&
        message === template.message
      ) || (
        !template
      ));
    },
    [selectedTemplate, name, addTemplate, subject, global, message, emailTemplates]
  );

  const disabled3 = useCallback(
    () => !addTemplate && !selectedTemplate,
    [addTemplate, selectedTemplate]
  );

  const onSelecting = useCallback((value: string) => {
    setSelectedTemplate(value);

    const template = emailTemplates.find((tp) => +tp.id === +value);
    if (template) {
      setName(template.name);
      setSubject(template.subject);
      setGlobal(template.global);
      setMessage(template.message);
    } else {
      setName("");
      setSubject("");
      setGlobal(false);
      setMessage("");
    }
  },[setSelectedTemplate, emailTemplates]);

  const addMessageInfoTag = useCallback((tag) => {
    const editor = ref.current.getEditor();
    const unprivilegedEditor = ref.current.makeUnprivilegedEditor(editor);
    const selection = unprivilegedEditor.getSelection()
      ? unprivilegedEditor.getSelection().index
      : 0;

    editor.insertText(selection, tag.label);
  }, [ref]);

  const addSubjectInfoTag = useCallback((tag) => {
    setSubject(subject.slice(0, cursorIndex)
      + `${tag.label} `
      + subject.slice(cursorIndex)
    );
  }, [subject, setSubject, cursorIndex]);

  const toggleGlobal = useCallback(() => {
    setGlobal(!global);
  },[global]);

  const templatesAvailable = useMemo(() => 
    (isAdmin)
      ? emailTemplates
      : emailTemplates.filter((template) => !template.global)
  , [emailTemplates, isAdmin]);

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 p-3">
            <h2 className="h2 d-flex justify-content-center align-items-center mb-4">
              {intl.formatMessage({ id: "app.sendEmail.template" })}
            </h2>
            <Form className="px-2 h-50" onSubmit={onFormSubmit}>
              <div className="d-flex align-items-center justify-content-around">
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.sendEmail.template.add" })}
                  checked={addTemplate}
                  onChange={toggleAddTemplate}
                />
                <RadiobuttonWembii
                  label={intl.formatMessage({ id: "app.sendEmail.template.modify" })}
                  checked={!addTemplate}
                  onChange={toggleAddTemplate}
                />
              </div>
              <InputWembii
                type="select"
                onChange={onSelecting}
                value={selectedTemplate}
                disabled={addTemplate}
              >
                <option value="">
                  {intl.formatMessage({ id: "app.select.placeholder" })}
                </option>
                {templatesAvailable.map((template: EmailTemplate) => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </InputWembii>

              <hr />

              <div className="d-flex align-items-center justify-content-between">
                <InputWembii
                  label={intl.formatMessage({ id: "app.sendEmail.template.name" })}
                  type="text"
                  name="name"
                  className={cn({ "w-75": isAdmin, "w-100": !isAdmin })}
                  required
                  placeholder={intl.formatMessage({ id: "app.sendEmail.template.name" })}
                  value={name}
                  onChange={setName}
                />
                {isAdmin && (
                  <CheckboxWembii
                    mb={false}
                    label={intl.formatMessage({ id: "app.sendEmail.template.global" })}
                    checked={global}
                    onChange={toggleGlobal}
                  />
                )}
              </div>

              <InputWembii
                label={intl.formatMessage({ id: "app.sendEmail.template.subject" })}
                type="text"
                name="subject"
                className="w-100"
                required
                placeholder={intl.formatMessage({ id: "app.sendEmail.template.subject" })}
                value={subject}
                onKeyUp={(ev: any) => setCursorIndex(ev.target.selectionStart)}
                onClick={(ev: any) => setCursorIndex(ev.target.selectionStart)}
                icon={
                  <div className="border-left pl-2">
                    <UncontrolledDropdown>
                      <DropdownToggle nav className="mb-0 mx-1 p-0">
                        <FontAwesomeIcon
                          icon={["fas", "asterisk"]}
                          color="grey"
                          className="mx-1 pointer"
                        />
                      </DropdownToggle>
                      <DropdownMenu right className="border-0 py-3 px-2 shadow">
                        {Object.values(INFO_TAGS).map((tag, i) => (
                          <DropdownItem key={i} onClick={() => addSubjectInfoTag(tag)}>
                            <div className="d-flex align-items-center">
                              {intl.formatMessage({ id: tag.name })}
                            </div>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                }
                iconPosition="right"
                onChange={setSubject}
              />
              
              <div className="pt-2 px-2 wembii-border-small">
                <ReactQuill
                  value={message}
                  ref={ref}
                  className="textEditor"
                  onChange={setMessage}
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline','strike', 'blockquote'],
                      [{ 'align': [] }],
                      [{ 'color': [] }, { 'background': [] }],
                      [{ 'size': ['small', false, 'large', 'huge'] }],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      [{ 'indent': '-1' }, { 'indent': '+1' }],
                      [{ 'script': 'sub' }, { 'script': 'super' }],
                      ['link'],
                      ['clean']
                    ],
                  }}
                  formats={[
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'align',
                    'color', 'background',
                    'size',
                    'list', 'bullet',
                    'indent',
                    'script',
                    'link'
                  ]}
                />
                <hr className="my-1" />
                <div className="d-flex justify-content-between py-1 px-3">
                  <div className="d-flex">
                    <UncontrolledDropdown>
                      <DropdownToggle nav className="mb-0 mx-1 p-0">
                        <FontAwesomeIcon
                          icon={["fas", "asterisk"]}
                          color="grey"
                          className="mx-1 pointer"
                        />
                      </DropdownToggle>
                      <DropdownMenu right className="border-0 py-3 px-2 shadow">
                        {Object.values(INFO_TAGS).map((tag, i) => (
                          <DropdownItem key={i} onClick={() => addMessageInfoTag(tag)}>
                            <div className="d-flex align-items-center">
                              {intl.formatMessage({ id: tag.name })}
                            </div>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <Button
                  color="primary"
                  block
                  disabled={disabled() || disabled2()}
                >
                  {intl.formatMessage({ id: "app.sendEmail.template.save" })}
                </Button>
                {!addTemplate && (
                  <Button
                    className="my-1"
                    color="danger"
                    block
                    onClick={onDelete}
                    disabled={disabled3()}
                  >
                    {intl.formatMessage({ id: "app.sendEmail.template.delete" })}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
