import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { useLinkedInCore } from "../../../../cores/linkedIn";
import { useMeCore } from "../../../../cores/me";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { Mention } from "../../../../cores/socialPost/interfaces/Mention";
import { useTwitterCore } from "../../../../cores/twitter";
import { InputWembii } from "../../InputWembii";
import { LabelWembii } from "../../LabelWembii";
import { IconMapper } from "../selector/SocialPostSelector";
import { v4 } from "uuid";
import { UpdateLoadingCard } from "../../UpdateLoadingCard";
import { LINKED_IN_PROFILES_GET_LOADING, TWITTER_PROFILES_GET_LOADING } from "../../../../constants/loading.constant";
import { useIntl } from "react-intl";

export const ableToMention = [
  SocialProviderId.Twitter,
  SocialProviderId.LinkedIn,
  SocialProviderId.LinkedInBusiness,
];

export const MentionForm = () => {
  const intl = useIntl();
  const { company } = useMeCore();
  const {
    getProfiles: getTwitterProfiles,
    profiles: twitterProfiles
  } = useTwitterCore();
  const {
    getProfiles: getLinkedinProfiles,
    profiles: linkedinProfiles
  } = useLinkedInCore();
  const {
    socialProviders,
    inputCursorIndex,
    updateMentions,
    addMention
  } = useSocialPostCore();
  const [mention, setMention] = useState("");
  const [providerId, setProviderId] = useState<string>("");
  const [mentions, setMentions] = useState<Mention[]>([]);

  const socialProvidersAbleToPost = useMemo(() => {
    let providers = socialProviders
      .filter((provider) => ableToMention.includes(provider.id as SocialProviderId));

    return providers;
  }, [socialProviders]);

  useEffect(() => {
    if (socialProviders.length === 0) setProviderId("");
    if (socialProviders.length > 0)
      if (
        !providerId ||
        !socialProviders.filter((sp) => sp.id === providerId).length
      )
      setProviderId(socialProviders[0].id);
  }, [mentions, socialProviders, providerId]);

  const profileList = useMemo(() => {
    switch (providerId) {
      case SocialProviderId.Twitter:
        return twitterProfiles;
      case SocialProviderId.LinkedIn:
        return linkedinProfiles;
      case SocialProviderId.LinkedInBusiness:
        return linkedinProfiles;
      default: 
        return [];
    }
  }, [twitterProfiles, linkedinProfiles, providerId]);

  const debouncedSearch = useRef(
    _.debounce((search) => setMention(search), 1000)
  ).current;

  const isSelected = useCallback((profile) =>
    mentions.find((mention) =>
      mention.socialProviderId === providerId && mention.id === profile.id)
  , [mentions, providerId]);

  useEffect(() => {
    if (mention) {
      if (socialProviders.find((provider) => provider.id === SocialProviderId.Twitter))
        getTwitterProfiles(company?.id!, mention);
      if (socialProviders.find((provider) => provider.id === SocialProviderId.LinkedIn))
        getLinkedinProfiles(company?.id!, mention);
      if (socialProviders.find((provider) => provider.id === SocialProviderId.LinkedInBusiness))
        getLinkedinProfiles(company?.id!, mention);
    }
  }, [mention, socialProviders, company, getTwitterProfiles, getLinkedinProfiles]);

  const handleAddMention = useCallback((profile) => {
    const longitud = providerId === SocialProviderId.Twitter
      ? profile.username.length
      : profile.name.length;
    const username = providerId === SocialProviderId.Twitter
      ? profile.username
      : profile.name;

    const mention = {
      id: profile.id,
      username,
      longitud,
      socialProviderId: providerId
    };

    setMentions([
      ...mentions.filter((mention) =>
        mention.socialProviderId !== providerId),
      mention
    ]);
  }, [providerId, mentions]);

  const handleAddMentions = useCallback(() => {
    addMention({
      id: v4(),
      offset: inputCursorIndex,
      mentions
    });
    updateMentions(mentions[0].longitud + 2, inputCursorIndex + 1);

    setMentions([]);
    setMention("");
  }, [mentions, inputCursorIndex, addMention, updateMentions]);

  return (
    <Container fluid className="p-3">
      <Row className="w-100 m-0">
        <Col sm="12">
          <LabelWembii forid="at">
            {intl.formatMessage({ id: "app.socialMediaPost.mention" })}
          </LabelWembii>
          <InputWembii
            type="text"
            name="at"
            id="at"
            mb={false}
            flat
            required
            icon={
              <FontAwesomeIcon
                icon={["fas", "at"]}
                size="1x"
                color="gray"
                className="m-1 z-index-50"
              />
            }
            iconPosition="left"
            onChange={(v) => debouncedSearch(v)}
          />
        </Col>
      </Row>
      <Row className="justify-content-around my-4">
        {socialProvidersAbleToPost.sort().map(({ id }) => (
          <Col xs="auto" key={id}>
            <div className="pointer" onClick={() => setProviderId(id)}>
              {_.isFunction(IconMapper[id]) &&
                IconMapper[id]({ active: id === providerId , size: "2x" })}
            </div>
          </Col>
        ))}
      </Row>
      <div className="overflow-auto" style={{ height: "190px" }}>
        <UpdateLoadingCard
          loadingId={[LINKED_IN_PROFILES_GET_LOADING, TWITTER_PROFILES_GET_LOADING]}
          text={intl.formatMessage({ id: "app.socialMediaPost.searchingProfiles" })}
        />
        {!providerId && (
          <div className="h-100 d-flex justify-content-center align-items-center text-muted">
            {intl.formatMessage({ id: "app.socialMediaPost.selectNetwork" })}
          </div>
        )}
        {providerId && profileList.length === 0 && (
          <div className="h-100 d-flex justify-content-center align-items-center text-muted">
            {intl.formatMessage({ id: "app.socialMediaPost.noProfiles" })}
          </div>
        )}
        {profileList.map((profile) => (
          <div
            key={profile.id}
            className="d-flex pointer p-2"
            style={{ 
              border: isSelected(profile) ? `3px solid ${COLOR_PRIMARY}` : "1px solid #dee2e6",
            }}
            onClick={() => handleAddMention(profile)}
          >
            {profile.avatarUrl && (
              <img
                style={{ width: "50px", height: "50px" }}
                className="rounded-circle mr-2"
                src={profile.avatarUrl}
                alt="Avatar"
              />
            )}
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex justify-content-center font-weight-bold">
                {profile.name}
                {profile.verified && (
                  <FontAwesomeIcon
                    icon={["fas", "certificate"]}
                    size="1x"
                    color={COLOR_PRIMARY}
                    className="m-1 z-index-50"
                  />
                )}
              </div>
              <div>
                {providerId === SocialProviderId.Twitter ? (
                  `@${profile.username}`
                ) : (
                  profile.username
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-1">
        <Button
          onClick={handleAddMentions}
          disabled={!mentions.length}
          color="primary"
        >
          {intl.formatMessage({ id: "app.socialMediaPost.add" })}
        </Button>
      </div>
    </Container>
  );
};
