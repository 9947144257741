import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  LINKED_IN_GET_LOADING,
  LINKED_IN_POST_LOADING,
  LINKED_IN_PROFILES_GET_LOADING,
  LINKED_IN_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { Media } from "../socialPost/interfaces/api/get-social-posts";
import { PostImage } from "../socialPost/interfaces/postImage";
import { linkedInApiService } from "./services/api";
import {
  getLinkedInFailureAction,
  getLinkedInInitAction,
  getLinkedInProfilesFailureAction,
  getLinkedInProfilesInitAction,
  getLinkedInProfilesSuccessAction,
  getLinkedInSuccessAction,
  postBindLinkedInInitAction,
  postBindLinkedInSuccessAction,
  postLinkedInSocialPostFailureAction,
  postLinkedInSocialPostInitAction,
  postLinkedInSocialPostSuccessAction,
  postUnbindLinkedInFailureAction,
  postUnbindLinkedInInitAction,
  postUnbindLinkedInSuccessAction,
  putLinkedInSocialPostFailureAction,
  putLinkedInSocialPostInitAction,
  putLinkedInSocialPostSuccessAction,
} from "./store/api-actions";
import { useIntl } from "react-intl";
import { profilesSelector, settingSelector } from "./store/selector";
import { Mentions } from "../socialPost/interfaces/Mention";

export function useLinkedInCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const profiles = useSelector(profilesSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();
  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindLinkedInInitAction());
        await linkedInApiService.unbind(companyId);
        dispatch(postUnbindLinkedInSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindLinkedInFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (companyId: string, token: string, onSuccess?: () => void) => {
      try {
        dispatch(postBindLinkedInInitAction());
        turnOnLoading(LINKED_IN_SYNC_LOADING);
        const response = await linkedInApiService.bind(companyId, {
          token,
        });
        dispatch(postBindLinkedInSuccessAction({ settings: response }));
        turnOffLoading(LINKED_IN_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess16" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(LINKED_IN_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error34" }));
        console.error(error);
        dispatch(postUnbindLinkedInFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getLinkedInInitAction());
        turnOnLoading(LINKED_IN_GET_LOADING);
        const response = await linkedInApiService.get(companyId);
        dispatch(getLinkedInSuccessAction({ settings: response }));
        turnOffLoading(LINKED_IN_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(LINKED_IN_GET_LOADING);
        console.error(error);
        dispatch(getLinkedInFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getProfiles = useCallback(
    async (companyId: string, name: string) => {
      try {
        dispatch(getLinkedInProfilesInitAction());
        turnOnLoading(LINKED_IN_PROFILES_GET_LOADING);
        const response = await linkedInApiService.getProfiles(companyId, name);
        dispatch(getLinkedInProfilesSuccessAction(response));
        turnOffLoading(LINKED_IN_PROFILES_GET_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(LINKED_IN_PROFILES_GET_LOADING);
        dispatch(getLinkedInProfilesFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const socialPost = useCallback(
    async (
      companyId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      repostId: string,
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(postLinkedInSocialPostInitAction());
        turnOnLoading(LINKED_IN_POST_LOADING);
        await linkedInApiService.socialPost(
          text,
          mentions,
          images,
          scheduleDate,
          repostId,
          companyId
        );
        dispatch(postLinkedInSocialPostSuccessAction());
        turnOffLoading(LINKED_IN_POST_LOADING);
        toast.success(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.sucess17" })
            : intl.formatMessage({ id: "app.toast.sucess18" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(LINKED_IN_POST_LOADING);
        toast.error(
          scheduleDate
            ? intl.formatMessage({ id: "app.toast.error35" })
            : intl.formatMessage({ id: "app.toast.error36" })
        );
        console.error(error);
        dispatch(postLinkedInSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const modifySocialPost = useCallback(
    async (
      companyId: string,
      postId: string,
      text: string,
      mentions: Mentions[],
      images: PostImage[],
      scheduleDate: string,
      media: Media[],
      onSuccess?: () => void,
    ) => {
      try {
        dispatch(putLinkedInSocialPostInitAction());
        turnOnLoading(LINKED_IN_POST_LOADING);
        await linkedInApiService.putSocialPost(
          postId,
          text,
          mentions,
          images,
          scheduleDate,
          companyId,
          media
        );
        dispatch(putLinkedInSocialPostSuccessAction());
        turnOffLoading(LINKED_IN_POST_LOADING);
        toast.success(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.sucess17" })
          : intl.formatMessage({ id: "app.toast.sucess18" })
        );
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(LINKED_IN_POST_LOADING);
        toast.error(
          scheduleDate
          ? intl.formatMessage({ id: "app.toast.error35" })
          : intl.formatMessage({ id: "app.toast.error36" })
        );
        console.error(error);
        dispatch(putLinkedInSocialPostFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    profiles,
    unbind,
    bind,
    get,
    socialPost,
    modifySocialPost,
    getProfiles,
  };
}
