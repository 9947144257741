import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PRODUCTS_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { productApiService } from "./services/api";
import { productSelector, productsSelector } from "./store/selector";
import { GetProductsParams } from "./interfaces/api/get-products";
import {
  deleteProductFailureAction,
  deleteProductInitAction,
  deleteProductSuccessAction,
  getProductFailureAction,
  getProductInitAction,
  getProductsFailureAction,
  getProductsInitAction,
  getProductsSuccessAction,
  getProductSuccessAction,
  postProductFailureAction,
  postProductInitAction,
  postProductSuccessAction,
  putProductFailureAction,
  putProductInitAction,
  putProductSuccessAction
} from "./store/api-actions";
import { PostProductRequest } from "./interfaces/api/post-product";
import { PutProductRequest } from "./interfaces/api/put-product";

export function useProductCore() {
  const dispatch = useDispatch();
  const products = useSelector(productsSelector);
  const product = useSelector(productSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const getProducts = useCallback(
    async (
      companyId: string,
      params: GetProductsParams,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(getProductsInitAction());
        turnOnLoading(PRODUCTS_LOADING);
        const response = await productApiService.getProducts(companyId, params);
        dispatch(getProductsSuccessAction(response));
        turnOffLoading(PRODUCTS_LOADING, 1500);
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(PRODUCTS_LOADING);
        console.error(error);
        dispatch(getProductsFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );
  const getProduct = useCallback(
    async (companyId: string, productId: string) => {
      try {
        dispatch(getProductInitAction());
        const response = await productApiService.getProduct(companyId, productId);
        dispatch(getProductSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(getProductFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const postProduct = useCallback(
    async (
      companyId: string,
      body: PostProductRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postProductInitAction());
        const response = await productApiService.postProduct(
          companyId,
          body
        );
        dispatch(postProductSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postProductFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const putProduct = useCallback(
    async (
      companyId: string,
      productId: string,
      body: PutProductRequest,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(putProductInitAction());
        const response = await productApiService.putProduct(
          companyId,
          productId,
          body
        );
        dispatch(putProductSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(putProductFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const deleteProduct = useCallback(
    async (companyId: string, productId: string, onSuccess?: () => void) => {
      try {
        dispatch(deleteProductInitAction());
        const response = await productApiService.deleteProduct(productId, companyId);
        dispatch(deleteProductSuccessAction(response));
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(deleteProductFailureAction());
      }
    },
    [dispatch]
  );

  return {
    products,
    product,
    getProducts,
    getProduct,
    postProduct,
    putProduct,
    deleteProduct,
  };
}
