import { produce } from "immer";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { Reseller } from "../interfaces/api/get-resellers";
import {
  createResellerSuccessAction,
  getResellersSuccessAction,
  putResellerSuccessAction,
  ResellerApiAction,
} from "./api-actions";

export interface ResellerState {
  ids: number[];
  entities: Record<string, Reseller>;
}

const initialState: ResellerState = {
  ids: [],
  entities: {},
};

const resellerAdapter = createEntityAdapter<Reseller>(
  (reseller) => reseller.id
);

export default produce(
  (draft: ResellerState = initialState, action: ResellerApiAction) => {
    switch (action.type) {
      case getResellersSuccessAction.type:
        resellerAdapter.addMany(action.resellers, draft);
        return draft;
      case createResellerSuccessAction.type:
        resellerAdapter.setOne(action.reseller, draft);
        return draft;
      case putResellerSuccessAction.type:
        resellerAdapter.updateOne(action.reseller, draft);
        return draft;
      default:
        return draft;
    }
  }
);
