import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { WhatsappSetting } from "../interface/api/post-whatsapp-bind";

export const postUnbindWhatsappInitAction = createAction(
  "[Whatsapp] Unbind Whatsapp Init"
);
export const postUnbindWhatsappSuccessAction = createAction(
  "[Whatsapp] Unbind Whatsapp Success"
);
export const postUnbindWhatsappFailureAction = createAction(
  "[Whatsapp] Unbind Whatsapp Failure",
  props<{ error: string }>()
);

export const postBindWhatsappInitAction = createAction(
  "[Whatsapp] Bind Whatsapp Init"
);
export const completeAuth0Verification = createAction(
  "[Whatsapp] Complete Auth0 Verification",
  props<{ code: string }>()
);
export const getAuth0WhatsappSuccessAction = createAction(
  "[Whatsapp] Auth0 Whatsapp Success",
  props<{ settings: WhatsappSetting }>()
);
export const postBindWhatsappSuccessAction = createAction(
  "[Whatsapp] Bind Whatsapp Success",
  props<{ settings: WhatsappSetting }>()
);
export const postBindWhatsappFailureAction = createAction(
  "[Whatsapp] Bind Whatsapp Failure",
  props<{ error: string }>()
);

export const getWhatsappInitAction = createAction(
  "[Whatsapp] Get Whatsapp Settings Init"
);
export const getWhatsappSuccessAction = createAction(
  "[Whatsapp] Get Whatsapp Settings Success",
  props<{ settings: WhatsappSetting }>()
);
export const getWhatsappFailureAction = createAction(
  "[Whatsapp] Get Whatsapp Settings Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindWhatsappInitAction,
  postUnbindWhatsappSuccessAction,
  postUnbindWhatsappFailureAction,
  postBindWhatsappInitAction,
  postBindWhatsappSuccessAction,
  postBindWhatsappFailureAction,
  getWhatsappInitAction,
  getWhatsappSuccessAction,
  getWhatsappFailureAction,
  completeAuth0Verification,
});

export type WhatsappApiAction = typeof actions;
