import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ContactsImportedNotification } from "../../../cores/notifications/interfaces/api/get-notifications";
import { SocialProviderNameMapper } from "../../../cores/socialPost/config";
import { SocialProviderIcon } from "../socialPost/selector/SocialProviderIcon";

interface Props {
  notification: ContactsImportedNotification; 
}

export default function ContactsImportedNotificationItem({
  notification
}: Props) {
  return (
    <div className="d-flex align-items-center border-bottom mb-1">
      <SocialProviderIcon
        className="mr-3"
        isSelected
        showErrors={false}
        socialProvider={{
          id: notification.origin,
        }}
      />
      <div className="d-flex flex-column overflow-hidden">
        <span className="capitalize">{`${SocialProviderNameMapper[notification.origin]} Contactos`}</span>

        <span className="text-truncate text-success">
          <FontAwesomeIcon
            icon={["fas", "check"]}
            className="mr-2"
          />
          Se importaron {notification.amount} contacto/s correctamente
        </span>
      </div>
    </div>
  );
}
