import React, { useCallback, useState, useMemo } from "react";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { useGlobalCore } from "../../../../cores/globals";
import { InputWembii } from "../../InputWembii";
import { EVENT_TIME } from "../../../../cores/event/constants/eventTime.constant";
import { useEventCore } from "../../../../cores/event";
import { TIME_AVAILABILITY } from "../../../../constants/internalModal.constant";

interface Props {
  weekday: string;
}

export default function TimeForm({ weekday }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const { postCalendarTime, calendar } = useEventCore();
  const { turnOffInternalModal } = useGlobalCore();

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const onSubmit = async (e: any) => {
    e.preventDefault();

    postCalendarTime(
      company?.id!,
      calendar?.id!,
      {
        weekday,
        start,
        end
      }
    );
    turnOffInternalModal(TIME_AVAILABILITY);
  };

  const isTimeRangeCorrect = useMemo(() => 
    start && end ? start < end : true  
  , [start, end]);

  const disabled = useCallback(
    () => !start || !end || !isTimeRangeCorrect,
    [start, end, isTimeRangeCorrect]
  );

  return (
    <Form onSubmit={onSubmit} style={{ width: 200 }}>
      {intl.formatMessage({ id: "app.calendar.time.range" })}
      <div className="d-flex">
        <InputWembii
          mb={false}
          type="select"
          className="mr-1"
          required
          value={start}
          onChange={setStart}
        >
          {EVENT_TIME.map((hour) => (
            <option key={hour} value={hour}>
              {hour}
            </option>
          ))}
        </InputWembii>
        <InputWembii
          mb={false}
          type="select"
          className="ml-1"
          required
          value={end}
          onChange={setEnd}
        >
          {EVENT_TIME.map((hour) => (
            <option key={hour} value={hour}>
              {hour}
            </option>
          ))}
        </InputWembii>
      </div>
      {!isTimeRangeCorrect && (
        <div className="error d-flex flex-wrap">
          {intl.formatMessage({ id: "app.calendar.time.range.error" })}
        </div>
      )}

      <Button color="primary" disabled={disabled()} className="mt-2">
        Agregar 
      </Button>
    </Form>
  );
}