import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESELLER_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { resellerApiService } from "./services/api";
import {
  createResellerFailureAction,
  createResellerInitAction,
  createResellerSuccessAction,
  getResellersFailureAction,
  getResellersInitAction,
  getResellersSuccessAction,
  putResellerFailureAction,
  putResellerInitAction,
  putResellerSuccessAction,
} from "./store/api-actions";
import { resellersSelector } from "./store/selector";

export function useResellerCore() {
  const dispatch = useDispatch();
  const resellers = useSelector(resellersSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const postReseller = useCallback(
    async (name: string, companyCreationLimit: number) => {
      try {
        dispatch(createResellerInitAction());
        turnOnLoading(RESELLER_LOADING);
        const reseller = await resellerApiService.postReseller(
          name,
          companyCreationLimit
        );
        dispatch(createResellerSuccessAction({ reseller }));
        turnOffLoading(RESELLER_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(RESELLER_LOADING);
        dispatch(createResellerFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  const putReseller = useCallback(
    async (
      id: string,
      body: { name?: string; companyCreationLimit?: number }
    ) => {
      try {
        dispatch(putResellerInitAction());
        const reseller = await resellerApiService.putReseller(id, body);
        dispatch(putResellerSuccessAction({ reseller }));
      } catch (error: any) {
        console.error(error);
        dispatch(putResellerFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const getResellers = useCallback(async () => {
    try {
      turnOnLoading(RESELLER_LOADING);
      dispatch(getResellersInitAction());
      const resellers = await resellerApiService.getResellers();
      turnOffLoading(RESELLER_LOADING, 500);
      dispatch(getResellersSuccessAction({ resellers }));
    } catch (error: any) {
      turnOffLoading(RESELLER_LOADING);
      console.error(error);
      dispatch(getResellersFailureAction({ error }));
    }
  }, [dispatch, turnOffLoading, turnOnLoading]);

  const assignResellerToUser = useCallback(
    async (userId: number, resellerId: number) => {
      try {
        await resellerApiService.postAssignUserReseller(userId, resellerId);
      } catch (error: any) {
        console.error(error);
      }
    },
    []
  );

  const unassignResellerToUser = useCallback(async (userId: number) => {
    try {
      await resellerApiService.deleteUnassignUserReseller(userId);
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  const assignResellerToCompany = useCallback(
    async (companyId: number, resellerId: number) => {
      try {
        await resellerApiService.postAssignCompanyReseller(
          companyId,
          resellerId
        );
      } catch (error: any) {
        console.error(error);
      }
    },
    []
  );

  const unassignResellerToCompany = useCallback(async (companyId: number) => {
    try {
      await resellerApiService.deleteUnassignCompanyReseller(companyId);
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  return {
    postReseller,
    getResellers,
    putReseller,
    resellers,
    assignResellerToUser,
    unassignResellerToUser,
    assignResellerToCompany,
    unassignResellerToCompany,
  };
}
