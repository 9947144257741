import React, { useCallback, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { FACEBOOK_FORMS_LOADING } from "../../constants/loading.constant";
import { useFacebookFormCore } from "../../cores/facebookForm";
import { useMeCore } from "../../cores/me";
import { useRouterCore } from "../../cores/router";
import FormCard from "../component/forms/facebook/FormCard";
import { NoData } from "../component/NoData";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import Content from "../layout/Content";
import { Header2 } from "../layout/Header2";
import Layout from "../layout/Layout";
import { useIntl } from "react-intl";
import { View } from "../../cores/facebookForm/constants/view";
import { ViewButtons } from "../component/ViewButtons";

export default function FacebookForms() {
  const intl = useIntl();
  const { facebookForms, view, setView, getFacebookForms } = useFacebookFormCore();
  const { goToViewFacebookForm } = useRouterCore();
  const { company, companyRole, isAdmin, isReseller, socialSettings } = useMeCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    if (
      !socialSettings?.facebook || (
        !companyRole?.forms &&
        !companyRole?.owner &&
        !companyRole?.admin &&
        !companyRole?.agent &&
        !isAdmin &&
        !isReseller
      )
    ) goToSocial();
  }, [goToSocial, socialSettings, companyRole, isAdmin, isReseller]);

  useEffect(() => {
    getFacebookForms(company?.id!);
  }, [getFacebookForms, company]);

  useEffect(() => {}, [facebookForms]);

  const handleViewFacebookForm = useCallback(async (facebookForm) => {
    goToViewFacebookForm(`${facebookForm.id}`);
  }, [goToViewFacebookForm]);

  return (
    <Layout>
      <Header2
        title={intl.formatMessage({ id: "app.facebookForm.title" })}
        rightChildren={
          <ViewButtons setView={setView} view={view} />
        }
      />  
      <Content>
        <UpdateLoadingCard
          loadingId={FACEBOOK_FORMS_LOADING}
          text={intl.formatMessage({ id: "app.facebookForm.updatingForm" })} 
        />
        <Container>
          {facebookForms.length ? (
            <Row>
              {facebookForms.map((facebookForm) => (
                <Col
                  md={view === View.GRID ? '4' : '12'}
                  sm={view === View.GRID ? '6' : '12'}
                  xs="12"
                  key={facebookForm.id}
                  className="mb-4"
                >
                  <FormCard
                    facebookForm={facebookForm}
                    onView={() => handleViewFacebookForm(facebookForm)}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <NoData
              image="generic3"
              text={intl.formatMessage({ id: "app.facebookForm.notForm" })} 
              horizontal
            />
          )}
        </Container>
      </Content>
    </Layout>
  );
}
