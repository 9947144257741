import notificationApiClient from "../../helpers/services/notification-api-client";
import { GetNotificationsResponse } from "../interfaces/api/get-notifications";
import { GetSystemNotificationsResponse } from "../interfaces/api/get-system-notifications";
import { PutReadNotificationResponse } from "../interfaces/api/put-read-notification";
import { PutReadNotificationsResponse } from "../interfaces/api/put-read-notifications";
import { notificationMapper } from "./mapper";
import { VERSION } from "../../../redux/store";

export const notificationApiService = {
  getNotifications(
    companyId: string,
    { page = 0, type = "full",},
    status?: number
  ) {
    return notificationApiClient()
      .get<GetNotificationsResponse>(`notifications`, {
        params: { companyId, status, page, type },
      })
      .then(({ data }) => notificationMapper.mapResponseToNotifications(data));
  },
  putReadNotifications(companyId: string) {
    return notificationApiClient()
      .put<PutReadNotificationsResponse>(
        `notifications/read`,
        {},
        {
          params: { companyId },
        }
      )
      .then(({ data }) => data.payload);
  },
  putReadNotification(companyId: string, notificationId: string) {
    return notificationApiClient()
      .put<PutReadNotificationResponse>(
        `notifications/${notificationId}/read`,
        {},
        {
          params: { companyId },
        }
      )
      .then(({ data }) => notificationMapper.mapResponseToId(data));
  },
  getSystemNotifications(companyId: string) {
    return notificationApiClient()
      .get<GetSystemNotificationsResponse>(`system/notifications`, {
        params: { companyId, version: VERSION },
      })
      .then(({ data }) =>
        notificationMapper.mapResponseToSystemNotifications(data)
      );
  },
};
