import _ from "lodash";
import React from "react";
import { Order } from "../../../../cores/market/interface/order";
import { CL$Format } from "../../../../cores/utils";
import { useIntl } from "react-intl";

interface Props {
  order: Order;
}

export const ShippingSection = (props: Props) => {
  const intl = useIntl();
  const {
    order: {
      currency: code,
      shipping: {
        total,
        firstName,
        lastName,
        company,
        address1,
        address2,
        state,
        postcode,
        country,
      },
    },
  } = props;
  return (
    <div className="my-2 text-center">
      <h5 className="h5 text-black-50 mb-1">
        {intl.formatMessage({ id: "app.onlinesales.order11" })}
      </h5>
      <h5 className="h5">
        <small className="text-black-50"> 
         {intl.formatMessage({ id: "app.onlinesales.order15" })}
        </small>
        {CL$Format(+total, code)}
      </h5>
      <h5 className="h5"> 
        <small className="text-black-50">
          {intl.formatMessage({ id: "app.onlinesales.order16" })}
        </small>
        {`${_.capitalize(lastName)} ${_.capitalize(firstName)}`}
      </h5>
      {company && (
        <h5 className="h5">
          <small className="text-black-50">
           {intl.formatMessage({ id: "app.onlineSalesJump.company" })}  
          </small>
          {_.capitalize(company)}
        </h5>
      )} 
      {(country || state) && (
        <h5 className="h5">
          <small className="text-black-50">
           {intl.formatMessage({ id: "app.onlinesales.order7" })} 
          </small>
          {`${_.capitalize(country)} - ${_.capitalize(state)} - (${postcode})`}
        </h5>
      )}
      {(address1 || address2) && (
        <h5 className="h5">
          <small className="text-black-50">
          {intl.formatMessage({ id: "app.onlinesales.order8" })} 
          </small>
          {_.capitalize(address1 || address2)}
        </h5>
      )}
    </div>
  );
};
