import { SocialPostRules } from "../socialPost/interfaces/postError";

export const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const SCOPE =
  // "email,pages_show_list,pages_messaging,pages_messaging_subscriptions,read_insights,leads_retrieval,pages_manage_ads,ads_read";
  "email,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_manage_posts,pages_show_list,pages_messaging,read_insights,leads_retrieval,pages_manage_ads,ads_read,ads_management,business_management";

export const WHATSAPP_SCOPE = "whatsapp_business_management,whatsapp_business_messaging";

export const SOCIAL_POST_RULES: SocialPostRules = {
  maxVideoSize: parseInt(
    process.env.REACT_APP_FACEBOOK_MAX_VIDEO_SIZE ||
      process.env.REACT_APP_DEFAULT_MAX_VIDEO_SIZE ||
      "100"
  ),
  maxImageSize: parseInt(
    process.env.REACT_APP_FACEBOOK_MAX_IMAGE_SIZE ||
      process.env.REACT_APP_DEFAULT_MAX_IMAGE_SIZE ||
      "100"
  ),
  maxCharactersAllowed: 2000,
  maxPostPhotoAllowed: 30,
  maxStoryPhotoAllowed: 1,
  postMediaRequired: false,
  storyMediaRequired: true,
  videoAllowed: true,
};
