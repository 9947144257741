import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { Order } from "../../../../cores/market/interface/order";
import { CL$Format } from "../../../../cores/utils";
import { useIntl } from "react-intl";

interface Props {
  order: Order;
}

export const ItemsSection = (props: Props) => {
  const intl = useIntl();
  const {
    order: { currency: code, items },
  } = props;
  return (
    <div className="my-2 text-center">
      <h5 className="h5 text-black-50 mb-1">
        {intl.formatMessage({ id: "app.onlinesales.order14" })}
      </h5>
      <ListGroup>
        {items.map(({ name, quantity, total }) => (
          <ListGroupItem>{`${name} X ${quantity} = ${CL$Format(
            +total,
            code
          )}`}</ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};
