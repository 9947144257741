import React, { useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-number-input";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { EDIT_ME_USER_LOADING } from "../../../constants/loading.constant";
import { useMeCore } from "../../../cores/me";
import { useIntl } from "react-intl";
import { LoadingSpinnerWembii } from "../LoadingSpinnerWembii";
import { LabelWembii } from "../LabelWembii";

export const PersonalInformationForm = () => {
  const intl = useIntl();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [phoneType, setPhoneType] = useState("");
  const { user, putMeUser, getMeUser } = useMeCore();

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPhoneNumber(user.phoneNumber);
      setCountry(user.country);
      setPhoneType(user.phoneType);
    }
  }, [user]);


  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    await putMeUser(firstName, lastName, country, phoneNumber, phoneType);
    await getMeUser();
  };

  const enabled = useMemo(
    () =>
      (firstName && firstName !== user?.firstName) ||
      (lastName && lastName !== user?.lastName) ||
      (country && country !== user?.country) ||
      (phoneType && phoneType !== user?.phoneType) ||
      (phoneNumber && phoneNumber !== user?.phoneNumber),
    [firstName, user, lastName, phoneNumber, phoneType, country]
  );

  return (
    <Row className="my-4">
      <Col sm="12">
        <Form onSubmit={onFormSubmit}>
          <FormGroup>
            <LabelWembii required forid="login-name">
              {intl.formatMessage({ id: "app.nameTxt" })}
            </LabelWembii>
            <Input
              type="text"
              name="name"
              id="login-name"
              placeholder={intl.formatMessage({ id: "app.nameTxt" })}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <LabelWembii required forid="login-lastName">
              {intl.formatMessage({ id: "app.surnameTxt" })}
            </LabelWembii>
            <Input
              type="text"
              name="lastName"
              id="login-lastName"
              placeholder={intl.formatMessage({ id: "app.surnameTxt" })}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <LabelWembii required forid="login-country">
              {intl.formatMessage({ id: "app.countryTxt" })}
            </LabelWembii>
            <Input
              type="text"
              name="country"
              id="login-country"
              placeholder={intl.formatMessage({ id: "app.countryTxt" })}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <LabelWembii required forid="login-phonetype">
              {intl.formatMessage({ id: "app.phoneTypeTxt" })}
            </LabelWembii>
            <Input
              type="select"
              name="phonetype"
              id="login-phonetype"
              placeholder={intl.formatMessage({ id: "app.phoneTypeTxt" })}
              value={phoneType}
              onChange={(e) => setPhoneType(e.target.value)}
            >
              <option value=''></option>
              <option value='celphone'>
                Celular
              </option>
              <option value='telephone'>
                Teléfono fijo
              </option>
            </Input>
          </FormGroup>
          <FormGroup>
            <LabelWembii required forid="login-Phone">  
              {intl.formatMessage({ id: "app.phoneTxt" })}
            </LabelWembii>
            <PhoneInput
              name="phone"
              id="login-phone"
              placeholder={intl.formatMessage({ id: "app.phoneTxt" })}
              value={phoneNumber}
              onChange={(v) => setPhoneNumber(v || "")}
            />
          </FormGroup>
          <LoadingSpinnerWembii id={EDIT_ME_USER_LOADING}>
            <Button color="primary" block disabled={!enabled}>
              {intl.formatMessage({ id: "app.navbar.save" })}
            </Button>
          </LoadingSpinnerWembii>
        </Form>
      </Col>
    </Row>       
  );
};
