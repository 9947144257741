import { ReactNode } from "react";

export enum Position {
  LEFT,
  RIGHT,
}

export interface LoadingEntity {
  id: string;
  value: boolean;
}

export interface ModalEntity {
  id: string;
  position: Position;
  value: ReactNode;
}
export interface SidebarGroupEntity {
  id: string;
}
