import { Filters } from "../../boards/interfaces/PanelFilters";
import panelApiClient from "../../helpers/services/panel-api-client";
import { GetBoardCardsResponse } from "../interface/api/get-board-cards";
import { GetInsightResponse } from "../interface/api/get-insights";
import { boardAnalyticsMapper } from "./mapper";

export const boardAnalyticsApiService = {
  crmInsights(companyId: string, boardId: string, filters: Filters) {
    const contacts = filters.contacts?.map((contact) => contact.id);

    return panelApiClient()
      .get<GetInsightResponse>(`board/insights/business/${boardId}`, {
        params: { companyId, ...filters, contacts },
      })
      .then(({ data }) => boardAnalyticsMapper.mapResponseToMetrics(data));
  },
  getBoardCards(
    companyId: string,
    boardId: string,
    filters: Filters,
    offset: number,
  ) {
    return panelApiClient()
      .get<GetBoardCardsResponse>(`boards/${boardId}/cards`, {
        params: { companyId, ...filters, offset },
      })
      .then(({ data }) => boardAnalyticsMapper.mapResponseToCards(data));
  },
};
