import React, { useCallback } from "react";
import { CHART_THEME } from "../../../constants/theme";
import { Metric2 } from "../../../cores/analytics/interface/Metric2";
import { CameFrom } from "./enum/CameFrom";
import { Monitor } from "./Monitor";

interface Props {
  metrics: Metric2[];
  cameFrom?: CameFrom;
}

export const Monitors = (props: Props) => {
  const { metrics, cameFrom = CameFrom.analytics } = props;
  const getColor = useCallback(
    (index: number) => CHART_THEME[index % CHART_THEME.length],
    []
  );
  return (
    <>
      {metrics
        .filter(({ title }) => title)
        .map((metric, index) => (
          <Monitor
            key={metric.title}
            metric={metric}
            cameFrom={cameFrom}
            backgroundColor={metric.color || getColor(index)}
          />
        ))}
    </>
  );
};
