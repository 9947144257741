import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { COLOR_DANGER, COLOR_PRIMARY } from "../../../../constants/theme";
import { useConfigCore } from "../../../../cores/config";
import { useMeCore } from "../../../../cores/me";
import { FreeTrialStatus, getAllFreeTrialStatus } from "../../../../cores/user/constants/free-trial-status";
import { FreeTrialBadge } from "../../companies/FreeTrialBadge";
import { InputWembii } from "../../InputWembii";

interface Props {
  isFreeTrial: boolean;
  freeTrialDays: number;
  freeTrialDate: string;
  onAssignFreeTrial: (arg0: boolean, arg1: number) => void;
}

export const FreeTrialSelector = (props: Props) => {
  const { isFreeTrial, freeTrialDays, freeTrialDate, onAssignFreeTrial } = props;
  const { freeTrialDays: freeTrialDaysConfig } = useConfigCore();
  const { isAdmin, isReseller } = useMeCore();
  const [freeTrialStatus, setFreeTrialStatus] = useState(FreeTrialStatus.NO_FREE_TRIAL);
  const [editFreeTrial, setEditFreeTrial] = useState(false);
  const [days, setDays] = useState(freeTrialDays);

  useEffect(() => {
    if (isFreeTrial) {
      setFreeTrialStatus(FreeTrialStatus.FREE_TRIAL);
    }
    setDays(freeTrialDays);
  }, [isFreeTrial, freeTrialDays]);

  const setEdit = useCallback(() => {
    if (isReseller || isAdmin) {
      if (freeTrialDays === 0) {
        setDays(parseInt(freeTrialDaysConfig.value));
      }
      setEditFreeTrial(true);
    }
  }, [isAdmin, isReseller, freeTrialDays, freeTrialDaysConfig]);

  const cancelEdit = useCallback(() => {
    setEditFreeTrial(false);
    if (isFreeTrial) {
      setFreeTrialStatus(FreeTrialStatus.FREE_TRIAL);
    } else {
      setFreeTrialStatus(FreeTrialStatus.NO_FREE_TRIAL);
    }
  }, [isFreeTrial]);

  const handleAssignFreeTrial = useCallback(() => {
    setEditFreeTrial(false);
    onAssignFreeTrial(freeTrialStatus === FreeTrialStatus.FREE_TRIAL ? true : false, days);
  }, [onAssignFreeTrial, freeTrialStatus, days]);

  const daysLeft = useMemo(() => {
    const date = isFreeTrial ? freeTrialDate : moment();
    return days - moment().diff(moment(date), "days");
  }, [isFreeTrial, freeTrialDate, days]);

  return (
    <div>
      <span className="w-fit mx-1 d-flex">
        {editFreeTrial ? (
          <div className="d-flex align-items-center">
            <InputWembii
              mb={false}
              type="select"
              className="mx-1"
              value={freeTrialStatus}
              onChange={(v) => setFreeTrialStatus(v)}
            >
              {getAllFreeTrialStatus().map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </InputWembii>

            {freeTrialStatus === FreeTrialStatus.FREE_TRIAL && (
              <InputWembii
                type="number"
                mb={false}
                className="mx-1"
                min="1"
                style={{ width: 100 }}
                value={days}
                onChange={(v) => setDays(v)}
              />
            )}
            
            <FontAwesomeIcon
              icon={["far", "save"]}
              size="2x"
              color={COLOR_PRIMARY}
              className="mx-1 pointer"
              onClick={handleAssignFreeTrial}
            />
            <FontAwesomeIcon
              icon={["fas", "times"]}
              size="2x"
              color={COLOR_DANGER}
              className="mx-1 pointer"
              onClick={cancelEdit}
            />
          </div>
        ) : (
          <FreeTrialBadge
            freeTrial={freeTrialStatus === FreeTrialStatus.FREE_TRIAL}
            onClick={setEdit}
          />
        )}
      </span>
      {freeTrialStatus === FreeTrialStatus.FREE_TRIAL && (
        <div className="text-center mt-1">
          {daysLeft} días restantes
        </div>
      )}
    </div>
  );
};
