import _ from "lodash";
import { GetBoardCardsPayload, GetBoardCardsResponse } from "../interface/api/get-board-cards";
import {
  GetInsightResponse,
  Metric,
  Value,
} from "../interface/api/get-insights";
import { ChartMetric } from "../interface/ChartMetric";
import { Metric2 } from "../interface/Metric2";


export const boardAnalyticsMapper = {
  mapResponseToMetrics(response: GetInsightResponse): Metric[] {
    return response.payload.metrics;
  },

  mapToChartMetric(metrics: Metric2[]): ChartMetric {
    return metrics
      .filter((metric) => metric !== undefined)
      .reduce(
        (
          prev: ChartMetric,
          { values = [], title = "", type, color, axis }: Metric2
        ): ChartMetric => ({
          labels: _.uniq([
            ...prev.labels,
            ...this.mapMetricValueToLabels(values),
          ]),
          datasets: [
            ...prev.datasets,
            {
              label: title,
              data: this.mapMetricValueToData(values),
              type,
              color,
              yAxisID: axis || "",
            },
          ],
        }),
        { labels: [], datasets: [] }
      );
  },

  mapMetricValueToLabels(values: Value[]): string[] {
    return values.map(({ label }) => `${label}`);
  },

  mapMetricValueToData(values: Value[]): number[] {
    return values.map(({ value }) => value);
  },

  mapResponseToCards(response: GetBoardCardsResponse): GetBoardCardsPayload {
    const cards = response?.payload?.cards
    return { cards };
  },
};
