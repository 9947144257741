import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";

export const setShowTemplatesAction = createAction(
  "[OpenAi] Set show templates",
  props<{ showTemplates: boolean }>()
);


const actions = joinActions({
  setShowTemplatesAction,
});

export type OpenAiAction = typeof actions;
