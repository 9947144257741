import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CHAT_MESSAGES_LOADING,
  CHAT_MESSAGES_SENDING,
  INSTAGRAM_CHAT_LOADING,
  WHATSAPP_CHAT_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { socialChatApiService } from "./services/api";
import {
  setConversationSocialAction,
  setDateFilters,
  setSelectedFacebookConversationAction,
  setSelectedInstagramConversationAction,
  setSelectedWhatsappConversationAction,
  syncFacebookConversationWithContactAction as syncMetaConversationWithContactAction,
} from "./store/actions";
import {
  getFacebookConversationsFailureAction,
  getFacebookConversationsInitAction,
  selectConversationStatusToShow,
  getFacebookConversationsSuccessAction,
  getFacebookMessagesFailureAction,
  getFacebookMessagesInitAction,
  getFacebookMessagesSuccessAction,
  getInstagramConversationsFailureAction,
  getInstagramConversationsInitAction,
  getInstagramConversationsSuccessAction,
  getInstagramMessagesFailureAction,
  getInstagramMessagesInitAction,
  getInstagramMessagesSuccessAction,
  getWhatsappConversationsFailureAction,
  getWhatsappConversationsInitAction,
  getWhatsappConversationsSuccessAction,
  getWhatsappMessagesFailureAction,
  getWhatsappMessagesInitAction,
  getWhatsappMessagesSuccessAction,
  postFacebookMessageFailureAction,
  postFacebookMessageInitAction,
  postFacebookMessageSuccessAction,
  postFacebookSeenMessageFailureAction,
  postFacebookSeenMessageInitAction,
  postFacebookSeenMessageSuccessAction,
  postInstagramMessageFailureAction,
  postInstagramMessageInitAction,
  postInstagramMessageSuccessAction,
  postWhatsappMessageFailureAction,
  postWhatsappMessageInitAction,
  postWhatsappMessageSuccessAction,
} from "./store/api-actions";
import {
  dateFilterSelector,
  facebookConversationsSelector,
  facebookMessagesSelector,
  facebookSelectedSelector,
  instagramConversationsSelector,
  instagramMessagesSelector,
  instagramSelectedSelector,
  socialChatRoot,
  whatsappConversationsSelector,
  whatsappMessagesSelector,
  whatsappSelectedSelector,
} from "./store/selector";
import { Attachment } from "../conversations/interfaces/attachment/attachment.interface";
import { ContentMessageType } from "./types/whatsapp-message.types";
import { ConversationStatus } from "./types/conversation-status.types";
import { ContactStateRequest } from "../contacts/interfaces/contact-state-request";
import { SocialType } from "./types/social.types";
import { ConversationContact } from "./interfaces/conversations.interface";
import { FACEBOOK_CHAT_LOADING } from "../../constants/loading.constant";

export function useSocialChatCore() {
  const dispatch = useDispatch();
  const { selectedSocial } = useSelector(socialChatRoot);
  const instagramConversations = useSelector(instagramConversationsSelector);
  const { conversationStatus } = useSelector(socialChatRoot);
  const dateFilter = useSelector(dateFilterSelector);
  const instagramSelected = useSelector(instagramSelectedSelector);
  const instagramMessages = useSelector(instagramMessagesSelector);
  const facebookConversations = useSelector(facebookConversationsSelector);
  const facebookSelected = useSelector(facebookSelectedSelector);
  const facebookMessages = useSelector(facebookMessagesSelector);
  const whatsappConversations = useSelector(whatsappConversationsSelector);
  const whatsappSelected = useSelector(whatsappSelectedSelector);
  const whatsappMessages = useSelector(whatsappMessagesSelector);

  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const getInstagramConversations = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getInstagramConversationsInitAction());
        turnOnLoading(INSTAGRAM_CHAT_LOADING);
        const response = await socialChatApiService.getInstagramConversations(
          companyId
        );
        dispatch(getInstagramConversationsSuccessAction(response));
        turnOffLoading(INSTAGRAM_CHAT_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(INSTAGRAM_CHAT_LOADING);
        dispatch(getInstagramConversationsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getInstagramMessages = useCallback(
    async (companyId: string, conversationId: string) => {
      try {
        dispatch(getInstagramMessagesInitAction());
        turnOnLoading(CHAT_MESSAGES_LOADING);
        const response = await socialChatApiService.getInstagramMessages(
          companyId,
          conversationId
        );
        dispatch(getInstagramMessagesSuccessAction(response));
        turnOffLoading(CHAT_MESSAGES_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(CHAT_MESSAGES_LOADING);
        dispatch(getInstagramMessagesFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const postInstagramMessage = useCallback(
    async (
      companyId: string,
      conversationId: string,
      recipientId: string,
      message: string
    ) => {
      try {
        dispatch(postInstagramMessageInitAction());
        const response = await socialChatApiService.postInstagramMessage(
          companyId,
          conversationId,
          recipientId,
          message as string
        );
        dispatch(postInstagramMessageSuccessAction(response));
        turnOffLoading(CHAT_MESSAGES_SENDING, 500);
      } catch (error: any) {
        turnOffLoading(CHAT_MESSAGES_SENDING);

        console.error(error);
        dispatch(postInstagramMessageFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading]
  );
  const getFacebookConversations = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getFacebookConversationsInitAction());
        turnOnLoading(FACEBOOK_CHAT_LOADING);
        const response = await socialChatApiService.getFacebookConversations(
          companyId
        );
        dispatch(getFacebookConversationsSuccessAction(response));
        turnOffLoading(FACEBOOK_CHAT_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(FACEBOOK_CHAT_LOADING);
        dispatch(getFacebookConversationsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const getFacebookMessages = useCallback(
    async (companyId: string, conversationId: string) => {
      try {
        dispatch(getFacebookMessagesInitAction());
        turnOnLoading(CHAT_MESSAGES_LOADING);
        const response = await socialChatApiService.getFacebookMessages(
          companyId,
          conversationId
        );
        dispatch(getFacebookMessagesSuccessAction(response));
        turnOffLoading(CHAT_MESSAGES_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(CHAT_MESSAGES_LOADING);
        dispatch(getFacebookMessagesFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const postFacebookMessage = useCallback(
    async (
      companyId: string,
      conversationId: string,
      recipientId: string,
      content?: string
    ) => {
      try {
        dispatch(postFacebookMessageInitAction());
        const response = await socialChatApiService.postFacebookMessage(
          companyId,
          conversationId,
          recipientId,
          content as string
        );

        dispatch(
          postFacebookMessageSuccessAction({
            ...response,
          })
        );
        turnOffLoading(CHAT_MESSAGES_SENDING);
      } catch (error: any) {
        turnOffLoading(CHAT_MESSAGES_SENDING);
        console.error(error);
        dispatch(postFacebookMessageFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading]
  );
  const postFacebookSeenMessage = useCallback(
    async (companyId: string, conversationId: string, recipientId: string) => {
      try {
        dispatch(postFacebookSeenMessageInitAction());
        const response = await socialChatApiService.postFacebookSeenMessage(
          companyId,
          conversationId,
          recipientId
        );
        dispatch(postFacebookSeenMessageSuccessAction(response));
      } catch (error: any) {
        console.error(error);
        dispatch(postFacebookSeenMessageFailureAction({ error }));
      }
    },
    [dispatch]
  );
  const getWhatsappConversations = useCallback(
    async (companyId: string, contactStateRequest: ContactStateRequest) => {
      try {
        dispatch(getWhatsappConversationsInitAction());
        turnOnLoading(WHATSAPP_CHAT_LOADING);
        const response = await socialChatApiService.getWhatsappConversations(
          companyId,
          contactStateRequest
        );
        dispatch(getWhatsappConversationsSuccessAction(response));
        turnOffLoading(WHATSAPP_CHAT_LOADING, 500);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(WHATSAPP_CHAT_LOADING);
        dispatch(getWhatsappConversationsFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );

  const getWhatsappMessages = useCallback(
    async (companyId: string, conversationId: string) => {
      try {
        dispatch(getWhatsappMessagesInitAction());
        turnOnLoading(CHAT_MESSAGES_LOADING);
        const response = await socialChatApiService.getWhatsappMessages(
          companyId,
          conversationId
        );
        dispatch(getWhatsappMessagesSuccessAction(response));
        turnOffLoading(CHAT_MESSAGES_LOADING);
      } catch (error: any) {
        console.error(error);
        turnOffLoading(CHAT_MESSAGES_LOADING);
        dispatch(getWhatsappMessagesFailureAction({ error }));
      }
    },
    [dispatch, turnOnLoading, turnOffLoading]
  );
  const postWhatsappMessage = useCallback(
    async (
      companyId: string,
      conversationId: string,
      recipientId: string,
      message: string,
      type: ContentMessageType,
      attachments: Attachment[] = []
    ) => {
      try {
        dispatch(postWhatsappMessageInitAction());
        const response = await socialChatApiService.postWhatsappMessage({
          type,
          companyId,
          conversationId,
          recipientId,
          message,
          attachments,
        });
        dispatch(
          postWhatsappMessageSuccessAction({
            ...response,
          })
        );
        turnOffLoading(CHAT_MESSAGES_SENDING, 500);
      } catch (error: any) {
        turnOffLoading(CHAT_MESSAGES_SENDING, 500);
        console.error(error);
        dispatch(postWhatsappMessageFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading]
  );

  return {
    instagramConversations,
    instagramSelected,
    instagramMessages,
    facebookConversations,
    facebookSelected,
    facebookMessages,
    whatsappConversations,
    whatsappSelected,
    whatsappMessages,
    getInstagramConversations,
    getInstagramMessages,
    postInstagramMessage,
    selectedSocial,
    setSelectedSocial: (social: SocialType) =>
      dispatch(setConversationSocialAction({ social })),
    selectInstagramConversation: (id: string | null) =>
      dispatch(
        setSelectedInstagramConversationAction({
          id,
        })
      ),
    getFacebookConversations,
    getFacebookMessages,
    postFacebookMessage,
    postFacebookSeenMessage,
    selectFacebookConversation: (id: string | null) =>
      dispatch(
        setSelectedFacebookConversationAction({
          id,
        })
      ),
    getWhatsappConversations,

    getWhatsappMessages,
    postWhatsappMessage,
    conversationStatus,
    updateConversationContact: (
      contact: ConversationContact,
      social: "facebook" | "instagram"
    ) => {
      turnOffLoading(INSTAGRAM_CHAT_LOADING);
      turnOffLoading(FACEBOOK_CHAT_LOADING);

      dispatch(
        syncMetaConversationWithContactAction({
          contact,
          social,
        })
      );
    },
    setConversationStatus: (status: ConversationStatus) => {
      dispatch(selectConversationStatusToShow({ conversationStatus: status }));
    },
    selectWhatsappConversation: (id: string | null) =>
      dispatch(
        setSelectedWhatsappConversationAction({
          id,
        })
      ),
    setDateFilter: (date: string, dateToUpdate: "from" | "to") =>
      dispatch(setDateFilters({ date, dateToUpdate })),
    dateFilter,
  };
}
