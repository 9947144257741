import boardApiClient from "../../helpers/services/panel-api-client";
import { GetProductResponse } from "../interfaces/api/get-product";
import { GetProductsParams, GetProductsResponse } from "../interfaces/api/get-products";
import { PostProductRequest, PostProductResponse } from "../interfaces/api/post-product";
import { PutProductRequest, PutProductResponse } from "../interfaces/api/put-product";

import { productsMapper } from "./mapper";

export const productApiService = {
  postProduct(
    companyId: string,
    body: PostProductRequest
  ) {
    return boardApiClient()
      .post<PostProductResponse>(`products`, body, { params: { companyId } })
      .then(({ data }) => productsMapper.mapResponseToProduct(data));
  },
  putProduct(
    companyId: string,
    productId: string,
    body: PutProductRequest
  ) {
    return boardApiClient()
      .put<PutProductResponse>(`products/${productId}`, body, { params: { companyId } })
      .then(({ data }) => productsMapper.mapResponseToProduct(data));
  },
  getProducts(companyId: string, params: GetProductsParams) {
    return boardApiClient()
      .get<GetProductsResponse>(`products`, {
        params: { companyId, ...params },
      })
      .then(({ data }) => productsMapper.mapResponseToProducts(data));
  },
  getProduct(companyId: string, productId: string) {
    return boardApiClient()
      .get<GetProductResponse>(`products/${productId}`, {
        params: { companyId },
      })
      .then(({ data }) => productsMapper.mapResponseToProduct(data));
  },
  deleteProduct(productId: string, companyId: string) {
    return boardApiClient()
      .delete(`products/${productId}`, { params: { companyId } })
      .then(({ data }) => data);
  },
};
