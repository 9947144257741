import React from "react";
import { FormFeedback } from "reactstrap";

interface Props {
  message: string;
  show: boolean;
}

export const FromFeedback2 = (props: Props) => {
  const { message, show } = props;
  return show ? <FormFeedback>{message}</FormFeedback> : null;
};
