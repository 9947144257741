import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const boardAnalyticsRoot = (state: State) => state.boardAnalytics;

export const crmInsightEntitySelector = createSelector(
  boardAnalyticsRoot,
  ({ crmInsights }) => crmInsights.entities
);

export const cardsSelector = createSelector(
  boardAnalyticsRoot,
  ({ cards }) => cards.ids.map((id) => cards.entities[id])
);

export const dateSelector = createSelector(boardAnalyticsRoot, ({ date }) => date);

export const offsetSelector = createSelector(boardAnalyticsRoot, ({ offset }) => offset);

export const hasMoreSelector = createSelector(boardAnalyticsRoot, ({ hasMore }) => hasMore);
