import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Col, Container, Input, Row } from "reactstrap";
import { useInstagramCore } from "../../../../cores/instagram";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { PostError } from "../../../../cores/socialPost/interfaces/postError";
import { ErrorList } from "./ErrorList";
import { MediaPreview } from "./MediaPreview";

export const InstagramStoryPreview = () => {
  const intl = useIntl();
  const { settings } = useInstagramCore();
  const { storyErrors, images, video, selectedPost } = useSocialPostCore();
  const { name, avatarUrl } = settings || {};

  const providerErrors: PostError[] = useMemo(
    () =>
      storyErrors.filter(
        ({ socialProviderId }) =>
          socialProviderId === SocialProviderId.Instagram
      ),
    [storyErrors]
  );

  return providerErrors.length > 0 ? (
    <ErrorList errors={providerErrors} />
  ) : (
    <div className="instagramPreview-container neu-out p-2 bg-dark">
      <Container fluid>
        <div
          className="rounded mx-4"
          style={{ height: 3, backgroundColor: "grey" }}
        ></div>
        <Row className="instagramPreview-header px-4">
          <Col xs="8" className="d-flex align-items-center p-0">
            <div>
              <img
                className="instagramPreview-header-avatar"
                src={avatarUrl}
                alt="avatar"
              />
            </div>
            <div>
              <div className="font-weight-bold text-light">
                {name}
              </div>
            </div>
          </Col>
          <Col
            xs="4"
            className="d-flex align-items-center justify-content-end p-0"
          >
            <FontAwesomeIcon icon={["fas", "ellipsis-h"]} color="gray" />
            <FontAwesomeIcon
              icon={["fas", "times"]}
              color="gray"
              className="ml-2"
            />
          </Col>
        </Row>
        <Row
          className="d-flex justify-content-center align-items-center px-2"
          style={{ minHeight: '300px' }}
        >
          {selectedPost || images.length > 0 || video ? (
            <MediaPreview className="instagramPreview-image" />
          ) : (
            <FontAwesomeIcon
              size="6x"
              className="my-4"
              icon={["fas", "camera"]}
            />
          )}
        </Row>
        <Row className="px-2 d-flex justify-content-between">
          <Input
            className="bg-transparent rounded-pill"
            placeholder={intl.formatMessage({ id: "app.socialStory.sendMessage" })}
          />
        </Row>
      </Container>
    </div>
  );
};
