import cn from "classnames";
import React, { useMemo } from "react";
import { useGlobalCore } from "../../cores/globals";
import { LoadingSpinnerWembii } from "./LoadingSpinnerWembii";

interface Props {
  text?: string;
  loadingId: string | string[];
  className?: string;
}

export const UpdateLoadingCard = (props: Props) => {
  const { text, loadingId, className } = props;
  const { isLoading, areLoading } = useGlobalCore();
  const shouldDisplay = useMemo(
    (): boolean =>
      Array.isArray(loadingId) ? areLoading(loadingId) : isLoading(loadingId),
    [areLoading, isLoading, loadingId]
  );
  return shouldDisplay ? (
    <div
      className={cn([
        "d-flex, align-items-center justify-content-center text-center mb-4",
        className,
      ])}
    >
      <h5 className="h5">{text || "Actualizando ..."}</h5>
      <LoadingSpinnerWembii active />
    </div>
  ) : null;
};
