export const ContactInputType = {
  CONTACT_NAME: { name: "app.editForm.input.name", id: "name" },
  CONTACT_EMAIL: { name: "app.editForm.input.email", id: "email" },
  CONTACT_PHONE: { name: "app.editForm.input.phone", id: "phone" }
}

export const CompanyInputType = {
  COMPANY_NAME: { name: "app.editForm.input.enterprise", id: "enterprise" }
}

export const InputType = {
  NUMBER: { name: "app.editForm.input.number", id: "number" },
  TEXT: { name: "app.editForm.input.text", id: "text" },
  LONG_TEXT: { name: "app.editForm.input.longText", id: "textarea" }
}
