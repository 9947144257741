import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { Col, Label, Row } from "reactstrap";
import { TASK_CARD_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useIntl } from "react-intl";
import TaskDetails from "../../tasks/modal/TaskDetails";
import { Task } from "../../../../cores/tasks/interfaces/api/get-task";
import { PriorityOptions, TaskStatus, TaskType } from "../../../../cores/tasks/config";
import { PanelWembii } from "../../PanelWembii";
import { COLOR_SUCCESS } from "../../../../constants/theme";
import { useBoardCore } from "../../../../cores/boards";
import { useMeCore } from "../../../../cores/me";

interface Props {
  task: Task;
  board: string;
}

export const TaskRow = (props: Props) => {
  const intl = useIntl();
  const {
    task: { id, title, status, user, type, endDate, reminderDate, priority },
    board,
  } = props;
  const { turnOnModal } = useGlobalCore();
  const { company } = useMeCore();
  const { card, getCard } = useBoardCore();

  const onClick = useCallback(() => {
    turnOnModal(
      TASK_CARD_MODAL,
      <TaskDetails
        task={id}
        onSuccess={() => {
          getCard(company?.id!, board, card?.list!, card?.id!);
        }}
      />
    );
  }, [turnOnModal, getCard, id, company, board, card]);

  const typeLabel = useMemo(() => {
    switch (type) {
      case TaskType.Other:
        return (
          <Label className="ml-2 mb-0">Otro</Label>
        );
      case TaskType.Call:
        return (
          <FontAwesomeIcon icon={["fas", "phone"]} size="2x" className="ml-2" />
        );
      case TaskType.Email:
        return (
          <FontAwesomeIcon icon={["far", "envelope"]} size="2x" className="ml-2" />
        );
    } 
  }, [type]);

  return (
    <PanelWembii className="w-100 px-4 py-3 my-2 pointer bg-light" onClick={onClick}>
      <Row>
        <Col sm="1">
          <div className="d-flex h-100 align-items-center">
            {status === TaskStatus.Incompleted ? (
              <FontAwesomeIcon icon={["far", "check-circle"]} className="mr-2" size="2x" />
            ) : (
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                className="mr-2"
                size="2x"
                color={COLOR_SUCCESS}
              />
            )}
          </div>
        </Col>
        <Col sm="5">
          <div className="d-flex h-100 flex-column justify-content-center">
            <span className="h5 mb-0 font-weight-bold truncate">{title}</span>
            <span>{`${user.firstName} ${user.lastName}`}</span>
          </div>
        </Col>
        <Col sm="2">
          <div className="d-flex h-100 align-items-center justify-content-center">
            {typeLabel}
          </div>
        </Col>
        <Col sm="4">
          <div className="d-flex flex-column align-items-end justify-content-center h-100">
            <div className="d-flex">
              <FontAwesomeIcon icon={["far", "clock"]} className="mr-2" />
              <span
                className={cn("mb-0 small", {
                  "text-danger": moment(endDate).isBefore(moment()) && status === TaskStatus.Incompleted,
                })}
              >
                {moment(endDate).format("DD/MM/YYYY HH:mm")}
              </span>
            </div>
            {reminderDate && (
              <div className="d-flex">
                <FontAwesomeIcon icon={["far", "bell"]} className="mr-2" />
                <span className="mb-0 small">
                  {moment(reminderDate).format("DD/MM/YYYY HH:mm")}
                </span>
              </div>
            )}
            <div className="d-flex">
              <span className="text-muted">
                {intl.formatMessage({ id: "app.crmTasksModal.prority1" })}
                </span>
              <span
                className="font-weight-bold ml-2"
                style={{ color: PriorityOptions[`${priority}`].color }}
              >
                {PriorityOptions[`${priority}`].label}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
