import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import {
  FormInput,
  FormNotification,
  FormUser,
  WebForm,
} from "../interfaces/api/get-web-form";
import {
  setFormAction,
  addFormInputAction,
  removeFormInputAction,
  WebFormAction,
  updateFormInputAction,
} from "./actions";
import {
  deleteFormNotificationFailureAction,
  deleteFormNotificationInitAction,
  deleteFormNotificationSuccessAction,
  deleteFormUserFailureAction,
  deleteFormUserInitAction,
  deleteFormUserSuccessAction,
  deleteWebFormFailureAction,
  deleteWebFormInitAction,
  deleteWebFormSuccessAction,
  getWebFormFailureAction,
  getWebFormInitAction,
  getWebFormResponseSuccessAction,
  getWebFormsFailureAction,
  getWebFormsInitAction,
  getWebFormsSuccessAction,
  getWebFormSuccessAction,
  postFormNotificationFailureAction,
  postFormNotificationInitAction,
  postFormNotificationSuccessAction,
  postFormUserFailureAction,
  postFormUserInitAction,
  postFormUserSuccessAction,
  postWebFormFailureAction,
  postWebFormInitAction,
  postWebFormSuccessAction,
  putFormInputsFailureAction,
  putFormInputsInitAction,
  putFormInputsSuccessAction,
  putWebFormFailureAction,
  putWebFormInitAction,
  putWebFormSuccessAction,
  WebFormApiAction,
} from "./api-actions";
import { FormResponsePayload } from "../interfaces/api/get-webform-response.response";

export interface WebFormState {
  webForms: {
    ids: string[];
    entities: Record<string, WebForm>;
  };
  webFormResponses: FormResponsePayload[];
  webForm: {
    form: WebForm | null;
    inputs: FormInput[];
    notifications: FormNotification[];
    users: FormUser[];
  };
}

const initialState: WebFormState = {
  webForms: {
    ids: [],
    entities: {},
  },
  webForm: {
    form: null,
    inputs: [],
    notifications: [],
    users: [],
  },
  webFormResponses: [],
};

const webFormsAdapter = createEntityAdapter<WebForm>((payload) => payload.id);

export default produce(
  (
    draft: WebFormState = initialState,
    action: WebFormApiAction | WebFormAction | GlobalActions
  ) => {
    switch (action.type) {
      case getWebFormResponseSuccessAction.type:
        draft.webFormResponses = action.payload;
        return draft;
      case setFormAction.type:
        draft.webForm.form = action.form;
        return draft;
      case addFormInputAction.type:
        draft.webForm.inputs = [...draft.webForm.inputs, action.input];
        return draft;
      case removeFormInputAction.type:
        let inputs3 = [...draft.webForm.inputs];
        const indexInput3 = inputs3.findIndex((item) => item.id === action.id);
        inputs3.splice(indexInput3, 1);
        draft.webForm.inputs = inputs3;
        return draft;
      case updateFormInputAction.type:
        let inputs4 = [...draft.webForm.inputs];
        const indexInput4 = inputs4.findIndex(
          (item) => item.id === action.input.id
        );
        inputs4[indexInput4] = action.input;
        draft.webForm.inputs = inputs4;
        return draft;
      case getWebFormsSuccessAction.type:
        webFormsAdapter.addMany(action.forms, draft.webForms);
        return draft;
      case getWebFormSuccessAction.type:
        draft.webForm = {
          form: action.form,
          inputs: action.inputs,
          notifications: action.notifications || [],
          users: action.users || [],
        };
        return draft;
      case postWebFormSuccessAction.type:
        webFormsAdapter.setOne(action.form, draft.webForms);
        return draft;
      case putWebFormSuccessAction.type:
        draft.webForm.form = action.form;
        return draft;
      case putFormInputsSuccessAction.type:
        draft.webForm = {
          ...draft.webForm,
          inputs: action.inputs,
        };
        return draft;
      case postFormNotificationSuccessAction.type:
        draft.webForm.notifications = [
          ...draft.webForm.notifications,
          action.notification,
        ];
        return draft;
      case deleteFormNotificationSuccessAction.type:
        let notifications = [...draft.webForm.notifications];
        const indexNotification = notifications.findIndex(
          (item) => item.id === action.id
        );
        notifications.splice(indexNotification, 1);
        draft.webForm.notifications = notifications;
        return draft;
      case postFormUserSuccessAction.type:
        draft.webForm.users = [...draft.webForm.users, action.user];
        return draft;
      case deleteFormUserSuccessAction.type:
        let users = [...draft.webForm.users];
        const indexUser = users.findIndex((item) => item.id === action.id);
        users.splice(indexUser, 1);
        draft.webForm.users = users;
        return draft;
      case resetAction.type:
        return initialState;
      case getWebFormsInitAction.type:
      case getWebFormsFailureAction.type:
      case getWebFormInitAction.type:
      case getWebFormFailureAction.type:
      case postWebFormInitAction.type:
      case postWebFormFailureAction.type:
      case putWebFormInitAction.type:
      case putWebFormFailureAction.type:
      case deleteWebFormInitAction.type:
      case deleteWebFormFailureAction.type:
      case deleteWebFormSuccessAction.type:
      case putFormInputsInitAction.type:
      case putFormInputsFailureAction.type:
      case postFormNotificationInitAction.type:
      case postFormNotificationFailureAction.type:
      case deleteFormNotificationInitAction.type:
      case deleteFormNotificationFailureAction.type:
      case postFormUserInitAction.type:
      case postFormUserFailureAction.type:
      case deleteFormUserInitAction.type:
      case deleteFormUserFailureAction.type:
      default:
        return draft;
    }
  }
);
