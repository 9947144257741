import React, { useCallback, useMemo, useState } from "react";
import ImageUploader from "react-images-upload";
import Switch from "react-switch";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { COLOR_PRIMARY } from "../../../../../constants/theme";
import { useWebChatCore } from "../../../../../cores/conversations";
import { useIntl } from "react-intl";
import { InputWembii } from "../../../InputWembii";
import { PanelWembii } from "../../../PanelWembii";
import { TitleWembii } from "../../../TitleWembii";
import { v4 } from "uuid";
import { Avatar } from "../../../../../cores/conversations/interfaces/avatar";
import { beforeUploadFile, UploadFileType } from "../../../../../cores/utils";
import { CHAT_CONFIG_RULES } from "../../../../../cores/conversations/config";
import Icofont from "react-icofont";

interface Props {
  onSubmit: (e: any) => void;
}

export default function GeneralForm({ onSubmit }: Props) {
  const intl = useIntl();
  const { chat, setChatConfig, setAvatar } = useWebChatCore();
  const [hasChange, setHasChange] = useState(false);

  const disabled = useCallback(
    () =>
      !chat?.config?.title ||
      !chat?.config?.subtitle ||
      !chat?.config?.titleColor ||
      !chat?.config?.backgroundColor ||
      !chat?.config?.startButtonColor ||
      !chat?.config?.openButtonColor ||
      !chat?.config?.openButtonIconColor ||
      !chat?.config?.welcomeMessage!,
    [chat]
  );

  const onFormSubmit = useCallback(
    (e: any) => {
      onSubmit(e);
      setHasChange(false);
    },
    [onSubmit]
  );

  const filesAllowed = useMemo(
    () => [UploadFileType.JPEG, UploadFileType.JPG, UploadFileType.PNG],
    []
  );

  const handleMainConfigChange = useCallback(
    (field) => {
      setChatConfig({ ...chat.config, ...field });
      setHasChange(true);
    },
    [chat, setChatConfig]
  );

  const handleSelectAvatar = (files: File[], pictures: string[]) => {
    let error = false;
    const image: Avatar[] = files.map((file, index): Avatar => {
      const { name, size, lastModified, type } = file;
      error = !beforeUploadFile(
        file,
        CHAT_CONFIG_RULES.maxImageSize,
        filesAllowed
      );
      return {
        id: v4(),
        lastModified,
        name,
        size,
        type,
        data: pictures[index],
      };
    });

    if (!error) {
      setHasChange(true);
      setAvatar(image[0]);
    }
  };

  const handleRemoveAvatar = () => {
    handleMainConfigChange({ avatar: null });
    setAvatar(null);
  };

  return (
    <Container className="p-0">
      <PanelWembii shadow className="mb-4">
        <Form className="w-100 p-4" onSubmit={onFormSubmit}>
          <TitleWembii
            Tag="h5"
            color="primary"
            toolbar={
              <FormGroup className="d-flex align-items-center">
                <span className="text-secondary font-weight-bold">
                  {chat?.config?.isActive === true
                    ? intl.formatMessage({ id: "app.configWspp.active" })
                    : intl.formatMessage({ id: "app.configWspp.disabled" })}
                </span>
                <Switch
                  id="isActive"
                  name="isActive"
                  className="ml-1"
                  checked={chat?.config?.isActive!}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor={COLOR_PRIMARY}
                  height={20}
                  width={30}
                  onChange={(v) => handleMainConfigChange({ isActive: v })}
                />
              </FormGroup>
            }
          >
            {intl.formatMessage({ id: "app.chatConfig.subTittle" })}
          </TitleWembii>
          <Row>
            <Col>
              <InputWembii
                type="text"
                name="title"
                id="title"
                label={intl.formatMessage({ id: "app.chatConfig.tittleTxt" })}
                value={chat?.config?.title}
                flat
                required
                onChange={(v) => handleMainConfigChange({ title: v })}
              />
            </Col>
            <Col>
              <InputWembii
                type="text"
                name="substitle"
                id="substitle"
                label={intl.formatMessage({
                  id: "app.chatConfig.subTittleTxt",
                })}
                value={chat?.config?.subtitle}
                flat
                required
                onChange={(v) => handleMainConfigChange({ subtitle: v })}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <InputWembii
                type="color"
                name="titleColor"
                id="titleColor"
                label={intl.formatMessage({ id: "app.chatConfig.colorTitle" })}
                value={chat?.config?.titleColor}
                flat
                required
                onChange={(c) => handleMainConfigChange({ titleColor: c })}
              />
            </Col>
            <Col>
              <InputWembii
                type="color"
                name="backgroundColor"
                id="backgroundColor"
                label={intl.formatMessage({ id: "app.chatConfig.backColor" })}
                value={chat?.config?.backgroundColor}
                flat
                required
                onChange={(c) => handleMainConfigChange({ backgroundColor: c })}
              />
            </Col>
            <Col>
              <InputWembii
                type="color"
                name="startButtonColor"
                id="startButtonColor"
                label={intl.formatMessage({ id: "app.chatConfig.startColor" })}
                value={chat?.config?.startButtonColor}
                flat
                required
                onChange={(c) =>
                  handleMainConfigChange({ startButtonColor: c })
                }
              />
            </Col>
          </Row>

          <TitleWembii
            Tag="h5"
            color="primary"
            toolbar={
              <FormGroup className="d-flex align-items-center">
                <span className="text-secondary font-weight-bold">
                  {intl.formatMessage({ id: "app.chatConfig.startChat" })}
                </span>
                <Switch
                  id="startChat"
                  name="startChat"
                  className="ml-1"
                  checked={chat?.config?.startChat!}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor={COLOR_PRIMARY}
                  height={20}
                  width={30}
                  onChange={(v) => handleMainConfigChange({ startChat: v })}
                />
              </FormGroup>
            }
          >
            {intl.formatMessage({ id: "app.configWspp.btnTxt" })}
          </TitleWembii>

          <Row>
            <Col>
              <InputWembii
                type="color"
                name="openButtonColor"
                id="openButtonColor"
                label={intl.formatMessage({
                  id: "app.chatConfig.startColorBtn",
                })}
                value={chat?.config?.openButtonColor}
                flat
                required
                onChange={(c) => handleMainConfigChange({ openButtonColor: c })}
              />
            </Col>
            <Col>
              <InputWembii
                type="color"
                name="openButtonIconColor"
                id="openButtonIconColor"
                label={intl.formatMessage({
                  id: "app.chatConfig.startColorIcon",
                })}
                value={chat?.config?.openButtonIconColor}
                flat
                required
                onChange={(c) =>
                  handleMainConfigChange({ openButtonIconColor: c })
                }
              />
            </Col>
          </Row>

          <TitleWembii Tag="h5" color="primary">
            {intl.formatMessage({ id: "app.chatConfig.welcomeMsg" })}
          </TitleWembii>
          <InputWembii
            type="text"
            name="welcomeMessage"
            id="welcomeMessage"
            label={intl.formatMessage({ id: "app.chatConfig.automaticMsg" })}
            value={chat?.config?.welcomeMessage}
            flat
            required
            onChange={(c) => handleMainConfigChange({ welcomeMessage: c })}
          />

          <TitleWembii Tag="h5" color="primary">
            {intl.formatMessage({ id: "app.chatConfig.avatar" })}
          </TitleWembii>
          <ImageUploader
            withIcon={false}
            buttonClassName="btn-outline"
            buttonStyles={{
              color: "#000",
              border: "1px #dee2e6 solid",
              boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)",
              background: "#fff",
            }}
            buttonText={intl.formatMessage({
              id: "app.chatConfig.chooseAvatar",
            })}
            onChange={handleSelectAvatar}
            imgExtension={[".jpg", ".jpeg", ".png"]}
            singleImage
            maxFileSize={104857600}
            fileTypeError={intl.formatMessage({
              id: "app.chatConfig.fileError1",
            })}
            fileSizeError={intl.formatMessage({
              id: "app.chatConfig.fileError2",
            })}
            withLabel={false}
            fileContainerStyle={{
              display: "block",
              boxShadow: "0 0 0 0",
              margin: "0",
              padding: "0",
            }}
          />
          <Label className="text-muted">
            <span onClick={handleRemoveAvatar}>
              <Icofont className="mx-2 pointer" icon="trash" />
            </span>
            {`${intl.formatMessage({ id: "app.chatConfig.ImageSize1" })} 
              ${CHAT_CONFIG_RULES.maxImageSize} 
              ${intl.formatMessage({ id: "app.chatConfig.ImageSize2" })}`}
          </Label>

          <div className="d-flex justify-content-center mt-2">
            <Button color="primary" disabled={disabled() || !hasChange}>
              {intl.formatMessage({ id: "app.chatConfig.saveConfig" })}
            </Button>
          </div>
        </Form>
      </PanelWembii>
    </Container>
  );
}
