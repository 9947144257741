import React, { ReactNode } from "react";
import { useGlobalCore } from "../../cores/globals";

interface Props {
  id: string;
  children: ReactNode;
}

export const LoadingElement = (props: Props) => {
  const { id, children } = props;
  const { isLoading } = useGlobalCore();

  return (
    <div className="position-relative">
      {children}
      {isLoading(id) && (
        <div className="lds-container w-100 h-100 position-absolute top-0 z-index-50">
          <div className="lds-loader">
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
            <div className="bar5"></div>
            <div className="bar6"></div>
          </div>
        </div>
      )}
    </div>
  );
};
