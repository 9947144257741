import { GetMercadolibreLoginResponse } from "../interface/api/get-mercadolibre-login";
import {
  MercadolibreSetting,
  PostMercadolibreBindResponse,
} from "../interface/api/post-mercadolibre-bind";

export const mercadolibreSettingMapper = {
  mapResponseToMercadolibreSetting(
    response: PostMercadolibreBindResponse
  ): MercadolibreSetting {
    const { mercadoLibreSetting } = response?.payload;
    return mercadoLibreSetting;
  },

  mapResponseToMercadolibreLoginUrl(
    response: GetMercadolibreLoginResponse
  ): { url: string } {
    const { url } = response?.payload;
    return { url };
  },
};
