import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { GoogleProject, Tokens } from "../interface/api/get-google-projects";
import { GoogleSetting } from "../interface/api/post-google-bind";

export const postUnbindGoogleInitAction = createAction(
  "[Google] Unbind Google Init"
);
export const postUnbindGoogleSuccessAction = createAction(
  "[Google] Unbind Google Success"
);
export const postUnbindGoogleFailureAction = createAction(
  "[Google] Unbind Google Failure",
  props<{ error: string }>()
);

export const postBindGoogleInitAction = createAction(
  "[Google] Bind Google Init"
);
export const postBindGoogleSuccessAction = createAction(
  "[Google] Bind Google Success",
  props<{ settings: GoogleSetting }>()
);
export const postBindGoogleFailureAction = createAction(
  "[Google] Bind Google Failure",
  props<{ error: string }>()
);

export const getGoogleInitAction = createAction(
  "[Google] Get Google Settings Init"
);
export const getGoogleSuccessAction = createAction(
  "[Google] Get Google Settings Success",
  props<{ settings: GoogleSetting }>()
);
export const getGoogleFailureAction = createAction(
  "[Google] Get Google Settings Failure",
  props<{ error: string }>()
);

export const getGoogleProjectsInitAction = createAction(
  "[Google] Get Google Projects Init"
);
export const getGoogleProjectsSuccessAction = createAction(
  "[Google] Get Google Projects Success",
  props<{ projects: GoogleProject[]; tokens: Tokens }>()
);
export const getGoogleProjectsFailureAction = createAction(
  "[Google] Get Google Projects Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  postUnbindGoogleInitAction,
  postUnbindGoogleSuccessAction,
  postUnbindGoogleFailureAction,
  postBindGoogleInitAction,
  postBindGoogleSuccessAction,
  postBindGoogleFailureAction,
  getGoogleInitAction,
  getGoogleSuccessAction,
  getGoogleFailureAction,
  getGoogleProjectsInitAction,
  getGoogleProjectsSuccessAction,
  getGoogleProjectsFailureAction,
});

export type GoogleApiAction = typeof actions;
