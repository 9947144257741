import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PLAN_LOADING, UPGRADE_PLAN_LOADING } from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { planApiService } from "./services/api";
import {
  getPlansFailureAction,
  getPlansInitAction,
  getPlansSuccessAction,
  postUpgradePlansFailureAction,
  postUpgradePlansInitAction,
  postUpgradePlansSuccessAction,
} from "./store/api-actions";
import { plansSelector } from "./store/selector";
import { useIntl } from "react-intl";

export function usePlanCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const plans = useSelector(plansSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const getPlans = useCallback(async () => {
    try {
      dispatch(getPlansInitAction());
      turnOnLoading(PLAN_LOADING);
      const plans = await planApiService.getPlans();
      dispatch(getPlansSuccessAction({ plans }));
      turnOffLoading(PLAN_LOADING);
    } catch (error: any) {
      console.error(error);
      turnOffLoading(PLAN_LOADING);
      dispatch(getPlansFailureAction({ error }));
    }
  }, [dispatch, turnOnLoading, turnOffLoading]);

  const getAllPlans = useCallback(async () => {
    try {
      dispatch(getPlansInitAction());
      turnOnLoading(PLAN_LOADING);
      const plans = await planApiService.getAllPlans();
      dispatch(getPlansSuccessAction({ plans }));
      turnOffLoading(PLAN_LOADING);
    } catch (error: any) {
      console.error(error);
      turnOffLoading(PLAN_LOADING);
      dispatch(getPlansFailureAction({ error }));
    }
  }, [dispatch, turnOnLoading, turnOffLoading]);

  const assignPlan = useCallback(async (userId: string, planId: string) => {
    try {
      await planApiService.assignPlan({ userId, planId });
    } catch (error: any) {
      toast.error(intl.formatMessage({ id: "app.toast.error50" }));
      console.error(error);
    }
  }, [intl]);
  
  const assignFreeTrial = useCallback(async (userId: string, days: number) => {
    try {
      await planApiService.assignFreetrial({ userId, days });
    } catch (error: any) {
      toast.error(intl.formatMessage({ id: "app.toast.error51" }));
      console.error(error);
    }
  }, [intl]);

  const unassignFreeTrial = useCallback(async (userId: string) => {
    try {
      await planApiService.unassignFreetrial(userId);
    } catch (error: any) {
      toast.error(intl.formatMessage({ id: "app.toast.error52" }));
      console.error(error);
    }
  }, [intl]);

  const requestUpgradePlan = useCallback(async (onSuccess?: () => void) => {
    try {
      dispatch(postUpgradePlansInitAction());
      turnOnLoading(UPGRADE_PLAN_LOADING);
      await planApiService.requestUpgradePlan();
      dispatch(postUpgradePlansSuccessAction());
      toast.success(intl.formatMessage({ id: "app.toast.sucess25" }));
      turnOffLoading(UPGRADE_PLAN_LOADING, 500);
      onSuccess?.();
    } catch (error: any) {
      toast.error(intl.formatMessage({ id: "app.toast.error53" }));
      turnOffLoading(UPGRADE_PLAN_LOADING);
      console.error(error);
      dispatch(postUpgradePlansFailureAction({ error }));
    }
  }, [dispatch, turnOnLoading, turnOffLoading, intl]);

  return {
    getPlans,
    getAllPlans,
    assignPlan,
    requestUpgradePlan,
    assignFreeTrial,
    unassignFreeTrial,
    plans,
  };
}
