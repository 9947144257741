import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const office365Root = (state: State) => state.office365;

export const settingSelector = createSelector(
  office365Root,
  (office365) => office365.settings
);

export const userSettingSelector = createSelector(
  office365Root,
  (office365) => office365.userSettings
);
