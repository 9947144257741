import React, { useCallback, useState } from "react";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardTag } from "../../../../cores/boards/interfaces/api/get-tags";
import { useContactCore } from "../../../../cores/contacts";
import TagForm from "../TagForm";
import InternalModal from "../../../layout/InternalModal";
import { CONTACT_TAGS } from "../../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../../cores/globals";

interface Props {
  onUpdate?: () => void;
}

export default function ContactTags({ onUpdate }: Props) {
  const intl = useIntl();
  const { tags } = useContactCore();
  const { turnOffInternalModal, isInternalModal, turnOnInternalModal } = useGlobalCore();
  const [tag, setTag] = useState<CardTag | null>(null);

  const editTag = useCallback((tag: CardTag) => {
    turnOnInternalModal(CONTACT_TAGS, true);
    setTag(tag);
  }, [turnOnInternalModal]);

  const createTag = useCallback(() => {
    turnOnInternalModal(CONTACT_TAGS, true);
    setTag(null);
  }, [turnOnInternalModal]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(CONTACT_TAGS)}
        onClose={() => turnOffInternalModal(CONTACT_TAGS)}
      >
        <TagForm
          tag={tag!}
          onUpdate={() => onUpdate?.()}
        />
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 p-4">
              <h2 className="h2 text-center mb-4">
                {intl.formatMessage({ id: "app.contacts.origins" })}
              </h2>

              <Button color="ligth" className="mb-3 border" onClick={createTag}>
                {intl.formatMessage({ id: "app.contacts.origins.create.new" })}
              </Button>

              {tags.length === 0 && (
                <div className="d-flex align-items-center justify-content-center h-100 text-muted">
                  {intl.formatMessage({ id: "app.contacts.noTags" })}
                </div>
              )}
              {tags.map((tag) => (
                <div className="d-flex justify-content-between align-items-center my-1" key={tag.id}>
                  <Badge
                    pill
                    className="w-100 px-3 py-2 pointer text-left"
                    style={{ backgroundColor: tag.backgroundColor }}
                  >
                    <span className="h5 mb-0 font-weight-bold" style={{ color: tag.textColor }}>
                      {tag.name}
                    </span>
                  </Badge>
                  <FontAwesomeIcon
                    icon={["fas", "edit"]}
                    size="1x"
                    className="ml-3 pointer"
                    color="gray"
                    onClick={() => editTag(tag)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
