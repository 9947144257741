import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GOOGLE_CALENDAR_GET_LOADING,
  GOOGLE_CALENDAR_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { googleCalendarApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  getGoogleCalendarFailureAction,
  getGoogleCalendarInitAction,
  getGoogleCalendarSuccessAction,
  postBindGoogleCalendarInitAction,
  postBindGoogleCalendarSuccessAction,
  postUnbindGoogleCalendarFailureAction,
  postUnbindGoogleCalendarInitAction,
  postUnbindGoogleCalendarSuccessAction,
} from "./store/api-actions";
import { userSettingSelector } from "./store/selector";

export function useGoogleCalendarCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userSettings = useSelector(userSettingSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindGoogleCalendarInitAction());
        await googleCalendarApiService.unbind(companyId);
        dispatch(postUnbindGoogleCalendarSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindGoogleCalendarFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      code: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindGoogleCalendarInitAction());
        turnOnLoading(GOOGLE_CALENDAR_SYNC_LOADING);
        const response = await googleCalendarApiService.bind(companyId, code);
        dispatch(postBindGoogleCalendarSuccessAction({ userSettings: response }));
        turnOffLoading(GOOGLE_CALENDAR_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess52" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(GOOGLE_CALENDAR_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error77" }));
        console.error(error);
        dispatch(postUnbindGoogleCalendarFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getGoogleCalendarInitAction());
        turnOnLoading(GOOGLE_CALENDAR_GET_LOADING);
        const response = await googleCalendarApiService.get(companyId);
        dispatch(getGoogleCalendarSuccessAction({ userSettings: response }));
        turnOffLoading(GOOGLE_CALENDAR_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(GOOGLE_CALENDAR_GET_LOADING);
        console.error(error);
        dispatch(getGoogleCalendarFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const login = useCallback(
    async (companyId: string) => {
      try {
        turnOnLoading(GOOGLE_CALENDAR_SYNC_LOADING);
        const response = await googleCalendarApiService.login(companyId);
        return response.url;
      } catch (error: any) {
        turnOffLoading(GOOGLE_CALENDAR_SYNC_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error68" }));
      }
    },
    [turnOffLoading, turnOnLoading, intl]
  );

  return {
    userSettings,
    unbind,
    bind,
    get,
    login,
  };
}
