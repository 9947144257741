import React, { useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import fakeText from "../../../../assets/preview/preview-fake-text-big.png";
import footerImage from "../../../../assets/preview/twitter-preview-footer.png";
import headerImage from "../../../../assets/preview/twitter-preview-header.png";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { SocialProviderId } from "../../../../cores/socialPost/config";
import { PostError } from "../../../../cores/socialPost/interfaces/postError";
import { useTwitterCore } from "../../../../cores/twitter";
import { ErrorList } from "./ErrorList";
import { MediaPreview } from "./MediaPreview";

export const TwitterPostPreview = () => {
  const { settings } = useTwitterCore();
  const { text, mentions, postErrors } = useSocialPostCore();
  const { name, avatarUrl } = settings || {};

  const providerErrors: PostError[] = useMemo(
    () =>
      postErrors.filter(
        ({ socialProviderId }) => socialProviderId === SocialProviderId.Twitter
      ),
    [postErrors]
  );

  const formattedText = useMemo(() => {
    const orderMentions = mentions.sort((a, b) =>
      a.offset > b.offset ? -1 : 1
    );

    let txt = text;
    orderMentions.forEach((mention) => {
      const submention = mention.mentions.find(
        (submention) => submention.socialProviderId === SocialProviderId.Twitter
      );
      const name = submention
        ? `<span style="color:#0d4b7e;">@${submention.username}</span>`
        : mention.mentions[0].username;
      const offset = mention.offset + mention.mentions[0].username.length + 1;
      txt = txt.slice(0, mention.offset) + name + txt.slice(offset);
    });

    return txt;
  }, [text, mentions]);

  return providerErrors.length > 0 ? (
    <ErrorList errors={providerErrors} />
  ) : (
    <div className="twitterPreview-container neu-out p-2">
      <Container fluid>
        <Row className="twitterPreview-header">
          <Col xs="2">
            <img
              className="twitterPreview-header-avatar"
              src={avatarUrl}
              alt="avatar"
            />
          </Col>
          <Col xs="10" className="twitterPreview-header-description">
            <div className="align-items-end d-flex">
              <span className="twitterPreview-header-name"> {name} </span>{" "}
              <img className="ml-3 w-100" src={headerImage} alt="header" />
            </div>
            <div className="line-break-anywhere pr-4 pre-wrap">
              {formattedText ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: formattedText,
                  }}
                ></div>
              ) : (
                <img src={fakeText} alt="fake-text" />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="twitterPreview-image-container">
            <MediaPreview className="twitterPreview-image" />
            <img
              className="twitterPreview-footer"
              src={footerImage}
              alt="footer"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
