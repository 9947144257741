export const FACEBOOK_COLOR = "#3b5998";
export const FACEBOOK_ADS_COLOR = "#263B74";
export const TWITTER_COLOR = "#00aced";
export const MERCADOLIBRE_COLOR = "#f4cf01";
export const JUMPSELLER_COLOR = "#99c633";
export const MAILCHIMP_COLOR = "#241c15";
export const LINKED_IN_COLOR = "#0076b5";
export const LINKED_IN_BUSINESS_COLOR = "#001a37";
export const INSTAGRAM_COLOR = "#E0416D";
export const MESSENGER_COLOR = "#009DFE";
export const GOOGLE_COLOR = "#34a853";
export const HUB_SPOT_COLOR = "#ff6b49";
export const WOO_COMMERCE_COLOR = "#9b5b8f";
export const CHAT_GPT_COLOR = "#10A37F";
export const WHATSAPP_COLOR = "#00bb2d";
export const SMTP_COLOR = "#ef767a";