import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { createEntityAdapter } from "../../helpers/store/create-entity-adapter";
import { EmailFile } from "../interface/emailFile";
import {
  EmailActions,
  setFilesAction,
  deleteFileAction,
  updateFileAction,
  clearAction,
  setSubjectAction,
  setMessageAction,
} from "./actions";

export interface EmailState {
  message: string;
  text: string;
  files: {
    ids: string[];
    entities: Record<string, EmailFile>;
  };
  subject: string;
  error: string;
}

const initialState: EmailState = {
  message: "",
  text: "",
  files: { ids: [], entities: {} },
  subject: "",
  error: "",
};

const filesAdapter = createEntityAdapter<EmailFile>((payload) => payload.id);

export default produce(
  (
    draft: EmailState = initialState,
    action: EmailActions | GlobalActions
  ) => {
    switch (action.type) {
      case setFilesAction.type:
        filesAdapter.updateMany(action.files, draft.files);
        return draft;
      case deleteFileAction.type:
        filesAdapter.removeOne(action.id, draft.files);
        return draft;
      case updateFileAction.type:
        filesAdapter.updateOne(action.file, draft.files);
        return draft;
      case clearAction.type:
        filesAdapter.removeAll(draft.files);
        return draft;
      case setSubjectAction.type:
        draft.subject = action.subject;
        return draft;
      case setMessageAction.type:
        draft.message = action.message;
        return draft;
      case resetAction.type:
        return initialState;
      default:
        return draft;
    }
  }
);
