import { Dictionary } from "lodash";

export enum Weekday {
  Mo = "Mo",
  Tu = "Tu",
  We = "We",
  Th = "Th",
  Fr = "Fr",
  Sa = "Sa",
  Su = "Su"
}

export const  WeekdayLabels: Dictionary<string> = {
  [`${Weekday.Mo}`]: "app.calendar.monday",
  [`${Weekday.Tu}`]: "app.calendar.tuesday",
  [`${Weekday.We}`]: "app.calendar.wednesday",
  [`${Weekday.Th}`]: "app.calendar.thursday",
  [`${Weekday.Fr}`]: "app.calendar.friday",
  [`${Weekday.Sa}`]: "app.calendar.saturday",
  [`${Weekday.Su}`]: "app.calendar.sunday",
};

export const WeekdayOptions = [
  { value: Weekday.Mo, label: "app.calendar.monday" },
  { value: Weekday.Tu, label: "app.calendar.tuesday" },
  { value: Weekday.We, label: "app.calendar.wednesday" },
  { value: Weekday.Th, label: "app.calendar.thursday" },
  { value: Weekday.Fr, label: "app.calendar.friday" },
  { value: Weekday.Sa, label: "app.calendar.saturday" },
  { value: Weekday.Su, label: "app.calendar.sunday" }
];