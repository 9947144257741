import {
  FacebookAccount,
  GetFacebookAccountResponse,
} from "../interface/api/get-facebook-account";
import {
  GetFacebookPagePayload,
  GetFacebookPageResponse,
} from "../interface/api/get-facebook-page";
import {
  FacebookSetting,
  PostFacebookBindResponse,
} from "../interface/api/post-facebook-bind";

export const facebookMapper = {
  mapResponseToFacebookSetting(
    response: PostFacebookBindResponse
  ): FacebookSetting {
    const { facebookSetting } = response?.payload;
    return facebookSetting;
  },

  mapResponseToFacebookAccounts(
    response: GetFacebookAccountResponse
  ): FacebookAccount[] {
    const { accounts } = response?.payload;
    return accounts;
  },

  mapResponseToFacebookPages(
    response: GetFacebookPageResponse
  ): GetFacebookPagePayload {
    const { pages } = response?.payload;
    return { pages };
  },
};
