import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { Col, Input, Row } from "reactstrap";
import { useInstagramCore } from "../../../../cores/instagram";
import { SocialPost } from "../../../../cores/socialPost/interfaces/api/get-social-posts";
import { DropdownOptions } from "./DropdownOptions";
import { MediaPreview } from "./MediaPreview";

interface Props {
  post: SocialPost;
  onDelete: () => void;
  onEdit: () => void;
}

export const InstagramStoryPreview = (props: Props) => {
  const intl = useIntl();
  const { post, onEdit, onDelete } = props;
  const { settings } = useInstagramCore();
  const { name: defaultName, avatarUrl: defaultAvatar } = settings || {};
  const { publisherName: name, publisherAvatarUrl: avatarUrl } =
    post.post || {};
  
  return (
    <>
      <Row className="instagramPreview-header">
        <Col xs="10" className="d-flex align-items-center p-0">
          <div>
            <img
              className="instagramPreview-header-avatar"
              src={avatarUrl || defaultAvatar}
              alt="avatar"
            />
          </div>
          <div>
            <div className="font-weight-bold text-light">{name || defaultName}</div>
            <div className="text-muted">
              {post.post.postAt ? (
                moment(post.post.postAt).format("DD MMM YYYY hh:mm a")
              ) : (
                <span className="text-warning">
                  {moment(post.post.scheduleDate).format("DD MMM YYYY hh:mm a")}{" "}
                  <FontAwesomeIcon icon={["far", "clock"]} />
                </span>
              )}
            </div>
          </div>
        </Col>
        <Col xs="2" className="align-content-center d-flex justify-content-end">
          {post.post.status === 0 && (
            <DropdownOptions
              children={
                <FontAwesomeIcon
                  className="pointer"
                  color="white"
                  icon={["fas", "ellipsis-h"]}
                />
              }
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
        </Col>
      </Row>
      <Row
        className="d-flex justify-content-center align-items-center px-2"
        style={{ minHeight: '300px' }}
      >
        <MediaPreview
          className="facebookPreview-image"
          type={post.post.type}
          media={post.media}
        />
      </Row>
      <Row className="px-2 d-flex justify-content-between">
        <Input
          className="bg-transparent rounded-pill"
          placeholder={intl.formatMessage({ id: "app.socialStory.sendMessage" })}
        />
      </Row>
    </>
  );
};
