import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface Props extends RouteProps {
  open?: boolean;
  redirectUrl?: string;
}

export default function ProtectedRoute(props: Props) {
  const { open, redirectUrl = "/", ...rest } = props;
  return (
    <>
      {!open && <Redirect to={redirectUrl} />}
      {open && <Route {...rest} />}
    </>
  );
}
