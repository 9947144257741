import QueryString from "query-string";
import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import Calendar from "react-calendar";
import DatePicker from "react-date-picker";
import { useIntl } from "react-intl";
import { useEventCore } from "../../cores/event";
import moment from "moment";
import Avatar from "../component/Avatar";
import { InputWembii } from "../component/InputWembii";
import { getFirstLetters, isEmail } from "../utils/stringUtils";
import { EVENT_TIMEZONE } from "../../cores/event/constants/eventTimezone.constant";
import { UpdateLoadingCard } from "../component/UpdateLoadingCard";
import { useGlobalCore } from "../../cores/globals";
import { CALENDAR_TIME_LOADING, EVENT_CREATION_LOADING } from "../../constants/loading.constant";
import { Errors } from "../../constants/errors";
import { COLOR_SUCCESS, COLOR_WARNING } from "../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "react-phone-number-input";
import { LabelWembii } from "../component/LabelWembii";
import { LoadingSpinnerWembii } from "../component/LoadingSpinnerWembii";
import { ContactInformation } from "../../cores/contacts/interfaces/DefaultInformation";

export const Meeting = () => {
  const intl = useIntl();
  const { token } = useParams<{ token: string }>();
  const { search } = useLocation();
  const { getAvailableTime, postPublicEvent, getPublicCalendar, time, publicCalendar, errorCode } = useEventCore();
  const { isLoading } = useGlobalCore();
  const [cardId, setCardId] = useState("");
  const [timezone, setTimezone] = useState("");
  const [duration, setDuration] = useState(15);
  const [hour, setHour] = useState("");
  const [date, setDate] = useState(new Date());
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [guest, setGuest] = useState<string>("");
  const [guests, setGuests] = useState<string[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [customValues, setCustomValues] = useState<{[key: string]: string}>({});

  useEffect(() => {
    getPublicCalendar(token);
  }, [getPublicCalendar, token]);

  useEffect(() => {
    setTimezone(publicCalendar?.timezone!);
    setDuration(15);
    setDate(new Date());
    setHour("");
    setEmail("");
    setPhone("");
    setWhatsapp("");
    setName("");
    setShowConfirmation(false);
  }, [publicCalendar]);

  const dateString = useMemo(() => {
    const dt = moment(date);
    const endHr = moment(`${dt.format("YYYY/MM/DD")} ${hour}`, "YYYY/MM/DD HH:mm").add(duration, "m").format("HH:mm")

    return `${hour} - ${endHr}, ${dt.format("dddd, DD MMMM YYYY")}`;
  } ,[date, hour, duration]);

  useEffect(() => {
    if (publicCalendar) {
      setTimezone(publicCalendar.timezone);
    }
    setHour("");
  }, [publicCalendar]);

  useEffect(() => {
    if (timezone) {
      getAvailableTime(
        token,
        moment(date).format("YYYY/MM/DD"),
        timezone,
        duration
      );
    }
  }, [token, date, timezone, duration, getAvailableTime]);

  useEffect(() => {
    const { card } = QueryString.parse(search);
    if (card) {
      setCardId(card as string);
    }
  }, [search]);

  const resetForm = useCallback(() => {
    setTimezone(publicCalendar?.timezone!);
    setDuration(15);
    setDate(new Date());
    setHour("");
    setEmail("");
    setPhone("");
    setWhatsapp("");
    setName("");
    setShowConfirmation(false);
    setDescription("");
    setGuest("");
    setGuests([]);
  }, [publicCalendar]);

  const onFormSubmit = useCallback(async (e: any) => {
    e.preventDefault();

    const customFields = Object.keys(customValues).map((key) => ({
      type: key,
      value: customValues[key],
    }))

    postPublicEvent(
      token,
      {
        date: moment(date).format("YYYY/MM/DD"),
        time: hour,
        duration,
        timezone,
        name,
        email,
        phone,
        whatsapp,
        description,
        guests,
        cardId,
        customFields,
      },
      () => {
        setShowConfirmation(true);
      }
    );
  }, [
    postPublicEvent,
    hour,
    cardId,
    token,
    date,
    duration,
    timezone,
    name,
    customValues,
    email,
    whatsapp,
    description,
    guests,
    phone
  ]);

  const onAdding = useCallback(() => {
    if (guest && !guests.includes(guest)) {
      setGuests([...guests, guest]);
    }
    setGuest("");
  }, [guests, guest]);

  const onRemoving = useCallback((guest) => {
    setGuests(guests.filter((gst) => gst !== guest));
  }, [guests]);

  const showPhoneInput = useMemo(() =>
    publicCalendar?.inputs.find((input) => input.field === ContactInformation.PHONE)
  , [publicCalendar?.inputs]);

  const showWhatsappInput = useMemo(() =>
  publicCalendar?.inputs.find((input) => input.field === ContactInformation.WHATSAPP)
  , [publicCalendar?.inputs]);

  const customFieldInputs = useMemo(() =>
    publicCalendar?.inputs.filter((input) => input.customField)
  , [publicCalendar?.inputs]);

  const disabled = useCallback(
    () =>
      !duration || !date || !hour ||
      (!cardId && (
        !email || !isEmail(email) || !name ||
        (showPhoneInput && !phone) ||
        (showWhatsappInput && !whatsapp)
      )),
    [
      email,
      duration,
      date,
      hour,
      cardId,
      name,
      phone,
      showPhoneInput,
      whatsapp,
      showWhatsappInput
    ]
  );

  const setCustomValue = useCallback((value: string, field: string) => {
    setCustomValues({
      ...customValues,
      [field]: value
    });
  }, [customValues]);

  useEffect(() => {}, [time]);

  return (
    <Container className="h-100 w-100 overflow-auto" fluid>
      <Row className="h-100">
        {errorCode === Errors.NoExistResource ? (
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              size="2x"
              color={COLOR_WARNING}
              className="mr-2"
            />
            {intl.formatMessage({ id: "app.calendar.no.exist" })}
          </div>
        ) : (
          showConfirmation ? (
            <Col
              md={{
                offset: 4,
                size: 4
              }}
              sm="12"
              className="col-sm-12 col-md-4 offset-md-4 d-flex flex-column justify-content-center"
            >
              <div className="d-flex flex-column align-items-center justify-content-center shadow bg-white p-3 rounded">
                <Avatar
                  className="text-dark-50 font-weight-bold pointer m-1"
                  image={publicCalendar?.user.avatarUrl}
                  height="100px"
                  width="100px"
                  label={getFirstLetters(`${publicCalendar?.user.lastName} ${publicCalendar?.user.firstName}`)}
                  />
                <div className="font-weight-bold h4">
                  {intl.formatMessage({ id: "app.calendar.event.confirmed.title" })}
                </div>
                <div>
                  {intl.formatMessage({ id: "app.calendar.event.confirmed.message" }, { name: `${publicCalendar?.user.firstName} ${publicCalendar?.user.lastName}` })}
                </div>

                <hr className="horizontal-separator" />

                <div className="h5 text-center font-weight-bold">
                  {intl.formatMessage({ id: "app.calendar.event.confirmed.duration" }, { duration })}
                </div>
                <div className="text-center">
                  {dateString}
                </div>
                <div className="font-weight-bold text-center">
                  {intl.formatMessage({ id: "app.calendar.event.confirmed.email" })}
                </div>
              </div>
              <div className="text-center pointer mt-3" onClick={resetForm}>
                {intl.formatMessage({ id: "app.calendar.event.confirmed.new" })}
              </div>
            </Col>
          ) : (
            <Col
              lg={{
                offset: 3,
                size: 6
              }}
              sm="12"
            >
              <Form className="m-4 d-flex flex-column align-items-center justify-content-center shadow bg-white p-3 rounded" onSubmit={onFormSubmit}>
                <div className="h2 text-center mb-5">
                  {publicCalendar?.title
                    ? publicCalendar.title
                    : `Reunirse con ${publicCalendar?.user.firstName} ${publicCalendar?.user.lastName}`
                  }
                </div>
                <Row className="mb-4">
                  <Col md="6" className="d-flex align-items-center justify-content-center justify-content-lg-end mb-2">
                    <div className="w-100">
                      <div className="d-flex justify-content-center mb-3">
                        <Avatar
                          className="text-dark-50 font-weight-bold pointer m-1"
                          image={publicCalendar?.user.avatarUrl}
                          height="170px"
                          width="170px"
                          label={getFirstLetters(`${publicCalendar?.user.lastName} ${publicCalendar?.user.firstName}`)}
                        />
                      </div>
                      <div className="d-none d-md-flex justify-content-center">
                        <Calendar minDate={new Date()} onChange={setDate} value={date} />
                      </div>
                      <div className="d-flex d-md-none justify-content-center">
                        <DatePicker className="w-100" minDate={new Date()} onChange={setDate} value={date} />
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="d-flex align-items-end justify-content-center justify-content-lg-start mb-2">
                    <div className="w-100">
                      <div>{intl.formatMessage({ id: "app.calendar.needed.time" })}</div>
                      <ButtonGroup
                        className="mt-2 mb-4 w-100 d-flex flex-wrap"
                        size="md"
                      >
                        <Button className={cn("squareButton", { 'bg-light': duration === 15 })} onClick={() => setDuration(15)}>
                          {intl.formatMessage({ id: "app.meetings.15min" })}
                        </Button>
                        <Button className={cn("squareButton", { 'bg-light': duration === 30 })} onClick={() => setDuration(30)}>
                          {intl.formatMessage({ id: "app.meetings.30min" })}
                        </Button>
                        <Button className={cn("squareButton", { 'bg-light': duration === 60 })} onClick={() => setDuration(60)}>
                          {intl.formatMessage({ id: "app.meetings.1hour" })}
                        </Button>
                      </ButtonGroup>

                      <div>¿A qué hora puedes?</div>
                      <InputWembii
                        type="select"
                        required
                        value={timezone}
                        onChange={setTimezone}
                      >
                        {EVENT_TIMEZONE.map((timezone) => (
                          <option key={timezone} value={timezone}>
                            {timezone}
                          </option>
                        ))}
                      </InputWembii>
                      {isLoading(CALENDAR_TIME_LOADING) ? (
                        <div className="h-100 w-100 d-flex justify-content-center align-items-center"  style={{ maxHeight: "300px", minHeight: "300px" }}>
                          <LoadingSpinnerWembii active />
                        </div>
                      ) : (
                        time.length > 0 ? (
                          <div className="overflow-auto" style={{ maxHeight: "300px", minHeight: "300px" }}>
                            {time.map((hr) => (
                              <div
                                key={hr}
                                onClick={() => setHour(hr)}
                                className={cn("border text-center m-1 p-2 pointer", {
                                  'bg-light': hour === hr,
                                  'text-primary': hour === hr,
                                  'bg-white': hour !== hr
                                })}
                              >
                                {hr}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-items-center text-muted" style={{ minHeight: "300px" }}>
                            {intl.formatMessage({ id: "app.calendar.no.time" })}
                          </div>
                        )
                      )}
                    </div>
                  </Col>
                </Row>
                {publicCalendar?.description && (
                  <div className="mb-3 pre-wrap">
                    {publicCalendar?.description}
                  </div>
                )}
                {!cardId && (
                  <>
                    <hr className="horizontal-separator" />
                    <div className="text-center">
                      {intl.formatMessage({ id: "app.meetings.information" })}
                    </div>
                    <Row>
                      <Col xs="12" lg="6">
                        <InputWembii
                          label={intl.formatMessage({ id: "app.contacts.email" })}
                          type="text"
                          required
                          mb={false}
                          placeholder={intl.formatMessage({ id: "app.contacts.email" })}
                          value={email}
                          onChange={setEmail}
                          />
                        {!!email && !isEmail(email) && (
                          <div className="error mb-0">
                            {intl.formatMessage({ id: "app.emailIsNotValid" })}
                          </div>
                        )}
                      </Col>
                      <Col xs="12" lg="6">
                        <InputWembii
                          label={intl.formatMessage({ id: "app.contacts.name" })}
                          type="text"
                          required
                          placeholder={intl.formatMessage({ id: "app.contacts.name" })}
                          value={name}
                          onChange={setName}
                          />
                      </Col>
                      {showPhoneInput && (
                        <Col xs="12" lg="6" className="d-flex flex-column align-items-start mb-3">
                          <LabelWembii forid="calendar-phone" required>
                            {intl.formatMessage({ id: "app.contacts.phone" })}
                          </LabelWembii>
                          <PhoneInput
                            name="phone"
                            id="calendar-phone"
                            className="w-100"
                            placeholder={intl.formatMessage({ id: "app.contacts.phone" })}
                            value={phone}
                            onChange={(v) => setPhone(v || "")}
                          />
                        </Col>
                      )}
                      {showWhatsappInput && (
                        <Col xs="12" lg="6" className="d-flex flex-column align-items-start mb-3">
                          <LabelWembii forid="calendar-whatsapp" required>
                            {intl.formatMessage({ id: "app.contacts.whatsapp" })}
                          </LabelWembii>
                          <PhoneInput
                            name="whatsapp"
                            id="calendar-whatsapp"
                            placeholder={intl.formatMessage({ id: "app.contacts.whatsapp" })}
                            value={whatsapp}
                            onChange={(v) => setWhatsapp(v || "")}
                          />
                        </Col>
                      )}
                      {customFieldInputs?.map((input) => (
                        <Col xs="12" lg="6" key={input.id}>
                          <InputWembii
                            label={input.label}
                            type="text"
                            required
                            placeholder={input.label}
                            value={customValues[input.field]}
                            onChange={(v) => setCustomValue(v, input.field)}
                            />      
                        </Col>
                      ))}
                      <Col xs="12">
                        <InputWembii
                          label={intl.formatMessage({ id: "app.createEvent.comment" })}
                          type="textarea"
                          value={description}
                          onChange={setDescription}
                        />
                      </Col>
                    </Row>
                    <div className="w-100">
                      <LabelWembii forid="guests">
                        {intl.formatMessage({ id: "app.createEvent.participants" })}
                      </LabelWembii>
                      <div className="d-flex">
                        <InputWembii
                          type="text"
                          className="w-100"
                          mb={false}
                          value={guest}
                          onChange={setGuest}
                        />
                        <FontAwesomeIcon
                          icon={["fas", "check"]}
                          size="2x"
                          color={COLOR_SUCCESS}
                          className="m-1 pointer z-index-5 ml-1"
                          onClick={onAdding}
                        />
                      </div>
                      {!!guest && !isEmail(guest) && (
                        <div className="error mb-0">
                          {intl.formatMessage({ id: "app.emailIsNotValid" })}
                        </div>
                      )}
                    </div>
                    <div className="w-100 d-flex justify-content-start">
                      {guests.map((guest) => (
                        <Badge 
                          key={guest}
                          color="light"
                          className="text-dark m-1 d-flex align-items-center"
                          pill
                        >
                          <span>
                            {guest}
                          </span>
                          <FontAwesomeIcon
                            icon={["fas", "times"]}
                            size="1x"
                            color="black"
                            className="m-1 pointer z-index-5 ml-1"
                            onClick={() => onRemoving(guest)}
                          />
                        </Badge>
                      ))}
                    </div>
                  </>
                )}
                <div className="text-center mt-2">
                  <UpdateLoadingCard
                    loadingId={EVENT_CREATION_LOADING}
                    text={intl.formatMessage({ id: "app.meetings.creation" })}
                  />
                  {!isLoading(EVENT_CREATION_LOADING) && (
                    <Button
                      color="primary"
                      className="px-4"
                      disabled={disabled()}
                    >
                      {intl.formatMessage({ id: "app.meetings.schedule" })}
                    </Button>
                  )}
                </div>
              </Form>
            </Col>
          )
        )}
      </Row>
    </Container>
  );
};
