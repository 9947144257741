import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Label } from "reactstrap";
import { INSTAGRAM_MENSSEGER_BIND_MODAL } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { InstagramAccount } from "../../../../cores/instagramMensseger/interface/api/get-instagram-accounts";
import { useInstagramMenssegerCore } from "../../../../cores/instagramMensseger";
import { useMeCore } from "../../../../cores/me";
import InstagramAccounts from "./InstagramAccounts";
import { InstagramMenssegerIcon } from "./InstagramMenssegerIcon";
import { INSTAGRAM_COLOR } from "../../../../constants/socialColors.constant";
import { Errors } from "../../../../constants/errors";
import { useIntl } from "react-intl";

export default function InstagramMenssegerLoginForm({
  userToken,
}: {
  userToken: string;
}) {
  const intl = useIntl();
  const { turnOffModal, isModal } = useGlobalCore();
  const { bind, errorCode, settings } = useInstagramMenssegerCore();
  const { company, getMeSocialSettings } = useMeCore();
  const [account, setAccount] = useState<InstagramAccount | null>(null);

  useEffect(() => {
    isModal(INSTAGRAM_MENSSEGER_BIND_MODAL) &&
      !_.isEmpty(settings) &&
      !errorCode &&
      turnOffModal(INSTAGRAM_MENSSEGER_BIND_MODAL);
  }, [errorCode, isModal, settings, turnOffModal]);

  const handleSubmit = useCallback(
    async (e) => {
      if (account) {
        e.preventDefault();
        bind(company!.id, account, userToken, () => {
          getMeSocialSettings(company?.id!);
        });
      }
    },
    [account, bind, company, userToken, getMeSocialSettings]
  );

  const disabled = useMemo(() => !account || !company, [company, account]);

  const error = useMemo(() => {
    switch (errorCode) {
      case Errors.MessageDissabled:
        return (
          <Label className="text-muted text-center my-4 w-100">
              {intl.formatMessage({id: "app.syncInstagramMessenger.txt1"})}{' '}
            <a
              href="https://soporte.wembii.com/es/article/admitir-mensajeria-desde-la-app-de-instagram-1mur9uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({id: "app.syncInstagramMessenger.txt2"})}
            </a>
            {' '}{intl.formatMessage({id: "app.syncInstagramMessenger.txt3"})}
          </Label>
        );
      case Errors.MessageNotAllowed:
        return (
          <Label className="text-muted text-center my-4 w-100">
            {intl.formatMessage({id: "app.syncInstagramMessenger.txt4"})}{' '}
            <a
              href="https://developers.facebook.com/docs/messenger-platform/instagram/rollout"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({id: "app.syncInstagramMessenger.txt5"})}
            </a>
            {' '}{intl.formatMessage({id: "app.syncInstagramMessenger.txt6"})}
          </Label>
        );
      case Errors.NotBusinessAccount:
        return (
          <Label className="text-muted text-center my-4 w-100">
            {intl.formatMessage({ id: "app.social.instagram.businessError" })}.
            {' Lee '}
            <a
              href="https://soporte.wembii.com/es/article/como-cambiar-una-cuenta-de-instagram-a-empresa-1d9gri7/"
              target="_blank"
              rel="noopener noreferrer"
            >
              aquí
            </a>
            {' como hacerlo'}
          </Label>
        );
    }
  }, [errorCode, intl]);

  return (
    <div className="p-4">
      <h2 className="h2 text-center mb-4">
        {intl.formatMessage({ id:  "app.syncInstagram.title"})}
      </h2>
      <div className="my-2 d-flex justify-content-center">
        <InstagramMenssegerIcon size="6x" color={INSTAGRAM_COLOR} className="mb-2" />
      </div>
      <InstagramAccounts
        onSelectAccount={setAccount}
        selectedAccount={account}
      />
      {errorCode && error}
      {!!account && (
        <Button
          className="my-4 mx-auto w-50"
          color="primary"
          block
          disabled={disabled}
          onClick={handleSubmit}
        >
          {intl.formatMessage({ id:  "app.syncInstagram.login"})}
        </Button>
      )}
    </div>
  );
}
