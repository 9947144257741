import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Hashtag } from "../interfaces/api/get-hashtags";
import { SocialPost } from "../interfaces/api/get-social-posts";

export const getSocialPostsInitAction = createAction(
  "[Social Post] Get Social Posts Init"
);
export const getSocialPostsSuccessAction = createAction(
  "[Social Post] Get Social Posts Success",
  props<{ posts: SocialPost[] }>()
);
export const getSocialPostsFailureAction = createAction(
  "[Social Post] Get Social Posts Failure",
  props<{ error: string }>()
);

export const deleteSocialPostInitAction = createAction(
  "[Social Post] Delete Social Posts Init"
);
export const deleteSocialPostSuccessAction = createAction(
  "[Social Post] Delete Social Posts Success",
  props<{ id: string }>()
);
export const deleteSocialPostFailureAction = createAction(
  "[Social Post] Delete Social Posts Failure",
  props<{ error: string }>()
);

export const updateSocialPostInitAction = createAction(
  "[Social Post] Update Social Posts Init"
);
export const updateSocialPostSuccessAction = createAction(
  "[Social Post] Update Social Posts Success",
  props<{ post: SocialPost }>()
);
export const updateSocialPostFinishAction = createAction(
  "[Social Post] Update Social Posts Failure",
  props<{ error: string }>()
);

export const getHashtagsInitAction = createAction(
  "[Social Post] Get Hashtags Init"
);
export const getHashtagsSuccessAction = createAction(
  "[Social Post] Get Hashtags Success",
  props<{ hashtags: Hashtag[] }>()
);
export const getHashtagsFailureAction = createAction(
  "[Social Post] Get Hashtags Failure",
  props<{ error: string }>()
);

export const postHashtagInitAction = createAction(
  "[Social Post] Post Hashtag Init"
);
export const postHashtagSuccessAction = createAction(
  "[Social Post] Post Hashtag Success",
  props<{ hashtag: Hashtag }>()
);
export const postHashtagFailureAction = createAction(
  "[Social Post] Post Hashtag Failure",
  props<{ error: string }>()
);

export const deleteHashtagInitAction = createAction(
  "[Social Post] Delete Hashtag Init"
);
export const deleteHashtagSuccessAction = createAction(
  "[Social Post] Get Hashtag Success",
  props<{ id: string }>()
);
export const deleteHashtagFailureAction = createAction(
  "[Social Post] Get Hashtag Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getSocialPostsInitAction,
  getSocialPostsSuccessAction,
  getSocialPostsFailureAction,
  deleteSocialPostInitAction,
  deleteSocialPostSuccessAction,
  deleteSocialPostFailureAction,
  updateSocialPostInitAction,
  updateSocialPostSuccessAction,
  updateSocialPostFinishAction,
  getHashtagsInitAction,
  getHashtagsSuccessAction,
  getHashtagsFailureAction,
  postHashtagInitAction,
  postHashtagSuccessAction,
  postHashtagFailureAction,
  deleteHashtagInitAction,
  deleteHashtagSuccessAction,
  deleteHashtagFailureAction,
});

export type SocialPostsApiAction = typeof actions;
