import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "react-phone-number-input/style.css";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  LAYOUT_LOADING,
  MAIN_LAYOUT_LOADING,
} from "./constants/loading.constant";
import { useCanvaCore } from "./cores/canva";
import { useCompanyCore } from "./cores/company";
import { useGlobalCore } from "./cores/globals";
import { useMeCore } from "./cores/me";
import { useNotificationCore } from "./cores/notifications";
import { useRouterCore } from "./cores/router";
import { useSessionCore } from "./cores/session";
import { useSocketCore } from "./cores/socket";
import { Context } from "./LocaleWrapper";
import PrivateRoute from "./ui/component/PrivateRoute";
import MainLayout from "./ui/layout/MainLayout";
import { CreateAccount } from "./ui/screen/CreateAccount";
import { ForgotPassword } from "./ui/screen/ForgotPassword";
import { Login } from "./ui/screen/Login";
import { RecoverPassword } from "./ui/screen/RecoverPassword";
import { Meeting } from "./ui/screen/Meeting";
import { CreateAccountEntel } from "./ui/screen/CreateAccountEntel";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import { Quill } from "react-quill";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";
import { useConfigCore } from "./cores/config";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import { WebForm } from "./ui/screen/WebForm";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
// @ts-ignore
import sound from "./assets/sounds/notification-sound.mp3";
import useSound from "use-sound";
import UnsubscribedSuccess from "./ui/screen/UnsubscribedSuccess";

function App() {
  const { selectLanguage } = useContext(Context);
  const { token } = useSessionCore();
  const {
    user,
    getMeUser,
    getMeCompany,
    getMeCompanies,
    getMeAreas,
    selectCompany,
    company,
    companies,
    companyId,
    areas,
    companyPlan,
  } = useMeCore();
  const { reset, turnOnLoading, turnOffLoading } = useGlobalCore();
  const { setPath, path } = useConfigCore();
  const { getCompany } = useCompanyCore();
  const { getUnreadNotifications, getSystemNotifications } =
    useNotificationCore();
  const { history, routes, pathname, search, goToMeCompany, goToSocial } =
    useRouterCore();
  const { initCanva } = useCanvaCore();
  const { subscribeToChannel, notification } = useSocketCore();
  const [companyDeleted, setCompanyDeleted] = useState(false);
  const [companiesFetched, setCompaniesFetched] = useState(false);
  const [play] = useSound(sound);

  library.add(fab);
  library.add(fas);
  library.add(far);
  Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste, true);
  Quill.register("modules/imageActions", ImageActions, true);
  Quill.register("modules/imageFormats", ImageFormats, true);

  if (process.env.REACT_APP_GOOGLE_PIXEL_ANALYTICS && !ReactGA.isInitialized) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_PIXEL_ANALYTICS);
  }

  useEffect(() => {
    if (navigator.onLine) {
      if (process.env.REACT_APP_MIXPANEL_ID) {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
          batch_requests: true,
        });
        if (user && company && companyPlan) {
          mixpanel.identify(user.id);
          mixpanel.people.set({
            $email: user.email,
            $first_name: user.firstName,
            $last_name: user.lastName,
            reseller: user.reseller?.name,
            plan: user.userPlan?.name,
            freetrial: user.freeTrial,
          });
        }
      }
    }
  }, [user, company, companyPlan]);

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_PIXEL_ANALYTICS) {
      ReactGA.send({ hitType: "pageview", page: pathname });
    }
    if (pathname !== "/" && pathname !== "/emails") setPath(pathname);
  }, [pathname, setPath]);

  useEffect(() => {
    return history.listen((location) => {
      // @ts-ignore
      window.Appcues?.page();
    });
  }, [history]);

  useEffect(() => {
    initCanva();
  }, [initCanva]);

  useEffect(() => {
    if (token) {
      getMeUser();
      getMeCompanies(() => {
        setCompaniesFetched(true);
      });
    }
  }, [getMeCompanies, getMeUser, token]);

  useEffect(() => {
    if (!company && token && companiesFetched) {
      if (companies.length > 0) {
        selectCompany(companies[0].company.id);
        goToSocial();
      }
      setCompaniesFetched(false);
      turnOffLoading(LAYOUT_LOADING);
    }
  }, [
    companies,
    companiesFetched,
    token,
    company,
    selectCompany,
    turnOffLoading,
    goToSocial,
  ]);

  useEffect(() => {
    if (user) {
      selectLanguage(user.locale);
      moment.locale(user.locale);
    }
  }, [user, selectLanguage]);

  useEffect(() => {
    if (token && user && company && companyPlan) {
      const appcuesData = {
        planTier: companyPlan.name,
        role: user.userRole?.admin
          ? "Admin"
          : user.userRole?.reseller
          ? "Reseller"
          : "User",
        firstName: user.firstName,
        // createdAt: 1566932390, // Unix timestamp of user signup date
        // purchasedAd: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
        // accountId: "1234", // Current user's account ID

        // additional suggestions
        email: user.email,
        companyName: company.name,
        location: company.country,
        version: "1.0",
        language: user.locale,
        // renewalDate: 1577880288 // to remind users to renew
      };

      // @ts-ignore
      window.Appcues?.identify(user.id, appcuesData);
    }
  }, [user, company, companyPlan, token]);

  useEffect(() => {
    if (token && user && company && companyPlan) {
      mixpanel.track("Select company", {
        name: company.name,
        plan: companyPlan.name,
        reseller: company.reseller?.name,
      });
    }
  }, [user, company, companyPlan, token]);

  useEffect(() => {
    setCompanyDeleted(false);
    if (companyId) {
      turnOnLoading(MAIN_LAYOUT_LOADING);
      getMeCompany(
        companyId,
        () => {
          getMeAreas(companyId);
          getCompany(companyId);
        },
        () => {
          setCompanyDeleted(true);
        }
      );
      turnOffLoading(MAIN_LAYOUT_LOADING, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, turnOnLoading, turnOffLoading]);

  useEffect(() => {
    if (companyId) {
      getUnreadNotifications(companyId);
      play();
    }
  }, [companyId, notification, getUnreadNotifications, play]);

  useEffect(() => {
    if (companyDeleted) {
      reset();
      goToMeCompany();
    }
  }, [companyDeleted, goToMeCompany, reset]);

  useEffect(() => {
    company && subscribeToChannel(user?.id!, null, company.room, company.id);
  }, [user, company, subscribeToChannel]);

  useEffect(() => {
    areas.forEach((area) => {
      subscribeToChannel(user?.id!, null, area.room, companyId!);
    });
  }, [areas, user, companyId, subscribeToChannel]);

  useEffect(() => {
    if (company && token) {
      getSystemNotifications(company.id);
    }
  }, [company, token, pathname, getSystemNotifications]);

  useEffect(() => {
    // @ts-ignore
    const $crisp = window["$crisp"];
    if (user && $crisp) {
      $crisp.push(["set", "user:email", user.email]);
      $crisp.push([
        "set",
        "user:nickname",
        `${user.firstName} ${user.lastName}`,
      ]);
      $crisp.push([
        "set",
        "user:avatar",
        `https://s3-sa-east-1.amazonaws.com/storage.wembii.com/assets/Logos/Fav.png`,
      ]);
      if (company?.name) $crisp.push(["set", "user:company", company?.name]);
    }
  }, [company, user]);

  const route = useMemo(() => {
    const noAuthRoutes = [
      routes.recoverPassword,
      routes.createAccount,
      routes.forgotPassword,
    ];

    const noAuthPathname = noAuthRoutes.some((path) => pathname.includes(path));

    if (!token) {
      if (
        noAuthPathname ||
        pathname.includes("/meeting/") ||
        pathname.includes("/webform/") ||
        pathname.includes("/emails")
      ) {
        return pathname;
      }

      return routes.login;
    }

    if (!company) {
      return routes.meCompany;
    }

    if (pathname === "/" && path !== "") {
      return path;
    }

    if (noAuthPathname || pathname === routes.login || pathname === "/") {
      return routes.social;
    }

    return `${pathname}${search}`;
  }, [token, pathname, search, company, routes, path]);

  return (
    <div className="vh-100 overflow-hidden">
      <Redirect to={route} />
      <ToastContainer hideProgressBar />
      <Switch>
        <Route path={routes.login} component={Login} />
        <Route path={routes.emails} component={UnsubscribedSuccess} />
        <Route path={routes.meeting} component={Meeting} />
        <Route path={routes.webForm} component={WebForm} />
        <Route
          path={routes.createAccountEntel}
          component={CreateAccountEntel}
        />
        <Route path={routes.createAccount} component={CreateAccount} />
        <Route path={routes.forgotPassword} component={ForgotPassword} />
        <Route path={routes.recoverPassword} component={RecoverPassword} />
        <PrivateRoute path={"/"} component={MainLayout} isLogged={!!token} />
      </Switch>
    </div>
  );
}

export default App;
