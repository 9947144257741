import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import { InputWembii } from "../../InputWembii";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../../cores/me";
import { useGlobalCore } from "../../../../cores/globals";
import { PRODUCT_EXPAND_CREATION_FORM } from "../../../../constants/modal.constant";
import { useProductCore } from "../../../../cores/products";
import { LabelWembii } from "../../LabelWembii";
import { Price } from "../../../../cores/products/interfaces/api/post-product";
import { getCurrencySymbol } from "../../../../cores/boards/constants/currencies.constant";
import InternalModal from "../../../layout/InternalModal";
import { PRODUCT_PRICE } from "../../../../constants/internalModal.constant";
import PriceForm from "../PriceForm";

interface Props {
  onSuccess?: () => void;
}

export default function CreateProductForm(props: Props) {
  const intl = useIntl();
  const { onSuccess } = props;
  const { company } = useMeCore();
  const { turnOffModal, isInternalModal, turnOffInternalModal, turnOnInternalModal } = useGlobalCore();
  const { postProduct } = useProductCore();
  
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [prices, setPrices] = useState<Price[]>([]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    
    postProduct(
      company?.id!,
      {
        name,
        code,
        description,
        prices
      }, 
      () => {
        turnOffModal(PRODUCT_EXPAND_CREATION_FORM);
        setName("");
        setCode("");
        setDescription("");
        setPrices([]);
        onSuccess?.();
      }
    )
  }

  const disabled = useCallback(
    () => !name,
    [name]
  );

  const onClickAddingPrice = useCallback(() => {
    turnOnInternalModal(PRODUCT_PRICE, true);
  }, [turnOnInternalModal]);

  const onAddingPrice = useCallback((currency, price) => {
    setPrices([
      ...prices,
      { currency, price }
    ]);
  }, [prices]);

  const onDeletingPrice = useCallback((index) => {
    const newPrices = [...prices];
    newPrices.splice(index, 1);
    setPrices(newPrices);
  }, [prices]);

  return (
    <div className="position-relative h-100">
      <InternalModal
        active={isInternalModal(PRODUCT_PRICE)}
        onClose={() => turnOffInternalModal(PRODUCT_PRICE)}
      >
        <PriceForm onAdding={onAddingPrice} />
      </InternalModal>
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12">
            <div className="d-flex flex-column h-100 justify-content-center p-4">
              <h2 className="h2 text-center mb-4">
                {intl.formatMessage({ id: "app.products.createProduct" })}
              </h2>
              <div className="mb-2 d-flex justify-content-center flex-column align-items-center">
                <FontAwesomeIcon
                  icon={["fas", "shopping-cart"]}
                  className="mr-2 mb-2"
                  size="6x"
                  color={COLOR_PRIMARY}
                />          
              </div>
              <Form className="px-2" onSubmit={onFormSubmit}>
                <InputWembii
                  label={intl.formatMessage({ id: "app.products.name" })}
                  type="text"
                  name="name"
                  id="product-name"
                  required
                  placeholder={intl.formatMessage({ id: "app.products.name" })}
                  value={name}
                  onChange={setName}
                />
                <InputWembii
                  label={intl.formatMessage({ id: "app.products.code" })}
                  type="text"
                  name="code"
                  id="product-code" 
                  placeholder={intl.formatMessage({ id: "app.products.code" })}
                  value={code}
                  onChange={setCode}
                />
                <InputWembii
                  label={intl.formatMessage({ id: "app.products.description" })}
                  type="textarea"
                  name="description"
                  id="product-description"
                  placeholder={intl.formatMessage({ id: "app.products.description" })}
                  style={{ minHeight: 150 }}
                  value={description}
                  onChange={setDescription}
                />

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <LabelWembii>
                    {intl.formatMessage({ id: "app.products.unitPrice" })}
                  </LabelWembii>

                  <Button
                    className="whiteButton shadow m-0"
                    onClick={onClickAddingPrice}
                  >
                    {intl.formatMessage({ id: "app.products.price.add" })}
                  </Button>
                </div>

                {prices.length > 0 && (
                  <Row>
                    <Col md="8">
                      <Row>
                        <Col className="font-weight-bold text-muted">
                          {intl.formatMessage({ id: "app.products.currency" })}
                        </Col>
                        <Col className="font-weight-bold text-muted">
                          {intl.formatMessage({ id: "app.products.unitPrice" })}
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4" />
                  </Row>
                )}
                {prices.map((price, index) => (
                  <Row key={index} className={cn("py-2", {
                    'bg-light': index % 2 === 0
                  })}>
                    <Col md="8">
                      <Row>
                        <Col>{getCurrencySymbol(price.currency)}</Col>
                        <Col>{price.price}</Col>
                      </Row>
                    </Col>
                    <Col md="4" className="d-flex justify-content-end">
                      <div className="text-danger mx-2 pointer" onClick={() => onDeletingPrice(index)}>
                        <FontAwesomeIcon
                          icon={["fas", "trash"]}
                          size="1x"
                          className="mr-1"
                        />
                      </div>
                    </Col>
                  </Row>
                ))}

                <Button color="primary" className="mt-3" block disabled={disabled()}>
                  {intl.formatMessage({ id: "app.products.create" })}  
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
