export const EVENT_DURATION = [
  { name: "15 mins", minutes: 15 },
  { name: "30 mins", minutes: 30 },
  { name: "45 mins", minutes: 45 },
  { name: "1 hr", minutes: 60 },
  { name: "1.5 hr", minutes: 90 },
  { name: "2 hrs", minutes: 120 },
  { name: "2.5 hrs", minutes: 150 },
  { name: "3 hrs", minutes: 180 },
  { name: "3.5 hrs", minutes: 210 },
  { name: "4 hrs", minutes: 240 },
  { name: "4.5 hrs", minutes: 270 },
  { name: "5 hrs", minutes: 300 },
  { name: "5.5 hrs", minutes: 330 },
  { name: "6 hrs", minutes: 360 },
  { name: "6.5 hrs", minutes: 390 },
  { name: "7 hrs", minutes: 420 },
  { name: "7.5 hrs", minutes: 450 },
  { name: "8 hrs", minutes: 480 },
  { name: "8.5 hrs", minutes: 510 },
  { name: "9 hrs", minutes: 540 },
  { name: "9.5 hrs", minutes: 570 },
  { name: "10 hrs", minutes: 600 },
  { name: "10.5 hrs", minutes: 630 },
  { name: "11 hrs", minutes: 660 },
  { name: "11.5 hrs", minutes: 690 },
  { name: "12 hrs", minutes: 720 },
  { name: "12.5 hrs", minutes: 750 },
  { name: "13 hrs", minutes: 780 },
  { name: "13.5 hrs", minutes: 810 },
  { name: "14 hrs", minutes: 840 },
  { name: "14.5 hrs", minutes: 870 },
  { name: "15 hrs", minutes: 900 },
  { name: "15.5 hrs", minutes: 930 },
  { name: "16 hrs", minutes: 960 },
  { name: "16.5 hrs", minutes: 990 },
  { name: "17 hrs", minutes: 1020 },
  { name: "17.5 hrs", minutes: 1050 },
  { name: "18 hrs", minutes: 1080 },
  { name: "18.5 hrs", minutes: 1110 },
  { name: "19 hrs", minutes: 1140 },
  { name: "19.5 hrs", minutes: 1170 },
  { name: "20 hrs", minutes: 1200 },
  { name: "20.5 hrs", minutes: 1230 },
  { name: "21 hrs", minutes: 1260 },
  { name: "21.5 hrs", minutes: 1290 },
  { name: "22 hrs", minutes: 1320 },
  { name: "22.5 hrs", minutes: 1350 },
  { name: "23 hrs", minutes: 1380 },
  { name: "23.5 hrs", minutes: 1420 },
]