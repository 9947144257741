export const MercadolibreOrderByOptions = [{ label: "Fecha", value: "date" }];

export enum MercadolibreOrderStatus {
  any = "any",
  confirmed = "confirmed",
  payment_required = "payment_required",
  payment_in_process = "payment_in_process",
  partially_paid = "partially_paid",
  paid = "paid",
  cancelled = "cancelled",
}

export const MercadolibreOrderStatusDic: Record<
  MercadolibreOrderStatus,
  string
> = {
  [MercadolibreOrderStatus.any]: "Todas",
  [MercadolibreOrderStatus.confirmed]: "Confirmado",
  [MercadolibreOrderStatus.payment_required]: "Pago Requerido",
  [MercadolibreOrderStatus.payment_in_process]: "Pago en Proceso",
  [MercadolibreOrderStatus.partially_paid]: "Pago Parcial",
  [MercadolibreOrderStatus.paid]: "Pagado",
  [MercadolibreOrderStatus.cancelled]: "Cancelado",
};

export const MercadolibreOrderStatusColor: Record<
  MercadolibreOrderStatus,
  string
> = {
  [MercadolibreOrderStatus.any]: "#36A2EB",
  [MercadolibreOrderStatus.paid]: "#36A2EB",
  [MercadolibreOrderStatus.partially_paid]: "#FFCE56",
  [MercadolibreOrderStatus.payment_in_process]: "#FFCE56",
  [MercadolibreOrderStatus.confirmed]: "#4BC0C0",
  [MercadolibreOrderStatus.cancelled]: "#FF6384",
  [MercadolibreOrderStatus.payment_required]: "#FFCE56",
};

export const MercadolibreStatusOptions = [
  {
    label: MercadolibreOrderStatusDic[MercadolibreOrderStatus.any],
    value: "",
  },
  {
    label: MercadolibreOrderStatusDic[MercadolibreOrderStatus.payment_required],
    value: MercadolibreOrderStatus.payment_required,
  },
  {
    label: MercadolibreOrderStatusDic[MercadolibreOrderStatus.confirmed],
    value: MercadolibreOrderStatus.confirmed,
  },
  {
    label: MercadolibreOrderStatusDic[MercadolibreOrderStatus.paid],
    value: MercadolibreOrderStatus.paid,
  },
  {
    label: MercadolibreOrderStatusDic[MercadolibreOrderStatus.cancelled],
    value: MercadolibreOrderStatus.cancelled,
  },
  {
    label: MercadolibreOrderStatusDic[MercadolibreOrderStatus.partially_paid],
    value: MercadolibreOrderStatus.partially_paid,
  },
  {
    label:
      MercadolibreOrderStatusDic[MercadolibreOrderStatus.payment_in_process],
    value: MercadolibreOrderStatus.payment_in_process,
  },
];

export const MercadolibreOrderOptions = [
  { label: "Descendente", value: "desc" },
  { label: "Ascendente", value: "asc" },
];

export const MercadolibreQuantityOptions = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
