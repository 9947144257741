import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";

export const setEventDescriptionAction = createAction(
  "[Event] Set Event Description Action",
  props<{ description: string }>()
);

export const setCalendarTimezoneAction = createAction(
  "[Event] Set Calendar Timezone Action",
  props<{ timezone: string }>()
);

export const setCalendarTitleAction = createAction(
  "[Event] Set Calendar Title Action",
  props<{ title: string }>()
);

export const setCalendarDescriptionAction = createAction(
  "[Event] Set Calendar Description Action",
  props<{ description: string }>()
);

const actions = joinActions({
  setEventDescriptionAction,
  setCalendarTimezoneAction,
  setCalendarTitleAction,
  setCalendarDescriptionAction,
});

export type EventAction = typeof actions;
