import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { INSTAGRAM_COLOR } from "../../../../constants/socialColors.constant";
import { IconProps } from "../Facebook/FacebookIcon";

export const InstagramIcon = (props: IconProps) => {
  const { active, className, size, color } = props;
  return (
    <FontAwesomeIcon
      style={{
        color: color || (active ? INSTAGRAM_COLOR : "gray"),
      }}
      icon={["fab", "instagram"]}
      className={className}
      size={size}
    />
  );
};
