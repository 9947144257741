import { original } from "immer";
import _ from "lodash";
import { EntityId } from "./entity-id.type";
import { EntityState } from "./entity-state.interface";

export const createEntityAdapter = <T>(selectIdFn: (entity: T) => EntityId) => {
  const addMany = (entitiesArray: T[], state: EntityState<T>) => {
    state.ids = entitiesArray.map(selectIdFn);
    state.entities = entitiesArray.reduce((entitiesDictionary, entity) => {
      const id = selectIdFn(entity);
      entitiesDictionary[id] = entity;
      return entitiesDictionary;
    }, {} as Record<EntityId, T>);
  };

  const updateMany = (entitiesArray: T[], state: EntityState<T>) => {
    state.ids = _.uniq([...state.ids, ...entitiesArray.map(selectIdFn)]);
    state.entities = {
      ...state.entities,
      ...entitiesArray.reduce((entitiesDictionary, entity) => {
        const id = selectIdFn(entity);
        entitiesDictionary[id] = entity;
        return entitiesDictionary;
      }, {} as Record<EntityId, T>),
    };
  };

  const setOne = (entity: T, state: EntityState<T>) => {
    const entityId = selectIdFn(entity);
    const { ids } = original(state) as EntityState<T>;
    if (!ids.find((id) => id === entityId)) {
      state.ids.push(entityId);
    }
    state.entities[entityId] = entity;
  };

  const updateOne = (entity: T, state: EntityState<T>) => {
    const id = selectIdFn(entity);
    state.entities[id] = entity;
  };

  const removeOne = (entityId: EntityId, state: EntityState<T>) => {
    const { ids } = original(state) as EntityState<T>;
    state.ids = ids.filter((id) => id !== entityId);
    delete state.entities[entityId];
  };

  const removeMany = (entityIds: EntityId[], state: EntityState<T>) => {
    const { ids } = original(state) as EntityState<T>;
    state.ids = ids.filter((id) => !entityIds.includes(id));
    entityIds.forEach((id) => {
      delete state.entities[id];
    });
  };

  const removeAll = (state: EntityState<T>) => {
    state.ids = [];
    state.entities = {};
  };

  const moveToFirstPosition = (entityId: EntityId, state: EntityState<T>) => {
    let { ids } = original(state) as EntityState<T>;
    ids = ids.filter((id) => id !== entityId);
    state.ids = [entityId, ...ids];
  };

  const moveToPosition = (entityId: EntityId, position: number,  state: EntityState<T>) => {
    let { ids } = original(state) as EntityState<T>;
    ids = ids.filter((id) => id !== entityId);
    ids.splice(position, 0, entityId);
    state.ids = ids;
  };

  return {
    addMany,
    setOne,
    updateOne,
    updateMany,
    removeOne,
    removeMany,
    removeAll,
    moveToFirstPosition,
    moveToPosition,
  };
};
