import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useBoardCore } from "../../../../cores/boards";
import { RadiobuttonWembii } from "../../RadiobuttonWembii";

export default function QuoteSignature() {
  const intl = useIntl();
  const { quote, setQuote } = useBoardCore();

  const setQuoteConfig = useCallback((field) => {
    setQuote({ ...quote, ...field });
  }, [quote, setQuote]);

  return (
    <div className="d-flex flex-column">
      <RadiobuttonWembii
        checked={!quote.signature}
        label={intl.formatMessage({ id: "app.crmBusiness.quote.noSignature" })}
        onChange={(signature) => setQuoteConfig({ signature })}
      />
      <RadiobuttonWembii
        checked={quote.signature}
        label={intl.formatMessage({ id: "app.crmBusiness.quote.signatureLines" })}
        onChange={(signature) => setQuoteConfig({ signature })}
      />
    </div>
  );
}
