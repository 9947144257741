import QueryString from "query-string";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMeCore } from "../../../../cores/me";
import { useRouterCore } from "../../../../cores/router";
import { useWooCommerceCore } from "../../../../cores/wooCommerce";

export default function WooCommercePopUp() {
  const { search } = useLocation();
  const { bind, name, url } = useWooCommerceCore();
  const { company, getMeSocialSettings } = useMeCore();
  const { goToSocial } = useRouterCore();

  useEffect(() => {
    const { success } = QueryString.parse(search);
    if (success) bind(company!.id, url, name, () => {
      getMeSocialSettings(company?.id!);
    });
    goToSocial();
  }, [search, company, bind, goToSocial, url, name, getMeSocialSettings]);

  return <div></div>;
}
