import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useMeCore } from "../../../cores/me";
import { InputWembii } from "../InputWembii";
import { useContactCore } from "../../../cores/contacts";
import { CONTACT_STATES } from "../../../constants/internalModal.constant";
import { useGlobalCore } from "../../../cores/globals";
import { ContactState } from "../../../cores/contacts/interfaces/api/get-contact-states";

interface Props {
  state?: ContactState;
  onUpdate: () => void;
}

export default function StateForm({ state, onUpdate }: Props) { 
  const intl = useIntl();
  const { company } = useMeCore();
  const { turnOffInternalModal } = useGlobalCore();
  const { postContactState, putContactState, deleteContactState } = useContactCore();

  const [name, setName] = useState("");

  useEffect(() => {
    if (state) {
      setName(state.name);
    }
  }, [state]);

  const cleanForm = useCallback(() => {
    setName("");
    turnOffInternalModal(CONTACT_STATES);
  }, [turnOffInternalModal]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!state) {
      postContactState(
        company?.id!,
        name,
        cleanForm
      );
    } else {
      putContactState(
        company?.id!,
        state.id,
        name,
        () => {
          cleanForm();
          onUpdate();
        }
      );
    }
  };

  const onDelete = useCallback(() => {
    deleteContactState(
      company?.id!,
      state?.id!,
      cleanForm
    );
  }, [deleteContactState, cleanForm, company, state]);

  const disabled = useCallback(
    () => !name,
    [name]
  );

  return (
    <Form className="p-2" onSubmit={onSubmit}>
      <Row>
        <Col>
          <InputWembii
            label={intl.formatMessage({ id: "app.contacts.origins.name" })}
            type="text"
            name="name"
            id="tag-name"
            className="pr-1"
            required
            placeholder={intl.formatMessage({ id: "app.contacts.origins.name" })}
            value={name}
            onChange={setName}
          />
        </Col>
      </Row>

      {state && (
        <Button color="danger" className="mr-1" onClick={onDelete}>
          {intl.formatMessage({ id: "app.contacts.origins.delete" })}  
        </Button>
      )}
      <Button color="primary" disabled={disabled()} className="ml-1">
        {state 
          ? intl.formatMessage({ id: "app.contacts.origins.update" })
          : intl.formatMessage({ id: "app.contacts.origins.create" })
        }  
      </Button>
    </Form>
  );
}