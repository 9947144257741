import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";

export const setPathAction = createAction(
  "[Config] Set Path Loading",
  props<{ path: string }>()
);

const actions = joinActions({
  setPathAction,
});

export type ConfigActions = typeof actions;