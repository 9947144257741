import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import React from "react"
import { FACEBOOK_ADS_COLOR } from "../../../../constants/socialColors.constant"
import { IconProps } from "../Facebook/FacebookIcon"

export const sizeReference: Record<SizeProp, number> = {
  xs: 30,
  xl: 40,
  lg: 150,
  sm: 45,
  "1x": 20,
  "2x": 30,
  "3x": 45,
  "4x": 60,
  "5x": 75,
  "6x": 90,
  "7x": 105,
  "8x": 120,
  "9x": 135,
  "10x": 150,
  "2xl": 40,
  "2xs": 35 
}

export const FacebookAdsIcon = (props: IconProps) => {
  const { active, className, size = "1x", color } = props
  return (
    <svg
      id="Lag_1"
      viewBox="0 0 204 204"
      style={{width: sizeReference[size]}}
      className={className}
    >
      <g>
        <linearGradient gradientUnits="userSpaceOnUse" id="SVGID_1_" x1="102.0005" x2="102.0005" y1="204.0009" y2="-2.670276e-09">
          <stop offset="0" style={{ stopColor: color || (active ? FACEBOOK_ADS_COLOR : "gray") }} />
          <stop offset="1" style={{ stopColor: color || (active ? "#4D78BF" : "gray") }} />
        </linearGradient>
        <path style={{fill: 'url(#SVGID_1_)'}}
              d="M161.5,204h-119C19.1,204,0,184.9,0,161.5v-119C0,19.1,19.1,0,42.5,0h119C184.9,0,204,19.1,204,42.5v119   C204,184.9,184.9,204,161.5,204z"
        />
        <g>
          <path style={{fill: "#FFFFFF"}}
                d="M48.3,116.5c0,0,14.2,34.7,16.7,36.5s10.5,2,13.8,0s6-3.2,5-6.2s-4.4-18.6-3.8-18.4s4.4,0.8,5.9,0.5    s2.1-1.5,1-4.2s-3.8-7.9-3.8-7.9s24.2-0.5,41.5,6.6c17.3,7.1,23.6,13.4,24.1,18.6s4.5,4.1,6,4.1s5.5,0.8,5.5-3.8s0-92.6,0-95.1    s-0.6-3.9-3-3.9s-3.8,0-5.2,0s-3.2,0.6-3.2,3.2c0,0-0.9,12.6-19.9,19S90.1,74,83.1,74s-41-1.1-41-1.1L32,84.6V105l8.4,11.4    L48.3,116.5z"
          />
          <path style={{fill: color || (active ? "#829EC6" : "#6f747d")}}
                d="M83,74c-7.2,0-40.9-1.1-40.9-1.1L32,84.6V105l8.4,11.4l8,0.2c0,0,0.1,0.1,0.1,0.3H83V74z" />
        </g>
      </g>
    </svg>
  )
}
