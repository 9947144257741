import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Container } from "reactstrap";
import { CUSTOM_FIELD } from "../../../constants/internalModal.constant";
import { useContactCore } from "../../../cores/contacts";
import { ContactHeaders } from "../../../cores/contacts/interfaces/ContactHeader";
import { useGlobalCore } from "../../../cores/globals";
import { InputWembii } from "../InputWembii";
import FieldForm from "./FieldForm";

interface Props {
  data: string[][];
}

const CREATE_CUSTOM_FIELD = "CREATE_CUSTOM_FIELD";

export const ContactTable = (props: Props) => {
  const intl = useIntl();
  const { data } = props;
  const { headers, customFields, setHeaders } = useContactCore();
  const { turnOnInternalModal, turnOffInternalModal } = useGlobalCore();

  const options = useMemo(() => ([
    {
      key: ContactHeaders.name.key,
      label: intl.formatMessage({ id: ContactHeaders.name.label }),
      value: headers.name,
    },
    {
      key: ContactHeaders.surname.key,
      label: intl.formatMessage({ id: ContactHeaders.surname.label }),
      value: headers.surname,
    },
    {
      key: ContactHeaders.email.key,
      label: intl.formatMessage({ id: ContactHeaders.email.label }),
      value: headers.email,
    },
    {
      key: ContactHeaders.phone.key,
      label: intl.formatMessage({ id: ContactHeaders.phone.label }),
      value: headers.phone,
    },
    {
      key: ContactHeaders.enterprise.key,
      label: intl.formatMessage({ id: ContactHeaders.enterprise.label }),
      value: headers.enterprise,
    },
    ...customFields.map((field) => ({
      key: `${field.id}`,
      label: field.name,
      value: headers[`${field.id}`],
    }))
  ]), [headers, customFields, intl]);

  const getSelectedKey = useCallback((index: number) => {
    const option = options.find((option) => option.value === index);
    return (option) ? option.key : "";
  }, [options]);

  const handlerHeaderChange = useCallback(
    (key: string, index: number) => {
      if (key === CREATE_CUSTOM_FIELD) {
        turnOnInternalModal(
          CUSTOM_FIELD,
          <FieldForm onCreation={async (id) => {
            const newHeaders = { ...headers }
            const option = options.find((option) => option.value === index);
      
            if (option)
              newHeaders[option.key] = -1;
      
            newHeaders[id] = index;
            setHeaders(newHeaders);
            turnOffInternalModal(CUSTOM_FIELD);
          }}  />
        );
      } else {
        const newHeaders = { ...headers }
        const option = options.find((option) => option.value === index);
  
        if (option)
          newHeaders[option.key] = -1;
  
        newHeaders[key] = index;
        setHeaders(newHeaders);
      }
    }, [headers, options, setHeaders, turnOnInternalModal, turnOffInternalModal]
  );

  return (
    <Container className="w-100" fluid>
      <div className="contactsTable overflow-auto">
        <div className="d-flex">
          {data[0] && data[0].map((_v, index) => (
            <div className="border p-3" style={{ minWidth: '300px' }} key={`header${index}`}>
              <InputWembii
                type="select"
                mb={false}
                value={getSelectedKey(index)}
                onChange={(v) => handlerHeaderChange(v, index)}
              >
                <option value="">
                  {intl.formatMessage({ id: "app.select.undefined" })}
                </option>
                {options.map(({ label, key }, index) => (
                  <option key={index} value={key}>
                    {label}
                  </option>
                ))}
                <option value={CREATE_CUSTOM_FIELD}>
                  + {intl.formatMessage({ id: "app.contacts.import.createCustomField" })}
                </option>
              </InputWembii>
            </div>
          ))}
        </div>
        {data.map((row, indexRow) => (
          <div
            className="d-flex"
            key={`row${indexRow}`}
          >
            {row.map((value, indexValue) => (
              <div
                className="p-3"
                style={{ minWidth: '300px' }}
                key={`cell${indexValue}`}
              >
                { value }
              </div>
            ))}
          </div>
        ))}
      </div>
    </Container>
  );
};
