import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Errors } from "../../constants/errors";
import {
  MAILCHIMP_AUDIENCE_LOADING,
  MAILCHIMP_GET_LOADING,
  MAILCHIMP_SYNC_LOADING,
} from "../../constants/loading.constant";
import { useGlobalCore } from "../globals";
import { mailchimpApiService } from "./services/api";
import { useIntl } from "react-intl";
import {
  getMailchimpAudiencesFailureAction,
  getMailchimpAudiencesInitAction,
  getMailchimpAudiencesSuccessAction,
  getMailchimpFailureAction,
  getMailchimpInitAction,
  getMailchimpSuccessAction,
  postBindMailchimpFailureAction,
  postBindMailchimpInitAction,
  postBindMailchimpSuccessAction,
  postMailchimpAudienceFailureAction,
  postMailchimpAudienceInitAction,
  postMailchimpAudienceSuccessAction,
  postMailchimpSyncContactsFailureAction,
  postMailchimpSyncContactsInitAction,
  postMailchimpSyncContactsSuccessAction,
  postUnbindMailchimpFailureAction,
  postUnbindMailchimpInitAction,
  postUnbindMailchimpSuccessAction
} from "./store/api-actions";
import { audiencesSelector, errorCodeSelector, errorMessageSelector, settingSelector } from "./store/selector";

export function useMailchimpCore() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);
  const audiences = useSelector(audiencesSelector);
  const errorCode = useSelector(errorCodeSelector);
  const errorMessage = useSelector(errorMessageSelector);
  const { turnOnLoading, turnOffLoading } = useGlobalCore();

  const unbind = useCallback(
    async (companyId: string, onSuccess?: () => void) => {
      try {
        dispatch(postUnbindMailchimpInitAction());
        await mailchimpApiService.unbind(companyId);
        dispatch(postUnbindMailchimpSuccessAction());
        onSuccess?.();
      } catch (error: any) {
        console.error(error);
        dispatch(postUnbindMailchimpFailureAction({ error }));
      }
    },
    [dispatch]
  );

  const bind = useCallback(
    async (
      companyId: string,
      token: string,
      prefix: string,
      audienceId: string,
      onSuccess?: () => void
    ) => {
      try {
        dispatch(postBindMailchimpInitAction());
        turnOnLoading(MAILCHIMP_SYNC_LOADING);
        const response = await mailchimpApiService.bind(companyId, token, prefix, audienceId);
        dispatch(postBindMailchimpSuccessAction({ settings: response }));
        turnOffLoading(MAILCHIMP_SYNC_LOADING, 500);
        toast.success(intl.formatMessage({ id: "app.toast.sucess22" }));
        onSuccess?.();
      } catch (error: any) {
        turnOffLoading(MAILCHIMP_SYNC_LOADING);
        toast.error(intl.formatMessage({ id: "app.toast.error41" }));
        console.error(error);
        dispatch(postBindMailchimpFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const get = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getMailchimpInitAction());
        turnOnLoading(MAILCHIMP_GET_LOADING);
        const settings = await mailchimpApiService.get(companyId);
        dispatch(getMailchimpSuccessAction({ settings }));
        turnOffLoading(MAILCHIMP_GET_LOADING, 1500);
      } catch (error: any) {
        turnOffLoading(MAILCHIMP_GET_LOADING);
        console.error(error);
        dispatch(getMailchimpFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const getAudiences = useCallback(
    async (companyId: string, code: string) => {
      try {
        dispatch(getMailchimpAudiencesInitAction());
        turnOnLoading(MAILCHIMP_SYNC_LOADING);
        const { audiences, token, prefix } = await mailchimpApiService.getAudiences(companyId, code);
        dispatch(getMailchimpAudiencesSuccessAction({ audiences }));
        turnOffLoading(MAILCHIMP_SYNC_LOADING, 1500);
        return { token, prefix }
      } catch (error: any) {
        turnOffLoading(MAILCHIMP_SYNC_LOADING);
        console.error(error);
        dispatch(getMailchimpAudiencesFailureAction({ error }));
      }
    },
    [dispatch, turnOffLoading, turnOnLoading]
  );

  const postAudience = useCallback(
    async (
      companyId: string,
      name: string,
      token: string,
      prefix: string
    ) => {
      try {
        dispatch(postMailchimpAudienceInitAction());
        turnOnLoading(MAILCHIMP_AUDIENCE_LOADING);
        const { audience } = await mailchimpApiService.postAudience(companyId, name, token, prefix);
        dispatch(postMailchimpAudienceSuccessAction({ audience }));
        turnOffLoading(MAILCHIMP_AUDIENCE_LOADING, 1500);
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string =
            JSON.parse(error.request.response).message || "";
          switch (code) {
            case Errors.NewAudienceNotAllowed:
              dispatch(
                postMailchimpAudienceFailureAction({
                  errorCode: Errors.NewAudienceNotAllowed,
                  errorMessage: message,
                })
              );
              break;
            default:
              toast.error(intl.formatMessage({ id: "app.toast.error42" }));
          }
        } else {
          toast.error(intl.formatMessage({ id: "app.toast.error42" }));
          console.error(error);
        }

        turnOffLoading(MAILCHIMP_AUDIENCE_LOADING);
      }
    },
    [dispatch, turnOffLoading, turnOnLoading, intl]
  );

  const syncContacts = useCallback(
    async (companyId: string) => {
      try {
        dispatch(postMailchimpSyncContactsInitAction());
        await mailchimpApiService.syncContacts(companyId);
        dispatch(postMailchimpSyncContactsSuccessAction());
        toast.success("Estamos sincronizando los contactos, en unos instantes te avisaremos del progreso")
      } catch (error: any) {
        if (error.request.status === 403) {
          const code: number = JSON.parse(error.request.response).code || 0;
          const message: string =
            JSON.parse(error.request.response).message || "";
          switch (code) {
            case Errors.BatchRunning:
              dispatch(
                postMailchimpSyncContactsFailureAction({
                  errorCode: Errors.BatchRunning,
                  errorMessage: message,
                })
              );
              toast.info("Ya existe un proceso ejecutandose para sincronizar los contactos. Espere que termine para iniciar uno nuevo.");
              break;
            default:
              toast.error("Error sincronizando contactos");
          }
        } else {
          toast.error("Error sincronizando contactos");
          console.error(error);
        }
      }
    },
    [dispatch]
  );

  const login = useCallback(
    async (companyId: string) => {
      try {
        turnOnLoading(MAILCHIMP_SYNC_LOADING);
        const response = await mailchimpApiService.login(companyId);
        return response.url;
      } catch (error: any) {
        turnOffLoading(MAILCHIMP_SYNC_LOADING);
        console.error(error);
        toast.error(intl.formatMessage({ id: "app.toast.error43" }));
      }
    },
    [turnOffLoading, turnOnLoading, intl]
  );

  return {
    settings,
    audiences,
    errorCode,
    errorMessage,
    unbind,
    login,
    bind,
    get,
    getAudiences,
    postAudience,
    syncContacts,
  };
}
