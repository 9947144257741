import { produce } from "immer";
import { GlobalActions, resetAction } from "../../globals/store/actions";
import { Audience } from "../interface/api/get-mailchimp-audiences";
import { MailchimpSetting } from "../interface/api/post-mailchimp-bind";
import {
  getMailchimpAudiencesFailureAction,
  getMailchimpAudiencesInitAction,
  getMailchimpAudiencesSuccessAction,
  getMailchimpFailureAction,
  getMailchimpInitAction,
  getMailchimpSuccessAction,
  MailchimpApiAction,
  postBindMailchimpFailureAction,
  postBindMailchimpInitAction,
  postBindMailchimpSuccessAction,
  postMailchimpAudienceFailureAction,
  postMailchimpAudienceInitAction,
  postMailchimpAudienceSuccessAction,
  postMailchimpSyncContactsFailureAction,
  postMailchimpSyncContactsInitAction,
  postMailchimpSyncContactsSuccessAction,
  postUnbindMailchimpFailureAction,
  postUnbindMailchimpInitAction,
  postUnbindMailchimpSuccessAction
} from "./api-actions";

export interface MailchimpState {
  settings: MailchimpSetting | null;
  audiences: Audience[];
  errorCode: number | null;
  errorMessage: string | null;
}

const initialState: MailchimpState = {
  settings: null,
  audiences: [],
  errorCode: null,
  errorMessage: null,
};

export default produce(
  (
    draft: MailchimpState = initialState,
    action: MailchimpApiAction | GlobalActions
  ) => {
    switch (action.type) {
      case postBindMailchimpSuccessAction.type:
      case getMailchimpSuccessAction.type:
        draft.settings = action.settings;
        draft.errorCode = null;
        draft.errorMessage = null;
        return draft;
      case postUnbindMailchimpFailureAction.type:
      case getMailchimpFailureAction.type:
      case postUnbindMailchimpSuccessAction.type:
      case postBindMailchimpFailureAction.type:
        draft.settings = null;
        return draft;
      case getMailchimpAudiencesSuccessAction.type:
        draft.audiences = action.audiences;
        return draft;
      case postMailchimpAudienceSuccessAction.type:
        draft.audiences = [...draft.audiences, action.audience];
        return draft;
      case postMailchimpAudienceFailureAction.type:
        draft.errorCode = action.errorCode;
        draft.errorMessage = action.errorMessage;
        return draft;
      case postMailchimpSyncContactsFailureAction.type:
        draft.errorCode = action.errorCode;
        draft.errorMessage = action.errorMessage;
        return draft; 
      case resetAction.type:
        return initialState;
      case postBindMailchimpInitAction.type:
      case postUnbindMailchimpInitAction.type:
      case getMailchimpInitAction.type:
      case getMailchimpAudiencesInitAction.type:
      case getMailchimpAudiencesFailureAction.type:
      case postMailchimpAudienceInitAction.type:
      case postMailchimpSyncContactsInitAction.type:
      case postMailchimpSyncContactsSuccessAction.type:
      default:
        return draft;
    }
  }
);
