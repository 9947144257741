import { User } from "../../session/interfaces/models/user";
import { DeleteMeSignResponse } from "../interfaces/api/delete-meSign";
import { GetMeAreasResponse } from "../interfaces/api/get-meArea";
import {
  GetMeCompaniesResponse,
  GetMeCompanyResponse,
  MeCompanyPayload,
} from "../interfaces/api/get-meCompany";
import { GetMeSignResponse, Sign } from "../interfaces/api/get-meSign";
import {
  GetMeSocialSettingsResponse,
  SocialSettings,
} from "../interfaces/api/get-meSocialSettings";
import { GetMeUserResponse } from "../interfaces/api/get-meUser";
import { GetMeUserSettingsResponse, UserSettings } from "../interfaces/api/get-meUserSettings";
import { PostMeSignResponse } from "../interfaces/api/post-meSign";
import { PutMeSignResponse } from "../interfaces/api/put-meSign";

export const userMapper = {
  mapResponseToUser(response: GetMeUserResponse): User {
    return response?.payload?.user;
  },

  mapResponseToCompanies(response: GetMeCompaniesResponse): MeCompanyPayload[] {
    return response?.payload?.companies.map((company) => company);
  },

  mapResponseToCompany(response: GetMeCompanyResponse): MeCompanyPayload {
    const { company, role, plan } = response?.payload;
    return { company, role, plan };
  },

  mapResponseToSocial(response: GetMeSocialSettingsResponse): SocialSettings {
    return response.payload.socialSettings;
  },

  mapResponseToUserSocial(response: GetMeUserSettingsResponse): UserSettings {
    return response.payload.socialSettings;
  },

  mapResponseToAreas(response: GetMeAreasResponse): any[] {
    return response.payload.areas;
  },

  mapResponseToSigns(response: GetMeSignResponse): Sign[] {
    return response.payload.signs;
  },

  mapResponseToSign(response: PostMeSignResponse | PutMeSignResponse): Sign {
    return response?.payload?.sign;
  },

  mapResponseToId(response: DeleteMeSignResponse) {
    return response?.payload?.id;
  },
};
