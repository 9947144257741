import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const webChatRoot = (state: State) => state.webChat;


export const chatSelector = createSelector(webChatRoot, (me) => me.chat);
export const avatarSelector = createSelector(webChatRoot, (me) => me.avatar);
export const conversationsSelector = createSelector(webChatRoot, (me) => {
  return {
    active: me.active.ids.map((id) => me.active.entities[id]),
    archived: me.archived.ids.map((id) => me.archived.entities[id]),
    inTrash: me.inTrash.ids.map((id) => me.inTrash.entities[id]),
  };
});
export const selectedSelector = createSelector(
  webChatRoot,
  (me) => me.selected
);
export const selectedAttachmentsSelector = createSelector(
  webChatRoot,
  (me) => me.attachments
);
export const messagesSelector = createSelector(webChatRoot, (me) =>
  me.messages.ids.map((id) => me.messages.entities[id])
);
