import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MentionsInput, Mention } from "react-mentions";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Form } from "reactstrap";
import { useIntl } from "react-intl";
import { useCompanyCore } from "../../../../cores/company";
import Avatar from "../../Avatar";
import { getFirstLetters } from "../../../utils/stringUtils";
import {
  CardStatusOptions,
  CommentType,
} from "../../../../cores/boards/constants/comment-type";
import { InputWembii } from "../../InputWembii";
import { useConfigCore } from "../../../../cores/config";
import { COLOR_PRIMARY } from "../../../../constants/theme";
import CommentTypes from "../../config/CommentTypes";
import { COMMENT_TYPES_FORM } from "../../../../constants/modal.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useBoardCore } from "../../../../cores/boards";

interface Props {
  onSubmit: (arg0: string, arg1: number, arg2: string[]) => void;
  onClose?: () => void;
  comment?: string;
  type?: number;
  board: string;
}

export default function CommentInput(props: Props) {
  const intl = useIntl();
  const {
    onSubmit,
    onClose,
    comment = "",
    type = CommentType.Comment,
    board,
  } = props;
  const { users, company } = useCompanyCore();
  const { turnOnModal } = useGlobalCore();
  const { getCard, card } = useBoardCore();
  const { commentTypes, getCommentTypes } = useConfigCore();
  const [newComment, setNewComment] = useState(comment);
  const [newType, setNewType] = useState(type);
  const [addComment, setAddComment] = useState(false || !!comment);
  const [usersTagged, setUsersTagged] = useState<string[]>([]);

  const toggleAddComment = useCallback(() => {
    setAddComment(true);
  }, [setAddComment]);

  const closeComment = useCallback(() => {
    onClose?.();
    setNewComment("");
    setNewType(CommentType.Comment);
    setAddComment(!addComment);
  }, [addComment, onClose]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    const newTags = usersTagged.filter((tagged) => {
      const user = users.find((user) => user.id === tagged);

      return !newComment.includes(
        `@@@B[${user?.firstName} ${user?.lastName})@M@(${user?.id})E@@@`
      );
    });

    onSubmit(newComment, newType, newTags);
    setNewComment("");
    setNewType(CommentType.Comment);
    setAddComment(false);
  };

  const handleChange = useCallback((text) => {
    setNewComment(text.target.value);
    setAddComment(true);
  }, []);

  const onTagUser = useCallback(
    (user) => {
      if (!usersTagged.includes(user)) {
        setUsersTagged([...usersTagged, user]);
      }
    },
    [usersTagged, setUsersTagged]
  );

  const userList = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        display: `${user.firstName} ${user.lastName}`,
      })),
    [users]
  );

  const onOpenCommentTypes = useCallback(() => {
    getCommentTypes(company?.id!);
    turnOnModal(
      COMMENT_TYPES_FORM,
      <CommentTypes
        onUpdate={() => {
          getCard(company?.id!, board, card?.list!, card?.id!, false);
        }}
      />
    );
  }, [
    getCommentTypes,
    turnOnModal,
    board,
    card?.id,
    card?.list,
    company?.id,
    getCard,
  ]);

  const typesValues = useMemo(
    () => [
      ...CardStatusOptions.map((status) => ({
        value: status.value,
        label: intl.formatMessage({ id: status.label }),
      })),
      ...commentTypes.map((type) => ({ value: type.id, label: type.name })),
    ],
    [commentTypes, intl]
  );

  return (
    <Form onSubmit={onFormSubmit}>
      <MentionsInput
        id="comment"
        value={newComment}
        style={{ minHeight: addComment ? 80 : 45 }}
        className="mention-input"
        onChange={handleChange}
        onClick={toggleAddComment}
        placeholder={intl.formatMessage({ id: "app.activity.addComent" })}
      >
        <Mention
          trigger="@"
          markup="@@@B[__display__]@M@(__id__)E@@@"
          appendSpaceOnAdd={true}
          data={userList}
          onAdd={onTagUser}
          renderSuggestion={(
            suggestion,
            _search,
            highlightedDisplay,
            _index,
            focused
          ) => (
            <div
              className={cn("d-flex align-items-center", { focused: focused })}
            >
              <Avatar
                className="text-dark-50 mr-2"
                label={`${getFirstLetters(suggestion.display || "")}`}
              />
              {highlightedDisplay}
            </div>
          )}
        />
      </MentionsInput>
      {addComment && (
        <div className="d-flex align-items-center mt-2">
          <Button
            color="primary"
            className="rounded"
            size="sm"
            disabled={!newComment}
          >
            {intl.formatMessage({ id: "app.chatConfigArea.save" })}
          </Button>
          <InputWembii
            type="select"
            className="mx-1"
            onChange={setNewType}
            value={newType}
            mb={false}
          >
            {typesValues.map(({ label, value }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </InputWembii>
          <FontAwesomeIcon
            icon={["fas", "plus"]}
            size="2x"
            className="m-2 pointer"
            color={COLOR_PRIMARY}
            onClick={() => onOpenCommentTypes()}
          />
          <FontAwesomeIcon
            icon={["fas", "times"]}
            size="1x"
            className="pointer ml-3"
            color="gray"
            onClick={closeComment}
          />
        </div>
      )}
    </Form>
  );
}
