import { GetTwitterLoginResponse } from "../interface/api/get-twitter-login";
import { GetTwitterProfilesPayload, GetTwitterProfilesResponse } from "../interface/api/get-twitter-profiles";
import {
  PostTwitterBindResponse,
  TwitterSetting,
} from "../interface/api/post-twitter-bind";

export const twitterSettingMapper = {
  mapResponseToTwitterSetting(
    response: PostTwitterBindResponse
  ): TwitterSetting {
    const { twitterSetting } = response?.payload;
    return twitterSetting;
  },

  mapResponseToTwitterLoginUrl(
    response: GetTwitterLoginResponse
  ): { url: string } {
    const { url } = response?.payload;
    return { url };
  },

  mapResponseToTwitterProfiles(
    response: GetTwitterProfilesResponse
  ): GetTwitterProfilesPayload {
    const { profiles } = response?.payload;
    return { profiles };
  },
};
