import cn from "classnames";
import React, { CSSProperties, ReactNode } from "react";

interface Props {
  className?: string;
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  shadow?: Boolean;
  roundedOff?: boolean;
  rounded?: string;
  borderColor?: string;
  backgroundColor?: string;
  style?: CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

export const PanelWembii = (props: Props) => {
  const {
    children,
    className,
    borderColor,
    backgroundColor,
    shadow,
    header,
    footer,
    style,
    onClick,
    onMouseEnter,
    onMouseLeave,
    roundedOff,
    rounded,
  } = props;

  return (
    <div
      className={cn(["d-flex flex-column", className], {
        shadow: shadow,
      })}
      style={{
        borderRadius: roundedOff ? "0px" : rounded ? rounded : "30px",
        border: borderColor ? `5px solid ${borderColor}` : "none",
        backgroundColor: backgroundColor ? backgroundColor : "white",
        ...style,
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {header && header}
      {children}
      {footer && footer}
    </div>
  );
};
