import {
  Area,
  ChatPayload,
  GetChatResponse,
} from "../interfaces/api/get-web-chat";
import {
  GetConversationsResponse,
} from "../interfaces/api/get-conversations";
import {
  GetMessagesResponse,
  WebMessage,
} from "../interfaces/api/get-messages";
import { PostAreaResponse } from "../interfaces/api/post-area";
import {
  PutChatResponse,
  ResponsePayload,
} from "../interfaces/api/put-web-chat";
import {
  ConversationStatusRequest,
  UpdateConversationStatusResponse,
} from "../interfaces/api/update-conversations";

export const chatMapper = {
  mapResponseToChat(response: GetChatResponse): ChatPayload {
    return { chat: response.payload.chat, areas: response.payload.areas };
  },

  mapResponseToChatConfig(response: PutChatResponse): ResponsePayload {
    return { chat: response.payload.chat };
  },
  mapResponseToConversations(response: GetConversationsResponse) {
    return {
      active: response.payload.active,
      archived: response.payload.archived,
      inTrash: response.payload.inTrash,
    };
  },
  mapResponseToConversationsStatus(
    response: UpdateConversationStatusResponse
  ): { status: ConversationStatusRequest } {
    return { status: response.payload.conversationStatus };
  },
  mapResponseToMessages(response: GetMessagesResponse): {
    messages: WebMessage[];
  } {
    return { messages: response.payload.messages };
  },
  mapResponseToArea(response: PostAreaResponse): { area: Area } {
    return { area: response.payload.area };
  },
};
