import { WebMessage } from "../../conversations/interfaces/api/get-messages";
import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";

export const subscribeToChannelAction = createAction(
  "[Socket] Subscribe to channel",
  props<{
    userId: string;
    conversationId: string | null;
    channel: string;
    companyId: string;
  }>()
);

export const leaveFromChannelAction = createAction(
  "[Socket] Leave from channel",
  props<{ channel: string }>()
);

export const sendMessageToChannelAction = createAction(
  "[Socket] Send Message to channel",
  props<{ channel: string; message: WebMessage }>()
);

const actions = joinActions({
  subscribeToChannelAction,
  leaveFromChannelAction,
  sendMessageToChannelAction,
});

export type SocketSocketAction = typeof actions;
