import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const socialChatRoot = (state: State) => state.socialChat;

export const instagramConversationsSelector = createSelector(
  socialChatRoot,
  (me) => {
    return {
      active: me.instagram.active.ids.map(
        (id) => me.instagram.active.entities[id]
      ),
      archived: me.instagram.archived.ids.map(
        (id) => me.instagram.archived.entities[id]
      ),
      inTrash: me.instagram.inTrash.ids.map(
        (id) => me.instagram.inTrash.entities[id]
      ),
    };
  }
);
export const dateFilterSelector = createSelector(
  socialChatRoot,
  (me) => me.dateFilter
);

export const instagramSelectedSelector = createSelector(
  socialChatRoot,
  (me) => me.instagram.selected
);
export const instagramMessagesSelector = createSelector(socialChatRoot, (me) =>
  me.instagram.messages.ids.map((id) => me.instagram.messages.entities[id])
);

export const facebookConversationsSelector = createSelector(
  socialChatRoot,
  (me) => {
    return {
      active: me.facebook.active.ids.map(
        (id) => me.facebook.active.entities[id]
      ),
      archived: me.facebook.archived.ids.map(
        (id) => me.facebook.archived.entities[id]
      ),
      inTrash: me.facebook.inTrash.ids.map(
        (id) => me.facebook.inTrash.entities[id]
      ),
    };
  }
);
export const facebookSelectedSelector = createSelector(
  socialChatRoot,
  (me) => me.facebook.selected
);
export const facebookMessagesSelector = createSelector(socialChatRoot, (me) =>
  me.facebook.messages.ids.map((id) => me.facebook.messages.entities[id])
);

export const whatsappConversationsSelector = createSelector(
  socialChatRoot,
  (me) => {
    return {
      active: me.whatsapp.active.ids.map(
        (id) => me.whatsapp.active.entities[id]
      ),
      archived: me.whatsapp.archived.ids.map(
        (id) => me.whatsapp.archived.entities[id]
      ),
      inTrash: me.whatsapp.inTrash.ids.map(
        (id) => me.whatsapp.inTrash.entities[id]
      ),
    };
  }
);
export const whatsappSelectedSelector = createSelector(
  socialChatRoot,
  (me) => me.whatsapp.selected
);

export const whatsappMessagesSelector = createSelector(socialChatRoot, (me) =>
  me.whatsapp.messages.ids.map((id) => me.whatsapp.messages.entities[id])
);
