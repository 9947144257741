import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import cn from "classnames";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import { useGlobalCore } from "../../../cores/globals";
import { Icon } from "../companies/icons/Icon";

interface Props {
  onClick?: () => void;
  iconName?: IconName;
  iconPrefix?: IconPrefix;
  icon?: ReactNode;
  label: string;
  disabled?: boolean;
  disabledMessage?: string;
  activeUrl?: string;
}

export const NavItem2 = (props: Props) => {
  const {
    onClick,
    iconName,
    iconPrefix,
    label,
    icon,
    disabled,
    activeUrl,
    disabledMessage,
  } = props;
  const { pathname } = useLocation();
  const { collapseSideBar } = useGlobalCore();

  return (
    <>
      <NavItem
        id={label.replace(/\s/g, "")}
        className={cn("pointer mb-2 sidebar-item", {
          "active-item": pathname === activeUrl,
        })}
      >
        <NavLink
          onClick={onClick}
          disabled={disabled}
          className={cn({
            "text-black-50": disabled,
            "text-center": collapseSideBar
          })}
        >
          {!icon && iconName && (
            <Icon
              className={cn("item-icon", { "text-black-50": disabled })}
              name={iconName}
              prefix={iconPrefix}
            />
          )}
          {icon != null && icon}
          {!collapseSideBar && <span className="ml-3 item-label">{label}</span>}
        </NavLink>
      </NavItem>
      {disabledMessage && disabled && (
        <UncontrolledTooltip
          placement="right"
          target={label.replace(/\s/g, "")}
        >
          {disabledMessage}
        </UncontrolledTooltip>
      )}
    </>
  );
};
