import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const taskRoot = (state: State) => state.tasks;

export const tasksSelector = createSelector(taskRoot, (me) =>
  me.tasks.ids.map((id) => me.tasks.entities[id])
);
export const tasksExportSelector = createSelector(taskRoot, (me) =>
  me.tasksExport.ids.map((id) => me.tasksExport.entities[id])
);
export const contactTasksSelector = createSelector(taskRoot, (me) =>
  me.contactTasks.ids.map((id) => me.contactTasks.entities[id])
);

export const totalTasksSelector = createSelector(
  taskRoot,
  ({ total }) => total
);

export const taskSelector = createSelector(taskRoot, (me) => me.task);

export const taskFiltersSelector = createSelector(
  taskRoot,
  (me) => me.taskFilters
);
