import { toast } from "react-toastify";
import currencyFormatter from "currency-formatter";

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getBase64 = (file: File | Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const gcd = (a: number, b: number): number => {
  return b === 0 ? a : gcd(b, a % b);
};
export const getAspectRatio = (width: number, height: number): string => {
  const ratio = gcd(width, height);
  return `${width / ratio}:${height / ratio}`;
};

export enum UploadFileType {
  JPEG = "image/jpeg",
  PNG = "image/png",
  JPG = "image/jpg",
  MP4 = "video/mp4",
  MOV = "video/quicktime",
  PDF = "application/pdf",
  CSV = "text/csv",
  EXCEL = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}

export const beforeUploadFile = (
  { size, type }: File | Blob,
  sizeLimitMB: number = 5,
  allowedTypes: UploadFileType[]
): boolean => {
  const isTypeAllowed: boolean = allowedTypes.length > 0
    ? allowedTypes.find((at) => at === type) !== undefined
    : true;
  const isSizeAllowed: boolean = size / 1024 <= 1024 * sizeLimitMB;
  if (!isSizeAllowed)
    toast.error(
      `Tamaño de archivo no permitido, el tamaño tiene que ser menor a ${
        sizeLimitMB
      } Megabytes`
    );
  if (!isTypeAllowed)
    toast.error(
      `Tipo de archivo no permitido, el tipo tiene que ser ${allowedTypes.join(
        " - "
      )}`
    );
  return isTypeAllowed && isSizeAllowed;
};

export const blobFromDataURI = (dataURI: string) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const urlToDataURL = async (
  url: string,
  contentType = "image/png"
): Promise<string> => {
  const blob = await fetch(url).then((r) => r.blob());
  const dataUrl: string = await new Promise((resolve: Function) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });

  return `data:${contentType};${dataUrl.split(";")[1]}`;
};

export const makeid = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const CL$Format = (value: any, code?: string, symbol?: string) => {
  const precision =  (value - Math.floor(value)) === 0 ? 0 : 2;
  const Format = currencyFormatter.format(value, {
    code: code,
    symbol,
    decimal: ",",
    thousand: ".",
    precision,
    format: {
      pos: "%s%v",
      neg: "(%s%v)",
      zero: "%s%v",
    },
  });

  return Format;
};
