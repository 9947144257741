import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import {useIntl} from 'react-intl';

interface Props {
  onSubmit?: () => void;
  onClose: () => void;
  message?: string;
}

export default function AreYouSure(props: Props) {
  const { onSubmit, onClose, message } = props;
  const intl = useIntl();
  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 align-items-center p-4">
            <h2 className="h2 text-center mb-4">
              {intl.formatMessage({ id :  "app.modal.Areyousure"})}
            </h2>
            <div className="my-3 text-center">{message}</div>
            <Button className="w-50 mb-2" color="primary" onClick={onSubmit}>
              {intl.formatMessage({ id :  "app.modal.Yes"})}
            </Button>
            <Button className="w-50" color="secondary" onClick={onClose}>
              {intl.formatMessage({ id :  "app.modal.No"})}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
