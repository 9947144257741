import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "reactstrap";
import { Company } from "../../../cores/contacts/interfaces/api/get-company";
import { PanelWembii } from "../PanelWembii";

export const CompanyRow = ({ company, canModify, onEdit }: {
  company: Company;
  canModify: boolean;
  onEdit: (arg0: Company) => void;
}) => {
  return (
    <PanelWembii
      className="w-100 px-4 py-3 my-2"
      shadow
    >
      <Row className="d-flex align-items-center justify-content-center">
        <Col md="7">
          <div>
            {company.name}
          </div>
          <div className="text-muted">
            {company.role}
          </div>
        </Col>
        <Col md="5" className="text-right">
          {canModify && (
            <FontAwesomeIcon
              icon={["fas", "edit"]}
              size="1x"
              className="ml-3 pointer"
              color="gray"
              onClick={() => onEdit(company)}
            />
          )}
        </Col>
      </Row>
    </PanelWembii>
  );
};