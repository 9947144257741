import { createSelector } from "reselect";
import { State } from "../../../redux/state";

export const socialPostRoot = (state: State) => state.socialPost;

export const socialProvidersSelector = createSelector(
  socialPostRoot,
  ({ socialProviders }) =>
    socialProviders.ids.map((id) => socialProviders.entities[id])
);

export const postErrorsSelector = createSelector(socialPostRoot, ({ postErrors }) =>
  postErrors.ids.map((id) => postErrors.entities[id])
);

export const storyErrorsSelector = createSelector(socialPostRoot, ({ storyErrors }) =>
  storyErrors.ids.map((id) => storyErrors.entities[id])
);

export const socialProviderEntitiesSelector = createSelector(
  socialPostRoot,
  ({ socialProviders }) => socialProviders.entities
);

export const imagesSelector = createSelector(socialPostRoot, ({ images }) =>
  images.ids.map((id) => images.entities[id])
);

export const videoSelector = createSelector(
  socialPostRoot,
  ({ video }) => video
);

export const textSelector = createSelector(socialPostRoot, ({ text }) => text);

export const socialPostsSelector = createSelector(socialPostRoot, ({ socialPosts }) =>
  socialPosts.ids.map((id) => socialPosts.entities[id])
);

export const selectedPostSelector = createSelector(socialPostRoot, ({ post }) => post);

export const socialActionSelector = createSelector(socialPostRoot, ({ action }) => action);

export const hashtagsSelector = createSelector(socialPostRoot, ({ hashtags }) =>
  hashtags.ids.map((id) => hashtags.entities[id])
);

export const dateSelector = createSelector(socialPostRoot, ({ date }) => date);

export const inputCursorIndexSelector = createSelector(socialPostRoot, ({ inputCursorIndex }) => inputCursorIndex);

export const mentionsSelector = createSelector(socialPostRoot, ({ mentions }) => 
  mentions.ids.map((id) => mentions.entities[id])
);
