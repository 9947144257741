import mainApiClient from "../../helpers/services/main-api-client";
import {
  PostSessionRequest,
  PostSessionResponse,
} from "../interfaces/api/post-session";
import {
  PostSessionRefreshRequest,
  PostSessionRefreshResponse,
} from "../interfaces/api/post-sessionRefresh";
import { sessionMapper } from "./mapper";

export const sessionApiService = {
  postSession(email: string, password: string) {
    const body: PostSessionRequest = { email, password };
    return mainApiClient()
      .post<PostSessionResponse>(`session`, body)
      .then(({ data }) => sessionMapper.mapResponseToSession(data));
  },
  postSessionRefresh(email: string, refreshToken: string) {
    const body: PostSessionRefreshRequest = { email, refreshToken };
    return mainApiClient()
      .post<PostSessionRefreshResponse>(`session/refresh`, body)
      .then(({ data }) => sessionMapper.mapResponseToSession(data));
  },
};
