import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Container, Form, Label, Media } from "reactstrap";
import { useWebChatCore } from "../../../../cores/conversations";
import { InputWembii } from "../../InputWembii";
import { PanelWembii } from "../../PanelWembii";
import { useIntl } from "react-intl";
import PoweredBy from "../../PoweredBy";
import { TitleWembii } from "../../TitleWembii";
import Icofont from "react-icofont";

export default function ChatPreview() {
  const intl = useIntl();
  const { chat, avatar } = useWebChatCore();

  return (
    <Container className="sticky-top p-0 z-index-50">
      <PanelWembii shadow className="p-4">
        <TitleWembii Tag="h5" color="primary">
          {intl.formatMessage({ id: "app.chatConfigPreview.preview" })}
        </TitleWembii>

        <PanelWembii
          shadow
          header={
            <div
              className="d-flex align-items-center p-2 overflow-hidden"
              style={{
                color: chat?.config?.titleColor,
                backgroundColor: chat?.config?.backgroundColor,
                borderRadius: "20px 20px 0 0",
              }}
            >
              {chat?.config?.avatar || avatar ? (
                <Media
                  style={{ width: 80, height: 80 }}
                  object
                  className="rounded-circle object-fit-cover"
                  src={avatar ? avatar.data : chat.config?.avatar!}
                  alt="Avatar chat"
                />
              ) : (
                <Icofont icon="live-support" size="4" />
              )}
              <div className="d-flex flex-column p-2">
                <Label className="font-weight-bold">
                  {chat?.config?.title}
                </Label>
                <Label className="m-0">{chat?.config?.subtitle}</Label>
              </div>
            </div>
          }
        >
          <Form className="p-4">
            <InputWembii
              type="text"
              name="name"
              id="name"
              placeholder={intl.formatMessage({
                id: "app.chatConfigPreview.nameTxt",
              })}
              className="shadow-sm"
            />
            <InputWembii
              type="email"
              name="email"
              id="email"
              className="shadow-sm"
              placeholder={intl.formatMessage({
                id: "app.chatConfigPreview.emailTxt",
              })}
            />
          </Form>

          <div className="d-flex justify-content-center my-4">
            <Button
              className="shadow border-0"
              style={{
                backgroundColor: chat?.config?.startButtonColor,
              }}
            >
              {intl.formatMessage({ id: "app.chatConfigPreview.startBntTxt" })}
            </Button>
          </div>

          <div className="d-flex justify-content-center">
            <PoweredBy />
          </div>
        </PanelWembii>

        <div className="d-flex justify-content-end mt-2">
          <Button
            className="shadow p-0 border-0"
            style={{
              width: 40,
              height: 40,
              backgroundColor: chat?.config?.openButtonColor,
            }}
          >
            <FontAwesomeIcon
              icon={["fas", "times"]}
              size="1x"
              color={chat?.config?.openButtonIconColor}
              className="pointer"
            />
          </Button>
        </div>
      </PanelWembii>
    </Container>
  );
}
