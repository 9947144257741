import React, { useCallback } from "react";
import { DropdownItem } from "reactstrap";
import { useRouterCore } from "../../../cores/router";
import { MessageType } from "../../../cores/socket/constant/messageType.constant";
import { NotificationType } from "../../../cores/notifications/constants/notificationType.constant";
import {
  ContactExportedNotification,
  ContactsImportedNotification,
  ConversationNotification,
  ImportingProcessNotification,
  MessageNotification,
  NewContactsNotification,
  Notification,
  SocialPostNotification,
  SubscriptionNotification,
  TaskAssignmentNotification,
} from "../../../cores/notifications/interfaces/api/get-notifications";
import { useWebChatCore } from "../../../cores/conversations";
import ConversationNotificationItem from "./ConversationNotificationItem";
import SocialPostNotificationItem from "./SocialPostNotificationItem";
import MessageNotificationItem from "./MessageNotificationItem";
import { useNotificationCore } from "../../../cores/notifications";
import { useCompanyCore } from "../../../cores/company";
import SubscriptionNotificationItem from "./SubscriptionNotificationItem";
import ContactsImportedNotificationItem from "./ContactsImportedNotificationItem";
import { useSocialChatCore } from "../../../cores/socialChat";
import ContactExportedNotificationItem from "./ContactExportedNotificationItem";
import TaskAssignmentNotificationItem from "./TaskAssignmentNotificationItem";
import NewContactNotificationItem from "./NewContactsNotificationItem";
import ImportingProcessNotificationItem from "./ImportingProcessNotificationItem";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  notification: Notification;
  unread?: boolean;
}

export default function NotificationContainer(props: Props) {
  const { notification, unread } = props;

  const { company } = useCompanyCore();
  const { goToWebChat } = useRouterCore();
  const { putReadNotification, getUnreadNotifications, getNotifications } =
    useNotificationCore();
  const { selectWebConversation } = useWebChatCore();
  const {
    selectFacebookConversation,
    selectInstagramConversation,
    selectWhatsappConversation,
  } = useSocialChatCore();

  const handleSelectNotification = useCallback(async () => {
    putReadNotification(company?.id!, notification.id);
    getUnreadNotifications(company?.id!);
    getNotifications(company?.id!, 1);

    const ntf = notification.data as MessageNotification;
    switch (ntf.type) {
      case MessageType.FACEBOOK:
        selectFacebookConversation(ntf.id);
        goToWebChat();
        break;
      case MessageType.WHATSAPP:
        selectWhatsappConversation(ntf.id);
        goToWebChat();
        break;
      case MessageType.INSTAGRAM:
        selectInstagramConversation(ntf.id);
        goToWebChat();
        break;
      case MessageType.WEB:
        selectWebConversation(ntf.id);
        goToWebChat();
        break;
    }
  }, [
    notification,
    company,
    goToWebChat,
    putReadNotification,
    getUnreadNotifications,
    selectWebConversation,
    selectFacebookConversation,
    selectInstagramConversation,
  ]);

  const getNotificationItem = useCallback(() => {
    switch (notification.type) {
      case NotificationType.MESSAGE:
        return (
          <MessageNotificationItem
            notification={notification.data as MessageNotification}
          />
        );
      case NotificationType.CONVERSATION:
        return (
          <ConversationNotificationItem
            notification={notification.data as ConversationNotification}
          />
        );
      case NotificationType.SOCIAL_POST:
        return (
          <SocialPostNotificationItem
            notification={notification.data as SocialPostNotification}
          />
        );
      case NotificationType.SUBSCRIPTION:
        return (
          <SubscriptionNotificationItem
            notification={notification.data as SubscriptionNotification}
          />
        );
      case NotificationType.CONTACTS_IMPORTED:
        return (
          <ContactsImportedNotificationItem
            notification={notification.data as ContactsImportedNotification}
          />
        );
      case NotificationType.IMPORTING_PROCESS:
        return (
          <ImportingProcessNotificationItem
            notification={notification.data as ImportingProcessNotification}
          />
        );
      case NotificationType.CONTACT_EXPORTED:
        return (
          <ContactExportedNotificationItem
            notification={notification.data as ContactExportedNotification}
          />
        );
      case NotificationType.NEW_CONTACT:
        return (
          <NewContactNotificationItem
            notification={notification.data as NewContactsNotification}
          />
        );
      case NotificationType.TASK_ASSIGNMENT:
        return (
          <TaskAssignmentNotificationItem
            notification={notification.data as TaskAssignmentNotification}
          />
        );
    }
  }, [notification]);
  const markAsRead = async () => {
    //TODO: Evitar requests y actualizar las notis leidas en el store
    await putReadNotification(company?.id!, notification.id);
    await getUnreadNotifications(company?.id!);
    await getNotifications(company?.id!, 1);
  };
  return (
    <div
      className="d-flex"
      style={unread ? { backgroundColor: "#daecff" } : {}}
    >
      <div className="rounded-lg w-100">
        <DropdownItem
          className={cn("w-100 p-2 ", {
            "fw-bold": unread,
          })}
          onClick={() => handleSelectNotification()}
        >
          {getNotificationItem()}
        </DropdownItem>
      </div>
      {unread && (
        <button onClick={() => markAsRead()} className="my-auto btn">
          <FontAwesomeIcon color="primary" icon={["fas", "check"]} />
        </button>
      )}
    </div>
  );
}
