import {
  createAction,
  joinActions,
  props,
} from "../../helpers/store/actions-helpers";
import { Area, WebChat } from "../interfaces/api/get-web-chat";
import { Conversation } from "../interfaces/api/get-conversations";
import { WebMessage } from "../interfaces/api/get-messages";
import { ConversationStatusRequest } from "../interfaces/api/update-conversations";
import { ConversationStatus } from "../../socialChat/types/conversation-status.types";
import { ContactOwner } from "../../contacts/interfaces/api/get-contact";

export const getChatInitAction = createAction("[WebChat] Get Chat Init");
export const getChatSuccessAction = createAction(
  "[WebChat] Get Chat Success",
  props<{ chat: WebChat; areas: Area[] }>()
);
export const getChatFailureAction = createAction(
  "[WebChat] Get Chat Failure",
  props<{ error: string }>()
);
export const updateWebConversationOwners = createAction(
  "[WebChat] Update Web Conversations Owners",
  props<{
    conversationId: string;
    statusToUpdate: ConversationStatus;
    newOwners: ContactOwner[];
    participantId: string;
  }>()
);

export const putChatInitAction = createAction("[WebChat] Put Chat Init");
export const putChatSuccessAction = createAction(
  "[WebChat] Put Chat Success",
  props<{ chat: WebChat }>()
);
export const putChatFailureAction = createAction(
  "[WebChat] Put Chat Failure",
  props<{ error: string }>()
);
export const updateConversationStatusAction = createAction(
  "[WebChat] Update Conversation Status Action",
  props<{ conversationStatus: ConversationStatusRequest }>()
);
export const getConversationsInitAction = createAction(
  "[WebChat] Get Conversations Init"
);
export const getConversationsSuccessAction = createAction(
  "[WebChat] Get Conversations Success",
  props<{
    active: Conversation[];
    archived: Conversation[];
    inTrash: Conversation[];
  }>()
);
export const getConversationsFailureAction = createAction(
  "[WebChat] Get Conversations Failure",
  props<{ error: string }>()
);

export const getMessagesInitAction = createAction(
  "[WebChat] Get Messages Init"
);
export const getMessagesSuccessAction = createAction(
  "[WebChat] Get Messages Success",
  props<{ messages: WebMessage[] }>()
);
export const getMessagesFailureAction = createAction(
  "[WebChat] Get Messages Failure",
  props<{ error: string }>()
);

export const postAreaInitAction = createAction("[WebChat] Post Area Init");
export const postAreaSuccessAction = createAction(
  "[WebChat] Post Area Success",
  props<{ area: Area }>()
);
export const postAreaFailureAction = createAction(
  "[WebChat] Post Area Failure",
  props<{ error: string }>()
);

export const putAreaInitAction = createAction("[WebChat] Put Area Init");
export const putAreaSuccessAction = createAction(
  "[WebChat] Put Area Success",
  props<{ area: Area }>()
);
export const putAreaFailureAction = createAction(
  "[WebChat] Put Area Failure",
  props<{ error: string }>()
);

export const deleteAreaInitAction = createAction("[WebChat] Delete Area Init");
export const deleteAreaSuccessAction = createAction(
  "[WebChat] Delete Area Success",
  props<{ id: string }>()
);
export const deleteAreaFailureAction = createAction(
  "[WebChat] Delete Area Failure",
  props<{ error: string }>()
);

export const deleteConversationInitAction = createAction(
  "[WebChat] Delete Conversation Init"
);
export const deleteConversationSuccessAction = createAction(
  "[WebChat] Delete Conversation Success",
  props<{ id: string; conversationStatus: ConversationStatus }>()
);
export const deleteConversationFailureAction = createAction(
  "[WebChat] Delete Conversation Failure",
  props<{ error: string }>()
);

const actions = joinActions({
  getChatInitAction,
  getChatSuccessAction,
  getChatFailureAction,
  putChatInitAction,
  putChatSuccessAction,
  putChatFailureAction,
  getConversationsInitAction,
  getConversationsSuccessAction,
  getConversationsFailureAction,
  getMessagesInitAction,
  getMessagesSuccessAction,
  getMessagesFailureAction,
  postAreaInitAction,
  postAreaSuccessAction,
  postAreaFailureAction,
  putAreaInitAction,
  putAreaSuccessAction,
  putAreaFailureAction,
  deleteAreaInitAction,
  deleteAreaSuccessAction,
  updateWebConversationOwners,
  deleteAreaFailureAction,
  deleteConversationInitAction,
  deleteConversationSuccessAction,
  deleteConversationFailureAction,
});

export type WebChatApiAction = typeof actions;
