import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Coupon } from "../../../cores/payment/interfaces/api/get-coupon";

interface Props {
  coupon: Coupon | null;
}

export const CouponInfo = (props: Props) => {
  const { coupon } = props;
  return (
    <div className="mb-2">
      {!coupon || !coupon.status ? (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            color="#ef767a"
            size="2x"
            className="mx-3"
            icon={["fas", "times"]}
          />
          <span className="text-muted">Coupon no disponible</span>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            color="#23f0c7"
            size="2x"
            className="mx-3"
            icon={["fas", "check"]}
          />
          {coupon.name}
          {coupon.percentOff ? (
            ` -${coupon.percentOff}%`
          ) : (
            ` -${coupon.currency} ${coupon.amount}`
          )}
        </div>
      )}
    </div>
  );
};
