import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  children: ReactNode;
  active: boolean;
  onClose: () => void;
}

export default function InternalModal(props: Props) {
  const { children, active, onClose } = props;

  return (
    <>
      {active && (
        <div
          className="h-100 w-100 position-fixed top-0 z-index-50 d-flex align-items-center justify-content-center bg-black-transparent"
          style={{ borderRadius: "30px" }}
        >
          <div className="overflow-auto mh-100 position-relative text-center bg-white rounded p-2">
            <div className="text-right w-100">
              <FontAwesomeIcon
                icon={["fas", "times"]}
                size="1x"
                className="pointer"
                onClick={onClose}
              />
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
}
