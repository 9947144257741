import { SocialPostRules } from "../socialPost/interfaces/postError";

export const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const SCOPE =
  "pages_manage_ads,pages_manage_metadata,pages_show_list,pages_read_engagement,ads_management,instagram_basic,instagram_manage_insights,instagram_content_publish,business_management";

export const SOCIAL_POST_RULES: SocialPostRules = {
  maxVideoSize: parseInt(
    process.env.REACT_APP_INSTAGRAM_MAX_VIDEO_SIZE ||
    process.env.REACT_APP_DEFAULT_MAX_VIDEO_SIZE ||
    "100"
  ),
  maxImageSize: parseInt(
    process.env.REACT_APP_INSTAGRAM_MAX_IMAGE_SIZE ||
    process.env.REACT_APP_DEFAULT_MAX_IMAGE_SIZE ||
    "100"
  ),
  maxCharactersAllowed: 2000,
  maxPostPhotoAllowed: 10,
  maxStoryPhotoAllowed: 1,
  postMediaRequired: true,
  storyMediaRequired: true,
  videoAllowed: true,
  maxVideoDuration: 60,
  imageAspectRatioSizeAllowed: [0.8, 1.91],
  videoAspectRatioSizeAllowed: [0.8, 1.91],
  imageAspectRatioAllowed: [],
};
