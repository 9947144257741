import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useSocialPostCore } from "../../../../cores/socialPost";
import { Media } from "../../../../cores/socialPost/interfaces/api/get-social-posts";
import { PostImage } from "../../../../cores/socialPost/interfaces/postImage";

interface Props {
  onClose: () => void;
  image: PostImage | Media;
}

export const ImageEditorForm = ({ onClose, image }: Props) => {
  const { removeImage, removePostMedia } = useSocialPostCore();
  const [name, setName] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [size, setSize] = useState(0);
  const [type, setType] = useState("");
  const [aspectRatio, setAspectRatio] = useState("");

  useEffect(() => {
    if (image.type) {
      setName((image as PostImage).name);
      setImageSrc((image as PostImage).data);
      setSize((image as PostImage).size);
      setType((image as PostImage).type);
      setAspectRatio((image as PostImage).aspectRatio || "");
    } else {
      setName((image as Media).key);
      setImageSrc((image as Media).url);
    }
  }, [image]);

  const handleDelete = () => {
    if (image.type) {
      removeImage(image as PostImage);
    } else {
      removePostMedia(image as Media);
    }
    onClose();
  };

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs="12">
          <div className="d-flex flex-column h-100 align-items-center p-4">
            <h2 className="h2 text-center mb-4">Editar Imagen</h2>
            <img className="imageEditor" src={imageSrc} alt={name} />
            <div className="px-2 my-3">
              <div className="my-3 text-center">
                <span className="font-weight-bolder">Nombre: </span>
                {name}
              </div>
              {image.type && (
                <>
                  <div className="my-3 text-center">
                    <span className="font-weight-bolder">Tamaño: </span>
                    {size} bytes
                  </div>
                  {aspectRatio && (
                    <div className="my-3 text-center">
                      <span className="font-weight-bolder">Aspect Ratio: </span>
                      {aspectRatio}
                    </div>
                  )}
                  <div className="my-3 text-center">
                    <span className="font-weight-bolder">Tipo: </span>
                    {type}
                  </div>
                </>
              )}
            </div>
            <Button className="w-50" color="danger" onClick={handleDelete}>
              Eliminar
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
